import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Spin } from 'antd'
import { Pie } from 'react-chartjs-2'
import 'chartjs-plugin-labels'
import { Heading, GraphCard, Graph } from '../../style'
import StatCard from '../../Components/Card'
import { TeamMemberOverviewLoader } from '../../Components/Loaders'
import { ReactComponent as RefreshIcon } from '../../../../../assets/Icons/Analytics/refresh.svg'
import { GetAnalyticsData } from '../../../../../services/NetworkCalls/Analytics'
import { warning } from '../../../../../services/Notification'
import apiCaller from '../../../../../services/apiCaller'
import { getPercentage } from '../../../../Helpers/utils'
import {
    CORNFLOWER_BLUE,
    NON_PHOTO_BLUE,
    CHRISTMAS_PINK,
    FRIENDLY_YELLOW,
} from '../../../../Helpers/Stylesheet/styledComponents'

const { formatError } = apiCaller

const Overview = ({ companyId, GetAnalyticsData }) => {
    const [cardData, setCardData] = useState({})
    const [chartData, setChartData] = useState({})
    const [cardLoading, setCardLoading] = useState(false)
    const [chartLoading, setChartLoading] = useState(false)
    const [loadTime, setLoadTime] = useState(moment().format('DD MMM, YYYY LT'))

    useEffect(() => {
        getTeamMemberOverviewData()
        getTeamMemberChartData()
    }, [])

    const getTeamMemberOverviewData = async () => {
        try {
            setCardLoading(true)
            const res = await GetAnalyticsData('GetTeamMemberOverviewData', null, companyId)
            setCardLoading(false)
            setCardData(res)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getTeamMemberChartData = async () => {
        try {
            setChartLoading(true)
            const res = await GetAnalyticsData('GetTeamMemberChartData', null, companyId)
            const data = {
                labels: ['Super Admin', 'Admin', 'Job Level Access', 'Recruiter'],
                datasets: [
                    {
                        data: [res['Super Admin'], res['Admin'], res['Job Level Access'], res['Recruiter']],
                        backgroundColor: [FRIENDLY_YELLOW, CHRISTMAS_PINK, NON_PHOTO_BLUE, CORNFLOWER_BLUE],
                        hoverBackgroundColor: [FRIENDLY_YELLOW, CHRISTMAS_PINK, NON_PHOTO_BLUE, CORNFLOWER_BLUE],
                        borderWidth: 0,
                    },
                ],
            }
            setChartData(data)
            setChartLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const handleRefresh = () => {
        getTeamMemberOverviewData()
        getTeamMemberChartData()
        setLoadTime(moment().format('DD MMM, YYYY LT'))
    }

    return (
        <>
            <div className="sub-header">
                <Heading>Overview</Heading>
                <div>
                    <RefreshIcon onClick={() => handleRefresh()} />
                    <span>Last Updated: {loadTime}</span>
                </div>
            </div>
            {cardLoading ? (
                <TeamMemberOverviewLoader />
            ) : (
                <div className="cards">
                    <StatCard
                        total={cardData.totalTeamMember}
                        title="Total Team Members"
                        tooltipText="Total team member added on platform"
                    />
                    <StatCard
                        total={cardData.interviewsScheduled}
                        title="Interviews Scheduled"
                        tooltipText="Total interview scheduled for candidates"
                    />
                    <StatCard
                        total={cardData.feedbackAdded}
                        title="Feedback Added"
                        desc={`${getPercentage(
                            cardData.feedbackAdded,
                            cardData.interviewsScheduled
                        )}% of Interviews Scheduled`}
                        tooltipText="No of feedback added out of total interview scheduled"
                    />
                </div>
            )}
            <Heading mb>Last 30 days</Heading>
            <Spin spinning={chartLoading}>
                <>
                    <GraphCard>CANDIDATE ADDED BY DIFFERENT ACCESS ROLES</GraphCard>
                    <Graph>
                        <Pie
                            data={chartData}
                            options={{
                                maintainAspectRatio: false,
                                cutoutPercentage: 25,
                                plugins: {
                                    labels: {
                                        render: 'percentage',
                                        fontSize: 15,
                                        fontStyle: 'bold',
                                    },
                                },
                                legend: {
                                    display: true,
                                    position: 'top',
                                    align: 'end',
                                    labels: {
                                        boxWidth: 7,
                                        usePointStyle: true,
                                        padding: 20,
                                    },
                                },
                            }}
                        />
                    </Graph>
                </>
            </Spin>
        </>
    )
}

const mapStateToProps = ({ Auth }) => {
    return {
        companyId: Auth.user.companyId,
    }
}

export default connect(
    mapStateToProps,
    {
        GetAnalyticsData,
    }
)(Overview)
