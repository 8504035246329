import React from 'react'
import { MessageWithIconModal } from '../common'
import { Typography } from 'antd'
import { ReactComponent as InfoIcon } from '../../../assets/Icons/Board/Info.svg'
import './style.scss'

export const WarningAccountAlreadyExists = props => {
    return (
        <MessageWithIconModal
            title="ACCOUNT ALREADY EXISTS"
            modalIcon={<InfoIcon />}
            message={
                <>
                    <p>
                        Looks like someone from <b>{props.companyMail}</b> has already signed up.
                    </p>
                    <p>
                        To start using SpringRecruit, please send an email to {''}
                        <Typography.Text className="warn-account-exists_mail-text" copyable>
                            info@springrecruit.com
                        </Typography.Text>
                    </p>
                    Our team will get in touch with you.
                </>
            }
            onConfirm={props.onConfirm}
            confirmText="OKAY"
        />
    )
}
