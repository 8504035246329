import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20">
            <defs>
                <path
                    id="a"
                    d="M906 3678a1 1 0 010 2h-6.5a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-6.5a1 1 0 012 0v6.5a2.5 2.5 0 01-2.5 2.5h-13a2.5 2.5 0 01-2.5-2.5v-13a2.5 2.5 0 012.5-2.5h6.5zm10-2h.02c.023 0 .046.002.07.004L916 3676a1.008 1.008 0 01.617.213c.063.05.12.107.17.17l-.08-.09a1.009 1.009 0 01.292.675l.001.032v6a1 1 0 01-2 0v-3.586l-7.293 7.293a1 1 0 01-1.414-1.414l7.291-7.293H910a1 1 0 010-2h6z"
                />
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" transform="translate(-897 -3676)">
                <mask fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <use fill="#FFF" fillRule="nonzero" xlinkHref="#a" />
            </g>
        </svg>
    )
}

export default Icon
