import styled from 'styled-components'
import { ContentWrapper } from '../style'
import { FormContainer } from '../../Pages/ScheduleInterview/Form/style'
import {
    DARK_BLUE,
    RED,
    LIGHT_GREY,
    DIVISION_COLOR,
    GREY,
    BLACK,
    ORANGE,
    WHITE,
} from '../../Helpers/Stylesheet/styledComponents'
import Error from '../../../assets/Icons/Access/error.svg'
import WarningIcon from '../../../assets/Icons/Interview/warning.svg'
import { Collapsible } from '../../Modals/style'
import { rotate } from '../../Pages/ScheduleInterview/Form/Slots/style'

export const ComponentWrapper = styled(ContentWrapper)`
    .help {
        font-size: 12px;
        color: ${LIGHT_GREY};
        margin-top: -10px;
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        .save {
            color: ${DARK_BLUE};
            cursor: pointer;
            &.disabled {
                pointer-events: none;
                color: ${LIGHT_GREY};
            }
        }
    }
`
export const CollapsibleGroup = styled.div`
    margin: 0 -25px;
    max-height: 50vh;
    min-height: 257px;
    overflow-y: auto;
    border-top: 1px solid ${DIVISION_COLOR};
    border-bottom: 1px solid ${DIVISION_COLOR};
`
export const FooterMessages = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 37px;
    right: 25px;
    .bold {
        font-family: SFProText-Bold;
        font-weight: bold;
        color: ${BLACK};
    }
`
export const CandidateCollapsible = styled(Collapsible)`
    .category {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-family: sanFranciscoMedium;
        text-transform: capitalize;
        padding-left: 25px;
        > div:first-child {
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &.active + .list {
            max-height: 500px;
        }
        &.failed {
            color: ${RED};
        }
        &.loading {
            color: ${LIGHT_GREY};
            .loader-icon {
                animation: ${rotate} 2s linear infinite;
            }
        }
        &.delete {
            background: #fff;
            text-transform: initial;
            padding-left: 30px;
            cursor: initial;
            button {
                font-size: 14px;
                font-weight: 500;
                font-family: sanFranciscoMedium;
                padding: 0 10px;
                border: none;
                text-transform: uppercase;
                color: ${DARK_BLUE};
                background: #fff;
                cursor: pointer;
            }
        }
        .error {
            color: ${RED};
            font-size: 12px;
            font-family: sanFrancisco;
        }
        svg:not(:first-child) {
            margin-left: 10px;
        }
        .delete-icon,
        :hover .status-icon {
            display: none;
        }
        :hover .delete-icon,
        .status-icon {
            display: inline;
        }
        .color-red {
            color: red;
        }
    }
    .list {
        padding-left: 50px;
        padding-right: 25px;
    }
`
export const FormWrapper = styled(FormContainer)`
    all: unset;
    display: grid;
    grid-template-columns: repeat(auto-fit, 48%);
    grid-gap: 30px 20px;
    margin: 20px 0;
    margin-top: ${props => props.mt || ''};
    .ant-upload.ant-upload-drag {
        padding: 20px 0;
        margin-bottom: 20px;
        background-color: rgba(116, 156, 228, 0.05);
        border: 2px dashed ${DARK_BLUE};
    }
    .title {
        margin-bottom: 5px;
        font-family: sanFranciscoMedium;
        font-size: 14px;
        font-weight: 500;
        color: ${BLACK};
    }
    .error {
        color: ${RED};
        -webkit-text-fill-color: ${RED};
        font-size: 12px;
        margin-top: 10px;
        &::before {
            content: url(${Error});
            margin-right: 5px;
            vertical-align: text-top;
        }
    }
    .ant-select {
        width: 100%;
    }
    @media only screen and (max-width: 769px) {
        display: block;
        padding: initial;
        margin-bottom: 0;
    }
`
export const Tabs = styled.div`
    border-bottom: 1px solid ${DIVISION_COLOR};
    font-family: sanFranciscoMedium;
    font-size: 14px;
    font-weight: 500;
    color: ${GREY};
    display: flex;
    text-transform: uppercase;
    width: fit-content;
    div {
        cursor: pointer;
        padding: 5px 0;
        :first-child {
            margin-right: 40px;
        }
    }
    .active {
        color: ${DARK_BLUE};
        border-bottom: 2px solid ${DARK_BLUE};
    }
`
export const Field = styled.div`
    .title {
        margin-bottom: 5px;
        font-family: sanFranciscoMedium;
        font-size: 14px;
        font-weight: 500;
        color: ${BLACK};
    }
    .file {
        display: flex;
        align-items: center;
        height: 40px;

        .failed {
            color: ${RED};
        }
    }
    .error {
        color: ${RED};
        -webkit-text-fill-color: ${RED};
        font-size: 12px;
        margin-top: 10px;
        ::before {
            content: url(${Error});
            margin-right: 5px;
            vertical-align: text-top;
        }
    }

    .warning {
        color: ${ORANGE};
        -webkit-text-fill-color: ${ORANGE};
        font-size: 12px;
        margin-top: 10px;
        ::before {
            content: url(${WarningIcon});
            margin-right: 5px;
            vertical-align: text-top;
        }
    }
    .phone {
        display: flex;
        width: 100%;
        flex-direction: row;

        .country-code {
            font-size: 12px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            border: 1px solid #d9d9d9;
            border-radius: 4px 0 0 4px;
        }

        .placeholder {
            color: #999999;
        }

        .mt30 {
            margin-top: 30px;
        }
    }
    .filter-popover {
        width: calc(100% - 30px);
    }
    .openings {
        height: 42px;
        background: #ffffff;
        border: 1px solid ${DIVISION_COLOR};
        border-radius: 4px;
        white-space: nowrap;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px 20px 10px 10px;
        font-size: 12px;
        position: relative;

        .down-icon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
        .selected {
            display: flex;
            flex-direction: row;
            &-view {
                width: 40px;
                border-radius: 25px;
                color: ${WHITE};
                background-color: ${DARK_BLUE};

                svg {
                    float: right;
                    margin-top: 5px;
                    margin-right: 5px;
                }
            }
            span {
                margin-left: 5px;
            }
        }
    }
    .border-red {
        border: 1px solid ${RED};
    }
    @media (max-width: 767px) {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`

export const PhoneInputSearch = styled.div`
    padding: 10px;
    width: 260px;
    .phone-code-list {
        margin-top: 5px;
        max-height: 200px;
        overflow-y: scroll;
    }
`
