import config from '../../../config'
import { store } from '../../../redux/store'
import apiCaller from '../../apiCaller'
import { success, warning } from '../../Notification'
import ajaxCaller from '../../apiCaller'
import authActions from '../../../redux/auth/actions'
import { LogE } from '../../../container/Helpers/errorHandler'
const { restricted } = apiCaller.type
const { formatError } = apiCaller
const axios = ajaxCaller.wrappers.axios
const { baseURL, apiVersion } = config.config()
const API_VERSION = apiVersion.v2
const baseUrl_V2 = `${baseURL}${API_VERSION}me/`
const baseUrl_V2_public = `${baseURL}${API_VERSION}`
const baseUrl = 'sourcing/'

/*
 authProof : avaiable as a query parameter from the candidate link when the
*/

export const GetInitialDetails = ({ authProof, applicant }) => async dispatch => {
    try {
        const url = `${baseUrl_V2_public}${baseUrl}form/candidate/${applicant}`
        const headers = {}
        if (authProof.length) headers['Auth-Secret'] = authProof

        const { data } = await axios.get(url, {
            headers,
        })

        if (data.error) {
            LogE('networkcalls-sourcing-getinitialdetails', '', data.error.msg)
            warning(data.error.msg || 'Failed to fetch initial candidate details')
            return {}
        }
        return data
    } catch (error) {
        LogE('networkcalls-sourcing-getinitialdetails-catch', '', error)
        warning(formatError(error))
        return {}
    }
}

export const GetSkills = async ({ authProof, search }) => {
    try {
        const url = `${baseUrl_V2_public}${baseUrl}search/skills?q=${search}`
        const headers = {}
        if (authProof.length) headers['Auth-Secret'] = authProof

        const { data } = await axios.get(url, {
            headers,
        })

        if (data.error) {
            LogE('networkcalls-sourcing-getskills', '', data.error.msg)
            warning(data.error.msg || 'Failed to fetch skills')
            return {}
        }
        return data
    } catch (error) {
        LogE('networkcalls-sourcing-getskills-catch', '', error)
        warning(formatError(error))
        return {}
    }
}

export const GetJobs = async ({ authProof, search }) => {
    try {
        const url = `${baseUrl_V2_public}${baseUrl}search/job_names?q=${search}`
        const headers = {}
        if (authProof.length) headers['Auth-Secret'] = authProof

        const { data } = await axios.get(url, {
            headers,
        })

        if (data.error) {
            LogE('networkcalls-sourcing-getJobs', '', data.error.msg)
            warning(data.error.msg || 'Failed to fetch jobs')
            return {}
        }
        return data
    } catch (error) {
        LogE('networkcalls-sourcing-getjobs-catch', '', error)
        warning(formatError(error))
        return {}
    }
}

export const GetTags = async search => {
    try {
        const { data } = await restricted.get(`${baseUrl_V2}tags/autocomplete?q=${search}`)

        if (data.error) {
            LogE('networkcalls-sourcing-gettags', '', data.error.msg)
            warning(data.error.msg || 'Failed to fetch tags')
            return {}
        }
        return data
    } catch (error) {
        LogE('networkcalls-sourcing-gettags-catch', '', error)
        warning(formatError(error))
        return {}
    }
}

export const UploadResume = input => async dispatch => {
    try {
        const url = `${baseUrl_V2_public}${baseUrl}upload/resume`
        const { authProof, formData } = input
        const headers = {
            'Content-Type': 'multipart/form-data',
        }
        if (authProof.length) headers['Auth-Secret'] = authProof

        const { data } = await axios.post(url, formData, {
            headers,
        })

        if (data.error) {
            LogE('networkcalls-sourcing-uploadresume', '', data.error.msg)
            warning(data.error.msg || 'Failed to upload resume')
            return null
        }
        return data
    } catch (error) {
        LogE('networkcalls-sourcing-uploadresume-catch', '', error)
        warning(formatError(error))
    }
}

export const AddUniversalCandidate = input => async dispatch => {
    try {
        const url = `${baseUrl_V2_public}${baseUrl}form/universal/candidate`
        const { authProof, ...payload } = input
        const headers = {
            'Content-Type': 'application/json',
        }
        if (authProof.length) headers['Auth-Secret'] = authProof

        const { data } = await axios.post(url, payload, {
            headers,
        })

        if (data.error) {
            LogE('networkcalls-sourcing-adduniversalcandidate', '', data.error.msg)
            warning(data.error.msg || 'Failed to add universal candidate')
            return {}
        }
        return data
    } catch (error) {
        LogE('networkcalls-sourcing-adduniversalcandidate-catch', '', error)
        warning(formatError(error))
    }
}

export const SourcingSubscription = input => async dispatch => {
    try {
        const { data } = await restricted.post(`${baseUrl}company/subscribe`, input)
        if (data && data.error) {
            LogE('NetworkCalls-SourcingSubscription-api-error', 'Failed to subscribe', data)
            warning(data.error.msg)
            return null
        } else {
            const {
                settings: { status },
            } = input
            success(
                status === 'enabled'
                    ? 'Subscription to sourcing successful'
                    : 'Sourcing subscription disabled successfully'
            )
            dispatch({ type: authActions.UPDATE_USER, data: { sourcing: input.settings } })
            return data
        }
    } catch (error) {
        warning(formatError(error))
    }
}
