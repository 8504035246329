import React from 'react'
import { MessageModal } from '../common'

export const ConfirmDeleteCandidate = props => (
    <MessageModal
        title="DELETE CANDIDATE"
        message={<span>Are you sure you want to Delete {props.candidateName}'s profile?</span>}
        onConfirm={() => props.onConfirm(props.candidateId)}
        onCancel={props.onCancel}
        confirmText="DELETE"
        cancelText="CANCEL"
    />
)
