import styled, { keyframes } from 'styled-components'

export const Slots = styled.div`
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    border: solid 1px #eeeeee;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    color: #666666;
    padding: 5px 10px;
    min-height: 95px;
    table {
        margin-left: ${({ noLeft }) => (noLeft ? '22px' : '0px')}
        margin-right: ${({ noRight }) => (noRight ? '22px' : '0px')}
        width: calc(100% - 44px);
        td {
            text-align: center;
            :hover {
                color: #0091ff;
                cursor: pointer;
            }
        }
    }
    .icons {
        width: 22px;
        svg {
            flex-shrink: 0;
            align-self: center;
            cursor: pointer;
        }
    }
`

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: solid 1px #eeeeee;
    background-color: #f7f7f7;
    font-size: 12px;
    color: #333333;
    padding: 25px 0;
    height: 101px;
    @media only screen and (max-width: 995px) {
        padding: 25px 30px;
        text-align: center;
        height: unset;
    }
    svg {
        width: 20px;
        height: 20px;
        margin-bottom: 2px;
        &.loading {
            width: 30px;
            height: 30px;
            animation: ${rotate} 2s linear infinite;
        }
    }
`
export const CTA = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    border-radius: 4px;
    background-color: #f7f7f7;
    border: solid 1px #eeeeee;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    color: #666666;
    cursor: pointer;
`
