import React, { useState } from 'react'
import { FSHeader, StickyFooter } from '../common'
import { FSContentWrapper, Card, Cards, Inputs, Wrapper, Collapsible, Tags } from '../style'
import { ReactComponent as DownIcon } from '../../../assets/Icons/Interview/down.svg'
import { ReactComponent as CloseIcon } from '../../../assets/Icons/Access/close.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/Icons/Access/error.svg'
import { Form, Input, Select, Icon } from 'antd'
import { Cards as CardsData } from './AccessLevelDefinitions'
import AccessLevelCollapsable from './AccessLevelCollapsable'
import { ValidateEmailAddress } from '../../../services/api'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import './styles.scss'

const { Option } = Select

const renderCardIcon = card => {
    return React.createElement(card.icon)
}

const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const onSubmit = async (values, onSuccess, onError) => {
    const errors = []
    if (!!!values.accessLevel) errors.push('accessLevel')
    if (values.email === '') errors.push('email')
    // not a new user (in updating the team member ) the email not to be check as its edit is disabled
    if (values.newUser && values.teamMembers.find(mem => mem.email === values.email)) errors.push('duplicateEmail')
    if (
        [3, 4].includes(values.accessLevel) &&
        (!values.accessibleJobs || (values.accessibleJobs && values.accessibleJobs.length === 0))
    )
        errors.push('accessibleJobs')
    const domain = values.email.split('@')[1]
    const res = await ValidateEmailAddress(domain)
    if (res.data.disposable === "true") {
        errors.push('invalidEmail')
    }
    if (errors.length > 0) return onError(errors)
    return onSuccess(values)
}

const isValidEmail = (value, teamMembers, selectedUser) => {
    // for selected user, email is valid, email edit is disabled
    if (selectedUser && selectedUser.length) return ''

    if (value && value !== '') {
        if (emailRegExp.test(value))
            return teamMembers.find(mem => mem.email === value) ? 'Already added as a team member' : ''
        else return 'Invalid email address'
    } else if (value === '') {
        return 'Email is required'
    }
    return ''
}

const InviteTeamMember = ({ onCancel, onConfirm, selectedUser, callInProgress }) => {
    const [email, setEmail] = useState((selectedUser && selectedUser.email) || '')
    const [accessLevel, setAccessLevel] = useState((selectedUser && selectedUser.accessLevel) || 0)
    //All active jobs for the company
    const allJobs = useSelector(({ Job }) => Job.allJobs)
    const _jobs = allJobs.filter(job => job.status === 1)

    const teamMembers = useSelector(({ Mixed }) => Mixed.teamMembers)
    const [jobs, setJobs] = useState({
        all:
            (selectedUser &&
                selectedUser.accessibleJobs &&
                _jobs.filter(job => !selectedUser.accessibleJobs.includes(job._id) && job.status === 1)) ||
            _jobs.filter(job => job.status === 1),
        selected: (selectedUser && selectedUser.accessibleJobs) || [],
    })
    const [accessLevelError, setAccessLevelError] = useState(
        (selectedUser && selectedUser._id && !!!selectedUser.accessLevel) || false
    )
    const [emailError, setEmailError] = useState((selectedUser && !!!selectedUser.email) || '')
    const [jobsError, setJobsError] = useState(
        (selectedUser && [3, 4].includes(selectedUser.accessLevel) && !!!selectedUser.accessibleJobs) || ''
    )
    const handleError = errors => {
        if (errors.includes('accessLevel')) setAccessLevelError(true)
        if (errors.includes('email')) setEmailError('Email is required')
        if (errors.includes('duplicateEmail')) setEmailError('Already added as a team member')
        if (errors.includes('accessibleJobs')) setJobsError('Atleast 1 opening needs to be selected')
        if (errors.includes('invalidEmail')) setEmailError('Please enter a valid email!')
    }

    return (
        <>
            <Wrapper>
                <FSHeader
                    title={_.has(selectedUser, '_id') ? 'Update Team Member' : 'Add Team Member'}
                    subtitle="Invite team members to collaborate and get the job done faster!"
                    fullscreen={true}
                />
                <FSContentWrapper jobsError={jobsError}>
                    <Inputs emailError={emailError}>
                        <Form>
                            <Form.Item label="Email ID of the Team Member">
                                <Input
                                    readOnly={_.has(selectedUser, '_id')}
                                    size="large"
                                    value={email}
                                    placeholder="Enter email id of team member"
                                    onChange={({ target: { value } }) => {
                                        setEmail(value)
                                        setEmailError(isValidEmail(value, teamMembers, selectedUser))
                                    }}
                                />
                                {emailError !== '' && (
                                    <div className="custom-inline-error">
                                        <ErrorIcon />
                                        <p className="custom-inline-error-msg">{emailError}</p>
                                    </div>
                                )}
                            </Form.Item>
                        </Form>
                    </Inputs>

                    <div className="title">User Access Level?</div>
                    <Cards>
                        {CardsData.map(card => (
                            <Card
                                key={card.id}
                                active={card.accessLevel === accessLevel}
                                onClick={() => {
                                    setAccessLevel(card.accessLevel)
                                    setAccessLevelError(false)
                                }}
                                error={accessLevelError}
                            >
                                {renderCardIcon(card)}
                                <div className="content">
                                    <div className="title">{card.title}</div>
                                    {card.paras.map((p, i) => (
                                        <p key={i}>{p}</p>
                                    ))}
                                </div>
                                <button className="active">
                                    {card.accessLevel === accessLevel ? 'Selected' : 'Select'}
                                </button>
                            </Card>
                        ))}
                    </Cards>
                    {[3, 4].includes(accessLevel) && (
                        <Form>
                            <Form.Item label="Select Job Opening" className="item">
                                <Select
                                    size="large"
                                    placeholder="Select"
                                    dropdownClassName="interview-types"
                                    className="interview-select"
                                    getPopupContainer={trigger => trigger.parentNode}
                                    suffixIcon={<Icon component={DownIcon} />}
                                    value={undefined}
                                    onChange={value => {
                                        const _selected = _.clone(jobs.selected)
                                        _selected.push(value)
                                        if (_selected.length === 0)
                                            setJobsError('Atleast 1 opening needs to be selected')
                                        else setJobsError('')
                                        setJobs({
                                            all: _jobs.filter(job => !_selected.includes(job._id)),
                                            selected: _selected,
                                        })
                                    }}
                                    notFoundContent="No more openings"
                                >
                                    {jobs.all.map(job => (
                                        <Option key={job._id} value={job._id}>
                                            {job.jobTitle}
                                        </Option>
                                    ))}
                                </Select>
                                {jobsError !== '' && (
                                    <div className="custom-inline-error">
                                        <ErrorIcon />
                                        <p className="custom-inline-error-msg">{jobsError}</p>
                                    </div>
                                )}
                            </Form.Item>
                            {jobs.selected.length > 0 && (
                                <Form.Item label="Access Openings" className="item">
                                    <Tags>
                                        {allJobs
                                            .filter(job => jobs.selected.includes(job._id))
                                            .map(job => (
                                                <div key={job._id}>
                                                    <span>{job.jobTitle}</span>
                                                    <i hidden={job.status == 0}>
                                                        <CloseIcon
                                                            onClick={() => {
                                                                const _selected = jobs.selected.filter(
                                                                    id => job._id !== id
                                                                )
                                                                if (_selected.length === 0)
                                                                    setJobsError(
                                                                        'Atleast 1 opening needs to be selected'
                                                                    )
                                                                setJobs({
                                                                    all: _jobs.filter(
                                                                        job => !_selected.includes(job._id)
                                                                    ),
                                                                    selected: _selected,
                                                                })
                                                            }}
                                                        />
                                                    </i>
                                                </div>
                                            ))}
                                    </Tags>
                                </Form.Item>
                            )}
                        </Form>
                    )}

                    <div className="title">Access Level Definition</div>

                    <AccessLevelCollapsable accessLevel={accessLevel} />
                </FSContentWrapper>
            </Wrapper>
            <StickyFooter
                onCancel={onCancel}
                onConfirm={() =>
                    onSubmit(
                        {
                            newUser: !!!selectedUser,
                            email,
                            accessibleJobs: jobs.selected,
                            accessLevel,
                            teamMemberId: selectedUser && selectedUser._id,
                            teamMembers,
                        },
                        onConfirm,
                        handleError
                    )
                }
                disabled={callInProgress}
                confirmText={_.has(selectedUser, '_id') ? 'Update' : 'Add'}
            />
        </>
    )
}

export default InviteTeamMember
