/*=================
    COLOR PALETTE
===================*/

export const DIVISION_COLOR = '#e1e4e8'
export const BORDER_COLOR = '#e8e8e8'
export const BACKGROUND_COLOR = '#f7f8fc'

export const DARK_BLUE = '#4767a0'
export const PALE_BLUE = '#ccdbf8'
export const CORNFLOWER_BLUE = '#abd2f2'
export const NON_PHOTO_BLUE = '#a3deea'
export const CHRISTMAS_PINK = '#f9cece'
export const FRIENDLY_YELLOW = '#f5dfb1'
export const JUNGLE_GREEN = '#26a69a'
export const BLUE_COLA = '#0288d1'
export const MICROSOFT_RED = '#f4511e'
export const WHITE = '#ffffff'
export const RED = '#e02020'
export const BLACK = '#333333'
export const ORANGE = '#fa6400'
export const GREY = '#666666'
export const LIGHT_GREY = '#999999'
export const WHISPER_GREY = '#eeeeee'
export const BLUE_GREY = '#8a94a6'
export const CYAN_GREY = '#9099a4'
export const WHITE_SMOKE = '#f7f7f7'

/*=================
    COMPONENTS
 ===================*/

export const TextEllipsis = `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const FlexCenterCol = `
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const FlexCenterRow = `
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`

export const CustomScrollBar = `
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &:hover::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 42px;
    }
`
