import React, { Component } from 'react'
import { Button } from 'antd'

import {
    SIContainer,
    NoInterview,
    NoInterviewTitle,
    SIListContainer,
    InterviewItem,
    CandidateName,
    Role,
    Type,
    Time,
    Date,
} from '../StyleComponent'
import styles from '../style'
import { timeFormat } from '../../../Helpers/times'
import moment from 'moment'

export default class ScheduledInterview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.interviews,
            hasJobs: props.hasJobs,
        }
    }

    interviewList = (index, interviewData) => {
        return (
            <InterviewItem key={index}>
                <CandidateName onClick={() => this.handleClick(interviewData)}>
                    {interviewData.candidateId && interviewData.candidateId.name}
                </CandidateName>
                <Role>{interviewData.jobId.jobTitle}</Role>
                <Type>{interviewData.interviewType}</Type>
                <Time>{moment(interviewData.interviewStartTime).format(timeFormat)}</Time>
                <Date>Today</Date>
            </InterviewItem>
        )
    }

    handleClick = data => {
        window.open(`/candidate/${data.candidateId._id}`, '_blank')
    }

    render() {
        const { data, hasJobs } = this.state
        return (
            <SIContainer>
                {!data.length && hasJobs ? (
                    <NoInterview className="no-interview">
                        <NoInterviewTitle>
                            No Interviews scheduled for today. Why wait! You can start screening candidates.
                        </NoInterviewTitle>
                        <Button
                            type="primary"
                            style={styles.noJobButton}
                            onClick={() => this.props.history.push(`/openings`)}
                        >
                            GO TO JOB OPENING
                        </Button>
                    </NoInterview>
                ) : null}

                {!data.length && !hasJobs ? (
                    <NoInterview>
                        <NoInterviewTitle>
                            No Interviews scheduled for today. Go ahead and create job opening so that you can screen
                            and schedule interview with the candidates who are applying for the position.
                        </NoInterviewTitle>
                        <Button
                            type="primary"
                            style={styles.noJobButton}
                            onClick={() => this.props.history.push(`/openings/createJob`)}
                        >
                            CREATE JOB OPENING
                        </Button>
                    </NoInterview>
                ) : null}

                {data.length ? (
                    <SIListContainer>
                        {data.slice(0, 3).map((interviewData, index) => {
                            return this.interviewList(index, interviewData)
                        })}
                    </SIListContainer>
                ) : null}
            </SIContainer>
        )
    }
}
