export { VALIDATION_STATUS } from './status'

export const COMPANY_LOGO = {
    IMAGE_TYPE: 'You can only upload JPG/PNG/JPEG files!',
    IMAGE_SIZE: 'Image must be smaller than 8 MB',
}

export const BANNER_LIMITS = {
    TITLE: 48,
    SUBTITLE: 100,
    TITLE_ERROR: `Banner Title should not exceed 48 characters`,
    SUBTITLE_ERROR: `Banner Subtitle should not exceed 100 characters`,
    IMAGE_ERROR: 'Please wait while the image is uploading',
    IMAGE_TYPE: 'You can only upload JPG/PNG files!',
    IMAGE_SIZE: 'Image must be smaller than 8 MB',
}

export const WORK_WITH_US_LIMITS = {
    POINTS: 250,
    TITLE: 48,
    POINTS_ERROR: `The point should not exceed 250 characters`,
    TITLE_ERROR: `The Title should not exceed 48 characters`,
    REQUIRED_ERROR: 'Atleast one point is required',
}

export const CULTURE_LIMITS = {
    TITLE: 48,
    DESCRIPTION: 300,
    IMAGE_UPLOADS: 6,
    TITLE_ERROR: `Culture Title should not exceed 48 characters`,
    DESCRIPTION_ERROR: `Culture Description should not exceed 300 characters`,
    IMAGE_TYPE: 'You can only upload JPG/PNG files!',
    IMAGE_SIZE: 'Image must be smaller than 8 MB',
    IMAGE_ERROR: 'Please wait while all the image is uploading is complete',
    DATA_COMPLETE_ERROR: 'Image or Description in required for custom Title',
    NO_TITLE_ERROR: 'Title is required for Image or Description',
}

export const BENEFITS_LIMITS = {
    TITLE: 48,
    DESCRIPTION: 300,
    TITLE_ERROR: `Benefit Title should not exceed 48 characters`,
    DESCRIPTION_ERROR: `Benefit Description should not exceed 300 characters`,
    REQUIRED_ERROR: 'Atleast one Benefit is required with the title',
}

export const SECTION_LIMITS = {
    TITLE: 48,
    DESCRIPTION: 500,
    TITLE_ERROR: `Section Title should not exceed 48 characters`,
    DESCRIPTION_ERROR: `Section Description should not exceed 500 characters`,
    EMPTY_ERROR: 'Save action cannot be performed with empty data',
}

export const REQUIRED_ERRORS = {
    REQUIRED_TITLE_ERROR: 'Title is required with the Description',
    REQUIRED_DESC_ERROR: 'Description is required with the Title',
    REQUIRED_ONLY_TITLE_ERROR: 'Title is required for this section',
}

export const DYNAMIC_SECTIONS = {
    section: 1,
    culture: 1,
    benefits: 1,
    why_work_with_us: 1,
}

export const MANUAL_SECTIONS = {
    company: 1,
    banner: 1,
}

export const USER_NAME_RESOLVER = {
    work: 'Why Work With Us',
    section: 'New Section',
    culture: 'Culture',
    benefit: 'Benefits',
    banner: 'Banner',
}
