import React, { useState } from 'react'
import { Footer, Header } from '../common'
import { ContentWrapper } from '../style'
import { Body, Field, Label, Tag } from '../../Pages/ScheduleInterview/EmailPreview/style'
import { StyledInput } from './../../Components'
import { Select } from 'antd'
import { Editor } from '@tinymce/tinymce-react'
import { useSelector } from 'react-redux'
import config from '../../../config'
const { tinyMCEApiKey } = config.config()
const { InputBorderless } = StyledInput

const SendEmail = ({
    data: {
        candidate = { name: 'Candidate' },
        job: { jobTitle, title },
        interviewStage: { stageName },
        prefillSubject = null,
    },
    onConfirm,
    onCancel,
}) => {
    const {
        Auth: {
            user: { companyName, companyUniqueEmailId },
        },
        Mixed: { teamMembers },
    } = useSelector(({ Auth, Mixed }) => ({ Auth, Mixed }))

    const [cc, setCc] = useState([])
    const [showCCOptions, setShowCCOptions] = useState(false)
    const [disableConfirm, setDisableConfirm] = useState(false)
    const [content, setContent] = useState('')
    const [subject, setSubject] = useState(prefillSubject || `${jobTitle || title} - ${stageName}`)

    const handleBlur = action => setShowCCOptions(false)
    const handleChange = values => {
        setShowCCOptions(false)
        setCc(values)
    }

    const sendEmail = () => {
        if (!disableConfirm) {
            setDisableConfirm(true)
            onConfirm({ cc, content, subject })
        }
    }

    return (
        <>
            <Header title="SEND MESSAGE" />
            <ContentWrapper mTop={false}>
                <Body>
                    <Field>
                        <Label>From</Label>
                        <Tag>
                            <span className="email">
                                <span className="name">{`${companyName} | SpringRecruit`}</span>({companyUniqueEmailId})
                            </span>
                        </Tag>
                    </Field>
                    <Field>
                        <Label>To</Label>
                        <Tag>
                            <span className="email">
                                <span className="name">{candidate.name || 'Candidate'}</span>({candidate.email})
                            </span>
                        </Tag>
                    </Field>
                    <Field>
                        <Label>CC</Label>
                        <Select
                            mode="multiple"
                            dropdownClassName="ghost-select-dropdown"
                            optionLabelProp="label"
                            getPopupContainer={trigger => trigger.parentNode}
                            className="ghost-select"
                            style={{ width: '100%' }}
                            placeholder="Optional"
                            filterOption={(input, { props }) => {
                                return (
                                    props.label.toLowerCase().includes(input.toLowerCase()) ||
                                    props.email.toLowerCase().includes(input.toLowerCase())
                                )
                            }}
                            value={cc}
                            open={showCCOptions}
                            onInputKeyDown={() => setShowCCOptions(true)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            {teamMembers.map(member => {
                                return (
                                    <Select.Option
                                        key={member._id}
                                        label={member.fullName || member.email}
                                        email={member.email}
                                        value={member._id}
                                    >
                                        <div className="option">
                                            <span>{member.fullName}</span>
                                            <span className="email">{member.email}</span>
                                        </div>
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Field>
                    <Field>
                        <Label>Sub</Label>
                        <InputBorderless onChange={({ target }) => setSubject(target.value)} value={subject} />
                    </Field>

                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        // apiKey={tinyMCEApiKey}
                        value={content}
                        onEditorChange={content => setContent(content)}
                        init={{
                            force_br_newlines: true,
                            forced_root_block: '',
                            content_style: 'div { font-size: 13px; color: #333 }',
                            menubar: false,
                            min_height: 230,
                            plugins: 'link image code autoresize lists',
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                        }}
                    />
                </Body>
            </ContentWrapper>
            <Footer
                onCancel={onCancel}
                loadingButton={true}
                disable={disableConfirm}
                onConfirm={sendEmail}
                cancelText="CANCEL"
                confirmText="SEND"
            />
        </>
    )
}

export default SendEmail
