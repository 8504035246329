import React, { useState, useEffect } from 'react'
import { PAGE_SECTIONS } from '../../../Helpers/Stylesheet/styleTypes'
import { PageContainer } from '../../../Components/StyledPages/PageContainer'
import LocationAutoComplete from '../../../Components/locationAutoComplete'
import { currencies } from '../../../Helpers/currencies'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { Field } from '../../../Modals/AddCandidate/style'
import { Form } from '../../WebForm/style'
import { StyledButton } from '../../../Components'
import { CANDIDATE } from '../../../Helpers/RegEx'
import { Icon, Select, Input, Radio, InputNumber } from 'antd'
const { Option } = Select
const { Group: RadioGroup } = Radio

const errorsEvaluator = (formData, setErrors, updateJobDetails) => {
    let errors = {}
    const {
        jobTitle,
        companyName,
        jobLocation,
        jobType,
        minExperience,
        maxExperience,
        minCompensation,
        maxCompensation,
    } = formData

    const specialCharErrMessage = 'No special character allowed'

    if (!jobTitle || !jobTitle.length) errors.jobTitle = 'Job title is required'
    else if (jobTitle.length < 2) errors.jobTitle = 'Please enter valid Job Title'
    else if (CANDIDATE.NOSPECIALCHAR.test(jobTitle)) errors.jobTitle = specialCharErrMessage

    if (!companyName || !companyName.length) errors.companyName = 'Company name is required'
    if (CANDIDATE.NOSPECIALCHAR.test(companyName)) errors.companyName = specialCharErrMessage
    if (!jobLocation || !jobLocation.length) errors.jobLocation = 'Job location is required'
    if (!jobType) errors.jobType = 'Job type is required'
    if (minExperience !== 0 && !minExperience) errors.minExperience = 'Minimum experience is required'
    if (maxExperience !== 0 && !maxExperience) errors.maxExperience = 'Maximum experience is required'
    if (minCompensation !== 0 && !minCompensation) errors.minCompensation = 'Minimum compensation is required'
    if (maxCompensation !== 0 && !maxCompensation) errors.maxCompensation = 'Maximum compensation is required'

    if (Object.keys(errors).length) setErrors(errors)
    else updateJobDetails(formData, 'next')
}

const JobDetails = ({ jobDetails, updateJobDetails }) => {
    const [formData, setFormData] = useState(jobDetails)
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        setFormData(jobDetails)
    }, [jobDetails])

    return (
        <>
            <PageContainer title="JOB DETAILS" type={PAGE_SECTIONS.MAIN}>
                <Form>
                    <Field>
                        <div className="title">Job Opening Name*</div>
                        <Input
                            size="large"
                            style={{ marginBottom: '0px' }}
                            value={formData.jobTitle}
                            placeholder="Enter opening name"
                            onChange={({ target: { value } }) => {
                                setFormData({ ...formData, jobTitle: value })
                                setErrors({ ...errors, jobTitle: null })
                            }}
                        />
                        {errors && errors.jobTitle ? <div className="error">{errors.jobTitle}</div> : null}
                    </Field>
                    <Field>
                        <div className="title">Company Name*</div>
                        <Input
                            size="large"
                            style={{ marginBottom: '0px' }}
                            value={formData.companyName}
                            placeholder="Enter company name"
                            onChange={({ target: { value } }) => {
                                setFormData({ ...formData, companyName: value })
                                setErrors({ ...errors, companyName: null })
                            }}
                        />
                        {errors && errors.companyName ? <div className="error">{errors.companyName}</div> : null}
                    </Field>
                    <Field>
                        <div className="title">Job Location*</div>
                        <LocationAutoComplete
                            size="large"
                            placeholder="Enter job location"
                            onLocationSelect={value => {
                                setFormData({ ...formData, jobLocation: value })
                                setErrors({ ...errors, jobLocation: null })
                            }}
                            initialValue={formData.jobLocation}
                        />
                        {errors && errors.jobLocation ? <div className="error">{errors.jobLocation}</div> : null}
                    </Field>
                    <Field>
                        <div className="title">Job Type*</div>
                        <Select
                            size="large"
                            value={formData.jobType}
                            suffixIcon={<Icon component={DownIcon} />}
                            onChange={value => {
                                setFormData({ ...formData, jobType: value })
                                setErrors({ ...errors, jobType: null })
                            }}
                        >
                            <Option value={1}>Full Time</Option>
                            <Option value={2}>Part Time</Option>
                        </Select>
                        {errors && errors.jobType ? <div className="error">{errors.jobType}</div> : null}
                    </Field>
                    <Field>
                        <div className="title">Required Experience (in years)*</div>
                        <div className="grid-2">
                            <InputNumber
                                size="large"
                                placeholder="Minimum"
                                min={0}
                                value={formData.minExperience}
                                onChange={value => {
                                    setFormData({ ...formData, minExperience: ~~value })
                                    setErrors({ ...errors, minExperience: null })
                                }}
                            />
                            <InputNumber
                                size="large"
                                placeholder="Maximum"
                                min={formData.minExperience}
                                value={formData.maxExperience}
                                onChange={value => {
                                    setFormData({ ...formData, maxExperience: ~~value })
                                    setErrors({ ...errors, maxExperience: null })
                                }}
                            />
                        </div>
                        <div className="grid-2">
                            {errors && errors.minExperience ? (
                                <div className="error">{errors.minExperience}</div>
                            ) : (
                                <div></div>
                            )}
                            {errors && errors.maxExperience ? (
                                <div className="error">{errors.maxExperience}</div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </Field>
                    <Field>
                        <div className="title">Compensation*</div>
                        <div className="grid-3">
                            <Select
                                size="large"
                                dropdownMatchSelectWidth={false}
                                optionLabelProp="value"
                                getPopupContainer={trigger => trigger.parentNode}
                                value={formData.currency}
                                onChange={value => setFormData({ ...formData, currency: value })}
                            >
                                {currencies.map(currency => (
                                    <Option value={currency.symbol}>{`${currency.name} (${currency.symbol})`}</Option>
                                ))}
                            </Select>
                            <InputNumber
                                size="large"
                                placeholder="Minimum"
                                min={0}
                                value={formData.minCompensation && formData.minCompensation / 1000}
                                onChange={value => {
                                    setFormData({ ...formData, minCompensation: value * 1000 })
                                    setErrors({ ...errors, minCompensation: null })
                                }}
                                formatter={value =>
                                    value.toString().includes(',') ? `${value.split(',')[0]},000` : `${value},000`
                                }
                                parser={value =>
                                    value.toString().includes(',')
                                        ? value.replace(',000', '')
                                        : value < 10
                                            ? value
                                            : (parseInt(value) / 1000).toString()
                                }
                            />
                            <InputNumber
                                size="large"
                                placeholder="Maximum"
                                min={formData.minCompensation / 1000}
                                value={formData.maxCompensation && formData.maxCompensation / 1000}
                                onChange={value => {
                                    setFormData({ ...formData, maxCompensation: value * 1000 })
                                    setErrors({ ...errors, maxCompensation: null })
                                }}
                                formatter={value =>
                                    value.toString().includes(',') ? `${value.split(',')[0]},000` : `${value},000`
                                }
                                parser={value =>
                                    value.toString().includes(',')
                                        ? value.replace(',000', '')
                                        : value < 10
                                            ? value
                                            : (parseInt(value) / 1000).toString()
                                }
                            />
                        </div>
                        <div className="errors-grid-2">
                            {errors && errors.minCompensation ? (
                                <div className="error">{errors.minCompensation}</div>
                            ) : (
                                <div></div>
                            )}
                            {errors && errors.maxCompensation ? (
                                <div className="error">{errors.maxCompensation}</div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                        <div className="show">
                            <span>Show this on job details page?</span>
                            <RadioGroup
                                value={formData.compensationStatus}
                                onChange={({ target: { value } }) =>
                                    setFormData({ ...formData, compensationStatus: value })
                                }
                            >
                                <Radio value={1}>Yes</Radio>
                                <Radio value={0}>No</Radio>
                            </RadioGroup>
                        </div>
                    </Field>
                    <Field>
                        <div className="title">Equity Range (in percentage)</div>
                        <div className="grid-2">
                            <InputNumber
                                size="large"
                                placeholder="Minimum %"
                                min={0}
                                value={formData.minEquity}
                                onChange={value => setFormData({ ...formData, minEquity: value })}
                                formatter={value => (value >= 0 || value === '.' ? `${value} %` : '0 %')}
                                parser={value => value.replace(' %', '')}
                            />
                            <InputNumber
                                size="large"
                                placeholder="Maximum %"
                                min={formData.minEquity}
                                value={formData.maxEquity}
                                onChange={value => setFormData({ ...formData, maxEquity: value })}
                                formatter={value => (value >= 0 || value === '.' ? `${value} %` : '0 %')}
                                parser={value => value.replace(' %', '')}
                            />
                        </div>
                        <div className="show">
                            <span>Show this on job details page?</span>
                            <RadioGroup
                                value={formData.equityStatus}
                                onChange={({ target: { value } }) => setFormData({ ...formData, equityStatus: value })}
                            >
                                <Radio value={1}>Yes</Radio>
                                <Radio value={0}>No</Radio>
                            </RadioGroup>
                        </div>
                    </Field>
                    <Field></Field>
                    <Field className="full">
                        <div className="title">In house recruitment or recruiting as a recruitment agency?</div>
                        <RadioGroup
                            value={formData.agencyStatus}
                            onChange={({ target: { value } }) => setFormData({ ...formData, agencyStatus: value })}
                        >
                            <p>
                                <Radio value={0}>I am recruiting for my own company</Radio>
                            </p>
                            <p>
                                <Radio value={1}>I am recruiting as a staffing/recruitment agency</Radio>
                            </p>
                        </RadioGroup>
                    </Field>
                </Form>
            </PageContainer>
            <div className="button">
                <StyledButton
                    width="80px"
                    height="40px"
                    onClick={() => errorsEvaluator(formData, setErrors, updateJobDetails)}
                >
                    Next
                </StyledButton>
            </div>
        </>
    )
}
export default JobDetails
