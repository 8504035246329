import React, { useState } from 'react'
import DataForm from './DataForm'
import JobStageSelect from './JobStageSelect'
import { FormWrapper } from '../style'
import actions from '../../../../redux/candidate/actions'
import { connect } from 'react-redux'
import { GetResumeDetails } from '../../../../services/NetworkCalls/Candidate'

const InlineMode = props => {
    const uploadId = props.list[0]
    const changeHandler = data => {
        props.ModifyCacheData({ uploadId, ...data })
    }
    return (
        <FormWrapper>
            <DataForm changeHandler={changeHandler} uploadId={uploadId} jobDetails={props.jobDetails} />
            <JobStageSelect changeHandler={changeHandler} uploadId={uploadId} jobDetails={props.jobDetails} />
        </FormWrapper>
    )
}

const ModifyCacheData = payload => dispatch => {
    dispatch({ type: actions.UPDATE_CANDIDATE, payload })
}

export default connect(
    ({ Candidate }) => Candidate,
    { ModifyCacheData, GetResumeDetails }
)(InlineMode)
