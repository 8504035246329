import { ReactComponent as CreateJobIcon } from '../../../assets/Icons/Dashboard/CreateJob.svg'
import { ReactComponent as AddCandidateIcon } from '../../../assets/Icons/Dashboard/AddCandidate.svg'
import { ReactComponent as InviteTeamIcon } from '../../../assets/Icons/Dashboard/InviteTeam.svg'

export default [
    {
        headerLogo: CreateJobIcon,
        title: 'Create Job Opening',
        details: ['Create opening and publish it', 'Post on various job boards', 'Share on social networks'],
        buttonText: 'CREATE JOB OPENING',
    },
    {
        headerLogo: AddCandidateIcon,
        title: 'Add Candidates',
        details: ['Upload candidate resumes', 'Move them to job openings', 'Search & manage candidates'],
        buttonText: 'ADD CANDIDATE',
    },
    {
        headerLogo: InviteTeamIcon,
        title: 'Invite Team Members',
        details: ['Invite co-recruiters', 'Invite interviewers', 'Collaborate to recruit'],
        buttonText: 'INVITE TEAM MEMBERS',
    },
]
