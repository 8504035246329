import React from 'react'
import { MessageModal } from '../common'

export const ArchivedOpeningNote = props => (
    <MessageModal
        title="NOTE"
        message="This candidate is associated to an archived job opening"
        onConfirm={props.onConfirm}
        confirmText="OKAY"
    />
)
