const URL = {
    PROD: 'https://api.springrecruit.com',
    DEV: 'https://api-dev.springrecruit.com',
    STAGE: 'https://api-stage.springrecruit.com',
    LOCAL: 'http://localhost:8080',
}

const CAREER_PAGE_URL = {
    PROD: domain => `https://${domain}.springrecruit.com/careers`,
    DEV: domain => `https://dev-widget.springrecruit.com/${domain}`,
}

const API_VERSION = {
    v1: '/api/v1/',
    v2: '/api/v2/',
}

const PRODINDEX = 'prod_SRecruit'
const DEVINDEX = 'test_ATSBoard1'
const TINYMCEAPIKEY = process.env.REACT_APP_TINYMCE_API_KEY
const GAKeyProd = 'UA-50434381-13'
const GAKeyDev = 'UA-147397046-1'

export default {
    config: () => {
        switch (process.env.REACT_APP_PATH) {
            case 'production':
                return {
                    baseURL: URL.PROD,
                    apiVersion: API_VERSION,
                    indexName: PRODINDEX,
                    careerPageURL: CAREER_PAGE_URL.PROD,
                    tinyMCEApiKey: TINYMCEAPIKEY,
                    googleAnalyticsTrackingId: GAKeyProd,
                    gMapsGeocodingAPIKey: 'AIzaSyAvkUhK8OPO2O1XFFrJyk9y3VvownX8dRk',
                    sampleSpreadsheetLink:
                        'https://springrecruit-assets.s3.amazonaws.com/Candidate_Profile_ImportSample_File.xlsx',
                    userInputCacheTTL: 24 * 60 * 60 * 1000,
                    zoomConnectLink:
                        'https://zoom.us/oauth/authorize?response_type=code&client_id=3CLMjafDRaSADCv5_Wo3Q&redirect_uri=https://springrecruit.com/account&state=',
                    outlookConnectLink:
                        'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=b97013e2-cbc6-44c2-848c-a6b19bbed563&response_type=code&redirect_uri=https://springrecruit.com/account&response_mode=query&scope=offline_access%20user.read%20calendars.readwrite&state=',
                    typesense: {
                        search_api_key: 'SB0uznPHtlLLt8Gvd9WIaRCJ4mQ81gdS',
                        host: 'nozyij4rl2mptq6xp-1.a1.typesense.net',
                        port: '443',
                        collection: 'sprc-prod',
                    },
                }
            case 'development':
                return {
                    baseURL: URL.DEV,
                    apiVersion: API_VERSION,
                    indexName: DEVINDEX,
                    careerPageURL: CAREER_PAGE_URL.DEV,
                    tinyMCEApiKey: TINYMCEAPIKEY,
                    googleAnalyticsTrackingId: GAKeyDev,
                    gMapsGeocodingAPIKey: 'AIzaSyAvkUhK8OPO2O1XFFrJyk9y3VvownX8dRk',
                    sampleSpreadsheetLink:
                        'https://springrecruit-assets.s3.amazonaws.com/Candidate_Profile_ImportSample_File.xlsx',
                    userInputCacheTTL: 60 * 60 * 1000,
                    zoomConnectLink:
                        'https://zoom.us/oauth/authorize?response_type=code&client_id=4hqUmrSQbK9RCTtMja75g&redirect_uri=https://dev.springrecruit.com/account&state=',
                    outlookConnectLink:
                        'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=b97013e2-cbc6-44c2-848c-a6b19bbed563&response_type=code&redirect_uri=https://dev.springrecruit.com/account&response_mode=query&scope=offline_access%20user.read%20calendars.readwrite&state=',
                    typesense: {
                        search_api_key: '8LdQno3xes6bm3mhZhOPsMxbQ7hybVDP',
                        host: '1vcimlu0x6deqo2pp-1.a1.typesense.net',
                        port: '443',
                        collection: 'sprc-dev1',
                    },
                }
            case 'stage':
                return {
                    baseURL: URL.STAGE,
                    apiVersion: API_VERSION,
                    indexName: DEVINDEX,
                    careerPageURL: CAREER_PAGE_URL.DEV,
                    tinyMCEApiKey: TINYMCEAPIKEY,
                    googleAnalyticsTrackingId: GAKeyDev,
                    gMapsGeocodingAPIKey: 'AIzaSyAvkUhK8OPO2O1XFFrJyk9y3VvownX8dRk',
                    sampleSpreadsheetLink:
                        'https://springrecruit-assets.s3.amazonaws.com/Candidate_Profile_ImportSample_File.xlsx',
                    userInputCacheTTL: 60 * 60 * 1000,
                    zoomConnectLink:
                        'https://zoom.us/oauth/authorize?response_type=code&client_id=4hqUmrSQbK9RCTtMja75g&redirect_uri=https://stage.springrecruit.com/account&state=',
                }
            case 'local':
                return {
                    baseURL: URL.LOCAL,
                    apiVersion: API_VERSION,
                    indexName: DEVINDEX,
                    careerPageURL: CAREER_PAGE_URL.DEV,
                    tinyMCEApiKey: TINYMCEAPIKEY,
                    googleAnalyticsTrackingId: GAKeyDev,
                    gMapsGeocodingAPIKey: 'AIzaSyAvkUhK8OPO2O1XFFrJyk9y3VvownX8dRk',
                    sampleSpreadsheetLink:
                        'https://springrecruit-assets.s3.amazonaws.com/Candidate_Profile_ImportSample_File.xlsx',
                    userInputCacheTTL: 30 * 60 * 1000,
                    zoomConnectLink:
                        'https://zoom.us/oauth/authorize?response_type=code&client_id=4hqUmrSQbK9RCTtMja75g&redirect_uri=http://localhost:3000/account&state=',
                    outlookConnectLink:
                        'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=b97013e2-cbc6-44c2-848c-a6b19bbed563&response_type=code&redirect_uri=http://localhost:3000/account&response_mode=query&scope=offline_access%20user.read%20calendars.readwrite&state=',
                    typesense: {
                        search_api_key: '8LdQno3xes6bm3mhZhOPsMxbQ7hybVDP',
                        host: '1vcimlu0x6deqo2pp-1.a1.typesense.net',
                        port: '443',
                        collection: 'sprc-dev1',
                    },
                }
            default:
                return {
                    baseURL: URL.DEV,
                    apiVersion: API_VERSION,
                    indexName: DEVINDEX,
                    careerPageURL: CAREER_PAGE_URL.DEV,
                    tinyMCEApiKey: TINYMCEAPIKEY,
                    googleAnalyticsTrackingId: GAKeyDev,
                    gMapsGeocodingAPIKey: 'AIzaSyAvkUhK8OPO2O1XFFrJyk9y3VvownX8dRk',
                    sampleSpreadsheetLink:
                        'https://springrecruit-assets.s3.amazonaws.com/Candidate_Profile_ImportSample_File.xlsx',
                    userInputCacheTTL: 60,
                }
        }
    },
    isMobileDevice: window.innerWidth <= 769,
    isSmallDesktop: window.innerHeight <= 720,
}
