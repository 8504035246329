import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import { HeaderContainer, MainContainer, SidebarWrapper, RefreshFooter } from './style'
import { Button, Icon, Checkbox, Menu, Popover } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import { connect } from 'react-redux'
import isEqual from 'lodash.isequal'
import './style.css'
import AddIcon from '../../../assets/Icons/Talent Pool/Add'
import { ReactComponent as ImportIcon } from '../../../assets/Icons/Talent Pool/import.svg'
import { ReactComponent as UploadIcon } from '../../../assets/Icons/Talent Pool/Upload.svg'
import DropdownIcon from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Action'
import {
    InstantSearch,
    connectSearchBox,
    Configure,
    connectMenu,
    connectRefinementList,
    connectToggleRefinement,
    connectStats,
    connectInfiniteHits,
    connectStateResults,
    Panel,
} from 'react-instantsearch-dom'
import config from '../../../config'
import { history, store } from '../../../redux/store'
import { success, warning } from '../../../services/Notification'
import { EVENTS, MODAL_TYPE } from './constants'
import { arrayToObject, objectKeyExtractAsArray, isObjectEmpty, randomString } from './../../Helpers/utils'
import { LogE } from './../../Helpers/errorHandler'
import ActionIcon from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Action'
import {
    UpdateFavouriteStatus,
    ArchiveApplications,
    UpdateMultiFavouriteStatus,
    UpdateCandidateStatus,
    UpdateMultipleCandidateJob,
    DownloadCandidateReport,
    DeleteCandidate,
} from '../../../services/api'
import { RejectMultipleCandidatesWithJob, UpdateResume } from '../../../services/NetworkCalls/Candidate'
import { UpdateTags } from '../../../services/NetworkCalls/SourcingTalentPool'
import {
    SearchBox,
    SourceViaBox,
    SourceByBox,
    ToggleBox,
    JobBox,
    StageBox,
    TotalCandidateStats,
    SearchResultBox,
    CandidateCountBox,
    CandidateStage,
    ArchiveReasonBox,
    CandidateTagBox,
} from './algoliaComponents'
import { HotKeys } from 'react-keyboard'
import { keyMap, access } from '../../Helpers/KeyMap'
import HiddenInput from '../../Helpers/HiddenInput'
import InfiniteHits from './InfiniteHits'
import _ from 'lodash'
import { BannerGoogleCal } from '../../Banners'
import { FORCE_OPEN } from '../../Helpers/calendarPopUp'
import { Header, Title, AddButton, Form } from './style'
import { FSModal, Modal } from './../../Modals'
import { FSMODAL_TYPES, MODAL_TYPES } from '../../Helpers/ModalTypes'
import { ACCESS_LEVEL_DEFINITIONS, FILE_STATUS } from '../../Helpers/constants'
import actionsCandidate from '../../../redux/candidate/actions'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'

const searchClient = algoliasearch('KC3488J1QJ', '08bf615eb3bfb1a9300be44426ab6766')
const { typesense } = config.config()
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
        apiKey: typesense.search_api_key, // Be sure to use an API key that only allows search operations
        nodes: [
            {
                host: typesense.host,
                port: typesense.port,
                protocol: 'https',
            },
        ],
        cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  query_by is required.
    additionalSearchParameters: {
        query_by: 'name,email,phone,location,tags',
    },
})
const CustomSearchBox = connectSearchBox(SearchBox)
const CustomSourceViaBox = connectMenu(SourceViaBox)
const CustomSourceByBox = connectRefinementList(SourceByBox)
const CustomToggleBox = connectToggleRefinement(ToggleBox)
const CustomJobBox = connectRefinementList(JobBox)
const CustomArchiveBox = connectRefinementList(ArchiveReasonBox)
const CustomStageBox = connectRefinementList(StageBox)
const CustomStatusBox = connectRefinementList(CandidateStage)
const CustomTagsBox = connectRefinementList(CandidateTagBox)
const TotalCandidateStatsBox = connectStats(TotalCandidateStats)
const InfiniteHitsBox = connectInfiniteHits(InfiniteHits)
const talentPoolAccess = access.talentPool

class TalentPool extends React.Component {
    constructor(props) {
        super(props)
        this.allowShortcuts = props.allowShortcuts
        document.title = 'Talent Pool | SpringRecruit'

        let defaultUnAssignedSelected = false
        let defaultIncompleteProfileSelected = false
        if (props.location.state) {
            defaultUnAssignedSelected = !!props.location.state.defaultUnAssignedSelected
            defaultIncompleteProfileSelected = !!props.location.state.defaultIncompleteProfileSelected
        }

        const urlSearchResult = new URLSearchParams(this.props.location.search)
        let jobIdPreRefine = urlSearchResult.get('jobId')
        let preFilter = jobIdPreRefine ? [jobIdPreRefine] : []

        this.state = {
            refresh: false,
            defaultUnAssignedSelected,
            defaultIncompleteProfileSelected,
            showCandidateFilter: true,
            showOpeningsFilter: true,
            showStageFilter: true,
            showTagsFilter: true,
            showArchiveReasonFilter: true,
            showAddCandidateModal: false,
            showDownloadReportModal: false,
            showSelectJobModal: false,
            moveCandidateModal: false,
            modalType: null,
            selected: {},
            jobDetails: {},
            allSelectList: false,
            warningModal: false,
            selectionSize: 0,
            lastUpdatedAt: moment().format('hh:mm:ss Do MMM, Y'),
            actionVisible: false,
            defaultJobRefine: preFilter,
            inputFocused: false,
            hitsData: [],
            calPopUpStatus: false,
            bulkMode: false,
            clickedOnce: false,
            moveOpeningModal: false,
            changeJobModal: false,
            changeStageModal: false,
            rejectCandidateModal: false,
            archiveModal: false,
            addTagModal: false,
            manageTagModal: false,
            showCandidateSheet: urlSearchResult.get('mode') === 'importsheet',
        }
    }

    componentDidMount() {
        this.props.allowSwitchTabs(true)
    }

    componentDidUpdate(prevProps) {
        const newPropState = this.props.location.state
        const oldPropState = prevProps.location.state

        if (!isEqual(newPropState, oldPropState)) {
            if (newPropState) {
                this.setState({
                    defaultUnAssignedSelected: !!newPropState.defaultUnAssignedSelected,
                    defaultIncompleteProfileSelected: !!newPropState.defaultIncompleteProfileSelected,
                })
            } else {
                this.setState({
                    defaultIncompleteProfileSelected: false,
                    defaultUnAssignedSelected: false,
                })
            }
        }
    }

    showSelectJobModal = () => {
        this.setState({ showSelectJobModal: true })
    }

    showAddCandidateModal = jobDetails => {
        store.dispatch({ type: actionsCandidate.CLEAR_CANDIDATES })
        store.dispatch({ type: actionsCandidate.ADD_CANDIDATES })
        this.setState({ showAddCandidateModal: true, showSelectJobModal: false, jobDetails })
    }

    showDownloadReportModal = () => {
        this.setState({ showDownloadReportModal: true })
    }

    showDownloadReportModal = () => {
        this.setState({ showDownloadReportModal: true })
    }

    showTagModal = type => {
        if (type === 'addTag') this.setState({ addTagModal: true })
        else this.setState({ manageTagModal: true })
    }

    refreshIndex = () => {
        this.setState({ refresh: true })
    }

    hideRefresh = () => {
        this.setState({ refresh: false })
    }

    closeModal = (skipRefresh = true) => {
        this.setState({
            moveCandidateModal: false,
            modalType: null,
            selected: {},
            allSelectList: false,
            warningModal: false,
            selectionSize: 0,
        })
        if (skipRefresh) {
            this.refreshIndex()
        }
    }

    moveOpening = () => {
        this.setState({ actionVisible: false })
        if (isObjectEmpty(this.state.selected)) warning('Must Select atleast one candidate to move job opening')
        else
            this.setState({
                moveCandidateModal: true,
                modalType: MODAL_TYPE.MOVE_JOB,
            })
    }

    changeOpening = () => {
        this.setState({ actionVisible: false })
        if (isObjectEmpty(this.state.selected)) warning('Must Select atleast one candidate to change job opening')
        else
            this.setState({
                moveCandidateModal: true,
                modalType: MODAL_TYPE.CHANGE_JOB,
            })
    }

    changeStage = () => {
        this.setState({ actionVisible: false })
        const { selected } = this.state
        const { allJobs } = this.props
        const unassociatedCandidates = [],
            validCandidates = []
        for (let key in selected) {
            if (selected[key].jobId && allJobs.find(x => x._id === selected[key].jobId).status === 1)
                validCandidates.push(key)
            else if (!selected[key].jobId) unassociatedCandidates.push(key)
        }
        if (isObjectEmpty(this.state.selected)) warning('Must Select atleast one candidate to change the stage')
        else if (!validCandidates.length && !unassociatedCandidates.length)
            warning('Selected candidate(s) must be in an active job opening to change the stage')
        else
            this.setState({
                moveCandidateModal: true,
                modalType: MODAL_TYPE.CHANGE_STAGE,
            })
    }

    favourite = () => {
        this.eventManager(EVENTS.ALL_FAVOURITE, null, null)
    }

    archive = () => {
        let isEmpty = isObjectEmpty(this.state.selected)
        if (isEmpty) warning('Must Select atleast one candidate to archive')
        else this.setState({ archiveModal: true })
    }

    reject = () => {
        let isEmpty = isObjectEmpty(this.state.selected)
        if (isEmpty) warning('Must Select atleast one candidate to reject')
        else this.setState({ rejectCandidateModal: true })
    }

    setHits = hitsData => {
        this.setState({ hitsData: hitsData })
    }

    selectAll = () => {
        this.eventManager(EVENTS.ALL_SELECT, null, null)
    }

    filterUnassociatedCandidates = value => {
        const { selected } = this.state
        const filteredIds = []
        let totalCandidates = 0
        for (const candidate in selected) {
            totalCandidates += 1
            if (selected[candidate].unassigned === false) {
                if (value === 'archive') filteredIds.push(selected[candidate].jobApplicationId)
                else filteredIds.push(selected[candidate].candidateId)
            }
        }
        const unassociatedCount = totalCandidates - filteredIds.length
        return { filteredIds, unassociatedCount }
    }

    findCandidateById = id => {
        const { selected } = this.state
        for (const candidate in selected) {
            if (selected[candidate].jobApplicationId === id || selected[candidate].candidateId === id) {
                return selected[candidate]
            }
        }
    }

    eventManager = (eventName, index, data, reason, isBulkMode = true, sendEmailToCandidate) => {
        this.setState({ actionVisible: false, bulkMode: false, rejectCandidateModal: false })
        switch (eventName) {
            case EVENTS.FAVOURITE:
                UpdateFavouriteStatus({ isFavourite: data.isFavourite }, data.jobApplicationId)
                    .then(() => {
                        success(
                            `${data.name || 'Candidate'} is ${data.isFavourite ? 'marked as' : 'removed from'
                            } favourite`
                        )
                        this.refreshIndex()
                        this.setState({ bulkMode: false })
                    })
                    .catch(err => warning('Unable to mark favourite: ' + err.message))
                break

            case EVENTS.ALL_FAVOURITE:
                const marked_fav = objectKeyExtractAsArray(this.state.selected, 'jobApplicationId')
                if (marked_fav && marked_fav.length) {
                    UpdateMultiFavouriteStatus({
                        isFavourite: true,
                        jobApplications: marked_fav,
                    })
                        .then(res => {
                            let { hitsData } = this.state
                            marked_fav.forEach(marked => {
                                let ind = hitsData.findIndex(hit => hit.jobApplicationId === marked)
                                hitsData[ind].isFavourite = true
                            })
                            if (marked_fav.length === 1) {
                                let person = Object.values(this.state.selected)[0]
                                success(`${person.name || 'Candidate'} is marked as favourite`)
                            } else success(`Marked ${marked_fav.length} candidates as favourite`)
                            this.setState({
                                selected: {},
                                allSelectList: false,
                                selectionSize: 0,
                                hitsData,
                                bulkMode: false,
                            })
                            this.refreshIndex()
                        })
                        .catch(err => warning('Unable to mark favourite: ' + err.message))
                } else warning('Must select atleast 1 candidate to favourite.')

                break

            case EVENTS.ARCHIVE:
                this.setState({ clickedOnce: true }, () => {
                    const { filteredIds: jobAppIds, unassociatedCount } = this.filterUnassociatedCandidates('archive')
                    if (jobAppIds && jobAppIds.length) {
                        // if one selected then same action update action should be prevented
                        let archivedIds = Object.keys(this.state.selected).map(
                            key => this.state.selected[key].status === 0
                        )
                        let anyArchived = _.reduce(archivedIds, (result = false, value) => {
                            return result || value
                        })
                        //Stop archive procecss even if one of the selected applications is archived.
                        if (anyArchived) {
                            this.setState(
                                {
                                    selected: {},
                                    allSelectList: false,
                                    warningModal: false,
                                    selectionSize: 0,
                                    archiveModal: false,
                                    clickedOnce: false,
                                },
                                warning(`Some of the selected candidates are already Archived`)
                            )
                            return
                        }
                        if (unassociatedCount > 0)
                            warning(`${unassociatedCount} candidates can't be archived as they are unassociated`)
                        ArchiveApplications(jobAppIds, reason, sendEmailToCandidate)
                            .then(res => {
                                let { hitsData } = this.state
                                jobAppIds.forEach(jobAppId => {
                                    let ind = hitsData.findIndex(hit => hit.jobApplicationId === jobAppId)
                                    hitsData[ind].status = 0
                                })
                                if (jobAppIds.length === 1) {
                                    let person = this.findCandidateById(jobAppIds[0])
                                    success(`${person.name || 'Candidate'} is archived`)
                                } else success(`${jobAppIds.length} candidates are archived`)
                                this.setState({
                                    selected: {},
                                    allSelectList: false,
                                    warningModal: false,
                                    selectionSize: 0,
                                    hitsData,
                                    bulkMode: false,
                                    archiveModal: false,
                                    clickedOnce: false,
                                })
                                this.refreshIndex()
                            })
                            .catch(err => {
                                this.setState({ clickedOnce: false, archiveModal: false })
                                warning('Unable to mark archive: ' + err.message)
                            })
                    } else if (Object.keys(this.state.selected).length) {
                        this.setState({ clickedOnce: false, archiveModal: false })
                        warning('Cannot archive unassociated candidates')
                    } else {
                        this.setState({ archiveModal: false })
                        warning('Must select atleast 1 candidate to archive.')
                    }
                })

                break

            case EVENTS.UNARCHIVE:
                const jobAppId = objectKeyExtractAsArray(this.state.selected, 'jobApplicationId')
                if (jobAppId && jobAppId.length) {
                    // if one selected then same action update action should be prevented
                    let unarchivedId = Object.keys(this.state.selected).map(
                        key => this.state.selected[key].status !== 0
                    )
                    let anyUnarchived = _.reduce(unarchivedId, (result = false, value) => {
                        return result || value
                    })
                    //Stop archive procecss even if one of the selected applications is unarchived.
                    if (anyUnarchived) {
                        this.setState(
                            {
                                selected: {},
                                allSelectList: false,
                                selectionSize: 0,
                            },
                            warning(`Some of the selected candidates are already Unarchived`)
                        )
                        break
                    }
                    const selectedData = this.state.selected[Object.keys(this.state.selected)[0]]
                    const value = {
                        jobId: selectedData.jobId,
                        status: 'active',
                        stageId: selectedData.stageId,
                    }
                    UpdateCandidateStatus(jobAppId, value)
                        .then(res => {
                            let { hitsData } = this.state
                            jobAppId.forEach(jobAppId => {
                                let ind = hitsData.findIndex(hit => hit.jobApplicationId === jobAppId)
                                hitsData[ind].status = 1
                            })
                            if (jobAppId.length === 1) {
                                let person = Object.values(this.state.selected)[0]
                                success(`${person.name || 'Candidate'} is unarchived`)
                            } else success(`${jobAppId.length} candidates are unarchived`)
                            this.setState({
                                selected: {},
                                allSelectList: false,
                                selectionSize: 0,
                                hitsData,
                                bulkMode: false,
                            })
                            this.refreshIndex()
                        })
                        .catch(err => warning('Unable to mark unarchive: ' + err.message))
                } else warning('Must select atleast 1 candidate to unarchive.')

                break

            case EVENTS.REJECT:
                const { filteredIds: candidates, unassociatedCount } = this.filterUnassociatedCandidates('reject')
                if (candidates && candidates.length) {
                    // if one selected then same action update action should be prevented
                    let isRejecteds = Object.keys(this.state.selected).map(key => this.state.selected[key].status === 2)
                    let anyRejected = _.reduce(isRejecteds, (result = false, value) => {
                        return result || value
                    })

                    if (anyRejected) {
                        this.setState(
                            {
                                selected: {},
                                allSelectList: false,
                                warningModal: false,
                                selectionSize: 0,
                                rejectCandidateModal: false,
                            },
                            () => {
                                if (candidates.length === 1)
                                    warning(`${candidates[0].name || 'Candidate'} is already Rejected`)
                                else warning(`Some or all of the candidates selected are already Rejected`)
                            }
                        )
                        break
                    }
                    if (unassociatedCount > 0)
                        warning(`${unassociatedCount} candidates can't be rejected as they are unassociated`)
                    this.props
                        .RejectMultipleCandidatesWithJob({ candidates }, this.props.companyId)
                        .then(res => {
                            let { hitsData } = this.state
                            candidates.forEach(candidate => {
                                let ind = hitsData.findIndex(hit => hit.objectID === candidate)
                                hitsData[ind].isRejected = true
                                hitsData[ind].status = 2
                            })
                            if (candidates.length === 1) {
                                let person = this.findCandidateById(candidates[0])
                                success(`${person.name || 'Candidate'} is rejected`)
                            } else success(`${candidates.length} candidates are rejected`)
                            this.setState({
                                selected: {},
                                allSelectList: false,
                                selectionSize: 0,
                                warningModal: false,
                                hitsData,
                                bulkMode: false,
                                rejectCandidateModal: false,
                            })
                            this.refreshIndex()
                        })
                        .catch(err => {
                            this.setState({ rejectCandidateModal: false })
                            warning('Unable to Reject: ' + err)
                        })
                } else if (Object.keys(this.state.selected).length) {
                    this.setState({ rejectCandidateModal: false })
                    warning('Cannot reject unassociated candidates')
                } else {
                    this.setState({ rejectCandidateModal: false })
                    warning('Must select some applicants to apply action.')
                }

                break

            case EVENTS.UNREJECT:
                const candidate = objectKeyExtractAsArray(this.state.selected, 'candidateId')
                if (candidate && candidate.length) {
                    // if one selected then same action update action should be prevented
                    let isRejecteds = Object.keys(this.state.selected).map(key => this.state.selected[key].status !== 2)
                    let anyRejected = _.reduce(isRejecteds, (result = false, value) => {
                        return result || value
                    })

                    if (anyRejected) {
                        this.setState(
                            {
                                selected: {},
                                allSelectList: false,
                                warningModal: false,
                                selectionSize: 0,
                            },
                            () => {
                                if (candidate.length === 1)
                                    warning(`${candidate[0].name || 'Candidate'} is already active`)
                                else warning(`Some or all of the candidates selected are already active`)
                            }
                        )
                        break
                    }

                    const selectedData = this.state.selected[Object.keys(this.state.selected)[0]]
                    const value = {
                        jobId: selectedData.jobId,
                        status: 'active',
                        stageId: selectedData.stageId,
                    }

                    UpdateCandidateStatus(selectedData.jobApplicationId, value)
                        .then(res => {
                            let { hitsData } = this.state
                            candidate.forEach(candidate => {
                                let ind = hitsData.findIndex(hit => hit.objectID === candidate)
                                hitsData[ind].isRejected = false
                                hitsData[ind].status = 1
                            })
                            if (candidate.length === 1) {
                                let person = this.state.selected[candidate[0]]
                                success(`${person.name || 'Candidate'} status is updated`)
                            } else success(`${candidate.length} candidates status are updated`)
                            this.setState({
                                selected: {},
                                allSelectList: false,
                                selectionSize: 0,
                                warningModal: false,
                                hitsData,
                                bulkMode: false,
                            })
                            this.refreshIndex()
                        })
                        .catch(err => warning('Unable to change status: ' + err.message))
                } else warning('Must select some applicants to apply action.')

                break
            case EVENTS.DELETE_CANDIDATE:
                DeleteCandidate({ candidateId: data, companyId: this.props.companyId })
                    .then(res => {
                        if (res && res.msg && res.msg === 'Deleted successfully') {
                            success('Candidate Deleted successfully!')
                            this.refreshIndex()
                        } else {
                            warning('Something went wrong.')
                        }
                    })
                    .catch(err => {
                        LogE('tp-eventManager', 'EVENTS.DELETE_CANDIDATE', {
                            eventName,
                            index,
                            data,
                        })
                        warning(err.message)
                    })
                break
            case EVENTS.SELECT:
                let { selected, selectionSize } = this.state
                let iD = data.objectID
                if (selected.hasOwnProperty(iD)) {
                    delete selected[iD]
                    selectionSize -= 1
                } else {
                    // if there is singleton selection then we can prevent repetative actions, so was not there
                    if (selectionSize === 0)
                        selected[iD] = {
                            ...data,
                            candidateId: data.objectID,
                        }
                    else
                        selected[iD] = {
                            ...data,
                            jobApplicationId: data.jobApplicationId,
                            candidateId: data.objectID,
                            unassigned: data.unassigned,
                            jobId: data.jobId,
                            stageId: data.stageId,
                            name: data.name,
                        }
                    selectionSize += 1
                }
                if (isBulkMode) this.setState({ selected, allSelectList: false, selectionSize, bulkMode: true })
                else {
                    this.setState({ selected, allSelectList: false, selectionSize })
                }
                break

            case EVENTS.ALL_SELECT:
                this.setState(state => {
                    let { selected, allSelectList, hitsData } = state
                    if (allSelectList)
                        return {
                            selected: {},
                            allSelectList: false,
                            selectionSize: 0,
                            bulkMode: true,
                        }
                    else {
                        selected = arrayToObject(hitsData, 'objectID')
                        return {
                            selected,
                            allSelectList: true,
                            selectionSize: (hitsData && hitsData.length) || 0,
                            bulkMode: true,
                        }
                    }
                })
                break

            case EVENTS.MOVE_JOB_OPENING:
                const selectedObj = {
                    [data.objectID]: {
                        stageId: data.stageId,
                        candidateId: data.objectID,
                        jobId: data.jobId,
                        unassigned: data.unassigned,
                        jobApplicationId: data.jobApplicationId,
                        name: data.name,
                        isRejected: data.isRejected,
                        status: data.status,
                    },
                }

                this.setState({
                    moveCandidateModal: true,
                    modalType: MODAL_TYPE.M_JOB,
                    selected: selectedObj,
                    bulkMode: false,
                })
                break

            case EVENTS.CHANGE_JOB_OPENING:
                let selectObj = this.state.selected
                selectObj[data.objectID] = {
                    ...data,
                    stageId: data.stageId,
                    candidateId: data.objectID,
                    jobId: data.jobId,
                    unassigned: data.unassigned,
                    jobApplicationId: data.jobApplicationId,
                    name: data.name,
                }
                this.setState({
                    selected: selectObj,
                    bulkMode: false,
                })
                break
            case EVENTS.UPDATE_TAGS:
                UpdateTags(data).then(res => {
                    let { hitsData } = this.state
                    const addedTags = res.result && res.result.map(tag => tag.name)
                    const deletedTags = data.deletedTags.map(tag => tag.name)
                    data.jobApplicationIdList.forEach(each => {
                        let index = hitsData.findIndex(hit => hit.jobApplicationId === each)
                        hitsData[index].tags =
                            (hitsData[index].tags && hitsData[index].tags.filter(tag => !deletedTags.includes(tag))) ||
                            []
                        hitsData[index].tags = [
                            ...hitsData[index].tags,
                            ...addedTags.slice(0, 15 - hitsData[index].tags.length),
                        ]
                    })
                    this.setState({
                        selected: {},
                        allSelectList: false,
                        selectionSize: 0,
                        hitsData,
                        bulkMode: false,
                    })
                })
                break
            default:
                LogE('tp-eventManager', 'EventManger Default case', {
                    eventName,
                    index,
                    data,
                })
        }
    }

    updateCandidate = async (value, jobPayload) => {
        // It is guaranteed  that JobId and the stage are valid, as the details are received from the drop-down
        // Finding the jobname and stagename;
        const { jobId, stageId, jobApplications, input } = jobPayload
        try {
            // if single selection (in TP-individual cards and Candidate Profile) use UpdateCandidateStatus
            // if multiple use UpdateMultipleCandidateJob
            let { selected, hitsData } = this.state
            const marked = Object.values(selected)
            if (marked && marked.length) {
                if (marked.length === 1) {
                    await UpdateCandidateStatus(jobApplications[0], input)
                    let person = Object.values(this.state.selected)[0]
                    let ind = hitsData.findIndex(hit => {
                        if (person.objectID) return hit.objectID === person.objectID
                        return hit.objectID === person.candidateId
                    })
                    hitsData[ind].jobId = jobId
                    hitsData[ind].stageId = stageId
                    hitsData[ind].status = input.status
                } else {
                    await UpdateMultipleCandidateJob({
                        jobApplications,
                        jobId,
                        stageId,
                        status: input.status,
                    })
                    marked.forEach(mark => {
                        let ind = hitsData.findIndex(hit => hit.objectID === mark.objectID)
                        hitsData[ind] = { ...hitsData[ind], jobId, stageId, status: input.status }
                    })
                }
                const jobDetail = this.props.allJobs.find(job => job._id === jobPayload.jobId)
                const jobName = jobDetail.jobTitle
                const stageName = jobDetail.stages.find(stage => stage._id === jobPayload.stageId).stageName
                let successText = ` moved to stage ${stageName} in ${jobName.toUpperCase()} opening`
                if (marked.length === 1) successText = Object.values(this.state.selected)[0].name + successText
                else successText = marked.length + ' Applications' + successText
                success(successText)

                this.refreshIndex()
                this.setState({
                    selected: {},
                    moveCandidateModal: false,
                    modalType: null,
                    selectionSize: 0,
                    hitsData,
                    bulkMode: false,
                    allSelectList: false,
                })
            } else warning('Must select some applicants to apply action.')
        } catch (e) {
            LogE('moveMultipleCandidate', '', e)
            warning(e.message)
        }
    }

    handleDownloadReport = async data => {
        this.setState({ showDownloadReportModal: false })
        await DownloadCandidateReport(data)
    }

    handleCancel = refresh => {
        this.setState({
            showAddCandidateModal: false,
            showSelectJobModal: false,
            showDownloadReportModal: false,
            archiveModal: false,
            rejectCandidateModal: false,
            addTagModal: false,
            manageTagModal: false,
        })
        if (refresh) {
            this.refreshIndex()
        }
        this.hiddenInputRef.focus({ preventScroll: true })
    }

    handleBulkCancel = () => {
        this.setState({
            bulkMode: false,
            selectionSize: 0,
            selected: {},
            allSelectList: false,
        })
    }

    //Used to change the visibility state of the action option dynamically
    handleVisibleChange = actionVisible => {
        this.setState({ actionVisible })
    }

    handleUpdateResume = async (fileCheck, objectID, companyId) => {
        try {
            const result = await this.props.UpdateResume(fileCheck, objectID, companyId)
            if (result) {
                let { hitsData } = this.state
                let index = hitsData.findIndex(hit => hit.objectID === objectID)
                hitsData[index].resumeLink = result.data && result.data.candidateResumeLink
                success('Resume added successfully')
                this.setState({ hitsData })
            }
        } catch (err) {
            const errorMsg = (err.response && err.response.data && err.response.data.error.msg) || err
            warning(errorMsg)
        }
    }

    updateDefaultRefinement = prop => value => {
        this.setState({ [prop]: value })
    }

    createOpening = () => {
        if (this.props.accessLevel > ACCESS_LEVEL_DEFINITIONS.ADMIN.accessLevel)
            warning('Unauthorised to perform the action')
        else
            this.props.history.push({
                pathname: 'openings/createJob',
            })
    }

    hideFilter = name => {
        this.setState(state => ({ [name]: !state[name] }))
    }

    renderActionsMenu(jobsExists, accessLevel) {
        const items = [
            { name: 'Move To Opening', condition: jobsExists && accessLevel < 4, action: this.moveOpening },
            { name: 'Change job opening', condition: jobsExists && accessLevel < 4, action: this.changeOpening },
            { name: 'Change Stage', condition: jobsExists && accessLevel < 4, action: this.changeStage },
            { name: 'Mark Favourite', condition: true, action: this.favourite },
            { name: 'Archive', condition: accessLevel < 3, action: this.archive },
            { name: 'Reject', condition: accessLevel < 5, action: this.reject },
            { name: 'Add Tag', condition: true, action: () => this.showTagModal('addTag') },
            { name: 'Manage Tag', condition: true, action: () => this.showTagModal('manageTag') },
        ]
        return (
            <Menu className="tp-card-menu">
                {items.map((item, index) => {
                    if (item.name === 'Reject') {
                        return (
                            item.condition && (
                                <Menu.Item className="tp-card-menu-items color-red" key={index} onClick={item.action}>
                                    {item.name}
                                </Menu.Item>
                            )
                        )
                    } else {
                        return (
                            item.condition && (
                                <Menu.Item className="tp-card-menu-items" key={index} onClick={item.action}>
                                    {item.name}
                                </Menu.Item>
                            )
                        )
                    }
                })}
            </Menu>
        )
    }

    handleFocus = inputFocused => {
        this.setState({ inputFocused })
        this.props.allowSwitchTabs(!inputFocused)
    }

    handlers = {
        createCandidate: () => {
            if (
                this.allowShortcuts &&
                !this.state.inputFocused &&
                this.props.accessLevel <= talentPoolAccess.createCandidate
            )
                this.showAddCandidateModal()
        },
        search: e => {
            if (this.allowShortcuts && !this.state.inputFocused) {
                e.preventDefault()
                this.searchInput.focus({ preventScroll: true })
            }
        },
    }

    render() {
        const {
            refresh,
            defaultIncompleteProfileSelected,
            defaultUnAssignedSelected,
            showCandidateFilter,
            showOpeningsFilter,
            showArchiveReasonFilter,
            showStageFilter,
            showTagsFilter,
            warningModal,
            selected,
            allSelectList,
            selectionSize,
            lastUpdatedAt,
            calPopUpStatus,
        } = this.state

        const { companyId, userId, accessLevel, accessibleJobs } = this.props

        // this component is used only to show the loader and no data component
        const StateResult = connectStateResults(SearchResultBox)
        const CandidateCount = connectStateResults(CandidateCountBox)

        let mainIndexFilter = `companyId:${companyId}`
        let unassignedFilter = `companyId:${companyId} && unassigned:true`
        let profileFilter = `companyId:${companyId} && isProfileCompleted:false`

        if (accessLevel === ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel) {
            mainIndexFilter += ` && ( userId:${userId} || assignedTo:${userId} )`
            unassignedFilter += ` && ( userId:${userId} || assignedTo:${userId} )`
            profileFilter += ` && ( userId:${userId} || assignedTo:${userId} )`
        }

        if (accessLevel === ACCESS_LEVEL_DEFINITIONS.JOB_LEVEL_ACCESS.accessLevel) {
            let accessibleJobsFilter = ` && (userId:${userId} || assignedTo:${userId} || `
            if (accessibleJobs && !!accessibleJobs.length) {
                accessibleJobs.forEach(aj => {
                    accessibleJobsFilter += `jobId:${aj} || `
                })
            }
            accessibleJobsFilter = accessibleJobsFilter.slice(0, -4)
            accessibleJobsFilter += ')'

            mainIndexFilter += accessibleJobsFilter
            unassignedFilter += accessibleJobsFilter
            profileFilter += accessibleJobsFilter
        }

        if (accessLevel === ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel) {
            const accessibleJobsFilter = ` && (userId:${userId} || assignedTo:${userId})`
            mainIndexFilter += accessibleJobsFilter
            unassignedFilter += accessibleJobsFilter
            profileFilter += accessibleJobsFilter
        }
        const areJobsExists = this.props.allJobs.length > 0
        const noData = this.state.hitsData.length <= 0
        const calBanner = this.props.inUse === null || this.props.inUse === undefined
        return (
            <React.Fragment>
                <HotKeys keyMap={keyMap.talentPool} handlers={this.handlers}>
                    <InstantSearch
                        indexName={typesense.collection}
                        searchClient={typesenseInstantsearchAdapter.searchClient}
                        refresh={refresh}
                    >
                        <Configure filters={mainIndexFilter} />
                        <div className="talent-pool-page">
                            {calBanner && (
                                <div className="google-banner">
                                    <BannerGoogleCal
                                        talentpool
                                        data={this.props.__redux.GCal}
                                        action_allowAccess={() =>
                                            this.setState({ calPopUpStatus: FORCE_OPEN.AUTHENTICATE })
                                        }
                                        action_setManually={() =>
                                            this.setState({ calPopUpStatus: FORCE_OPEN.AVAILABILITY })
                                        }
                                    />
                                </div>
                            )}

                            <div className="sticky-main-header">
                                <Header>
                                    <Title>Talent Pool</Title>
                                    <HiddenInput reference={input => (this.hiddenInputRef = input)} />
                                    <Form>
                                        <div className="talentpool-header-search-container">
                                            <div className="talentpool-header-search">
                                                <CustomSearchBox
                                                    reference={input => (this.searchInput = input)}
                                                    handleFocus={this.handleFocus}
                                                />
                                            </div>
                                        </div>
                                        <AddButton ghost size="large" type="default" onClick={this.showSelectJobModal}>
                                            <AddIcon />
                                            ADD CANDIDATE
                                        </AddButton>

                                        <AddButton
                                            ghost
                                            size="large"
                                            type="default"
                                            onClick={() => this.props.history.push('/import/spreadsheet')}
                                        >
                                            <UploadIcon />
                                            IMPORT CANDIDATE
                                        </AddButton>
                                        {accessLevel < 3 && (
                                            <AddButton
                                                ghost
                                                size="large"
                                                type="default"
                                                onClick={this.showDownloadReportModal}
                                            >
                                                <ImportIcon />
                                                EXPORT CANDIDATES
                                            </AddButton>
                                        )}
                                    </Form>
                                </Header>
                            </div>
                            <MainContainer>
                                <div className="relative-class">
                                    <div className={calBanner ? 'sticky-with-banner' : 'sticky-without-banner'}>
                                        <p
                                            className={
                                                calBanner
                                                    ? 'tp-filter-title-with-banner'
                                                    : 'tp-filter-title-without-banner'
                                            }
                                        >
                                            FILTER BY
                                        </p>
                                        <div className="talentpool-filter-block">
                                            <div className="talent-pool-container-inner">
                                                <div className="talent-pool-container">
                                                    <SidebarWrapper>
                                                        {/* <div>
                                                     <div>LOCATION</div>
                                                     <CustomLocationSearchBox />
                                                     </div> */}

                                                        <CustomToggleBox
                                                            attribute="unassigned"
                                                            value={true}
                                                            label="Unassociated Candidates"
                                                            defaultRefinement={defaultUnAssignedSelected}
                                                            updateState={this.updateDefaultRefinement(
                                                                'defaultUnAssignedSelected'
                                                            )}
                                                        />
                                                        <CustomToggleBox
                                                            className="tp-filter-mb-12"
                                                            attribute="userId"
                                                            value={userId}
                                                            label="Added by me"
                                                        />
                                                        <div className="tp-filter-divide" />

                                                        <div className="tp-filter-mb-12 mt-12 tp-filter-title">
                                                            SOURCE
                                                        </div>
                                                        <CustomSourceViaBox attribute="sourceVia" />
                                                        <CustomSourceByBox attribute="sourceBy" />
                                                        <div className="tp-filter-divide" />

                                                        <Panel
                                                            header={
                                                                <div className="tp-filter-mb-12 tp-filter-title">
                                                                    CANDIDATES
                                                                    <TotalCandidateStatsBox />
                                                                    <Icon
                                                                        component={DropdownIcon}
                                                                        onClick={() =>
                                                                            this.hideFilter('showCandidateFilter')
                                                                        }
                                                                        className={classNames({
                                                                            'tp-dropdown-icon': true,
                                                                            'rotate-180': showCandidateFilter,
                                                                            'rotate-360': !showCandidateFilter,
                                                                        })}
                                                                    />
                                                                </div>
                                                            }
                                                            className={classNames({
                                                                'tp-filter-none': !showCandidateFilter,
                                                            })}
                                                        >
                                                            <CustomToggleBox
                                                                attribute="assignedTo"
                                                                value={userId}
                                                                label="Assigned to me"
                                                            />
                                                            <CustomToggleBox
                                                                attribute="isFavourite"
                                                                value={true}
                                                                label="Starred candidates"
                                                            />
                                                            <CustomToggleBox
                                                                attribute="isProfileCompleted"
                                                                value={false}
                                                                label="Incomplete profiles"
                                                                defaultRefinement={defaultIncompleteProfileSelected}
                                                                updateState={this.updateDefaultRefinement(
                                                                    'defaultIncompleteProfileSelected'
                                                                )}
                                                            />
                                                            <CustomStatusBox attribute="status" operator="or" />
                                                        </Panel>

                                                        <div className="tp-filter-divide" />
                                                        <Panel
                                                            header={
                                                                <div className="tp-filter-mb-12 mt-12 tp-filter-title">
                                                                    ARCHIVE REASONS
                                                                    <Icon
                                                                        component={DropdownIcon}
                                                                        onClick={() =>
                                                                            this.hideFilter('showArchiveReasonFilter')
                                                                        }
                                                                        className={classNames({
                                                                            'tp-dropdown-icon': true,
                                                                            'rotate-180': showArchiveReasonFilter,
                                                                            'rotate-360': !showArchiveReasonFilter,
                                                                        })}
                                                                    />
                                                                </div>
                                                            }
                                                            className={classNames({
                                                                'tp-opening-panel': true,
                                                                'tp-filter-none': !showArchiveReasonFilter,
                                                            })}
                                                        >
                                                            <CustomArchiveBox
                                                                attribute="archiveReason"
                                                                operator="or"
                                                                limit={100}
                                                            />
                                                        </Panel>

                                                        <div className="tp-filter-divide-opening" />
                                                        <Panel
                                                            header={
                                                                <div className="tp-filter-mb-12 mt-12 tp-filter-title">
                                                                    OPENINGS
                                                                    <Icon
                                                                        component={DropdownIcon}
                                                                        onClick={() =>
                                                                            this.hideFilter('showOpeningsFilter')
                                                                        }
                                                                        className={classNames({
                                                                            'tp-dropdown-icon': true,
                                                                            'rotate-180': showOpeningsFilter,
                                                                            'rotate-360': !showOpeningsFilter,
                                                                        })}
                                                                    />
                                                                </div>
                                                            }
                                                            className={classNames({
                                                                'tp-opening-panel': true,
                                                                'tp-filter-none': !showOpeningsFilter,
                                                            })}
                                                        >
                                                            <CustomJobBox
                                                                attribute="jobId"
                                                                operator="or"
                                                                defaultRefinement={this.state.defaultJobRefine}
                                                                limit={100}
                                                            />
                                                        </Panel>

                                                        <div className="tp-filter-divide" />
                                                        <Panel
                                                            header={
                                                                <div className="tp-filter-mb-12 mt-12 tp-filter-title">
                                                                    STAGES
                                                                    <Icon
                                                                        component={DropdownIcon}
                                                                        onClick={() =>
                                                                            this.hideFilter('showStageFilter')
                                                                        }
                                                                        className={classNames({
                                                                            'tp-dropdown-icon': true,
                                                                            'rotate-180': showStageFilter,
                                                                            'rotate-360': !showStageFilter,
                                                                        })}
                                                                    />
                                                                </div>
                                                            }
                                                            className={classNames({
                                                                'tp-opening-panel': true,
                                                                'tp-filter-none': !showStageFilter,
                                                            })}
                                                        >
                                                            <CustomStageBox
                                                                attribute="stageId"
                                                                operator="or"
                                                                limit={100}
                                                            />
                                                        </Panel>

                                                        <div className="tp-filter-divide" />
                                                        <Panel
                                                            header={
                                                                <div className="tp-filter-mb-12 mt-12 tp-filter-title">
                                                                    TAGS
                                                                    <Icon
                                                                        component={DropdownIcon}
                                                                        onClick={() =>
                                                                            this.hideFilter('showTagsFilter')
                                                                        }
                                                                        className={classNames({
                                                                            'tp-dropdown-icon': true,
                                                                            'rotate-180': showTagsFilter,
                                                                            'rotate-360': !showTagsFilter,
                                                                        })}
                                                                    />
                                                                </div>
                                                            }
                                                            className={classNames({
                                                                'tp-opening-panel': true,
                                                                'tp-filter-none': !showTagsFilter,
                                                            })}
                                                        >
                                                            <CustomTagsBox attribute="tags" operator="or" limit={100} />
                                                        </Panel>
                                                    </SidebarWrapper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tp-data-cont">
                                    {!noData ? (
                                        this.state.bulkMode ? (
                                            <div className="sticky-header">
                                                <div className="tp-page-bulkaction">
                                                    {selectionSize > 0 ? (
                                                        <div className="tp-page-count">
                                                            {selectionSize} Candidates Selected
                                                        </div>
                                                    ) : (
                                                        <div className="tp-page-count">
                                                            Please select the candidates to perform an action
                                                        </div>
                                                    )}
                                                    <div className="tp-page-btn-section">
                                                        {selectionSize > 0 ? (
                                                            <Popover
                                                                placement="bottomRight"
                                                                getPopupContainer={() =>
                                                                    document.getElementById('actionsButton')
                                                                }
                                                                content={this.renderActionsMenu(
                                                                    areJobsExists,
                                                                    accessLevel
                                                                )}
                                                                trigger="click"
                                                                visible={this.state.actionVisible}
                                                                onVisibleChange={this.handleVisibleChange}
                                                            >
                                                                <Button
                                                                    id="actionsButton"
                                                                    type="primary"
                                                                    className="talentpool-bulk-btn"
                                                                >
                                                                    <span className="tp-page-btn-text">
                                                                        Bulk Action
                                                                    </span>
                                                                    <Icon
                                                                        component={ActionIcon}
                                                                        style={{ marginLeft: '5px' }}
                                                                    />
                                                                </Button>
                                                            </Popover>
                                                        ) : (
                                                            <Button
                                                                id="actionsButton"
                                                                type="primary"
                                                                className="talentpool-bulk-btn"
                                                                disabled={true}
                                                            >
                                                                <span className="tp-page-btn-text">Bulk Actions</span>
                                                                <Icon
                                                                    component={ActionIcon}
                                                                    style={{ marginLeft: '5px' }}
                                                                />
                                                            </Button>
                                                        )}
                                                        <Button
                                                            onClick={this.handleBulkCancel}
                                                            ghost
                                                            type="default"
                                                            className="tp-page-cancel-btn"
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="tp-page-top-select">
                                                    <CandidateCount />
                                                    <div className="tp-page-top-select-container">
                                                        <Checkbox
                                                            className="tp-page-bulk-select"
                                                            onClick={this.selectAll}
                                                            checked={allSelectList}
                                                        >
                                                            Select All
                                                        </Checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    calBanner
                                                        ? 'sticky-bar-with-banner tp-page-bulkcontainer'
                                                        : 'sticky-bar-without-banner tp-page-bulkcontainer'
                                                }
                                            >
                                                <CandidateCount />
                                                <div
                                                    className="tp-page-bulk-btn"
                                                    onClick={() => {
                                                        this.setState({ bulkMode: true })
                                                    }}
                                                >
                                                    Bulk Actions
                                                </div>
                                            </div>
                                        )
                                    ) : null}
                                    <InfiniteHitsBox
                                        refresh={this.refreshIndex}
                                        eventManager={this.eventManager}
                                        selected={this.state.selected}
                                        setHits={this.setHits}
                                        bulkMode={this.state.bulkMode}
                                        allSelectList={allSelectList}
                                        clickedOnce={this.state.clickedOnce}
                                        updateCandidate={(value, jobPayload) => this.updateCandidate(value, jobPayload)}
                                        handleBulkCancel={this.handleBulkCancel}
                                        handleUpdateResume={(fileCheck, objectID, companyId) =>
                                            this.handleUpdateResume(fileCheck, objectID, companyId)
                                        }
                                    />
                                    <StateResult />
                                </div>
                            </MainContainer>
                        </div>
                    </InstantSearch>

                    {this.state.refresh ? (
                        <RefreshFooter>
                            <div className="tp-refresh-container">
                                <span className="tp-refresh-now-date">Last updated at: {lastUpdatedAt}</span>
                                <span className="tp-refresh-now-btn" onClick={() => window.location.reload()}>
                                    Refresh Now
                                </span>
                            </div>
                        </RefreshFooter>
                    ) : null}
                    <Modal
                        type={MODAL_TYPES.ADD_CANDIDATE}
                        visible={this.state.showAddCandidateModal}
                        onCancel={() => this.handleCancel(false)}
                        onSubmit={() => this.handleCancel(true)}
                        jobDetails={this.state.jobDetails}
                    />

                    <Modal
                        type={MODAL_TYPES.DOWNLOAD_REPORT}
                        visible={this.state.showDownloadReportModal}
                        user={this.props.__redux.Auth.user}
                        companyId={this.props.companyId}
                        onCancel={() => this.handleCancel(false)}
                        onSubmit={this.handleDownloadReport}
                    />

                    <Modal
                        visible={this.state.showSelectJobModal}
                        type={MODAL_TYPES.SELECT_JOB}
                        companyId={this.props.companyId}
                        openAddCandidate={jobDetails => this.showAddCandidateModal(jobDetails)}
                        onCancel={() => this.setState({ showSelectJobModal: false })}
                    />

                    <FSModal
                        type={FSMODAL_TYPES.ADD_CANDIDATE_SHEET}
                        visible={this.state.showCandidateSheet}
                        onCancel={() => this.setState({ showCandidateSheet: false })}
                    />

                    <Modal
                        type={MODAL_TYPES.CALENDAR_ONBOARDING}
                        visible={!!calPopUpStatus}
                        redirectPath={'talent-pool'}
                        onCancel={() =>
                            this.setState({ calPopUpStatus: false }, () => history.replace(window.location.pathname))
                        }
                        bodyStyle={{ padding: 0 }}
                        closable={true}
                        width={750}
                        calPopUpStatus={calPopUpStatus}
                    />

                    <Modal
                        type={MODAL_TYPES.UPDATE_CANDIDATE}
                        visible={this.state.moveCandidateModal}
                        onConfirm={(value, jobPayload) => this.updateCandidate(value, jobPayload)}
                        onCancel={this.closeModal}
                        selectionSize={Object.keys(this.state.selected).length}
                        selected={this.state.selected}
                        source={this.state.modalType}
                    />

                    <Modal
                        type={MODAL_TYPES.ARCHIVE_REASON}
                        visible={this.state.archiveModal}
                        onCancel={this.handleCancel}
                        onConfirm={(reason, sendEmailToCandidate) =>
                            this.eventManager(EVENTS.ARCHIVE, null, null, reason, null, sendEmailToCandidate)
                        }
                        callInProgress={this.state.clickedOnce}
                    />

                    <Modal
                        type={MODAL_TYPES.CONFIRM_REJECT_CANDIDATE}
                        visible={this.state.rejectCandidateModal}
                        onConfirm={() => this.eventManager(EVENTS.REJECT)}
                        onCancel={this.handleCancel}
                        singleCandidate={false}
                        totalCandidates={this.state.selectionSize || 'the selected'}
                    />

                    <Modal
                        type={MODAL_TYPES.ADD_TAG}
                        visible={this.state.addTagModal}
                        selectedCandidates={Object.values(selected).map(each => {
                            const tags =
                                (each.tags && each.tags.map((tag, index) => ({ tagId: index, name: tag }))) || []
                            return { ...each, tags }
                        })}
                        updateTags={data => {
                            this.setState({ addTagModal: false })
                            this.eventManager(EVENTS.UPDATE_TAGS, null, {
                                ...data,
                                jobApplicationIdList: Object.values(selected).map(each => each.jobApplicationId),
                            })
                        }}
                        onCancel={this.handleCancel}
                    />
                    <Modal
                        type={MODAL_TYPES.MANAGE_TAG}
                        visible={this.state.manageTagModal}
                        selectedCandidates={Object.values(selected).map(each => {
                            const tags =
                                (each.tags && each.tags.map((tag, index) => ({ tagId: index, name: tag }))) || []
                            return { ...each, tags }
                        })}
                        updateTags={data => {
                            this.setState({ manageTagModal: false })
                            this.eventManager(EVENTS.UPDATE_TAGS, null, {
                                ...data,
                                jobApplicationIdList: Object.values(selected).map(each => each.jobApplicationId),
                            })
                        }}
                        onCancel={this.handleCancel}
                    />
                </HotKeys>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    const { GCal, Auth, Job } = state
    return {
        inUse: GCal.inUse,
        allowShortcuts: Auth.user.allowShortcuts,
        accessLevel: Auth.user.accessLevel,
        companyId: Auth.user.companyId,
        userId: Auth.user.id,
        accessibleJobs: Auth.user.accessibleJobs,
        allJobs: Job.allJobs,
    }
}

export default connect(
    mapStateToProps,
    { RejectMultipleCandidatesWithJob, UpdateResume }
)(TalentPool)
