import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Bottom, Card } from './style'
import JobSkill from './FormPages/JobSkill'
import Details from './FormPages/Details'
import Preview from './FormPages/Preview'
import CandidateData from './FormPages/CandidateData'
import className from 'classnames'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { EXPERIENCE_OPTIONS } from '../../Helpers/constants'
import { warning } from '../../../services/Notification'
import apiCaller from '../../../services/apiCaller'
import { GetInitialDetails, UploadResume, AddUniversalCandidate } from '../../../services/NetworkCalls/Sourcing'
import * as querystring from 'querystring'
import Error from '../Error'
import { SuccessComponent, TopComponent } from './StaticComponents'
import { ProjectFooter } from '../../Components/ProjectFooter'
import REGEX from '../../Helpers/RegEx'

const { formatError } = apiCaller

class WebForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageStep: 1,
            details: {},
            jobSkill: {},
            candidate: {},
            successModal: false,
            isLoading: false,
            authProof: '',
            isLinkExpired: false,
            showFinalPage: false,
            isExistingCandidate: false, //Flag to track whether the page is being used by an existing applicant on the platform
        }
    }

    componentDidMount() {
        try {
            let { applicant, authProof } = querystring.parse(this.props.location.search.substring(1))

            const _isExistingCandidate = applicant && authProof ? true : false

            if (_isExistingCandidate) {
                this.setState({ isLoading: true }, async () => {
                    const { GetInitialDetails } = this.props
                    const { candidate } = await GetInitialDetails({ applicant, authProof })

                    this.setState({
                        authProof,
                        isLoading: false,
                        candidate,
                        details: candidate,
                        isLinkExpired: !candidate,
                    })
                })
            } else {
                // If the webform is being accessed publicly, and not via email invite
                this.setState({
                    isExistingCandidate: _isExistingCandidate,
                    authProof: '',
                    candidate: {},
                    details: {},
                })
            }
        } catch (error) {
            warning(formatError(error))
        }
    }

    changePage = type => {
        this.setState(({ pageStep }) => {
            if (type === 'INC') return { pageStep: pageStep + 1 }
            if (type === 'DEC') return { pageStep: pageStep - 1 }
        })
    }

    preSubmissionChecks = () => {
        const {
            candidate: { name, totalExperience, email },
        } = this.state
        if (!name) {
            warning('Name is required')
            return false
        }
        if (!totalExperience) {
            warning('Total Experience is required')
            return false
        }

        if (!email) {
            warning('Email is required')
            return false
        } else if (!REGEX.CANDIDATE.EMAIL.test(email)) {
            warning('Please enter a valid email.')
            return false
        }

        return true
    }

    submitCandidature = async () => {
        try {
            const { jobSkill, candidate, details, authProof } = this.state

            // data checks before submission
            if (!this.preSubmissionChecks()) return null

            let universalCandidate = {
                name: candidate.name,
                email: candidate.email,
                location: candidate.currentLocation,
                interested_jobs: jobSkill.jobs,
                key_skills: jobSkill.skills,
                noticePeriod: details.noticePeriod,
                ctc: {
                    currency: details.ctcInfo.expectedCTCCurrency,
                    current: details.ctcInfo.current,
                    expected: details.ctcInfo.expected,
                },
                relocation: details.relocation || false,
                resume: {
                    current: details.resumeLink || details.resume.link,
                },
                socialProfiles: {
                    [details.socialType]: details.socialLink || null,
                },
                experience: {
                    totalYears: EXPERIENCE_OPTIONS.indexOf(candidate.totalExperience),
                },
            }

            const phoneObject = parsePhoneNumberFromString(candidate.phone.toString())

            if (phoneObject && !phoneObject.isValid()) {
                warning('Phone number is not valid')
                return
            }

            universalCandidate['phone'] = {
                country_code: phoneObject.countryCallingCode,
                number: phoneObject.nationalNumber,
            }

            const res = await this.props.AddUniversalCandidate({ universalCandidate, authProof })

            if (res && res.universal_candidate) this.setState({ showFinalPage: true })
            return null
        } catch (error) {
            warning(formatError(error))
        }
    }

    flipPage = ({ direction, field, ...data }) => {
        if (field) {
            let { pageStep } = this.state
            if (direction === 'NEXT') pageStep++
            if (direction === 'BACK') pageStep--
            this.setState({ pageStep, [field]: data })
        }
    }

    uploadResume = async formData => {
        try {
            const { authProof } = this.state
            const res = await this.props.UploadResume({ formData, authProof })
            return res
        } catch (error) {
            warning(formatError(error))
        }
    }

    render() {
        const {
            pageStep,
            details,
            jobSkill,
            candidate,
            isLoading,
            authProof,
            isLinkExpired,
            showFinalPage,
            isExistingCandidate,
        } = this.state

        if (isLinkExpired) return <Error isWebform={true} />
        if (showFinalPage)
            return (
                <>
                    {TopComponent}
                    <SuccessComponent authProof={authProof} />
                </>
            )
        return (
            <>
                {TopComponent}
                <Bottom>
                    <Card padding={true}>
                        <CandidateData
                            pageStep={pageStep}
                            flipPage={this.flipPage}
                            candidate={candidate}
                            isLoading={isLoading}
                            isExistingCandidate={isExistingCandidate}
                        />
                    </Card>
                    <Card>
                        <JobSkill
                            flipPage={this.flipPage}
                            authProof={authProof}
                            jobSkill={jobSkill}
                            isLoading={isLoading}
                            showComponent={pageStep === 1}
                        />
                        {pageStep === 2 && (
                            <Details flipPage={this.flipPage} details={details} uploadResume={this.uploadResume} />
                        )}
                        {pageStep === 3 && (
                            <Preview
                                showComponent={pageStep === 3}
                                data={{ jobSkill, details }}
                                changePage={this.changePage}
                                submitCandidature={this.submitCandidature}
                            />
                        )}
                        <div className="steps">
                            <div className="step" />
                            <div className={className({ step: true, active: pageStep > 1 })} />
                            <div className={className({ step: true, active: pageStep > 2 })} />
                        </div>
                    </Card>
                </Bottom>
                <ProjectFooter />
            </>
        )
    }
}

export default connect(
    null,
    {
        GetInitialDetails,
        UploadResume,
        AddUniversalCandidate,
    }
)(WebForm)
