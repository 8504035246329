import React from 'react'
import classNames from 'classnames'
import { Header, Footer } from './../common'
import { store } from '../../../redux/store'
import { objectKeyExtractAsArray, validateJobId } from '../../Helpers/utils'
import REASONS from '../ArchiveReason/Reasons'
import { CANDIDATE_STATUS_ARR, ACCESS_LEVEL_DEFINITIONS } from '../../Helpers/constants'
import { SOURCE_TEXT, SOURCES } from './constants'
import { warning } from '../../../services/Notification'
import { Form, Select, Checkbox } from 'antd'
import './style.scss'
import { ReactComponent as DownIcon } from '../../../assets/Icons/Interview/down.svg'

const { Option } = Select

class UpdateCandidate extends React.Component {
    constructor(props) {
        super(props)
        let calculatedJobId = null
        let calculatedStageId = null
        let { allJobs } = store.getState().Job
        if (props.selectionSize === 1) {
            let job = Object.values(props.selected)[0]
            // checking if the selected default is archived jobid, if yes then set the first unarchived job
            calculatedJobId = (job.hasOwnProperty('jobId') && job.jobId && validateJobId(job.jobId)) || undefined
            if (props.source === SOURCES.UNJOIN) {
                calculatedStageId =
                    (calculatedJobId && allJobs.find(jobs => jobs._id === job.jobId).stages[0]._id) || undefined
            } else {
                calculatedStageId =
                    (calculatedJobId &&
                        ((job.stageName && job.stageId) ||
                            allJobs.find(jobs => jobs._id === job.jobId).stages[0]._id)) ||
                    undefined
            }

            if (job.jobStatus === 0) {
                calculatedJobId = job.jobTitle
                calculatedStageId = job.stageName
            }
        }
        const archiveReasons = REASONS.map(reason => reason.key)
        let defaultReason
        const candidateDetails = Object.values(props.selected)[0]
        if (archiveReasons.includes(candidateDetails.archiveReason)) defaultReason = candidateDetails.archiveReason
        else defaultReason = REASONS[0].key
        this.state = {
            allJobs,
            disableBtn: false,
            calculatedJobId,
            calculatedStageId,
            showJoinedConfirmation: false,
            archiveSelected: CANDIDATE_STATUS_ARR[candidateDetails.status] === CANDIDATE_STATUS_ARR[0],
            defaultReason,
            stageId: calculatedStageId,
            sendEmailToCandidate: true,
        }
    }

    onSubmit = () => {
        this.props.form.validateFieldsAndScroll(async (err, value) => {
            if (!err) {
                try {
                    const { source, selected, selectionSize } = this.props
                    const { allJobs, sendEmailToCandidate } = this.state
                    let jobPayload = {}
                    const jobApplications = objectKeyExtractAsArray(this.props.selected, 'jobApplicationId')
                    let selectedJob = null
                    if (selectionSize === 1) selectedJob = Object.values(selected)[0]
                    // recruiter level does not have stageId, so we have to improvise
                    if (!value.hasOwnProperty('stageId')) {
                        if (value.jobId === this.props.jobId && this.props.stageId) {
                            // means only the status is changed, keep the stageId same as sent in props.
                            value.stageId = this.props.stageId
                        } else {
                            value.stageId = allJobs.find(job => job._id === value.jobId).stages[0]._id
                        }
                    }
                    //If there is no field as status, then for single candidate, if the job has not been changed, the application status should remail the same
                    if (!value.hasOwnProperty('status')) {
                        if (selectionSize === 1 && selectedJob.jobId === value.jobId)
                            value.status = ['archived', 'active', 'rejected', 'joined'][selectedJob.status]
                        else value.status = 'active'
                    }

                    if (source === SOURCES.UPDATE_CANDIDATE_STATUS || source === SOURCES.ARCHIVED) {
                        value.sendEmailToCandidate = sendEmailToCandidate
                        jobPayload = {
                            value,
                            jobApplications,
                        }
                        this.props.onConfirm({ ...value, source }, jobPayload)
                    } else if (
                        source === SOURCES.MOVE_JOB ||
                        source === SOURCES.CHANGE_JOB ||
                        source === SOURCES.CHANGE_STAGE ||
                        source === SOURCES.UNJOIN
                    ) {
                        const input = {
                            jobId: value.jobId,
                            stageId: value.stageId,
                        }
                        if (selectionSize === 1 && selectedJob.jobId === value.jobId) input.status = selectedJob.status
                        else input.status = 1

                        const stageName = allJobs
                            .find(job => job._id === value.jobId)
                            .stages.find(stage => stage._id === value.stageId).stageName
                        if (stageName === 'JOINED') input.status = 3
                        if (input.status === 0) {
                            if (value.archiveReason) input.archiveReason = value.archiveReason
                            else input.archiveReason = selectedJob.archiveReason
                        }
                        jobPayload = {
                            input,
                            selectionSize: this.props.selectionSize,
                            jobApplications,
                            jobId: value.jobId,
                            stageId: value.stageId,
                        }
                        this.props.onConfirm({ ...value, source }, jobPayload)
                    }
                } catch (err) {
                    warning(err.message || err)
                }
            }
        })
    }

    confirmJoinedModel = () => {
        this.setState({ showJoinedConfirmation: true })
    }

    handleCancel = () => {
        this.props.form.setFieldsValue({ status: CANDIDATE_STATUS_ARR[this.props.status] })
        this.setState({ showJoinedConfirmation: false })
    }

    handleChange = (value, stages) => {
        const { getFieldValue, setFieldsValue } = this.props.form
        if (value === 'joined') setFieldsValue({ stageId: stages[stages.length - 1]._id })
        else if (value !== 'joined' && getFieldValue('stageId') === stages[stages.length - 1]._id)
            setFieldsValue({ stageId: stages[0]._id })

        if (value === 'archived') this.setState({ archiveSelected: true })
        else this.setState({ archiveSelected: false })
    }
    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form
        const { source, selected, selectionSize } = this.props
        const { calculatedStageId, calculatedJobId, archiveSelected, defaultReason, sendEmailToCandidate } = this.state
        const { allJobs } = store.getState().Job
        const { accessLevel } = store.getState().Auth.user

        const unArchivedJobs = allJobs.filter(job => job.status)
        let selectedJob = null
        if (selectionSize === 1) selectedJob = Object.values(selected)[0]

        let jobId = getFieldValue('jobId')
        if (selectedJob && selectedJob.jobStatus === 0 && jobId === calculatedJobId) jobId = selectedJob.jobId
        if (!jobId) {
            if (selectedJob && selectedJob.jobStatus === 0) jobId = selectedJob.jobId
            else
                jobId =
                    (this.props.hasOwnProperty('jobId') && validateJobId(this.props.jobId)) ||
                    calculatedJobId ||
                    (unArchivedJobs.length && unArchivedJobs[0]._id)
        }
        const getStages = (allJobs.length && allJobs.find(job => job._id === jobId)) || {}
        let stages = []
        if (source === SOURCES.UNJOIN) {
            stages =
                (getStages.hasOwnProperty('stages') &&
                    getStages.stages.filter(stage => stage.stageName !== 'JOINED')) ||
                []
        } else {
            stages = (getStages.hasOwnProperty('stages') && getStages.stages) || []
        }

        const currentStage = stages.filter(each => each._id === this.props.stageId)
        const candidateDetails = Object.values(this.props.selected)[0]
        const status = candidateDetails.hasOwnProperty('status') && candidateDetails.status
        const isRequired =
            this.props.source === SOURCES.UPDATE_CANDIDATE_STATUS ? (!!calculatedJobId ? '*' : null) : '*'
        const isRequiredRule = this.props.source === SOURCES.UPDATE_CANDIDATE_STATUS ? !!calculatedJobId : true
        const isDisabled =
            this.props.source === SOURCES.UPDATE_CANDIDATE_STATUS
                ? !this.state.stageId || getFieldValue('stageId') === stages[stages.length - 1]._id
                : !this.state.stageId

        return (
            <>
                <Header title={SOURCE_TEXT[source].heading} />
                <div className="uc-modal-wrapper">
                    <Form onSubmit={this.onSubmit}>
                        <div className="uc-element-body-wrapper">
                            {this.props.source === SOURCES.UPDATE_CANDIDATE_STATUS ? (
                                <div className="uc-element-row-wrapper top-margin">
                                    <div className="uc-element-column-wrapper">
                                        <Form.Item>
                                            <span className="modal-ad-title-color">Candidate Status</span>
                                            {getFieldDecorator('status', {
                                                initialValue: CANDIDATE_STATUS_ARR[status],
                                                rules: [{ required: true, message: 'Status is required!' }],
                                            })(
                                                <Select
                                                    size="large"
                                                    className="account-modal-role"
                                                    onChange={value => this.handleChange(value, stages)}
                                                    suffixIcon={<DownIcon />}
                                                >
                                                    <Option
                                                        className="account-modal-role-option"
                                                        key="active"
                                                        value="active"
                                                    >
                                                        <h5>Active</h5>
                                                    </Option>

                                                    <Option
                                                        className="account-modal-role-option"
                                                        key="rejected"
                                                        value="rejected"
                                                    >
                                                        <h5>Rejected</h5>
                                                    </Option>

                                                    {accessLevel < 3 && (
                                                        <Option
                                                            className="account-modal-role-option"
                                                            key="archived"
                                                            value="archived"
                                                        >
                                                            <h5>Archived</h5>
                                                        </Option>
                                                    )}

                                                    <Option
                                                        className="account-modal-role-option"
                                                        key="joined"
                                                        value="joined"
                                                        onClick={this.confirmJoinedModel}
                                                    >
                                                        <h5>Joined</h5>
                                                    </Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </div>
                                    {archiveSelected && (
                                        <div className="uc-element-column-wrapper">
                                            <Form.Item>
                                                <span className="modal-ad-title-color">Archive Reason</span>
                                                {getFieldDecorator('archiveReason', {
                                                    initialValue: defaultReason,
                                                    rules: [{ required: true, message: 'Archive Reason is required!' }],
                                                })(
                                                    <Select
                                                        size="large"
                                                        className="account-modal-role"
                                                        suffixIcon={<DownIcon />}
                                                    >
                                                        {REASONS.map(reason => (
                                                            <Option
                                                                className="account-modal-role-option"
                                                                key={reason.key}
                                                                value={reason.key}
                                                            >
                                                                <h5>{reason.value}</h5>
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </div>
                                    )}
                                </div>
                            ) : null}

                            {source === SOURCES.UNJOIN ? (
                                <div className="uc-element-text-wrapper">
                                    Select a stage to move the candidate profile once unmarked joined
                                </div>
                            ) : null}

                            <div
                                className={classNames({
                                    'uc-element-row-wrapper': true,
                                    'top-margin': ![SOURCES.UPDATE_CANDIDATE_STATUS, SOURCES.UNJOIN].includes(source),
                                })}
                            >
                                <div className="uc-element-column-wrapper">
                                    <Form.Item>
                                        <span className="modal-ad-title-color">
                                            Job Opening
                                            {isRequired}
                                        </span>
                                        {getFieldDecorator('jobId', {
                                            initialValue: calculatedJobId,
                                            rules: [
                                                {
                                                    required: isRequiredRule,
                                                    message: 'Job Opening is required!',
                                                },
                                            ],
                                        })(
                                            <Select
                                                placeholder="Select"
                                                size="large"
                                                className="account-modal-role"
                                                suffixIcon={<DownIcon />}
                                                disabled={
                                                    (source === SOURCES.CHANGE_STAGE && !!calculatedJobId) ||
                                                    source === SOURCES.UNJOIN
                                                }
                                                onChange={jobId => {
                                                    let stage
                                                    if (getFieldValue('status') === 'joined')
                                                        stage = allJobs
                                                            .find(job => job._id === jobId)
                                                            .stages.slice(-1)
                                                            .pop()
                                                    else stage = allJobs.find(job => job._id === jobId).stages[0]

                                                    setFieldsValue({ stageId: stage._id })
                                                    this.setState({ stageId: stage._id })
                                                }}
                                            >
                                                {unArchivedJobs
                                                    .filter(job => !!job.stages.length)
                                                    .map(option => (
                                                        <Option
                                                            className="account-modal-role-option"
                                                            key={option._id}
                                                            value={option._id}
                                                        >
                                                            <h5>{option.jobTitle}</h5>
                                                        </Option>
                                                    ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="uc-element-column-wrapper">
                                    <Form.Item>
                                        <span className="modal-ad-title-color">
                                            Stage
                                            {isRequired}
                                        </span>
                                        {getFieldDecorator('stageId', {
                                            initialValue: calculatedStageId,
                                            rules: [
                                                {
                                                    required: isRequiredRule,
                                                    message: 'Stage is required!',
                                                },
                                            ],
                                        })(
                                            <Select
                                                placeholder="Select"
                                                size="large"
                                                suffixIcon={<DownIcon />}
                                                className="account-modal-role"
                                                onChange={value => {
                                                    if (value === stages[stages.length - 1]._id)
                                                        setFieldsValue({ status: 'joined' })
                                                }}
                                                disabled={isDisabled || getFieldValue('status') === 'joined'}
                                            >
                                                {stages.map(option => {
                                                    if (
                                                        (source === SOURCES.UPDATE_CANDIDATE_STATUS &&
                                                            getFieldValue('status') !== 'joined' &&
                                                            option.stageName.toLowerCase() === 'joined') ||
                                                        (accessLevel > 3 && option.stageName === 'JOINED')
                                                    )
                                                        return undefined
                                                    return (
                                                        <Option
                                                            className="account-modal-role-option"
                                                            key={option._id}
                                                            value={option._id}
                                                        >
                                                            <h5>{option.stageName}</h5>
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </div>
                            </div>
                            {source === SOURCES.UPDATE_CANDIDATE_STATUS && archiveSelected ? (
                                <div className="mt-10">
                                    <Checkbox
                                        checked={sendEmailToCandidate}
                                        onChange={() => this.setState({ sendEmailToCandidate: !sendEmailToCandidate })}
                                    >
                                        <span>Send an email to the candidate for archival</span>
                                    </Checkbox>
                                </div>
                            ) : null}
                        </div>
                    </Form>
                </div>
                <Footer
                    onConfirm={this.onSubmit}
                    onCancel={this.props.onCancel}
                    confirmText={SOURCE_TEXT[source].confirmText}
                    cancelText={'CANCEL'}
                    disable={selectedJob && selectedJob.jobStatus === 0 && calculatedJobId === getFieldValue('jobId')}
                    disableCancel={false}
                    hasSeparator
                />
            </>
        )
    }
}

export default Form.create({ name: 'UpdateCandidate' })(UpdateCandidate)
