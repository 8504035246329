import React, { useState } from 'react'
import { Header, Footer } from '../common'
import { ContentWrapper } from '../style'
import { Radio, Checkbox } from 'antd'
import REASONS from './Reasons'
import './style.scss'

const ArchiveReason = ({ onCancel, onConfirm, callInProgress }) => {
    const [reason, setReason] = useState(REASONS[0].key)
    const [sendEmailToCandidate, setSendEmailToCandidate] = useState(true)

    return (
        <>
            <Header title="REASON FOR ARCHIVING" />
            <ContentWrapper mTop={false}>
                <Radio.Group defaultValue={REASONS[0].value}>
                    {REASONS.map(reason => (
                        <div key={reason.key} className="option">
                            <Radio value={reason.value} onChange={() => setReason(reason.key)}>
                                <span className="option__label">{reason.value}</span>
                            </Radio>
                        </div>
                    ))}
                </Radio.Group>
                <div className="archive-mail-confirm">
                    <Checkbox
                        checked={sendEmailToCandidate}
                        onChange={() => setSendEmailToCandidate(!sendEmailToCandidate)}
                    >
                        <span>Send an email to the candidate for archival</span>
                    </Checkbox>
                </div>
            </ContentWrapper>
            <Footer
                onCancel={onCancel}
                onConfirm={() => onConfirm(reason, sendEmailToCandidate)}
                cancelText="CANCEL"
                confirmText="ARCHIVE"
                disable={callInProgress}
            />
        </>
    )
}

export default ArchiveReason
