import styled from 'styled-components'
import { GREY, BORDER_COLOR, LIGHT_GREY, RED } from '../../Helpers/Stylesheet/styledComponents'
export const Body = styled.div`
    margin: 30px 0;
    .ant-input {
        width: 50%;
        color: ${GREY};
        font-size: 12px;
    }
    .help {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-top: 5px;
        color: ${LIGHT_GREY};
        svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }
    .confirm {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: ${GREY};
        margin-top: 10px;
    }
    .error {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        p {
            font-size: 12px;
            color: ${RED};
            margin-left: 5px;
            margin-top: -3px;
        }
    }
    .warning {
        padding-top: 30px;
        border-top: 1px solid ${BORDER_COLOR};
        color: ${GREY};
        line-height: 16px;
        font-size: 12px;
    }
`
export const GreyTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: ${({ mBottom = true }) => (mBottom ? '20px -5px' : '20px -5px 0')};
    font-size: 12px;
    color: ${GREY};
    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        width: fit-content;
        padding: 0 10px;
        height: 30px;
        border-radius: 5px;
        color: #4767a0;
        background-color: #ccdbf8;
        margin: 5px;
    }
    > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        padding: 0 10px;
        height: 30px;
        border-radius: 5px;
        background-color: #eeeeee;
        margin: 5px;
        &.show {
            background: initial;
            padding: 0;
        }
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        svg {
            margin-left: 10px;
            path {
                fill: ${GREY};
            }
        }
    }
`
