import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Footer, Header } from '../common'
import { Content } from './style'
import Description from './pages/Description'
import HowItWorks from './pages/HowItWorks'
import Form from './pages/Form'
import Success from './pages/Success'
import { LogE } from './../../Helpers/errorHandler'
import { warning } from '../../../services/Notification'
import { SourcingSubscription } from '../../../services/NetworkCalls/Sourcing'

class CandidateSourcing extends Component {
    constructor(props) {
        super(props)
        const selectedOpenings = [],
            selectedOpeningIds = []
        props.allJobs.forEach(job => {
            if (props.selectedOpeningIds.includes(job._id)) {
                selectedOpenings.push(job)
                selectedOpeningIds.push(job._id)
            }
        })
        this.state = {
            page: props.hasSubscribed ? 3 : 1,
            candidateType: props.hasSubscribed && props.candidateType,
            openingType: props.hasSubscribed && props.openingType,
            candidateTypeError: null,
            openingTypeError: null,
            selectedOpeningIds,
            selectedOpenings,
            disable: false,
        }
    }

    changePage = type => {
        this.setState(({ page, candidateType, openingType }) => {
            if (type === 'INC') {
                if (page === 3 && candidateType && openingType) {
                    this.formSubmit()
                    return
                } else if (page === 3) {
                    this.hasErrors()
                    return
                }
                return { page: page + 1 }
            }
            if (type === 'DEC') return { page: page - 1 }
        })
    }

    hasErrors = () => {
        let { candidateType, openingType, candidateTypeError, openingTypeError, selectedOpeningIds } = this.state
        if (!candidateType) candidateTypeError = true
        if (!openingType || (openingType === 'selected' && !selectedOpeningIds.length)) openingTypeError = true

        if (candidateTypeError || openingTypeError) {
            this.setState({ candidateTypeError, openingTypeError })
            return true
        }
        return false
    }

    formSubmit = () => {
        try {
            const hasErrors = this.hasErrors()
            if (!hasErrors) {
                const { candidateType, selectedOpeningIds } = this.state
                const enabledFor = {
                    archived: ['archived', 'both'].includes(candidateType),
                    rejected: ['rejected', 'both'].includes(candidateType),
                }

                const data = {
                    settings: {
                        enabledFor,
                        jobIds: selectedOpeningIds,
                        status: 'enabled',
                    },
                }

                this.setState({ disable: true }, async () => {
                    const res = await this.props.SourcingSubscription(data)
                    if (res) {
                        if (this.props.hasSubscribed) this.props.onCancel()
                        else this.setState({ page: 4, disable: false })
                        this.props.updateSubscription(res)
                    }
                })
            }
        } catch (err) {
            LogE('candidate-sourcing-modal', '', err)
            warning(err)
        }
    }

    setSelectedRadioFields = (field, value) => {
        if (field === 'candidateType') {
            this.setState({ candidateType: value, candidateTypeError: false })
        } else {
            this.setState({ openingType: value, openingTypeError: false, selectedOpeningIds: [], selectedOpenings: [] })
        }
    }

    setSelectedOpenings = (field, value) => {
        let { selectedOpeningIds, selectedOpenings } = this.state

        if (field === 'push') {
            selectedOpeningIds.push(value._id)
            selectedOpenings.push(value)
        } else {
            selectedOpeningIds = selectedOpeningIds.filter(id => id !== value._id)
            selectedOpenings = selectedOpenings.filter(opening => opening._id !== value._id)
        }

        this.setState({ selectedOpenings, selectedOpeningIds, openingTypeError: false })
    }

    pageSelector = step => {
        switch (step) {
            case 1:
                return <Description />
            case 2:
                return <HowItWorks />
            case 3:
                return (
                    <Form
                        setSelectedRadioFields={this.setSelectedRadioFields}
                        setSelectedOpenings={this.setSelectedOpenings}
                        selectedOpeningIds={this.state.selectedOpeningIds}
                        selectedOpenings={this.state.selectedOpenings}
                        candidateType={this.state.candidateType}
                        openingType={this.state.openingType}
                        candidateTypeError={this.state.candidateTypeError}
                        openingTypeError={this.state.openingTypeError}
                    />
                )
            case 4:
                return <Success />
        }
        return null
    }

    render() {
        const { page, disable } = this.state
        return (
            <>
                <Header title={`${this.props.hasSubscribed && page !== 4 ? 'EDIT' : ''} CANDIDATE SOURCING`} />
                <Content>
                    <div className="back" hidden={page === 1 || page === 4} onClick={() => this.changePage('DEC')}>
                        {'< Back'}
                    </div>
                    {this.pageSelector(page)}
                    {page !== 4 ? (
                        <div className="steps">
                            {Array(3)
                                .fill()
                                .map((_, i) => (
                                    <div key={i} className={i + 1 <= this.state.page ? 'active' : undefined}></div>
                                ))}
                        </div>
                    ) : null}
                </Content>
                {page === 4 ? (
                    <Footer hasSeparator centered onConfirm={this.props.onCancel} confirmText="DONE" />
                ) : (
                    <Footer
                        hasSeparator
                        onCancel={this.props.onCancel}
                        onConfirm={() => this.changePage('INC')}
                        cancelText="CANCEL"
                        confirmText={page === 3 && this.props.hasSubscribed ? 'SAVE' : 'NEXT'}
                        disable={disable}
                    />
                )}
            </>
        )
    }
}

export default connect(
    null,
    { SourcingSubscription }
)(CandidateSourcing)
