import React, { useState } from 'react'
import { ResultBox, Button, Heading } from '../style'
import { DatePicker, Icon, Popover, Menu } from 'antd'
import { REFINE_DAYS } from './../../../Helpers/constants'
import '../style.scss'
import { ReactComponent as CalendarIcon } from './../../../../assets/Icons/Todo/calendar1.svg'
import { ReactComponent as FilterIcon } from './../../../../assets/Icons/Todo/filter.svg'

const refineItems = Object.values(REFINE_DAYS)

const RefineResult = ({ updateRefineType, refineType: { key }, interviewCount, showMobileFilter, mobileFilter }) => {
    const datePickerUpdated = date => {
        date
            ? updateRefineType({
                key: 'CUSTOM',
                start: date.startOf('day').toISOString(),
                end: date.endOf('day').toISOString(),
            })
            : updateRefineType(REFINE_DAYS.NONE)
        setDate(date)
    }

    const [date, setDate] = useState(null)

    return (
        <ResultBox>
            {interviewCount > 0 ? <Heading>{interviewCount} Interviews</Heading> : <Heading />}
            <div>
                {refineItems.map(
                    each =>
                        each !== REFINE_DAYS.NONE && (
                            <Button
                                key={each.key}
                                active={key === each.key}
                                onClick={() => {
                                    updateRefineType(each)
                                    setDate(null)
                                }}
                                className="refine-buttons"
                            >
                                {each.text}
                            </Button>
                        )
                )}
                <DatePicker
                    suffixIcon={<Icon type={date ? 'close-circle' : 'calendar'} />}
                    onChange={datePickerUpdated}
                    value={date}
                    getCalendarContainer={trigger => trigger.parentNode}
                />
            </div>
            <div className="mobile">
                <Popover
                    placement="bottomRight"
                    trigger="click"
                    content={
                        <Menu>
                            {refineItems.map(
                                each =>
                                    each !== REFINE_DAYS.NONE && (
                                        <Menu.Item
                                            key={each.key}
                                            onClick={() => {
                                                updateRefineType(each)
                                                setDate(null)
                                            }}
                                        >
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span>{each.text}</span>
                                                {key === each.key && (
                                                    <span>
                                                        <Icon type="check" />
                                                    </span>
                                                )}
                                            </div>
                                        </Menu.Item>
                                    )
                            )}
                            <DatePicker
                                suffixIcon={<Icon type={date ? 'close-circle' : 'calendar'} />}
                                onChange={datePickerUpdated}
                                value={date}
                            />
                        </Menu>
                    }
                >
                    <CalendarIcon />
                </Popover>
                <FilterIcon onClick={() => showMobileFilter(true)} />
            </div>
        </ResultBox>
    )
}

export default RefineResult
