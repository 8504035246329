import React, { Component } from 'react'
import { Slots, Info, Content, CTA } from './style'
import { ReactComponent as InfoIcon } from '../../../../../assets/Icons/Interview/info.svg'
import { ReactComponent as LoadingIcon } from '../../../../../assets/Icons/Interview/loading.svg'
import { ReactComponent as LeftIcon } from '../../../../../assets/Icons/Interview/left.svg'
import { ReactComponent as RightIcon } from '../../../../../assets/Icons/Interview/right.svg'
import { ReactComponent as DownIcon } from '../../../../../assets/Icons/Interview/timeslots-down.svg'

export default class Slot extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pages: props.timeSlots && Math.ceil(props.timeSlots.length / 9),
            currentPage: 0,
            currentSet: props.timeSlots && props.timeSlots.slice(0, 9),
            showSlots: props.showSlots,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.timeSlots) {
            if (prevProps.timeSlots !== this.props.timeSlots) {
                this.setState({
                    currentSet: this.props.timeSlots.slice(0, 9),
                    pages: Math.ceil(this.props.timeSlots.length / 9),
                    currentPage: 0,
                    showSlots: this.props.showSlots,
                })
            } else {
                if (prevProps !== this.props && this.props.reShow) {
                    this.setState({ showSlots: this.props.reShow })
                }
            }
        } else {
            if (prevProps !== this.props) {
                this.setState({ pages: 0, currentPage: 0, currentSet: [], showSlots: this.props.showSlots })
            }
        }
    }

    showPrevSet = () => {
        if (this.state.currentPage !== 0) {
            this.setState({ currentPage: this.state.currentPage - 1 }, () => {
                this.setState({
                    currentSet: this.props.timeSlots.slice(
                        this.state.currentPage * 9,
                        (this.state.currentPage + 1) * 9
                    ),
                })
            })
        }
    }

    showNextSet = () => {
        if (this.state.currentPage !== this.state.pages - 1) {
            this.setState({ currentPage: this.state.currentPage + 1 }, () => {
                this.setState({
                    currentSet: this.props.timeSlots.slice(
                        this.state.currentPage * 9,
                        (this.state.currentPage + 1) * 9
                    ),
                })
            })
        }
    }

    handleTimeSlotSelection = slot => {
        this.setState(
            {
                showSlots: false,
            },
            () => this.props.handleTimeSlotSelection(slot)
        )
    }

    render() {
        return (
            <>
                {this.props.interviewers.length && this.props.selectedDate ? (
                    <Info
                        hidden={
                            (this.props.timeSlots != undefined &&
                                (this.props.timeSlots === null ||
                                    (this.props.timeSlots && this.props.timeSlots.length > 0))) ||
                            this.props.loading
                        }
                    >
                        <InfoIcon /> No available slots on selected date for selected interviewers.
                    </Info>
                ) : (
                    <Info hidden={this.props.loading || this.props.timeSlots !== null}>
                        <InfoIcon />
                        Select interviewers and date to check their available time slots.
                    </Info>
                )}
                <Info hidden={!this.props.loading}>
                    <LoadingIcon className="loading" /> Fetching the available time slots.
                </Info>
                <CTA hidden={this.state.showSlots} onClick={() => this.setState({ showSlots: true })}>
                    <span>Click here to view available time slots</span>
                    <DownIcon />
                </CTA>
                <Slots
                    hidden={!this.state.showSlots || !(this.props.timeSlots && this.props.timeSlots.length > 0)}
                    noLeft={this.state.currentPage === 0}
                    noRight={this.state.currentPage === this.state.pages - 1}
                >
                    <div className="icons" hidden={this.state.currentPage === 0}>
                        <LeftIcon onClick={this.showPrevSet} />
                    </div>
                    <table>
                        <tbody>
                            <tr>
                                {this.state.currentSet &&
                                    this.state.currentSet.slice(0, 3).map((timeSlot, index) => {
                                        return (
                                            <td key={index} onClick={() => this.handleTimeSlotSelection(timeSlot)}>
                                                {timeSlot}
                                            </td>
                                        )
                                    })}
                            </tr>
                            <tr>
                                {this.state.currentSet &&
                                    this.state.currentSet.slice(3, 6).map((timeSlot, index) => {
                                        return (
                                            <td key={index} onClick={() => this.handleTimeSlotSelection(timeSlot)}>
                                                {timeSlot}
                                            </td>
                                        )
                                    })}
                            </tr>
                            <tr>
                                {this.state.currentSet &&
                                    this.state.currentSet.slice(6, 9).map((timeSlot, index) => {
                                        return (
                                            <td key={index} onClick={() => this.handleTimeSlotSelection(timeSlot)}>
                                                {timeSlot}
                                            </td>
                                        )
                                    })}
                            </tr>
                        </tbody>
                    </table>
                    <div className="icons" hidden={this.state.currentPage === this.state.pages - 1}>
                        <RightIcon onClick={this.showNextSet} />
                    </div>
                </Slots>
            </>
        )
    }
}
