import styled from 'styled-components'
import BackgroundSVG from '../../../assets/svg/background.svg'
import LogoIcon from '../../../assets/Icons/Landing/logo.svg'

export const SignupWrapper = styled.div`
    width: auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;

    .ant-form-explain {
        text-align: left;
        width: 304px;
        margin: auto;
        @media only screen and (max-width: 995px) {
            width: 100%;
        }
    }

    .ant-form-item {
        margin-bottom: 10px;
    }
`

export const SignupContainer = styled.div`
    width: 100%;
    height: 100vh;
    background: url(${BackgroundSVG});
    background-size: cover;
`

export const ContentWrapper = styled.div`
    background-color: #fff;
    padding: 25px 67px 56px;
    text-align: center;
    margin: auto;
    height: 425px;
    width: 464px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 995px) {
        width: calc(100% - 30px);
        padding: 10px;
    }
`

export const FormWrapper = styled.div`
    height: 167px;
    margin: auto;
    display: flex;
    width: 100%;
`

export const LogoWrapper = styled.div`
    position: absolute;
    top: 0;
    margin: 55px 0 0 48px;
    background: url(${LogoIcon});
    padding: 53px;
    background-repeat: no-repeat;
    @media only screen and (max-width: 995px) {
        margin: 10px 0 0 10px;
    }
`

export const SpringWrapper = styled.div`
    height: 14.17px;
    width: 54px;
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
`

export const RecruitWrapper = styled.div`
    height: 27px;
    width: 102.89px;
    color: #ffffff;
    font-size: 22.4px;
    font-weight: bold;
    line-height: 28px;
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
`
