export const FILTER_TYPES = {
    ALL: 'ALL',
    ADDED: 'ADDED',
    ASSIGNED: 'ASSIGNED',
}

export const FILTER_TYPES_BE = {
    [FILTER_TYPES.ALL]: 'all',
    [FILTER_TYPES.ADDED]: 'assignedByMe',
    [FILTER_TYPES.ASSIGNED]: 'assignedToMe',
}

export const FILTER_TYPES_BE_REVERSED = {
    all: FILTER_TYPES.ALL,
    assignedByMe: FILTER_TYPES.ADDED,
    assignedToMe: FILTER_TYPES.ASSIGNED,
}

export const PAGE_SIZE_LIMIT = 30

export const EVENTS = {
    FILTER: 'FILTER',
    OPENINGS: 'OPENINGS',
    STAGE: 'STAGE',
    STATUS: 'STATUS',
    SEARCH: 'SEARCH',
    NEXT_PAGE: 'NEXT_PAGE',
    SEARCH_WITH_TAB_MODE: 'SEARCH_WITH_TAB_MODE',
}

export const TAB_MODE = {
    ALL: 'ALL',
    UNREAD: 'UNREAD',
}
