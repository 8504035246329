const actions = {
    SET_DOSELECT_TEST: 'SET_DOSELECT_TEST',
    SET_UNBERRY_POSITION: 'SET_UNBERRY_POSITION',
    SET_TEAM_MEMBER: 'SET_TEAM_MEMBER',
    ADD_TEAM_MEMBER: 'ADD_TEAM_MEMBER',
    REMOVE_TEAM_MEMBER: 'REMOVE_TEAM_MEMBER',
    UPDATE_TEAM_MEMBER: 'UPDATE_TEAM_MEMBER',

    MODIFY_CACHE_DATA: 'MODIFY_CACHE_DATA',
}
export default actions
