import React, { Component } from 'react'
import { ReactComponent as AddIcon } from '../../../../assets/Icons/Access/add.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/Icons/Openings/delete.svg'
import { ReactComponent as ErrorIcon } from '../../../../assets/Icons/Openings/error.svg'
import { ReactComponent as SuccessIcon } from '../../../../assets/Icons/Openings/success.svg'
import { ReactComponent as LoaderIcon } from '../../../../assets/Icons/Openings/loader.svg'
import { ReactComponent as MinusIcon } from '../../../../assets/Icons/Access/minus.svg'
import { FormWrapper } from '../style'
import { CandidateCollapsible } from '../style'
import { FILE_STATUS } from '../../../Helpers/constants'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import actions from '../../../../redux/candidate/actions'
import DataForm from './DataForm'
import JobStageSelect from './JobStageSelect'
import { formatFileName } from '../../../Helpers/utils'

const classCalculate = (uploadStatus, isExpanded) => {
    switch (uploadStatus) {
        case FILE_STATUS.FAILED:
            return 'failed'
        case FILE_STATUS.UPLOADING:
            return 'loading'
        case FILE_STATUS.SUCCESS:
            if (isExpanded === null) return 'active'
            else return isExpanded ? 'active' : 'error'
        default:
            return 'error'
    }
}

const messageIconHandler = (uploadStatus, hasErrors, errors) => {
    if (hasErrors) {
        const message = Object.keys(errors)
            .map(each => (errors[each] ? each.toString() : false))
            .filter(each => each)
        return (
            <>
                {message.length ? <span className="error">{message.join(', ')}&nbsp;field needs attention</span> : null}
                <ErrorIcon className="status-icon" />
            </>
        )
    }

    switch (uploadStatus) {
        case FILE_STATUS.FAILED:
            return (
                <>
                    <span>Upload failed</span>
                    <ErrorIcon className="status-icon" />
                </>
            )
        case FILE_STATUS.UPLOADING:
            return <LoaderIcon className="loader-icon" />
        case FILE_STATUS.SUCCESS:
            return <SuccessIcon className="status-icon" />
        default:
            return null
    }
}

class BulkPreviewList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deleteMode: false,
            isExpanded: null,
        }
    }

    changeHandler = data => {
        const { ModifyCacheData, uploadId } = this.props
        ModifyCacheData({ uploadId, ...data })
    }

    deleteCandidate = () => {
        const { deleteFile, uploadId, updateSuccessCount } = this.props
        deleteFile(uploadId)
        updateSuccessCount(uploadId, 'sub')
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            data: { uploadStatus },
            updateSuccessCount,
            uploadId,
        } = this.props

        // if the uploadStatus had changed to success then update the parent's count
        if (uploadStatus === FILE_STATUS.SUCCESS && prevProps.data.uploadStatus === FILE_STATUS.UPLOADING) {
            updateSuccessCount(uploadId, 'add')
        }
    }

    setListHeaderName = (uploadStatus, fileName, name) => {
        if (uploadStatus === FILE_STATUS.SUCCESS) return name.length ? name : 'Candidate Name'
        return formatFileName(fileName, 40)
    }

    render() {
        const { deleteMode, isExpanded } = this.state
        const { data, uploadId, deleteFile, hasErrors, errors, counting, jobDetails } = this.props
        const { name, email, uploadStatus, resumeLink, fileName } = data
        if (deleteMode)
            return (
                <CandidateCollapsible>
                    <div className="category delete">
                        <div>Are you sure you want to delete?</div>
                        <div>
                            <button onClick={this.deleteCandidate}>Yes</button>
                            <button onClick={() => this.setState({ deleteMode: false })}>No</button>
                        </div>
                    </div>
                </CandidateCollapsible>
            )
        return (
            <CandidateCollapsible>
                <div
                    className={`category ${classCalculate(uploadStatus, isExpanded)}`}
                    onClick={() => this.setState({ isExpanded: isExpanded === null ? false : !isExpanded })}
                >
                    <div>
                        {isExpanded || (uploadStatus === FILE_STATUS.SUCCESS && isExpanded === null) ? (
                            uploadStatus === FILE_STATUS.UPLOADING ? (
                                <AddIcon />
                            ) : (
                                <MinusIcon />
                            )
                        ) : (
                            <AddIcon />
                        )}
                        <span>{this.setListHeaderName(uploadStatus, fileName, name)}</span>
                    </div>
                    <div>
                        {messageIconHandler(uploadStatus, hasErrors, errors)}
                        {uploadStatus !== FILE_STATUS.UPLOADING && (
                            <DeleteIcon onClick={() => this.setState({ deleteMode: true })} className="delete-icon" />
                        )}
                    </div>
                </div>

                <div className="list">
                    <FormWrapper>
                        <DataForm changeHandler={this.changeHandler} uploadId={uploadId} jobDetails={jobDetails} />
                        <JobStageSelect
                            changeHandler={this.changeHandler}
                            uploadId={uploadId}
                            jobDetails={jobDetails}
                        />
                    </FormWrapper>
                </div>
            </CandidateCollapsible>
        )
    }
}

BulkPreviewList.propTypes = {
    uploadId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.Candidate.data[ownProps.uploadId],
        hasErrors: state.Candidate.data[ownProps.uploadId].hasErrors,
        errors: state.Candidate.data[ownProps.uploadId].errors,
    }
}

const ModifyCacheData = payload => dispatch => {
    dispatch({ type: actions.UPDATE_CANDIDATE, payload })
}

const deleteFile = uploadId => dispatch => {
    dispatch({ type: actions.REMOVE_CANDIDATE, payload: { uploadId } })
}

export default connect(
    mapStateToProps,
    { deleteFile, ModifyCacheData }
)(BulkPreviewList)
