import React from 'react'
import { Provider } from 'react-redux'
import { store, history, persistor } from './redux/store'
import PublicRoutes from './router'
import { notification } from 'antd'
import { PersistGate } from 'redux-persist/integration/react'

notification.config({
  placement: 'topRight',
  top: 60,
})

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PublicRoutes history={history} />
        </PersistGate>
      </Provider>
    )
  }
}
