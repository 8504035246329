import React, { Component } from 'react'
import { Filter } from '../style'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { Select, Icon } from 'antd'
import { Field } from '../../../../container/Modals/AddCandidate/style'
import { ReactComponent as InfoIcon } from '../../../../assets/Icons/Openings/info.svg'

const { Option } = Select

export const Filters = ({ total, title, desc }) => {
    return (
        <Filter>
            <Field>
                <div className="title">Filter by job type</div>
                <Select
                    size="large"
                    dropdownClassName="interview-types"
                    className="interview-select"
                    getPopupContainer={trigger => trigger.parentNode}
                    suffixIcon={<Icon component={DownIcon} />}
                >
                    <Option key="1">1</Option>
                </Select>
            </Field>
            <Field>
                <div className="title">Filter by job name</div>
                <Select
                    size="large"
                    dropdownClassName="interview-types"
                    className="interview-select"
                    getPopupContainer={trigger => trigger.parentNode}
                    suffixIcon={<Icon component={DownIcon} />}
                >
                    <Option key="1">1</Option>
                </Select>
            </Field>
            <Field>
                <div className="title">Filter by tag</div>
                <Select
                    size="large"
                    dropdownClassName="interview-types"
                    className="interview-select"
                    getPopupContainer={trigger => trigger.parentNode}
                    suffixIcon={<Icon component={DownIcon} />}
                >
                    <Option key="1">1</Option>
                </Select>
            </Field>
            <Field>
                <div className="title">Filter by date</div>
                <Select
                    size="large"
                    dropdownClassName="interview-types"
                    className="interview-select"
                    getPopupContainer={trigger => trigger.parentNode}
                    suffixIcon={<Icon component={DownIcon} />}
                >
                    <Option key="1">1</Option>
                </Select>
            </Field>
        </Filter>
    )
}

export default Filters
