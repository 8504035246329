import config from '../../config'
import { LogE } from '../Helpers/errorHandler'
const { userInputCacheTTL } = config.config()

class StorageManager {
    constructor(instanceType, id) {
        this._keyPrefix = '__srec.caches_'
        this.refId = id
        this._key = `${this._keyPrefix}${instanceType}_${id}`
    }

    setData(data) {
        const payload = { data, ttl: Date.now() + userInputCacheTTL }
        if (!this.refId) return null

        if (data.length) localStorage.setItem(this._key, JSON.stringify(payload))
        else this.clearSelf()
    }

    getData() {
        if (!this.refId) return ''
        const { data = '' } = JSON.parse(localStorage.getItem(this._key) || '{}')
        return data
    }

    clearSelf() {
        if (!this.refId) return null
        localStorage.removeItem(this._key)
    }

    static sanitisation() {
        const keyPrefix = '__srec.caches_'
        for (const key in localStorage) {
            if (key.substring(0, keyPrefix.length) === keyPrefix) {
                try {
                    const { ttl = null, data } = JSON.parse(localStorage.getItem(key) || '{}')
                    if (ttl === null || Date.now() < ttl || !data) localStorage.removeItem(key)
                } catch (e) {
                    LogE('StorageManager-sanitisation', '', e)
                }
            }
        }
    }
}

export default StorageManager
