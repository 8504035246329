import styled from 'styled-components'
import BackgroundSVG from '../../../assets/svg/background.svg'
import LogoIcon from '../../../assets/Icons/Landing/logo.svg'

export const SignupWrapper = styled.div`
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    @media only screen and (max-width: 995px) {
        width: 100%;
    }
`

export const SignupContainer = styled.div`
    width: 100%;
    height: auto;
    background: url(${BackgroundSVG});
    background-size: cover;
    display: inline-table;
    @media only screen and (max-width: 995px) {
        padding: 0;
        height: 100vh;
        background: none;
        background-color: #fff;
    }
`

export const LogoWrapper = styled.div`
    position: absolute;
    top: 0;
    background: url(${LogoIcon});
    padding: 50px;
    background-repeat: no-repeat;
    margin: 55px 0 0 48px;
    @media only screen and (max-width: 995px) {
        margin: 0;
        position: unset;
        box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
        background-color: #ffffff;
        display: inline-block;
        width: 100%;
        padding: 13px 10px;
    }
`

export const SpringWrapper = styled.div`
    height: 14.17px;
    width: 54px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    opacity: 0.9;
    @media only screen and (max-width: 995px) {
        color: #4767a0;
        font-size: 12px;
        opacity: 1;
        text-align: left;
        height: unset;
    }
`

export const RecruitWrapper = styled.div`
    height: 27px;
    width: 102.89px;
    color: #fff;
    font-size: 22.4px;
    font-weight: bold;
    line-height: 30px;
    opacity: 0.8;
    @media only screen and (max-width: 995px) {
        color: #4767a0;
        font-size: 14px;
        opacity: 1;
        width: 100%;
        line-height: normal;
        font-family: 'sanFranciscoSemi';
        height: unset;
    }
`
