import React from 'react'
import { ReactComponent as SuccessIcon } from '../../../../assets/Icons/Notification/success.svg'
import { ReactComponent as WarningIcon } from '../../../../assets/Icons/Notification/warning.svg'
import { StyledButton } from '../../../Components'

const ErrorsPreview = props => {
    return (
        <div className="status">
            <div>
                <SuccessIcon />
                <div>
                    {props.success} candidates profile added successfully.{' '}
                    <span onClick={() => props.history.push('/talent-pool')}>Go to Talent Pool</span>
                </div>
            </div>
            <div>
                <WarningIcon />
                <div>
                    {props.fails} candidates profile failed to add.{' '}
                    {props.link ? (
                        <a href={props.link} target="_blank" download>
                            Download Error Files
                        </a>
                    ) : null}
                </div>
            </div>
            <StyledButton type="inverted" height="40px" width="200px" onClick={() => props.changeStep(1, 1)}>
                IMPORT MORE CANDIDATES
            </StyledButton>
        </div>
    )
}

export default ErrorsPreview
