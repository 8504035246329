import React, { useState, useEffect } from 'react'
import { ReactComponent as MedalIcon } from '../../../../assets/Icons/Interview/medal.svg'

const Success = props => {
    return (
        <div className="success">
            <MedalIcon />
            <p>
                You have been successfully subscribed to candidate sourcing feature. Please navigate to Sourcing Talent
                Pool to explore and know more about it.
            </p>
            <a href={'/sourcing'}>Go to Sourcing Talent Pool</a>
        </div>
    )
}

export default Success
