export const CANDIDATE = {
    NAME: /^[a-z ,.'-]+$/i,
    COMPANY_NAME: /^[A-Za-z]+[a-zA-Z0-9\s\!\@\#\\\\/\$\%\^\&\*\/\)\(\]\[+=._-]*$/,
    LOCATION: /^[A-Za-z]+[a-zA-Z0-9\s\!\#\\\\/\$\%\^\&\*\/\)\(\]\[+,=._-]*$/,
    PORTFOLIO: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    LINKEDIN: /^(((https|http):\/\/(www\.)?|(www\.)?)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/,
    GITHUB: /^((https|http):\/\/(www\.)?|(www\.)?)?github\.com\/.*$/,
    EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    OTP: /^\d{6}$/,
    NOSPECIALCHAR: /[*|":<>[\]{}`\\();@&$]/,
}

export const ONLY_DIGITS = /[^0-9]/g
export default {
    CANDIDATE,
    ONLY_DIGITS,
}
