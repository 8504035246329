import React from 'react'

const SVGComponent = () => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M8.16666667,8.16666667 L5.66666667,8.16666667 C5.20642938,8.16666667 4.83333333,8.53976271 4.83333333,9 C4.83333333,9.46023729 5.20642938,9.83333333 5.66666667,9.83333333 L8.16666667,9.83333333 L8.16666667,12.3333333 C8.16666667,12.7935706 8.53976271,13.1666667 9,13.1666667 C9.46023729,13.1666667 9.83333333,12.7935706 9.83333333,12.3333333 L9.83333333,9.83333333 L12.3333333,9.83333333 C12.7935706,9.83333333 13.1666667,9.46023729 13.1666667,9 C13.1666667,8.53976271 12.7935706,8.16666667 12.3333333,8.16666667 L9.83333333,8.16666667 L9.83333333,5.66666667 C9.83333333,5.20642938 9.46023729,4.83333333 9,4.83333333 C8.53976271,4.83333333 8.16666667,5.20642938 8.16666667,5.66666667 L8.16666667,8.16666667 Z M9,17.3333333 C4.39762708,17.3333333 0.666666667,13.6023729 0.666666667,9 C0.666666667,4.39762709 4.39762708,0.666666667 9,0.666666667 C13.6023729,0.666666667 17.3333333,4.39762709 17.3333333,9 C17.3333333,13.6023729 13.6023729,17.3333333 9,17.3333333 Z"
                id="path-11"
            />
        </defs>
        <g id="Product-Flow" fill="auto" fillRule="evenodd">
            <g id="Talent-Pool" transform="translate(-920 -119)">
                <g id="Add-Candidate-Btn" transform="translate(907 106)">
                    <g id="Add-Candidate" transform="translate(13 12)">
                        <g id="💚-Icon" transform="translate(0 1)">
                            <mask id="mask-2-tp-add" fill="#fff">
                                <use xlinkHref="#path-11" />
                            </mask>
                            <use id="Mask" fillOpacity="0" fill="#051033" xlinkHref="#path-11" />
                            <g id="↳🎨-Icon-Color" mask="url(#mask-2-tp-add)" fill="#4767A0">
                                <polygon
                                    points="0 0 20 0 20 20 0 20"
                                    transform="translate(-1 -1)"
                                    id="Blue-/-Blue-Base-700"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
