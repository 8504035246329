import React, { Component } from 'react'
import './../style.css'
import { Container } from '../style'
import { Row, Col, Form, Typography, Input, Icon } from 'antd'
import { success, warning } from '../../../../services/Notification'
import { ReactComponent as Up } from '../../../../assets/svg/career-up.svg'
import { ReactComponent as Down } from '../../../../assets/svg/career-down.svg'
import { ReactComponent as Edit } from '../../../../assets/svg/career-edit.svg'
import { WORK_WITH_US_LIMITS, REQUIRED_ERRORS, VALIDATION_STATUS } from '../DataConfigs'
import SaveContainer from './SaveContainer'

const { Text } = Typography
const { TextArea } = Input
const { REQUIRED_ONLY_TITLE_ERROR } = REQUIRED_ERRORS
const { TITLE, TITLE_ERROR } = WORK_WITH_US_LIMITS

class WorkWithUs extends Component {
    state = {
        editFlag: false,
        titleRequired: false,
        validate_title_status: VALIDATION_STATUS.success,
        validate_title_help: '',
    }

    onSubmit = e => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    let points = []
                    let dataFilledPoints = 0
                    for (let key in values) {
                        if (key !== 'title') {
                            const data = values[key]
                            if (data.length) ++dataFilledPoints
                            points.push(data)
                        }
                    }

                    // VALIDATION CHECK: if there are any of the points filled then title is neceseccary
                    if (values.title.length === 0 && dataFilledPoints > 0) {
                        this.setState({
                            validate_title_status: VALIDATION_STATUS.error,
                            validate_title_help: REQUIRED_ONLY_TITLE_ERROR,
                        })
                        return null
                    }

                    if (values.title.length > TITLE) {
                        this.setState({
                            validate_title_status: VALIDATION_STATUS.error,
                            validate_title_help: TITLE_ERROR,
                        })
                        return null
                    }
                    let value = {
                        title: values.title,
                        points: points,
                    }
                    this.props.onSubmit(value, this.props.index, 'why_work_with_us')
                    this.setState({ editFlag: false })
                    this.props.updateEditModeCount('work', 1)
                    success('WHY WORK WITH US section saved successfully')
                } catch (err) {
                    warning(err.message || err)
                }
            }
        })
    }

    pointsValidator = () => {
        const { getFieldsValue } = this.props.form
        const { title } = getFieldsValue(['title'])
        const { p1, p2, p3, p4, p5, p6 } = getFieldsValue(['p1', 'p2', 'p3', 'p4', 'p5', 'p6'])
        const points = p1 || p2 || p3 || p4 || p5 || p6
        return (title && title.length > 0 && !points) || false
    }

    renderPoints = (each, index) => {
        const { getFieldDecorator } = this.props.form

        return (
            <Col span={8} key={index}>
                <Text className="carrer-cont-title">Point {index + 1}</Text>
                <Form.Item>
                    {getFieldDecorator(`p${index + 1}`, {
                        initialValue: each || '',
                        validateTrigger: ['onChange', 'onBlur'],
                        validateFirst: true,
                        rules: [
                            {
                                max: WORK_WITH_US_LIMITS.POINTS,
                                message: WORK_WITH_US_LIMITS.POINTS_ERROR,
                            },
                            {
                                required: this.pointsValidator(index),
                                message: WORK_WITH_US_LIMITS.REQUIRED_ERROR,
                            },
                        ],
                    })(
                        <TextArea rows={4} placeholder={`Enter your point ${index + 1}`} onChange={this.handleChange} />
                    )}
                </Form.Item>
            </Col>
        )
    }

    onCancel = () => {
        const {
            form: { resetFields },
            workWithUs,
        } = this.props
        resetFields([...workWithUs.points.map((work, index) => `p${index + 1}`), 'title'])
        this.setState({
            editFlag: false,
            validate_title_status: VALIDATION_STATUS.success,
            validate_title_help: '',
        })
        this.props.updateEditModeCount('work', -1)
    }

    moveUp = () => {
        this.props.moveUp(this.props.index)
    }

    moveDown = () => {
        this.props.moveDown(this.props.index)
    }

    edit = () => {
        this.setState({ editFlag: true })
        this.props.updateEditModeCount('work', 0)
    }

    handleChange = value => {
        this.setState({ editFlag: true })
        this.props.updateEditModeCount('work', 0)
    }

    handleTitleChange = ({ target: { value } }) => {
        const successIndicator = {
            validate_title_status: VALIDATION_STATUS.success,
            validate_title_help: '',
        }

        const failureindicator = {
            validate_title_status: VALIDATION_STATUS.error,
            validate_title_help: value.length === 0 ? REQUIRED_ONLY_TITLE_ERROR : TITLE_ERROR,
        }

        this.setState(state => {
            if (value.length > 0 && value.length < 49) return { editFlag: true, ...successIndicator }
            return { editFlag: true, ...failureindicator }
        })
        this.props.updateEditModeCount('work', 0)
    }

    render() {
        const { getFieldDecorator } = this.props.form

        return (
            <div key={this.props.key}>
                <Container>
                    <Row gutter={48}>
                        <Col span={12}>
                            <Text className="carrer-cont-title">WHY WORK WITH US</Text>
                        </Col>
                        <Col span={12}>
                            <div className="career-cont-btn">
                                {this.props.index >= 1 ? (
                                    <div className="career-section-icons" onClick={this.moveUp}>
                                        <Icon className="career-icons" component={Up} />
                                    </div>
                                ) : null}
                                {this.props.index < this.props.length - 1 ? (
                                    <div className="career-section-icons" onClick={this.moveDown}>
                                        <Icon className="career-icons" component={Down} />
                                    </div>
                                ) : null}
                                <div className="career-section-icons" onClick={this.edit}>
                                    <Icon className="career-icons" component={Edit} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={48}>
                        <Col span={12}>
                            <Text className="carrer-cont-title">Title</Text>
                            <Form.Item
                                validateStatus={this.state.validate_title_status}
                                help={this.state.validate_title_help}
                            >
                                {getFieldDecorator(`title`, {
                                    initialValue: this.props.workWithUs.title || '',
                                })(
                                    <Input
                                        size="large"
                                        placeholder="WHY WORK WITH US"
                                        onChange={this.handleTitleChange}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={48}>
                        {this.props.workWithUs.points.map((each, index) => this.renderPoints(each, index))}
                    </Row>
                    {this.state.editFlag && <SaveContainer onSubmit={this.onSubmit} onCancel={this.onCancel} />}
                </Container>
            </div>
        )
    }
}

export default Form.create({ name: 'workWithUs' })(WorkWithUs)
