import { good, must_hire, not_fit, not_good, not_sure } from '../../../assets/Icons/Feedback/InActiveIconsBundle'
import {
    good_active,
    must_hire_active,
    not_fit_active,
    not_good_active,
    not_sure_active,
} from '../../../assets/Icons/Feedback/ActiveIconsBundle'
import { Typography } from 'antd'

export const { Paragraph } = Typography

export const F_OVERALL_COLLECTION = [
    {
        name: 'not_fit',
        text: 'Not Fit',
        value: 1,
        inActiveIcon: not_fit,
        activeIcon: not_fit_active,
    },
    {
        name: 'not_good',
        text: 'Not Good',
        value: 2,
        inActiveIcon: not_good,
        activeIcon: not_good_active,
    },
    {
        name: 'not_sure',
        text: 'Not Sure',
        value: 3,
        inActiveIcon: not_sure,
        activeIcon: not_sure_active,
    },
    {
        name: 'good',
        text: 'Good',
        value: 4,
        inActiveIcon: good,
        activeIcon: good_active,
    },
    {
        name: 'must_hire',
        text: 'Must Hire',
        value: 5,
        inActiveIcon: must_hire,
        activeIcon: must_hire_active,
    },
]

export const MODAL_TYPE = {
    MOVE_JOB: 'MOVE_JOB',
    CHANGE_JOB: 'CHANGE_JOB',
    UPDATE_CANDIDATE_STATUS: 'UPDATE_CANDIDATE_STATUS',
    UNJOIN: 'UNJOIN',
}
