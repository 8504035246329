import React, { useState } from 'react'
import styled from 'styled-components'
import { GREY, DARK_BLUE } from '../../Helpers/Stylesheet/styledComponents'

const ComponentStyle = styled.pre`
    position: relative;
    font-family: sanFrancisco;
    margin-bottom: 0px;
    ${({ fontSize, fontColor, rows, isCollapsed }) => `
        color: ${fontColor};
        font-size: ${fontSize}px;
        line-height: ${fontSize + 4}px;
        ${isCollapsed ? `height: ${(fontSize + 4) * rows}px; overflow: hidden;` : ''};
    `};
    word-wrap: break-word;
    white-space: pre-wrap;

    .suffix-text {
        position: ${({ isCollapsed }) => (isCollapsed ? 'absolute' : 'relative')};
        width: 100%;
        color: ${DARK_BLUE};
        bottom: 0;
        z-index: 100;
        text-align: right;
        font-size: 12px;
    }

    ${({ isCollapsed, fontSize }) =>
        isCollapsed
            ? `::after {
            content: "";           
            position: absolute;            
            bottom: 0;
            right: 0;
            width: 100%;
            height: ${fontSize * 2}px;            
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) );
    }`
            : ''};
`

const Paragraph = ({
    rows = 2,
    expandText = 'Show More',
    collapseText = 'Show Less',
    content,
    children,
    fontSize = 12,
    fontColor = GREY,
    wordLimit = 200,
}) => {
    const newLines = children.split('\n').length
    const shouldCollapse = children.length > wordLimit || newLines > 2
    const [isCollapsed, setIsCollapsed] = useState(shouldCollapse)
    return (
        <ComponentStyle
            /* collapse should happen only when collapseText is clicked*/
            onClick={() => isCollapsed && setIsCollapsed(false)}
            isCollapsed={isCollapsed}
            expandText={expandText}
            collapseText={collapseText}
            rows={rows}
            fontSize={fontSize}
            fontColor={fontColor}
        >
            {content || children}
            {shouldCollapse ? (
                <div className="suffix-text" onClick={() => !isCollapsed && setIsCollapsed(true)}>
                    {isCollapsed ? expandText : collapseText}
                </div>
            ) : null}
        </ComponentStyle>
    )
}

export default Paragraph
