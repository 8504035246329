import React from 'react'
import { EmptyWrapper } from './style'
import './style.css'
import { Icon, Button } from 'antd'
import { ReactComponent as EmptyIcon } from '../../../assets/Icons/Openings/openings-empty.svg'

export default class NoOpening extends React.Component {
    state = {}

    goToOpenings = () => {
        this.props.history.push('openings/createJob')
    }

    render() {
        return (
            <EmptyWrapper>
                <Icon component={EmptyIcon} className="opening-empty" />
                <div className="empty-opening-wrapper no-openings-added">No Opening Added</div>
                <div className="lets-get-started-by">Let’s get started by creating job opening</div>
                <Button type="primary" className="no-openings-add-btn" onClick={this.goToOpenings}>
                    ADD OPENING
                </Button>
            </EmptyWrapper>
        )
    }
}
