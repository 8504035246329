import React, { useState } from 'react'
import { Aside, Block, SubTitle } from '../../Sourcing/style'
import { Spin } from 'antd'
import { ReactComponent as NoEmailsIcon } from '../../../../assets/Icons/Openings/no-emails.svg'
import { connect } from 'react-redux'
import FilterBox from '../Components/FilterBox'
import RefineBox from '../Components/RefineBox'
import { EVENTS } from '../Components/constants'

const FilterSection = ({
    handleFilterUpdates,
    callInProgress,
    filterCount,
    userEmail,
    mainFilter,
    stageId,
    jobId,
    status,
    allJobs,
    selectedFilter,
}) => {
    return (
        <div className="sidebar">
            <SubTitle className="sub-title">Filter by</SubTitle>
            <Spin spinning={callInProgress}>
                <Aside full>
                    <Block>
                        <div className="inbox-title">
                            <NoEmailsIcon />
                            <div>
                                <span>Inbox</span> <p>{userEmail}</p>
                            </div>
                        </div>
                    </Block>
                    <FilterBox
                        onFilterSelected={data => handleFilterUpdates(EVENTS.FILTER, data)}
                        filterCount={filterCount}
                        mainFilter={mainFilter}
                    />
                    <RefineBox
                        onFilterSelected={handleFilterUpdates}
                        stageId={stageId}
                        jobId={jobId}
                        status={status}
                        allJobs={allJobs}
                        selectedFilter={selectedFilter}
                    />
                </Aside>
            </Spin>
        </div>
    )
}

const mapStateToProps = ({
    Inbox: { filterCount, mainFilter, callInProgress, stageId, jobId, status, selectedFilter },
    Auth,
    Job: { allJobs },
}) => ({
    userEmail: Auth.user.email,
    filterCount,
    mainFilter,
    callInProgress,
    stageId,
    jobId,
    status,
    allJobs,
    selectedFilter,
})

export default connect(
    mapStateToProps,
    {}
)(FilterSection)
