import React from 'react'
import { Icon, Rate, Button } from 'antd'
import './style.css'
import StarIcon from '../../../assets/Icons/Talent Pool/Star'
import EditIcon from '../../../assets/Icons/AccountSettingPage/Edit Icon'
import moment from 'moment'
import { store } from '../../../redux/store'
import { CandidateProfileContainer, FeedBackWrapper } from './style'
import { FeedBackLoader } from './Helper/Loaders'
import { F_OVERALL_COLLECTION, Paragraph } from '../../Helpers/constants'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import { Modal } from '../../Modals'

export default class FeedBack extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            feedbacks: props.feedbacks,
            userId: store.getState().Auth.user.id,
            showFeedbackModal: false,
            feedbackDefaultData: null,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.feedbacks !== this.state.feedbacks) {
            this.setState({ feedbacks: nextProps.feedbacks })
        }
    }

    toggleFeedbackEdit = feedback => {
        const feedbackDefaultData = {
            data: {
                communication: feedback.communication,
                attitude: feedback.attitude,
                learningPotential: feedback.learningPotential,
                technicalSkills: feedback.technicalSkills,
                overallPoints: feedback.overallPoints,
                overallFeedback: feedback.overallFeedback,
                overrideCustomSkills: feedback.customFields,
            },
            id: feedback._id,
        }

        this.setState({ showFeedbackModal: true, feedbackDefaultData })
    }

    handleCancel = () => {
        this.setState({ showFeedbackModal: false, feedbackDefaultData: null })
    }

    feedbackUpdated = feedback => {
        this.props.updateFeedback(feedback)
        this.handleCancel()
    }

    render() {
        const { feedbacks, userId, showFeedbackModal, feedbackDefaultData } = this.state
        const { isLoading, candidateName } = this.props

        return (
            <CandidateProfileContainer>
                <div className="Feedback_right-wrapper">
                    <h2 className="feedback_container_title">Feedback: </h2>
                    {isLoading ? (
                        <>
                            <FeedBackWrapper>
                                <FeedBackLoader />
                            </FeedBackWrapper>
                            <FeedBackWrapper>
                                <FeedBackLoader />
                            </FeedBackWrapper>
                        </>
                    ) : !feedbacks.length ? (
                        <div className="feedback_empty_textInfo">
                            <div className="feedback_header-text">
                                No feedback has been submitted for this candidate yet.
                            </div>
                            <div className="feedback_button">
                                <Button type="primary" className="feedback-btn" onClick={this.props.sendFeedback}>
                                    Add Feedback
                                </Button>
                            </div>
                        </div>
                    ) : (
                        feedbacks.map((feedback, index) => (
                            <div className="block-wrapper" key={feedback._id}>
                                <div className="block-feedback-list">
                                    <div className="avf-header-container">
                                        <div className="avf-title">
                                            {index + 1}. From{' '}
                                            {feedback.interviewerName || feedback.interviewerEmail || 'DoSelect'}
                                        </div>
                                        <div className="avf-subtitle-cont">
                                            <div className="avf-stagename">{feedback.stageName}</div>
                                            <div className="avf-stagename-divider">|</div>
                                            <div className="avf-jobtitle">{feedback.jobTitle}</div>
                                            <div className="avf-stagename-divider">|</div>
                                            <div className="avf-date">
                                                {moment(feedback.updatedAt).format('DD MMM')}
                                            </div>
                                        </div>
                                        {feedback.userId && userId === feedback.userId._id && !feedback.noShow ? (
                                            <Icon
                                                component={EditIcon}
                                                className="cpf-edit-icon"
                                                onClick={() => this.toggleFeedbackEdit(feedback)}
                                            />
                                        ) : null}
                                    </div>
                                    {feedback.noShow ? (
                                        <div className="avf-content-wrapper">
                                            <div className="avf-content-feedback">
                                                {feedback.overallFeedback ? (
                                                    <Paragraph
                                                        className="avf-overallfeedback"
                                                        ellipsis={{ rows: 5, expandable: true }}
                                                    >
                                                        {feedback.overallFeedback}
                                                    </Paragraph>
                                                ) : null}
                                                <Paragraph style={{ color: 'red' }}>
                                                    Candidate didn't show up for interview..
                                                </Paragraph>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="avf-content-wrapper">
                                            <div className="avf-content-feedback">
                                                <Paragraph
                                                    className="avf-overallfeedback"
                                                    ellipsis={{ rows: 5, expandable: true }}
                                                >
                                                    {feedback.overallFeedback}
                                                </Paragraph>
                                                <br />
                                                <br />
                                            </div>

                                            <div className="avf-content-rating">
                                                <div className="avf-rate-block">
                                                    <span className="avf-skills">Communication:</span>
                                                    <span className="avf-rate">
                                                        <Rate
                                                            character={<Icon component={StarIcon} />}
                                                            className="avf-feedback-star"
                                                            disabled
                                                            value={feedback.communication}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="avf-rate-block">
                                                    <span className="avf-skills">Attitude:</span>
                                                    <span className="avf-rate">
                                                        <Rate
                                                            character={<Icon component={StarIcon} />}
                                                            className="avf-feedback-star"
                                                            disabled
                                                            value={feedback.attitude}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="avf-rate-block">
                                                    <span className="avf-skills">Potential To Learn:</span>
                                                    <span className="avf-rate">
                                                        <Rate
                                                            character={<Icon component={StarIcon} />}
                                                            className="avf-feedback-star"
                                                            disabled
                                                            value={feedback.learningPotential}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="avf-rate-block">
                                                    <span className="avf-skills">Technical Skills:</span>
                                                    <span className="avf-rate">
                                                        <Rate
                                                            character={<Icon component={StarIcon} />}
                                                            className="avf-feedback-star"
                                                            disabled
                                                            value={feedback.technicalSkills}
                                                        />
                                                    </span>
                                                </div>
                                                {feedback.customFields &&
                                                    feedback.customFields.map(each => {
                                                        if (!each.ans) return null
                                                        return (
                                                            <div className="avf-rate-block">
                                                                <span className="avf-skills">{each.name}:</span>
                                                                <span className="avf-rate">
                                                                    <Rate
                                                                        character={<Icon component={StarIcon} />}
                                                                        className="avf-feedback-star"
                                                                        disabled
                                                                        value={each.ans}
                                                                    />
                                                                </span>
                                                            </div>
                                                        )
                                                    })}
                                            </div>

                                            {feedback.overallPoints ? (
                                                feedback.overallPoints >= 1 ? (
                                                    <div className="avf-content-opinion">
                                                        <Icon
                                                            className="avf-content-opinion-icon"
                                                            component={
                                                                F_OVERALL_COLLECTION[feedback.overallPoints - 1]
                                                                    .activeIcon
                                                            }
                                                        />
                                                        <p className="active-text">
                                                            {F_OVERALL_COLLECTION[feedback.overallPoints - 1].text}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className="avf-content-opinion">
                                                        <Icon
                                                            className="avf-content-opinion-icon"
                                                            component={F_OVERALL_COLLECTION[0].inActiveIcon}
                                                        />
                                                        <p className="active-text">{F_OVERALL_COLLECTION[0].text}</p>
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    )}

                    <Modal
                        type={MODAL_TYPES.ADD_FEEDBACK}
                        visible={showFeedbackModal}
                        feedbackId={feedbackDefaultData && feedbackDefaultData.id}
                        defaultFeedback={feedbackDefaultData && feedbackDefaultData.data}
                        overrideCustomSkills={feedbackDefaultData && feedbackDefaultData.data.overrideCustomSkills}
                        candidateName={candidateName}
                        onCancel={this.handleCancel}
                        onConfirm={this.feedbackUpdated}
                    />
                </div>
            </CandidateProfileContainer>
        )
    }
}
