import styled from 'styled-components'
import {
    BLACK,
    GREY,
    WHITE,
    DARK_BLUE,
    PALE_BLUE,
    BORDER_COLOR,
    LIGHT_GREY,
    RED,
} from '../../Helpers/Stylesheet/styledComponents'
import Oval from '../../../assets/Icons/Openings/oval.svg'
import Circle from '../../../assets/Icons/Openings/circle.svg'

export const Wrapper = styled.div`
    max-width: 1280px;
    margin: auto;
`

export const Header = styled.div`
    background: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100px;
    padding: 0 40px;
    font-size: 18px;
    color: ${BLACK};
    .title {
        font-family: sanFranciscoMedium;
        font-weight: bold;
    }
    .breadcrumb {
        color: ${LIGHT_GREY};
        font-size: 12px;
        span {
            color: ${DARK_BLUE};
            cursor: pointer;
        }
        &.disabled {
            opacity: 0.2;
        }
    }
`
export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
    margin: 40px;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    position: relative;
    min-height: 700px;
    &:after {
        content: '';
        border-left: 1px solid ${BORDER_COLOR};
        height: 100%;
        position: absolute;
        left: 50%;
        top: 0;
    }
    .info-wrapper {
        .title {
            font-family: sanFranciscoMedium;
            font-size: 18px;
            color: ${BLACK};
            margin-bottom: 5px;
        }
        .desc {
            font-size: 14px;
            color: ${GREY};
            list-style: none;
            padding-left: 0;
            li {
                margin-bottom: 15px;
                display: flex;
                &:before {
                    content: '';
                    display: inline-block;
                    height: 1em;
                    width: 1em;
                    background-image: url(${Oval});
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-right: 0.5em;
                    flex-shrink: 0;
                    margin-top: 3px;
                }
            }
            ul {
                font-size: 12px;
                padding-left: 20px;
                li:before {
                    background-image: url(${Circle});
                    font-size: 7px;
                    vertical-align: baseline;
                    margin-right: 1em;
                    margin-top: 6px;
                }
            }
        }
        a {
            font-size: 14px;
            font-weight: 600;
            color: ${DARK_BLUE};
            font-family: sanFranciscoSemi;
            margin-top: 10px;
            background: none;
            border: none;
            padding: 0;
        }
    }
    .status {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        font-size: 16px;
        color: ${LIGHT_GREY};
        text-align: center;
        &.img {
            align-items: center;
        }
        > div {
            display: flex;
            align-items: center;
            :first-child {
                margin-bottom: 50px;
            }
            :nth-child(2) {
                margin-bottom: 35px;
                path {
                    fill: ${RED};
                }
            }
            div {
                margin-left: 10px;
            }
        }
        span {
            color: ${DARK_BLUE};
            cursor: pointer;
        }
        a {
            color: ${DARK_BLUE};
            cursor: pointer;
        }
        .title {
            font-size: 16px;
            margin-top: 10px;
        }
    }
`
export const Item = styled.div`
    display: flex;
    margin-bottom: 30px;
    svg {
        margin-right: 10px;
        flex-shrink: 0;
    }
`

export const FormWrapper = styled.div`
    .drop-header {
        margin-top: 30px;

        &-zone {
            height: 112px;
        }

        &-title {
            font-family: sanFranciscoMedium;
            font-size: 14px;
            line-height: 16px;
            color: ${BLACK};
            margin-bottom: 10px;
        }

        &-select .ant-select-selection-selected-value {
            font-size: 14px;
        }

        &-error {
            display: flex;
            flex-direction: row;

            &-text {
                margin-top: 7px;
                margin-left: 5px;
                color: ${RED};
            }
        }

        &.drop-display {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .drop-zone {
        &-file {
            width: 50px;
            height: 50px;
            background-color: ${WHITE};
            border-radius: 5px;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-cross {
            width: 12px;
            height: 12px;
            background-color: ${WHITE};
            border-radius: 25px;
            margin-top: -6px;
            margin-left: -6px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-flex-column {
            flex-direction: column;
        }

        &-flex-row {
            display: flex;
            flex-direction: row;
        }

        &-file-name {
            font-size: 12px;
            margin-top: 5px;
            color: ${BLACK};
        }
    }

    .help {
        margin-top: 10px;
        font-size: 12px;
        color: ${LIGHT_GREY};
    }
`
