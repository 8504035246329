import styled from 'styled-components'

export const ContentParagraph = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    margin-bottom: 5px;

    .para {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        margin: 7px 0 30px 0;
    }
`
