import React, { Component } from 'react'
import { Input, Form, Icon } from 'antd'
import { Header, Footer } from '../common'
import { ChangePassElementWrapper } from '../style'
import { ContentWrapper } from '../style'
import { ReactComponent as ErrorIcon } from '../../../assets/Icons/AccountSettingPage/Error_Icon.svg'
import PasswordIcon from '../../../assets/Icons/Auth/Password'
import './style.scss'

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            oldPassWarning: false,
            newPassWarning: false,
            newPassMsg: null,
            confirmPassWarning: false,
            oldPasswordType: 'password',
            newPasswordType: 'password',
        }
    }

    confirmPasswordValidator = (rule, value, callback) => {
        const { getFieldValue, setFields, setFieldsValue } = this.props.form
        const newPassword = getFieldValue('newPassword')
        const confirmPassword = getFieldValue('confirmPassword')
        if (confirmPassword && confirmPassword !== newPassword) {
            setFields({
                newPassword: {
                    value: newPassword,
                    errors: [new Error()],
                },
                confirmPassword: {
                    value: confirmPassword,
                    errors: [new Error()],
                },
            })
            this.setState({ confirmPassWarning: true })
        } else {
            setFieldsValue({ newPassword, confirmPassword })
            this.setState({ confirmPassWarning: false })
        }
    }

    oldPasswordValidator = (rule, value, callback) => {
        const { getFieldValue, setFields, setFieldsValue } = this.props.form
        const oldPassword = getFieldValue('oldPassword')
        const newPassword = getFieldValue('newPassword')

        if (oldPassword) {
            if (oldPassword === newPassword) {
                setFields({
                    newPassword: {
                        value: newPassword,
                        errors: [new Error()],
                    },
                })
                setFieldsValue({ oldPassword })
                this.setState({
                    oldPassWarning: false,
                    newPassWarning: true,
                    newPassMsg: 'Current and new password cannot be same',
                })
                return
            } else if (this.state.newPassMsg === 'Current and new password cannot be same') {
                if (newPassword.length < 6) {
                    setFields({
                        newPassword: {
                            value: newPassword,
                            errors: [new Error()],
                        },
                    })
                    setFieldsValue({ oldPassword })
                    this.setState({
                        oldPassWarning: false,
                        newPassWarning: true,
                        newPassMsg: 'Password must contain atleast 6 characters',
                    })
                    return
                } else {
                    setFieldsValue({ oldPassword, newPassword })
                    this.setState({ oldPassWarning: false, newPassWarning: false, newPassMsg: null })
                    return
                }
            } else {
                setFieldsValue({ oldPassword })
                this.setState({ oldPassWarning: false })
                return
            }
        } else {
            setFields({
                oldPassword: {
                    value: oldPassword,
                    errors: [new Error()],
                },
            })
            this.setState({ oldPassWarning: true })
            return
        }
    }

    validateToNextPassword = () => {
        const { getFieldValue, setFields, setFieldsValue } = this.props.form
        const oldPassword = getFieldValue('oldPassword')
        const newPassword = getFieldValue('newPassword')

        if (oldPassword === newPassword) {
            setFields({
                newPassword: {
                    value: newPassword,
                    errors: [new Error()],
                },
            })
            this.setState({ newPassWarning: true, newPassMsg: 'Current and new password cannot be same' })
            return
        } else if (newPassword.length < 6) {
            setFields({
                newPassword: {
                    value: newPassword,
                    errors: [new Error()],
                },
            })
            this.setState({ newPassWarning: true, newPassMsg: 'Password must contain atleast 6 characters' })
            return
        } else {
            if (!this.state.confirmPassWarning) setFieldsValue({ newPassword })
            this.setState({ newPassWarning: false, newPassMsg: null })
            return
        }
    }

    newPasswordValidator = (rule, value, callback) => {
        const { getFieldValue, setFields, setFieldsValue } = this.props.form
        const oldPassword = getFieldValue('oldPassword')
        const newPassword = getFieldValue('newPassword')
        const confirmPassword = getFieldValue('confirmPassword')

        if (newPassword) {
            if (confirmPassword && newPassword !== confirmPassword) {
                setFields({
                    newPassword: {
                        value: newPassword,
                        errors: [new Error()],
                    },
                    confirmPassword: {
                        value: confirmPassword,
                        errors: [new Error()],
                    },
                })
                this.setState({ confirmPassWarning: true }, () => this.validateToNextPassword())
            } else {
                setFieldsValue({ newPassword, confirmPassword })
                this.setState({ confirmPassWarning: false }, () => this.validateToNextPassword())
            }
        } else {
            setFields({
                newPassword: {
                    value: newPassword,
                    errors: [new Error()],
                },
            })
            this.setState({ newPassWarning: true, newPassMsg: 'New password required' })
            return
        }
    }

    changePassword = async () => {
        this.oldPasswordValidator()
        this.newPasswordValidator()
        this.confirmPasswordValidator()
        const { oldPassWarning, newPassWarning, confirmPassWarning } = this.state
        const error = oldPassWarning || newPassWarning || confirmPassWarning
        const { getFieldValue } = this.props.form
        const oldPassword = getFieldValue('oldPassword'),
            newPassword = getFieldValue('newPassword'),
            confirmPassword = getFieldValue('confirmPassword')
        const values = {
            password: oldPassword,
            newPassword,
            confirmPassword,
        }

        if (!error) {
            this.props.onConfirm({ values })
        }
    }

    visibility = (field, value) => {
        if (field === 'oldPassword') {
            if (value === 'password') this.setState({ oldPasswordType: 'text' })
            else this.setState({ oldPasswordType: 'password' })
        } else {
            if (value === 'password') this.setState({ newPasswordType: 'text' })
            else this.setState({ newPasswordType: 'password' })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const {
            oldPassWarning,
            newPassWarning,
            newPassMsg,
            confirmPassWarning,
            oldPasswordType,
            newPasswordType,
        } = this.state
        return (
            <>
                <Header title="CHANGE PASSWORD" />
                <ContentWrapper>
                    <Form>
                        <ChangePassElementWrapper>
                            <Form.Item className={oldPassWarning ? 'pass-field-1' : 'pass-field-2'}>
                                {getFieldDecorator('oldPassword', {
                                    rules: [{ validator: this.oldPasswordValidator }],
                                })(
                                    <Input
                                        className="cp-input"
                                        type={oldPasswordType}
                                        placeholder="Enter old password"
                                        suffix={
                                            <Icon
                                                component={PasswordIcon}
                                                onClick={() => this.visibility('oldPassword', oldPasswordType)}
                                            />
                                        }
                                    />
                                )}
                            </Form.Item>
                            {oldPassWarning ? (
                                <div className="pass-error field-height">
                                    <ErrorIcon style={{ marginTop: '10px' }} />
                                    <p className="pass-error-msg">Old password is required</p>
                                </div>
                            ) : null}
                            <Form.Item className={newPassWarning ? 'pass-field-1' : 'pass-field-2'}>
                                {getFieldDecorator('newPassword', {
                                    rules: [
                                        {
                                            validator: this.newPasswordValidator,
                                        },
                                    ],
                                })(
                                    <Input
                                        className="cp-input"
                                        type={newPasswordType}
                                        placeholder="Enter new password"
                                        suffix={
                                            <Icon
                                                component={PasswordIcon}
                                                onClick={() => this.visibility('newPassword', newPasswordType)}
                                            />
                                        }
                                    />
                                )}
                            </Form.Item>
                            {newPassWarning ? (
                                <div className="pass-error field-height">
                                    <ErrorIcon style={{ marginTop: '10px' }} />
                                    <p className="pass-error-msg">{newPassMsg}</p>
                                </div>
                            ) : null}
                            <Form.Item className="pass-field-1">
                                {getFieldDecorator('confirmPassword', {
                                    rules: [
                                        {
                                            validator: this.confirmPasswordValidator,
                                        },
                                    ],
                                })(<Input className="cp-input" type="password" placeholder="Confirm password" />)}
                            </Form.Item>
                            {confirmPassWarning ? (
                                <div className="pass-error last-field-height">
                                    <ErrorIcon style={{ marginTop: '10px' }} />
                                    <p className="pass-error-msg">New and confirm password do not match</p>
                                </div>
                            ) : null}
                        </ChangePassElementWrapper>
                    </Form>
                </ContentWrapper>
                <Footer
                    onCancel={this.props.onCancel}
                    onConfirm={this.changePassword}
                    cancelText="CANCEL"
                    confirmText="SAVE"
                    hasSeparator
                />
            </>
        )
    }
}

export default Form.create({ name: 'password' })(ChangePassword)
