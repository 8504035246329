import React, { useState, useEffect } from 'react'
import { Tooltip } from 'antd'
import { PageContainer } from '../../../Components/StyledPages/PageContainer'
import { PAGE_SECTIONS } from '../../../../container/Helpers/Stylesheet/styleTypes'
import { StyledButton } from '../../../../container/Components'
import { ReactComponent as InfoIcon } from '../../../../assets/Icons/Interview/info.svg'
import { ReactComponent as CopyIcon } from '../../../../assets/Icons/Openings/copy.svg'
import { ReactComponent as FbIcon } from '../../../../assets/Icons/Openings/fb1.svg'
import { ReactComponent as LinkedinIcon } from '../../../../assets/Icons/Openings/linkedin1.svg'
import { ReactComponent as TwitterIcon } from '../../../../assets/Icons/Openings/twitter1.svg'
import { ReactComponent as EmailIcon } from '../../../../assets/Icons/Openings/email.svg'
import { SocialButton } from '../style'
import { utmBuilder } from '../../../Helpers/utils'
import { success } from '../../../../services/Notification'
import config from '../../../../config'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from 'react-share'

const { careerPageURL } = config.config()

const copyText = elementId => {
    const value = document.getElementById(elementId).innerHTML
    const textArea = document.createElement('textArea')
    textArea.value = value

    let message = ''
    if (elementId === 'copyUniqueEmailId') {
        message = `The unique email id “${value}” to apply for the job has been copied to clipboard.`
    } else if (elementId === 'copyReferalInput' || elementId === 'referralLink') {
        message = `The job link “${value}” to apply for the job has been copied to clipboard.`
    } else if (elementId === 'copyUniqueCareerPageUrl') {
        message = `The career page link "${value}" to visit career page has been copied to clipboard.`
    }

    if (message.length) {
        success(message)
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand('copy')
        document.body.removeChild(textArea)
    }
}

const Promote = ({ jobDetails, jobPromotion, history, user }) => {
    const port = window.location.port
    const uniqueReferal =
        jobDetails && jobDetails.uniqueReferal
            ? `${window.location.protocol}//${window.location.hostname}${port ? ':3000' : ''}/applynow/${jobDetails.uniqueReferal
            }`
            : null
    const uniqueReferralLink =
        jobDetails && jobDetails.uniqueReferal
            ? `${window.location.protocol}//${window.location.hostname}${port ? ':3000' : ''}/refernow/${jobDetails.uniqueReferal
            }`
            : null
    const { companyUniqueEmailId } = user
    const careerPageLink = careerPageURL(user.domain)

    const utm_defaults = { campaign: 'applyNow', medium: 'social' }
    const facebookShareUrl = `${uniqueReferal}?${utmBuilder({ ...utm_defaults, source: 'facebook' })}`
    const linkedInShareUrl = `${uniqueReferal}?${utmBuilder({ ...utm_defaults, source: 'linkedIn' })}`
    const twitterShareUrl = `${uniqueReferal}?${utmBuilder({ ...utm_defaults, source: 'twitter' })}`
    const emailShareUrl = `${uniqueReferal}?${utmBuilder({
        campaign: utm_defaults.campaign,
        medium: 'email',
        source: 'email',
    })}`

    let linkedInShareText = ''
    if (jobDetails && jobDetails.jobTitle)
        linkedInShareText = `We're hiring! #jobs #careers #${jobDetails.jobTitle.replace(/\s/g, '')}`

    return (
        <>
            <PageContainer title="PROMOTE" type={PAGE_SECTIONS.MAIN} empty />
            <PageContainer title="REFERRAL" type={PAGE_SECTIONS.SECTION}>
                <p>You can copy the Job application URL to share employee so that they can refer candidates</p>
                <p>
                    <span id="referralLink">{uniqueReferralLink}</span>
                    <Tooltip title="Copied" trigger="click">
                        <CopyIcon className="copy" onClick={() => copyText('referralLink')} />
                    </Tooltip>
                </p>
                {/* TODO: Need to do copy changes */}
                <p>You can copy the Job application URL to share or email employee so that they can refer candidates</p>
                <p>
                    <span id="copyReferalInput">{uniqueReferal}</span>
                    <Tooltip title="Copied" trigger="click">
                        <CopyIcon className="copy" onClick={() => copyText('copyReferalInput')} />
                    </Tooltip>
                </p>
            </PageContainer>
            <PageContainer title="COMPANY EMAIL ID" type={PAGE_SECTIONS.SECTION}>
                <p>
                    You can send candidate resumes to the unique company email id to add candidates to this job opening
                </p>
                <p>
                    <span id="copyUniqueEmailId">{companyUniqueEmailId}</span>
                    <Tooltip title="Copied" trigger="click">
                        <CopyIcon className="copy" onClick={() => copyText('copyUniqueEmailId')} />
                    </Tooltip>
                </p>
                <p>
                    <InfoIcon />
                    Remember to add the job opening name in the subject line of the email and we will add the candidate
                    to that job opening
                </p>
            </PageContainer>
            <PageContainer title="CAREER PAGE" type={PAGE_SECTIONS.SECTION}>
                <p>
                    This job opening has been added to your company's career page as well. To view all open positions
                    visit the following URL
                </p>
                <p>
                    <span id="copyUniqueCareerPageUrl">{careerPageLink}</span>
                    <Tooltip title="Copied" trigger="click">
                        <CopyIcon className="copy" onClick={() => copyText('copyUniqueCareerPageUrl')} />
                    </Tooltip>
                </p>
            </PageContainer>
            <PageContainer title="SHARE JOB ON SOCIAL MEDIA" type={PAGE_SECTIONS.SECTION}>
                <div className="social-buttons">
                    <FacebookShareButton url={facebookShareUrl} quote={`We're hiring! \n`} hashtag={'#careers'}>
                        <SocialButton bg={'#4968AD'} iconBg={'#3a5389'}>
                            <div className="icon">
                                <FbIcon />
                            </div>
                            <div className="text">Share on Facebook</div>
                        </SocialButton>
                    </FacebookShareButton>
                    <LinkedinShareButton
                        url={linkedInShareUrl}
                        disabled={false}
                        title={`We're hiring !`}
                        summary={`${linkedInShareText}`}
                    >
                        <SocialButton bg={'#3787CB'} iconBg={'#2b6ba2'}>
                            <div className="icon">
                                <LinkedinIcon />
                            </div>
                            <div className="text">Share on LinkedIn</div>
                        </SocialButton>
                    </LinkedinShareButton>
                    <TwitterShareButton
                        url={twitterShareUrl}
                        title={`We're hiring! \n`}
                        hashtags={[`jobs`, `careers`, `${jobDetails ? jobDetails.jobTitle : null}`]}
                    >
                        <SocialButton bg={'#51BAF2'} iconBg={'#4195c2'}>
                            <div className="icon">
                                <TwitterIcon />
                            </div>
                            <div className="text">Share on Twitter</div>
                        </SocialButton>
                    </TwitterShareButton>
                    <EmailShareButton
                        url={emailShareUrl}
                        subject={`${jobDetails ? `${jobDetails.companyName} is looking for ${jobDetails.jobTitle} role` : null
                            }`}
                        body={`We're hiring! \n`}
                    >
                        <SocialButton bg={'#F14336'} iconBg={'#c1362b'}>
                            <div className="icon">
                                <EmailIcon />
                            </div>
                            <div className="text">Share via Email</div>
                        </SocialButton>
                    </EmailShareButton>
                </div>
            </PageContainer>
            <div className="buttons">
                <StyledButton width="120px" height="40px" onClick={() => history.push('/openings')}>
                    GO TO OPENINGS
                </StyledButton>
            </div>
        </>
    )
}
export default Promote
