import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardCollapsible, Title, Message } from '../style'
import { ReactComponent as ExpandIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Btn.svg'
import { ReactComponent as CollapseIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand or Collapse Btn.svg'
import { ReactComponent as AttachIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/attach.svg'
import RemoveIcon from '../../../../assets/Icons/AccountSettingPage/Remove Button'
import DocIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/docx'
import XlsIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/xls'
import PdfIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/pdf'
import ImgIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/img'
import { Editor } from '@tinymce/tinymce-react'
import { Checkbox, Upload, Icon, Select, Input } from 'antd'
import { ReactComponent as EmailIcon } from '../../../../assets/Icons/Talent Pool/Email.svg'
import StyledButton from '../../../../container/Components/StyledButton/index.js'
import getAvatar from '../../../../services/getAvatar'
import config from '../../../../config'

export default class MessageBox extends Component {
    constructor(props) {
        super(props)

        this.state = {
            candidateId: props.candidate._id,
            candidateName: props.candidate.name,
            candidateEmail: props.candidate.email,
            emailSubject: 'Details required',
            emailContent: null,
            attachments: [],
            markedPrivate: false,
            showUserList: false,
            accessibleTo: [],
            userSearchText: '',
            disable: false,
        }
    }

    componentDidMount() {
        const { messageDetail } = this.props
        if (messageDetail) {
            this.setState({
                emailSubject: `Re - ${messageDetail.subject &&
                    messageDetail.subject.replace(new RegExp('Re - ', 'g'), '')}`,
                markedPrivate: messageDetail.privacy_options.private,
                accessibleTo: messageDetail.privacy_options.allowedToView || [],
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.messageDetail !== nextProps.messageDetail && nextProps.messageDetail) {
            this.setState({
                emailSubject: `Re - ${nextProps.messageDetail.subject &&
                    nextProps.messageDetail.subject.replace(new RegExp('Re - ', 'g'), '')}`,
                markedPrivate: nextProps.messageDetail.privacy_options.private,
                accessibleTo: nextProps.messageDetail.privacy_options.allowedToView || [],
            })
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleMessageChange = e => {
        this.setState({
            emailContent: e.target.getContent(),
        })
    }

    handlePrivateChange = e => {
        this.setState({ markedPrivate: e.target.checked, showUserList: false })
    }

    showUserList = () => {
        this.setState({ showUserList: true })
    }

    addUser = user => {
        const { accessibleTo } = this.state
        accessibleTo.push(user)
        this.setState({ accessibleTo, userSearchText: undefined })
    }

    removeUser = userId => {
        let { accessibleTo } = this.state
        accessibleTo = accessibleTo.filter(mem => mem !== userId)
        this.setState({ accessibleTo })
    }

    setFile = file => {
        this.setState(state => ({ attachments: [...state.attachments, file] }))
        return false
    }

    removeFile = file => {
        this.setState(state => {
            const index = state.attachments.indexOf(file)
            const newattachments = state.attachments.slice()
            newattachments.splice(index, 1)
            return {
                attachments: newattachments,
            }
        })
    }

    displayAttachment = file => {
        const { name, uid } = file
        const ext = name.split('.')[1]

        let icon = PdfIcon
        if (['xls', 'xlxs', 'excel', 'csv'].includes(ext)) {
            icon = XlsIcon
        } else if (['jpg', 'jpeg', 'png', 'svg'].includes(ext)) {
            icon = ImgIcon
        } else if (['doc', 'docx'].includes(ext)) {
            icon = DocIcon
        }

        return (
            <div className="message-files" key={uid}>
                <Icon component={icon} />
                <div className="sample-pdf">{name}</div>
                <Icon component={RemoveIcon} className="cpm-attachment-icon" onClick={() => this.removeFile(file)} />
            </div>
        )
    }

    handleSubmit = () => {
        let {
            emailSubject,
            emailContent,
            attachments,
            markedPrivate,
            accessibleTo,
            candidateId,
            candidateEmail,
        } = this.state
        if (!emailContent) return

        this.setState({ disable: true }, async () => {
            const data = {
                email_subject: emailSubject,
                email_content: emailContent,
                email_to: candidateEmail,
                attachments,
                privacy_options_private: markedPrivate,
                privacy_options_allowedToView: JSON.stringify({
                    ids: accessibleTo,
                }),
                universalCandidateId: candidateId,
            }
            await this.props.sendEmail(data)
            this.setState({ disable: false })
        })
    }

    render() {
        const { candidateName, candidateEmail, emailSubject, emailContent, attachments, disable } = this.state
        const { user, teamMembers } = this.props
        return (
            <Message active>
                <div className="content">
                    <div className="compose">
                        <div className="header">
                            <div className="flex">
                                <div className="avatar">{getAvatar(user.userName)}</div>
                                <div>
                                    <div className="name">{user.userName}</div>
                                    <div className="field">
                                        <label>From:</label>
                                        <label>{user.companyUniqueEmailId}</label>
                                    </div>
                                    <div className="field">
                                        <label>To:</label>
                                        <label>{candidateEmail}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <CollapseIcon />
                            </div>
                        </div>
                        <input
                            type="text"
                            name="emailSubject"
                            value={emailSubject}
                            className="subject"
                            onChange={this.handleChange}
                        />
                        <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                            value={emailContent}
                            onEditorChange={this.handleMessageChange}
                            // apiKey={`${config.config().tinyMCEApiKey}`}
                            init={{
                                menubar: false,
                                plugins: 'link image code autoresize lists',
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                            }}
                        />
                        <div className="buttons">
                            <Upload
                                multiple
                                beforeUpload={this.setFile}
                                showUploadList={false}
                                onRemove={this.removeFile}
                                fileList={this.state.attachments}
                            >
                                <StyledButton
                                    capitalize
                                    type="inverted"
                                    width={'125px'}
                                    height={'44px'}
                                    className="add-attachment"
                                >
                                    <Icon className="attachment-icon" type="paper-clip" />
                                    <span>Add attachment</span>
                                </StyledButton>
                            </Upload>
                            <div hidden={user.accessLevel > 2}>
                                <Checkbox checked={this.state.markedPrivate} onChange={this.handlePrivateChange}>
                                    <span className="cpm-mark-as-private">Mark as private</span>
                                    <Icon type="lock" theme="filled" />
                                </Checkbox>
                            </div>
                        </div>
                        {this.state.attachments.length ? (
                            <div className="cpm-mail-attach-file-block1">
                                {this.state.attachments.map(this.displayAttachment)}
                            </div>
                        ) : null}
                        {this.state.markedPrivate && user.accessLevel < 3 && (
                            <div className="cpm-private-cont">
                                <div className="cpm-private-message">
                                    Note: This message is only visible to you,
                                    {this.state.accessibleTo.map(mem => {
                                        let displayName = teamMembers.find(x => x._id === mem)
                                        displayName = displayName.fullName || displayName.email
                                        return (
                                            <React.Fragment key={mem}>
                                                <div className="an-nn-sel-user1 top-margin-7">
                                                    <div>{displayName}</div>
                                                    <Icon component={RemoveIcon} onClick={() => this.removeUser(mem)} />
                                                </div>
                                                , &nbsp;
                                            </React.Fragment>
                                        )
                                    })}
                                    {candidateName}
                                    &nbsp;, admins and super admins.
                                </div>
                                {!this.state.showUserList ? (
                                    <div className="add-more-people-who" onClick={this.showUserList}>
                                        Add more people who can view this note?
                                    </div>
                                ) : (
                                    <Select
                                        className="an-nn-search"
                                        placeholder="Search team member to add"
                                        notFoundContent={null}
                                        showSearch
                                        suffixIcon={<Icon type="search" />}
                                        defaultActiveFirstOption={false}
                                        onSelect={this.addUser}
                                        value={this.state.userSearchText}
                                        onSearch={value => this.setState({ userSearchText: value })}
                                        filterOption
                                        optionFilterProp="title"
                                    >
                                        {teamMembers
                                            .filter(
                                                mem =>
                                                    mem.status &&
                                                    mem._id !== user.id &&
                                                    [1, 2].includes(mem.accessLevel) &&
                                                    !this.state.accessibleTo.includes(mem._id)
                                            )
                                            .map(mem => (
                                                <Select.Option
                                                    key={mem._id}
                                                    className="an-nn-opt"
                                                    title={mem.fullName + ' ' + mem.email}
                                                >
                                                    <div className="an-nn-name">{mem.fullName || mem.email}</div>
                                                    <div className="an-nn-add">Add</div>
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </div>
                        )}
                        <div className="buttons">
                            <StyledButton
                                capitalize
                                width={'125px'}
                                height={'44px'}
                                disable={disable}
                                onClick={this.handleSubmit}
                            >
                                <span>Send</span>
                            </StyledButton>
                            <StyledButton
                                capitalize
                                type="inverted"
                                width={'125px'}
                                height={'44px'}
                                onClick={this.props.handleCancel}
                            >
                                <span>Cancel</span>
                            </StyledButton>
                        </div>
                    </div>
                </div>
            </Message>
        )
    }
}
