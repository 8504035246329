import React, { Component } from 'react'
import { Wrapper, Header, Title, SearchBox, Form, Body, Main, EmptyBox } from './style'
import { Empty, Spin } from 'antd'
import { ReactComponent as SearchIcon } from '../../../assets/Icons/Talent Pool/Search.svg'
import Filter from './Components/Filter'
import CandidateCard from './Components/CandidateCard'
import { CandidateCardLoader } from './Components/Loaders'
import { Modal } from '../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import {
    getApplications,
    UpdateUniversalCandidate,
    AddNote,
    assignToJob,
    setApiProcess,
    UpdateSourcingTags,
} from '../../../services/NetworkCalls/SourcingTalentPool'
import BulkBox from './Components/BulkBox'
import { EVENTS, FILTER_TYPES } from './constants'
import { filterBulkActionsIds } from './utilityFunctions'

class SourcingTalentPool extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCandidate: {},
            selectionSize: 0,
            dataFilters: {},
            bulkMode: false,
            queryString: '',
        }
    }

    selectCandidate = data => {
        let { selectedCandidate, selectionSize } = this.state

        if (data.action === 'ALL') {
            if (data.checked) {
                this.props.applications.forEach(app => (selectedCandidate[app._id] = app))
                selectionSize = this.props.applications.length
            } else {
                selectedCandidate = {}
                selectionSize = 0
            }
        } else if (selectedCandidate.hasOwnProperty(data._id)) {
            delete selectedCandidate[data._id]
            selectionSize--
        } else {
            selectedCandidate[data._id] = data
            selectionSize++
        }
        this.setState({ selectedCandidate, selectionSize })
    }

    bulkModeOff = pauseModeChange => {
        this.setState({
            selectedCandidate: {},
            selectionSize: 0,
            ...(!pauseModeChange && { bulkMode: false }),
        })
    }

    bulkEventManager = (type, payload) => {
        const { mainFilter, UpdateUniversalCandidate, assignToJob, allJobs } = this.props
        const { selectedCandidate } = this.state
        const { ids, totalCount } = filterBulkActionsIds(selectedCandidate, type)

        const emitUpdate = data => {
            if (type === EVENTS.ADD_JOB) {
                const selectedJob = allJobs.find(job => job._id === data.jobId)
                const selectedStage = selectedJob.stages.find(stage => stage._id === data.stageId)
                const payload = {
                    jobTitle: selectedJob.jobTitle,
                    jobId: data.jobId,
                    stageName: selectedStage.stageName,
                    stageId: data.stageId,
                    universalCandidateIds: ids,
                    name: selectedCandidate[ids[0]].name,
                }
                assignToJob(payload)
            } else if (type === EVENTS.UPDATE_TAG) this.props.UpdateSourcingTags(payload)
            else {
                const paramData = { update: type, data, ids, totalCount }
                if (ids.length === 1) paramData.name = selectedCandidate[ids[0]].name
                UpdateUniversalCandidate(paramData)
            }
        }

        switch (type) {
            case EVENTS.ADD_JOB:
                emitUpdate(payload)
                break
            case EVENTS.HIDE_CANDIDATE:
                emitUpdate(mainFilter !== FILTER_TYPES.HIDDEN)
                break
            case EVENTS.MARK_FAV:
                emitUpdate(mainFilter !== FILTER_TYPES.FAVOURITE)
                break
            case EVENTS.UPDATE_TAG:
                emitUpdate(payload)
                break
        }

        this.bulkModeOff()
    }

    getNewPage = () => {
        const { currentPage, mainFilter, getApplications } = this.props
        const {
            dataFilters: {
                assigned_jobs = [],
                skills = [],
                interested_jobs = [],
                locations = [],
                relocation = null,
                tags = [],
                query = '',
            },
        } = this.state

        getApplications({
            assigned_jobs,
            skills,
            interested_jobs,
            locations,
            relocation,
            tags,
            main_filter: mainFilter,
            currentPage: currentPage + 1,
            query,
        })
    }

    componentDidMount() {
        const initialFilter = {
            relocation: null,
            interested_jobs: [],
            main_filter: FILTER_TYPES.ALL,
        }
        this.props.getApplications(initialFilter)
    }

    updateParentState = data => {
        this.bulkModeOff(true)
        this.setState(state => ({ dataFilters: { ...state.dataFilters, ...data } }))
    }

    handleSearch = query => {
        let tagsQuery = { tags: [] }
        if (query && query.trim().length > 0 && this.props.tags.length > 0) {
            const tag = this.props.tags.find(({ name }) => {
                return name.toLowerCase() === query.toLowerCase()
            })
            if (tag) {
                tagsQuery.tags = [tag.tagId]
            }
        }
        const { mainFilter, getApplications, setApiProcess } = this.props
        const {
            dataFilters: {
                assigned_jobs = [],
                skills = [],
                interested_jobs = [],
                locations = [],
                relocation = null,
                tags = [],
            },
        } = this.state
        setApiProcess()
        getApplications({
            assigned_jobs,
            skills,
            interested_jobs,
            locations,
            relocation,
            tags: tagsQuery.tags.length ? tagsQuery.tags : tags,
            main_filter: mainFilter,
            currentPage: 1,
            query: tagsQuery.tags.length ? '' : query,
            dataChange: true,
        })
        this.updateParentState({ query })
    }

    render() {
        const {
            bulkMode,
            selectedCandidate,
            selectionSize,
            dataFilters: { query },
            queryString,
        } = this.state
        const {
            applications,
            countData,
            callInProgress,
            mainFilter,
            UpdateUniversalCandidate,
            AddNote,
            assignToJob,
            hasNextPage,
            allJobs,
        } = this.props
        return (
            <Wrapper>
                <Header>
                    <Title>Sourcing Talent Pool</Title>
                    <Form>
                        <SearchBox
                            placeholder="Search candidates based on skills, role.."
                            onSearch={this.handleSearch}
                            onChange={({ target: { value } }) => {
                                if (queryString && queryString.length && value.length === 0) this.handleSearch(value)
                                this.setState({ queryString: value })
                            }}
                        />
                        <button onClick={() => this.handleSearch(queryString)}>
                            <SearchIcon />
                        </button>
                    </Form>
                </Header>
                <Body>
                    <Filter updateParent={this.updateParentState} query={query} callInProgress={callInProgress} />
                    {callInProgress ? (
                        <Main style={{ marginTop: '70px' }}>
                            <CandidateCardLoader />
                            <CandidateCardLoader />
                        </Main>
                    ) : (
                        <Main>
                            <BulkBox
                                applicationSize={applications.length}
                                mainFilter={mainFilter}
                                bulkMode={bulkMode}
                                selectionSize={selectionSize}
                                countData={countData}
                                bulkEventManager={this.bulkEventManager}
                                bulkModeOff={this.bulkModeOff}
                                selectCandidate={this.selectCandidate}
                                selectedCandidate={selectedCandidate}
                                bulkModeOn={() => this.setState({ bulkMode: true })}
                            />
                            {applications.length ? (
                                <InfiniteScroll loadMore={this.getNewPage} hasMore={hasNextPage}>
                                    {applications.map((app, index) => (
                                        <CandidateCard
                                            key={app._id + index.toString()} // todo: remove index.toString in dev deploy
                                            data={app}
                                            selectCandidate={this.selectCandidate}
                                            updateUniversalCandidate={UpdateUniversalCandidate}
                                            addNote={AddNote}
                                            assignToJob={assignToJob}
                                            updateTags={payload => this.props.UpdateSourcingTags(payload)}
                                            allJobs={allJobs}
                                            {...this.state}
                                        />
                                    ))}
                                </InfiniteScroll>
                            ) : (
                                <EmptyBox>
                                    <Empty description="You have zero candidates in sourcing talent pool" />
                                </EmptyBox>
                            )}
                            {applications.length && hasNextPage ? <Spin className="spin-loader-center1" /> : null}
                        </Main>
                    )}
                </Body>
            </Wrapper>
        )
    }
}

const mapStateToProps = state => {
    const {
        Auth,
        Job: { allJobs },
        Sourcing: {
            applications,
            countData,
            skills,
            interestedJobs,
            callInProgress,
            mainFilter,
            hasNextPage,
            currentPage,
            tags,
        },
    } = state

    return {
        companyId: Auth.user.companyId,
        allJobs,
        applications: applications || [],
        countData,
        skills,
        interestedJobs,
        callInProgress,
        mainFilter,
        currentPage,
        hasNextPage,
        tags,
    }
}

export default connect(
    mapStateToProps,
    { getApplications, UpdateUniversalCandidate, AddNote, assignToJob, setApiProcess, UpdateSourcingTags }
)(SourcingTalentPool)
