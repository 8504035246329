export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const NOTICE_PERIOD_OPTIONS = [
    'Serving now',
    'Can join now',
    '10 Days',
    '11-30 Days',
    '1 Month',
    '2 Months',
    '3 Months',
    '> 3 Months',
]
export const DEFAULT_CANDIDATE_SETTINGS = {
    name: true,
    email: true,
    phone: true,
    currentCompanyName: false,
    currentLocation: false,
    totalExperience: false,
    noticePeriod: false,
}
