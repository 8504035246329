import { AutoComplete, Button, Checkbox, Empty, Input, Select, Tag, Tooltip } from 'antd'
import { store } from '../../../redux/store'
import React from 'react'
import { OpeningCardWrapper } from './style'
import ContentLoader from 'react-content-loader'
import { ARCHIVE_REASONS } from '../../Helpers/constants'

const { Search } = Input

// ===== Algolia Loaders ====
const TalentPoolCardLoader = () => (
    <ContentLoader height={150} width={660} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x="12" y="0" rx="10" ry="10" width="50" height="50" />
        <rect x="83" y="4" rx="3" ry="3" width="40%" height="17" />
        <rect x="83" y="35" rx="3" ry="3" width="25%" height="12" />
        <rect x="83" y="60" rx="3" ry="3" width="25%" height="12" />
        <rect x="83" y="85" rx="3" ry="3" width="25%" height="12" />
        <rect x="40%" y="85" rx="3" ry="3" width="25%" height="12" />
    </ContentLoader>
)
// =========================

export const SearchBox = ({ currentRefinement, refine, reference, handleFocus }) => (
    <Search
        placeholder="Search candidates based on name, email, company, location, etc."
        value={currentRefinement}
        onChange={e => refine(e.currentTarget.value)}
        ref={reference}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
    />
)

export const LocationSearchBox = ({ currentRefinement, refine, hits }) => (
    <div className="talentpool-card-subtitle-text">
        <Search
            size="small"
            placeholder="Enter Location"
            value={currentRefinement}
            onChange={e => refine(e.currentTarget.value)}
        />
    </div>
)

export const AutocompleteBox = ({ hits, currentValue, setValue }) => {
    return (
        <AutoComplete
            className="w-100"
            placeholder="Enter location"
            value={currentValue}
            onSelect={console.log}
            dataSource={hits
                .filter(x => !!x.location)
                .map(x => x.location)
                .filter((x, i, s) => s.indexOf(x) === i)
                .map(location => (
                    <Select.Option key={location}>{location}</Select.Option>
                ))}
        />
    )
}

export const SourceViaBox = ({ items, currentRefinement, refine }) => (
    <div style={{ position: 'relative' }}>
        <Select
            className="w-100 mb-9"
            value={currentRefinement || ''}
            onChange={e => refine(e)}
            placeholder="Sourced Via"
            getPopupContainer={trigger => trigger.parentNode}
        >
            <Select.Option value="">All</Select.Option>
            {items.map(item => (
                <Select.Option key={item.label} value={item.value}>
                    {item.label}
                </Select.Option>
            ))}
        </Select>
    </div>
)

export const SourceByBox = ({ items, currentRefinement, refine }) => {
    const { teamMembers } = store.getState().Mixed
    return (
        <div style={{ position: 'relative' }}>
            <AutoComplete
                className="w-100 mb-18"
                placeholder="Sourced By"
                value={currentRefinement}
                onChange={e => refine(e)}
                onSelect={e => refine(e)}
                dataSource={items.map(item => {
                    const mem = teamMembers.find(x => [x._id, x.email].includes(item.label))
                    const displayName = mem ? mem.fullName || mem.email : item.label
                    return (
                        <Select.Option title={displayName} key={item.label} value={item.label}>
                            {displayName}
                        </Select.Option>
                    )
                })}
                optionLabelProp="title"
                filterOption
                getPopupContainer={trigger => trigger.parentNode}
            />
        </div>
    )
}

export const ToggleBox = ({ currentRefinement, refine, count, label, defaultRefinement, updateState }) => (
    <div style={{ marginBottom: 6 }}>
        <Checkbox
            defaultChecked={defaultRefinement}
            checked={currentRefinement}
            onChange={e => {
                refine(e.target.checked)
                if (updateState) {
                    updateState(e.target.checked)
                }
            }}
            className="tp-cb-filter"
        >
            <span className="tp-filter-label">{label}</span>
            <span className="tp-filter-count">{count.unchecked || 0}</span>
        </Checkbox>
    </div>
)

export const JobBox = ({ currentRefinement, refine, items }) => {
    const { allJobs } = store.getState().Job
    return (
        <Checkbox.Group value={currentRefinement} onChange={e => refine(e)}>
            {items.map(item => {
                const job = allJobs.find(x => x._id === item.label)
                const jobTitle = job ? job.jobTitle : 'Unassigned'
                return (
                    <Tooltip title={jobTitle} overlayClassName="fs-10" key={item.label}>
                        <Checkbox className="tp-cb-filter" value={item.label}>
                            <span className="tp-job-filter-label-wrapper">
                                <span className="tp-job-filter-label">{jobTitle}</span>
                                {job && job.status === 0 && <span className="openings-tag">Archived</span>}
                            </span>
                            <span className="tp-filter-count">{item.count}</span>
                        </Checkbox>
                    </Tooltip>
                )
            })}
        </Checkbox.Group>
    )
}

export const ArchiveReasonBox = ({ currentRefinement, refine, items }) => {
    return (
        <Checkbox.Group className="tp-status-filter" value={currentRefinement} onChange={e => refine(e)}>
            {items
                .filter(each => each.label !== '0')
                .map(item => (
                    <Checkbox className="tp-cb-filter" value={item.label} key={item.label}>
                        <span className="tp-filter-label">{ARCHIVE_REASONS[parseInt(item.label) - 1].value}</span>
                        <span className="tp-filter-count">{item.count}</span>
                    </Checkbox>
                ))}
        </Checkbox.Group>
    )
}

export const StageBox = ({ currentRefinement, refine, items }) => {
    const { allJobs } = store.getState().Job
    const stages = {}
    allJobs.forEach(x => {
        x.stages.forEach(s => {
            stages[s._id] = {
                stageName: s.stageName,
                jobTitle: x.jobTitle,
            }
        })
    })

    return (
        <Checkbox.Group className="w-100" value={currentRefinement} onChange={e => refine(e)}>
            {items
                .filter(item => Boolean(stages[item.label]))
                .map(item => {
                    const stageName = stages[item.label].stageName || 'Unassigned'
                    const jobTitle = stages[item.label].jobTitle
                    return (
                        <Checkbox className="tp-cb-filter" value={item.label} key={item.label}>
                            <span className="tp-filter-label">
                                {stageName}
                                <span className="tp-stage-filter-job"> | {jobTitle}</span>
                            </span>
                            <span className="tp-filter-count">{item.count}</span>
                        </Checkbox>
                    )
                })}
        </Checkbox.Group>
    )
}

export const HeaderStats = ({ nbHits, label, onClick }) => (
    <Tag className="talent-pool-tags cursor-pointer" onClick={() => onClick(true)}>
        <span>{nbHits}</span>
        <span>{label}</span>
    </Tag>
)

export const TotalCandidateStats = ({ nbHits }) => <span className="tp-filter-title ml-4">({nbHits})</span>

export const CurrentRefinementBox = ({ items, refine }) => (
    <React.Fragment>
        {items.map(item => {
            if (item.id === 'query') {
                return (
                    <Tag key={item.id} closable onClose={() => refine(item.value)} className="talent-pool-filters">
                        {item.currentRefinement}
                    </Tag>
                )
            } else if (item.attribute === 'sourceVia') {
                return (
                    <Tag key={item.id} closable onClose={() => refine(item.value)} className="talent-pool-filters">
                        {item.currentRefinement}
                    </Tag>
                )
            } else if (item.attribute === 'sourceBy') {
                const { teamMembers } = store.getState().Mixed
                let mem = teamMembers.find(x => [x._id, x.email].includes(item.currentRefinement[0]))
                mem = mem ? mem.fullName || mem.email : item.currentRefinement[0]
                return (
                    <Tag key={item.id} closable onClose={() => refine(item.value)} className="talent-pool-filters">
                        {mem}
                    </Tag>
                )
            } else if (item.attribute === 'jobId') {
                const { allJobs } = store.getState().Job
                return (
                    <React.Fragment key={item.id}>
                        {item.items.map(jobItem => {
                            const job = allJobs.find(x => x._id === jobItem.label)
                            const jobTitle = job ? job.jobTitle : 'Unassigned'
                            return (
                                <Tag
                                    key={jobItem.label}
                                    closable
                                    onClose={() => refine(jobItem.value)}
                                    className="talent-pool-filters"
                                >
                                    {jobTitle}
                                </Tag>
                            )
                        })}
                    </React.Fragment>
                )
            } else if (item.attribute === 'stageId') {
                const { allJobs } = store.getState().Job
                const stages = {}
                allJobs.forEach(x => {
                    x.stages.forEach(s => {
                        stages[s._id] = s.stageName
                    })
                })
                return (
                    <React.Fragment key={item.id}>
                        {item.items.map(jobItem => {
                            return (
                                <Tag
                                    key={jobItem.label}
                                    closable
                                    onClose={() => refine(jobItem.value)}
                                    className="talent-pool-filters"
                                >
                                    {stages[jobItem.label]}
                                </Tag>
                            )
                        })}
                    </React.Fragment>
                )
            } else {
                return (
                    <Tag key={item.id} closable onClose={() => refine(item.value)} className="talent-pool-filters">
                        {item.label}
                    </Tag>
                )
            }
        })}

        {!!items.length && (
            <Button type="primary" ghost size="small" className="tp-clear-all" onClick={() => refine(items)}>
                Clear All
            </Button>
        )}
    </React.Fragment>
)

export const SearchResultBox = ({ searchResults }) => {
    if (searchResults === null)
        return (
            <>
                <OpeningCardWrapper>
                    <TalentPoolCardLoader />
                </OpeningCardWrapper>
                <OpeningCardWrapper>
                    <TalentPoolCardLoader />
                </OpeningCardWrapper>
            </>
        )

    if (searchResults && searchResults.hits && searchResults.hits.length < 1)
        return (
            <div className="talentpool-block-empty">
                <Empty style={{ width: '100%', margin: 'auto' }} />
            </div>
        )
    return null
}

export const CandidateCountBox = ({ allSearchResults }) => {
    if (allSearchResults && allSearchResults.nbHits >= 0)
        return <div className="tp-page-can-count">{allSearchResults.nbHits} Candidates</div>
    return <div className="tp-page-can-count" />
}

export const CandidateStage = ({ currentRefinement, refine, items }) => {
    let allItems = []
    items.forEach(item => {
        if (item.label === '0') allItems[3] = item
        else if (item.label === '1') allItems[0] = item
        else if (item.label === '2') allItems[2] = item
        else if (item.label === '3') allItems[1] = item
    })

    return (
        <Checkbox.Group className="tp-status-filter" value={currentRefinement} onChange={e => refine(e)}>
            {allItems.map(item => {
                let statusName
                if (item.label === '0') statusName = 'Archived'
                else if (item.label === '1') statusName = 'Active'
                else if (item.label === '2') statusName = 'Rejected'
                else if (item.label === '3') statusName = 'Joined'
                return (
                    <Checkbox className="tp-cb-filter" value={item.label} key={item.label}>
                        <span className="tp-filter-label">{statusName}</span>
                        <span className="tp-filter-count">{item.count}</span>
                    </Checkbox>
                )
            })}
        </Checkbox.Group>
    )
}

export const CandidateTagBox = ({ currentRefinement, refine, items }) => {
    return (
        <Checkbox.Group className="tp-status-filter" value={currentRefinement} onChange={e => refine(e)}>
            {items.map(item => {
                return (
                    <Checkbox className="tp-cb-filter" value={item.label} key={item.label}>
                        <span className="tp-filter-label">{item.label}</span>
                        <span className="tp-filter-count">{item.count}</span>
                    </Checkbox>
                )
            })}
        </Checkbox.Group>
    )
}
