import React, { useState, useEffect } from 'react'
import * as S from './style'
import { ReactComponent as TestimonialImg } from '../../../assets/Icons/Landing/testimonial.svg'
import { ReactComponent as ArrowIcon } from '../../../assets/Icons/Landing/arrow.svg'
import Naman from '../../../assets/Icons/Landing/Testimonials/naman.jpg'
import Sandeep from '../../../assets/Icons/Landing/Testimonials/sandeep.png'
import Edul from '../../../assets/Icons/Landing/Testimonials/edul.jpg'
import Rahul from '../../../assets/Icons/Landing/Testimonials/rahul.png'
import Priyanka from '../../../assets/Icons/Landing/Testimonials/priyanka.png'

const Testimonials = ({ isLoggedIn, accessLevel }) => {
    const [item, setItem] = useState(0)
    const testimonials = [
        {
            img: Naman,
            name: 'Naman Sarawagi',
            role: 'Co-Founder of Refrens',
            desc: `Good product. Easy to use. Every function is well thought of and the various levels of
                            access are well defined too and ensure that we find the right fit at my company.`,
        },
        {
            img: Rahul,
            name: 'Rahul Tripathi',
            role: 'Practice Head at PHilter HR Solution',
            desc: `Found SpringRecruit very user friendly and it actually helped in saving my time for its easy
                            accessibility.`,
        },
        {
            img: Edul,
            name: 'Edul Patel',
            role: 'Co-Founder of Mudrex',
            desc: `Time is currency and SpringRecruit helps save time.`,
        },
        {
            img: Sandeep,
            name: 'Sandeep Kodavati',
            role: 'HR Lead at ThoughtGreen Technologies',
            desc: `I’ve worked with different ATSs and I’ve found SpringRecruit to be the simplest and easiest
                            to use by far.`,
        },
        {
            img: Priyanka,
            name: 'Priyanka Lekhi',
            role: 'Vice President of People and Culture at Open Financial Services',
            desc: `It’s free forever. It’s got a simple UI - you know exactly where what is. GCalendar
                            integration ensures you never lose track of interviews. A recommendable ATS for a startup.`,
        },
    ]
    const currItem = testimonials[item]
    const handleNext = selected => {
        if (selected < 0) setItem(testimonials.length - 1)
        else if (selected >= testimonials.length) setItem(0)
        else setItem(selected)
    }

    return (
        <S.Testimonials>
            <S.H1>What Clients Say</S.H1>
            <div className="card">
                <p className="show-sm">{currItem.desc}</p>
                <p className="hide-sm">{currItem.desc}</p>
                <div className="user">
                    {currItem.img ? <img src={currItem.img} alt={currItem.name} /> : <TestimonialImg />}
                    <div>
                        <S.H3>{currItem.name}</S.H3>
                        <div className="role">{currItem.role}</div>
                    </div>
                </div>
                <div className="arrows">
                    <ArrowIcon onClick={() => handleNext(item - 1)} />
                    <ArrowIcon onClick={() => handleNext(item + 1)} className="right" />
                </div>
            </div>
            {/* <div className="button">
                <StartedButton isLoggedIn={isLoggedIn} accessLevel={accessLevel} />
            </div> */}
        </S.Testimonials>
    )
}

export default Testimonials
