import ContentLoader from 'react-content-loader'
import React from 'react'
import { Card } from '../style'

export const CandidateCardLoader = () => (
    <Card>
        <ContentLoader height={130}>
            <rect x="0" y="0" rx="5" ry="5" width="32" height="32" />
            <rect x="43" y="3" rx="1" ry="1" width="80" height="10" />
            <rect x="43" y="22" rx="1" ry="1" width="70" height="7" />
            <rect x="125" y="22" rx="1" ry="1" width="70" height="7" />

            <rect x="43" y="45" rx="5" ry="5" width="80" height="10" />
            <rect x="130" y="45" rx="5" ry="5" width="80" height="10" />
            <rect x="217" y="45" rx="5" ry="5" width="80" height="10" />

            <rect x="43" y="75" rx="1" ry="1" width="200" height="8" />
            <rect x="0" y="96" rx="0" ry="0" width="400" height="1" />

            <rect x="0" y="108" rx="1" ry="1" width="90" height="7" />
            <rect x="130" y="108" rx="1" ry="1" width="90" height="7" />
            <rect x="260" y="108" rx="1" ry="1" width="90" height="7" />
            <rect x="0" y="123" rx="1" ry="1" width="90" height="7" />
            <rect x="130" y="123" rx="1" ry="1" width="90" height="7" />
            <rect x="260" y="123" rx="1" ry="1" width="90" height="7" />
        </ContentLoader>
    </Card>
)
