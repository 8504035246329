import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 14 14">
            <path
                fill="#8A94A6"
                fillRule="nonzero"
                stroke="none"
                strokeWidth="1"
                d="M174 26.6a5.6 5.6 0 100-11.2 5.6 5.6 0 000 11.2zm0 1.4a7 7 0 110-14 7 7 0 010 14zm0-8.05a.7.7 0 00-.7.7v2.8a.7.7 0 001.4 0v-2.8a.7.7 0 00-.7-.7zm0-2.45a.7.7 0 100 1.4.7.7 0 000-1.4z"
                transform="translate(-227 -421) translate(60 407)"
            />
        </svg>
    )
}

export default Icon
