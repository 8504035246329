import React from 'react'
import * as classNames from 'classnames'
import { Modal, Typography, Button, Upload, Row, Col, Input, Select, Form, Icon } from 'antd'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { ModalElementWrapper, ModalWrapper, CandidateDetailsWrapper } from './style'
import './style.css'
import { connect } from 'react-redux'
import { success, warning } from '../../../services/Notification'
import RemoveIcon from '../../../assets/Icons/Todo/Remove Button'
import { countryCodes } from '../../Helpers/countryCode'
import { phoneValidator } from '../../Helpers/utils'
import { LogE } from './../../Helpers/errorHandler'
import { HotKeys } from 'react-keyboard'
import { keyMap } from '../../Helpers/KeyMap'
import Dropzone from 'react-dropzone'
import { fileTypeValidation } from '../../Helpers/fileTypeValidation'
import { GetResumeDetails, AddCandidateWithJob, GetCandidatesEmail } from '../../../services/NetworkCalls/Candidate'

const { Text } = Typography
const { Option } = Select
const { Dragger } = Upload

class AddCandidateModal extends React.Component {
    constructor(props) {
        super(props)

        const defaultCandidateSettings = {
            name: true,
            email: true,
            phone: false,
        }
        let { candidateSettings } = props
        candidateSettings = candidateSettings || defaultCandidateSettings

        this.state = {
            disableAdd: false,
            visible: true,
            uploadInProgress: 0,
            form: [],
            result: [],
            duplicates: [],
            addedUsers: [],
            candidateSettings,
        }

        this.phoneNumberObject = {}
    }

    onCancel = () => {
        this.props.onCancel()
    }

    formSubmit = (hasDuplicatePhoneError, err, values) => {
        if (!err && !hasDuplicatePhoneError) {
            try {
                this.setState({ disableAdd: true }, async () => {
                    let { form, addedUsers } = this.state
                    let { value } = values

                    value = value.map((x, index) => {
                        let phnNumber = JSON.stringify(x.phone)
                        if (phnNumber) phnNumber = phnNumber.split('"')[1]
                        x.phone = parseInt(phnNumber, 10)
                        if (!x.phone) {
                            //if phone number is not required
                            x.phone = null
                        } else {
                            const countryCode = countryCodes.find(c => c.code === x.countryCode).dial_code
                            const phoneNumber = parsePhoneNumberFromString(countryCode + x.phone)
                            x.phone = phoneNumber && phoneNumber.number
                        }
                        delete x.countryCode
                        x.resumeId = form[index].resumeId

                        return {
                            ...x,
                            candidateResumeLink: form[index].candidateResumeLink,
                        }
                    })
                    const res = await this.props.AddCandidateWithJob(value)
                    if (res.candidates.length) {
                        form = value.map((x, index) => ({
                            ...x,
                            resumeName: form[index].resumeName,
                        }))

                        this.setState({
                            form,
                            result: form,
                            addedUsers: addedUsers.concat(res.candidates),
                            visible: false,
                        })
                        success(res.message)
                    }
                })
            } catch (err) {
                LogE('onsubmit-addcandidate-onSubmit', '', err)
                warning('Email already exists in this job')
            }
        }
    }

    fieldsValidator = (errorsArray, hasDuplicatePhoneError) => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            for (let index = 0; index < this.state.form.length; index++) {
                const value = this.props.form.getFieldValue(`value[${index}].phone`)
                const errors = errorsArray[index] ? [new Error(errorsArray[index][0])] : null
                this.props.form.setFields({ [`value[${index}].phone`]: { value, errors } })
            }
            this.setState({ disableAdd: false }, this.formSubmit(hasDuplicatePhoneError, err, values))
        })
    }

    onSubmit = e => {
        e.preventDefault()

        const errorsArray = []
        let hasDuplicatePhoneError = false

        for (let index = 0; index < this.state.form.length; index++) {
            const error = this.props.form.getFieldError(`value[${index}].phone`)
            errorsArray.push(error)
            if (error) hasDuplicatePhoneError = true
        }

        this.setState({ disableAdd: true }, () => this.fieldsValidator(errorsArray, hasDuplicatePhoneError))
    }

    uploadDoc = info => {
        let result = this.state.result.slice()

        if (info.status === 200) {
            const response = info.data
            result = result.concat(
                ...response.map(rs => {
                    let countryCode
                    let phone = rs.candidateDetail && rs.candidateDetail.phone && rs.candidateDetail.phone[0]
                    if (phone) {
                        countryCode = phone.Country_ISO_Code
                        phone = phone['Extracted phone number']
                    }
                    return {
                        jobId: this.props.jobId,
                        name: rs.candidateDetail && rs.candidateDetail.name && rs.candidateDetail.name,
                        phone,
                        email: rs.candidateDetail && rs.candidateDetail.email && rs.candidateDetail.email[0],
                        candidateResumeLink: rs.resumeLink,
                        resumeName: rs.resumeName,
                        countryCode,
                        resumeId: rs.candidateDetail.resume_id,
                    }
                })
            )
            this.setState({ uploadInProgress: 3, form: result, result })
        } else if (info.status !== 200) {
            warning('Error uploading resume! Please ensure the file is either of type pdf, doc or docx ')
        }
    }

    removeCandidate = index => {
        let { uploadInProgress } = this.state
        let result = this.state.result.slice()
        let form = this.state.form.slice()

        result.splice(index, 1)
        form.splice(index, 1)

        if (!result.length) {
            uploadInProgress = 0
        }

        let value = this.props.form.getFieldValue('value').slice()
        value.splice(index, 1)
        this.props.form.setFieldsValue({ value })

        this.setState({ result, form, uploadInProgress })
    }

    duplicates = (rule, value, callback) => {
        const { duplicates } = this.state
        const values = this.props.form.getFieldValue('value').slice()

        if (duplicates.includes(value)) {
            return callback('Email already added to the job')
        }

        const multipleEntries = values.filter(x => x.email === value).length
        if (multipleEntries > 1) {
            return callback('Multiple candidates with same email are present')
        }
        callback()
    }

    duplicatePhoneCheck = (input, field, index) => {
        const { getFieldValue, setFields } = this.props.form
        let countryCode = field === 'countryCode' ? input : getFieldValue(`value[${index}].countryCode`)
        const phone = field === 'phone' ? input : getFieldValue(`value[${index}].phone`)
        if (countryCode && phone) {
            countryCode = countryCodes.find(x => x.code === countryCode).dial_code
            const num = countryCode + phone
            const phoneNumber = parsePhoneNumberFromString(num)
            if (phoneNumber && phoneNumber.isValid()) {
                if (this.phoneNumberObject.hasOwnProperty(phoneNumber.number)) {
                    this.phoneNumberObject[phoneNumber.number].push(index)
                    this.phoneNumberObject[phoneNumber.number].forEach(each => {
                        setFields({
                            [`value[${each}].phone`]: { value: phone, errors: [new Error('Same phone number')] },
                        })
                    })
                } else {
                    this.phoneNumberObject[phoneNumber.number] = [index]
                }
            } else {
                const keysArray = Object.keys(this.phoneNumberObject)
                keysArray.forEach(each => {
                    let array = this.phoneNumberObject[each]
                    if (array.includes(index) && array.length === 1) delete this.phoneNumberObject[each]
                    else if (array.includes(index) && array.length === 2) {
                        array.forEach(e => {
                            const value = getFieldValue(`value[${e}].phone`)
                            setFields({ [`value[${e}].phone`]: { value } })
                        })
                        array.splice(array.indexOf(index), 1)
                    } else if (array.includes(index)) {
                        const value = getFieldValue(`value[${index}].phone`)
                        setFields({ [`value[${index}].phone`]: { value } })
                        array.splice(array.indexOf(index), 1)
                        array.forEach(each => {
                            const val = getFieldValue(`value[${each}].phone`)
                            setFields({
                                [`value[${each}].phone`]: { value: val, errors: [new Error('Same phone number')] },
                            })
                        })
                    }
                })
            }
        }
    }

    addCandidateManually = () => {
        const result = [
            {
                jobId: this.props.jobId,
                name: undefined,
                phone: undefined,
                email: undefined,
                candidateResumeLink: undefined,
            },
        ]

        this.setState({
            uploadInProgress: 3,
            form: result,
            result,
        })
    }

    validEmail = async (rule, value, callback) => {
        try {
            if (value && value.trim().length > 0) {
                const checkMail = await this.props.GetCandidatesEmail({ email: value, companyId: this.props.companyId })
                if (checkMail.isPresent && value !== this.props.currentEmail) {
                    return callback('Email is already present')
                }
            }
            callback()
        } catch (err) {
            warning(err.message || err)
            LogE('add-candidate-duplicate-email', '', err)
        }
    }

    onResumeSelect = async acceptedFiles => {
        try {
            this.setState({ uploadInProgress: acceptedFiles.length })
            // Check if the uploaded files are in either pdf, doc or docx format
            const fileCheck = fileTypeValidation(acceptedFiles)
            if (!fileCheck) {
                this.setState({ uploadInProgress: false })
                warning('Error uploading resume! Please ensure the file is either of type pdf, doc or docx ')
                return true
            }
            const result = await this.props.GetResumeDetails(fileCheck)
            if (result) {
                result.data = result.data.map((data, index) => {
                    data.resumeName = acceptedFiles[index].name
                    return data
                })
                this.uploadDoc(result)
                return true
            }
        } catch (err) {
            const errorMsg = (err.response && err.response.data && err.response.data.error.msg) || err
            warning(errorMsg)
        }

        this.setState({ uploadInProgress: false })
        return false
    }

    handlers = {
        manualEdit: () => {
            if (this.props.allowShortcuts) this.addCandidateManually()
        },
    }

    render() {
        const { form, uploadInProgress, disableAdd, candidateSettings } = this.state
        const { getFieldDecorator, getFieldsValue, getFieldValue, setFields } = this.props.form
        let { allJobs } = this.props

        // in all the modal opening, we do not show the archived jobs
        allJobs = allJobs.filter(job => job.status && job.stages && job.stages.length).reverse()

        const formValues = getFieldsValue()

        let stages =
            formValues.value &&
            formValues.value.map(x => allJobs.find(job => job._id === (this.props.jobId || x.jobId)))
        stages = stages ? stages.map(x => (x ? x.stages : [])) : []

        formValues.value &&
            formValues.value.forEach((fr, index) => {
                if ((this.props.jobId && !fr.stageId) || (fr.jobId && !fr.stageId)) {
                    const defaultStageId = stages[index][0]._id
                    this.props.form.setFieldsValue({
                        [`value[${index}].stageId`]: defaultStageId,
                    })
                }
            })

        const stagesOptions = index =>
            allJobs.find(job => job._id === getFieldValue('value')[index].jobId) &&
            allJobs.find(job => job._id === getFieldValue('value')[index].jobId).stages

        const prefixSelector = (index, initialValue) =>
            getFieldDecorator(`value[${index}].countryCode`, {
                initialValue,
                rules: [
                    {
                        required: candidateSettings.phone || !!getFieldValue(`value[${index}].phone`),
                        message: 'Country code is required',
                    },
                    {
                        validator: phoneValidator(
                            field => getFieldValue(field),
                            (field, value, errors) => {
                                setFields({
                                    [field]: { value, errors },
                                })
                            },
                            null,
                            index
                        ),
                    },
                ],
            })(
                <Select
                    showSearch
                    style={{ width: 70 }}
                    optionLabelProp="title"
                    optionFilterProp="children"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 245 }}
                    dropdownClassName="phone-input-dropdown"
                    onChange={value => this.duplicatePhoneCheck(value, 'countryCode', index)}
                    filterOption={(input, option) =>
                        option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                >
                    {countryCodes.map(code => (
                        <Option key={code.code} title={code.dial_code}>
                            {code.name} ({code.dial_code})
                        </Option>
                    ))}
                </Select>
            )

        return (
            <div>
                <Modal
                    visible={this.state.visible}
                    onCancel={this.onCancel}
                    onOk={this.onSubmit}
                    footer={null}
                    closable={false}
                    width={642}
                    bodyStyle={{ padding: '28px 40px 40px', fontSize: '12px' }}
                    centered
                    maskClosable={false}
                    destroyOnClose={true}
                >
                    <HotKeys keyMap={keyMap.addCandidate} handlers={this.handlers}>
                        <ModalWrapper>
                            <div className="modal-as-title">Add Candidate</div>
                            <Text type="secondary" className="font-family-mediumn" style={{ marginBottom: 20 }}>
                                {uploadInProgress && uploadInProgress === 3 && form[0]
                                    ? 'Verify the fetched details from the resume and add candidate to the job opening'
                                    : 'Upload candidate resume and let our resume parser do the rest!'}
                            </Text>
                            {uploadInProgress && uploadInProgress === 3 && form[0] ? (
                                <div className="cd-form-main">
                                    <Form
                                        onSubmit={this.onSubmit}
                                        className={classNames({
                                            'no-margin-cd': form.length === 1,
                                        })}
                                    >
                                        {form.map((fr, index) => (
                                            <div key={index} style={{ marginTop: 20 }}>
                                                <div className="cd-count-cont">
                                                    <div className="cd-count-index" hidden={form.length === 1}>
                                                        CANDIDATE {index + 1}
                                                    </div>
                                                    <Icon
                                                        component={RemoveIcon}
                                                        onClick={() => this.removeCandidate(index)}
                                                    />
                                                </div>
                                                <CandidateDetailsWrapper>
                                                    <ModalElementWrapper>
                                                        <Row gutter={16}>
                                                            <Col xs={12} className="add-candidate-form-inputs">
                                                                <Text className="modal-ad-title-color">
                                                                    Name
                                                                    {candidateSettings.name && '*'}:
                                                                </Text>
                                                                <Form.Item>
                                                                    {getFieldDecorator(`value[${index}].name`, {
                                                                        initialValue: fr.name,
                                                                        rules: [
                                                                            {
                                                                                required: candidateSettings.name,
                                                                                whitespace: true,
                                                                                message: 'Name is required',
                                                                            },
                                                                        ],
                                                                    })(
                                                                        <Input
                                                                            size="large"
                                                                            className="margin-top-6 account-modal-ad-input"
                                                                            placeholder="Enter Candidate Name"
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={12} className="add-candidate-form-inputs">
                                                                <Text className="modal-ad-title-color">
                                                                    Email
                                                                    {candidateSettings.email && '*'}:
                                                                </Text>
                                                                <Form.Item>
                                                                    {getFieldDecorator(`value[${index}].email`, {
                                                                        validateTrigger: 'onBlur',
                                                                        initialValue: fr.email,
                                                                        rules: [
                                                                            {
                                                                                required: candidateSettings.email,
                                                                                whitespace: true,
                                                                                message: 'Email is required',
                                                                            },
                                                                            {
                                                                                type: 'email',
                                                                                message: 'Invalid Email!',
                                                                            },
                                                                            {
                                                                                validator: this.duplicates,
                                                                            },
                                                                            {
                                                                                validator: this.validEmail,
                                                                            },
                                                                        ],
                                                                    })(
                                                                        <Input
                                                                            size="large"
                                                                            className="margin-top-6 account-modal-ad-input"
                                                                            placeholder="Enter Candidate Email"
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </ModalElementWrapper>
                                                    <ModalElementWrapper>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap: 'wrap',
                                                                justifyContent: 'space-between',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    marginTop: 12,
                                                                }}
                                                            >
                                                                <Text className="modal-ad-title-color">
                                                                    Phone
                                                                    {candidateSettings.phone && '*'}:
                                                                </Text>
                                                                <Form.Item>
                                                                    {getFieldDecorator(`value[${index}].phone`, {
                                                                        initialValue: fr.phone,
                                                                        rules: [
                                                                            {
                                                                                required: candidateSettings.phone,
                                                                                whitespace: true,
                                                                                message: 'Phone number is required',
                                                                            },
                                                                            {
                                                                                validator: phoneValidator(
                                                                                    field => getFieldValue(field),
                                                                                    (field, value, errors) => {
                                                                                        setFields({
                                                                                            [field]: { value, errors },
                                                                                        })
                                                                                    },
                                                                                    null,
                                                                                    index
                                                                                ),
                                                                            },
                                                                        ],
                                                                    })(
                                                                        <Input
                                                                            addonBefore={prefixSelector(
                                                                                index,
                                                                                fr.countryCode
                                                                            )}
                                                                            size="large"
                                                                            className="margin-top-6 phone-input-sr"
                                                                            onChange={e =>
                                                                                this.duplicatePhoneCheck(
                                                                                    e.target.value,
                                                                                    'phone',
                                                                                    index
                                                                                )
                                                                            }
                                                                            placeholder="Candidate Phone"
                                                                            style={{
                                                                                border: 'none !important',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    marginTop: 12,
                                                                }}
                                                            >
                                                                <Text
                                                                    className="modal-ad-title-color"
                                                                    style={{
                                                                        paddingLeft: 3,
                                                                    }}
                                                                >
                                                                    Resume:
                                                                </Text>
                                                                {fr.candidateResumeLink ? (
                                                                    fr.candidateResumeLink.includes('.doc') ? (
                                                                        <div
                                                                            className="add-can-resume-name"
                                                                            onClick={() =>
                                                                                window.open(
                                                                                    `https://docs.google.com/viewerng/viewer?url=${fr.candidateResumeLink}`,
                                                                                    '_blank'
                                                                                )
                                                                            }
                                                                        >
                                                                            {fr.resumeName}
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className="add-can-resume-name"
                                                                            onClick={() =>
                                                                                window.open(
                                                                                    `${fr.candidateResumeLink}`,
                                                                                    '_blank'
                                                                                )
                                                                            }
                                                                        >
                                                                            {fr.resumeName}
                                                                        </div>
                                                                    )
                                                                ) : (
                                                                    <div className="add-can-resume-name">
                                                                        Not Available
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </ModalElementWrapper>
                                                </CandidateDetailsWrapper>

                                                {allJobs.length ? (
                                                    <ModalElementWrapper>
                                                        <div className="candidate-job-cont">
                                                            <div className="candidate-job-item">
                                                                <Text className="modal-ad-title-color">
                                                                    Job Opening:
                                                                </Text>
                                                                <Form.Item>
                                                                    {getFieldDecorator(`value[${index}].jobId`, {
                                                                        initialValue: this.props.jobId,
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message: 'Please select a Job',
                                                                            },
                                                                        ],
                                                                    })(
                                                                        <Select
                                                                            size="large"
                                                                            className="account-modal-role"
                                                                            placeholder="Select Opening"
                                                                            disabled={!!this.props.jobId}
                                                                            onChange={value => {
                                                                                this.props.form.setFieldsValue({
                                                                                    [`value[${index}].stageId`]: '',
                                                                                })
                                                                            }}
                                                                            getPopupContainer={trigger =>
                                                                                trigger.parentNode
                                                                            }
                                                                        >
                                                                            {allJobs.map(option => (
                                                                                <Option
                                                                                    className="account-modal-role-option"
                                                                                    key={option._id}
                                                                                    value={option._id}
                                                                                >
                                                                                    <h5>{option.jobTitle}</h5>
                                                                                </Option>
                                                                            ))}
                                                                        </Select>
                                                                    )}
                                                                </Form.Item>
                                                            </div>
                                                            <div className="candidate-job-item">
                                                                <Text className="modal-ad-title-color">Stage:</Text>
                                                                <Form.Item>
                                                                    {getFieldDecorator(`value[${index}].stageId`, {
                                                                        initialValue:
                                                                            stagesOptions(index) &&
                                                                            stagesOptions(index)[0]._id,
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message: 'Please select a Stage',
                                                                            },
                                                                        ],
                                                                    })(
                                                                        <Select
                                                                            getPopupContainer={trigger =>
                                                                                trigger.parentNode
                                                                            }
                                                                            size="large"
                                                                            className="account-modal-role"
                                                                            placeholder="Select Stage"
                                                                        >
                                                                            {stagesOptions(index) &&
                                                                                stagesOptions(index).map(option => (
                                                                                    <Option
                                                                                        className="account-modal-role-option"
                                                                                        key={option._id}
                                                                                        value={option._id}
                                                                                    >
                                                                                        <h5>{option.stageName}</h5>
                                                                                    </Option>
                                                                                ))}
                                                                        </Select>
                                                                    )}
                                                                </Form.Item>
                                                            </div>
                                                        </div>
                                                    </ModalElementWrapper>
                                                ) : null}
                                            </div>
                                        ))}
                                    </Form>

                                    {!allJobs.length ? (
                                        <div className="no-job-text">
                                            As there are no job openings created yet, hence you can find this candidate
                                            in Talent Pool section. After creating a Job Opening you can move this
                                            candidate to it from the Talent Pool section.
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div>
                                    <div className="candidate-upload-wrapper">
                                        {uploadInProgress ? (
                                            <div className="candidate-upload-inprogress-cont">
                                                <div className="candidate-upload-inprogress">
                                                    <div className="candidate-upload-inprogress-loader">
                                                        <div className="candidate-upload-inprogress-33"></div>
                                                        <div
                                                            className={classNames({
                                                                'candidate-upload-inprogress-66': true,
                                                                'candidate-upload-inprogress-66-fill':
                                                                    uploadInProgress > 1,
                                                            })}
                                                        ></div>
                                                        <div className="candidate-upload-inprogress-100"></div>
                                                    </div>
                                                    <div className="candidate-upload-inprogress-text">
                                                        {uploadInProgress === 1
                                                            ? 'Uploading resume...'
                                                            : 'Parsing and fetching details from the resume...'}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <Dropzone onDrop={this.onResumeSelect}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()} className="candidate-upload-start">
                                                        <input {...getInputProps()} />
                                                        <div className="candidate-upload-info">
                                                            <div className="candidate-upload-info-detail">
                                                                Drop your files here (PDF/Doc/Word)
                                                            </div>
                                                            <div className="candidate-upload-info-detail">or</div>
                                                            <div className="candidate-upload-info-detail">
                                                                <span className="candidate-upload-click-here">
                                                                    Click here
                                                                </span>{' '}
                                                                to browse
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        )}
                                    </div>
                                    <div className="candidate-upload-manually">
                                        <span
                                            className="candidate-upload-click-here"
                                            onClick={this.addCandidateManually}
                                        >
                                            Click here{' '}
                                        </span>
                                        to add candidates without uploading their resume.
                                    </div>
                                </div>
                            )}

                            <ModalElementWrapper style={{ marginTop: 35 }}>
                                <Button
                                    type="primary"
                                    ghost
                                    size="large"
                                    className="account-width-120p"
                                    onClick={this.onCancel}
                                    disabled={uploadInProgress > 0 && uploadInProgress < 3}
                                >
                                    Cancel
                                </Button>
                                {uploadInProgress && uploadInProgress === 3 && form[0] ? (
                                    <Button
                                        type="primary"
                                        size="large"
                                        className="account-width-120p"
                                        onClick={this.onSubmit}
                                        disabled={disableAdd}
                                    >
                                        Add
                                    </Button>
                                ) : (
                                    <Dropzone onDrop={this.onResumeSelect}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Button type="primary" size="large" className="account-width-120p">
                                                    Upload
                                                </Button>
                                            </div>
                                        )}
                                    </Dropzone>
                                )}
                            </ModalElementWrapper>
                        </ModalWrapper>
                    </HotKeys>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { Auth, Job } = state
    return {
        candidateSettings: Auth.user.candidateSettings,
        companyId: Auth.user.companyId,
        allJobs: Job.allJobs,
        allowShortcuts: Auth.user.allowShortcuts,
    }
}

export default connect(
    mapStateToProps,
    { AddCandidateWithJob, GetResumeDetails, GetCandidatesEmail }
)(Form.create()(AddCandidateModal))
