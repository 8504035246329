import React from 'react'
import { Heading } from './style'
import { PAGE_SECTIONS } from '../../Helpers/Stylesheet/styleTypes'
import { Content } from '../../../container/Pages/CreateNewOpening/style.js'

export const PageContainer = ({ title, type = PAGE_SECTIONS.SECTION, children, empty }) => (
    <Content empty={empty}>
        <Heading type={type}>{title}</Heading>
        {children}
    </Content>
)
