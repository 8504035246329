import React from 'react'
import { NoFeedbackBox } from './style'
import { Empty } from 'antd'

const EmptyFeedback = () => {
    return (
        <NoFeedbackBox>
            <Empty description="No feedback to show" />
        </NoFeedbackBox>
    )
}

export default EmptyFeedback
