import { actions } from './actions'
import { PURGE } from 'redux-persist'
import { EVENTS, FILTER_TYPES, PAGE_SIZE_LIMIT } from '../../container/Pages/Inbox/Components/constants'
import { wrapMapToPropsConstant } from 'react-redux/lib/connect/wrapMapToProps'

const initState = {
    callInProgress: true,
    currentPage: 1,
    hasNextPage: true,
    contacts: [],
    contactsData: {},
    mainFilter: FILTER_TYPES.ALL,
    selectedContact: null,
    filterCount: {
        [FILTER_TYPES.ALL]: 0,
        [FILTER_TYPES.ADDED]: 0,
        [FILTER_TYPES.ASSIGNED]: 0,
    },
    jobId: [],
    stageId: [],
    status: [],
    searchResult: '',
    selectedFilter: {},
    emailLoading: false,
}

export default function InboxReducer(state = initState, action) {
    const { payload, type } = action

    switch (type) {
        case PURGE:
            return initState

        case actions.SET_CALL_IN_PROGRESS:
            return { ...state, callInProgress: true, selectedContact: null }

        case actions.SELECT_CONTACT:
            const { candidateId, shouldLoad } = payload
            const contacts = state.contacts.map(each => {
                if (each._id === candidateId) {
                    each.emailData.count = 0
                    each.emailData.manualEmailCount = 0
                }
                return each
            })
            return { ...state, contacts, selectedContact: candidateId, ...(shouldLoad && { emailLoading: true }) }

        case actions.GET_CONTACTS: {
            const {
                searchResult,
                candidateCount: [filterCountData = {}],
                status,
                job,
                stage,
                pageNo,
                mainFilter,
                shouldResetContacts = true,
                filterType,
                selectedFilter,
                search,
            } = payload

            if (pageNo !== 1) {
                // just loaded more data
                return {
                    ...state,
                    contacts: [...state.contacts, ...searchResult],
                    currentPage: pageNo,
                    hasNextPage: searchResult.length === PAGE_SIZE_LIMIT,
                }
            }

            const filterCount = {
                [FILTER_TYPES.ALL]: filterCountData.total || 0,
                [FILTER_TYPES.ADDED]: filterCountData.assignedByMe || 0,
                [FILTER_TYPES.ASSIGNED]: filterCountData.assignedToMe || 0,
            }

            const resultData = {
                ...state,
                filterCount,
                jobId: job,
                stageId: stage,
                callInProgress: false,
                currentPage: pageNo,
                mainFilter,
                status,
                hasNextPage: searchResult.length === PAGE_SIZE_LIMIT,
                searchResult: search,
                // its reset the contacts but not contactsData
                contacts: shouldResetContacts ? searchResult : [...state.contacts, ...searchResult],
                selectedFilter: { tabMode: selectedFilter.tabMode },
            }

            switch (filterType) {
                case EVENTS.OPENINGS:
                    resultData.selectedFilter = { ...resultData.selectedFilter, jobId: selectedFilter.jobId }
                    break
                case EVENTS.STAGE:
                    resultData.selectedFilter = { ...resultData.selectedFilter, stageId: selectedFilter.stageId }
                    break
                case EVENTS.STATUS:
                    resultData.selectedFilter = {
                        ...resultData.selectedFilter,
                        status: selectedFilter.status,
                        jobId: [],
                    }
                    break
            }

            return resultData
        }

        case actions.GET_INBOX: {
            const { candidateId, data } = payload
            return {
                ...state,
                emailLoading: false,
                contactsData: {
                    ...state.contactsData,
                    [candidateId]: data,
                },
            }
        }

        case actions.SEND_INBOX: {
            const { candidateId } = payload
            const email_content = state.contactsData[candidateId].email_content.map(each => each)
            email_content.unshift(payload)
            return {
                ...state,
                emailLoading: false,
                contactsData: {
                    ...state.contactsData,
                    [candidateId]: {
                        ...state.contactsData[candidateId],
                        email_content,
                    },
                },
            }
        }

        case actions.INBOX_MARK_PRIVATE: {
            const { markedPrivate, conversationId } = payload
            const candidateId = state.selectedContact

            const email_content = state.contactsData[candidateId].email_content.map(each => {
                if (each._id === conversationId) {
                    return { ...each, markedPrivate }
                } else return each
            })

            return {
                ...state,
                contactsData: {
                    ...state.contactsData,
                    [candidateId]: {
                        ...state.contactsData[candidateId],
                        email_content,
                    },
                },
            }
        }

        default:
            return { ...state }
    }
}
