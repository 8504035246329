import React from 'react'

const SVGComponent = () => (
    <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M1.91666667,0.333333333 L16.0833333,0.333333333 C16.7736893,0.333333333 17.3333333,0.892977396 17.3333333,1.58333333 L17.3333333,12.4166667 C17.3333333,13.1070226 16.7736893,13.6666667 16.0833333,13.6666667 L1.91666667,13.6666667 C1.22631073,13.6666667 0.666666667,13.1070226 0.666666667,12.4166667 L0.666666667,1.58333333 C0.666666667,0.892977396 1.22631073,0.333333333 1.91666667,0.333333333 Z M2.33333333,2 L2.33333333,12 L15.6666667,12 L15.6666667,2 L2.33333333,2 Z M4.51050769,4.00008271 C4.21046171,3.99588247 3.93137398,4.15192119 3.78011266,4.40844858 C3.62885134,4.66497598 3.62885134,4.98224826 3.78011266,5.23877566 C3.93137398,5.49530305 4.21046171,5.65134177 4.51050769,5.64714153 L12.8304216,5.64714153 C13.1304676,5.65134177 13.4095553,5.49530305 13.5608167,5.23877566 C13.712078,4.98224826 13.712078,4.66497598 13.5608167,4.40844858 C13.4095553,4.15192119 13.1304676,3.99588247 12.8304216,4.00008271 L4.51050769,4.00008271 Z M4.54092933,7.00008271 C4.34859017,6.99588247 4.16968593,7.15192119 4.07272254,7.40844858 C3.97575915,7.66497598 3.97575915,7.98224826 4.07272254,8.23877566 C4.16968593,8.49530305 4.34859017,8.65134177 4.54092933,8.64714153 L9.87426266,8.64714153 C10.0666018,8.65134177 10.2455061,8.49530305 10.3424694,8.23877566 C10.4394328,7.98224826 10.4394328,7.66497598 10.3424694,7.40844858 C10.2455061,7.15192119 10.0666018,6.99588247 9.87426266,7.00008271 L4.54092933,7.00008271 Z"
                id="path-1-cn"
            />
        </defs>
        <g id="Product-Flow-cn" fill="none" fillRule="evenodd">
            <g id="Sign-Up-cn" transform="translate(-761 -362)">
                <g transform="translate(488 273)" id="Text-Fields">
                    <g id="Company-Name" transform="translate(0 72)">
                        <g id="Icon" transform="translate(273 17)">
                            <mask id="mask-2-cn" fill="#fff">
                                <use xlinkHref="#path-1-cn" />
                            </mask>
                            <use id="Mask" fillOpacity="0" fill="#051033" fillRule="nonzero" xlinkHref="#path-1-cn" />
                            <g id="Mask" mask="url(#mask-2-cn)" fill="#C9CED6" fillRule="nonzero">
                                <g id="Shape">
                                    <path
                                        d="M0.6,0 C0.268642874,2.49001666e-05 3.31307432e-05,0.201904687 0,0.450943701 L0,9.46981773 C3.31307432e-05,9.71885674 0.268642874,9.92073653 0.6,9.92076143 L17.4,9.92076143 C17.7313571,9.92073653 17.9999669,9.71885674 18,9.46981773 L18,0.450943701 C17.9999669,0.201904687 17.7313571,2.49001666e-05 17.4,0 L0.6,0 Z M1.2,0.901887403 L16.8,0.901887403 L16.8,9.01887403 L1.2,9.01887403 L1.2,0.901887403 Z"
                                        transform="translate(1)"
                                    />
                                    <path
                                        d="M0.6,0 C0.268642874,2.49001666e-05 3.31307432e-05,0.201904687 0,0.450943701 L0,9.46981773 C3.31307432e-05,9.71885674 0.268642874,9.92073653 0.6,9.92076143 L17.4,9.92076143 C17.7313571,9.92073653 17.9999669,9.71885674 18,9.46981773 L18,0.450943701 C17.9999669,0.201904687 17.7313571,2.49001666e-05 17.4,0 L0.6,0 Z M1.2,0.901887403 L16.8,0.901887403 L16.8,9.01887403 L1.2,9.01887403 L1.2,0.901887403 Z"
                                        transform="translate(1)"
                                    />
                                    <path
                                        d="M4.0040157,2.94117647 C3.6439776,2.9381763 3.30908822,3.04963253 3.12758324,3.23286638 C2.94607826,3.41610024 2.94607826,3.64272329 3.12758324,3.82595715 C3.30908822,4.009191 3.6439776,4.12064723 4.0040157,4.11764706 L13.9874388,4.11764706 C14.3474769,4.12064723 14.6823663,4.009191 14.8638713,3.82595715 C15.0453762,3.64272329 15.0453762,3.41610024 14.8638713,3.23286638 C14.6823663,3.04963253 14.3474769,2.9381763 13.9874388,2.94117647 L4.0040157,2.94117647 Z"
                                        transform="translate(1)"
                                    />
                                    <path
                                        d="M3.6,5.29411765 C3.38361845,5.29111748 3.18235118,5.4025737 3.07326737,5.58580756 C2.96418355,5.76904141 2.96418355,5.99566447 3.07326737,6.17889832 C3.18235118,6.36213218 3.38361845,6.47358841 3.6,6.47058824 L9.6,6.47058824 C9.81638155,6.47358841 10.0176488,6.36213218 10.1267326,6.17889832 C10.2358164,5.99566447 10.2358164,5.76904141 10.1267326,5.58580756 C10.0176488,5.4025737 9.81638155,5.29111748 9.6,5.29411765 L3.6,5.29411765 Z"
                                        transform="translate(1)"
                                    />
                                </g>
                            </g>
                            <g id="Icon-Colour" mask="url(#mask-2-cn)" fill="#C9CED6">
                                <polygon
                                    points="0 0 20 0 20 20 0 20"
                                    transform="translate(-1 -3)"
                                    id="Grey-/-Grey-Base-500"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
