import React, { Component } from 'react'
import { Button, Icon, Typography } from 'antd'
import { CardWrapper } from '../StyleComponent'
import styles from '../style'
import '../style.css'
import { MODAL_TYPES } from '../../../Helpers/ModalTypes'
import { Modal } from '../../../Modals'
import { store } from '../../../../redux/store'
import actionsCandidate from '../../../../redux/candidate/actions'
import { randomString } from '../../../Helpers/utils'
import { FILE_STATUS } from '../../../Helpers/constants'

const { Text, Title } = Typography

export default class DashCard extends Component {
    constructor() {
        super()

        this.state = {
            addTeamMemberModal: false,
            addCandidateModal: false,
            showSelectJobModal: false,
            jobDetails: {},
        }
    }

    handleCancel = () => {
        this.setState({
            addTeamMemberModal: false,
            addCandidateModal: false,
            showSelectJobModal: false,
        })
    }

    showSelectJobModal = () => {
        this.setState({ showSelectJobModal: true })
    }

    openAddCandidateModal = jobDetails => {
        store.dispatch({ type: actionsCandidate.CLEAR_CANDIDATES })
        store.dispatch({ type: actionsCandidate.ADD_CANDIDATES })
        this.setState({ addCandidateModal: true, showSelectJobModal: false, jobDetails })
    }

    render() {
        const { headerLogo, title, details, buttonText } = this.props.data
        return (
            <CardWrapper className="job-descriptions">
                <Icon component={headerLogo} className="header-logo" />
                <Title level={4} style={styles.dashboardCardTitle}>
                    {title}
                </Title>
                <ul className="dashboard-card-info">
                    <li>
                        <Text className="dashboard-card-text">{details[0]}</Text>
                    </li>
                    <li>
                        <Text className="dashboard-card-text">{details[1]}</Text>
                    </li>
                    <li>
                        <Text className="dashboard-card-text">{details[2]}</Text>
                    </li>
                </ul>
                <Button
                    type="primary"
                    className="dashoboard-card-btn"
                    onClick={() => {
                        if (buttonText === 'CREATE JOB OPENING') {
                            this.props.history.push('openings/createJob')
                        } else if (buttonText === 'INVITE TEAM MEMBERS') {
                            this.props.inviteTeamMember()
                        } else if (buttonText === 'ADD CANDIDATE') {
                            this.showSelectJobModal()
                        }
                    }}
                >
                    {buttonText}
                </Button>

                <Modal
                    visible={this.state.showSelectJobModal}
                    type={MODAL_TYPES.SELECT_JOB}
                    companyId={store.getState().Auth.user.companyId}
                    openAddCandidate={jobDetails => this.openAddCandidateModal(jobDetails)}
                    onCancel={() => this.setState({ showSelectJobModal: false })}
                />
                <Modal
                    type={MODAL_TYPES.ADD_CANDIDATE}
                    visible={this.state.addCandidateModal}
                    onCancel={this.handleCancel}
                    onSubmit={this.handleCancel}
                    jobDetails={this.state.jobDetails}
                />
            </CardWrapper>
        )
    }
}
