import React, { useState, useEffect } from 'react'
import { Icon, Input, Select, Popover } from 'antd'
import getAvatar from '../../../../services/getAvatar'
import { ReactComponent as EnvelopeIcon } from '../../../../assets/Icons/Talent Pool/envelope.svg'
import { ReactComponent as PhoneIcon } from '../../../../assets/Icons/Talent Pool/phone.svg'
import { ReactComponent as EditIcon } from '../../../../assets/Icons/Talent Pool/Edit.svg'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import LocationAutoComplete from '../../../Components/locationAutoComplete'
import StyledPhoneNumberInput from '../../../Components/StyledPhoneNumberInput'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Field } from '../../../Modals/AddCandidate/style'
import { EXPERIENCE_OPTIONS } from '../../../Helpers/constants'
import REGEX from '../../../Helpers/RegEx'
import { CandidateDataLoader } from '../Loaders'
import className from 'classnames'
import { ORANGE } from '../../../Helpers/Stylesheet/styledComponents'

const { Option } = Select

const ErrorEvaluator = ({ name, phone, code, experience, email, selective = false }) => {
    const tempError = { name: false, phone: false, experience: false, email: false }

    const selector = selective || 'name' // if the selective===false then it will check all data
    switch (selector) {
        case 'name':
            if (!name) tempError.name = 'Name is required'
            if (selective) break
        case 'email':
            if (!email || email.trim().length === 0) tempError.email = 'Email is required'
            else if (!REGEX.CANDIDATE.EMAIL.test(email)) tempError.email = 'Enter a valid email.'
            if (selective) break
        case 'phone':
            if (!phone) tempError.phone = 'Phone is required'
            else {
                if (phone && !code) code = '+91'
                const phoneObject = parsePhoneNumberFromString(code + phone)
                if (phoneObject && !phoneObject.isValid()) {
                    tempError.phone = 'Phone number is invalid'
                }
            }
            if (selective) break
        case 'experience':
            if (!experience) tempError.experience = 'Experience is required'
            if (selective) break
    }

    return Object.values(tempError).filter(each => each).length ? tempError : false
}

const CandidateData = ({ candidate, flipPage, pageStep, isLoading, isExistingCandidate = true }) => {
    const phoneObject = parsePhoneNumberFromString((candidate.phone || '9650023649').toString()) || {}
    const getEmail = () => (isExistingCandidate ? candidate.email : email)
    const [code, setCode] = useState(
        (phoneObject && phoneObject.countryCallingCode && `+${phoneObject.countryCallingCode}`) || '+91'
    )
    const [editMode, setEditMode] = useState(false)
    const [name, setName] = useState(candidate.name || '')
    const [email, setEmail] = useState(candidate.email || '')

    const [phone, setPhone] = useState(phoneObject && phoneObject.nationalNumber)
    const [location, setLocation] = useState(candidate.currentLocation || '')
    const [experience, setExperience] = useState(candidate.totalExperience || undefined)
    const [showPhoneCodes, setShowPhoneCodes] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (pageStep === 2) {
            const hasError = ErrorEvaluator({ name, phone, code, experience, email })
            if (hasError !== false) {
                setErrors(hasError)
                setEditMode(true)
            }
        }
    }, [pageStep])

    if (isLoading) return <CandidateDataLoader />

    const onSubmit = () => {
        const hasError = ErrorEvaluator({ name, phone, code, experience, email })

        if (hasError !== false) setErrors(hasError)
        else
            flipPage({
                direction: null,
                field: 'candidate',
                name,
                email: getEmail(),
                phone: (code || '+91') + phone,
                currentLocation: location,
                totalExperience: experience,
            })

        setEditMode(!!hasError)
    }

    const onCancel = () => {
        setName(candidate.name)
        setCode((phoneObject.countryCallingCode && `+${phoneObject.countryCallingCode}`) || '+91')
        setPhone(phoneObject.nationalNumber)
        setLocation(candidate.currentLocation)
        setEmail(candidate.email)
        setEditMode(false)
    }

    const nullifyError = type => setErrors({ ...errors, [type]: false })

    const onBlur = (type, data) => {
        const hasError = ErrorEvaluator({ [type]: data, selective: type })
        if (hasError !== false) setErrors({ ...errors, ...hasError })
        else nullifyError(type)
    }

    if (editMode)
        return (
            <div className="pb0">
                <div className="avatar">{getAvatar(name)}</div>
                <div className="name">{name}</div>
                <div className="sidebar-form">
                    <Field>
                        <Input
                            size="large"
                            placeholder="Name"
                            value={name}
                            onBlur={event => onBlur('name', name)}
                            onChange={({ target: { value } }) => setName(value)}
                        />
                        {errors.name && <div className="error">{errors.name}</div>}
                    </Field>
                    <Field hidden={isExistingCandidate}>
                        <Input
                            size="large"
                            placeholder="Email"
                            value={email}
                            onBlur={event => onBlur('email', email)}
                            onChange={({ target: { value } }) => setEmail(value)}
                        />
                        {errors.email && <div className="error">{errors.email}</div>}
                    </Field>
                    <Field>
                        <div className="phone">
                            <Popover
                                arrowPointAtCenter
                                placement="bottomLeft"
                                content={
                                    <StyledPhoneNumberInput
                                        onSelected={({ key, code }) => {
                                            setShowPhoneCodes(false)
                                            let phoneError = false
                                            // calculate the error
                                            if (phone && phone.length) {
                                                const phoneObject = parsePhoneNumberFromString(code + phone)
                                                if (phoneObject && !phoneObject.isValid()) {
                                                    phoneError = 'Phone number is invalid'
                                                }
                                            }
                                            setCode(code)
                                            setErrors({ ...errors, phone: phoneError })
                                        }}
                                    />
                                }
                                visible={showPhoneCodes}
                            >
                                <div className={`country-code mt30`} onClick={() => setShowPhoneCodes(true)}>
                                    {code || '+91'}
                                </div>
                            </Popover>
                            <Input
                                size="large"
                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                placeholder={'Candidate phone number'}
                                value={phone}
                                onChange={({ target: { value } }) => {
                                    let number = parseInt(value)
                                    number = isNaN(number) ? '' : number.toString()
                                    setPhone(number)

                                    let phoneError = false // by default we assume there are no errors
                                    if (value && value.length) {
                                        const evaluatedCode = code ? code : '+91'
                                        const phoneObject = parsePhoneNumberFromString(evaluatedCode + value)
                                        if (phoneObject && !phoneObject.isValid()) {
                                            phoneError = 'Phone number is invalid'
                                        }
                                    }
                                    setErrors({ ...errors, phone: phoneError })
                                }}
                            />
                        </div>
                        {errors.phone && <div className="error">{errors.phone}</div>}
                    </Field>
                    <Field>
                        <Select
                            size="large"
                            value={experience}
                            placeholder="Experience"
                            onChange={value => {
                                setExperience(value)
                                nullifyError('experience')
                            }}
                            getPopupContainer={trigger => trigger.parentNode}
                            suffixIcon={<Icon component={DownIcon} />}
                        >
                            {EXPERIENCE_OPTIONS.map(each => (
                                <Option key={each} value={each}>
                                    {each}
                                </Option>
                            ))}
                        </Select>
                        {errors.experience && <div className="error">{errors.experience}</div>}
                    </Field>
                    <Field>
                        <LocationAutoComplete
                            size="large"
                            className="candidate__input"
                            placeholder="Enter current location"
                            onLocationSelect={loc => setLocation(loc)}
                            initialValue={location}
                        />
                    </Field>
                    <div className="sidebar-buttons">
                        <button onClick={onCancel}>CANCEL</button>
                        <button onClick={onSubmit}>SAVE</button>
                    </div>
                </div>
            </div>
        )
    const combinedPhone = phone ? (code || '+91') + phone : ''
    return (
        <div className="mb10 p30">
            <div className="avatar">{getAvatar(name || 'Candidate')}</div>
            <div className={className({ name: true, warning: !name })}>{name || 'Candidate'}</div>
            <div className={className({ label: true, warning: !candidate.email })}>
                <EnvelopeIcon />
                {candidate.email || 'NA'}
            </div>
            <div className={className({ label: true, warning: !combinedPhone })}>
                <PhoneIcon />
                {combinedPhone || 'NA'}
            </div>
            <div className="label small">
                <span>Experience:</span>{' '}
                {experience ? <span>{experience}</span> : <span style={{ color: ORANGE }}>NA</span>}
            </div>
            <div className="label small">
                <span>Location:</span> <span>{location || 'NA'}</span>
            </div>
            <div
                className="edit"
                onClick={() => {
                    setEditMode(true)
                    setErrors({})
                }}
            >
                <EditIcon /> Edit
            </div>
        </div>
    )
}

export default CandidateData
