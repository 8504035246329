import React from 'react'

const ZoomDocumentation = props => (
    <iframe
        title="Zoom Documentation"
        key="documentation-zoom-iframe-key"
        style={{ minWidth: '100%', minHeight: 'calc(100vh - 8px)' }}
        src="https://springrecruit-storage-prod.s3.amazonaws.com/externalSource/Zoom/Zoom_+FInal+Website+Submisstion+.pdf"
        allowFullScreen
    ></iframe>
)

export default ZoomDocumentation
