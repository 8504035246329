import { success, warning } from '../../../services/Notification'
import { store } from '../../../redux/store'
import apiCaller from '../../apiCaller'
import ajaxCaller from '../../apiCaller'
import { actions } from '../../../redux/sourcing/actions'
import { LogE } from '../../../container/Helpers/errorHandler'
import { EVENTS } from '../../../container/Pages/Sourcing/constants'

const { restricted } = apiCaller.type
const { formatError } = apiCaller
const axios = ajaxCaller.wrappers.axios
const baseUrl = 'sourcing/talentpool/'

export const setApiProcess = () => dispatch => {
    dispatch({ type: actions.SET_CALL_IN_PROGRESS })
    return true
}

const getUpdateUniversalCandidateSuccess = ({ ids, successMsg, totalCount, name }) =>
    `${ids.length === 1 ? name || 'candidate' : ids.length.toString() + ' candidates'} ${successMsg}${
        totalCount && totalCount !== ids.length ? `, ignored ${totalCount}` : ''
    }`

export const getApplications = filters => async dispatch => {
    const currentPage = filters.currentPage || 1
    const query = filters.query || ''

    filters.interested_jobs = filters.interested_jobs || []
    filters.skills = filters.skills || []
    filters.assigned_jobs = filters.assigned_jobs || []
    filters.locations = filters.locations || []
    filters.tags = filters.tags || []

    const data = {
        filters,
        query,
        pageNo: currentPage,
    }
    try {
        let response = await restricted.post(`${baseUrl}search`, data)
        response = response.data
        const payload = {
            mainFilter: filters.main_filter,
            applications: response.search_results || [],
            interestedJobs: response.filters.interested_jobs || [],
            locations: response.filters.locations || [],
            assignedJobs: response.filters.assigned_jobs || [],
            skills: response.filters.key_skills || [],
            tags: response.filters.tags || [],
            countData: {
                all: response.filters.all,
                assignedJob: response.filters.assigned_to_job,
                unassignedJob: response.filters.unassigned_to_job,
                hidden: response.filters.hidden,
                favourite: response.filters.starred,
            },
            currentPage,
            dataChange: filters.dataChange || false,
            hasNextPage: (response.search_results && response.search_results.length === 8) || false,
        }
        dispatch({ type: actions.GET_APPLICATIONS, payload })
        return true
    } catch (error) {
        warning(formatError(error))
        return false
    }
}

export const UpdateUniversalCandidate = (input, shouldDispatch = true) => async dispatch => {
    try {
        let bodyData = {}
        let successMsg = ''
        const { update, ids, data, totalCount = 0, name } = input
        if (update === EVENTS.ADD_JOB) {
            successMsg = 'added to opening'
            bodyData = { universalCandidateIds: ids, jobId: data.jobId, stageId: data.stageId }
        } else if (update === EVENTS.MARK_FAV) {
            successMsg = (data ? 'marked as' : 'removed from') + ' favourite'
            bodyData = { starred: data, ids }
        } else if (update === EVENTS.HIDE_CANDIDATE) {
            successMsg = (data ? 'marked as' : 'removed from') + ' hidden'
            bodyData = { hidden: data, ids }
        }

        if (shouldDispatch) {
            dispatch({ type: actions.UPDATE_APPLICATIONS, payload: input })
        }

        const { data: response } = await restricted.post(`${baseUrl}updatePreferrences`, bodyData)
        if (response.error) {
            LogE('networkcalls-sourcingtalentpool-updateuniversalcandidate', 'Failed to update', response.error)
            warning('Failed to update universal candidate')
        } else success(getUpdateUniversalCandidateSuccess({ ids, successMsg, totalCount, name }))
    } catch (error) {
        LogE('networkcalls-sourcingtalentpool-updateuniversalcandidate-catch', 'Failed to update', error)
        warning(formatError(error))
    }
}

export const AddNote = input => async dispatch => {
    try {
        const { data } = await restricted.post(`${baseUrl}note`, input)
        if (data.error) {
            LogE('networkcalls-sourcingtalentpool-addnote', 'Failed to add', data.error)
            warning('Failed to add note')
            return null
        }
        success('Note added successfully')
        return data
    } catch (error) {
        LogE('networkcalls-sourcingtalentpool-addnote-catch', 'Failed to add', error)
        warning(formatError(error))
    }
}

export const assignToJob = input => async dispatch => {
    try {
        const { data } = await restricted.post(`${baseUrl}/assignToJob`, input)
        if (data && data.error) {
            LogE('NetworkCalls-STP-AssignToJob-api-error', 'Failed to ', data)
            warning(data.error.msg)
            return null
        } else {
            const { universalCandidateIds, jobId, stageId, jobTitle, stageName } = input
            let successMsg = ` has been added to stage ${stageName} in ${jobTitle} opening`

            if (universalCandidateIds.length === 1) successMsg = input.name + successMsg
            else successMsg = universalCandidateIds.length + ' Applications' + successMsg

            success(successMsg)

            const payload = {
                ids: universalCandidateIds,
                update: 'add_to_job',
                data: { jobId, stageId },
            }
            dispatch({ type: actions.UPDATE_APPLICATIONS, payload })
            return data
        }
    } catch (error) {
        warning(formatError(error))
    }
}

export const UpdateNote = input => async dispatch => {
    try {
        const { data } = await restricted.put(`${baseUrl}note`, input)
        if (data.error) {
            LogE('networkcalls-sourcingtalentpool-updatenote', 'Failed to update', data.error)
            warning('Failed to update note')
            return null
        }
        success('Note updated successfully')
        return data
    } catch (error) {
        LogE('networkcalls-sourcingtalentpool-updatenote-catch', 'Failed to update', error)
        warning(formatError(error))
    }
}

export const DeleteNote = (noteId, universalCandidateId) => async dispatch => {
    try {
        const { data } = await restricted.delete(`${baseUrl}note/${noteId}/${universalCandidateId}`)
        if (data.error) {
            LogE('networkcalls-sourcingtalentpool-deletenote', 'Failed to delete', data.error)
            warning('Failed to delete note')
            return null
        }
        success('Note deleted successfully')
        return data
    } catch (error) {
        LogE('networkcalls-sourcingtalentpool-deletenote-catch', 'Failed to delete', error)
        warning(formatError(error))
    }
}

export const sendEmail = async input => {
    try {
        const { data } = await restricted.post(`${baseUrl}email`, input)
        if (data && data.error) {
            LogE('NetworkCalls-SendEmail-api-error', 'Failed to ', data)
            warning(data.error.msg)
            return null
        } else {
            success('Email sent successfully')
            return data
        }
    } catch (error) {
        warning(formatError(error))
    }
}

export const GetEmails = universalCandidateId => async dispatch => {
    try {
        const { data } = await restricted.get(`${baseUrl}emails/${universalCandidateId}`)
        if (data.error) {
            LogE('networkcalls-sourcingtalentpool-getemails', 'Failed to fetch', data.error)
            warning('Failed to fetch emails')
            return null
        }
        return data
    } catch (error) {
        LogE('networkcalls-sourcingtalentpool-getemails-catch', 'Failed to fetch', error)
        warning(formatError(error))
    }
}

export const SendEmail = input => async dispatch => {
    try {
        const formData = new FormData()
        Object.keys(input).forEach(key => {
            if (key === 'attachments') {
                input.attachments.forEach(x => {
                    formData.append('attachments', x, x.name)
                })
            } else {
                formData.append(key, input[key])
            }
        })
        const { data } = await axios.post(`me/${baseUrl}email`, formData, {
            headers: {
                Authorization: store.getState().Auth.idToken,
                'Content-Type': 'multipart/form-data',
            },
        })
        if (data.error) {
            LogE('networkcalls-sourcingtalentpool-sendemail', 'Failed to send', data.error)
            warning('Failed to send email')
            return null
        }
        success('Email sent successfully')
        return data
    } catch (error) {
        LogE('networkcalls-sourcingtalentpool-sendemail-catch', 'Failed to send', error)
        warning(formatError(error))
    }
}

export const UpdateEmailPrivacy = (emailId, input) => async dispatch => {
    try {
        const { data } = await restricted.patch(`${baseUrl}email/${emailId}`, input)
        if (data.error) {
            LogE('networkcalls-sourcingtalentpool-updateemailprivacy', 'Failed to update', data.error)
            warning('Failed to update email privacy')
            return null
        }
        return data
    } catch (error) {
        LogE('networkcalls-sourcingtalentpool-updateemailprivacy-catch', 'Failed to update', error)
        warning(formatError(error))
    }
}

export const UpdateSourcingTags = (input, shouldDispatch = true) => async dispatch => {
    try {
        const { data } = await restricted.post(`${baseUrl}updateTags`, input)
        if (data.error) {
            LogE('networkcalls-sourcingtalentpool-updatesourcingtags', 'Failed to update', data.error)
            warning('Failed to update tags')
            return null
        } else {
            success('Tags updated successfully')
            if (shouldDispatch) {
                const payload = {
                    addedTags: data.result,
                    deletedTags: input.deletedTags,
                    universalCandidateIds: input.uCandidateList,
                }
                dispatch({ type: actions.UPDATE_TAGS, payload })
            }
            return data
        }
    } catch (error) {
        LogE('networkcalls-sourcingtalentpool-updatesourcingtags-catch', 'Failed to update', error)
        warning(formatError(error))
    }
}

export const UpdateTags = async input => {
    try {
        const { data } = await restricted.post(`jobApplication/bulk/updateTags`, input)
        if (data.error) {
            LogE('networkcalls-sourcingtalentpool-updatetags', 'Failed to update', data.error)
            warning('Failed to update tags')
            return null
        }
        success('Tags updated successfully')
        return data
    } catch (error) {
        LogE('networkcalls-sourcingtalentpool-updatetags-catch', 'Failed to update', error)
        warning(formatError(error))
    }
}
