import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'antd'
import ComponentContainer from '../ComponentContainer'
import StyledSwitch from '../../../../Components/StyledSwitch'
import { Modal, FSModal } from '../../../../Modals'
import { MODAL_TYPES, FSMODAL_TYPES } from '../../../../Helpers/ModalTypes'
import { ACCESS_LEVEL_DEFINITIONS } from '../../../../Helpers/constants'
import { success, warning } from '../../../../../services/Notification'
import {
    ResendUserInvite,
    AddTeamMember,
    UpdateTeamMember,
    RemoveTeamMember,
} from '../../../../../services/NetworkCalls/User'
import _ from 'lodash'
import classNames from 'classnames'
import { SendInvite, AddSendInvites, InviteMenu } from './style.js'

const TeamMemberCard = ({ invite, currentUserId, updateTeamMember, resendInvite, teamMemberManipulation }) => (
    <SendInvite>
        <div className="sendInviteUser">
            <h6>{invite.fullName || invite.email}</h6>
            <span>
                {_.values(ACCESS_LEVEL_DEFINITIONS).find(role => role.accessLevel === invite.accessLevel).name}
                {invite._id !== currentUserId && (
                    <img src={require('./images/edit.svg').default} onClick={() => updateTeamMember(invite)} />
                )}
            </span>
        </div>
        <div className="sendInviteStatus">
            <div className="sendInviteStatuslabel">
                {invite.status === 0 && <span className="statusPending">Pending </span>}
                {invite.status === 1 && <span className="statusAccepted">Accepted </span>}
                {invite.status === 2 && <span className="statusDisabled">Disabled </span>}
                {invite.status === 0 && (
                    <Tooltip title="resend invite">
                        <span className="resend-icon">
                            <img src={require('./images/resend-icon.svg').default} onClick={() => resendInvite(invite)} />
                        </span>
                    </Tooltip>
                )}
                <span>
                    <StyledSwitch
                        disable={invite.status === 0}
                        checked={invite.status === 1}
                        onChange={() => teamMemberManipulation(invite)}
                    />
                </span>
            </div>
        </div>
    </SendInvite>
)

class TeamMembers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedUser: null,
            inviteModalVisible: false,
            enable: false,
            callInProgress: false,
            deleteUserSelected: null,
            selectedTab: 0,
        }
    }

    showInviteTeamMemberModal = () => {
        this.setState({ inviteModalVisible: true })
    }

    updateTeamMember = invite => {
        this.setState({ selectedUser: invite, inviteModalVisible: true })
    }

    resendInvite = ({ _id, email }) => {
        this.props.ResendUserInvite(_id, email, this.props.companyId)
    }

    showDeleteUserConfirmModal = user => {
        this.setState({ deleteUserSelected: user })
    }

    teamMemberManipulation = user => {
        if (user.status === 1) {
            if (this.props.user.accessLevel > 2) warning('You are not allowed to disable a team member')
            else this.showDeleteUserConfirmModal(user)
        } else if (user.status === 2) {
            this.enableTeamMember(user)
        }
    }

    saveTeamMember = ({ newUser, email, accessibleJobs, accessLevel, teamMemberId }) => {
        try {
            this.setState({ callInProgress: true }, async () => {
                let details = {
                    email,
                    accessibleJobs,
                    accessLevel,
                    userId: this.props.user.id,
                    companyId: this.props.user.companyId,
                    enable: this.state.enable,
                }
                newUser ? this.props.AddTeamMember(details) : this.props.UpdateTeamMember(details, teamMemberId)
                this.setState({
                    inviteModalVisible: false,
                    selectedUser: null,
                    enable: false,
                    callInProgress: false,
                })
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    enableTeamMember = user => {
        this.setState({
            enable: true,
            selectedUser: user,
            inviteModalVisible: true,
        })
    }

    disableUser = async () => {
        try {
            const userId = this.state.deleteUserSelected._id
            this.props.RemoveTeamMember(userId)
            this.setState({ deleteUserSelected: null })
        } catch (err) {
            warning(err.message || err)
        }
    }

    handleCancel = () => {
        this.setState({
            selectedUser: null,
            inviteModalVisible: false,
            enable: false,
            deleteUserSelected: null,
        })
    }

    render() {
        if (this.props.hidden) return null

        const {
            currentUserId,
            allTeamMembers,
            acceptedTeamMembers,
            pendingTeamMembers,
            disabledTeamMembers,
        } = this.props
        const { selectedTab } = this.state
        return (
            <ComponentContainer
                heading="User accounts"
                headingOptions={
                    <AddSendInvites>
                        <span onClick={() => this.showInviteTeamMemberModal()}>+ INVITE MEMBER</span>
                    </AddSendInvites>
                }
            >
                <InviteMenu>
                    <span
                        className={classNames({ current: selectedTab === 0 })}
                        onClick={() => this.setState({ selectedTab: 0 })}
                    >
                        ALL ({allTeamMembers.length})
                    </span>
                    <span
                        className={classNames({ current: selectedTab === 1 })}
                        onClick={() => this.setState({ selectedTab: 1 })}
                    >
                        ACCEPTED ({acceptedTeamMembers.length})
                    </span>
                    <span
                        className={classNames({ current: selectedTab === 2 })}
                        onClick={() => this.setState({ selectedTab: 2 })}
                    >
                        PENDING ({pendingTeamMembers.length})
                    </span>
                    <span
                        className={classNames({ current: selectedTab === 3 })}
                        onClick={() => this.setState({ selectedTab: 3 })}
                    >
                        DISABLED ({disabledTeamMembers.length})
                    </span>
                </InviteMenu>
                <div className="sendInviteWrapper">
                    {[allTeamMembers, acceptedTeamMembers, pendingTeamMembers, disabledTeamMembers][selectedTab].map(
                        invite => (
                            <TeamMemberCard
                                key={invite._id}
                                invite={invite}
                                currentUserId={currentUserId}
                                updateTeamMember={this.updateTeamMember}
                                resendInvite={this.resendInvite}
                                teamMemberManipulation={this.teamMemberManipulation}
                            />
                        )
                    )}
                </div>

                <Modal
                    member={this.state.deleteUserSelected}
                    type={MODAL_TYPES.CONFIRM_DISABLE_TEAM_MEMBER}
                    visible={this.state.deleteUserSelected !== null}
                    onCancel={this.handleCancel}
                    onConfirm={this.disableUser}
                />

                <FSModal
                    visible={this.state.inviteModalVisible}
                    type={FSMODAL_TYPES.INVITE_TEAM_MEMBER}
                    onCancel={this.handleCancel}
                    onConfirm={this.saveTeamMember}
                    selectedUser={this.state.selectedUser}
                    callInProgress={this.state.callInProgress}
                />
            </ComponentContainer>
        )
    }
}

const mapStateToProps = state => {
    const teamMembers = state.Mixed.teamMembers
    const acceptedTeamMembers = [],
        pendingTeamMembers = [],
        disabledTeamMembers = []
    teamMembers.forEach(member => {
        if (member._id !== state.Auth.user.id) {
            if (member.status === 0) pendingTeamMembers.push(member)
            else if (member.status === 1) acceptedTeamMembers.push(member)
            else if (member.status === 2) disabledTeamMembers.push(member)
        }
    })
    return {
        user: state.Auth.user,
        allTeamMembers: teamMembers.sort((a, b) => (b.created > a.created ? 1 : -1)),
        acceptedTeamMembers: acceptedTeamMembers.sort((a, b) => (b.created > a.created ? 1 : -1)),
        pendingTeamMembers: pendingTeamMembers.sort((a, b) => (b.created > a.created ? 1 : -1)),
        disabledTeamMembers: disabledTeamMembers.sort((a, b) => (b.created > a.created ? 1 : -1)),
    }
}

export default connect(
    mapStateToProps,
    { ResendUserInvite, AddTeamMember, UpdateTeamMember, RemoveTeamMember }
)(TeamMembers)
