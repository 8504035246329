import React from 'react'

const SVGComponent = () => (
    <svg
        width="16"
        height="8"
        viewBox="0 0 16 8"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M1.41153437,3.94 C1.41153437,2.68 2.27886272,1.66 3.35026832,1.66 L7.15971047,1.66 L7.15971047,0 L3.35026832,0 C1.49656656,0 0,1.76 0,3.94 C0,6.12 1.49656656,7.88 3.35026832,7.88 L7.15971047,7.88 L7.15971047,6.22 L3.35026832,6.22 C2.27886272,6.22 1.41153437,5.2 1.41153437,3.94 Z M12.4997321,0 L8.69028991,0 L8.69028991,1.66 L12.4997321,1.66 C13.5711377,1.66 14.438466,2.68 14.438466,3.94 C14.438466,5.2 13.5711377,6.22 12.4997321,6.22 L8.69028991,6.22 L8.69028991,7.88 L12.4997321,7.88 C14.3534338,7.88 15.8500004,6.12 15.8500004,3.94 C15.8500004,1.76 14.3534338,0 12.4997321,0 Z M3.89447434,3.94 C3.89447434,4.4 4.21759667,4.78 4.60874475,4.78 L11.2582621,4.78 C11.6494102,4.78 11.9725325,4.4 11.9725325,3.94 C11.9725325,3.48 11.6494102,3.1 11.2582621,3.1 L4.60874475,3.1 C4.21759667,3.1 3.89447434,3.48 3.89447434,3.94 Z"
                id="path-1-url"
            />
        </defs>
        <g id="Product-Flow-url" fill="none" fillRule="evenodd">
            <g id="Sign-Up-url" transform="translate(-760 -437)">
                <g transform="translate(488 273)" id="Text-Fields">
                    <g id="Website-URL" transform="translate(0 144)">
                        <g id="Icon" transform="translate(272 20)">
                            <mask id="mask-2" fill="#fff">
                                <use xlinkHref="#path-1-url" />
                            </mask>
                            <use id="Mask" fill="#C9CED6" fillRule="nonzero" xlinkHref="#path-1-url" />
                            <g id="Mask" mask="url(#mask-2)" fill="#C9CED6" fillRule="nonzero">
                                <g id="Shape">
                                    <path
                                        d="M0.6,0 C0.268642874,2.49001666e-05 3.31307432e-05,0.201904687 0,0.450943701 L0,9.46981773 C3.31307432e-05,9.71885674 0.268642874,9.92073653 0.6,9.92076143 L17.4,9.92076143 C17.7313571,9.92073653 17.9999669,9.71885674 18,9.46981773 L18,0.450943701 C17.9999669,0.201904687 17.7313571,2.49001666e-05 17.4,0 L0.6,0 Z M1.2,0.901887403 L16.8,0.901887403 L16.8,9.01887403 L1.2,9.01887403 L1.2,0.901887403 Z"
                                        transform="translate(0 -6)"
                                    />
                                    <path
                                        d="M0.6,0 C0.268642874,2.49001666e-05 3.31307432e-05,0.201904687 0,0.450943701 L0,9.46981773 C3.31307432e-05,9.71885674 0.268642874,9.92073653 0.6,9.92076143 L17.4,9.92076143 C17.7313571,9.92073653 17.9999669,9.71885674 18,9.46981773 L18,0.450943701 C17.9999669,0.201904687 17.7313571,2.49001666e-05 17.4,0 L0.6,0 Z M1.2,0.901887403 L16.8,0.901887403 L16.8,9.01887403 L1.2,9.01887403 L1.2,0.901887403 Z"
                                        transform="translate(0 -6)"
                                    />
                                    <path
                                        d="M3.6,5.29411765 C3.38361845,5.29111748 3.18235118,5.4025737 3.07326737,5.58580756 C2.96418355,5.76904141 2.96418355,5.99566447 3.07326737,6.17889832 C3.18235118,6.36213218 3.38361845,6.47358841 3.6,6.47058824 L9.6,6.47058824 C9.81638155,6.47358841 10.0176488,6.36213218 10.1267326,6.17889832 C10.2358164,5.99566447 10.2358164,5.76904141 10.1267326,5.58580756 C10.0176488,5.4025737 9.81638155,5.29111748 9.6,5.29411765 L3.6,5.29411765 Z"
                                        transform="translate(0 -6)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
