import React from 'react'

const SVGComponent = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="26"
        height="30"
        viewBox="0 0 26 30"
    >
        <image
            id="image0"
            width="26"
            height="30"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAMAAAAFBf7qAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABRFBMVEUAAAAAgP8AgP8AgP8A gP8AgP8AgP8AgP8AgP8AgP8AgP8Af/8AgP8Af/8AgP8Af/8AgP8AgP8Ae/YAefIAd+4AcuUAdegA fvsAgP8AgP8AgP8AgP8AgP8AgP8Aif8Af/8Ae/gAcuUAefQAd+8Afv4AdesAffxKpP+w1/+ezv9Q p/80mf+j0P8qlP8LhP+Jw/+t1f9arP9rtP+dzf85m//D4P9Zq/8TiP/g7/9Io/9TqP8Ig/+gz/+K xP8ikP/G4v8zmP+Qx/9Rp/+02f9Mpf+12f/L5P83mv/p8/8Xiv8Yi/89nf9brP+l0f+63P9Fof/3 +v8Jg/8DgP+Yy/+Fwf8mkv/i8P8Mhf8Phv/u9v8Uif/F4f9Jo/+Nxf/o8//T6P8Ggv9xt//b7P/c 7f9jsP8vlv/X6v/H4v+n0v8CgP8Fgf/////OWue+AAAAH3RSTlMAHKblgwjtoYSu5qOvp8+l9MCu rYfU1NHRt3jT3JENjOGO9wAAAAFiS0dEa1JlpZgAAAAHdElNRQfjBhgGOQhCOzBmAAAA9ElEQVQo z2NgYGRilkcGLKwMUMDIJo8K2Dlgckzy6FKcMDkuTCmYHLqMPDcnTA5DiocTJochxcYLk8OQkufj h8phSskLCAoJCwuLYJOSl1dQVFQUxS4lr4RbSl4Zt5SKKk4peTUxnFLy4rilJCiQUtfQ1JKX19bR 1JXX0zcwRJYyMjYxNTO3sLSysLaxtbN3QJZylHdydnGVl3dz9/CU9/JElfI29fGVl/fzDwhEtcvI MSg4JDQsPCIyKjpGPtYfWSouXjNBPjEpOUU+NS09IwjT8UGZIDKLGH9x4ZKSxEyiMCCFmbChQBqY pNCzAxjIyMoxAABxxWESy0F9AQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNi0yNFQwNjo1Nzow OCswMzowMFv3QzMAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDYtMjRUMDY6NTc6MDgrMDM6MDAq qvuPAAAAAElFTkSuQmCC"
        />
    </svg>
)

export default SVGComponent
