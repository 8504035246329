import config from '../../../config'
import ajaxCaller from '../../apiCaller'

const { baseURL, apiVersion } = config.config()
const API_VERSION = apiVersion.v2

const fetch = ajaxCaller.wrappers.fetch

const apiCaller = async ({ path, method, body }) => {
    const url = `${baseURL}${API_VERSION}backToWork${path}`
    const args = {
        method,
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
    }

    const res = await fetch(url, args)
    return res
}

export const subscribeToMailchimp = async ({ name, email }) => {
    try {
        const payload = {
            body: {
                name,
                email,
            },
            path: '/subscribe',
            method: 'POST',
        }

        const res = await apiCaller(payload)
        return res
    } catch (error) {
        throw error
    }
}
