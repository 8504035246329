import React, { useState } from 'react'
import { Icon, Input, Skeleton, Spin } from 'antd'
import { Tabs } from '../../Analytics/style'
import { Avatar, Contacts } from '../style'
import { Tags } from '../../../Modals/style'
import getAvatar from '../../../../services/getAvatar'
import { connect } from 'react-redux'
import { getInboxEmails } from '../../../../services/NetworkCalls/Inbox'
import { ModifyCacheData } from '../../../../redux/auth/actionListeners'
import className from 'classnames'
import { Form, SearchBox } from '../../Sourcing/style'
import { ReactComponent as SearchIcon } from '../../../../assets/Icons/Talent Pool/Search.svg'
import { ReactComponent as RefreshIcon } from '../../../../assets/Icons/Openings/retry.svg'
import { EVENTS, TAB_MODE } from '../Components/constants'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller'
import { ReactComponent as InfoIcon } from '../../../../assets/Icons/Talent Pool/info.svg'

const isToday = date => moment(date).isSame(moment(), 'day')

const ContactsSection = ({
    contacts,
    getInboxEmails,
    mainFilter,
    jobObj,
    callInProgress,
    contactsData,
    selectedContact,
    ModifyCacheData,
    handleFilterUpdates,
    switchTabMode,
    toggleNewMessage,
    hasNextPage,
}) => {
    const [tabMode, setTabMode] = useState(TAB_MODE.ALL)

    const handleTabChange = mode => {
        setTabMode(mode)
        switchTabMode(mode)
    }

    const getNextPage = () => handleFilterUpdates(EVENTS.NEXT_PAGE)

    const handleRefresh = () => {
        switchTabMode(tabMode)
    }

    return (
        <Contacts>
            <div className="head">
                <ContactSearch handleFilterUpdates={handleFilterUpdates} />
                <div className="flex">
                    <Tabs>
                        <div
                            className={className({ active: tabMode === TAB_MODE.ALL })}
                            onClick={() => handleTabChange(TAB_MODE.ALL)}
                        >
                            All
                        </div>
                        <div
                            className={className({ active: tabMode === TAB_MODE.UNREAD })}
                            onClick={() => handleTabChange(TAB_MODE.UNREAD)}
                        >
                            UNREAD
                        </div>
                    </Tabs>
                    <RefreshIcon onClick={handleRefresh} />
                </div>
            </div>
            <div className="list">
                {callInProgress ? (
                    <div
                        style={{
                            width: '100%',
                            height: 500,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Spin />
                    </div>
                ) : (
                    <InfiniteScroll
                        useWindow={false}
                        loadMore={getNextPage}
                        hasMore={hasNextPage}
                        style={{ height: 'calc(100% - 149px)' }}
                        loader={
                            <div
                                className="ats-skeleton"
                                key="something-random-key"
                                style={{ width: '100%', paddingLeft: '25px' }}
                            >
                                <Skeleton avatar={{ shape: 'square' }} paragraph={{ rows: 1 }} />
                            </div>
                        }
                    >
                        {contacts.map(({ _id, jobId, ...rest }) => (
                            <ContactsCard
                                key={_id}
                                {...rest}
                                isSelected={selectedContact === _id}
                                cardOnClickHandler={() => {
                                    if (selectedContact !== _id) {
                                        toggleNewMessage(false)
                                        ModifyCacheData(true, 'pullToRefresh')
                                        getInboxEmails({
                                            candidateId: _id,
                                            shouldLoad: !contactsData.hasOwnProperty(_id),
                                        })
                                    }
                                }}
                                opening={jobObj[jobId]}
                            />
                        ))}
                    </InfiniteScroll>
                )}
            </div>
        </Contacts>
    )
}

const ContactSearch = ({ handleFilterUpdates }) => {
    const [search, setSearch] = useState('')
    return (
        <Form className="search">
            <SearchBox
                onSearch={() => search.length && handleFilterUpdates(EVENTS.SEARCH, search)}
                onChange={({ target: { value } }) => {
                    if (value.length === 0) {
                        handleFilterUpdates(EVENTS.SEARCH, value)
                    }
                    if (search && search.length && search.length === 0) {
                        handleFilterUpdates(EVENTS.SEARCH, value)
                    }
                    setSearch(value)
                }}
                placeholder="Search by name or email"
            />
            <button onClick={() => search.length && handleFilterUpdates(EVENTS.SEARCH, search)}>
                <SearchIcon />
            </button>
        </Form>
    )
}

const ContactsCard = ({
    name,
    email,
    opening,
    cardOnClickHandler,
    emailData: { count, latestEmailTime, manualEmailCount },
    isSelected,
}) => {
    const timeShow = moment(latestEmailTime).format(isToday(latestEmailTime) ? 'LT' : 'D MMM')

    return (
        <div className={className({ item: true, active: isSelected })} onClick={cardOnClickHandler}>
            <Avatar className={className({ online: manualEmailCount > 0 })}>
                {name ? getAvatar(name) : <InfoIcon style={{ width: '15px', height: '15px' }} />}
            </Avatar>
            <div>
                <div className="flex">
                    <div className={className({ name: true, 'name-warning': !name })} title={name || 'Candidate'}>
                        {name || 'Candidate'}
                        {count ? '(' + count + ')' : null}
                    </div>
                    <div className="time">{timeShow}</div>
                </div>
                <div title={email} className="email">
                    {email}
                </div>
                <Tags hidden={!opening}>
                    <div>
                        <span>{opening}</span>
                    </div>
                    {/*<div>
                        <span>UI Designer</span>
                    </div>*/}
                </Tags>
            </div>
        </div>
    )
}

const mapStateToProps = ({
    Inbox: { contacts, callInProgress, mainFilter, contactsData, selectedContact, selectedFilter, hasNextPage },
    Job: { allJobs },
}) => {
    const jobObj = {}
    allJobs.forEach(({ _id, jobTitle }) => (jobObj[_id] = jobTitle))
    return {
        jobObj,
        contacts,
        callInProgress,
        mainFilter,
        contactsData,
        selectedContact,
        selectedFilter,
        hasNextPage,
    }
}

export default connect(
    mapStateToProps,
    { getInboxEmails, ModifyCacheData }
)(ContactsSection)
