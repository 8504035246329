import usePlacesAutocomplete from 'use-places-autocomplete'
import React, { useRef, useState, useEffect } from 'react'
import './style.css'
import { Input, List } from 'antd'
import googleLogo from '../../../assets/Icons/Extras/google_logo.png'
import { reducePropsToState } from 'react-helmet/es/HelmetUtils'

const LocationAutoComplete = props => {
    let [valueChange, setValueChange] = useState(false)
    let {
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: { types: ['(cities)'] },
        debounce: 300,
    })

    const useOutsideAlerter = ref => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                clearSuggestions()
            }
        }

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        })
    }
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef)

    const handleInput = e => {
        setValueChange(true)
        setValue(e.target.value)
        props.onLocationSelect(e.target.value)
    }

    const handleSelect = ({ description }) => () => {
        setValue(description, false)
        clearSuggestions()
        props.onLocationSelect(description)
        if (props.handleSelect) {
            props.handleSelect(description)
        }
    }

    const renderSuggestions = () => {
        data = data.map(suggestion => {
            const {
                id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion

            return (
                <li className="autocomplete-item" key={id} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            )
        })
        return data
    }
    return (
        <div className="location-auto-complete-list" ref={wrapperRef}>
            <Input
                size={props.size || 'default'}
                onChange={handleInput}
                value={valueChange ? value : props.initialValue}
                className={props.className}
                placeholder={props.placeholder}
            />
            {data.length ? (
                <div className="autocomplete-container">
                    <ul className="autocomplete-list">{renderSuggestions()}</ul>
                    <div className="autocomplete-item-last">
                        <div className="autocomplete-branding">
                            Powered by <img src={googleLogo} className="google-logo" alt="googleLogo" />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default LocationAutoComplete
