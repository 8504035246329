import React from 'react'
import Candidate from '../Pages/Candidate'
import Job from '../Pages/Job'
import Team_Member from '../Pages/TeamMember'

export default {
    CANDIDATE: {
        Overview: Candidate.Overview,
        Progress: Candidate.Progress,
        Source: Candidate.Source,
        TSA: Candidate.TSA,
    },
    JOB: {
        Overview: Job.Overview,
        Timeline: Job.Timeline,
        Stage: Job.Stage,
    },
    TEAM_MEMBER: {
        Overview: Team_Member.Overview,
        Member: Team_Member.Member,
    },
}
