/* ==========================
    Modal Types
    The values for each key should be the same as the component name under Modals
 ========================== */

export const MODAL_TYPES = {
    CONFIRM_GOOGLE_DISCONNECT: 'ConfirmGoogleDisconnect',
    CONFIRM_ARCHIVE_OPENING_ALERT: 'ConfirmArchiveOpeningAlert',
    CONFIRM_CLONE_JOB_ALERT: 'ConfirmCloneJobAlert',
    CONFIRM_DISABLE_TEAM_MEMBER: 'ConfirmDisableTeamMember',
    CONFIRM_REJECT_CANDIDATE: 'ConfirmRejectCandidate',
    CONFIRM_DELETE_CANDIDATE: 'ConfirmDeleteCandidate',
    CONFIRM_DELETE_JOB: 'ConfirmDeleteJob',
    CALENDAR_ONBOARDING: 'CalendarOnBoarding',
    CANDIDATE_SOURCING: 'CandidateSourcing',
    ADD_STAGE: 'AddStage',
    WARNING_DELETE_STAGE: 'WarningDeleteStage',
    SEND_EMAIL: 'SendEmail',
    VIEW_FEEDBACKS: 'ViewFeedbacks',
    CHANGE_PASSWORD: 'ChangePassword',
    UPDATE_CANDIDATE: 'UpdateCandidate',
    CONFIRM_JOIN_CANDIDATE: 'ConfirmJoinCandidate',
    UNARCHIVE_CANDIDATE: 'UnarchiveCandidate',
    CONFIRM_DELETE_STAGE: 'ConfirmDeleteStage',
    ADD_FEEDBACK: 'AddFeedback',
    CUSTOMIZE_FEEDBACK: 'CustomizeFeedback',
    ADD_NOTES: 'AddNotes',
    WARNING_ACCOUNT_ALREADY_EXISTS: 'WarningAccountAlreadyExists',
    WARNING_OPENING_ARCHIVED: 'WarningOpeningArchived',
    WARNING_CANDIDATE_EMAIL_MISSING: 'WarningCandidateEmailMissing',
    WARNING_HANGOUTS_UNAVAILABLE: 'WarningHangoutsUnavailable',
    WARNING_DELETE_SECTION: 'WarningDeleteSection',
    ASSOCIATE_OPENING_NOTE: 'AssociateOpeningNote',
    UPDATE_CANDIDATE_SOURCING: 'UpdateCandidateSourcing',
    WARNING_DOSELECT: 'WarningDoSelect',
    ARCHIVED_OPENING_NOTE: 'ArchivedOpeningNote',
    ARCHIVE_REASON: 'ArchiveReason',
    ADD_CANDIDATE_DETAILS: 'AddCandidateDetails',
    ADD_CANDIDATE: 'AddCandidate',
    SELECT_JOB: 'SelectJob',
    DOWNLOAD_REPORT: 'DownloadReport',
    ASSIGN_TEST: 'AssignTest',
    SUCCESS_WEBFORM: 'SuccessWebForm',
    MANAGE_NOTIFICATIONS: 'ManageNotifications',
    CONFIRM_SOURCING_UNSUBSCRIBE: 'ConfirmSourcingUnsubscribe',
    ADD_TAG: 'AddTag',
    MANAGE_TAG: 'ManageTag',
}

export const FSMODAL_TYPES = {
    INVITE_TEAM_MEMBER: 'InviteTeamMember',
    SCHEDULE_INTERVIEW: 'ScheduleInterview',
}
