import React from 'react'

const SVGComponent = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g id="Talent-Pool" transform="translate(-1212 -372)" stroke="#4767A0" strokeWidth="2">
                <g id="Candidate-List" transform="translate(366 200)">
                    <g id="Candidate1">
                        <polygon
                            points="835 166 830.297718 168.472136 831.195774 163.236068 827.391548 159.527864 832.648859 158.763932 835 154 837.351141 158.763932 842.608452 159.527864 838.804226 163.236068 839.702282 168.472136"
                            transform="translate(20 20)"
                            id="Star"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
