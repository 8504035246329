import styled from 'styled-components'
import { PAGE_SECTIONS } from '../../Helpers/Stylesheet/styleTypes'
import { BLACK } from '../../Helpers/Stylesheet/styledComponents'

export const Heading = styled.div`
    height: ${props => (props.type === PAGE_SECTIONS.SECTION ? '40' : '77')}px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-size: 14px;
    font-family: sanFranciscoSemi;
    background: #f1f2f4;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    margin: -30px -30px 30px -30px;
    color: ${BLACK};
`
