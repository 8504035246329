import React from 'react'

const SVGComponent = () => (
    <svg width="12" height="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g id="Dashboard-New" transform="translate(-135 -487)" fill="#4767a0">
                <g id="Onboarding-Option1" transform="translate(60 270)">
                    <g id="Points" transform="translate(75 217)">
                        <g id="Point1">
                            <path
                                d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M10.6513141,5.43431458 L6.41699957,9.66862915 L4.96568542,8.21731501 C4.65326599,7.90489557 4.14673401,7.90489557 3.83431458,8.21731501 C3.52189514,8.52973444 3.52189514,9.03626642 3.83431458,9.34868586 L5.85131414,11.3656854 C6.16373358,11.6781049 6.67026556,11.6781049 6.98268499,11.3656854 L11.782685,6.56568542 C12.0951044,6.25326599 12.0951044,5.74673401 11.782685,5.43431458 C11.4702656,5.12189514 10.9637336,5.12189514 10.6513141,5.43431458 Z"
                                id="Mask"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
