export const USER_SETTINGS = [
    {
        heading: 'New candidate added to the platform',
        description: 'Sends an email whenever new candidate is added onto platform by self or others',
        alDescription: 'Sends an email whenever new candidate is added onto platform by self',
    },
    {
        heading: 'Send message to candidate',
        description: 'Sends an email whenever a message is sent to a candidate involving self',
    },
    {
        heading: 'Candidate stage change',
        description: 'Sends an email whenever a user changes candidate stage',
    },
    {
        heading: 'Add Internal note to Candidate',
        description: 'Sends an email whenever new note is added for a candidate',
    },
    {
        heading: 'New feedback added to candidate profile',
        description: 'Sends an email whenever new feedback is added against candidate by self or others',
        alDescription: 'Sends an email whenever new feedback is added against candidate by self',
    },
    {
        heading: 'New interview scheduled/rescheduled',
        description: 'Sends an email whenever an interview is scheduled or re-scehduled for self',
    },
    {
        heading: 'Assign candidate to team member',
        description: 'Sends email whenever new candidate is assigned to self',
    },
    {
        heading: 'Reminder to add feedback',
        description: 'Sends an email as reminder to add feedback for interviews conducted by self',
    },
    {
        heading: 'Reminder mails for interview',
        description: 'Sends an email as reminder to conduct interview scheduled for self',
    },
    {
        heading: 'Interview cancellation email',
        description: 'Sends an email whenever an interview scheduled for self is cancelled',
    },
]

export const COMPANY_SETTINGS = [
    {
        heading: 'Disable All Candidate Email',
        description:
            'Stops sending email to candidate for any event like Job Archival, Rejection, New Application ,etc ',
    },
    {
        heading: 'Disable Candidate Notify Email',
        description: 'Stops sending email to candiate when his application is added to the platform',
    },
]

export const COMPANY_TOGGLE = 'COMPANY_TOGGLE'
export const CANDIDATE_TOGGLE = 'CANDIDATE_TOGGLE'
