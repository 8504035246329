import React, { Component } from 'react'
import { Button, Icon } from 'antd'
import { warning } from '../../../services/Notification'
import ApplyForm from './ApplyForm'
import { currencyUnitAndValueCalculator } from '../../Helpers/currencies'
import FacebookIcon from '../../../assets/Icons/DashboardStep3/Facebook'
import LinkedInIcon from '../../../assets/Icons/DashboardStep3/linkedin'
import TwitterIcon from '../../../assets/Icons/DashboardStep3/Twitter'
import { ReactComponent as Location } from '../../../assets/Icons/Dashboard/location.svg'
import { ReactComponent as Briefcase } from '../../../assets/Icons/Dashboard/briefcase.svg'
import { ReactComponent as EmailIcon } from '../../../assets/Icons/Openings/mail.svg'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from 'react-share'
import { ReactComponent as CompanyURLImage } from '../../../assets/svg/open-new-link.svg'
import { ReactComponent as OptionsURLImage } from '../../../assets/svg/options.svg'
import { Helmet } from 'react-helmet'
import moment from 'moment'

import styles from './style'
import './style.css'
import { GetCompanyJobByUniqueUrl } from '../../../services/api'
import config from '../../../config'
import { utmBuilder } from '../../Helpers/utils'
import { OPENING_STATUS } from '../../Helpers/constants'
import InactiveJob from './InactiveJob'
import _ from 'lodash'
import { PreLoader } from '../../Components/preLoader'
import { zohoServiceVisibility } from '../../../services/ChatBotsService/zohoService'
const { careerPageURL } = config.config()

export default class ApplyNow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            job: {},
            publicToken: null,
            showForm: false,
            startAnimation: false,
        }
    }

    async componentDidMount() {
        try {
            const uniqueReferal = this.props.match.params.uniqueReferal
            const data = await GetCompanyJobByUniqueUrl(uniqueReferal)
            setTimeout(() => {
                setTimeout(() => {
                    this.setState({ startAnimation: true })
                }, 100)
                if (data.error) {
                    this.props.history.push('/signin')
                    warning(data.error)
                } else {
                    this.setState(data)
                }
            }, 1000)

            zohoServiceVisibility(false)
        } catch (err) {
            warning(err.message || err)
        }
    }

    showForm = () => {
        this.setState({ showForm: true })
    }

    hideForm = () => {
        this.setState({ showForm: false })
    }

    currencyValueCalculator = () => {
        const { job } = this.state
        let currencyArray = []

        if (job.minCompensation === null && job.maxCompensation !== null) job.minCompensation = 0
        else if (job.minCompensation !== null && job.maxCompensation === null) job.maxCompensation = job.minCompensation

        if (job.minCompensation !== null && job.maxCompensation !== null) {
            currencyArray = currencyUnitAndValueCalculator(job.maxCompensation, job.currency, job.minCompensation)
        }

        return currencyArray
    }

    companyWebsite = () => {
        const { job } = this.state
        window.open(`http://${job.companyId.websiteUrl}`, '_blank')
    }

    viewOpenings = () => {
        const { job } = this.state
        window.open(careerPageURL(job.companyId.domain), '_self')
    }

    render() {
        const { job, showForm, publicToken } = this.state
        const currencyArray = this.currencyValueCalculator()
        let CustomHelmet = (
            <Helmet>
                <title>{`Careers ${job.companyName ? `|${job.companyName}` : ``}`}</title>
                <meta property="og:title" content={`${job.companyName}`} />
                <meta property="og:description" content={`${job.jobDescription}`} />
            </Helmet>
        )

        let linkedInShareText = ''

        if (job.jobTitle) {
            // document.title = `${job.jobTitle} at ${job.companyName} | SpringRecruit`
            CustomHelmet = (
                <Helmet>
                    <title>{`${job.jobTitle} | ${job.companyName}`}</title>
                    <meta property="og:title" content={`${job.companyName}`} />
                    <meta property="og:description" content={`${job.jobDescription}`} />
                </Helmet>
            )
            linkedInShareText = `We're hiring! #jobs #careers #${job.jobTitle.replace(/\s/g, '')}`
        }

        const sharedUrl = window.location.href
        const isReferredLink = sharedUrl.includes('/refernow/')
        const utm_defaults = { campaign: 'applyNow', medium: 'social' }
        const facebookShareUrl = `${sharedUrl}?${utmBuilder({ ...utm_defaults, source: 'facebook' })}`
        const linkedInShareUrl = `${sharedUrl}?${utmBuilder({ ...utm_defaults, source: 'linkedIn' })}`
        const twitterShareUrl = `${sharedUrl}?${utmBuilder({ ...utm_defaults, source: 'twitter' })}`
        const emailShareUrl = `${sharedUrl}?${utmBuilder({
            campaign: utm_defaults.campaign,
            medium: 'email',
            source: 'email',
        })}`
        return _.isEmpty(job) ? (
            <PreLoader startAnimation={this.state.startAnimation} />
        ) : job.status === OPENING_STATUS.ACTIVE ? (
            <div
                className="apply-now-container"
                style={{
                    opacity: this.state.startAnimation ? 1 : 0,
                    transition: 'opacity 500ms linear 100ms',
                }}
            >
                {process.env.REACT_APP_PATH === 'production' && !isReferredLink ? (
                    <script type="application/ld+json">
                        {JSON.stringify({
                            '@context': 'https://schema.org/',
                            '@type': 'JobPosting',
                            title: `${job.jobTitle ? job.jobTitle : job.companyName}`,
                            url: `https://springrecruit.com/applynow/${job.uniqueReferal}`,
                            baseSalary: `${job.maxCompensation ? job.maxCompensation : '-'}`,
                            salaryCurrency: `${job.currency ? job.currency : '-'}`,
                            directApply: true,
                            educationRequirements: `${job.jobRequirements ? job.jobRequirements : '-'}`,
                            employerOverview: `${job.jobBenefits ? job.jobBenefits : '-'}`,
                            experienceRequirements: `${job.maxExperience ? job.maxExperience : '-'}`,
                            jobLocation: { address: `${job.jobLocation ? job.jobLocation : '-'}` },
                            hiringOrganization: `${job.companyName ? job.companyName : '-'}`,
                            datePosted: `${job.createdAt ? moment(job.publishedAt).format('DD-MM-YYYY') : '-'}`,
                            description: `${job.jobDescription ? job.jobDescription : '-'}`,
                        })}
                    </script>
                ) : null}
                {CustomHelmet}

                <div className="header">
                    <div style={styles.new}>NEW</div>
                    <div style={styles.jobRole}>
                        <span className="job-role">{job.jobTitle}</span>
                        <span className="full-time">{job.jobType === 1 ? 'Full-Time' : 'Part-Time'}</span>
                    </div>
                    <div style={styles.companyName}>{job.companyName}</div>
                    <div style={styles.location} className="job-details">
                        {job.minExperience !== null && job.maxExperienceQ !== null ? (
                            job.minExperience === 0 && job.maxExperience === 0 ? (
                                <span>
                                    <Icon component={Briefcase} /> Fresher
                                </span>
                            ) : (
                                <span>
                                    <Icon component={Briefcase} /> {job.minExperience} - {job.maxExperience} Years
                                </span>
                            )
                        ) : null}
                        {job.compensationStatus === 1 &&
                        (job.minCompensation !== null || job.maxCompensation !== null) ? (
                            job.minCompensation !== null && job.maxCompensation === null ? (
                                <span>
                                    <Icon component={Briefcase} /> {job.currency} {currencyArray[0]} {currencyArray[2]}
                                </span>
                            ) : (
                                <span>
                                    <Icon component={Briefcase} /> {job.currency} {currencyArray[0]} -{' '}
                                    {currencyArray[1]} {currencyArray[2]}
                                </span>
                            )
                        ) : null}
                        <span>
                            <Icon component={Location} /> {job.jobLocation}
                        </span>
                    </div>
                    {!showForm && job.status ? (
                        <Button className="apply-now-btn" onClick={this.showForm}>
                            Apply Now
                        </Button>
                    ) : null}
                </div>
                <div className="apply-now-body">
                    <div className="apply-now-body-container">
                        <div>
                            {showForm ? (
                                <ApplyForm
                                    jobApplicationForm={job.jobApplicationForm}
                                    jobId={job._id}
                                    onCancel={this.hideForm}
                                    publicToken={publicToken}
                                    companyId={job.companyId && job.companyId._id}
                                    isReferredLink={isReferredLink}
                                />
                            ) : null}
                            {['Job Description', 'Requirements', 'About Company / Benefits'].map((title, index) => {
                                const htmlBody =
                                    index === 0
                                        ? job.jobDescription
                                        : index === 1
                                        ? job.jobRequirements
                                        : job.jobBenefits

                                return (
                                    <div key={index} className="apply-now-card">
                                        <div className="apply-now-card-title">{title}</div>
                                        <div
                                            className="apply-now-card-detail"
                                            style={{
                                                display: 'inline-block',
                                                width: '-webkit-fill-available',
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: htmlBody,
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>

                        <div className="apply-now-job">
                            {job.jobTitle ? (
                                <div className="apply-now-job-detail">
                                    {['Role:', 'Location:', 'Work Exp.:', 'Job Type:', 'Salary:', 'Equity:'].map(
                                        (title, index) => {
                                            return (
                                                <div key={index}>
                                                    {(job.compensationStatus === 0 && index === 4) ||
                                                    (job.minCompensation === null && job.maxCompensation === null) ||
                                                    (job.equityStatus === 0 && index === 5) ||
                                                    ((job.minExperience === null || job.maxExperience === null) &&
                                                        index === 2) ? null : (
                                                        <div className="apply-now-row">
                                                            <div className="apply-now-column1">{title}</div>
                                                            <div className="apply-now-column2">
                                                                {index === 0
                                                                    ? job.jobTitle
                                                                    : index === 1
                                                                    ? job.jobLocation
                                                                    : index === 2
                                                                    ? job.minExperience === 0 && job.maxExperience === 0
                                                                        ? 'Fresher'
                                                                        : job.minExperience +
                                                                          '-' +
                                                                          job.maxExperience +
                                                                          ' Years'
                                                                    : index === 3
                                                                    ? job.jobType === 1
                                                                        ? 'Full Time'
                                                                        : 'Part Time'
                                                                    : index === 4
                                                                    ? job.minCompensation !== null &&
                                                                      job.maxCompensation === null
                                                                        ? job.currency +
                                                                          ' ' +
                                                                          currencyArray[0] +
                                                                          ' ' +
                                                                          currencyArray[2]
                                                                        : job.currency +
                                                                          ' ' +
                                                                          currencyArray[0] +
                                                                          '-' +
                                                                          currencyArray[1] +
                                                                          ' ' +
                                                                          currencyArray[2]
                                                                    : index === 5
                                                                    ? job.minEquity + '% - ' + job.maxEquity + '%'
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            ) : null}
                            {!showForm && job.status ? (
                                <Button type="primary" style={styles.applyNowJob} onClick={this.showForm}>
                                    Apply Now
                                </Button>
                            ) : null}
                            <div className="apply-now-social">
                                Share with friends
                                <div className="share-icon-row">
                                    <div className="share-icon">
                                        <FacebookShareButton
                                            url={`${facebookShareUrl}`}
                                            quote={`We're hiring! \n`}
                                            hashtag={'#jobs#careers'}
                                        >
                                            <div className="apply-now-social-cell">
                                                <FacebookIcon />
                                            </div>
                                        </FacebookShareButton>
                                    </div>
                                    <div className="share-icon">
                                        <LinkedinShareButton
                                            url={linkedInShareUrl}
                                            title={"We're hiring !"}
                                            summary={`${linkedInShareText}`}
                                        >
                                            <div className="apply-now-social-cell">
                                                <LinkedInIcon />
                                            </div>
                                        </LinkedinShareButton>
                                    </div>
                                    <div className="share-icon">
                                        <TwitterShareButton
                                            url={twitterShareUrl}
                                            title={`We're hiring! \n`}
                                            hashtags={[`jobs`, `careers`, `${job ? job.jobTitle : null}`]}
                                        >
                                            <div className="apply-now-social-cell">
                                                <TwitterIcon />
                                            </div>
                                        </TwitterShareButton>
                                    </div>
                                    <div className="share-icon">
                                        <EmailShareButton
                                            url={emailShareUrl}
                                            subject={`${
                                                job ? `${job.companyName} is looking for ${job.jobTitle} role` : null
                                            }`}
                                            body={`We're hiring! \n`}
                                        >
                                            <div className="apply-now-social-cell">
                                                <EmailIcon />
                                            </div>
                                        </EmailShareButton>
                                    </div>
                                </div>
                            </div>
                            <div className="Company-n-openings-btn">
                                <button onClick={this.companyWebsite}>
                                    Company website
                                    <Icon className="applynow-opening-icon" component={CompanyURLImage} />
                                </button>
                                <button onClick={this.viewOpenings}>
                                    View all openings
                                    <Icon className="applynow-opening-icon" component={OptionsURLImage} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <>
                {/* {CustomHelmet} */}
                <InactiveJob
                    jobTitle={job.jobTitle}
                    domain={job.companyId && job.companyId.domain}
                    companyName={job.companyName}
                    logo={job.companyLogo}
                    startAnimation={this.state.startAnimation}
                />
            </>
        )
    }
}
