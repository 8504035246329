import React from 'react'
import { Card } from '../style'
import { ReactComponent as InfoIcon } from '../../../../assets/Icons/Openings/info.svg'
import { Tooltip } from 'antd'
import { getNumberOfDays } from '../../../Helpers/utils'

export const StatCard = ({ total, title, desc, day = false, tooltipText }) => {
    if (day) total = getNumberOfDays(total, false)

    return (
        <Card>
            {tooltipText ? (
                <Tooltip title={tooltipText}>
                    <InfoIcon />
                </Tooltip>
            ) : null}
            <div className="body">
                <div className="total">
                    <span className="number">{total}</span>
                    {day ? <span className="day">{total > 1 ? 'Days' : 'Day'}</span> : null}
                </div>
                <div className="title">{title}</div>
            </div>
            {desc && <div className="desc">{desc}</div>}
        </Card>
    )
}

export default StatCard
