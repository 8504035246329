import styled, { createGlobalStyle } from 'styled-components'
import { TextEllipsis, FlexCenterRow, DARK_BLUE, GREY, WHISPER_GREY } from '../../Helpers/Stylesheet/styledComponents'

export const GlobalStyle = createGlobalStyle`
  .banner {
    margin: 10px auto;
  }
`
export const TodoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: auto;
    @media only screen and (max-width: 769px) {
        width: 100%;
        flex-direction: column;
    }
`
export const FilterContainer = styled.div`
    margin-top: 18px;
    width: calc(33% - 40px);
    position: sticky;
    top: 165px;
    max-height: calc(100vh - 250px);
    .ant-checkbox-inner::after {
        left: 22%;
    }
    @media only screen and (max-width: 769px) {
        position: initial;
        width: 100%;
        max-height: initial;
        margin-top: 0;
    }
`
export const Main = styled.div`
    width: 67%;
    @media only screen and (max-width: 769px) {
        margin-left: 0;
        width: 100%;
    }
`
export const LabelBox = styled.div`
    position: sticky;
    top: 56px;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    z-index: 3;
    width: 1200px;
    height: 90px;
    margin: auto;
    padding: 30px;
    font-size: 18px;
    font-weight: 500;
    font-family: sanFranciscoMedium;
    color: #333333;
    @media only screen and (max-width: 769px) {
        width: 100%;
        top: 54px;
        height: auto;
        padding: 30px 15px;
    }
`

export const ResultBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: content-box;
    height: 70px;
    top: 146px;
    padding-top: 15px;
    margin: 0 -2px;
    margin-bottom: 1px;
    position: sticky;
    background: #f7f8fc;
    .ant-calendar-picker {
        width: 140px;
        margin-left: 10px;
    }
    .ant-calendar-picker-input {
        padding: 17px 11px;
        border: solid 1px #e1e4e8 !important;
        ::placeholder {
            font-size: 12px;
            font-weight: 500;
            color: #666666;
        }
    }
    @media only screen and (min-width: 769px) {
        .mobile {
            display: none;
        }
    }
    @media only screen and (max-width: 769px) {
        height: 66px;
        top: 141px;
        padding: 0 15px 0 0;
        margin: 0;
        button,
        .ant-calendar-picker {
            display: none;
        }
        .mobile {
            svg {
                margin-left: 20px;
                vertical-align: middle;
            }
        }
    }
`
export const Card = styled.div`
    margin-bottom: ${props => (!props.filters ? '40px' : '0px')};
    border-radius: 5px;
    background: #fff;
    ${({ filters, isEmpty }) => (filters && isEmpty ? 'filter: blur(1px); -webkit-filter: blur(1px);' : null)};
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    @media only screen and (max-width: 769px) {
        width: 100%;
        border-radius: 0;
        height: 100%;
    }
`
export const Body = styled.div`
    padding: 30px;
    max-height: ${props => props.filters && 'calc(100vh - 350px)'};
    overflow: auto;
    .ant-checkbox-wrapper {
        display: block;
        margin-left: 0 !important;
        font-size: 14px;
        color: #666666;
        padding: 8px 0;
    }
    hr {
        margin: 20px 0;
        border: 0;
        height: 1px;
        background: #e1e4e8;
    }
    @media only screen and (max-width: 769px) {
        max-height: initial;
        margin-bottom: 30px;
        .ant-checkbox-wrapper {
            padding: 0;
        }
    }
`
export const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const Content = styled.div`
    display: flex;
    .candidate-details {
        width: 42%;
        margin-right: 50px;
        border-right: 1px solid #e1e4e8;
    }
    .interview-details {
        width: 42%;
    }
    .rescheduled {
        width: 100px;
        height: 25px;
        margin-top: 10px;
        border-radius: 5px;
        background-color: ${WHISPER_GREY};
        color: ${GREY};
        font-size: 12px;
        ${FlexCenterRow}
    }
    @media only screen and (max-width: 769px) {
        .candidate-details {
            width: 100%;
        }
        .interview-details {
            width: 100%;
        }
        flex-direction: column;
        > div:first-child {
            padding-right: 0;
            margin-right: 0;
            border-right: none;
        }
        > div:nth-child(2) {
            margin-top: 20px;
        }
    }
`
export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`
export const Heading = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: bold;
    color: #333333;
    text-transform: uppercase;
    height: 68px;
    display: flex;
    align-items: center;
    background: #f7f8fc;
    @media only screen and (max-width: 769px) {
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        position: sticky;
        top: 141px;
        height: 68px;
        z-index: 2;
    }
`
export const Title = styled.b`
    font-family: sanFranciscoMedium;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 15px;
    text-transform: uppercase;
`
export const SubTitle = styled.div`
    font-family: sanFrancisco;
    font-size: 13px;
    color: #333333;
    margin-bottom: 12px;
    text-transform: uppercase;
    ${TextEllipsis}
`
export const Label = styled.div`
    font-size: 0.9em;
    font-weight: 500;
    color: #333333;
    margin: 8px 0;
    min-width: 180px;
    display: flex;
    a {
        color: #4767a0;
    }
    .key {
        color: #999;
        display: inline-block;
        width: 130px;
        flex-shrink: 0;
    }
    b {
        font-family: sanFranciscoMedium;
    }
    .value {
        margin-right: 20px;
        word-break: break-word;
        svg {
            cursor: pointer;
            &:hover path {
                fill: #4767a0;
            }
        }
        & + svg {
            margin-left: 20px;
        }
    }
    .value-bold {
        font-family: sanFranciscoMedium;
        font-weight: bold;
    }

    .value-ellipsis {
        ${TextEllipsis}
    }
    .grey {
        color: #999999;
    }
    svg {
        margin-right: 5px;
        vertical-align: middle;
    }
    .candidate-name-span {
        color: ${DARK_BLUE};
        cursor: pointer;
    }
    @media only screen and (max-width: 769px) {
        .value {
            flex-shrink: 0;
            max-width: calc(100% - 150px);
        }
    }
`
export const Footer = styled.div`
    background-color: #f1f1f1;
    align-items: center;
    display: inline-flex;
    width: 100%;
    padding: 0 30px;
    justify-content: space-between;
    height: 35px;
    font-size: 12px;
    color: #999999;

    .assignment {
        .assigned-by {
            display: inline-block;
            white-space: nowrap;
            width: 180px;
            margin-top: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .status-reschedule-box {
        margin-left: 30px;
        display: inline-flex;
        width: 565px;
        justify-content: space-between;
        .upcoming {
            color: #00aff5;
        }
        .complete {
            color: #3acc6c;
        }
        .cancelled {
            color: #e02020;
        }
        .pending {
            color: #fa6400;
            .remind {
                color: #4767a0;
                cursor: pointer;
            }
        }
        .labels {
            display: inherit;
            justify-content: flex-end;
            width: 50%;
            .view-profile {
                color: ${DARK_BLUE};
                cursor: pointer;
            }
            .view-feedback {
                cursor: pointer;
                margin-right: 30px;
                color: ${DARK_BLUE};
            }
        }
    }
    .resend {
        color: #333333;
        .remind {
            color: #4767a0;
            cursor: pointer;
        }
    }

    @media only screen and (max-width: 769px) {
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: left;
        > div {
            align-self: flex-start;
            margin-top: 5px;
        }

        .assignment {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .assigned-by {
                display: inline-block;
                white-space: nowrap;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .view-feedback {
                cursor: pointer;

                color: #4767a0;
            }
        }
        .status-reschedule-box {
            width: 100%;
            margin-left: 0;
            display: flex;
            flex-direction: column;
            .labels {
                margin-top: 5px;
                margin-bottom: 5px;
                width: 100%;
                justify-content: space-between;
                .view-feedback {
                    margin-left: 0;
                }
            }
        }
    }
`
export const Icons = styled.div`
    svg {
        width: 18px;
        height: 14px;
        margin-left: 20px;
        cursor: pointer;
    }
    @media only screen and (min-width: 769px) {
        .menu {
            display: none;
        }
    }
    @media only screen and (max-width: 769px) {
        svg:not(.menu) {
            display: none;
        }
        .menu {
            margin-right: -20px;
        }
    }
`
export const Button = styled.button`
    height: 35px;
    padding: 0 22px;
    border-radius: 4px;
    border: solid 1px #e1e4e8;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    color: ${props => (props.active ? '#fff' : '#666')};
    cursor: pointer;
    margin-left: 10px;
    background: ${props => (props.active ? '#4767a0' : '#fff')};
`
export const ModalHeader = styled.div`
    text-align: center;
    border-bottom: solid 1px #e1e4e8;
    padding-bottom: 20px;
`
export const ModalTitle = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-transform: uppercase;
`
export const ModalDesc = styled.div`
    font-size: 16px;
    color: #333333;
`
export const ModalSubTitle = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 15px;
`
export const ModalButtons = styled.div`
    text-align: end;
    button {
        width: 80px;
        height: 40px;
        border-radius: 4px;
        border: solid 1px #4767a0;
        margin-left: 10px;
    }
    .add {
        background: #4767a0;
        color: #fff;
    }
    .cancel {
        background: #fff;
        color: #4767a0;
    }
    @media only screen and (max-width: 769px) {
        display: flex;
        button {
            width: 50%;
        }
        .cancel {
            margin: 0;
        }
    }
`
export const Block = styled.div`
    padding: ${props => (!props.small ? '30px 0' : '20px 0 0 0')};
    border-bottom: ${props => !props.small && 'solid 1px #e1e4e8'};
    .opinion {
        display: flex;
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
            color: #999999;
            margin-right: 42px;
            svg {
                margin-bottom: 10px;
            }
        }
    }
    .rating {
        display: flex;
        justify-content: space-between;
        .item {
            width: 25%;
            font-family: sanFranciscoMedium;
            font-size: 12px;
            font-weight: 600;
            color: #333333;
        }
        .ant-rate {
            color: #4767a0;
        }
    }
    textarea {
        width: 100%;
        resize: none;
    }
    @media only screen and (max-width: 769px) {
        .opinion {
            flex-wrap: wrap;
            margin-top: -15px;
            .item {
                margin-top: 15px;
                width: 60px;
            }
            svg {
                width: 30px;
                height: 30px;
            }
        }
        .rating {
            flex-direction: column;
            .item {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 10px 0;
            }
        }
    }
`

export const CloseFilter = styled.div`
    margin-right: 15px;
    border: thin solid #999;
    border-radius: 3px;
    padding: 2px 8px;
    background-color: white;
    font-size: 11px;
`
