import actions from './actions'
import { PURGE } from 'redux-persist'
import _ from 'lodash'
import { randomString } from '../../container/Helpers/utils'
import { FILE_STATUS } from '../../container/Helpers/constants'

const initState = { data: {}, list: [], errorObject: {} }
// const initState = STORE_CANDIDATE

export default function jobReducer(state = initState, action) {
    const { list, data, errorObject } = { ...state }
    const { payload = {}, type } = action
    switch (type) {
        case actions.CLEAR_CANDIDATES:
        case PURGE:
            return { data: {}, list: [], errorObject: {} }
        case actions.ADD_CANDIDATES: {
            const uploadId = payload.uploadId || randomString()
            return {
                ...state,
                list: [...list, uploadId],
                data: {
                    ...data,
                    [uploadId]: {
                        name: '',
                        email: '',
                        phone: { code: '', number: '' },
                        uploadId,
                        uploadStatus: FILE_STATUS.SUCCESS,
                        hasErrors: false,
                        errors: {},
                        ...payload,
                    },
                },
            }
        }

        // send only the partial data to be updated, everything is not required
        case actions.UPDATE_CANDIDATE:
            data[payload.uploadId] = { ...data[payload.uploadId], ...payload }
            break

        case actions.REMOVE_CANDIDATE:
            // TODo: remove the errorObject

            delete data[payload.uploadId]
            const newList = [..._.without(list, payload.uploadId)]
            return { list: newList, data, errorObject }

        // TODO: combine multiple calls
        case actions.EMIT_ERROR: {
            const newData = {}
            payload.uploadIds.forEach(each => {
                newData[each] = {
                    ...data[each],
                    errors: { ...data[each].errors, [payload.type]: payload.msg },
                    hasErrors: true,
                }

                // return {
                //     [each]: {
                //         ...data[each],
                //         hasErrors: true,
                //         errors: { ...data[each].errors, [payload.type]: payload.msg },
                //     },
                // }
            })
            return { ...state, data: { ...data, ...newData } }
        }

        case actions.REORDER_LIST: {
            return { ...state, list: [...payload.list] }
        }

        case actions.EMIT_DUPLICATE_ERROR: {
            const duplicateCandidateEmail = {}
            const duplicateCandidatePhone = {}
            const newData = {}
            payload.forEach(({ email, phone = null }) => {
                duplicateCandidateEmail[email] = 1
                if (phone) duplicateCandidatePhone[phone] = 1
            })
            for (const each in data) {
                let foundError = false
                const errors = {}
                if (duplicateCandidateEmail[data[each].email]) {
                    foundError = true
                    errors.email = 'Email already exists'
                }

                if (duplicateCandidatePhone[data[each].phone]) {
                    foundError = true
                    errors.phone = 'Phone already exists'
                }

                if (foundError) {
                    newData[each] = {
                        ...data[each],
                        errors: { ...data[each].errors, ...errors },
                        hasErrors: true,
                    }
                }
            }

            return { ...state, data: { ...data, ...newData } }
        }

        default:
            return state
    }

    return { list, data, errorObject }
}
