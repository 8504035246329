import React from 'react'
import { MessageModal } from '../common'

export const ConfirmDeleteStage = props => (
    <MessageModal
        title="DELETE STAGE"
        message={
            <span>
                Are you sure you want to delete stage <b>{props.stageName ? props.stageName : ''}</b> ? This action
                cannot be undone
            </span>
        }
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
        confirmText="DELETE"
        cancelText="CANCEL"
    />
)
