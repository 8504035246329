import React from 'react'

const SVGComponent = () => (
    <svg width="18" height="17" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g
                id="Candidate-Profile---Feedback-section-+-Multiple-company-feedback"
                transform="translate(-716 -369)"
                stroke="#4767A0"
                strokeWidth="1.2"
            >
                <g id="Company-Feedback1" transform="translate(326 221)">
                    <g id="Feedback1" transform="translate(60 91.512)">
                        <g id="Feedback" transform="translate(60 54.335)">
                            <g id="Rating">
                                <g id="criteria4">
                                    <g id="Rating" transform="translate(187 3.733)">
                                        <polygon
                                            id="Star-Copy-4"
                                            points="92 11.2 87.297718 13.5073269 88.1957739 8.62033011 84.3915479 5.15933978 89.648859 4.44633655 92 0 94.351141 4.44633655 99.6084521 5.15933978 95.8042261 8.62033011 96.702282 13.5073269"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
