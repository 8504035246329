import React, { useState, useEffect } from 'react'
import { Tooltip, Table, Popover, Spin, Empty } from 'antd'
// import ReactExport from 'react-export-excel'
import { CSVLink } from "react-csv";
import { TableWrapper, Graph, DataCard } from '../style'
import { ReactComponent as DownloadIcon } from '../../../../assets/Icons/Talent Pool/download.svg'
import { ReactComponent as HelpIcon } from '../../../../assets/Icons/Talent Pool/help.svg'
import config from '../../../../config'
const ReactExport = {
    ExcelFile: {
        ExcelSheet: null,
        ExcelColumn: null
    },
}
const { isMobileDevice } = config
// const ExcelFile = ReactExport.ExcelFile
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export const StyledTable = ({ columns, downloadColumns, filename, tableData, loading, xScroll }) => {
    const [pageSizeOptions, setPageSizeOptions] = useState([])
    const [pageSize, setPageSize] = useState(5)

    const scroll = { y: 270 }
    if (xScroll) scroll.x = xScroll

    useEffect(() => {
        const pageSizeOptions = []
        for (let index = 0; index < tableData.length; index = index + 5) pageSizeOptions.push((index + 5).toString())
        setPageSizeOptions(pageSizeOptions)
        setPageSize(5)
    }, [tableData])

    const onShowSizeChange = (current, pageSize) => setPageSize(pageSize)
    const showTotal = (total, range) => ` ${range[0]}-${range[1]} of ${total} items`
    if (isMobileDevice)
        return (
            <div className="data">
                <div className="buttons">
                    {/* <ExcelFile filename={filename} element={<a>DOWNLOAD</a>}>
                        <ExcelSheet data={tableData} name="Analytics Report">
                            {downloadColumns.map(column => (
                                <ExcelColumn label={column.value} value={column.index} />
                            ))}
                        </ExcelSheet>
                    </ExcelFile> */}
                    <CSVLink 
                        filename={`${filename}.csv`}
                        data={tableData} 
                        headers={downloadColumns?.map(col => ({label: col.value, key: col.index }))}
                    >
                        <a>DOWNLOAD</a>
                    </CSVLink>
                    <Popover
                        title="Tap over column name to learn more about it"
                        arrowPointAtCenter={true}
                        placement="bottomRight"
                        trigger="click"
                        overlayStyle={{
                            fontSize: '12px',
                            maxWidth: '177px',
                        }}
                    >
                        <a>GET HELP</a>
                    </Popover>
                </div>
                <Spin spinning={loading}>
                    <DataCard>
                        {tableData.map(td => (
                            <ul>
                                {downloadColumns.map(column => {
                                    if (
                                        [
                                            'jobName',
                                            'sourceName',
                                            'monthCreated',
                                            'tagName',
                                            'name',
                                            'stageName',
                                            'teamMember',
                                        ].includes(column.index)
                                    )
                                        return <li className="title">{td[column.index]}</li>
                                    else
                                        return (
                                            <li>
                                                <span>{column.value}</span>
                                                <span>{td[column.index]}</span>
                                            </li>
                                        )
                                })}
                            </ul>
                        ))}
                        {tableData.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </DataCard>
                </Spin>
            </div>
        )
    return (
        <TableWrapper scroll={pageSize > 5}>
            <div className="icons">
                {/* <ExcelFile
                    filename={filename}
                    element={
                        <Tooltip title="Download Report">
                            <DownloadIcon />
                        </Tooltip>
                    }
                >
                    <ExcelSheet data={tableData} name="Analytics Report">
                        {downloadColumns.map(column => (
                            <ExcelColumn label={column.value} value={column.index} />
                        ))}
                    </ExcelSheet>
                </ExcelFile> */}
                <CSVLink 
                    filename={`${filename}.csv`} 
                    data={tableData} 
                    headers={downloadColumns?.map(col => ({label: col.value, key: col.index }))}
                >
                    <Tooltip title="Download Report">
                        <span>
                            <DownloadIcon />
                        </span>
                    </Tooltip>
                </CSVLink>
                <Popover
                    title="Hover over column name to learn more about it"
                    arrowPointAtCenter={true}
                    placement="bottomRight"
                    trigger="click"
                    overlayStyle={{
                        fontSize: '12px',
                        maxWidth: '177px',
                    }}
                >
                    <Tooltip title="Get Help">
                        <HelpIcon />
                    </Tooltip>
                </Popover>
            </div>
            <Table
                columns={columns}
                pagination={{ showSizeChanger: true, pageSizeOptions, pageSize, onShowSizeChange, showTotal }}
                dataSource={tableData}
                bordered={true}
                loading={loading}
                scroll={scroll}
            />
        </TableWrapper>
    )
}

export default StyledTable
