import React from 'react'
import { Button, Col, Row } from 'antd'

const SaveContainer = ({ onCancel, onSubmit }) => {
    return (
        <Row gutter={48}>
            <Col>
                <div className="career-save-cancel-container">
                    <div className="carrer-cont-btn-single">
                        <Button type="primary" onClick={onCancel}>
                            CANCEL
                        </Button>
                    </div>
                    <div className="carrer-cont-btn-single">
                        <Button type="primary" onClick={onSubmit}>
                            SAVE
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default SaveContainer
