import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'

import { history } from './redux/store'
import App from './container/App'
import SignIn from './container/Pages/SignIn'
import Error from './container/Pages/Error'
import ForgotPassword from './container/Pages/ForgotPassword'
import SignUp from './container/Pages/SignUp'
import ApplyNow from './container/Pages/ApplyNow'
import ScrollIntoTopView from './routerScrollToTop'
import PrivacyPolicy from './container/Pages/PrivacyPolicy'
import TermsAndConditions from './container/Pages/TermsAndConditions'
import NextOpportunity from './container/Pages/NextOpportunity'
import HiringInitiative from './container/Pages/HiringInitiative'
import BackToWorkForm from './container/Pages/NextOpportunity/BackToWorkForm'
import { AUTH_ENTRY_POINTS } from './container/Helpers/calendarPopUp'
import { ACCESS_LEVEL_DEFINITIONS } from './container/Helpers/constants'
import ImportCandidate from './container/Pages/ImportCandidate'
import WebForm from './container/Pages/WebForm'
import Landing from './container/Pages/Landing'
import ZoomDocumentation from './container/Pages/ZoomDocumentation'

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (isLoggedIn) {
                return <Component {...props} />
            } else {
                const [empty, path] = props.location.pathname.split('/')
                if (
                    [
                        'dashboard',
                        'openings',
                        'todo',
                        'talent-pool',
                        'account',
                        'careers',
                        'analytics',
                        'candidate',
                    ].includes(path)
                ) {
                    // you are valid path
                    return (
                        <Redirect
                            to={{
                                pathname: '/signin', ///error
                                state: { from: props.location },
                            }}
                        />
                    )
                } else return <Redirect to="/error" />
            }
        }}
    />
)

const redirectPathEvaluator = ({ location, accessLevel, token }) => {
    let redirect_uri = location.search.split('redirect_ui=')[1]
    if (redirect_uri) {
        redirect_uri += `#authToken=${token}`
        return window.location.replace(redirect_uri)
    }

    // only for the interview only, the opening is not visible.
    let goToPath = accessLevel === ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel ? '/dashboard' : '/openings'
    if (location.state && location.state.hasOwnProperty('from')) {
        // if by any change router-state has error, then it is not to be redirected
        const { from = { pathname: null } } = location.state
        goToPath = from.pathname === '/error' ? goToPath : location.state.from.pathname
    }
    return goToPath
}

// NOTE : the re-routing is happening 2 times everytime. Has to be taken care of.
const PublicRoutes = ({ history, isLoggedIn, accessLevel, token }) => {
    const handledReRedirects = props => {
        if (!isLoggedIn) return <Redirect to="/" />
        const goToPath = redirectPathEvaluator({ ...props, accessLevel, token })
        return (
            <Redirect
                to={{
                    pathname: goToPath,
                    state: {
                        entryPoint: AUTH_ENTRY_POINTS.SIGN_IN,
                    },
                }}
            />
        )
    }
    return (
        <ConnectedRouter history={history}>
            <ScrollIntoTopView>
                <Switch>
                    <Route path="/" exact component={Landing} />

                    <Route
                        path="/signin"
                        exact
                        render={props => {
                            if (!isLoggedIn) return <SignIn {...props} />
                            const goToPath = redirectPathEvaluator({ ...props, accessLevel, token })
                            return (
                                <Redirect
                                    to={{
                                        pathname: goToPath,
                                        state: {
                                            entryPoint: AUTH_ENTRY_POINTS.SIGN_IN,
                                        },
                                    }}
                                />
                            )
                        }}
                    />

                    <Route
                        path="/import/spreadsheet"
                        exact
                        render={props => {
                            if (!isLoggedIn) return <Redirect to={{ pathname: '/signin' }} />
                            else return <ImportCandidate {...props} />
                        }}
                    />

                    <Route path="/webform" exact render={props => <WebForm {...props} />} />

                    <Route path="/Back-to-Work-project-by-Springworks" exact render={handledReRedirects} />

                    <Route path="/india-hiring-freeze-layoffs" exact render={handledReRedirects} />

                    <Route path="/Back-to-Work-project-by-Springworks/form" exact render={handledReRedirects} />

                    <Route path="/documentation/zoom" exact render={props => <ZoomDocumentation />} />

                    <Route path="/webform" exact render={props => <WebForm {...props} />} />

                    <Route path="/error" exact render={props => <Error {...props} />} />

                    <Route path="/public/privacy-policy" exact render={props => <PrivacyPolicy {...props} />} />

                    <Route
                        path="/public/terms-and-conditions"
                        exact
                        render={props => <TermsAndConditions {...props} />}
                    />

                    <Route
                        path="/signup/"
                        exact
                        render={props =>
                            !isLoggedIn ? (
                                <SignUp {...props} />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: '/dashboard',
                                        state: {
                                            entryPoint: AUTH_ENTRY_POINTS.SIGN_UP,
                                        },
                                    }}
                                />
                            )
                        }
                    />

                    <Route
                        path="/signup/:slug/:userId"
                        exact
                        render={props =>
                            !isLoggedIn ? (
                                <SignUp {...props} />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: '/dashboard',
                                        state: {
                                            entryPoint: AUTH_ENTRY_POINTS.SIGN_UP,
                                        },
                                    }}
                                />
                            )
                        }
                    />

                    <Route
                        path="/forgot-password"
                        exact
                        render={props =>
                            !isLoggedIn ? <ForgotPassword {...props} /> : <Redirect to={{ pathname: '/dashboard' }} />
                        }
                    />
                    <Route path="/refernow/:uniqueReferal" exact render={props => <ApplyNow {...props} />} />
                    <Route path="/applynow/:uniqueReferal" exact render={props => <ApplyNow {...props} />} />

                    <RestrictedRoute path="/" component={App} isLoggedIn={isLoggedIn} />

                    <Route path="*" render={() => <Redirect to="/signin" />} />
                </Switch>
            </ScrollIntoTopView>
        </ConnectedRouter>
    )
}

export default connect(state => ({
    token: state.Auth.idToken,
    isLoggedIn: state.Auth.idToken !== null,
    accessLevel: state.Auth.user && state.Auth.user.accessLevel,
}))(PublicRoutes)
