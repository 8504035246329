import React, { useState, useEffect } from 'react'
import { CardCollapsible, Title } from '../style'
import { ProfileLoader } from './Loaders'
import { ReactComponent as ExpandIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Btn.svg'
import { ReactComponent as CollapseIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand or Collapse Btn.svg'
import { Avatar, Tooltip } from 'antd'
import classNames from 'classnames'
import { ReactComponent as EyeIcon } from '../../../../assets/Icons/Talent Pool/eye.svg'
import { ReactComponent as EyeSlashIcon } from '../../../../assets/Icons/Talent Pool/eyeslash.svg'
import { ReactComponent as StarIcon } from '../../../../assets/Icons/Talent Pool/star.svg'
import { ReactComponent as StarFilledIcon } from '../../../../assets/Icons/Talent Pool/Star Filled.svg'
import { EXPERIENCE_OPTIONS } from '../../../Helpers/constants'
import { currencyUnitAndValueCalculator } from '../../../Helpers/currencies'
import getAvatar from '../../../../services/getAvatar'
import { ReactComponent as InfoIcon } from '../../../../assets/Icons/Talent Pool/info.svg'
import { extractSocialsUsername } from '../../../Helpers/utils'

const Profile = ({ candidateDetails, userOptions, isLoading, updateUniversalCandidate }) => {
    const {
        _id,
        name,
        email,
        location,
        noticePeriod,
        experience = {},
        phone = {},
        ctc = {},
        socialProfiles = {},
    } = candidateDetails

    const [isHidden, setIsHidden] = useState(hidden)
    const [isStarred, setIsStarred] = useState(starred)
    const [isActive, setIsActive] = useState(true)

    if (isLoading)
        return (
            <CardCollapsible active maxHeight={'500px'}>
                <div className="header">
                    <Title>Profile:</Title>
                    <CollapseIcon />
                </div>

                <ProfileLoader />
            </CardCollapsible>
        )

    const { hidden, starred } = userOptions || {}
    const { linkedIn, github } = socialProfiles || {}
    const currentCTCArray = currencyUnitAndValueCalculator(ctc.current, ctc.currency)
    const expectedCTCArray = currencyUnitAndValueCalculator(ctc.expected, ctc.currency)

    const updateCandidate = (field, value) => {
        updateUniversalCandidate({
            data: value,
            ids: [_id],
            name,
            update: field === 'hidden' ? 'hide_candidate' : 'mark_favorite',
        })
        if (field === 'hidden') setIsHidden(value)
        else setIsStarred(value)
    }

    return (
        <>
            <CardCollapsible active={isActive} maxHeight={'600px'}>
                <div className="header">
                    <Title>Profile:</Title>
                    {isActive ? (
                        <CollapseIcon onClick={() => setIsActive(false)} />
                    ) : (
                        <ExpandIcon onClick={() => setIsActive(true)} />
                    )}
                </div>

                <div className="body">
                    <Avatar size={80} shape="square" className="avatar">
                        <span>{name ? getAvatar(name) : <InfoIcon style={{ width: '25px', height: '25px' }} />}</span>
                    </Avatar>
                    <div className="content">
                        <div className="data">
                            <div className="item">
                                <span className="key">Name:</span>
                                <span className="value">{name || 'Candidate'}</span>
                            </div>

                            <div className="item">
                                <span className="key">Location:</span>
                                <span className="value">{location || 'NA'}</span>
                            </div>
                            <div className="item">
                                <span className="key">Experience:</span>
                                <span className="value">
                                    {typeof experience.totalYears === 'number'
                                        ? EXPERIENCE_OPTIONS[experience.totalYears]
                                        : 'NA'}
                                </span>
                            </div>
                            <div className="item">
                                <span className="key">Notice period:</span>
                                <span className="value">{noticePeriod || 'NA'}</span>
                            </div>
                            <div className="item">
                                <span className="key">Email:</span>
                                <span className="value">{email}</span>
                            </div>
                            <div className="item">
                                <span className="key">Phone Number:</span>
                                <span className="value">{`+${phone.country_code}${phone.number}`}</span>
                            </div>

                            <div className="item">
                                <span className="key">Current CTC:</span>
                                <span className="value">
                                    {ctc.current ? `${ctc.currency} ${currentCTCArray[1]} ${currentCTCArray[2]}` : 'NA'}
                                </span>
                            </div>

                            <div className="item">
                                <span className="key">Expected CTC:</span>
                                <span className="value">
                                    {ctc.expected
                                        ? `${ctc.currency} ${expectedCTCArray[1]} ${expectedCTCArray[2]}`
                                        : 'NA'}
                                </span>
                            </div>

                            <div className="item">
                                <span className="key">Github:</span>
                                <span
                                    className={classNames({ value: true, 'hyper-links': !!github.link })}
                                    onClick={() =>
                                        github.link &&
                                        window.open(extractSocialsUsername(github.link, true).socialLink, '_blank')
                                    }
                                >
                                    {github.link ? extractSocialsUsername(github.link).socialName : 'NA'}
                                </span>
                            </div>
                            <div className="item">
                                <span className="key">LinkedIn:</span>
                                <span
                                    className={classNames({ value: true, 'hyper-links': !!linkedIn.link })}
                                    onClick={() =>
                                        linkedIn.link &&
                                        window.open(extractSocialsUsername(linkedIn.link, true).socialLink, '_blank')
                                    }
                                >
                                    {linkedIn.link ? extractSocialsUsername(linkedIn.link).socialName : 'NA'}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="icons">
                        <Tooltip title={isStarred ? 'Unmark from favourite' : 'Mark as favourite'}>
                            {isStarred ? (
                                <StarFilledIcon onClick={() => updateCandidate('starred', !isStarred)} />
                            ) : (
                                <StarIcon onClick={() => updateCandidate('starred', !isStarred)} />
                            )}
                        </Tooltip>
                        <Tooltip title={isHidden ? 'View' : 'Hide'}>
                            {isHidden ? (
                                <EyeSlashIcon
                                    className="eye-icon"
                                    onClick={() => updateCandidate('hidden', !isHidden)}
                                />
                            ) : (
                                <EyeIcon className="eye-icon" onClick={() => updateCandidate('hidden', !isHidden)} />
                            )}
                        </Tooltip>
                    </div>
                </div>
            </CardCollapsible>
        </>
    )
}

export default Profile
