import actions from './actions'
import { PURGE } from 'redux-persist'

const initState = { idToken: null, user: null, gCal: null, failure: true }

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case PURGE:
            return initState
        case actions.LOGIN_SUCCESS:
            return { ...state, idToken: action.token }
        case actions.LOGOUT:
            return initState
        case actions.SET_USER:
            return { ...state, user: action.user }
        case actions.UPDATE_USER:
            return { ...state, user: Object.assign(state.user, action.data) }
        case actions.SET_ADDRESS:
            return { ...state, addressArray: action.data }
        case actions.FAILED_SIGNUP: {
            return { ...state, failure: action.payload }
        }
        case actions.REFRESH_USER: {
            let { user } = state
            const { accessLevel, status, accessibleJobs, exportInProcess } = action.payload.user
            user = {
                ...user,
                accessLevel,
                status,
                accessibleJobs,
                sourcing: action.payload.sourcing,
                isZoomEnabled: action.payload.isZoomEnabled,
                exportInProcess,
            }
            return { ...state, user }
        }

        case actions.UPDATE_USER_DETAILS: {
            const { companyName = null, fullName = null } = action.payload
            return {
                ...state,
                user: {
                    ...state.user,
                    ...(companyName && { companyName: companyName }),
                    ...(fullName && { userName: fullName }),
                },
            }
        }
        default:
            return state
    }
}
