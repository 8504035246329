import React, { useState, useEffect } from 'react'
import Button from '../../../Components/StyledButton'
import { Form, StepButton } from '../style'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/Icons/Notification/close.svg'
import { ReactComponent as GithubIcon } from '../../../../assets/Icons/Interview/github.svg'
import { ReactComponent as LinkedinIcon } from '../../../../assets/Icons/Openings/linkedin.svg'
import { Field } from '../../../Modals/AddCandidate/style.js'
import { Icon, Select, Input, Radio } from 'antd'
import { FILE_STATUS, NOTICE_PERIOD_ARR } from '../../../Helpers/constants'
import { currencies } from '../../../Helpers/currencies'
import { StyledDropzoneButton } from '../../../Components/StyledDropzone'
import { ALLOWED_FILE_FORMATS, SingleFileTypeValidation } from '../../../Helpers/fileTypeValidation'
import { warning } from '../../../../services/Notification'
import { formatFileName } from '../../../Helpers/utils'
import { CANDIDATE, ONLY_DIGITS } from '../../../Helpers/RegEx'

const { Option } = Select
const InputGroup = Input.Group
const { Group: RadioGroup } = Radio

// Page 2 of the form
const Details = ({ flipPage, details, uploadResume }) => {
    const [noticePeriod, setNoticePeriod] = useState(details.noticePeriod || undefined)
    const [currentCTC, setCurrentCTC] = useState((details.ctcInfo && details.ctcInfo.current) || undefined)
    const [expectedCTC, setExpectedCTC] = useState((details.ctcInfo && details.ctcInfo.expected) || undefined)
    const [currency, setCurrency] = useState((details.ctcInfo && details.ctcInfo.expectedCTCCurrency) || 'INR')
    const [relocation, setRelocation] = useState(details.relocation)
    const [socialLink, setSocialLink] = useState('')
    const [socialType, setSocialType] = useState('linkedIn')
    const [resume, setResume] = useState({
        name: details.resume && details.resume.name,
        link: details.resumeLink || (details.resume && details.resume.link),
    })
    const [errors, setErrors] = useState(details.errors || {})
    const [upload, setUpload] = useState(false)

    const onDrop = async acceptedFile => {
        if (acceptedFile.length === 1) {
            const formData = SingleFileTypeValidation(acceptedFile[0])
            setUpload(true)
            const res = await uploadResume(formData)
            if (res) {
                setResume({ name: acceptedFile[0].name, link: res.link })
                setUpload(false)
                nullifyError('resume')
            }
        }
    }

    const onSubmit = direction => {
        const tempError = {}

        if (noticePeriod === undefined) tempError.noticePeriod = 'Notice Period is required'

        if (relocation === undefined) tempError.relocation = 'This field can not be blank'

        if (currentCTC && expectedCTC && parseInt(currentCTC) > parseInt(expectedCTC)) tempError.ctc = 'Invalid ranger'

        if (currency === 'USD') delete tempError.ctc

        if (!resume.link) tempError.resume = 'This field can not be blank'

        if (socialLink.length) {
            if (socialType === 'github' && !socialLink.match(CANDIDATE.GITHUB))
                tempError.socials = 'Github link is invalid'
            if (socialType === 'linkedIn' && !socialLink.match(CANDIDATE.LINKEDIN))
                tempError.socials = 'LinkedIn link is invalid'
        }

        if (Object.keys(tempError).length) setErrors(tempError)
        else {
            // as per the product requirements,USD will not ask for CTC
            const ctcInfo =
                currency === 'USD'
                    ? {
                        current: null,
                        expected: null,
                        expectedCTCCurrency: null,
                    }
                    : {
                        current: currentCTC,
                        expected: expectedCTC,
                        expectedCTCCurrency: currency,
                    }
            // Update parent and move to next page
            flipPage({
                direction,
                field: 'details',
                noticePeriod,
                ctcInfo,
                relocation,
                resume,
                socialLink,
                socialType,
            })
        }
    }
    const nullifyError = type => setErrors({ ...errors, [type]: false })

    return (
        <>
            <Form>
                <Field>
                    <div className="title">Notice period*</div>
                    <Select
                        size="large"
                        value={noticePeriod}
                        placeholder={'Select'}
                        onChange={value => {
                            setNoticePeriod(value)
                            nullifyError('noticePeriod')
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        suffixIcon={<Icon component={DownIcon} />}
                    >
                        {NOTICE_PERIOD_ARR.map(each => (
                            <Option key={each} value={each}>
                                {each}
                            </Option>
                        ))}
                    </Select>
                    {errors.noticePeriod && <div className="error">{errors.noticePeriod}</div>}
                </Field>
                <Field>
                    <div className="title">Profile link</div>
                    <InputGroup compact>
                        <Select
                            size="large"
                            defaultValue="linkedIn"
                            onChange={value => setSocialType(value)}
                            suffixIcon={<Icon component={DownIcon} />}
                        >
                            <Option value="github">
                                <GithubIcon />
                            </Option>
                            <Option value="linkedIn">
                                <LinkedinIcon />
                            </Option>
                        </Select>
                        <Input
                            size="large"
                            placeholder={`Enter ${socialType === 'linkedIn' ? 'LinkedIn' : 'Github'} Link`}
                            onChange={({ target: { value } }) => setSocialLink(value)}
                            onBlur={({ target: { value } }) => {
                                if (
                                    value === '' ||
                                    (socialType === 'linkedIn' && value.match(CANDIDATE.LINKEDIN)) ||
                                    (socialType === 'github' && value.match(CANDIDATE.GITHUB))
                                )
                                    nullifyError('socials')
                            }}
                        />
                    </InputGroup>
                    {errors.socials && <div className="error">{errors.socials}</div>}
                </Field>
                <Field>
                    <div className="title">Currency</div>
                    <Select
                        value={currency}
                        allowClear={false}
                        optionLabelProp="title"
                        size="large"
                        onChange={val => setCurrency(val)}
                        suffixIcon={<Icon component={DownIcon} />}
                        getPopupContainer={trigger => trigger.parentNode}
                    >
                        {currencies.map(currency => (
                            <Option
                                title={currency.symbol}
                                key={currency.symbol}
                            >{`${currency.name} (${currency.symbol})`}</Option>
                        ))}
                    </Select>
                </Field>
                <div className="ctc">
                    <Field hidden={currency === 'USD'}>
                        <div className="title">Current CTC</div>
                        <Input
                            size="large"
                            value={currentCTC}
                            placeholder="Enter value"
                            onChange={({ target: { value } }) => {
                                value = value.replace(ONLY_DIGITS, '')
                                setCurrentCTC(value)
                                if (value && expectedCTC && parseInt(value) <= parseInt(expectedCTC)) {
                                    nullifyError('ctc')
                                }
                            }}
                        />
                        {errors.ctc && <div className="error">{errors.ctc}</div>}
                    </Field>
                    <Field hidden={currency === 'USD'}>
                        <div className="title">Expected CTC</div>
                        <Input
                            size="large"
                            value={expectedCTC}
                            placeholder="Enter value"
                            onChange={({ target: { value } }) => {
                                value = value.replace(ONLY_DIGITS, '')
                                setExpectedCTC(value)
                                if (value >= currentCTC) nullifyError('ctc')
                            }}
                        />
                    </Field>
                </div>
                <Field className="full">
                    <div className="title">Are you willing to relocate*</div>
                    <RadioGroup
                        defaultValue={relocation}
                        onChange={({ target: { value } }) => {
                            setRelocation(value)
                            nullifyError('relocation')
                        }}
                    >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </RadioGroup>
                    {errors.relocation && <div className="error">{errors.relocation}</div>}
                </Field>
                <Field className="full">
                    <div className="title">Resume*</div>
                    <div className="resume">
                        {resume.link ? (
                            <div className="tags">
                                <span>
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => window.open(resume.link, '_blank')}
                                    >
                                        {formatFileName(resume.name || 'Resume', 20)}{' '}
                                    </span>
                                    <CloseIcon onClick={() => setResume({})} style={{ cursor: 'pointer' }} />
                                </span>
                            </div>
                        ) : null}

                        <StyledDropzoneButton
                            onDrop={onDrop}
                            uploadText={`${resume.link ? 'Update' : 'Add'} Resume`}
                            accept={ALLOWED_FILE_FORMATS.CANDIDATE.join(', ')}
                            height="40px"
                        />
                    </div>
                    {errors.resume && <div className="error">{errors.resume}</div>}
                </Field>
            </Form>
            <StepButton>
                <Button type="inverted" width="80px" height="40px" disable={upload} onClick={() => onSubmit('BACK')}>
                    BACK
                </Button>
                <Button width="80px" height="40px" disable={upload} onClick={() => onSubmit('NEXT')}>
                    NEXT
                </Button>
            </StepButton>
        </>
    )
}

export default Details
