import React from 'react'
import { Background, Content, Heading, Desc, Button } from './style'
import { ReactComponent as LinkExpired } from '../../../assets/Icons/WebForm/linkexpired.svg'

const ERROR_DESC = {
    404: 'The page your are looking for doesn’t exist or is unavailable.',
    500: 'Uh-oh, something just isn’t right. Please refresh the page. If the problem persists, please contact our support team.',
    WEBFORM: 'Uh oh! \n' + 'The link you followed has expired',
}

export default class Error extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: 404,
        }
    }

    goHome = () => {
        this.props.history.push('/dashboard')
    }

    render() {
        const { isWebform } = this.props
        let headingData = this.state.error === 404 ? 404 : 500
        let descriptionData = this.state.error === 404 ? ERROR_DESC['404'] : ERROR_DESC['500']
        let showButton = true

        if (isWebform) {
            headingData = <LinkExpired style={{ marginBottom: 15 }} />
            descriptionData = ERROR_DESC.WEBFORM
            showButton = false
        }
        return (
            <Background>
                <Content isWebform>
                    <Heading>{headingData}</Heading>
                    <Desc>{descriptionData}</Desc>
                    {showButton && <Button onClick={this.goHome}>Go to homepage</Button>}
                </Content>
            </Background>
        )
    }
}
