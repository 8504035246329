import React from 'react'

const SVGComponent = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g
                id="Openings"
                transform="translate(-1169 -234)"
                fill="#BFBFBF"
                fillRule="nonzero"
                stroke="#BFBFBF"
                strokeWidth="0.1"
            >
                <g id="Job-Openings" transform="translate(40 200)">
                    <g id="Opening1">
                        <g id="Edit-Icon" transform="translate(1130 35)">
                            <path
                                d="M23.3170591,3.51827253 L10.2012134,16.6341183 L10.2012134,19.7987866 L13.3658817,19.7987866 L26.4817275,6.68294085 L23.3170591,3.51827253 Z M29.573162,7.71341902 L15,22.286581 C14.7267002,22.5598808 14.3560261,22.713419 13.9695218,22.713419 L8.74389718,22.713419 C7.93904367,22.713419 7.28658098,22.0609563 7.28658098,21.2561028 L7.28658098,16.0304782 C7.28658098,15.6439739 7.44011921,15.2732998 7.71341902,15 L22.286581,0.426838032 C22.8556984,-0.142279344 23.7784199,-0.142279344 24.3475373,0.426838032 L29.573162,5.65246269 C30.1422793,6.22158006 30.1422793,7.14430164 29.573162,7.71341902 Z M23.3170591,18.3414704 C23.3170591,17.5366169 23.9695218,16.8841542 24.7743753,16.8841542 C25.5792289,16.8841542 26.2316915,17.5366169 26.2316915,18.3414704 L26.2316915,26.3567095 C26.2316915,28.3688433 24.6005348,30 22.5884011,30 L3.64329049,30 C1.63115671,30 0,28.3688433 0,26.3567095 L0,7.41159895 C0,5.39946517 1.63115671,3.76830846 3.64329049,3.76830846 L11.6585296,3.76830846 C12.4633831,3.76830846 13.1158458,4.42077114 13.1158458,5.22562465 C13.1158458,6.03047817 12.4633831,6.68294085 11.6585296,6.68294085 L3.64329049,6.68294085 C3.24086374,6.68294085 2.91463239,7.00917219 2.91463239,7.41159895 L2.91463239,26.3567095 C2.91463239,26.7591363 3.24086374,27.0853676 3.64329049,27.0853676 L22.5884011,27.0853676 C22.9908278,27.0853676 23.3170591,26.7591363 23.3170591,26.3567095 L23.3170591,18.3414704 Z"
                                id="Mask"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
