export const actions = {
    SET_CALL_IN_PROGRESS: 'SET_CALL_IN_PROGRESS',

    GET_CONTACTS: 'GET_CONTACTS',
    LOAD_MORE_CONTACTS: 'LOAD_MORE_CONTACTS',

    SELECT_CONTACT: 'SELECT_CONTACT',
    GET_INBOX: 'GET_INBOX',
    SEND_INBOX: 'SEND_INBOX',
    INBOX_MARK_PRIVATE: 'INBOX_MARK_PRIVATE',
}
