import React from 'react'
import { MessageModal } from '../common'
import './style.scss'

const WarningCandidateEmailMissing = ({ onConfirm, onRedirect, redirect }) => (
    <MessageModal
        confirmText="OKAY"
        onConfirm={onConfirm}
        title={'CANDIDATE EMAIL MISSING'}
        message={
            <div>
                <div>Please add the candidate’s email address to schedule interview.</div>
                {redirect && (
                    <div>
                        <span onClick={onRedirect} className="click-here">
                            {' '}
                            <b>Click here</b>
                        </span>{' '}
                        to add this candidate's email address.
                    </div>
                )}
            </div>
        }
    />
)

export default WarningCandidateEmailMissing
