import React from 'react'
import { MessageModal } from '../common'

export const ConfirmRejectCandidate = props => (
    <MessageModal
        title="REJECT CANDIDATE"
        message={
            <span>
                Are you sure you want to reject{' '}
                {props.singleCandidate ? <b>{props.candidateName} </b> : <b>{props.totalCandidates} candidates </b>}?
            </span>
        }
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
        confirmText="REJECT"
        cancelText="CANCEL"
    />
)
