import React, { useState, useEffect } from 'react'
import { SidebarLoader } from './Loaders'
import { GreyTags } from '../../../Modals/AddTag/style'

const Sidebar = ({ interestedJobs = [], isLoading }) => {
    const [showMore, setShowMore] = useState(false)
    return (
        <div className="sidebar">
            <div className="card">
                <div className="title">Interested Job Openings</div>
                {isLoading ? (
                    <SidebarLoader />
                ) : (
                    <GreyTags>
                        {showMore
                            ? interestedJobs.map(job => <span key={job.professionId}>{job.title}</span>)
                            : interestedJobs.slice(0, 3).map(job => <span key={job.professionId}>{job.title}</span>)}
                    </GreyTags>
                )}
                {interestedJobs.length > 3 ? (
                    <span className="expand" onClick={() => setShowMore(!showMore)}>
                        {showMore ? 'SHOW LESS' : 'SHOW MORE'}
                    </span>
                ) : null}
            </div>
        </div>
    )
}

export default Sidebar
