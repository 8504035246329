import HeaderBg from '../../../assets/svg/headerBG.png'

export default {
    applyHeader: {
        height: 250,
        width: 1280,
        background: HeaderBg,
    },
    new: {
        width: '50px',
        height: '25px',
        color: '#fff',
        fontSize: '12px',
        fontFamily: 'sanFranciscoMedium',
        borderRadius: '3px',
        backgroundColor: '#999999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    companyName: {
        color: 'rgb(71, 103, 160)',
        fontSize: 18,
        fontWeight: '500',
        lineHeight: 'initial',
        marginBottom: 10,
    },
    location: {
        color: '#E2E5E4',
        fontSize: 12,
        fontWeight: 'bold',
    },
    jobType: {
        color: '#8A94A6',
        fontSize: 8,
        marginLeft: 5,
    },
    jobRole: {
        margin: '10px 0',
    },
    applyNowJob: {
        height: 41,
        color: '#fff',
        fontSize: 13.44,
        marginTop: 20,
        backgroundColor: '#4767A0',
    },
    textInput: {
        width: '100%',
        height: 42,
        fontSize: 14,
    },
    submit: {
        color: '#fff',
        fontSize: 12,
    },
    submitdiv: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}
