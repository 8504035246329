import { warning } from '../../Notification'
import apiCaller from '../../apiCaller'
import actions from '../../../redux/zoom/actions'
import { LogE } from '../../../container/Helpers/errorHandler'

const { restricted } = apiCaller.type
const { formatError } = apiCaller
const baseUrl = 'zoom/'

export const ZoomUserRegister = token => async dispatch => {
    try {
        const { data } = await restricted.get(`${baseUrl}access/token/${token}`)
        if (data.error) {
            throw new Error('Failed to connect Zoom account')
        }
        dispatch({ type: actions.SET_USER_ZOOM, payload: data })
        return data
    } catch (error) {
        LogE('NetworkCalls-ZoomUserRegister-api-error', 'Failed to update', error)
        warning(formatError(error))
        return null
    }
}

export const ZoomGetUserDetails = () => async dispatch => {
    try {
        const { data } = await restricted.get(`${baseUrl}access/token`)
        if (!data.error && data.status) {
            dispatch({ type: actions.SET_USER_ZOOM, payload: data })
        }
        return data
    } catch (error) {
        LogE('NetworkCalls-ZoomGetUserDetails-api-error', 'Failed to update', error)
        return null
    }
}

export const ZoomGetUserDisconnect = () => async dispatch => {
    try {
        const { data } = await restricted.get(`${baseUrl}disconnectAccount`)
        dispatch({ type: actions.REMOVE_USER_ZOOM })
        return data
    } catch (error) {
        LogE('NetworkCalls-ZoomGetUserDetails-api-error', 'Failed to update', error)
        warning(formatError(error))
        return null
    }
}
