import styled from 'styled-components'

export const CalendarInfo = styled.div`
    width: 350px;
    height: 35px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
    background-color: #ffffff;
    padding: 2% 0 0 1.5%;
    margin-top: 2%;
`

export const GoogleCalendarData = styled.div`
    margin-top: 0;
    display: flex;
    flex-direction: column;
`
