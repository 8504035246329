import React, { useState } from 'react'
import _ from 'lodash'
import { AccessLevelDefinitions } from './AccessLevelDefinitions'
import { Collapsible } from '../style'
import { ReactComponent as TickIcon } from './../../../assets/Icons/Access/tick.svg'
import { ReactComponent as DashIcon } from './../../../assets/Icons/Access/dash.svg'
import { ReactComponent as AddIcon } from './../../../assets/Icons/Access/add.svg'
import { ReactComponent as MinusIcon } from './../../../assets/Icons/Access/minus.svg'
import Config from './../../../config'
const { isMobileDevice } = Config

const renderRowData = (permissions, category) => {
    return _.keys(permissions).map((name, index) => {
        const rowData = []
        for (let i = 1; i <= 5; i++) {
            rowData.push(<span key={i}>{permissions[name].includes(i) ? <TickIcon /> : <DashIcon />}</span>)
        }
        return (
            <>
                <div
                    className={`sub-category ${category === 'Talent Pool' && index === 2 ? 'last-sub-category' : ''}`}
                    key={index}
                >
                    <span>{name}</span>
                    {rowData.map(data => data)}
                </div>
            </>
        )
    })
}

const renderRowDataMob = permissions => {
    const ACCESS = ['Super Admin', 'Admin', 'Job Level Access', 'Recruiter', 'Interview Only']
    return _.keys(permissions).map((name, index) => {
        const itemData = permissions[name]
        return (
            <>
                <div className="sub-category" key={index}>
                    <span>{name}</span>
                </div>
                {ACCESS.map((role, a_index) => (
                    <div key={role}>
                        <span>{role}</span>
                        <span>{itemData.includes(a_index + 1) ? <TickIcon /> : <DashIcon />}</span>
                    </div>
                ))}
            </>
        )
    })
}

const totalItems = permissions => {
    return _.keys(permissions).length
}

const AccessLevelCollapsable = ({ accessLevel }) => {
    const [expand, setExpand] = useState('Company Settings Permissions')

    if (isMobileDevice)
        return (
            <div className="definition">
                <div className="collapsible-header">
                    <div>Functionality</div>
                </div>
                {_.keys(AccessLevelDefinitions).map((category, index) => {
                    return (
                        <Collapsible items={totalItems(AccessLevelDefinitions[category])}>
                            <div
                                className={`category ${category === expand && 'active'}`}
                                onClick={() => (expand === category ? setExpand('none') : setExpand(category))}
                            >
                                {expand === category ? (
                                    <MinusIcon onClick={() => setExpand('none')} />
                                ) : (
                                    <AddIcon onClick={() => setExpand(category)} />
                                )}
                                <span>{category}</span>
                            </div>
                            <div className="list">{renderRowDataMob(AccessLevelDefinitions[category])}</div>
                        </Collapsible>
                    )
                })}
            </div>
        )

    return (
        <div className="definition">
            <div className="collapsible-header">
                <div>Functionality</div>
                <div className={`${accessLevel === 1 ? 'active-header' : ''}`}>Super Admin</div>
                <div className={`${accessLevel === 2 ? 'active-header' : ''}`}>Admin</div>
                <div className={`${accessLevel === 3 ? 'active-header' : ''}`}>Job Level Access</div>
                <div className={`${accessLevel === 4 ? 'active-header' : ''}`}>Recruiter</div>
                <div className={`${accessLevel === 5 ? 'active-header' : ''}`}>Interview Only</div>
            </div>
            {_.keys(AccessLevelDefinitions).map((category, index) => {
                return (
                    <Collapsible items={totalItems(AccessLevelDefinitions[category])}>
                        <div
                            className={`category ${category === expand && 'active'}`}
                            onClick={() => (expand === category ? setExpand('none') : setExpand(category))}
                        >
                            {expand === category ? (
                                <MinusIcon onClick={() => setExpand('none')} />
                            ) : (
                                <AddIcon onClick={() => setExpand(category)} />
                            )}
                            <span>{category}</span>
                        </div>
                        <div className="list">{renderRowData(AccessLevelDefinitions[category], category)}</div>
                    </Collapsible>
                )
            })}
        </div>
    )
}

export default AccessLevelCollapsable
