import styled from 'styled-components'
import { Button } from 'antd'
import { GREY, DARK_BLUE } from '../../Helpers/Stylesheet/styledComponents'

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 30px 0;
    max-width: 1200px;
    margin: auto;
    @media only screen and (max-width: 769px) {
        display: block;
        padding: 15px;
    }
`

export const HeaderButton = styled(Button)`
    min-width: 102px;
    width: 7.467vw;
    height: 40px;
    border-color: #eee;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.09px;
    color: #bfbfbf;
    background-color: #fff !important;

    :hover {
        color: #bfbfbf !important;
        border-color: #eee;
    }

    :active {
        color: #4767a0;
        box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
        height: 41px;
        min-width: 103px;
        width: 7.54vw;
        border: 2px solid #eee;
        border-radius: 6px;
    }

    :focus {
        color: #4767a0;
        box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
        height: 41px;
        min-width: 103px;
        width: 7.54vw;
        border: 2px solid #eee;
        border-radius: 6px;
    }
`

export const ActionContainer = styled.div`
    width: 23%;
    min-width: 250px;
    @media only screen and (max-width: 769px) {
        width: 100%;
        min-width: unset;
        margin-bottom: 22px;
    }
`

export const CandidateStatusContainer = styled.div`
    min-height: 126px;
    padding: 20px;
    box-shadow: 0 2px 10px 0 #0a1f4419, 0 0 1px 0 #0a1f4414;
    border-radius: 4px;
    margin-bottom: 30px;
`

export const TagCard = styled.div`
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 #0a1f4419, 0 0 1px 0 #0a1f4414;
    padding: 20px 20px 10px;
    margin-bottom: 30px;
    background-color: #ffffff;
    .header {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-family: sanFranciscoSemi;
        color: ${GREY};
        svg + svg {
            margin-left: 20px;
        }
    }
    .tags {
        display: flex;
        flex-wrap: wrap;
        margin: 5px -5px;
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-family: sanFranciscoSemi;
            padding: 0 9px;
            height: 25px;
            border-radius: 3px;
            background-color: #f1f2f4;
            margin: 5px;
            color: #8a94a6;
        }
    }
    .sourcing-tags {
        margin-top: 30px;
        border-top: 1px solid #e1e4e8;
        .via-stp {
            margin-top: 30px;
        }
    }
    a {
        font-size: 12px;
        color: ${DARK_BLUE};
    }
`

export const FeedBackWrapper = styled.div`
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid #4767a0;
    border-radius: 3px;
`

export const CandidateProfileCont = styled.div`
    background: #fff;
    padding: 40px 40px 20px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    border-radius: 4px;
    margin-bottom: 22px;
    @media only screen and (max-width: 769px) {
        padding: 15px;
        .ant-collapse-content-active {
            display: flex;
            justify-content: center;
        }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0;
    }

    .ant-collapse-content-box {
        padding: 0 16px 0 0 !important;
    }

    :last-child {
        margin-bottom: 40px;
    }
`

export const CandidateProfileContainer = styled.div`
    width: 75%;
    min-width: 800px;
    margin-left: 2%;
    @media only screen and (max-width: 769px) {
        width: 100%;
        min-width: unset;
        margin-left: 0;
    }
`

export const ActionButton = styled(Button)`
    width: 100%;
    height: 51px;
    margin-bottom: 22px;
    display: flex;
    @media only screen and (max-width: 769px) {
        margin-bottom: 10px;
    }
`

export const NoteViewWrapper = styled.div`
    border: 1px solid #e1e4e8;
    border-radius: 4px;
    padding: 12px 15px 15px;
    text-align: left;
`

export const NoResumeWrapper = styled.div`
    height: 72px;
    width: auto;
    border: 1px solid #e1e4e8;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 25px 20px;
    @media only screen and (max-width: 995px) {
        padding: 15px;
        display: block;
        height: unset;
        text-align: center;
    }
`

export const NoteDeleteWrapper = styled.div`
    height: 20px;
    width: 517px;
    color: #53627c;
    font-size: 11px;
    line-height: 20px;
    display: flex;
    margin-top: 3px;
`
