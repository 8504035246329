import styled from 'styled-components'

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    height: 50px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
    background-color: #ffffff;
    @media only screen and (max-width: 995px) {
        padding: 15px;
        height: unset;
    }
    .title {
        font-family: sanFranciscoMedium;
        font-size: 18px;
        color: #000000;
        text-transform: uppercase;
        font-weight: 600;
        @media only screen and (max-width: 995px) {
            font-size: 14px;
            display: flex;
            align-content: center;
            align-items: center;
        }
        svg {
            margin-right: 10px;
            cursor: pointer;
            width: 16px;
        }
    }
    .open {
        font-family: sanFranciscoMedium;
        font-size: 12px;
        color: #4767a0;
        text-transform: uppercase;
        cursor: pointer;
        @media only screen and (max-width: 995px) {
            display: block;
            font-size: 10px;
        }
    }
`
