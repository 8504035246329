import { EVENTS } from './constants'

/** Used in bulkEventManager **/
export const filterBulkActionsIds = (selectedCandidate, type) => {
    let totalCount = 0
    const ids = []
    for (let id in selectedCandidate) {
        totalCount++
        const data = selectedCandidate[id]

        if (type === EVENTS.ADD_JOB) {
            if (!data.assignedJob) ids.push(id)
        } else if (type === EVENTS.HIDE_CANDIDATE) {
            ids.push(id)
        } else if (type === EVENTS.MARK_FAV) {
            if (!data.starred) ids.push(id)
        }
    }

    return { ids, totalCount }
}
