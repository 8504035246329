import styled from 'styled-components'

export const UnberryEditPass = styled.div`
    span {
        font-size: 12px;
        color: #4767a0;
        font-weight: normal;
        cursor: pointer;
        font-family: 'SFProDisplay-Light';
        letter-spacing: 0.6px;
        img {
            margin-right: 5px;
        }
    }
`
export const UnberryContainer = styled.div`
    .details {
        display: flex;
        .label {
            span {
                display: block;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.25;
                color: #999999;
                margin-top: 10px;
                margin-bottom: 30px;
            }
        }
        .values {
            margin-left: 30px;
        }
        .detailsInputs {
            input {
                display: flex;
                flex-direction: column;
                width: 250px;
                border: solid 1px #e1e4e8;
                margin-left: 85px;
                margin-bottom: 20px;
                &::placeholder {
                    color: #999999;
                }
            }
        }
    }
`
export const UnberryMessage = styled.div`
    color: #333333;
    font-family: sanFranciscoMedium;
`
