import React, { useState, useEffect } from 'react'
import { Header } from '../common'
import { ContentWrapper, FooterButtons } from '../style'
import './style.scss'
import { Input, Select, DatePicker } from 'antd'
import LocationAutoComplete from '../../Components/locationAutoComplete'
import { countryCodes } from '../../Helpers/countryCode'
import { EXPERIENCE_OPTIONS } from '../../Helpers/constants'
import moment from 'moment'
import { ReactComponent as ErrorIcon } from '../../../assets/Icons/Access/error.svg'
import { connect } from 'react-redux'
import { DEFAULT_CANDIDATE_SETTINGS, NOTICE_PERIOD_OPTIONS } from './Data'
import { getErrors } from './Helper'
import config from '../../../config'

const { isMobileDevice } = config

const AddCandidateDetails = ({
    onCancel,
    onConfirm,
    onNext,
    hasNextData,
    candidateData,
    callInProgress,
    allJobs,
    candidateSettings,
}) => {
    const activeJobs = allJobs.filter(job => job.status && job.stages && job.stages.length)
    candidateSettings = candidateSettings || DEFAULT_CANDIDATE_SETTINGS
    const [name, setName] = useState(candidateData.name || null)
    const [email, setEmail] = useState(candidateData.email || null)
    const [phone, setPhone] = useState({ cc: candidateData.countryCode || '+91', number: candidateData.phone || null })
    const [company, setCompany] = useState(candidateData.currentCompanyName || null)
    const [experience, setExperience] = useState(candidateData.totalExperience || null)
    const [notice, setNotice] = useState(candidateData.noticePeriod || null)
    const [location, setLocation] = useState(candidateData.currentLocation || null)
    const [lwd, setLwd] = useState(candidateData.noticeDate || null)
    const [errors, setErrors] = useState({
        name: null,
        email: null,
        phone: null,
        lwd: null,
        company: null,
        location: null,
        experience: null,
        notice: null,
    })

    const [jobId, setJobId] = useState(candidateData.jobId || ((allJobs.length && allJobs[0]._id) || null))
    const jobData = allJobs.length && allJobs.find(job => job._id === jobId)
    const [stages, setStages] = useState((jobData.hasOwnProperty('stages') && jobData.stages) || [])
    const [stageId, setStageId] = useState((stages && stages.length && stages[0]._id) || null)
    const [resumeLink, setResumeLink] = useState(candidateData.resumeLink || null)
    const prefixSelector = (value = 'IN') => {
        return (
            <Select
                showSearch
                style={{ width: 'fit-content', minWidth: '70px' }}
                dropdownStyle={{ width: 245 }}
                optionLabelProp="title"
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
                filterOption={(input, option) =>
                    option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) === 0
                }
                getPopupContainer={trigger => trigger.parentNode}
                value={value}
                onChange={value => {
                    setPhone({ ...phone, cc: value })
                    handleErrors({ type: 'phone', value: { cc: value, number: phone.number } })
                }}
                onBlur={() => handleErrors({ type: 'phone' })}
            >
                {countryCodes.map(code => (
                    <Select.Option key={code.code} title={code.dial_code}>
                        {code.name} ({code.dial_code}){' '}
                    </Select.Option>
                ))}
            </Select>
        )
    }

    const handleErrors = async ({ type, value }) => {
        const data = {
            email,
            name,
            phone,
            company,
            experience,
            notice,
            location,
            lwd,
        }
        setErrors(await getErrors(errors, data, { type, value }, candidateSettings))
    }

    const verifyData = () => {
        const data = {
            name,
            email,
            phone: !errors.phone && phone.number ? phone.cc + phone.number : null,
            currentCompanyName: company,
            totalExperience: experience,
            noticePeriod: notice,
            currentLocation: location,
            noticeDate: lwd,
            jobId,
            stageId,
        }
        const _errors = { ...errors }
        if (candidateSettings.name && !name) _errors.name = 'Candidate name is required'
        if (candidateSettings.email && !email) _errors.email = 'Candidate email is required'
        if (candidateSettings.phone && !phone.number) _errors.phone = 'Phone number is required'
        if (candidateSettings.currentCompanyName && !company) _errors.company = 'Current company is required'
        if (candidateSettings.totalExperience && !experience) _errors.experience = 'Experience is required'
        if (candidateSettings.noticePeriod && !notice) _errors.notice = 'Notice Period is required'
        if (candidateSettings.currentLocation && !location) _errors.location = 'Current location is required'
        if (notice === 'Serving now' && !lwd) _errors.lwd = 'Last working day is required'
        else if (notice !== 'Serving now') _errors.lwd = null
        if (!Object.values(_errors).find(error => error !== null)) return data
        else setErrors(_errors)
        return null
    }

    const setNewData = data => {
        setName(data.name || null)
        setEmail(data.email || null)
        setPhone({ cc: candidateData.countryCode || '+91', number: candidateData.phone || null })
        setCompany(data.currentCompanyName || null)
        setExperience(data.totalExperience || null)
        setNotice(data.noticePeriod || null)
        setLocation(data.currentLocation || null)
        setLwd(data.noticeDate || null)

        setJobId(candidateData.jobId || ((allJobs.length && allJobs[0]._id) || null))
        const jobData = allJobs.length && allJobs.find(job => job._id === jobId)
        setStages((jobData.hasOwnProperty('stages') && jobData.stages) || [])
        setStageId((stages && stages.length && stages[0]._id) || null)
    }

    useEffect(() => {
        setNewData(candidateData)
    }, [candidateData, hasNextData])

    return (
        <>
            <Header title="ADD CANDIDATE DETAILS" />
            <ContentWrapper mTop={true}>
                <div className="candidate">
                    <div className="candidate__row">
                        <div className="candidate__column">
                            <div className="candidate__label">Name{candidateSettings.name && '*'}</div>
                            <Input
                                size="large"
                                placeholder="Candidate Name"
                                className="candidate__input"
                                onBlur={() => handleErrors({ type: 'name' })}
                                value={name}
                                onChange={({ target: { value } }) => {
                                    setName(value)
                                    handleErrors({ type: 'name', value })
                                }}
                            />
                            {errors.name && (
                                <div className="candidate__error">
                                    <ErrorIcon className="candidate__error--icon" />
                                    <div>{errors.name}</div>
                                </div>
                            )}
                        </div>
                        <div className="candidate__column">
                            <div className="candidate__label">Email{candidateSettings.email && '*'}</div>
                            <Input
                                size="large"
                                placeholder="Candidate Email"
                                className="candidate__input"
                                onBlur={() => handleErrors({ type: 'email' })}
                                value={email}
                                onChange={({ target: { value } }) => {
                                    setEmail(value)
                                    handleErrors({ type: 'email', value })
                                }}
                            />
                            {errors.email && (
                                <div className="candidate__error">
                                    <ErrorIcon className="candidate__error--icon" />
                                    <div>{errors.email}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="candidate__row">
                        <div className="candidate__column">
                            <div className="candidate__label">Phone Number{candidateSettings.phone && '*'}</div>
                            <Input
                                addonBefore={prefixSelector(phone.cc)}
                                size="large"
                                value={phone.number}
                                onChange={({ target: { value } }) => {
                                    setPhone({ ...phone, number: value })
                                    handleErrors({ type: 'phone', value: { cc: phone.cc, number: value } })
                                }}
                                onBlur={() => handleErrors({ type: 'phone' })}
                                className="candidate__input--phone"
                                placeholder="Candidate Phone Number"
                            />
                            {errors.phone && (
                                <div className="candidate__error">
                                    <ErrorIcon className="candidate__error--icon" />
                                    <div>{errors.phone}</div>
                                </div>
                            )}
                        </div>
                        <div className="candidate__column">
                            <div className="candidate__label">
                                Current Company{candidateSettings.currentCompanyName && '*'}
                            </div>
                            <Input
                                size="large"
                                placeholder="Enter current company"
                                className="candidate__input"
                                onChange={({ target: { value } }) => setCompany(value)}
                                value={company}
                            />
                            {errors.company && (
                                <div className="candidate__error">
                                    <ErrorIcon className="candidate__error--icon" />
                                    <div>{errors.company}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="candidate__row">
                        <div className="candidate__column__small">
                            <div className="candidate__label">Experience{candidateSettings.totalExperience && '*'}</div>
                            <Select
                                size="large"
                                placeholder="Select"
                                className="candidate__input--select"
                                getPopupContainer={trigger => trigger.parentNode}
                                value={experience || undefined}
                                onChange={value => setExperience(value)}
                            >
                                {EXPERIENCE_OPTIONS.map(option => (
                                    <Select.Option key={option} value={option}>
                                        {option}
                                    </Select.Option>
                                ))}
                            </Select>
                            {errors.experience && (
                                <div className="candidate__error">
                                    <ErrorIcon className="candidate__error--icon" />
                                    <div>{errors.experience}</div>
                                </div>
                            )}
                        </div>
                        <div className="candidate__column__small">
                            <div className="candidate__label">Notice Period{candidateSettings.noticePeriod && '*'}</div>
                            <Select
                                size="large"
                                placeholder="Select"
                                className="candidate__input--select"
                                getPopupContainer={trigger => trigger.parentNode}
                                value={notice || undefined}
                                onChange={value => {
                                    setNotice(value)
                                    handleErrors({ type: 'lwd' })
                                }}
                            >
                                {NOTICE_PERIOD_OPTIONS.map(option => (
                                    <Select.Option key={option} value={option}>
                                        {option}
                                    </Select.Option>
                                ))}
                            </Select>
                            {errors.notice && (
                                <div className="candidate__error">
                                    <ErrorIcon className="candidate__error--icon" />
                                    <div>{errors.notice}</div>
                                </div>
                            )}
                        </div>
                        <div className="candidate__column">
                            <div className="candidate__label">
                                Current Location{candidateSettings.currentLocation && '*'}
                            </div>
                            <LocationAutoComplete
                                size="large"
                                className="candidate__input"
                                placeholder="Enter current location"
                                onLocationSelect={loc => setLocation(loc)}
                                initialValue={location}
                            />
                            {errors.location && (
                                <div className="candidate__error">
                                    <ErrorIcon className="candidate__error--icon" />
                                    <div>{errors.location}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    {notice === 'Serving now' && (
                        <div className="candidate__row">
                            <div className="candidate__column">
                                <div className="candidate__label">Last working day*</div>
                                <DatePicker
                                    size="large"
                                    className="candidate__input--date"
                                    showTime={false}
                                    showToday={false}
                                    format="DD MMM Y"
                                    value={lwd}
                                    onChange={value => {
                                        setLwd(value)
                                        handleErrors({ type: 'lwd', value: 'null' })
                                    }}
                                    onBlur={() => handleErrors({ type: 'lwd' })}
                                    disabledDate={current => current && current < moment().startOf('day')}
                                />
                                {errors.lwd && (
                                    <div className="candidate__error">
                                        <ErrorIcon className="candidate__error--icon" />
                                        <div>{errors.lwd}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {activeJobs && activeJobs.length > 0 && candidateData.unassigned && (
                    <div className="job">
                        <div className="job__message">Assign candidate to a job opening</div>
                        <div className="candidate__row">
                            <div className="candidate__column">
                                <div className="candidate__label">Job Opening</div>
                                <Select
                                    size="large"
                                    defaultValue={jobData && jobData.jobTitle}
                                    placeholder="Select"
                                    className="job__input"
                                    onChange={value => {
                                        setJobId(value)
                                        const jobData = activeJobs.length && activeJobs.find(job => job._id === value)
                                        setStages((jobData.hasOwnProperty('stages') && jobData.stages) || [])
                                    }}
                                >
                                    {activeJobs.map(job => (
                                        <Select.Option key={job._id} value={job._id}>
                                            {job.jobTitle}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <div className="candidate__column">
                                <div className="candidate__label">Stage</div>
                                <Select
                                    size="large"
                                    placeholder="Select"
                                    className="job__input"
                                    value={stageId}
                                    onChange={value => setStageId(value)}
                                >
                                    {stages.map(stage => (
                                        <Select.Option key={stage._id} value={stage._id}>
                                            {stage.stageName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )}
                {isMobileDevice && resumeLink && (
                    <div
                        className="resume"
                        onClick={() =>
                            resumeLink.includes('.doc')
                                ? window.open(`https://docs.google.com/viewerng/viewer?url=${resumeLink}`, '_blank')
                                : window.open(`${resumeLink}`, '_blank')
                        }
                    >
                        VIEW RESUME
                    </div>
                )}
            </ContentWrapper>
            <div className="add-candidate-division-line" />
            <div className="footer">
                <div className="footer__left">
                    <FooterButtons type="inverted" onClick={onCancel}>
                        CANCEL
                    </FooterButtons>
                    <FooterButtons
                        width={'120px'}
                        onClick={() => {
                            const data = verifyData()
                            if (data) onConfirm(data)
                        }}
                        disabled={callInProgress}
                    >
                        SAVE AND EXIT
                    </FooterButtons>
                </div>
                {hasNextData && (
                    <div className="footer__right">
                        <div
                            className={`footer__right--next-candidate-btn${callInProgress ? '-disabled' : ''}`}
                            onClick={() => {
                                const data = verifyData()
                                if (data) onNext(data)
                            }}
                        >
                            NEXT CANDIDATE
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

const mapStateToProps = ({ Job, Auth }) => {
    return {
        allJobs: Job.allJobs,
        candidateSettings: Auth.user.candidateSettings,
    }
}

export default connect(mapStateToProps)(AddCandidateDetails)
