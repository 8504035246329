import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="30px" width="30px" viewBox="0 0 30 30">
            <path
                fill="#4C4F59"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                d="M32.94 41.06l6 6a1.5 1.5 0 002.12-2.12l-3.439-3.44H46a1.5 1.5 0 000-3h-8.379l3.44-3.44a1.5 1.5 0 00-2.122-2.12l-6 6c-.271.27-.439.646-.439 1.06 0 .414.168.79.44 1.06zM40 55c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15z"
                transform="translate(-433 -213) translate(408 188)"
            />
        </svg>
    )
}

export default Icon
