import React, { Component } from 'react'
import { connect } from 'react-redux'
import algoliasearch from 'algoliasearch/lite'
import AppRouter from './router'
import { Layout, Menu, Icon, Avatar, Popover } from 'antd'
import { Topbar, Logo, Options } from './style'
import './style.css'
import { CAL_RESPONSE_CODES, GCAL_USER_STATUS, ACCESS_LEVEL_DEFINITIONS, SOURCING_STATUS } from './../Helpers/constants'
import DropdownIconIcon from '../../assets/Icons/Board/Dropdown'
import { RefreshToken, Signout } from '../../services/NetworkCalls/Authentication'
import { ModifyCacheData } from '../../redux/auth/actionListeners'
import { AddUserGCal, SetUserGcal } from '../../redux/gCal/actionListeners'
import getAvatar from '../../services/getAvatar'
import { warning } from '../../services/Notification'
import config from '../../config'
import CreateNewCompanyModal from '../Components/createNewCompany'
import { HotKeys } from 'react-keyboard'
import { keyMap } from '../Helpers/KeyMap'
import GlobalSearch from './globalSearch'
import * as querystring from 'querystring'
import { AES, enc } from 'crypto-js'
import { Modal } from './../Modals'
import { MODAL_TYPES } from '../Helpers/ModalTypes'
import { history } from '../../redux/store'
import Error from '../Pages/Error'
import { ProjectFooter } from '../Components/ProjectFooter'
import Typesense from 'typesense'
const { careerPageURL, indexName, typesense } = config.config()
const client = algoliasearch('KC3488J1QJ', '08bf615eb3bfb1a9300be44426ab6766')
const index = client.initIndex(indexName)

const typesenseClient = new Typesense.Client({
    nodes: [
        {
            host: typesense.host,
            port: typesense.port,
            protocol: 'https',
        },
    ],
    apiKey: typesense.search_api_key,
})

const { Content } = Layout

class App extends Component {
    constructor(props) {
        super(props)

        const route = props.location.pathname.split('/')[1]
        const accessLevel = props.user.accessLevel
        this.allowShortcuts = props.user.allowShortcuts
        this.state = {
            accessLevel,
            selectedKey: route,
            options: [
                'Create New Company',
                'Account Settings',
                // 'About Us!',
                'Logout',
            ],
            darkBackground:
                route === 'dashboard' ||
                route === 'todo' ||
                route === 'reports' ||
                route === 'analytics' ||
                route === 'inbox' ||
                props.location.pathname.indexOf('openings/createJob') > -1 ||
                props.location.pathname.indexOf('openings/edit') > -1,
            candidateText: undefined,
            visibleAcPopover: false,
            candidates: [],
            showSearch: ['/openings', '/account', '/dashboard', '/todo'].includes(props.location.pathname),
            createNewCompany: false,
            isSearchFocused: false,
            switchTabsAllowed: true,
        }

        let { flag } = querystring.parse(props.location.search.substring(1))
        if (flag) {
            let searchString = props.location.search.split('=')
            const { googleAuth } = props.mixed.cachedData

            if (googleAuth && googleAuth.inProgress && googleAuth.encryptionKey) {
                const { encryptionKey } = googleAuth
                const decryptedData = AES.decrypt(searchString[1], encryptionKey)
                const plainData = decryptedData.toString(enc.Utf8)
                const [encryptionId, status, data] = plainData.split(':')
                if (encryptionId === encryptionKey && status === CAL_RESPONSE_CODES.success) {
                    const { availability, accounts } = props.gCal || {}
                    const { ACTIVE, DEFAULT } = GCAL_USER_STATUS
                    const [email, _id] = data.split('|')
                    const newAccount = {
                        _id,
                        email,
                        status: accounts ? ACTIVE : DEFAULT,
                    }
                    const gCalObject = {
                        inUse: true,
                        accounts: [newAccount],
                        availability,
                    }
                    if (accounts) props.AddUserGCal(newAccount)
                    else props.SetUserGcal(gCalObject)
                    this.state['calPopUpStatus'] = { status: true, step: 2 }
                } else this.state['calPopUpStatus'] = { status: true, step: 5, message: data }

                props.ModifyCacheData('googleAuth', 'DELETE')
            }
        }
    }

    componentDidMount() {
        this.props.RefreshToken()
    }

    componentDidUpdate() {
        const route = this.props.location.pathname.split('/')[1]
        const darkBackground =
            route === 'dashboard' ||
            route === 'openings' ||
            route === 'talent-pool' ||
            route === 'todo' ||
            route === 'sourcing' ||
            route === 'reports' ||
            route === 'analytics' ||
            route === 'inbox' ||
            this.props.location.pathname.indexOf('openings/createJob') > -1 ||
            this.props.location.pathname.indexOf('openings/edit') > -1
        const showSearch = ['/openings', '/account', '/dashboard', '/todo'].includes(this.props.location.pathname)

        if (
            this.state.darkBackground !== darkBackground ||
            this.state.selectedKey !== route ||
            this.state.showSearch !== showSearch
        ) {
            this.setState({
                darkBackground,
                selectedKey: route,
                showSearch,
                candidateText: undefined,
                candidates: [],
            })
        }
    }

    goToTalentPool = e => {
        if (e.key === 'talent_pool-1') {
            this.props.history.push(`/talent-pool`)
        } else if (e.key === 'talent_pool-2') {
            this.props.history.push({
                pathname: `/talent-pool`,
                state: {
                    defaultUnAssignedSelected: true,
                },
            })
        } else {
            this.props.history.push({
                pathname: `/talent-pool`,
                state: {
                    defaultIncompleteProfileSelected: true,
                },
            })
        }
    }

    changeTab = e => {
        if (e.key.indexOf('talent_pool') !== -1) return
        if (e.key.indexOf('talent-pool') !== -1) {
            this.setState({ selectedKey: 'talent-pool' })
            this.props.history.push(`/talent-pool`)
        } else {
            this.setState({ selectedKey: e.key })
            this.props.history.push(`/${e.key}`)
        }
    }

    takeAction = async () => {
        this.props.history.push('/account')
        this.setState({ visibleAcPopover: false })
    }

    gotoCareerEdit = () => {
        this.props.history.push('/careers/customise')
        this.setState({ visibleAcPopover: false })
    }

    logout = async () => {
        this.props.Signout()
    }

    createNewCompany = () => {
        this.setState({ createNewCompany: true, visibleAcPopover: false })
    }

    handleCancel = () => {
        this.setState({ createNewCompany: false })
    }

    allowSwitchTabs = value => {
        this.setState({ switchTabsAllowed: value, isSearchFocused: !value })
    }

    searchCandidate = async query => {
        const { companyId, id: userId, accessLevel, accessibleJobs } = this.props.user
        const { allJobs } = this.props.job

        if (!query) {
            this.setState({ candidates: [] })
            return
        }

        let filters = `companyId:"${companyId}"`
        if (accessLevel === ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel) {
            filters = `${filters} && ( userId:"${userId}" || assignedTo:"${userId}" )`
        } else if (accessLevel === ACCESS_LEVEL_DEFINITIONS.JOB_LEVEL_ACCESS.accessLevel) {
            let jobFilter = ''
            accessibleJobs.forEach(aj => {
                jobFilter += `jobId:${aj} || `
            })
            jobFilter = jobFilter.slice(0, -4)

            filters = `${filters} && (${jobFilter})`
            filters = ` ${filters} || ( userId:"${userId}" || assignedTo:"${userId}" ) `
        }

        typesenseClient
            .collections(typesense.collection)
            .documents()
            .search({ q: query, query_by: 'email,name', filter_by: filters, per_page: 20 })
            .then(({ hits: candidates }) => {
                candidates = candidates
                    ? candidates.map(({ document: can }) => {
                          const job = allJobs.find(job => job._id === can.jobId)
                          if (job) {
                              can.jobTitle = job.jobTitle
                              if (can.isRejected || !can.status) {
                                  can.stageName = can.isRejected ? 'REJECTED' : 'ARCHIVED'
                              } else {
                                  const stage = job.stages.find(stg => stg._id === can.stageId)
                                  if (stage) {
                                      can.stageName = stage.stageName
                                  }
                              }
                          }

                          return can
                      })
                    : []
                this.setState({ candidates })
            })
            .catch(err => {
                if (err) {
                    warning(err.message)
                }
            })
    }

    goToViewCareerPage = () => {
        window.open(careerPageURL(this.props.user.domain), '_blank')
    }

    handleChange = candidateText => {
        this.setState({ candidateText })
    }

    viewCandidate = async can => {
        this.props.history.push(`/candidate/${can.objectID}`)
    }

    handleAcPopoverChange = visibleAcPopover => {
        this.setState({ visibleAcPopover })
    }

    handlers = {
        search: e => {
            if (
                this.allowShortcuts &&
                !this.state.isSearchFocused &&
                this.props.user.accessLevel < ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel
            ) {
                e.preventDefault()
                this.searchInput && this.searchInput.focus()
            }
        },
        switchTabRight: e => {
            if (this.allowShortcuts && !this.state.isSearchFocused && this.state.switchTabsAllowed) {
                if (
                    ['dashboard', 'openings', 'talent-pool', 'todo'].includes(this.state.selectedKey) &&
                    this.state.isSearchFocused
                )
                    e.preventDefault()
                let nextPage = null
                switch (this.state.selectedKey) {
                    case 'dashboard':
                        nextPage = this.props.user.accessLevel <= 4 ? '/openings' : '/talent-pool'
                        break
                    case 'openings':
                        nextPage = '/talent-pool'
                        break
                    case 'talent-pool':
                        nextPage = '/todo'
                        break
                    case 'todo':
                        nextPage = '/dashboard'
                        break
                    default:
                        nextPage = null
                }
                if (nextPage) this.props.history.push(nextPage)
            }
        },
        switchTabLeft: e => {
            if (this.allowShortcuts && !this.state.isSearchFocused && this.state.switchTabsAllowed) {
                if (
                    ['dashboard', 'openings', 'talent-pool', 'todo'].includes(this.state.selectedKey) &&
                    this.state.isSearchFocused
                )
                    e.preventDefault()
                let prevPage = null
                switch (this.state.selectedKey) {
                    case 'dashboard':
                        prevPage = '/todo'
                        break
                    case 'openings':
                        prevPage = '/dashboard'
                        break
                    case 'talent-pool':
                        prevPage = this.props.user.accessLevel <= 4 ? '/openings' : 'dashboard'
                        break
                    case 'todo':
                        prevPage = '/talent-pool'
                        break
                    default:
                        prevPage = null
                }
                if (prevPage) this.props.history.push(prevPage)
            }
        },
    }

    handleInputFocus = value => {
        this.setState({ isSearchFocused: value })
    }

    render() {
        const { url } = this.props.match
        const { darkBackground, selectedKey, visibleAcPopover, showSearch, calPopUpStatus } = this.state
        const { userName, email, accessLevel } = this.props.user
        const { location, sourcingStatus } = this.props

        if (
            accessLevel === ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel &&
            ['openings', 'talent-pool', 'careers'].includes(location.pathname.split('/')[1])
        ) {
            return <Error {...this.props} />
        }

        return (
            <HotKeys keyMap={keyMap.global} handlers={this.handlers}>
                <Layout className="layout" style={{ backgroundColor: darkBackground ? '#f7f8fc' : '#fff' }}>
                    <div className="mobile-menu">
                        <Logo onClick={() => this.props.history.push('/dashboard')} />
                        <button className="tap-menu" onClick={() => this.setState({ shown: !this.state.shown })}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    {this.state.shown ? (
                        <div className="menu-content">
                            <Menu
                                key="key-dashboard-menu"
                                theme="light"
                                mode="horizontal"
                                className="topbar-menu"
                                onSelect={this.changeTab}
                                selectedKeys={[selectedKey]}
                            >
                                <Menu.Item onClick={this.changeTab} key="dashboard">
                                    Dashboard
                                </Menu.Item>

                                {accessLevel < 3 && (
                                    <Menu.Item onClick={this.changeTab} key="reports">
                                        Reports
                                    </Menu.Item>
                                )}

                                {/*Openings disabled for "interview only" user access*/}
                                {accessLevel < 5 && (
                                    <Menu.Item onClick={this.changeTab} key="openings">
                                        Openings
                                    </Menu.Item>
                                )}

                                {/*Talent pool disabled for "interview only" user access*/}
                                {accessLevel < 5 && (
                                    <Menu.SubMenu
                                        key="talent-pool"
                                        className={`app-tp ${this.props.location.pathname === '/talent-pool' &&
                                            'ant-menu-item-selected'}`}
                                        onTitleClick={this.changeTab}
                                        title={
                                            <span>
                                                <span>Talent Pool</span>
                                                <Icon className="app-tp-ddi" component={DropdownIconIcon} />
                                            </span>
                                        }
                                    >
                                        <Menu className="app-tp-menu">
                                            <Menu.Item
                                                key="talent_pool-1"
                                                onClick={this.goToTalentPool}
                                                className="app-tp-item"
                                            >
                                                All Candidates
                                            </Menu.Item>
                                            <Menu.Item
                                                key="talent_pool-2"
                                                onClick={this.goToTalentPool}
                                                className="app-tp-item"
                                            >
                                                Unassociated Candidates
                                            </Menu.Item>
                                            <Menu.Item
                                                key="talent_pool-3"
                                                onClick={this.goToTalentPool}
                                                className="app-tp-item"
                                            >
                                                Incomplete Profiles
                                            </Menu.Item>
                                        </Menu>
                                    </Menu.SubMenu>
                                )}

                                {sourcingStatus === SOURCING_STATUS.ENABLED && (
                                    <Menu.Item onClick={this.changeTab} key="sourcing">
                                        Sourcing Talent Pool
                                    </Menu.Item>
                                )}

                                <Menu.Item onClick={this.changeTab} key="todo">
                                    Todo List
                                </Menu.Item>
                            </Menu>
                        </div>
                    ) : null}

                    <div className="desktop-navbar">
                        <Topbar>
                            <Logo onClick={() => this.props.history.push('/dashboard')} />
                            <Menu
                                key="key-dashboard-menu"
                                theme="light"
                                mode="horizontal"
                                className="topbar-menu"
                                onSelect={this.changeTab}
                                selectedKeys={[selectedKey]}
                            >
                                <Menu.Item onClick={this.changeTab} key="dashboard">
                                    Dashboard
                                </Menu.Item>

                                {/*Openings disabled for "interview only" user access*/}
                                {accessLevel < 5 && (
                                    <Menu.Item onClick={this.changeTab} key="openings">
                                        Openings
                                    </Menu.Item>
                                )}

                                <Menu.Item onClick={this.changeTab} key="inbox">
                                    Inbox
                                </Menu.Item>

                                {/*Talent pool disabled for "interview only" user access*/}
                                {accessLevel < 5 && (
                                    <Menu.SubMenu
                                        key="talent-pool"
                                        className={`app-tp ${this.props.location.pathname === '/talent-pool' &&
                                            'ant-menu-item-selected'}`}
                                        onTitleClick={this.changeTab}
                                        title={
                                            <span>
                                                <span>Talent Pool</span>
                                                <Icon className="app-tp-ddi" component={DropdownIconIcon} />
                                            </span>
                                        }
                                    >
                                        <Menu className="app-tp-menu">
                                            <Menu.Item
                                                key="talent_pool-1"
                                                onClick={this.goToTalentPool}
                                                className="app-tp-item"
                                            >
                                                All Candidates
                                            </Menu.Item>
                                            <Menu.Item
                                                key="talent_pool-2"
                                                onClick={this.goToTalentPool}
                                                className="app-tp-item"
                                            >
                                                Unassociated Candidates
                                            </Menu.Item>
                                            <Menu.Item
                                                key="talent_pool-3"
                                                onClick={this.goToTalentPool}
                                                className="app-tp-item"
                                            >
                                                Incomplete Profiles
                                            </Menu.Item>
                                        </Menu>
                                    </Menu.SubMenu>
                                )}

                                {sourcingStatus === SOURCING_STATUS.ENABLED && (
                                    <Menu.Item onClick={this.changeTab} key="sourcing">
                                        Sourcing Talent Pool
                                    </Menu.Item>
                                )}

                                <Menu.Item onClick={this.changeTab} key="todo">
                                    Todo List
                                </Menu.Item>

                                {accessLevel < 3 && (
                                    <Menu.Item onClick={this.changeTab} key="reports">
                                        Reports
                                    </Menu.Item>
                                )}
                            </Menu>

                            <Options>
                                {[1, 2, 3, 4].includes(accessLevel) && showSearch ? (
                                    <GlobalSearch
                                        candidates={this.state.candidates}
                                        reference={input => (this.searchInput = input)}
                                        handleFocus={this.handleInputFocus}
                                        onSearch={this.searchCandidate}
                                        onSelect={this.viewCandidate}
                                    />
                                ) : null}
                                {/* <Icon style={{ marginRight: '10px', fontSize: '20px' }} component={NotificationIcon} /> */}

                                <Popover
                                    arrowPointAtCenter
                                    placement="bottomRight"
                                    overlayClassName="dropdown-popover-ac"
                                    visible={visibleAcPopover}
                                    onVisibleChange={this.handleAcPopoverChange}
                                    style={{ width: '191px', padding: 0 }}
                                    content={
                                        <Menu style={{ borderRight: 0 }} mode="inline" defaultOpenKeys={['company']}>
                                            <Menu.Item
                                                onClick={this.takeAction}
                                                key="drop-dn-ac-settings"
                                                className="ac-dropdown color-blue"
                                            >
                                                <span>Account settings</span>
                                            </Menu.Item>

                                            {accessLevel < 3 && (
                                                <Menu.Item key="drop-dn-career-page" className="ac-dropdown color-blue">
                                                    <Popover
                                                        arrowPointAtCenter
                                                        placement="leftTop"
                                                        overlayClassName="dropdown-popover-ac"
                                                        style={{ width: '191px', padding: 0 }}
                                                        content={
                                                            <Menu style={{ borderRight: 0 }} mode="inline">
                                                                <Menu.Item
                                                                    onClick={this.goToViewCareerPage}
                                                                    key="sub1"
                                                                    className="ac-sub-dropdown color-blue"
                                                                >
                                                                    <span>View</span>
                                                                </Menu.Item>

                                                                <Menu.Item
                                                                    onClick={this.gotoCareerEdit}
                                                                    key="sub2"
                                                                    className="ac-sub-dropdown color-blue"
                                                                >
                                                                    <span>Customize</span>
                                                                </Menu.Item>
                                                            </Menu>
                                                        }
                                                    >
                                                        <span>Career Page</span>
                                                    </Popover>
                                                </Menu.Item>
                                            )}

                                            <Menu.Item
                                                onClick={this.logout}
                                                key="drop-dn-logout"
                                                className="ac-dropdown color-blue"
                                            >
                                                <span>Logout</span>
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                                    <Avatar className="app-name-avatar">{getAvatar(userName || email)}</Avatar>
                                </Popover>
                            </Options>
                        </Topbar>
                    </div>

                    <Content className="overall-content">
                        <AppRouter url={url} allowSwitchTabs={this.allowSwitchTabs} />
                    </Content>
                    <ProjectFooter {...this.props} />

                    <Modal
                        type={MODAL_TYPES.CALENDAR_ONBOARDING}
                        onCancel={() =>
                            this.setState({ calPopUpStatus: false }, () => history.replace(window.location.pathname))
                        }
                        calPopUpStatus={calPopUpStatus}
                        width={750}
                        bodyStyle={{ padding: 0 }}
                        visible={!!calPopUpStatus}
                        closable={true}
                    />

                    {this.state.createNewCompany ? (
                        <CreateNewCompanyModal visible={this.state.createNewCompany} onCancel={this.handleCancel} />
                    ) : null}
                </Layout>
            </HotKeys>
        )
    }
}

const mapStateToProps = state => {
    const { gCal, Auth, Mixed, Job } = state
    const {
        user,
        user: { sourcing },
    } = Auth
    return {
        gCal,
        user,
        mixed: Mixed,
        job: Job,
        sourcingStatus: (sourcing && sourcing.status) || SOURCING_STATUS.DISABLED,
    }
}

export default connect(
    mapStateToProps,
    { Signout, ModifyCacheData, AddUserGCal, SetUserGcal, RefreshToken }
)(App)
