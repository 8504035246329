import React, { useState, useEffect } from 'react'
import { Checkbox, Collapse, Icon, Input } from 'antd'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { EVENTS } from './constants'

const { Search } = Input
const { Panel } = Collapse

const customPanelStyle = {
    background: '#fff',
    border: 0,
    overflow: 'hidden',
    color: '#333',
    fontFamily: 'SFProText-Bold',
}

const refineAndSort = (masterList, search, selected, { idName, dataName }) => {
    const selectObject = {}
    const finalList = selected.map(each => {
        selectObject[each[idName]] = each
        return { label: each[dataName], value: each[idName] }
    })

    masterList.forEach(each => {
        const data = { label: each[dataName], value: each[idName] }
        if (data.label.toLowerCase().includes(search.toLowerCase())) {
            if (!selectObject[data.value]) finalList.push(data)
        }
    })
    return finalList
}

const STATUS_LIST = [
    { name: 'Active', status: 1 },
    { name: 'Archived', status: 0 },
    { name: 'Rejected', status: 2 },
    { name: 'Joined', status: 3 },
]

const refineAndSortLocation = (masterList, search, selected) => {
    const finalList = [...selected]

    masterList.forEach(each => {
        if (each.toLowerCase().includes(search.toLowerCase())) {
            if (!selected.includes(each)) finalList.push(each)
        }
    })

    return finalList
}

const RefineBox = ({ onFilterSelected, jobId, status, allJobs, selectedFilter }) => {
    const [jobObj, selectedJobObj] = [{}, {}]

    allJobs.forEach(({ _id, jobTitle, stages }) => {
        jobObj[_id] = jobTitle
    })
    if (selectedFilter.jobId) selectedFilter.jobId.forEach(each => (selectedJobObj[each] = each))

    const [jobSearch, setJobSearch] = useState('')

    const hideJobClearAll = !(selectedFilter.jobId && selectedFilter.jobId.length > 0)

    return (
        <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <Icon component={DownIcon} rotate={isActive ? 180 : 0} />}
            expandIconPosition="right"
        >
            <Panel header="Status" key="status" style={customPanelStyle}>
                <Checkbox.Group
                    value={selectedFilter.status || []}
                    onChange={data => onFilterSelected(EVENTS.STATUS, data)}
                >
                    {STATUS_LIST.map(({ name, status: statusState }) => {
                        const { count = null } = status.find(each => each._id === statusState) || {}
                        if (count === null) return undefined
                        else
                            return (
                                <Checkbox key={statusState} value={statusState}>
                                    <div>{name}</div>
                                    <div>{count}</div>
                                </Checkbox>
                            )
                    })}
                </Checkbox.Group>
            </Panel>

            <Panel
                header="Openings"
                key="assigned"
                style={customPanelStyle}
                extra={
                    <span
                        hidden={hideJobClearAll}
                        className="clear-all"
                        onClick={() => onFilterSelected(EVENTS.OPENINGS, [])}
                    >
                        Clear All
                    </span>
                }
            >
                {jobId.length ? (
                    <>
                        <Input
                            placeholder="Search"
                            size="large"
                            allowClear
                            onChange={({ target: { value } }) => setJobSearch(value)}
                        />
                        <div className="mt-20" />
                        <Checkbox.Group
                            value={selectedFilter.jobId || []}
                            onChange={data => onFilterSelected(EVENTS.OPENINGS, data)}
                        >
                            {jobId
                                .filter(
                                    ({ _id }) =>
                                        _id &&
                                        jobObj[_id] &&
                                        jobObj[_id].toLowerCase().includes(jobSearch.toLowerCase())
                                )
                                .sort((a, b) => {
                                    if (selectedJobObj[a._id]) return -1
                                    return a.count < b.count ? 1 : -1
                                })
                                .map(({ _id, count, isJobArchived }) => (
                                    <Checkbox key={_id} value={_id}>
                                        <div className="flex">
                                            <div className="job-name">{jobObj[_id]}</div>
                                            {isJobArchived && <div className="archived-tag">Archived</div>}
                                        </div>
                                        <div>{count}</div>
                                    </Checkbox>
                                ))}
                        </Checkbox.Group>
                    </>
                ) : null}
            </Panel>
        </Collapse>
    )
}

export default RefineBox
