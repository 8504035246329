import styled from 'styled-components'

export const Preview = styled.div`
    padding: 25px 25px 0;
    opacity: ${props => props.disabled && '0.3'};
    @media only screen and (max-width: 995px) {
        padding: 25px 20px 60px 20px;
    }
`
export const Content = styled.div`
    width: 50%;
    min-width: 400px;
    background-color: #fff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin-left: 20px;
    @media only screen and (max-width: 995px) {
        margin-left: 0px;
        min-width: unset;
        width: 100%;
    }
`
export const Header = styled.div`
    height: 40px;
    background-color: #eeeeee;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    border-bottom: 1px solid #d5d5d5;
    padding-left: 20px;
    display: flex;
    align-items: center;
`
export const Body = styled.div`
    border-radius: 4px;
    border: solid 1px #e1e4e8;
    min-height: 400px;
`
export const Field = styled.div`
    display: flex;
    align-items: center;
    margin: 0 20px;
    min-height: 42px;
    border-bottom: solid 1px #f1f1f4;
    .subject {
        font-size: 12px;
        color: #333333;
        outline: none;
        border: none;
        padding: 0 5px;
        @media only screen and (max-width: 769px) {
            margin-right: 5px;
        }
    }

    .ghost-select .ant-select-selection {
        border: none !important;
    }

    .ghost-select > .ant-select-selection {
        box-shadow: none;
    }

    .ghost-select .ant-select-selection__rendered {
        margin-left: 3px;
    }

    .ghost-select > .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
        margin-top: 5px;
        margin-left: 2.5px;
        @media only screen and (max-width: 995px) {
            margin-left: 5px;
        }
    }

    .ghost-select > .ant-select-selection--multiple .ant-select-selection__choice {
        border-radius: 12px;
        background-color: #eeeeee;
        height: 22px;
        line-height: 20px;
    }

    .ghost-select > .ant-select-selection--multiple .ant-select-selection__choice__content {
        margin-left: 5px;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 500;
        font: sanFrancisco;
    }

    .ghost-select > .ant-select-selection--multiple .ant-select-selection__choice__remove {
        right: 0;
        padding: 0 5px;
        background-color: #d6d6d6;
        transform: none;
    }

    .ghost-select .ant-select-selection__placeholder {
        margin-left: 0;
    }

    .ghost-select-dropdown > div {
        transform: none !important;
    }

    .ghost-select-dropdown .ant-select-dropdown-menu-item .option {
        border-bottom: solid 0.5px rgba(102, 102, 102, 0.3);
        padding: 10px 0;
    }

    .ghost-select-dropdown .ant-select-dropdown-menu-item .email {
        color: #666;
        white-space: normal;
    }

    .ghost-select-dropdown .ant-select-dropdown-menu-item {
        padding: 0 20px;
    }

    .ghost-select-dropdown .ant-select-dropdown-menu-item .option {
        border-bottom: solid 0.5px rgba(102, 102, 102, 0.3);
        padding: 10px 0;
    }

    .ghost-select-dropdown .ant-select-dropdown-menu-item:last-child {
        border: none;
    }

    .ghost-select-dropdown .ant-select-dropdown-menu-item span {
        display: block;
        font-size: 12px;
        color: #333333;
    }
`
export const Label = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 12px;
    color: #999999;
    width: 40px;
    margin-right: 10px;
`
export const Tag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 5px 20px;
    border-radius: 12px;
    background-color: #eeeeee;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: 995px) {
        width: 150px;
    }
    .email {
        .name {
            color: #666666;
        }
        color: #999;
        margin-left: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
export const Footer = styled.div`
    display: flex;
    align-items: center;
    margin: 15px 5px;
    span {
        font-size: 12px;
        color: #333333;
        margin: 0 5px;
    }
    .info:hover path {
        fill: #000;
        cursor: pointer;
    }
`
