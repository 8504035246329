import React, { Component } from 'react'
import { ReactComponent as LoadingIcon } from '../../../../assets/Icons/Openings/loading.svg'

export default class Loader extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.pageRefreshhandler)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.pageRefreshhandler)
    }

    pageRefreshhandler(e) {
        var confirmationMessage = 'o/'
        e.returnValue = confirmationMessage
        return confirmationMessage
    }

    render() {
        return (
            <div className="status img">
                <LoadingIcon />
                <p className="title">Uploading File.</p>
                <p className="desc">
                    This process may take some time, please refrain from
                    <br />
                    closing browser or going back.
                </p>
            </div>
        )
    }
}
