import React from 'react'
import './style.scss'
import { subscribeToMailchimp } from '../../../services/NetworkCalls/BackToWork'
import Header from './Header'
import Footer from './Footer'
import SuccessScreen from './SuccessScreen'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import LiveUpdateBuzzer from './LiveUpdateBuzzer'

export default class HiringInitiative extends React.Component {
    constructor() {
        super()
        this.state = {
            name: null,
            email: null,
            emailError: false,
            nameError: false,
            showSuccessScreen: false,
        }
    }

    setField = (fieldName, event) => {
        let args = {}
        args[fieldName] = event.target.value

        if (this.state[`${fieldName}Error`]) {
            args[`${fieldName}Error`] =
                fieldName === 'email' ? this.isValidEmailAddress(args[fieldName]) : this.isValidName(args[fieldName])
        }

        this.setState(args)
    }

    onSubmit = async event => {
        event.preventDefault()
        const { name, email } = this.state
        const isValidName = this.isValidName(name)
        const isValidEmail = this.isValidEmailAddress(email)

        if (!isValidEmail || !isValidName) {
            return this.setState({
                nameError: !isValidName,
                emailError: !isValidEmail,
            })
        }
        try {
            await subscribeToMailchimp({ name, email })
            this.setState({ showSuccessScreen: true })
        } catch (error) {
            console.error(error)
        }
    }

    onSignup = () => this.props.history.push('/signup')

    isValidEmailAddress = value => {
        if (value) {
            value = value.trim()
            let validEmail = value.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )

            if (validEmail) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    isValidName = value => {
        if (value) return value.trim().length > 2
        else return false
    }

    getSuccessScreen = () => {
        return (
            <div className="next-opportunity">
                <div className="next-opportunity-container">
                    <Header />
                    <SuccessScreen />
                </div>
                <Footer />
            </div>
        )
    }

    render() {
        const emailErrorClass = this.state.emailError ? 'next-opportunity-download-name-error' : ''
        const nameErrorClass = this.state.nameError ? 'next-opportunity-download-name-error' : ''

        if (this.state.showSuccessScreen) return this.getSuccessScreen()

        return (
            <div className="next-opportunity">
                <Helmet>
                    <title>Covid-19 Effect on India Inc: Who's still hiring?</title>
                </Helmet>
                <div className="next-opportunity-container">
                    <Header />

                    <div className="next-opportunity-download">
                        <div className="next-opportunity-download-pattern">
                            <img src={require('../NextOpportunity/images/download-pattern.png')} />
                        </div>
                        <div className="next-opportunity-download-content">
                            <div>Covid-19 Effect on India Inc: Who's still hiring?</div>
                            <h1>
                                <span>
                                    We’ve compiled this list of Indian companies that are still hiring. This is a live
                                    list that’s being updated constantly. You can help keep it up-to-date
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="next-opportunity-form-section">
                    <div className="next-opportunity-container">
                        <LiveUpdateBuzzer />
                        <div className="next-opportunity-form-section-iframe">
                            <iframe
                                src="https://airtable.com/embed/shrKO4nu5K6N362qb?backgroundColor=purple&viewControls=on"
                                title="airtableEmployeesIFrame"
                            ></iframe>
                            <div className="next-opportunity-iframe-contribute-btn">
                                <p>
                                    Have info about an Indian company that’s not on the list yet? What’s your company
                                    doing?
                                </p>
                                <span>Know of other hiring freezes?</span>
                                <a href="https://airtable.com/shrlVPryBibJo5hAg" target="_blank">
                                    CONTRIBUTE <span>→</span>
                                </a>
                            </div>
                        </div>

                        <div className="next-opportunity-form-download-card">
                            <div className="next-opportunity-txt-content">
                                <h1>
                                    <span>Over 500+ recently laid off, </span>talented candidates are looking out for
                                    their next opportunity. If you’re a recruiter or an employer that’s hiring, you can
                                    get a download of all of this data (including the resumes of all these candidates)
                                    instantly in your inbox in a single .xlsx file by{' '}
                                    <span>sharing your name and e-mail below.</span>
                                </h1>
                            </div>
                            <div className="next-opportunity-name-email">
                                <div className={`next-opportunity-download-name ${nameErrorClass}`}>
                                    <input placeholder="Enter Your Name" onChange={this.setField.bind(null, 'name')} />
                                    {this.state.nameError ? (
                                        <img src={require('../NextOpportunity/images/error-icon.svg').default} />
                                    ) : (
                                        <img src={require('../NextOpportunity/images/name-icon.svg').default} />
                                    )}
                                </div>
                                <div className={`next-opportunity-download-name ${emailErrorClass}`}>
                                    <input
                                        placeholder="Enter Your Email"
                                        onChange={this.setField.bind(null, 'email')}
                                    />
                                    {this.state.emailError ? (
                                        <img src={require('../NextOpportunity/images/error-icon.svg').default} />
                                    ) : (
                                        <img src={require('../NextOpportunity/images/email-icon.svg').default} />
                                    )}
                                </div>
                                <div className="next-opportunity-download-button">
                                    <button onClick={this.onSubmit}>DOWNLOAD</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="next-opportunity-standup-idea">
                    <div className="next-opportunity-container">
                        <div className="next-opportunity-meeting-icon">
                            <img src={require('../NextOpportunity/images/meeting.svg')} />
                        </div>
                        <h3>Are you a recently laid-off candidate looking for your next job?</h3>
                        <p>
                            Back-to-work is a project by SpringRecruit to help people just like yourself get back to
                            work by
                            <br />
                            showcasing your profile to recruiters, hiring managers and employers that access this
                            website
                            <br />
                            looking for candidates like you.{' '}
                        </p>
                        <Link to="/Back-to-Work-project-by-Springworks/form">
                            Fill up a short form to get added to the public list →{' '}
                        </Link>
                    </div>
                </div>

                <div className="next-opportunity-who-made-this">
                    <div className="next-opportunity-who-made-this-header">
                        <h2>Who made this?</h2>
                    </div>
                    <div className="next-opportunity-who-made-this-content">
                        <div className="next-opportunity-who-made-this-springrecruit-logo">
                            <img src={require('../NextOpportunity/images/spring-recruit.svg')} />
                        </div>
                        <div className="next-opportunity-who-made-this-springrecruit-para">
                            <p>
                                Hi! We’re Springworks. We are building tools and products to usher in the Future of
                                Recruiting! SpringRecruit is our step in that direction. SpringRecruit is a forever-free
                                Applicant Tracking System (ATS) that saves you the hassles of Google Sheets and Excel
                                files just like these. With our elegant and advanced, yet simple-to-use ATS you get
                                total control over the creation, promotion and management of job openings. That’s why
                                SpringRecruit is already being used by high-growth upstarts like BankOpen and many more
                                organisations. <Link to="/signup">Sign up</Link> to get access to SpringRecruit
                            </p>
                            <div className="next-opportunity-download-pattern-bottom">
                                <img src={require('../NextOpportunity/images/download-pattern.png')} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
