import React from 'react'

const SVGComponent = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g
                id="ATS-Board---Sort-By-Dropdown-(Hover-+-Selected-state"
                transform="translate(-197 -323)"
                stroke="#697998"
            >
                <g id="ATS-Stages" transform="translate(40 200)">
                    <g id="Stage1">
                        <g id="Candidates" transform="translate(20 64)">
                            <g id="Candidate-Tile">
                                <g id="Action-Buttons" transform="translate(72 60)">
                                    <path
                                        d="M73.4192388,6.5 L74.9096194,5.00961941 C75.1634602,4.75577862 75.1634602,4.34422138 74.9096194,4.09038059 C74.6557786,3.8365398 74.2442214,3.8365398 73.9903806,4.09038059 L72.5,5.58076118 L71.0096194,4.09038059 C70.7557786,3.8365398 70.3442214,3.8365398 70.0903806,4.09038059 C69.8365398,4.34422138 69.8365398,4.75577862 70.0903806,5.00961941 L71.5807612,6.5 L70.0903806,7.99038059 C69.8365398,8.24422138 69.8365398,8.65577862 70.0903806,8.90961941 C70.3442214,9.1634602 70.7557786,9.1634602 71.0096194,8.90961941 L72.5,7.41923882 L73.9903806,8.90961941 C74.2442214,9.1634602 74.6557786,9.1634602 74.9096194,8.90961941 C75.1634602,8.65577862 75.1634602,8.24422138 74.9096194,7.99038059 L73.4192388,6.5 Z M72.5,13 C68.9101491,13 66,10.0898509 66,6.5 C66,2.91014913 68.9101491,0 72.5,0 C76.0898509,0 79,2.91014913 79,6.5 C79,10.0898509 76.0898509,13 72.5,13 Z"
                                        id="Mask"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
