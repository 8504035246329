export const EVENTS = {
    ARCHIVE: 'ARCHIVE',
    UNARCHIVE: 'UNARCHIVE',
    FAVOURITE: 'FAVOURITE',
    REJECT: 'REJECT',
    UNREJECT: 'UNREJECT',
    SELECT: 'SELECT',
    ALL_SELECT: 'ALL_SELECT',
    ALL_FAVOURITE: 'ALL_FAVOURITE',
    MOVE_JOB_OPENING: 'MOVE_JOB_OPENING',
    CHANGE_JOB_OPENING: 'CHANGE_JOB_OPENING',
    UPDATE_TAGS: 'UPDATE_TAGS',
    DELETE_CANDIDATE: 'DELETE_CANDIDATE',
}
export const MODAL_TYPE = {
    MOVE_JOB: 'MOVE_JOB',
    CHANGE_JOB: 'CHANGE_JOB',
    CHANGE_STAGE: 'CHANGE_STAGE',
}

export const WARNING_MESSAGE = {
    REJECT: data => {
        // using keys instead of values because it is cheaper in terms of memory
        let keys = Object.keys(data)
        if (keys.length === 1) return `Are you sure you want to reject ${data[keys[0]].name}?`
        return `Are you sure you want to reject ${keys.length} candidates?`
    },
    ARCHIVE: data => {
        // using keys instead of values because it is cheaper in terms of memory
        let keys = Array.isArray(data) ? data : Object.keys(data)
        if (keys.length === 1) return `Are you sure you want to archive ${data[keys[0]].name}?`
        return `Are you sure you want to archive ${keys.length} candidates?`
    },
}
