import styled from 'styled-components'

export const DoSelectEditPass = styled.div`
    span {
        font-size: 12px;
        color: #4767a0;
        font-weight: normal;
        cursor: pointer;
        font-family: 'SFProDisplay-Light';
        letter-spacing: 0.6px;
        img {
            margin-right: 5px;
        }
    }
`
export const DoselectContainer = styled.div`
    .details {
        display: flex;
        margin-bottom: 30px;
        .label {
            span {
                display: block;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.25;
                color: #999999;
                margin-top: 10px;
                margin-bottom: 30px;
                &:last-child {
                    margin-top: 40px;
                    margin-bottom: 0;
                }
            }
        }
        .values {
            margin-left: 30px;
        }
        .detailsInputs {
            input {
                display: flex;
                flex-direction: column;
                width: 250px;
                border: solid 1px #e1e4e8;
                margin-left: 85px;
                margin-bottom: 20px;
                &::placeholder {
                    color: #999999;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
`
export const DoSelectWebHook = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
`
export const WebHookContent = styled.div`
    h5 {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        color: #333333;
        margin-bottom: 7px;
    }
    a {
        font-size: 16px;
        color: #4767a0;
        line-height: 1.25;
        word-break: break-all;
    }
    .webHookCopyIcon {
        cursor: pointer;
    }
`
export const DoSelectMessage = styled.div`
    color: #333333;
    font-family: sanFranciscoMedium;
`
