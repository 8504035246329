import React, { Component } from 'react'
import { Button, Icon, Input, Popover, Avatar, Tooltip, Menu } from 'antd'
import { ReactComponent as PlusIcon } from '../../../../assets/Icons/Dashboard/plus.svg'
import { ReactComponent as ThreeDots } from '../../../../assets/Icons/Dashboard/threeDots.svg'
import { ReactComponent as Briefcase } from '../../../../assets/Icons/Dashboard/briefcase.svg'
import { ReactComponent as CompanyIcon } from '../../../../assets/Icons/Dashboard/Company.svg'
import { ReactComponent as Location } from '../../../../assets/Icons/Dashboard/location.svg'
import getAvatar from '../../../../services/getAvatar'
import { AddCoordinatorToJob, AddTeamMember } from '../../../../services/api'
import styles from '../style'
import { success, warning } from '../../../../services/Notification'
import { FSModal, Modal } from '../../../Modals'
import { FSMODAL_TYPES, MODAL_TYPES } from '../../../Helpers/ModalTypes'
import { connect } from 'react-redux'

const { Search } = Input

class JobList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            coordinatorText: null,
            coordinators: [],
            popoverVisible: false,
            data: props.data,
            accessLevel: this.props.accessLevel,
            showTeamMemberModal: false,
            selectedJob: null,
            jobPopoverVisible: false,
            showArchiveWarning: false,
            totalCandidates: 0,
            callInProgress: false,
        }
    }

    componentDidMount() {
        const { data } = this.props
        const coordinators = this.props.teamMembers.filter(
            coordinator => data.coordinators.findIndex(x => x._id === coordinator._id) === -1
        )
        this.setState({ coordinators })
    }

    handleCancel = () => {
        this.setState({
            showTeamMemberModal: false,
            selectedJob: null,
            showArchiveWarning: false,
        })
    }

    handleCoordinatorPopover = popoverVisible => {
        this.setState({ popoverVisible })
    }

    isCoordinatorValid = () => {
        const valid = this.state.coordinatorText
            ? this.state.coordinators.filter(x =>
                  x.email.toLowerCase().includes(this.state.coordinatorText.toLowerCase())
              ).length
            : true

        const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isEmail = emailRegExp.test(this.state.coordinatorText)
        return { valid, isEmail }
    }

    addCoordinator = async (coordinator1, jobId) => {
        try {
            let { coordinators, data } = this.state
            const input = {
                companyId: this.props.companyId,
                jobId,
                userId: coordinator1._id,
            }
            await AddCoordinatorToJob(input)
            data.coordinators.push(coordinator1)
            coordinators = coordinators.filter(x => x._id !== coordinator1._id)
            this.setState({
                data,
                coordinators,
                showTeamMemberModal: false,
                selectedJob: null,
                popoverVisible: false,
                coordinatorText: null,
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    archiveJob = job => {
        this.setState({ jobPopoverVisible: false, showArchiveWarning: false }, this.props.jobRemover(job))
    }

    showArchiveWarning = job => {
        if (job.totalCandidates > 0)
            this.setState({
                showArchiveWarning: true,
                totalCandidates: job.totalCandidates,
            })
        else {
            this.archiveJob(job)
        }
    }

    copyToClipboard = str => {
        const el = document.createElement('textarea')
        el.value = str
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)

        const message = `The unique email id “${str}” to apply for the job has been copied to clipboard.`
        success(message)
    }

    addTeamMember = jobId => {
        this.setState({ showTeamMemberModal: true, selectedJob: jobId, popoverVisible: false })
    }

    gotToDetailView = (view, id) => {
        this.props.history.push(`openings/${view}/${id}`)
    }

    addTeamMemberAsCoordinator = ({ email, accessibleJobs, accessLevel }) => {
        try {
            this.setState({ callInProgress: true }, async () => {
                let details = {
                    email,
                    accessibleJobs,
                    accessLevel,
                    userId: this.props.userId,
                    companyId: this.props.companyId,
                }
                const response = await AddTeamMember(details)
                this.addCoordinator(response.user, this.state.selectedJob)

                this.setState({
                    showTeamMemberModal: false,
                    callInProgress: false,
                    selectedJob: null,
                })
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    render() {
        const { data, accessLevel } = this.state

        return (
            <div style={styles.jobContainer} className="job-ctr">
                <div style={styles.joblistDetailContainer} className="joblistDetailContainer">
                    <div style={styles.joblistTitleContainer} className="joblistTitleContainer">
                        <div
                            style={styles.joblistTitle}
                            onClick={() => this.props.history.push(`/openings/board/${data._id}`)}
                        >
                            {data.jobTitle}
                        </div>
                        <div className="candidate-count">{data.totalCandidates} Candidate(s) </div>
                        {data.status && (
                            <div
                                style={styles.joblistCandidates}
                                onClick={() => this.props.history.push(`/openings/list/${data._id}`)}
                            >
                                {data.newCandidateCount > 0 ? (
                                    <div style={{ fontWeight: 'bold' }}> ({data.newCandidateCount} New)</div>
                                ) : null}
                            </div>
                        )}
                    </div>
                    <div className="duration-location">
                        <div className="duration">
                            {data.minExperience !== null && data.maxExperience !== null ? (
                                data.minExperience === 0 && data.maxExperience === 0 ? (
                                    <span>
                                        <Icon component={Briefcase} />
                                        Fresher
                                    </span>
                                ) : (
                                    <span>
                                        <Icon component={Briefcase} />
                                        {data.minExperience} - {data.maxExperience} Years
                                    </span>
                                )
                            ) : null}
                        </div>
                        <div className="location">
                            <span>
                                <p>
                                    <Icon component={Location} />
                                    {data.jobLocation}
                                </p>
                            </span>
                        </div>
                        {data.agencyStatus === 1 && (
                            <div className="location">
                                <span>
                                    <p>
                                        <Icon component={CompanyIcon} />
                                        {data.companyName}
                                    </p>
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div style={styles.joblistActionContainer}>
                    {accessLevel !== 4 &&
                        data.coordinators.map(c => (
                            <Tooltip
                                key={c._id}
                                placement="top"
                                title={c.email}
                                className="dashboard-coordinator-tooltip"
                            >
                                {/* <Avatar shape="square" className="coordinator-avatar1">{getAvatar(c.email)}</Avatar> */}
                                <div className="coordinator">{getAvatar(c.email)}</div>
                            </Tooltip>
                        ))}
                    <Popover
                        placement="bottomLeft"
                        content={
                            <div className="coordinator-popover">
                                <Search
                                    placeholder="Search name or email"
                                    onChange={e =>
                                        this.setState({
                                            coordinatorText: e.target.value,
                                        })
                                    }
                                    className="coordinator-popover-search"
                                    value={this.state.coordinatorText}
                                    autoFocus
                                />

                                {this.isCoordinatorValid().valid && this.state.coordinatorText ? (
                                    <div className="coordinator-list-result">
                                        {this.state.coordinators
                                            .filter(
                                                x =>
                                                    x.status === 1 &&
                                                    x.accessLevel !== 5 &&
                                                    (x.email
                                                        .toLowerCase()
                                                        .includes(this.state.coordinatorText.toLowerCase()) ||
                                                        (x.fullName &&
                                                            x.fullName
                                                                .toLowerCase()
                                                                .includes(this.state.coordinatorText.toLowerCase())))
                                            )
                                            .map((c, ind) => (
                                                <div key={ind} style={{ display: 'flex' }}>
                                                    <Avatar shape="square" className="coordinator-list-result-avatar">
                                                        {getAvatar(c.fullName || c.email)}
                                                    </Avatar>
                                                    <div className="coordinator-result-name">
                                                        {c.fullName || c.email}
                                                    </div>
                                                    {!data.coordinators.find(cor => cor._id === c._id) ? (
                                                        <Button
                                                            ghost
                                                            size="large"
                                                            type="primary"
                                                            className="coordinator-result-btn"
                                                            onClick={() => this.addCoordinator(c, data._id)}
                                                        >
                                                            Add
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            ))}
                                    </div>
                                ) : null}
                                {!this.isCoordinatorValid().valid ? (
                                    <div>
                                        <small style={{ color: '#bfbfbf' }}>No result found</small>
                                    </div>
                                ) : null}
                                {!this.isCoordinatorValid().valid && this.isCoordinatorValid().isEmail ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <div style={{ margin: 'auto 0' }}>{this.state.coordinatorText}</div>
                                        <Button
                                            ghost
                                            size="large"
                                            type="primary"
                                            style={{
                                                border: 'none',
                                                boxShadow: 'none',
                                                marginLeft: 'auto',
                                            }}
                                            onClick={() => this.addTeamMember(data._id)}
                                        >
                                            Invite
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                        }
                        trigger="click"
                        visible={this.state.popoverVisible}
                        onVisibleChange={this.handleCoordinatorPopover}
                        getPopupContainer={() => document.getElementById('addCoordinatorButton')}
                    >
                        {accessLevel < 3 ? (
                            <Button shape="circle" className="plus-button" id="addCoordinatorButton">
                                <div className="plus coordinator">
                                    <Icon component={PlusIcon} />
                                </div>
                            </Button>
                        ) : null}
                    </Popover>

                    {this.state.showTeamMemberModal && (
                        <FSModal
                            type={FSMODAL_TYPES.INVITE_TEAM_MEMBER}
                            visible={this.state.showTeamMemberModal}
                            onConfirm={this.addTeamMemberAsCoordinator}
                            onCancel={this.handleCancel}
                            selectedUser={{ email: this.state.coordinatorText }}
                            callInProgress={this.state.callInProgress}
                        />
                    )}
                </div>

                <Popover
                    placement="bottomRight"
                    arrowPointAtCenter
                    visible={this.state.jobPopoverVisible}
                    onVisibleChange={jobPopoverVisible => this.setState({ jobPopoverVisible })}
                    getPopupContainer={() => document.getElementById('moreButton')}
                    content={
                        <Menu className="card-option-cont">
                            <Menu.Item
                                className="card-options"
                                onClick={() => this.props.history.push(`/openings/board/${data._id}`)}
                            >
                                <span className="card-option-color">View board</span>
                            </Menu.Item>
                            {accessLevel < 3 && (
                                <Menu.Item
                                    className="card-options"
                                    onClick={() => this.props.history.push(`/openings/edit/${data._id}`)}
                                >
                                    <span className="card-option-color">Edit opening</span>
                                </Menu.Item>
                            )}
                            {accessLevel < 3 && (
                                <Menu.Item className="card-options" onClick={() => this.showArchiveWarning(data)}>
                                    <span className="card-option-color">Archive opening</span>
                                </Menu.Item>
                            )}
                        </Menu>
                    }
                >
                    <Button
                        id="moreButton"
                        style={{
                            border: 'none',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                        }}
                    >
                        <Icon component={ThreeDots} className="three-dots" />
                    </Button>
                </Popover>

                <Modal
                    type={MODAL_TYPES.CONFIRM_ARCHIVE_OPENING_ALERT}
                    visible={this.state.showArchiveWarning}
                    onCancel={this.handleCancel}
                    redirect={() => this.gotToDetailView('board', data._id)}
                    onConfirm={sendEmailToCandidate => this.archiveJob(this.state.data, sendEmailToCandidate)}
                    activeCandidates={this.state.totalCandidates}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ Auth: { user }, Mixed: { teamMembers } }) => {
    return {
        accessLevel: user.accessLevel,
        userId: user.id,
        companyId: user.companyId,
        teamMembers,
    }
}

export default connect(mapStateToProps)(JobList)
