import React, { useState, useEffect } from 'react'
import { Header, Footer } from '../common'
import { Input, Tooltip, AutoComplete, Select } from 'antd'
import { Body, GreyTags } from './style'
import { ReactComponent as CloseIcon } from '../../../assets/Icons/Access/close.svg'
import { ReactComponent as InfoIcon } from '../../../assets/Icons/Interview/info.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/Icons/AccountSettingPage/Error_Icon.svg'
import { ReactComponent as TagIcon } from '../../../assets/Icons/Talent Pool/tag.svg'
import { StyledButton } from '../../Components'
import { GetTags } from '../../../services/NetworkCalls/Sourcing'

// To ensure that a tag is added only once to the array even if it is present for multiple candidates
const getUniqueTags = candidates => {
    const tagArray = [],
        tagNameArray = []
    candidates.forEach(each => {
        each.tags &&
            each.tags.forEach(tag => {
                if (!tagNameArray.includes(tag.name)) {
                    tagArray.push(tag)
                    tagNameArray.push(tag.name)
                }
            })
    })
    return { tagArray, tagNameArray, hasTags: tagArray.length }
}

const AddTag = props => {
    const [selectedCandidates, setSelectedCandidates] = useState(props.selectedCandidates)
    const [oldTags, setOldTags] = useState([])
    const [oldTagNames, setOldTagNames] = useState([])
    const [newTags, setNewTags] = useState([])
    const [newTagNames, setNewTagNames] = useState([])
    const [deletedTags, setDeletedTags] = useState([])
    const [tagValue, setTagValue] = useState('')
    const [tagDropList, setTagDropList] = useState([])
    const [tagResultList, setTagResultList] = useState([])
    const [overTagCandidates, setOverTagCandidates] = useState([])
    const [errors, setErrors] = useState(null)
    const [showMore, setShowMore] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [hasTags, setHasTags] = useState(false)
    const bulkMode = selectedCandidates.length > 1

    useEffect(() => {
        const { tagArray, tagNameArray, hasTags } = getUniqueTags(selectedCandidates)
        setOldTags(tagArray)
        setOldTagNames(tagNameArray)
        setHasTags(hasTags)
    }, [])

    const callTagsAPI = async search => {
        if (search.trim().length > 25) return
        setTagValue(search)
        if (search.length) {
            const result = await GetTags(search.trim())
            const tagDataList = (result && result.results) || []
            const newList = (tagDataList.length &&
                tagDataList.map(each => (
                    <Select.Option key={each.tagId} label={each.tagId}>
                        {each.name}
                    </Select.Option>
                ))) || [<Select.Option key="there-is-another">{search}</Select.Option>]
            setTagDropList(newList)
            setTagResultList(tagDataList)
            setErrors(null)
        }
    }

    const filterTag = tag => {
        const exceededTagCandidates = []

        if (oldTagNames.includes(tag.name)) {
            const updatedCandidates = selectedCandidates.map(each => {
                const tags = each.tags.filter(oldTag => oldTag.name !== tag.name)
                if (tags.length + newTags.length >= 15) exceededTagCandidates.push(each.name)
                return { ...each, tags }
            })
            const remainingTags = oldTags.filter(each => each.name !== tag.name)
            oldTagNames.splice(oldTagNames.indexOf(tag.name), 1)
            setSelectedCandidates(updatedCandidates)
            setOldTags(remainingTags)
            setDeletedTags([...deletedTags, tag])
            setOverTagCandidates(exceededTagCandidates)
        } else {
            const remainingTags = newTags.filter(each => each.name !== tag.name)
            newTagNames.splice(newTagNames.indexOf(tag.name), 1)
            selectedCandidates.forEach(each => {
                const existingTags = each.tags || []
                if (existingTags.length + remainingTags.length >= 15) exceededTagCandidates.push(each.name)
            })
            setNewTags(remainingTags)
            setOverTagCandidates(exceededTagCandidates)
        }
    }

    const tagSelect = value => {
        const newData = tagResultList.find(each => each.tagId === value) || { name: tagValue.trim() }
        if (!newData.name.length) return
        if (!bulkMode && oldTags.length + newTags.length === 15) {
            setErrors({ maxTag: 'Maximum tag limit reached' })
            return
        }
        if ([...oldTagNames, ...newTagNames].includes(newData.name)) {
            setErrors({ tagExist: 'Tag already exists' })
            return
        }
        if (bulkMode) {
            const exceededTagCandidates = []
            selectedCandidates.forEach(each => {
                const existingTags = each.tags || []
                if (existingTags.length + newTags.length + 1 >= 15) exceededTagCandidates.push(each.name)
            })
            setOverTagCandidates(exceededTagCandidates)
        }
        setNewTags([...newTags, newData])
        setNewTagNames([...newTagNames, newData.name])
        setTagValue('')
        setTagDropList([])
        setErrors(null)
    }

    const submitTags = () => {
        const data = {
            deletedTags,
            addedTags: newTags,
            uCandidateList: selectedCandidates.map(each => each._id || each.candidateId || each.objectID),
        }
        props.updateTags(data)
    }

    return (
        <>
            <Header title="ADD TAG" />
            {confirm ? (
                <Body>
                    <div className="confirm">
                        All tags will be removed from selected profile, do you want to proceed?{' '}
                    </div>
                </Body>
            ) : (
                <Body>
                    <AutoComplete
                        value={tagValue}
                        dataSource={tagDropList}
                        onSelect={value => tagSelect(value)}
                        onSearch={text => callTagsAPI(text)}
                        placeholder="Enter tag here"
                    />
                    {errors ? (
                        <div className="error">
                            <ErrorIcon />
                            <p>{errors.maxTag || errors.tagExist}</p>
                        </div>
                    ) : (
                        <div className="help">
                            <InfoIcon /> You can add maximum 15 tags
                        </div>
                    )}
                    <GreyTags>
                        {showMore
                            ? [...oldTags, ...newTags].map((tag, index) => (
                                  <span key={index}>
                                      {tag.name} <CloseIcon onClick={() => filterTag(tag)} />
                                  </span>
                              ))
                            : [...oldTags, ...newTags].slice(0, 7).map((tag, index) => (
                                  <span key={index}>
                                      {tag.name} <CloseIcon onClick={() => filterTag(tag)} />
                                  </span>
                              ))}
                        {oldTags.length + newTags.length > 7 ? (
                            showMore ? (
                                <span className="show">
                                    <a onClick={() => setShowMore(false)}>Show less</a>
                                </span>
                            ) : (
                                <StyledButton
                                    capitalize
                                    type="inverted"
                                    width="80px"
                                    height="30px"
                                    onClick={() => setShowMore(true)}
                                >
                                    +{oldTags.length + newTags.length - 7} more
                                </StyledButton>
                            )
                        ) : null}
                    </GreyTags>
                    {bulkMode && overTagCandidates.length ? (
                        <div className="bulk-warning">
                            <TagIcon className="tag-icon" />
                            <p className="warning">
                                <Tooltip
                                    overlayClassName="add-tag-tooltip"
                                    title={
                                        <>
                                            {overTagCandidates.map(each => (
                                                <div>{each}</div>
                                            ))}
                                        </>
                                    }
                                >
                                    <a>
                                        {overTagCandidates.length === 1
                                            ? `${overTagCandidates[0]}`
                                            : `${overTagCandidates.length}/${selectedCandidates.length} candidate profile`}
                                    </a>{' '}
                                </Tooltip>
                                has reached maximum tag limit. No new tag can be added to this profile any further.
                                Manage it separately to remove extra tag
                            </p>
                        </div>
                    ) : null}
                </Body>
            )}
            <Footer
                hasSeparator
                onCancel={() => (confirm ? setConfirm(false) : props.onCancel())}
                onConfirm={() => {
                    if (confirm) submitTags()
                    else if (hasTags && ![...oldTags, ...newTags].length) setConfirm(true)
                    else submitTags()
                }}
                disable={
                    (!hasTags && ![...oldTags, ...newTags].length) ||
                    !!errors ||
                    (bulkMode && overTagCandidates.length === selectedCandidates.length)
                }
                disableCancel={false}
                cancelText={confirm ? 'NO' : 'CANCEL'}
                confirmText={confirm ? 'YES' : 'SAVE'}
            />
        </>
    )
}

export default AddTag
