import React, { Component } from 'react'
import { connect } from 'react-redux'
import qs from 'querystring'
import Profile from './Components/Profile'
import Sidebar from './Components/Sidebar'
import Tags from './Components/Tags'
import { Header, HeaderTitle, Main } from './style'
import Notes from './Components/Notes'
import Skills from './Components/Skills'
import Resume from './Components/Resume'
import Messages from './Components/Messages'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import { Modal } from '../../Modals'
import { GetUniversalCandidateDetails } from '../../../services/NetworkCalls/SourcingCandidate'
import {
    UpdateUniversalCandidate,
    GetEmails,
    UpdateSourcingTags,
} from '../../../services/NetworkCalls/SourcingTalentPool'

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            candidateDetails: {},
            companyOptions: {},
            emails: [],
            isLoading: true,
            selectedTab: 1,
            showAddTag: false,
            showManageTag: false,
        }
    }

    async componentDidMount() {
        const { pathname } = this.props.location
        const pathArray = pathname.split('/')
        const universalCandidateId = pathArray[pathArray.length - 1]

        const details = await this.props.GetUniversalCandidateDetails(universalCandidateId)
        this.setState({
            candidateDetails: details.universalCandidate,
            companyOptions: details.companyOptions || {},
            emails: (details.sentEmails && details.sentEmails.emails) || [],
            isLoading: false,
        })
    }

    updateUniversalCandidate = data => {
        this.props.UpdateUniversalCandidate(data, false)
    }

    showTagModal = type => {
        if (type === 'addTag') this.setState({ showAddTag: true })
        else this.setState({ showManageTag: true })
    }

    updateTags = async data => {
        const res = await this.props.UpdateSourcingTags(data, false)
        let { companyOptions } = this.state
        const deletedTags = data.deletedTags.map(tag => tag.tagId)
        companyOptions.tags = companyOptions.tags.filter(tag => !deletedTags.includes(tag.tagId))
        companyOptions.tags = [...companyOptions.tags, ...res.result]
        this.setState({ companyOptions })
    }

    render() {
        const {
            candidateDetails,
            companyOptions,
            emails,
            isLoading,
            selectedTab,
            showAddTag,
            showManageTag,
        } = this.state
        return (
            <>
                <Header>
                    <HeaderTitle>Candidate Profile</HeaderTitle>
                    <div className="tabs">
                        <div
                            className={`tab ${selectedTab === 1 && 'active'}`}
                            onClick={() => this.setState({ selectedTab: 1 })}
                        >
                            Profile
                        </div>
                        <div
                            className={`tab ${selectedTab === 2 && 'active'}`}
                            onClick={() => this.setState({ selectedTab: 2 })}
                        >
                            Messages
                        </div>
                    </div>
                </Header>
                <Main>
                    <div>
                        <Sidebar interestedJobs={candidateDetails.interested_jobs} isLoading={isLoading} />
                        <Tags tags={companyOptions.tags} showTagModal={this.showTagModal} />
                    </div>
                    <div className="cards">
                        {selectedTab === 1 ? (
                            <>
                                <Profile
                                    candidateDetails={candidateDetails}
                                    userOptions={companyOptions.userOptions}
                                    isLoading={isLoading}
                                    updateUniversalCandidate={this.updateUniversalCandidate}
                                />
                                <Notes
                                    candidate={candidateDetails}
                                    allNotes={companyOptions.notes}
                                    isLoading={isLoading}
                                />
                                <Skills keySkills={candidateDetails.key_skills} isLoading={isLoading} />
                                <Resume isLoading={isLoading} candidateDetails={candidateDetails} />
                            </>
                        ) : (
                            <Messages candidate={candidateDetails} allEmails={emails} />
                        )}
                    </div>
                </Main>

                <Modal
                    type={MODAL_TYPES.ADD_TAG}
                    visible={showAddTag}
                    selectedCandidates={[{ ...candidateDetails, tags: companyOptions.tags }]}
                    updateTags={data => {
                        this.setState({ showAddTag: false })
                        this.updateTags(data)
                    }}
                    onCancel={() => this.setState({ showAddTag: false })}
                />
                <Modal
                    type={MODAL_TYPES.MANAGE_TAG}
                    visible={showManageTag}
                    selectedCandidates={[{ ...candidateDetails, tags: companyOptions.tags }]}
                    updateTags={data => {
                        this.setState({ showManageTag: false })
                        this.updateTags(data)
                    }}
                    onCancel={() => this.setState({ showManageTag: false })}
                />
            </>
        )
    }
}

export default connect(
    null,
    {
        GetUniversalCandidateDetails,
        UpdateUniversalCandidate,
        GetEmails,
        UpdateSourcingTags,
    }
)(Index)
