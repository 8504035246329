import React from 'react'
import { Header } from './style'
import { ReactComponent as BackIcon } from '../../../../assets/Icons/Interview/back.svg'

const ContentResume = ({ candidateResumeLink }) => {
    const resumeLink = candidateResumeLink.includes('.doc')
        ? `https://view.officeapps.live.com/op/embed.aspx?src=${candidateResumeLink}`
        : candidateResumeLink
    return (
        <iframe
            title="resume"
            className="resume-preview"
            style={{ width: '100%', height: '80vh', marginBottom: '-6px', border: 'none' }}
            src={resumeLink}
        ></iframe>
    )
}

const Resume = props => {
    const { candidateResumeLink, onCancel } = props
    const openInNewTab = () =>
        window.open(
            candidateResumeLink.includes('.doc')
                ? `https://docs.google.com/viewerng/viewer?url=${candidateResumeLink}`
                : candidateResumeLink,
            '_blank'
        )

    return (
        <>
            <Header>
                <span className="title">
                    <BackIcon onClick={onCancel} /> Resume
                </span>
                <span className="open" onClick={openInNewTab}>
                    OPEN IN NEW TAB
                </span>
            </Header>
            <ContentResume {...props} />
        </>
    )
}

export default Resume
