import React, { useState, useEffect } from 'react'
import * as S from './style'
import { ReactComponent as Stat1Icon } from '../../../assets/Icons/Landing/Stats/1.svg'
import { ReactComponent as Stat2Icon } from '../../../assets/Icons/Landing/Stats/2.svg'
import { ReactComponent as Stat3Icon } from '../../../assets/Icons/Landing/Stats/3.svg'
import { ReactComponent as Stat4Icon } from '../../../assets/Icons/Landing/Stats/4.svg'

const Achievements = props => {
    const [variable, setVariable] = useState(true)
    return (
        <S.Achievements>
            <div className="center">
                <S.H2 underlined>SpringRecruit in Numbers</S.H2>
            </div>
            <S.Stats>
                <S.Stat>
                    <div>
                        <Stat1Icon />
                        <div>
                            <S.H2>390+</S.H2>
                            <p className="hide-sm">Active companies</p>
                        </div>
                    </div>
                    <p className="show-sm">Active companies</p>
                </S.Stat>
                <S.Stat>
                    <div>
                        <Stat2Icon />
                        <div>
                            <S.H2>1,650+</S.H2>
                            <p className="hide-sm">Job openings</p>
                        </div>
                    </div>
                    <p className="show-sm">Job openings</p>
                </S.Stat>
                <S.Stat>
                    <div>
                        <Stat3Icon />
                        <div>
                            <S.H2>61,000+</S.H2>
                            <p className="hide-sm">Candidates processed</p>
                        </div>
                    </div>
                    <p className="show-sm">Candidates processed</p>
                </S.Stat>
                <S.Stat>
                    <div>
                        <Stat4Icon />
                        <div>
                            <S.H2>200,000+</S.H2>
                            <p className="hide-sm">{'Excel sheets saved ;)'}</p>
                        </div>
                    </div>
                    <p className="show-sm">{'Excel sheets saved ;)'}</p>
                </S.Stat>
            </S.Stats>
        </S.Achievements>
    )
}

export default Achievements
