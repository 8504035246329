import React, { useState } from 'react'
import { Header, Footer } from '../common'
import { ContentWrapper, Divider } from '../style'
import { Select } from 'antd'
import './style.scss'
import { connect } from 'react-redux'
import { ReactComponent as ErrorIcon } from '../../../assets/Icons/Access/error.svg'

const AssignTest = ({ onCancel, onConfirm, tests, callInProgress }) => {
    const [form, setForm] = useState({ slug: '', expiryDate: null, startTime: null })
    const [error, setError] = useState(null)
    return (
        <>
            <Header title="ASSIGN TEST" />
            <ContentWrapper mTop={true}>
                <div className="assign-test">
                    <p className="assign-test__text">Select DoSelect Test*</p>
                    <div>
                        <Select
                            className="assign-test__select"
                            size="large"
                            placeholder="Select doselect test"
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={value => {
                                setForm({ ...form, slug: value })
                                setError(null)
                            }}
                        >
                            {tests.map(test => (
                                <Select.Option title={test.name} key={test.slug} value={test.slug}>
                                    {test.name}
                                </Select.Option>
                            ))}
                        </Select>
                        {error && (
                            <div className="assign-test__error">
                                <ErrorIcon className="assign-test__error--icon" />
                                <div>{error}</div>
                            </div>
                        )}
                    </div>
                    <div className="assign-test__message">
                        DoSelect test will be sent to the candidate and the candidate will be moved to next stage if he
                        passes the test or will be rejected and moved to the rejected list if he fails the test
                    </div>
                </div>
                <Divider />
            </ContentWrapper>
            <Footer
                onCancel={onCancel}
                onConfirm={() => {
                    if (!error && form.slug === '') setError('DoSelect test is required')
                    else if (!error) onConfirm(form)
                }}
                cancelText="CANCEL"
                confirmText="ADD"
                disable={callInProgress}
            />
        </>
    )
}

const mapStateToProps = ({ Mixed: { doSelectTests } }) => {
    return {
        tests: doSelectTests,
    }
}

export default connect(mapStateToProps)(AssignTest)
