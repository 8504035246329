import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { PAGE_SECTIONS } from '../../Helpers/Stylesheet/styleTypes'
import LeftPanel from './Components/LeftPanel'
import { LabelBox, Title } from '../../../container/Pages/Todo/style'
import JobDetails from './Components/JobDetails'
import ApplicationForm from './Components/ApplicationForm'
import Descriptions from './Components/Descriptions'
import { Body, Main } from './style'
import Promote from './Components/Promote'
import { GetJobDetails, CreateJob, UpdateJob } from '../../../services/NetworkCalls/Job'
import { JobApplicationForm } from '../../../services/api'
import apiCaller from '../../../services/apiCaller'
import { success, warning } from '../../../services/Notification'
import Config from '../../../config'

const { formatError } = apiCaller
const { isMobileDevice } = Config

class CreateNewOpening extends Component {
    constructor(props) {
        super(props)

        const page = props.location.state === 'promote' ? 4 : 1

        this.state = {
            page,
            maxAllowedPage: 1,
            jobDetails: {},
            jobApplication: {},
            jobPromotion: {},
            onEditMode: false,
            onCloneMode: false,
            customFeedbackData: {
                title: 'default',
                skills: [],
            },
        }

        document.title = 'Create job opening | SpringRecruit'
    }

    async componentDidMount() {
        try {
            const { location, match, allowSwitchTabs } = this.props
            allowSwitchTabs(true)
            let tab = ''
            if (location.pathname.indexOf('edit') >= 0 || location.pathname.indexOf('clone') >= 0) {
                const res = await this.props.GetJobDetails(match.params.jobId, this.props.companyId)
                this.setState({
                    page: location.state === 'promote' ? 4 : 1,
                    ..._.pick(res, ['jobDetails', 'jobApplication', 'jobPromotion']),
                    onEditMode: true,
                    onCloneMode: location.pathname.indexOf('clone') >= 0 ? true : false,
                    maxAllowedPage: 4,
                })
            } else {
                this.setState({
                    jobDetails: {
                        currency: 'INR',
                        minCompensation: 0,
                        maxCompensation: 0,
                        minEquity: 0,
                        maxEquity: 0,
                        jobType: 1,
                        compensationStatus: 1,
                        equityStatus: 1,
                        agencyStatus: 1,
                    },
                    page: 1,
                    maxAllowedPage: 1,
                })
            }
        } catch (err) {
            warning('Job not found. Continue to create new job.')
            this.props.history.push('/openings/createJob')
        }
    }

    updateJobDetails = async (data, option, customData, onCloneMode = false) => {
        if (option === 'back')
            this.setState({
                jobDetails: { ...this.state.jobDetails, ...data },
                customFeedbackData: customData,
                page: 1,
            })
        else if (option === 'next')
            this.setState({ jobDetails: { ...this.state.jobDetails, ...data }, page: 2, maxAllowedPage: 2 })
        else {
            try {
                const { jobDetails } = this.state
                const payload = {
                    agencyStatus: data.agencyStatus,
                    companyId: this.props.companyId,
                    companyName: data.companyName,
                    compensationStatus: data.compensationStatus,
                    currency: data.currency,
                    equityStatus: data.equityStatus,
                    jobBenefits: data.jobBenefits,
                    jobDescription: data.jobDescription,
                    jobLocation: data.jobLocation,
                    jobRequirements: data.jobRequirements,
                    jobTitle: data.jobTitle,
                    jobType: data.jobType,
                    maxCompensation: data.maxCompensation,
                    maxEquity: data.maxEquity,
                    maxExperience: data.maxExperience,
                    minCompensation: data.minCompensation,
                    minEquity: data.minEquity,
                    minExperience: data.minExperience,
                    feedbackForm: {
                        components: customData,
                    },
                }
                if (onCloneMode) {
                    if (this.props.match.params.jobId === jobDetails._id) {
                        const res = await this.props.CreateJob(payload)
                        this.setState({
                            jobApplication: res.appForm,
                            jobDetails: res.jobDetail,
                            jobPromotion: res.appPromote,
                            page: 3,
                            maxAllowedPage: 3,
                        })
                    } else {
                        await this.props.UpdateJob(payload, jobDetails._id)
                        this.setState({ jobDetails: { ...this.state.jobDetails, ...data }, page: 3 })
                    }
                } else {
                    if (jobDetails._id) {
                        await this.props.UpdateJob(payload, jobDetails._id)
                        this.setState({ jobDetails: { ...this.state.jobDetails, ...data }, page: 3 })
                    } else {
                        const res = await this.props.CreateJob(payload)
                        this.setState({
                            jobApplication: res.appForm,
                            jobDetails: res.jobDetail,
                            jobPromotion: res.appPromote,
                            page: 3,
                            maxAllowedPage: 3,
                        })
                    }
                }
            } catch (error) {
                warning(formatError(error))
            }
        }
    }

    updateApplicationForm = async (data, option) => {
        if (option === 'back') this.setState({ jobApplication: { ...this.state.jobApplication, ...data }, page: 2 })
        else {
            try {
                const { jobDetails } = this.state
                data.jobId = jobDetails._id
                data.companyId = this.props.companyId
                await JobApplicationForm(data, jobDetails._id)
                success('Application form saved successfully')
                this.setState({ jobApplication: { ...this.state.jobApplication, ...data }, page: 4, maxAllowedPage: 4 })
            } catch (error) {
                warning(formatError(error))
            }
        }
    }

    render() {
        const { history, customFeedbackJobs } = this.props
        const {
            page,
            maxAllowedPage,
            jobApplication,
            jobDetails,
            jobPromotion,
            onEditMode,
            onCloneMode,
            customFeedbackData,
        } = this.state

        return (
            <>
                <LabelBox>
                    <Title>Create Job Opening</Title>
                </LabelBox>
                <Body isMobileDevice={isMobileDevice}>
                    <LeftPanel
                        page={page}
                        maxAllowedPage={maxAllowedPage}
                        setPage={page => this.setState({ page })}
                        onEditMode={onEditMode}
                        onCloneMode={onCloneMode}
                        isMobileDevice={isMobileDevice}
                    />
                    <Main>
                        {page === 1 && (
                            <JobDetails
                                jobDetails={jobDetails}
                                updateJobDetails={data =>
                                    this.setState({
                                        jobDetails: { ...this.state.jobDetails, ...data },
                                        page: 2,
                                        maxAllowedPage: 2,
                                    })
                                }
                            />
                        )}
                        {page === 2 && (
                            <Descriptions
                                jobDetails={jobDetails}
                                updateJobDetails={this.updateJobDetails}
                                onEditMode={onEditMode}
                                onCloneMode={onCloneMode}
                                customFeedbackData={customFeedbackData}
                            />
                        )}
                        {page === 3 && (
                            <ApplicationForm
                                jobApplication={jobApplication}
                                updateApplicationForm={this.updateApplicationForm}
                            />
                        )}
                        {page === 4 && (
                            <Promote
                                jobDetails={jobDetails}
                                jobPromotion={jobPromotion}
                                history={history}
                                user={this.props.user}
                            />
                        )}
                    </Main>
                </Body>
            </>
        )
    }
}

const mapStateToProps = ({ Auth }) => {
    return {
        user: Auth.user,
        companyId: Auth.user.companyId,
    }
}

export default connect(
    mapStateToProps,
    {
        GetJobDetails,
        CreateJob,
        UpdateJob,
    }
)(CreateNewOpening)
