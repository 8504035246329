import React, { Component } from 'react'
import { connect } from 'react-redux'
import ComponentContainer from '../ComponentContainer'
import { SourcingLoader, ButtonLoader } from '../Loaders'
import { Modal } from '../../../../Modals'
import { MODAL_TYPES } from '../../../../Helpers/ModalTypes'
import { SourcingSubscription } from '../../../../../services/NetworkCalls/Sourcing'
import './style.scss'

class Sourcing extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sourcingModalVisible: false,
            unsubscribeModalVisible: false,
            disable: false,
        }
    }

    showSourcingModal = () => {
        this.setState({ sourcingModalVisible: true })
    }

    showUnsubscribeModal = () => {
        this.setState({ unsubscribeModalVisible: true })
    }

    handleCancel = () => {
        this.setState({ sourcingModalVisible: false, unsubscribeModalVisible: false })
    }

    updateSubscription = data => {
        const {
            sourcing_settings: { enabledFor, jobIds },
        } = data
        this.props.updateSubscription(enabledFor, jobIds, true)
    }

    getCandidateSubscriptionType = (archived, rejected) => {
        if (archived && rejected) return 'Both archived and rejected'
        else if (archived) return 'Only archived candidate'
        else if (rejected) return 'Only rejected candidate'
    }

    unsubscribeSourcing = () => {
        const data = {
            settings: {
                enabledFor: {
                    archived: false,
                    rejected: false,
                },
                jobIds: [],
                status: 'disabled',
            },
        }

        this.setState({ disable: true }, async () => {
            this.handleCancel()
            const res = await this.props.SourcingSubscription(data)
            if (res) {
                const {
                    settings: { enabledFor, jobIds },
                } = data
                this.props.updateSubscription(enabledFor, jobIds, false)
                this.setState({ disable: false })
            }
        })
    }

    render() {
        const { sourcingModalVisible, unsubscribeModalVisible, disable } = this.state
        const { enabledFor = {}, jobIds = [], hasSubscribed, allJobs, isLoading } = this.props
        const { archived, rejected } = enabledFor
        const jobOpenings = allJobs.filter(job => jobIds.includes(job._id))

        return (
            <ComponentContainer heading="Candidate Sourcing Initiative">
                <div className="AccountSettingCandidateSourceInitative">
                    <img src={require('./images/sourcing.svg').default} />
                    <p>Get associated with fastest growing network of active candidates.</p>
                    {isLoading ? (
                        <SourcingLoader />
                    ) : (
                        <>
                            {hasSubscribed ? (
                                <>
                                    <div className="CandidateEditIcon">
                                        <span onClick={() => this.showSourcingModal()}>
                                            <img src={require('./images/edit.svg').default} />
                                            Edit
                                        </span>
                                    </div>
                                    <div className="CandidateSubscriptionType">
                                        <p className="CandidateTitle">Candidate subscription type:</p>
                                        <p className="CandidateType">
                                            {this.getCandidateSubscriptionType(archived, rejected)}
                                        </p>
                                    </div>
                                    <div className="OpeningSubscriptionType">
                                        <p className="OpeningTitle">Opening subscription type:</p>
                                        {jobIds.length === 0 ? (
                                            <p className="OpeningType">All Openings</p>
                                        ) : (
                                            <div className="SelectedOpenings">
                                                {jobOpenings.map(job => (
                                                    <div className="SingleOpening">
                                                        <span>{job.jobTitle}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : null}
                        </>
                    )}
                    {isLoading ? (
                        <ButtonLoader />
                    ) : (
                        <button
                            style={{ width: hasSubscribed ? '110px' : '80px', opacity: disable && '0.5' }}
                            disabled={disable}
                            onClick={hasSubscribed ? this.showUnsubscribeModal : this.showSourcingModal}
                        >
                            {hasSubscribed ? 'UNSUBSCRIBE' : 'SUBSCRIBE'}
                        </button>
                    )}
                </div>

                <Modal
                    type={MODAL_TYPES.CANDIDATE_SOURCING}
                    visible={sourcingModalVisible}
                    updateSubscription={this.updateSubscription}
                    hasSubscribed={hasSubscribed}
                    selectedOpeningIds={jobIds}
                    allJobs={allJobs}
                    candidateType={archived && rejected ? 'both' : archived ? 'archived' : 'rejected'}
                    openingType={jobIds.length === 0 ? 'all' : 'selected'}
                    onCancel={this.handleCancel}
                />

                <Modal
                    type={MODAL_TYPES.CONFIRM_SOURCING_UNSUBSCRIBE}
                    visible={unsubscribeModalVisible}
                    onConfirm={this.unsubscribeSourcing}
                    onCancel={this.handleCancel}
                />
            </ComponentContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        allJobs: state.Job.allJobs,
    }
}

export default connect(
    mapStateToProps,
    { SourcingSubscription }
)(Sourcing)
