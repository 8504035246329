import React, { useState } from 'react'
import { Icon, Select } from 'antd'
import { ComponentWrapper } from './style'
import { ReactComponent as DownIcon } from '../../../assets/Icons/Interview/down.svg'
import { connect } from 'react-redux'
import { Header, Footer } from '../common'
import { GetJobDetails } from '../../../services/NetworkCalls/Job'
const { Option } = Select

const SelectJob = props => {
    const { allJobs, changeHandler, onCancel, companyId, openAddCandidate } = props
    const onSubmit = async value => {
        if (value) {
            setLoading(true)
            const res = await props.GetJobDetails(value, companyId)
            openAddCandidate(res)
            setLoading(false)
        } else {
            openAddCandidate({})
        }
    }
    const [jobId, setJobId] = useState(undefined)
    const [loading, setLoading] = useState(false)
    return (
        <>
            <Header title="SELECT JOB OPENING" />
            <ComponentWrapper>
                <div className="title">Openings</div>
                <Select
                    size="large"
                    placeholder={'Select'}
                    getPopupContainer={trigger => trigger.parentNode}
                    suffixIcon={<Icon component={DownIcon} />}
                    value={jobId}
                    style={{ width: '200px' }}
                    onChange={value => {
                        setJobId(value)
                    }}
                >
                    <Option key="job-unselect-key" value={undefined}>
                        --Select---
                    </Option>
                    {allJobs.map(option => (
                        <Option value={option._id} key={option._id}>
                            {option.jobTitle}
                        </Option>
                    ))}
                </Select>
            </ComponentWrapper>
            <Footer
                disable={loading}
                loadingButton={loading}
                onCancel={() => onCancel()}
                onConfirm={() => onSubmit(jobId)}
                confirmText="SUBMIT"
                hasSeparator
            />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        allJobs: (state.Job.allJobs && state.Job.allJobs.filter(each => each.status)) || [],
    }
}

export default connect(
    mapStateToProps,
    {
        GetJobDetails,
    }
)(SelectJob)
