import { applyMiddleware, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import rootSaga from '../redux/sagas'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createEncryptor from 'redux-persist-transform-encrypt'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]
const encryptor = createEncryptor({
    secretKey: 'spring-recruit-persist-redux-state',
    onError: error => {
        console.log(error)
    },
})
const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    transforms: [encryptor],
    whitelist: ['Mixed', 'Auth', 'Job', 'GCal', 'Zoom', 'OCal'],
    blacklist: ['Todo', 'Candidate', 'Sourcing'],
}

const persistedReducer = persistReducer(persistConfig, reducers(history))

let store = null
let persistor = null
if (process.env.REACT_APP_PATH !== 'production') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)))
} else {
    store = createStore(persistedReducer, compose(applyMiddleware(...middlewares)))
}

persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
export { store, persistor, history }
