import Dropzone from 'react-dropzone'
import React from 'react'
import { ALLOWED_FILE_FORMATS } from '../../../Helpers/fileTypeValidation'
import { ReactComponent as ResumeIcon } from '../../../../assets/Icons/Landing/resume.svg'
import { ReactComponent as CancelIcon } from '../../../../assets/Icons/Todo/cancel.svg'

export const LandingPageDropZone = ({ onDrop, uploadText = 'Choose File', isDisabled = false, handleCancel = () => { } }) => {
    return (
        <Dropzone
            onDrop={onDrop}
            multiple={false}
            accept={ALLOWED_FILE_FORMATS.CANDIDATE.join(', ')}
            disabled={isDisabled}
        >
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="drop-area">
                    {isDisabled ? <CancelIcon className="cancel" onClick={handleCancel} /> : ''}
                    {isDisabled ? <ResumeIcon /> : ''}
                    <div>{uploadText}</div>
                    <input {...getInputProps()} />
                </div>
            )}
        </Dropzone>
    )
}
