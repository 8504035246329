import React from 'react'

export function good_active() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
                <path
                    fill="#4767A0"
                    d="M26 0C11.7 0 0 11.7 0 26s11.7 26 26 26 26-11.7 26-26S40.3 0 26 0z"
                    transform="translate(-692 -478) translate(327 124) translate(40 116) translate(76 237) translate(242 1) translate(7)"
                />
                <path
                    fill="#FFF"
                    d="M25.833 49.833c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24z"
                    transform="translate(-692 -478) translate(327 124) translate(40 116) translate(76 237) translate(242 1) translate(7)"
                />
                <g fill="#4767A0">
                    <path
                        d="M27.714 15.052c0-.885-.62-1.623-1.447-1.86-.03 0-.03-.058 0-.058a1.915 1.915 0 001.919-1.919 1.915 1.915 0 00-1.919-1.918c-1.062 0-2.597 0-3.895.03-.797.029-2.391.029-3.483 0-.59 0-1.004-.59-.826-1.122.56-1.682 1.564-4.781 1.74-5.755.444-2.361-3.216-3.07-3.954-1.24-.679 1.77-3.365 7.054-6.552 9.002-.384.236-.62.65-.62 1.092l-.03 10.772c0 .62 2.539 2.48 6.14 2.48H24.85a1.915 1.915 0 001.918-1.919c0-.885-.62-1.623-1.446-1.86-.03 0-.03-.058 0-.058A1.915 1.915 0 0027.24 18.8c0-.885-.62-1.623-1.446-1.859-.03 0-.03-.06 0-.06 1.063.06 1.919-.796 1.919-1.829zM5.667 9.946H1.564c-.797 0-1.416.65-1.416 1.417v10.861c0 .797.649 1.417 1.416 1.417h4.103c.797 0 1.416-.65 1.416-1.417V11.363c0-.768-.62-1.417-1.416-1.417zM3.63 21.604a1.09 1.09 0 01-1.092-1.092c0-.62.502-1.092 1.092-1.092.59 0 1.092.502 1.092 1.092 0 .59-.502 1.092-1.092 1.092z"
                        transform="translate(-692 -478) translate(327 124) translate(40 116) translate(76 237) translate(242 1) translate(7) translate(11.833 12.5)"
                    />
                </g>
            </g>
        </svg>
    )
}

export function must_hire_active() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
                <path
                    fill="#4767A0"
                    d="M26 0C11.7 0 0 11.7 0 26s11.7 26 26 26 26-11.7 26-26S40.3 0 26 0z"
                    transform="translate(-778 -477) translate(327 124) translate(40 116) translate(76 237) translate(328) translate(7)"
                />
                <path
                    fill="#FFF"
                    d="M25.833 49.833c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24z"
                    transform="translate(-778 -477) translate(327 124) translate(40 116) translate(76 237) translate(328) translate(7)"
                />
                <g fill="#4767A0">
                    <path
                        d="M13.33 7.989c-1.832-2.31-2.925-4.045-4.799-6.952-.948-1.472-2.34-1.377-2.888.286-.115.348-.093.688.134.935 1.944 2.104 5.189 4.312 7.552 5.73zM16.02 9.559c-1.005-2.88-1.304-4.305-1.572-6.156-.127-.882-.93-1.41-1.77-1.112l-.016.006c-.554.201-.94.81-.841 1.39.203 1.207 1.766 3.725 4.12 5.927.037.035.096-.007.08-.055zM18.513 12.487c-2.132-2.323-5.6-3.987-8.464-5.638-2.104-1.213-4.327-2.534-7.177-4.195a1.675 1.675 0 00-2.429.918c-.268.797-.33 1.612.18 2.022 2.313 1.866 5.372 3.11 9.41 4.572-7.045-.84-9.793 4.403-8.617 6.421.228.39 1.294.634 2.057.699.352.03 1.068-.114 1.052-.467-.06-1.424 1.01-3.325 4.372-3.325 2.477 0 3.548 1.736 4.158 4.104.64 2.485-1.445 4.095-3.92 4.095-1.963 0-4.034-1.177-4.32-2.769-.22-1.23-3.637-1.206-3.48.264.406 3.82 3.472 5.34 7.397 6.368 3.05.799 5.202.921 7.045 3.251.535.678 1.68.364 2.295-.243l5.18-5.055c.594-.587.67-1.505.214-2.203-1.821-2.783-2.547-6.2-4.953-8.819z"
                        transform="translate(-778 -477) translate(327 124) translate(40 116) translate(76 237) translate(328) translate(7) translate(14 11)"
                    />
                </g>
            </g>
        </svg>
    )
}

export function not_sure_active() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
                <path
                    fill="#4767A0"
                    d="M26 0C11.7 0 0 11.7 0 26s11.7 26 26 26 26-11.7 26-26S40.3 0 26 0z"
                    transform="translate(-605 -478) translate(327 124) translate(40 116) translate(76 237) translate(155 1) translate(7)"
                />
                <path
                    fill="#FFF"
                    d="M25.833 49.833c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24z"
                    transform="translate(-605 -478) translate(327 124) translate(40 116) translate(76 237) translate(155 1) translate(7)"
                />
                <g fill="#4767A0">
                    <path
                        d="M14.983.01C6.72.01.013 6.716.013 14.98c0 8.262 6.707 14.97 14.97 14.97 8.262 0 14.97-6.708 14.97-14.97 0-8.263-6.708-14.97-14.97-14.97zM10.32 8.046a2.335 2.335 0 012.328 2.329 2.335 2.335 0 01-2.328 2.328 2.335 2.335 0 01-2.329-2.328 2.335 2.335 0 012.329-2.329zm9.356 0a2.335 2.335 0 012.328 2.329 2.335 2.335 0 01-2.328 2.328 2.335 2.335 0 01-2.329-2.328 2.335 2.335 0 012.329-2.329zm-11.6 12.206h13.77a.877.877 0 110 1.754H8.075a.877.877 0 110-1.754z"
                        transform="translate(-605 -478) translate(327 124) translate(40 116) translate(76 237) translate(155 1) translate(7) translate(11 11)"
                    />
                </g>
            </g>
        </svg>
    )
}

export function not_fit_active() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
                <path
                    fill="#4767A0"
                    d="M26 0C11.7 0 0 11.7 0 26s11.7 26 26 26 26-11.7 26-26S40.3 0 26 0z"
                    transform="translate(-443 -478) translate(327 124) translate(40 116) translate(76 237) translate(0 1)"
                />
                <path
                    fill="#FFF"
                    d="M25.833 49.833c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24z"
                    transform="translate(-443 -478) translate(327 124) translate(40 116) translate(76 237) translate(0 1)"
                />
                <g fill="#4767A0">
                    <path
                        d="M15 3.4C8.6 3.4 3.4 8.6 3.4 15c0 2.8 1 5.5 2.833 7.6L22.567 6.267A11.425 11.425 0 0015 3.4zM23.733 7.4L7.4 23.733c2.1 1.834 4.8 2.834 7.6 2.834 6.4 0 11.6-5.2 11.6-11.6 0-2.767-1.033-5.434-2.867-7.567z"
                        transform="translate(-443 -478) translate(327 124) translate(40 116) translate(76 237) translate(0 1) translate(11 11)"
                    />
                    <path
                        d="M15 0C6.7 0 0 6.7 0 15s6.7 15 15 15 15-6.7 15-15S23.3 0 15 0zm0 28.2C7.733 28.2 1.8 22.267 1.8 15 1.8 7.733 7.733 1.8 15 1.8c7.267 0 13.2 5.933 13.2 13.2 0 7.267-5.933 13.2-13.2 13.2z"
                        transform="translate(-443 -478) translate(327 124) translate(40 116) translate(76 237) translate(0 1) translate(11 11)"
                    />
                </g>
            </g>
        </svg>
    )
}

export function not_good_active() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
                <path
                    fill="#4767A0"
                    d="M26 0C11.7 0 0 11.7 0 26s11.7 26 26 26 26-11.7 26-26S40.3 0 26 0z"
                    transform="translate(-522 -478) translate(327 124) translate(40 116) translate(76 237) translate(72 1) translate(7)"
                />
                <path
                    fill="#FFF"
                    d="M25.833 49.833c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24z"
                    transform="translate(-522 -478) translate(327 124) translate(40 116) translate(76 237) translate(72 1) translate(7)"
                />
                <g fill="#4767A0">
                    <path
                        d="M27.714 15.052c0-.885-.62-1.623-1.447-1.86-.03 0-.03-.058 0-.058a1.915 1.915 0 001.919-1.919 1.915 1.915 0 00-1.919-1.918c-1.062 0-2.597 0-3.895.03-.797.029-2.391.029-3.483 0-.59 0-1.004-.59-.826-1.122.56-1.682 1.564-4.781 1.74-5.755.444-2.361-3.216-3.07-3.954-1.24-.679 1.77-3.365 7.054-6.552 9.002-.384.236-.62.65-.62 1.092l-.03 10.772c0 .62 2.539 2.48 6.14 2.48H24.85a1.915 1.915 0 001.918-1.919c0-.885-.62-1.623-1.446-1.86-.03 0-.03-.058 0-.058A1.915 1.915 0 0027.24 18.8c0-.885-.62-1.623-1.446-1.859-.03 0-.03-.06 0-.06 1.063.06 1.919-.796 1.919-1.829zM5.667 9.946H1.564c-.797 0-1.416.65-1.416 1.417v10.861c0 .797.649 1.417 1.416 1.417h4.103c.797 0 1.416-.65 1.416-1.417V11.363c0-.768-.62-1.417-1.416-1.417zM3.63 21.604a1.09 1.09 0 01-1.092-1.092c0-.62.502-1.092 1.092-1.092.59 0 1.092.502 1.092 1.092 0 .59-.502 1.092-1.092 1.092z"
                        transform="translate(-522 -478) translate(327 124) translate(40 116) translate(76 237) translate(72 1) translate(7) rotate(180 20.083 19.646)"
                    />
                </g>
            </g>
        </svg>
    )
}

export function selected_active() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
                <path
                    fill="#4767A0"
                    d="M26 0C11.7 0 0 11.7 0 26s11.7 26 26 26 26-11.7 26-26S40.3 0 26 0z"
                    transform="translate(-605 -478) translate(327 124) translate(40 116) translate(76 237) translate(155 1) translate(7)"
                />
                <path
                    fill="#FFF"
                    d="M25.833 49.833c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24z"
                    transform="translate(-605 -478) translate(327 124) translate(40 116) translate(76 237) translate(155 1) translate(7)"
                />
                <g fill="#4767A0">
                    <path
                        d="M14.983.01C6.72.01.013 6.716.013 14.98c0 8.262 6.707 14.97 14.97 14.97 8.262 0 14.97-6.708 14.97-14.97 0-8.263-6.708-14.97-14.97-14.97zM10.32 8.046a2.335 2.335 0 012.328 2.329 2.335 2.335 0 01-2.328 2.328 2.335 2.335 0 01-2.329-2.328 2.335 2.335 0 012.329-2.329zm9.356 0a2.335 2.335 0 012.328 2.329 2.335 2.335 0 01-2.328 2.328 2.335 2.335 0 01-2.329-2.328 2.335 2.335 0 012.329-2.329zm-11.6 12.206h13.77a.877.877 0 110 1.754H8.075a.877.877 0 110-1.754z"
                        transform="translate(-605 -478) translate(327 124) translate(40 116) translate(76 237) translate(155 1) translate(7) translate(11 11)"
                    />
                </g>
            </g>
        </svg>
    )
}
