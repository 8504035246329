import React from 'react'
import { CollapsibleGroup } from '../style'
import { connect } from 'react-redux'
import BulkPreviewList from './BulkPreviewList'

const BulkPreview = ({ list, updateSuccessCount, jobDetails = {} }) => {
    return (
        <CollapsibleGroup>
            {list.map(each => (
                <BulkPreviewList
                    counting={list.length}
                    key={each}
                    uploadId={each}
                    updateSuccessCount={updateSuccessCount}
                    jobDetails={jobDetails}
                />
            ))}
        </CollapsibleGroup>
    )
}

const mapStateToProps = state => {
    const { list } = state.Candidate
    return { list }
}

export default connect(mapStateToProps)(BulkPreview)
