import React, { Component } from 'react'

export default class TermsAndConditions extends Component {
    render() {
        return (
            <iframe
                title="Terms And Conditions"
                key="terms-and-conditions-iframe-key"
                style={{ minWidth: '100%', minHeight: 'calc(100vh + 64px )', marginTop: -70 }}
                src={`https://www.springworks.in/tnc/`}
                allowFullScreen
            ></iframe>
        )
    }
}
