import { store } from '../redux/store'
import authActions from '../redux/auth/actions'
import jobActions from '../redux/jobs/actions'
import mixedActions from '../redux/mixed/actions'
import config from '../config'
import { getBootData } from '../redux/boot'
import { warning, success } from '../services/Notification'
import { LogE } from '../container/Helpers/errorHandler'
import _ from 'lodash'
import moment from 'moment'
import apiCaller from './apiCaller'
import ajaxCaller from './apiCaller'
const axios = ajaxCaller.wrappers.axios
const fetch = apiCaller.wrappers.fetch

const { baseURL: apiUrl, apiVersion, gMapsGeocodingAPIKey } = config.config()
const apiType = {
    restricted: 'me/',
    public: '',
}
const baseUrl = apiUrl + apiVersion.v1
const baseUrl_v2 = apiUrl + apiVersion.v2

const headers = {
    'Content-Type': 'application/json',
}

const getHeaders = (customToken = null) => {
    const token = customToken || store.getState().Auth.idToken
    if (token) {
        headers['Authorization'] = token
    }

    return headers
}

/*
  +++++++++++++++++++++
  +   TABLE OF APIS   +
  +++++++++++++++++++++

     CheckTokenStatus
     ForgotPasswordStep1
     ForgotPasswordStep2
     ForgotPasswordStep3
     NewTeamMemberSignup
     Signup
     signUpOTP
     Signin
     Signout
     CreateJobForCompany
     JobApplicationForm
     AddTeamMember
     UpdateTeamMember
     GetCountryCodeFromPosition
     GetTeamMember
     GetCompanyJobs
     GetCandidates
     GetCandidatesEmail
     GetResumeDetails
     GetCandidatesPhoneNumber
     GetCompanyJobByUniqueUrl
     AddCandidateWithJob
     GetATSBoardData
     GetATSBoardNextData
     GetATSBoardApplication
     GetJobLIstViewData
     GetCandidateDetail
     UpdateCandidateStage
     UpdateMultipeCandidateStage
     UpdateMultipleCandidatesStage
     AddNewStage
     PublicApplyNow
     EditCandidate
     GetDashboardData
     AddCoordinatorToJob
     GetCoordinatorByJobId
     PostInterview
     RejectCandidate
     UpdateFavouriteStatus
     ArchiveApplications
     AssignTeamMember
     UpdateJob
     GetJobById
     UpdateStagePosition
     DeleteStage
     GetAllInterviews
     PatchInterview
     SendInterviewReminder
     PatchInterviewStatus
     AddFeedback
     UpdateFeedback
     RequestFeedback
     SaveDoSelectCredentials
     GetCompany
     GetDoSelectTests
     GetUnberryPositions
     PostDoSelectTests
     ResendDoSelectTests
     SendMessage
     EmailAddTeamMember
     EmailRemoveTeamMember
     ChangeEmailPrivacy
     RemoveTeamMember
     GetAllJobsWithStage
     SaveAccountDetails
     ChangePasswordApi
     AddNotes
     PatchNotes
     DeleteNote
     SendMultipleDoSelectTest
     RejectMultipleCandidates
     AssignStage
     UpdateCandidateStatus
     UpdateCandidateSource
     DeleteCandiate,
     DeleteJobOpeing,

     --- Career Form ---
     CareerFormGet
     CareerFormPublish
     CareerFormSave

     --- Schedule Interview ---
     SetAddress
     GetAddress
     EditAddress
     DeleteAddress

     --- Schedule Interview ---
     SetAddress
     GetAddress
     EditAddress
     DeleteAddress

     --- Schedule Interview ---
     SetAddress
     GetAddress
     EditAddress
     DeleteAddress

   =============================
*/

export const CheckTokenStatus = async token => {
    try {
        const url = baseUrl + 'current'
        let { body } = await fetch(url, {
            method: 'GET',
            headers: {
                ...headers,
                Authorization: token,
            },
        })

        return body
    } catch (err) {
        throw err
    }
}

export const ForgotPasswordStep1 = async email => {
    try {
        const url = baseUrl + 'forgotpassword'
        let { body } = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify({ email }),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const ForgotPasswordStep2 = async (otp, email) => {
    try {
        const url = baseUrl + `forgotpassword/verify/${otp}`
        let { body } = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify({ email }),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const ForgotPasswordStep3 = async (otp, email, newPassword) => {
    try {
        const url = baseUrl + `forgotpassword/reset/${otp}`
        let { body } = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify({ newPassword, email }),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const NewTeamMemberSignup = async input => {
    try {
        const url = baseUrl + 'companyUser'
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers,
            body: JSON.stringify(input),
        })

        if (!body.error) {
            store.dispatch({ type: authActions.SET_USER, user: body })
            store.dispatch(authActions.login(body.token))
            await getBootData(body)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const Signup = async input => {
    try {
        const url = baseUrl + 'company'
        let { body } = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(input),
        })

        if (!body.error) {
            store.dispatch({ type: authActions.SET_USER, user: body })
            store.dispatch(authActions.login(body.token))
            await getBootData(body)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const signUpOTP = async data => {
    try {
        const url = baseUrl + 'signup/otp'
        let { body } = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        })
        return body
    } catch (err) {
        throw err
    }
}

export const Signin = async input => {
    try {
        const url = baseUrl + 'signin'
        let { body } = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(input),
        })

        if (!body.error) {
            store.dispatch({ type: authActions.SET_USER, user: _.omit(body, 'gCalInUse', 'gCalData') })
            if (body.hasOwnProperty('gCalInUse'))
                store.dispatch({ type: authActions.SET_USER_GCAL, data: { gCalInUse: body.gCalInUse } })
            store.dispatch(authActions.login(body.token))
            await getBootData(body)
            return body
        } else {
            return body
        }
    } catch (err) {
        throw err
    }
}

export const Signout = async () => {
    try {
        let userId = store.getState().Auth.user.id
        await store.dispatch(authActions.logout(userId))
        return
    } catch (err) {
        throw err
    }
}

export const CreateJobForCompany = async req => {
    try {
        const url = baseUrl + 'job'
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(req),
        })

        store.dispatch({
            type: jobActions.ADD_JOB,
            job: body.jobDetail,
            stages: body.stages,
        })
        return body
    } catch (err) {
        throw err
    }
}

export const JobApplicationForm = async (req, jobId) => {
    try {
        const url = baseUrl + `job-application-form/${jobId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(req),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const AddTeamMember = async input => {
    try {
        const url = baseUrl + 'user'
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            LogE('api-AddTeamMember-try-body-error', 'Failed to invite team member', body.error)
            warning('Failed to invite team member.')
            return null
        } else {
            success('Invite sent successfully')
            store.dispatch({
                type: mixedActions.ADD_TEAM_MEMBER,
                payload: body.user,
            })
            return body
        }
    } catch (err) {
        LogE('api-AddTeamMember-catch', 'Failed to invite team member', err)
        warning('Failed to invite team member.')
    }
}

export const UpdateTeamMember = async (input, teamMemberId) => {
    try {
        const url = baseUrl + `user/${teamMemberId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        input.status = body.status
        success('Team member updated successfully')
        store.dispatch({
            type: mixedActions.UPDATE_TEAM_MEMBER,
            payload: { _id: teamMemberId, ...input },
        })
        return body
    } catch (err) {
        LogE('api-UpdateTeamMember-catch', 'Failed to update team member', err)
        warning('Failed to update team member.')
    }
}

export const GetCountryCodeFromPosition = async (latitude, longitude) => {
    try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${gMapsGeocodingAPIKey}`
        let { body } = await fetch(url, {
            method: 'GET',
        })

        return body
    } catch (err) {
        throw err
    }
}

export const GetTeamMember = async () => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl + `companyUser/${companyId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        store.dispatch({
            type: mixedActions.SET_TEAM_MEMBER,
            payload: body.details,
        })
        return body
    } catch (err) {
        throw err
    }
}

export const GetCompanyJobs = async () => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl + `jobs/${companyId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const GetCandidates = async () => {
    try {
        const url = baseUrl + 'candidates'
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const GetResumeDetails = async (data, publicToken) => {
    try {
        let authToken
        if (publicToken) {
            authToken = publicToken
        } else {
            authToken = store.getState().Auth.idToken
        }
        const url = baseUrl_v2 + apiType.restricted + 'candidate/basicResume'
        const result = await axios.post(url, data, {
            headers: {
                Authorization: authToken,
                'Content-Type': 'multipart/form-data',
            },
        })
        return result
    } catch (err) {
        throw err
    }
}

export const GetCandidatesPhoneNumber = async ({ phone, companyId, token = null, jobId = null }) => {
    try {
        // the compnayId, token is taken from the calling function.
        // in the ApplyNow page the user is not authenticated
        const url = baseUrl_v2 + apiType.restricted + `candidate/phone/${companyId}`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(token),
            body: JSON.stringify({ phone, jobId }),
        })
        return body
    } catch (err) {
        throw err
    }
}

export const ValidateEmailAddress = async emailDomain => {
    const url = `https://open.kickbox.com/v1/disposable/${emailDomain}`
    const res = await axios.get(url)
    return res
}

export const GetCompanyJobByUniqueUrl = async uniqueCode => {
    try {
        const url = baseUrl + `job-uniqueReferal/${uniqueCode}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const DownloadCandidateReport = async data => {
    try {
        const { user } = store.getState().Auth
        store.dispatch({ type: authActions.SET_USER, user: { ...user, exportInProcess: true } })
        const url = baseUrl_v2 + `me/candidate/downloadCandidateReport/${user.companyId}`
        let resp = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(data),
        })
        setTimeout(() => {
            store.dispatch({ type: authActions.SET_USER, user: { ...user, exportInProcess: false } })
        }, 600000)
        return resp
    } catch (error) {}
}

export const UpdateCandidateWithJob = async (cId, data) => {
    try {
        const url = baseUrl + `candidates/updateDetails/${cId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(data),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const GetATSBoardData = async (jobId, filterApplied) => {
    try {
        const url = baseUrl + `interview-stages/${jobId}?filterApplied=${filterApplied}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const GetATSBoardNextData = async (jobId, stageId, page, filterApplied) => {
    try {
        const url = baseUrl + `interview-stages-next/${jobId}/${stageId}/${page}?filterApplied=${filterApplied}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const GetATSBoardApplication = async candidateId => {
    try {
        const url = baseUrl + `interview-stage-application/${candidateId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const GetJobLIstViewData = async jobId => {
    try {
        const url = baseUrl + `interview-stages-list/${jobId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const GetCandidateDetail = async id => {
    try {
        const url = baseUrl + 'candidate/' + id
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        if (body.error && body.error.msg && body.error.msg !== 'Session Expired.') {
            throw new Error(body.error.msg)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const UpdateCandidateStage = async input => {
    try {
        const url = baseUrl + `job-application-stage`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const UpdateMultipeCandidateStage = async input => {
    try {
        const url = baseUrl + `job-application-stage-multiple`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const UpdateMultipleCandidateJob = async input => {
    try {
        const url = baseUrl + `job-application/updateOpening`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ ...input, offset: moment().utcOffset() }),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const UpdateMultipleCandidatesStage = async (stageId, input) => {
    try {
        const url = baseUrl + `job-application/multiple/${stageId}`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const AddNewStage = async input => {
    try {
        const url = baseUrl + `interview-stage`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        store.dispatch({
            type: jobActions.ADD_STAGE,
            stage: body.stage,
        })
        return body
    } catch (err) {
        throw err
    }
}

export const PublicApplyNow = async input => {
    try {
        const url = baseUrl_v2 + `candidate/applynow`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            return Promise.reject({ ...body.error })
        }
        return body
    } catch (err) {
        throw err
    }
}

export const GetDashboardData = async () => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl_v2 + `me/dashboard/${companyId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        return body
    } catch (err) {
        throw err
    }
}

export const AddCoordinatorToJob = async input => {
    try {
        const url = baseUrl + 'job/assignCoordinate'
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            warning('Failed to add user as coordinator')
        } else {
            success('Successfully added new user as coordinator')
            store.dispatch({
                type: mixedActions.UPDATE_TEAM_MEMBER,
                payload: {
                    updateJob: true,
                    _id: input.userId,
                    jobId: input.jobId,
                },
            })
        }
        return body
    } catch (err) {
        warning('Failed to add user as coordinator')
    }
}

export const GetCoordinatorByJobId = async jobId => {
    try {
        const url = baseUrl + `coordinator/${jobId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        if (body.error) {
            throw new Error(body.error)
        }

        return body
    } catch (err) {
        throw err
    }
}

export const PostInterview = async input => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl_v2 + apiType.restricted + `interview/createInterview/${companyId}`
        if (input && input.interviewType && input.interviewType === 'Google Meet') {
            input.interviewType = 'HANGOUTS'
        }
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        return body
    } catch (error) {
        LogE('PostInterview', '', error.message || error)
        throw error
    }
}

export const RejectCandidate = async (input, id) => {
    try {
        const url = baseUrl + `job-application/reject/${id}`
        const offset = moment().utcOffset()
        input['offset'] = offset
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const UpdateFavouriteStatus = async (input, id) => {
    try {
        const url = baseUrl + `job-application/favourite/${id}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const UpdateMultiFavouriteStatus = async input => {
    try {
        const url = baseUrl + 'job-application/multiple/favourite'
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const ArchiveApplications = async (jobAppIds, reason, sendEmailToCandidate) => {
    try {
        const input = {
            jobAppIds,
            archiveReason: reason,
            sendEmailToCandidate,
        }
        const url = baseUrl + `job-application-archive`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}
export const DeleteCandidate = async data => {
    try {
        const url = baseUrl_v2 + apiType.restricted + `candidate/delete`
        let { body } = await fetch(url, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify(data),
        })
        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (error) {
        throw error
    }
}

export const AssignTeamMember = async (jobApplicationId, assignedTo) => {
    try {
        const url = baseUrl + `job-application/assignCandidate/${jobApplicationId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ assignedTo }),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const UpdateJob = async (input, jobId) => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl_v2 + `me/job/${jobId}/?companyId=${companyId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        store.dispatch({
            type: jobActions.UPDATE_JOB,
            job: body.job,
        })
        return body
    } catch (err) {
        throw err
    }
}

export const GetJobById = async jobId => {
    try {
        const url = baseUrl + `job/${jobId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const UpdateStagePosition = async (input, jobId) => {
    try {
        const url = baseUrl + `interview-stage`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }

        store.dispatch({
            type: jobActions.UPDATE_STAGE,
            jobId,
            stages: input,
        })
        return body
    } catch (err) {
        throw err
    }
}

export const DeleteStage = async (stageId, jobId) => {
    try {
        const url = baseUrl + `interview-stage/${stageId}`
        let { body } = await fetch(url, {
            method: 'DELETE',
            headers: getHeaders(),
        })

        if (body.error) {
            throw new Error(body.error)
        }

        store.dispatch({
            type: jobActions.DELETE_STAGE,
            jobId,
            stageId,
        })
        return body
    } catch (err) {
        throw err
    }
}

export const GetAllInterviews = async () => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl + `interviews/${companyId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        if (body.error) {
            throw new Error(body.error)
        }

        return body
    } catch (err) {
        throw err
    }
}

export const PatchInterview = async (input, interviewId) => {
    try {
        input.userId = store.getState().Auth.user.id
        input.companyId = store.getState().Auth.user.companyId
        const url = baseUrl + `interview/${interviewId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }

        if (body.newTeamMember) {
            store.dispatch({
                type: mixedActions.ADD_TEAM_MEMBER,
                payload: body.newTeamMember,
            })
        }

        return body
    } catch (err) {
        throw err
    }
}

export const SendInterviewReminder = async (interviewId, offset) => {
    try {
        const url = baseUrl + `interview/reminder/${interviewId}`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ offset }),
        })

        if (body.error) {
            throw new Error(body.error)
        }

        return body
    } catch (err) {
        throw err
    }
}

export const PatchInterviewStatus = async (input, interviewId) => {
    try {
        const url = baseUrl + `interview-status/${interviewId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const AddFeedback = async input => {
    try {
        input.userId = store.getState().Auth.user.id
        const url = baseUrl + `feedback`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const UpdateFeedback = async input => {
    try {
        const url = baseUrl + `feedback/${input._id}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const RequestFeedback = async interviewId => {
    try {
        const url = baseUrl + `feedback-request/${interviewId}`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const SaveDoSelectCredentials = async input => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl + `doSelect/credentials/${companyId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const SaveUnberryCredentials = async input => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl + `unberry/credentials/${companyId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const GetCompany = async () => {
    try {
        const { companyId } = store.getState().Auth.user
        const userId = store.getState().Auth.user.id
        const url = baseUrl + `company-details/${userId}/${companyId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const GetDoSelectTests = async () => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl + `doSelect/tests/${companyId}`
        let { res, body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })
        if (res.status === 204) {
            store.dispatch({
                type: mixedActions.SET_DOSELECT_TEST,
                payload: [],
            })
        } else {
            if (body.error) {
                throw new Error(body.error)
            }
            store.dispatch({
                type: mixedActions.SET_DOSELECT_TEST,
                payload: body.tests || [],
            })
        }
        return body
    } catch (err) {
        return null
    }
}

export const GetUnberryPositions = async () => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl + `unberry/positions/${companyId}`
        let { res, body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })
        if (res.status === 204) {
            store.dispatch({
                type: mixedActions.SET_UNBERRY_POSITION,
                payload: [],
            })
        } else {
            if (body.error) {
                throw new Error(body.error)
            }
            store.dispatch({
                type: mixedActions.SET_UNBERRY_POSITION,
                payload: body.positions || [],
            })
        }
        return body
    } catch (err) {
        return null
    }
}

export const PostDoSelectTests = async (jobAppId, input) => {
    try {
        const url = baseUrl + `doSelect/test/${jobAppId}`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const ResendDoSelectTests = async (jobAppId, input) => {
    try {
        const url = baseUrl + `doSelect/resend/${jobAppId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const SendMessage = async input => {
    try {
        const token = store.getState().Auth.idToken
        const formData = new FormData()
        Object.keys(input).forEach(key => {
            if (key === 'attachments') {
                input.attachments.forEach(x => {
                    formData.append('attachments', x)
                })
            } else {
                input[key] && formData.append(key, input[key])
            }
        })

        const url = baseUrl + `candidate-email`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: token,
            },
            body: formData,
        })

        return body
    } catch (err) {
        throw err
    }
}

export const EmailAddTeamMember = async (emailId, userId) => {
    try {
        const url = baseUrl + `email/${emailId}/addTeamMember`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ userId }),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const EmailRemoveTeamMember = async (emailId, userId) => {
    try {
        const url = baseUrl + `email/${emailId}/removeTeamMember`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ userId }),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const ChangeEmailPrivacy = async (emailId, markedPrivate) => {
    try {
        const url = baseUrl + `email/${emailId}/setPrivacy`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ markedPrivate }),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const RemoveTeamMember = async userId => {
    try {
        const url = baseUrl + `disableUser`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ userId }),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        store.dispatch({
            type: mixedActions.UPDATE_TEAM_MEMBER,
            payload: { _id: userId, status: 2 },
        })
        return body
    } catch (err) {
        throw err
    }
}

export const GetAllJobsWithStage = async () => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl_v2 + apiType.restricted + `jobs-list/${companyId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        store.dispatch({ type: jobActions.SET_ALL_JOBS, jobs: body.jobs })
        return body
    } catch (err) {
        throw err
    }
}

export const SaveAccountDetails = async input => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl + `company/${companyId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const ChangePasswordApi = async input => {
    try {
        const { email } = store.getState().Auth.user
        input.email = email
        const url = baseUrl + `changePassword`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const AddNotes = async input => {
    try {
        const url = baseUrl + `note`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (!body.status) {
            return new Error(body.message)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const PatchNotes = async (input, noteId) => {
    try {
        const url = baseUrl + `note/${noteId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            return new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const DeleteNote = async noteId => {
    try {
        const url = baseUrl + `note/${noteId}`
        let { body } = await fetch(url, {
            method: 'DELETE',
            headers: getHeaders(),
        })

        if (body.error) {
            return new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const SendMultipleDoSelectTest = async (stageId, input) => {
    try {
        const url = baseUrl + `doSelect/test/${stageId}/bulkAdd`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            return new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const RejectMultipleCandidates = async (stageId, input) => {
    try {
        const url = baseUrl + `job-application/reject-multiple/${stageId}`
        const offset = moment().utcOffset()
        input['offset'] = offset
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            return new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const AssignStage = async input => {
    try {
        const url = baseUrl + `interview-stage/assign`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            return new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

export const UpdateCandidateStatus = async (jobAppId, input) => {
    try {
        const url = baseUrl_v2 + `me/jobApplication/${jobAppId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ ...input, offset: moment().utcOffset() }),
        })

        if (body.error) {
            return new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}
export const DeleteJobOpening = async data => {
    try {
        const url = baseUrl_v2 + `me/job/delete`
        let { body } = await fetch(url, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify(data),
        })
        if (body.error) {
            return new Error(body.error)
        }
        store.dispatch({ type: jobActions.UPDATE_JOBS, data: body.jobId })
        return body
    } catch (error) {
        throw error
    }
}
export const UpdateCandidateSource = async (jobAppId, input) => {
    try {
        const url = baseUrl + `job-application/updateSource/${jobAppId}`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        if (body.error) {
            return new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}

/**
 * Formatting has been added here so that the frontend can always expect a consistant data format
 */
export const CareerFormGet = async () => {
    const { companyId } = store.getState().Auth.user
    const url = baseUrl_v2 + apiType.restricted + `career-page/${companyId}/settings`
    let { body } = await fetch(url, {
        method: 'GET',
        headers: getHeaders(),
    })

    function findMaxPosition(data) {
        let maxPosition = 1
        let fixedPositions = ['why_work_with_us', 'culture', 'benefits'] //order is important, it determines the order in which missingSections is traversed.
        let missingSections = []

        for (let key of fixedPositions) {
            if (data[key]) {
                if (data[key].position > maxPosition) maxPosition = data[key].position
            } else {
                missingSections.push(key)
            }
        }

        if (data.sections) {
            for (let item of data.sections) {
                if (item.position > maxPosition) {
                    maxPosition = item.position
                }
            }
        }

        return { maxPosition, missingSections }
    }

    if (body.settings) {
        let { maxPosition, missingSections } = findMaxPosition(body.settings)

        let currentPosition = maxPosition
        for (let section of missingSections) {
            switch (section) {
                case 'why_work_with_us':
                    body.settings[section] = {
                        points: Array(6).fill(''),
                        position: ++currentPosition,
                    }
                    break
                case 'culture':
                    body.settings[section] = { position: ++currentPosition }
                    break
                case 'benefits':
                    body.settings[section] = {
                        details: Array(4).fill({
                            title: '',
                            description: '',
                        }),
                        position: ++currentPosition,
                    }
                    break
                default:
                    break
            }
        }
    }
    return body
}

function _formatCareerData(input) {
    let data = JSON.parse(JSON.stringify(input))
    if (data['why_work_with_us'].points.length === 0) {
        data['why_work_with_us'] = null
    }

    if (data['benefits'].details.length === 0) {
        data['benefits'] = null
    }

    if (data['culture'].description !== null && data['culture'].image_urls) {
        if (data['culture'].description.trim().length === 0 && data['culture'].image_urls.length === 0) {
            data['culture'] = null
        }
    } else if (
        data['culture'].description === null &&
        (data['culture'].image_urls && data['culture'].image_urls.length === 0)
    ) {
        data['culture'] = null
    }
    return data
}

export const CareerFormSave = async input => {
    input = _formatCareerData(input)

    const { companyId } = store.getState().Auth.user
    const url = baseUrl_v2 + apiType.restricted + `career-page/${companyId}/settings`
    let { body } = await fetch(url, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(input),
    })

    return body
}

export const CareerFormPublish = async input => {
    let data = _formatCareerData(input)

    const { companyId } = store.getState().Auth.user
    const url = baseUrl_v2 + apiType.restricted + `career-page/${companyId}/settings`
    let { body } = await fetch(url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    })

    return body
}

export const SetAddress = async input => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl_v2 + apiType.restricted + `address/addAddress/${companyId}`
        let { body } = await fetch(url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        body.map((each, index) => (each.key = `ADDRESS-${index + 1}`))
        store.dispatch({ type: authActions.SET_ADDRESS, data: body })

        return body
    } catch (err) {
        LogE('api-setaddress-catch', '', err.message || err)
        warning(err.message || err, null, { bottom: 30 })
    }
}

export const GetAddress = async () => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl_v2 + apiType.restricted + `address/getAddress/${companyId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })

        body.map((each, index) => (each.key = `ADDRESS-${index + 1}`))
        store.dispatch({ type: authActions.SET_ADDRESS, data: body })

        return body
    } catch (err) {
        LogE('api-getaddress-catch', '', err.message || err)
        warning(err.message || err, null, { bottom: 30 })
    }
}

export const EditAddress = async input => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl_v2 + apiType.restricted + `address/editAddress/${companyId}`
        let { body } = await fetch(url, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        body.map((each, index) => (each.key = `ADDRESS-${index + 1}`))
        store.dispatch({ type: authActions.SET_ADDRESS, data: body })

        return body
    } catch (err) {
        LogE('api-editaddress-catch', '', err.message || err)
        warning(err.message || err, null, { bottom: 30 })
    }
}

export const DeleteAddress = async input => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl_v2 + apiType.restricted + `address/removeAddress/${companyId}`
        let { body } = await fetch(url, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        body.map((each, index) => (each.key = `ADDRESS-${index + 1}`))
        store.dispatch({ type: authActions.SET_ADDRESS, data: body })

        return body
    } catch (err) {
        LogE('api-deleteaddress-catch', '', err.message || err)
        warning(err.message || err, null, { bottom: 30 })
    }
}

export const CancelInterview = async input => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl_v2 + apiType.restricted + `interview/cancelInterview/${companyId}`
        let { body } = await fetch(url, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(input),
        })

        return body
    } catch (error) {
        LogE('CancelInterview', '', error.message || error)
        throw error
    }
}

export const GetUsersCalendarStatus = async () => {
    try {
        const { companyId } = store.getState().Auth.user
        const url = baseUrl_v2 + apiType.restricted + `interview/getInterviewersCalStatus/${companyId}`
        let { body } = await fetch(url, {
            method: 'GET',
            headers: getHeaders(),
        })
        return body
    } catch (error) {
        LogE('GetUsersCalendarStatus', '', error.message || error)
        throw error
    }
}
