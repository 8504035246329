import React from 'react'
import { Notification } from './style'
import { ReactComponent as NoteIcon } from '../../assets/Icons/Banner/info.svg'

const GoogleCalendar = ({ action_allowAccess, action_setManually, data: gCal, talentpool = false }) => {
    const { inUse = null } = gCal || {}

    if (inUse !== null) return null
    const allowAccess = <a onClick={action_allowAccess}>Allow Access</a>
    const setManually = <a onClick={action_setManually}>Setup Manually</a>
    return (
        <Notification talentpool={talentpool}>
            <NoteIcon />
            <div>
                Let your recruitment team stay in sync with your availability and get rid of unwanted meeting overlaps
                with our google calendar integration. {allowAccess} or {setManually}
            </div>
        </Notification>
    )
}

export default GoogleCalendar
