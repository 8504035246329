import _ from 'lodash'
import apiCaller from '../../apiCaller'
import actions from '../../../redux/auth/actions'
import gCalActions from '../../../redux/gCal/actions'
import zoomActions from '../../../redux/zoom/actions'
import outlookActions from '../../../redux/outlook/actions'

import { getBootData } from '../../../redux/boot'
import { success, warning } from '../../../services/Notification'
import { history, persistor } from '../../../redux/store'
import { AUTH_ENTRY_POINTS } from '../../../container/Helpers/calendarPopUp'
import { LogE } from '../../../container/Helpers/errorHandler'
import { ACCESS_LEVEL_DEFINITIONS } from '../../../container/Helpers/constants'

const { open, restricted } = apiCaller.type
const { formatError } = apiCaller
const baseUrl = `auth/`

export const Signin = ({ email, password }, location) => async dispatch => {
    try {
        let user = await open.post(`${baseUrl}signin`, { email, password })
        user = user.data
        dispatch({ type: actions.SET_USER, user: _.omit(user, 'gCalInUse') })
        if (user.hasOwnProperty('gCalInUse'))
            dispatch({ type: gCalActions.SET_IN_USE, payload: { inUse: user.gCalInUse } })

        if (user.zoomUserDetails) {
            dispatch({ type: zoomActions.SET_USER_ZOOM, payload: user.zoomUserDetails })
        }
        if (user.outlookUserDetails) {
            dispatch({ type: outlookActions.SET_USER_OUTLOOK, payload: user.outlookUserDetails })
        }
        dispatch(actions.login(user.token))
        let redirect_uri = location.search.split('redirect_ui=')[1]
        if (redirect_uri) {
            redirect_uri += `#authToken=${user.token}`
            return window.location.replace(redirect_uri)
        }
        let sendTo =
            user.accessLevel === ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel
                ? { pathname: '/dashboard' }
                : { pathname: '/openings' }
        if (location.state) {
            const { from = sendTo } = location.state
            sendTo = from.pathname === '/error' ? sendTo : from.pathname
        }
        history.push({
            pathname: sendTo.pathname,
            state: {
                entryPoint: AUTH_ENTRY_POINTS.SIGN_IN,
            },
        })
    } catch (error) {
        warning(formatError(error))
    }
}

export const SendOtpSignup = input => async dispatch => {
    try {
        let { data } = await open.post(`${baseUrl}signup/otp`, input)
        success(data.message || 'Otp has been sent to your email id.')
        return data
    } catch (error) {
        warning(formatError(error))
        return error.response.data
    }
}

export const CompanySignup = input => async dispatch => {
    try {
        let { data: user } = await open.post(`${baseUrl}signup/company`, input)
        dispatch({ type: actions.FAILED_SIGNUP, payload: false })
        dispatch({ type: actions.SET_USER, user })
        dispatch(actions.login(user.token))
        history.push({
            pathname: '/dashboard',
            state: {
                entryPoint: AUTH_ENTRY_POINTS.SIGN_UP,
            },
        })
    } catch (error) {
        warning(formatError(error))
        dispatch({ type: actions.FAILED_SIGNUP, payload: true })
    }
}

export const UserSignup = input => async dispatch => {
    try {
        let { data: user } = await open.patch(`${baseUrl}signup/user`, input)
        dispatch({ type: actions.SET_USER, user })
        dispatch(actions.login(user.token))
        getBootData(user)
        history.push({
            pathname: '/dashboard',
            state: {
                entryPoint: AUTH_ENTRY_POINTS.SIGN_UP,
            },
        })
    } catch (error) {
        warning(formatError(error))
    }
}

export const RefreshToken = () => async dispatch => {
    try {
        let { data } = await restricted.get(`${baseUrl}current`)
        const sourcing = (data.companySettings && data.companySettings.sourcing) || null
        const isZoomEnabled = (data.companySettings && data.companySettings.isZoomEnabled) || false
        dispatch(actions.login(data.token))
        dispatch({ type: actions.REFRESH_USER, payload: { user: data.user, sourcing, isZoomEnabled } })
        getBootData()
    } catch (error) {
        warning(formatError(error))
    }
}

export const Signout = () => async dispatch => {
    try {
        let { data } = await restricted.post(`${baseUrl}logout`)
        dispatch(actions.logout(data.userId))
        await persistor.purge()
    } catch (error) {
        LogE('signout', 'Error during logout', error)
        history.replace('/signin')
    }
}

export const ChangePassword = values => async dispatch => {
    try {
        let { data } = await restricted.patch(`${baseUrl}changePassword`, values)
        success(data.message || 'Password changed successfully.')
        return data
    } catch (error) {
        warning(formatError(error))
    }
}

export const SendOtpForgotPassword = email => async dispatch => {
    try {
        let { data } = await open.post(`${baseUrl}forgotPassword`, { email })
        success(data.message || 'OTP sent successfully to registered email.')
        return data
    } catch (error) {
        warning(formatError(error))
    }
}

export const VerifyOtpForgotPassword = (otp, email) => async dispatch => {
    try {
        let { data } = await open.post(`${baseUrl}forgotPassword/verify/${otp}`, { email })
        success(data.message || 'OTP verified successfully.')
        return data
    } catch (error) {
        warning(formatError(error))
    }
}

export const ResetPassword = (otp, email, newPassword) => async dispatch => {
    try {
        let { data } = await open.post(`${baseUrl}forgotPassword/reset/${otp}`, { email, newPassword })
        success('Password successfully changed. Please login to continue.' || data.message)
        history.push('/signin')
    } catch (error) {
        warning(formatError(error))
    }
}
