import React, { Component } from 'react'
import './../style.css'
import { Container } from './../style'
import { Upload, Button, Row, Col, Form, Typography, Input, Tooltip, Icon } from 'antd'
import AntdCropper from '../../../Helpers/AntdCropper'
import config from '../../../../config'
import { store } from '../../../../redux/store'
import InfoIcon from '../../../../assets/Icons/Dashboard/Info'
import { success, warning } from '../../../../services/Notification'
import { ReactComponent as Edit } from '../../../../assets/svg/career-edit.svg'
import { BANNER_LIMITS } from '../DataConfigs'
import SaveContainer from './SaveContainer'

const { Text } = Typography

const { baseURL: apiUrl, apiVersion } = config.config()
const apiType = {
    restricted: 'me/',
    public: '',
}

const baseUrl_v2 = apiUrl + apiVersion.v2

class Banner extends Component {
    resetImageUrlData = ({ banner = null }) => {
        // setting in <url,uid,status> format for antd.
        return (
            (banner &&
                banner.background_url &&
                banner.background_url.length && [
                    {
                        uid: 'banner-image1',
                        status: 'done',
                        url: banner.background_url,
                        name:
                            banner.background_url &&
                            banner.background_url
                                .split('/')
                                .slice(-1)[0]
                                .replace(/\d+_/g, ''),
                    },
                ]) ||
            []
        )
    }
    state = {
        editFlag: false,
        banner: this.props.banner,
        background_url: this.resetImageUrlData(this.props),
    }

    onSubmit = async e => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const { background_url } = this.state
                    if (background_url.length > 0 && background_url[0].status === 'done') {
                        values.background_url = background_url.length
                            ? background_url[0].url || background_url[0].response.url
                            : null
                    } else if (background_url.length > 0 && background_url[0].status !== 'done') {
                        warning(BANNER_LIMITS.IMAGE_ERROR)
                        return null
                    }
                    this.props.onSubmit(values, 0, 'banner')
                    this.setState({ editFlag: false, banner: values })
                    this.props.updateEditModeCount('banner', 1)
                    success('BANNER section saved successfully')
                } catch (err) {
                    warning(err.message || err)
                }
            }
        })
    }

    onCancel = () => {
        const { banner } = this.props
        this.props.form.setFieldsValue({
            title: banner.title,
            subTitle: banner.subTitle,
            description: banner.description,
        })
        this.setState({
            editFlag: false,
            background_url: this.resetImageUrlData(this.props),
        })
        this.props.updateEditModeCount('banner', -1)
    }

    edit = () => {
        this.setState({ editFlag: true })
        this.props.updateEditModeCount('banner', 0)
    }

    handleChange = value => {
        this.setState({ editFlag: true })
        this.props.updateEditModeCount('banner', 0)
    }

    uploadBanner = ({ fileList }) => {
        this.setState({ background_url: fileList, editFlag: true })
        this.props.updateEditModeCount('banner', 0)
    }

    beforeUpload = file => {
        const isValidFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
        if (!isValidFormat) {
            warning(BANNER_LIMITS.IMAGE_TYPE)
            return false
        }
        const isLt8M = file.size / 1024 / 1024 < 8
        if (!isLt8M) {
            warning(BANNER_LIMITS.IMAGE_SIZE)
            return false
        }
        return true
    }

    render() {
        const { getFieldDecorator } = this.props.form

        const { banner } = this.props
        const { background_url, editFlag } = this.state

        const { companyId } = store.getState().Auth.user
        const baseUrl = baseUrl_v2 + apiType.restricted + `career-page/${companyId}/upload/image` // TODO: UPDATE THIS

        const props = {
            name: 'image',
            action: baseUrl,
            listType: 'picture',
            headers: {
                authorization: store.getState().Auth.idToken,
            },
            onChange: this.uploadBanner,
            multiple: false,
            beforeUpload: this.beforeUpload,
            showUploadList: true,
            defaultFileList: background_url,
            accept: '.jpg, .png, .jpeg',
            className: 'upload-list-inline-banner',
        }

        return (
            <div key={this.props.key}>
                <Container editFlag={editFlag}>
                    <Row gutter={48}>
                        <Col span={12}>
                            <Text className="carrer-cont-title">BANNER</Text>
                        </Col>

                        <Col span={12}>
                            <div className="career-cont-btn">
                                <div className="career-section-icons" onClick={this.edit}>
                                    <Icon className="career-icons" component={Edit} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={48}>
                        <Col span={12}>
                            <Text className="carrer-cont-title">Title</Text>
                            <Form.Item>
                                {getFieldDecorator('title', {
                                    initialValue: banner.title,
                                    rules: [
                                        {
                                            max: BANNER_LIMITS.TITLE,
                                            message: BANNER_LIMITS.TITLE_ERROR,
                                        },
                                    ],
                                })(<Input size="large" placeholder="JOIN OUR TEAM" onChange={this.handleChange} />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Text className="carrer-cont-title">Subtitle</Text>
                            <Form.Item>
                                {getFieldDecorator('description', {
                                    initialValue: banner.description,
                                    rules: [
                                        {
                                            max: BANNER_LIMITS.SUBTITLE,
                                            message: BANNER_LIMITS.SUBTITLE_ERROR,
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        placeholder="Enter your subtitle"
                                        onChange={this.handleChange}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={48}>
                        <Col span={12} onClick={this.handleChange}>
                            <Text className="carrer-cont-title">
                                Upload Background
                                <Tooltip
                                    placement="top"
                                    title="You can upload banner image in the size of 1280 * 500"
                                    overlayClassName="career-tooltip"
                                >
                                    <Icon className="career-img-tooltip" component={InfoIcon} />
                                </Tooltip>
                            </Text>
                            <div className="career-upload">
                                <AntdCropper height={1} width={3} useRatio={true} resize={false} resizeAndDrag={true}>
                                    <Upload {...props}>
                                        {background_url.length === 0 && (
                                            <Button className="career-banner-btn" type="primary" size="large">
                                                Upload
                                            </Button>
                                        )}
                                    </Upload>
                                </AntdCropper>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div></div>
                        </Col>
                    </Row>
                    {editFlag && <SaveContainer onSubmit={this.onSubmit} onCancel={this.onCancel} />}
                </Container>
            </div>
        )
    }
}

export default Form.create({ name: 'banner' })(Banner)
