export const LOGIN_TEXT = 'GO TO DASHBOARD'

export const COMPANY_LOGS = [
           'instamojo.png',
           'hero.png',
           'open.png',
           'zolo.png',
           'simpl.png',
           'cred.png',
           'apollo.png',
           'godrej.png',
           '10.png',
       ]

export const ASSETS_CDN = 'https://assets.springrecruit.com/landing/logos/'
