import { LogE } from '../../container/Helpers/errorHandler'

/* * On dev env there is no tawk service */

/*
 * On prod: shouldShow:true make sure its not hidden
 *  (This is done to make sure that widget exists when user moves from page where it was hidden to a different page where it is to be opened.)
 * */
export const zohoServiceVisibility = shouldShow => {
    const cleanZoho = setInterval(() => {
        try {
            if (!window.$zoho) {
                // its dev env, cannot do anything
                clearInterval(cleanZoho)
            } else if (window.$zoho.salesiq.init_called) {
                if (shouldShow) {
                    window.$zoho.salesiq.floatbutton.visible('show')
                } else if (!shouldShow) {
                    window.$zoho.salesiq.floatbutton.visible('hide')
                }
                clearInterval(cleanZoho)
            }
        } catch (e) {
            LogE('zoho-service-error', '', e)
        }
    }, 500)
}
