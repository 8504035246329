import React, { Component } from 'react'
import { Tooltip } from 'antd'
import { Header, Footer } from '../common'
import StyledSwitch from '../../Components/StyledSwitch'
import { USER_SETTINGS, COMPANY_SETTINGS, COMPANY_TOGGLE, CANDIDATE_TOGGLE } from './data'
import './style.scss'

export default class ManageNotifications extends Component {
    constructor(props) {
        super(props)
        const {
            emailNotifications: { candidate, feedback },
            companySettings,
        } = props
        this.state = {
            candidate: {
                added: candidate.added,
                message_sent: candidate.message_sent,
                stage_moved: candidate.stage_moved,
                note_added: candidate.note_added,
            },
            feedback: {
                added: feedback.added,
            },
            companySettings: {
                disableAllCandidateEmail: companySettings.disableAllCandidateEmail,
                disableNotifyCandidateEmail: companySettings.disableNotifyCandidateEmail,
            },
        }
    }

    isChecked = (index, toggleType) => {
        if (toggleType === CANDIDATE_TOGGLE) {
            const { candidate, feedback } = this.state
            if (index === 0) return candidate.added
            else if (index === 1) return candidate.message_sent
            else if (index === 2) return candidate.stage_moved
            else if (index === 3) return candidate.note_added
            else if (index === 4) return feedback.added
            else return false
        } else if (toggleType === COMPANY_TOGGLE) {
            const { companySettings } = this.state
            if (index === 0) return companySettings.disableAllCandidateEmail
            else if (index === 1) return companySettings.disableNotifyCandidateEmail
        }
    }

    notificationToggle = (index, toggleType) => {
        if (toggleType === CANDIDATE_TOGGLE) {
            const { candidate, feedback } = this.state
            if (index === 0) {
                candidate.added = !candidate.added
                this.setState({ candidate })
            } else if (index === 1) {
                candidate.message_sent = !candidate.message_sent
                this.setState({ candidate })
            } else if (index === 2) {
                candidate.stage_moved = !candidate.stage_moved
                this.setState({ candidate })
            } else if (index === 3) {
                candidate.note_added = !candidate.note_added
                this.setState({ candidate })
            } else if (index === 4) {
                feedback.added = !feedback.added
                this.setState({ feedback })
            }
        } else if (toggleType === COMPANY_TOGGLE) {
            const { companySettings } = this.state
            if (index === 0) {
                companySettings.disableAllCandidateEmail = !companySettings.disableAllCandidateEmail
                this.setState({ companySettings })
            } else if (index === 1) {
                companySettings.disableNotifyCandidateEmail = !companySettings.disableNotifyCandidateEmail
                this.setState({ companySettings })
            }
        }
    }

    render() {
        const { candidate, feedback, companySettings } = this.state
        const { accessLevel } = this.props
        return (
            <>
                <Header title="MANAGE NOTIFICATIONS" />
                <h3>COMPANY SETTING</h3>
                <div className="managaeNotificationParent">
                    {COMPANY_SETTINGS.map((each, index) => (
                        <div className="managaeNotification" key={index}>
                            <div className="managaeNotificationContent">
                                <h6>{each.heading}</h6>
                                <p>{each.description}</p>
                            </div>
                            <div className="managaeNotificationSwitch">
                                <StyledSwitch
                                    checked={this.isChecked(index, 'COMPANY_TOGGLE')}
                                    onChange={() => this.notificationToggle(index, 'COMPANY_TOGGLE')}
                                />{' '}
                            </div>
                        </div>
                    ))}
                </div>
                <h3>USER SETTING</h3>
                <div className="managaeNotificationParent">
                    {USER_SETTINGS.map((each, index) => (
                        <div className="managaeNotification" key={index}>
                            <div className="managaeNotificationContent">
                                <h6>{each.heading}</h6>
                                <p>
                                    {[4, 5, 6].includes(accessLevel) && [0, 4].includes(index)
                                        ? each.alDescription
                                        : each.description}
                                </p>
                            </div>
                            {index > 4 ? (
                                <Tooltip title="This cannot be turned off" placement="topRight" arrowPointAtCenter>
                                    <div className="managaeNotificationSwitch">
                                        <StyledSwitch disable={true} checked={true} />
                                    </div>
                                </Tooltip>
                            ) : (
                                <div className="managaeNotificationSwitch">
                                    <StyledSwitch
                                        checked={this.isChecked(index, 'CANDIDATE_TOGGLE')}
                                        onChange={() => this.notificationToggle(index, 'CANDIDATE_TOGGLE')}
                                    />{' '}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <Footer
                    onCancel={this.props.onCancel}
                    onConfirm={() => this.props.onConfirm({ candidate, feedback, companySettings })}
                    cancelText="CANCEL"
                    confirmText="SAVE"
                />
            </>
        )
    }
}
