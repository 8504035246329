import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import qs from 'querystring'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Typography, Button, Icon, Collapse, Tooltip, Avatar, Select, Popover, Menu, Spin, Skeleton } from 'antd'
import {
    HeaderContainer,
    HeaderButton,
    ActionContainer,
    CandidateProfileCont,
    CandidateStatusContainer,
    ActionButton,
    CandidateProfileContainer,
    NoteViewWrapper,
    NoResumeWrapper,
    NoteDeleteWrapper,
    TagCard,
} from './style'
import './style.css'
import StarIcon from '../../../assets/Icons/Talent Pool/Star'
import ActionIcon from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Action'
import ExpandIcon from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Btn'
import { ReactComponent as GreyInfoIcon } from '../../../assets/Icons/Board/Info.svg'
import { ReactComponent as InfoIcon } from '../../../assets/Icons/Talent Pool/info.svg'
import { ReactComponent as OpenInNewTabIcon } from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/OpenInNewTab.svg'
import { ReactComponent as MarkJoinedIcon } from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/MarkJoined.svg'
import { ReactComponent as MarkUnjoinedIcon } from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/UnmarkJoined.svg'
import { ReactComponent as CalendarIcon } from '../../../assets/Icons/Todo/calendar.svg'
import { ReactComponent as FeedbackIcon } from '../../../assets/Icons/Todo/feedback.svg'
import { ReactComponent as CommentIcon } from '../../../assets/Icons/Todo/comment.svg'
import { ReactComponent as CancelIcon } from '../../../assets/Icons/Todo/cancel.svg'
import CollapseIcon from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Or Collapse Btn'
import EditIcon from '../../../assets/Icons/Openings/Edit Icon'
import AddIcon from '../../../assets/Icons/Talent Pool/Add'
import CopyIcon from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Copy'
import WhatsAppIcon from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/whatsapp'
import DropdownIcon from '../../../assets/Icons/Board/Dropdown'
import { ReactComponent as TagIcon } from '../../../assets/Icons/Talent Pool/tag.svg'
import { ReactComponent as EditIcon1 } from '../../../assets/Icons/Talent Pool/Edit.svg'
import { connect } from 'react-redux'
import Error from '../Error'
import FeedBack from './FeedBack'
import Message from './Message'
import {
    GetCandidateDetail,
    ChangeEmailPrivacy,
    UpdateCandidateStage,
    DeleteNote,
    UpdateFavouriteStatus,
    RejectCandidate,
    AssignTeamMember,
    RequestFeedback,
    UpdateCandidateStatus,
    UpdateCandidateWithJob,
    CancelInterview,
    PatchNotes,
    AddNotes,
    UpdateCandidateSource,
    UpdateMultipleCandidateJob,
} from '../../../services/api'
import getAvatar from '../../../services/getAvatar'
import { success, warning } from '../../../services/Notification'
import { LogE } from './../../Helpers/errorHandler'
import EditForm from './EditForm'
import { CANDIDATE_STATUS_ARR, CANDIDATE_STATUS, ACCESS_LEVEL_DEFINITIONS } from '../../Helpers/constants'
import { BLUE_GREY } from '../../Helpers/Stylesheet/styledComponents'
import { accessibleAdminString, accessibleMemberString, canIViewCTCEvaluator } from './Helper/functions'
import { HotKeys } from 'react-keyboard'
import { keyMap, access } from '../../Helpers/KeyMap'
import HiddenInput from '../../Helpers/HiddenInput'
import { currencyUnitAndValueCalculator } from '../../Helpers/currencies'
import ScheduleInterview from './../ScheduleInterview'
import { timeFormat } from '../../Helpers/times'
import Dropzone from 'react-dropzone'
import { fileTypeValidation } from '../../Helpers/fileTypeValidation'
import Paragraph from '../../Components/StyledParagraph'
import { UpdateResume } from '../../../services/NetworkCalls/Candidate'
import { UpdateTags } from '../../../services/NetworkCalls/SourcingTalentPool'
import { Modal } from '../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import { MODAL_TYPE } from './constants'
import { extractSocialsUsername } from '../../Helpers/utils'
import { GreyTags } from '../../Modals/AddTag/style'
import { ReactComponent as CloseIcon } from '../../../assets/Icons/Access/close.svg'
import { StyledButton } from '../../Components'
import { INTERVIEW_TYPES } from '../ScheduleInterview/Constants'
const { Title } = Typography
const { Panel } = Collapse
const profileAccess = access.candidateProfile

class CandidateProfile extends React.Component {
    constructor(props) {
        super(props)
        this.allowShortcuts = props.allowShortcuts
        const { teamMembers } = props
        const teamMembersMap = {}
        teamMembers.forEach(mem => {
            teamMembersMap[mem._id] = mem
        })

        this.state = {
            allInterview: [],
            messages: [],
            notes: [],
            tags: [],
            sourcingTags: [],
            candidateForm: null,
            candidate: {},
            interview: null,
            feedbacks: [],
            jobDetail: null,
            selectedTab: 0,
            editFlag: false,
            interviewModal: false,
            candidateId: null,
            sendNewMessage: false,
            addFeedback: false,
            showNoteModal: false,
            selectedNote: null,
            disableBtn: false,
            showCTCAddMember: false,
            userSearchText: {},
            stageChangeVisible: false,
            rejectCandidateModal: false,
            showUpdateCandidateModal: false,
            showUnarchiveModal: false,
            showUpdateCandidateSourceModal: false,
            showArchivedCandidateModal: false,
            resumeLoaderActive: true,
            isEmailOriginal: true,
            isEmailValid: true,
            isCompanyValid: true,
            isLocationValid: true,
            actionVisible: false,
            noJobAssigned: false,
            isLoading: true,
            candidateNotFound: false, // for the error
            isJobArchived: false,
            selectedId: null,
            teamMembersMap,
            showJoinedConfirmation: false,
            inputFocused: false,
            archiveReasonModel: false,
            clickedOnce: false,
            modalType: '',
            showJobArchivedPopup: false,
            showMore: false,
        }
    }

    async componentDidMount() {
        document.title = 'Candidate Profile | SpringRecruit'

        let { search } = this.props.location
        this.props.allowSwitchTabs(false)
        const tabs = ['profile', 'message', 'feedback']

        if (search) {
            search = search.replace('?', '')
            const { view, id, add } = qs.parse(search)
            const index = tabs.indexOf(view)
            this.setState({
                addFeedback: (add && view === 'feedback') || false,
                selectedTab: index >= 0 ? index : 0,
                selectedId: id,
            })
        }

        const candidateId = this.props.match.params.id

        try {
            const { teamMembersMap } = this.state
            const { allJobs } = this.props

            let candidateDetail = null
            try {
                candidateDetail = await GetCandidateDetail(candidateId)
            } catch (error) {
                this.handleError({ error, api: true })
                return null
            }

            this.currentEmail = candidateDetail.candidate.email

            if (candidateDetail.messages && candidateDetail.messages.length) {
                candidateDetail.messages = candidateDetail.messages.map(message => {
                    message.userId = teamMembersMap[message.userId]
                    return message
                })
            }

            if (candidateDetail.feedbacks && candidateDetail.feedbacks.length) {
                candidateDetail.feedbacks = candidateDetail.feedbacks.map(feedback => {
                    const user = teamMembersMap[feedback.userId]
                    feedback.interviewerEmail = user && user.email
                    feedback.interviewerName = user && user.fullName
                    feedback.userId = user
                    feedback.customSkills = feedback.customFields
                    return feedback
                })
            }

            // adding a pop-up if the candidate's job is archived
            const showArchiveJobPopUp = candidateDetail.jobDetail.jobStatus
            const canIViewCTC = canIViewCTCEvaluator(candidateDetail.candidate)

            this.setState({
                ...candidateDetail,
                candidateId,
                messages: candidateDetail.messages,
                isLoading: false,
                isJobArchived: showArchiveJobPopUp === 0,
                showJobArchivedPopup: showArchiveJobPopUp === 0,
                canIViewCTC,
            })
        } catch (error) {
            this.handleError({ error, api: false })
        }
    }

    handleError = ({ error, api = false }) => {
        LogE('pages-cp-componentDidMount', api ? 'API_ERROR' : '', error)
        warning(api ? error.message : 'Candidate not found.')
        this.setState({ candidateNotFound: true })
    }

    handleCancel = () => {
        this.setState({
            interviewModal: false,
            addFeedback: false,
            showNoteModal: false,
            selectedNote: null,
            rejectCandidateModal: false,
            showUpdateCandidateModal: false,
            showUnarchiveModal: false,
            showUpdateCandidateSourceModal: false,
            editFlag: false,
            noJobAssigned: false,
            showArchivedCandidateModal: false,
            showJoinedConfirmation: false,
            inputFocused: false,
            noEmailPresent: false,
            archiveReasonModel: false,
            showJobArchivedPopup: false,
        })
        this.hiddenInput.focus({ preventScroll: true })
    }

    showUpdateCandidateSourceModal = () => {
        this.setState({ showUpdateCandidateSourceModal: true })
    }

    changeTab = tab => this.setState({ selectedTab: tab, actionVisible: false })

    //Used to change the visibility state of the action option dynamically
    handleVisibleChange = actionVisible => {
        this.setState({ actionVisible })
    }

    updateMessage = async res => {
        let messages = this.state.messages.slice()
        try {
            if (res) {
                messages = [res].concat(messages)
            }
        } catch (err) {
            LogE('pages-cp-updateMessage', '', err)
        } finally {
            this.setState({ sendNewMessage: false, messages })
        }
    }

    saveProfile = candidateForm => {
        this.currentEmail = candidateForm.email
        const canIViewCTC = canIViewCTCEvaluator(candidateForm)

        const { status } = this.state.candidate

        this.setState({
            isEmailOriginal: true,
            candidate: { ...candidateForm, status },
            candidateForm,
            editFlag: false,
            canIViewCTC,
            inputFocused: false,
        })
        this.hiddenInput.focus({ preventScroll: true })
    }

    edtProfile = () => {
        this.setState({
            editFlag: !this.state.editFlag,
            candidateForm: Object.assign({ ctcInfo: {} }, this.state.candidate),
            showCTCAddMember: false,
            userSearchText: undefined,
            inputFocused: true,
        })
    }

    showInterviewModal = () => {
        const { jobDetail, candidate } = this.state
        if (!candidate.email) {
            this.setState({ noEmailPresent: true })
        } else if (jobDetail && jobDetail.jobId) {
            this.setState({ interviewModal: true })
        } else {
            this.setState({ noJobAssigned: true })
        }
    }

    cancelInterview = async () => {
        let { interview } = this.state
        let res = await CancelInterview({
            _id: interview._id,
            interviewEventId: interview.interviewEventId,
            interviewEventUserId: interview.interviewEventUserId,
            offset: moment().utcOffset(),
            type: interview.interviewType,
        })
        if (!res.error) {
            this.setState({ interview: res.interview }, () => {
                success('Interview cancelled successfully')
            })
        }
    }

    interviewSubmit = interview => {
        interview = JSON.parse(JSON.stringify(interview))
        const { teamMembers } = this.props
        const teamMembersMap = {}

        teamMembers.forEach(mem => {
            teamMembersMap[mem._id] = mem
        })

        interview.interviewerIds = interview.interviewers
            ? interview.interviewers.map(interviewer => teamMembersMap[interviewer._id])
            : interview.interviewerIds.map(_id => teamMembersMap[_id])

        interview.interviewStageId = { _id: interview.interviewStageId || interview.interviewStage._id }

        let { allInterview } = this.state
        let index = allInterview.findIndex(
            _interview => _interview.interviewStageId._id === interview.interviewStageId._id
        )
        if (index === -1) {
            allInterview = Array.from([...allInterview, interview])
        } else {
            allInterview.splice(index, 1, interview)
        }
        this.setState({
            interview,
            interviewModal: false,
            allInterview,
        })
        this.hiddenInput.focus({ preventScroll: true })
    }

    sendMessage = () => {
        this.setState({
            selectedTab: 1,
            sendNewMessage: true,
            actionVisible: false,
        })
    }

    sendFeedback = () => {
        this.setState({ addFeedback: true, actionVisible: false })
    }

    handleFeedbackUpdate = (type, updatedFeedback) => {
        let { feedbacks, interview, jobDetail } = this.state
        let stateUpdate = {}

        // Updates the feedback into format that the frontend expects
        const transformFeedback = (updatedFeedback, oldFeedback) => {
            updatedFeedback.userId = oldFeedback.userId
            updatedFeedback.interviewerName = oldFeedback.interviewerName
            updatedFeedback.interviewerEmail = oldFeedback.interviewerEmail
            updatedFeedback.jobTitle = this.state.jobDetail.jobTitle
            if (this.state.jobDetail && this.state.jobDetail.stageId) {
                updatedFeedback.stageId = this.state.jobDetail.stageId
                updatedFeedback.stageName = this.state.jobDetail.stageName
            }
            return updatedFeedback
        }

        if (type === 'update') {
            feedbacks = feedbacks.map(fb =>
                fb._id === updatedFeedback._id ? transformFeedback(updatedFeedback, fb) : fb
            )

            stateUpdate = { feedbacks }
        } else if (type === 'add') {
            const { user } = this.props
            const {
                attitude,
                candidate,
                communication,
                createdAt,
                learningPotential,
                overallFeedback,
                overallPoints,
                technicalSkills,
                customFields,
                noShow,
                _id,
            } = updatedFeedback

            const formatAddFeedback = {
                _id,
                attitude,
                candidateId: candidate._id,
                communication,
                createdAt,
                noShow,
                interviewId: null,
                interviewerEmail: user.email,
                interviewerName: user.userName,
                jobId: jobDetail.jobId,
                jobTitle: jobDetail.jobTitle,
                learningPotential,
                overallFeedback,
                overallPoints,
                technicalSkills,
                stageId: jobDetail.stageId,
                stageName: jobDetail.stageName,
                customFields,
            }

            feedbacks.unshift({ ...formatAddFeedback, userId: updatedFeedback.user })
            if (updatedFeedback.status === 1 && interview) interview.status = 'completed'
            stateUpdate = { feedbacks, interview, selectedTab: 2, addFeedback: false }
        }

        this.setState(stateUpdate)
    }

    showNoteBox = note => {
        if (!note) {
            note = {
                note: undefined,
                private: false,
                canView: [],
            }
        }
        this.setState({
            showNoteModal: true,
            selectedNote: note,
            actionVisible: false,
        })
    }

    onNoteSubmit = async newNote => {
        try {
            const res = newNote._id ? await PatchNotes(newNote, newNote._id) : await AddNotes(newNote)
            success(`Note ${newNote._id ? 'updated' : 'added'} successfully`)
            const { selectedNote } = this.state
            let notes = this.state.notes.slice()
            if (selectedNote) {
                notes = notes.filter(x => x._id !== newNote._id)
            }

            notes = [res.details].concat(notes)

            this.setState({ showNoteModal: false, selectedNote: null, notes })
            this.hiddenInput.focus({ preventScroll: true })
        } catch (err) {
            warning(err.message || err)
        }
    }

    changeEmailPrivacy = async (emailId, checked) => {
        try {
            await ChangeEmailPrivacy(emailId, checked)
            const messages = this.state.messages.map(email => {
                if (email._id === emailId) {
                    email.markedPrivate = checked
                    email.showUserList = false
                    email.markedPrivateBy = this.props.userId
                }

                return email
            })

            this.setState({ messages })
        } catch (err) {
            warning(err.messages || err)
        }
    }

    copyToClipboard = (str, type) => {
        let message = `Candidate ${type} ${str} copied to clipboard.`
        const el = document.createElement('textarea')
        el.value = str
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)

        success(message)
    }

    updateResume = resume => {
        if (resume.status === 200) {
            const { candidate } = this.state
            candidate.candidateResumeLink = resume.data.candidateResumeLink
            this.setState({ disableBtn: false, candidate })
        } else {
            if (!this.state.disableBtn) {
                this.setState({ disableBtn: true })
            }
        }
    }

    onResumeSelect = async acceptedFile => {
        try {
            const fileCheck = fileTypeValidation(acceptedFile)
            if (!fileCheck) {
                warning('Error uploading resume! Please ensure the file is either of type pdf, doc or docx ')
                return true
            }
            const companyId = this.state.candidate.companyId
            const result = await this.props.UpdateResume(fileCheck, this.props.match.params.id, companyId)
            if (result) {
                this.updateResume(result)
                return true
            }
        } catch (err) {
            const errorMsg = (err.response && err.response.data && err.response.data.error.msg) || err
            warning(errorMsg)
        }
        return false
    }

    changeCandidateStage = async item => {
        try {
            this.setState({ stageChangeVisible: false })
            const { jobDetail, candidateId, allInterview, feedbacks, candidate } = this.state
            await UpdateCandidateStage({
                jobId: jobDetail.jobId,
                candidateId,
                stageId: item.key,
            })
            if (jobDetail.stageName === 'JOINED') candidate.status = 1 // If previous stage was JOINED
            jobDetail.stageId = item.key
            jobDetail.stageName = item.item.props.children
            if (jobDetail.stageName === 'JOINED') candidate.status = 3 // If new stage is JOINED

            let interview = null

            if (allInterview.length) {
                interview = allInterview.find(
                    int => int.interviewStageId && int.interviewStageId._id === jobDetail.stageId
                )
                if (interview) {
                    interview.hasFeedback = !!feedbacks.find(x => x.interviewId && x.interviewId === interview._id)
                }
            }

            this.setState({ jobDetail, interview, candidate })
        } catch (err) {
            warning(err.message || err)
        }
    }

    showCTCAddMember = () => {
        this.setState({ showCTCAddMember: true })
    }

    addCTCUser = user => {
        const { candidateForm } = this.state
        if (!candidateForm.ctcInfo.accessibleTo) {
            candidateForm.ctcInfo.accessibleTo = []
        }
        candidateForm.ctcInfo.accessibleTo.push(user)
        this.setState({ candidateForm, userSearchText: undefined })
    }

    removeCTCUser = userId => {
        const { candidateForm } = this.state
        candidateForm.ctcInfo.accessibleTo = candidateForm.ctcInfo.accessibleTo.filter(mem => mem !== userId)
        this.setState({ candidateForm })
    }

    deleteNoteToggle = index => {
        const notes = this.state.notes.slice()
        const [currentNote] = notes.splice(index, 1)
        currentNote.showDeleteBox = !currentNote.showDeleteBox
        notes.splice(index, 0, currentNote)
        this.setState({ notes })
    }

    deleteNote = async (noteId, index) => {
        try {
            await DeleteNote(noteId)
            const notes = this.state.notes.slice()
            notes.splice(index, 1)
            this.setState({ notes })
            success('Note was successfully deleted')
        } catch (err) {
            warning(err.message || err)
        }
    }

    onCandidateStageChange = stageChangeVisible => {
        this.setState({ stageChangeVisible })
    }

    markFavourite = async () => {
        try {
            this.setState({ actionVisible: false })
            const {
                jobDetail: { jobAppId },
                candidate,
            } = this.state
            await UpdateFavouriteStatus({ isFavourite: !candidate.isFavourite }, jobAppId)
            candidate.isFavourite = !candidate.isFavourite
            this.setState({ candidate })
            success(
                `${candidate.name || 'Candidate'} is ${!candidate.isFavourite ? 'removed from' : 'marked as'} favourite`
            )
        } catch (err) {
            warning(err.message || err)
        }
    }

    rejectCan = () => {
        this.setState({ rejectCandidateModal: true, actionVisible: false })
    }

    rejectCandidateConfirm = async () => {
        try {
            const { candidate } = this.state
            this.handleCancel()

            candidate.isRejected = {
                interviewerId: this.props.userId,
                status: true,
            }

            await RejectCandidate(
                {
                    candidateId: this.state.candidateId,
                    isRejected: candidate.isRejected,
                },
                this.state.jobDetail.jobAppId
            )

            candidate.status = 2
            success(`${this.state.candidate.name} has been rejected.`)
            this.setState({ candidate })
        } catch (err) {
            warning(err.message || err)
        }
    }

    handleArchive = () => {
        this.setState({ archiveReasonModel: true })
    }

    archiveApplication = (reason, sendEmailToCandidate) => {
        try {
            this.setState({ actionVisible: false, clickedOnce: true }, async () => {
                const { jobAppId } = this.state.jobDetail
                const { candidate } = this.state
                await UpdateCandidateStatus(jobAppId, {
                    status: CANDIDATE_STATUS.archived.key,
                    archiveReason: reason,
                    sendEmailToCandidate,
                })
                success('Application archived successfully')
                candidate.status = 0
                this.setState({ candidate, archiveReasonModel: false, clickedOnce: false })
                this.hiddenInput.focus({ preventScroll: true })
            })
        } catch (err) {
            warning(err.message || err)
            this.setState({ clickedOnce: false })
        }
    }

    joinCandidate = async stages => {
        try {
            this.setState({ actionVisible: false, showJoinedConfirmation: false })
            const { jobAppId } = this.state.jobDetail
            let { candidate, jobDetail } = this.state
            const joinedStage = stages[stages.length - 1]
            await UpdateCandidateStatus(jobAppId, {
                status: CANDIDATE_STATUS.joined.key,
                stageId: joinedStage._id,
            })
            success(`Application marked as ${CANDIDATE_STATUS.joined.key} successfully`)
            candidate.status = 3
            jobDetail = { ...jobDetail, stageId: joinedStage._id, stageName: joinedStage.stageName }
            this.setState({ candidate, jobDetail, showJoinedConfirmation: false })
            this.hiddenInput.focus({ preventScroll: true })
        } catch (err) {
            warning(err.message || err)
        }
    }

    activeApplication = async payload => {
        try {
            this.setState({ actionVisible: false, showUpdateCandidateModal: false })
            const { allJobs } = this.props
            const { candidate, jobDetail } = this.state
            const stages = allJobs.find(job => job._id === jobDetail.jobId).stages
            const { jobAppId } = jobDetail
            const value = {
                jobId: this.state.jobDetail.jobId,
                status: 'active',
            }
            if (payload && payload.source === MODAL_TYPE.UNJOIN) {
                value.stageId = payload.stageId
                jobDetail.stageId = payload.stageId
                jobDetail.stageName = stages.find(stage => stage._id === payload.stageId).stageName
            } else value.stageId = jobDetail.stageId
            await UpdateCandidateStatus(jobAppId, value)
            success('Application updated successfully')

            candidate.status = 1
            this.setState({ candidate, jobDetail })
        } catch (err) {
            warning(err.message || err)
        }
    }

    moveAndUnarchiveCandidate = async ({ jobId, stageId }) => {
        const { candidate, jobDetail, allInterview, feedbacks } = this.state
        const { allJobs } = this.props

        const job = allJobs.find(x => x._id === jobId)
        const stage = job.stages.find(x => x._id === stageId)

        jobDetail.jobId = job._id
        jobDetail.jobTitle = job.jobTitle
        jobDetail.stageId = stage._id
        jobDetail.stageName = stage.stageName

        const value = {
            jobId,
            status: 'active',
            stageId,
        }
        await UpdateCandidateStatus(jobDetail.jobAppId, value)
        success('Application updated successfully')

        candidate.status = 1

        let interview = null

        if (allInterview.length) {
            interview = allInterview.find(int => int.interviewStageId && int.interviewStageId._id === jobDetail.stageId)
            if (interview) {
                interview.hasFeedback = !!feedbacks.find(x => x.interviewId && x.interviewId === interview._id)
            }
        }

        this.setState({ showUnarchiveModal: false, isJobArchived: false, candidate, jobDetail, interview })
    }

    requestFeedback = async () => {
        try {
            const { interview } = this.state
            await RequestFeedback(interview._id)
            const interviewers = interview.interviewerIds
                .map(interviewer => interviewer.fullName || interviewer.email)
                .join(', ')

            success(
                `${interviewers} ${interview.interviewerIds.length ? 'have' : 'has'} been requested to add feedback.`
            )
        } catch (err) {
            warning(err.message || err)
        }
    }

    assignTeamMember = async teamMember => {
        try {
            const {
                jobDetail: { jobAppId },
                candidate,
            } = this.state
            let { teamMembers } = this.props
            teamMember = teamMembers.find(mem => mem._id === teamMember)
            const name = teamMember.fullName || teamMember.email

            candidate.assignedTo = teamMember._id
            await AssignTeamMember(jobAppId, teamMember._id)
            success(`Candidate has been assigned to ${name}`)
            this.setState({ candidate })
        } catch (err) {
            warning(err.message || err)
        }
    }

    updateCandidate = async (value, jobPayload) => {
        // value = rejected or archived
        try {
            const { jobDetail, candidate, isJobArchived, allInterview, feedbacks, modalType } = this.state
            const { allJobs } = this.props
            const { jobId, stageId, jobApplications, input } = jobPayload

            const job = allJobs.find(x => x._id === value.jobId)
            const stage = job && job.stages.find(x => x._id === value.stageId)

            jobDetail.jobId = job && job._id
            jobDetail.jobTitle = job && job.jobTitle
            jobDetail.stageId = stage && stage._id
            jobDetail.stageName = stage && stage.stageName

            if (modalType === MODAL_TYPE.UPDATE_CANDIDATE_STATUS)
                candidate.status = CANDIDATE_STATUS[value.status].value
            else candidate.status = input.status

            let interview = null
            if (modalType === MODAL_TYPE.UPDATE_CANDIDATE_STATUS) {
                await UpdateCandidateStatus(jobApplications[0], jobPayload.value)
            } else {
                await UpdateMultipleCandidateJob({
                    jobApplications: jobApplications[0],
                    jobId,
                    stageId,
                    status: input.status,
                })

                if (allInterview.length) {
                    interview = allInterview.find(
                        int => int.interviewStageId && int.interviewStageId._id === jobDetail.stageId
                    )
                    if (interview) {
                        interview.hasFeedback = !!feedbacks.find(x => x.interviewId && x.interviewId === interview._id)
                    }
                }
            }
            success('Application updated successfully')
            this.setState({
                candidate,
                jobDetail,
                showUpdateCandidateModal: false,
                isJobArchived: false,
                interview,
            })
            this.hiddenInput.focus({ preventScroll: true })
        } catch (err) {
            warning(err.message || err)
            LogE('updatecandidatemodal-onSubmit', '', err)
        }
    }

    handleUpdatedSource = async value => {
        const { jobDetail } = this.state
        await UpdateCandidateSource(jobDetail && jobDetail.jobAppId, value)
        jobDetail.source = {
            via: value.sourcedVia,
            by: value.sourcedBy,
        }
        this.setState({ jobDetail, showUpdateCandidateSourceModal: false })
        this.hiddenInput.focus({ preventScroll: true })
    }

    resumeLoadHandler = event => {
        this.setState({ resumeLoaderActive: false })
    }

    CTCLockTextGenerator = ({ candidate: { ctcInfo = {} }, canIViewCTC, teamMembersMap }) => {
        if (!canIViewCTC) {
            return null
        }

        // text rule: - ,(comas) are added at the end for dynamic data

        // show : you
        let ctcVisibleToolTip = 'Visible to you,'

        // show : accessibleTo
        ctcVisibleToolTip += accessibleMemberString(ctcInfo, teamMembersMap)

        // Show: SAdmins, Admins
        ctcVisibleToolTip += accessibleAdminString()

        return ctcVisibleToolTip
    }

    confirmJoined = () => {
        this.setState({ showJoinedConfirmation: true })
    }

    handlers = {
        messageCandidate: e => {
            if (this.allowShortcuts && !this.state.isLoading && !this.state.inputFocused) {
                e.preventDefault()
                this.sendMessage()
            }
        },
        addFeedback: e => {
            if (this.allowShortcuts && !this.state.isLoading && !this.state.inputFocused) {
                e.preventDefault()
                this.sendFeedback()
            }
        },
        scheduleInterview: e => {
            if (this.allowShortcuts && !this.state.isLoading && !this.state.inputFocused && !this.state.isJobArchived) {
                e.preventDefault()
                this.showInterviewModal()
            }
        },
        addNote: e => {
            if (this.allowShortcuts && !this.state.isLoading && !this.state.inputFocused) {
                e.preventDefault()
                this.showNoteBox()
            }
        },
        archive: () => {
            const { accessLevel } = this.props
            if (
                this.allowShortcuts &&
                !this.state.isLoading &&
                !this.state.inputFocused &&
                accessLevel <= profileAccess.archive
            ) {
                if (this.state.isJobArchived) this.showUnarchiveCandidateModal()
                else {
                    if (this.state.candidate.status === 0) {
                        this.activeApplication()
                    } else {
                        this.handleArchive()
                    }
                }
            }
        },
        reject: e => {
            if (
                this.allowShortcuts &&
                !this.state.isLoading &&
                !this.state.inputFocused &&
                this.props.accessLevel <= profileAccess.reject
            ) {
                e.preventDefault()
                if (this.state.candidate.status === 2) {
                    this.activeApplication()
                } else {
                    this.rejectCan()
                }
            }
        },
        join: e => {
            if (
                this.allowShortcuts &&
                !this.state.isLoading &&
                !this.state.inputFocused &&
                this.props.accessLevel <= profileAccess.join
            ) {
                e.preventDefault()
                if (this.state.candidate.status === 3) {
                    this.activeApplication()
                } else {
                    this.confirmJoined()
                }
            }
        },
        switchCPTabRight: e => {
            if (this.allowShortcuts && !this.state.inputFocused) {
                e.preventDefault()
                this.changeTab(this.state.selectedTab === 2 ? 0 : this.state.selectedTab + 1)
            }
        },
        switchCPTabLeft: e => {
            if (this.allowShortcuts && !this.state.inputFocused) {
                e.preventDefault()
                this.changeTab(this.state.selectedTab === 0 ? 2 : this.state.selectedTab - 1)
            }
        },
        edit: () => {
            if (
                this.allowShortcuts &&
                !this.state.isLoading &&
                !this.state.inputFocused &&
                this.props.accessLevel <= profileAccess.edit
            )
                this.edtProfile()
        },
        cancelEdit: () => {
            if (
                this.allowShortcuts &&
                !this.state.isLoading &&
                this.state.inputFocused &&
                this.props.accessLevel <= profileAccess.cancelEdit
            )
                this.handleCancel()
        },
    }

    currencyValueCalculator = () => {
        const { candidate } = this.state
        let currentCTCArray = [],
            expectedCTCArray = []
        if (candidate && candidate.ctcInfo) {
            if (candidate.ctcInfo.currentCTC) {
                currentCTCArray = currencyUnitAndValueCalculator(
                    candidate.ctcInfo.currentCTC,
                    candidate.ctcInfo.currentCTCCurrency
                )
            }
            if (candidate.ctcInfo.expectedCTC) {
                expectedCTCArray = currencyUnitAndValueCalculator(
                    candidate.ctcInfo.expectedCTC,
                    candidate.ctcInfo.expectedCTCCurrency
                )
            }
        }
        const ctcArray = [...currentCTCArray, ...expectedCTCArray]
        return ctcArray
    }

    handleAssociateJobOpening = () => {
        this.setState({
            showUpdateCandidateModal: true,
            modalType: MODAL_TYPE.MOVE_JOB,
        })
    }

    showUpdateCandidateModal = isUnjoining => {
        if (isUnjoining) {
            this.setState({
                showUpdateCandidateModal: true,
                modalType: MODAL_TYPE.UNJOIN,
            })
        } else {
            this.setState({
                showUpdateCandidateModal: true,
                modalType: MODAL_TYPE.CHANGE_JOB,
            })
        }
    }

    showUnarchiveCandidateModal = () => {
        this.setState({ showUnarchiveModal: true })
    }

    handleUpdateCandidateStatus = () => {
        this.setState({
            showUpdateCandidateModal: true,
            modalType: MODAL_TYPE.UPDATE_CANDIDATE_STATUS,
        })
    }

    updateTags = async data => {
        const input = {
            addedTags: data.addedTags,
            deletedTags: data.deletedTags,
            jobApplicationIdList: [this.state.jobDetail.jobAppId],
        }
        const res = await UpdateTags(input)
        let { tags } = this.state
        const deletedTags = data.deletedTags.map(tag => tag.tagId)
        tags = tags.filter(tag => !deletedTags.includes(tag.tagId))
        tags = [...tags, ...res.result]
        this.setState({ tags })
    }

    render() {
        const {
            candidate,
            candidateForm,
            jobDetail,
            interview,
            candidateId,
            notes,
            showCTCAddMember,
            stageChangeVisible,
            candidateNotFound,
            isJobArchived,
            canIViewCTC,
            tags,
            sourcingTags,
            showMore,
        } = this.state
        const ctcArray = this.currencyValueCalculator()

        if (candidateNotFound)
            return (
                <div className="cp-no-candidate">
                    <Error {...this.props} />
                </div>
            )

        const defaultInterview = interview
            ? Object.assign(
                {},
                {
                    interviewType: interview.interviewType,
                    interviewerIds: interview.interviewerIds,
                    interviewDate: interview.interviewDate,
                    interviewStartTime: interview.interviewStartTime,
                    interviewerTodo: interview.interviewerTodo,
                    myTodo: interview.myTodo,
                }
            )
            : null

        let hideRequestFeedback = true

        if (interview) {
            const currentDateAfter = moment()
                .startOf('day')
                .isAfter(moment(interview.interviewDate), 'date')
            const currentDateSame = moment()
                .startOf('day')
                .isSame(moment(interview.interviewDate), 'date')
            const currentTimeAfter = moment().isAfter(moment(interview.interviewStartTime), 'hour')

            if (currentDateAfter && !interview.hasFeedback) {
                hideRequestFeedback = false
            }

            if (currentDateSame && currentTimeAfter && !interview.hasFeedback) {
                hideRequestFeedback = false
            }
        }

        const { teamMembers } = this.props
        const { id: userId, accessLevel, allJobs } = this.props

        let candidateJobStages = []
        if (jobDetail && jobDetail.jobId) {
            const candidateJob = allJobs.find(job => job._id === jobDetail.jobId)
            if (candidateJob && candidateJob.stages) candidateJobStages = candidateJob.stages
        }

        if (jobDetail && jobDetail.source.by) {
            const by = teamMembers.find(mem => [mem._id, mem.email].includes(jobDetail.source.by))
            jobDetail.source.byName = by ? by.fullName || by.email : jobDetail.source.by
        }

        const CTCLockToolTipText = canIViewCTC ? this.CTCLockTextGenerator(this.state) : ''

        const isRejected = candidate && candidate.status === 2
        const isArchived = candidate && candidate.status === 0

        let whatsAppNumber = null
        if (candidate && candidate.phone) {
            const phoneNumber = parsePhoneNumberFromString(candidate.phone)
            if (phoneNumber && phoneNumber.isValid()) {
                whatsAppNumber = phoneNumber.countryCallingCode + phoneNumber.nationalNumber
            }
        }
        const warning_message =
            'This candidate is not assigned to any job opening. Please move this candidate to a job opening before scheduling an interview.'
        let interviewerNames = ''

        if (interview && interview.interviewerIds) {
            interviewerNames = interview.interviewerIds.map(interviewers => interviewers.fullName || interviewers.email)
            interviewerNames = interviewerNames.join(', ')
        } else if (interview && interview.interviewers) {
            interviewerNames = interview.interviewers.map(interviewer => interviewer.fullName || interviewer.email)
            interviewerNames = interviewerNames.join(', ')
        }

        return (
            <HotKeys keyMap={keyMap.candidateProfile} handlers={this.handlers}>
                <div>
                    <HeaderContainer>
                        <div className="cp-header-container">
                            <Title level={3} className="cp-header-text">
                                Candidate Profile
                            </Title>
                        </div>
                        <HiddenInput reference={input => (this.hiddenInput = input)} />
                        <div className="cp-header-button-container">
                            <Button.Group>
                                <HeaderButton
                                    onClick={() => this.changeTab(0)}
                                    className={classNames({
                                        'header-btn-active': this.state.selectedTab === 0,
                                    })}
                                >
                                    Profile
                                </HeaderButton>
                                <HeaderButton
                                    onClick={() => this.changeTab(1)}
                                    className={classNames({
                                        'header-btn-active': this.state.selectedTab === 1,
                                    })}
                                >
                                    Messages
                                </HeaderButton>
                                <HeaderButton
                                    onClick={() => this.changeTab(2)}
                                    className={classNames({
                                        'header-btn-active': this.state.selectedTab === 2,
                                    })}
                                >
                                    Feedback
                                </HeaderButton>
                            </Button.Group>

                            <Popover
                                placement="bottomRight"
                                content={
                                    <Menu className="cp-option-cont">
                                        <Menu.Item className="cp-options" onClick={this.markFavourite}>
                                            <span className="cp-option-color">
                                                {candidate && candidate.isFavourite
                                                    ? 'Remove Favourite'
                                                    : 'Mark Favourite'}
                                            </span>
                                        </Menu.Item>

                                        <Menu.Item className="cp-options" onClick={this.sendMessage}>
                                            <span className="cp-option-color">Send Message</span>
                                        </Menu.Item>

                                        <Menu.Item className="cp-options" onClick={this.sendFeedback}>
                                            <span className="cp-option-color">Add Feedback</span>
                                        </Menu.Item>

                                        <Menu.Item className="cp-options" onClick={() => this.changeTab(2)}>
                                            <span className="cp-option-color">View Feedback</span>
                                        </Menu.Item>

                                        {!hideRequestFeedback && (
                                            <Menu.Item className="cp-options" onClick={this.requestFeedback}>
                                                <span className="cp-option-color">Request Feedback</span>
                                            </Menu.Item>
                                        )}

                                        {accessLevel < 5 &&
                                            accessLevel !== ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel ? (
                                            <Menu.Item className="cp-options">
                                                <Popover
                                                    placement="right"
                                                    content={
                                                        <Select
                                                            className="ats-assign-tm-search"
                                                            placeholder="Assign team member"
                                                            notFoundContent={null}
                                                            showSearch
                                                            suffixIcon={<Icon type="search" />}
                                                            defaultActiveFirstOption={false}
                                                            filterOption
                                                            optionFilterProp="title"
                                                            onSelect={this.assignTeamMember}
                                                        >
                                                            {teamMembers
                                                                .filter(
                                                                    mem =>
                                                                        mem.status === 1 &&
                                                                        mem.accessLevel <
                                                                        ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY
                                                                            .accessLevel
                                                                )
                                                                .map(mem => (
                                                                    <Select.Option
                                                                        key={mem._id}
                                                                        className="ats-col-popover-option sort-by-popover-item"
                                                                        title={mem.fullName || mem.email}
                                                                    >
                                                                        {mem.fullName || mem.email}
                                                                    </Select.Option>
                                                                ))}
                                                        </Select>
                                                    }
                                                >
                                                    <span className="cp-option-color">
                                                        {candidate && candidate.assignedTo ? 'Change' : 'Assign'} Team
                                                        Member
                                                    </span>
                                                </Popover>
                                            </Menu.Item>
                                        ) : null}

                                        {accessLevel < 4 ? (
                                            <Menu.Item className="cp-options">
                                                {jobDetail && jobDetail.jobId ? (
                                                    <span
                                                        className="cp-option-color"
                                                        onClick={() => this.showUpdateCandidateModal(false)}
                                                    >
                                                        Change Job Opening
                                                    </span>
                                                ) : (
                                                    <span
                                                        className="cp-option-color"
                                                        onClick={() => this.setState(this.handleAssociateJobOpening)}
                                                    >
                                                        Associate Job Opening
                                                    </span>
                                                )}
                                            </Menu.Item>
                                        ) : null}

                                        <Menu.Item className="cp-options">
                                            <span className="cp-option-color" onClick={() => this.showNoteBox()}>
                                                Add Note
                                            </span>
                                        </Menu.Item>

                                        {accessLevel < 3 && jobDetail && jobDetail.jobId && candidate.status !== 3 ? (
                                            isJobArchived && [0, 1].includes(candidate.status) ? (
                                                <Menu.Item
                                                    className="cp-options"
                                                    onClick={() => this.showUnarchiveCandidateModal()}
                                                >
                                                    <span className="cp-option-color">Unarchive</span>
                                                </Menu.Item>
                                            ) : candidate.status === 0 ? (
                                                <Menu.Item className="cp-options" onClick={this.activeApplication}>
                                                    <span className="cp-option-color">Unarchive</span>
                                                </Menu.Item>
                                            ) : (
                                                <Menu.Item className="cp-options" onClick={this.handleArchive}>
                                                    <span className="cp-option-color">Archive</span>
                                                </Menu.Item>
                                            )
                                        ) : null}

                                        {jobDetail && jobDetail.jobId && candidate.status !== 3 ? (
                                            candidate.status === 2 ? (
                                                <Menu.Item className="cp-options" onClick={this.activeApplication}>
                                                    <span className="cp-option-color">Unreject</span>
                                                </Menu.Item>
                                            ) : (
                                                <Menu.Item className="cp-options" onClick={this.rejectCan}>
                                                    <span className="cp-option-color">Reject</span>
                                                </Menu.Item>
                                            )
                                        ) : null}

                                        {accessLevel < 4 &&
                                            jobDetail &&
                                            jobDetail.jobId &&
                                            (candidate.status === 3 ? (
                                                <Menu.Item
                                                    className="cp-options"
                                                    onClick={() => this.showUpdateCandidateModal(true)}
                                                >
                                                    <span className="cp-option-color">Unmark Joined</span>
                                                </Menu.Item>
                                            ) : (
                                                <Menu.Item className="cp-options" onClick={this.confirmJoined}>
                                                    <span className="cp-option-color">Mark Joined</span>
                                                </Menu.Item>
                                            ))}
                                    </Menu>
                                }
                                trigger="hover"
                                visible={this.state.actionVisible}
                                onVisibleChange={this.handleVisibleChange}
                                getPopupContainer={() => document.getElementById('actions-button')}
                            >
                                {accessLevel !== ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel && (
                                    // Interview only user cannot perform any actions.
                                    <Button type="primary" className="cp-action-btn" id="actions-button">
                                        Actions
                                        <Icon component={ActionIcon} />
                                    </Button>
                                )}
                            </Popover>
                        </div>
                    </HeaderContainer>
                    <div className="cp-container Feedback_outer">
                        <ActionContainer>
                            {
                                <CandidateStatusContainer>
                                    <div className="cp-cd-status-cont">
                                        <div className="cp-cd-status">Status:</div>
                                        {this.state.isLoading ? (
                                            <Skeleton
                                                className="cp-cd-status-label bg-white zero-margin"
                                                title={false}
                                                paragraph={{
                                                    width: '100%',
                                                    rows: 1,
                                                }}
                                                active
                                            />
                                        ) : (
                                            <div className="cp-cd-status-label">
                                                <span>
                                                    {isJobArchived && [0, 1].includes(candidate.status)
                                                        ? CANDIDATE_STATUS[CANDIDATE_STATUS_ARR[0]].name
                                                        : CANDIDATE_STATUS[CANDIDATE_STATUS_ARR[candidate.status]].name}

                                                    {(candidate.status !== 1 || jobDetail.jobStatus === 0) &&
                                                        accessLevel < 4 && (
                                                            <Icon
                                                                className="cp-status-icon"
                                                                component={EditIcon}
                                                                onClick={this.handleUpdateCandidateStatus}
                                                            />
                                                        )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="cp-cd-status-cont1">
                                        <div className="cp-cd-status1">Name:</div>
                                        <div className="cp-cd-status-label1">
                                            {this.state.isLoading ? (
                                                <Skeleton
                                                    title={false}
                                                    paragraph={{
                                                        width: '100%',
                                                        rows: 1,
                                                    }}
                                                />
                                            ) : (
                                                <p>{candidate.name}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="cp-cd-status-cont1">
                                        <div className="cp-cd-status1">Opening:</div>
                                        <div className="cp-cd-status-label1">
                                            {this.state.isLoading ? (
                                                <Skeleton
                                                    title={false}
                                                    paragraph={{
                                                        width: '100%',
                                                        rows: 1,
                                                    }}
                                                    active
                                                />
                                            ) : jobDetail && jobDetail.jobId ? (
                                                <>
                                                    <p>{jobDetail.jobTitle}</p>
                                                    {isJobArchived && <span className="openings-tag">Archived</span>}
                                                </>
                                            ) : (
                                                <Button
                                                    className="associate-job-btn"
                                                    size="large"
                                                    onClick={() =>
                                                        this.setState({
                                                            showUpdateCandidateModal: true,
                                                            modalType: MODAL_TYPE.MOVE_JOB,
                                                        })
                                                    }
                                                >
                                                    <div className="button-text">Associate Job Opening</div>
                                                </Button>
                                            )}
                                        </div>
                                    </div>

                                    {jobDetail && jobDetail.jobId && (
                                        <div className="cp-cd-status-cont2">
                                            <div className="cp-cd-status1">Stage:</div>

                                            <div className="cp-cd-status-label1 flex">
                                                {this.state.isLoading ? (
                                                    <Skeleton
                                                        title={false}
                                                        paragraph={{
                                                            width: '100%',
                                                            rows: 1,
                                                        }}
                                                        active
                                                    />
                                                ) : (
                                                    <div className="cp-cd-stage-label">{jobDetail.stageName}</div>
                                                )}
                                                {!this.state.isLoading &&
                                                    accessLevel < ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel &&
                                                    !isJobArchived &&
                                                    candidate.status !== 3 ? (
                                                    <Popover
                                                        placement="bottom"
                                                        arrowPointAtCenter
                                                        visible={stageChangeVisible}
                                                        onVisibleChange={this.onCandidateStageChange}
                                                        overlayClassName="cp-cd-stage-move"
                                                        content={
                                                            <Menu
                                                                defaultSelectedKeys={[jobDetail.stageId]}
                                                                onSelect={this.changeCandidateStage}
                                                            >
                                                                {candidateJobStages.map(stage => {
                                                                    if (stage.stageName === 'JOINED') return undefined
                                                                    return (
                                                                        <Menu.Item
                                                                            title={stage.stageName}
                                                                            key={stage._id}
                                                                            className="cp-stage-move-option"
                                                                        >
                                                                            {stage.stageName}
                                                                        </Menu.Item>
                                                                    )
                                                                })}
                                                            </Menu>
                                                        }
                                                        title={<div className="cp-stage-move-title">MOVE TO:</div>}
                                                        getPopupContainer={() =>
                                                            document.getElementById('change-stage-arrow')
                                                        }
                                                    >
                                                        {candidateJobStages.length > 1 && (
                                                            <Button
                                                                id="change-stage-arrow"
                                                                style={{
                                                                    border: 'none',
                                                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                                                }}
                                                            >
                                                                <Icon
                                                                    component={DropdownIcon}
                                                                    className="cp-cd-stage-icon cursor-pointer"
                                                                />
                                                            </Button>
                                                        )}
                                                    </Popover>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}

                                    {interview && !isJobArchived && !(isRejected || isArchived) ? (
                                        <div>
                                            <div className="cp-cd-status-cont2">
                                                <div className="cp-cd-status1">Interview Type:</div>
                                                <div className="cp-cd-status-label1">
                                                    {INTERVIEW_TYPES[interview.interviewType]}
                                                </div>
                                            </div>
                                            <div className="cp-cd-status-cont2">
                                                <div className="cp-cd-status1">Interview Date:</div>
                                                <div className="cp-cd-status-label1">
                                                    {moment(interview.interviewDate).format('Do MMM, YYYY')}
                                                </div>
                                            </div>

                                            <div className="cp-cd-status-cont2">
                                                <div className="cp-cd-status1">Interview Time:</div>
                                                <div className="cp-cd-status-label1">
                                                    {moment(interview.interviewStartTime).format(timeFormat)}
                                                </div>
                                            </div>

                                            <div className="cp-cd-status-cont2">
                                                <div className="cp-cd-status1">Interviewer:</div>
                                                <div className="cp-cd-status-label2">
                                                    <Tooltip title={interviewerNames}>{interviewerNames}</Tooltip>
                                                </div>
                                            </div>

                                            <div className="cp-cd-status-cont2">
                                                <div className="cp-cd-status1">Interview Status:</div>
                                                <div className="cp-cd-status-label1">
                                                    {interview.status.charAt(0).toUpperCase()}
                                                    {interview.status.slice(1)}
                                                    <div
                                                        className="view-feddback-text"
                                                        onClick={() => this.changeTab(2)}
                                                    >
                                                        {interview.status === 'completed' ? '(View Feedback)' : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </CandidateStatusContainer>
                            }
                            <TagCard>
                                <div className="header">
                                    <span>TAGS</span>
                                    <div className="icons">
                                        <Tooltip title="Add Tag" placement="topRight" arrowPointAtCenter>
                                            <TagIcon onClick={() => this.setState({ showAddTag: true })} />
                                        </Tooltip>
                                        {sourcingTags.length || tags.length ? (
                                            <Tooltip title="Edit Tag" placement="topRight" arrowPointAtCenter>
                                                <EditIcon1 onClick={() => this.setState({ showManageTag: true })} />
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="tags">
                                    {this.state.showMore
                                        ? tags && tags.map(tag => <span key={tag.tagId}>{tag.name}</span>)
                                        : tags && tags.slice(0, 7).map(tag => <span key={tag.tagId}>{tag.name}</span>)}
                                </div>
                                {sourcingTags.length && (!tags.length || showMore) ? (
                                    <div className={tags.length ? 'sourcing-tags' : ''}>
                                        <div className="via-stp">
                                            <GreyInfoIcon />
                                            <span>Via Sourcing Talent Pool</span>
                                        </div>
                                        <div className="tags">
                                            {sourcingTags.map(tag => (
                                                <span key={tag.tagId}>{tag.name}</span>
                                            ))}
                                        </div>
                                    </div>
                                ) : null}
                                {(tags && tags.length > 7) || (sourcingTags && sourcingTags.length) ? (
                                    <a onClick={() => this.setState({ showMore: !showMore })}>
                                        {showMore ? 'SHOW LESS' : 'SHOW MORE'}
                                    </a>
                                ) : null}
                            </TagCard>

                            {/* {
                         <ActionButton type="primary" ghost>
                         <Icon className="cp-action-button-icon" component={MoveUpIcon} />
                         <span className="cp-action-button-text">Move To Opening</span>
                         </ActionButton>
                         } */}

                            <ActionButton
                                type="primary"
                                ghost
                                onClick={this.showInterviewModal}
                                hidden={
                                    candidate.status !== 1 ||
                                    isJobArchived ||
                                    accessLevel === ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel
                                }
                            >
                                <CalendarIcon className="cp-action-button-icon" />
                                <span className="cp-action-button-text">
                                    {interview ? 'Reschedule' : 'Schedule'} Interview
                                </span>
                            </ActionButton>
                            {interview && (interview.status === 'pending' || interview.status === 'upcoming') && (
                                <ActionButton
                                    type="primary"
                                    ghost
                                    onClick={this.cancelInterview}
                                    hidden={
                                        candidate.status !== 1 ||
                                        isJobArchived ||
                                        accessLevel === ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel
                                    }
                                >
                                    <CancelIcon className="cp-action-button-icon" />
                                    <span className="cp-action-button-text">Cancel Interview</span>
                                </ActionButton>
                            )}

                            <ActionButton type="primary" ghost onClick={this.sendMessage}>
                                <CommentIcon className="cp-action-button-icon" />
                                <span className="cp-action-button-text">Message Candidate</span>
                            </ActionButton>

                            <ActionButton type="primary" ghost onClick={this.sendFeedback}>
                                <FeedbackIcon className="cp-action-button-icon" />
                                <span className="cp-action-button-text">Add Feedback</span>
                            </ActionButton>

                            {accessLevel < 4 && jobDetail && jobDetail.jobId && jobDetail && jobDetail.jobStatus !== 0 && (
                                <ActionButton
                                    type="primary"
                                    ghost
                                    onClick={() => {
                                        if (candidate.status === 3) this.showUpdateCandidateModal(true)
                                        else this.confirmJoined()
                                    }}
                                >
                                    {candidate.status === 3 ? (
                                        <MarkUnjoinedIcon className="cp-action-button-icon" />
                                    ) : (
                                        <MarkJoinedIcon className="cp-action-button-icon" />
                                    )}
                                    <span className="cp-action-button-text">
                                        {candidate.status === 3 ? 'Unmark Joined' : 'Mark Joined'}
                                    </span>
                                </ActionButton>
                            )}

                            {jobDetail &&
                                jobDetail.jobId &&
                                ([1, 2].includes(accessLevel) ||
                                    ([3, 4].includes(accessLevel) &&
                                        this.props.accessibleJobs.includes(jobDetail.jobId))) &&
                                !isJobArchived ? (
                                <div
                                    className="goto-job-opening-board-btn"
                                    onClick={() =>
                                        this.props.history.push({
                                            pathname: `/openings/${[0, 2].includes(candidate.status) ? 'list' : 'board'
                                                }/${jobDetail.jobId}`,
                                            state: {
                                                searchCandidate: candidate._id,
                                                filter: candidate.status === 2 ? 'rejected' : 'archived',
                                            },
                                        })
                                    }
                                >
                                    {' '}
                                    Go to Job Opening {candidate.status === 2 ? 'List' : 'Board'}{' '}
                                </div>
                            ) : null}
                        </ActionContainer>

                        {this.state.selectedTab === 2 && (
                            <FeedBack
                                feedbacks={this.state.feedbacks}
                                updateFeedback={this.handleFeedbackUpdate.bind(this.handleFeedbackUpdate, 'update')}
                                sendFeedback={this.sendFeedback}
                                isLoading={this.state.isLoading}
                                candidateName={candidate.name}
                            />
                        )}

                        {this.state.selectedTab === 1 && (
                            <Message
                                messages={this.state.messages}
                                candidateName={candidate && candidate.name}
                                {...this.state}
                                {...this.props}
                                isAdd={this.state.sendNewMessage}
                                cancelNewMessage={this.updateMessage}
                                changeEmailPrivacy={this.changeEmailPrivacy}
                                selectedId={this.state.selectedId}
                                handleFocus={() => this.hiddenInput.focus({ preventScroll: true })}
                                companyUniqueEmailId={this.props.user.companyUniqueEmailId}
                            />
                        )}

                        {this.state.selectedTab === 0 && (
                            <CandidateProfileContainer>
                                <CandidateProfileCont>
                                    <Collapse
                                        className="cp-collapse candidate-profile-source-parent"
                                        defaultActiveKey={['1']}
                                        expandIcon={({ isActive }) => (
                                            <Icon component={isActive ? CollapseIcon : ExpandIcon} />
                                        )}
                                    >
                                        <Panel
                                            className="candidate-source-panel"
                                            header="Profile:"
                                            key="1"
                                            extra={
                                                jobDetail && (
                                                    <div
                                                        className="cp-source candidate-profile-source"
                                                        onClick={e => e.stopPropagation()}
                                                    >
                                                        SOURCE: <span>via</span> {jobDetail && jobDetail.source.via}{' '}
                                                        {jobDetail && jobDetail.source.by && (
                                                            <React.Fragment>
                                                                | <span>by</span>{' '}
                                                                {jobDetail && jobDetail.source.via === 'Referral'
                                                                    ? `${JSON.parse(jobDetail.source.byName).name} (${JSON.parse(jobDetail.source.byName).email
                                                                    })`
                                                                    : jobDetail && jobDetail.source.byName}
                                                            </React.Fragment>
                                                        )}
                                                        {accessLevel < 5 && (
                                                            <Icon
                                                                component={EditIcon}
                                                                onClick={this.showUpdateCandidateSourceModal}
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            }
                                        >
                                            {this.state.isLoading ? (
                                                <Skeleton
                                                    avatar={{
                                                        size: 'large',
                                                        shape: 'square',
                                                    }}
                                                    paragraph={{
                                                        rows: 6,
                                                        width: Array(6).fill('95%'),
                                                    }}
                                                    title={false}
                                                    active
                                                />
                                            ) : (
                                                <div className="cp-card-block">
                                                    <div className="cp-avatar-block">
                                                        <span
                                                            style={{
                                                                marginRight: 24,
                                                            }}
                                                        >
                                                            {/* {<Badge count="72%">} */}
                                                            <Avatar size={80} shape="square">
                                                                <span className="cp-avatarName">
                                                                    {candidate.name ? (
                                                                        getAvatar(candidate.name)
                                                                    ) : (
                                                                        <InfoIcon
                                                                            style={{ width: '25px', height: '25px' }}
                                                                        />
                                                                    )}
                                                                </span>
                                                            </Avatar>
                                                            {/* {</Badge>} */}
                                                        </span>
                                                    </div>
                                                    <div className="cp-description">
                                                        {!this.state.editFlag ? (
                                                            <div className="cp-profile-block">
                                                                <div className="cp-profile-row">
                                                                    <div className="cp-profile-label">Name:</div>
                                                                    <div className="cp-profile-text">
                                                                        <p
                                                                            className={
                                                                                !candidate.name ? 'color-orange' : ''
                                                                            }
                                                                        >
                                                                            {candidate.name || 'Candidate'}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="cp-profile-row">
                                                                    <div className="cp-profile-label">Company:</div>
                                                                    <div className="cp-profile-text">
                                                                        <p>{candidate.currentCompanyName || 'N/A'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="cp-profile-row">
                                                                    <div className="cp-profile-label">Location:</div>
                                                                    <div className="cp-profile-text">
                                                                        <p>{candidate.currentLocation || 'N/A'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="cp-profile-row">
                                                                    <div className="cp-profile-label">Experience:</div>
                                                                    <div className="cp-profile-text">
                                                                        <p>{candidate.totalExperience || 'N/A'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="cp-profile-row">
                                                                    <div className="cp-profile-label">
                                                                        Notice Period:
                                                                    </div>
                                                                    <div className="cp-profile-text">
                                                                        {candidate.noticePeriod === 'Serving now' &&
                                                                            candidate.noticeDate ? (
                                                                            <p>
                                                                                {' '}
                                                                                Last working day is{' '}
                                                                                {moment(candidate.noticeDate).format(
                                                                                    'DD MMM Y'
                                                                                )}{' '}
                                                                            </p>
                                                                        ) : candidate.noticePeriod ? (
                                                                            <p>{candidate.noticePeriod}</p>
                                                                        ) : (
                                                                            <p>N/A</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="cp-profile-row">
                                                                    <div className="cp-profile-label">Email:</div>
                                                                    <div className="cp-profile-text">
                                                                        <p>{candidate.email || 'N/A'}</p>
                                                                        {candidate.email && (
                                                                            <Tooltip
                                                                                overlayClassName="fs-10"
                                                                                title="Copy candidate's email"
                                                                            >
                                                                                <Icon
                                                                                    component={CopyIcon}
                                                                                    rotate="180"
                                                                                    className="cp-copy-icon"
                                                                                    onClick={() =>
                                                                                        this.copyToClipboard(
                                                                                            candidate.email,
                                                                                            'email'
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="cp-profile-row">
                                                                    <div className="cp-profile-label">
                                                                        Phone Number:
                                                                    </div>
                                                                    <div className="cp-profile-text">
                                                                        <p>{candidate.phone || 'N/A'}</p>
                                                                        {whatsAppNumber && (
                                                                            <div
                                                                                className="cp-whats-app-cont"
                                                                                onClick={() =>
                                                                                    window.open(
                                                                                        `https://api.whatsapp.com/send?phone=${whatsAppNumber}`,
                                                                                        '_blank'
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Tooltip
                                                                                    overlayClassName="fs-10"
                                                                                    title="Send message via WhatsApp"
                                                                                >
                                                                                    <Icon
                                                                                        component={WhatsAppIcon}
                                                                                        className="cp-whatsapp-icon"
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                        {candidate.phone && (
                                                                            <Tooltip
                                                                                overlayClassName="fs-10"
                                                                                title="Copy candidate's phone number"
                                                                            >
                                                                                <Icon
                                                                                    component={CopyIcon}
                                                                                    rotate="180"
                                                                                    className="cp-copy-icon ml-5"
                                                                                    onClick={() =>
                                                                                        this.copyToClipboard(
                                                                                            candidate.phone,
                                                                                            'phone'
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="cp-profile-row">
                                                                    <div className="cp-profile-label">Added on:</div>
                                                                    <div className="cp-profile-text">
                                                                        <p>
                                                                            {moment(candidate.createdAt).format(
                                                                                'DD MMM, Y'
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="cp-profile-row">
                                                                    <div className="cp-profile-label">Skype ID:</div>
                                                                    <div className="cp-profile-text">
                                                                        <p>{candidate.skypeId || 'N/A'}</p>
                                                                        {candidate.skypeId && (
                                                                            <Tooltip
                                                                                overlayClassName="fs-10"
                                                                                title="Copy candidate's skype id"
                                                                            >
                                                                                <Icon
                                                                                    component={CopyIcon}
                                                                                    rotate="180"
                                                                                    className="cp-copy-icon"
                                                                                    onClick={() =>
                                                                                        this.copyToClipboard(
                                                                                            candidate.skypeId,
                                                                                            'skype Id'
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {canIViewCTC &&
                                                                    candidate.ctcInfo &&
                                                                    candidate.ctcInfo.currentCTC && (
                                                                        <div className="cp-profile-row">
                                                                            <div className="cp-profile-label">
                                                                                Current CTC:
                                                                            </div>
                                                                            <div className="cp-profile-text">
                                                                                <p>
                                                                                    {
                                                                                        candidate.ctcInfo
                                                                                            .currentCTCCurrency
                                                                                    }{' '}
                                                                                    {ctcArray[1]} {ctcArray[2]}
                                                                                </p>

                                                                                <Tooltip
                                                                                    overlayClassName="fs-10"
                                                                                    title={CTCLockToolTipText}
                                                                                >
                                                                                    <Icon
                                                                                        className="cp-copy-icon"
                                                                                        type="lock"
                                                                                        theme="filled"
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                {canIViewCTC &&
                                                                    candidate.ctcInfo &&
                                                                    candidate.ctcInfo.expectedCTC && (
                                                                        <div className="cp-profile-row">
                                                                            <div className="cp-profile-label">
                                                                                Expected CTC:
                                                                            </div>
                                                                            <div className="cp-profile-text">
                                                                                <p>
                                                                                    {
                                                                                        candidate.ctcInfo
                                                                                            .expectedCTCCurrency
                                                                                    }{' '}
                                                                                    {ctcArray[4]} {ctcArray[5]}
                                                                                </p>

                                                                                <Tooltip
                                                                                    overlayClassName="fs-10"
                                                                                    title={CTCLockToolTipText}
                                                                                >
                                                                                    <Icon
                                                                                        className="cp-copy-icon"
                                                                                        type="lock"
                                                                                        theme="filled"
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                {
                                                                    <div className="cp-profile-row">
                                                                        <div className="cp-profile-label">
                                                                            Portfolio:
                                                                        </div>
                                                                        {candidate.otherDetails ? (
                                                                            <div className="cp-profile-text flex-column">
                                                                                {candidate.otherDetails
                                                                                    .split('\n')
                                                                                    .map((x, i) => (
                                                                                        <a
                                                                                            key={i}
                                                                                            href={x}
                                                                                            target="_blank"
                                                                                        >
                                                                                            {x}
                                                                                        </a>
                                                                                    ))}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="cp-profile-text">
                                                                                <p>N/A</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="cp-profile-row">
                                                                        <div className="cp-profile-label">
                                                                            LinkedIn:
                                                                        </div>
                                                                        {candidate.linkedIn ? (
                                                                            <div className="cp-profile-text">
                                                                                <a
                                                                                    onClick={() =>
                                                                                        window.open(
                                                                                            extractSocialsUsername(
                                                                                                candidate.linkedIn,
                                                                                                true
                                                                                            ).socialLink,
                                                                                            '_blank'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        extractSocialsUsername(
                                                                                            candidate.linkedIn
                                                                                        ).socialName
                                                                                    }
                                                                                </a>
                                                                                <Tooltip
                                                                                    overlayClassName="fs-10"
                                                                                    title="Copy candidate's linkedIn URL"
                                                                                >
                                                                                    <Icon
                                                                                        component={CopyIcon}
                                                                                        rotate="180"
                                                                                        className="cp-copy-icon"
                                                                                        onClick={() =>
                                                                                            this.copyToClipboard(
                                                                                                candidate.linkedIn,
                                                                                                'LinkedIn'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="cp-profile-text">
                                                                                <p>N/A</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="cp-profile-row">
                                                                        <div className="cp-profile-label">Github:</div>
                                                                        {candidate.github ? (
                                                                            <div className="cp-profile-text">
                                                                                <a
                                                                                    onClick={() =>
                                                                                        window.open(
                                                                                            extractSocialsUsername(
                                                                                                candidate.github,
                                                                                                true
                                                                                            ).socialLink,
                                                                                            '_blank'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        extractSocialsUsername(
                                                                                            candidate.github
                                                                                        ).socialName
                                                                                    }
                                                                                </a>
                                                                                <Tooltip
                                                                                    overlayClassName="fs-10"
                                                                                    title="Copy candidate's github URL"
                                                                                >
                                                                                    <Icon
                                                                                        component={CopyIcon}
                                                                                        rotate="180"
                                                                                        className="cp-copy-icon"
                                                                                        onClick={() =>
                                                                                            this.copyToClipboard(
                                                                                                candidate.github,
                                                                                                'Github'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="cp-profile-text">
                                                                                <p>N/A</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="cp-profile-row">
                                                                        <div className="cp-profile-label">Note:</div>
                                                                        {candidate.noteToTheHiringTeam ? (
                                                                            <div className="cp-profile-text">
                                                                                <p
                                                                                    style={{
                                                                                        color: '#8a94a6',
                                                                                        lineHeight: '1.4',
                                                                                        wordSpacing: '5px',
                                                                                    }}
                                                                                >
                                                                                    {candidate.noteToTheHiringTeam}
                                                                                </p>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="cp-profile-text">
                                                                                <p>N/A</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                }
                                                            </div>
                                                        ) : (
                                                            <EditForm
                                                                candidateForm={candidateForm}
                                                                candidate={candidate}
                                                                currentEmail={this.currentEmail}
                                                                onCancel={this.handleCancel}
                                                                saved={this.saveProfile}
                                                                handleInputFocus={this.setInputFocused}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="cp-bottom-icon">
                                                        <Icon
                                                            component={StarIcon}
                                                            onClick={this.markFavourite}
                                                            className={classNames({
                                                                'candidate-star': candidate.isFavourite,
                                                            })}
                                                        />
                                                        {accessLevel < 5 ? (
                                                            <Icon type="form" onClick={this.edtProfile} />
                                                        ) : null}
                                                    </div>
                                                    <div style={{ clear: 'both' }}></div>
                                                </div>
                                            )}
                                        </Panel>
                                    </Collapse>
                                </CandidateProfileCont>

                                <CandidateProfileCont>
                                    <Collapse
                                        className="cp-collapse"
                                        defaultActiveKey={['internalNotes']}
                                        expandIcon={({ isActive }) => (
                                            <Icon component={isActive ? CollapseIcon : ExpandIcon} />
                                        )}
                                    >
                                        <Panel header="Internal Notes:" key="internalNotes">
                                            {this.state.isLoading ? (
                                                <Skeleton
                                                    title={false}
                                                    paragraph={{
                                                        rows: 1,
                                                        width: '95%',
                                                    }}
                                                />
                                            ) : (
                                                notes.map((note, index) => {
                                                    const userName = teamMembers.find(mem => mem._id === note.addedBy)
                                                    note.userName = userName && userName.fullName
                                                    if (note.addedBy === this.props.userId) {
                                                        note.userName += '(You)'
                                                        note.isEditable = note.sourcingNote ? false : true
                                                    }

                                                    let visibility = [note.addedBy].concat(note.canView)
                                                    visibility = visibility.filter(x => x !== this.props.userId)

                                                    let visibilityMessage = 'Visible to you'
                                                    visibility.forEach(x => {
                                                        let noteUserName = teamMembers.find(mem => mem._id === x)
                                                        noteUserName =
                                                            noteUserName &&
                                                            (noteUserName.fullName || noteUserName.email)
                                                        visibilityMessage += `, ${noteUserName}`
                                                    })

                                                    visibilityMessage += ' admins and super admins.'

                                                    return (
                                                        <div key={note._id} className="cp-note-mb">
                                                            <NoteViewWrapper
                                                                key={note._id}
                                                                className="cp-internal-notes"
                                                            >
                                                                <div className="cp-notes-title">
                                                                    <div>
                                                                        {note.userName || 'User'} added a{' '}
                                                                        {note.private ? 'private' : 'public'} note
                                                                        <span className="cp-notes-time">
                                                                            {moment(note.updated).fromNow()}
                                                                        </span>
                                                                    </div>

                                                                    <div>
                                                                        {note.isEditable && !note.showDeleteBox && (
                                                                            <Icon
                                                                                className="cp-notes-icon"
                                                                                component={EditIcon}
                                                                                onClick={() => this.showNoteBox(note)}
                                                                            />
                                                                        )}
                                                                        {note.isEditable && !note.showDeleteBox && (
                                                                            <Icon
                                                                                className="cp-notes-icon"
                                                                                type="delete"
                                                                                theme="filled"
                                                                                onClick={() =>
                                                                                    this.deleteNoteToggle(index)
                                                                                }
                                                                            />
                                                                        )}
                                                                        {note.private && (
                                                                            <Popover
                                                                                overlayClassName="fs-10"
                                                                                title={visibilityMessage}
                                                                                arrowPointAtCenter
                                                                                placement="topRight"
                                                                            >
                                                                                <Icon
                                                                                    className="cp-notes-icon"
                                                                                    type="lock"
                                                                                    theme="twoTone"
                                                                                    twoToneColor="#D1D6D4"
                                                                                />
                                                                            </Popover>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <Paragraph rows={3} fontSize={18} fontColor={BLUE_GREY}>
                                                                    {note.note}
                                                                </Paragraph>
                                                                {note.sourcingNote ? (
                                                                    <div className="via-stp">
                                                                        <GreyInfoIcon />
                                                                        <span>Via Sourcing Talent Pool</span>
                                                                    </div>
                                                                ) : null}
                                                            </NoteViewWrapper>

                                                            {note.showDeleteBox && (
                                                                <NoteDeleteWrapper>
                                                                    <div>
                                                                        Are you sure you want to delete this note?
                                                                    </div>
                                                                    <Button
                                                                        size={'small'}
                                                                        type="primary"
                                                                        className="note-delete-btn"
                                                                        onClick={() => this.deleteNote(note._id, index)}
                                                                    >
                                                                        Yes
                                                                    </Button>
                                                                    <Button
                                                                        size={'small'}
                                                                        type="primary"
                                                                        className="note-delete-btn"
                                                                        onClick={() => this.deleteNoteToggle(index)}
                                                                    >
                                                                        Don't delete
                                                                    </Button>
                                                                </NoteDeleteWrapper>
                                                            )}
                                                        </div>
                                                    )
                                                })
                                            )}

                                            <Button
                                                type="primary"
                                                size="large"
                                                className="cp-notes-add-btn"
                                                ghost
                                                onClick={() => this.showNoteBox()}
                                            >
                                                <Icon component={AddIcon} className="cp-note-add-icon" />
                                                <span>Add Notes</span>
                                            </Button>
                                        </Panel>
                                    </Collapse>
                                </CandidateProfileCont>

                                {/*<CandidateProfileCont>
                             <Collapse className="cp-collapse" defaultActiveKey={['2']}
                             expandIcon={({ isActive }) => <Icon component={isActive ? CollapseIcon : ExpandIcon} />}
                             >
                             <Panel header="Summary:" key="2">
                             <p>{candidate.summary}</p>
                             </Panel>
                             </Collapse>
                             </CandidateProfileCont>
                             <CandidateProfileCont>
                             <Collapse className="cp-collapse" defaultActiveKey={['3']}
                             expandIcon={({ isActive }) => <Icon component={isActive ? CollapseIcon : ExpandIcon} />}
                             >
                             <Panel header="Skills:" key="3">
                             <div className="cp-skills-chip-block">
                             {candidate && candidate.skillSet && candidate.skillSet.length > 0 &&
                             candidate.skillSet.map((skill, index) => (
                             <Tag key={index}>{skill}</Tag>
                             ))
                             }
                             </div>
                             </Panel>
                             </Collapse>
                             </CandidateProfileCont>*/}
                                <CandidateProfileCont>
                                    <Collapse
                                        className="cp-collapse"
                                        defaultActiveKey={['resume']}
                                        expandIcon={({ isActive }) => (
                                            <Icon component={isActive ? CollapseIcon : ExpandIcon} />
                                        )}
                                    >
                                        <Panel
                                            header="Resume:"
                                            key="resume"
                                            extra={
                                                !this.state.resumeLoaderActive && (
                                                    <div
                                                        className="cp-update-resume-btn"
                                                        onClick={e => e.stopPropagation()}
                                                    >
                                                        <Popover
                                                            getPopupContainer={() =>
                                                                document.getElementById('cp-resume-menu')
                                                            }
                                                            content={
                                                                <Menu className="cp-option-cont" id="cp-resume-menu">
                                                                    <Menu.Item className="cp-options">
                                                                        <Dropzone onDrop={this.onResumeSelect}>
                                                                            {({ getRootProps, getInputProps }) => (
                                                                                <div {...getRootProps()}>
                                                                                    <input {...getInputProps()} />
                                                                                    <span className="cp-option-color">
                                                                                        {' '}
                                                                                        Update resume
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </Dropzone>
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        className="cp-options"
                                                                        onClick={() =>
                                                                            candidate.candidateResumeLink.includes(
                                                                                '.doc'
                                                                            )
                                                                                ? window.open(
                                                                                    `https://docs.google.com/viewerng/viewer?url=${candidate.candidateResumeLink}`,
                                                                                    '_blank'
                                                                                )
                                                                                : window.open(
                                                                                    `${candidate.candidateResumeLink}`,
                                                                                    '_blank'
                                                                                )
                                                                        }
                                                                    >
                                                                        <span className="cp-option-color">
                                                                            {' '}
                                                                            Open resume in new tab
                                                                        </span>
                                                                    </Menu.Item>
                                                                </Menu>
                                                            }
                                                        >
                                                            <Button
                                                                size="large"
                                                                type="default"
                                                                className="cp-resume-options"
                                                                icon="more"
                                                                id="cp-resume-menu"
                                                            ></Button>
                                                        </Popover>
                                                    </div>
                                                )
                                            }
                                        >
                                            {this.state.isLoading ? (
                                                <Spin className="spin-loader-center1" />
                                            ) : candidate && candidate.candidateResumeLink ? (
                                                <div className="full-wide">
                                                    {this.state.resumeLoaderActive && (
                                                        <Spin className="spin-loader-center1" />
                                                    )}
                                                    {this.state.resumeLoaderActive && (
                                                        <Button
                                                            className="new-tab"
                                                            onClick={() =>
                                                                candidate.candidateResumeLink.includes('.doc')
                                                                    ? window.open(
                                                                        `https://view.officeapps.live.com/op/embed.aspx?src=${candidate.candidateResumeLink}`,
                                                                        '_blank'
                                                                    )
                                                                    : window.open(
                                                                        `${candidate.candidateResumeLink}`,
                                                                        '_blank'
                                                                    )
                                                            }
                                                            type="primary"
                                                        >
                                                            Open in new tab <OpenInNewTabIcon />
                                                        </Button>
                                                    )}
                                                    {candidate.candidateResumeLink.includes('.doc') ? (
                                                        <iframe
                                                            title="resume"
                                                            key="resume-iframe-key"
                                                            onLoad={this.resumeLoadHandler}
                                                            className={
                                                                this.state.resumeLoaderActive
                                                                    ? 'display-none'
                                                                    : 'resume-preview'
                                                            }
                                                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${candidate.candidateResumeLink}`}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            title="resume"
                                                            key="resume-iframe-key"
                                                            onLoad={this.resumeLoadHandler}
                                                            className={
                                                                this.state.resumeLoaderActive
                                                                    ? 'display-none'
                                                                    : 'resume-preview'
                                                            }
                                                            src={`${candidate.candidateResumeLink}`}
                                                        />
                                                    )}
                                                </div>
                                            ) : (
                                                <NoResumeWrapper>
                                                    <div className="no-resume-text">Candidate resume is not added.</div>
                                                    <Dropzone onDrop={this.onResumeSelect}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <Button type="primary" className="cp-upload-resume-btn">
                                                                    Upload Resume
                                                                </Button>{' '}
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </NoResumeWrapper>
                                            )}
                                        </Panel>
                                    </Collapse>
                                </CandidateProfileCont>
                            </CandidateProfileContainer>
                        )}
                    </div>

                    <ScheduleInterview
                        visible={this.state.interviewModal}
                        candidate={candidate}
                        jobDetail={jobDetail}
                        isRescheduled={!!interview}
                        onCancel={this.handleCancel}
                        interview={interview}
                        onSchedule={interview => this.interviewSubmit(interview)}
                        isZoomEnabled={this.props.user.isZoomEnabled}
                    />

                    <Modal
                        type={MODAL_TYPES.ADD_FEEDBACK}
                        visible={!!(this.state.addFeedback && jobDetail)}
                        interviewId={interview && interview._id}
                        candidateId={candidateId}
                        candidateName={candidate && candidate.name}
                        jobId={jobDetail && jobDetail.jobId}
                        stageId={jobDetail && jobDetail.stageId}
                        onCancel={this.handleCancel}
                        onConfirm={this.handleFeedbackUpdate.bind(this.handleFeedbackUpdate, 'add')}
                    />

                    <Modal
                        type={MODAL_TYPES.ADD_NOTES}
                        visible={this.state.showNoteModal}
                        returnOnSubmit={true}
                        candidateId={candidateId}
                        candidateName={candidate.name}
                        jobApplicationId={jobDetail && jobDetail.jobAppId}
                        stageId={jobDetail && jobDetail.stageId}
                        note={this.state.selectedNote}
                        onCancel={this.handleCancel}
                        onConfirm={this.onNoteSubmit}
                    />

                    <Modal
                        type={MODAL_TYPES.UPDATE_CANDIDATE}
                        visible={this.state.showUpdateCandidateModal}
                        onCancel={this.handleCancel}
                        onConfirm={(value, jobPayload) => {
                            if (value.source === MODAL_TYPE.UNJOIN) this.activeApplication(value)
                            else this.updateCandidate(value, jobPayload)
                        }}
                        source={this.state.modalType}
                        selectionSize={1}
                        selected={{
                            [candidate._id]: {
                                archiveReason: jobDetail && jobDetail.archiveReason,
                                jobId: jobDetail && jobDetail.jobId,
                                jobApplicationId: jobDetail && jobDetail.jobAppId,
                                stageId: jobDetail && jobDetail.stageId,
                                stageName: jobDetail && jobDetail.stageName,
                                jobTitle: jobDetail && jobDetail.jobTitle,
                                jobStatus: jobDetail && jobDetail.jobStatus,
                                status: jobDetail && jobDetail.jobStatus === 0 ? 0 : candidate.status,
                            },
                        }}
                    />

                    <Modal
                        type={MODAL_TYPES.UNARCHIVE_CANDIDATE}
                        visible={this.state.showUnarchiveModal}
                        onCancel={this.handleCancel}
                        onConfirm={this.moveAndUnarchiveCandidate}
                    />

                    <Modal
                        type={MODAL_TYPES.ARCHIVE_REASON}
                        visible={this.state.archiveReasonModel}
                        onCancel={this.handleCancel}
                        onConfirm={(data, sendEmailToCandidate) => this.archiveApplication(data, sendEmailToCandidate)}
                        callInProgress={this.state.clickedOnce}
                    />

                    <Modal
                        type={MODAL_TYPES.UPDATE_CANDIDATE_SOURCING}
                        visible={this.state.showUpdateCandidateSourceModal}
                        onCancel={this.handleCancel}
                        onConfirm={this.handleUpdatedSource}
                        source={jobDetail && jobDetail.source}
                    />

                    <Modal
                        visible={this.state.noJobAssigned}
                        type={MODAL_TYPES.ASSOCIATE_OPENING_NOTE}
                        onCancel={this.handleCancel}
                        onConfirm={() =>
                            this.setState({
                                noJobAssigned: false,
                                showUpdateCandidateModal: true,
                                modalType: MODAL_TYPE.MOVE_JOB,
                            })
                        }
                    />

                    <Modal
                        type={MODAL_TYPES.WARNING_CANDIDATE_EMAIL_MISSING}
                        visible={this.state.noEmailPresent}
                        onConfirm={this.handleCancel}
                        redirect={false}
                    />

                    <Modal
                        type={MODAL_TYPES.CONFIRM_REJECT_CANDIDATE}
                        visible={this.state.rejectCandidateModal}
                        onCancel={this.handleCancel}
                        onConfirm={this.rejectCandidateConfirm}
                        singleCandidate={true}
                        candidateName={candidate.name || 'the Candidate'}
                    />

                    <Modal
                        type={MODAL_TYPES.CONFIRM_JOIN_CANDIDATE}
                        visible={this.state.showJoinedConfirmation}
                        onConfirm={() => this.joinCandidate(candidateJobStages)}
                        onCancel={this.handleCancel}
                        candidateName={candidate.name}
                    />

                    <Modal
                        type={MODAL_TYPES.ARCHIVED_OPENING_NOTE}
                        visible={this.state.showJobArchivedPopup}
                        onConfirm={this.handleCancel}
                    />

                    <Modal
                        type={MODAL_TYPES.ADD_TAG}
                        visible={this.state.showAddTag}
                        selectedCandidates={[{ ...candidate, tags: [...this.state.tags] }]}
                        updateTags={data => {
                            this.setState({ showAddTag: false })
                            this.updateTags(data)
                        }}
                        onCancel={() => this.setState({ showAddTag: false })}
                    />
                    <Modal
                        type={MODAL_TYPES.MANAGE_TAG}
                        visible={this.state.showManageTag}
                        selectedCandidates={[{ ...candidate, tags: [...this.state.tags] }]}
                        updateTags={data => {
                            this.setState({ showManageTag: false })
                            this.updateTags(data)
                        }}
                        onCancel={() => this.setState({ showManageTag: false })}
                    />
                </div>
            </HotKeys>
        )
    }
}

const mapStateToProps = state => {
    const { Auth, Mixed, Job } = state
    return {
        teamMembers: Mixed.teamMembers,
        allowShortcuts: Auth.user.allowShortcuts,
        allJobs: Job.allJobs,
        userId: Auth.user.id,
        accessLevel: Auth.user.accessLevel,
        accessibleJobs: Auth.user.accessibleJobs,
        user: Auth.user,
    }
}

export default connect(
    mapStateToProps,
    { UpdateResume }
)(CandidateProfile)
