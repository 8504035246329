import React, { useState, useEffect } from 'react'
import OpenInNewTabIcon from '../../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/OpenInNewTab'
import { Button, Icon, Drawer } from 'antd'

const ResumeDrawer = ({ resumeLink, onClose }) => {
    if (!resumeLink) return null
    return (
        <Drawer
            placement="left"
            closable={false}
            onClose={onClose}
            visible={!!resumeLink}
            width={'50%'}
            bodyStyle={{ padding: 0 }}
            zIndex={1500}
            destroyOnClose={true}
        >
            <Button
                className="new-tab"
                onClick={() =>
                    resumeLink.includes('.doc')
                        ? window.open(`https://docs.google.com/viewerng/viewer?url=${resumeLink}`, '_blank')
                        : window.open(`${resumeLink}`, '_blank')
                }
                type="primary"
                size="large"
            >
                Open in new tab <Icon component={OpenInNewTabIcon} />
            </Button>

            {resumeLink.includes('.doc') ? (
                <iframe
                    title="resume"
                    className="resume-preview"
                    style={{ width: '100%', height: '99.5vh' }}
                    src={`https://docs.google.com/gview?url=${resumeLink}&embedded=true`}
                ></iframe>
            ) : (
                <iframe
                    title="resume"
                    className="resume-preview"
                    style={{ width: '100%', height: '100vh' }}
                    src={`${resumeLink}`}
                ></iframe>
            )}
        </Drawer>
    )
}

export default ResumeDrawer
