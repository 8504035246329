import { actions } from './actions'
import _ from 'lodash'
import { PURGE } from 'redux-persist'
import moment from 'moment'
import { REFINE_DAYS } from '../../container/Helpers/constants'

const initState = { interviews: null, dataCount: {}, callInProgress: false, currentPage: 0, hasNextPage: false }

export default function todoReducer(state = initState, action) {
    let { interviews, dataCount } = state
    let interviewsClone = _.cloneDeep(interviews)
    let dataCountClone = _.cloneDeep(dataCount)

    let { data, page, progress } =
        action.hasOwnProperty('payload') && action.payload !== undefined ? action.payload : {}

    switch (action.type) {
        case PURGE:
            return initState
        case actions.GET_INTERVIEWS:
            //#region Get Interviews
            if (page) {
                interviews =
                    page === 0
                        ? { ..._.mapKeys(data.interviews, '_id') }
                        : { ...interviews, ..._.mapKeys(data.interviews, '_id') }
            } else {
                interviews = { ..._.mapKeys(data.interviews, '_id') }
            }

            return { ...state, interviews, currentPage: parseInt(data.currentPage), hasNextPage: data.hasNextPage }
        //#endregion
        case actions.SET_TOTAL_COUNTS:
            //#region Set Total Counts
            const { all: allCount, byMe: byMeCount, forMe: forMeCount } = data
            dataCount = { ...dataCount, all: allCount, byMe: byMeCount, forMe: forMeCount }
            return { ...state, dataCount }
        //#endregion
        case actions.SET_CALL_IN_PROGRESS:
            //#region Set Call in progress
            return { ...state, callInProgress: progress }
        //#endregion
        case actions.UPDATE_INTERVIEW:
            //#region Update Interview
            const { interview: curInt } = data
            const oldInt = interviewsClone[curInt._id]
            const { type, refineType, filter, userId } = action.payload
            let updateInterview = true
            //* Check if the interview falls under `forMe` or `byMe`, if it doesnt then need to remove from list
            switch (type) {
                case 'forMe':
                    if (curInt.interviewers && !curInt.interviewers.find(each => each._id === userId))
                        updateInterview = false
                    break
                case 'byMe':
                    if (curInt.assignee._id !== userId) updateInterview = false
                    break
                default:
            }
            if (!updateInterview) {
                interviewsClone = _.omit(interviewsClone, curInt._id)
                return { ...state, interviews: interviewsClone }
            }

            //* Check if the interview falls under atleast 1 of the selected filters AND if rescheduled selected, then the interview is rescheduled.
            //* If it doesnt then needs to be removed from list.
            const filters = Object.keys(filter).filter(key => filter[key])
            let showInterview = []
            for (const _filter in filters) {
                switch (_filter) {
                    case 'upcoming':
                        if (
                            (curInt.status === 'pending' && moment().isAfter(moment(curInt.interviewStartTime))) ||
                            curInt.status !== 'pending'
                        )
                            showInterview.push(false)
                        else showInterview.push(true)
                        break
                    case 'feedbackPending':
                        if (
                            (curInt.status === 'pending' &&
                                moment().isSameOrBefore(moment(curInt.interviewStartTime))) ||
                            curInt.status !== 'pending'
                        )
                            showInterview.push(false)
                        else showInterview.push(true)
                        break
                    case 'rescheduled':
                        if (!curInt.isRescheduled) updateInterview = false
                        break
                    case 'canceled':
                    case 'completed':
                        if (!curInt.status !== _filter) showInterview.push(false)
                        else showInterview.push(true)
                        break
                    default:
                }
            }

            if ((showInterview.length > 0 && !showInterview.includes(true)) || !updateInterview) {
                interviewsClone = _.omit(interviewsClone, curInt._id)
                return { ...state, interviews: interviewsClone }
            }

            //* Check if the interview falls in the refine type selected. If it doesnt then remove it from list.
            switch (refineType.key) {
                case REFINE_DAYS.TODAY.key:
                    if (!moment().isSame(moment(curInt.interviewStartTime), 'day')) updateInterview = false
                    break
                case REFINE_DAYS.TOMORROW.key:
                    if (
                        !moment()
                            .add(1, 'day')
                            .isSame(moment(curInt.interviewStartTime), 'day')
                    )
                        updateInterview = false
                    break
                case REFINE_DAYS.WEEK.key:
                    if (
                        !moment(curInt.interviewStartTime).isBetween(
                            moment().startOf('isoWeek'),
                            moment().endOf('isoWeek'),
                            '[]'
                        )
                    )
                        updateInterview = false
                    break
                case 'CUSTOM':
                    if (
                        (!moment(curInt.interviewStartTime).isBetween(moment(refineType.start), moment(refineType.end)),
                        '[]')
                    )
                        updateInterview = false
                    break
                default:
            }

            if (!updateInterview) {
                interviewsClone = _.omit(interviewsClone, curInt._id)
                return { ...state, interviews: interviewsClone }
            } else {
                interviewsClone = { ...interviewsClone, [curInt._id]: curInt }
            }

            const { status: oStatus, isRescheduled: oIsRescheudled, interviewStartTime: oInterviewTime } = oldInt
            const { status: cStatus, isRescheduled: cIsRescheudled } = curInt

            if (cIsRescheudled && !oIsRescheudled) dataCountClone[type].rescheduled++

            // * Update the counts appropriately even if we are removing the interview.
            if (oStatus === 'canceled' && cStatus === 'pending') {
                dataCountClone[type].upcoming++
                dataCountClone[type].canceled--
            } else if (oStatus === 'completed' && cStatus === 'pending') {
                dataCountClone[type].upcoming++
                dataCountClone[type].completed--
            } else if (oStatus === 'pending' && cStatus === 'canceled') {
                dataCountClone[type].canceled++
                if (moment().isAfter(moment(oInterviewTime))) dataCountClone[type].feedbackPending--
                else dataCountClone[type].upcoming--
            } else if (oStatus === 'pending' && cStatus === 'completed') {
                if (moment().isAfter(moment(oInterviewTime))) dataCountClone[type].feedbackPending--
                else dataCountClone[type].upcoming--
            } else if (oStatus === 'pending' && cStatus === 'pending') {
                if (moment().isAfter(moment(oInterviewTime))) {
                    dataCountClone[type].feedbackPending--
                    dataCountClone[type].upcoming++
                }
            }

            return { ...state, interviews: interviewsClone, dataCount: dataCountClone }
        //#endregion
        case actions.FEEDBACK_ADDED:
            //#region Feedback added
            const _interview = interviewsClone[action.payload.interview._id]

            _interview['hasFeedback'] = true

            //* We update the status only if the user adding the feedback is one of the interviewers.
            if (_interview.interviewers.find(each => each._id === action.payload.userId)) {
                const { type, filter } = action.payload
                const filters = Object.keys(filter).filter(key => filter[key])
                switch (_interview.status) {
                    case 'completed':
                        break
                    case 'canceled':
                        dataCountClone[type].canceled--
                        dataCountClone[type].completed++
                        break
                    default:
                        if (moment().isAfter(_interview.interviewStartTime)) {
                            dataCountClone[type].feedbackPending--
                            dataCountClone[type].completed++
                        } else {
                            dataCountClone[type].upcoming--
                            dataCountClone[type].completed++
                        }
                }

                //* If the filters selected has completed or none of the filters are selected, then we need to update interview status
                //* Else we remove the interview from the list.
                if (filters.length === 0 || (filters.length > 0 && filters.includes('completed'))) {
                    _interview.status = 'completed'
                    interviewsClone = { ...interviewsClone, [action.payload.interview._id]: _interview }
                } else {
                    interviewsClone = _.omit(interviewsClone, _interview._id)
                }
            } else interviewsClone = { ...interviewsClone, [action.payload.interview._id]: _interview }

            return { ...state, interviews: interviewsClone, dataCount: dataCountClone }
        //#endregion
        default:
            return { ...state }
    }
}
