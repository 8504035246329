import config from '../../../config'
import { store } from '../../../redux/store'
import apiCaller from '../../apiCaller'
import { warning } from '../../../services/Notification'
import ajaxCaller from '../../apiCaller'
import actionsCandidate from '../../../redux/candidate/actions'
import { FILE_STATUS } from '../../../container/Helpers/constants'
import { CANDIDATE } from '../../../container/Helpers/RegEx'
const { restricted } = apiCaller.type
const { formatError } = apiCaller
const axios = ajaxCaller.wrappers.axios
const { baseURL, apiVersion } = config.config()
const API_VERSION = apiVersion.v2
const baseUrl_V2 = `${baseURL}${API_VERSION}me/`
const baseUrl = 'candidate/'

export const GetCandidatesEmail = ({ email, companyId, token = null, jobId = null }) => async dispatch => {
    try {
        // * If the token is not passed from the calling function, then we assume its internal request.
        if (token) {
            // * On the Apply now page, we need set a public token.
            const url = `${baseUrl_V2}${baseUrl}emails/${companyId}`
            let { data } = await axios.post(
                url,
                { email: email.toLowerCase(), jobId },
                { headers: { 'Content-Type': 'application/json', Authorization: token } }
            )
            return data
        } else {
            // * For internal platform requests, the token is set based on the user logged in.
            let { data } = await restricted.post(`${baseUrl}emails/${companyId}`, { email: email.toLowerCase() })
            return data
        }
    } catch (err) {
        warning(formatError(err))
    }
}

export const markAsReadMessage = candidateId => {
    try {
        const url = `${baseUrl_V2}${baseUrl}message/${candidateId}`

        restricted.get(url)
    } catch (err) {
        warning(formatError(err))
    }
}

export const EditCandidate = (details, companyId, candidateId) => async dispatch => {
    try {
        let { data } = await restricted.patch(`${baseUrl}${companyId}/candidate/${candidateId}`, details)
        return data
    } catch (err) {
        warning(formatError(err))
    }
}
export const RejectMultipleCandidatesWithJob = (input, companyId) => async dispatch => {
    try {
        let { data } = await restricted.patch(`${baseUrl}${companyId}/rejectMultiple`, input)
        if (data.error) {
            warning(data.error)
        }
        return data
    } catch (err) {
        formatError(err)
    }
}
export const AddCandidateWithJob = info => async dispatch => {
    try {
        let { data } = await restricted.post(`${baseUrl}formValues`, { candidates: info })
        if (data.error) {
            dispatch({ type: actionsCandidate.EMIT_DUPLICATE_ERROR, payload: data.payload })
            warning(data.error)
        }

        return data
    } catch (err) {
        warning(formatError(err))

        if (err.response.data.payload) {
            dispatch({ type: actionsCandidate.EMIT_DUPLICATE_ERROR, payload: err.response.data.payload })
        }

        return false
    }
}

export const UpdateResume = (data, candidateId, companyId) => async dispatch => {
    try {
        const url = `${baseUrl_V2}${baseUrl}${companyId}/updateResume/${candidateId}`
        const result = await axios.post(url, data, {
            headers: {
                Authorization: store.getState().Auth.idToken,
                'Content-Type': 'multipart/form-data',
            },
        })
        return result
    } catch (err) {
        throw err
    }
}
export const GetResumeDetails = (
    { uploadFile, fileName, uploadId, jobId, stageId, hasJobId, isNewUpload = true },
    publicToken
) => async dispatch => {
    try {
        let authToken
        if (publicToken) {
            authToken = publicToken
        } else {
            authToken = store.getState().Auth.idToken
        }
        const url = `${baseUrl_V2}${baseUrl}basicResume`

        if (isNewUpload)
            dispatch({
                type: actionsCandidate.ADD_CANDIDATES,
                payload: {
                    uploadId,
                    uploadStatus: FILE_STATUS.UPLOADING,
                    uploadFile,
                    fileName,
                    jobId,
                    stageId,
                    hasJobId,
                },
            })
        await axios
            .post(url, uploadFile, {
                headers: {
                    Authorization: authToken,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(res =>
                dispatch({
                    type: actionsCandidate.UPDATE_CANDIDATE,
                    payload: serialiseResumeUploadResult({ uploadId, fileName }, res, { jobId, stageId }),
                })
            )
            .catch(error =>
                dispatch({
                    type: actionsCandidate.UPDATE_CANDIDATE,
                    payload: serialiseResumeUploadResult({ uploadId, fileName }, { error }),
                })
            )
        return true
    } catch (err) {
        throw err
    }
}

export const UploadCSVFile = data => async dispatch => {
    try {
        const url = `${baseUrl_V2}import/excel`
        const result = await axios.post(url, data, {
            headers: {
                Authorization: store.getState().Auth.idToken,
                'Content-Type': 'multipart/form-data',
            },
        })
        return result
    } catch (err) {
        throw err
    }
}

export const ImportCSVFile = data => async dispatch => {
    try {
        const url = `${baseUrl_V2}import/start`
        const result = await axios.post(url, data, {
            headers: {
                Authorization: store.getState().Auth.idToken,
                'Content-Type': 'application/json',
            },
        })
        return result
    } catch (err) {
        throw err
    }
}

export const GetImportStatus = () => async dispatch => {
    try {
        const result = await restricted.get(`${baseUrl_V2}import/status`)
        return result
    } catch (err) {
        throw err
    }
}

// update only what is needed.
const serialiseResumeUploadResult = ({ uploadId, fileName }, response, jobData) => {
    const defaultPayload = {
        name: '',
        email: '',
        phone: { code: '', number: '' },
        uploadId,
        fileName,
        uploadStatus: FILE_STATUS.SUCCESS,
        hasErrors: false,
        errors: {},
        ...(jobData && jobData.jobId && jobData.stageId && { ...jobData }),
    }
    const { data, status, error } = response
    if (status === 200) {
        const phone = data[0].candidateDetail.phone[0]
        let code = null
        let phoneNumber = null
        if (phone) {
            const splitPhone = phone.split(' ')
            if (splitPhone.length > 1) {
                code = splitPhone[0]
                if (!code.includes('+')) {
                    code = '+' + code
                }
                phoneNumber = splitPhone[1]
            } else {
                phoneNumber = splitPhone[0]
            }
        }
        return {
            ...defaultPayload,
            resumeLink: (data[0] && data[0].resumeLink) || '',
            ...(data[0] &&
                data[0].candidateDetail && {
                    name: data[0].candidateDetail.name || '',
                    resumeId: data[0].candidateDetail.resume_id || '',
                    email: data[0].candidateDetail.email[0] || '',
                    phone: {
                        code: code || '',
                        country: '',
                        number: phoneNumber || '',
                    },
                    linkedIn: data[0].candidateDetail.linkedIn || '',
                    github: data[0].candidateDetail.github || '',
                    skypeId: data[0].candidateDetail.skypeId || '',
                    designation: data[0].candidateDetail.designation || '',
                    totalExperience: data[0].candidateDetail.totalExperience || '',
                    currentCompanyName: data[0].candidateDetail.company || '',
                    currentLocation: data[0].candidateDetail.currentLocation || '',
                }),
        }
    }

    return {
        ...defaultPayload,
        uploadStatus: error ? FILE_STATUS.FAILED : FILE_STATUS.UPLOADING,
        hasErrors: true,
        errors: {
            ...(error ? { resume: error.response && error.response.data && error.response.data.error.msg } : {}),
        },
    }
}
