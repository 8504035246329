import React from 'react'
import { LOGIN_TEXT } from '../constants'
import { Button } from '../style'

export const StartedButton = ({ isLoggedIn, top = false, accessLevel }) => {
    const redirectLocation = isLoggedIn ? (accessLevel < 5 ? 'openings' : 'dashboard') : 'signup/'
    // To Hide/Show the GET STARTED botton
    const pagePath = window.location.pathname
    let isToBeShown =
        pagePath !== '/signin/' && pagePath !== '/signin' && pagePath !== '/signup/' && pagePath !== '/signup'
    if (pagePath.includes('/signup/') || pagePath.includes('/signup')) {
        isToBeShown = false
    }
    return isToBeShown ? (
        <Button top={top} onClick={() => window.open(redirectLocation, '_self')}>
            {isLoggedIn ? LOGIN_TEXT : 'GET STARTED'}
        </Button>
    ) : null
}

export const DemoButton = ({ type = 'inverted' }) => (
    <a href="https://calendly.com/ishika-jani/30min">
        <Button secondary={type === 'inverted'}>REQUEST DEMO</Button>
    </a>
)
