import React from 'react'
import './style.css'
import { SignupWrapper, LogoWrapper, RecruitWrapper } from './style.js'

export default function LeftSideWrapper(props) {
    return (
        <div className="left-blocks">
            <div className="block-left-top">
                <div className="block-element block-e-1">
                    <LogoWrapper/>
                </div>
                <div className="block-element block-e-2"></div>
                <div className="block-element block-e-3"></div>
            </div>

            <div className="block-left-middle">
                <div className="block-last-left">
                    <div className="block-element block-e-4"></div>
                    <div className="block-element block-e-5"></div>
                    <div className="block-element block-e-6"></div>
                </div>

                <div className="block-form-holder">
                    <div className="block-element block-e-7">
                        <SignupWrapper>{props.children}</SignupWrapper>
                    </div>
                </div>

                <div className="block-left-middle-right">
                    <div className="block-element block-e-8"></div>
                    <div className="block-element block-e-9"></div>
                </div>
            </div>

            <div className="block-left-bottom">
                <div className="block-element block-e-10"></div>
                <div className="block-element block-e-11"></div>
                <div className="block-element block-e-12"></div>
            </div>
        </div>
    )
}
