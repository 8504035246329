import styled from 'styled-components'
import { FlexCenterRow, DARK_BLUE } from '../../../../Helpers/Stylesheet/styledComponents'

export const UserEditPass = styled.div`
    span {
        font-size: 12px;
        color: ${DARK_BLUE};
        font-weight: normal;
        font-family: 'SFProDisplay-Light';
        letter-spacing: 0.6px;
        cursor: pointer;
        img {
            margin-right: 5px;
        }
        &:last-child {
            margin-left: 30px;
        }
    }
`
export const UserDetails = styled.div`
    .userLabelsValues {
        display: flex;
        align-content: center;
        align-items: center;
        margin-bottom: 18px;
        &:last-child {
            margin-bottom: 0;
        }
        .userLabels {
            display: flex;
            align-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.25;
            letter-spacing: normal;
            color: #999999;
            width: 165px;
            margin-right: 28px;
            &:last-child {
                margin-bottom: 0;
            }

            img {
                margin-left: 5px;
            }
        }
        .userDetailsAL {
            height: 26px;
            border-radius: 5px;
            background-color: $solitude-grey;
            color: $manatee-grey;
            text-align: center;
            font-size: 12px;
            line-height: 26px;
            padding: 0 7px;
            margin-left: 12px;
        }
        input {
            width: 250px;
            line-height: 42px;
            border-radius: 4px;
            border: solid 1px #e1e4e8;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.25;
            color: #666666;
            padding: 10px;
        }
        a {
            color: ${DARK_BLUE};
        }
        .manageNotification {
            display: flex;
            align-items: center;
            width: 185px;
            height: 20px;
            .manageNotificationLabel {
                cursor: pointer;
                margin-left: 10px;
                color: ${DARK_BLUE};
            }
        }
    }
    .userInputField {
        width: 250px;
    }
    .userLabelsValues {
        margin-left: 28px;
        color: #666;
        input {
            line-height: 1.25;
            padding: 0 12px;
            margin: 0;
        }
    }
`
