import React, { Component } from 'react'
import { Button, Col, Row, Typography, Upload, Spin, Icon } from 'antd'
import { Container, NoHeaderWrapper } from '../style'
import { store } from '../../../../redux/store'
import config from '../../../../config'
import { warning } from '../../../../services/Notification'
import { COMPANY_LOGO } from '../DataConfigs'

const { Text } = Typography

const { baseURL: apiUrl, apiVersion } = config.config()
const apiType = {
    restricted: 'me/',
    public: '',
}

export default class CompanyLogo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            image_url:
                (props.company.logo_url &&
                    props.company.logo_url.length > 0 && [
                        {
                            uid: '19',
                            status: 'done',
                            name: props.company.logo_url
                                .split('/')
                                .slice(-1)[0]
                                .replace(/\d+_/g, ''),
                            url: props.company.logo_url,
                        },
                    ]) ||
                [],
            uploading: false,
        }
    }

    removeLogo = () => {
        this.setState({ image_url: [] })
        this.props.onSubmit({ logo_url: '' }, 0, 'company')
    }

    uploadLogo = ({ file, fileList }) => {
        if (file.status === 'done') {
            let { image_url } = this.state
            image_url[0] = {
                uid: file.uid,
                status: file.status,
                name: file.name,
                url: file.response.url,
            }
            this.props.onSubmit({ logo_url: file.response.url }, 0, 'company')
            this.setState({ uploading: false, image_url })
        } else if (file.status === 'uploading') {
            this.setState({ uploading: true })
        }
    }

    beforeUpload = file => {
        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
            this.setState({ uploading: false })
            warning(COMPANY_LOGO.IMAGE_TYPE)
            return false
        }
        const isLt8M = file.size / 1024 / 1024 < 8
        if (!isLt8M) {
            warning('Please ensure the Logo is of type png, jpeg or jpg.')
            return false
        }
        return true
    }

    render() {
        const { companyId } = store.getState().Auth.user
        const { image_url, uploading } = this.state
        const apiURL_v2 = apiUrl + apiVersion.v2 + apiType.restricted + `career-page/${companyId}/upload/image` // TODO: UPDATE THIS
        const uploadIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

        const props = {
            name: 'image',
            action: apiURL_v2,
            headers: {
                authorization: store.getState().Auth.idToken,
            },
            onChange: this.uploadLogo,
            multiple: false,
            showUploadList: false,
            accept: '.jpg, .png, .jpeg',
            defaultFileList: image_url,
            className: 'company-logo-upload',
            beforeUpload: this.beforeUpload,
        }
        return (
            <Container>
                <Text className="carrer-cont-title">COMPANY LOGO*</Text>
                <Row gutter={48}>
                    <Col span={12}>
                        <NoHeaderWrapper>
                            {image_url.length === 0 && !uploading ? (
                                <div className="no-header-text">Upload your logo</div>
                            ) : uploading ? (
                                <div className="no-header-text">{<Spin indicator={uploadIcon} />}</div>
                            ) : (
                                <div className="no-header-text">
                                    <div>
                                        <a
                                            className="career-header-link"
                                            href={image_url[0].url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {image_url[0].name}
                                        </a>
                                    </div>
                                    <div className="career-header-close">
                                        <Icon onClick={this.removeLogo} type="close-circle" />
                                    </div>
                                </div>
                            )}
                            <Upload {...props}>
                                <div className="no-header-btn">
                                    <Button type="primary">Upload</Button>
                                </div>
                            </Upload>
                        </NoHeaderWrapper>
                    </Col>
                </Row>
            </Container>
        )
    }
}
