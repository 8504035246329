import React, { useState, useEffect } from 'react'
import { Dropdown, Icon, Menu, Checkbox } from 'antd'
import { ActionsCard, CTA, SubTitle } from '../style'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { COUNTDATA_REVERSE_MAP, EVENTS, FILTER_TYPES } from '../constants'
import { Spacer } from '../style'
import { MODAL_TYPES } from '../../../Helpers/ModalTypes'
import { Modal } from '../../../Modals'
import { MODAL_TYPE } from '../../TalentPool/constants'

const BulkBox = ({
    bulkModeOff,
    bulkModeOn,
    bulkMode,
    selectedCandidate,
    selectionSize,
    countData,
    bulkEventManager,
    mainFilter,
    applicationSize,
    selectCandidate,
}) => {
    const [modalType, setModalType] = useState(null)
    const handleJobChange = (result, { jobId, stageId }) => {
        const payload = {
            jobId,
            stageId,
        }
        setModalType(null)
        bulkEventManager(EVENTS.ADD_JOB, payload)
    }
    const updateTags = data => {
        bulkEventManager(EVENTS.UPDATE_TAG, data)
        setModalType(null)
    }
    const menu = (
        <Menu>
            {mainFilter !== FILTER_TYPES.ASSIGNED && (
                <Menu.Item key="1" onClick={() => setModalType(MODAL_TYPE.MOVE_JOB)}>
                    Add to Openings
                </Menu.Item>
            )}
            <Menu.Item key="2" onClick={() => bulkEventManager(EVENTS.HIDE_CANDIDATE)}>
                {mainFilter === FILTER_TYPES.HIDDEN ? 'Unhide Candidate' : 'Hide Candidate'}
            </Menu.Item>
            {mainFilter !== FILTER_TYPES.FAVOURITE && (
                <Menu.Item key="3" onClick={() => bulkEventManager(EVENTS.MARK_FAV)}>
                    Mark Favorite
                </Menu.Item>
            )}
            <Menu.Item key="4" onClick={() => setModalType(MODAL_TYPES.ADD_TAG)}>
                Add Tag
            </Menu.Item>
            <Menu.Item key="5" onClick={() => setModalType(MODAL_TYPES.MANAGE_TAG)}>
                Manage Tag
            </Menu.Item>
        </Menu>
    )

    if (bulkMode && applicationSize)
        return (
            <>
                <Spacer />
                <ActionsCard>
                    <div className="action-block">
                        <span>
                            {selectionSize
                                ? `${selectionSize} Candidates Selected`
                                : 'Please select the candidates to make the actions'}
                        </span>
                        <div className="buttons">
                            <Dropdown overlay={menu} disabled={!selectionSize}>
                                <CTA type="primary" disabled={!selectionSize}>
                                    Bulk action <Icon component={DownIcon} />
                                </CTA>
                            </Dropdown>
                            <CTA onClick={() => bulkModeOff()}>Cancel</CTA>
                        </div>
                    </div>

                    <Checkbox
                        onChange={({ target: { checked } }) => selectCandidate({ action: 'ALL', checked })}
                        className="bolder"
                        checked={selectionSize === applicationSize}
                    >
                        Select all candidates
                    </Checkbox>
                </ActionsCard>

                <Modal
                    type={MODAL_TYPES.UPDATE_CANDIDATE}
                    visible={modalType === MODAL_TYPE.MOVE_JOB}
                    onConfirm={handleJobChange}
                    onCancel={() => setModalType(null)}
                    selectionSize={1}
                    selected={{ ignored: 'ignored' }}
                    source={modalType}
                />
                <Modal
                    type={MODAL_TYPES.ADD_TAG}
                    visible={modalType === MODAL_TYPES.ADD_TAG}
                    selectedCandidates={Object.values(selectedCandidate)}
                    updateTags={updateTags}
                    onCancel={() => setModalType(null)}
                />
                <Modal
                    type={MODAL_TYPES.MANAGE_TAG}
                    visible={modalType === MODAL_TYPES.MANAGE_TAG}
                    selectedCandidates={Object.values(selectedCandidate)}
                    updateTags={updateTags}
                    onCancel={() => setModalType(null)}
                />
            </>
        )
    else
        return (
            <SubTitle sticky>
                <span>{countData[COUNTDATA_REVERSE_MAP[mainFilter]]} candidates</span>
                {applicationSize ? <a onClick={bulkModeOn}>Bulk actions</a> : null}
            </SubTitle>
        )
}

export default BulkBox
