import React from 'react'

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 50 44"
            height="40"
            width="50"
        >
            <defs>
                <path
                    id="a"
                    d="M5.128 0h39.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v31.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512C.186 39.302 0 38.655 0 36.872V5.128c0-1.783.186-2.43.534-3.082A3.635 3.635 0 012.046.534C2.698.186 3.345 0 5.128 0z"
                />
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-1141 -105) translate(1141 105)">
                    <mask fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <use fill="#FFF" fillOpacity="0" xlinkHref="#a" />
                </g>
                <path
                    fill="#4767A0"
                    stroke="#4767A0"
                    d="M49.5 43.5V.5H5.128C3.578.5 2.94.623 2.282.975A3.135 3.135 0 00.975 2.282C.623 2.941.5 3.578.5 5.128v33.744c0 1.55.123 2.187.475 2.846a3.135 3.135 0 001.307 1.307c.659.352 1.296.475 2.846.475H49.5z"
                    transform="translate(-1141 -105) translate(1141 105)"
                />
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    stroke="#FFF"
                    strokeWidth="2"
                    d="M22.587 31.528a.993.993 0 01-.993.993h-9.6a.993.993 0 01-.994-.993V11.993c0-.548.445-.993.993-.993h9.601c.549 0 .993.445.993.993v19.535zm16.413 0a.993.993 0 01-.993.993h-9.601a.993.993 0 01-.993-.993V11.993c0-.548.444-.993.993-.993h9.6c.55 0 .994.445.994.993v19.535z"
                    transform="translate(-1141 -105) translate(1141 105)"
                />
            </g>
        </svg>
    )
}

export default Icon
