import React from 'react'

const SVGComponent = () => (
    <svg width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g id="Candidate-Profile---Messages---Thread-view" transform="translate(-1114 -362)" fill="#8E8E93">
                <g id="Messages" transform="translate(326 202)">
                    <g id="Minimised-View" transform="translate(40 111)">
                        <g id="Group" transform="translate(30 20)">
                            <g id="Attachment-Icon" transform="translate(718 29)">
                                <path
                                    d="M12.3627907,1.17209302 C11.3023256,0.111627907 9.57209302,0.111627907 8.51162791,1.17209302 L2.79069767,6.92093023 C2.14883721,7.5627907 2.14883721,8.59534884 2.79069767,9.2372093 C3.43255814,9.87906977 4.46511628,9.87906977 5.10697674,9.2372093 L8.65116279,5.66511628 C8.8744186,5.44186047 8.8744186,5.10697674 8.65116279,4.88372093 C8.42790698,4.66046512 8.09302326,4.66046512 7.86976744,4.88372093 L4.29767442,8.42790698 C4.10232558,8.62325581 3.76744186,8.62325581 3.57209302,8.42790698 C3.37674419,8.23255814 3.37674419,7.89767442 3.57209302,7.70232558 L9.29302326,1.95348837 C9.90697674,1.33953488 10.9395349,1.33953488 11.5534884,1.95348837 C12.1674419,2.56744186 12.1674419,3.6 11.5534884,4.21395349 L5.83255814,9.9627907 C4.77209302,11.0232558 3.06976744,11.0232558 2.0372093,9.9627907 C0.976744186,8.90232558 0.976744186,7.2 2.0372093,6.16744186 L7.17209302,1.03255814 C7.39534884,0.809302326 7.39534884,0.474418605 7.17209302,0.251162791 C6.94883721,0.0279069767 6.61395349,0.0279069767 6.39069767,0.251162791 L1.25581395,5.35813953 C-0.223255814,6.8372093 -0.223255814,9.26511628 1.25581395,10.744186 C2.73488372,12.2232558 5.1627907,12.2232558 6.64186047,10.744186 L12.3627907,5.02325581 C13.4232558,3.9627907 13.4232558,2.23255814 12.3627907,1.17209302 L12.3627907,1.17209302 Z"
                                    id="Shape"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
