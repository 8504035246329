import React from 'react'
import moment from 'moment'
import { Editor } from '@tinymce/tinymce-react'
import { store } from '../../../redux/store'
import { Icon, Avatar, Button, Input, Upload, Select, Checkbox } from 'antd'
import './style.css'
import getAvatar from '../../../services/getAvatar'
import { success, warning } from '../../../services/Notification'
import { SendMessage } from '../../../services/api'
import className from 'classnames'
import RemoveIcon from '../../../assets/Icons/AccountSettingPage/Remove Button'
import DocIcon from '../../../assets/Icons/CandidateProfile-Messages+ThreadView/docx'
import XlsIcon from '../../../assets/Icons/CandidateProfile-Messages+ThreadView/xls'
import PdfIcon from '../../../assets/Icons/CandidateProfile-Messages+ThreadView/pdf'
import ImgIcon from '../../../assets/Icons/CandidateProfile-Messages+ThreadView/img'
import config from '../../../config'
export default class MessageBox extends React.Component {
    constructor(props) {
        super(props)

        let subject = ''
        let jobApplicationId = ''
        let fromEmail = ''
        let emailStage = ''
        if (props.jobDetail) {
            subject = `${props.jobDetail.jobTitle} - ${props.jobDetail.stageName}`
            jobApplicationId = props.jobDetail.jobAppId
            emailStage = props.jobDetail.stageId
        }

        this.state = {
            fromEmail: props.companyUniqueEmailId,
            receiveremail: props.candidate && props.candidate.email,
            ccemail: [],
            emailsubject: subject,
            emailcontent: '',
            candidateid: props.match.params.id,
            userid: store.getState().Auth.user.id,
            jobApplicationId,
            emailStage,
            attachments: [],
            markedPrivate: false,
            candidateName: props.candidateName,
            showUserList: false,
            accessibleTo: [],
            userSearchText: undefined,
            disabled: false,
            isReceiverEmailValid: true,
            sendingMail: false,
        }
    }

    componentDidMount() {
        const { messagedetail } = this.props
        if (messagedetail) {
            this.setState({
                ccemail: messagedetail.ccEmails || [],
                emailsubject: `Re - ${(messagedetail.subject &&
                    messagedetail.subject.replace(new RegExp('Re - ', 'g'), '')) ||
                    ''}`,
                markedPrivate: messagedetail.markedPrivate,
                accessibleTo: messagedetail.accessibleTo || [],
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.messagedetail !== nextProps.messagedetail && nextProps.messagedetail) {
            this.setState({
                ccemail: nextProps.messagedetail.ccEmails || [],
                emailsubject: `Re - ${(nextProps.messagedetail.subject &&
                    nextProps.messagedetail.subject.replace(new RegExp('Re - ', 'g'), '')) ||
                    ''}`,
                markedPrivate: nextProps.messagedetail.markedPrivate,
                accessibleTo: nextProps.messagedetail.accessibleTo || [],
            })
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
        if (e.target.value.length === 1) {
            this.setState({
                isReceiverEmailValid: true,
            })
        }
    }

    handleCCChange = ccemail => {
        const { teamMembers } = store.getState().Mixed
        let cc = teamMembers
            .filter(member => ccemail.includes(member.email))
            .map(member => {
                return member.email
            })
        const accessibleTo = this.state.accessibleTo.filter(mem => {
            const user = teamMembers.find(x => x._id === mem)
            return !ccemail.includes(user.email)
        })
        this.setState({ ccemail: cc, accessibleTo })
    }

    handleMessageChange = (content, editor) => {
        this.setState({
            emailcontent: content,
        })
    }

    handlePrivateChange = e => {
        this.setState({ markedPrivate: e.target.checked, showUserList: false })
    }

    setFile = file => {
        this.setState(state => ({ attachments: [...state.attachments, file] }))
        return false
    }

    showUserList = () => {
        this.setState({ showUserList: true })
    }

    addUser = user => {
        const { accessibleTo } = this.state
        accessibleTo.push(user)
        this.setState({ accessibleTo, userSearchText: undefined })
    }

    removeUser = userId => {
        let { accessibleTo } = this.state
        accessibleTo = accessibleTo.filter(mem => mem !== userId)
        this.setState({ accessibleTo })
    }

    removeFile = file => {
        this.setState(state => {
            const index = state.attachments.indexOf(file)
            const newattachments = state.attachments.slice()
            newattachments.splice(index, 1)
            return {
                attachments: newattachments,
            }
        })
    }

    displayAttachment = file => {
        const { name, uid } = file
        const ext = name.split('.')[1]

        let icon = PdfIcon
        if (['xls', 'xlxs', 'excel', 'csv'].includes(ext)) {
            icon = XlsIcon
        } else if (['jpg', 'jpeg', 'png', 'svg'].includes(ext)) {
            icon = ImgIcon
        } else if (['doc', 'docx'].includes(ext)) {
            icon = DocIcon
        }

        return (
            <div className="message-files" key={uid}>
                <Icon component={icon} />
                <div className="sample-pdf">{name}</div>
                <Icon component={RemoveIcon} className="cpm-attachment-icon" onClick={() => this.removeFile(file)} />
            </div>
        )
    }

    handleSubmit = e => {
        try {
            if (!this.state.receiveremail) {
                this.setState({ isReceiverEmailValid: false })
                return
            }
            e.preventDefault()
            this.setState({ disabled: true, sendingMail: true }, async () => {
                const values = {
                    attachments: this.state.attachments,
                    candidateId: this.state.candidateid,
                    userId: this.state.userid,
                    jobApplicationId: this.state.jobApplicationId,
                    toEmail: this.state.receiveremail,
                    fromEmail: this.state.fromEmail,
                    ccEmails: this.state.ccemail,
                    subject: this.state.emailsubject,
                    emailContent: this.state.emailcontent,
                    emailDate: moment().format(),
                    emailStage: this.state.emailStage,
                    markedPrivate: this.state.markedPrivate,
                    accessibleTo: this.state.accessibleTo,
                }

                var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (this.state.receiveremail.length <= 0 || !this.state.receiveremail.match(mailformat)) {
                    this.setState({
                        isReceiverEmailValid: false,
                        sendingMail: false,
                        disabled: false,
                    })
                    return
                }

                const result = await SendMessage(values)
                if (result.status) {
                    const { user } = store.getState().Auth
                    result.details.userId = {
                        _id: user.id,
                        fullName: user.userName,
                        email: user.email,
                    }
                }
                success('Message sent successfully')
                this.props.cancelAddMessage(result.details)
                this.setState({ disabled: false, sendingMail: false })
            })
        } catch (err) {
            warning(err.message || err)
            this.setState({ disabled: false, sendingMail: false })
        }
    }

    render() {
        const {
            user,
            user: { accessLevel },
        } = store.getState().Auth
        let { teamMembers } = store.getState().Mixed
        teamMembers = teamMembers.filter(mem => mem._id !== user.id)

        return (
            <div className="new-message-cont">
                <div className="mail-header-new">
                    <div className="cpm-avatar-block">
                        <span style={{ marginRight: 10 }}>
                            <Avatar size={45} shape="square">
                                <span className="cpm-avatarName">{getAvatar(user.userName)}</span>
                            </Avatar>
                        </span>
                    </div>
                    <div className="cpm-mail-detail">
                        <p className="cpm-recruiter-name">{user.userName}</p>
                        <p className="cpm-from-mail">
                            From: <span className="from">{this.state.fromEmail}</span>
                        </p>
                        <p className="cpm-from-mail">
                            To:{' '}
                            <Input
                                className="receiver message-input"
                                placeholder="Enter email"
                                name="receiveremail"
                                value={this.state.receiveremail}
                                onChange={this.handleChange}
                            />
                        </p>
                        <span className={this.state.isReceiverEmailValid ? 'cpm-to-mail-hidden' : 'cpm-to-mail-active'}>
                            Please enter a valid receiver's email
                        </span>
                        <div className="cpm-from-mail">
                            Cc:
                            <Select
                                className="cpm-cc message-input"
                                placeholder="Enter email"
                                name="ccemail"
                                filterOption
                                optionFilterProp="title"
                                optionLabelProp="label"
                                mode="tags"
                                showArrow={false}
                                size="small"
                                onChange={this.handleCCChange}
                                menuItemSelectedIcon={null}
                                removeIcon={<Icon component={RemoveIcon} className="cpm-cc-remove-icon" />}
                                value={this.state.ccemail}
                                dropdownClassName="cc-email-dropdown"
                                tokenSeparators={[',']}
                            >
                                {teamMembers.map((mem, index) => {
                                    const displayName = `${mem.fullName || 'No Name'} <${mem.email}>`
                                    return (
                                        <Select.Option
                                            key={index}
                                            value={mem.email}
                                            title={displayName}
                                            label={mem.fullName || mem.email}
                                            className="cc-email-options"
                                        >
                                            {displayName}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="cpm-mail-content-block">
                    <Input
                        className="cpm-mail-subject"
                        name="emailsubject"
                        value={this.state.emailsubject}
                        onChange={this.handleChange}
                        placeholder="UI / UX Designer - Telephonic Interview"
                    />
                    <Editor
                        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                        // apiKey={`${config.config().tinyMCEApiKey}`}
                        id="jobDescription"
                        value={this.state.emailcontent}
                        onEditorChange={this.handleMessageChange}
                        init={{
                            menubar: false,
                            plugins: 'link image code autoresize lists',
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                        }}
                    />
                </div>
                <div className="cpm-mail-attach-file-block">
                    <Upload
                        multiple
                        beforeUpload={this.setFile}
                        showUploadList={false}
                        onRemove={this.removeFile}
                        fileList={this.state.attachments}
                    >
                        <Button className="message-attachment-btn">
                            <Icon className="attachment-icon" type="paper-clip" />
                            <span className="attachment-text"> Add attachment</span>
                        </Button>
                    </Upload>
                    <div hidden={accessLevel > 2}>
                        <Checkbox checked={this.state.markedPrivate} onChange={this.handlePrivateChange}>
                            <span className="cpm-mark-as-private">Mark as private</span>
                            <Icon type="lock" theme="filled" />
                        </Checkbox>
                    </div>
                </div>
                {this.state.attachments.length ? (
                    <div className="cpm-mail-attach-file-block1">
                        {this.state.attachments.map(this.displayAttachment)}
                    </div>
                ) : null}
                {this.state.markedPrivate && accessLevel < 3 && (
                    <div className="cpm-private-cont">
                        <div className="cpm-private-message">
                            Note: This message is only visible to you,&nbsp;
                            {this.state.accessibleTo.map(mem => {
                                let displayName = teamMembers.find(x => x._id === mem)
                                displayName = displayName.fullName || displayName.email
                                return (
                                    <React.Fragment key={mem}>
                                        <div className="an-nn-sel-user1 top-margin-7">
                                            <div>{displayName}</div>
                                            <Icon component={RemoveIcon} onClick={() => this.removeUser(mem)} />
                                        </div>
                                        , &nbsp;
                                    </React.Fragment>
                                )
                            })}
                            {this.state.candidateName}
                            {teamMembers
                                .filter(mem => this.state.ccemail.includes(mem.email))
                                .map(mem => `, ${mem.fullName || mem.email}`)}
                            &nbsp;, admins and super admins.
                        </div>
                        {!this.state.showUserList ? (
                            <div className="add-more-people-who" onClick={this.showUserList}>
                                Add more people who can view this note?
                            </div>
                        ) : (
                            <Select
                                className="an-nn-search"
                                placeholder="Search team member to add"
                                notFoundContent={null}
                                showSearch
                                suffixIcon={<Icon type="search" />}
                                defaultActiveFirstOption={false}
                                onSelect={this.addUser}
                                value={this.state.userSearchText}
                                onSearch={v => this.setState({ userSearchText: v })}
                                filterOption
                                optionFilterProp="title"
                            >
                                {teamMembers
                                    .filter(
                                        mem =>
                                            mem.status &&
                                            mem._id !== user.id &&
                                            !this.state.ccemail.includes(mem.email) &&
                                            !this.state.accessibleTo.includes(mem._id)
                                    )
                                    .map(mem => (
                                        <Select.Option
                                            key={mem._id}
                                            className="an-nn-opt"
                                            title={mem.fullName + ' ' + mem.email}
                                        >
                                            <div className="an-nn-name">{mem.fullName || mem.email}</div>
                                            <div className="an-nn-add">Add</div>
                                        </Select.Option>
                                    ))}
                            </Select>
                        )}
                    </div>
                )}
                <div className="cpm-mail-footer-block">
                    <Button
                        loading={this.state.sendingMail}
                        className={className({
                            send: true,
                            'no-pointer-event': this.state.disabled || !this.state.emailcontent,
                        })}
                        type="primary"
                        onClick={this.handleSubmit}
                    >
                        Send
                    </Button>
                    <Button
                        type="primary"
                        ghost
                        className="cancel"
                        disabled={this.state.sendingMail}
                        onClick={() => this.props.cancelAddMessage()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        )
    }
}
