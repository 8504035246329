import React from 'react'
import { notification } from 'antd'
import { history } from '../../redux/store'
import { ReactComponent as SuccessIcon } from '../../assets/Icons/Notification/success.svg'
import { ReactComponent as WarningIcon } from '../../assets/Icons/Notification/warning.svg'
import { ReactComponent as CloseIcon } from '../../assets/Icons/Notification/close.svg'
import './styles.scss'

export const success = (description, route, duration) => {
    notification.open({
        message: 'SUCCESS',
        description,
        className: 'notification success',
        icon: <SuccessIcon />,
        duration: duration || 3,
        closeIcon: <CloseIcon />,
        onClick: () => {
            if (route) {
                const { pathname, state, newTab } = route
                if (pathname) {
                    if (newTab) {
                        window.open(pathname, '_blank')
                    } else {
                        history.push({ pathname, state })
                    }
                }
            }
        },
    })
}

export const warning = description => {
    notification.open({
        message: 'WARNING',
        description,
        className: 'notification warning',
        icon: <WarningIcon />,
        duration: 3,
        closeIcon: <CloseIcon />,
    })
}
