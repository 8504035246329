import React, { useState, useEffect } from 'react'

const Description = props => {
    const [variable, setVariable] = useState(true)
    return (
        <>
            <h3>What is SpringRecruit Sourcing Initiative?</h3>
            <p>
                Sourcing is the medium connecting companies with active candidates. Companies part of the sourcing
                initiative agree to allow SpringRecruit to reach out to their rejected/archived candidates so as to
                populate such candidates' profile on the Sourcing Talent Pool.
            </p>
            <p>
                Companies part of this initiative will also get access to the Sourcing Talent Pool where they can source
                talent from other companies part of the Sourcing Initiative for no cost.
            </p>
        </>
    )
}

export default Description
