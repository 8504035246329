import React, { useEffect, useState } from 'react'
import { Avatar, Messages } from '../style'
import { ReactComponent as PhoneIcon } from '../../../../assets/Icons/Talent Pool/phone.svg'
import { ReactComponent as LocationIcon } from '../../../../assets/Icons/Openings/location.svg'
import { ReactComponent as PlusIcon } from '../../../../assets/Icons/Openings/plus.svg'
import { ReactComponent as AttachIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/attach.svg'
import { Button, Collapse, Checkbox, Icon, Input, Spin, Upload, Select } from 'antd'
import { ReactComponent as LockIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/lock.svg'
import { StyledButton } from '../../../Components'
import { ReactComponent as NoEmailsIcon } from '../../../../assets/Icons/Openings/no-emails.svg'
import { ReactComponent as ReplyIcon } from '../../../../assets/Icons/Openings/reply.svg'
import { connect } from 'react-redux'
import { NewMessageView } from '../Components/Static'
import { success, warning } from '../../../../services/Notification'
import { ChangeEmailPrivacy, getInboxEmails, sendInboxEmail } from '../../../../services/NetworkCalls/Inbox'
import { ModifyCacheData } from '../../../../redux/auth/actionListeners'
import PdfIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/pdf'
import XlsIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/xls'
import ImgIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/img'
import DocIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/docx'
import RemoveIcon from '../../../../assets/Icons/AccountSettingPage/Remove Button'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import getAvatar from '../../../../services/getAvatar'
import moment from 'moment'
import { EmailAddTeamMember, EmailRemoveTeamMember } from '../../../../services/api'
import config from '../../../../config'
import { Editor } from '@tinymce/tinymce-react'
import { validateEmailData } from '../Components/helperFunctions'
import { CANDIDATE_STATUS_ARR } from '../../../Helpers/constants'
import { ReactComponent as InfoIcon } from '../../../../assets/Icons/Talent Pool/info.svg'
import className from 'classnames'
import { CANDIDATE } from '../../../Helpers/RegEx'

const { Panel } = Collapse

const { TextArea } = Input

const isToday = date => moment(date).isSame(moment(), 'day')

const CandidateDetailsCard = ({
    contactData: { candidateId, name, email, phone, currentLocation, status },
    jobName,
    stageName,
    jobStatus,
    newConversation,
    setNewConversation,
}) => (
    <div className="item">
        <div className="head">
            <Avatar>{name ? getAvatar(name) : <InfoIcon style={{ width: '15px', height: '15px' }} />}</Avatar>
            <div className="details">
                <div
                    className={className({ name: true, 'name-warning': !name })}
                    onClick={() => window.open(`/candidate/${candidateId}`, '_blank')}
                >
                    {name || 'Candidate'}
                </div>
                <span>
                    <PhoneIcon />
                    {phone || 'N/A'}
                </span>
                <span>
                    <LocationIcon />
                    {currentLocation || 'N/A'}
                </span>
            </div>
            <a
                className="new"
                onClick={() => setNewConversation({ jobName, stageName, name })}
                hidden={newConversation}
            >
                <PlusIcon />
                New Conversation
            </a>
        </div>
        <div className="body">
            <table className="openings">
                <thead>
                    <tr>
                        <th>Opening</th>
                        <th>Stage</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{jobName || 'unassigned'}</td>
                        <td>{stageName || 'unassigned'}</td>
                        <td>{CANDIDATE_STATUS_ARR[status]}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
)

const CandidateEmailViewComponent = props => {
    const {
        emailContent,
        subject,
        toEmail,
        name,
        email,
        autoGenerated,
        createdAt,
        setReplyData,
        returnWhat,
        markedPrivate,
        teamMembers,
        accessibleTo,
        user,
        markedPrivateBy,
        attachments,
        ccEmails = [],
        _id,
        ChangeEmailPrivacy,
        idToken,
    } = props

    const [addMore, setAddMore] = useState(false)
    const [accessibleList, setAccessibleList] = useState(accessibleTo || [])
    const timeShow = moment(createdAt).format(isToday(createdAt) ? 'LT' : 'ddd, MMM D, h:mm A')

    useEffect(() => {
        setReplyData({
            ccEmails,
            subject,
            markedPrivate,
            toEmail,
            createdAt,
            accessibleTo: accessibleList,
            markedPrivateBy,
        })
    })

    if (returnWhat === 'head') {
        const isCompanyEmail = toEmail === user.companyUniqueEmailId
        const computedName = isCompanyEmail ? user.companyName : name
        return (
            <>
                <Avatar>
                    {computedName ? getAvatar(computedName) : <InfoIcon style={{ width: '15px', height: '15px' }} />}
                </Avatar>
                <div className="details">
                    <div className={className({ name: true, 'name-warning': !computedName })}>
                        {computedName || 'Candidate'}
                    </div>
                    <span className="to" hidden={isCompanyEmail}>
                        To: {toEmail}
                    </span>
                    <div className="sub m15">{subject}</div>
                </div>
                <div className="timestamp">{timeShow}</div>
            </>
        )
    }

    // const [textSearch, setTextSearch] = useState(false)

    const removeUserHandler = mem =>
        EmailRemoveTeamMember(_id, mem).then(res => {
            setAccessibleList(accessibleList.filter(each => each !== mem))
        })

    const displayAttachment = file => {
        const { name = file, uid = Date.now().toString() } = file
        const ext = name.split('.').pop()

        let icon = PdfIcon
        if (['xls', 'xlxs', 'excel', 'csv'].includes(ext)) {
            icon = XlsIcon
        } else if (['jpg', 'jpeg', 'png', 'svg'].includes(ext)) {
            icon = ImgIcon
        } else if (['doc', 'docx'].includes(ext)) {
            icon = DocIcon
        }
        const hasLink = typeof file === 'string'
        return (
            <div
                className="message-files"
                style={{ cursor: 'pointer' }}
                key={uid}
                onClick={() => hasLink && window.open(file, '_blank')}
            >
                <Icon component={icon} />
                <div className="sample-pdf">{name}</div>
            </div>
        )
    }

    const addUser = users => {
        EmailAddTeamMember(_id, users)
        setAccessibleList([...accessibleList, users])
    }

    return (
        <div className="body">
            <div
                className={className({ 'mandril-email-template-view': autoGenerated })}
                dangerouslySetInnerHTML={{ __html: emailContent }}
            />
            <div className="email-attachment-view">{attachments.map(displayAttachment)}</div>
            <div className="mail-options">
                <Checkbox checked={markedPrivate} onChange={() => ChangeEmailPrivacy(_id, idToken, !markedPrivate)}>
                    <LockIcon className="lock" />
                    Mark as private
                </Checkbox>
            </div>
            {markedPrivate ? (
                <>
                    <div className="note">
                        Note: This message is only visible to You,
                        {accessibleList.map(mem => {
                            let { fullName, email } = teamMembers.find(x => x._id === mem) || {}
                            const displayName = fullName || email
                            if (displayName)
                                return (
                                    <React.Fragment key={mem}>
                                        <div className="an-nn-sel-user2 top-margin-7">
                                            <div>{displayName}</div>
                                            {markedPrivateBy === user.id ? (
                                                <Icon component={RemoveIcon} onClick={() => removeUserHandler(mem)} />
                                            ) : null}
                                        </div>
                                        , &nbsp;
                                    </React.Fragment>
                                )
                            return null
                        })}
                        {teamMembers
                            .filter(mem => ccEmails.includes(mem.email))
                            .map(mem => `, ${mem.fullName || mem.email}`)}
                        Super Admins and Admins
                    </div>
                    <div className="add-more top-margin-7" onClick={() => setAddMore(true)}>
                        Add more people who can view this message?
                    </div>
                    {addMore ? (
                        <Select
                            className="an-nn-search"
                            placeholder="Search team member to add"
                            notFoundContent={null}
                            showSearch
                            suffixIcon={<Icon type="search" />}
                            defaultActiveFirstOption={false}
                            onSelect={addUser}
                            style={{ width: '60%' }}
                            value={''}
                            // onSearch={searchTextHandler}
                            filterOption
                            optionFilterProp="title"
                        >
                            {teamMembers
                                .filter(
                                    ({ _id, status, email }) =>
                                        status && !ccEmails.includes(email) && !accessibleList.includes(_id)
                                )
                                .map(mem => (
                                    <Select.Option
                                        key={mem._id}
                                        className="an-nn-opt"
                                        title={mem.fullName + ' ' + mem.email}
                                    >
                                        <div className="an-nn-name">{mem.fullName || mem.email}</div>
                                        <div className="an-nn-add">Add</div>
                                    </Select.Option>
                                ))}
                        </Select>
                    ) : null}
                </>
            ) : null}

            <div className="top-margin-7" />

            <StyledButton
                type="inverted"
                width="80px"
                height="30px"
                onClick={() => {
                    document.getElementById('message-section-view').scrollTo({ top: 0, behavior: 'smooth' })
                    setReplyData({
                        ccEmails,
                        subject,
                        markedPrivate,
                        toEmail,
                        createdAt,
                        accessibleTo: accessibleList,
                        markedPrivateBy,
                    })
                }}
            >
                <ReplyIcon />
                Reply
            </StyledButton>
        </div>
    )
}

const mapToPros = ({ Mixed: { teamMembers }, Auth: { user, idToken }, Inbox: { selectedContact } }) => ({
    teamMembers,
    user,
    selectedContact,
    idToken,
})
const CandidateEmailView = connect(
    mapToPros,
    { ChangeEmailPrivacy }
)(CandidateEmailViewComponent)

const CandidateReplyViewComponent = ({
    replyData,
    contactData,
    cancelNewMessage,
    teamMembers,
    candidateToEmail,
    user,
    selectedContact,
    sendInboxEmail,
}) => {
    const {
        toEmail,
        ccEmails,
        subject,
        markedPrivate,
        accessibleTo,
        candidateEmail = candidateToEmail,
        markedPrivateBy,
        jobName,
        stageName,
        name = contactData.name,
    } = replyData

    const [ccText, setccText] = useState('')
    const [ccEmailList, setccEmailList] = useState(ccEmails || [])
    const [subjectText, setSubjectText] = useState(
        subject ? `Re: ${subject}` : jobName && stageName ? `${jobName} - ${stageName}` : ''
    )
    const [bodyText, setBodyText] = useState('')
    const [markPrivateBol, setMarkPrivateBol] = useState(markedPrivate)
    const [addMore, setAddMore] = useState(false)
    const [accessibleList, setAccessibleList] = useState(accessibleTo || [])

    const [markedPrivateBolBy, setMarkedPrivateBolBy] = useState(markedPrivateBy || null)

    const [attachmentsArray, setAttachmentsArray] = useState([])

    const [disableSendButton, setDisableSentButton] = useState(false)

    const setFile = file => {
        const newArray = attachmentsArray.map(each => each)
        newArray.push(file)
        setAttachmentsArray(newArray)
        return false
    }

    const removeUserHandler = mem => setAccessibleList(accessibleList.filter(each => each !== mem))

    const removeFile = file => {
        const index = attachmentsArray.indexOf(file)
        const newattachments = attachmentsArray.slice()
        newattachments.splice(index, 1)
        setAttachmentsArray(newattachments)
    }

    const displayAttachment = file => {
        const { name, uid } = file
        const ext = name.split('.')[1]

        let icon = PdfIcon
        if (['xls', 'xlxs', 'excel', 'csv'].includes(ext)) {
            icon = XlsIcon
        } else if (['jpg', 'jpeg', 'png', 'svg'].includes(ext)) {
            icon = ImgIcon
        } else if (['doc', 'docx'].includes(ext)) {
            icon = DocIcon
        }

        return (
            <div className="message-files" key={uid}>
                <Icon component={icon} />
                <div className="sample-pdf">{name}</div>
                <Icon component={RemoveIcon} className="cpm-attachment-icon" onClick={() => removeFile(file)} />
            </div>
        )
    }

    const addCCEMail = data => {
        if (data.length > ccEmailList.length) {
            // email added.. check if its in teamMember
            data = data.filter(each => CANDIDATE.EMAIL.test(each) && teamMembers.find(({ email }) => email === each))
        }
        setccEmailList(data)
    }

    const handleSendEmail = async () => {
        const values = {
            attachments: attachmentsArray,
            candidateId: '',
            userId: user.id,
            toEmail: candidateEmail,
            fromEmail: user.companyUniqueEmailId,
            ccEmails: ccEmailList,
            subject: subjectText,
            emailContent: bodyText,
            emailDate: moment().format(),
            markedPrivate: markPrivateBol,
            accessibleTo: accessibleList,
            shouldSendDispatch: true,
        }

        const validationResult = validateEmailData(values)
        if (!validationResult.result) {
            warning(validationResult.message)
            return null
        }

        setDisableSentButton(true)
        await sendInboxEmail(values)
        setDisableSentButton(false)
        cancelNewMessage()
    }
    const handleMessageChange = (content, editor) => {
        setBodyText(content)
    }
    return (
        <div className="item">
            <div className="input">
                <span>To</span>
                <p>
                    {`${name || 'Candidate'} `}
                    <span>({candidateEmail})</span>
                </p>
            </div>
            <div className="input">
                <span>Cc</span>
                <Select
                    placeholder="Enter email"
                    name="ccemail"
                    filterOption
                    optionFilterProp="title"
                    optionLabelProp="label"
                    mode="tags"
                    showArrow={false}
                    onChange={addCCEMail}
                    menuItemSelectedIcon={null}
                    removeIcon={<Icon component={RemoveIcon} />}
                    value={ccEmailList}
                    tokenSeparators={[',']}
                >
                    {teamMembers.map((mem, index) => {
                        const displayName = `${mem.fullName || 'No Name'} <${mem.email}>`
                        return (
                            <Select.Option
                                key={index}
                                value={mem.email}
                                title={displayName}
                                label={mem.fullName || mem.email}
                                className="cc-email-options"
                            >
                                {displayName}
                            </Select.Option>
                        )
                    })}
                </Select>
            </div>
            <div className="input">
                <span>Sub</span>{' '}
                <input
                    type="text"
                    className="sub"
                    value={subjectText}
                    onChange={({ target: { value } }) => setSubjectText(value)}
                />
            </div>
            {/* Textarea needs the tool icons to be added */}
            <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                value={bodyText}
                onEditorChange={handleMessageChange}
                // apiKey={`${config.config().tinyMCEApiKey}`}
                init={{
                    menubar: false,
                    max_height: 230,
                    plugins: 'link image code autoresize lists',
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                }}
            />
            <div className="mail-options">
                <Upload
                    multiple
                    beforeUpload={setFile}
                    showUploadList={false}
                    onRemove={removeFile}
                    fileList={attachmentsArray}
                >
                    <span>
                        <AttachIcon />
                        Add Attachment
                    </span>
                </Upload>
                <Checkbox
                    checked={markPrivateBol}
                    onChange={() => {
                        if (markPrivateBol) {
                            setMarkPrivateBol(false)
                            setMarkedPrivateBolBy(null)
                        } else {
                            setMarkPrivateBol(true)
                            setMarkedPrivateBolBy(user.id)
                        }
                    }}
                >
                    <LockIcon className="lock" />
                    Mark as private
                </Checkbox>
            </div>
            <div className="email-attachment-view">{attachmentsArray.map(displayAttachment)}</div>

            {markPrivateBol ? (
                <>
                    <div className="note">
                        Note: This message is only visible to You,
                        {accessibleList.map(mem => {
                            let { fullName, email } = teamMembers.find(x => x._id === mem) || {}
                            const displayName = fullName || email
                            if (displayName)
                                return (
                                    <React.Fragment key={mem}>
                                        <div className="an-nn-sel-user2 top-margin-7">
                                            <div>{displayName}</div>
                                            {markedPrivateBolBy === user.id ? (
                                                <Icon component={RemoveIcon} onClick={() => removeUserHandler(mem)} />
                                            ) : null}
                                        </div>
                                        , &nbsp;
                                    </React.Fragment>
                                )
                            return null
                        })}
                        {teamMembers
                            .filter(mem => ccEmailList.includes(mem.email))
                            .map(mem => (
                                <div>,&nbsp; {mem.fullName || mem.email}</div>
                            ))}
                        Super Admins and Admins
                    </div>
                    <div className="add-more top-margin-7" onClick={() => setAddMore(true)}>
                        Add more people who can view this message?
                    </div>
                    {addMore ? (
                        <Select
                            className="an-nn-search"
                            placeholder="Search team member to add"
                            notFoundContent={null}
                            showSearch
                            suffixIcon={<Icon type="search" />}
                            defaultActiveFirstOption={false}
                            onSelect={data => setAccessibleList([...accessibleList, data])}
                            style={{ width: '60%' }}
                            value={''}
                            // onSearch={searchTextHandler}
                            filterOption
                            optionFilterProp="title"
                        >
                            {teamMembers
                                .filter(
                                    ({ _id, status, email }) =>
                                        status && !ccEmailList.includes(email) && !accessibleList.includes(_id)
                                )
                                .map(mem => (
                                    <Select.Option
                                        key={mem._id}
                                        className="an-nn-opt"
                                        title={mem.fullName + ' ' + mem.email}
                                    >
                                        <div className="an-nn-name">{mem.fullName || mem.email}</div>
                                        <div className="an-nn-add">Add</div>
                                    </Select.Option>
                                ))}
                        </Select>
                    ) : null}
                </>
            ) : null}

            <div className="buttons">
                <StyledButton type="inverted" width="80px" height="40px" onClick={cancelNewMessage}>
                    Cancel
                </StyledButton>
                <StyledButton disabled={disableSendButton} width="80px" height="40px" onClick={handleSendEmail}>
                    Send
                </StyledButton>
            </div>
        </div>
    )
}

const CandidateReplyView = connect(
    mapToPros,
    { sendInboxEmail }
)(CandidateReplyViewComponent)

const MessageSection = ({
    contactData,
    selectedContact,
    jobName,
    stageName,
    jobStatus,
    toggleNewMessage,
    showNewMessage,
    emailLoading,
    teamMembers,
    getInboxEmails,
    pullToRefresh,
    ModifyCacheData,
    user,
    sendInboxEmail,
    searchedContactListSize,
    callInProgress,
}) => {
    const [replyData, setReplyData] = useState(null) // replay can be new message or actual reply
    useEffect(() => {
        if (selectedContact !== null) {
            setReplyData(null)
        }
    }, [selectedContact])

    if (selectedContact === null) {
        return (
            <Messages>
                <div className="empty">
                    <NoEmailsIcon />
                    <p>
                        {emailLoading || callInProgress
                            ? 'Loading....'
                            : searchedContactListSize === 0
                                ? 'No Results found'
                                : 'Click on the candidate name to view emails'}
                    </p>
                </div>
                <NewMessageView
                    user={user}
                    teamMembers={teamMembers}
                    showNewMessage={showNewMessage}
                    toggleNewMessage={toggleNewMessage}
                    sendInboxEmail={sendInboxEmail}
                />
            </Messages>
        )
    }

    if (selectedContact === null || (contactData && contactData.length === 0)) {
        return (
            <Messages>
                <div className="empty">
                    <NoEmailsIcon />
                    <p>Loading...</p>
                </div>
                <NewMessageView
                    user={user}
                    teamMembers={teamMembers}
                    showNewMessage={showNewMessage}
                    toggleNewMessage={toggleNewMessage}
                    sendInboxEmail={sendInboxEmail}
                />
            </Messages>
        )
    }

    const setNewConversation = data => {
        if (replyData) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            warning('New conversation already open')
        } else setReplyData(data || {})
    }

    const onRefreshLoading = () => {
        return new Promise(resolve => {
            setTimeout(resolve, 2000)
        })
    }

    const onRefreshClick = () => {
        ModifyCacheData(true, 'pullToRefresh')
        getInboxEmails({ candidateId: selectedContact })
    }

    return (
        <Messages id="message-section-view">
            <CandidateDetailsCard
                selectedContact={selectedContact}
                contactData={contactData}
                jobName={jobName}
                stageName={stageName}
                jobStatus={jobStatus}
                newConversation={replyData}
                setNewConversation={setNewConversation}
            />
            {replyData && (
                <CandidateReplyView
                    candidateToEmail={contactData.email}
                    replyData={replyData}
                    cancelNewMessage={() => setReplyData(null)}
                    contactData={contactData}
                />
            )}
            <div className="refresh-email" onClick={onRefreshClick}>
                {pullToRefresh ? <Spin /> : ''}
            </div>

            {contactData.email_content && contactData.email_content.length === 0 ? (
                <div className="empty" style={{ height: 'calc(100% - 325px)', padding: 80 }}>
                    <NoEmailsIcon />
                    <p>No Email conversations...</p>
                </div>
            ) : (
                <Collapse
                    style={{ height: '600px' }}
                    defaultActiveKey={
                        contactData.email_content && contactData.email_content.length
                            ? contactData.email_content[0]._id
                            : []
                    }
                >
                    {contactData.email_content.map(each => (
                        <Panel
                            className="item"
                            key={each._id}
                            showArrow={false}
                            header={
                                <CandidateEmailView
                                    returnWhat="head"
                                    {...each}
                                    name={contactData.name}
                                    email={contactData.email}
                                    setReplyData={setReplyData}
                                />
                            }
                        >
                            <CandidateEmailView
                                returnWhat="body"
                                {...each}
                                key={each._id}
                                setReplyData={setReplyData}
                            />
                        </Panel>
                    ))}
                </Collapse>
            )}

            {/* <div className="item">
                <div className="head">
                    <Avatar>RR</Avatar>
                    <div className="details">
                        <div className="name">Appication Received</div>
                        <span className="to">To: ankurhsdvbjh@yopmail.com</span>
                    </div>
                    <div className="timestamp">Thu, Dec 3, 10:30 AM (5 mins ago)</div>
                </div>
            </div>*/}

            {/* The new message popup */}
            <NewMessageView
                user={user}
                teamMembers={teamMembers}
                showNewMessage={showNewMessage}
                toggleNewMessage={toggleNewMessage}
                sendInboxEmail={sendInboxEmail}
            />
        </Messages>
    )
}

const mapStateToProps = ({
    Inbox: { selectedContact, contactsData, emailLoading, contacts, callInProgress },
    Job: { allJobs },
    Auth: { user },
    Mixed: {
        teamMembers,
        cachedData: { pullToRefresh = false },
    },
}) => {
    let [jobName, stageName, jobStatus] = [null, null, 1]
    if (contactsData[selectedContact]) {
        // const { } = allJobs.find(each=> each._id=== contactsData[selectedContact][0].jobApplication[0])
        const { jobId, stageId } = contactsData[selectedContact]
        const { stages, jobTitle = '', status } = allJobs.find(each => each._id === jobId) || {}
        if (jobTitle) {
            jobName = jobTitle
            jobStatus = status
            const { stageName: name } = stages.find(each => each._id === stageId)
            stageName = name
        }
    }

    return {
        contactData: (selectedContact && contactsData[selectedContact]) || [],
        searchedContactListSize: contacts.length,
        jobName,
        stageName,
        jobStatus,
        selectedContact,
        emailLoading,
        teamMembers,
        pullToRefresh,
        user,
        callInProgress,
    }
}

export default connect(
    mapStateToProps,
    { getInboxEmails, ModifyCacheData, sendInboxEmail }
)(MessageSection)
