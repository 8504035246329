export const ALLOWED_FILE_FORMATS = {
    CANDIDATE: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    SPREADSHEET_CSV: [
        '.csv',
        '.xls',
        '.xlsx',
        'text/plain',
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    SPREADSHEET_ZIP: ['application/zip', 'application/x-zip-compressed'],
}

// Check if the uploaded files are in either pdf, doc or docx format
export const fileTypeValidation = files => {
    let validFiles = true
    files.forEach(file => {
        if (!ALLOWED_FILE_FORMATS.CANDIDATE.includes(file.type)) {
            return (validFiles = false)
        }
    })
    if (validFiles) {
        let formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            let file = files[i]
            formData.append('resume', file)
        }
        return formData
    } else {
        return false
    }
}

// We have only one file per upload now
export const SingleFileTypeValidation = file => {
    if (ALLOWED_FILE_FORMATS.CANDIDATE.includes(file.type)) {
        const formData = new FormData()
        formData.append('resume', file, file.name)
        return formData
    }
    return false
}
