import React, { useState, useEffect } from 'react'
import { Checkbox, Collapse, Icon, Input } from 'antd'
import { FILTER_TYPES } from '../constants'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'

const { Search } = Input
const { Panel } = Collapse

const customPanelStyle = {
    background: '#fff',
    border: 0,
    overflow: 'hidden',
    color: '#333',
    fontFamily: 'SFProText-Bold',
}

const refineAndSort = (masterList, search, selected, { idName, dataName }) => {
    const selectObject = {}
    const finalList = selected.map(each => {
        selectObject[each[idName]] = each
        return { label: each[dataName], value: each[idName] }
    })

    masterList.forEach(each => {
        const data = { label: each[dataName], value: each[idName] }
        if (data.label.toLowerCase().includes(search.toLowerCase())) {
            if (!selectObject[data.value]) finalList.push(data)
        }
    })
    return finalList
}

const refineAndSortLocation = (masterList, search, selected) => {
    const finalList = [...selected]

    masterList.forEach(each => {
        if (each.toLowerCase().includes(search.toLowerCase())) {
            if (!selected.includes(each)) finalList.push(each)
        }
    })

    return finalList
}

/** Presentational Component **/
const RefineBox = ({ interestedJobs, updateFilter, selectedFilter, assignedJobs, skills, tags, locations }) => {
    const [assigned, setAssigned] = useState([])
    const [assignedInput, setAssignedInput] = useState('')

    const [interested, setInterested] = useState([])
    const [interestedInput, setInterestedInput] = useState('')

    const [skill, setSkill] = useState([])
    const [skillsInput, setSkillsInput] = useState('')

    const [tag, setTag] = useState([])
    const [tagsInput, setTagsInput] = useState('')

    const [location, setLocation] = useState([])
    const [locationInput, setLocationInput] = useState('')

    useEffect(() => {
        // resetting refinements when main filters are changes
        // updating only those that is required
        if (assigned.length) setAssigned([])
        if (assignedInput.length) setAssignedInput('')

        if (interested.length) setInterested([])
        if (interestedInput.length) setInterestedInput('')

        if (skill.length) setSkill([])
        if (skillsInput.length) setSkillsInput('')

        if (tags.length) setTag([])
        if (tagsInput.length) setTagsInput('')

        if (location.length) setLocation([])
        if (locationInput.length) setLocationInput('')
    }, [selectedFilter])

    const assignedJobOnChange = data => {
        const newArray = data.map(each => assignedJobs.find(job => job._id === each))
        updateFilter({ type: 'assigned', data })
        setAssigned(newArray)
    }
    const interestedJobsOnChange = data => {
        const newArray = data.map(each => interestedJobs.find(job => job.professionId === each))
        updateFilter({ type: 'interested', data })
        setInterested(newArray)
    }
    const skillsOnChange = data => {
        const newArray = data.map(each => skills.find(skill => skill.skillId === each))
        updateFilter({ type: 'skills', data })
        setSkill(newArray)
    }

    const tagsOnChange = data => {
        const newArray = data.map(each => tags.find(tag => tag.tagId === each))
        updateFilter({ type: 'tags', data })
        setTag(newArray)
    }

    const locationOnChange = data => {
        const newArray = data.map(each => locations.find(location => location === each))
        updateFilter({ type: 'locations', data })
        setLocation(newArray)
    }

    const assignedOptionsList = refineAndSort(assignedJobs, assignedInput, assigned, {
        idName: '_id',
        dataName: 'title',
    })

    const interestedOptionsList = refineAndSort(interestedJobs, interestedInput, interested, {
        idName: 'professionId',
        dataName: 'title',
    })

    const skillsOptionsList = refineAndSort(skills, skillsInput, skill, { idName: 'skillId', dataName: 'name' })
    const tagsOptionList = refineAndSort(tags, tagsInput, tag, { idName: 'tagId', dataName: 'name' })
    const locationOptionList = refineAndSortLocation(locations, locationInput, location)

    return (
        <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <Icon component={DownIcon} rotate={isActive ? 180 : 0} />}
            expandIconPosition="right"
        >
            <Panel
                header="Location"
                key="location"
                style={customPanelStyle}
                extra={
                    <span hidden={location.length === 0} className="clear-all" onClick={() => locationOnChange([])}>
                        Clear All
                    </span>
                }
            >
                {location.length + locations.length > 0 ? (
                    <>
                        <Input
                            allowClear
                            placeholder="Search"
                            size="large"
                            onChange={({ target: { value } }) => setLocationInput(value)}
                        />
                        <div className="mt-20" />
                        <Checkbox.Group value={location} options={locationOptionList} onChange={locationOnChange} />
                    </>
                ) : null}
            </Panel>

            {selectedFilter !== FILTER_TYPES.UNASSIGNED && (
                <Panel
                    header="Assigned Job opening"
                    key="assigned"
                    style={customPanelStyle}
                    extra={
                        <span
                            hidden={assigned.length === 0}
                            className="clear-all"
                            onClick={() => assignedJobOnChange([])}
                        >
                            Clear All
                        </span>
                    }
                >
                    {assigned.length + assignedJobs.length > 0 ? (
                        <>
                            <Input
                                placeholder="Search"
                                size="large"
                                allowClear
                                onChange={({ target: { value } }) => setAssignedInput(value)}
                            />
                            <div className="mt-20" />
                            <Checkbox.Group
                                value={assigned.map(each => each._id)}
                                options={assignedOptionsList}
                                onChange={assignedJobOnChange}
                            />
                        </>
                    ) : null}
                </Panel>
            )}

            {selectedFilter !== FILTER_TYPES.ASSIGNED && (
                <Panel
                    header="Interested Job opening"
                    key="interested"
                    style={customPanelStyle}
                    extra={
                        <span
                            hidden={interested.length === 0}
                            className="clear-all"
                            onClick={() => interestedJobsOnChange([])}
                        >
                            Clear All
                        </span>
                    }
                >
                    {interested.length + interestedJobs.length > 0 ? (
                        <>
                            <Input
                                allowClear
                                placeholder="Search"
                                size="large"
                                onChange={({ target: { value } }) => setInterestedInput(value)}
                            />
                            <div className="mt-20" />
                            <Checkbox.Group
                                value={interested.map(each => each.professionId)}
                                options={interestedOptionsList}
                                onChange={interestedJobsOnChange}
                            />
                        </>
                    ) : null}
                </Panel>
            )}

            <Panel
                header="Key skills"
                key="skills"
                style={customPanelStyle}
                extra={
                    <span hidden={skill.length === 0} className="clear-all" onClick={() => skillsOnChange([])}>
                        Clear All
                    </span>
                }
            >
                {skill.length + skills.length > 0 ? (
                    <>
                        <Input
                            allowClear
                            placeholder="Search"
                            size="large"
                            onChange={({ target: { value } }) => setSkillsInput(value)}
                        />
                        <div className="mt-20" />
                        <Checkbox.Group
                            value={skill.map(each => each.skillId)}
                            options={skillsOptionsList}
                            onChange={skillsOnChange}
                        />
                    </>
                ) : null}
            </Panel>

            <Panel
                header="Tags"
                key="tags"
                style={customPanelStyle}
                extra={
                    <span hidden={tag.length === 0} className="clear-all" onClick={() => tagsOnChange([])}>
                        Clear All
                    </span>
                }
            >
                {tags.length + tags.length > 0 ? (
                    <>
                        <Input
                            allowClear
                            placeholder="Search"
                            size="large"
                            onChange={({ target: { value } }) => setTagsInput(value)}
                        />
                        <div className="mt-20" />
                        <Checkbox.Group
                            value={tag.map(each => each.tagId)}
                            options={tagsOptionList}
                            onChange={tagsOnChange}
                        />
                    </>
                ) : null}
            </Panel>
        </Collapse>
    )
}

export default RefineBox
