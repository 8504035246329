import React, { useState, useEffect } from 'react'
import { PageContainer } from '../../../Components/StyledPages/PageContainer'
import { PAGE_SECTIONS } from '../../../Helpers/Stylesheet/styleTypes'
import { StyledButton } from '../../../Components'
import { fields, defaultData } from '../constants'
import { ReactComponent as OffIcon } from '../../../../assets/Icons/Openings/off.svg'
import { ReactComponent as OptionalIcon } from '../../../../assets/Icons/Openings/optional.svg'
import { ReactComponent as MandatoryIcon } from '../../../../assets/Icons/Openings/mandatory.svg'
import { CYAN_GREY } from '../../../Helpers/Stylesheet/styledComponents'

const Radios = ({ fieldName, value, setFieldValue }) => {
    const disabled = fieldName === 'email' || fieldName === 'fullName'
    return (
        <div className="radios">
            <StyledButton
                capitalize
                disable={disabled}
                style={{
                    marginRight: '10px',
                    border: `1px solid ${CYAN_GREY}`,
                    fontFamily: 'sanFrancisco',
                }}
                type={value !== 1 ? 'inverted' : null}
                width="49px"
                height="30px"
                onClick={() => setFieldValue(1)}
            >
                <OffIcon />
                Off
            </StyledButton>
            <StyledButton
                capitalize
                disable={disabled}
                style={{
                    marginRight: '10px',
                    border: `1px solid ${CYAN_GREY}`,
                    fontFamily: 'sanFrancisco',
                }}
                type={value !== 2 ? 'inverted' : null}
                width="80px"
                height="30px"
                onClick={() => setFieldValue(2)}
            >
                <OptionalIcon />
                Optional
            </StyledButton>
            <StyledButton
                capitalize
                style={{
                    marginRight: '10px',
                    border: `1px solid ${CYAN_GREY}`,
                    fontFamily: 'sanFrancisco',
                }}
                type={value !== 3 ? 'inverted' : null}
                width="90px"
                height="30px"
                onClick={() => setFieldValue(3)}
            >
                <MandatoryIcon />
                Mandatory
            </StyledButton>
        </div>
    )
}

const ApplicationForm = ({ jobApplication, updateApplicationForm }) => {
    const [formData, setFormData] = useState({ ...defaultData, ...jobApplication })
    const [disable, setDisable] = useState(false)

    useEffect(() => {
        setFormData({ ...defaultData, ...jobApplication })
    }, [jobApplication])

    return (
        <>
            <PageContainer title="APPLICATION FORM" type={PAGE_SECTIONS.MAIN}>
                <ul>
                    {Object.keys(fields).map((columnName, index) => (
                        <li key={index}>
                            <span>{fields[columnName]}</span>
                            <Radios
                                fieldName={columnName}
                                value={formData[columnName]}
                                setFieldValue={value => setFormData({ ...formData, [columnName]: value })}
                            />
                        </li>
                    ))}
                </ul>
            </PageContainer>
            <div className="buttons">
                <StyledButton
                    type="inverted"
                    width="80px"
                    height="40px"
                    disable={disable}
                    onClick={() => updateApplicationForm(formData, 'back')}
                >
                    Back
                </StyledButton>
                <StyledButton
                    width="140px"
                    height="40px"
                    disable={disable}
                    onClick={async () => {
                        setDisable(true)
                        await updateApplicationForm(formData, 'next')
                        setDisable(false)
                    }}
                >
                    SAVE & CONTINUE
                </StyledButton>
            </div>
        </>
    )
}
export default ApplicationForm
