import React from 'react'

const SVGComponent = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="26"
        height="30"
        viewBox="0 0 26 30"
    >
        <image
            id="image0"
            width="26"
            height="30"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAMAAAAFBf7qAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA9lBMVEUAAAD/bVL/Z1H/ZlDy kIT/oqL/ZlHvlo/snpb/ZlHvlYn0h3v+albsnZT6dWPtnJT/Z1P5fWzthXvriX3pfHDlW0joXUn7 ZU//ZlH/Z1H/ZlH/Z1H/gGD/ZlH/ZlH/dk7/ZlDsnZX4Y03lW0f0YUzvX0r+ZU/rXkn8ZE//koL/ z8j/zcb/qZ3/cFz/xLz/lob/fWr/jn7/ppn/xLv/eGT/uK7/vLL/iHf/29X/m43/h3b/39r/dmL/ bFb/y8P/lof/0sv/4t7/hHP/Z1H/8e//ysP/f2z/inn/8O7/wLb/alX/Z1L/wbj/tav/29b/5OD/ aVT///9okSUpAAAAIHRSTlMAHKblnAvtysDmxb/u6dPi9MjBwpTU1NHRt3jTCNyRDTbucwEAAAAB YktHRFGUaXwqAAAAB3RJTUUH4wYYBjkNMlHE6QAAANtJREFUKM9jYGBkYlZABiysDFDAyKaACtg5 YHJMCuhSijA5TkwpmBy6jAKXIkwOQ4pbESaHIcXDC5PDkFLg44fKYUopCAgKCQsLi2CTUlBQUlZW FsUupaCCW0pBFbeUmjpOKQUNMZxSCuK4pSQokNLU0tbR1dTS01cw0NLSMkSWMjI2MTUzMja3sLSy trGxRZEyU7C0AxL2DlaOTk7OKFIu1q5uQCl3CysPT08vNF0gwtvH18oPzRkQKRd/vQAMKdL9xYlL ShIzicKAFGbChgJpGQaM7AAGsnLyDADPfGA/QidIKQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0w Ni0yNFQwNjo1NzoxMyswMzowMJVaF1cAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDYtMjRUMDY6 NTc6MTMrMDM6MDDkB6/rAAAAAElFTkSuQmCC"
        />
    </svg>
)

export default SVGComponent
