import React, { useState } from 'react'
import { TagCard } from '../style'
import { ReactComponent as TagIcon } from '../../../../assets/Icons/Talent Pool/tag.svg'
import { ReactComponent as EditIcon } from '../../../../assets/Icons/Talent Pool/Edit.svg'
import { Tooltip } from 'antd'

const Tags = ({ tags = [], showTagModal }) => {
    const [showMore, setShowMore] = useState(false)

    return (
        <TagCard>
            <div className="header">
                <span>TAGS</span>
                <div className="icons">
                    <Tooltip title="Add Tag" placement="topRight" arrowPointAtCenter>
                        <TagIcon onClick={() => showTagModal('addTag')} />
                    </Tooltip>
                    {tags.length ? (
                        <Tooltip title="Edit Tag" placement="topRight" arrowPointAtCenter>
                            <EditIcon onClick={() => showTagModal('manageTag')} />
                        </Tooltip>
                    ) : null}
                </div>
            </div>
            <div className="tags">
                {showMore
                    ? tags.map(tag => <span key={tag.tagId}>{tag.name}</span>)
                    : tags.slice(0, 7).map(tag => <span key={tag.tagId}>{tag.name}</span>)}
            </div>
            {tags.length > 7 ? (
                <a onClick={() => setShowMore(!showMore)}>{showMore ? 'SHOW LESS' : 'SHOW MORE'}</a>
            ) : null}
        </TagCard>
    )
}

export default Tags
