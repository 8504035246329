import ContentLoader from 'react-content-loader'
import React from 'react'

export const SidebarLoader = () => (
    <ContentLoader height={150}>
        <rect x="0" y="0" rx="20" ry="20" width="150" height="35" />
        <rect x="0" y="55" rx="20" ry="20" width="250" height="35" />
        <rect x="0" y="110" rx="20" ry="20" width="350" height="35" />
    </ContentLoader>
)

export const ProfileLoader = () => (
    <ContentLoader height={162}>
        <rect x="0" y="0" rx="10" ry="10" width="58" height="58" />
        <rect x="80" y="5" rx="1" ry="1" width="120" height="10" />

        <rect x="80" y="30" rx="1" ry="1" width="60" height="8" />
        <rect x="80" y="45" rx="1" ry="1" width="60" height="8" />
        <rect x="80" y="60" rx="1" ry="1" width="60" height="8" />
        <rect x="80" y="75" rx="1" ry="1" width="60" height="8" />
        <rect x="80" y="90" rx="1" ry="1" width="60" height="8" />

        <rect x="165" y="30" rx="1" ry="1" width="70" height="8" />
        <rect x="165" y="45" rx="1" ry="1" width="70" height="8" />
        <rect x="165" y="60" rx="1" ry="1" width="70" height="8" />
        <rect x="165" y="75" rx="1" ry="1" width="70" height="8" />
        <rect x="165" y="90" rx="1" ry="1" width="70" height="8" />
    </ContentLoader>
)

export const NotesLoader = () => (
    <ContentLoader height={120}>
        <rect x="0" y="0" rx="0" ry="0" width="400" height="50" />
        <rect x="0" y="60" rx="0" ry="0" width="400" height="50" />
    </ContentLoader>
)

export const SkillsLoader = () => (
    <ContentLoader height={50}>
        <rect x="0" y="0" rx="0" ry="0" width="50" height="12" />
        <rect x="60" y="0" rx="0" ry="0" width="50" height="12" />
        <rect x="120" y="0" rx="0" ry="0" width="50" height="12" />
        <rect x="180" y="0" rx="0" ry="0" width="50" height="12" />
        <rect x="240" y="0" rx="0" ry="0" width="50" height="12" />

        <rect x="0" y="20" rx="0" ry="0" width="50" height="12" />
        <rect x="60" y="20" rx="0" ry="0" width="50" height="12" />
        <rect x="120" y="20" rx="0" ry="0" width="50" height="12" />
        <rect x="180" y="20" rx="0" ry="0" width="50" height="12" />
        <rect x="240" y="20" rx="0" ry="0" width="50" height="12" />

        <rect x="0" y="40" rx="0" ry="0" width="50" height="12" />
        <rect x="60" y="40" rx="0" ry="0" width="50" height="12" />
    </ContentLoader>
)
