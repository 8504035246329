import styled from 'styled-components'
import { ContentWrapper } from '../style'
import { DARK_BLUE, GREY, LIGHT_GREY, BORDER_COLOR } from '../../Helpers/Stylesheet/styledComponents'
import Oval from '../../../assets/Icons/Openings/oval.svg'
import Circle from '../../../assets/Icons/Openings/circle.svg'
import Info from '../../../assets/Icons/Interview/info.svg'
import { FormWrapper } from '../AddCandidate/style'

export const Content = styled(ContentWrapper)`
    position: relative;
    min-height: 340px;
    padding-bottom: 10px;
    h3 {
        font-family: sanFranciscoMedium;
    }
    .back {
        font-size: 12px;
        font-family: sanFranciscoSemi;
        color: ${DARK_BLUE};
        margin-bottom: 20px;
        cursor: pointer;
    }
    .steps {
        display: flex;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: 0;
        > div {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: ${BORDER_COLOR};
            &.active {
                background: ${DARK_BLUE};
            }
            + div {
                margin-left: 10px;
            }
        }
    }
    .desc {
        font-size: 14px;
        color: ${GREY};
        list-style: none;
        padding-left: 0;
        li {
            margin-bottom: 15px;
            display: flex;
            &:before {
                content: '';
                display: inline-block;
                height: 1em;
                width: 1em;
                background-image: url(${Oval});
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 0.5em;
                flex-shrink: 0;
                margin-top: 3px;
            }
        }
        &.info li::before {
            height: 20px;
            width: 20px;
            background-image: url(${Info});
        }
        ul {
            font-size: 12px;
            padding-left: 20px;
            li:before {
                background-image: url(${Circle});
                font-size: 7px;
                vertical-align: baseline;
                margin-right: 1em;
                margin-top: 6px;
            }
        }
    }
    .success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 340px;
        p {
            font-size: 16px;
            color: ${GREY};
            width: 80%;
            text-align: center;
            margin-top: 15px;
            margin-bottom: 30px;
        }
        a {
            font-size: 16px;
            font-family: sanFranciscoMedium;
            color: ${DARK_BLUE};
        }
    }
`
export const Form1 = styled(FormWrapper)`
    grid-template-columns: initial;
    .tags {
        margin-top: 10px;
    }
`
export const OpeningsSearch = styled.div`
    width: 260px;
    padding: 15px 5px 15px 15px;
    .openings-search-box {
        width: calc(100% - 10px);
    }
    .openings-search-box .ant-input-lg {
        font-size: 12px;
        padding-right: 10px;
    }
    .openings-list {
        max-height: 110px;
        overflow-y: scroll;
        margin: 16px 0 0 0;
    }
    .openings-list::-webkit-scrollbar {
        width: 5px;
    }
    .openings-list::-webkit-scrollbar-thumb {
        background-color: #ebebeb;
        opacity: 0.2;
        border-radius: 2.5px;
    }
    .openings-checkbox {
        margin-top: 15px;
        display: block;
        + .ant-checkbox-wrapper {
            margin-left: 0px;
        }
    }
`
