import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, Button, Skeleton, Tooltip } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import _ from 'lodash'
import ComponentContainer from '../ComponentContainer'
import { GoogleCalendarData, CalendarInfo } from './style'
import './style.scss'
import { ReactComponent as PlusIcon } from '../../../../../assets/Icons/Openings/plus.svg'
import { ReactComponent as EditIcon } from '../../../../../assets/Icons/AccountSettingPage/CalendarEdit.svg'
import { ReactComponent as WarningIcon } from '../../../../../assets/Icons/Notification/warning.svg'
import { ReactComponent as CalendarIcon } from '../../../../../assets/Icons/AccountSettingPage/CalendarIcon.svg'
import { ReactComponent as GoogleIcon } from '../../../../../assets/Icons/Calendar/google_icon.svg'
import { warning } from '../../../../../services/Notification'
import { Modal } from '../../../../Modals'
import { MODAL_TYPES } from '../../../../Helpers/ModalTypes'
import {
    GetAllConnectedAccounts,
    DisconnectAccount,
    SetDefault,
} from '../../../../../services/NetworkCalls/GoogleCalendar'
import { initiateGoogleAuth } from '../../../../Helpers/calendarPopUp'

class GoogleCalendar extends Component {
    constructor(props) {
        super()
        this.state = {
            isDefault: true,
            calendarModelState: false,
            showDisconnectConfirm: false,
            disconnectAccount: null,
        }
    }

    async componentDidMount() {
        await this.props.GetAllConnectedAccounts()
    }

    syncMoreCalendar = () => {
        const status = {
            status: true,
            step: 1,
        }
        this.setState({ calendarModelState: status })
    }

    editAvailability = () => {
        const status = {
            status: true,
            step: 3,
        }
        this.setState({ calendarModelState: status })
    }

    connectAccount = () => {
        const status = {
            status: true,
            step: 1,
        }
        this.setState({ calendarModelState: status })
    }

    handleCancel = () => {
        this.setState({ calendarModelState: false })
    }

    showDisconnectConfirm = value => {
        if (this.props.accounts && this.props.accounts.length > 1 && this.props.defaultAccount.email === value.email) {
            warning(`Make one of the other accounts as default, before disconnecting ${value.email}`)
        } else {
            this.setState({ showDisconnectConfirm: true, disconnectAccount: value })
        }
    }

    disconnectAccount = async () => {
        const { disconnectAccount } = this.state
        this.props.DisconnectAccount(disconnectAccount.email)
        this.setState({ showDisconnectConfirm: false, disconnectAccount: null })
    }

    makeAsDefault = value => {
        this.props.SetDefault(value.email)
    }

    renderNotConnected = () => {
        return (
            <GoogleCalendarData>
                <div className="calendar-data-body">
                    <CalendarIcon />
                    <div>
                        <p className="calendar-data-title">
                            <b>Connect your google calendar</b>
                        </p>
                        <p className="calendar-data-text">
                            Connect your calendar to stay up to date with events scheduled in SpringRecruit
                        </p>
                    </div>
                    <div className="calendar-data-btn" onClick={this.connectAccount}>
                        <button>Connect</button>
                    </div>
                </div>
            </GoogleCalendarData>
        )
    }

    renderConnectedUsers = (value, index, length) => {
        return (
            <div className="calendar-data-body" key={value._id}>
                {value.revoked ? (
                    <Tooltip
                        placement="top"
                        title="This account has been revoked, reconnect to keep using the account."
                    >
                        <WarningIcon style={{ marginLeft: '5px' }} width="31" height="30" />
                    </Tooltip>
                ) : (
                    <CalendarIcon />
                )}
                <div>
                    <p className="calendar-data-title">{value.email}</p>
                    <p className="calendar-data-text">
                        Recruiters will be able to schedule interviews within time slots which you provided
                    </p>
                    <div className="calendar-buttons">
                        {value.revoked ? (
                            <ButtonGroup>
                                <Button
                                    className="google-icon-button"
                                    onClick={() => initiateGoogleAuth('account', value.email)}
                                >
                                    <GoogleIcon className="google-icon" />
                                </Button>
                                <Button
                                    className="disconnect-button"
                                    onClick={() => initiateGoogleAuth('account', value.email)}
                                >
                                    Reconnect with Google
                                </Button>
                            </ButtonGroup>
                        ) : (
                            <ButtonGroup>
                                <Button
                                    className="google-icon-button"
                                    onClick={() => this.showDisconnectConfirm(value)}
                                >
                                    <GoogleIcon className="google-icon" />
                                </Button>
                                <Button className="disconnect-button" onClick={() => this.showDisconnectConfirm(value)}>
                                    Disconnect from Google
                                </Button>
                            </ButtonGroup>
                        )}

                        {length > 1 &&
                            (value.status === 'default' ? (
                                <Button type="primary" className="calendar-default-btn">
                                    Default
                                </Button>
                            ) : (
                                <Button
                                    disabled={value.revoked}
                                    ghost
                                    type="primary"
                                    className="calendar-default-btn"
                                    onClick={() => this.makeAsDefault(value)}
                                >
                                    Make as Default
                                </Button>
                            ))}
                        {value.revoked && (
                            <Button
                                style={{ color: '#FA6400', backgroundColor: '#fff', border: 'solid 1px #fa6400' }}
                                type="primary"
                                className="calendar-default-btn"
                                onClick={() => this.showDisconnectConfirm(value)}
                            >
                                Remove Account
                            </Button>
                        )}
                    </div>
                    {value.status === 'default' ? (
                        <div className="calendar-info">
                            <CalendarInfo>
                                <Icon type="info-circle" theme="filled" className="calendar-info-icon" />
                                All the new events will be added to this calendar.
                            </CalendarInfo>
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }

    render() {
        let { calendarModelState } = this.state
        let { accounts } = this.props
        return (
            <ComponentContainer
                heading="Google Calendar"
                headingOptions={
                    <div className="AccountSettingEditPass">
                        {accounts.length ? (
                            <span onClick={this.syncMoreCalendar}>
                                <Icon component={PlusIcon} className="calendar-plus-icon" />
                                Add Another Calendar
                            </span>
                        ) : null}
                        <span onClick={this.editAvailability}>
                            <Icon component={EditIcon} className="calendar-edit-icon" />
                            {this.props.availability && this.props.availability.length > 0
                                ? 'Edit Availability'
                                : 'Set Availability'}
                        </span>
                    </div>
                }
            >
                {!accounts ? (
                    <Skeleton
                        className="calendar-loaders"
                        avatar
                        title={{ width: '30%' }}
                        paragraph={{
                            rows: 2,
                            width: Array(2).fill('90%'),
                        }}
                        active
                    />
                ) : accounts.length > 0 ? (
                    <GoogleCalendarData>
                        {accounts.map((each, index, fullArray) =>
                            this.renderConnectedUsers(each, index, fullArray.length)
                        )}
                    </GoogleCalendarData>
                ) : (
                    <>{this.renderNotConnected()}</>
                )}

                <Modal
                    type={MODAL_TYPES.CALENDAR_ONBOARDING}
                    visible={!!calendarModelState}
                    bodyStyle={{ padding: 0 }}
                    onCancel={this.handleCancel}
                    redirectPath={'account'}
                    width={750}
                    calPopUpStatus={calendarModelState}
                    closable={true}
                />

                <Modal
                    visible={this.state.showDisconnectConfirm}
                    type={MODAL_TYPES.CONFIRM_GOOGLE_DISCONNECT}
                    onConfirm={this.disconnectAccount}
                    onCancel={() => this.setState({ showDisconnectConfirm: false })}
                />
            </ComponentContainer>
        )
    }
}

const mapStateToProps = state => {
    let { inUse, accounts, availability, sortedAccounts } = state.GCal

    accounts = accounts && _.values(accounts)
    if (accounts && sortedAccounts) {
        accounts.sort((a, b) => {
            if (a.status === 'default') return -1
        })
    }

    return {
        gCalInUse: inUse,
        defaultAccount: (accounts && _.values(accounts).find(account => account.status === 'default')) || null,
        accounts: accounts || [],
        availability: availability || null,
    }
}

export default connect(
    mapStateToProps,
    { GetAllConnectedAccounts, DisconnectAccount, SetDefault }
)(GoogleCalendar)
