import React from 'react'

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 50 44"
            height="40"
            width="50"
        >
            <defs>
                <path
                    id="a"
                    d="M5.128 0h39.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v31.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512C.186 39.302 0 38.655 0 36.872V5.128c0-1.783.186-2.43.534-3.082A3.635 3.635 0 012.046.534C2.698.186 3.345 0 5.128 0z"
                />
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-1142 -105) translate(40 95) translate(1102 10)">
                    <mask fill="#fff">
                        <use xlinkHref="#a" />
                    </mask>
                    <use fill="#FFF" fillOpacity="0" xlinkHref="#a" />
                </g>
                <path
                    stroke="#4767A0"
                    d="M49.5 43.5V.5H5.128C3.578.5 2.94.623 2.282.975A3.135 3.135 0 00.975 2.282C.623 2.941.5 3.578.5 5.128v33.744c0 1.55.123 2.187.475 2.846a3.135 3.135 0 001.307 1.307c.659.352 1.296.475 2.846.475H49.5z"
                    transform="translate(-1142 -105) translate(40 95) translate(1102 10)"
                />
                <path
                    fillRule="nonzero"
                    stroke="#4767A0"
                    strokeWidth="1.5"
                    d="M23.816 33.476c0 .654-.53 1.184-1.184 1.184H11.184c-.654 0-1.184-.53-1.184-1.184V10.184C10 9.53 10.53 9 11.184 9h11.448c.654 0 1.184.53 1.184 1.184v23.292zm17.184 0c0 .654-.53 1.184-1.184 1.184H28.368c-.654 0-1.184-.53-1.184-1.184V10.184c0-.654.53-1.184 1.184-1.184h11.448C40.47 9 41 9.53 41 10.184v23.292z"
                    transform="translate(-1142 -105) translate(40 95) translate(1102 10)"
                />
            </g>
        </svg>
    )
}

export default Icon
