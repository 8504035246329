import React from 'react'
import { Field, FormWrapper } from '../style'
import { Icon, Select } from 'antd'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { connect } from 'react-redux'
const { Option } = Select

const jobDataChange = (changeHandler, allJobs) => value => {
    if (value === undefined) changeHandler({ jobId: value, stageId: value })
    else {
        // if job is changed then pre-fill the first stage
        const { stages = [] } = (value && allJobs.find(job => job._id === value)) || {}
        changeHandler({ jobId: value, stageId: stages[0]._id })
    }
}

const JobStageSelect = ({ allJobs, data = {}, changeHandler, accessLevel, jobDetails = {} }) => {
    const jobId = jobDetails && jobDetails.jobApplication && jobDetails.jobApplication.jobId
    let { stageId } = data
    const { stages = [] } = (jobId && allJobs.find(job => job._id === jobId)) || {}
    return (
        <>
            <Field>
                <div className="title">Opening</div>
                <Select
                    size="large"
                    placeholder={'Select'}
                    getPopupContainer={trigger => trigger.parentNode}
                    suffixIcon={<Icon component={DownIcon} />}
                    value={jobId}
                    disabled={true}
                    onChange={jobDataChange(changeHandler, allJobs)}
                >
                    <Option key="job-unselect-key" value={undefined}>
                        --Select---
                    </Option>
                    {allJobs.map(option => (
                        <Option value={option._id} key={option._id}>
                            {option.jobTitle}
                        </Option>
                    ))}
                </Select>
            </Field>
            <Field>
                <div className="title">Stage</div>
                <Select
                    size="large"
                    placeholder="Select"
                    value={stageId}
                    getPopupContainer={trigger => trigger.parentNode}
                    suffixIcon={<Icon component={DownIcon} />}
                    onChange={value => changeHandler({ stageId: value })}
                >
                    {stages.map(option => {
                        if (accessLevel > 3 && option.stageName === 'JOINED') return null
                        else
                            return (
                                <Option value={option._id} key={option._id}>
                                    {option.stageName}
                                </Option>
                            )
                    })}
                </Select>
            </Field>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.Candidate.data[ownProps.uploadId],
        allJobs: (state.Job.allJobs && state.Job.allJobs.filter(each => each.status)) || [],
        accessLevel: state.Auth.user.accessLevel,
    }
}

export default connect(mapStateToProps)(JobStageSelect)
