import styled from 'styled-components'
import { CustomScrollBar, FlexCenterCol } from '../../Helpers/Stylesheet/styledComponents'
import { Aside as Aside1 } from '../Sourcing/style'
import { FormWrapper } from '../../Modals/AddCandidate/style'
import { DARK_BLUE, LIGHT_GREY } from '../../Helpers/Stylesheet/styledComponents'

export const Container = styled.div`
    width: 100%;
    padding: 30px;
`
export const Body = styled.div`
    display: flex;
`
export const Aside = styled(Aside1)`
    width: 20%;
    background: #ffffff;
    border: 1px solid #ccd7de;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    max-height: initial;
    a {
        display: block;
        margin-left: 26px;
        font-size: 12px;
        color: #999;
        padding-left: 10px;
        line-height: 10px;
        &.active {
            color: #4d679c;
            border-left: 3px solid #4d679c;
            padding-left: 7px;
        }
        + a {
            margin-top: 15px;
        }
    }
    .ant-collapse .ant-collapse-item {
        padding-bottom: 0;
        border-bottom: none !important;
    }
    .ant-collapse-header {
        display: flex;
        align-items: center;
        svg {
            margin-right: 10px;
        }
    }
`
export const Main = styled.div`
    width: 80%;
    margin-left: 30px;
    .cards {
        display: flex;
        flex-wrap: wrap;
        margin: -10px;
        margin-bottom: 30px;
    }
`
export const Card = styled.div`
    width: 204px;
    height: 116px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    border: 1px solid #ccd7de;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: Roboto;
    color: #666666;
    position: relative;
    margin: 10px;
    > svg {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .body {
        ${FlexCenterCol};
        flex: 1;
    }
    .total {
        font-weight: bold;
        font-size: 26px;
    }
    .title {
        font-size: 14px;
    }
    .desc {
        font-weight: 500;
        font-size: 12px;
        background: #f7f7f7;
        width: 100%;
        text-align: center;
        padding: 8px 0;
        border-radius: 0 0 5px 5px;
    }
`
export const Heading = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
`
export const GraphCard = styled.div`
    width: 100%;
    padding: 30px;
    background: #ffffff;
    border: 1px solid #ccd7de;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
`
export const Tabs = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 14px;
    font-weight: 500;
    color: ${LIGHT_GREY};
    display: flex;
    text-transform: uppercase;
    margin-bottom: 15px;
    div {
        cursor: pointer;
        padding: 5px 0;
        + div {
            margin-left: 30px;
        }
    }
    .active {
        color: ${DARK_BLUE};
        border-bottom: 3px solid ${DARK_BLUE};
    }
`
export const TableWrapper = styled.div`
    position: relative;
    .overflow {
        width: 100%;
        max-height: 500px;
        overflow: auto;
        ${CustomScrollBar};
        .icons {
            text-align: right;
            position: absolute;
            top: -25px;
            right: 0;
            svg {
                cursor: pointer;
                + svg {
                    margin-left: 10px;
                }
            }
        }
        table {
            border-radius: 5px;
            border: 1px solid #ccd7de;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
            thead {
                background: #f7f7f7;
                tr {
                    border-bottom: 1px solid #ccd7de;
                }
            }
            tbody tr {
                border-bottom: 1px dashed #ccd7de;
                background: #fff;
            }
            tr {
                height: 60px;
                th {
                    div {
                        display: flex;
                        align-items: center;
                        span {
                            text-align: center;
                        }
                        svg {
                            margin-left: 5px;
                            flex-shrink: 0;
                        }
                    }
                    text-transform: uppercase;
                }
                th,
                td {
                    padding: 0 30px;
                    &.right {
                        text-align: right;
                    }
                }
            }
        }
    }
`
export const Filter = styled(FormWrapper)`
    grid-template-columns: repeat(auto-fit, 204px);
`
