import ContentLoader from 'react-content-loader'
import React from 'react'

export const SourcingLoader = () => (
    <ContentLoader height={40}>
        <rect x="0" y="5" rx="1" ry="1" width="130" height="12" />
        <rect x="150" y="5" rx="1" ry="1" width="125" height="12" />
        <rect x="0" y="30" rx="1" ry="1" width="120" height="12" />
        <rect x="149" y="28" rx="7" ry="7" width="80" height="12" />
        <rect x="235" y="28" rx="7" ry="7" width="80" height="12" />
    </ContentLoader>
)

export const ButtonLoader = () => (
    <ContentLoader height={32}>
        <rect x="0" y="10" rx="2" ry="2" width="65" height="22" />
    </ContentLoader>
)
