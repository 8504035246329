import React, { useState, useEffect } from 'react'
import * as S from './style'
import StepsImg from '../../../assets/Icons/Landing/recruit-best-candidates-free-ats.svg'
import { ReactComponent as Step1Icon } from '../../../assets/Icons/Landing/Steps/1.svg'
import { ReactComponent as Step2Icon } from '../../../assets/Icons/Landing/Steps/2.svg'
import { ReactComponent as Step3Icon } from '../../../assets/Icons/Landing/Steps/3.svg'
import { ReactComponent as Step4Icon } from '../../../assets/Icons/Landing/Steps/4.svg'
import { LOGIN_TEXT } from './constants'
import { DemoButton, StartedButton } from './Components/StaticComponents'

const Steps = ({ isLoggedIn, accessLevel }) => {
    return (
        <S.StepsSection>
            <div className="center">
                <S.H2 underlined>Recruit Top Talent Faster</S.H2>
                <p>
                    SpringRecruit makes it easier to attract interested candidates, evaluate their
                    <br />
                    performance, pick the right talent and improve your recruitment process.
                </p>
            </div>
            <S.StepsBody>
                <S.Steps>
                    <div className="step">
                        <Step1Icon />
                        <div>
                            <S.H3>Job Creation</S.H3>
                            <p>Create openings quickly for roles that are vacant.</p>
                        </div>
                    </div>
                    <div className="step">
                        <Step2Icon />
                        <div>
                            <S.H3>Interview Scheduling</S.H3>
                            <p>Set up interviews and meeting with the shortlisted candidates at various stages.</p>
                        </div>
                    </div>
                    <div className="step">
                        <Step3Icon />
                        <div>
                            <S.H3>Automated Assessments</S.H3>
                            <p>
                                Selecting the right talent by automating assessments across various stages of the hiring
                                process.
                            </p>
                        </div>
                    </div>
                    <div className="step">
                        <Step4Icon />
                        <div>
                            <S.H3>Hiring & Feedback</S.H3>
                            <p>
                                Select the talent that fits best for your team’s requirements and take instant feedback
                                from interviewers to improve candidate
                            </p>
                        </div>
                    </div>
                </S.Steps>
                <img
                    src={StepsImg}
                    title="Recruit Best Candidates with Free ATS"
                    alt="Recruit Best Candidates with Free ATS"
                    className="hide-sm"
                />
                <div className="buttons">
                    <StartedButton isLoggedIn={isLoggedIn} accessLevel={accessLevel} />
                    {/* <DemoButton /> */}
                </div>
            </S.StepsBody>
        </S.StepsSection>
    )
}

export default Steps
