import React, { useState, useEffect } from 'react'
import { Checkbox, Icon, Select } from 'antd'
import moment from 'moment'
import { CardCollapsible, Title, Message } from '../style'
import { ReactComponent as ExpandIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Btn.svg'
import { ReactComponent as CollapseIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand or Collapse Btn.svg'
import { ReactComponent as LockIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/lock.svg'
import { ReactComponent as AttachIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/attach.svg'
import RemoveIcon from '../../../../assets/Icons/AccountSettingPage/Remove Button'
import DocIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/docx'
import XlsIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/xls'
import PdfIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/pdf'
import ImgIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/img'
import StyledButton from '../../../../container/Components/StyledButton/index.js'
import getAvatar from '../../../../services/getAvatar'

export const MessageView = ({ active, email, user, teamMembers, candidate, updateEmailPrivacy, setReply }) => {
    const [isActive, setIsActive] = useState(active)
    const [isPrivate, setIsPrivate] = useState(email.privacy_options.private)
    const [allowedToView, setAllowedToView] = useState(email.privacy_options.allowedToView)
    const [showUserList, setShowUserList] = useState(false)
    const [searchText, setSearchText] = useState('')

    const getDate = date => {
        if (moment(date).isBetween(moment().startOf('day'), moment().endOf('day'), 'date', [])) {
            return moment(date).format('hh:mm A [Today]')
        } else {
            return moment(date).format('hh:mm A Do MMMM, Y')
        }
    }

    const updatePrivacy = () => {
        const data = {
            privacy_options: {
                private: !isPrivate,
                allowedToView,
            },
            universalCandidateId: candidate._id,
        }
        updateEmailPrivacy(data, email._id)
        setIsPrivate(!isPrivate)
        setShowUserList(false)
    }

    const addUser = user => {
        const allowedUsers = [...allowedToView, user]
        const data = {
            privacy_options: {
                private: isPrivate,
                allowedToView: allowedUsers,
            },
            universalCandidateId: candidate._id,
        }
        updateEmailPrivacy(data, email._id)
        setAllowedToView(allowedUsers)
        setSearchText('')
    }

    const removeUser = userId => {
        const remainingViewers = allowedToView.filter(mem => mem !== userId)
        const data = {
            privacy_options: {
                private: isPrivate,
                allowedToView: remainingViewers,
            },
            universalCandidateId: candidate._id,
        }
        updateEmailPrivacy(data, email._id)
        setAllowedToView(remainingViewers)
    }

    const displayAttachment = file => {
        const filePathArray = file.link.split('.')
        const ext = filePathArray[filePathArray.length - 1]

        let icon = PdfIcon
        if (['xls', 'xlxs', 'excel', 'csv'].includes(ext)) {
            icon = XlsIcon
        } else if (['jpg', 'jpeg', 'png', 'svg'].includes(ext)) {
            icon = ImgIcon
        } else if (['doc', 'docx'].includes(ext)) {
            icon = DocIcon
        }

        return (
            <div className="message-files" key={file.link}>
                <Icon component={icon} />
                <div className="sample-pdf">{file.name}</div>
            </div>
        )
    }

    return (
        <Message active={isActive}>
            {!isActive ? (
                <div className="header">
                    <div>
                        <div className="title">{email.subject}</div>
                        <div className="sub-title">{user.userName}</div>
                    </div>
                    <div className="right">
                        <div>
                            <div className="when">
                                <span>{getDate(email.createdAt)}</span>
                            </div>
                            <div className="icons">
                                {email.privacy_options.private ? <LockIcon /> : null}
                                {email.attachments.length ? <AttachIcon /> : null}
                            </div>
                        </div>
                        <ExpandIcon onClick={() => setIsActive(true)} />
                    </div>
                </div>
            ) : (
                <div className="content">
                    <div className="compose">
                        <div className="header">
                            <div className="flex">
                                <div className="avatar">{getAvatar(user.userName)}</div>
                                <div>
                                    <div className="name">{user.userName}</div>
                                    <div className="field">
                                        <label>From:</label>
                                        {teamMembers.find(mem => mem._id === user.id).email}
                                    </div>
                                    <div className="field">
                                        <label>To:</label>
                                        {candidate.email}
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div>
                                    <div className="when">
                                        <span>{getDate(email.createdAt)}</span>
                                    </div>
                                    <div className="icons">
                                        {email.privacy_options.private ? <LockIcon /> : null}
                                        {email.attachments.length ? <AttachIcon /> : null}
                                    </div>
                                </div>
                                <CollapseIcon onClick={() => setIsActive(false)} />
                            </div>
                        </div>
                        <div className="divider"></div>
                        <p dangerouslySetInnerHTML={{ __html: email.content }}></p>
                        <div className="divider"></div>
                        <div className="attachments">
                            {email.attachments && email.attachments.length
                                ? email.attachments.map(file => displayAttachment(file))
                                : null}
                            <div hidden={user.accessLevel > 2} className="checkbox">
                                <Checkbox checked={isPrivate} onChange={updatePrivacy}>
                                    Mark as Private <LockIcon />
                                </Checkbox>
                            </div>
                        </div>
                        {isPrivate && user.accessLevel < 3 && (
                            <div className="cpm-private-cont">
                                <div className="cpm-private-message">
                                    Note: This message is only visible to you,{' '}
                                    {allowedToView.map(mem => {
                                        let displayName = teamMembers.find(x => x._id === mem)
                                        displayName = displayName.fullName || displayName.email
                                        return (
                                            <React.Fragment key={mem}>
                                                <div className="an-nn-sel-user1 top-margin-7">
                                                    <div>{displayName}</div>
                                                    <Icon component={RemoveIcon} onClick={() => removeUser(mem)} />
                                                </div>
                                                , &nbsp;
                                            </React.Fragment>
                                        )
                                    })}
                                    {candidate.name}
                                    &nbsp;, admins and super admins.
                                </div>
                                {!showUserList ? (
                                    <div className="add-more-people-who" onClick={() => setShowUserList(true)}>
                                        Add more people who can view this note?
                                    </div>
                                ) : (
                                    <Select
                                        className="an-nn-search"
                                        placeholder="Search team member to add"
                                        notFoundContent={null}
                                        showSearch
                                        suffixIcon={<Icon type="search" />}
                                        defaultActiveFirstOption={false}
                                        onSelect={addUser}
                                        value={searchText}
                                        onSearch={value => setSearchText(value)}
                                        filterOption
                                        optionFilterProp="title"
                                    >
                                        {teamMembers
                                            .filter(
                                                mem =>
                                                    mem.status &&
                                                    mem._id !== user.id &&
                                                    [1, 2].includes(mem.accessLevel) &&
                                                    !allowedToView.includes(mem._id)
                                            )
                                            .map(mem => (
                                                <Select.Option
                                                    key={mem._id}
                                                    className="an-nn-opt"
                                                    title={mem.fullName + ' ' + mem.email}
                                                >
                                                    <div className="an-nn-name">{mem.fullName || mem.email}</div>
                                                    <div className="an-nn-add">Add</div>
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </div>
                        )}
                        <div className="buttons">
                            <StyledButton capitalize width={'125px'} height={'44px'} onClick={() => setReply(email)}>
                                <span>Reply</span>
                            </StyledButton>
                        </div>
                    </div>
                </div>
            )}
        </Message>
    )
}
