import React, { Component } from 'react'
import './../style.css'
import { Container, CultureUpload } from '../style'
import { Upload, Row, Col, Form, Typography, Input, Tooltip, Icon, Modal } from 'antd'
import AntdCropper from '../../../Helpers/AntdCropper'
import config from '../../../../config'
import { store } from '../../../../redux/store'
import { success, warning } from '../../../../services/Notification'
import { ReactComponent as Up } from '../../../../assets/svg/career-up.svg'
import { ReactComponent as Down } from '../../../../assets/svg/career-down.svg'
import { ReactComponent as Edit } from '../../../../assets/svg/career-edit.svg'
import { CULTURE_LIMITS, VALIDATION_STATUS } from '../DataConfigs'
import SaveContainer from './SaveContainer'

const { Text } = Typography
const { TextArea } = Input

const { baseURL: apiUrl, apiVersion } = config.config()
const apiType = {
    restricted: 'me/',
    public: '',
}

const { DATA_COMPLETE_ERROR, NO_TITLE_ERROR, TITLE, TITLE_ERROR } = CULTURE_LIMITS

const baseUrl_v2 = apiUrl + apiVersion.v2

class Culture extends Component {
    resetImageUrlData = () => {
        // setting in <url,uid> format for antd.
        return (
            (this.props.culture &&
                this.props.culture.image_urls &&
                this.props.culture.image_urls.map((each, index) => ({
                    url: each,
                    uid: index,
                    status: 'done',
                }))) ||
            []
        )
    }

    state = {
        editFlag: false,
        image_urls: this.resetImageUrlData(),
        previewVisible: false,
        previewImage: '',
        validate_title_status: VALIDATION_STATUS.success,
        validate_title_help: '',
    }

    onSubmit = e => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const { image_urls } = this.state
                    const imageUploadedUrls =
                        image_urls.filter(each => each.status === 'done' && (each.url || each.response.url)) || []
                    if (imageUploadedUrls.length !== image_urls.length) {
                        warning(CULTURE_LIMITS.IMAGE_ERROR)
                        return null
                    }
                    if ((imageUploadedUrls.length > 0 || values.description.length > 0) && values.title.length === 0) {
                        this.setState({
                            validate_title_status: VALIDATION_STATUS.error,
                            validate_title_help: NO_TITLE_ERROR,
                        })
                        return null
                    }
                    // DATA Check: Title without image or description not allowed.
                    if (!(values.title.length > 0 && values.description.length > 0)) {
                        if (
                            values.title.length > 0 &&
                            imageUploadedUrls.length === 0 &&
                            values.description.length === 0
                        ) {
                            warning(DATA_COMPLETE_ERROR)
                            return null
                        }
                    }
                    if (values.title.length > TITLE) {
                        this.setState({
                            validate_title_status: VALIDATION_STATUS.error,
                            validate_title_help: TITLE_ERROR,
                        })
                        return null
                    }
                    values.image_urls = imageUploadedUrls.map(each => each.url || each.response.url)
                    values.position = this.props.index

                    this.props.onSubmit(values, this.props.index, 'culture')
                    this.setState({ editFlag: false })
                    this.props.updateEditModeCount('culture', 1)
                    success('CULTURE section saved successfully')
                } catch (err) {
                    warning(err.message || err)
                }
            }
        })
    }

    onCancel = () => {
        const { culture } = this.props
        this.props.form.setFieldsValue({
            title: culture.title,
            description: culture.description,
        })
        this.setState({
            editFlag: false,
            image_urls: this.resetImageUrlData(),
        })
        this.props.updateEditModeCount('culture', -1)
    }

    moveUp = () => {
        this.props.moveUp(this.props.index)
    }

    moveDown = () => {
        this.props.moveDown(this.props.index)
    }

    edit = () => {
        this.setState({ editFlag: true })
        this.props.updateEditModeCount('culture', 0)
    }

    handleChange = value => {
        this.setState({ editFlag: true })
        this.props.updateEditModeCount('culture', 0)
    }

    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = async file => {
        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        }

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        })
    }

    handleTitleChange = ({ target: { value } }) => {
        const successIndicator = {
            validate_title_status: VALIDATION_STATUS.success,
            validate_title_help: '',
        }

        this.setState(state => {
            if (value.length > 0) return { editFlag: true, ...successIndicator }
            return { editFlag: true }
        })
        this.props.updateEditModeCount('culture', 0)
    }

    handleChangeIMAGE = ({ fileList }) => {
        this.setState({
            image_urls: fileList.splice(0, CULTURE_LIMITS.IMAGE_UPLOADS),
            editFlag: true,
        })
        this.props.updateEditModeCount('culture', 0)
    }

    beforeUpload = file => {
        const isValidFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
        if (!isValidFormat) {
            warning(CULTURE_LIMITS.IMAGE_TYPE)
            return false
        }
        const isLt8M = file.size / 1024 / 1024 < 8
        if (!isLt8M) {
            warning(CULTURE_LIMITS.IMAGE_TYPE)
            return false
        }
        return true
    }

    handleRemove = file => {
        let { image_urls } = this.state

        image_urls = image_urls.filter(each => {
            if (file.response) {
                return each.url !== file.response.url
            } else {
                return each.url !== file.url
            }
        })

        this.setState({ image_urls, editFlag: true })
        return true
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { image_urls, previewVisible, previewImage } = this.state
        const { culture } = this.props
        const { companyId } = store.getState().Auth.user
        const baseUrl = baseUrl_v2 + apiType.restricted + `career-page/${companyId}/upload/image` // TODO: UPDATE THIS
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        )

        const props = {
            name: 'image',
            action: baseUrl,
            headers: {
                authorization: store.getState().Auth.idToken,
            },
            multiple: true,
            showUploadList: true,
            beforeUpload: this.beforeUpload,
            onPreview: this.handlePreview,
            onChange: this.handleChangeIMAGE,
            accept: '.jpg, .png, .jpeg',
            onRemove: this.handleRemove,
        }

        return (
            <div key={this.props.key}>
                <Container>
                    <Row gutter={48}>
                        <Col span={12}>
                            <Text className="carrer-cont-title">OUR CULTURE</Text>
                        </Col>
                        <Col span={12}>
                            <div className="career-cont-btn">
                                {this.props.index >= 1 ? (
                                    <div className="career-section-icons" onClick={this.moveUp}>
                                        <Icon className="career-icons" component={Up} />
                                    </div>
                                ) : null}
                                {this.props.index < this.props.length - 1 ? (
                                    <div className="career-section-icons" onClick={this.moveDown}>
                                        <Icon className="career-icons" component={Down} />
                                    </div>
                                ) : null}
                                <div className="career-section-icons" onClick={this.edit}>
                                    <Icon className="career-icons" component={Edit} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={48}>
                        <Col span={12}>
                            <Row>
                                <Text className="carrer-cont-title">Title</Text>
                                <Form.Item
                                    validateStatus={this.state.validate_title_status}
                                    help={this.state.validate_title_help}
                                >
                                    {getFieldDecorator('title', {
                                        initialValue: culture.title || '',
                                    })(
                                        <Input
                                            size="large"
                                            placeholder="OUR CULTURE"
                                            onChange={this.handleTitleChange}
                                        />
                                    )}
                                </Form.Item>
                            </Row>

                            <Row>
                                <Text className="carrer-cont-title">Description</Text>
                                <Form.Item>
                                    {getFieldDecorator('description', {
                                        initialValue: culture.description || '',
                                        rules: [
                                            {
                                                max: CULTURE_LIMITS.DESCRIPTION,
                                                message: CULTURE_LIMITS.DESCRIPTION_ERROR,
                                            },
                                        ],
                                    })(
                                        <TextArea
                                            rows={6}
                                            placeholder="Enter your description"
                                            onChange={this.handleChange}
                                        />
                                    )}
                                </Form.Item>
                            </Row>
                        </Col>

                        <Col span={12}>
                            <Text className="carrer-cont-title">
                                Upload Images
                                <Tooltip
                                    placement="top"
                                    title="You can upload maximum of 6 images in the size of 768 * 430"
                                    overlayClassName="career-tooltip"
                                >
                                    <Icon type="info-circle" className="career-img-tooltip" theme="filled" />
                                </Tooltip>
                            </Text>

                            <CultureUpload list={image_urls}>
                                <AntdCropper height={1} width={3} useRatio={true} resize={false} resizeAndDrag={true}>
                                    <Upload {...props} listType="picture-card" fileList={image_urls}>
                                        {image_urls.length >= 6 ? null : uploadButton}
                                    </Upload>
                                </AntdCropper>
                                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </CultureUpload>
                        </Col>
                    </Row>
                    {this.state.editFlag && <SaveContainer onSubmit={this.onSubmit} onCancel={this.onCancel} />}
                </Container>
            </div>
        )
    }
}

export default Form.create({ name: 'culture' })(Culture)
