import { Bottom, Icons, Info, Label, Name } from '../../style'
import { Menu, Popover, Tooltip } from 'antd'
import React, { useState } from 'react'
import moment from 'moment'
import { EXPERIENCE_OPTIONS } from '../../../../Helpers/constants'
import { currencyUnitAndValueCalculator } from '../../../../Helpers/currencies'
import { ReactComponent as EnvelopeIcon } from '../../../../../assets/Icons/Talent Pool/envelope.svg'
import { ReactComponent as PhoneIcon } from '../../../../../assets/Icons/Talent Pool/phone.svg'
import { ReactComponent as ResumeIcon } from '../../../../../assets/Icons/Talent Pool/resume.svg'
import { ReactComponent as EyeIcon } from '../../../../../assets/Icons/Talent Pool/eye.svg'
import { ReactComponent as EyeSlashIcon } from '../../../../../assets/Icons/Talent Pool/eyeslash.svg'
import { ReactComponent as StarIcon } from '../../../../../assets/Icons/Talent Pool/star.svg'
import { ReactComponent as StarFilledIcon } from '../../../../../assets/Icons/Talent Pool/Star Filled.svg'
import { ReactComponent as ThreedotsIcon } from '../../../../../assets/Icons/Talent Pool/three-dots.svg'
import { ReactComponent as TagIcon } from '../../../../../assets/Icons/Talent Pool/tag.svg'
import { Tags } from '../../../../../container/Modals/style'
import { TagBar } from '../../../TalentPool/style'
import { StyledButton } from '../../../../Components'
import { EVENTS } from '../../constants'
import { GreyTags } from '../../../../Modals/AddTag/style'
import { DataCard } from '../../../../../container/Pages/Analytics/style'
import config from '../../../../../config'

const { isMobileDevice } = config

export const OpeningTags = ({ interested_jobs }) => (
    <Label>
        <div className="title">Interested Job Openings</div>
        <GreyTags noMargins>
            {interested_jobs.slice(0, 3).map(job => (
                <span key={job.professionId}>{job.title}</span>
            ))}
            {interested_jobs.length > 3 ? (
                <Tooltip
                    title={
                        <>
                            {interested_jobs.slice(3).map(job => (
                                <div style={{ margin: 0 }} key={job.professionId}>
                                    {job.title}
                                </div>
                            ))}
                        </>
                    }
                >
                    <GreyTags noMargins>
                        <span>{`+${interested_jobs.length - 3} more`}</span>
                    </GreyTags>
                </Tooltip>
            ) : null}
        </GreyTags>
    </Label>
)

export const SkillsTag = ({ key_skills }) => (
    <Label>
        <div className="title">Key skills</div>
        <span>
            {key_skills
                .slice(0, 3)
                .map(skill => skill.name)
                .join(', ')}

            {key_skills.length > 3 ? (
                <Tooltip
                    title={
                        <>
                            {key_skills.slice(3).map(skill => (
                                <div key={skill.skillId}>{skill.name}</div>
                            ))}
                        </>
                    }
                >
                    <span className="more">{`, +${key_skills.length - 3} more`}</span>
                </Tooltip>
            ) : null}
        </span>
    </Label>
)

export const CardBottom = ({ data }) => {
    const { location, experience, createdAt, ctc, relocation } = data
    const currentCTCArray = currencyUnitAndValueCalculator(ctc.current, ctc.currency)
    const expectedCTCArray = currencyUnitAndValueCalculator(ctc.expected, ctc.currency)
    return isMobileDevice ? (
        <DataCard>
            <ul>
                <li>
                    <span>Location:</span>
                    <span className="value">{location || 'NA'}</span>
                </li>
                <li>
                    <span>Current CTC:</span>
                    <span className="value">
                        {ctc.current ? `${ctc.currency} ${currentCTCArray[1]} ${currentCTCArray[2]}` : 'NA'}
                    </span>
                </li>
                <li>
                    <span>Added on:</span>
                    <span className="value">{moment(createdAt).format('DD MMM, Y')}</span>
                </li>
                <li>
                    <span>Experience:</span>
                    <span className="value">
                        {typeof experience.totalYears === 'number' ? EXPERIENCE_OPTIONS[experience.totalYears] : 'NA'}
                    </span>
                </li>
                <li>
                    <span>Expected CTC:</span>
                    <span className="value">
                        {ctc.expected ? `${ctc.currency} ${expectedCTCArray[1]} ${expectedCTCArray[2]}` : 'NA'}
                    </span>
                </li>
                <li>
                    <span>Relocation:</span>
                    <span className="value">{relocation ? 'Yes' : 'No'}</span>
                </li>
            </ul>
        </DataCard>
    ) : (
        <Bottom>
            <tbody>
                <tr>
                    <td>Location:</td>
                    <td className="value">{location || 'NA'}</td>
                    <td>Current CTC:</td>
                    <td className="value">
                        {ctc.current ? `${ctc.currency} ${currentCTCArray[1]} ${currentCTCArray[2]}` : 'NA'}
                    </td>
                    <td>Added on:</td>
                    <td className="value">{moment(createdAt).format('DD MMM, Y')}</td>
                </tr>
                <tr>
                    <td>Experience:</td>
                    <td className="value">
                        {typeof experience.totalYears === 'number' ? EXPERIENCE_OPTIONS[experience.totalYears] : 'NA'}
                    </td>
                    <td>Expected CTC:</td>
                    <td className="value">
                        {ctc.expected ? `${ctc.currency} ${expectedCTCArray[1]} ${expectedCTCArray[2]}` : 'NA'}
                    </td>
                    <td>Relocation:</td>
                    <td className="value">{relocation ? 'Yes' : 'No'}</td>
                </tr>
            </tbody>
        </Bottom>
    )
}

export const CardInfo = ({ data: { _id, name, email, phone, interested_jobs, key_skills } }) => (
    <Info>
        <a href={`/candidate/sourcing/${_id}`} target="_blank" rel="noopener noreferrer">
            <Name>{name}</Name>
        </a>
        <Label>
            <span>
                <EnvelopeIcon />
                {email}
            </span>
            <span>
                <PhoneIcon />
                {`+${phone.country_code}${phone.number}`}
            </span>
        </Label>
        <OpeningTags interested_jobs={interested_jobs} />
        <SkillsTag key_skills={key_skills} />
    </Info>
)

export const CardActions = ({
    setResumeLink,
    updateCandidate,
    actionMenuClicked,
    setShowMoreOptions,
    data: { resume, assignedJob, starred, hidden },
    showMoreOptions,
    bulkMode,
}) => {
    const resumeComponent = (
        <Tooltip title="View resume">
            <ResumeIcon onClick={() => setResumeLink(resume.current)} />
        </Tooltip>
    )

    if (bulkMode)
        return (
            <Icons>
                <div className="icons">{resumeComponent}</div>
                {assignedJob && <div className="assigned">Assigned</div>}
            </Icons>
        )

    return (
        <Icons>
            <div className="icons">
                {resume.current && resumeComponent}

                <Tooltip title={starred ? 'Unmark from favourite' : 'Mark as favourite'}>
                    {starred ? (
                        <StarFilledIcon onClick={() => updateCandidate(EVENTS.MARK_FAV, !starred)} />
                    ) : (
                        <StarIcon onClick={() => updateCandidate(EVENTS.MARK_FAV, !starred)} />
                    )}
                </Tooltip>
                <Tooltip title={hidden ? 'View' : 'Hide'}>
                    {hidden ? (
                        <EyeSlashIcon onClick={() => updateCandidate(EVENTS.HIDE_CANDIDATE, !hidden)} />
                    ) : (
                        <EyeIcon onClick={() => updateCandidate(EVENTS.HIDE_CANDIDATE, !hidden)} />
                    )}
                </Tooltip>
                <Popover
                    content={
                        <Menu className="tp-card-menu">
                            <Menu.Item className="tp-card-menu-items" onClick={() => actionMenuClicked(1)}>
                                Add Note
                            </Menu.Item>
                            <Menu.Item className="tp-card-menu-items" onClick={() => actionMenuClicked(2)}>
                                Send Message
                            </Menu.Item>
                            {!assignedJob && (
                                <Menu.Item className="tp-card-menu-items" onClick={() => actionMenuClicked(3)}>
                                    Add to opening
                                </Menu.Item>
                            )}
                            <Menu.Item className="tp-card-menu-items" onClick={() => actionMenuClicked(4)}>
                                Add Tag
                            </Menu.Item>
                        </Menu>
                    }
                    trigger="click"
                    placement="bottomRight"
                    arrowPointAtCenter
                    visible={showMoreOptions}
                    onVisibleChange={setShowMoreOptions}
                >
                    <ThreedotsIcon />
                </Popover>
            </div>
            {assignedJob && <div className="assigned">Assigned</div>}
        </Icons>
    )
}

export const BottomTags = ({ tags, setShowAddTag, bulkMode }) => {
    const [showMore, setShowMore] = useState(false)

    return (
        <TagBar sourcing>
            {bulkMode ? (
                <TagIcon />
            ) : (
                <StyledButton
                    capitalize
                    type="inverted"
                    width="80px"
                    height="30px"
                    className="button"
                    onClick={() => setShowAddTag(true)}
                >
                    <TagIcon />
                    Add Tag
                </StyledButton>
            )}
            <div>
                {showMore
                    ? tags.map(tag => <span key={tag.tagId}>{tag.name}</span>)
                    : tags.slice(0, 7).map(tag => <span key={tag.tagId}>{tag.name}</span>)}
                {tags.length > 7 ? (
                    <a onClick={() => setShowMore(!showMore)}>{showMore ? 'Show less' : `+${tags.length - 7} more`}</a>
                ) : null}
            </div>
        </TagBar>
    )
}
