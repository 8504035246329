import React, { useState, useEffect } from 'react'
import * as S from './style'
import { StartedButton } from './Components/StaticComponents'

const WhyUs = ({ isLoggedIn, accessLevel }) => {
    const [more, showMore] = useState(false)
    return (
        <S.WhyUs>
            <div className="card">
                <S.H2>Why is SpringRecruit Free-Forever?</S.H2>
                <p>
                    Most Applicant Tracking Systems are expensive and unaffordable for small businesses and startups. So
                    they end up using a combination of Excel/Google Sheets and emails, which is not even close to an ATS
                    in terms of efficiency.
                    {!more ? (
                        <>
                            ........( <a onClick={() => showMore(true)}>ReadMore</a> )
                        </>
                    ) : null}
                </p>
                {more ? (
                    <>
                        <p>
                            With the no-code movement gaining ground, 2-3 years down the line teams will use some
                            combination of these no-code tools to build their own ATSs. Even mid-size, growth stage
                            companies may choose not to pay for an ATS.
                        </p>
                        <p>
                            Acting on this thesis, we have gone ahead and built SpringRecruit today. It's built with
                            code which means it's 10x better than something built with no-code, but still free for teams
                            to solve their need of a streamlined ATS
                        </p>
                    </>
                ) : null}
                <div className="buttons">
                    <StartedButton isLoggedIn={isLoggedIn} accessLevel={accessLevel} />
                    {/* <a href="https://calendly.com/ishika-jani/30min">
                        <S.Button secondary>REQUEST DEMO</S.Button>
                    </a> */}
                </div>
            </div>
        </S.WhyUs>
    )
}

export default WhyUs
