import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { Select, Icon, Popover, Input, Checkbox, DatePicker, Button } from 'antd'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { ReactComponent as CalendarIcon } from '../../../../assets/Icons/Analytics/calendar.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/Icons/Analytics/cross.svg'
import { Field } from '../../../Modals/AddCandidate/style'
import StyledButton from '../../../Components/StyledButton'
import { SearchDropdown, Footer } from '../style'
import { BORDER_COLOR, DARK_BLUE, WHITE, WHITE_SMOKE } from '../../../Helpers/Stylesheet/styledComponents'
import { BROWSER_TYPE } from '../../../Helpers/constants'
import config from '../../../../config'

const { Option } = Select
const { isMobileDevice } = config

const CheckboxDropdown = ({ data, search, apply, type, applyFilteredData, selectedValues }) => {
    const [inputValue, setInputValue] = useState('')
    const [filteredValues, setFilteredValues] = useState(selectedValues)

    const sortedData = [...selectedValues]
    data.forEach(each => {
        if (!selectedValues.includes(each)) sortedData.push(each)
    })

    return (
        <SearchDropdown search={search} isMobileDevice={isMobileDevice}>
            {search ? (
                <Input
                    size="large"
                    className="search-box"
                    value={inputValue}
                    placeholder="Search"
                    suffix={<Icon type="search" />}
                    onChange={({ target: { value } }) => setInputValue(value)}
                />
            ) : null}
            <div className="search-list">
                {sortedData
                    .filter(element => {
                        return element
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.trim().toLowerCase())
                    })
                    .map((element, index) => (
                        <Checkbox
                            key={index}
                            className="search-checkbox"
                            checked={filteredValues.includes(element)}
                            onChange={({ target: { checked } }) => {
                                if (checked) setFilteredValues([...filteredValues, element])
                                else setFilteredValues(filteredValues.filter(value => value !== element))
                                if (!apply) {
                                    if (checked) applyFilteredData(type, [...selectedValues, element])
                                    else applyFilteredData(type, selectedValues.filter(value => value !== element))
                                }
                            }}
                        >
                            {element}
                        </Checkbox>
                    ))}
            </div>
            {apply ? (
                <p className="search-apply" onClick={() => applyFilteredData(type, filteredValues)}>
                    APPLY
                </p>
            ) : null}
        </SearchDropdown>
    )
}

const StyledDropdown = ({ data, setData, setFilterValue }) => {
    const [inputValue, setInputValue] = useState('')
    return (
        <SearchDropdown search={true} isMobileDevice={isMobileDevice}>
            <Input
                size="large"
                className="search-box"
                value={inputValue}
                placeholder="Search"
                suffix={<Icon type="search" />}
                onChange={({ target: { value } }) => setInputValue(value)}
            />
            <div className="search-list mt20">
                {data
                    .filter(element => {
                        return element
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.trim().toLowerCase())
                    })
                    .map((element, index) => (
                        <p
                            key={index}
                            className="search-list-option"
                            onClick={() => {
                                setFilterValue(element)
                                setData(element)
                            }}
                        >
                            {element}
                        </p>
                    ))}
            </div>
        </SearchDropdown>
    )
}

const CheckboxFilter = ({
    title,
    data,
    search = true,
    apply = true,
    type,
    setFilteredData,
    applyFilteredData,
    resetFilter,
    selectedValues,
}) => {
    return (
        <Field>
            <div className="title">{title}</div>
            <Popover
                arrowPointAtCenter
                placement="bottom"
                trigger="click"
                content={
                    <CheckboxDropdown
                        data={data}
                        search={search}
                        apply={apply}
                        type={type}
                        setFilteredData={setFilteredData}
                        applyFilteredData={applyFilteredData}
                        selectedValues={selectedValues}
                    />
                }
                getPopupContainer={trigger => trigger.parentNode}
                destroyTooltipOnHide={true}
                overlayClassName={classNames({
                    'filter-popover': isMobileDevice,
                })}
            >
                <div className="openings">
                    {selectedValues.length ? (
                        <div className="selected">
                            <div className="selected-view">
                                <span>{selectedValues.length}</span>
                                <CloseIcon onClick={() => resetFilter(type)} />
                            </div>
                            <span>Values are selected</span>
                        </div>
                    ) : (
                        'Select'
                    )}
                    <DownIcon className="down-icon" />
                </div>
            </Popover>
        </Field>
    )
}

const SingleOptionFilter = ({ title, data, selectedJob, setData }) => {
    const [filterValue, setFilterValue] = useState(selectedJob)
    return (
        <Field>
            <div className="title">{title}</div>
            <Popover
                arrowPointAtCenter
                placement="bottom"
                trigger="hover"
                content={<StyledDropdown data={data} setFilterValue={setFilterValue} setData={setData} />}
                getPopupContainer={trigger => trigger.parentNode}
                destroyTooltipOnHide={true}
                overlayClassName={classNames({
                    'filter-popover': isMobileDevice,
                })}
            >
                <div className="openings">
                    {filterValue || 'Select'}
                    <DownIcon className="down-icon" />
                </div>
            </Popover>
        </Field>
    )
}

const DateFilter = ({ applyFilteredData, start, end }) => {
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(end)
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('All')

    useEffect(() => {
        if (start && end) setValue(`${start.format('DD MMM YYYY')} - ${end.format('DD MMM YYYY')}`)
        else if (start) setValue(`${start.format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')}`)
        setStartDate(start)
        setEndDate(end)
    }, [start, end])

    return (
        <Field>
            <div className="title">Filter by date</div>
            <DatePicker
                size="large"
                allowClear={false}
                showToday={false}
                value={null}
                placeholder={value}
                open={open}
                onOpenChange={() => {
                    //TODO: Improve this logic
                    if (open) setOpen(false)
                    if (BROWSER_TYPE === 'Firefox' && !open) setOpen(true)
                }}
                onFocus={() => {
                    if (BROWSER_TYPE === 'Other') setOpen(true)
                }}
                suffixIcon={<CalendarIcon onClick={() => setOpen(true)} />}
                disabledDate={current => current.isAfter(moment())}
                onChange={date => {
                    setOpen(true)
                    if (!startDate) setStartDate(date)
                    else if (startDate && endDate && date.isBefore(startDate)) setStartDate(date)
                    else if (startDate && !endDate && date.isBefore(startDate)) {
                        setEndDate(startDate)
                        setStartDate(date)
                    } else if (startDate && date.isAfter(startDate)) setEndDate(date)
                }}
                dateRender={current => {
                    const style = {}
                    if (current.isAfter(moment())) {
                        style.backgroundColor = WHITE_SMOKE
                    } else {
                        style.cursor = 'pointer'
                        if (
                            (startDate && current.isSame(startDate, 'day')) ||
                            (endDate && current.isSame(endDate, 'day'))
                        ) {
                            style.backgroundColor = DARK_BLUE
                            style.color = WHITE
                            style.borderRadius = '10%'
                        } else if (current.isSame(moment(), 'day')) {
                            style.border = `1px solid ${DARK_BLUE}`
                            style.borderRadius = '50%'
                        } else if (startDate && endDate && current.isBetween(startDate, endDate)) {
                            style.backgroundColor = BORDER_COLOR
                        }
                    }
                    return <div style={style}>{current.date()}</div>
                }}
                renderExtraFooter={() => (
                    <Footer>
                        <div className="footer-date p40">
                            <span className="footer-date-text">Start</span>
                            <Input
                                size="small"
                                className="footer-date-input"
                                value={startDate && startDate.format('DD/MM/YYYY')}
                            ></Input>
                        </div>
                        <div className="footer-date bTop p40">
                            <span className="footer-date-text">To</span>
                            <Input
                                size="small"
                                className="footer-date-input"
                                value={endDate && endDate.format('DD/MM/YYYY')}
                            ></Input>
                        </div>
                        <div className="footer-date p10 mb10">
                            <StyledButton
                                width="84px"
                                height="32px"
                                borderRadius="0px"
                                type="inverted"
                                onClick={() => {
                                    setStartDate(null)
                                    setEndDate(null)
                                }}
                            >
                                Clear
                            </StyledButton>
                            <StyledButton
                                width="84px"
                                height="32px"
                                borderRadius="0px"
                                onClick={() => {
                                    let value = 'All'
                                    if (startDate && endDate)
                                        value = `${startDate.format('DD MMM YYYY')} - ${endDate.format('DD MMM YYYY')}`
                                    else if (startDate)
                                        value = `${startDate.format('DD MMM YYYY')} - ${moment().format('DD MMM YYYY')}`
                                    setValue(value)
                                    applyFilteredData('date', [startDate, endDate])
                                    setOpen(false)
                                }}
                            >
                                Apply
                            </StyledButton>
                        </div>
                    </Footer>
                )}
            />
        </Field>
    )
}

export default { CheckboxFilter, SingleOptionFilter, DateFilter }
