import styled from 'styled-components'
import {
    GREY,
    DARK_BLUE,
    LIGHT_GREY,
    FlexCenterRow,
    FlexCenterCol,
    BLACK,
    BACKGROUND_COLOR,
} from '../../Helpers/Stylesheet/styledComponents'
import { Input, Button } from 'antd'
import { CustomScrollBar } from '../../Helpers/Stylesheet/styledComponents'

const { Search } = Input

const commonHeadingStyles = `
    font-size: 12px;
    font-weight: bold;
    color: #333333 !important;
    text-transform: uppercase;
`
export const Wrapper = styled.div`
    background-color: #f7f8fc;
`

export const EmptyBox = styled.div`
    ${FlexCenterCol};
    width: 100%;
    height: 50vh;
`
export const Header = styled.div`
    position: sticky;
    top: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    z-index: 3;
    width: 1200px;
    height: 90px;
    margin: auto;
    padding: 30px;
    @media (max-width: 767px) {
        width: 100%;
        padding: 15px;
        display: block;
        height: auto;
    }
`
export const Form = styled.div`
    display: flex;
    position: relative;
    button {
        position: absolute;
        right: 0;
        height: 100%;
        width: 40px;
        background: ${DARK_BLUE};
        border: none;
        border-radius: 0 5px 5px 0;
        svg g {
            fill: #fff;
        }
    }
    .ant-input-suffix {
        display: none;
    }
`
export const Body = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #f7f8fc;
    width: 1200px;
    margin: 0 auto;
    .sidebar {
        width: calc(33% - 40px);
        position: sticky;
        top: 150px;
        padding: 20px 0;
        height: calc(100vh - 260px);
        .ant-checkbox-group {
            width: 100%;
            max-height: 130px;
            overflow: auto;
            ${CustomScrollBar};
            label {
                white-space: nowrap;
                width: 100%;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }
        }
        .ant-spin-container {
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
        }
    }
    @media (max-width: 767px) {
        flex-direction: column;
        width: 100%;
        padding: 0px 15px;
        .sidebar {
            width: auto;
            position: initial;
        }
    }
`
export const Title = styled.span`
    font-family: sanFranciscoSemi;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    text-transform: uppercase;
    margin-bottom: 20px;
    display: block;
`
export const SearchBox = styled(Search)`
    width: 400px !important;
    height: 40px;
    border-radius: 4px;
    .ant-input,
    .ant-input::placeholder {
        background-color: #f1f2f4;
        font-size: 0.9em;
        color: #8a94a6;
    }
`
export const AddButton = styled.button`
    width: 151px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px ${DARK_BLUE};
    margin-left: 20px;
    font-size: 0.9em;
    font-weight: 500;
    color: ${DARK_BLUE};
    text-transform: uppercase;
    background: #fff;
    svg {
        vertical-align: middle;
        margin-right: 5px;
    }
`
export const SubTitle = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    ${props =>
        props.sticky &&
        `position: sticky;
        height: 70px;
        top: 146px;
        padding: 16px 0 0 0;
        margin: 0px -2px;
        margin-bottom: 1px;
        align-items: center;
        background: #f7f8fc;
        z-index: 2;
    `};
    ${commonHeadingStyles}
    a {
        color: ${DARK_BLUE};
    }
    @media (max-width: 767px) {
        position: initial;
    }
`
export const Aside = styled.div`
    border-radius: 5px;
    background-color: #ffffff;
    padding: 0 30px;
    overflow: auto;
    max-height: ${props => (props.full ? '100%' : 'calc(100vh - 312px)')};
    .ant-radio-group {
        width: 100%;
        > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1em;
            :last-child {
                margin-bottom: 0;
            }
            label {
                margin-bottom: 0;
            }
        }
        .count {
            margin-left: auto;
        }
    }
    label {
        display: block;
        :last-child {
            margin-bottom: 0;
        }
    }
    .ant-input-search {
        margin-bottom: 20px;
    }
    .ant-input {
        border: 1px solid #d9d9d9 !important;
    }
    .ant-input,
    .ant-select {
        font-size: 0.9em;
        color: ${LIGHT_GREY};
    }
    label,
    .ant-select {
        margin-bottom: 1em;
        .ant-select-arrow-icon {
            font-size: 10px;
            svg use {
                fill: ${LIGHT_GREY};
            }
        }
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
    }
    .ant-collapse {
        background: transparent;
        svg use {
            fill: ${LIGHT_GREY};
        }
        .ant-collapse-item {
            margin: 30px 0;
            padding-bottom: 30px;
            border-bottom: solid 1px #e1e4e8 !important;
            :last-child {
                border-bottom: 0 !important;
                padding-bottom: 0;
            }
        }
        > .ant-collapse-item > .ant-collapse-header {
            margin-bottom: 0;
            line-height: initial;
            ${commonHeadingStyles}
            .ant-collapse-arrow {
                right: 0;
                font-size: 10px;
                margin-top: 0;
                line-height: 0;
            }
        }
        .ant-collapse-content {
            > .ant-collapse-content-box {
                padding: 0;
                padding-top: 15px !important;
            }
        }
    }
    .clear-all {
        float: right;
        margin-right: 15px;
        color: ${DARK_BLUE};
        text-transform: none;
    }
    ${CustomScrollBar};
`
export const Block = styled.div`
    padding: 30px 0;
    border-bottom: solid 1px #e1e4e8;
    :last-child {
        border: none;
    }
    .inbox-title {
        display: flex;
        color: #999;
        svg {
            width: 17px;
            height: 20px;
            margin-right: 5px;
        }
        span {
            font-size: 16px;
        }
        p {
            font-size: 12px;
            margin-bottom: 0;
        }
    }
`
export const Main = styled.div`
    width: 67%;
    > .ant-checkbox-wrapper {
        ${commonHeadingStyles}
        margin-bottom: 10px;
    }

    .bolder {
        font-family: SFProText-Bold;
        font-weight: bolder;
        color: ${BLACK};
    }
    @media (max-width: 767px) {
        width: 100%;
    }
`
export const Heading = styled.span`
    margin-bottom: ${props => (props.noMargin ? '0' : '10px')};
    display: ${props => (props.noMargin ? '' : 'block')};
    font-family: SFProText-Bold;
    ${commonHeadingStyles}
    .clear {
        color: ${DARK_BLUE};
        float: right;
        cursor: pointer;
    }
`
export const Card = styled.div`
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 30px;
    margin-bottom: 30px;
    .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        height: 60px;
    }
    @media (max-width: 767px) {
        .ant-checkbox-wrapper {
            height: auto;
        }
    }
`
export const Top = styled.div`
    display: flex;
    border-bottom: solid 1px #e1e4e8;
    padding-bottom: 10px;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        border-bottom: none;
    }
`
export const Info = styled.div`
    margin-left: 20px;
    flex: 1;
    @media (max-width: 767px) {
        text-align: center;
        margin-left: 0;
    }
`
export const Bottom = styled.table`
    width: 100%;
    margin-top: 15px;
    td {
        padding-right: 20px;
        font-size: 12px;
        font-weight: 500;
        color: ${LIGHT_GREY};
        padding: 5px 0;
        &.value {
            padding-right: 25px;
            color: #333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
        }
    }
`
export const Avatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background-color: ${DARK_BLUE};
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
`
export const Name = styled.div`
    ${commonHeadingStyles}
    font-size: 18px;
    text-transform: unset;
`
export const Icons = styled.div`
    .icons {
        display: flex;
        align-items: center;
        min-width: 75px;
    }
    svg {
        cursor: pointer;
        &.inactive {
            opacity: 0.2;
        }
        & + svg {
            margin-left: 15px;
        }
    }
    .assigned {
        ${FlexCenterRow};
        width: 75px;
        height: 25px;
        border-radius: 5px;
        background-color: #eeeeee;
        font-size: 12px;
        color: ${GREY};
        margin-top: 15px;
        margin-left: auto;
    }
    @media (max-width: 767px) {
        .assigned {
            width: auto;
        }
    }
`
export const Label = styled.div`
    font-size: 12px;
    font-weight: 500;
    margin: 8px 0;
    .title {
        color: #333;
        margin-bottom: 5px;
    }
    a {
        color: ${DARK_BLUE};
    }
    > span {
        color: ${LIGHT_GREY};
        & + span {
            margin-left: 20px;
        }
        &.error {
            color: #fa6400;
            svg path {
                fill: #fa6400;
            }
        }
        .more {
            color: ${DARK_BLUE};
        }
    }
    svg {
        margin-right: 5px;
        vertical-align: middle;
    }
    @media (max-width: 767px) {
        > span {
            display: block;
            & + span {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
`
export const ActionsCard = styled.div`
    height: 120px;
    position: sticky;
    top: 218px;
    width: calc(100% + 2px);
    margin-left: -1px; // there was card's box shadow coming.. so width had to be increased to compensate card at the back during scroll
    z-index: 1;
    margin-bottom: 2px;
    background-color: ${BACKGROUND_COLOR};
    .action-block {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
        background-color: #ffffff;
        padding: 0 20px;
        margin-bottom: 30px;
        > span {
            font-size: 14px;
            font-weight: 500;
            color: ${GREY};
        }
    }
    @media (max-width: 767px) {
        position: initial;
        height: auto;
        .action-block {
            height: auto;
            flex-direction: column;
            padding: 20px;
            .buttons {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: 10px;
            }
        }
    }
`
export const Spacer = styled.div`
    height: 72px;
    background: #f7f8fc;
    position: sticky;
    margin: 0px -3px;
    top: 146px;
    z-index: 1;
    @media (max-width: 767px) {
        position: initial;
        margin: 0;
    }
`
export const CTA = styled(Button)`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    svg {
        font-size: 10px;
        use {
            fill: #fff;
        }
    }
    &.ant-btn:hover {
        color: ${DARK_BLUE};
        background-color: #fff;
    }
    @media (max-width: 767px) {
        margin-left: 0;
    }
`
