import React from 'react'
import classNames from 'classnames'
import { Draggable } from 'react-beautiful-dnd'
import { Card, Icon, Avatar, Popover, Menu, Tooltip, Select } from 'antd'
import './card.css'
import { CardContainer } from './style'
import OptionIcon from '../../../assets/Icons/Board/Options'
import StarIcon from '../../../assets/Icons/Board/Star'
import { ReactComponent as InfoIcon } from '../../../assets/Icons/Talent Pool/info.svg'
import DiscardIcon from '../../../assets/Icons/Board/Discard'
import ScheduleIcon from '../../../assets/Icons/Board/Schedule'
import ScheduleInterview from '../../Pages/ScheduleInterview'
import {
    RejectCandidate,
    UpdateFavouriteStatus,
    ArchiveApplications,
    RequestFeedback,
    AssignTeamMember,
    ResendDoSelectTests,
    UpdateCandidateStatus,
    PostDoSelectTests,
    PatchNotes,
    AddNotes,
    DeleteNote,
} from '../../../services/api'
import getAvatar from '../../../services/getAvatar'
import { store } from '../../../redux/store'
import { success, warning } from '../../../services/Notification'
import { LogE } from './../../Helpers/errorHandler'
import { CANDIDATE_STATUS, ACCESS_LEVEL_DEFINITIONS } from '../../Helpers/constants'
import { Modal } from './../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'

const { Meta } = Card

const Description = props => {
    return (
        <div className="meta-cont">
            {props.assignedTo ? (
                <Tooltip title={`Assigned to ${props.assignedTo}`}>
                    <div className="card-assignedTo-icon">{props.assignedTo.charAt(0).toUpperCase()}</div>
                </Tooltip>
            ) : null}

            {props.coordinators ? (
                <Tooltip title={`Interviewers: ${props.card.coordinators.join(', ')}`}>
                    <div className="card-coordinator-icon">{props.coordinators.length}</div>
                </Tooltip>
            ) : null}

            <Tooltip title={props.stared ? 'Remove Favourite' : 'Mark Favourite'}>
                <Icon
                    className={classNames({
                        'card-icons': true,
                        'card-pointer': true,
                        'star-candidate': props.stared,
                    })}
                    component={StarIcon}
                    onClick={props.markFavourite}
                />
            </Tooltip>

            {/* <Icon className="card-icons" component={MailIcon} /> */}

            {props.showInterview && props.candidateStatus === 1 ? (
                <Tooltip title={props.hasInterview ? 'Reschedule Interview' : 'Schedule Interview'}>
                    <Icon
                        component={ScheduleIcon}
                        className={classNames({
                            'card-icons': true,
                            'card-pointer': true,
                            'star-candidate': props.hasInterview,
                        })}
                        onClick={props.scheduleInterview}
                    />
                </Tooltip>
            ) : null}

            <Tooltip title="Reject Candidate">
                <Icon
                    className={classNames({
                        'card-icons': true,
                        'card-pointer': true,
                        'is-rejected': props.isRejected,
                    })}
                    component={DiscardIcon}
                    onClick={props.rejectCan}
                />
            </Tooltip>
        </div>
    )
}

const Title = props => {
    const hasInterview = props.card.interviewAt && props.card.interviewAt[0]
    const coordinators = props.card.coordinators
        ? props.card.coordinators.forEach(coordinator => coordinator.charAt(0).toUpperCase())
        : null
    const requestFeedback =
        hasInterview &&
        props.hasInterview &&
        (props.card.interviewStatus === 1 || !props.card.isInterviewUpcoming) &&
        !props.card.hasFeedback
    let assignedTo = null
    const candidateStatus = props.calculateCandidateStatus()

    let { teamMembers } = store.getState().Mixed
    if (props.card.assignedTo) {
        assignedTo = teamMembers.find(mem => mem._id === props.card.assignedTo)
        assignedTo = assignedTo && (assignedTo.fullName || assignedTo.email)
    }

    teamMembers = teamMembers.filter(mem => mem.status)

    return (
        <div className="display-flex">
            <div className="card-avatar" onClick={() => window.open(`/candidate/${props.card.id}`, '_blank')}>
                <Avatar shape="square" size="large" className="card-avatar-bg">
                    {props.card.title ? getAvatar(props.card.title) : <InfoIcon style={{ verticalAlign: 'middle' }} />}
                </Avatar>
            </div>
            <div className="card-head-cont">
                <div className="card-title-cont">
                    <div className="cd-card-title" onClick={() => window.open(`/candidate/${props.card.id}`, '_blank')}>
                        <Tooltip
                            title={props.card.title || 'Incomplete profile'}
                            className={props.card.title ? 'color-black' : 'color-orange'}
                        >
                            {props.card.title || 'Candidate'}
                        </Tooltip>
                    </div>
                    <div className="display-flex">
                        <Popover
                            overlayStyle={{ zIndex: 999 }}
                            placement="bottom"
                            arrowPointAtCenter
                            visible={props.visible}
                            onVisibleChange={props.handleVisibleChange}
                            content={
                                <Menu className="card-option-cont">
                                    {props.accessLevel !== ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel && (
                                        <Menu.Item className="card-options">
                                            <Popover
                                                placement="right"
                                                content={
                                                    <Select
                                                        className="ats-assign-tm-search"
                                                        placeholder="Assign team member"
                                                        notFoundContent={null}
                                                        showSearch
                                                        suffixIcon={<Icon type="search" />}
                                                        defaultActiveFirstOption={false}
                                                        filterOption
                                                        optionFilterProp="title"
                                                        onSelect={props.assignTeamMember}
                                                    >
                                                        {teamMembers
                                                            .filter(mem => {
                                                                return mem.status === 1 && mem.accessLevel !== 5
                                                            })
                                                            .map(mem => (
                                                                <Select.Option
                                                                    key={mem._id}
                                                                    className="ats-col-popover-option sort-by-popover-item"
                                                                    title={mem.fullName || mem.email}
                                                                >
                                                                    {mem.fullName || mem.email}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                }
                                            >
                                                <span className="card-option-color">
                                                    {props.card.assignedTo ? 'Change' : 'Assign'} Team Member
                                                </span>
                                            </Popover>
                                        </Menu.Item>
                                    )}
                                    {props.accessLevel !== ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel && (
                                        <Menu.Item className="card-options" onClick={props.markFavourite}>
                                            <span className="card-option-color">
                                                {props.card.stared ? 'Remove Favourite' : 'Mark Favourite'}
                                            </span>
                                        </Menu.Item>
                                    )}
                                    <Menu.Item
                                        className="card-options"
                                        onClick={() =>
                                            window.open(`/candidate/${props.card.id}?view=message`, '_blank')
                                        }
                                    >
                                        <span className="card-option-color">Send Message</span>
                                    </Menu.Item>
                                    <Menu.Item className="card-options" onClick={props.addNotes}>
                                        <span className="card-option-color">
                                            {props.card.notes.length ? 'View / Add' : 'Add'} Note
                                        </span>
                                    </Menu.Item>
                                    {props.hasInterview && candidateStatus === 1 ? (
                                        <Menu.Item className="card-options" onClick={props.scheduleInterview}>
                                            <span className="card-option-color">
                                                {props.card.interviewDetail ? 'Reschedule' : 'Schedule'} Interview
                                            </span>
                                        </Menu.Item>
                                    ) : null}
                                    {props.card.hasOneFeedback ? (
                                        <Menu.Item className="card-options" onClick={props.viewFeedback}>
                                            <span className="card-option-color">View Feedback</span>
                                        </Menu.Item>
                                    ) : null}
                                    <Menu.Item className="card-options" onClick={props.addFeedback}>
                                        <span className="card-option-color">Add Feedback</span>
                                    </Menu.Item>
                                    {requestFeedback ? (
                                        <Menu.Item className="card-options" onClick={props.requestFeedback}>
                                            <span className="card-option-color">Request Feedback</span>
                                        </Menu.Item>
                                    ) : null}
                                    {props.hasDoselectCredentials &&
                                    props.accessLevel !== ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel ? (
                                        <Menu.Item className="card-options" onClick={props.assignTest}>
                                            <span className="card-option-color">Assign Test</span>
                                        </Menu.Item>
                                    ) : null}
                                    <Menu.Item className="card-options">
                                        <span
                                            className="card-option-color"
                                            onClick={() => window.open(`/candidate/${props.card.id}`, '_blank')}
                                        >
                                            Edit Details
                                        </span>
                                    </Menu.Item>
                                    {props.accessLevel < 3 && candidateStatus !== 3 ? (
                                        candidateStatus === 0 ? (
                                            <Menu.Item
                                                className="card-options"
                                                onClick={() => props.activeApplication('unarchive')}
                                            >
                                                <span className="card-option-color">Unarchive</span>
                                            </Menu.Item>
                                        ) : (
                                            <Menu.Item className="card-options" onClick={props.archiveCan}>
                                                <span className="card-option-color">Archive</span>
                                            </Menu.Item>
                                        )
                                    ) : null}

                                    {candidateStatus !== 3 ? (
                                        props.accessLevel < 5 && candidateStatus === 2 ? (
                                            <Menu.Item
                                                className="card-options"
                                                onClick={() => props.activeApplication('unreject')}
                                            >
                                                <span className="card-option-color">Unreject</span>
                                            </Menu.Item>
                                        ) : (
                                            <Menu.Item className="card-options" onClick={props.rejectCan}>
                                                <span className="card-option-color">Reject</span>
                                            </Menu.Item>
                                        )
                                    ) : null}

                                    {props.accessLevel < 4 ? (
                                        candidateStatus === 3 ? (
                                            <Menu.Item
                                                className="card-options"
                                                onClick={() => props.showUpdateCandidateModal()}
                                            >
                                                <span className="card-option-color">Unmark Joined</span>
                                            </Menu.Item>
                                        ) : (
                                            <Menu.Item className="card-options" onClick={props.confirmJoinedModal}>
                                                <span className="card-option-color">Mark Joined</span>
                                            </Menu.Item>
                                        )
                                    ) : null}
                                </Menu>
                            }
                        >
                            <Icon className="card-option-icon" component={OptionIcon} />
                        </Popover>
                    </div>
                </div>
                <div className="ant-card-meta-description card-subtitle">{/* {props.card.phone} */}</div>
                <Description
                    showInterview={props.hasInterview}
                    coordinators={coordinators}
                    stared={props.card.stared}
                    isRejected={props.card.isRejected}
                    assignedTo={assignedTo}
                    {...props}
                    hasInterview={hasInterview}
                    candidateStatus={candidateStatus}
                />
            </div>
        </div>
    )
}

export default class CardComponent extends React.Component {
    state = {
        feedbackModal: false,
        interviewModal: false,
        doSelectModal: false,
        viewFeedbackModal: false,
        showNotesModal: false,
        rejectCandidateModal: false,
        showSelectBox: false,
        isCandidatesSelected: false,
        visible: false,
        showJoinedConfirmation: false,
        selectedAction: null,
        clickedOnce: false,
        noEmailPresent: false,
        archiveModal: false,
        callInProgress: false,
        showUpdateCandidateModal: false,
        latestNote: {},
        ...this.props,
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.card !== this.props.card ||
            nextProps.showSelectBox !== this.props.showSelectBox ||
            nextProps.isCandidatesSelected !== this.props.isCandidatesSelected ||
            nextProps.hasInterview !== this.props.hasInterview
        ) {
            this.setState(nextProps)
        }
    }

    showFeedbackModal = () => {
        this.setState({ feedbackModal: true })
    }

    showInterviewModal = () => {
        if (this.props.card.email) {
            this.setState({ interviewModal: true })
        } else {
            this.setState({ noEmailPresent: true })
        }
    }

    confirmJoinedModal = () => {
        this.setState({ showJoinedConfirmation: true })
    }

    showUpdateCandidateModal = () => this.setState({ showUpdateCandidateModal: true })

    handleCancel = () => {
        this.setState({
            feedbackModal: false,
            interviewModal: false,
            doSelectModal: false,
            viewFeedbackModal: false,
            showNotesModal: false,
            rejectCandidateModal: false,
            showJoinedConfirmation: false,
            noEmailPresent: false,
            archiveModal: false,
            showUpdateCandidateModal: false,
            latestNote: {},
        })
    }

    handleClickHere = () => {
        window.open(`/candidate/${this.props.card.id}`, '_blank')
        this.setState({ noEmailPresent: false })
    }

    addNotes = () => {
        this.setState({ showNotesModal: true })
    }

    newNoteAdded = async newNote => {
        try {
            const res = newNote._id ? await PatchNotes(newNote, newNote._id) : await AddNotes(newNote)

            success(`Note ${newNote._id ? 'updated' : 'added'} successfully`)

            let card = this.state.card
            let { notes } = card

            res.details.userName = newNote.userName

            if (newNote._id) {
                notes = notes.map(note => {
                    if (note._id !== newNote._id) {
                        return note
                    } else return res.details
                })
            } else {
                notes.unshift(res.details)
            }

            card.notes = notes
            this.setState({ card })
        } catch (err) {
            warning(err.message || err)
        }
    }

    deleteNote = async (noteId, index) => {
        try {
            let card = this.state.card
            let { notes } = card
            await DeleteNote(noteId)
            notes.splice(index, 1)
            this.setState({ card })
            success('Note was successfully deleted')
        } catch (err) {
            warning(err.message || err)
        }
    }

    interviewSubmit = res => {
        this.handleCancel()
        this.props.candidateEvents(this.state.card.id, 'interview', res)
    }

    doSelectTestSubmit = (jobAppId, form) => {
        this.setState({ callInProgress: true }, async () => {
            try {
                await PostDoSelectTests(jobAppId, form)
                success('Assigned test successfully.')
                this.handleCancel()
                this.props.candidateEvents(this.state.card.id, 'doSelectTest', form.slug)
            } catch (error) {
                warning('Something went wrong assigning test to candidate')
            } finally {
                this.setState({ callInProgress: false })
            }
        })
    }

    showDoSelectModal = () => {
        this.setState({ doSelectModal: true })
    }

    rejectCan = () => {
        this.setState({ rejectCandidateModal: true, selectedAction: 'Reject' })
    }

    archiveCan = () => {
        this.setState({ archiveModal: true, selectedAction: 'Archive' })
    }

    rejectCandidateConfirm = async () => {
        try {
            this.setState({ rejectCandidateModal: false })
            await RejectCandidate(
                {
                    candidateId: this.state.card.id,
                    isRejected: {
                        interviewerId: store.getState().Auth.user.id,
                        status: true,
                    },
                },
                this.state.card.jobAppId
            )
            this.handleCancel()
            success(`${this.state.card.title} has been rejected. You can view rejected candidates here.`, {
                pathname: `/openings/list/${this.state.card.jobId}?filter=rejected`,
                newTab: true,
            })
            this.props.candidateEvents(this.state.card.id, 'reject', this.state.stageId)
        } catch (err) {
            warning(err.message || err)
        }
    }

    archiveApplication = (reason, sendEmailToCandidate) => {
        try {
            this.setState({ clickedOnce: true }, async () => {
                const { jobAppId, title } = this.state.card
                const candidateName = title ? title : 'Candidate'
                this.hide()
                await ArchiveApplications([jobAppId], reason, sendEmailToCandidate)
                this.handleCancel()
                success(`${candidateName} has been archived successfully`)
                this.setState({ archiveModal: false, clickedOnce: false }, () => {
                    this.props.candidateEvents(this.state.card.id, 'archive', this.state.stageId)
                })
            })
        } catch (err) {
            warning(err.message || err)
            this.setState({ clickedOnce: false })
        }
    }

    updateCandidateStatus = async status => {
        try {
            this.setState({ showJoinedConfirmation: false })
            const { stages } = this.props
            const { jobAppId, title } = this.state.card
            const candidateName = title ? title : 'Candidate'
            this.hide()
            await UpdateCandidateStatus(jobAppId, {
                status,
                stageId: stages.find(stage => stage.title === 'JOINED').id,
            })

            success(`${candidateName} marked as ${status} successfully`)
            this.props.candidateEvents(this.state.card.id, 'join', this.state.stageId)
        } catch (err) {
            warning(err.message || err)
        }
    }

    markFavourite = async () => {
        try {
            const { jobAppId, stared, title } = this.state.card
            this.hide()
            await UpdateFavouriteStatus({ isFavourite: !stared }, jobAppId)
            success(`${title || 'Candidate'} is ${stared ? 'removed from' : 'marked as'} favourite`)
            this.props.candidateEvents(this.state.card.id, 'favourite', !stared)
        } catch (err) {
            warning(err.message || err)
        }
    }

    viewFeedback = () => {
        this.setState({ viewFeedbackModal: true })
    }

    newFeedback = feedback => {
        this.handleCancel()
        this.props.candidateEvents(this.state.card.id, 'feedback', feedback)
    }

    requestFeedback = async () => {
        try {
            const { interviewId, coordinators } = this.state.card
            this.hide()
            await RequestFeedback(interviewId)
            success(
                `${coordinators.join(', ')} ${coordinators.length > 1 ? 'have' : 'has'} been requested to add feedback.`
            )
        } catch (err) {
            warning(err.message || err)
        }
    }

    removeHighlight = () => {
        if (this.state.card.highlight) {
            this.props.candidateEvents(this.state.card.id, 'removeHighlight', null)
        }
    }

    assignTeamMember = async teamMember => {
        try {
            const { jobAppId, id } = this.state.card
            let { teamMembers } = store.getState().Mixed
            teamMember = teamMembers.find(mem => mem._id === teamMember)
            const name = teamMember.fullName || teamMember.email

            await AssignTeamMember(jobAppId, teamMember._id)
            success(`Candidate has been assigned to ${name}`)
            this.props.candidateEvents(id, 'assignTeamMember', teamMember._id)
        } catch (err) {
            warning(err.message || err)
        }
    }

    resendTest = async () => {
        try {
            const { jobAppId, testSlug, testHasExpired, id } = this.state.card
            if (!testHasExpired && testSlug) return

            await ResendDoSelectTests(jobAppId, { slug: testSlug })
            success('Test resent successfully')
            this.props.candidateEvents(id, 'resendDoSelectTest', null)
        } catch (err) {
            warning(err.message || err)
        }
    }

    activeApplication = async (type, payload) => {
        try {
            const { stages } = this.props
            const { stageId, card } = this.state
            const { jobId, jobAppId, title } = this.state.card
            const candidateName = title ? title : 'Candidate'
            const value = {
                jobId,
                status: 'active',
            }
            if (payload && payload.source === 'UNJOIN') {
                value.stageId = payload.stageId
            } else {
                value.stageId = stageId
            }
            this.hide()
            this.handleCancel()
            await UpdateCandidateStatus(jobAppId, value)
            let notificationStatus = ''
            if (type === 'unarchive') {
                notificationStatus = 'unarchived'
            } else if (type === 'unjoin') {
                notificationStatus = 'unjoined'
            } else if (type === 'unreject') {
                notificationStatus = 'unrejected'
            } else {
                notificationStatus = 'marked active'
            }
            success(`${candidateName} has been ${notificationStatus} successfully`)
            this.props.candidateEvents(card.id, 'active', stageId, payload && payload.stageId)
        } catch (err) {
            LogE('board-card-activeApplication', '', err)
            warning('An unexpected error occurred.')
        }
    }

    calculateCandidateStatus = () => {
        const { candidateStatusObject, card } = this.state

        let status =
            candidateStatusObject &&
            card &&
            candidateStatusObject[card.id] &&
            candidateStatusObject[card.id].hasOwnProperty('status')
                ? candidateStatusObject[card.id].status
                : null

        return status
    }

    hide = () => {
        this.setState({ visible: false })
    }

    handleVisibleChange = visible => {
        this.setState({ visible })
    }

    render() {
        const {
            card,
            feedbackModal,
            interviewModal,
            doSelectModal,
            viewFeedbackModal,
            showNotesModal,
            rejectCandidateModal,
            showSelectBox,
            stages,
        } = this.state
        const { accessLevel } = store.getState().Auth.user
        const dragDisabled =
            feedbackModal ||
            interviewModal ||
            doSelectModal ||
            viewFeedbackModal ||
            showNotesModal ||
            rejectCandidateModal ||
            accessLevel === ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel ||
            this.props.card.highlight //If the card is highlighted, then it cant be dragged, there is an onMouseDown event that will unhighlight it when click
        const currentInterview = card.interviews.find(interview => interview.interviewStageId === card.stageId)
        return (
            <Draggable draggableId={card.id} isDragDisabled={dragDisabled} index={this.props.index}>
                {provided => (
                    <CardContainer {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <Card
                            className={classNames({
                                'candidate-card': true,
                                'ats-card-highlight': card.highlight,
                            })}
                            title={
                                <Title
                                    {...this.state}
                                    addFeedback={this.showFeedbackModal}
                                    assignTest={this.showDoSelectModal}
                                    scheduleInterview={this.showInterviewModal}
                                    markFavourite={this.markFavourite}
                                    rejectCan={this.rejectCan}
                                    archiveCan={this.archiveCan}
                                    archiveApplication={this.archiveApplication}
                                    updateCandidateStatus={this.updateCandidateStatus}
                                    requestFeedback={this.requestFeedback}
                                    viewFeedback={this.viewFeedback}
                                    addNotes={this.addNotes}
                                    assignTeamMember={this.assignTeamMember}
                                    accessLevel={accessLevel}
                                    filterName={this.props.filterName}
                                    calculateCandidateStatus={this.calculateCandidateStatus}
                                    activeApplication={this.activeApplication}
                                    handleVisibleChange={this.handleVisibleChange}
                                    confirmJoinedModal={this.confirmJoinedModal}
                                    coordinators={card.coordinators}
                                    showUpdateCandidateModal={this.showUpdateCandidateModal}
                                />
                            }
                            onMouseDown={this.removeHighlight}
                        >
                            <Meta
                                className="cd-card-meta"
                                title={
                                    <React.Fragment>
                                        {showSelectBox && (
                                            <div className="candidate-card-meta">
                                                <label className="ats-card-cb-cont">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.isCandidatesSelected}
                                                        onChange={e =>
                                                            this.props.candidateSelectionChange(
                                                                card.jobAppId,
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                    <span className="ats-card-cb-ch"></span>
                                                </label>
                                            </div>
                                        )}

                                        {!showSelectBox && card.showTest && (
                                            <div className="candidate-card-meta">
                                                <small>
                                                    {card.testHasExpired ? (
                                                        <span>Failed to attempt test.</span>
                                                    ) : (
                                                        <span>Test {card.isTestResent ? 'resent' : 'sent'}</span>
                                                    )}
                                                </small>
                                                <small
                                                    className="primary-color cursor-pointer"
                                                    onClick={this.resendTest}
                                                >
                                                    {card.testHasExpired && 'Resend Test?'}
                                                </small>
                                            </div>
                                        )}

                                        {!showSelectBox && !card.showTest && this.state.hasInterview && (
                                            <div className="candidate-card-meta">
                                                <small>
                                                    {!currentInterview || card.interviews.length === 0
                                                        ? 'Interview not scheduled'
                                                        : currentInterview.interviewStatus === 0 &&
                                                          currentInterview.isInterviewUpcoming
                                                        ? 'Interview at ' + currentInterview.interviewAt.join(' on ')
                                                        : currentInterview.interviewStatus === 2
                                                        ? 'Interview canceled'
                                                        : null}
                                                </small>
                                                <small className="card-pointer">
                                                    {card.hasFeedback ? (
                                                        <span onClick={this.viewFeedback}>View Feedback</span>
                                                    ) : card.interviewAt &&
                                                      (card.interviewStatus === 1 || !card.isInterviewUpcoming) ? (
                                                        <span onClick={this.requestFeedback}>Request Feedback</span>
                                                    ) : null}
                                                </small>
                                            </div>
                                        )}

                                        {!showSelectBox &&
                                            !card.showTest &&
                                            !this.state.hasInterview &&
                                            !card.assigned &&
                                            (card.isRejected ? (
                                                <div className="candidate-card-meta">
                                                    <small></small>
                                                    <small>Rejected</small>
                                                </div>
                                            ) : (
                                                <div className="candidate-card-meta">
                                                    <small>{card.assignedTo ? 'Assigned' : 'Unassigned'}</small>
                                                </div>
                                            ))}
                                    </React.Fragment>
                                }
                            />
                        </Card>

                        <Modal
                            type={MODAL_TYPES.ADD_FEEDBACK}
                            visible={this.state.feedbackModal}
                            onCancel={this.handleCancel}
                            onConfirm={this.newFeedback}
                            interviewId={card.interviewId}
                            candidateId={card.id}
                            stageId={this.state.stageId}
                            jobId={card.jobId}
                        />

                        <ScheduleInterview
                            visible={this.state.interviewModal} // using not true as Modals "destroyOnClose" might not work
                            onCancel={this.handleCancel}
                            onSchedule={this.interviewSubmit}
                            candidate={{
                                _id: card.id,
                                name: card.title,
                                phone: card.phone,
                                email: card.email,
                                skypeId: card.skypeId,
                            }}
                            jobDetail={{ stageId: card.stageId, jobId: card.jobId }}
                            isRescheduled={!!currentInterview}
                            interview={currentInterview && currentInterview.interviewDetail}
                        />

                        <Modal
                            type={MODAL_TYPES.ASSIGN_TEST}
                            visible={this.state.doSelectModal}
                            onCancel={this.handleCancel}
                            onConfirm={form => this.doSelectTestSubmit(card.jobAppId, form)}
                            callInProgress={this.state.callInProgress}
                        />

                        <Modal
                            type={MODAL_TYPES.VIEW_FEEDBACKS}
                            candidateId={card.id}
                            visible={this.state.viewFeedbackModal}
                            onCancel={this.handleCancel}
                        />

                        <Modal
                            type={MODAL_TYPES.ADD_NOTES}
                            visible={this.state.showNotesModal}
                            onCancel={this.handleCancel}
                            onConfirm={newNote => this.newNoteAdded(newNote)}
                            handleDelete={(noteId, index) => this.deleteNote(noteId, index)}
                            candidateId={card.id}
                            candidateName={card.title}
                            jobApplicationId={card.jobAppId}
                            stageId={this.state.stageId}
                            notes={card.notes}
                        />

                        <Modal
                            type={MODAL_TYPES.WARNING_CANDIDATE_EMAIL_MISSING}
                            visible={this.state.noEmailPresent}
                            onConfirm={this.handleCancel}
                            redirect={true}
                            onRedirect={this.handleClickHere}
                        />

                        <Modal
                            type={MODAL_TYPES.ARCHIVE_REASON}
                            visible={this.state.archiveModal}
                            onCancel={this.handleCancel}
                            onConfirm={(reason, sendEmailToCandidate) =>
                                this.archiveApplication(reason, sendEmailToCandidate)
                            }
                            callInProgress={this.state.clickedOnce}
                        />

                        <Modal
                            type={MODAL_TYPES.CONFIRM_REJECT_CANDIDATE}
                            visible={this.state.rejectCandidateModal}
                            onCancel={this.handleCancel}
                            onConfirm={this.rejectCandidateConfirm}
                            singleCandidate={true}
                            candidateName={this.props.card.title || 'the Candidate'}
                        />

                        <Modal
                            type={MODAL_TYPES.CONFIRM_JOIN_CANDIDATE}
                            visible={this.state.showJoinedConfirmation}
                            onCancel={this.handleCancel}
                            onConfirm={() => this.updateCandidateStatus(CANDIDATE_STATUS.joined.key)}
                            candidateName={card.title}
                        />

                        <Modal
                            type={MODAL_TYPES.UPDATE_CANDIDATE}
                            visible={this.state.showUpdateCandidateModal}
                            onCancel={this.handleCancel}
                            onConfirm={(value, jobPayload) => this.activeApplication('unjoin', value)}
                            source="UNJOIN"
                            selectionSize={1}
                            selected={{
                                [card.id]: {
                                    jobId: card.jobId,
                                    jobApplicationId: card.jobAppId,
                                    stageId: card.stageId,
                                    stageName: stages.find(stage => stage.id === card.stageId).stageName,
                                    status: card.status,
                                },
                            }}
                        />
                    </CardContainer>
                )}
            </Draggable>
        )
    }
}
