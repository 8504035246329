import styled from 'styled-components'
import { Wrapper } from '../../../container/Pages/Sourcing/style'
import { Container } from '../../../container/Pages/Analytics/style'
import { Avatar as Avatar1 } from '../../../container/Pages/ScheduleInterview/Form/style'
import { CustomScrollBar, DARK_BLUE, ORANGE } from '../../Helpers/Stylesheet/styledComponents'

export const InboxWrapper = styled(Container)`
    padding: 30px 0px;
    .sidebar {
        padding-top: 0;
        width: 300px;
        margin-right: 30px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        background: #fff;
        .ant-spin-nested-loading,
        .ant-spin-container {
            height: 100%;
        }
        .ant-checkbox-wrapper {
            display: flex;
            align-items: center;
            span:not(.ant-checkbox) {
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .flex {
                align-items: center;
                flex: 1;
                > div:not(.archived-tag) {
                    word-break: break-word;
                }
            }
        }
        .ant-checkbox-group {
            width: 100%;
        }
        .ant-checkbox {
            margin-right: 10px;
            + span {
                padding: 0;
            }
        }
    }
    .sub-title {
        display: none;
    }
    .flex {
        display: flex;
        justify-content: space-between;
        svg {
            height: 24px;
        }
    }
    .job-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 110px;
        width: 100%;
    }
    .archived-tag {
        color: #8a94a6;
        font-size: 10px;
        text-align: center;
        border-radius: 2px;
        background: #f1f1f4;
        padding: 2px 4px;
        margin: 0 10px;
    }
`
export const Body = styled.div`
    display: flex;
    height: calc(100vh - 250px);
`
export const Main = styled.div`
    display: flex;
    background: #fff;
    width: calc(100% - 330px);
`
export const Avatar = styled(Avatar1)`
    margin-right: 10px;
    width: 40px;
    height: 40px;
    font-size: 14px;
    flex-shrink: 0;
    position: relative;
    &.online::before {
        content: '';
        background: #55be15;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: -20px;
    }
`
export const Contacts = styled.div`
    width: 280px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-right: 1px solid #edf0f5;
    .head {
        padding: 30px 30px 10px 30px;
        background: #fff;
        .search {
            margin-bottom: 20px;
            button {
                cursor: pointer;
            }
        }
    }
    .list {
        border-top: 1px solid #edf0f5;
        height: calc(100% - 149px);
        overflow: scroll;
        ${CustomScrollBar};
        .item {
            display: flex;
            border-bottom: 1px solid #edf0f5;
            padding: 30px;
            border-left: 3px solid #fbfcfd;
            background: #fbfcfd;
            cursor: pointer;
            .name {
                font-size: 14px;
                cursor: pointer;
                font-weight: 600;
                margin: 0 5px 10px 0;
                line-height: initial;
                word-break: break-all;
                flex: 3;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .email {
                font-size: 13px;
                margin-bottom: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .name-warning {
                color: ${ORANGE};
            }
            .time {
                all: unset;
                font-size: 10px;
                text-align: right;
                color: #999;
                white-space: nowrap;
            }
            &.active {
                background: #f6f9ff;
                border-left: 3px solid #4767a0;
            }
            > div:last-child {
                width: 100%;
                overflow: hidden;
            }
        }
    }
`
export const Messages = styled.div`
    width: calc(100% - 280px);
    height: 100%;
    overflow: auto;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    ${CustomScrollBar}
    .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        svg {
            flex-shrink: 0;
        }
        p {
            margin-top: 20px;
        }
    }
    .input {
        display: flex;
        align-items: center;
        color: #999;
        border-bottom: 1px solid #edf0f5;
        margin-bottom: 30px;
        > span {
            font-size: 12px;
            width: 50px;
        }
        p {
            background: #eee;
            border-radius: 100px;
            padding: 5px 15px;
            font-size: 12px;
            font-weight: 600;
            color: #666;
            margin-bottom: 5px;
            > span {
                color: #999;
            }
        }
        input,
        .ant-select {
            width: 100%;
            border: none;
            font-size: 12px;
            :focus {
                outline: none;
            }
        }
        input:disabled {
            background-color: transparent;
        }
        .ant-select-selection {
            border: none;
        }
        .ant-select-selection--multiple {
            .ant-select-selection__placeholder {
                margin-left: 0;
            }
            .ant-select-selection__rendered {
                margin-left: 0;
            }
        }
        .ant-select-selection--multiple .ant-select-selection__choice__remove {
            padding-left: 5px;
            height: 40px;
            svg {
                width: 15px;
                height: 15px;
                padding-left: 2px;
            }
        }
    }
    .item {
        border-bottom: 1px solid #edf0f5 !important;
        padding: 30px;
        .body {
            margin-left: 50px;
            table.openings {
                font-size: 12px;
                margin-top: 20px;
                td {
                    text-transform: capitalize;
                    color: #4767a0;
                    padding-right: 20px;
                    word-break: break-word;
                    width: 40%;
                }
            }
            p {
                font-size: 12px;
                line-height: 20px;
                color: #666666;
                white-space: pre-line;
            }
        }

        .email-attachment-view {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .ant-collapse {
        background: #fff;
        > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
            padding-left: 0;
            margin-bottom: 0;
            line-height: 18px;
        }
        .ant-collapse-content > .ant-collapse-content-box {
            padding: 0;
        }
    }
    .sub {
        font-weight: 600;
        font-size: 14px;
        color: #333;
        &.m15 {
            margin: 15px 0;
        }
    }
    .mail-options {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0 30px;
        .lock {
            vertical-align: middle;
            margin-right: 5px;
            path {
                fill: #4767a0;
            }
        }
        > span {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            color: #4767a0;
            svg {
                margin-right: 5px;
                transform: rotate(90deg);
                path {
                    fill: #4767a0;
                }
            }
        }
        > label {
            margin-left: 20px;
            font-size: 12px;
        }
    }
    .note {
        font-size: 12px;
        color: #999;
        .black {
            color: #333;
        }
    }
    .add-more {
        font-size: 12px;
        color: #4767a0;
    }
    .buttons {
        display: flex;
        margin-top: 30px;
    }
    .head,
    .ant-collapse-header {
        display: flex;
        .details {
            flex: 1;
            .name {
                cursor: pointer;
                font-weight: bold;
                color: #4767a0;
            }
            .name-warning {
                color: ${ORANGE};
            }
        }
        span {
            font-size: 12px;
            color: #666;
            + span {
                margin-left: 20px;
            }
            svg {
                margin-right: 5px;
                vertical-align: middle;
            }
            &.to {
                color: #999;
            }
        }
        .name {
            font-size: 12px;
            font-weight: 600;
            color: #333;
            &.active {
                color: #4767a0;
            }
        }
        .new {
            font-size: 12px;
            color: #4767a0;
            svg {
                width: 7px;
                height: 7px;
                margin-right: 5px;
            }
        }
        .timestamp {
            color: #666;
            font-size: 12px;
        }
    }

    .refresh-email {
        text-align: right;
        padding-right: 10px;
        margin-left: 80%;
        line-height: 28px;
        width: 100px;
        cursor: pointer;
    }
`

export const NewMessageContainer = styled.div`
    position: fixed;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 540px;
    background: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    .header {
        all: unset;
        display: flex;
        align-items: center;
        padding-left: 30px;
        height: 50px;
        background: #f1f2f4;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        font-size: 14px;
        font-weight: 700;
        color: #333;
    }
    .body {
        padding: 30px;
        ${({ isSmallDesktop }) => (isSmallDesktop ? `height: 300px; overflow: auto; ${CustomScrollBar}; ` : '')}
    }
`
