import React from 'react'
import { MessageModal } from '../common'

export const ConfirmDisableTeamMember = props => (
    <MessageModal
        title="DISABLE TEAM MEMBER"
        message={
            <span>
                Are you sure you want to disable{' '}
                <b>{props.member !== null && (props.member.fullName || props.member.email)}</b> from the platform?
            </span>
        }
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
        confirmText="DISABLE"
        cancelText="CANCEL"
    />
)
