import React from 'react'
import { Button, Input, Select, Form, Icon, InputNumber, DatePicker } from 'antd'
import { countryCodes } from '../../Helpers/countryCode'
import { currencies } from '../../Helpers/currencies'
import { phoneValidator } from '../../Helpers/utils'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import RemoveIcon from '../../../assets/Icons/AccountSettingPage/Remove Button'
import { warning } from '../../../services/Notification'
import { ReactComponent as CalendarSvg } from '../../../assets/Icons/Extras/Calendar.svg'
import moment from 'moment'
import { defaultCandidateSettings } from './DefaultData'
import { CANDIDATE } from '../../Helpers/RegEx'
import { connect } from 'react-redux'
import { EditCandidate, GetCandidatesEmail } from '../../../services/NetworkCalls/Candidate'
import LocationAutoComplete from '../../Components/locationAutoComplete'

const { Option } = Select

// Data
// only candidateForm is used in this edit mode, which is a copy of cadidate data got from the parent class

class EditProfileForm extends React.Component {
    constructor(props) {
        super(props)

        let { candidateSettings, accessLevel, id } = props
        const { candidateForm = null } = props
        candidateSettings = candidateSettings || defaultCandidateSettings
        const CTCInfo = (candidateForm && candidateForm.ctcInfo) || null

        // Edit can be only be by admins and super-admins and by team members if only its empty.
        const IsCTCAddedByMe = CTCInfo && CTCInfo.addedBy === id
        const CTCData = CTCInfo !== null && (CTCInfo.currentCTC || CTCInfo.expectedCTC)
        const IsCTCMarkedPrivate = CTCInfo && CTCInfo.markedPrivate // if marked private means admin and sadmin has edited the CTC

        // The order checks in canIEditCTC is important
        // markPrivate=True when candidate added via Apply Now and CTC added by Sadmin/admin first time.
        this.state = {
            candidateForm,
            showCTCAddMember: false,
            userSearchText: undefined,
            candidateSettings,
            canIEditCTC: candidateForm.ctcDataAllowed && (!CTCData || accessLevel < 3 || IsCTCAddedByMe),
        }
    }

    onCancel = () => {
        this.props.onCancel()
    }

    saveProfile = e => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                try {
                    const { candidateForm, canIEditCTC } = this.state
                    const { accessLevel, id } = this.props
                    let { ctcInfo = {}, ctcDataAllowed } = candidateForm

                    let countryCode = countryCodes.find(c => c.code === values.countryCode)
                    if (countryCode) countryCode = countryCode.dial_code
                    else countryCode = ''
                    const phoneNumber = parsePhoneNumberFromString(countryCode + values.phone)
                    const candidateId = candidateForm._id
                    if (phoneNumber) {
                        values.phone = phoneNumber.number
                    }
                    delete values.countryCode

                    // update total experience for number
                    if (candidateForm.totalExperience || candidateForm.totalExperience === 0) {
                        if (values.totalExperience === 0) {
                            values.totalExperience = 'Fresher'
                        } else {
                            values.totalExperience = (values.totalExperience || 0) + ' years'
                        }
                    }

                    if (canIEditCTC && ctcDataAllowed) {
                        values.ctcInfo.addedBy = ctcInfo.addedBy
                        values.ctcInfo.markedPrivate = ctcInfo.markedPrivate
                        values.ctcInfo.accessibleTo = ctcInfo.accessibleTo

                        // checking if data is changed
                        if (
                            ctcInfo.currentCTC != values.ctcInfo.currentCTC ||
                            ctcInfo.expectedCTC != values.ctcInfo.expectedCTC
                        ) {
                            //
                            // // case 1
                            // // earlier empty now filled
                            if (
                                (!ctcInfo.currentCTC && values.ctcInfo.currentCTC) ||
                                (!ctcInfo.expectedCTC && values.ctcInfo.expectedCTC)
                            ) {
                                if (accessLevel > 2) {
                                    values.ctcInfo.addedBy = id
                                    values.ctcInfo.markedPrivate = false
                                } else {
                                    values.ctcInfo.addedBy = null
                                    values.ctcInfo.markedPrivate = true
                                }
                            }

                            // case 2
                            // data is cleared
                            else if (values.ctcInfo.currentCTC === null && values.ctcInfo.expectedCTC === null) {
                                values.markedPrivate = false
                            }

                            // case 3
                            // added by already there and data changed
                            else if (ctcInfo.addedBy) {
                                // we do not do anything
                            }

                            // case 4
                            // alterations are done by the team member having the
                            else if (accessLevel > 2) {
                                // alterations are done by the team member having the
                                // we do not do anything
                            } else {
                                // the alterations are done by admins do not change any addedBy or markedPrivate
                            }
                        }
                    }
                    this.props
                        .EditCandidate(values, this.props.companyId, candidateId)
                        .then(res => {
                            if (res.error) warning(res.error.msg || 'Unknown error, Please try again later')
                            else {
                                delete res.status
                                this.props.saved({ ...res, ctcDataAllowed })
                            }
                        })
                        .catch(err => warning(err.message || err))
                } catch (err) {
                    warning(err.message || err)
                }
            }
        })
    }

    showCTCAddMember = () => {
        this.setState({ showCTCAddMember: true })
    }

    removeCTCUser = userId => {
        const { candidateForm } = this.state
        candidateForm.ctcInfo.accessibleTo = candidateForm.ctcInfo.accessibleTo.filter(({ id }) => id !== userId)
        this.setState({ candidateForm })
    }

    addCTCUser = user => {
        const { candidateForm } = this.state
        const { id } = this.props

        if (!candidateForm.ctcInfo.accessibleTo) {
            candidateForm.ctcInfo.accessibleTo = []
        }
        candidateForm.ctcInfo.accessibleTo.push({ id: user, addedBy: id, timestamp: Date.now() })
        this.setState({ candidateForm, userSearchText: undefined })
    }

    handleCTCChange = name => value => {
        const { candidateForm } = this.state
        candidateForm.ctcInfo[name] = value
        this.setState({ candidateForm })
    }

    handleLocationSelect = data => {
        this.props.form.setFieldsValue({ currentLocation: data })
    }

    validEmail = async (rule, value, callback) => {
        if (value.trim().length > 0) {
            const checkMail = await this.props.GetCandidatesEmail({ email: value, companyId: this.props.companyId })
            if (checkMail.isPresent && value !== this.props.currentEmail) {
                return callback('Email is already present')
            }
        }
        callback()
    }

    portfolioValidator = (rule, value, callback) => {
        if (value && value.length > 0) {
            let links = value.split('\n')
            links.forEach(each => {
                if (!each.match(CANDIDATE.PORTFOLIO)) callback('Invalid URL')
            })
        }
        callback()
    }

    ctcValidator = (rule, value, callback) => {
        const currentCTC = this.props.form.getFieldValue('ctcInfo.currentCTC')
        const expectedCTC = this.props.form.getFieldValue('ctcInfo.expectedCTC')
        const currentCTCUnit = this.props.form.getFieldValue('ctcInfo.currentCTCCurrency')
        const expectedCTCUnit = this.props.form.getFieldValue('ctcInfo.expectedCTCCurrency')

        if (currentCTC && expectedCTC) {
            if (currentCTCUnit === expectedCTCUnit && expectedCTC < currentCTC) {
                if (rule.field === 'ctcInfo.currentCTCCurrency') {
                    this.props.form.setFields({
                        'ctcInfo.currentCTC': {
                            value: currentCTC,
                            errors: [new Error('Expected CTC cannot be lesser than current CTC')],
                        },
                    })
                } else if (rule.field === 'ctcInfo.expectedCTCCurrency') {
                    this.props.form.setFields({
                        'ctcInfo.expectedCTC': {
                            value: expectedCTC,
                            errors: [new Error('Expected CTC cannot be lesser than current CTC')],
                        },
                    })
                } else {
                    callback('Expected CTC cannot be lesser than current CTC')
                }
            } else {
                let errors = this.props.form.getFieldsError(['ctcInfo.currentCTC', 'ctcInfo.expectedCTC'])
                if (errors.ctcInfo.currentCTC) {
                    this.props.form.setFieldsValue({ 'ctcInfo.currentCTC': currentCTC })
                }
                if (errors.ctcInfo.expectedCTC) {
                    this.props.form.setFieldsValue({ 'ctcInfo.expectedCTC': expectedCTC })
                }
                callback()
            }
        }
        callback()
    }

    handleChange = e => {
        const { user } = this.props
        const { candidateForm } = this.state
        candidateForm.ctcInfo.markedPrivate = e.target.checked
        candidateForm.ctcInfo.accessibleTo = []
        candidateForm.ctcInfo.markedPrivateBy = user.id

        this.setState({ candidateForm })
    }

    render() {
        const { getFieldDecorator, getFieldValue, setFields } = this.props.form
        const { candidateForm, candidate, showCTCAddMember, candidateSettings, canIEditCTC } = this.state
        if (candidateForm.phone) {
            const phoneNumber = parsePhoneNumberFromString(candidateForm.phone)
            if (phoneNumber && phoneNumber.nationalNumber) {
                candidateForm.phone = phoneNumber.nationalNumber
                candidateForm.countryCode = phoneNumber.country
            }
        }

        const prefixSelector = initialValue =>
            getFieldDecorator(`countryCode`, {
                initialValue,
                rules: [
                    {
                        validator: phoneValidator(
                            field => getFieldValue(field),
                            (field, value, errors) => {
                                setFields({
                                    [field]: { value, errors },
                                })
                            },
                            this.props.candidate.phone
                        ),
                    },
                ],
            })(
                <Select
                    showSearch
                    style={{ width: 80 }}
                    optionLabelProp="title"
                    optionFilterProp="children"
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 245 }}
                    dropdownClassName="phone-input-dropdown"
                    getPopupContainer={trigger => trigger.parentNode}
                    filterOption={(input, option) =>
                        option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                >
                    {countryCodes.map(code => (
                        <Option key={code.code} title={code.dial_code}>
                            {code.name} ({code.dial_code}){' '}
                        </Option>
                    ))}
                </Select>
            )

        const { teamMembers, id: userId } = this.props
        const teamMembersMap = {}
        teamMembers.forEach(mem => {
            teamMembersMap[mem._id] = mem
        })

        return (
            <div className="cp-profile-block">
                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">Name{candidateSettings.name && '*'}:</div>
                    <div className="cp-profile-input">
                        <Form.Item>
                            {getFieldDecorator('name', {
                                initialValue: candidateForm.name,
                                rules: [
                                    {
                                        required: candidateSettings.name,
                                        whitespace: true,
                                        message: 'Name is required',
                                    },
                                    {
                                        pattern: CANDIDATE.NAME,
                                        message: 'Enter a valid name',
                                    },
                                ],
                            })(<Input size="default" />)}
                        </Form.Item>
                    </div>
                </div>

                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">
                        Company{candidateSettings.currentCompanyName && '*'}:
                    </div>
                    <div className="cp-profile-input">
                        <Form.Item>
                            {getFieldDecorator('currentCompanyName', {
                                initialValue: candidateForm.currentCompanyName,
                                rules: [
                                    {
                                        required: candidateSettings.currentCompanyName,
                                        whitespace: true,
                                        message: 'Company is required',
                                    },
                                    {
                                        pattern: CANDIDATE.COMPANY_NAME,
                                        message: 'Enter a valid Company name',
                                    },
                                ],
                            })(<Input size="default" />)}
                        </Form.Item>
                    </div>
                </div>

                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">
                        Location{candidateSettings.currentLocation && '*'}:
                    </div>
                    <div className="cp-profile-input">
                        <Form.Item>
                            {getFieldDecorator('currentLocation', {
                                initialValue: candidateForm.currentLocation,
                                rules: [
                                    {
                                        required: candidateSettings.currentLocation,
                                        whitespace: true,
                                        message: 'Location is required',
                                    },
                                ],
                            })(
                                <LocationAutoComplete
                                    initialValue={candidateForm.currentLocation}
                                    onLocationSelect={this.handleLocationSelect}
                                    className={'candidate-profile'}
                                />
                            )}
                        </Form.Item>
                    </div>
                </div>

                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">
                        Experience{candidateSettings.totalExperience && '*'}:
                    </div>
                    <div className="cp-profile-input">
                        <Form.Item>
                            {getFieldDecorator('totalExperience', {
                                rules: [
                                    {
                                        required: candidateSettings.totalExperience,
                                        message: 'Experience is required',
                                    },
                                ],
                                initialValue:
                                    (candidateForm.totalExperience &&
                                        parseFloat(candidateForm.totalExperience.split('years')[0])) ||
                                    0,
                            })(<Input size="default" type="number" step="0.5" addonAfter="years" min={0} />)}
                        </Form.Item>
                    </div>
                </div>

                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">
                        Notice Period{candidateSettings.noticePeriod && '*'}:
                    </div>
                    <div className="cp-profile-input">
                        <Form.Item>
                            {getFieldDecorator('noticePeriod', {
                                rules: [
                                    {
                                        required: candidateSettings.noticePeriod,
                                        message: 'Notice Period is required',
                                    },
                                ],
                                initialValue: candidateForm.noticePeriod,
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select"
                                    getPopupContainer={trigger => trigger.parentNode}
                                >
                                    <Select.Option value="Currently in notice period">
                                        Currently in notice period
                                    </Select.Option>
                                    <Select.Option value="Immediately Available">Immediately Available</Select.Option>
                                    <Select.Option value="10 Days">10 Days</Select.Option>
                                    <Select.Option value="11-30 Days">11-30 Days</Select.Option>
                                    <Select.Option value="1 Month">1 Month</Select.Option>
                                    <Select.Option value="2 Months">2 Months</Select.Option>
                                    <Select.Option value="3 Months">3 Months</Select.Option>
                                    <Select.Option value="More than 3 Months">More than 3 Months</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </div>
                </div>

                {getFieldValue('noticePeriod') === 'Currently in notice period' && (
                    <div className="cp-profile-row">
                        <div className="cp-profile-label edit-label">Last working day*:</div>
                        <div className="cp-profile-input">
                            <Form.Item style={{ marginBottom: 0 }}>
                                {getFieldDecorator('noticeDate', {
                                    rules: [
                                        {
                                            type: 'object',
                                            required: true,
                                            message: 'Last working day is required!',
                                        },
                                    ],
                                    initialValue: moment(candidateForm.noticeDate),
                                })(
                                    <DatePicker
                                        className="cp-form-dp"
                                        size="small"
                                        showTime={false}
                                        showToday={false}
                                        suffixIcon={<Icon component={CalendarSvg} />}
                                        format="DD MMM Y"
                                        disabledDate={current => current && current < moment().startOf('day')}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </div>
                )}

                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">Email{candidateSettings.email && '*'}:</div>
                    <div className="cp-profile-input">
                        <Form.Item>
                            {getFieldDecorator('email', {
                                initialValue: candidateForm.email,
                                validateTrigger: 'onBlur',
                                rules: [
                                    {
                                        required: candidateSettings.email,
                                        whitespace: true,
                                        message: 'Email is required',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Invalid Email!',
                                    },
                                    { validator: this.validEmail },
                                ],
                            })(<Input size="default" />)}
                        </Form.Item>
                    </div>
                </div>

                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">Phone Number{candidateSettings.phone && '*'}:</div>
                    <div className="cp-profile-input">
                        <Form.Item>
                            {getFieldDecorator('phone', {
                                initialValue: candidateForm.phone,
                                rules: [
                                    {
                                        required: candidateSettings.phone,
                                        whitespace: true,
                                        message: 'Phone Number is required',
                                    },
                                    {
                                        validator: phoneValidator(
                                            field => getFieldValue(field),
                                            (field, value, errors) => {
                                                setFields({
                                                    [field]: { value, errors },
                                                })
                                            },
                                            this.props.candidate.phone
                                        ),
                                    },
                                ],
                            })(<Input addonBefore={prefixSelector(candidateForm.countryCode)} size="default" />)}
                        </Form.Item>
                    </div>
                </div>

                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">SkypeID:</div>
                    <div className="cp-profile-input">
                        <Form.Item>
                            {getFieldDecorator('skypeId', {
                                initialValue: candidateForm.skypeId,
                            })(<Input size="default" />)}
                        </Form.Item>
                    </div>
                </div>
                {canIEditCTC && (
                    <div className="cp-profile-row">
                        <div className="cp-profile-label edit-label">Current CTC:</div>
                        <div className="cp-profile-currency-input">
                            <Form.Item style={{ width: '28%' }}>
                                {getFieldDecorator('ctcInfo.currentCTCCurrency', {
                                    initialValue: candidateForm.ctcInfo.currentCTCCurrency,
                                    rules: [
                                        {
                                            validator: this.ctcValidator,
                                        },
                                    ],
                                })(
                                    <Select
                                        allowClear={false}
                                        optionLabelProp="title"
                                        size="default"
                                        style={{ width: '95%' }}
                                        dropdownMatchSelectWidth={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {currencies.map(currency => (
                                            <Select.Option
                                                style={{ lineHeight: '18px' }}
                                                title={currency.symbol}
                                                key={currency.symbol}
                                            >{`${currency.name} (${currency.symbol})`}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item style={{ width: '70%' }}>
                                {getFieldDecorator('ctcInfo.currentCTC', {
                                    initialValue: candidateForm.ctcInfo.currentCTC
                                        ? candidateForm.ctcInfo.currentCTC.includes('.')
                                            ? parseFloat(candidateForm.ctcInfo.currentCTC)
                                            : parseInt(candidateForm.ctcInfo.currentCTC)
                                        : null,
                                    rules: [
                                        {
                                            validator: this.ctcValidator,
                                        },
                                    ],
                                })(<InputNumber style={{ width: '100%' }} placeholder="Enter current CTC" min={0} />)}
                            </Form.Item>
                        </div>
                    </div>
                )}
                {canIEditCTC && (
                    <div className="cp-profile-row">
                        <div className="cp-profile-label edit-label">Expected CTC:</div>
                        <div className="cp-profile-currency-input">
                            <Form.Item style={{ width: '28%' }}>
                                {getFieldDecorator('ctcInfo.expectedCTCCurrency', {
                                    initialValue: candidateForm.ctcInfo.expectedCTCCurrency,
                                    rules: [
                                        {
                                            validator: this.ctcValidator,
                                        },
                                    ],
                                })(
                                    <Select
                                        allowClear={false}
                                        optionLabelProp="title"
                                        size="default"
                                        style={{ width: '95%' }}
                                        dropdownMatchSelectWidth={false}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {currencies.map(currency => (
                                            <Select.Option
                                                style={{ lineHeight: '18px' }}
                                                title={currency.symbol}
                                                key={currency.symbol}
                                            >{`${currency.name} (${currency.symbol})`}</Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item style={{ width: '70%' }}>
                                {getFieldDecorator('ctcInfo.expectedCTC', {
                                    initialValue: candidateForm.ctcInfo.expectedCTC
                                        ? candidateForm.ctcInfo.expectedCTC.includes('.')
                                            ? parseFloat(candidateForm.ctcInfo.expectedCTC)
                                            : parseInt(candidateForm.ctcInfo.expectedCTC)
                                        : null,
                                    rules: [
                                        {
                                            validator: this.ctcValidator,
                                        },
                                    ],
                                })(<InputNumber style={{ width: '100%' }} placeholder="Enter expected CTC" min={0} />)}
                            </Form.Item>
                        </div>
                    </div>
                )}
                {canIEditCTC &&
                    candidateForm.ctcInfo &&
                    (getFieldValue('ctcInfo.currentCTC') ||
                        candidateForm.ctcInfo.currentCTC ||
                        getFieldValue('ctcInfo.expectedCTC') ||
                        candidateForm.ctcInfo.expectedCTC) && (
                        <div className="cp-profile-row cp-ctc-padding">
                            <div>
                                <div className="cp-ctc-user">
                                    Note: Current and Expected CTC is only visible to you
                                    {candidateForm.ctcInfo &&
                                        candidateForm.ctcInfo.accessibleTo &&
                                        candidateForm.ctcInfo.accessibleTo.map(({ id }) => {
                                            let displayName = teamMembersMap[id] || {}
                                            displayName = displayName.fullName || displayName.email
                                            return (
                                                <React.Fragment key={id}>
                                                    , &nbsp;
                                                    <div className="an-nn-sel-user1">
                                                        <div>{displayName}</div>
                                                        <Icon
                                                            component={RemoveIcon}
                                                            onClick={() => this.removeCTCUser(id)}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                    &nbsp;, admins and super admins.
                                </div>
                                {!showCTCAddMember ? (
                                    <div className="add-more-people-who left-margin-10" onClick={this.showCTCAddMember}>
                                        Add more people who can view Current and Expected CTC?
                                    </div>
                                ) : (
                                    <Select
                                        className="cp-tm-search"
                                        placeholder="Search team member to add"
                                        notFoundContent={null}
                                        showSearch
                                        suffixIcon={<Icon type="search" />}
                                        defaultActiveFirstOption={false}
                                        onSelect={this.addCTCUser}
                                        value={this.state.userSearchText}
                                        onSearch={v =>
                                            this.setState({
                                                userSearchText: v,
                                            })
                                        }
                                        filterOption
                                        optionFilterProp="title"
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {teamMembers
                                            .filter(mem => {
                                                let check = mem.status === 1 && mem._id !== userId
                                                if (candidateForm.ctcInfo.accessibleTo) {
                                                    check =
                                                        check &&
                                                        !candidateForm.ctcInfo.accessibleTo.find(
                                                            each => each.id === mem._id
                                                        )
                                                }
                                                return check
                                            })
                                            .map(mem => (
                                                <Select.Option
                                                    key={mem._id}
                                                    className="an-nn-opt"
                                                    title={mem.fullName + ' ' + mem.email}
                                                >
                                                    <div className="an-nn-name">{mem.fullName || mem.email}</div>
                                                    <div className="an-nn-add">Add</div>
                                                </Select.Option>
                                            ))}
                                    </Select>
                                )}
                            </div>
                        </div>
                    )}

                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">Portfolio:</div>
                    <div className="cp-profile-textarea">
                        <Form.Item>
                            {getFieldDecorator('otherDetails', {
                                initialValue: candidateForm.otherDetails,
                                validateTrigger: 'onBlur',
                                rules: [
                                    {
                                        validator: this.portfolioValidator,
                                    },
                                ],
                            })(<Input.TextArea rows={4} placeholder="Add 1 link per Line" />)}
                        </Form.Item>
                    </div>
                </div>

                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">LinkedIn:</div>
                    <div className="cp-profile-input">
                        <Form.Item>
                            {getFieldDecorator('linkedIn', {
                                initialValue: candidateForm.linkedIn,
                                rules: [
                                    {
                                        pattern: CANDIDATE.LINKEDIN,
                                        message: 'Invalid Linkedin URL',
                                    },
                                ],
                            })(<Input size="default" />)}
                        </Form.Item>
                    </div>
                </div>

                <div className="cp-profile-row">
                    <div className="cp-profile-label edit-label">Github:</div>
                    <div className="cp-profile-input">
                        <Form.Item>
                            {getFieldDecorator('github', {
                                initialValue: candidateForm.github,
                                rules: [
                                    {
                                        pattern: CANDIDATE.GITHUB,
                                        message: 'Invalid github URL',
                                    },
                                ],
                            })(<Input size="default" />)}
                        </Form.Item>
                    </div>
                </div>

                <div className="cp-footer-edit-button">
                    <Button type="primary" size="large" ghost style={{ marginRight: 12 }} onClick={this.onCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" size="large" onClick={this.saveProfile}>
                        Save
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { Auth, Mixed } = state
    return {
        candidateSettings: Auth.user.candidateSettings,
        accessLevel: Auth.user.accessLevel,
        id: Auth.user.id,
        user: Auth.user,
        teamMembers: Mixed.teamMembers,
        companyId: Auth.user.companyId,
    }
}

export default connect(
    mapStateToProps,
    { EditCandidate, GetCandidatesEmail }
)(Form.create()(EditProfileForm))
