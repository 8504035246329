import { warning } from '../../../services/Notification'
import apiCaller from '../../apiCaller'
import actions from '../../../redux/jobs/actions'
import mixedActions from '../../../redux/mixed/actions'

const { restricted } = apiCaller.type
const baseUrl = 'job/'

export const UpdateJobOpening = (jobId, data) => async dispatch => {
    try {
        let response = await restricted.patch(`${baseUrl}${jobId}`, data)
        response = response.data
        dispatch({ type: actions.UPDATE_JOB, job: response.job })
        return response.job
    } catch (error) {
        warning(
            (error.response && error.response.data && error.response.data.error && error.response.data.error.msg) ||
                error
        )
    }
}

export const CreateJobOpening = data => async dispatch => {
    try {
        let response = await restricted.post(baseUrl, data)
        response = response.data
        dispatch({ type: actions.ADD_JOB, job: response.jobDetail, stages: response.stages })
        return response
    } catch (error) {
        warning(
            (error.response && error.response.data && error.response.data.error && error.response.data.error.msg) ||
                error
        )
    }
}

export const AddJobCoordinator = data => async dispatch => {
    try {
        await restricted.post(`${baseUrl}assignCoordinator`, data)
        dispatch({
            type: mixedActions.UPDATE_TEAM_MEMBER,
            payload: { updateJob: true, _id: data.userId, jobId: data.jobId },
        })
        return
    } catch (error) {
        warning(
            (error.response && error.response.data && error.response.data.error && error.response.data.error.msg) ||
                error
        )
    }
}

export const RemoveJobCoordinator = data => async dispatch => {
    try {
        const resp = await restricted.post(`${baseUrl}removeCoordinator`, data)
        dispatch({
            type: mixedActions.UPDATE_TEAM_MEMBER,
            payload: { updateJob: true, _id: data.userId, jobId: data.jobId, isRemoveCoordinator: true },
        })
        return
    } catch (error) {
        warning(
            (error.response && error.response.data && error.response.data.error && error.response.data.error.msg) ||
                error
        )
    }
}

export const GetJob = jobId => async dispatch => {
    try {
        let response = await restricted.get(`${baseUrl}${jobId}`)
        //TODO: Check if we can store this in redux somehow
        return response.data
    } catch (error) {
        warning(
            (error.response && error.response.data && error.response.data.error && error.response.data.error.msg) ||
                error
        )
    }
}
