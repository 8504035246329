import { store } from '../../../../redux/store'

export const accessibleMemberString = ({ accessibleTo = [] }, teamMembersMap) => {
    let text = ''
    const { id: userId } = store.getState().Auth.user
    // cleaning users own access name from the list
    accessibleTo = accessibleTo.filter(({ id }) => id !== userId)

    // using this because join do not work with length=1
    if (accessibleTo.length === 0) {
        return text
    } else if (accessibleTo.length === 1) {
        const accessId = accessibleTo[0].id
        if (userId === accessId) return text
        const person = teamMembersMap[accessId]
        text = `${person.fullName || person.email} ,`
    } else
        text = accessibleTo
            .map(({ id }) => {
                const person = teamMembersMap[id]
                return person.fullName || person.email
            })
            .join(' ,')
    return text
}

export const accessibleAdminString = () => {
    return ' super admin and admin.'
}

export const canIViewCTCEvaluator = ({ ctcInfo = {}, ctcDataAllowed = true }) => {
    const { accessLevel, id } = store.getState().Auth.user

    const canIViewCTC =
        ctcDataAllowed &&
        (accessLevel < 3 ||
            ctcInfo.addedBy === id ||
            (ctcInfo.accessibleTo && ctcInfo.accessibleTo.find(each => each.id === id)))

    return canIViewCTC
}
