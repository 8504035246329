import React from 'react'
import { Modal, Button, Input, Form, Icon } from 'antd'
import { ModalWrapper, ModalElementWrapper, ElementWrapper, InfoWrapper } from './style'
import './style.css'
import CompanyNameIcon from '../../../assets/Icons/Auth/Comapny Name'
import URLIcon from '../../../assets/Icons/Auth/URL'
import TickIcon from '../../../assets/Icons/Dashboard/Tick'

class CreateNewCompanyModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            visible: props.visible,
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Modal
                visible={this.state.visible}
                onCancel={this.props.onCancel}
                footer={null}
                closable={false}
                width={464}
                bodyStyle={{ padding: '33px 80px 40px', fontSize: '12px' }}
                centered
            >
                <ModalWrapper>
                    <div className="cnc-modal-as-title">Create New Company</div>
                    <Form>
                        <ElementWrapper>
                            <Form.Item>
                                {getFieldDecorator('companyName', {
                                    rules: [{ required: true, message: 'Company name is required' }],
                                })(
                                    <Input
                                        size="large"
                                        className="chp-input"
                                        placeholder="Enter Your Company Name"
                                        suffix={<Icon component={CompanyNameIcon} />}
                                    />
                                )}
                            </Form.Item>

                            <Form.Item>
                                {getFieldDecorator('websiteUrl', {
                                    rules: [{ required: true, message: 'website Url is required' }],
                                })(
                                    <Input
                                        size="large"
                                        className="chp-input"
                                        placeholder="Enter Your Website URL"
                                        suffix={<Icon component={URLIcon} />}
                                    />
                                )}
                            </Form.Item>
                        </ElementWrapper>
                        <ModalElementWrapper className="cnc-action-wrapper">
                            <Button
                                type="primary"
                                ghost
                                size="large"
                                className="delete-width-120p"
                                onClick={this.props.onCancel}
                            >
                                Cancel
                            </Button>
                            <Button type="primary" size="large" className="delete-width-120p">
                                Create
                            </Button>
                        </ModalElementWrapper>

                        <InfoWrapper>
                            <div>
                                <Icon component={TickIcon} />
                                <div>A complete new dashboard for the new company</div>
                            </div>
                            <div>
                                <Icon component={TickIcon} />
                                <div>One login to access all the dashboards</div>
                            </div>
                            <div>
                                <Icon component={TickIcon} />
                                <div>Switch between company dashboards at anytime</div>
                            </div>
                        </InfoWrapper>
                    </Form>
                </ModalWrapper>
            </Modal>
        )
    }
}

export default Form.create({ name: 'cnc' })(CreateNewCompanyModal)
