import React from 'react'
import { ReactComponent as CalendarIcon } from '../../../../assets/Icons/Calendar/calendar.svg'
import { Content, Header, Item, Num, Heading } from '../style'
import { history } from '../../../../redux/store'
import { Btn } from '../../../Pages/ScheduleInterview/Form/Address/style'
import { useSelector } from 'react-redux'

const ConnectSuccess = props => {
    const availability = useSelector(({ GCal }) => GCal.availability) || {}

    const goToNextStep = () => {
        if (availability && availability.length) {
            history.replace(window.location.pathname)
            props.cancel()
        } else props.changeStep(3)
    }
    return (
        <Content>
            <Header>
                <CalendarIcon /> Google calendar connected successfully
            </Header>
            <Heading>SpringRecruit will</Heading>
            <Item>
                <Num>01</Num>
                <p>Check your calendar for conflicts</p>
            </Item>
            <Item>
                <Num>02</Num>
                <p>Check your calendar to view busy / available times</p>
            </Item>
            <Item>
                <Num>03</Num>
                <p>Add events to your calendar</p>
            </Item>
            <Btn primary save onClick={goToNextStep}>
                CONTINUE
            </Btn>
        </Content>
    )
}

export default ConnectSuccess
