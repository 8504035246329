import styled from 'styled-components'

export const Popup = styled.div`
    display: flex;
    flex-direction: row;
    width: 750px;
`

export const Aside = styled.aside`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    background-color: #dde3ee;
    .tick {
        margin-bottom: 20px;
    }
`
export const Title = styled.div`
    span {
        font-family: sanFranciscoMedium;
        font-size: 22px;
        font-weight: 500;
        color: #333333;
    }
    text-align: center;
    width: 60%;
    display: block;
    margin-bottom: 30px;
`
export const Message = styled.span`
    font-family: sanFranciscoMedium;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    text-align: center;
    width: 80%;
    display: block;
    margin-bottom: ${props => (props.small ? '10px' : '30px')};
`
export const Content = styled.div`
    padding: 20px 25px;
    min-height: 440px;
    min-width: 400px;
    width: calc(100% - 300px);
    ${props =>
        props.center &&
        `
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `};
    .tick {
        margin-bottom: 10px;
    }
    .ant-form-explain {
        margin-top: 3px;
    }
`
export const Header = styled.div`
    display: flex;
    align-items: center;
    font-family: sanFranciscoMedium;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #d4d4d4;
    svg {
        margin-right: 10px;
    }
`
export const Heading = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 10px;
`
export const Item = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: ${props => (props.small ? '15px' : '30px')};
    p {
        font-size: 12px;
        color: #666666;
        margin-bottom: 0;
    }
    ${props =>
        props.center &&
        `
        text-align: center;
    `};
    .options {
        margin-left: 30px;
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        ::after {
            content: '';
            border-bottom: 1px solid #d4d4d4;
            height: 1px;
            width: 20px;
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .ant-select {
        width: 130px;
        font-size: 12px;
        color: #666666;
    }
    .ant-select .ant-select-arrow {
        svg {
            transition: transform 0.2s ease-in;
        }
    }
    .ant-select-open .ant-select-arrow {
        svg {
            transition: transform 0.2s ease-in;
            transform: rotate(180deg);
        }
    }
    .ant-select-arrow {
        margin-top: -4px;
    }
`
export const Num = styled.div`
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: solid 1.2px #4767a0;
    border-radius: 50%;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: bold;
    color: #4767a0;
    margin-right: 10px;
`
export const Buttons = styled.div`
    display: flex;
    width: 210px;
    :focus-within {
        outline: -webkit-focus-ring-color auto 1px;
    }
`
export const Button = styled.button`
    width: ${props => (props.secondary ? '80px' : '170px')};
    height: ${props => (props.secondary ? '30px' : '40px')};
    border-radius: 4px;
    background-color: ${props => (props.secondary ? '#fff' : '#4385f5')};
    font-family: ${props => (props.secondary ? 'sanFranciscoMedium' : 'Roboto')};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    color: ${props => (props.secondary ? '#4767a0' : '#fff')};
    border: ${props => (props.secondary ? 'solid 1px #4767a0' : 'none')};
    cursor: pointer;
    :disabled {
        opacity: 0.5;
    }
    :focus {
        outline: none;
    }
    border-top-left-radius: ${props => (props.secondary ? '4px' : '0px')};
    border-bottom-left-radius: ${props => (props.secondary ? '4px' : '0px')};
`
export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-top: 20px;
    border-top: solid 1px #d4d4d4;
    height: 50px;
    span {
        font-size: 12px;
        color: #666;
    }
`
export const Day = styled.div`
    width: 30px;
    height: 30px;
    border: solid 2px #4767a0;
    background-color: ${props => (props.status ? '#4767a0' : '#fff')};
    font-family: sanFranciscoMedium;
    font-size: 14px;
    font-weight: bold;
    color: ${props => (props.status ? '#fff' : '#4767a0')};
    border: ${props => (props.status ? 'none' : 'solid 2px #4767a0;')};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 4em;
    cursor: pointer;
    transition: background-color 400ms ease;
`
export const Days = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 20px;
`
export const DayLabel = styled.span`
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    color: #333333;
    width: 85px;
`

export const Google = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: solid 2px #4385f5;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none;
    cursor: pointer;
    background-color: #fff;
    :disabled {
        opacity: 0.5;
    }
    :focus {
        outline: none;
    }
`

export const Error = styled.span`
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    margin-bottom: 10px;
    color: red;
`
