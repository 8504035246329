import ContentLoader from 'react-content-loader'
import React from 'react'

export const CandidateDataLoader = () => (
    <ContentLoader height={284}>
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
        <rect x="0" y="105" rx="1" ry="1" width="120" height="20" />
        <rect x="0" y="150" rx="1" ry="1" width="250" height="18" />
    </ContentLoader>
)

export const JobSkillLoader = () => (
    <ContentLoader height={225}>
        <rect x="0" y="0" rx="1" ry="1" width="150" height="10" />
        <rect x="0" y="15" rx="3" ry="3" width="400" height="70" />
    </ContentLoader>
)
