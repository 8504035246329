import React from 'react'
import { HeaderContainer } from './style'
import { Button, Icon, Select } from 'antd'
import './style.css'
import NoOpening from './NoOpenings'
import OpeningCard from './OpeningCard'
import { Modal } from './../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import { GetCompanyJobs } from '../../../services/api'
import { warning } from '../../../services/Notification'
import ContentLoader from 'react-content-loader'
import { store, history } from '../../../redux/store'
import { ReactComponent as WhiteAddIcon } from '../../../assets/Icons/Talent Pool/white-bg-add.svg'
import { HotKeys } from 'react-keyboard'
import { keyMap, access } from '../../Helpers/KeyMap'
import { shouldCalPopBeOpened, AUTH_ENTRY_POINTS, FORCE_OPEN } from './../../Helpers/calendarPopUp'
import HiddenInput from '../../Helpers/HiddenInput'
import { BannerGoogleCal } from '../../Banners'
import actionsCandidate from '../../../redux/candidate/actions'
import StyledButton from '../../Components/StyledButton'
import { SearchBox } from '../../../container/Pages/Sourcing/style.js'
import { zohoServiceVisibility } from '../../../services/ChatBotsService/zohoService'
import { OPENING_STATUS } from '../../Helpers/constants'

const MyLoader = () => (
    <ContentLoader height={160} width={300} speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        {[0, 35, 70].map(y => (
            <rect key={y} x="0" y={y} rx="3" ry="3" width="100%" height="30" />
        ))}
    </ContentLoader>
)

const openingsAccess = access.openings

export default class Opening extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openings: [],
            openingsForSearch: [],
            isLoaded: false,
            candidateModalVisible: false,
            showSelectJobModal: false,
            selectedJobId: null,
            jobDetails: {},
            accessLevel: store.getState().Auth.user.accessLevel,
            isLoading: true,
            calPopUpStatus: false,
            openingStatusFilter: 'ALL',
            openingSeacrhQuery: '',
        }
    }

    componentDidMount() {
        document.title = 'Openings | SpringRecruit'
        this.allowShortcuts = store.getState().Auth.user.allowShortcuts
        this.getOpenings()
        this.props.allowSwitchTabs(true)

        zohoServiceVisibility(true)
    }

    async getOpenings() {
        try {
            const openings = await GetCompanyJobs()
            const {
                location: { state },
            } = store.getState().router
            const calPopUpStatus =
                (state &&
                    state.entryPoint === AUTH_ENTRY_POINTS.SIGN_IN &&
                    shouldCalPopBeOpened(this.props.__redux.GCal)) ||
                false

            if (openings.jobs) {
                openings.jobs.reverse()

                // the status value of the variable is made to sequencial numerical valued
                // needed status order: 1->2->0
                const equalWeightMaping = [10, 12, 11]

                const sortfn = (a, b) => {
                    const _a = equalWeightMaping[a.status]
                    const _b = equalWeightMaping[b.status]
                    return _a < _b ? 1 : -1
                }
                openings.jobs.sort(sortfn)

                this.setState({
                    openings: openings ? openings.jobs : [],
                    openingsForSearch: openings ? openings.jobs : [],
                    isLoading: false,
                    isLoaded: true,
                    calPopUpStatus,
                })
            }
        } catch (err) {
            warning(err.message || err)
        }
    }

    handleOpeningFilterChange = openingStatusFilter => {
        this.setState({ openingStatusFilter })
    }

    handleCancel = () => {
        this.setState({
            candidateModalVisible: false,
            showSelectJobModal: false,
        })
        // needed this as the modal is unmounted with animation, clearing the data without component unmounted will cause errors
        setTimeout(() => store.dispatch({ type: actionsCandidate.CLEAR_CANDIDATES }), 500)
        this.hiddenInputRef.focus({ preventScroll: true })
    }

    showSelectJobModal = () => {
        this.setState({ showSelectJobModal: true })
    }

    showCandidateModal = jobDetails => {
        store.dispatch({ type: actionsCandidate.CLEAR_CANDIDATES })
        store.dispatch({ type: actionsCandidate.ADD_CANDIDATES })
        this.setState({ candidateModalVisible: true, showSelectJobModal: false, jobDetails })
    }

    goToOpenings = () => {
        this.props.history.push('openings/createJob')
    }

    newCandidate = candidate => {
        this.setState({ candidateModalVisible: false })
    }

    handlers = {
        createJob: () => {
            if (this.allowShortcuts && store.getState().Auth.user.accessLevel <= openingsAccess.createJob)
                this.goToOpenings()
        },
        createCandidate: () => {
            if (this.allowShortcuts && store.getState().Auth.user.accessLevel <= openingsAccess.createCandidate)
                this.showCandidateModal()
        },
    }

    render() {
        const { calPopUpStatus, openingStatusFilter, openingsForSearch, openingSeacrhQuery } = this.state
        return (
            <HotKeys keyMap={keyMap.openings} handlers={this.handlers}>
                <div className="opening-container">
                    <BannerGoogleCal
                        data={this.props.__redux.GCal}
                        action_allowAccess={() => this.setState({ calPopUpStatus: FORCE_OPEN.AUTHENTICATE })}
                        action_setManually={() => this.setState({ calPopUpStatus: FORCE_OPEN.AVAILABILITY })}
                    />
                    <HeaderContainer>
                        <div className="opening-header-section">
                            <div className="opening-header-title">
                                <h3>OPENINGS</h3>
                            </div>
                            <div className="opening-header-buttons">
                                <SearchBox
                                    placeholder="Search openings by name, location"
                                    onChange={({ target: { value } }) => {
                                        this.setState({ openingSeacrhQuery: value })
                                        if (value === '') {
                                            this.setState({ openings: openingsForSearch })
                                        } else {
                                            const filteredOpenings = openingsForSearch.filter(job => {
                                                if (
                                                    job.jobTitle
                                                        .toLowerCase()
                                                        .includes(openingSeacrhQuery.toLowerCase()) ||
                                                    job.jobLocation
                                                        .toLowerCase()
                                                        .includes(openingSeacrhQuery.toLowerCase())
                                                )
                                                    return job
                                            })
                                            this.setState({ openings: filteredOpenings })
                                        }
                                    }}
                                />
                                <StyledButton width={'150px'} height={'42px'} onClick={this.showSelectJobModal}>
                                    <Icon component={WhiteAddIcon} />
                                    <span className="op-header-button">Add Candidate</span>
                                </StyledButton>
                                <StyledButton
                                    width={'150px'}
                                    height={'42px'}
                                    onClick={this.goToOpenings}
                                    hidden={this.state.accessLevel > 2}
                                >
                                    <Icon component={WhiteAddIcon} />
                                    <span className="op-header-button">Add Opening</span>
                                </StyledButton>
                            </div>
                        </div>
                    </HeaderContainer>
                    <div className="filter">
                        <span>Filter by: </span>
                        <Select defaultValue={openingStatusFilter} onChange={this.handleOpeningFilterChange}>
                            <Select.Option key="ALL">All</Select.Option>
                            <Select.Option key="ACTIVE">Active</Select.Option>
                            <Select.Option key="UNPUBLISHED">Unpublished</Select.Option>
                            <Select.Option key="ARCHIVED">Archived</Select.Option>
                        </Select>
                    </div>

                    {this.state.isLoaded && this.state.openings.length ? (
                        <OpeningCard
                            openings={this.state.openings.filter(
                                ({ status }) =>
                                    openingStatusFilter === 'ALL' || OPENING_STATUS[openingStatusFilter] === status
                            )}
                            addCandidate={this.showCandidateModal}
                            {...this.props}
                        />
                    ) : null}

                    {this.state.isLoaded && this.state.openings.length === 0 ? (
                        <NoOpening history={this.props.history} />
                    ) : null}

                    {!this.state.isLoaded ? (
                        <div className="card-container">
                            <MyLoader />
                        </div>
                    ) : null}

                    <Modal
                        visible={this.state.showSelectJobModal}
                        type={MODAL_TYPES.SELECT_JOB}
                        companyId={this.props.__redux.Auth.user.companyId}
                        openAddCandidate={jobDetails => this.showCandidateModal(jobDetails)}
                        onCancel={() => this.setState({ showSelectJobModal: false })}
                    />

                    <Modal
                        type={MODAL_TYPES.ADD_CANDIDATE}
                        visible={this.state.candidateModalVisible}
                        onCancel={this.handleCancel}
                        onSubmit={this.newCandidate}
                        jobDetails={this.state.jobDetails}
                    />
                    <Modal
                        type={MODAL_TYPES.CALENDAR_ONBOARDING}
                        visible={!!calPopUpStatus}
                        onCancel={() =>
                            this.setState({ calPopUpStatus: false }, () => history.replace(window.location.pathname))
                        }
                        redirectPath={'openings'}
                        calPopUpStatus={calPopUpStatus}
                        width={750}
                        closable={true}
                        bodyStyle={{ padding: 0 }}
                    />

                    <HiddenInput reference={input => (this.hiddenInputRef = input)} />
                </div>
            </HotKeys>
        )
    }
}
