import React, { useState } from 'react'
import { Button, Spin } from 'antd'
import { CardCollapsible, Title } from '../style'
import { ReactComponent as ExpandIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Btn.svg'
import { ReactComponent as CollapseIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand or Collapse Btn.svg'
import { ReactComponent as OpenInNewTabIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/OpenInNewTab.svg'

const HeaderBox = ({ isActive, setIsActive }) => (
    <div className="header">
        <Title>Resume:</Title>
        {isActive ? (
            <CollapseIcon onClick={() => setIsActive(false)} />
        ) : (
            <ExpandIcon onClick={() => setIsActive(true)} />
        )}
    </div>
)

const OpenInNewButton = ({ link }) => (
    <Button className="new-tab" onClick={() => window.open(link, '_blank')} type="primary">
        Open in new tab <OpenInNewTabIcon />
    </Button>
)

const Resume = ({ isLoading, candidateDetails }) => {
    const [isActive, setIsActive] = useState(true)
    const [resumeLoaderActive, setResumeLoaderActive] = useState(true)
    if (isLoading)
        return (
            <CardCollapsible active>
                <HeaderBox isActive setIsActive={() => { }} />
                <Spin className="spin-loader-center1" />
            </CardCollapsible>
        )

    const {
        resume: { current },
    } = candidateDetails

    if (!current) return null

    const resumeLoadHandler = event => setResumeLoaderActive(false)
    const formatedLink = current.includes('.doc')
        ? `https://view.officeapps.live.com/op/embed.aspx?src=${current}`
        : current

    return (
        <CardCollapsible active={isActive}>
            <HeaderBox isActive={isActive} setIsActive={setIsActive} />
            {!resumeLoaderActive && <OpenInNewButton link={formatedLink} />}
            <iframe
                title="resume"
                key="resume-iframe-key"
                onLoad={resumeLoadHandler}
                className={resumeLoaderActive ? 'display-none' : 'resume-preview'}
                src={formatedLink}
            />
        </CardCollapsible>
    )
}

export default Resume
