import { Field } from '../../AddCandidate/style'
import { ModalSubTitle } from '../../AddFeedback/style'
import { ReactComponent as RemoveIcon } from '../../../../assets/Icons/AccountSettingPage/Remove Button.svg'
import { Checkbox, Input } from 'antd'
import { Form } from '../../../Pages/WebForm/style'
import React, { useState } from 'react'
import { EDIT_TYPES, INPUT_LIMITS, NAME_FREQUENCY } from '../Constants'
import { FieldPreview } from './SkillsPreview'
import { warning } from '../../../../services/Notification'
import StyledButton from '../../../Components/StyledButton'
import { Footer } from '../../common'
import { skills } from '../../../Pages/WebForm/constants'

const defaultSkillData = { name: '', description: '', required: false, nameError: '', descriptionError: '' }

export const SkillForm = ({ formMode = null, changeFormMode, confirmSkill, editSkill, skills }) => {
    const [newArray, setNewArray] = useState((editSkill && [editSkill]) || [{ ...defaultSkillData }])
    const [errorCount, setErrorCount] = useState(0)
    if (formMode === null) return null

    const saveSkills = () => {
        let sectionErrorCount = 0
        const duplicateNameArr = []
        const nameFrequency = {}
        skills.forEach(each => (nameFrequency[each.name.toLowerCase()] = [-1]))

        const verifiedArray = newArray.map((skill, index) => {
            skill.name = skill.name.trim()
            skill.description = skill.description.trim()
            const skillNameLowerCase = skill.name.toLowerCase()

            if (!skill.nameError && skill.name.length === 0) skill.nameError = 'Section name cannot be blank'
            else skill.nameError = ''

            if (nameFrequency.hasOwnProperty(skillNameLowerCase)) {
                nameFrequency[skillNameLowerCase].push(index)
                duplicateNameArr.push(skillNameLowerCase)
            } else nameFrequency[skillNameLowerCase] = [index]

            if (skill.nameError) sectionErrorCount++

            return skill
        })

        if (sectionErrorCount > 0) {
            setErrorCount(sectionErrorCount)
            setNewArray(verifiedArray)
            return null
        }

        if (duplicateNameArr.length) {
            duplicateNameArr.forEach(name =>
                nameFrequency[name].forEach(nameIndex => {
                    if (nameIndex >= 0) {
                        verifiedArray[nameIndex].nameError = `Duplicate Section Name found`
                        sectionErrorCount++
                    }
                })
            )
            setErrorCount(sectionErrorCount)
            setNewArray(verifiedArray)
            return null
        }

        confirmSkill(verifiedArray)
    }

    const updateSkillArray = (index, field, data) => {
        const updatedData = [...newArray]
        const hadError = updatedData[index].nameError.length || updatedData[index].descriptionError.length

        if (data.length > INPUT_LIMITS[field]) {
            updatedData[index][`${field}Warning`] = `Maximum text limit of ${INPUT_LIMITS[field]} reached`
            setNewArray(updatedData)
            return null
        }

        updatedData[index][field] = data
        updatedData[index][`${field}Error`] = ''
        updatedData[index][`${field}Warning`] = ''

        if (hadError && !(updatedData[index].nameError.length || updatedData[index].descriptionError.length))
            setErrorCount(errorCount - 1)
        setNewArray(updatedData)
    }

    const addNewSkill = () => {
        if (newArray.length < 4) setNewArray([...newArray, { ...defaultSkillData }])
        else warning('Maximum 8 skills allowed')
    }

    const removeSkill = index => {
        if (newArray.length === 1) {
            changeFormMode(null)
            return null
        }

        if (newArray[index].nameError.length) setErrorCount(errorCount - 1)

        setNewArray([...newArray.slice(0, index), ...newArray.slice(index + 1)])
    }

    return (
        <Form className="form">
            <Field className="full">
                <ModalSubTitle className="heading">
                    {formMode === EDIT_TYPES.EDIT ? 'Edit Skill' : 'Add New Skill'}
                    {errorCount > 0 && <span className="error-found">{errorCount} Errors found</span>}
                </ModalSubTitle>
            </Field>

            <FieldPreview skills={newArray} updateSkillArray={updateSkillArray} removeSkill={removeSkill} />

            <Footer
                onCancel={() => changeFormMode(null)}
                onConfirm={saveSkills}
                cancelText="CANCEL"
                confirmText="ADD"
            />

            <Field className="full" hidden={formMode === EDIT_TYPES.EDIT || skills.length + newArray.length === 8}>
                <button onClick={addNewSkill} className="add-more">
                    ADD MORE SKILL
                </button>
            </Field>
        </Form>
    )
}
