import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { PAGE_SECTIONS } from '../../../Helpers/Stylesheet/styleTypes'
import { PageContainer } from '../../../Components/StyledPages/PageContainer'
import { Form } from '../../../Pages/WebForm/style'
import { Field } from '../../../Modals/AddCandidate/style'
import { Editor } from '@tinymce/tinymce-react'
import config from '../../../../config'
import { StyledButton } from '../../../Components'
import { Modal } from '../../../Modals'
import { MODAL_TYPES } from '../../../Helpers/ModalTypes'
import { ReactComponent as InfoIcon } from '../../../../assets/Icons/Interview/info.svg'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { ReactComponent as EyeIcon } from '../../../../assets/Icons/Talent Pool/eye.svg'
import { ReactComponent as EditIcon } from '../../../../assets/Icons/Talent Pool/Edit.svg'
import { Select, Icon } from 'antd'
import { success } from '../../../../services/Notification'

const { Option } = Select
const { tinyMCEApiKey } = config.config()

const errorsEvaluator = async (formData, setErrors, setDisable, updateJobDetails, customSkills, onCloneMode) => {
    const { jobDescription, jobRequirements, jobBenefits } = formData
    let errors = {}

    if (!jobDescription) errors.jobDescription = 'Job description is required'
    if (!jobRequirements) errors.jobRequirements = 'Job Requirements are required'
    if (!jobBenefits) errors.jobBenefits = 'Job Benefits are required'

    if (Object.keys(errors).length) setErrors(errors)
    else {
        setDisable(true)
        await updateJobDetails(formData, 'save', customSkills, onCloneMode)
        setDisable(false)
    }
}

const getCustomFeedbackJobsMap = customFeedbackJobs => {
    let customFeedbackJobsMap = {}
    customFeedbackJobs.forEach(job => {
        customFeedbackJobsMap[job.jobTitle] = job.feedbackForm.components.map(component => ({
            name: component.name,
            description: component.description,
            status: component.status,
            required: component.required,
        }))
    })
    return customFeedbackJobsMap
}

const Descriptions = ({
    jobDetails,
    updateJobDetails,
    onEditMode,
    onCloneMode,
    customFeedbackData,
    customFeedbackJobs,
}) => {
    const [formData, setFormData] = useState(jobDetails)
    const [errors, setErrors] = useState(null)
    const [disable, setDisable] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(customFeedbackData.title)
    const [customSkills, setCustomSkills] = useState(customFeedbackData.skills)
    const [modalVisible, setModalVisible] = useState({})
    const [isDefault, setIsDefault] = useState(false)
    const [customFeedbackJobsMap, setCustomFeedbackJobsMap] = useState({})

    useEffect(() => {
        setFormData(jobDetails)
        const customFeedbackJobsMap = getCustomFeedbackJobsMap(customFeedbackJobs)
        setCustomFeedbackJobsMap(customFeedbackJobsMap)
        if (Object.keys(customFeedbackJobsMap).includes(jobDetails.jobTitle)) {
            setSelectedTemplate(jobDetails.jobTitle)
            setCustomSkills(customFeedbackJobsMap[jobDetails.jobTitle])
        } else {
            setSelectedTemplate('default')
            setCustomSkills([])
        }
    }, [jobDetails, customFeedbackJobs])

    const handleChange = (content, editor) => {
        setFormData({ ...formData, [editor.id]: content })
        setErrors({ ...errors, [editor.id]: null })
    }

    return (
        <>
            <PageContainer title="DESCRIPTION AND FEEDBACK" type={PAGE_SECTIONS.MAIN} empty />
            <PageContainer title="JOB DESCRIPTION" type={PAGE_SECTIONS.SECTION}>
                <Form>
                    <Field className="full">
                        <div className="title">Description*</div>
                        <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                            // apiKey={tinyMCEApiKey}
                            id="jobDescription"
                            value={formData.jobDescription}
                            onEditorChange={handleChange}
                            init={{
                                max_height: 230,
                                force_br_newlines: true,
                                forced_root_block: '',
                                menubar: false,
                                plugins: 'link image code autoresize lists',
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                            }}
                        />
                        {errors && errors.jobDescription ? <div className="error">{errors.jobDescription}</div> : null}
                    </Field>
                    <Field className="full">
                        <div className="title">Requirements*</div>
                        <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                            // apiKey={tinyMCEApiKey}
                            id="jobRequirements"
                            value={formData.jobRequirements}
                            onEditorChange={handleChange}
                            init={{
                                max_height: 230,
                                force_br_newlines: true,
                                forced_root_block: '',
                                menubar: false,
                                plugins: 'link image code autoresize lists',
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                            }}
                        />
                        {errors && errors.jobRequirements ? (
                            <div className="error">{errors.jobRequirements}</div>
                        ) : null}
                    </Field>
                    <Field className="full">
                        <div className="title">Benefits and about company*</div>
                        <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                            // apiKey={tinyMCEApiKey}
                            id="jobBenefits"
                            value={formData.jobBenefits}
                            onEditorChange={handleChange}
                            init={{
                                max_height: 230,
                                force_br_newlines: true,
                                forced_root_block: '',
                                menubar: false,
                                plugins: 'link image code autoresize lists',
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                            }}
                        />
                        {errors && errors.jobBenefits ? <div className="error">{errors.jobBenefits}</div> : null}
                    </Field>
                </Form>
            </PageContainer>
            <PageContainer title="FEEDBACK FORM" type={PAGE_SECTIONS.SECTION}>
                <p>Customize the feedback form to add additional skills to evaluate candidates on</p>
                <div className="feedback">
                    {Object.keys(customFeedbackJobsMap).length ? (
                        <Field className="field">
                            <div className="title">Available Feedback Form</div>
                            <p className="flex">
                                Choose a feedback form to use with this opening
                                <div>
                                    <EyeIcon className="eye-icon" />
                                    <a onClick={() => setModalVisible({ addFeedback: true })}>Preview</a>
                                    {selectedTemplate !== 'default' ? (
                                        <>
                                            <EditIcon className="edit-icon" />
                                            <a onClick={() => setModalVisible({ customizeFeedback: true })}>Edit</a>
                                        </>
                                    ) : null}
                                </div>
                            </p>
                            <Select
                                size="large"
                                className="feedback-select"
                                placeholder="Select"
                                value={selectedTemplate}
                                suffixIcon={<Icon component={DownIcon} />}
                                onChange={value => {
                                    setSelectedTemplate(value)
                                    if (value === 'default') setCustomSkills([])
                                    else setCustomSkills(customFeedbackJobsMap[value])
                                }}
                            >
                                <Option value="default">Default</Option>
                                {Object.keys(customFeedbackJobsMap).map(template => (
                                    <Option value={template}>{template}</Option>
                                ))}
                            </Select>
                        </Field>
                    ) : null}
                    <StyledButton
                        type="inverted"
                        width="160px"
                        height="40px"
                        className="button"
                        onClick={() => {
                            setIsDefault(true)
                            setModalVisible({ customizeFeedback: true })
                        }}
                    >
                        Customize Feedback
                    </StyledButton>
                    <p>
                        <InfoIcon />
                        Default feedback form will be associated with this opening, if not customized.{' '}
                        <a
                            onClick={() => {
                                setIsDefault(true)
                                setModalVisible({ addFeedback: true })
                            }}
                        >
                            &nbsp;Click here&nbsp;
                        </a>{' '}
                        to see default form view
                    </p>
                </div>
            </PageContainer>
            <div className="buttons">
                <StyledButton
                    type="inverted"
                    width="80px"
                    height="40px"
                    disable={disable}
                    onClick={() =>
                        updateJobDetails(formData, 'back', {
                            title: selectedTemplate,
                            skills: customSkills,
                        })
                    }
                >
                    Back
                </StyledButton>
                <StyledButton
                    width={onEditMode ? '140px' : '80px'}
                    height="40px"
                    disable={disable}
                    onClick={() =>
                        errorsEvaluator(formData, setErrors, setDisable, updateJobDetails, customSkills, onCloneMode)
                    }
                >
                    {/* Being used in edit job opening */}
                    {onEditMode ? 'Save & Continue' : 'Next'}
                </StyledButton>
            </div>
            <Modal
                type={MODAL_TYPES.ADD_FEEDBACK}
                visible={modalVisible.addFeedback}
                onCancel={() => {
                    setModalVisible({})
                    setIsDefault(false)
                }}
                onlyPreview
                overrideCustomSkills={isDefault ? [] : customSkills}
            />
            <Modal
                type={MODAL_TYPES.CUSTOMIZE_FEEDBACK}
                customSkills={isDefault ? [] : customSkills}
                visible={modalVisible.customizeFeedback}
                onCancel={() => {
                    setModalVisible({})
                    setIsDefault(false)
                }}
                onSubmit={data => {
                    if (data.length) {
                        customFeedbackJobsMap[jobDetails.jobTitle] = data
                        setSelectedTemplate(jobDetails.jobTitle)
                        setCustomSkills(data)
                    } else {
                        setSelectedTemplate('default')
                        setCustomSkills([])
                    }
                    success('Feedback form customized successfully, click next to save it for the opening')
                    setIsDefault(false)
                    setModalVisible({})
                }}
            />
        </>
    )
}

const mapStateToProps = ({ Job }) => {
    return {
        customFeedbackJobs: Job.allJobs
            .map(job => {
                job.feedbackForm.components = job.feedbackForm.components.filter(component => component.status === 1)
                return job
            })
            .filter(job => job.feedbackForm.components.length),
    }
}

export default connect(
    mapStateToProps,
    {}
)(Descriptions)
