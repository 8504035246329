import React, { useState } from 'react'
import { Icon, Select } from 'antd'
import { ComponentWrapper } from './style'
import { ReactComponent as DownIcon } from '../../../assets/Icons/Interview/down.svg'
import { connect } from 'react-redux'
import { Header, Footer } from '../common'
import { GetJobDetails } from '../../../services/NetworkCalls/Job'
const { Option } = Select

const DownloadReport = props => {
    const { allJobs, onCancel, onSubmit, user } = props
    const onConfirm = async value => {
        if (value) {
            setLoading(true)
            onSubmit({ jobId: value })
            setLoading(false)
        }
    }
    const [jobId, setJobId] = useState(undefined)
    const [loading, setLoading] = useState(false)
    return (
        <>
            <Header title="CANDIDATE REPORT" />
            {user && !user.exportInProcess ? (
                <ComponentWrapper>
                    <div className="title">Select opening to download</div>
                    <Select
                        size="large"
                        placeholder={'Select'}
                        getPopupContainer={trigger => trigger.parentNode}
                        suffixIcon={<Icon component={DownIcon} />}
                        value={jobId}
                        style={{ width: '200px' }}
                        onChange={value => {
                            setJobId(value)
                        }}
                        required={true}
                    >
                        <Option key="job-unselect-key" value={undefined}>
                            --Select---
                        </Option>
                        {allJobs.map(option => (
                            <Option value={option._id} key={option._id}>
                                {option.jobTitle}
                            </Option>
                        ))}
                    </Select>
                </ComponentWrapper>
            ) : null}
            {user && user.exportInProcess && (
                <p>
                    Note: You will shortly receive the candidate report via email. In the mean time you won't be able to
                    generate another report. This modal will automatically reset after 10 mins.
                </p>
            )}
            <Footer
                disable={user && user.exportInProcess}
                loadingButton={loading}
                onCancel={() => onCancel()}
                onConfirm={() => onConfirm(jobId)}
                confirmText="SUBMIT"
                hasSeparator
            />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        allJobs: (state.Job.allJobs && state.Job.allJobs.filter(each => each.status)) || [],
    }
}

export default connect(
    mapStateToProps,
    {
        GetJobDetails,
    }
)(DownloadReport)
