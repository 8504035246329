import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { store } from '../../redux/store'

import Openings from '../Modules/Openings'
import TalentPool from '../Modules/TalentPool'
import Analytics from '../Modules/Analytics'
import Sourcing from '../Pages/Sourcing'
import SourcingProfile from '../Pages/SourcingProfile'
import Dashboard from '../Modules/Dashboard'
import Todo from '../Modules/Todo'
import Candidate from '../Pages/CandidateProfile'
import AccountSettings from '../Pages/AccountSettingsNew'
import Career from '../Pages/Career'
import Reports from '../Pages/Reports'
import { SOURCING_STATUS } from '../Helpers/constants'
import Inbox from '../Pages/Inbox'

const SELECTIVE_ROUTES = {
    SOURCING: {
        path: 'sourcing',
        render: props => <Sourcing {...props} />,
        exact: true,
        accessLevel: [1, 2],
    },
    SOURCING_CP: {
        path: 'candidate/sourcing/:id',
        render: props => <SourcingProfile {...props} />,
        exact: true,
        accessLevel: [1, 2],
    },
}

/*
 * @description exact
 * for routes that has nested routes, exact: false in this file
 * and will have exact:true in their respective module folder
 *
 * */

const routes = [
    {
        path: 'openings',
        render: props => <Openings {...props} />,
        exact: false, // rule mentioned in desc
        accessLevel: [1, 2, 3, 4],
    },
    {
        path: 'dashboard',
        render: props => <Dashboard {...props} />,
        exact: true,
        accessLevel: [1, 2, 3, 4, 5],
    },
    {
        path: 'todo',
        render: props => <Todo {...props} />,
        exact: true,
        accessLevel: [1, 2, 3, 4, 5],
    },
    {
        path: 'talent-pool',
        render: props => <TalentPool {...props} />,
        exact: true,
        accessLevel: [1, 2, 3, 4, 5],
    },
    {
        path: 'candidate/:id',
        render: props => <Candidate {...props} />,
        exact: true,
        accessLevel: [1, 2, 3, 4, 5],
    },
    {
        path: 'account',
        render: props => <AccountSettings {...props} />,
        exact: true,
        accessLevel: [1, 2, 3, 4, 5],
    },
    {
        path: 'careers/customise',
        render: props => <Career {...props} />,
        exact: true,
        accessLevel: [1, 2],
    },
    {
        path: 'reports',
        render: props => <Analytics {...props} />,
        exact: false,
        accessLevel: [1, 2],
    },
    {
        path: 'inbox',
        render: props => <Inbox {...props} />,
        exact: true,
        accessLevel: [1, 2, 3, 4, 5],
    },
]

class AppRouter extends Component {
    render() {
        const { accessLevel, sourcing } = store.getState().Auth.user
        const { url, style, allowSwitchTabs } = this.props

        if (sourcing && sourcing.status === SOURCING_STATUS.ENABLED)
            routes.push(SELECTIVE_ROUTES.SOURCING, SELECTIVE_ROUTES.SOURCING_CP)

        return (
            <div style={style}>
                <Switch>
                    {routes
                        .filter(singleRoute => singleRoute.accessLevel.includes(accessLevel))
                        .map(singleRoute => {
                            const { path, key = path, exact, render, ...otherProps } = singleRoute
                            return (
                                <Route
                                    exact={exact}
                                    key={key}
                                    path={`${url}${path}`}
                                    render={render ? props => render({ ...props, allowSwitchTabs }) : undefined}
                                    {...otherProps}
                                />
                            )
                        })}
                    <Redirect path="*" to="/error" />
                </Switch>
            </div>
        )
    }
}

export default AppRouter
