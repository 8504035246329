import { Typography } from 'antd'
import { good, must_hire, not_fit, not_good, not_sure } from '../../assets/Icons/Feedback/InActiveIconsBundle'
import {
    good_active,
    must_hire_active,
    not_fit_active,
    not_good_active,
    not_sure_active,
} from '../../assets/Icons/Feedback/ActiveIconsBundle'

export const { Paragraph } = Typography

/* ===================
 Feedback Constants
 ================== */

export const F_OVERALL_COLLECTION = [
    {
        name: 'not_fit',
        text: 'Not Fit',
        value: 1,
        inActiveIcon: not_fit,
        activeIcon: not_fit_active,
    },
    {
        name: 'not_good',
        text: 'Not Good',
        value: 2,
        inActiveIcon: not_good,
        activeIcon: not_good_active,
    },
    {
        name: 'not_sure',
        text: 'Not Sure',
        value: 3,
        inActiveIcon: not_sure,
        activeIcon: not_sure_active,
    },
    {
        name: 'good',
        text: 'Good',
        value: 4,
        inActiveIcon: good,
        activeIcon: good_active,
    },
    {
        name: 'must_hire',
        text: 'Must Hire',
        value: 5,
        inActiveIcon: must_hire,
        activeIcon: must_hire_active,
    },
]

export const defaultFeedback = {
    communication: 0,
    attitude: 0,
    learningPotential: 0,
    technicalSkills: 0,
    overallPoints: 0,
    overallFeedback: '',
    opinion: 3,
    noShow: false,
}

/* ===================
 Candidate Status
 =================== */

export const CANDIDATE_STATUS = {
    archived: {
        key: 'archived',
        name: 'Archived',
        value: 0,
    },
    active: {
        key: 'active',
        name: 'Active',
        value: 1,
    },
    rejected: {
        key: 'rejected',
        name: 'Rejected',
        value: 2,
    },
    joined: {
        key: 'joined',
        name: 'Joined',
        value: 3,
    },
}

export const CANDIDATE_STATUS_ARR = ['archived', 'active', 'rejected', 'joined']

// --- NOTICE PERIOD ---
export const NOTICE_PERIOD = {
    CURRENT: 'Currently in notice period',
    IMMEDIATE: 'Immediately Available',
    D_10: '10 Days',
    D_30: '11-30 Days',
    M_1: '1 Month',
    M_2: '2 Months',
    M_3: '3 Months',
    ABOVE: 'More than 3 Months',
}

export const NOTICE_PERIOD_ARR = [
    'Currently in notice period',
    'Immediately Available',
    '10 Days',
    '11-30 Days',
    '1 Month',
    '2 Months',
    '3 Months',
    'More than 3 Months',
]

export const EXPERIENCE_OPTIONS = ['Fresher', '1 year', '2 years', '3 years', '4 years', '5 years', '5+ years']

export const ARCHIVE_REASONS = [
    { value: 'Best suited for another role', key: 1 },
    { value: 'Withdrew application', key: 2 },
    { value: 'Found suitable candidate', key: 3 },
    { value: 'Overqualified for this role', key: 4 },
]

/* ===================
 Dashboard
 =================== */
export const DASHBOARD_COUNTER_DURATION = 2

/* ==========================
 Google Calander
 ========================== */

export const CAL_RESPONSE_CODES = {
    success: 'SUCCESS',
    fail: 'FAILURE',
}

export const GCAL_USER_STATUS = {
    ACTIVE: 'active',
    DEFAULT: 'default',
}

/* ==========================
 User Access Roles
 ========================== */

export const ACCESS_LEVEL_DEFINITIONS = {
    SUPER_ADMIN: { accessLevel: 1, name: 'Super Admin' },
    ADMIN: { accessLevel: 2, name: 'Admin' },
    JOB_LEVEL_ACCESS: { accessLevel: 3, name: 'Job Level Access' },
    RECRUITER: { accessLevel: 4, name: 'Recruiter' },
    INTERVIEW_ONLY: { accessLevel: 5, name: 'Interview Only' },
}

export const ACCESS_LEVEL_ARR = ['Super Admin', 'Admin', 'Job Level Access', 'Recruiter', 'Interview Only']

export const SOURCING_STATUS = {
    ENABLED: 'enabled',
    DISABLED: 'disabled',
}

export const OPENING_STATUS = { ARCHIVED: 0, ACTIVE: 1, UNPUBLISHED: 2 }

/* ==========================
 To-do list
 ========================== */

export const REFINE_DAYS = {
    NONE: { key: 'NONE', text: 'None' },
    TODAY: {
        key: 'TODAY',
        text: 'Today',
    },
    TOMORROW: {
        key: 'TOMORROW',
        text: 'Tomorrow',
    },
    WEEK: {
        key: 'WEEK',
        text: 'This week',
    },
}

/* ==========================
 File Upload Status
 ========================== */

export const FILE_STATUS = {
    UPLOADING: 'UPLOADING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
}

export const MONTHS_ARRAY = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const BROWSER_TYPE = navigator.userAgent.indexOf('Firefox') ? 'Firefox' : 'Other'
