import mixedActions from '../../redux/mixed/actions'
import { store } from '../../redux/store'
import config from '../../config'
const { v1: uuid } = require('uuid')
const { baseURL, apiVersion } = config.config()

export const shouldCalPopBeOpened = gCal => {
    const resultGenerator = step => ({ status: true, step })

    if (gCal === null || gCal === undefined) gCal = {}

    const { inUse: isGcalInUse = null } = gCal

    let hasPopupOpened
    let popupOpened = localStorage.getItem('popupOpened')
    if (popupOpened) {
        popupOpened = JSON.parse(popupOpened)
        if (popupOpened.includes(store.getState().Auth.user.id)) hasPopupOpened = true
        else {
            popupOpened.push(store.getState().Auth.user.id)
            localStorage.setItem('popupOpened', JSON.stringify(popupOpened))
            hasPopupOpened = false
        }
    } else {
        hasPopupOpened = false
        localStorage.setItem('popupOpened', JSON.stringify([store.getState().Auth.user.id]))
    }

    if (!hasPopupOpened && isGcalInUse === null) {
        //For signin of existing users in new browser sessions or different browsers or computers.
        return resultGenerator(1)
    } else return false
}

export const initiateGoogleAuth = (redirectTo, emailId = null) => {
    const key = uuid().toString()
    const redirectPath = redirectTo ? redirectTo : 'openings'
    const { id } = store.getState().Auth.user
    const dispatchData = {
        type: mixedActions.MODIFY_CACHE_DATA,
        data: {
            payload: {
                inProgress: true,
                encryptionKey: key,
            },
            key: 'googleAuth',
            event: 'INSERT',
        },
    }
    store.dispatch(dispatchData)
    window.open(
        `${baseURL}${apiVersion.v2}google-calendar/authenticate/${id}?key=${key}&redirectPath=${redirectPath}${
            emailId ? `&emailId=${emailId}` : ''
        }`,
        '_self'
    )
    return false // returning for the sake of sanity, that if the function when used is checking for error also.
}

export const FORCE_OPEN = {
    AUTHENTICATE: { status: true, step: 1 },
    AVAILABILITY: { status: true, step: 3 },
}

export const AUTH_ENTRY_POINTS = {
    SIGN_IN: 'sign-in',
    SIGN_UP: 'sign-up',
}
