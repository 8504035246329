import React, { useEffect } from 'react'
import Router from './router'

export default ({ match, allowSwitchTabs, ...rest }) => {
    useEffect(() => {
        document.title = 'Reports | SpringRecruit'
    })
    const { url } = match
    return <Router url={url} allowSwitchTabs={allowSwitchTabs} {...rest} />
}
