import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Dropdown, Icon, Button } from 'antd'
import { Body, Header, Content, Add, Buttons, Btn, Empty, List, Item, Icons } from './style'
import { ReactComponent as AddIcon } from '../../../../../assets/Icons/Interview/add.svg'
import { ReactComponent as EmptyIcon } from '../../../../../assets/Icons/Interview/empty.svg'
import { ReactComponent as DownIcon } from '../../../../../assets/Icons/Interview/down.svg'
import { ReactComponent as EditIcon } from '../../../../../assets/Icons/Interview/edit.svg'
import { ReactComponent as BinIcon } from '../../../../../assets/Icons/Interview/bin.svg'
import TextArea from 'antd/lib/input/TextArea'
import { SetAddress, GetAddress, EditAddress, DeleteAddress } from '../../../../../services/api'
import { warning } from '../../../../../services/Notification'
import '../../style.scss'

const Menu = props => {
    const [addAddress, setAddAddress] = useState(false)
    const [editAddress, setEditAddress] = useState(false)
    const [address, setAddress] = useState(null)
    const [inputAddress, setInputAddress] = useState('')
    const { addressArray } = props
    const [curItem, setCurItem] = useState(null)

    useEffect(() => {
        let idArray = []
        if (addressArray.length === 1) {
            props.saveAddress(addressArray[0])
        } else if (props.savedAddress) {
            idArray = addressArray.map(each => {
                if (each._id === props.savedAddress._id) {
                    props.saveAddress(each)
                }
                return each._id
            })
            if (!idArray.includes(props.savedAddress._id)) {
                props.saveAddress(null)
            }
        }
    }, [addressArray])

    const handleChange = e => {
        setInputAddress(e.target.value)
    }

    const handleSave = () => {
        if (inputAddress.trim().length > 0) {
            SetAddress({ address: inputAddress.trim() })
            setAddAddress(false)
        } else {
            warning('Address field cannot be empty.', null, { bottom: 30 })
        }
    }

    const handleEditSave = item => {
        if (inputAddress.trim().length > 0) {
            EditAddress({ addressId: item._id, address: inputAddress.trim() })
            setEditAddress(false)
            setAddress(null)
        } else {
            warning('Address field cannot be empty.', null, { bottom: 30 })
        }
    }

    const handleDelete = item => {
        DeleteAddress({ addressId: item._id })
    }

    const handleSelect = item => {
        props.changeAddress(item)
    }

    return (
        <Content>
            <Header>
                <span>Saved Address</span>
                {!addAddress && !editAddress && (
                    <Add onClick={() => setAddAddress(true)}>
                        <AddIcon /> Add New
                    </Add>
                )}
            </Header>
            {addressArray.length === 0 && !addAddress && (
                <Empty>
                    <EmptyIcon />
                    <span>
                        To add a new address
                        <br /> click on Add New
                    </span>
                </Empty>
            )}
            {addAddress && (
                <Body>
                    <TextArea placeholder="Enter Address" onChange={e => handleChange(e)} />
                    <Buttons>
                        <Btn
                            onClick={() => {
                                setInputAddress('')
                                setAddAddress(false)
                            }}
                        >
                            Cancel
                        </Btn>
                        <Btn primary onClick={() => handleSave()}>
                            Save
                        </Btn>
                    </Buttons>
                </Body>
            )}
            <List>
                {addressArray.map((item, index) =>
                    editAddress && address.key === item.key ? (
                        <Body key={item._id}>
                            <TextArea defaultValue={item.address} onChange={e => handleChange(e)} />
                            <Buttons>
                                <Btn
                                    onClick={() => {
                                        setEditAddress(false)
                                        setAddress(null)
                                    }}
                                >
                                    Cancel
                                </Btn>
                                <Btn primary onClick={() => handleEditSave(item)}>
                                    Save
                                </Btn>
                            </Buttons>
                        </Body>
                    ) : (
                        <Item
                            key={item._id}
                            onClick={() => handleSelect(item)}
                            onMouseEnter={() => setCurItem(item._id)}
                            onMouseLeave={() => setCurItem(null)}
                        >
                            <div className="title">
                                <p>{item.key}</p>
                                <Icons>
                                    {!addAddress && curItem === item._id && (
                                        <>
                                            <EditIcon
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    setAddress(item)
                                                    setEditAddress(true)
                                                    setInputAddress(item.address)
                                                }}
                                            />
                                            <BinIcon
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    handleDelete(item)
                                                }}
                                            />
                                        </>
                                    )}
                                </Icons>
                            </div>
                            <p>{item.address}</p>
                        </Item>
                    )
                )}
            </List>
        </Content>
    )
}

class Address extends Component {
    constructor(props) {
        super(props)
        this.state = {
            address: null,
            visible: false,
        }
    }

    componentDidMount() {
        GetAddress().then(res => {
            if (this.props.addressArray.length === 1) {
                if (this.props.addressArray[0].address === this.props.addedAddress) {
                    this.saveAddress(this.props.addressArray[0], true)
                } else {
                    this.saveAddress(this.props.addressArray[0])
                }
            }
            if (this.props.addedAddress) {
                this.saveAddress(this.props.addedAddress, true)
                this.setState({ addedAddress: this.props.addedAddress })
            }
        })
    }

    saveAddress = (item, autoFill) => {
        this.props.getSelectedAddress(item)
        if (autoFill) {
            this.props.updateParentState({ selectedAddress: item && item.address }, false, true)
        } else {
            this.props.updateParentState({ selectedAddress: item && item.address })
        }
        this.setState({ address: item })
    }

    handleVisibleChange = flag => {
        this.setState({ visible: flag })
    }

    changeAddress = item => {
        this.setState({ addedAddress: null }, () => {
            setTimeout(() => this.setState({ visible: false }), 200)
        })
        this.saveAddress(item)
    }

    render() {
        let { address, addedAddress, visible } = this.state
        address = address ? address.address : 'Enter Address'
        return (
            <Dropdown
                overlay={
                    <Menu
                        saveAddress={this.saveAddress}
                        addressArray={this.props.addressArray}
                        savedAddress={this.state.address}
                        changeAddress={this.changeAddress}
                    />
                }
                placement="bottomRight"
                trigger={['click']}
                onVisibleChange={this.handleVisibleChange}
                visible={visible}
                getPopupContainer={trigger => trigger.parentNode}
            >
                <Button size="large" className="dropdown-button">
                    <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {addedAddress ? addedAddress : address}
                    </div>{' '}
                    <DownIcon />
                </Button>
            </Dropdown>
        )
    }
}

const mapStateToProps = state => {
    const { addressArray } = state.Auth
    return { addressArray: addressArray || [] }
}

export default connect(mapStateToProps)(Address)
