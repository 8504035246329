import React, { Component } from 'react'
import { Button } from 'antd'
import moment from 'moment'
import {
    CAContainer,
    NoJob,
    NoJobTitle,
    NoCandidate,
    NoCandidateTitle,
    NCSub,
    NoCandidateClickHere,
    NoCandidateSubTitle,
    ACListContainer,
    ACListCell,
    PositionDetail,
    Candidate,
    StaticString,
    Position,
    AppliedTime,
    AppliedDate,
} from '../StyleComponent'
import styles from '../style'

export default class CandidateApplication extends Component {
    appliedList = (index, listData) => {
        const { history } = this.props
        const candidateCount = listData.candidates.length
        const candidate = candidateCount === 1 ? listData.candidates[0].name : candidateCount + ' candidates'
        return (
            <ACListCell key={index} className="list-cell">
                <PositionDetail className="positionDetail">
                    <Candidate
                        onClick={() => {
                            candidateCount === 1
                                ? history.push(`candidate/${listData.candidates[0]._id}`)
                                : history.push(`openings/list/${listData.jobId}`)
                        }}
                    >
                        {candidate}
                    </Candidate>
                    <StaticString> {candidateCount === 1 ? 'has' : 'have'} applied for the position </StaticString>
                    <Position onClick={() => history.push(`openings/board/${listData.jobId}`)}>
                        {listData.jobTitle}, {listData.jobLocation}
                    </Position>
                </PositionDetail>
                <div>
                    <AppliedTime>{moment(listData.createdAt).format('h:mm A')}</AppliedTime>
                    <AppliedDate>{moment(listData.createdAt).format('Do MMM, Y')}</AppliedDate>
                </div>
            </ACListCell>
        )
    }

    openAddCandidateModal = () => {
        this.props.openAddCandidateModal()
    }

    render() {
        const { candidates, jobs, history } = this.props
        return (
            <CAContainer>
                {candidates.length > 0 ? (
                    <ACListContainer>
                        {candidates.map((listData, index) => {
                            return this.appliedList(index, listData)
                        })}
                    </ACListContainer>
                ) : !jobs ? (
                    <NoJob>
                        <NoJobTitle>
                            Currently there are no job openings to apply for. Create new job opening and publish it so
                            that candidates start applying for it.
                        </NoJobTitle>
                        <Button
                            type="primary"
                            style={styles.noJobButton}
                            onClick={() => history.push('openings/createJob')}
                        >
                            CREATE JOB OPENING
                        </Button>
                    </NoJob>
                ) : (
                    <NoCandidate>
                        <NoCandidateTitle>
                            No new candidates have applied. You can go to job openings section to promote/share the job!
                        </NoCandidateTitle>
                        <NCSub>
                            <NoCandidateClickHere onClick={this.openAddCandidateModal}>Click here</NoCandidateClickHere>
                            <NoCandidateSubTitle>
                                {' '}
                                to manually add/upload candidate resumes to any job opening.
                            </NoCandidateSubTitle>
                        </NCSub>
                        <Button
                            type="primary"
                            style={styles.noCandidateButton}
                            onClick={() => history.push('openings')}
                        >
                            GO TO JOB OPENING
                        </Button>
                    </NoCandidate>
                )}
            </CAContainer>
        )
    }
}
