import styled from 'styled-components'
import { DARK_BLUE, RED, LIGHT_GREY } from '../../Helpers/Stylesheet/styledComponents'
import LoadingIcon from '../../../assets/Icons/Feedback/buttonLoading.svg'

const StyledButton = styled.div`
    text-transform: ${props => (props.capitalize ? 'capitalize' : 'uppercase')};
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '4px')};
    border-width: thin;
    user-select: none;
    outline: none;
    cursor: pointer;
    color: white;
    ${({ width }) => (width ? `width:${width}` : null)};
    ${({ height }) => (height ? `height:${height}` : null)};
    ${({ size }) => (size ? `width:${size}; height:${size}` : null)};
    ${({ type, disable }) =>
        type === 'inverted'
            ? `background-color: white;color: ${disable ? LIGHT_GREY : DARK_BLUE}; border-color: ${disable ? LIGHT_GREY : DARK_BLUE
            };margin-right: 10px; border-style: solid;`
            : type === 'danger'
                ? `background-color: ${RED}`
                : `background-color: ${DARK_BLUE}`};
    :active {
        transform: translateY(1px);
    }
    ${({ disable }) => disable && 'opacity:0.5; pointer-events: none;'}
    svg {
        margin-right: 5px;
    }
    ${({ loading }) =>
        loading &&
        `:after {
      content: '';
      background: url(${LoadingIcon});
      background-size: contain;
      width: 15px;
      height: 15px;
      margin-left: 5px;
    }`}
`

export default StyledButton
