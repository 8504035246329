import { PURGE } from 'redux-persist'

const initState = { inUse: false }

export default function outlookReducer(state = initState, actions) {
    const { type, payload } = actions

    switch (type) {
        case PURGE:
            return { ...initState }
        case actions.SET_IN_USE:
            return { ...state, inUse: actions.payload.inUse }

        case 'SET_USER_OUTLOOK':
            return { ...state, inUse: true, ...payload }

        case 'REMOVE_USER_OUTLOOK':
            return { ...initState }
        default:
            return state
    }
}
