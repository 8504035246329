import styled from 'styled-components'
import { CustomScrollBar, FlexCenterCol, GREY, WHITE, WHITE_SMOKE } from '../../Helpers/Stylesheet/styledComponents'
import { Aside as Aside1 } from '../Sourcing/style'
import { FormWrapper } from '../../Modals/AddCandidate/style'
import { DARK_BLUE, LIGHT_GREY } from '../../Helpers/Stylesheet/styledComponents'

export const Container = styled.div`
    margin: auto;
    width: 100%;
    max-width: 1200px;
    padding: 30px 0;
    .header {
        margin-bottom: 15px;
    }
    @media (max-width: 767px) {
        padding: 0 0 15px 0;
        .header {
            padding: 15px;
            background: #fff;
            .tabs {
                padding: 15px 0;
                margin-bottom: 0;
            }
            span {
                font-size: 12px;
                svg use {
                    fill: #666;
                }
            }
        }
    }
`
export const Body = styled.div`
    display: flex;
`
export const Aside = styled(Aside1)`
    width: 250px;
    background: #ffffff;
    border: 1px solid #ccd7de;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    max-height: initial;
    a {
        display: block;
        margin-left: 26px;
        font-size: 12px;
        color: #999;
        padding-left: 10px;
        line-height: 10px;
        &.active {
            color: #4d679c;
            border-left: 3px solid #4d679c;
            padding-left: 7px;
        }
        + a {
            margin-top: 15px;
        }
    }
    .ant-collapse .ant-collapse-item {
        padding-bottom: 0;
        border-bottom: none !important;
    }
    .ant-collapse-header {
        display: flex;
        align-items: center;
        svg {
            margin-right: 10px;
        }
    }
    @media (max-width: 767px) {
        display: none;
    }
`
export const Main = styled.div`
    width: calc(100% - 280px);
    margin-left: 30px;

    .sub-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        > div {
            display: flex;
            align-items: center;
        }
        div + svg {
            margin-left: 5px;
        }
        svg {
            cursor: pointer;
            + span {
                margin-left: 5px;
            }
        }
        span {
            font-size: 12px;
        }
    }
    .data {
        .buttons {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 8px;
            a {
                color: #4d679c;
                font-weight: 600;
                margin-left: 20px;
                + a {
                    margin-left: 20px;
                }
            }
        }
    }
    .cards {
        display: flex;
        flex-wrap: wrap;
        margin: -10px 0 20px -10px;
    }
    @media (max-width: 767px) {
        width: 100%;
        padding: 0 15px;
        margin: 0;
        .sub-header {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
            > div {
                margin-bottom: 10px;
            }
        }
        .cards {
            margin: 0 -5px 20px;
        }
    }
`
export const Card = styled.div`
    width: 212px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${WHITE};
    border: 1px solid #ccd7de;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: Roboto;
    color: ${GREY};
    position: relative;
    margin: 10px;
    > svg {
        position: absolute;
        right: 10px;
        top: 10px;
        &:hover path {
            fill: #666;
            cursor: pointer;
            opacity: 1;
        }
    }
    .body {
        ${FlexCenterCol};
        flex: 1;
    }
    .total {
        display: flex;
        flex-direction: row;
        .number {
            font-weight: bold;
            font-size: 26px;
        }
        .day {
            font-family: sanFrancisco;
            margin-left: 10px;
            margin-top: 10px;
        }
    }
    .title {
        font-size: 14px;
    }
    .desc {
        font-weight: 500;
        font-size: 12px;
        background: ${WHITE_SMOKE};
        width: 100%;
        text-align: center;
        padding: 8px 0;
        border-radius: 0 0 5px 5px;
    }
    @media (max-width: 767px) {
        width: calc(50% - 10px);
        margin: 5px;
        height: 140px;
        .desc {
            font-size: 10px;
        }
    }
`
export const Heading = styled.div`
    font-size: 20px;
    font-family: sanFranciscoMedium;
    color: #333;
    margin-bottom: ${props => (props.flex ? '20px' : '10px')};
    display: ${props => props.flex && 'flex'};
    justify-content: ${props => props.flex && 'space-between'};
    align-items: ${props => props.flex && 'center'};

    @media (max-width: 767px) {
        ${props =>
            props.white
                ? `
                padding-bottom: 15px;
                text-transform: uppercase;
                line-height: initial;
                font-size: 18px;
                margin-bottom: 0;
                border-bottom: 1px solid #ccd7de;
                `
                : ''};
    }
`
export const GraphCard = styled.div`
    width: 100%;
    padding: 20px;
    font-family: sanFranciscoMedium;
    background: #f7f7f7;
    border: 1px solid #ccd7de;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px 5px 0px 0px;
`
export const Graph = styled.div`
    width: 100%;
    min-height: 480px;
    background: #ffffff;
    border: 1px solid #ccd7de;
    border-top: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 5px 5px;
    canvas {
        padding: 0 10px;
    }
    padding-bottom: 20px;
`
export const Tabs = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 14px;
    font-weight: 500;
    color: ${LIGHT_GREY};
    display: flex;
    text-transform: uppercase;
    margin-bottom: 15px;
    white-space: nowrap;
    overflow: auto;
    a {
        color: ${LIGHT_GREY};
        margin-right: 20px;
    }
    div {
        cursor: pointer;
        padding: 5px 0;
        line-height: initial;
        + div {
            margin-left: 30px;
        }
    }
    .active {
        color: ${DARK_BLUE};
        border-bottom: 3px solid ${DARK_BLUE};
    }
`
export const SubTabs = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-size: 12px;
    a {
        color: ${GREY};
        :not(:last-child) {
            margin-bottom: 10px;
        }
    }
    a:active,
    a:hover {
        color: ${GREY} !important;
    }
`
export const TableWrapper = styled.div`
    position: relative;
    .icons {
        text-align: right;
        position: absolute;
        top: -25px;
        right: 0;
        span {
            margin-right: 10px;
        }
        svg {
            + svg {
                margin-left: 20px;
            }
            &:hover path:first-child {
                fill: #666;
                cursor: pointer;
            }
        }
    }
    .ant-table-thead {
        th {
            background-color: ${WHITE_SMOKE};
        }
    }
    .ant-table-tbody {
        .ant-table-row {
            td {
                background-color: ${WHITE};
            }
        }
    }
    .ant-table-fixed-header .ant-table-scroll .ant-table-header {
        overflow: hidden;
        margin-bottom: 0 !important;
    }
    .ant-table-bordered .ant-table-tbody > tr > td {
        border-right: none;
        max-width: 230px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: keep-all;
    }
    .ant-table-bordered .ant-table-thead > tr > th {
        border-right: none;
    }
    .ant-table-tbody > tr > td {
        border-bottom: 2px dashed #e8e8e8;
    }
    .ant-table-body {
        overflow-y: ${({ scroll }) => (scroll ? 'scroll' : 'hidden')} !important;
        ${CustomScrollBar};
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            border-radius: 42px;
        }
    }
    .overflow {
        width: 100%;
        max-height: 500px;
        overflow: auto;
        ${CustomScrollBar};
        .icons {
            text-align: right;
            position: absolute;
            top: -25px;
            right: 0;
            svg {
                cursor: pointer;
                + svg {
                    margin-left: 10px;
                }
            }
        }
        table {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #ccd7de;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
            thead {
                background: #f7f7f7;
                tr {
                    border-bottom: 1px solid #ccd7de;
                }
            }
            tbody tr {
                border-bottom: 1px dashed #ccd7de;
                background: #fff;
            }
            tr {
                height: 60px;
                th {
                    div {
                        display: flex;
                        align-items: center;
                        span {
                            text-align: center;
                        }
                        svg {
                            margin-left: 5px;
                            flex-shrink: 0;
                        }
                    }
                    text-transform: uppercase;
                }
                th,
                td {
                    padding: 0 30px;
                    &.right {
                        text-align: right;
                    }
                }
            }
        }
    }
`
export const DataCard = styled.div`
    background: #ffffff;
    border: 1px solid #ccd7de;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 20px;
    ul {
        list-style: none;
        padding-left: 0;
        :not(:last-child) {
            border-bottom: 1px dashed #ccd7de;
        }
        li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            line-height: initial;
            color: #333;
            font-size: 12px;
            &.title {
                color: #666;
                font-size: 14px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            :not(.title) {
                text-transform: uppercase;
            }
        }
    }
`
export const Filter = styled(FormWrapper)`
    grid-template-columns: repeat(auto-fit, 212px);
    @media (max-width: 767px) {
        margin: 10px 0 30px;
    }
`
export const SearchDropdown = styled.div`
    width: ${props => (props.isMobileDevice ? '100%' : '212px')};
    padding: ${({ search }) => (search ? '15px 5px 15px 15px' : '0px 5px 15px 15px')};
    .search-box {
        width: calc(100% - 10px);
    }
    .search-box .ant-input-lg {
        font-size: 12px;
        padding-right: 30px;
    }
    .search-list {
        max-height: 110px;
        overflow-y: scroll;
    }
    .search-list::-webkit-scrollbar {
        width: 5px;
    }
    .search-list::-webkit-scrollbar-thumb {
        background-color: #ebebeb;
        opacity: 0.2;
        border-radius: 2.5px;
    }
    .search-list-option {
        height: 24px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .search-checkbox {
        margin-top: 15px;
        display: block;
        word-break: break-all;
        + .ant-checkbox-wrapper {
            margin-left: 0px;
        }
    }
    .search-apply {
        color: ${DARK_BLUE};
        font-size: 12px;
        font-family: sanFranciscoMedium;
        margin-top: 12px;
        margin-bottom: 0px;
        cursor: pointer;
    }
    .mt20 {
        margin-top: 20px;
    }
`

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    .footer-date {
        display: flex;
        justify-content: space-between;
        width: calc(100% + 24px);
        margin-left: -12px;
        &-text {
            color: #999999;
        }
        &-input {
            width: 76px;
            margin-top: 5px;
            border: none;
            border-bottom: 1px dashed #707070;
            pointer-events: none;
        }
    }
    .p40 {
        padding: 0px 40px;
    }
    .p10 {
        padding: 0px 10px;
    }
    .bTop {
        border-top: 1px solid #e8e8e8;
    }
    .mb10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`
