import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import { Graph, GraphCard, Heading } from '../../style'
import { JobsOverviewLoader } from '../../Components/Loaders'
import { ReactComponent as RefreshIcon } from '../../../../../assets/Icons/Analytics/refresh.svg'
import StatCard from '../../Components/Card'
import { GetAnalyticsData } from '../../../../../services/NetworkCalls/Analytics'
import { warning } from '../../../../../services/Notification'
import apiCaller from '../../../../../services/apiCaller'
import { getPercentage } from '../../../../Helpers/utils'
import { Spin } from 'antd'
import { BLUE_COLA, JUNGLE_GREEN, MICROSOFT_RED, WHITE } from '../../../../Helpers/Stylesheet/styledComponents'

const { formatError } = apiCaller

const Overview = ({ companyId, GetAnalyticsData }) => {
    const [cardData, setCardData] = useState({})
    const [chartData, setChartData] = useState([])
    const [cardLoading, setCardLoading] = useState(false)
    const [chartLoading, setChartLoading] = useState(false)
    const [loadTime, setLoadTime] = useState(moment().format('DD MMM, YYYY LT'))

    useEffect(() => {
        getJobsOverviewData()
        getJobsChartData()
    }, [])

    const getJobsOverviewData = async () => {
        try {
            setCardLoading(true)
            const res = await GetAnalyticsData('GetJobsOverviewData', null, companyId)
            setCardData(res)
            setCardLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getJobsChartData = async () => {
        try {
            setChartLoading(true)
            const graphData = await GetAnalyticsData('GetJobsChartData', null, companyId)
            const dataPoints = {
                Active: {
                    color: JUNGLE_GREEN,
                    data: [],
                },
                Published: {
                    color: BLUE_COLA,
                    data: [],
                },
                Unpublished: {
                    color: MICROSOFT_RED,
                    data: [],
                },
            }
            const labels = []
            Object.keys(graphData)
                .reverse()
                .forEach((date, index) => {
                    if (index === 3 || index === 26)
                        labels.push(
                            `;Week ${[3, 11, 18, 26].indexOf(index) + 1} \n ${moment(date)
                                .subtract(3, 'days')
                                .format('DD/MM/YYYY')} - ${moment(date)
                                .add(3, 'days')
                                .format('DD/MM/YYYY')}`
                        )
                    else if (index === 11)
                        labels.push(
                            `;Week 2 \n ${moment(date)
                                .subtract(4, 'days')
                                .format('DD/MM/YYYY')} - ${moment(date)
                                .add(3, 'days')
                                .format('DD/MM/YYYY')}`
                        )
                    else if (index === 18)
                        labels.push(
                            `;Week 3 \n ${moment(date)
                                .subtract(3, 'days')
                                .format('DD/MM/YYYY')} - ${moment(date)
                                .add(4, 'days')
                                .format('DD/MM/YYYY')}`
                        )
                    else labels.push('')
                    dataPoints.Active.data.push(graphData[date].active)
                    dataPoints.Published.data.push(graphData[date].publishedJobs)
                    dataPoints.Unpublished.data.push(graphData[date].unPublishedJobs)
                })
            const datasets = []
            for (let type in dataPoints) {
                datasets.push({
                    label: type,
                    fill: false,
                    lineTension: 0.4,
                    backgroundColor: dataPoints[type].color,
                    borderColor: dataPoints[type].color,
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: WHITE,
                    pointBackgroundColor: dataPoints[type].color,
                    pointBorderWidth: 1,
                    pointHoverRadius: 7,
                    pointHoverBackgroundColor: dataPoints[type].color,
                    pointHoverBorderColor: dataPoints[type].color,
                    pointHoverBorderWidth: 2,
                    pointRadius: 5,
                    pointHitRadius: 10,
                    data: dataPoints[type].data,
                })
            }
            setChartData({
                labels,
                datasets,
            })
            setChartLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const handleRefresh = () => {
        getJobsOverviewData()
        getJobsChartData()
        setLoadTime(moment().format('DD MMM, YYYY LT'))
    }

    return (
        <>
            <div className="sub-header">
                <div>
                    <Heading>Overview</Heading>
                </div>
                <div>
                    <RefreshIcon onClick={() => handleRefresh()} />
                    <span>Last Updated: {loadTime}</span>
                </div>
            </div>
            {cardLoading ? (
                <JobsOverviewLoader />
            ) : (
                <div className="cards">
                    <StatCard total={cardData.totalJobs} title="Total Jobs" tooltipText="Total number of jobs" />
                    <StatCard
                        total={cardData.activejobs}
                        title="Active Jobs"
                        desc={`${getPercentage(cardData.activejobs, cardData.totalJobs)}% of Total Jobs`}
                        tooltipText="Total number of active jobs"
                    />
                    <StatCard
                        total={cardData.archivedJobs}
                        title="Archived Jobs"
                        desc={`${getPercentage(cardData.archivedJobs, cardData.totalJobs)}% of Total Jobs`}
                        tooltipText="Total number of archived jobs"
                    />
                    <StatCard
                        total={cardData.publishedJobs}
                        title="Published Jobs"
                        desc={`${getPercentage(cardData.publishedJobs, cardData.activejobs)}% of Active Jobs`}
                        tooltipText="Total number of published jobs"
                    />
                    <StatCard
                        total={cardData.unPublishedJobs}
                        title="Unpublished Jobs"
                        desc={`${getPercentage(cardData.unPublishedJobs, cardData.activejobs)}% of Active Jobs`}
                        tooltipText="Total number of unpublished jobs"
                    />
                    <StatCard
                        total={cardData.averageTime}
                        day={true}
                        title="Avg Job Lifetime"
                        tooltipText="Average time taken to archive a job"
                    />
                </div>
            )}
            <Heading mb>Last 30 days</Heading>
            <Spin spinning={chartLoading}>
                <GraphCard>Distribution of Active Jobs</GraphCard>
                <Graph>
                    <Line
                        data={chartData}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [
                                    {
                                        type: 'category',
                                        gridLines: {
                                            drawOnChartArea: false,
                                        },
                                        ticks: {
                                            callback: function(value) {
                                                const day = value.split(';')[0]
                                                return day
                                            },
                                        },
                                    },
                                    {
                                        type: 'category',
                                        gridLines: {
                                            drawOnChartArea: false,
                                        },
                                        ticks: {
                                            callback: function(value) {
                                                const week = value.split(';')[1] && value.split(';')[1].split('\n')
                                                return week
                                            },
                                            autoSkip: false,
                                            maxRotation: 0,
                                            minRotation: 0,
                                        },
                                    },
                                ],
                                yAxes: [
                                    {
                                        gridLines: {
                                            drawOnChartArea: false,
                                        },
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'No. of Jobs',
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                            legend: {
                                display: true,
                                position: 'top',
                                align: 'end',
                                labels: {
                                    boxWidth: 7,
                                    usePointStyle: true,
                                    padding: 20,
                                },
                            },
                        }}
                    />
                </Graph>
            </Spin>
        </>
    )
}

const mapStateToProps = ({ Auth }) => {
    const { user } = Auth
    return {
        companyId: user.companyId,
    }
}

export default connect(
    mapStateToProps,
    {
        GetAnalyticsData,
    }
)(Overview)
