import React from 'react'
import { MessageModal } from '../common'

const WarningOpeningArchived = ({ onConfirm }) => (
    <MessageModal
        title={'WARNING'}
        message={
            <span>
                This job has been archived. Click on <b>GO TO OPENINGS</b> to go back.
            </span>
        }
        onConfirm={onConfirm}
        confirmText={'GO TO OPENINGS'}
        width={'120px'}
    />
)

export default WarningOpeningArchived
