import React from 'react'
import { MessageWithIconModal } from '../common'
import { ReactComponent as InfoIcon } from '../../../assets/Icons/Board/Info.svg'

export const WarningDeleteStage = props => (
    <MessageWithIconModal
        title="WARNING"
        modalIcon={<InfoIcon />}
        message={
            props.candidatesPresent !== undefined
                ? 'You cannot delete this stage since there are candidates in this stage in other filters. Please move all the candidates to other stage before deleting this stage'
                : 'You cannot delete a stage if there are Candidates in it. Please move all the candidates to other stage before deleting this stage'
        }
        onConfirm={props.onConfirm}
        confirmText="OKAY"
    />
)
