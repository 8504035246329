import moment from 'moment'
import _ from 'lodash'
import { timeFormat } from './times'

/**
 * Function to get available time slots for all selected interviewers and duration.
 *
 * @param {interviewerTimeSlots} timeSlotsArray Object with time slots for each interviewer
 *                                        {"interviewerId":{..."date" : [...{start, end}] }}
 * @param {duration} duration duration of the interview selected by the schedular in minutes, if not given, assume 30 mins
 * @returns Object of dateTimeString as key and array of timeslots as values {... "date" : [timeslots]}
 */
export const getAvailableTimeSlots = (interviewerTimeSlots, duration = 30) => {
    const interviewers = Object.keys(interviewerTimeSlots)
    let timeSlots = {}
    interviewers.forEach((interviewer, index) => {
        let availableTimeSlots = interviewerTimeSlots[interviewer]
        if (index === 0) {
            Object.keys(availableTimeSlots).forEach(day => {
                let dayTimeSlots = []
                let aTimeSlot = availableTimeSlots[day]
                aTimeSlot.forEach(timeSlot => {
                    let _start = moment(timeSlot.start)
                    let _end = moment(timeSlot.end)
                    let totalDuration = moment(_end).diff(_start, 'minutes')
                    let numberOfSlots =
                        duration !== 60
                            ? Math.floor(totalDuration / duration)
                            : totalDuration % duration === 30
                            ? Math.floor(totalDuration / duration) * 2
                            : Math.floor(totalDuration / duration) * 2 - 1
                    let newStart = _start
                    for (let slot = 0; slot < numberOfSlots; slot++) {
                        let start = newStart.format(timeFormat)
                        newStart = moment(start, timeFormat).add(duration, 'minutes')
                        let end = newStart.format(timeFormat)
                        dayTimeSlots.push(`${start}-${end}`)
                        if (duration === 60) newStart = moment(start, timeFormat).add(30, 'minutes')
                    }
                })
                timeSlots = {
                    ...timeSlots,
                    [moment(day)
                        .startOf('day')
                        .format()]: dayTimeSlots,
                }
            })
        } else {
            Object.keys(timeSlots).forEach(day => {
                let removedTimeSlots = []
                let dailySlots =
                    availableTimeSlots[
                        moment(day)
                            .utc(true)
                            .toISOString()
                    ]
                timeSlots[day].forEach(timeSlot => {
                    let [startTime, endTime] = timeSlot.split('-')
                    if (!dailySlots) {
                        removedTimeSlots.push(timeSlot)
                    } else {
                        let available = dailySlots
                            .map(_aTimeSlot => {
                                let [startHr, startMinAm] = startTime.split(':')
                                let [startMin, startAmPm] = startMinAm.split(' ')
                                let [endHr, endMinAm] = endTime.split(':')
                                let [endMin, endAmPm] = endMinAm.split(' ')
                                startHr = parseInt(startHr)
                                startMin = parseInt(startMin)
                                endHr = parseInt(endHr)
                                endMin = parseInt(endMin)

                                let interviewerAvailableStart = moment(_aTimeSlot.start)
                                let interviewerAvailableEnd = moment(_aTimeSlot.end)
                                let timeSlotStart = moment(day)
                                    .hours(
                                        startHr === 12 && startAmPm === 'am'
                                            ? 0
                                            : (startHr === 12 && startAmPm === 'pm') || startAmPm === 'am'
                                            ? startHr
                                            : startHr + 12
                                    )
                                    .minutes(startMin)
                                let timeSlotEnd = moment(day)
                                    .hours(
                                        endHr === 12 && endAmPm === 'am'
                                            ? 0
                                            : (endHr === 12 && endAmPm === 'pm') || endAmPm === 'am'
                                            ? endHr
                                            : endHr + 12
                                    )
                                    .minutes(endMin)
                                return (
                                    timeSlotStart.isSameOrAfter(interviewerAvailableStart) &&
                                    timeSlotEnd.isSameOrBefore(interviewerAvailableEnd)
                                )
                            })
                            .reduce((ret, cur) => {
                                return ret || cur
                            })
                        if (!available) removedTimeSlots.push(timeSlot)
                    }
                })
                let daySlot = timeSlots[day].filter(timeSlot => !removedTimeSlots.includes(timeSlot))
                if (daySlot.length > 0) timeSlots[day] = daySlot
                else timeSlots = _.omit(timeSlots, day)
            })
        }
    })
    return timeSlots
}
