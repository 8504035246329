import { warning } from '../../Notification'
import apiCaller from '../../apiCaller'
import actions from '../../../redux/outlook/actions'
import { LogE } from '../../../container/Helpers/errorHandler'

const { restricted } = apiCaller.type
const { formatError } = apiCaller
const baseUrl = 'outlook/'

export const OutlookUserRegister = code => async dispatch => {
    try {
        const resp = await restricted.post(`${baseUrl}authorize`, { code })
        if (resp.data.error) {
            throw new Error('Failed to connect Outlook account')
        }
        dispatch({ type: actions.SET_USER_OUTLOOK, payload: resp.data })
        return resp.data
    } catch (error) {
        LogE('NetworkCalls-OutlookUserRegister-api-error', 'Failed to update', error)
        warning(formatError(error))
        return null
    }
}

export const OutlookUserDisconnect = () => async dispatch => {
    try {
        const { data } = await restricted.get(`${baseUrl}disconnect`)
        if (data.error) {
            throw new Error('Failed to connect Outlook account')
        }
        dispatch({ type: actions.REMOVE_USER_OUTLOOK, payload: data })
        return data
    } catch (error) {
        LogE('NetworkCalls-OutlookUserRegister-api-error', 'Failed to update', error)
        warning(formatError(error))
        return null
    }
}
