import React, { Component } from 'react'
import { Button, Typography } from 'antd'
import JobList from './JobList'
import styles from '../style'

const { Text } = Typography

export default class JobOpening extends Component {
    render() {
        const { history, jobs, accessLevel, jobRemover, activeOpenings } = this.props
        return (
            <div style={styles.jobOpeningContainer}>
                <div style={styles.joblistContainer}>
                    {jobs.length ? (
                        jobs.map(job => <JobList key={job._id} data={job} history={history} jobRemover={jobRemover} />)
                    ) : accessLevel <= 2 && activeOpenings === 0 ? (
                        <div style={styles.jobContainer}>
                            <Text style={styles.noJobText}>
                                No job openings available to display. Create one to get started!
                            </Text>
                            <Button
                                style={styles.createJobButton}
                                type="primary"
                                onClick={() => history.push('openings/createJob')}
                            >
                                CREATE JOB OPENING
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }
}
