import styled from 'styled-components'
import { BLACK, DIVISION_COLOR } from '../../Helpers/Stylesheet/styledComponents'
import { CustomScrollBar } from '../../Helpers/Stylesheet/styledComponents'

export const PageWrapper = styled.div``

export const ContainerBox = styled.div`
    width: 100%;
    padding: 30px;
    border-radius: 5px;
    margin-top: 30px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    .heading {
        display: flex;
        justify-content: space-between;
        color: ${BLACK};
        font-size: 16px;
        line-height: 19px;
        font-family: sanFranciscoMedium;
    }
    .division-line {
        background-color: ${DIVISION_COLOR};
        width: 100%;
        height: 1px;
        margin: 30px 0;
    }
    .sendInviteWrapper {
        padding-right: 15px;
        margin-right: -15px;
        max-height: 1128px;
        overflow: auto;
        ${CustomScrollBar};
    }
`
