import { all, takeEvery, put, fork } from 'redux-saga/effects'
import actions from './actions'
import { history } from '../store'

export function* loginRequest() {
    yield takeEvery('LOGIN_REQUEST', function*() {
        yield put({
            type: actions.LOGIN_SUCCESS,
            token: 'success',
        })
    })
}

export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
        yield localStorage.setItem('id_token', payload.token)
    })
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function*() {})
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function*({ payload = null }) {
        let popupOpened = localStorage.getItem('popupOpened')
        popupOpened = popupOpened ? JSON.parse(popupOpened) : null
        if (popupOpened && payload && popupOpened.includes(payload)) {
            localStorage.clear()
            yield localStorage.setItem('popupOpened', JSON.stringify(popupOpened))
        } else yield localStorage.clear()

        history.replace('/signin/')
    })
}
export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
        const token = localStorage.getItem('id_token')
        if (token) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                token,
            })
        }
    })
}
export default function* rootSaga() {
    yield all([fork(checkAuthorization), fork(loginRequest), fork(loginSuccess), fork(loginError), fork(logout)])
}
