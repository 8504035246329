import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import TalentPool from '../../Pages/TalentPool'
import OpeningRouter from '../Openings/router'
import { connect } from 'react-redux'

const routes = [
    {
        path: '',
        render: props => <TalentPool {...props} />,
    },
]

class TalentPoolRouter extends Component {
    render() {
        const { url, style, allowSwitchTabs, __redux } = this.props
        return (
            <div style={style}>
                {routes.map(singleRoute => {
                    const { path, exact, render, ...otherProps } = singleRoute
                    return (
                        <Route
                            exact={exact === false ? false : true}
                            key={singleRoute.path}
                            render={render ? props => render({ ...props, allowSwitchTabs, __redux }) : undefined}
                            path={`${url}/${singleRoute.path}`}
                            {...otherProps}
                        />
                    )
                })}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { __redux: state }
}

export default connect(mapStateToProps)(TalentPoolRouter)
