import React, { useState, useEffect } from 'react'
import { Item } from '../style'
import { ReactComponent as Formatscon } from '../../../../assets/Icons/Openings/formats.svg'
import { ReactComponent as LimitIcon } from '../../../../assets/Icons/Openings/limits.svg'
import { ReactComponent as NotesIcon } from '../../../../assets/Icons/Openings/notes.svg'
import config from '../../../../config'
import { IMPORTANT_NOTES } from '../ConstantData'

const { sampleSpreadsheetLink } = config.config()

const InfoBox = props => {
    return (
        <div className="info-wrapper">
            <Item>
                <Formatscon />
                <div>
                    <div className="title">Supported Format</div>
                    <div className="desc">
                        Supported format to upload candidate profile details through sheet is .xls, .xlsx or .csv with
                        flexibility to import up to 1000 records.
                    </div>
                    <a href={sampleSpreadsheetLink} target="_blank" download>
                        Download Sample file
                    </a>
                </div>
            </Item>
            <Item>
                <LimitIcon />
                <div>
                    <div className="title">Maximum Limit</div>
                    <div className="desc">Please ensure your file size does not exceed 60 MB.</div>
                </div>
            </Item>
            <Item>
                <NotesIcon />
                <div>
                    <div className="title">Important Notes</div>
                    <ul className="desc">
                        {IMPORTANT_NOTES.DESC.map((each, index) => (
                            <li key={index}>{each}</li>
                        ))}
                        <ul>
                            {IMPORTANT_NOTES.SUBSTEPS.map((each, index) => (
                                <li key={index}>{each}</li>
                            ))}
                        </ul>
                    </ul>
                </div>
            </Item>
        </div>
    )
}

export default InfoBox
