import React from 'react'
import {
    SignupWrapper,
    ContentWrapper,
    FormWrapper,
    LogoWrapper,
    RecruitWrapper,
    SpringWrapper,
    ButtonWrapper,
    SignupContainer,
} from './style'
import './style.css'

import { Input, Icon, Form } from 'antd'
import EmailIcon from '../../../assets/Icons/Auth/Email'
import BackIcon from '../../../assets/Icons/Auth/BackButton'
import { warning } from '../../../services/Notification'
import { LogE } from '../../Helpers/errorHandler'
import { CANDIDATE } from '../../Helpers/RegEx'
import { StyledButton } from '../../Components'
import {
    SendOtpForgotPassword,
    VerifyOtpForgotPassword,
    ResetPassword,
} from '../../../services/NetworkCalls/Authentication'
import { connect } from 'react-redux'

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            passwordType: true,
            forgotPasswordIndex: 0,
            email: null,
            otp: null,
            isLoading: false,
        }

        document.title = 'Forgot Password | SpringRecruit'
    }

    forgotPassword = e => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                try {
                    this.setState({ isLoading: true }, async () => {
                        const { forgotPasswordIndex } = this.state
                        let res
                        if (forgotPasswordIndex === 0) {
                            res = await this.props.SendOtpForgotPassword(values.email)
                            if (res) this.setState({ forgotPasswordIndex: 1, email: values.email })
                        } else if (forgotPasswordIndex === 1) {
                            const { OTP } = CANDIDATE
                            if (OTP.test(values.otp)) {
                                res = await this.props.VerifyOtpForgotPassword(values.otp, this.state.email)
                                if (res) this.setState({ forgotPasswordIndex: 2, otp: values.otp })
                            } else {
                                warning('Invalid OTP or OTP expired.')
                            }
                        } else {
                            this.props.ResetPassword(this.state.otp, this.state.email, values.password)
                        }
                        this.setState({ isLoading: false })
                    })
                } catch (err) {
                    LogE('pages-fp-forgotPassword', '', err)
                    warning(err.message)
                }
            }
        })
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form
        if (value && value !== form.getFieldValue('password')) {
            callback(`The entered passwords don't match`)
        } else {
            callback()
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true })
        }
        callback()
    }

    indexCalculator = () => {
        const { forgotPasswordIndex } = this.state
        if (forgotPasswordIndex === 0) {
            this.props.history.push('/signin')
        } else {
            this.setState({ forgotPasswordIndex: 0 }, () => {
                this.props.form.setFieldsValue({ email: this.state.email })
            })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form

        const getDisplayInfo = () => {
            switch (this.state.forgotPasswordIndex) {
                case 2:
                    return {
                        title: 'Reset Password',
                        subTitle: 'Reset your password and use the same to login to your account',
                    }
                case 1:
                    return {
                        title: 'Enter OTP',
                        subTitle: 'Enter the One time password sent to your email address',
                    }
                case 0:
                default:
                    return {
                        title: 'Forgot Password',
                        subTitle:
                            'Enter the email address you used to sign up, and we will send you instructions to login into your account.',
                    }
            }
        }

        return (
            <SignupContainer>
                <SignupWrapper>
                    <LogoWrapper/>
                    <ContentWrapper>
                        <Icon component={BackIcon} className="fp-back-icon" onClick={() => this.indexCalculator()} />
                        <div className="header-text fp-title">{getDisplayInfo().title}</div>
                        <div className="fp-subtitle">{getDisplayInfo().subTitle}</div>
                        <FormWrapper>
                            <Form className="fp-form" onSubmit={this.forgotPassword}>
                                {this.state.forgotPasswordIndex === 0 ? (
                                    <Form.Item>
                                        {getFieldDecorator('email', {
                                            validateTrigger: 'onBlur',
                                            rules: [
                                                { required: true, whitespace: true, message: 'Email is required' },
                                                { type: 'email', message: 'Invalid Email!' },
                                            ],
                                        })(
                                            <Input
                                                className="background-colour fp-input"
                                                placeholder="Your Email"
                                                suffix={<Icon component={EmailIcon} />}
                                            />
                                        )}
                                    </Form.Item>
                                ) : null}

                                {this.state.forgotPasswordIndex === 1 ? (
                                    <Form.Item>
                                        {getFieldDecorator('otp', {
                                            rules: [{ required: true, whitespace: true, message: 'OTP is required' }],
                                        })(<Input className="background-colour fp-input" placeholder="Enter OTP" />)}
                                    </Form.Item>
                                ) : null}

                                {this.state.forgotPasswordIndex === 2 ? (
                                    <Form.Item>
                                        {getFieldDecorator('password', {
                                            rules: [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: 'Password is required',
                                                },
                                                {
                                                    validator: this.validateToNextPassword,
                                                },
                                            ],
                                        })(
                                            <Input
                                                type="password"
                                                className="background-colour fp-input1"
                                                placeholder="Enter Password"
                                            />
                                        )}
                                    </Form.Item>
                                ) : null}

                                {this.state.forgotPasswordIndex === 2 ? (
                                    <Form.Item>
                                        {getFieldDecorator('confirmPassword', {
                                            rules: [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: 'Password is required',
                                                },
                                                {
                                                    validator: this.compareToFirstPassword,
                                                },
                                            ],
                                        })(
                                            <Input
                                                type="password"
                                                className="background-colour fp-input1"
                                                placeholder="Confirm Password"
                                            />
                                        )}
                                    </Form.Item>
                                ) : null}
                            </Form>
                        </FormWrapper>
                        <ButtonWrapper>
                            <StyledButton
                                className="fp-button"
                                width="304px"
                                height="48px"
                                onClick={this.forgotPassword}
                                disable={this.state.isLoading}
                                loading={this.state.isLoading}
                            >
                                Submit
                            </StyledButton>
                        </ButtonWrapper>
                    </ContentWrapper>
                </SignupWrapper>
            </SignupContainer>
        )
    }
}

export default connect(
    null,
    { SendOtpForgotPassword, VerifyOtpForgotPassword, ResetPassword }
)(Form.create({ name: 'ForgotPasswordForm' })(ForgotPassword))
