import styled from 'styled-components'
import { CustomScrollBar, GREY, RED } from '../../Helpers/Stylesheet/styledComponents'
import { ContentWrapper } from '../style'

export const ModalWrapper = styled(ContentWrapper)`
    max-height: 70vh;
    overflow: auto;
    ${CustomScrollBar};
    padding-right: 20px;
    margin-right: -20px;
`

export const Block = styled.div`
    padding: ${props => (!props.small ? '30px 0' : '20px 0 0 0')};
    border-bottom: ${props => !props.small && 'solid 1px #e1e4e8'};
    .opinion {
        display: flex;
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
            color: #999999;
            margin-right: 30px;
            i {
                height: 60px;
                width: 60px;
                margin: auto;
            }
            svg {
                margin-bottom: 10px;
            }
            cursor: pointer;
        }
    }
    .form {
        background: #f7f8fc;
        border-radius: 4px;
        padding: 25px;
        grid-gap: 20px;
        border-bottom: solid 1px #e1e4e8;
        position: relative;
        :last-child {
            border-bottom: none;
        }
        .close {
            text-align: right;
            margin: -10px 0;
            svg {
                width: 14px;
                height: 14px;
            }
        }
        .heading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
            padding-bottom: 20px;
            border-bottom: solid 1px #e1e4e8;
            svg + svg {
                margin-left: 10px;
            }
            .error-found {
                font-size: 12px;
                color: ${RED};
                font-family: sanFrancisco;
                font-weight: initial;
            }
        }
        .star {
            position: relative;
            margin-left: 15px;
            :before {
                content: '*';
                font-size: 30px;
                line-height: 24px;
                position: absolute;
                left: -15px;
                top: 0;
            }
        }
        .add-more {
            height: 40px;
            background: #fff;
            border: 1px dashed #e1e4e8;
            border-radius: 5px;
            width: 100%;
            cursor: pointer;
        }
        .divider {
            border: solid 1px #e1e4e8;
            margin: 0 -24px;
            &:last-child {
                border: none;
            }
        }
    }
    .rating {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 30px;

        .rating-rows {
            display: flex;
            width: 99%;
            justify-content: space-between;
            :hover {
                svg {
                    display: inline;
                }
            }
        }

        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            font-family: sanFranciscoMedium;
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            > span,
            div {
                flex: 1;
                svg {
                    vertical-align: middle;
                    margin-left: 2px;
                }
            }
            svg {
                width: 14px;
                height: 14px;
            }
        }

        .delete-confirm {
            font-size: 12px;
            line-height: 30px;
            color: ${GREY};
        }
        .ant-rate {
            color: #4767a0;
        }

        .confirmSection {
            width: 20%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            svg {
                display: none;
                width: 14px;
                height: 14px;
                margin-left: 15px;
                cursor: pointer;
                transition: transform 300ms;
                :hover {
                    transform: scale(1.3);
                }
            }
        }
    }

    textarea {
        width: 100%;
        height: 150px;
        resize: none;
    }
    @media only screen and (max-width: 769px) {
        .opinion {
            flex-wrap: wrap;
            margin-top: -15px;
            .item {
                margin-top: 15px;
                width: 20%;
            }
            svg {
                width: 30px;
                height: 30px;
            }
        }
        .rating {
            .item {
                flex-direction: column;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 10px 0;
            }
        }
    }
`
