const timestampArray = []

const hourArray = ['12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11']
const minuteArray = ['00', '15', '30', '45']
const meridianArray = ['am', 'pm']

meridianArray.forEach(meri => {
    hourArray.forEach(hour => {
        minuteArray.forEach(minute => {
            timestampArray.push(`${hour}:${minute} ${meri}`)
        })
    })
})

export const times = timestampArray

export const timeFormat = 'hh:mm a'

export const dateFormat = 'Do MMM YYYY'
