import styled from 'styled-components'
import { ContentWrapper } from '../style'
import { DARK_BLUE, LIGHT_GREY, BLACK } from '../../Helpers/Stylesheet/styledComponents'

export const ComponentWrapper = styled(ContentWrapper)`
    .help {
        font-size: 12px;
        color: ${LIGHT_GREY};
        margin-top: -10px;
    }
    .title {
        margin-bottom: 5px;
        font-family: sanFranciscoMedium;
        font-size: 14px;
        font-weight: 500;
        color: ${BLACK};
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        .save {
            color: ${DARK_BLUE};
            cursor: pointer;
            &.disabled {
                pointer-events: none;
                color: ${LIGHT_GREY};
            }
        }
    }
`
