import React from 'react'
import { MessageModal } from '../common'

export const ConfirmJoinCandidate = props => (
    <MessageModal
        title="CONFIRM JOINED"
        message={
            <span>
                Are you sure you want to mark <b>{props.candidateName ? props.candidateName : 'the Candidate'}</b> as
                joined?
            </span>
        }
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
    />
)
