import styled from 'styled-components'
import {
    DARK_BLUE,
    TextEllipsis,
    FlexCenterCol,
    FlexCenterRow,
    BORDER_COLOR,
    BLACK,
    GREY,
} from '../../Helpers/Stylesheet/styledComponents'
import { BuzzOut, Shake } from '../../Helpers/Stylesheet/animations'
import { ContentWrapper } from '../style'

/*
 Single Feedback Styles
 */

/*
 * Bottom Margin is only used to give vertical separation to the main block elements(NoteBox, ScoreBox .etc)
 * */

const InitialCardHeight = '300px'
const actionIconDimenstion = `
    &-image svg {
        width: 13px;
        height: 14px;
    }
`

const boxBorder = `
    border-radius: 4px;
    border: solid 1px ${BORDER_COLOR};
`

const headingFont = `
    font-size: 14px;    
    color: black;
    font-family: SFProText-Bold;
    font-weight: bold;
`

const opinionBox = `    
    margin-right: 25px;
    ${FlexCenterCol}

    &-image {
        height: 50px;
        width: 50px;
    }

    &-text {
        width: 60px;
        color: ${DARK_BLUE};
        margin-top: 10px;
        text-align: center;
        font-size: 12px;
    }    
`

export const FeedbackWrapper = styled(ContentWrapper)`
    overflow-y: auto;
    max-height: 65vh;
`

export const SingleFBCard = styled.div`    
    height: ${props => (props.height ? props.height : 'auto')}
    overflow-y: auto;
    width: 100%;
    padding: 20px;
    margin-bottom: 10px;
    ${boxBorder};

    font-size: 14px;
    line-height: 15px;
    :last-child {
        margin-bottom: 1px;
    }
    transition: height 2s;
    
    .heading {        
        ${headingFont}
    }
    
    // used only for mobile on outer side
    .overall-opinion {
        ${opinionBox};
        ${FlexCenterRow};
        justify-content: flex-start;
        margin-bottom: 20px;
    }
    
    
    .card-actions-block{        
        width: 20px ;
        font-size: 12px;
        color: ${BLACK};
        &_save-cancel{
            margin-top: 15px;
            span{                            
              font-size: 12px;
              color: ${DARK_BLUE};
            }
        }
    }

    //used to show the edit and delete icon when hover is done on the feedback card
    .card-section-delete-edit {
        width: 49px;
        display: flex;
        justify-content: space-between;
        ${actionIconDimenstion};        
        opacity: 0;               
        transition: opacity 400ms;        
    }

    :hover {
        .card-section-delete-edit {
            opacity: 1;   
            &-bin {
                :hover {
                    animation: ${BuzzOut} 500ms;
                    svg path{
                      fill: red;
                    }
                    
                }                          
            }    
            &-pen{
                :hover{
                    transform: rotate(-90deg) scale(1.3);
                    transition : transform 500ms;                  
                }
            }     
        }
    }

    .added-by-container {
        width: 100%;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 10px;
        display: inline-flex;

        .text-container {
            display: inline-flex;
            flex-direction: row;
            font-family: sanFranciscoMedium;
            color: ${BLACK};            

            &-user {
                max-width: 360px;                
                ${TextEllipsis};
                @media only screen and (max-width: 769px) {
                    max-width: 60%;
                }
            }         
        }

        .font-small {
            font-size: 12px;
        }

        .edit-icon-container {
            display: flex;
            width: 40px;
            justify-content: space-evenly;

            ${actionIconDimenstion}
        }
    }

    .job-container {
        display: inline-flex;
        width: 100%;
        font-size: 12px;
        line-height: 12px;
        
        &-job {
            width: 40%;
            ${TextEllipsis}
        }
        &-stage {
            max-width: 50%;
            ${TextEllipsis}
        }
    }
`
export const NoteBox = styled.div`
    margin-bottom: 30px;
    .heading {
        margin: 20px 0 10px;
        ${headingFont}
    }
    .para {
        width: 100%;
        color: ${GREY};
        font-size: 12px;
        line-height: 16px;
        white-space: pre-wrap;
    }

    .ant-typography,
    .ant-typography p {
        margin: 0;
    }
    @media only screen and (max-width: 769px) {
        margin-bottom: 20px;
    }
`

export const ScoreBox = styled.div`
    display: flex;
    flex-direction: row;

    .overall-opinion {
        ${opinionBox}
    }

    .rating {
        display: inline-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        &-item {
            margin: 0 30px 30px 0;
            :last-child {
                margin-bottom: 0;
                height: fit-content;
            }
            &-title {
                margin-bottom: 10px;
                font-size: 12px;
                line-height: 14px;
                color: ${BLACK};
                font-family: sanFranciscoSemi;
            }

            @media only screen and (max-width: 769px) {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin: 0 0 20px 0;
            }
        }
    }
    .feedback-star {
        margin-top: 0;
    }
    .feedback-star .anticon svg {
        width: 13px;
        height: 13px;
    }
`

export const OpinionEditorBox = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    p {
        font-size: 14px;
        line-height: 15px;
    }
    @media only screen and (max-width: 769px) {
        margin-bottom: -10px;
    }
`

export const DeleteBox = styled.div`
    width: 100%;
    min-height: ${({ initialHeight }) => initialHeight || InitialCardHeight};
    display: flex;
    padding: 0 17%;
    margin-bottom: 10px;
    ${FlexCenterCol};
    ${({ noBorder }) => (noBorder ? '' : boxBorder)};
    :last-child {
        margin-bottom: 0;
    }
    .text-box {
        font-size: 16px;
        text-align: center;
    }
    .button-box {
        ${FlexCenterRow};
        margin-top: 10px;
    }
`

export const NoFeedbackBox = styled(ContentWrapper)`
    ${FlexCenterCol};
    ${boxBorder};
    width: 100%;
    height: ${InitialCardHeight};
`
