export const fields = {
    fullName: 'Full Name',
    email: 'Email',
    contactNo: 'Phone Number',
    designation: 'Current Title/ Designation',
    currentLocation: 'Current Location',
    skypeId: 'Skype ID',
    currentCtc: 'Current CTC',
    expectedCtc: 'Expected CTC',
    noticePeriod: 'Notice Period',
    resume: 'Resume',
    noteToTheHiringTeam: 'Note to the hiring team',
    linkedIn: 'LinkedIn URL',
    github: 'Github URL',
    otherDetails: 'Portfolio/Website/Work Reference link',
}

export const defaultData = {
    fullName: 1,
    email: 1,
    contactNo: 1,
    designation: 1,
    currentLocation: 1,
    skypeId: 1,
    currentCtc: 1,
    expectedCtc: 1,
    noticePeriod: 1,
    resume: 1,
    noteToTheHiringTeam: 1,
    linkedIn: 1,
    github: 1,
    otherDetails: 1,
}
