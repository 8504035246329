import React from 'react'

const SVGComponent = () => (
    <svg width="18" height="4" viewBox="0 0 18 4" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g
                id="ATS-Board---Sort-By-Dropdown-(Hover-+-Selected-state"
                transform="translate(-271 -280)"
                fill="#182C4F"
                fillRule="nonzero"
            >
                <g id="ATS-Stages" transform="translate(40 200)">
                    <g id="Stage1">
                        <g id="Candidates" transform="translate(20 64)">
                            <g id="Candidate-Tile">
                                <g id="Options" transform="translate(211 16)">
                                    <path
                                        d="M9,4 C7.8954305,4 7,3.1045695 7,2 C7,0.8954305 7.8954305,0 9,0 C10.1045695,0 11,0.8954305 11,2 C11,3.1045695 10.1045695,4 9,4 Z M9,11 C7.8954305,11 7,10.1045695 7,9 C7,7.8954305 7.8954305,7 9,7 C10.1045695,7 11,7.8954305 11,9 C11,10.1045695 10.1045695,11 9,11 Z M9,-3 C7.8954305,-3 7,-3.8954305 7,-5 C7,-6.1045695 7.8954305,-7 9,-7 C10.1045695,-7 11,-6.1045695 11,-5 C11,-3.8954305 10.1045695,-3 9,-3 Z"
                                        id="Mask"
                                        transform="rotate(90 9 2)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
