import styled from 'styled-components'

export const CardContainer = styled.div`
    width: 245px;
    margin: 0 auto 16px;
`

export const ColumnContainer = styled.div`
    width: 291px;
    min-height: ${props => (props.isCardsAbsent ? '0px' : '560px')};
    margin: 8px 16px 8px 0;
    background-color: #e1e0eb;
    padding: 0 20px;
    border-radius: 4px;
    overflow: hidden;
    flex: 0 0 auto;
    @media only screen and (max-width: 995px) {
        width: calc(100% - 15px);
        min-height: unset;
        margin: 10px 0 15px 0;
    }
`

export const TaskList = styled.div`
    padding-right: 19px;
    min-height: 470px;
    height: 62vh;
    overflow: auto;
    width: 245px;
    box-sizing: content-box;
`

export const Title = styled.h4`
    width: 245px;
    margin: 21px auto 4px;
    line-height: 20px;
    font-weight: bolder;
    color: #697998;
    display: flex;
    flex-grow: 1;
    justify-content: space-between !important;
`

export const BoardContainer = styled.div`
    padding-top: 25px;
    display: flex;
    margin: auto;
    overflow-x: auto;
    width: 1200px;
    @media only screen and (max-width: 995px) {
        margin: 0 15px;
        padding-top: 0;
        width: calc(100% - 30px);
    }
`

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 40px;
    width: 1200px;
    position: relative;
    @media only screen and (max-width: 995px) {
        padding: 0 15px;
        display: block;
        width: 100%;
    }
`

export const TaskActionWrapper = styled.div`
    height: 38px;
    width: 285px;
    border-radius: 2px;
    background-color: #0a1f44;
    color: #ffffff;
    font-family: sanFranciscoMedium;
    font-size: 10px;
    line-height: 16px;
    padding: 11px 20px;
    margin-top: -38px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 100;
`
