import React from 'react'
import { MessageModal } from '../common'

const AssociateOpeningNote = ({ onCancel, onConfirm }) => (
    <MessageModal
        title="NOTE"
        cancelText="CANCEL"
        confirmText="ASSOCIATE JOB OPENING"
        onCancel={onCancel}
        onConfirm={onConfirm}
        width={'180px'}
        message="This candidate is not assigned to any job opening. Please move this candidate to a job opening before scheduling an interview."
    />
)

export default AssociateOpeningNote
