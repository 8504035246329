import React, { Component } from 'react'
import { Input, InputNumber, Button, Form, Select, DatePicker, Icon, Spin } from 'antd'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { PublicApplyNow } from '../../../services/api'
import { success, warning } from '../../../services/Notification'
import styles from './style'
import { countryCodes } from '../../Helpers/countryCode'
import { phoneValidator } from '../../Helpers/utils'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { ReactComponent as CalendarSvg } from '../../../assets/Icons/Extras/Calendar.svg'
import { CANDIDATE } from '../../Helpers/RegEx'
import { LogE } from './../../Helpers/errorHandler'
import { currencies } from '../../Helpers/currencies'
import LocationAutoComplete from '../../Components/locationAutoComplete'
import Dropzone from 'react-dropzone'
import { fileTypeValidation } from '../../Helpers/fileTypeValidation'
import { GetResumeDetails } from '../../../services/api'
import { GetCandidatesEmail } from '../../../services/NetworkCalls/Candidate'

class ApplyForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disabled: false,
            resumeName: null,
            uploading: false,
            candidateResumeLink: null,
            candidateResumeId: null,
        }
    }

    //Gets the UTM link data, and saves it in the state
    getUTMData = () => {
        const parsedUrl = new URL(window.location.href)
        const utmList = ['campaign', 'source', 'medium']
        let utm = {}
        let hasValue = false
        let value

        for (let utmKey of utmList) {
            value = parsedUrl.searchParams.get(`utm_${utmKey}`)
            if (value) hasValue = true
            utm[utmKey] = value
        }

        if (!hasValue) return null

        return utm
    }

    handleSubmit = e => {
        this.setState({ disabled: true })
        e.preventDefault()
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                try {
                    const { jobApplicationForm } = this.props

                    if (values.noticeDate) {
                        values.noticeDate = values.noticeDate.format()
                    }

                    if (jobApplicationForm && jobApplicationForm.contactNo !== 1 && values.phone) {
                        const countryCode = countryCodes.find(c => c.code === values.countryCode).dial_code
                        const phoneNumber = parsePhoneNumberFromString(countryCode + values.phone)
                        values.phone = phoneNumber.number
                    }
                    delete values.countryCode
                    values.jobId = this.props.jobId

                    if (values.ctcInfo) {
                        // if candidate adds currentCTC/expectedCTC on its own then it is marked private
                        if (values.ctcInfo.currentCTC || values.ctcInfo.expectedCTC) {
                            values.ctcInfo.markedPrivate = true
                        }
                    }
                    values.candidateResumeLink = this.state.candidateResumeLink
                    values.resumeId = this.state.candidateResumeId
                    values.utm = this.getUTMData()
                    values.isReferred = this.props.isReferredLink
                    await PublicApplyNow({ candidates: [values] })
                    success(`Your application was successfully submitted`)
                    this.props.onCancel()
                } catch (err) {
                    warning(err.msg || err)
                } finally {
                    this.setState({ disabled: false })
                }
            } else {
                this.setState({ disabled: false })
            }
        })
    }

    normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    uploadDoc = info => {
        if (info.status === 200) {
            let response = info.data
            if (response && response.length) {
                const details = response[0].candidateDetail
                const name = details && details.name && details.name
                const email = details && details.email && details.email[0]
                const candidateResumeLink = response && response[0] && response[0].resumeLink

                let phone = details && details.phone && details.phone[0]
                let phoneNumber = null
                if (phone) {
                    const splitPhone = phone.split(' ')
                    if (splitPhone.length > 1) {
                        phoneNumber = splitPhone[1]
                    } else {
                        phoneNumber = splitPhone[0]
                    }
                }
                phone = phoneNumber
                let countryCode = null
                const requiredFields = ['name', 'email', 'candidateResumeLink', 'phone', 'countryCode']

                const fieldSetter = {}

                requiredFields.forEach(each => {
                    let value = eval(each)
                    if (value && value.trim().length > 0) {
                        fieldSetter[each] = value
                    }
                })
                this.props.form.setFieldsValue(fieldSetter)
                this.setState({ candidateResumeLink, candidateResumeId: details.resume_id })
            }
            this.setState({ uploading: false })
        } else if (info.file.status === 'error') {
            warning('Error uploading resume!')
        } else if (info.file.status === 'uploading') {
            this.setState({ uploading: true })
        }
    }

    portfolioValidator = (rule, value, callback) => {
        if (value && value.trim().length > 0) {
            let links = value.split('\n')
            links.forEach(each => {
                if (each.trim().length > 0) {
                    if (!each.match(CANDIDATE.PORTFOLIO)) callback('Invalid URL')
                }
            })
        } else if (value && value.length > 0) {
            callback('Please enter a valid URL')
        }
        callback()
    }

    validEmail = async (rule, value, callback) => {
        try {
            const { companyId, publicToken, jobId } = this.props
            if (value && value.trim().length > 0) {
                const checkMail = await this.props.GetCandidatesEmail({
                    email: value,
                    companyId,
                    token: publicToken,
                    jobId,
                })
                if (checkMail.isPresent && value !== this.props.currentEmail) {
                    return callback('Email is already present')
                }
            }
            callback()
        } catch (err) {
            warning(err.message || err)
            LogE('apply-now-duplicate-email', '', err)
        }
    }

    onResumeSelect = async acceptedFile => {
        try {
            this.setState({ uploading: true })
            const fileCheck = fileTypeValidation(acceptedFile)
            if (!fileCheck) {
                warning('Error uploading resume! Please ensure the file is either of type pdf, doc or docx ')
                this.setState({ uploading: false })
                return true
            }
            const result = await GetResumeDetails(fileCheck, this.props.publicToken)
            if (result) {
                this.uploadDoc(result)
                this.setState({ uploading: false, resumeName: acceptedFile[0].name })
                return true
            }
        } catch (err) {
            const errorMsg = (err.response && err.response.data && err.response.data.error.msg) || err
            warning(errorMsg)
        }
        this.setState({ uploading: false })
        return false
    }

    handleLocationSelect = data => {
        this.props.form.setFieldsValue({ currentLocation: data })
    }

    render() {
        const { jobApplicationForm, publicToken, companyId } = this.props
        const { getFieldDecorator, getFieldValue, setFields } = this.props.form
        const { disabled, resumeName, uploading } = this.state
        const {
            contactNo,
            currentCtc,
            currentLocation,
            designation,
            email,
            fullName,
            noticePeriod,
            resume,
            skypeId,
            status,
        } = jobApplicationForm

        const uploadIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

        const prefixSelector = getFieldDecorator('countryCode', {
            rules: [
                {
                    required: !!getFieldValue('phone'),
                    message: 'Country code is required',
                },
                {
                    validator: phoneValidator(
                        field => getFieldValue(field),
                        (field, value, errors) => {
                            setFields({
                                [field]: { value, errors },
                            })
                        },
                        null,
                        null,
                        { company: { _id: companyId }, jobId: this.props.jobId }
                    ),
                },
            ],
        })(
            <Select
                showSearch
                style={{ width: 70 }}
                dropdownStyle={{ width: 260 }}
                optionLabelProp="title"
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
                dropdownClassName="phone-input-dropdown"
                getPopupContainer={trigger => trigger.parentNode}
                filterOption={(input, option) =>
                    option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) === 0
                }
            >
                {countryCodes.map(code => (
                    <Select.Option key={code.code} title={code.dial_code}>
                        {code.name} ({code.dial_code})
                    </Select.Option>
                ))}
            </Select>
        )

        return (
            <Form>
                <div className="apply-form-container">
                    <div className="apply-form-title">Apply Now</div>
                    <div className="apply-form-cell">
                        {resume !== 1 && (
                            <div className="apply-form-left">
                                <div className="apply-form-static">Resume{resume === 3 && '*'}</div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('candidateResumeLink', {
                                        // valuePropName: 'fileList',
                                        // getValueFromEvent: this.normFile,
                                        rules: [
                                            {
                                                required: resume === 3,
                                                message: 'Resume is required!',
                                            },
                                        ],
                                    })(
                                        <div>
                                            <Dropzone onDrop={this.onResumeSelect}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()} className="apply-form-resume-btn">
                                                        <input {...getInputProps()} />
                                                        {uploading ? (
                                                            <div className="apply-form-resume-btn">
                                                                {<Spin indicator={uploadIcon} />}
                                                            </div>
                                                        ) : (
                                                            <Button>
                                                                <span className="overflow-ellipsis">
                                                                    {resumeName || 'Add resume'}
                                                                </span>
                                                                <div className="apply-form-resume-btn1">UPLOAD</div>
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                    )}
                                </Form.Item>
                            </div>
                        )}

                        {fullName !== 1 && (
                            <div className="apply-form-left">
                                <div className="apply-form-static">Full Name{fullName === 3 && '*'}</div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: fullName === 3,
                                                whitespace: true,
                                                message: 'Full name is required!',
                                            },
                                            {
                                                pattern: /^[a-zA-Z].*$/,
                                                message: 'Enter valid name',
                                            },
                                        ],
                                    })(<Input style={styles.textInput} placeholder="Enter full name" />)}
                                </Form.Item>
                            </div>
                        )}

                        {email !== 1 && (
                            <div className="apply-form-right">
                                <div className="apply-form-static">Email Address{email === 3 && '*'}</div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('email', {
                                        validateTrigger: 'onBlur',
                                        rules: [
                                            {
                                                required: email === 3,
                                                whitespace: true,
                                                message: 'Email is required!',
                                            },
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                validator: this.validEmail,
                                            },
                                        ],
                                    })(<Input style={styles.textInput} placeholder="Enter email address" />)}
                                </Form.Item>
                            </div>
                        )}

                        {contactNo !== 1 && (
                            <div className="apply-form-left">
                                <div className="apply-form-static">Phone Number{contactNo === 3 && '*'}</div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('phone', {
                                        rules: [
                                            {
                                                required: contactNo === 3,
                                                whitespace: true,
                                                message: 'Phone number is required!',
                                            },
                                            {
                                                validator: phoneValidator(
                                                    field => getFieldValue(field),
                                                    (field, value, errors) => {
                                                        setFields({
                                                            [field]: { value, errors },
                                                        })
                                                    },
                                                    null,
                                                    null,
                                                    { company: { _id: companyId }, jobId: this.props.jobId }
                                                ),
                                            },
                                        ],
                                    })(
                                        <Input
                                            addonBefore={prefixSelector}
                                            style={styles.textInput}
                                            className="apply-now-phone"
                                            placeholder="Enter phone number"
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        )}

                        {jobApplicationForm && jobApplicationForm.skypeId !== 1 ? (
                            <div className="apply-form-right">
                                <div className="apply-form-static">
                                    Skype Id
                                    {jobApplicationForm.skypeId === 3 ? '*' : null}
                                </div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('skypeId', {
                                        rules: [
                                            {
                                                required: jobApplicationForm.skypeId === 3,
                                                whitespace: true,
                                                message: 'Skype Id is required!',
                                            },
                                        ],
                                    })(<Input style={styles.textInput} placeholder="Enter skype id" />)}
                                </Form.Item>
                            </div>
                        ) : null}

                        {jobApplicationForm && jobApplicationForm.designation !== 1 ? (
                            <div className="apply-form-left">
                                <div className="apply-form-static">
                                    Job Title / Designation
                                    {jobApplicationForm.designation === 3 ? '*' : null}
                                </div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('designation', {
                                        rules: [
                                            {
                                                required: jobApplicationForm.designation === 3,
                                                whitespace: true,
                                                message: 'Job Title is required!',
                                            },
                                        ],
                                    })(<Input style={styles.textInput} placeholder="Enter job title / designation" />)}
                                </Form.Item>
                            </div>
                        ) : null}
                        {jobApplicationForm && jobApplicationForm.currentLocation !== 1 ? (
                            <div className="apply-form-right">
                                <div className="apply-form-static">
                                    Current Location
                                    {jobApplicationForm.currentLocation === 3 ? '*' : null}
                                </div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('currentLocation', {
                                        rules: [
                                            {
                                                required: jobApplicationForm.currentLocation === 3,
                                                whitespace: true,
                                                message: 'Current Location is required!',
                                            },
                                        ],
                                    })(
                                        <LocationAutoComplete
                                            placeholder="Enter current location"
                                            onLocationSelect={this.handleLocationSelect}
                                            className={'apply-now'}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                        ) : null}

                        {jobApplicationForm && jobApplicationForm.currentCtc !== 1 ? (
                            <div className="apply-form-left">
                                <div className="apply-form-static">
                                    Current CTC
                                    {jobApplicationForm.currentCtc === 3 ? '*' : null}
                                </div>
                                <div className="apply-form-currency">
                                    <Form.Item style={{ marginBottom: 0, width: '20%' }}>
                                        {getFieldDecorator('ctcInfo.currentCTCCurrency', {
                                            initialValue: 'INR',
                                        })(
                                            <Select
                                                allowClear={false}
                                                optionLabelProp="title"
                                                size="large"
                                                style={{ fontSize: '14px' }}
                                                dropdownMatchSelectWidth={false}
                                                getPopupContainer={trigger => trigger.parentNode}
                                            >
                                                {currencies.map(currency => (
                                                    <Select.Option
                                                        style={{ lineHeight: '18px' }}
                                                        title={currency.symbol}
                                                        key={currency.symbol}
                                                    >{`${currency.name} (${currency.symbol})`}</Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: 0, width: '75%' }}>
                                        {getFieldDecorator('ctcInfo.currentCTC', {
                                            rules: [
                                                {
                                                    required: jobApplicationForm.currentCtc === 3,
                                                    message: 'Current CTC is required!',
                                                },
                                            ],
                                        })(
                                            <InputNumber
                                                style={{ width: '100%', fontSize: 14 }}
                                                size="large"
                                                placeholder="Enter current CTC"
                                                min={0}
                                            />
                                        )}
                                    </Form.Item>
                                </div>
                            </div>
                        ) : null}

                        {jobApplicationForm && jobApplicationForm.expectedCtc !== 1 ? (
                            <div className="apply-form-left">
                                <div className="apply-form-static">
                                    Expected CTC
                                    {jobApplicationForm.expectedCtc === 3 ? '*' : null}
                                </div>
                                <div className="apply-form-currency">
                                    <Form.Item style={{ marginBottom: 0, width: '20%' }}>
                                        {getFieldDecorator('ctcInfo.expectedCTCCurrency', {
                                            initialValue: 'INR',
                                        })(
                                            <Select
                                                allowClear={false}
                                                optionLabelProp="title"
                                                size="large"
                                                style={{ fontSize: '14px' }}
                                                dropdownMatchSelectWidth={false}
                                                getPopupContainer={trigger => trigger.parentNode}
                                            >
                                                {currencies.map(currency => (
                                                    <Select.Option
                                                        style={{ lineHeight: '18px' }}
                                                        title={currency.symbol}
                                                        key={currency.symbol}
                                                    >{`${currency.name} (${currency.symbol})`}</Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: 0, width: '75%' }}>
                                        {getFieldDecorator('ctcInfo.expectedCTC', {
                                            rules: [
                                                {
                                                    required: jobApplicationForm.expectedCtc === 3,
                                                    message: 'Expected CTC is required!',
                                                },
                                            ],
                                        })(
                                            <InputNumber
                                                style={{ width: '100%', fontSize: 14 }}
                                                size="large"
                                                placeholder="Enter expected CTC"
                                                min={0}
                                            />
                                        )}
                                    </Form.Item>
                                </div>
                            </div>
                        ) : null}

                        {jobApplicationForm && jobApplicationForm.noticePeriod !== 1 ? (
                            <div className="apply-form-right display-flex notice-period">
                                <div style={{ flex: 1 }}>
                                    <div className="apply-form-static">
                                        Notice Period
                                        {jobApplicationForm.noticePeriod === 3 ? '*' : null}
                                    </div>
                                    <Form.Item style={{ marginBottom: 0 }} className="applynow-select">
                                        {getFieldDecorator('noticePeriod', {
                                            rules: [
                                                {
                                                    required: jobApplicationForm.noticePeriod === 3,
                                                    whitespace: true,
                                                    message: 'Notice Period is required!',
                                                },
                                            ],
                                        })(
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Enter notice period"
                                                getPopupContainer={trigger => trigger.parentNode}
                                            >
                                                <Select.Option value="Currently in notice period">
                                                    Currently in notice period
                                                </Select.Option>
                                                <Select.Option value="Immediately Available">
                                                    Immediately Available
                                                </Select.Option>
                                                <Select.Option value="10 Days">10 Days</Select.Option>
                                                <Select.Option value="11-30 Days">11-30 Days</Select.Option>
                                                <Select.Option value="1 Month">1 Month</Select.Option>
                                                <Select.Option value="2 Months">2 Months</Select.Option>
                                                <Select.Option value="3 Months">3 Months</Select.Option>
                                                <Select.Option value="More than 3 Months">
                                                    More than 3 Months
                                                </Select.Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </div>

                                {getFieldValue('noticePeriod') === 'Currently in notice period' && (
                                    <div className="last-working">
                                        <div className="apply-form-static">Last working day*</div>
                                        <Form.Item style={{ marginBottom: 0 }}>
                                            {getFieldDecorator('noticeDate', {
                                                rules: [
                                                    {
                                                        type: 'object',
                                                        required: true,
                                                        message: 'Last working day is required!',
                                                    },
                                                ],
                                            })(
                                                <DatePicker
                                                    className="apply-form-dp"
                                                    size="large"
                                                    showTime={false}
                                                    showToday={false}
                                                    suffixIcon={<Icon component={CalendarSvg} />}
                                                    format="DD MMM Y"
                                                    disabledDate={current =>
                                                        current && current < moment().startOf('day')
                                                    }
                                                />
                                            )}
                                        </Form.Item>
                                    </div>
                                )}
                            </div>
                        ) : null}

                        {jobApplicationForm && jobApplicationForm.linkedIn !== 1 ? (
                            <div className="apply-form-right">
                                <div className="apply-form-static">
                                    LinkedIn URL
                                    {jobApplicationForm.linkedIn === 3 ? '*' : null}
                                </div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('linkedIn', {
                                        rules: [
                                            {
                                                required: jobApplicationForm.linkedIn === 3,
                                                whitespace: true,
                                                message: 'Linkedin URL is required!',
                                            },
                                            {
                                                pattern: CANDIDATE.LINKEDIN,
                                                message: 'Invalid Linkedin URL',
                                            },
                                        ],
                                    })(<Input style={styles.textInput} placeholder="Enter your linkedin URL" />)}
                                </Form.Item>
                            </div>
                        ) : null}

                        {jobApplicationForm && jobApplicationForm.github !== 1 ? (
                            <div className="apply-form-right">
                                <div className="apply-form-static">
                                    Github URL
                                    {jobApplicationForm.github === 3 ? '*' : null}
                                </div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('github', {
                                        rules: [
                                            {
                                                required: jobApplicationForm.github === 3,
                                                whitespace: true,
                                                message: 'Github URL is required!',
                                            },
                                            {
                                                pattern: CANDIDATE.GITHUB,
                                                message: 'Invalid github URL',
                                            },
                                        ],
                                    })(<Input style={styles.textInput} placeholder="Enter your github URL" />)}
                                </Form.Item>
                            </div>
                        ) : null}

                        {jobApplicationForm && jobApplicationForm.otherDetails !== 1 ? (
                            <div className="apply-form-full">
                                <div className="apply-form-static">
                                    Portfolio/Website/Work Reference link
                                    {jobApplicationForm.otherDetails === 3 ? '*' : null}
                                </div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('otherDetails', {
                                        validateTrigger: 'onBlur',
                                        rules: [
                                            {
                                                required: jobApplicationForm.otherDetails === 3,
                                                message: 'Please enter the required details',
                                            },
                                            {
                                                validator: this.portfolioValidator,
                                            },
                                        ],
                                    })(<Input.TextArea rows={4} placeholder="Add 1 link per line" />)}
                                </Form.Item>
                            </div>
                        ) : null}

                        {jobApplicationForm && jobApplicationForm.noteToTheHiringTeam !== 1 ? (
                            <div className="apply-form-full">
                                <div className="apply-form-static">
                                    Note To The Hiring Team
                                    {jobApplicationForm.noteToTheHiringTeam === 3 ? '*' : null}
                                </div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('noteToTheHiringTeam', {
                                        validateTrigger: 'onBlur',
                                        rules: [
                                            {
                                                required: jobApplicationForm.noteToTheHiringTeam === 3,
                                                message: 'Note is required',
                                            },
                                        ],
                                    })(<Input.TextArea rows={4} placeholder="Leave your note here for hiring team" />)}
                                </Form.Item>
                            </div>
                        ) : null}
                        {this.props.isReferredLink ? (
                            <div className="apply-form-left">
                                <div className="apply-form-static">Referrer Name*</div>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('referrerName', {
                                        rules: [
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: 'Referrer name is required!',
                                            },
                                            {
                                                pattern: /^[a-zA-Z].*$/,
                                                message: 'Enter valid name',
                                            },
                                        ],
                                    })(<Input style={styles.textInput} placeholder="Enter full name" />)}
                                </Form.Item>
                            </div>
                        ) : null}
                        {this.props.isReferredLink ? (
                            <div className="apply-form-right">
                                <div className="apply-form-right">
                                    <div className="apply-form-static">Referrer Email*</div>
                                    <Form.Item style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('referrerEmail', {
                                            validateTrigger: 'onBlur',
                                            rules: [
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: 'Referrer Email is required!',
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail!',
                                                },
                                                {
                                                    validator: this.validEmail,
                                                },
                                            ],
                                        })(<Input style={styles.textInput} placeholder="Enter email address" />)}
                                    </Form.Item>
                                </div>
                            </div>
                        ) : null}
                    </div>

                    <div style={styles.submitdiv}>
                        <Button type="primary" style={styles.submit} disabled={disabled} onClick={this.handleSubmit}>
                            SUBMIT
                        </Button>
                    </div>
                </div>
            </Form>
        )
    }
}

export default connect(
    null,
    { GetCandidatesEmail }
)(Form.create({ name: 'applyNowForm' })(ApplyForm))
