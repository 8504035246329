import styled from 'styled-components'
import LogoImg from '../../assets/svg/new_logo.svg'
import { Layout } from 'antd'

const { Header, Footer } = Layout

export const Logo = styled.div`
    width: 120px;
    height: 46px;
    background-image: url(${LogoImg});
    margin: 10px 0 auto;
    background-repeat: no-repeat;
    cursor: pointer;
`

export const Topbar = styled(Header)`
    padding: 0;
    background-color: #fff !important;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    box-shadow: 0 3px 0px -2px #eee;
    position: relative;
    line-height: 56px !important;
    height: 56px !important;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    margin: 0 auto;
    @media only screen and (min-width: 1200px) {
        padding: 0 calc((100% - 1200px) / 2);
    }
`

export const Options = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: auto 0;
`
export const FooterBar = styled(Footer)`
    height: 64px;
    box-shadow: 0 1px 4px 0 rgba(10, 31, 68, 0.1);
    background-color: #1c2234;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
        color: #999;
        padding: 0 10px;
        border-right: 1px solid #9996;
        :hover {
            color: #fff;
        }
        :last-child {
            border: none;
        }
    }

    @media only screen and (max-width: 769px) {
        display: block;
        height: unset;
        padding: 15px;
        font-size: 10px;

        a {
            width: 100%;
            display: block;
            border: 0;
            text-align: center;
            line-height: 20px;
        }
    }
`
