import React, { useState } from 'react'
import { Spin } from 'antd'
import { Aside, SubTitle, Block } from '../style'
import { FILTER_TYPES } from '../constants'
import RefineBox from './RefineBox'
import FilterBox from './FilterBox'
import { connect } from 'react-redux'
import { getApplications, setApiProcess } from '../../../../services/NetworkCalls/SourcingTalentPool'
import { ReactComponent as NoEmailsIcon } from '../../../../assets/Icons/Openings/no-emails.svg'

/** Container Component **/
function Filter({
    countData,
    getApplications,
    setApiProcess,
    interestedJobs,
    assignedJobs,
    skills,
    tags,
    updateParent,
    query,
    callInProgress,
    locations,
    inbox,
}) {
    const [filter, setFilter] = useState(FILTER_TYPES.ALL)
    const [relocate, setRelocate] = useState(null)
    const [saveChildFilter, setSaveChildFilter] = useState({
        interested_jobs: null,
        skills: null,
        assigned_jobs: null,
        tags: null,
        locations: null,
    })
    const updateFilter = ({ type, data }) => {
        let structureData = {
            dataChange: true,
            relocation: relocate,
            assigned_jobs: FILTER_TYPES.UNASSIGNED ? null : saveChildFilter.assigned_jobs, // assigned filter does not come in unassigned
            interested_jobs: saveChildFilter.interested_jobs,
            skills: saveChildFilter.skills,
            tags: saveChildFilter.tags,
            locations: saveChildFilter.locations,
            main_filter: filter,
            currentPage: 1,
            query,
        }

        switch (type) {
            case 'filter':
                setFilter(data)
                structureData = {
                    ...structureData,
                    assigned_jobs: null,
                    interested_jobs: null,
                    skills: null,
                    tags: null,
                    locations: null,
                    main_filter: data,
                }
                break
            case 'relocate':
                setRelocate(data)
                structureData.relocation = data
                break
            case 'interested':
                structureData.interested_jobs = data && data.length ? data : null
                setSaveChildFilter({ ...saveChildFilter, interested_jobs: data && data.length ? data : null })
                break
            case 'skills':
                structureData.skills = data && data.length ? data : null
                setSaveChildFilter({ ...saveChildFilter, skills: data && data.length ? data : null })
                break
            case 'assigned':
                structureData.assigned_jobs = data && data.length ? data : null
                setSaveChildFilter({ ...saveChildFilter, assigned_jobs: data && data.length ? data : null })
                break
            case 'tags':
                structureData.tags = data && data.length ? data : null
                setSaveChildFilter({ ...saveChildFilter, tags: data && data.length ? data : null })
                break
            case 'locations':
                structureData.locations = data && data.length ? data : null
                setSaveChildFilter({
                    ...saveChildFilter,
                    locations: data && data.length ? data : null,
                })
                break
            default:
                return null
        }
        setApiProcess()
        getApplications(structureData)
        updateParent(structureData)
    }
    return (
        <div className="sidebar">
            <SubTitle className="sub-title">Filter by</SubTitle>
            <Spin spinning={callInProgress}>
                <Aside>
                    {inbox ? (
                        <Block>
                            <div className="inbox-title">
                                <NoEmailsIcon />
                                <div>
                                    <span>Inbox</span> <p>springrole@yopmail.com</p>
                                </div>
                            </div>
                        </Block>
                    ) : (
                        ''
                    )}
                    <FilterBox
                        onFilterSelected={updateFilter}
                        filter={filter}
                        relocate={relocate}
                        countData={countData}
                    />
                    <RefineBox
                        interestedJobs={interestedJobs}
                        updateFilter={updateFilter}
                        selectedFilter={filter}
                        assignedJobs={assignedJobs}
                        skills={skills}
                        tags={tags}
                        locations={locations}
                    />
                </Aside>
            </Spin>
        </div>
    )
}

const mapStateToProps = state => {
    const { countData, interestedJobs, assignedJobs, skills, tags, locations } = state.Sourcing
    return { countData, interestedJobs, assignedJobs, skills, tags, locations }
}

export default connect(
    mapStateToProps,
    { getApplications, setApiProcess }
)(Filter)
