import styled, { createGlobalStyle } from 'styled-components'
import {
    DARK_BLUE,
    BLACK,
    GREY,
    LIGHT_GREY,
    BORDER_COLOR,
    TextEllipsis,
    ORANGE,
} from '../../Helpers/Stylesheet/styledComponents'
import { FormWrapper } from '../../Modals/AddCandidate/style'

export const Top = styled.div`
    background: ${DARK_BLUE};
    @media only screen and (min-width: 1200px) {
        padding: 0 calc((100% - 1200px) / 2);
    }
    h1 {
        font-size: 36px;
        font-weight: 600;
        color: #fff;
        padding-top: 85px;
    }
    p {
        color: #fff;
        font-size: 16px;
        padding-bottom: 85px;
    }
    .header {
        padding: 40px 0;
        border-bottom: solid 1px #ffffff20;
    }
`
export const Bottom = styled.div`
    display: grid;
    grid-template-columns: 1fr 2.3fr;
    grid-gap: 30px;
    margin: 40px auto;
    max-width: 1200px;
`

export const Success = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 30px;
    margin: 40px auto;
    max-width: 1200px;
    .title {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        margin-top: 10px;
    }
    p {
        font-size: 16px;
        line-height: 24px;
        color: #333;
        margin-top: 10px;
        margin-bottom: 30px;
        width: 70%;
        text-align: center;
    }
`

export const StepButton = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 45px;
`

export const Card = styled.div`
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: ${({ padding }) => (padding ? '' : '30px')};
    position: relative;
    height: fit-content;
    .mb10 div {
        margin-bottom: 10px;
    }
    .pb0 {
        padding: 30px 30px 0px;
    }
    .p30 {
        padding: 30px;
    }
    .sidebar-form {
        .ant-input,
        .ant-select {
            margin-top: 30px;
            color: ${LIGHT_GREY};
        }
        .ant-input {
            border: 1px solid #d9d9d9;
        }
        .ant-select {
            width: 100%;
            svg {
                width: 10px;
            }
        }
        .sidebar-buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 45px;
            margin-bottom: 30px;
            button {
                cursor: pointer;
                background: transparent;
                border: none;
                color: ${DARK_BLUE};
                font-size: 12px;
                + button {
                    margin-left: 10px;
                }
            }
        }
    }
    .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border-radius: 10px;
        background: ${DARK_BLUE};
        font-size: 24px;
        font-weight: 600;
        font-family: sanFranciscoSemi;
        color: #fff;
        margin-bottom: 10px;
    }
    .name {
        font-family: sanFranciscoMedium;
        font-size: 18px;
        font-weight: bold;
        ${TextEllipsis};
        max-width: 295px;
        color: ${BLACK};
    }
    .label {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: ${LIGHT_GREY};
        &.small {
            font-size: 12px;
            margin-bottom: 5px;
            span {
                :first-child {
                    width: 100px;
                }
                :nth-child(2) {
                    color: ${BLACK};
                }
            }
        }
        svg {
            width: 15px;
            height: 15px;
            margin-right: 5px;
            path {
                fill: ${LIGHT_GREY};
            }
        }
    }
    .edit {
        position: absolute;
        top: 30px;
        right: 30px;
        color: ${DARK_BLUE};
        font-size: 12px;
        cursor: pointer;
    }
    .mt30 {
        margin-top: 30px;
    }

    .warning {
        color: ${ORANGE};
    }

    .input-container {
        position: relative;
        border-radius: 4px;
        border: solid 1px ${BORDER_COLOR};
        width: 100%;
        .ant-input {
            position: sticky;
            bottom: 0;
            font-size: 12px;
            border: none;
            ::placeholder {
                color: ${LIGHT_GREY};
            }
            :focus {
                box-shadow: none;
            }
        }
        .tags {
            padding: 0 10px;
            margin-top: 5px;
            height: 100px;
            overflow: auto;
        }
    }
    .tags {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
        font-size: 12px;
        color: ${GREY};
        &.bordered {
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid ${BORDER_COLOR};
        }
        > span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            padding: 0 15px;
            height: 40px;
            border-radius: 5px;
            background-color: #eeeeee;
            margin: 5px;
            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            svg {
                margin-left: 10px;
                path {
                    fill: ${GREY};
                }
            }
        }
    }
    .resume {
        display: flex;
        align-items: flex-end;
        > .tags {
            margin-right: 20px;
        }
        > div:not(.tags) {
            flex-shrink: 0;
        }
    }
    .details {
        .item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            > span {
                width: 235px;
                min-width: 235px;
                display: inline-block;
                font-size: 16px;
                color: ${LIGHT_GREY};
                :nth-child(2) {
                    font-family: sanFranciscoMedium;
                    color: ${GREY};
                }
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 45px;
    }
    .steps {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        .step {
            height: 5px;
            background: ${BORDER_COLOR};
            :nth-child(1) {
                border-radius: 0 0 0 5px;
                background: ${DARK_BLUE};
            }
            :nth-child(2) {
                border-left: 1px solid #fff;
                border-right: 1px solid #fff;
            }
            :nth-child(3) {
                border-radius: 0 0 5px 0;
            }
            &.active {
                background: ${DARK_BLUE};
            }
        }
    }
`
export const Form = styled(FormWrapper)`
    margin: 0;
    .ctc {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
    }
    .full {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .ant-input-group {
        display: flex;
        .ant-select {
            width: auto;
        }
        .ant-select-selection-selected-value {
            line-height: 0;
            svg {
                height: 38px;
                width: 24px;
            }
        }
    }
`
