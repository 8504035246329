import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="55" width="60" viewBox="0 0 60 55">
            <path
                fill="#A6AEBC"
                fillRule="nonzero"
                stroke="none"
                strokeWidth="1"
                d="M60 23.317a6.559 6.559 0 00-6.549-6.551H38.177c.603-3.197.906-6.443.906-9.696A7.075 7.075 0 0026.98 2.1a7.013 7.013 0 00-2.038 5.064c.054 4.123-.109 7.035-2.787 9.834-1.18 1.235-3.824 3.014-5.296 3.953v-.092H0V53.9h12.599a4.251 4.251 0 004.2-3.693c1.583 2.394 4.513 4.155 9.713 4.155H45.78a6.549 6.549 0 006.067-9.019 6.542 6.542 0 002.858-7.96 6.53 6.53 0 002.819-8.942A6.532 6.532 0 0060 23.317zM13.997 48.211a3.155 3.155 0 11-3.542-3.537 3.16 3.16 0 013.542 3.537zm39.454-22.954h-.2a2.305 2.305 0 00-.8 4.463 1.937 1.937 0 01-.692 3.753h-1.502a2.305 2.305 0 00-.917 4.42 1.942 1.942 0 01-.77 3.732h-1.095a2.305 2.305 0 00-.921 4.417 1.94 1.94 0 01-.773 3.71H26.524c-4.076 0-6.79-1.025-6.79-6.022V24.58c1.54-.989 4.253-2.834 5.743-4.393 3.868-4.041 4.128-8.317 4.064-13.093a2.47 2.47 0 114.94-.032 47.826 47.826 0 01-1.393 11.448 2.305 2.305 0 002.223 2.858h18.135a1.942 1.942 0 110 3.881l.005.008z"
                transform="translate(-1088 -190) translate(60 116) translate(12 55) translate(1016 19)"
            />
        </svg>
    )
}

export default Icon
