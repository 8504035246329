import apiCaller from '../../apiCaller'
import { success, warning } from '../../../services/Notification'
import { LogE } from '../../../container/Helpers/errorHandler'
import actions from '../../../redux/jobs/actions'

const { restricted } = apiCaller.type
const { formatError } = apiCaller
const baseUrl = `job/`

export const GetJobDetails = (jobId, companyId) => async dispatch => {
    try {
        const { data } = await restricted.get(`${baseUrl}${jobId}?companyId=${companyId}`)
        return data
    } catch (error) {
        warning(formatError(error))
    }
}

export const CreateJob = input => async dispatch => {
    try {
        const { data } = await restricted.post('job', input)
        if (data.error) {
            LogE('networkCalls-job-createJob', 'Failed to create', data.error)
            warning('Failed to create job')
            return {}
        } else {
            success('Job created successfully')
            dispatch({ type: actions.ADD_JOB, job: data.jobDetail, stages: data.stages })
            return data
        }
    } catch (error) {
        LogE('networkCalls-job-createJob-catch', 'Failed to create', error)
        warning(formatError(error))
    }
}

export const UpdateJob = (input, jobId) => async dispatch => {
    try {
        const { data } = await restricted.patch(`${baseUrl}${jobId}`, input)
        if (data.error) {
            LogE('networkCalls-job-updateJob', 'Failed to update', data.error)
            warning('Failed to update job')
            return null
        } else {
            success('Job updated successfully')
            dispatch({ type: actions.UPDATE_JOB, job: data.job })
            return data
        }
    } catch (error) {
        LogE('networkCalls-job-updateJob-catch', 'Failed to update', error)
        warning(formatError(error))
    }
}
