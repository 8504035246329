import React, { Component } from 'react'
// import { Button } from 'antd';

import {
    TAContainer,
    NoActivity,
    NoTAText,
    // ActivityCell, Activity, ActivityData, TimeAndRole, RoleActivity, ActivityStatic, ActivityTime, NoTeamMember
} from '../StyleComponent'
// import styles from '../style';

export default class TeamActivity extends Component {
    render() {
        return (
            <TAContainer className="no-activity">
                <NoActivity>
                    <NoTAText>No new activities from team member(s) available to display.</NoTAText>
                </NoActivity>

                {/* <NoTeamMember>
          <NoTAText>
            No team members added yet. You can invite team members to collaborate and delegate work for a faster recruitment experience.
          </NoTAText>
          <Button type='primary' style={styles.inviteTeamMembers}>
            INVITE TEAM MEMBERS
          </Button>
        </NoTeamMember> */}

                {/**
        <ActivityCell>
          <Activity>
            <ActivityData>
              Recruiter1 moved  John Deo  from Screening to telephonic Interview
            </ActivityData>
          </Activity>
          <TimeAndRole>
            <RoleActivity>
              <ActivityStatic>
                Role:
              </ActivityStatic>
              <ActivityData>
                Lead Designer
              </ActivityData>
            </RoleActivity>
            <ActivityTime>
              10:55PM 10th Mar,2019
            </ActivityTime>
          </TimeAndRole>
        </ActivityCell>
        */}
            </TAContainer>
        )
    }
}
