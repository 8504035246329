import React from 'react'
import { connect } from 'react-redux'
import { Rate, Input, Icon, Tooltip, Checkbox } from 'antd'
import './style.css'
import StarIcon from '../../../assets/Icons/Feedback/Star Outline'
import { CreateFeedback, EditFeedback } from '../../../services/NetworkCalls/Feedback'
import { F_OVERALL_COLLECTION, defaultFeedback } from '../../Helpers/constants'
import { Block, ModalSubTitle, ModalWrapper } from './style'
import { Header, Footer, FeedbackOpinionEditor } from '../common'
import { ContentWrapper } from '../style'
import { feedbackDataValidator } from '../../Helpers/Validations/Feedback'
import { OpinionEditorBox } from '../ViewFeedbacks/style'
import Config from './../../../config'
import { ReactComponent as InfoIcon } from './../../../assets/Icons/Interview/info.svg'
import StorageManager from '../../Managers/Storage'
const { isMobileDevice } = Config

class AddFeedback extends React.Component {
    constructor(props) {
        super(props)
        let feedback = props.defaultFeedback || Object.assign({}, defaultFeedback)
        let customSkills = []
        this.cachedInput = new StorageManager('FEEDBACK', props.candidateId)
        const cachedData = this.cachedInput.getData()
        if (cachedData && !feedback.overallFeedback) feedback.overallFeedback = cachedData

        if (!props.overrideCustomSkills) {
            const { components = [] } = props.allCustomSkills
            const customSkillMap = {}
            components.forEach(({ description, name, required, status, _id }) => {
                if (!props.feedbackId && status !== 1) return null
                customSkillMap[_id] = { description, name, required, status, _id }
            })

            if (props.feedbackId)
                customSkills = props.customSkillsData
                    ? props.customSkillsData.map(skill => ({ ...skill, ...customSkillMap[skill.id] }))
                    : []
            else customSkills = Object.values(customSkillMap)
        } else customSkills = Object.assign([], props.overrideCustomSkills)

        this.state = {
            feedback,
            customSkills,
            opinion: (feedback.hasOwnProperty('overallPoints') && feedback.overallPoints) || 0,
            disable: false,
        }
    }

    onCancel = () => {
        this.cachedInput.clearSelf()
        this.props.onCancel()
    }

    opinionManager({ overallPoints }) {
        const { feedback } = this.state
        feedback['overallPoints'] = overallPoints
        this.setState({ feedback, opinion: overallPoints })
    }

    onSubmit = () => {
        if (this.props.overrideCustomSkills && !this.props.feedbackId) return this.onCancel()
        this.setState({ disable: true }, async () => {
            const {
                interviewId,
                candidateId,
                stageId,
                feedbackId,
                jobId,
                userId,
                filter,
                todoFilterType,
                allCustomSkills,
            } = this.props
            let { feedback, customSkills } = this.state

            if (feedback && !feedbackDataValidator(feedback, customSkills)) this.setState({ disable: false })
            else {
                if (customSkills.length) feedback.customFields = customSkills.map(({ _id, ans }) => ({ _id, ans }))
                let finalFeedback = feedback.noShow
                    ? { noShow: true, overallFeedback: feedback.overallFeedback }
                    : { ...feedback }
                feedback = feedbackId
                    ? await this.props.EditFeedback({ ...feedback, _id: feedbackId, userId })
                    : await this.props.CreateFeedback(
                        { ...finalFeedback, interviewId, candidateId, stageId, jobId, userId },
                        { filter, todoFilterType }
                    )
                this.cachedInput.clearSelf()
                this.setState({ disable: false }, () => feedback && this.props.onConfirm(feedback))
            }
        })
    }

    handleChange = name => event => {
        const { feedback } = this.state
        const value = typeof event === 'object' ? event.target.value : event
        feedback[name] = value
        if (name === 'overallFeedback') this.cachedInput.setData(value)
        this.setState({ feedback })
    }

    handleNoShow = () => {
        const {
            feedback,
            feedback: { noShow },
        } = this.state
        feedback.noShow = !noShow
        feedback.overallFeedback = ''
        this.setState({ feedback })
    }

    customSkillChange = index => value => {
        const { customSkills } = this.state
        customSkills[index].ans = value
        this.setState({ customSkills })
    }

    render() {
        const { feedback, customSkills, disable } = this.state
        const { feedbackId, overrideCustomSkills, onlyPreview, candidateName } = this.props

        return (
            <>
                <Header
                    title={`${feedbackId ? 'UPDATE' : overrideCustomSkills ? 'CUSTOMIZE' : 'ADD'
                        } FEEDBACK - ${candidateName || 'Candidate'}`}
                />
                <ModalWrapper noShow={feedback.noShow} mTop={false}>
                    <Block>
                        <ModalSubTitle>Your rating based on these skills</ModalSubTitle>
                        <div className="rating">
                            <div className="item">
                                Communication
                                <Rate
                                    className="feedback-star"
                                    character={<Icon component={StarIcon} />}
                                    defaultValue={feedback.communication}
                                    count={5}
                                    onChange={this.handleChange('communication')}
                                />
                            </div>
                            <div className="item">
                                Attitude
                                <Rate
                                    className="feedback-star"
                                    character={<Icon component={StarIcon} />}
                                    defaultValue={feedback.attitude}
                                    count={5}
                                    onChange={this.handleChange('attitude')}
                                />
                            </div>
                            <div className="item">
                                Potential to learn
                                <Rate
                                    className="feedback-star"
                                    character={<Icon component={StarIcon} />}
                                    defaultValue={feedback.learningPotential}
                                    count={5}
                                    onChange={this.handleChange('learningPotential')}
                                />
                            </div>
                            <div className="item">
                                Technical Skills
                                <Rate
                                    className="feedback-star"
                                    character={<Icon component={StarIcon} />}
                                    defaultValue={feedback.technicalSkills}
                                    count={5}
                                    onChange={this.handleChange('technicalSkills')}
                                />
                            </div>

                            {customSkills.map((skill, index) => (
                                <div className="item" key={skill._id}>
                                    <div className="item-custom-skill">
                                        {skill.name}
                                        {!!skill.description && (
                                            <Tooltip placement="top" title={skill.description} overlayClassName="fs-10">
                                                <InfoIcon />
                                            </Tooltip>
                                        )}
                                    </div>
                                    <Rate
                                        className="feedback-star"
                                        character={<Icon component={StarIcon} />}
                                        defaultValue={skill.ans}
                                        count={5}
                                        onChange={this.customSkillChange(index)}
                                    />
                                </div>
                            ))}
                        </div>
                    </Block>

                    <Block>
                        <ModalSubTitle>Your overall opinion on the candidate*</ModalSubTitle>
                        <OpinionEditorBox>
                            <FeedbackOpinionEditor
                                points={feedback.overallPoints}
                                showText={isMobileDevice}
                                showPopUp
                                onChange={value => this.opinionManager({ overallPoints: value })}
                            />
                        </OpinionEditorBox>
                    </Block>

                    <Block small>
                        <ModalSubTitle small>Overall Feedback*</ModalSubTitle>
                        <Input.TextArea
                            defaultValue={feedback.overallFeedback}
                            placeholder="Enter your feedback here"
                            rows={5}
                            onChange={this.handleChange('overallFeedback')}
                        />
                    </Block>
                </ModalWrapper>
                {!feedbackId ? (
                    <>
                        <Block>
                            <Checkbox onChange={this.handleNoShow}>Candidate didn't show up for interview.</Checkbox>
                            {this.state.feedback.noShow ? (
                                <Block>
                                    <ModalSubTitle small>Any Feedback?</ModalSubTitle>
                                    <Input.TextArea
                                        style={{ height: '75px' }}
                                        defaultValue={feedback.overallFeedback}
                                        placeholder="Enter Feedback, if any!"
                                        onChange={this.handleChange('overallFeedback')}
                                    />
                                </Block>
                            ) : null}
                        </Block>
                    </>
                ) : null}
                <Footer
                    hasSeparator
                    onCancel={this.onCancel}
                    onConfirm={onlyPreview ? null : this.onSubmit}
                    cancelText="CANCEL"
                    confirmText={`${feedbackId ? 'UPDATE' : 'ADD'}`}
                    loadingButton={true}
                    disable={disable}
                />
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { feedbackForm = {} } = state.Job.allJobs.find(job => job._id === ownProps.jobId) || {}
    return {
        userId: state.Auth.user.id,
        allCustomSkills: feedbackForm,
    }
}

export default connect(
    mapStateToProps,
    { CreateFeedback, EditFeedback }
)(AddFeedback)
