import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { DataCard, Filter, Heading } from '../../style'
import Table from '../../Components/Table'
import { JobStageLoader } from '../../Components/Loaders'
import StatCard from '../../Components/Card'
import { EMPTY_JOBS_STAGE_CARD_DATA, JOBS_STAGE_COLUMNS } from '../../Components/constants'
import CustomFilters from '../../Components/Filters'
import { ReactComponent as InfoIcon } from '../../../../../assets/Icons/Openings/info.svg'
import { ReactComponent as RefreshIcon } from '../../../../../assets/Icons/Analytics/refresh.svg'
import { getNumberOfDays, getPercentage } from '../../../../Helpers/utils'
import { GetAnalyticsData } from '../../../../../services/NetworkCalls/Analytics'
import { warning } from '../../../../../services/Notification'
import apiCaller from '../../../../../services/apiCaller'
import { Tooltip } from 'antd'
import config from '../../../../../config'
import { StyledButton } from '../../../../../container/Components/'

const { isMobileDevice } = config

const { SingleOptionFilter } = CustomFilters
const { formatError } = apiCaller

const Stage = ({ companyId, jobNamesArray, jobsObject, initialJob, GetAnalyticsData }) => {
    const [cardData, setCardData] = useState(EMPTY_JOBS_STAGE_CARD_DATA)
    const [tableData, setTableData] = useState([])
    const [cardLoading, setCardLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [selectedJobId, setSelectedJobId] = useState(initialJob._id)
    const [loadTime, setLoadTime] = useState(moment().format('DD MMM, YYYY LT'))

    const downloadColumns = [
        { index: 'stageName', value: 'STAGE NAME' },
        { index: 'candidates', value: 'CANDIDATES' },
        { index: 'moved', value: 'MOVED' },
        { index: 'archived', value: 'ARCHIVED' },
        { index: 'rejected', value: 'REJECTED' },
        { index: 'avg', value: 'AVG TIME SPENT IN STAGE' },
    ]

    useEffect(() => {
        if (selectedJobId) {
            getJobsStageCardData(selectedJobId)
            getJobsStageTableData(selectedJobId)
        }
    }, [])

    const selectJob = async jobName => {
        setSelectedJobId(jobsObject[jobName]._id)
        getJobsStageCardData(jobsObject[jobName]._id)
        getJobsStageTableData(jobsObject[jobName]._id)
    }

    const getJobsStageCardData = async jobId => {
        try {
            setCardLoading(true)
            const res = await GetAnalyticsData('GetJobsStageData', { companyId }, jobId)
            setCardData(res)
            setCardLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getJobsStageTableData = async jobId => {
        try {
            setTableLoading(true)
            const stageData = await GetAnalyticsData('GetJobsStageTableData', { companyId }, jobId)
            const stageDataArray = stageData.map(stage => stage.stage)
            let selectedJobStages = []
            for (let jobName in jobsObject) {
                if (jobsObject[jobName]._id === jobId) {
                    selectedJobStages = jobsObject[jobName].stages
                    break
                }
            }
            const tableData = []
            selectedJobStages.forEach((stage, index) => {
                if (stageDataArray.includes(stage.stageName)) {
                    const data = stageData.find(each => each.stage === stage.stageName)
                    tableData.push({
                        key: index,
                        stageName: data.stage,
                        candidates: data.candidate || 0,
                        moved: data.moved || 0,
                        archived: data.archived || 0,
                        rejected: data.rejected || 0,
                        avg: getNumberOfDays(data.averageTimeSpend),
                    })
                } else
                    tableData.push({
                        key: index,
                        stageName: stage.stageName,
                        candidates: 0,
                        moved: 0,
                        archived: 0,
                        rejected: 0,
                        avg: '0 day',
                    })
            })
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const handleRefresh = () => {
        if (selectedJobId) {
            getJobsStageCardData(selectedJobId)
            getJobsStageTableData(selectedJobId)
        }
        setLoadTime(moment().format('DD MMM, YYYY LT'))
    }

    return (
        <>
            <div className="sub-header">
                <div>
                    <Heading>Stage Analysis</Heading>
                    <Tooltip title="Time based analysis of each stage across your openings on SpringRecruit. Use the filter to search for a particular opening.">
                        <InfoIcon />
                    </Tooltip>
                </div>
                <div>
                    <RefreshIcon onClick={() => handleRefresh()} />
                    <span>Last Updated: {loadTime}</span>
                </div>
            </div>
            {isMobileDevice && <Heading>Filter</Heading>}
            <Filter>
                <SingleOptionFilter
                    title="Select job name"
                    data={jobNamesArray}
                    selectedJob={initialJob.title}
                    setData={selectJob}
                />
            </Filter>
            {cardLoading ? (
                <JobStageLoader />
            ) : (
                <>
                    <div className="cards">
                        <StatCard total={cardData.totalCandidate} title="Total Candidates" />
                        <StatCard
                            total={cardData.totalArchived}
                            title="Archived"
                            desc={`${getPercentage(
                                cardData.totalArchived,
                                cardData.totalCandidate
                            )}% of Total Candidates`}
                        />
                        <StatCard
                            total={cardData.totalRejected}
                            title="Rejected"
                            desc={`${getPercentage(
                                cardData.totalRejected,
                                cardData.totalCandidate
                            )}% of Total Candidates`}
                        />
                        <StatCard
                            total={cardData.totalJoined}
                            title="Joined"
                            desc={`${getPercentage(
                                cardData.totalJoined,
                                cardData.totalCandidate
                            )}% of Total Candidates`}
                        />
                        <StatCard total={cardData.avgtimeToHire} day={true} title="Avg Time To Hire" />
                    </div>
                </>
            )}
            <Table
                columns={JOBS_STAGE_COLUMNS}
                downloadColumns={downloadColumns}
                filename={`STAGE ANALYSIS_${moment().format('DD-MM-YYYY_h-mm a')}`}
                tableData={tableData}
                loading={tableLoading}
            />
        </>
    )
}

const mapStateToProps = ({ Auth, Job }) => {
    const { allJobs } = Job
    let jobNamesArray = [],
        jobsObject = {},
        initialJob = {}
    allJobs.forEach(job => {
        jobNamesArray.push(job.jobTitle)
        jobsObject[job.jobTitle] = {
            _id: job._id,
            title: job.jobTitle,
            stages: job.stages,
        }
        if (job.status === 1)
            initialJob = {
                _id: job._id,
                title: job.jobTitle,
            }
    })
    return {
        companyId: Auth.user.companyId,
        jobNamesArray,
        jobsObject,
        initialJob,
    }
}

export default connect(
    mapStateToProps,
    {
        GetAnalyticsData,
    }
)(Stage)
