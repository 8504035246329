import React from 'react'

const SVGComponent = () => (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g id="Account-Setting-Page-remove" transform="translate(-1190 -291)" fill="#4767A0">
                <g id="Invites" transform="translate(820 203)">
                    <g id="User1" transform="translate(30 85)">
                        <path
                            d="M351.414214,13 L353.707107,10.7071068 C354.097631,10.3165825 354.097631,9.68341751 353.707107,9.29289322 C353.316582,8.90236893 352.683418,8.90236893 352.292893,9.29289322 L350,11.5857864 L347.707107,9.29289322 C347.316582,8.90236893 346.683418,8.90236893 346.292893,9.29289322 C345.902369,9.68341751 345.902369,10.3165825 346.292893,10.7071068 L348.585786,13 L346.292893,15.2928932 C345.902369,15.6834175 345.902369,16.3165825 346.292893,16.7071068 C346.683418,17.0976311 347.316582,17.0976311 347.707107,16.7071068 L350,14.4142136 L352.292893,16.7071068 C352.683418,17.0976311 353.316582,17.0976311 353.707107,16.7071068 C354.097631,16.3165825 354.097631,15.6834175 353.707107,15.2928932 L351.414214,13 Z M350,23 C344.477153,23 340,18.5228475 340,13 C340,7.4771525 344.477153,3 350,3 C355.522847,3 360,7.4771525 360,13 C360,18.5228475 355.522847,23 350,23 Z"
                            id="Remove-Button"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
