import styled from 'styled-components'

export const Background = styled.div`
    background-image: url('/error.png');
    height: 100vh;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: ${props => (props.startAnimation === true || props.startAnimation === undefined ? 1 : 0)};
    transition: 'opacity 500ms linear 100ms';
`

export const Content = styled.div`
    height: 40%;
    position: absolute;
    bottom: 10%;
    width: 320px;
    justify-content: ${props => (props.isWebform ? 'center' : 'space-between')};
    text-align: center;
    left: 50%;
    padding-right: 5vmin;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Heading = styled.span`
    display: block;
    color: #4a4a4a;
    font-family: Montserrat;
    font-size: 13vmin;
    line-height: 70px;
    /* margin-bottom: 10vh; */
`

export const Desc = styled.span`
    display: block;
    color: #4a4a4a;
    font-family: Montserrat;
    font-size: 18px;
    line-height: 22px;
    width: 90%;
    /* margin-bottom: 10vh; */
`

export const Button = styled.button`
    height: 56px;
    width: 240px;
    background-color: #4767a0;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: none;
    cursor: pointer;
`
