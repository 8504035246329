import React from 'react'
import { ContentWrapper, FormWrapper, ButtonWrapper } from './style'
import './style.css'
import AuthWrapper from '../../Components/SignupWrapper/index.js'
import { Button, Input, Icon, Form } from 'antd'
import EmailIcon from '../../../assets/Icons/Auth/Email'
import PasswordIcon from '../../../assets/Icons/Auth/Password'
import { Signin } from '../../../services/NetworkCalls/Authentication'
import { connect } from 'react-redux'

class SignIn extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            passwordType: true,
        }

        document.title = 'Log In | SpringRecruit'
    }

    signIn = () => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.props.Signin(values, this.props.location)
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form

        return (
            <AuthWrapper>
                <ContentWrapper>
                    <h1 className="header-text">Login to your account</h1>
                    <div className="already-have-an-acco">
                        Don't have an account?
                        <button className="have-account" onClick={() => this.props.history.push('/signup')}>
                            Create New
                        </button>
                    </div>
                    <FormWrapper>
                        <Form className="signup-form">
                            <Form.Item>
                                {getFieldDecorator('email', {
                                    validateTrigger: 'onBlur',
                                    rules: [
                                        { required: true, whitespace: true, message: 'Email is required' },
                                        { type: 'email', message: 'Invalid Email!' },
                                    ],
                                })(
                                    <Input
                                        className="background-colour"
                                        placeholder="Enter Your Email"
                                        suffix={<Icon component={EmailIcon} />}
                                        onPressEnter={this.signIn}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, whitespace: true, message: 'Password is required' }],
                                })(
                                    <Input
                                        className="background-colour"
                                        placeholder="Enter Your Password"
                                        suffix={
                                            <Icon
                                                component={PasswordIcon}
                                                onClick={() =>
                                                    this.setState(state => ({ passwordType: !state.passwordType }))
                                                }
                                            />
                                        }
                                        type={this.state.passwordType ? 'password' : 'text'}
                                        onPressEnter={this.signIn}
                                    />
                                )}
                            </Form.Item>
                        </Form>
                    </FormWrapper>
                    <ButtonWrapper>
                        <h5 className="forgot-password" onClick={() => this.props.history.push('/forgot-password')}>
                            Forgot Password?
                        </h5>
                        <Button className="login-button" size="large" onClick={this.signIn}>
                            Login
                        </Button>
                    </ButtonWrapper>
                </ContentWrapper>
            </AuthWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.Auth.user,
    }
}

export default connect(
    mapStateToProps,
    { Signin }
)(Form.create({ name: 'SignInForm' })(SignIn))
