import React, { useState } from 'react'
import { Header, Footer } from '../common'
import { ContentWrapper } from '../style'
import { Select, AutoComplete, Avatar, Input, Icon } from 'antd'
import { connect } from 'react-redux'
import getAvatar from '../../../services/getAvatar'
import { ReactComponent as ErrorIcon } from '../../../assets/Icons/Access/error.svg'
import './style.scss'

const SOURCED_VIA_OPTIONS = [
    'SpringRecruit Sourcing Initiative',
    'SpringRecruit Interface',
    'Email',
    'Career Page',
    'Hirist',
    'InstaHyre',
    'Others',
]

const filterOption = (input, option) => {
    const { name, email } = option.props
    const nameCheck = name && name.toLowerCase().indexOf(input.toLowerCase()) > -1
    const emailCheck = email && email.toLowerCase().indexOf(input.toLowerCase()) > -1
    return nameCheck || emailCheck
}
const UpdateCandidateSourcing = ({ onConfirm, onCancel, source, teamMembers }) => {
    const [sourcedVia, setSourcedVia] = useState(source.via || 'SpringRecruit Interface')
    const [sourcedBy, setSourcedBy] = useState(source.by)
    const [otherText, setOtherText] = useState(source.via === 'Others' ? source.by : '')
    const [sourcedByError, setSourcedByError] = useState(null)
    const [arrow, setArrow] = useState('down')

    const handleSubmit = () => {
        if (sourcedBy === '') {
            setSourcedByError('Sourced by is required!')
        } else {
            let _sourcedBy = sourcedBy
            if (sourcedVia === 'SpringRecruit Sourcing Initiative') _sourcedBy = 'Sourcing Talent Pool'
            else if (sourcedVia === 'Career Page') _sourcedBy = 'Candidate'
            else if (sourcedVia === 'Others') _sourcedBy = otherText
            setSourcedByError(null)
            onConfirm({ sourcedBy: _sourcedBy, sourcedVia })
        }
    }

    return (
        <>
            <Header title="UPDATE CANDIDATE SOURCING DETAILS" />
            <ContentWrapper mTop={true}>
                <div className="selection-container">
                    <div className="selection-container__sourcedVia">
                        <div className="selection-container__label">Sourced Via</div>
                        <Select
                            className="selection-container__dropdown"
                            getPopupContainer={trigger => trigger.parentNode}
                            size="large"
                            value={sourcedVia}
                            onChange={value => {
                                setSourcedVia(value)
                            }}
                        >
                            {SOURCED_VIA_OPTIONS.map(option => (
                                <Select.Option key={option} value={option}>
                                    {option}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className="selection-container__sourcedBy">
                        <div className="selection-container__label">Sourced By</div>
                        {['SpringRecruit Sourcing Initiative', 'Career Page', 'Referral', 'Others'].includes(
                            sourcedVia
                        ) ? (
                            <>
                                {sourcedVia === 'Referral' ? (
                                    <Input
                                        size="large"
                                        value={`${JSON.parse(sourcedBy).name} (${JSON.parse(sourcedBy).email})`}
                                        disabled={true}
                                    ></Input>
                                ) : sourcedVia === 'Others' ? (
                                    <Input
                                        size="large"
                                        value={otherText}
                                        onChange={e => setOtherText(e.target.value)}
                                        placeholder="Enter Other Source"
                                        type="text"
                                    ></Input>
                                ) : (
                                    <Input
                                        size="large"
                                        value={sourcedVia === 'Career Page' ? 'Candidate' : 'Sourcing Talent Pool'}
                                        disabled={true}
                                    ></Input>
                                )}
                            </>
                        ) : (
                            <AutoComplete
                                value={sourcedBy}
                                size="large"
                                optionLabelProp="title"
                                filterOption={filterOption}
                                onFocus={() => setArrow('up')}
                                onBlur={() => setArrow('down')}
                                onChange={value => {
                                    setSourcedBy(value)
                                    setSourcedByError(value === '' ? 'Sourced by is required!' : null)
                                }}
                                onSelect={() => setArrow('down')}
                                dataSource={teamMembers.map(mem => (
                                    <AutoComplete.Option
                                        key={mem._id}
                                        name={mem.fullName}
                                        email={mem.email}
                                        title={mem.fullName || mem.email}
                                        value={mem._id}
                                    >
                                        <div className="selection-container__sourcedBy__option">
                                            <Avatar
                                                className="selection-container__sourcedBy__option__avatar"
                                                shape="square"
                                            >
                                                {getAvatar(mem.fullName || mem.email)}
                                            </Avatar>
                                            <div className="selection-container__sourcedBy__option__name">
                                                {mem.fullName || mem.email}
                                            </div>
                                        </div>
                                    </AutoComplete.Option>
                                ))}
                            >
                                <Input suffix={<Icon type="down" className={arrow} />} />
                            </AutoComplete>
                        )}
                        {sourcedByError && (
                            <div className="selection-container__sourcedBy--error">
                                <div className="selection-container__sourcedBy--error--icon">
                                    <ErrorIcon />
                                </div>
                                <div className="selection-container__sourcedBy--error--msg">{sourcedByError}</div>
                            </div>
                        )}
                    </div>
                </div>
            </ContentWrapper>
            <Footer
                onCancel={onCancel}
                onConfirm={handleSubmit}
                cancelText="CANCEL"
                confirmText="UPDATE"
                hasSeparator
            />
        </>
    )
}

const mapStateToProps = ({ Mixed }) => {
    return {
        teamMembers: Mixed.teamMembers,
    }
}

export default connect(mapStateToProps)(UpdateCandidateSourcing)
