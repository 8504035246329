import { emailRegExp } from '../Data'
import { phoneValidatorWithoutForm as phoneValidator } from '../../../Helpers/utils'
import moment from 'moment'

export const getErrors = async (errors, data, { type, value }, candidateSettings) => {
    const _errors = { ...errors }
    const { email, name, phone, company, experience, notice, location, lwd } = data
    switch (type) {
        case 'email':
            if (!value) value = email
            if (candidateSettings.email && (!value || value === '')) _errors.email = 'Candidate email is required'
            else if (!emailRegExp.test(value)) _errors.email = 'Candidate email is invalid'
            else _errors.email = null
            break
        case 'name':
            if (!value) value = name
            if (candidateSettings.name && (!value || value === '')) _errors.name = 'Candidate name is required'
            else _errors.name = null
            break
        case 'phone':
            if (!value) value = phone
            if (candidateSettings.phone && !value) _errors.phone = 'Phone number is required'
            else if (value.number) _errors.phone = await phoneValidator(value.cc + value.number)
            else _errors.phone = null
            break
        case 'company':
            if (!value) value = company
            if (candidateSettings.currentCompanyName && !value) _errors.company = 'Current company is required'
            else _errors.company = null
            break
        case 'experience':
            if (!value) value = experience
            if (candidateSettings.totalExperience && !value) _errors.experience = 'Experience is required'
            else _errors.experience = null
            break
        case 'notice':
            if (!value) value = notice
            if (candidateSettings.noticePeriod && !value) _errors.notice = 'Notice Period is required'
            else _errors.notice = null
            break
        case 'location':
            if (!value) value = location
            if (candidateSettings.currentLocation && !value) _errors.location = 'Current location is required'
            else _errors.location = null
            break
        case 'lwd':
            if (!value) value = lwd
            if (notice === 'Serving now' && (value === 'null' || !value)) _errors.lwd = 'Last working day is required'
            else if (notice === 'Serving now' && !moment(value).isValid()) _errors.lwd = 'Last working day is invalid'
            else _errors.lwd = null
            break
        default:
            break
    }
    return _errors
}
