import axios from 'axios'
import config from '../config'
import { store } from '../redux/store'
import actions from '../redux/auth/actions'

const { baseURL, apiVersion } = config.config()

const API_VERSION = apiVersion.v2
const LOGOUT_USER = 'LOG_USER_OUT'

const _instanceBuilder = ({ type = 'open' }) => {
    const headers = {
        'Content-Type': 'application/json',
    }

    const instance = axios.create({
        baseURL: type === 'open' ? `${baseURL}${API_VERSION}` : `${baseURL}${API_VERSION}me`, //restricted route,
        headers,
    })

    instance.interceptors.request.use(
        function(config) {
            if (type === 'restricted') {
                config.headers.Authorization = store.getState().Auth.idToken
            }
            return config
        },
        function(err) {
            return
        }
    )

    instance.interceptors.response.use(null, function(error) {
        if (error && error.response && 401 === error.response.status) {
            if (error.response.data && error.response.data.payload && error.response.data.action === LOGOUT_USER) {
                logUserOut()
            }
        }
        return Promise.reject(error)
    })
    return instance
}

const restrictedCaller = _instanceBuilder({ type: 'restricted' })
const openCaller = _instanceBuilder({ type: 'open' })

const axiosWrapper = openCaller

const fetchWrapper = async function(url, options) {
    try {
        const res = await fetch(url, options)
        let body

        try {
            body = await res.json()
        } catch (error) {
            // * In case of no response body, res.json() fails in some browsers, in such cases return {}
            body = {}
        }

        if (res.status === 401) {
            //Incase an unauthorized access was attempted
            let { payload } = body
            if (payload && payload.action === LOGOUT_USER) {
                logUserOut()
            }
        }

        return { body, res }
    } catch (error) {
        return Promise.reject(error)
    }
}

const logUserOut = () => {
    const state = store.getState()
    const userId = state.Auth && state.Auth.user ? state.Auth.user.id : null
    store.dispatch(actions.logout(userId))
}

const formatError = error => {
    return (
        (error.response && error.response.data && error.response.data.error && error.response.data.error.msg) ||
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error
    )
}

const wrappers = { axios: axiosWrapper, fetch: fetchWrapper }
const type = { restricted: restrictedCaller, open: openCaller }
export default { apiCaller: restrictedCaller, wrappers, type, formatError } //Since a majority of the calls on the platform are restricted
