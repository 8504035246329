import styled from 'styled-components'

export const Content = styled.div`
    width: 40vw;
    @media only screen and (max-width: 995px) {
        width: 100%;
    }
`
export const Body = styled.div`
    background-color: #eeeeee;
    padding: 10px 20px;
    border-top: 1px solid #d8d8d8;
`
export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    width: 100%;
    border-radius: 5px 5px 0 0;
    background-color: #eeeeee;
    padding: 15px 20px;
    span {
        font-family: sanFranciscoMedium;
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        text-transform: uppercase;
    }
`
export const Add = styled.a`
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #4767a0;
    svg {
        margin-right: 5px;
    }
    :hover svg path {
        fill: #40a9ff;
    }
`
export const Btn = styled.button`
    width: 110px;
    height: 40px;
    border-radius: 4px;
    border: ${props => (props.primary ? 'none' : '1px solid #4767a0')};
    background-color: ${props => (props.primary ? '#4767a0' : '#fff')};
    color: ${props => (props.primary ? '#fff' : '#4767a0')};
    margin-left: ${props => (props.save ? '0' : '10px')};
    text-transform: uppercase;
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    outline-color: darkorange;
`
export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`
export const Empty = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 20px 0;
    svg {
        width: 79px;
        height: 79px;
    }
    span {
        font-size: 12px;
        text-align: center;
        color: #333333;
    }
`
export const List = styled.ul`
    background: #fff;
    border: 1px solid #d8d8d8;
    list-style: none;
    padding-left: 0;
    max-height: 300px;
    overflow: auto;
`
export const Item = styled.li`
    padding: 20px;
    .title {
        display: flex;
        justify-content: space-between;
        p {
            font-family: sanFranciscoMedium;
            font-size: 12px;
            font-weight: bold;
            color: #333333;
            text-transform: uppercase;
        }
    }
    p {
        font-size: 12px;
        color: #666666;
        margin-bottom: 0.3em;
        white-space: pre-line;
    }
    :hover {
        background: #ccdbf870;
        cursor: pointer;
    }
`
export const Icons = styled.div`
    display: flex;
    svg {
        margin-left: 20px;
    }
`
