import React, { Component } from 'react'
import Router from './router'

export default class Dashboard extends Component {
    render() {
        const { url } = this.props.match

        return (
            <div>
                <Router url={url} {...this.props} allowSwitchTabs={this.props.allowSwitchTabs} />
            </div>
        )
    }
}