import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" height="15" width="15">
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
                <path
                    fill="#FFF"
                    d="M7.5 0C3.375 0 0 3.375 0 7.5S3.375 15 7.5 15 15 11.625 15 7.5 11.625 0 7.5 0z"
                    transform="translate(-1211 -124) translate(1139 112) translate(72 12)"
                />
                <path
                    fill="#4767A0"
                    d="M11.625 5.813a.604.604 0 00-.875 0L7.5 9.063l-3.25-3.25a.604.604 0 00-.875 0 .604.604 0 000 .875l3.688 3.687c.25.25.625.25.875 0l3.687-3.688c.25-.187.25-.625 0-.875z"
                    transform="translate(-1211 -124) translate(1139 112) translate(72 12)"
                />
            </g>
        </svg>
    )
}

export default Icon
