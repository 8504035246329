import React from 'react'
import { Block, Heading } from '../../Sourcing/style'
import { Radio } from 'antd'
import { FILTER_TYPES } from './constants'

const { Group: RadioGroup } = Radio

const FilterBox = ({ onFilterSelected, mainFilter, filterCount }) => {
    const onFilterChanged = ({ target: { value } }) => onFilterSelected(value)
    return (
        <Block>
            <Heading>Filter by</Heading>
            <RadioGroup onChange={onFilterChanged} value={mainFilter}>
                <div>
                    <Radio value={FILTER_TYPES.ALL}>All Candidates</Radio>
                    <span className="count">{filterCount[FILTER_TYPES.ALL]}</span>
                </div>
                <div>
                    <Radio value={FILTER_TYPES.ADDED}>Added by me</Radio>
                    <span className="count">{filterCount[FILTER_TYPES.ADDED]}</span>
                </div>
                <div>
                    <Radio value={FILTER_TYPES.ASSIGNED}>Assigned to me</Radio>
                    <span className="count">{filterCount[FILTER_TYPES.ASSIGNED]}</span>
                </div>
            </RadioGroup>
        </Block>
    )
}

export default FilterBox
