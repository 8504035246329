import React, { useState } from 'react'
import { SingleFBCard, NoteBox, ScoreBox, OpinionEditorBox } from './style'
import { F_OVERALL_COLLECTION } from '../../Helpers/constants'
import { Icon, Input, Menu, Popover, Rate, Tooltip } from 'antd'
import StarIcon from '../../../assets/Icons/Talent Pool/Star'
import ConfirmDeleteCard from './ConfirmDeleteCard'
import { ReactComponent as Bin } from '../../../assets/Icons/Feedback/bin.svg'
import { ReactComponent as Edit } from '../../../assets/Icons/Feedback/edit.svg'
import { ReactComponent as ThreeDots } from '../../../assets/Icons/Dashboard/threeDots.svg'
import { FeedbackOpinionEditor } from '../common'
import RemoveIcon from '../../../assets/Icons/Todo/Remove Button'
import SaveIcon from '../../../assets/Icons/Todo/Tick'
import { humaniseMoment } from '../../Helpers/utils'
import Paragraph from './../../Components/StyledParagraph'
import Configs from '../../../../src/config'
const { isMobileDevice } = Configs

const HandleCardActions = ({
    isEditMode,
    closeEditChanges,
    saveFeedbackHandler,
    isAddedByMe,
    feedbackEditingId,
    deleteModeOn,
    editModeOn,
}) => {
    if (isEditMode && !isMobileDevice)
        return (
            <div className="edit-icon-container">
                <Tooltip title="Click to remove changes" arrowPointAtCenter placement="topRight" mouseEnterDelay={1}>
                    <Icon className="edit-icon-container-image" onClick={closeEditChanges} component={RemoveIcon} />
                </Tooltip>
                <Tooltip title="Click to save changes" arrowPointAtCenter placement="topRight" mouseEnterDelay={1}>
                    <Icon className="edit-icon-container-image" onClick={saveFeedbackHandler} component={SaveIcon} />
                </Tooltip>
            </div>
        )
    else if (isAddedByMe && !feedbackEditingId) {
        // mobile view has three dots for delete and edit action and it always visible

        if (isMobileDevice) {
            return (
                <Popover
                    placement="bottomRight"
                    arrowPointAtCenter
                    content={
                        <Menu>
                            <Menu.Item onClick={editModeOn}>Edit</Menu.Item>
                            <Menu.Item onClick={deleteModeOn}>Delete</Menu.Item>
                        </Menu>
                    }
                >
                    <div className="card-actions-block">
                        <ThreeDots />
                    </div>
                </Popover>
            )
        }

        return (
            <div className="card-section-delete-edit">
                <Icon className="card-section-delete-edit-bin" component={Bin} onClick={deleteModeOn} />
                <Icon className="card-section-delete-edit-pen" component={Edit} onClick={editModeOn} />
            </div>
        )
    }

    return null
}

const SingleFeedback = ({
    feedbackEditingId,
    setFeedbackEditingId,
    saveEditChanges,
    deleteFeedback,
    data: { id, feedback: _feedback, addedByLabel, isAddedByMe, index, jobTitle, stageName, createdAt },
}) => {
    const isEditMode = feedbackEditingId === id

    /*  APPROACH:
     we make a copy of the prop for each card, when the edit mode is clicked,
     we mutate the local copy and in the end will submit
     */

    const [feedback, setFeedback] = useState(_feedback)
    const [isDeleteMode, setIsDeleteMode] = useState(false)

    /* Generic data change handler */
    const updateFeedback = data => setFeedback({ ...feedback, ...data })
    const updateCustomSkill = (ans, index) => {
        const _clone_customSkill = feedback.customFields.map((each, i) => {
            if (i === index) return { ...each, ans }
            return each
        })

        setFeedback({ ...feedback, customFields: _clone_customSkill })
    }

    const saveFeedbackHandler = () => saveEditChanges(feedback)

    const editModeOn = () => setFeedbackEditingId(id)
    const closeEditChanges = () => {
        // reset the props value to the local value
        setFeedback(_feedback)
        setFeedbackEditingId(null)
    }

    /* Delete Feedback functions */
    const deleteFeedbackHandler = () => deleteFeedback(index)
    const deleteModeOn = () => setIsDeleteMode(true)
    const deleteModeOff = () => setIsDeleteMode(false)

    const { activeIcon, text } = F_OVERALL_COLLECTION[feedback.overallPoints - 1] || {}

    if (isDeleteMode)
        return (
            <ConfirmDeleteCard
                onDelete={deleteFeedbackHandler}
                onCancel={deleteModeOff}
                confirmMessage={
                    <>
                        Are you sure you want to delete this feedback? <br />
                        This action can not be undone
                    </>
                }
            />
        )

    return (
        <SingleFBCard>
            <div className="added-by-container">
                <div className="text-container">
                    <div className="text-container-user">{`${index + 1}. From ${addedByLabel}`}</div>
                    <div>&nbsp;/&nbsp;{humaniseMoment(createdAt)}</div>
                </div>
                <HandleCardActions
                    isEditMode={isEditMode}
                    closeEditChanges={closeEditChanges}
                    saveFeedbackHandler={saveFeedbackHandler}
                    isAddedByMe={isAddedByMe}
                    feedbackEditingId={feedbackEditingId}
                    deleteModeOn={deleteModeOn}
                    editModeOn={editModeOn}
                />
            </div>

            <div className="job-container" hidden={!stageName && !jobTitle}>
                <div className="job-container-stage">{stageName}</div>
                {/* Side case handling if jobTitle is empty then '|' should not come*/}
                <div hidden={!jobTitle}>&nbsp;|&nbsp;</div>
                <div className="job-container-job">{jobTitle}</div>
            </div>

            <div className="card-actions-block_save-cancel" hidden={!(isMobileDevice && isEditMode)}>
                <span onClick={saveFeedbackHandler}>SAVE</span>
                &nbsp;&nbsp;&nbsp;
                <span onClick={closeEditChanges}>CANCEL</span>
            </div>

            <NoteBox>
                <div className="heading">Overall Feedback</div>
                {isEditMode ? (
                    <Input.TextArea
                        placeholder="Enter your feedback here"
                        rows={3}
                        value={feedback.overallFeedback}
                        onChange={({ target: { value } }) => updateFeedback({ overallFeedback: value })}
                    />
                ) : (
                    <Paragraph>{feedback.overallFeedback}</Paragraph>
                )}
            </NoteBox>

            {isMobileDevice ? <p className="heading">Your rating based on these skills {isEditMode && '*'}</p> : null}

            <div className="overall-opinion" hidden={!(isMobileDevice && !isEditMode)}>
                <Icon className="overall-opinion-image" component={activeIcon} />
                <div className="overall-opinion-text">{text}</div>
            </div>

            <ScoreBox>
                <div className="overall-opinion" hidden={isMobileDevice || isEditMode}>
                    <Icon className="overall-opinion-image" component={activeIcon} />
                    <div className="overall-opinion-text">{text}</div>
                </div>
                <div className="rating">
                    <div className="rating-item" hidden={!feedback.communication}>
                        <div className="rating-item-title">Communication</div>
                        <Rate
                            character={<Icon component={StarIcon} />}
                            className="feedback-star"
                            disabled={!isEditMode}
                            value={feedback.communication}
                            onChange={value => updateFeedback({ communication: value })}
                        />
                    </div>

                    <div className="rating-item" hidden={!feedback.attitude}>
                        <div className="rating-item-title">Attitude</div>
                        <Rate
                            character={<Icon component={StarIcon} />}
                            className="feedback-star"
                            disabled={!isEditMode}
                            value={feedback.attitude}
                            onChange={value => updateFeedback({ attitude: value })}
                        />
                    </div>

                    <div className="rating-item" hidden={!feedback.learningPotential}>
                        <div className="rating-item-title">Potential To Learn</div>
                        <Rate
                            character={<Icon component={StarIcon} />}
                            className="feedback-star"
                            disabled={!isEditMode}
                            value={feedback.learningPotential}
                            onChange={value => updateFeedback({ learningPotential: value })}
                        />
                    </div>

                    <div className="rating-item" hidden={!feedback.technicalSkills}>
                        <div className="rating-item-title">Technical Skills</div>
                        <Rate
                            character={<Icon component={StarIcon} />}
                            className="feedback-star"
                            disabled={!isEditMode}
                            value={feedback.technicalSkills}
                            onChange={value => updateFeedback({ technicalSkills: value })}
                        />
                    </div>

                    {feedback.customFields.map((skill, index) => (
                        <div className="rating-item" hidden={!skill.ans} key={skill._id}>
                            <div className="rating-item-title">{skill.name}</div>
                            <Rate
                                character={<Icon component={StarIcon} />}
                                className="feedback-star"
                                disabled={!isEditMode}
                                value={skill.ans}
                                onChange={value => updateCustomSkill(value, index)}
                            />
                        </div>
                    ))}
                </div>
            </ScoreBox>

            {isMobileDevice && isEditMode ? <p className="heading">Your overall opinion on the candidate*</p> : null}

            <OpinionEditorBox hidden={!isEditMode}>
                <FeedbackOpinionEditor
                    points={feedback.overallPoints}
                    showText={isMobileDevice}
                    showPopUp
                    onChange={value => updateFeedback({ overallPoints: value })}
                />
            </OpinionEditorBox>
        </SingleFBCard>
    )
}

export default SingleFeedback
