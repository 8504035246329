import React, { useState, useEffect } from 'react'
import { Aside, Block, Heading } from '../style'
import { Radio } from 'antd'
import { FILTER_TYPES } from '../constants'

const { Group: RadioGroup } = Radio

/** Presentational Component **/

const FilterBox = ({ onFilterSelected, filter, relocate, countData }) => {
    const onFilterChanged = ({ target: { value } }) => onFilterSelected({ type: 'filter', data: value })
    const onRelocateChanged = ({ target: { value } }) => onFilterSelected({ type: 'relocate', data: value })
    return (
        <>
            <Block>
                <RadioGroup onChange={onFilterChanged} value={filter}>
                    <div>
                        <Radio value={FILTER_TYPES.ALL}>All Candidates</Radio>
                        <span className="count">{countData.all}</span>
                    </div>
                    <div>
                        <Radio value={FILTER_TYPES.ASSIGNED}>Assigned to Job</Radio>
                        <span className="count">{countData.assignedJob}</span>
                    </div>
                    <div>
                        <Radio value={FILTER_TYPES.UNASSIGNED}>Unassigned to Job</Radio>
                        <span className="count">{countData.unassignedJob || 0}</span>
                    </div>
                    <div>
                        <Radio value={FILTER_TYPES.FAVOURITE}>Marked Favourite</Radio>
                        <span className="count">{countData.favourite || 0}</span>
                    </div>
                    <div>
                        <Radio value={FILTER_TYPES.HIDDEN}>Hidden Profiles</Radio>
                        <span className="count">{countData.hidden || 0}</span>
                    </div>
                </RadioGroup>
            </Block>
            <Block>
                <Heading>
                    Willing to relocate
                    <span
                        className="clear"
                        hidden={relocate == null}
                        onClick={() => onRelocateChanged({ target: { value: null } })}
                    >
                        Clear
                    </span>
                </Heading>
                <RadioGroup onChange={onRelocateChanged} value={relocate}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </RadioGroup>
            </Block>
        </>
    )
}

export default FilterBox
