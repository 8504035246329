import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { store } from '../../../redux/store'

import Opening from '../../Pages/Openings'
import Board from '../../Pages/Board'
import CandidateList from '../../Pages/CandidatesList'
import CreateNewOpening from '../../Pages/CreateNewOpening'
import { connect } from 'react-redux'

const routes = [
    {
        path: '',
        render: props => <Opening {...props} />,
        accessLevel: [1, 2, 3, 4],
    },
    {
        path: 'board/:jobId',
        render: props => <Board {...props} />,
        accessLevel: [1, 2, 3, 4],
    },
    {
        path: 'list/:jobId',
        render: props => <CandidateList {...props} />,
        accessLevel: [1, 2, 3, 4],
    },
    {
        path: 'createJob',
        render: props => <CreateNewOpening {...props} />,
        accessLevel: [1, 2],
        exact: true,
    },
    // {
    //   path: 'applicationForm',
    //   component: CreateNewOpening,
    // },
    // {
    //   path: 'promote',
    //   component: CreateNewOpening,
    // },
    {
        path: 'edit/:jobId',
        render: props => <CreateNewOpening {...props} />,
        accessLevel: [1, 2, 3, 4],
    },
    {
        path: 'clone/:jobId',
        render: props => <CreateNewOpening {...props} />,
        accessLevel: [1, 2, 3, 4],
    },
]

class OpeningRouter extends Component {
    render() {
        const { url, style, allowSwitchTabs, __redux } = this.props
        const { accessLevel } = store.getState().Auth.user

        return (
            <div style={style}>
                <Switch>
                    {routes
                        .filter(singleRoute => singleRoute.accessLevel.includes(accessLevel))
                        .map(singleRoute => {
                            const { path, exact = true, render, ...otherProps } = singleRoute
                            return (
                                <Route
                                    exact={exact}
                                    key={singleRoute.path}
                                    render={
                                        render ? props => render({ ...props, allowSwitchTabs, __redux }) : undefined
                                    }
                                    path={`${url}/${singleRoute.path}`}
                                    {...otherProps}
                                />
                            )
                        })}
                    <Redirect path="*" to="/error" />
                </Switch>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { __redux: state }
}

export default connect(mapStateToProps)(OpeningRouter)
