import React from 'react'
import { Drawer } from 'antd'
import DrawerHeader from './DrawerHeader'
// import Carrer from '@springrole/springrecruit-career-page'

const DIMENSIONS = {
    WIDTH: '100%',
    HEIGHT: '50px',
}

const Preview = ({ onClose, visible, payload }) => {
    return (
        <Drawer
            placement="left"
            visible={visible}
            destroyOnClose={true}
            closable={false}
            width={DIMENSIONS.WIDTH}
            bodyStyle={{ padding: 0 }}
        >
            <DrawerHeader title="Career Page Preview" height={DIMENSIONS.HEIGHT} onClose={onClose} />
            <div style={{ paddingTop: DIMENSIONS.HEIGHT }}>
                {/* <Carrer hasData={true} isApp={false} data={payload} /> */}
            </div>
        </Drawer>
    )
}

export default Preview
