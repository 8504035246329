import React from 'react'

const SVGComponent = () => (
    <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M17.3333333,1.98707499 C17.3334515,1.9951853 17.3334513,2.00329242 17.3333333,2.01139365 L17.3333333,12.4166667 C17.3333333,13.1070226 16.7736893,13.6666667 16.0833333,13.6666667 L1.91666667,13.6666667 C1.22631073,13.6666667 0.666666667,13.1070226 0.666666667,12.4166667 L0.666666667,2.01139365 C0.666548675,2.00329242 0.666548495,1.9951853 0.666666666,1.98707499 L0.666666667,1.58333333 C0.666666667,0.892977396 1.22631073,0.333333333 1.91666667,0.333333333 L16.0833333,0.333333333 C16.7736893,0.333333333 17.3333333,0.892977396 17.3333333,1.58333333 L17.3333333,1.98707495 Z M3.00231303,2 L9,5.99845798 L14.997687,2 L3.00231303,2 Z M15.6666667,3.55709758 L9.46225016,7.69337525 C9.18233399,7.87998603 8.81766601,7.87998603 8.53774984,7.69337525 L2.33333333,3.55709758 L2.33333333,12 L15.6666667,12 L15.6666667,3.55709758 Z"
                id="path-1-email"
            />
        </defs>
        <g id="Product-Flow-email" fill="none" fillRule="evenodd">
            <g id="Sign-Up-email" transform="translate(-761 -290)">
                <g transform="translate(488 273)" id="Text-Fields">
                    <g id="Email">
                        <g id="Icon-email" transform="translate(273 17)">
                            <mask id="mask-2-email" fill="#fff">
                                <use xlinkHref="#path-1-email" />
                            </mask>
                            <use
                                id="Mask"
                                fillOpacity="0"
                                fill="#051033"
                                fillRule="nonzero"
                                xlinkHref="#path-1-email"
                            />
                            <g id="Icon-Colour" mask="url(#mask-2-email)" fill="#C9CED6">
                                <polygon
                                    points="0 0 20 0 20 20 0 20"
                                    transform="translate(-1 -3)"
                                    id="Grey-/-Grey-Base-500"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
