export const DEFAULT_SKILLS = [
    {
        name: 'Communication',
        description: '',
        required: true,
    },
    {
        name: 'Attitude',
        description: '',
        required: true,
    },
    {
        name: 'Potential To Learn',
        description: '',
        required: true,
    },
    {
        name: 'Technical Skills',
        description: '',
        required: true,
    },
]

export const EDIT_TYPES = {
    ADD: 'ADD',
    EDIT: 'EDIT',
}

export const INPUT_LIMITS = {
    name: 30,
    description: 70,
}
