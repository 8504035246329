import React from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import { ConfirmGoogleDisconnect } from './ConfirmGoogleDisconnect'
import { ArchivedOpeningNote } from './ArchivedOpeningNote'
import { ConfirmDisableTeamMember } from './ConfirmDisableTeamMember'
import { MODAL_TYPES, FSMODAL_TYPES } from '../Helpers/ModalTypes'
import { ConfirmRejectCandidate } from './ConfirmRejectCandidate'
import DownloadReport from './DownloadReport'
import { ConfirmDeleteCandidate } from './ConfirmDeleteCandidate'
import { ConfirmDeleteJob } from './ConfirmDeleteJob'
import CalendarOnBoarding from './CalendarOnBoarding'
import ViewFeedbacks from './ViewFeedbacks'
import InviteTeamMember from './InviteTeamMember'
import { AddStage } from './AddNewStage'
import { WarningDeleteStage } from './WarningDeleteStage'
import SendEmail from './SendEmail'
import ChangePassword from './ChangePassword'
import UpdateCandidate from './UpdateCandidate'
import { ConfirmDeleteStage } from './ConfirmDeleteStage'
import { ConfirmJoinCandidate } from './ConfirmJoinCandidate'
import UnarchiveCandidate from './UnarchiveCandidate'
import AddFeedback from './AddFeedback'
import CustomizeFeedback from './CustomizeFeedback'
import AddNotes from './AddNotes'
import { WarningAccountAlreadyExists } from './WarningAccountAlreadyExists'
import ConfirmArchiveOpeningAlert from './ConfirmArchiveOpeningAlert'
import ConfirmCloneJobAlert from './ConfirmCloneJobAlert'
import WarningOpeningArchived from './WarningOpeningArchived'
import WarningCandidateEmailMissing from './WarningCandidateEmailMissing'
import WarningHangoutsUnavailable from './WarningHangoutsUnavailable'
import WarningDeleteSection from './WarningDeleteSection'
import AssociateOpeningNote from './AssociateOpeningNote'
import UpdateCandidateSourcing from './UpdateCandidateSourcing'
import WarningDoSelect from './WarningDoSelect'
import ArchiveReason from './ArchiveReason'
import AddCandidateDetails from './AddCandidateDetails'
import AssignTest from './AssignTest'
import AddCandidate from './AddCandidate'
import SelectJob from './SelectJob'
import SuccessWebForm from './SuccessWebForm'
import CandidateSourcing from './CandidateSourcing'
import ManageNotifications from './ManageNotifications'
import { ConfirmSourcingUnsubscribe } from './ConfirmSourcingUnsubscribe'
import AddTag from './AddTag'
import ManageTag from './ManageTag'
import './style.scss'
import config from '../../config'
const { isMobileDevice } = config
//All popup components will be imported only in this file.

//This converts the string to components.
const Components = {
    ChangePassword,
    ConfirmGoogleDisconnect,
    ArchivedOpeningNote,
    ConfirmDisableTeamMember,
    CalendarOnBoarding,
    CandidateSourcing,
    AddStage,
    WarningDeleteStage,
    ConfirmRejectCandidate,
    ConfirmDeleteCandidate,
    ConfirmDeleteJob,
    SendEmail,
    InviteTeamMember,
    UpdateCandidate,
    ViewFeedbacks,
    ConfirmJoinCandidate,
    UnarchiveCandidate,
    ConfirmDeleteStage,
    AddFeedback,
    DownloadReport,
    CustomizeFeedback,
    AddNotes,
    WarningAccountAlreadyExists,
    ConfirmArchiveOpeningAlert,
    ConfirmCloneJobAlert,
    WarningOpeningArchived,
    WarningCandidateEmailMissing,
    WarningHangoutsUnavailable,
    WarningDeleteSection,
    AssociateOpeningNote,
    UpdateCandidateSourcing,
    WarningDoSelect,
    ArchiveReason,
    AddCandidateDetails,
    AssignTest,
    AddCandidate,
    SelectJob,
    ManageNotifications,
    ConfirmSourcingUnsubscribe,
    SuccessWebForm,
    AddTag,
    ManageTag,
}

const MyModal = props => {
    const ModalComponent = Components[props.type]
    return (
        <Modal
            visible={props.visible}
            footer={null}
            closable={props.closable}
            width={props.width}
            bodyStyle={{ padding: isMobileDevice ? '20px' : '25px', ...props.bodyStyle }}
            centered
            wrapClassName={props.wrapClassName}
            destroyOnClose
            maskClosable
            onCancel={props.onCancel}
        >
            <ModalComponent {...props} isMobileDevice={isMobileDevice} />
        </Modal>
    )
}

MyModal.propTypes = {
    /** Component name as string. Saves importing the component in calling file.*/
    type: PropTypes.oneOf(Object.values(MODAL_TYPES)).isRequired,
    /** On Confirm function */
    onConfirm: PropTypes.func,
    /** On Cancel function */
    onCancel: PropTypes.func,
    /** Visibility of the modal to be controlled by the calling file */
    visible: PropTypes.bool.isRequired,
    /** Width of the modal */
    width: PropTypes.number,
    /** Body Style of the modal */
    bodyStyle: PropTypes.object,
    /** Show the close icon at the top right or not */
    closable: PropTypes.bool,
}

MyModal.defaultProps = {
    width: isMobileDevice ? '100%' : 600,
    closable: false,
}

const FullScreenComponents = {
    InviteTeamMember,
}

const FullScreenModal = props => {
    const ModalComponent = FullScreenComponents[props.type]
    return (
        <Modal
            visible={props.visible}
            footer={null}
            closable={false}
            centered
            width={'100%'}
            style={{ paddingBottom: 0 }}
            bodyStyle={{ width: '100%', minHeight: '100vh', padding: 0, background: '#f7f8fc' }}
            destroyOnClose
            maskClosable
            onCancel={props.onCancel}
            className="fs-modal"
        >
            <ModalComponent {...props} isMobileDevice={isMobileDevice} />
        </Modal>
    )
}

FullScreenModal.propTypes = {
    /** Component name as string. Saves importing the component in calling file.*/
    type: PropTypes.oneOf(Object.values(FSMODAL_TYPES)).isRequired,
    /** Visibility of the modal to be controlled by the calling file */
    visible: PropTypes.bool.isRequired,
}

export { MyModal as Modal, FullScreenModal as FSModal }
