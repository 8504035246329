import React, { useState } from 'react'
import { Header, Footer } from '../common'
import { ContentWrapper } from '../style'
import './style.scss'
import { Checkbox } from 'antd'
import { StyledButton as Button } from '../../Components'

const ConfirmArchiveOpeningAlert = ({ onCancel, onConfirm, redirect, activeCandidates }) => {
    const [enable, setEnable] = useState(false)
    const [sendEmailToCandidate, setSendEmailToCandidate] = useState(true)
    return (
        <>
            <Header title="ALERT" />
            <ContentWrapper mTop={true}>
                <div className="message">
                    There are {activeCandidates} active candidates in various stages of the job
                </div>
                <div className="action-container">
                    <div className="action-container-box">
                        <div className="action-container-box-redirect">
                            <div className="action-container-box-redirect-msg">
                                Please archive / reject the candidates in the job opening
                            </div>
                            <Button
                                className="action-container-box-button"
                                width={'150px'}
                                height={'35px'}
                                onClick={redirect}
                            >
                                VIEW CANDIDATES
                            </Button>
                        </div>
                    </div>
                    <div className="action-container-or">OR</div>
                    <div className="action-container-box">
                        <div className="action-container-box-archive">
                            <div className="action-container-box-archive-checkbox">
                                <Checkbox onChange={() => setEnable(!enable)}>
                                    Archive all candidates and close this opening
                                </Checkbox>
                            </div>
                            {enable ? (
                                <div className="action-container-box-archive-checkbox">
                                    <Checkbox
                                        checked={sendEmailToCandidate}
                                        onChange={() => setSendEmailToCandidate(!sendEmailToCandidate)}
                                    >
                                        Send an email to the candidates for archival
                                    </Checkbox>
                                </div>
                            ) : null}
                            <Button
                                className="action-container-box-button"
                                width={'130px'}
                                height={'35px'}
                                type={!enable ? 'inverted' : ''}
                                disable={!enable}
                                onClick={() => onConfirm(sendEmailToCandidate)}
                            >
                                ARCHIVE OPENING
                            </Button>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
            <Footer hasSeparator onCancel={onCancel} cancelText="Cancel" />
        </>
    )
}

export default ConfirmArchiveOpeningAlert
