const actions = {
    CLEAR_CANDIDATES: 'CLEAR_CANDIDATES',
    ADD_CANDIDATES: 'ADD_CANDIDATES',
    UPDATE_CANDIDATE: 'UPDATE_CANDIDATE',
    REMOVE_CANDIDATE: 'REMOVE_CANDIDATE',
    PUSH_UP_CANDIDATE: 'PUSH_UP_CANDIDATE',
    REORDER_LIST: 'REORDER_LIST',
    EMIT_DUPLICATE_ERROR: 'EMIT_DUPLICATE_ERROR',
    EMIT_ERROR: 'EMIT_ERROR',
}
export default actions
