import { success, warning } from '../../../services/Notification'
import apiCaller from '../../apiCaller'
import { store } from '../../../redux/store'
import config from '../../../config'
import { LogE } from '../../../container/Helpers/errorHandler'
import mixedActions from '../../../redux/mixed/actions'
import authActions from '../../../redux/auth/actions'
const axios = apiCaller.wrappers.axios
const { baseURL, apiVersion } = config.config()
const { restricted } = apiCaller.type
const { formatError } = apiCaller
const baseUrl = 'user/'

export const ResendUserInvite = (userId, email, companyId) => async dispatch => {
    try {
        const { data } = await restricted.post(`${baseUrl}inviteResend/${companyId}`, { _id: userId, email })
        success(data.message)
        return true
    } catch (error) {
        warning(formatError(error))
    }
}

// backend v1
export const AddTeamMember = input => async dispatch => {
    try {
        const url = baseURL + apiVersion.v1 + 'user'
        const result = await axios.post(url, input, {
            headers: {
                Authorization: store.getState().Auth.idToken,
                'Content-Type': 'application/json',
            },
        })

        if (result.data && result.data.error) {
            LogE('NetworkCalls-AddTeamMember-api-error', 'Failed to invite team member', result)
            warning('Failed to invite team member')
            return null
        } else {
            success('Invite sent successfully')
            dispatch({
                type: mixedActions.ADD_TEAM_MEMBER,
                payload: result.data.user,
            })
        }
        return result
    } catch (err) {
        LogE('NetworkCalls-AddTeamMember-try-body-error', 'Failed to invite team member', err)
        warning((err.response && err.response.data && err.response.data.error) || 'Something went wrong')
    }
}

// backend v1
export const UpdateTeamMember = (input, teamMemberId) => async dispatch => {
    try {
        const url = `${baseURL}${apiVersion.v1}user/${teamMemberId}`
        const { data } = await axios.patch(url, input, {
            headers: {
                Authorization: store.getState().Auth.idToken,
                'Content-Type': 'application/json',
            },
        })

        success('Team member updated successfully')
        dispatch({
            type: mixedActions.UPDATE_TEAM_MEMBER,
            payload: { _id: teamMemberId, ...input, ...data },
        })
        return data
    } catch (err) {
        LogE('NetworkCalls-UpdateTeamMember-catch', 'Failed to update team member', err)
        warning('Failed to update team member.')
    }
}

// backend v1
export const UpdateUserDetails = input => async dispatch => {
    try {
        const {
            user: { companyId },
            idToken,
        } = store.getState().Auth
        const url = `${baseURL}${apiVersion.v1}company/${companyId}`
        const result = await axios.patch(url, input, {
            headers: {
                Authorization: idToken,
                'Content-Type': 'application/json',
            },
        })

        if (result.errors) {
            LogE('NetworkCalls-AddTeamMember-api-error', 'Failed to update', result)
            warning('Failed to update details')
        } else {
            success('Details updated successfully')
            dispatch({ type: authActions.UPDATE_USER_DETAILS, payload: input })
        }
    } catch (err) {
        LogE('NetworkCalls-UpdateUserDetails-catch', 'Failed to update details', err)
        return null
    }
}

// backend v1
export const RemoveTeamMember = userId => async dispatch => {
    try {
        const url = baseURL + apiVersion.v1 + 'disableUser'
        const result = await axios.patch(
            url,
            { userId },
            {
                headers: {
                    Authorization: store.getState().Auth.idToken,
                    'Content-Type': 'application/json',
                },
            }
        )

        if (result.errors) {
            LogE('NetworkCalls-RemoveTeamMember-api-error', 'Failed to remove', result)
            warning('Failed to remove Team Member')
        } else {
            success('User disabled successfully')
            dispatch({ type: mixedActions.UPDATE_TEAM_MEMBER, payload: { _id: userId, status: 2 } })
        }
    } catch (err) {
        LogE('NetworkCalls-RemoveTeamMember-catch', 'Failed to remove', err)
        throw err
    }
}

export const UpdateEmailNotifications = data => async dispatch => {
    try {
        const res = await restricted.post(`${baseUrl}settings/email_notifications`, data)

        if (res.error) {
            LogE('NetworkCalls-UpdateEmailNotifications-api-error', 'Failed to update', res)
            warning(res.error.msg || 'Failed to update user settings')
        } else {
            success('User settings updated successfully')
        }
    } catch (err) {
        warning(formatError(err))
    }
}
