import React, { useState } from 'react'
import { Input, Select, Spin, Popover } from 'antd'
import { Field } from '../style'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CANDIDATE } from '../../../Helpers/RegEx'
import { StyledDropzoneMini } from '../../../Components/StyledDropzone'
import StyledPhoneNumberInput from '../../../Components/StyledPhoneNumberInput'
import { formatFileName, phoneValidatorWithoutForm as phoneValidator } from '../../../Helpers/utils'
import { ReactComponent as CloseIcon } from '../../../../assets/Icons/Access/close.svg'
import { ALLOWED_FILE_FORMATS, SingleFileTypeValidation } from '../../../Helpers/fileTypeValidation'
import { GetResumeDetails } from '../../../../services/NetworkCalls/Candidate'
import { warning } from '../../../../services/Notification'
import { Tags } from '../../style'
import { FILE_STATUS } from '../../../Helpers/constants'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { GetCandidatesPhoneNumber } from '../../../../services/api'
import { GetCandidatesEmail } from '../../../../services/NetworkCalls/Candidate'
import { RED } from '../../../Helpers/Stylesheet/styledComponents'
import { currencies } from '../../../Helpers/currencies'

import LocationAutoComplete from '../../../Components/locationAutoComplete'

const handleErrors = ({ type, value, errors: _errors, jobApplication = {} }) => {
    const errors = { name: false, email: false, ..._errors }
    const specialCharMsg = 'Special characters not allowed'
    if (type === 'name') {
        if (value.length < 1) errors.name = 'Candidate name is required'
        else if (value.length > 0 && !CANDIDATE.NAME.test(value)) errors.name = 'Invalid name'
        else errors.name = false
    }
    if (type === 'email') {
        if (value.length < 1) errors.email = 'Candidate email is required'
        else if (!CANDIDATE.EMAIL.test(value)) errors.email = 'Candidate email is invalid'
        else errors.email = false
    }

    if (type === 'skypeId') {
        if (value.length < 1 && jobApplication.skypeId === 3) errors.skypeId = 'Skype Id is required'
        else errors.skypeId = false
    }

    if (type === 'designation') {
        if (value.length < 1 && jobApplication.designation === 3) errors.designation = 'Designation is required'
        else if (value.length > 0 && CANDIDATE.NOSPECIALCHAR.test(value)) errors.designation = specialCharMsg
        else errors.designation = false
    }
    if (type === 'currentLocation') {
        if (value.length < 1 && jobApplication.currentLocation === 3)
            errors.currentLocation = 'Current Location is required'
        else errors.currentLocation = false
    }
    if (type === 'currentCtc') {
        if (value.currentCTC.length < 1 && jobApplication.currentCtc === 3)
            errors.currentCtc = 'Current CTC is required'
        else errors.currentCtc = false
    }
    if (type === 'expectedCtc') {
        if (value.expectedCTC.length < 1 && jobApplication.expectedCtc === 3)
            errors.expectedCtc = 'Expected CTC is required'
        else errors.expectedCtc = false
    }
    if (type === 'noticePeriod') {
        if (value === 'Currently in notice period' && jobApplication.noticePeriod === 3)
            errors.noticePeriod = 'Notice Period is required.'
        else errors.noticePeriod = false
    }
    if (type === 'linkedIn') {
        if (value.length < 1 && jobApplication.linkedIn === 3) errors.linkedIn = 'Linkedin URL is required!'
        else if (value.length > 0 && !CANDIDATE.LINKEDIN.test(value)) errors.linkedIn = 'Invalid Linkedin URL'
        else errors.linkedIn = false
    }
    if (type === 'github') {
        if (value.length < 1 && jobApplication.github === 3) errors.github = 'Github URL is required!'
        else if (value.length > 0 && !CANDIDATE.GITHUB.test(value)) errors.github = 'Invalid Github URL'
        else errors.github = false
    }
    if (type === 'otherDetails') {
        errors.otherDetails = false
        if (value.trim().length < 1 && jobApplication.otherDetails === 3)
            errors.otherDetails = 'Please enter the required details'
        else if (value.trim().length > 0) {
            let links = value.split('\n')
            links.forEach(each => {
                if (each.trim().length > 0) {
                    if (!each.match(CANDIDATE.PORTFOLIO)) errors.otherDetails = 'Invalid URL'
                }
            })
        } else {
            errors.otherDetails = false
        }
    }
    if (type === 'noteToTheHiringTeam') {
        if (value.trim().length < 1 && jobApplication.noteToTheHiringTeam === 3)
            errors.noteToTheHiringTeam = 'Note is required'
        else if (value.length > 0 && CANDIDATE.NOSPECIALCHAR.test(value)) errors.noteToTheHiringTeam = specialCharMsg
        else errors.noteToTheHiringTeam = false
    }
    return errors
}

const onChange = (type, errors, changeHandler) => ({ target: { value } }) => {
    if (type === 'name' && value.length && value[0] === ' ') {
        return null
    }
    const newErrors = handleErrors({ type, value, errors })
    changeHandler({
        [type]: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}

const onBlurEmail = async (value, errors, changeHandler, companyId, GetCandidatesEmail) => {
    const newErrors = handleErrors({ type: 'email', value, errors })

    // if types email has not regex errors
    if (!newErrors.email) {
        // call API to check data
        const checkMail = await GetCandidatesEmail({ email: value, companyId })
        if (checkMail.isPresent) {
            newErrors.email = 'Email is already present'
        }
    }
    changeHandler({
        email: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}
const onBlurSkype = async (value, errors, changeHandler, jobApplication) => {
    const newErrors = handleErrors({ type: 'skypeId', value, errors, jobApplication })
    changeHandler({
        skypeId: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}
const onBlurDesignation = (value, errors, changeHandler, jobApplication) => {
    const newErrors = handleErrors({ type: 'designation', value, errors, jobApplication })
    changeHandler({
        designation: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}

const onBlurLocation = (value, errors, changeHandler, jobApplication) => {
    const newErrors = handleErrors({ type: 'currentLocation', value, errors, jobApplication })
    changeHandler({
        currentLocation: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}
const onBlurCurrentCtc = (value, errors, changeHandler, jobApplication) => {
    const newErrors = handleErrors({ type: 'currentCtc', value, errors, jobApplication })
    changeHandler({
        currentCtc: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}
const onBlurExpectedCtc = (value, errors, changeHandler, jobApplication) => {
    const newErrors = handleErrors({ type: 'expectedCtc', value, errors, jobApplication })
    changeHandler({
        expectedCtc: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}

const onBlurNoticePeriod = (value, errors, changeHandler, jobApplication) => {
    const newErrors = handleErrors({ type: 'noticePeriod', value, errors, jobApplication })
    changeHandler({
        noticePeriod: value === 'Currently in notice period' ? '' : value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}
const onBlurLinkedIn = (value, errors, changeHandler, jobApplication) => {
    const newErrors = handleErrors({ type: 'linkedIn', value, errors, jobApplication })
    changeHandler({
        linkedIn: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}
const onBlurGithub = (value, errors, changeHandler, jobApplication) => {
    const newErrors = handleErrors({ type: 'github', value, errors, jobApplication })
    changeHandler({
        github: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}
const onBlurOtherDetails = (value, errors, changeHandler, jobApplication) => {
    const newErrors = handleErrors({ type: 'otherDetails', value, errors, jobApplication })
    changeHandler({
        otherDetails: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}
const onBlurNote = (value, errors, changeHandler, jobApplication) => {
    const newErrors = handleErrors({ type: 'noteToTheHiringTeam', value, errors, jobApplication })
    changeHandler({
        noteToTheHiringTeam: value,
        errors: newErrors,
        hasErrors: Object.values(newErrors).filter(each => each).length > 0,
    })
}
const removeResume = changeHandler => {
    // TODO add API call for remove
    // PR: https://github.com/SpringRole/springrecruit-backend/pull/1046
    changeHandler({ resumeLink: '', fileName: '' })
}

const DataForm = ({
    GetResumeDetails,
    GetCandidatesEmail,
    changeHandler,
    data: {
        name,
        email,
        phone,
        resumeLink,
        fileName,
        hasErrors,
        uploadStatus,
        skypeId,
        designation,
        currentLocation,
        currentCtc = { currentCTCCurrency: '', currentCTC: '' },
        expectedCtc = { expextedCTCCurrency: '', expectedCTC: '' },
        linkedIn,
        github,
        otherDetails,
        noteToTheHiringTeam,
    },
    errors,
    uploadId,
    companyId,
    jobDetails: { jobApplication },
}) => {
    const [showPhoneCodes, setShowPhoneCodes] = useState(false)
    const [showSelectLocation, setShowSelectLocation] = useState(false)
    const [currentCTCCurrency, setCurrentCTCCurrency] = useState('INR')
    const [expectedCTCCurrency, setExpectedCTCCurrency] = useState('INR')
    const [noticePeriod, setNoticePeriod] = useState('Currently in notice period')
    const onDrop = acceptedFile => {
        try {
            if (acceptedFile.length === 0) return null
            const uploadFile = SingleFileTypeValidation(acceptedFile[0])

            if (uploadFile) {
                changeHandler({ fileName: acceptedFile[0].name, uploadStatus: FILE_STATUS.UPLOADING })
                GetResumeDetails({ uploadFile, fileName: acceptedFile[0].name, uploadId, isNewUpload: false })
            } else warning('Incorrect filetype')
        } catch (err) {
            const errorMsg = (err.response && err.response.data && err.response.data.error.msg) || err
            warning(errorMsg)
        }
    }
    const handleLocationSelect = data => { }
    return (
        <>
            {(jobApplication && jobApplication.resume !== 1) || !jobApplication ? (
                <Field>
                    <div className="title">Resume {jobApplication && jobApplication.resume === 3 ? '*' : null} </div>
                    {fileName && fileName.length ? (
                        <div className="file">
                            {uploadStatus === FILE_STATUS.FAILED && fileName && (
                                <>
                                    <span className="failed">{formatFileName(fileName, 30)}</span>
                                    <i onClick={() => removeResume(changeHandler)}>
                                        <CloseIcon />
                                    </i>
                                </>
                            )}

                            {uploadStatus === FILE_STATUS.UPLOADING && !resumeLink && (
                                <>
                                    <span>Uploading {formatFileName(fileName, 20)}</span>
                                    <Spin style={{ marginLeft: 10 }} />
                                </>
                            )}

                            {uploadStatus === FILE_STATUS.SUCCESS && resumeLink && (
                                <Tags>
                                    <div>
                                        <span onClick={() => window.open(resumeLink, '_blank')}>
                                            {formatFileName(fileName, 30)}
                                        </span>
                                        <i onClick={() => removeResume(changeHandler)}>
                                            <CloseIcon />
                                        </i>
                                    </div>
                                </Tags>
                            )}
                        </div>
                    ) : (
                        uploadStatus === FILE_STATUS.SUCCESS &&
                        !resumeLink && (
                            <StyledDropzoneMini onDrop={onDrop} accept={ALLOWED_FILE_FORMATS.CANDIDATE.join(', ')} />
                        )
                    )}
                    {errors.resume && <div className="error">{errors.resume}</div>}
                </Field>
            ) : null}
            <Field>
                <div className="title">Name *</div>
                <Input
                    style={{ ...(errors.name && { border: `1px solid ${RED}` }) }}
                    size="large"
                    placeholder="Candidate Name"
                    value={name}
                    onChange={onChange('name', errors, changeHandler)}
                />
                {errors.name && <div className="error">{errors.name}</div>}
            </Field>
            <Field>
                <div className="title">Email *</div>
                <Input
                    size="large"
                    style={{ ...(errors.email && { border: `1px solid ${RED}` }) }}
                    placeholder="Candidate Email"
                    value={email}
                    onChange={change => changeHandler({ email: change.target.value })}
                    onBlur={({ target: { value } }) =>
                        onBlurEmail(value, errors, changeHandler, companyId, GetCandidatesEmail)
                    }
                />
                {errors.email && <div className="error">{errors.email}</div>}
            </Field>
            {(jobApplication && jobApplication.contactNo !== 1) || !jobApplication ? (
                <Field>
                    <div className="title">
                        Phone Number {jobApplication && jobApplication.contactNo === 3 ? '*' : null}{' '}
                    </div>
                    <div className="phone">
                        <Popover
                            arrowPointAtCenter
                            placement="bottomLeft"
                            content={
                                <StyledPhoneNumberInput
                                    onSelected={async ({ key, code }) => {
                                        setShowPhoneCodes(false)
                                        let phoneError = null
                                        // calculate the error
                                        if (phone.number && phone.number.length) {
                                            const phoneObject = parsePhoneNumberFromString(code + phone.number)
                                            if (phoneObject && phoneObject.isValid()) {
                                                const res = await GetCandidatesPhoneNumber({
                                                    phone: code + phone.number,
                                                    companyId,
                                                })
                                                if (res.isPresent) {
                                                    phoneError = 'Phone number is already present'
                                                }
                                            } else phoneError = 'Phone number is invalid'
                                        }
                                        changeHandler({
                                            phone: { ...phone, code },
                                            ...(phoneError !== null && { errors: { ...errors, phone: phoneError } }),
                                        })
                                    }}
                                />
                            }
                            visible={showPhoneCodes}
                        >
                            <div
                                className={`country-code ${phone.code ? '' : 'placeholder'}`}
                                onClick={() => setShowPhoneCodes(true)}
                            >
                                {phone.code || '+91'}
                            </div>
                        </Popover>

                        <Input
                            size="large"
                            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            placeholder={'Candidate phone number'}
                            value={phone.number}
                            onBlur={async ({ target: { value } }) => {
                                let phoneError = false // by default we assume there r no errors
                                if (phone.number && phone.number.length) {
                                    const evaluatedCode = phone.code ? phone.code : '+91'
                                    const phoneObject = parsePhoneNumberFromString(evaluatedCode + phone.number)
                                    if (phoneObject && phoneObject.isValid()) {
                                        const res = await GetCandidatesPhoneNumber({
                                            phone: evaluatedCode + phone.number,
                                            companyId,
                                        })
                                        if (res.isPresent) phoneError = 'Phone number is already present'
                                    } else phoneError = 'Phone number is invalid'
                                } else {
                                    if (
                                        phone &&
                                        phone.number.length === 0 &&
                                        jobApplication &&
                                        jobApplication.contactNo === 3
                                    ) {
                                        phoneError = 'Phone number is required'
                                    }
                                }
                                const newErrors = { ...errors, phone: phoneError }
                                changeHandler({
                                    errors: newErrors,
                                    hasErrors: Object.values(newErrors).filter(each => each).length > 0,
                                })
                            }}
                            onChange={({ target: { value } }) => {
                                let number = parseInt(value)
                                number = isNaN(number) ? '' : number.toString()
                                changeHandler({ phone: { ...phone, number } })
                            }}
                        />
                    </div>
                    {errors.phone && <div className="error">{errors.phone}</div>}
                </Field>
            ) : null}
            {jobApplication && jobApplication.skypeId !== 1 ? (
                <Field>
                    <div className="title">Skype Id {jobApplication.skypeId === 3 ? '*' : null}</div>
                    <Input
                        size="large"
                        style={{ ...(errors.skypeId && { border: `1px solid ${RED}` }) }}
                        placeholder="Enter skype id"
                        value={skypeId}
                        onChange={change => changeHandler({ skypeId: change.target.value })}
                        onBlur={({ target: { value } }) => onBlurSkype(value, errors, changeHandler, jobApplication)}
                    />
                    {errors.skypeId && <div className="error">{errors.skypeId}</div>}
                </Field>
            ) : null}
            {jobApplication && jobApplication.designation !== 1 ? (
                <Field>
                    <div className="title">Job Title / Designation {jobApplication.designation === 3 ? '*' : null}</div>
                    <Input
                        size="large"
                        style={{ ...(errors.designation && { border: `1px solid ${RED}` }) }}
                        placeholder="Enter job title / designation"
                        value={designation}
                        onChange={change => changeHandler({ designation: change.target.value })}
                        onBlur={({ target: { value } }) =>
                            onBlurDesignation(value, errors, changeHandler, jobApplication)
                        }
                    />
                    {errors.designation && <div className="error">{errors.designation}</div>}
                </Field>
            ) : null}
            {jobApplication && jobApplication.currentLocation !== 1 ? (
                <Field>
                    <div className="title">Current Location {jobApplication.currentLocation === 3 ? '*' : null}</div>
                    <Popover
                        arrowPointAtCenter
                        placement="bottomLeft"
                        content={
                            <LocationAutoComplete
                                placeholder="Enter current location"
                                className={'apply-now'}
                                onLocationSelect={e => handleLocationSelect(e)}
                                handleSelect={e => {
                                    changeHandler({
                                        currentLocation: e,
                                    })
                                    onBlurLocation(e, errors, changeHandler, jobApplication)
                                    setShowSelectLocation(false)
                                }}
                            />
                        }
                        visible={showSelectLocation}
                    >
                        <Input
                            size="large"
                            style={{ ...(errors.currentLocation && { border: `1px solid ${RED}` }) }}
                            placeholder="Enter current location"
                            value={currentLocation}
                            onClick={() => setShowSelectLocation(true)}
                        />
                        {errors.currentLocation && <div className="error">{errors.currentLocation}</div>}
                    </Popover>
                </Field>
            ) : null}
            {jobApplication && jobApplication.currentCtc !== 1 ? (
                <Field className="currency">
                    <div className="title">Current CTC {jobApplication.currentCtc === 3 ? '*' : ''}</div>
                    <div style={{ display: 'flex' }}>
                        <Select
                            allowClear={false}
                            optionLabelProp="title"
                            size="large"
                            style={{ fontSize: '14px', width: '35%' }}
                            onChange={e => {
                                setCurrentCTCCurrency(e)
                            }}
                            placeholder="INR"
                            dropdownMatchSelectWidth={false}
                            getPopupContainer={trigger => trigger.parentNode}
                        >
                            {currencies.map(currency => (
                                <Select.Option
                                    style={{ lineHeight: '18px' }}
                                    title={currency.symbol}
                                    key={currency.symbol}
                                >{`${currency.name} (${currency.symbol})`}</Select.Option>
                            ))}
                        </Select>
                        <Input
                            size="large"
                            type="number"
                            style={{ ...(errors.currentCtc && { border: `1px solid ${RED}` }) }}
                            placeholder="Enter Current CTC"
                            value={currentCtc.currentCTC}
                            onChange={change =>
                                changeHandler({ currentCtc: { currentCTC: change.target.value, currentCTCCurrency } })
                            }
                            onBlur={({ target: { value } }) =>
                                onBlurCurrentCtc(
                                    { currentCTC: value, currentCTCCurrency },
                                    errors,
                                    changeHandler,
                                    jobApplication
                                )
                            }
                        />
                    </div>
                    {errors.currentCtc && <div className="error">{errors.currentCtc}</div>}
                </Field>
            ) : null}
            {jobApplication && jobApplication.expectedCtc !== 1 ? (
                <Field>
                    <div className="title">Expected CTC {jobApplication.expectedCtc === 3 ? '*' : ''}</div>
                    <div style={{ display: 'flex' }}>
                        <Select
                            allowClear={false}
                            optionLabelProp="title"
                            size="large"
                            style={{ fontSize: '14px', width: '35%' }}
                            onChange={e => {
                                setExpectedCTCCurrency(e)
                            }}
                            placeholder="INR"
                            dropdownMatchSelectWidth={false}
                            getPopupContainer={trigger => trigger.parentNode}
                        >
                            {currencies.map(currency => (
                                <Select.Option
                                    style={{ lineHeight: '18px' }}
                                    title={currency.symbol}
                                    key={currency.symbol}
                                >{`${currency.name} (${currency.symbol})`}</Select.Option>
                            ))}
                        </Select>
                        <Input
                            size="large"
                            type="number"
                            style={{ ...(errors.expectedCtc && { border: `1px solid ${RED}` }) }}
                            placeholder="Enter Expected CTC"
                            value={expectedCtc.expectedCTC}
                            onChange={change =>
                                changeHandler({
                                    expectedCtc: { expectedCTC: change.target.value, expectedCTCCurrency },
                                })
                            }
                            onBlur={({ target: { value } }) =>
                                onBlurExpectedCtc(
                                    { expectedCTC: value, expectedCTCCurrency },
                                    errors,
                                    changeHandler,
                                    jobApplication
                                )
                            }
                        />
                    </div>
                    {errors.expectedCtc && <div className="error">{errors.expectedCtc}</div>}
                </Field>
            ) : null}
            {jobApplication && jobApplication.noticePeriod !== 1 ? (
                <Field>
                    <div className="title">Notice Period {jobApplication.noticePeriod === 3 ? '*' : null}</div>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Enter notice period"
                        // style={{ ...(errors.noticePeriod && { border: `1px solid ${RED}` }) }}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={e => {
                            setNoticePeriod(e)
                            changeHandler({
                                noticePeriod: e,
                            })
                        }}
                        onBlur={() => onBlurNoticePeriod(noticePeriod, errors, changeHandler, jobApplication)}
                    >
                        <Select.Option value="Currently in notice period">Currently in notice period</Select.Option>
                        <Select.Option value="Immediately Available">Immediately Available</Select.Option>
                        <Select.Option value="10 Days">10 Days</Select.Option>
                        <Select.Option value="11-30 Days">11-30 Days</Select.Option>
                        <Select.Option value="1 Month">1 Month</Select.Option>
                        <Select.Option value="2 Months">2 Months</Select.Option>
                        <Select.Option value="3 Months">3 Months</Select.Option>
                        <Select.Option value="More than 3 Months">More than 3 Months</Select.Option>
                    </Select>
                    {errors.noticePeriod && <div className="error">{errors.noticePeriod}</div>}
                </Field>
            ) : null}
            {jobApplication && jobApplication.linkedIn !== 1 ? (
                <Field>
                    <div className="title">
                        LinkedIn URL
                        {jobApplication.linkedIn === 3 ? '*' : null}
                    </div>
                    <Input
                        size="large"
                        style={{ ...(errors.linkedIn && { border: `1px solid ${RED}` }) }}
                        placeholder="Enter your linkedin URL"
                        value={linkedIn}
                        onChange={change => changeHandler({ linkedIn: change.target.value })}
                        onBlur={({ target: { value } }) => onBlurLinkedIn(value, errors, changeHandler, jobApplication)}
                    />
                    {errors.linkedIn && <div className="error">{errors.linkedIn}</div>}
                </Field>
            ) : null}
            {jobApplication && jobApplication.github !== 1 ? (
                <Field>
                    <div className="title">
                        Github URL
                        {jobApplication.github === 3 ? '*' : null}
                    </div>
                    <Input
                        size="large"
                        style={{ ...(errors.github && { border: `1px solid ${RED}` }) }}
                        placeholder="Enter your github URL"
                        value={github}
                        onChange={change => changeHandler({ github: change.target.value })}
                        onBlur={({ target: { value } }) => onBlurGithub(value, errors, changeHandler, jobApplication)}
                    />
                    {errors.github && <div className="error">{errors.github}</div>}
                </Field>
            ) : null}
            {jobApplication && jobApplication.otherDetails !== 1 ? (
                <Field style={{ width: '540px' }}>
                    <div className="title">
                        Portfolio/Website/Work Reference link
                        {jobApplication.otherDetails === 3 ? '*' : null}
                    </div>
                    <Input.TextArea
                        size="large"
                        style={{ ...(errors.otherDetails && { border: `1px solid ${RED}` }) }}
                        placeholder="Add Details..."
                        value={otherDetails}
                        onChange={change => changeHandler({ otherDetails: change.target.value })}
                        onBlur={({ target: { value } }) =>
                            onBlurOtherDetails(value, errors, changeHandler, jobApplication)
                        }
                    />
                    {errors.otherDetails && <div className="error">{errors.otherDetails}</div>}
                </Field>
            ) : null}
            {jobApplication && jobApplication.noteToTheHiringTeam !== 1 ? (
                <Field
                    style={{
                        marginLeft: '-285px',
                        width: '540px',
                        ...(jobApplication.otherDetails !== 1 ? { marginTop: '100px' } : { marginTop: '75px' }),
                    }}
                >
                    <div className="title">
                        Note To The Hiring Team
                        {jobApplication.noteToTheHiringTeam === 3 ? '*' : null}
                    </div>
                    <Input.TextArea
                        size="large"
                        style={{ ...(errors.noteToTheHiringTeam && { border: `1px solid ${RED}` }) }}
                        placeholder="Leave your note here for hiring team"
                        value={noteToTheHiringTeam}
                        onChange={change => changeHandler({ noteToTheHiringTeam: change.target.value })}
                        onBlur={({ target: { value } }) => onBlurNote(value, errors, changeHandler, jobApplication)}
                    />
                    {errors.noteToTheHiringTeam && <div className="error">{errors.noteToTheHiringTeam}</div>}
                </Field>
            ) : null}
        </>
    )
}

DataForm.propTypes = {
    uploadId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    const data = state.Candidate.data[ownProps.uploadId]
    return {
        data,
        companyId: state.Auth.user.companyId,
        errors: data && data.errors,
    }
}
export default connect(
    mapStateToProps,
    { GetResumeDetails, GetCandidatesEmail }
)(DataForm)
