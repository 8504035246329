import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Aside, AsideRow, StepsBar, StepsBarRow } from '../style'
import { ReactComponent as BriefcaseIcon } from '../../../../assets/Icons/Openings/briefcase.svg'
import { ReactComponent as DescriptionIcon } from '../../../../assets/Icons/Openings/description.svg'
import { ReactComponent as ApplyIcon } from '../../../../assets/Icons/Openings/apply.svg'
import { ReactComponent as PromoteIcon } from '../../../../assets/Icons/Openings/promote.svg'
import { ReactComponent as SuccessIcon } from '../../../../assets/Icons/Openings/success.svg'
import { ReactComponent as CircledBriefcaseIcon } from '../../../../assets/Icons/Openings/briefcase-circled.svg'
import { ReactComponent as CircledDescriptionIcon } from '../../../../assets/Icons/Openings/description-circled.svg'
import { ReactComponent as CircledApplyIcon } from '../../../../assets/Icons/Openings/apply-circled.svg'
import { ReactComponent as CircledPromoteIcon } from '../../../../assets/Icons/Openings/promote-circled.svg'

const LeftPanel = ({ page, maxAllowedPage, setPage, onEditMode, isMobileDevice }) => {
    if (isMobileDevice)
        return (
            <StepsBar>
                <StepsBarRow
                    className={classNames({
                        active: page === 1,
                        complete: (onEditMode || maxAllowedPage > 1) && page !== 1,
                    })}
                    status={
                        page === 1 ? 'active' : (onEditMode || maxAllowedPage > 1) && page !== 1 ? 'complete' : null
                    }
                    onClick={() => {
                        if (onEditMode || maxAllowedPage >= 1) setPage(1)
                    }}
                >
                    <CircledBriefcaseIcon />
                    STEP 1
                </StepsBarRow>
                <StepsBarRow
                    className={classNames({
                        active: page === 2,
                        complete: (onEditMode || maxAllowedPage > 2) && page !== 2,
                    })}
                    status={
                        page === 2 ? 'active' : (onEditMode || maxAllowedPage > 2) && page !== 2 ? 'complete' : null
                    }
                    onClick={() => {
                        if (onEditMode || maxAllowedPage >= 2) setPage(2)
                    }}
                >
                    <CircledDescriptionIcon />
                    STEP 2
                </StepsBarRow>
                <StepsBarRow
                    classNames={classNames({
                        active: page === 3,
                        complete: (onEditMode || maxAllowedPage > 3) && page !== 3,
                    })}
                    status={
                        page === 3 ? 'active' : (onEditMode || maxAllowedPage > 3) && page !== 3 ? 'complete' : null
                    }
                    onClick={() => {
                        if (onEditMode || maxAllowedPage >= 3) setPage(3)
                    }}
                >
                    <CircledApplyIcon />
                    STEP 3
                </StepsBarRow>
                <StepsBarRow
                    className={classNames({
                        item: true,
                        active: page === 4,
                        complete: (onEditMode || maxAllowedPage === 4) && page !== 4,
                    })}
                    status={
                        page === 4 ? 'active' : (onEditMode || maxAllowedPage === 4) && page !== 4 ? 'complete' : null
                    }
                    onClick={() => {
                        if (onEditMode || maxAllowedPage === 4) setPage(4)
                    }}
                >
                    <CircledPromoteIcon />
                    STEP 4
                </StepsBarRow>
            </StepsBar>
        )

    return (
        <Aside>
            <div
                className={classNames({
                    item: true,
                    active: page === 1,
                    complete: (onEditMode || maxAllowedPage > 1) && page !== 1,
                })}
            >
                <AsideRow
                    status={
                        page === 1 ? 'active' : (onEditMode || maxAllowedPage > 1) && page !== 1 ? 'complete' : null
                    }
                    onClick={() => {
                        if (onEditMode || maxAllowedPage >= 1) setPage(1)
                    }}
                >
                    <BriefcaseIcon />
                    Job Details
                </AsideRow>
                {(onEditMode || maxAllowedPage > 1) && page !== 1 ? (
                    <SuccessIcon />
                ) : (
                    <div
                        className={classNames({
                            step: true,
                            active: page === 1,
                        })}
                    >
                        1
                    </div>
                )}
            </div>
            <div
                className={classNames({
                    item: true,
                    active: page === 2,
                    complete: (onEditMode || maxAllowedPage > 2) && page !== 2,
                })}
            >
                <AsideRow
                    status={
                        page === 2 ? 'active' : (onEditMode || maxAllowedPage > 2) && page !== 2 ? 'complete' : null
                    }
                    onClick={() => {
                        if (onEditMode || maxAllowedPage >= 2) setPage(2)
                    }}
                >
                    <DescriptionIcon />
                    Description and Feedback
                </AsideRow>
                {(onEditMode || maxAllowedPage > 2) && page !== 2 ? (
                    <SuccessIcon />
                ) : (
                    <div
                        className={classNames({
                            step: true,
                            active: page === 2,
                        })}
                    >
                        2
                    </div>
                )}
            </div>
            <div
                className={classNames({
                    item: true,
                    active: page === 3,
                    complete: (onEditMode || maxAllowedPage > 3) && page !== 3,
                })}
            >
                <AsideRow
                    status={
                        page === 3 ? 'active' : (onEditMode || maxAllowedPage > 3) && page !== 3 ? 'complete' : null
                    }
                    onClick={() => {
                        if (onEditMode || maxAllowedPage >= 3) setPage(3)
                    }}
                >
                    <ApplyIcon />
                    Application Form
                </AsideRow>
                {(onEditMode || maxAllowedPage > 3) && page !== 3 ? (
                    <SuccessIcon />
                ) : (
                    <div
                        className={classNames({
                            step: true,
                            active: page === 3,
                        })}
                    >
                        3
                    </div>
                )}
            </div>
            <div
                className={classNames({
                    item: true,
                    active: page === 4,
                    complete: (onEditMode || maxAllowedPage === 4) && page !== 4,
                })}
            >
                <AsideRow
                    status={
                        page === 4 ? 'active' : (onEditMode || maxAllowedPage === 4) && page !== 4 ? 'complete' : null
                    }
                    onClick={() => {
                        if (onEditMode || maxAllowedPage === 4) setPage(4)
                    }}
                >
                    <PromoteIcon />
                    Promote
                </AsideRow>
                {(onEditMode || maxAllowedPage === 4) && page !== 4 ? (
                    <SuccessIcon />
                ) : (
                    <div
                        className={classNames({
                            step: true,
                            active: page === 4,
                        })}
                    >
                        4
                    </div>
                )}
            </div>
        </Aside>
    )
}
export default LeftPanel
