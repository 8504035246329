import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'
import { Filter, Heading, Tabs, DataCard } from '../../style'
import CustomFilters from '../../Components/Filters'
import { CandidatesFilterLoader, CandidatesProgressLoader } from '../../Components/Loaders'
import { GetAnalyticsData } from '../../../../../services/NetworkCalls/Analytics'
import apiCaller from '../../../../../services/apiCaller'
import { warning } from '../../../../../services/Notification'
import { ReactComponent as InfoIcon } from '../../../../../assets/Icons/Openings/info.svg'
import { ReactComponent as RefreshIcon } from '../../../../../assets/Icons/Analytics/refresh.svg'
import { getNumberOfDays, getPercentage } from '../../../../Helpers/utils'
import StatCard from '../../Components/Card'
import {
    getCandidatesInitialPayload,
    getFilteredPayload,
    getJobStatusArray,
    getRefreshPayload,
    SOURCING_JOB_COLUMNS,
    SOURCING_MONTH_COLUMNS,
    SOURCING_TAG_COLUMNS,
} from '../../Components/constants'
import Table from '../../Components/Table'
import { MONTHS_ARRAY } from '../../../../Helpers/constants'
import { Tooltip } from 'antd'
import config from '../../../../../config'
import { StyledButton } from '../../../../../container/Components/'

const { isMobileDevice } = config
const { CheckboxFilter, DateFilter } = CustomFilters
const { formatError } = apiCaller

const Source = ({ companyId, jobNames, jobsObject, GetAnalyticsData, history }) => {
    const [filterLoading, setFilterLoading] = useState(false)
    const [cardLoading, setCardLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [cardData, setCardData] = useState({})
    const [tableData, setTableData] = useState([])
    const [jobNamesArray, setJobNamesArray] = useState(jobNames)
    const [tagsArray, setTagsArray] = useState([])
    const [selectedJobTypes, setSelectedJobTypes] = useState([])
    const [selectedJobNames, setSelectedJobNames] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedTab, setSelectedTab] = useState('jobs')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [loadTime, setLoadTime] = useState(moment().format('DD MMM, YYYY LT'))

    const jobTypeArray = ['Active', 'Archived']
    const selectedColumn = {
        jobs: {
            columns: SOURCING_JOB_COLUMNS,
            downloadColumns: [
                { index: 'name', value: 'JOB NAME' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'careerPage', value: 'CAREER PAGE' },
                { index: 'ats', value: 'ATS' },
                { index: 'sourced', value: 'SOURCED' },
                { index: 'email', value: 'EMAIL' },
            ],
            filename: 'CANDIDATE SOURCE_JOBS',
        },
        months: {
            columns: SOURCING_MONTH_COLUMNS,
            downloadColumns: [
                { index: 'name', value: 'MONTH CREATED' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'careerPage', value: 'CAREER PAGE' },
                { index: 'ats', value: 'ATS' },
                { index: 'sourced', value: 'SOURCED' },
                { index: 'email', value: 'EMAIL' },
            ],
            filename: 'CANDIDATE SOURCE_MONTH',
        },
        tags: {
            columns: SOURCING_TAG_COLUMNS,
            downloadColumns: [
                { index: 'name', value: 'TAG  NAME' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'careerPage', value: 'CAREER PAGE' },
                { index: 'ats', value: 'ATS' },
                { index: 'sourced', value: 'SOURCED' },
                { index: 'email', value: 'EMAIL' },
            ],
            filename: 'CANDIDATE SOURCE_TAGS',
        },
    }

    useEffect(() => {
        candidatesInitialLoad()
    }, [])

    const candidatesInitialLoad = async () => {
        const urlSearchResult = new URLSearchParams(history.location.search)
        const jobTypes = urlSearchResult.get('jobTypes') ? urlSearchResult.get('jobTypes').split(',') : []
        const jobNames = urlSearchResult.get('jobNames') ? urlSearchResult.get('jobNames').split(',') : []
        const tagNames = urlSearchResult.get('tagNames') ? urlSearchResult.get('tagNames').split(',') : []
        const startDate = urlSearchResult.get('startDate') ? urlSearchResult.get('startDate') : null
        const endDate = urlSearchResult.get('endDate') ? urlSearchResult.get('endDate') : null
        let tagNamesArray = []

        if (jobTypes.length || jobNames.length || tagNames.length || startDate || endDate)
            tagNamesArray = await getCandidateTags()
        const { payload, filteredJobNames } = getCandidatesInitialPayload(
            companyId,
            jobTypes,
            jobNames,
            jobsObject,
            tagNames,
            tagNamesArray,
            startDate,
            endDate
        )
        getSourcingData(payload)
        getCandidateTags({ jobId: payload.jobId })
        getSourcingTableData(payload, 'jobs', jobTypes, jobNames)
        setSelectedJobTypes(jobTypes)
        setSelectedJobNames(jobNames)
        setJobNamesArray(filteredJobNames)
        setSelectedTags(tagNames)
        setStartDate(startDate && moment(startDate))
        setEndDate(endDate && moment(endDate))
    }

    const getSourcingData = async (payload = {}) => {
        try {
            setCardLoading(true)
            const { result } = await GetAnalyticsData('GetSourcingData', payload)
            setCardData(result[0])
            setCardLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getSourcingTableData = async (payload = {}, tab, jobTypes, jobNames) => {
        try {
            setTableLoading(true)
            const res = await GetAnalyticsData('GetSourcingTableData', payload, tab)
            let tableData = {}
            if (tab === 'tags') {
                tableData = []
                for (let tag in res) {
                    tableData.push({
                        key: tag,
                        name: tag,
                        candidatesAdded: res[tag].total || 0,
                        careerPage: res[tag].careerPage || 0,
                        ats: res[tag].ats || 0,
                        sourced: res[tag].sourced || 0,
                        email: res[tag].email || 0,
                    })
                }
            } else {
                const jobResultList = []
                let jobNamesList = []
                if (tab === 'jobs') {
                    if (jobNames.length) jobNamesList = jobNames
                    else if (jobTypes.length) {
                        const jobStatusArray = getJobStatusArray(jobTypes)
                        for (let job in jobsObject) {
                            if (jobStatusArray.includes(jobsObject[job].status)) jobNamesList.push(job)
                        }
                    } else jobNamesList = Object.keys(jobsObject)
                }
                tableData = res.map((each, index) => {
                    const obj = {
                        key: index,
                        candidatesAdded: each.total || 0,
                        careerPage: each.careerPage || 0,
                        ats: each.ats || 0,
                        sourced: each.sourced || 0,
                        email: each.email || 0,
                    }
                    if (tab === 'jobs') {
                        obj.name = each._id
                        jobResultList.push(each._id)
                    } else obj.name = `${MONTHS_ARRAY[each._id.month - 1]} ${each._id.year}`
                    return obj
                })
                jobNamesList.forEach(jobName => {
                    if (!jobResultList.includes(jobName))
                        tableData.push({
                            key: jobName,
                            name: jobName,
                            candidatesAdded: 0,
                            careerPage: 0,
                            ats: 0,
                            sourced: 0,
                            email: 0,
                        })
                })
            }
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getCandidateTags = async (payload = {}) => {
        try {
            setFilterLoading(true)
            const { result } = await GetAnalyticsData('GetCandidatesTags', payload, companyId)
            setTagsArray(result)
            setFilterLoading(false)
            return result
        } catch (err) {
            warning(formatError(err))
        }
    }

    const applyFilteredData = (type, data) => {
        const payload = getFilteredPayload(
            type,
            data,
            companyId,
            selectedJobTypes,
            selectedJobNames,
            jobsObject,
            selectedTags,
            tagsArray,
            startDate,
            endDate,
            getCandidateTags,
            setSelectedJobTypes,
            setJobNamesArray,
            setSelectedJobNames,
            setSelectedTags,
            setStartDate,
            setEndDate,
            history
        )
        getSourcingData(payload)
        if (type === 'jobType') getSourcingTableData(payload, selectedTab, data, [])
        else if (type === 'jobName') getSourcingTableData(payload, selectedTab, selectedJobTypes, data)
        else getSourcingTableData(payload, selectedTab, selectedJobTypes, selectedJobNames)
    }

    const resetFilter = type => {
        const params = new URLSearchParams(history.location.search)
        if (type === 'jobType') {
            const payload = {
                companyId,
                startDate: startDate && startDate.format(),
                endDate: endDate && endDate.format(),
            }
            getCandidateTags(payload)
            getSourcingData(payload)
            getSourcingTableData(payload, selectedTab, [], [])
            setSelectedJobTypes([])
            setSelectedJobNames([])
            setSelectedTags([])
            setJobNamesArray(Object.keys(jobsObject))
            params.delete('jobTypes')
            params.delete('jobNames')
            params.delete('tagNames')
            history.push({ search: params.toString() })
        } else if (type === 'jobName') {
            const jobStatusArray = getJobStatusArray(selectedJobTypes)
            let jobId = []
            if (jobStatusArray.length) {
                for (let job in jobsObject)
                    if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
            } else jobId = null
            const payload = {
                companyId,
                jobId,
                startDate: startDate && startDate.format(),
                endDate: endDate && endDate.format(),
            }
            getCandidateTags(payload)
            getSourcingData(payload)
            getSourcingTableData(payload, selectedTab, selectedJobTypes, [])
            setSelectedJobNames([])
            setSelectedTags([])
            params.delete('jobNames')
            params.delete('tagNames')
            history.push({ search: params.toString() })
        } else {
            const jobStatusArray = getJobStatusArray(selectedJobTypes)
            let jobId = []
            if (selectedJobNames.length) jobId = selectedJobNames.map(jobName => jobsObject[jobName]._id)
            else if (jobStatusArray.length) {
                for (let job in jobsObject)
                    if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
            }
            const payload = {
                companyId,
                jobId: (jobId.length && jobId) || null,
                startDate: startDate && startDate.format(),
                endDate: endDate && endDate.format(),
            }
            getSourcingData(payload)
            getSourcingTableData(payload, selectedTab, selectedJobTypes, selectedJobNames)
            setSelectedTags([])
            params.delete('tagNames')
            history.push({ search: params.toString() })
        }
    }

    const handleTabClick = tab => {
        const jobStatusArray = getJobStatusArray(selectedJobTypes)
        let jobId = [],
            tags = []
        if (selectedJobNames.length) jobId = selectedJobNames.map(jobName => jobsObject[jobName]._id)
        else if (jobStatusArray.length) {
            for (let job in jobsObject)
                if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
        } else jobId = null

        if (selectedTags.length) {
            tagsArray.forEach(tag => {
                if (selectedTags.includes(tag.name)) tags.push(tag._id)
            })
        }
        const payload = {
            companyId,
            jobId,
            tags: (tags.length && tags) || null,
            startDate: startDate && startDate.format(),
            endDate: endDate && endDate.format(),
        }
        getSourcingTableData(payload, tab, selectedJobTypes, selectedJobNames)

        setSelectedTab(tab)
    }

    const handleRefresh = () => {
        const payload = getRefreshPayload(
            selectedJobTypes,
            selectedJobNames,
            selectedTags,
            jobsObject,
            tagsArray,
            companyId,
            startDate,
            endDate
        )
        getSourcingData(payload)
        getSourcingTableData(payload, selectedTab, selectedJobTypes, selectedJobNames)
        setLoadTime(moment().format('DD MMM, YYYY LT'))
    }

    return (
        <>
            <div className="sub-header">
                <div>
                    <Heading>Candidate Source</Heading>
                    <Tooltip title="Analyze where your candidates come from by tracking and analyzing their source. Use the filters to narrow the search criteria.">
                        <InfoIcon />
                    </Tooltip>
                </div>
                <div>
                    <RefreshIcon onClick={() => handleRefresh()} />
                    <span>Last Updated: {loadTime}</span>
                </div>
            </div>
            {filterLoading ? (
                <CandidatesFilterLoader />
            ) : (
                <>
                    {isMobileDevice && <Heading>Filter</Heading>}
                    <Filter>
                        <CheckboxFilter
                            title="Filter by job type"
                            data={jobTypeArray}
                            search={false}
                            apply={false}
                            type="jobType"
                            applyFilteredData={applyFilteredData}
                            resetFilter={resetFilter}
                            selectedValues={selectedJobTypes}
                        />
                        <CheckboxFilter
                            title="Filter by job name"
                            data={jobNamesArray}
                            type="jobName"
                            applyFilteredData={applyFilteredData}
                            resetFilter={resetFilter}
                            selectedValues={selectedJobNames}
                        />
                        <CheckboxFilter
                            title="Filter by tag"
                            data={tagsArray.map(tag => tag.name)}
                            type="tag"
                            applyFilteredData={applyFilteredData}
                            resetFilter={resetFilter}
                            selectedValues={selectedTags}
                        />
                        <DateFilter applyFilteredData={applyFilteredData} start={startDate} end={endDate} />
                    </Filter>
                </>
            )}
            {cardLoading || filterLoading ? (
                <CandidatesProgressLoader />
            ) : (
                <div className="cards">
                    <StatCard total={cardData.total} title="Total Candidates" />
                    <StatCard
                        total={cardData.careerPage}
                        title="Career Page"
                        desc={`${getPercentage(cardData.careerPage, cardData.total)}% of Total Candidates`}
                    />
                    <StatCard
                        total={cardData.ats}
                        title="ATS"
                        desc={`${getPercentage(cardData.ats, cardData.total)}% of Total Candidates`}
                    />
                    <StatCard
                        total={cardData.email}
                        title="Email"
                        desc={`${getPercentage(cardData.email, cardData.total)}% of Total Candidates`}
                    />
                    <StatCard
                        total={cardData.sourced}
                        title="Sourced"
                        desc={`${getPercentage(cardData.sourced, cardData.total)}% of Total Candidates`}
                    />
                </div>
            )}
            <Tabs>
                <div className={classNames({ active: selectedTab === 'jobs' })} onClick={() => handleTabClick('jobs')}>
                    JOB
                </div>
                <div
                    className={classNames({ active: selectedTab === 'months' })}
                    onClick={() => handleTabClick('months')}
                >
                    MONTH CREATED
                </div>
                <div className={classNames({ active: selectedTab === 'tags' })} onClick={() => handleTabClick('tags')}>
                    CANDIDATE TAG
                </div>
            </Tabs>
            <Table
                columns={selectedColumn[selectedTab].columns}
                downloadColumns={selectedColumn[selectedTab].downloadColumns}
                filename={`${selectedColumn[selectedTab].filename}_${moment().format('DD-MM-YYYY_h-mm a')}`}
                tableData={tableData}
                loading={tableLoading || filterLoading}
            />
        </>
    )
}

const mapStateToProps = ({ Auth, Job }) => {
    const { user } = Auth
    const { allJobs } = Job
    const jobNames = [],
        jobsObject = {}

    allJobs.forEach(job => {
        jobNames.push(job.jobTitle)
        jobsObject[job.jobTitle] = {
            _id: job._id,
            status: job.status,
        }
    })

    return {
        companyId: user.companyId,
        jobNames,
        jobsObject,
    }
}

export default connect(
    mapStateToProps,
    {
        GetAnalyticsData,
    }
)(Source)
