import React from 'react'
import { Body, Card, Avatar, Name, Label, Top, Bottom, Info, Icons, TagBar } from './style'
import './style.css'
import { Checkbox, Icon, Menu, Tooltip, Button, Drawer, Popover } from 'antd'
import { ReactComponent as EditIcon } from '../../../assets/Icons/Talent Pool/Edit.svg'
import { ReactComponent as AddIcon } from '../../../assets/Icons/Talent Pool/add.svg'
import { ReactComponent as EnvelopeIcon } from '../../../assets/Icons/Talent Pool/envelope.svg'
import { ReactComponent as PhoneIcon } from '../../../assets/Icons/Talent Pool/phone.svg'
import { ReactComponent as ProfileIcon } from '../../../assets/Icons/Talent Pool/profile.svg'
import { ReactComponent as ResumeIcon } from '../../../assets/Icons/Talent Pool/resume.svg'
import { ReactComponent as ThreedotsIcon } from '../../../assets/Icons/Talent Pool/three-dots.svg'
import { ReactComponent as StarFilledIcon } from '../../../assets/Icons/Talent Pool/Star Filled.svg'
import { ReactComponent as InfoIcon } from '../../../assets/Icons/Talent Pool/info.svg'
import { ReactComponent as NoPhoneIcon } from '../../../assets/Icons/Talent Pool/noPhone.svg'
import { ReactComponent as NoEmailIcon } from '../../../assets/Icons/Talent Pool/noEmail.svg'
import { ReactComponent as Star } from '../../../assets/Icons/Talent Pool/star.svg'
import { ReactComponent as TagIcon } from '../../../assets/Icons/Talent Pool/tag.svg'
import { connect } from 'react-redux'
import { findNextEmpty, isObjectEmpty } from '../../Helpers/utils'
import { EVENTS, MODAL_TYPE } from '../TalentPool/constants'
import getAvatar from '../../../services/getAvatar'
import moment from 'moment'
import OpenInNewTabIcon from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/OpenInNewTab'
import Dropzone from 'react-dropzone'
import { fileTypeValidation } from '../../Helpers/fileTypeValidation'
import { success, warning } from '../../../services/Notification'
import { UpdateResume, EditCandidate } from '../../../services/NetworkCalls/Candidate'
import { Modal } from '../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import config from '../../../config'
import { ARCHIVE_REASONS } from '../../Helpers/constants'
import { StyledButton } from '../../Components'
const { isMobileDevice } = config

class TalentPoolCard extends React.Component {
    state = {
        dataStared: false,
        viewResume: false,
        iCandidateModal: false,
        addTagModal: false,
        resumeLink: '',
        cData: {},
        nextData: {},
        hit: { ...this.props.hit },
        showMoreOptions: false,
        archiveModal: false,
        showUnarchiveModal: false,
        moveOpeningModal: false,
        modalType: '',
        rejectCandidateModal: false,
        deleteCandidateModal: false,
        callInProgress: false,
        searchIndex: 0,
        showMore: false,
    }

    viewResume = (resumeLink = '') => {
        this.setState({
            viewResume: true,
            iCandidateModal: false,
            resumeLink,
        })
    }

    addDetail = data => {
        const { renderList } = this.props
        let cData = data
        let next = findNextEmpty(renderList, 0)
        if (next.cData && next.cData.objectID === cData.objectID) {
            next = findNextEmpty(renderList, next.index + 1)
        }

        this.setState({
            viewResume: true,
            iCandidateModal: true,
            cData,
            nextData: next.cData,
            resumeLink: data.resumeLink || '',
        })
    }

    handleClose = (newData = {}) => {
        this.setState({
            viewResume: false,
            iCandidateModal: false,
            addTagModal: false,
            showWarning: false,
            resumeLink: '',
            hit: { ...this.state.hit, ...newData },
            archiveModal: false,
            moveOpeningModal: false,
            changeJobModal: false,
            changeStageModal: false,
            rejectCandidateModal: false,
            deleteCandidateModal: false,
            showUnarchiveModal: false,
            searchIndex: 0,
        })
        this.props.handleBulkCancel()
        this.props.refresh()
    }

    handleAddCandidateDetails = data => {
        try {
            this.setState({ callInProgress: true }, async () => {
                const { objectID, companyId } = this.state.cData
                await this.props.EditCandidate(data, companyId, objectID)
                this.setState({ callInProgress: false }, () => this.handleClose(data))
            })
        } catch (error) {
            warning('Something went wrong. Please try again!')
        }
    }

    updateCandidate = (newData, jobPayload) => {
        this.setState(
            {
                moveOpeningModal: false,
                changeJobModal: false,
                changeStageModal: false,
                hit: { ...this.state.hit, ...newData },
            },
            () => {
                this.props.updateCandidate(newData, jobPayload)
            }
        )
    }

    markFavourite = (index, data) => {
        data.isFavourite = !data.isFavourite
        this.props.eventManager(EVENTS.FAVOURITE, index, data)
    }

    markSelect = data => {
        this.props.eventManager(EVENTS.SELECT, 3333, data)
        this.setState({ isSelected: true })
    }

    archiveCandidate = data => {
        this.setState({ showMoreOptions: false })
        if (data.status === 0 || data.jobStatus === 0) {
            if (data.jobStatus === 0) {
                this.setState({ showUnarchiveModal: true })
            } else {
                this.props.eventManager(EVENTS.SELECT, 3333, data, 333, false)
                this.props.eventManager(EVENTS.UNARCHIVE)
                const { hit } = this.state
                hit.status = 1
                this.setState({ hit })
            }
        } else {
            this.props.eventManager(EVENTS.SELECT, 3333, data, 333, false)
            this.setState({ archiveModal: true })
        }
    }

    rejectCandidate = data => {
        this.setState({ showMoreOptions: false })
        this.props.eventManager(EVENTS.SELECT, 3333, data, 333, false)
        if (data.status === 2) {
            this.props.eventManager(EVENTS.UNREJECT)
            const { hit } = this.state
            hit.status = 1
            this.setState({ hit })
        } else {
            this.setState({ rejectCandidateModal: true })
        }
    }

    deleteCandidate = data => {
        this.setState({ showMoreOptions: false, deleteCandidateModal: true })
    }

    updateTags = data => {
        this.props.eventManager(EVENTS.UPDATE_TAGS, null, data)
        this.handleClose()
    }

    nextIncomplete = data => {
        try {
            this.setState({ callInProgress: true }, async () => {
                const { objectID, companyId } = this.state.cData
                await this.props.EditCandidate(data, companyId, objectID)
                const { renderList } = this.props
                let search = findNextEmpty(renderList, this.state.searchIndex)
                // If the searched item is the present item itself, then start searching from next index
                if (search.cData && search.cData.objectID === this.state.cData.objectID) {
                    search = findNextEmpty(renderList, search.index + 1)
                    search.index++
                }
                this.setState({
                    resumeLink: (this.state.nextData && this.state.nextData.resumeLink) || '',
                    cData: this.state.nextData,
                    nextData: search.cData,
                    callInProgress: false,
                    searchIndex: search.index,
                })
            })
        } catch (error) {
            warning('Something went wrong. Please try again!')
        }
    }

    handleVisibleChange = showMoreOptions => {
        this.setState({ showMoreOptions })
    }

    handleArchive = (reason, sendEmailToCandidate) => {
        this.props.eventManager(EVENTS.ARCHIVE, 333, 333, reason, null, sendEmailToCandidate)
        const { hit } = this.state
        hit.status = 0
        this.setState({ archiveModal: false, hit })
    }

    handleUnarchive = newData => {
        this.setState(
            {
                showUnarchiveModal: false,
                hit: { ...this.state.hit, ...newData, status: 1, jobStatus: 1 },
            },
            () => {
                const data = { ...this.state.hit, status: 0 }
                this.props.eventManager(EVENTS.SELECT, 3333, data, 333, false)
                this.props.eventManager(EVENTS.UNARCHIVE)
            }
        )
    }

    handleJobChange = data => {
        this.props.eventManager(EVENTS.SELECT, 3333, data, 333, false)
        this.setState({ moveOpeningModal: true, modalType: MODAL_TYPE.CHANGE_JOB })
    }

    handleStageChange = data => {
        this.props.eventManager(EVENTS.SELECT, 3333, data, 333, false)
        this.setState({ moveOpeningModal: true, modalType: MODAL_TYPE.CHANGE_STAGE })
    }

    handleJobMove = data => {
        this.props.eventManager(EVENTS.SELECT, 3333, data, 333, false)
        this.setState({ moveOpeningModal: true, modalType: MODAL_TYPE.MOVE_JOB, showMoreOptions: false })
    }

    handleReject = () => {
        this.props.eventManager(EVENTS.REJECT)
        const { hit } = this.state
        hit.status = 2
        this.setState({ rejectCandidateModal: false, hit })
    }

    handleDelete = data => {
        this.props.eventManager(EVENTS.DELETE_CANDIDATE, 3333, [data], 333, false)
        this.setState({ deleteCandidateModal: false })
    }
    render() {
        const data = this.props.hit
        const onResumeSelect = async acceptedFile => {
            try {
                const fileCheck = fileTypeValidation(acceptedFile)
                if (!fileCheck) {
                    warning('Error uploading resume! Please ensure the file is either of type pdf, doc or docx ')
                    return true
                }
                const companyId = data.companyId
                this.props.handleUpdateResume(fileCheck, data.objectID, companyId)
            } catch (err) {
                const errorMsg = (err.response && err.response.data && err.response.data.error.msg) || err
                warning(errorMsg)
            }
            return false
        }

        const { allJobs, teamMembers, accessLevel } = this.props

        const mem = teamMembers.find(x => [x._id, x.email].includes(data.sourceBy || data.userId))
        let sourceBy = mem ? mem.fullName || mem.email : data.sourceBy
        const isRefferedCandidate = sourceBy.split('"name"').length > 1
        if (isRefferedCandidate) {
            sourceBy = JSON.parse(sourceBy)
        }
        if (data.jobId) {
            const job = allJobs.find(x => x._id === data.jobId)
            data.jobTitle = job && job.jobTitle
            data.jobStatus = job && job.status
            if (job && data.stageId) {
                const stage = job.stages.find(x => x._id === data.stageId)
                data.stageName = stage && stage.stageName
                // For disabling the editIcon on the Joined candidates
                data.hasJoined = data.stageId === job.stages[job.stages.length - 1]._id
            }
        }
        let toolTipText
        const namePresent = !!data.name
        const emailPresent = !!data.email
        const phonePresent = !!data.phone

        if (!namePresent && !emailPresent && !phonePresent) {
            toolTipText = 'Incomplete candidate details. Click add details button to add'
        } else if (!namePresent && !emailPresent) {
            toolTipText = 'Missing Name and Email. Click add details button to add'
        } else if (!namePresent && !phonePresent) {
            toolTipText = 'Missing Name and Phone. Click add details button to add'
        } else if (!namePresent) {
            toolTipText = 'Missing Name. Click add details button to add'
        }

        let isSelected = false
        if (Object.keys(this.props.selected).length > 0) {
            isSelected = Object.keys(this.props.selected).find(each => each === data.objectID)
        }

        return (
            <>
                <Body>
                    <div>
                        <Card bradius={data.tags}>
                            <Top>
                                {data.name ? (
                                    <Avatar>
                                        <span className="talentpool-avatarName">{getAvatar(data.name || 'C')}</span>
                                    </Avatar>
                                ) : (
                                    <Tooltip overlayClassName="tp-card-tooltip" placement={'top'} title={toolTipText}>
                                        <div>
                                            <Avatar>
                                                <span className="talentpool-avatarName">
                                                    <InfoIcon />
                                                </span>
                                            </Avatar>
                                        </div>
                                    </Tooltip>
                                )}
                                <Info>
                                    <Name>
                                        <a
                                            href={`/candidate/${data.objectID}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span className={!namePresent ? 'color-orange' : 'color-black'}>
                                                {data.name || 'Candidate'}
                                            </span>
                                        </a>
                                    </Name>
                                    <Label>
                                        Source: <span> via </span>
                                        <span className="color-blue">
                                            {isRefferedCandidate ? 'Referral' : data.sourceVia}
                                        </span>{' '}
                                        <span className="sr-bold"> by </span>
                                        <span className="sr-bold">
                                            {' '}
                                            {isRefferedCandidate ? `${sourceBy.name} (${sourceBy.email})` : sourceBy}
                                        </span>
                                    </Label>
                                    <Label flex>
                                        <div>
                                            Job opening:
                                            {data.jobTitle ? (
                                                <span className="tp-card-edit-option"> {data.jobTitle} </span>
                                            ) : (
                                                <span className="tp-card-edit-option color-orange"> unassigned </span>
                                            )}
                                        </div>
                                        {(accessLevel < 4 || !data.jobId) && !data.hasJoined ? (
                                            <span className="tp-card-edit" onClick={() => this.handleJobChange(data)}>
                                                <EditIcon />
                                            </span>
                                        ) : null}
                                    </Label>
                                    <Label flex>
                                        <div>
                                            Stage:
                                            {data.stageName ? (
                                                <span className="tp-card-edit-option"> {data.stageName} </span>
                                            ) : (
                                                <span className="tp-card-edit-option color-orange"> unassigned </span>
                                            )}
                                        </div>
                                        {data.jobStatus && accessLevel < 4 && !data.hasJoined ? (
                                            <span className="tp-card-edit" onClick={() => this.handleStageChange(data)}>
                                                <EditIcon />
                                            </span>
                                        ) : null}
                                    </Label>

                                    <Label>
                                        {data.email ? (
                                            <>
                                                <EnvelopeIcon />
                                                <span> {data.email}</span>
                                            </>
                                        ) : (
                                            <>
                                                <NoEmailIcon />
                                                <span className="color-orange"> Email is not added</span>
                                            </>
                                        )}
                                        {data.phone ? (
                                            <div className="tp-card-phone">
                                                <PhoneIcon />

                                                <span> {data.phone}</span>
                                            </div>
                                        ) : (
                                            <>
                                                <NoPhoneIcon />
                                                <span className="color-orange"> Phone number is not added</span>
                                            </>
                                        )}
                                    </Label>
                                    {!data.isProfileCompleted && (
                                        <div>
                                            <Button
                                                onClick={() => this.addDetail(data)}
                                                type="default"
                                                ghost
                                                size="default"
                                                className="tp-card-btn"
                                            >
                                                Add Details
                                            </Button>
                                        </div>
                                    )}
                                </Info>
                                <div className="tp-card-tags">
                                    {this.props.bulkMode ? (
                                        <div className="tp-card-select">
                                            <Checkbox checked={isSelected} onClick={() => this.markSelect(data)}>
                                                {isSelected ? 'Selected' : 'Select'}
                                            </Checkbox>
                                        </div>
                                    ) : (
                                        <Icons>
                                            <a
                                                href={`/candidate/${data.objectID}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Tooltip
                                                    overlayClassName="tp-card-icon-tt"
                                                    placement={'top'}
                                                    title="View profile"
                                                >
                                                    <ProfileIcon />
                                                </Tooltip>
                                            </a>
                                            {this.state.resumeLink !== '' || data.resumeLink ? (
                                                <Tooltip
                                                    overlayClassName="tp-card-icon-tt"
                                                    placement={'top'}
                                                    title="View Resume"
                                                >
                                                    <ResumeIcon
                                                        onClick={() =>
                                                            this.viewResume(
                                                                this.state.resumeLink === ''
                                                                    ? data.resumeLink
                                                                    : this.state.resumeLink
                                                            )
                                                        }
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Dropzone onDrop={onResumeSelect}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <span {...getRootProps()} className="tp-card-upload">
                                                            <input {...getInputProps()} />
                                                            <Tooltip title="Add Resume">
                                                                <AddIcon />
                                                            </Tooltip>
                                                        </span>
                                                    )}
                                                </Dropzone>
                                            )}
                                            {data.isFavourite ? (
                                                <StarFilledIcon onClick={() => this.markFavourite(333, data)} />
                                            ) : (
                                                <Star onClick={() => this.markFavourite(333, data)} />
                                            )}

                                            <Popover
                                                content={
                                                    <Menu className="tp-card-menu">
                                                        {/* Hiding the Archive/Unarchive option if Job opening is Archived  */}
                                                        {accessLevel < 3 && data.jobId && data.jobStatus !== 0 && (
                                                            <Menu.Item
                                                                className="tp-card-menu-items"
                                                                onClick={() => this.archiveCandidate(data)}
                                                            >
                                                                {data.status === 0 ? 'Unarchive' : 'Archive'}
                                                            </Menu.Item>
                                                        )}
                                                        {accessLevel < 4 && (
                                                            <Menu.Item
                                                                className="tp-card-menu-items"
                                                                onClick={() => this.handleJobMove(data)}
                                                            >
                                                                Move to Opening
                                                            </Menu.Item>
                                                        )}
                                                        {data.status !== 0 && data.jobStatus !== 0 && data.jobId && (
                                                            <Menu.Item
                                                                className="tp-card-menu-items color-red"
                                                                onClick={() => this.rejectCandidate(data)}
                                                            >
                                                                {data.status === 2 ? 'Unreject' : 'Reject'}
                                                            </Menu.Item>
                                                        )}
                                                        {accessLevel < 3 && (
                                                            <Menu.Item
                                                                className="tp-card-menu-items color-red"
                                                                onClick={() => this.deleteCandidate(data)}
                                                            >
                                                                Delete Candidate
                                                            </Menu.Item>
                                                        )}
                                                        <Menu.Item
                                                            className="tp-card-menu-items"
                                                            onClick={() => this.setState({ addTagModal: true })}
                                                        >
                                                            Add Tag
                                                        </Menu.Item>
                                                    </Menu>
                                                }
                                                getPopupContainer={trigger => trigger.parentNode}
                                                trigger="click"
                                                placement="bottomRight"
                                                visible={this.state.showMoreOptions}
                                                onVisibleChange={this.handleVisibleChange}
                                            >
                                                <ThreedotsIcon />
                                            </Popover>
                                        </Icons>
                                    )}
                                    {data.status === 2 ? (
                                        <div className="tp-archived-tag">
                                            <span>Rejected</span>
                                        </div>
                                    ) : (
                                        (data.jobStatus === 0 || data.status === 0) && (
                                            <div className="tp-archived-tag">
                                                <span>Archived</span>
                                            </div>
                                        )
                                    )}
                                </div>
                            </Top>
                            <Bottom>
                                <div className="tps-wrapper">
                                    <div className="tps-wrapper-card">
                                        <div className="tps-label">Location:</div>
                                        <div className="tps-values">
                                            {data.currentLocation || data.location || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="tps-wrapper-card">
                                        <div className="tps-label">Current Company:</div>
                                        <div className="tps-values">
                                            {data.currentCompanyName || data.currentCompany || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="tps-wrapper-card">
                                        <div className="tps-label">Added on:</div>
                                        <div className="tps-values">
                                            {' '}
                                            <span className="sr-bold">
                                                {' '}
                                                {moment(data.createdAt).format('DD MMM, Y')}{' '}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="tps-wrapper tps-wrapper-last">
                                    <div className="tps-wrapper-card">
                                        <div className="tps-label">Experience:</div>
                                        <div className="tps-values">
                                            {data.totalExperience || data.experience || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="tps-wrapper-card tps-wrapper-card-notice">
                                        <div className="tps-label">Notice Period:</div>
                                        <div className="tps-values">{data.noticePeriod || 'N/A'}</div>
                                    </div>
                                    {parseInt(data.archiveReason) ? (
                                        <div className="tps-wrapper-card">
                                            <div className="tps-label">Archive Reason:</div>
                                            <div className="tps-values">
                                                <span className="sr-bold">
                                                    {ARCHIVE_REASONS[parseInt(data.archiveReason) - 1].value}
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </Bottom>
                        </Card>
                        {data.tags && data.tags.length ? (
                            <TagBar>
                                {this.props.bulkMode ? (
                                    <TagIcon />
                                ) : (
                                    <StyledButton
                                        capitalize
                                        type="inverted"
                                        width="80px"
                                        height="30px"
                                        className="button"
                                        onClick={() => this.setState({ addTagModal: true })}
                                    >
                                        <TagIcon />
                                        Add Tag
                                    </StyledButton>
                                )}
                                <div>
                                    {this.state.showMore
                                        ? data.tags && data.tags.map((tag, index) => <span key={index}>{tag}</span>)
                                        : data.tags &&
                                        data.tags.slice(0, 7).map((tag, index) => <span key={index}>{tag}</span>)}
                                    {data.tags && data.tags.length > 7 ? (
                                        <a onClick={() => this.setState({ showMore: !this.state.showMore })}>
                                            {this.state.showMore
                                                ? 'Show less'
                                                : `+${data.tags && data.tags.length - 7} more`}
                                        </a>
                                    ) : null}
                                </div>
                            </TagBar>
                        ) : null}
                    </div>
                </Body>

                <Modal
                    type={MODAL_TYPES.ADD_CANDIDATE_DETAILS}
                    visible={this.state.iCandidateModal}
                    candidateData={this.state.cData}
                    onCancel={this.handleClose}
                    onConfirm={this.handleAddCandidateDetails}
                    callInProgress={false}
                    onNext={this.nextIncomplete}
                    hasNextData={!isObjectEmpty(this.state.nextData)}
                    wrapClassName={this.state.resumeLink && !isMobileDevice ? 'complete-profile' : 'incomplete-profile'}
                />

                <Modal
                    type={MODAL_TYPES.UPDATE_CANDIDATE}
                    visible={this.state.moveOpeningModal}
                    onConfirm={(data, jobPayload) => this.updateCandidate(data, jobPayload)}
                    onCancel={this.handleClose}
                    selectionSize={1}
                    selected={{ [data.objectId]: data }}
                    source={this.state.modalType}
                />

                <Modal
                    type={MODAL_TYPES.ADD_TAG}
                    visible={this.state.addTagModal}
                    selectedCandidates={[
                        {
                            ...data,
                            tags: (data.tags && data.tags.map((tag, index) => ({ tagId: index, name: tag }))) || [],
                        },
                    ]}
                    updateTags={input => {
                        this.setState({ addTagModal: false })
                        this.updateTags({
                            ...input,
                            jobApplicationIdList: [data.jobApplicationId],
                        })
                    }}
                    onCancel={this.handleClose}
                />

                {this.state.resumeLink.length && !isMobileDevice ? (
                    <Drawer
                        placement="right"
                        closable={false}
                        onClose={this.handleClose}
                        visible={this.state.viewResume}
                        width={'50%'}
                        mask={!this.state.iCandidateModal}
                        bodyStyle={{ padding: 0 }}
                        maskClosable={!this.state.iCandidateModal}
                        zIndex={1500}
                        maskStyle={{ pointerEvents: 'initial' }}
                        destroyOnClose={true}
                    >
                        {/*TODO: Check the styling on big screen, use % if required*/}
                        <Button
                            className="new-tab right28"
                            onClick={() =>
                                this.state.resumeLink.includes('.doc')
                                    ? window.open(
                                        `https://docs.google.com/viewerng/viewer?url=${this.state.resumeLink}`,
                                        '_blank'
                                    )
                                    : window.open(`${this.state.resumeLink}`, '_blank')
                            }
                            type="primary"
                            size="large"
                        >
                            Open in new tab <Icon component={OpenInNewTabIcon} />
                        </Button>

                        {this.state.resumeLink.includes('.doc') ? (
                            <iframe
                                title="resume"
                                className="resume-preview"
                                style={{ width: '100%', height: '100vh' }}
                                src={`https://docs.google.com/gview?url=${this.state.resumeLink}&embedded=true`}
                            ></iframe>
                        ) : (
                            <iframe
                                title="resume"
                                className="resume-preview"
                                style={{ width: '100%', height: '100vh' }}
                                src={`${this.state.resumeLink}`}
                            ></iframe>
                        )}
                    </Drawer>
                ) : null}

                <Modal
                    type={MODAL_TYPES.ARCHIVE_REASON}
                    visible={this.state.archiveModal}
                    onCancel={this.handleClose}
                    onConfirm={(reason, sendEmailToCandidate) => this.handleArchive(reason, sendEmailToCandidate)}
                    callInProgress={this.props.clickedOnce}
                />

                <Modal
                    type={MODAL_TYPES.CONFIRM_REJECT_CANDIDATE}
                    visible={this.state.rejectCandidateModal}
                    onCancel={this.handleClose}
                    onConfirm={this.handleReject}
                    singleCandidate={true}
                    candidateName={data.name || 'the Candidate'}
                />

                <Modal
                    type={MODAL_TYPES.CONFIRM_DELETE_CANDIDATE}
                    visible={this.state.deleteCandidateModal}
                    onCancel={this.handleClose}
                    onConfirm={data => this.handleDelete(data)}
                    candidateName={data.name || 'the Candidate'}
                    candidateId={data.objectID}
                />

                <Modal
                    type={MODAL_TYPES.UNARCHIVE_CANDIDATE}
                    visible={this.state.showUnarchiveModal}
                    onConfirm={this.handleUnarchive}
                    onCancel={this.handleClose}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    const { Auth, Mixed, Job } = state
    return {
        allJobs: Job.allJobs,
        accessLevel: Auth.user.accessLevel,
        teamMembers: Mixed.teamMembers,
    }
}

export default connect(
    mapStateToProps,
    { EditCandidate }
)(TalentPoolCard)
