export const SOURCES = {
    UPDATE_CANDIDATE_STATUS: 'UPDATE_CANDIDATE_STATUS',
    MOVE_JOB: 'MOVE_JOB',
    CHANGE_JOB: 'CHANGE_JOB',
    CHANGE_STAGE: 'CHANGE_STAGE',
    UNJOIN: 'UNJOIN',
}

export const SOURCE_TEXT = {
    UPDATE_CANDIDATE_STATUS: {
        heading: 'UPDATE CANDIDATE STATUS',
        confirmText: 'UPDATE',
    },
    MOVE_JOB: {
        heading: 'MOVE CANDIDATES TO AN OPENING',
        confirmText: 'MOVE',
    },
    CHANGE_JOB: {
        heading: 'CHANGE CANDIDATES JOB OPENING',
        confirmText: 'UPDATE',
    },
    CHANGE_STAGE: {
        heading: 'CHANGE CANDIDATES JOB STAGE',
        confirmText: 'UPDATE',
    },
    UNJOIN: {
        heading: 'UNMARK JOINED',
        confirmText: 'UPDATE',
    },
}
