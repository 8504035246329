import React, { useState } from 'react'
import { Input } from 'antd'
import { PhoneInputSearch } from '../../Modals/AddCandidate/style'
import { countryCodes } from '../../Helpers/countryCode'

const StyledPhoneNumberInput = props => {
    const [inputValue, setInputValue] = useState('')

    const onSelected = (key, code) => props.onSelected({ key, code })
    return (
        <PhoneInputSearch>
            <Input
                size="large"
                value={inputValue}
                placeholder="Search country code"
                onChange={({ target: { value } }) => setInputValue(value)}
            />
            <div className="phone-code-list">
                {countryCodes
                    .filter(({ name, dial_code }) => {
                        if (parseInt(inputValue)) {
                            return dial_code.includes(inputValue)
                        }
                        return (
                            dial_code.toLowerCase().includes(inputValue.toLowerCase()) ||
                            name.toLowerCase().includes(inputValue.toLowerCase())
                        )
                    })
                    .map(code => (
                        <p key={code.code} onClick={() => onSelected(code.code, code.dial_code)}>
                            {code.name} ({code.dial_code})
                        </p>
                    ))}
            </div>
        </PhoneInputSearch>
    )
}

export default StyledPhoneNumberInput
