import actions from './actions'
import _ from 'lodash'

import { PURGE } from 'redux-persist'

const initState = { doSelectTests: [], teamMembers: [], cachedData: {} }

export default function jobReducer(state = initState, action) {
    switch (action.type) {
        case PURGE:
            return initState
        case actions.SET_DOSELECT_TEST: {
            return { ...state, doSelectTests: action.payload }
        }
        case actions.SET_UNBERRY_POSITION: {
            return { ...state, unberryPositions: action.payload }
        }

        case actions.SET_TEAM_MEMBER: {
            return { ...state, teamMembers: action.payload }
        }

        case actions.ADD_TEAM_MEMBER: {
            return { ...state, teamMembers: [...state.teamMembers, action.payload] }
        }

        case actions.REMOVE_TEAM_MEMBER: {
            let teamMembers = state.teamMembers.filter(x => x._id !== action.payload)
            return { ...state, teamMembers }
        }

        case actions.UPDATE_TEAM_MEMBER: {
            let { teamMembers } = state
            if (
                action.payload.hasOwnProperty('updateJob') &&
                action.payload.updateJob &&
                action.payload.hasOwnProperty('isRemoveCoordinator')
            ) {
                teamMembers.map(value => {
                    if (value._id === action.payload._id) {
                        const ind = value.accessibleJobs.indexOf(action.payload.jobId)
                        if (ind > -1) {
                            return value.accessibleJobs.splice(ind, 1)
                        }
                    } else return value
                })
            } else if (action.payload.hasOwnProperty('updateJob') && action.payload.updateJob) {
                teamMembers.map(value => {
                    if (value._id === action.payload._id) return value.accessibleJobs.push(action.payload.jobId)
                    else return value
                })
            } else {
                teamMembers = teamMembers.map(x => {
                    if (x._id === action.payload._id) {
                        x = { ...x, ...action.payload }
                    }
                    return x
                })
            }
            return { ...state, teamMembers }
        }

        case actions.MODIFY_CACHE_DATA: {
            const { payload, key, event } = action.data
            const { cachedData } = state
            if (event === 'DELETE') delete cachedData[key]
            else cachedData[key] = payload
            return { ...state, cachedData }
        }

        default:
            return state
    }
}
