import apiCaller from '../../apiCaller'
import { warning } from '../../../services/Notification'
import _ from 'lodash'
import { actions } from '../../../redux/todo/actions'
import { store } from '../../../redux/store'
import { REFINE_DAYS } from '../../../container/Helpers/constants'
import moment from 'moment'

const { restricted } = apiCaller.type
const { formatError } = apiCaller
const baseUrl = `todo/`

export const GetList = ({ type, filter, refineType, page, rescheduled }) => async dispatch => {
    try {
        const {
            user: { companyId },
        } = store.getState().Auth

        if (!page) dispatch({ type: actions.SET_CALL_IN_PROGRESS, payload: { progress: true } })

        refineType = updateDateTime(refineType)
        const { data } = await restricted.get(
            `${baseUrl}list/${companyId}?type=${type}${filter ? `&filter=${filter}` : ''}${
                refineType ? `&startDateTime=${refineType.start}&endDateTime=${refineType.end}` : ''
            }${page ? `&page=${page}` : ''}${rescheduled ? `&rescheduled=${rescheduled}` : ''}`
        )

        dispatch({ type: actions.GET_INTERVIEWS, payload: { data, page } })
        if (!page) dispatch({ type: actions.SET_CALL_IN_PROGRESS, payload: { progress: false } })
    } catch (error) {
        warning(formatError(error))
    }
}

export const GetCount = (options = {}) => async dispatch => {
    let { refineType, rescheduled } = options
    try {
        const {
            user: { companyId },
        } = store.getState().Auth

        let params
        refineType = updateDateTime(refineType)
        if (refineType && rescheduled) {
            params = `?startDateTime=${refineType.start}&endDateTime=${refineType.end}&rescheduled=${rescheduled}`
        } else if (refineType) {
            params = `?startDateTime=${refineType.start}&endDateTime=${refineType.end}`
        } else if (rescheduled) {
            params = `?rescheduled=${rescheduled}`
        }

        const { data } = await restricted.get(`${baseUrl}list/count/${companyId}${params ? params : ''}`)
        dispatch({ type: actions.SET_TOTAL_COUNTS, payload: { data } })
    } catch (error) {
        warning(formatError(error))
    }
}

const updateDateTime = refineType => {
    if (refineType) {
        switch (refineType.key) {
            case REFINE_DAYS.TODAY.key:
                refineType.start = moment()
                    .startOf('day')
                    .toISOString()
                refineType.end = moment()
                    .endOf('day')
                    .toISOString()
                break
            case REFINE_DAYS.TOMORROW.key:
                refineType.start = moment()
                    .add(1, 'day')
                    .startOf('day')
                    .toISOString()
                refineType.end = moment()
                    .add(1, 'day')
                    .endOf('day')
                    .toISOString()
                break
            case REFINE_DAYS.WEEK.key:
                refineType.start = moment()
                    .startOf('isoWeek')
                    .toISOString()
                refineType.end = moment()
                    .endOf('isoWeek')
                    .toISOString()
                break
            case 'CUSTOM':
                break
            default:
                refineType = null
        }
    }
    return refineType
}
