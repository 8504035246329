import React, { Component } from 'react'
import './../style.css'
import { Container } from './../style'
import { Row, Col, Form, Typography, Input, Icon } from 'antd'
import { warning, success } from '../../../../services/Notification'
import { ReactComponent as Up } from '../../../../assets/svg/career-up.svg'
import { ReactComponent as Down } from '../../../../assets/svg/career-down.svg'
import { ReactComponent as Edit } from '../../../../assets/svg/career-edit.svg'
import { ReactComponent as Delete } from '../../../../assets/svg/career-delete.svg'
import { SECTION_LIMITS, REQUIRED_ERRORS } from '../DataConfigs'
import SaveContainer from './SaveContainer'
import { Modal } from '../../../Modals'
import { MODAL_TYPES } from '../../../Helpers/ModalTypes'

const { REQUIRED_TITLE_ERROR, REQUIRED_DESC_ERROR } = REQUIRED_ERRORS
const { Text } = Typography
const { TextArea } = Input

class NewSection extends Component {
    state = {
        editFlag: false,
        showDeleteWarning: false,
    }

    // @@@VASAN : change the name to onSave everywhere
    onSubmit = e => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    values.position = this.props.index
                    values.key = this.props.newSection.key

                    // VALIDATION CHECK: both the fields cannot be empty for saving.
                    if (values.title.length === 0 && values.description.length === 0) {
                        warning(SECTION_LIMITS.EMPTY_ERROR)
                        return null
                    }

                    this.props.onSubmit(values, this.props.index, 'section')
                    this.setState({ editFlag: false })
                    this.props.updateEditModeCount('section', 1, this.props.newSection.key)
                    success(`${values.title ? values.title + ' as ' : ''}NEW SECTION  saved successfully`)
                } catch (err) {
                    warning(err.message || err)
                }
            }
        })
    }

    onCancel = () => {
        const { newSection } = this.props
        const { title, description } = newSection
        this.props.form.setFieldsValue({ title, description })
        this.setState({ editFlag: false })
        this.props.updateEditModeCount('section', -1, this.props.newSection.key)
    }

    moveUp = () => {
        this.props.moveUp(this.props.index)
    }

    moveDown = () => {
        this.props.moveDown(this.props.index)
    }

    deleteSection = () => {
        this.props.deleteSection(this.props.index)
        this.setState({ showDeleteWarning: false })
        this.props.updateEditModeCount('section', -1, this.props.newSection.key)
    }

    showDeleteWarning = () => {
        this.setState({ showDeleteWarning: true })
    }

    edit = () => {
        this.setState({ editFlag: true })
        this.props.updateEditModeCount('section', 0, this.props.newSection.key)
    }

    handleCancel = () => {
        this.setState({ showDeleteWarning: false })
        this.props.updateEditModeCount('section', -1, this.props.newSection.key)
    }

    handleChange = value => {
        this.setState({ editFlag: true })
        this.props.updateEditModeCount('section', 0, this.props.newSection.key)
    }

    validator = () => {
        const title = this.props.form.getFieldValue('title')
        const description = this.props.form.getFieldValue('description')
        const isTitleValid = title && title.length > 0
        const isDescValid = description && description.length > 0

        return isTitleValid || isDescValid
    }

    render() {
        const { getFieldDecorator } = this.props.form

        const { newSection } = this.props

        return (
            <Container key={newSection.key.toString()}>
                <Row gutter={48}>
                    <Col span={12}>
                        <Text className="carrer-cont-title">NEW SECTION HEADING</Text>
                    </Col>
                    <Col span={12}>
                        <div className="career-cont-btn">
                            {this.props.index >= 1 ? (
                                <div className="career-section-icons" onClick={this.moveUp}>
                                    <Icon className="career-icons" component={Up} />
                                </div>
                            ) : null}
                            {this.props.index < this.props.length - 1 ? (
                                <div className="career-section-icons" onClick={this.moveDown}>
                                    <Icon className="career-icons" component={Down} />
                                </div>
                            ) : null}
                            <div className="career-section-icons" onClick={this.edit}>
                                <Icon className="career-icons" component={Edit} />
                            </div>
                            <div className="career-section-icons" onClick={this.showDeleteWarning}>
                                <Icon className="career-icons" component={Delete} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col span={12}>
                        <Text className="carrer-cont-title">Title</Text>
                        <Form.Item>
                            {getFieldDecorator('title', {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: newSection.title || '',
                                rules: [
                                    {
                                        max: SECTION_LIMITS.TITLE,
                                        message: SECTION_LIMITS.TITLE_ERROR,
                                    },
                                    {
                                        required: this.validator(),
                                        whitespace: true,
                                        message: REQUIRED_TITLE_ERROR,
                                    },
                                ],
                            })(<Input size="large" placeholder="Enter your title" onChange={this.handleChange} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text className="carrer-cont-title">Description</Text>
                        <Form.Item>
                            {getFieldDecorator('description', {
                                validateTrigger: ['onChange', 'onBlur'],
                                initialValue: newSection.description || '',
                                rules: [
                                    {
                                        required: this.validator(),
                                        whitespace: true,
                                        message: REQUIRED_DESC_ERROR,
                                    },
                                    {
                                        max: SECTION_LIMITS.DESCRIPTION,
                                        message: SECTION_LIMITS.DESCRIPTION_ERROR,
                                    },
                                ],
                            })(<TextArea row={4} placeholder="Enter your description" onChange={this.handleChange} />)}
                        </Form.Item>
                    </Col>
                </Row>
                {this.state.editFlag && <SaveContainer onSubmit={this.onSubmit} onCancel={this.onCancel} />}
                <Modal
                    type={MODAL_TYPES.WARNING_DELETE_SECTION}
                    visible={this.state.showDeleteWarning}
                    onCancel={this.handleCancel}
                    onConfirm={this.deleteSection}
                />
            </Container>
        )
    }
}

export default Form.create({ name: 'newSection' })(NewSection)
