const timestampArray = []

const hourArray = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
const minuteArray = ['00', '30']
const meridianArray = ['am', 'pm']

meridianArray.forEach(meri => {
    hourArray.forEach(hour => {
        minuteArray.forEach(minute => {
            const timeObject = {}
            timeObject.time = `${hour}:${minute}`
            timeObject.meridian = meri
            timestampArray.push(timeObject)
        })
    })
})

export const timestamps = timestampArray
