import React from 'react'
import { connect } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { BoardContainer, HeaderContainer } from './style'
import { Button, Typography, Icon, Select, Radio, Popover, Menu, Tooltip } from 'antd'
import './style.css'
import Column from './column'
import AddIcon from '../../../assets/Icons/Talent Pool/Add'
import DropdownIcon from '../../../assets/Icons/Board/Dropdown'
import AtsBoardViewIcon from '../../../assets/Icons/Board/AtsBoardView'
import ATSCLViewIcon from '../../../assets/Icons/Board/ATSCLView'
import {
    GetATSBoardData,
    UpdateCandidateStage,
    GetATSBoardNextData,
    GetATSBoardApplication,
    GetJobById,
} from '../../../services/api'
import { UpdateStages, DeleteStage } from '../../../services/NetworkCalls/Interview'
import { GetJobDetails } from '../../../services/NetworkCalls/Job'

import moment from 'moment'
import { store } from '../../../redux/store'
import { success, warning } from '../../../services/Notification'
import SearchBox from './algoliaSearchBox'
import { LogE } from './../../Helpers/errorHandler'
import { randomString, validateJobId } from '../../Helpers/utils'
import EmptyColumn from './emptyColumn'
import _ from 'lodash'
import { HotKeys } from 'react-keyboard'
import { keyMap, access } from '../../Helpers/KeyMap'
import { timeFormat } from '../../Helpers/times'
import { Modal } from '../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import actionsCandidate from '../../../redux/candidate/actions'
import { FILE_STATUS } from '../../Helpers/constants'
import { VIEW_TYPES } from './constants'

class Board extends React.Component {
    constructor(props) {
        super(props)
        this.getJobDetails = this.getJobDetails.bind(this)
        this.allowShortcuts = store.getState().Auth.user.allowShortcuts
        this.state = {
            accessLevel: store.getState().Auth.user.accessLevel,
            jobDetails: {},
            stagesMap: [],
            cardsMap: {},
            stageForm: {
                stageName: '',
                stagePosition: 0,
                hasInterview: true,
                hasTest: false,
                doSelect: {
                    status: false,
                    slug: undefined,
                },
                unberry: {
                    status: false,
                    positionId: undefined,
                },
                assesmentType: undefined,
            },
            previewTask: [],
            editStagePos: null,
            showStageModal: false,
            isStageModalInEdit: false,
            editableStageName: '',
            addCandidateModal: false,
            jobId: props.match.params.jobId,
            // jobId value updated in componentDidUpdate also
            isJobValid: validateJobId(props.match.params.jobId),
            hasDoselectCredentials: false,
            hasUnberryCredentials: false,
            searchCandidate: props.location.state ? props.location.state.searchCandidate : null,
            highlightStage:
                props.location.state && props.location.state.highlightStage ? props.location.state.stageId : null,
            isLoading: true,
            isCardsAbsent: false,
            filterName: VIEW_TYPES.LIVE.name,
            filterApplied: VIEW_TYPES.LIVE.key,
            noCards: false,
            filteredCards: false,
            activeCards: true,
            currentJobStatus: 1,
            candidateStatusObject: {},
            currentJobDetails: {},
            stages: [],
            inputFocused: false,
            error: false,
        }
    }

    componentDidMount() {
        this.getJobDetails()
        this.props.allowSwitchTabs(true)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.jobId !== this.props.match.params.jobId) {
            this.updateNewJob()
        }
    }

    updateNewJob = async () => {
        const { jobId } = this.props.match.params
        await this.setState({ jobId, isJobValid: validateJobId(jobId) })
        this.getJobDetails(this.state.filterApplied)
    }

    handleInputFocus = inputFocused => {
        this.setState({ inputFocused })
        this.props.allowSwitchTabs(!inputFocused)
    }

    calculateStages = stagesMap => {
        const { filterApplied } = this.state
        let stages = [...stagesMap]
        if (![VIEW_TYPES.LIVE.key, VIEW_TYPES.ALL.key].includes(filterApplied))
            stages = stages.filter(stage => stage.title !== 'JOINED')
        return stages
    }

    formatInterview = interview => {
        const { teamMembers } = store.getState().Mixed
        let isInterviewUpcoming = true

        const dt = moment(interview.interviewDate).format('DD MMM, YYYY')
        let interviewAt = [moment(interview.interviewStartTime).format(timeFormat), dt]

        const interviewDatePassed = moment()
            .startOf('day')
            .isAfter(moment(interview.interviewDate), 'date')
        const interviewDateToday = moment()
            .startOf('day')
            .isSame(moment(interview.interviewDate), 'date')
        const interviewTimePassed = moment().isAfter(moment(interview.interviewStartTime), 'hour')

        if ((interviewDateToday && interviewTimePassed) || interviewDatePassed) {
            isInterviewUpcoming = false
        }

        let coordinators = interview.interviewerIds
            ? teamMembers.filter(mem => interview.interviewerIds.includes(mem._id))
            : teamMembers.filter(mem => interview.interviewers.map(interviewer => interviewer._id).includes(mem._id))
        coordinators = coordinators.map(coordinator => coordinator.fullName || coordinator.email)

        return {
            interviewStageId: interview.interviewStageId || (interview.interviewStage && interview.interviewStage._id),
            interviewAt,
            interviewDetail: {
                _id: interview._id,
                interviewType: interview.interviewType,
                interviewerIds: interview.interviewerIds
                    ? interview.interviewerIds.map(interviewerId => ({
                          _id: interviewerId,
                      }))
                    : interview.interviewers.map(interviewer => interviewer._id),
                interviewDate: interview.interviewDate,
                interviewStartTime: interview.interviewStartTime,
                interviewEndTime: interview.interviewEndTime,
                interviewerTodo: interview.interviewerTodo,
                myTodo: interview.myTodo,
                meetingDetails: interview.meetingDetails,
                interviewEventId: interview.interviewEventId,
                interviewEventUserId: interview.interviewEventUserId,
                emailSubject: interview.emailSubject,
                cc: interview.cc,
            },
            interviewId: interview._id,
            interviewStatus: ['pending', 'completed', 'canceled'].indexOf(interview.status),
            isInterviewUpcoming,
            hasFeedback: interview.feedbacks ? !!interview.feedbacks.length : false,
            coordinators,
        }
    }

    formatApplication = app => {
        const { searchCandidate } = this.state
        app.interviews = app.interviews.map(this.formatInterview)
        const activeTest =
            app.doSelect && app.doSelect[0]
                ? app.doSelect[app.doSelect.length - 1]
                : app.unberry && app.unberry[0]
                ? app.unberry[app.unberry.length - 1]
                : null
        return {
            id: app.candidateId,
            title: app.candidate[0].name,
            phone: app.candidate[0].phone,
            email: app.candidate[0].email,
            status: app.status,
            skypeId: app.candidate[0].skypeId,
            jobId: this.state.jobId,
            jobAppId: app._id,
            isRejected: app.isRejected && app.isRejected.status,
            stared: app.isFavourite,
            totalExperience: app.candidate[0].totalExperience,
            createdAt: app.createdAt,
            interviews: app.interviews,
            hasOneFeedback: app.feedbacks && !!app.feedbacks.length,
            highlight: searchCandidate === app.candidateId,
            stageId: app.stageId,
            feedbacks: app.feedbacks || [],
            notes: app.notes,
            assignedTo: app.assignedTo,
            showTest: activeTest && activeTest.status === 0,
            testStatus: activeTest && activeTest.status,
            isTestResent: activeTest && activeTest.resent,
            testHasExpired:
                activeTest &&
                activeTest.status === 0 &&
                activeTest.expiryDate &&
                moment(activeTest.expiryDate).isBefore(moment()),
            testSlug: activeTest && activeTest.slug,
        }
    }

    arrayToObject = array => {
        const obj = {}

        array.forEach(each => {
            const { candidateId, status } = each
            obj[candidateId._id] = { _id: candidateId._id, status }
        })

        return obj
    }

    getJobDetails = async filterValue => {
        try {
            let currentJobOpening = await GetJobById(this.state.jobId)
            const selectedJobData = await this.props.GetJobDetails(
                this.state.jobId,
                store.getState().Auth.user.companyId
            )
            this.setState({
                currentJobStatus: currentJobOpening.jobDetails.status,
                currentJobDetails: selectedJobData,
            })
            let initialData = ''
            if (filterValue !== undefined) {
                this.setState({ filterApplied: filterValue })
                initialData = await GetATSBoardData(this.state.jobId, filterValue)

                const cardLength = initialData.interviewStages.filter(value => value.totalApplications !== 0).length
                if (cardLength === 0) this.setState({ noCards: true })
            } else {
                initialData = await GetATSBoardData(this.state.jobId, VIEW_TYPES.LIVE.key)

                const cardLength = initialData.interviewStages.filter(value => value.totalApplications !== 0).length
                if (cardLength === 0) this.setState({ noCards: true })

                const activeCards = initialData.interviewStages.filter(value => value.totalApplications !== 0).length
                if (activeCards === 0) this.setState({ activeCards: false })
            }

            const hasDoselectCredentials = initialData.hasDoselectCredentials
            const hasUnberryCredentials = initialData.hasUnberryCredentials

            const cardsMap = {}
            initialData.jobApplications.forEach(app => {
                cardsMap[app.candidateId] = this.formatApplication(app)
            })

            let scrollToCurrentStageId = null
            if (this.state.searchCandidate) {
                const searchCandidate = cardsMap[this.state.searchCandidate]
                scrollToCurrentStageId = (searchCandidate && searchCandidate.stageId) || null
            } else if (this.state.highlightStage) {
                scrollToCurrentStageId = this.state.highlightStage
            }

            let candidateStatusObject = {}

            const stagesMap = initialData.interviewStages.map(task => {
                candidateStatusObject = { ...candidateStatusObject, ...this.arrayToObject(task.allApplications) }
                return {
                    id: task._id,
                    title: task.stageName,
                    stagePosition: task.stagePosition,
                    cards: initialData.jobApplications
                        .filter(app => app.stageId === task._id)
                        .map(app => app.candidateId),
                    hasInterview: task.hasInterview,
                    hasTest: !!(task.doSelect && task.doSelect.status) || !!(task.unberry && task.unberry.status),
                    totalApplications: task.totalApplications,
                    assignedTo: task.assignedTo,
                    highlightStage: task._id === this.state.highlightStage,
                    doSelect: task.doSelect,
                    unberry: task.unberry,
                    allApplications: task.allApplications,
                }
            })

            let stages = [...stagesMap]
            if (![VIEW_TYPES.LIVE.key, VIEW_TYPES.ALL.key].includes(filterValue || VIEW_TYPES.LIVE.key))
                stages = stagesMap.filter(stage => stage.title !== 'JOINED')

            const filteredCards = initialData.interviewStages.filter(value => value.totalApplications !== 0).length
            if (filteredCards === 0) {
                this.setState({ filteredCards: true })
            }

            const cardsAvailable = stagesMap.filter(task => {
                if (task.cards.length) {
                    return task
                }
            }).length
            if (!cardsAvailable) {
                this.setState({ isCardsAbsent: true })
            } else {
                this.setState({ isCardsAbsent: false })
            }

            document.title = `${initialData.jobDetails[0].jobTitle} | SpringRecruit`
            await this.setState({
                cardsMap,
                stagesMap,
                jobDetails: initialData.jobDetails[0],
                hasDoselectCredentials,
                hasUnberryCredentials,
                isLoading: false,
                candidateStatusObject,
                stages,
            })

            if (this.state.searchCandidate && !cardsMap[this.state.searchCandidate]) {
                this.onSearchResult(this.state.searchCandidate, null)
            }

            if (scrollToCurrentStageId) {
                this.scrollToStage(scrollToCurrentStageId)
            }
        } catch (err) {
            LogE('pages-board-getJobDetails', '', err)
            if (err.message) warning(err.message)
            else success('Opening has been archived or deleted')
            this.props.history.push('/openings')
        }
    }

    getNextDataSet = async (stageId, page) => {
        try {
            let { stagesMap, cardsMap, filterApplied } = this.state
            const nextSet = await GetATSBoardNextData(this.state.jobId, stageId, page, filterApplied)

            stagesMap = stagesMap.map(task => {
                if (task.id === stageId) {
                    task.cards = task.cards.concat(nextSet.data.map(x => x.candidateId))
                    task.cards = task.cards.filter((x, index, self) => self.indexOf(x) === index)
                }
                return task
            })

            nextSet.data.forEach(app => {
                cardsMap[app.candidateId] = this.formatApplication(app)
            })

            this.setState({ cardsMap, stagesMap, stages: this.calculateStages(stagesMap) })
        } catch (err) {
            warning(err.message)
        }
    }

    candidateEvents = (candidateId, type, value, stageId) => {
        let { cardsMap, noCards, isCardsAbsent, filterApplied, candidateStatusObject } = JSON.parse(
            JSON.stringify(this.state)
        )
        let stagesMap = this.state.stagesMap.slice()

        if (type === 'doSelectTest') {
            cardsMap[candidateId].showTest = true
            cardsMap[candidateId].testStatus = 0
            cardsMap[candidateId].isTestResent = false
            cardsMap[candidateId].testHasExpired = false
            cardsMap[candidateId].testSlug = value
        } else if (type === 'resendDoSelectTest') {
            cardsMap[candidateId].isTestResent = true
            cardsMap[candidateId].testHasExpired = false
        } else if (type === 'interview') {
            const int = this.formatInterview(value)
            if (value.isRescheduled) {
                cardsMap[candidateId].interviews = cardsMap[candidateId].interviews.map(x => {
                    const hasFeedback = x.hasFeedback
                    x = x.interviewId === value._id ? int : x
                    x.hasFeedback = hasFeedback
                    return x
                })
            } else {
                cardsMap[candidateId].interviews.push(int)
            }
        } else if (
            ((type === 'archive' || type === 'reject') && filterApplied !== VIEW_TYPES.ALL.key) ||
            ((type === 'active' || type === 'join') &&
                ![VIEW_TYPES.LIVE.key, VIEW_TYPES.ALL.key].includes(filterApplied))
        ) {
            stagesMap = stagesMap.map(task => {
                if (task.id === value) {
                    task.totalApplications -= 1
                    task.cards = task.cards.filter(x => x !== candidateId)
                    task.allApplications = task.allApplications.filter(
                        application => application.candidateId._id !== candidateId
                    )
                }
                return task
            })
            cardsMap = _.omit(cardsMap, candidateId)
        } else if (type === 'join' && [VIEW_TYPES.LIVE.key, VIEW_TYPES.ALL.key].includes(filterApplied)) {
            let joinedStageId
            stagesMap = stagesMap.map(task => {
                if (task.id === value) {
                    task.totalApplications -= 1
                    task.cards = task.cards.filter(x => x !== candidateId)
                    task.allApplications = task.allApplications.filter(
                        application => application.candidateId._id !== candidateId
                    )
                } else if (task.title === 'JOINED') {
                    joinedStageId = task.id
                    task.totalApplications += 1
                    task.cards.push(candidateId)
                    task.allApplications.push({
                        _id: cardsMap[candidateId].jobAppId,
                        status: 3,
                        jobId: cardsMap[candidateId].jobId,
                        stageId: task.id,
                        candidateId: {
                            _id: cardsMap[candidateId].id,
                        },
                    })
                }
                return task
            })

            cardsMap[candidateId] = {
                ...cardsMap[candidateId],
                status: 3,
                stageId: joinedStageId,
            }

            candidateStatusObject[candidateId] = {
                _id: candidateId,
                status: 3,
            }
        } else if (type === 'active' && [VIEW_TYPES.LIVE.key, VIEW_TYPES.ALL.key].includes(filterApplied)) {
            const isMovable = candidateStatusObject[candidateId].status === 3
            if (isMovable) {
                let newStageId
                stagesMap = stagesMap.map(task => {
                    if (task.id === value) {
                        task.totalApplications -= 1
                        task.cards = task.cards.filter(x => x !== candidateId)
                        task.allApplications = task.allApplications.filter(
                            application => application.candidateId._id !== candidateId
                        )
                    } else if (task.id === stageId) {
                        newStageId = task.id
                        task.totalApplications += 1
                        task.cards.push(candidateId)
                        task.allApplications.push({
                            _id: cardsMap[candidateId].jobAppId,
                            status: 1,
                            jobId: cardsMap[candidateId].jobId,
                            stageId: task.id,
                            candidateId: {
                                _id: cardsMap[candidateId].id,
                            },
                        })
                    }
                    return task
                })

                cardsMap[candidateId] = {
                    ...cardsMap[candidateId],
                    status: 1,
                    stageId: newStageId,
                }

                candidateStatusObject[candidateId] = {
                    _id: candidateId,
                    status: 1,
                }
            } else {
                stagesMap = stagesMap.map(task => {
                    if (task.id === value) {
                        task.allApplications.forEach(application => {
                            if (application.candidateId._id === candidateId) {
                                application.status = 1
                            }
                        })
                    }
                    return task
                })

                cardsMap[candidateId] = {
                    ...cardsMap[candidateId],
                    status: 1,
                }

                candidateStatusObject[candidateId] = {
                    _id: candidateId,
                    status: 1,
                }
            }
        } else if ((type === 'archive' || type === 'reject') && filterApplied === VIEW_TYPES.ALL.key) {
            stagesMap = stagesMap.map(task => {
                if (task.id === value) {
                    task.allApplications.forEach(application => {
                        if (application.candidateId._id === candidateId) {
                            application.status = type === 'archive' ? 0 : 2
                        }
                    })
                }
                return task
            })

            cardsMap[candidateId] = {
                ...cardsMap[candidateId],
                status: type === 'archive' ? 0 : 2,
            }

            candidateStatusObject[candidateId] = {
                _id: candidateId,
                status: type === 'archive' ? 0 : 2,
            }
        } else if (type === 'favourite') {
            cardsMap[candidateId].stared = value
        } else if (type === 'feedback') {
            cardsMap[candidateId].hasOneFeedback = true
            cardsMap[candidateId].feedbacks = [value].concat(cardsMap[candidateId].feedbacks)
            if (value.stageId) {
                cardsMap[candidateId].interviews = cardsMap[candidateId].interviews.map(int => {
                    if (int.interviewStageId === value.stageId) {
                        int.hasFeedback = true
                    }
                    return int
                })
            }
        } else if (type === 'removeHighlight') {
            cardsMap[candidateId].highlight = false
            stagesMap = stagesMap.map(task => {
                if (task.id === cardsMap[candidateId].stageId) {
                    const index = task.cards.indexOf(candidateId)
                    task.cards.splice(index, 1)
                    task.cards.splice(0, 0, candidateId)
                }

                return task
            })
        } else if (type === 'notes') {
            cardsMap[candidateId].notes = value
        } else if (type === 'assignTeamMember') {
            cardsMap[candidateId].assignedTo = value
        } else if (type === 'multipleTestAssigned') {
            value.candidates &&
                value.candidates.forEach(val => {
                    if (!val.error && !!val.id && cardsMap[val.id]) {
                        cardsMap[val.id].showTest = true
                        cardsMap[val.id].testStatus = 0
                        cardsMap[val.id].isTestResent = false
                        cardsMap[val.id].testHasExpired = false
                        cardsMap[val.id].testSlug = value.slug
                    }
                })
        } else if (type === 'rejectMultiple') {
            stagesMap = stagesMap.map(task => {
                if (task.id === value.stageId) {
                    task.totalApplications -= value.candidates.length
                    task.cards = task.cards.filter(
                        rejectedCandidates =>
                            !value.candidates
                                .map(selectedCandidates => selectedCandidates._id)
                                .includes(rejectedCandidates)
                    )
                }

                return task
            })
        } else if (type === 'multipleCandidatesMoved') {
            let hasAutoTest = null
            stagesMap = stagesMap.map(task => {
                if (task.id === value.newStageId) {
                    hasAutoTest = task.doSelect && task.doSelect.status && task.doSelect.slug
                    task.totalApplications += value.candidates.length
                    task.cards = value.candidates.concat(task.cards)
                    task.cards = task.cards.filter(x => !!cardsMap[x])
                    value.candidates.forEach(candidateId =>
                        task.allApplications.push({
                            _id: cardsMap[candidateId].jobAppId,
                            status: cardsMap[candidateId].status,
                            jobId: cardsMap[candidateId].jobId,
                            stageId: value.newStageId,
                            candidateId: {
                                _id: cardsMap[candidateId].id,
                            },
                        })
                    )
                }

                if (task.id === value.stageId) {
                    task.totalApplications -= value.candidates.length
                    task.cards = task.cards.filter(x => !value.candidates.includes(x))
                    task.allApplications = task.allApplications.filter(
                        application => !value.candidates.includes(application.candidateId._id)
                    )
                }

                return task
            })

            value.candidates.forEach(x => {
                cardsMap[x].stageId = value.newStageId
                if (hasAutoTest) {
                    cardsMap[x].showTest = true
                    cardsMap[x].testStatus = 0
                    cardsMap[x].isTestResent = false
                    cardsMap[x].testHasExpired = false
                    cardsMap[x].testSlug = hasAutoTest
                } else {
                    cardsMap[x].showTest = false
                }
            })
        } else if (type === 'assignStage') {
            stagesMap = stagesMap.map(task => {
                if (task.id === value.stageId) {
                    task.assignedTo = value.userId
                }

                return task
            })
        }
        noCards = Object.keys(cardsMap).length === 0
        isCardsAbsent = noCards
        this.setState({
            cardsMap,
            stagesMap,
            noCards,
            isCardsAbsent,
            candidateStatusObject,
            stages: this.calculateStages(stagesMap),
        })
    }

    handleCancel = () => {
        this.setState({
            previewTask: [],
            showStageModal: false,
            addCandidateModal: false,
            stageForm: {
                stageName: '',
                stagePosition: 0,
                hasInterview: true,
                hasTest: false,
                doSelect: {
                    status: false,
                    slug: undefined,
                },
                unberry: {
                    status: false,
                    positionId: undefined,
                },
                assesmentType: undefined,
            },
            isStageModalInEdit: false,
            editableStageName: '',
            editStagePos: null,
            error: false,
        })
    }

    onDragEnd = async result => {
        const { source, destination, type, draggableId } = result
        let { jobId, cardsMap, candidateStatusObject, stages } = this.state
        let stagesMap = this.state.stagesMap.slice()
        const stagesArray = stages.map(stage => stage.title)

        if (!destination) return
        if (stagesArray.includes('JOINED') && destination.index === stagesMap.length - 1) return

        if (source.index === destination.index && source.droppableId === destination.droppableId) return

        if (type === 'card') {
            cardsMap[draggableId].stageId = destination.droppableId
            stagesMap = stagesMap.map(task => {
                if (task.id === source.droppableId) {
                    task.cards.splice(source.index, 1)
                    task.totalApplications -= 1
                    task.allApplications = task.allApplications.filter(
                        application => application.candidateId._id !== draggableId
                    )
                }

                if (task.id === destination.droppableId) {
                    task.cards.splice(destination.index, 0, draggableId)
                    task.totalApplications += 1
                    task.allApplications.push({
                        _id: cardsMap[draggableId].jobAppId,
                        status: cardsMap[draggableId].status,
                        jobId: cardsMap[draggableId].jobId,
                        stageId: cardsMap[draggableId].stageId,
                        candidateId: {
                            _id: cardsMap[draggableId].id,
                        },
                    })
                    if (task.doSelect && task.doSelect.status && task.doSelect.slug) {
                        cardsMap[draggableId].showTest = true
                        cardsMap[draggableId].testStatus = 0
                        cardsMap[draggableId].isTestResent = false
                        cardsMap[draggableId].testHasExpired = false
                        cardsMap[draggableId].testSlug = task.doSelect.slug
                    } else if (task.doSelect && !task.doSelect.status) {
                        cardsMap[draggableId].showTest = false
                    }
                }

                return task
            })

            const joinedStage = stagesMap.find(stage => stage.title === 'JOINED')
            if (joinedStage.id === destination.droppableId) {
                candidateStatusObject[draggableId] = {
                    _id: draggableId,
                    status: 3,
                }
            } else if (joinedStage.id === source.droppableId && joinedStage.id !== destination.droppableId) {
                candidateStatusObject[draggableId] = {
                    _id: draggableId,
                    status: 1,
                }
            }

            this.setState({ stagesMap, cardsMap, stages: this.calculateStages(stagesMap) })

            if (source.droppableId !== destination.droppableId) {
                await UpdateCandidateStage({
                    jobId,
                    candidateId: draggableId,
                    stageId: destination.droppableId,
                })
            }
        } else {
            const sp = stagesMap.splice(source.index, 1)

            stagesMap.splice(destination.index, 0, sp[0])
            stagesMap[destination.index].stagePosition = destination.index + 1
            stagesMap[source.index].stagePosition = source.index + 1

            let stages = stagesMap.slice()

            stages = stages.map((task, index) => ({
                stageName: task.title,
                stageId: task.id,
                stagePosition: index + 1,
                hasInterview: task.hasInterview,
                unberry: task.unberry,
                doSelect: task.doSelect,
            }))
            this.setState({ stagesMap, stages: this.calculateStages(stagesMap) })
            await this.props.UpdateStages({ stages, jobId })
        }
    }

    addStage = () => {
        const { stagesMap, stageForm } = this.state
        const doSelectTests = store.getState().Mixed.doSelectTests
        const unberryPositions = store.getState().Mixed.unberryPositions

        stageForm.doSelect.slug = doSelectTests[0] && doSelectTests[0].slug
        stageForm.unberry.positionId = unberryPositions[0] && unberryPositions[0].positionId
        const previewTask = [{ id: 'new', title: '' }].concat(stagesMap)
        this.setState({ previewTask, showStageModal: true, stageForm })
    }

    addNewStage = async () => {
        if (this.state.error) return
        const { companyId } = store.getState().Auth.user
        const { stageForm, jobId, isStageModalInEdit, editStagePos, filterApplied } = this.state
        let stagesMap = this.state.stagesMap.slice()

        try {
            if (stageForm.stageName.trim().length < 1) {
                warning('The stage name cannot be empty')
                return
            }
            if (stageForm.hasTest && !stageForm.assesmentType) {
                warning('Please select assesment type')
                return
            }
            if (isStageModalInEdit) {
                const [oldStage] = stagesMap.splice(editStagePos, 1)

                if (stageForm.stageName === 'JOINED') {
                    stagesMap.splice(stagesMap.length, 0, {
                        ...oldStage,
                        ...stageForm,
                        title: stageForm.stageName,
                        id: stageForm.stageId,
                    })
                    stagesMap = stagesMap.map((stage, index) => ({
                        ...stage,
                        stagePosition: index + 1,
                    }))
                } else {
                    stagesMap.splice(stageForm.stagePosition, 0, {
                        ...oldStage,
                        ...stageForm,
                        title: stageForm.stageName,
                        id: stageForm.stageId,
                    })
                }
                const stages = stagesMap.map((stage, index) => ({
                    stageName: stage.title,
                    stageId: stage.id,
                    stagePosition: index + 1,
                    hasInterview: stage.hasInterview,
                    doSelect: stage.doSelect,
                    unberry: stage.unberry,
                }))
                await this.props.UpdateStages({ stages, jobId })
                success(`${stageForm.stageName} stage details updated successfully`)
            } else {
                let stages = stagesMap.map(task => ({
                    stageId: task.id,
                    stagePosition: task.stagePosition,
                    hasInterview: task.hasInterview,
                    stageName: task.title,
                }))
                if (stageForm.stageName === 'JOINED') {
                    stages.splice(stagesMap.length, 0, {
                        ...stageForm,
                    })
                } else {
                    stages.splice(stageForm.stagePosition, 0, {
                        ...stageForm,
                    })
                }
                stages = stages.map((task, index) => ({
                    ...task,
                    stagePosition: index + 1,
                }))
                let res = await this.props.UpdateStages({ stages, jobId })
                const stage = res.created[0]
                stagesMap.splice(stage.stagePosition - 1, 0, {
                    id: stage._id,
                    title: stage.stageName,
                    ...stage,
                    allApplications: [],
                    cards: [],
                    totalApplications: 0,
                })
                success(`Added the new stage ${stageForm.stageName} successfully`)
            }
        } catch (err) {
            warning(err.message || err)
        } finally {
            stagesMap = stagesMap.map((x, i) => ({
                ...x,
                stagePosition: i + 1,
            }))
            this.setState({ stagesMap, stages: this.calculateStages(stagesMap) })
            this.handleCancel()
        }
    }

    showAddCandidateModal = () => {
        const { jobId, stagesMap } = this.state
        store.dispatch({ type: actionsCandidate.CLEAR_CANDIDATES })
        store.dispatch({
            type: actionsCandidate.ADD_CANDIDATES,
            payload: { stageId: stagesMap[0].id, jobId, hasJobId: true },
        })

        this.setState({ addCandidateModal: true })
    }

    handleChange = name => event => {
        let { stageForm, previewTask, isStageModalInEdit, editableStageName, stagesMap, error } = this.state
        const pos = stageForm.stagePosition

        if (name === 'doSelectSlug') {
            stageForm.doSelect.slug = event
        } else if (name === 'unberryPosition') {
            stageForm.unberry.positionId = event
        } else if (name === 'assesmentType') {
            stageForm.assesmentType = event.target.value
            if (stageForm.assesmentType === 'unberry') {
                stageForm.unberry.status = true
                stageForm.doSelect.status = false
            } else if (stageForm.assesmentType === 'doSelect') {
                stageForm.unberry.status = false
                stageForm.doSelect.status = true
            }
        } else if (name === 'hasTest') {
            stageForm.hasTest = event.target.value
            if (stageForm.hasTest === false) {
                stageForm.unberry.status = false
                stageForm.doSelect = false
            }
        } else if (name !== 'hasInterview') {
            stageForm[name] = typeof event === 'object' ? event.target.value.toUpperCase() : event

            if (stageForm.stageName !== 'JOINED' && previewTask[previewTask.length - 2].title === 'JOINED')
                previewTask.splice(stagesMap.length, 1)
            else previewTask.splice(pos, 1)

            if (stageForm.stageName === 'JOINED')
                previewTask.splice(stagesMap.length, 0, {
                    id: 'new',
                    title: stageForm.stageName,
                })
            else
                previewTask.splice(stageForm.stagePosition, 0, {
                    id: 'new',
                    title: stageForm.stageName,
                })
            const stagesArray = stagesMap.map(stage => {
                if (stage.title !== editableStageName) return stage.title
            })
            if (stagesArray.includes(stageForm[name])) error = true
            else error = false
        } else {
            stageForm.hasInterview = event.target.value
        }

        if (stageForm.stageName === ' ') {
            stageForm.stageName = undefined
        }

        this.setState({ stageForm, previewTask, error })
    }

    formatUserCardData = data => {
        const { _id, name, phone, email, jobId, jobAppId, createdAt, stageId } = data

        return {
            id: _id,
            title: name,
            phone,
            email,
            jobId,
            jobAppId,
            isRejected: false,
            stared: false,
            createdAt,
            interviews: [],
            hasOneFeedback: false,
            highlight: false,
            stageId,
            feedbacks: [],
            notes: [],
        }
    }

    newCandidateAdded = res => {
        let { stagesMap, cardsMap, candidateStatusObject, jobId } = this.state
        const newStageMap = {}

        const candidateToAdd = res.candidates[0].filter(each => each.jobId === jobId && each.stageId)

        candidateToAdd.forEach(can => {
            const foundStageIndex = stagesMap.findIndex(each => each.id === can.stageId)
            if (foundStageIndex > -1) {
                const _stagesMap = stagesMap[foundStageIndex]
                const formatCardsData = {
                    candidateId: { _id: can._id },
                    jobId,
                    stageId: can.stageId,
                    status: 1,
                    _id: can.jobAppId,
                }
                stagesMap[foundStageIndex] = {
                    ..._stagesMap,
                    allApplications: [formatCardsData, ..._stagesMap.allApplications],
                    cards: [can._id, ..._stagesMap.cards],
                    totalApplications: ++_stagesMap.totalApplications,
                }
            }

            // update cardsMap
            cardsMap[can._id] = this.formatUserCardData(can)

            // update cardsMap
            candidateStatusObject[can._id] = { _id: can._id, status: 1 }
        })

        this.setState({
            stagesMap,
            stages: this.calculateStages(stagesMap),
            cardsMap,
            addCandidateModal: false,
            noCards: false,
            isCardsAbsent: false,
            candidateStatusObject,
        })
    }

    editStage = task => {
        const doSelectTests = store.getState().Mixed.doSelectTests
        const unberryPositions = store.getState().Mixed.unberryPositions
        const stageForm = {
            stageName: task.title,
            stagePosition: task.stagePosition - 1,
            hasInterview: task.hasInterview,
            hasTest: !!(task.doSelect && task.doSelect.status) || !!(task.unberry && task.unberry.status),
            stageId: task.id,
            doSelect: {
                status: task.doSelect ? task.doSelect.status : false,
                slug: task.doSelect ? task.doSelect.slug : doSelectTests[0] && doSelectTests[0].slug,
            },
            unberry: {
                status: task.unberry ? task.unberry.status : false,
                positionId: task.unberry
                    ? task.unberry.positionId
                    : unberryPositions[0] && unberryPositions[0].positionId,
            },
        }
        stageForm.assesmentType =
            stageForm.doSelect.status || stageForm.unberry.status
                ? stageForm.doSelect.status
                    ? 'doSelect'
                    : 'unberry'
                : undefined

        this.setState({
            editStagePos: stageForm.stagePosition,
            stageForm,
            showStageModal: true,
            isStageModalInEdit: true,
            editableStageName: task.title,
            previewTask: this.state.stagesMap.slice(),
        })
    }

    deleteStage = async task => {
        try {
            await this.props.DeleteStage(task.id, this.state.jobId)
            let stagesMap = this.state.stagesMap.filter(stage => stage.id !== task.id)
            stagesMap = stagesMap.map((stage, index) => ({
                ...stage,
                stagePosition: index + 1,
            }))
            this.setState({ stagesMap, stages: this.calculateStages(stagesMap) })
            success(`Deleted the stage ${task.title} successfully`)
        } catch (err) {
            warning('Error deleting stage. Try Again!')
        }
    }

    sortCards = (taskId, cards) => {
        let stagesMap = this.state.stagesMap.slice()
        stagesMap = stagesMap.map(stage => {
            if (stage.id === taskId) {
                stage.cards = cards
            }

            return stage
        })

        this.setState({ stagesMap, stages: this.calculateStages(stagesMap) })
    }

    onSearchResult = async (candidateId, selectedCandidateId) => {
        const { cardsMap } = this.state

        let stagesMap = this.state.stagesMap.slice()

        try {
            if (cardsMap[candidateId]) {
                cardsMap[candidateId].highlight = true
            } else {
                const { data } = await GetATSBoardApplication(candidateId)
                cardsMap[candidateId] = this.formatApplication(data)
                cardsMap[candidateId].highlight = true

                stagesMap = stagesMap.map(task => {
                    if (task.id === cardsMap[candidateId].stageId) {
                        task.cards.push(candidateId)
                    }
                    return task
                })
            }
        } catch (err) {
            warning(err.message || err)
        } finally {
            if (selectedCandidateId) {
                cardsMap[selectedCandidateId].highlight = false
            }
            const currentStageId = cardsMap[candidateId].stageId
            this.scrollToStage(currentStageId)
            if (cardsMap[candidateId].stageId === '5d68f2ae04bf65001fe76612') {
                stagesMap = this._sortStageCard({
                    stagesMap,
                    cardsMap,
                    stageId: cardsMap[candidateId].stageId,
                })
            }

            this.setState({ cardsMap, stagesMap, stages: this.calculateStages(stagesMap) })
        }
    }

    /**
     * Sorts the stages cards such that highlighted cards are shown first
     */
    _sortStageCard = data => {
        let { stagesMap, cardsMap, stageId } = data
        if (!stageId) {
            return stagesMap
        }

        if (!stagesMap) {
            stagesMap = this.state.stagesMap.splice()
        }

        if (!cardsMap) {
            cardsMap = JSON.parse(JSON.stringify(this.state.cardsMap))
        }

        let index = null
        let stageDetails = null
        for (let i = 0; i < stagesMap.length; i++) {
            if (stagesMap[i].id === stageId) {
                index = i
                stageDetails = stagesMap[i]
                break
            }
        }

        if (!index)
            //Stage not found
            return stagesMap

        stageDetails.cards.sort(function(firstCardId, secondCardId) {
            let firstCard = cardsMap[firstCardId]
            let secondCard = cardsMap[secondCardId]
            if (firstCard.highlight && !secondCard.highlight) {
                return -1
            } else if (
                (firstCard.highlight && secondCard.highlight) ||
                (!firstCard.highlight && !secondCard.highlight)
            ) {
                return 0
            } else if (!firstCard.highlight && secondCard.highlight) {
                return 1
            }
        })

        stagesMap[index] = stageDetails

        return stagesMap
    }

    clearResult = candidateId => {
        const { cardsMap } = this.state
        cardsMap[candidateId].highlight = false
        this.setState({ cardsMap })
    }

    scrollToStage = stageId => {
        const body = document.getElementById('ats-board')
        const currentStagePos = document.getElementById(stageId).offsetLeft
        body.scrollTo({
            left: currentStagePos - 50,
            top: 0,
            behavior: 'smooth',
        })
    }

    changeJob = async job => {
        this.props.history.push(`/openings/board/${job.key}`)
    }

    filterChange = filterApplied => {
        this.setState({ filterApplied, filterName: VIEW_TYPES[filterApplied].name, isLoading: true })
        this.getJobDetails(filterApplied)
    }

    liveCandidates = () => {
        this.setState({ filterName: 'Active Candidates', isLoading: true })
        this.getJobDetails(VIEW_TYPES.LIVE.key)
    }

    allCandidates = () => {
        this.setState({ filterName: 'All Candidates', isLoading: true })
        this.getJobDetails(VIEW_TYPES.ALL.key)
    }

    handlers = {
        toggleView: () => {
            if (this.allowShortcuts && !this.state.inputFocused)
                this.props.history.push(`/openings/list/${this.state.jobId}`)
        },
        search: e => {
            if (this.allowShortcuts && !this.state.inputFocused) {
                e.preventDefault()
                this.searchInput.focus({ preventScroll: true })
            }
        },
        createCandidate: () => {
            if (
                this.allowShortcuts &&
                !this.state.inputFocused &&
                store.getState().Auth.user.accessLevel <= access.opening.createCandidate
            )
                this.showAddCandidateModal()
        },
        createStage: () => {
            if (
                this.allowShortcuts &&
                !this.state.inputFocused &&
                store.getState().Auth.user.accessLevel <= access.opening.createStage
            )
                this.addStage()
        },
    }
    render() {
        const {
            hasDoselectCredentials,
            hasUnberryCredentials,
            accessLevel,
            isJobValid,
            noCards,
            filterApplied,
        } = this.state
        const stagesMap = this.state.stagesMap.slice()
        const doSelectTests = store.getState().Mixed.doSelectTests
        const unberryPositions = store.getState().Mixed.unberryPositions

        if (this.state.isStageModalInEdit && this.state.editStagePos !== null) {
            stagesMap.splice(this.state.editStagePos, 1)
        }

        let { allJobs } = store.getState().Job

        const { accessibleJobs } = store.getState().Auth.user
        if (accessLevel > 2) allJobs = allJobs.filter(job => job.status && accessibleJobs.includes(job._id)).reverse()
        else allJobs = allJobs.filter(job => job.status).reverse()
        return this.state.currentJobStatus === 0 ? (
            <Modal
                type={MODAL_TYPES.WARNING_OPENING_ARCHIVED}
                visible={true}
                onConfirm={() => this.props.history.push('/openings')}
            />
        ) : (
            <HotKeys keyMap={keyMap.opening} handlers={this.handlers}>
                <div>
                    <HeaderContainer>
                        <div className="header-container">
                            <div className="board-header-text-parent">
                                <div className="board-header-text" hidden={!this.state.jobDetails.jobTitle}>
                                    <div className="board-header-text-title">{this.state.jobDetails.jobTitle}</div>
                                    <Popover
                                        placement="bottomLeft"
                                        arrowPointAtCenter
                                        overlayClassName="job-change-overlay"
                                        title={<div className="job-change-title">SWITCH TO:</div>}
                                        content={
                                            <Menu defaultSelectedKeys={[this.state.jobId]} onSelect={this.changeJob}>
                                                {allJobs.map(job => (
                                                    <Menu.Item key={job._id} className="job-change-option">
                                                        {job.jobTitle}
                                                    </Menu.Item>
                                                ))}
                                            </Menu>
                                        }
                                    >
                                        {allJobs.length > 1 && (
                                            <Icon
                                                component={DropdownIcon}
                                                className="board-change-job-icon cursor-pointer"
                                            />
                                        )}
                                    </Popover>
                                </div>
                            </div>
                            <div className="header-subtitle">{this.state.jobDetails.jobLocation}</div>
                        </div>
                        <div className="header-button-container">
                            <SearchBox
                                jobId={this.state.jobId}
                                stages={this.state.stagesMap}
                                onSubmit={this.onSearchResult}
                                onClear={this.clearResult}
                                history={this.props.history}
                                reference={input => (this.searchInput = input)}
                                handleFocus={this.handleInputFocus}
                            />

                            {isJobValid ? (
                                <Button ghost size="large" type="primary" onClick={this.showAddCandidateModal}>
                                    <Icon component={AddIcon} />
                                    Add Candidate
                                </Button>
                            ) : null}

                            <Button
                                ghost
                                size="large"
                                type="primary"
                                className="margin-left-10"
                                onClick={this.addStage}
                                hidden={accessLevel > 2}
                            >
                                <Icon component={AddIcon} />
                                Add Stage
                            </Button>

                            <div className="ats-view-button">
                                <Tooltip placement="top" overlayClassName="fs-10" title="Board view">
                                    <Icon component={AtsBoardViewIcon} />
                                </Tooltip>
                                <Tooltip placement="top" overlayClassName="fs-10" title="Candidate list view">
                                    <Icon
                                        component={ATSCLViewIcon}
                                        style={{ marginLeft: -4 }}
                                        onClick={() => this.props.history.push(`/openings/list/${this.state.jobId}`)}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </HeaderContainer>
                    {accessLevel < 5 ? (
                        <div className="ats-filter">
                            <div className="ats-filter-text">View:</div>
                            <div className="ats-filter-select">
                                <Select
                                    className="candidate-opening-select"
                                    defaultValue={VIEW_TYPES.LIVE.key}
                                    value={filterApplied}
                                    style={{
                                        width: '180px',
                                        color: '#8A94A6',
                                        zIndex: '1',
                                    }}
                                    size="small"
                                    onChange={this.filterChange}
                                    getPopupContainer={trigger => trigger.parentNode}
                                >
                                    {Object.values(VIEW_TYPES).map(({ name, key }) => (
                                        <Select.Option value={key} key={key}>
                                            {name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    ) : null}

                    {this.state.isLoading ? (
                        <BoardContainer>
                            <EmptyColumn cards={4} />
                            <EmptyColumn cards={2} />
                            <EmptyColumn cards={3} />
                            <EmptyColumn cards={1} />
                        </BoardContainer>
                    ) : this.state.isCardsAbsent ? (
                        <BoardContainer>
                            {this.state.stagesMap.map(each => {
                                if (
                                    [VIEW_TYPES.ACTIVE.key, VIEW_TYPES.ARCHIVED.key, VIEW_TYPES.REJECTED.key].includes(
                                        filterApplied
                                    ) &&
                                    each.title === 'JOINED'
                                )
                                    return null

                                return (
                                    <EmptyColumn
                                        key={each.id}
                                        id={each.id}
                                        accessLevel={accessLevel}
                                        cards={0}
                                        isCardsAbsent={this.state.isCardsAbsent}
                                        title={each.title}
                                        onDelete={this.deleteStage}
                                        stageLength={this.state.stagesMap.length}
                                        allApplication={each.allApplications}
                                        filterName={this.state.filterName}
                                    />
                                )
                            })}
                            {noCards && VIEW_TYPES.LIVE.key === filterApplied ? (
                                <div className="ats-filter-no-result">
                                    <p> There are no {this.state.filterName}. Go to </p>
                                    <Button
                                        type="primary"
                                        size="large"
                                        className=".ats-filter-no-result-button"
                                        onClick={this.allCandidates}
                                    >
                                        <span className=".ats-filter-button-text">All candidates</span>
                                    </Button>
                                </div>
                            ) : noCards &&
                              [VIEW_TYPES.ACTIVE.key, VIEW_TYPES.ARCHIVED.key, VIEW_TYPES.REJECTED.key].includes(
                                  filterApplied
                              ) ? (
                                <div className="ats-filter-no-result">
                                    <p> There are no {this.state.filterName}. Go back to </p>
                                    <Button
                                        type="primary"
                                        size="large"
                                        className=".ats-filter-no-result-button"
                                        onClick={this.liveCandidates}
                                    >
                                        <span className=".ats-filter-button-text">Live candidates</span>
                                    </Button>
                                </div>
                            ) : (
                                <div className="ats-filter-no-result">
                                    <p> There are no candidates in this Job opening</p>
                                    <Button
                                        type="primary"
                                        size="large"
                                        className=".ats-filter-no-result-button"
                                        onClick={this.showAddCandidateModal}
                                    >
                                        <span className=".ats-filter-button-text">Add candidates</span>
                                    </Button>
                                </div>
                            )}
                        </BoardContainer>
                    ) : (
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="columns" type="stages" direction="horizontal">
                                {provided => (
                                    <BoardContainer id="ats-board" ref={provided.innerRef} {...provided.droppableProps}>
                                        {this.state.stages.map((task, i) => {
                                            let cards = task.cards
                                                .map(card => this.state.cardsMap[card])
                                                .filter(card => card)

                                            const stageLength = this.state.stages.length
                                            return (
                                                <Column
                                                    key={task.id}
                                                    task={task}
                                                    stageLength={stageLength}
                                                    cards={cards}
                                                    index={i}
                                                    editStage={this.editStage}
                                                    deleteStage={this.deleteStage}
                                                    sortCards={this.sortCards}
                                                    hasDoselectCredentials={hasDoselectCredentials}
                                                    hasUnberryCredentials={hasUnberryCredentials}
                                                    candidateEvents={this.candidateEvents}
                                                    nextDataSet={this.getNextDataSet}
                                                    jobId={this.state.jobId}
                                                    filterName={this.state.filterName}
                                                    filterApplied={filterApplied}
                                                    candidateStatusObject={this.state.candidateStatusObject}
                                                    allApplication={task.allApplications}
                                                    stages={this.state.stagesMap}
                                                />
                                            )
                                        })}
                                        {provided.placeholder}
                                    </BoardContainer>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}

                    <Modal
                        type={MODAL_TYPES.ADD_CANDIDATE}
                        visible={this.state.addCandidateModal}
                        onCancel={this.handleCancel}
                        onSubmit={this.newCandidateAdded}
                        jobId={this.state.jobId}
                        jobDetails={this.state.currentJobDetails}
                    />

                    <Modal
                        type={MODAL_TYPES.ADD_STAGE}
                        visible={this.state.showStageModal}
                        onCancel={this.handleCancel}
                        onConfirm={this.addNewStage}
                        isStageModalInEdit={this.state.isStageModalInEdit}
                        stageForm={this.state.stageForm}
                        stagesMap={stagesMap}
                        hasDoselectCredentials={hasDoselectCredentials}
                        hasUnberryCredentials={hasUnberryCredentials}
                        doSelectTests={doSelectTests}
                        unberryPositions={unberryPositions}
                        previewTask={this.state.previewTask}
                        handleChange={value => this.handleChange(value)}
                        editMode={this.state.isStageModalInEdit}
                        error={this.state.error}
                    />
                </div>
            </HotKeys>
        )
    }
}

export default connect(
    null,
    {
        UpdateStages,
        DeleteStage,
        GetJobDetails,
    }
)(Board)
