const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    SET_USER: 'SET_USER',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS',
    SET_USER_GCAL: 'SET_USER_GCAL',
    SET_USER_GCAL_SCHEDULE: 'SET_USER_GCAL_SCHEDULE',
    ADD_USER_GCAL: 'ADD_USER_GCAL',
    UPDATE_DEFAULT_USER_GCAL: 'UPDATE_DEFAULT_USER_GCAL',
    DISCONNECT_GCAL_EMAIL: 'DISCONNECT_GCAL_EMAIL',
    RE_ORDER_ACCOUNTS: 'REORDER_ACCOUNTS',
    SET_ADDRESS: 'SET_ADDRESS',
    FAILED_SIGNUP: 'FAILED_SIGNUP',
    REFRESH_USER: 'REFRESH_USER',
    checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
    login: token => ({
        type: actions.LOGIN_SUCCESS,
        token,
    }),
    logout: userId => ({
        type: actions.LOGOUT,
        payload: userId,
    }),
}
export default actions
