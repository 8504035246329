import styled from 'styled-components'

export const FormContainer = styled.div`
    width: 50%;
    min-width: 600px;
    padding: 25px;
    background-color: #fff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    @media only screen and (max-width: 995px) {
        min-width: unset;
        width: 100%;
        padding: 20px;
        margin-bottom: 20px;
    }
    .ant-form-item {
        margin-bottom: 20px;
        width: 100%;
    }

    .ant-form-item-label,
    .ant-form-item-control {
        line-height: 25px;
    }

    .ant-form-item-label label,
    #info span {
        font-family: sanFranciscoMedium;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
    }

    .ant-select-selection__placeholder,
    .ant-select-selection-selected-value,
    .ant-btn {
        font-size: 12px;
        color: #666666;
    }
    .ant-calendar-picker {
        width: 100%;
    }
    .ant-input,
    .ant-input::placeholder,
    .ant-input-number,
    .ant-input-number-input::placeholder {
        font-size: 12px;
        color: #666666;
    }
    .ant-input {
        border: 1px solid #d9d9d9;
    }
    .has-error input {
        border-color: #f5222d !important;
        .ant-input:not([disabled]):hover {
            border-color: #f5222d !important;
        }
    }
    .times .ant-select-dropdown {
        width: 50% !important;
        text-align: center;
    }
    .ant-select-dropdown {
        transform: none;
    }
    .ant-input-group-addon:first-child {
        border-right: 1px solid #fff;
    }
    .ant-form-item-children .ant-input-group-addon:hover,
    .ant-calendar-picker-input:hover,
    .ant-form-item-children .ant-input:hover:not(#interviewForm_phone),
    .ant-select-search__field:hover {
        border-color: #6884ad !important;
    }
    .ant-calendar-footer {
        line-height: initial;
    }

    .ant-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }

    .ant-form-item-label > label::after {
        content: '';
    }

    .ant-form-item-label .ant-form-item-required::before {
        content: '';
    }

    .ant-form-item-label .ant-form-item-required::after {
        display: inline-block;
        margin-right: 4px;
        color: #000;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }

    #info svg {
        vertical-align: middle;
        margin-left: 5px;
        :hover path {
            fill: #000;
            cursor: pointer;
        }
    }
    .ant-tooltip-inner {
        font-size: 12px;
    }
    .ant-select-arrow svg {
        width: 10px;
    }
    .ant-form-explain {
        margin-top: 3px;
    }

    .interview-select .ant-select-selection-selected-value {
        display: flex !important;
        align-items: center;
        svg {
            margin-right: 5px;
        }
    }
`
export const Interviewee = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #d9d9d9;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-family: 'sanFrancisco';
    .openingTitleWrapper {
        white-space: nowrap;
        width: 450px;
    }
    @media only screen and (max-width: 995px) {
        display: block;
        text-align: left;
        padding-bottom: 20px;
    }
    > div {
        display: flex;
        @media only screen and (max-width: 995px) {
            display: block;
            text-align: center;
        }
    }
`
export const Detail = styled.div`
    display: flex;
    margin-bottom: 8px;
    .openingTitle {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`
export const Key = styled.div`
    height: 18px;
    font-family: 'sanFrancisco';
    font-size: 14px;
    color: #999;
    margin-right: 5px;
    @media only screen and (max-width: 995px) {
        font-size: 12px;
        margin-right: 5px;
    }
`
export const Value = styled(Key)`
    color: #333;
    @media only screen and (max-width: 995px) {
        text-transform: capitalize;
        margin: 0;
    }
`
export const Avatar = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: sanFranciscoMedium;
    font-weight: 600;
    color: #ffffff;
    border-radius: 10px;
    background-color: #4767a0;
    margin-right: 20px;
    @media only screen and (max-width: 995px) {
        display: none;
    }
`
export const Name = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    @media only screen and (max-width: 995px) {
        text-align: left;
        text-transform: capitalize;
        font-size: 16px;
        margin-bottom: 8px;
    }
`
export const Button = styled.button`
    border-radius: 4px;
    padding: 0;
    border: none;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #4767a0;
    background: #fff;
    cursor: pointer;
`
export const Warning = styled.div`
    font-size: 12px;
    color: #fa6400;
    margin-top: 10px;
    svg {
        vertical-align: middle;
        margin-right: 5px;
    }
`
export const Help = styled.div`
    margin-top: -15px;
    font-size: 12px;
    color: #999999;
    svg {
        width: 10px;
        height: 10px;
        vertical-align: middle;
        path {
            fill: #999999;
        }
    }
`

export const Footer = styled.span`
    font-size: 12px;
`

export const Indicator = styled.span`
    font-size: 12px;
    width: 6px;
    height: 6px;
    background-color: #0091ff;
    border-radius: 50%;
    display: inline-block;
`
