import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'
import { DataCard, Filter, Heading, Tabs } from '../../style'
import CustomFilters from '../../Components/Filters'
import { CandidatesFilterLoader, CandidatesTSALoader } from '../../Components/Loaders'
import { GetAnalyticsData } from '../../../../../services/NetworkCalls/Analytics'
import apiCaller from '../../../../../services/apiCaller'
import { warning } from '../../../../../services/Notification'
import { ReactComponent as InfoIcon } from '../../../../../assets/Icons/Openings/info.svg'
import { ReactComponent as RefreshIcon } from '../../../../../assets/Icons/Analytics/refresh.svg'
import { getNumberOfDays, getPercentage } from '../../../../Helpers/utils'
import StatCard from '../../Components/Card'
import {
    TSA_JOBS_COLUMNS,
    TSA_SOURCE_COLUMNS,
    TSA_MONTH_COLUMNS,
    TSA_TAG_COLUMNS,
    getRefreshPayload,
    getFilteredPayload,
    getJobStatusArray,
    getCandidatesInitialPayload,
} from '../../Components/constants'
import Table from '../../Components/Table'
import { MONTHS_ARRAY } from '../../../../Helpers/constants'
import { Tooltip } from 'antd'
import config from '../../../../../config'
import { StyledButton } from '../../../../../container/Components/'

const { isMobileDevice } = config

const { CheckboxFilter, DateFilter } = CustomFilters
const { formatError } = apiCaller

const TSA = ({ companyId, jobNames, jobsObject, GetAnalyticsData, history }) => {
    const [filterLoading, setFilterLoading] = useState(false)
    const [cardLoading, setCardLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [cardData, setCardData] = useState({})
    const [tableData, setTableData] = useState([])
    const [jobNamesArray, setJobNamesArray] = useState(jobNames)
    const [tagsArray, setTagsArray] = useState([])
    const [selectedJobTypes, setSelectedJobTypes] = useState([])
    const [selectedJobNames, setSelectedJobNames] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedTab, setSelectedTab] = useState('job')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [loadTime, setLoadTime] = useState(moment().format('DD MMM, YYYY LT'))

    const jobTypeArray = ['Active', 'Archived']
    const selectedColumn = {
        job: {
            columns: TSA_JOBS_COLUMNS,
            downloadColumns: [
                { index: 'jobName', value: 'JOB NAME' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'archived', value: 'ARCHIVED' },
                { index: 'rejected', value: 'REJECTED' },
                { index: 'joined', value: 'JOINED' },
                { index: 'avgTimeToHire', value: 'AVG TIME TO HIRE' },
                { index: 'avgTimeToArchive', value: 'AVG TIME TO ARCHIVE' },
                { index: 'avgTimeToReject', value: 'AVG TIME TO REJECT' },
            ],
            filename: 'TIME SPENT ANALYSIS_JOBS',
        },
        source: {
            columns: TSA_SOURCE_COLUMNS,
            downloadColumns: [
                { index: 'sourceName', value: 'SOURCE NAME' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'archived', value: 'ARCHIVED' },
                { index: 'rejected', value: 'REJECTED' },
                { index: 'joined', value: 'JOINED' },
                { index: 'avgTimeToHire', value: 'AVG TIME TO HIRE' },
                { index: 'avgTimeToArchive', value: 'AVG TIME TO ARCHIVE' },
                { index: 'avgTimeToReject', value: 'AVG TIME TO REJECT' },
            ],
            filename: 'TIME SPENT ANALYSIS_SOURCE',
        },
        month: {
            columns: TSA_MONTH_COLUMNS,
            downloadColumns: [
                { index: 'monthCreated', value: 'MONTH CREATED' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'archived', value: 'ARCHIVED' },
                { index: 'rejected', value: 'REJECTED' },
                { index: 'joined', value: 'JOINED' },
                { index: 'avgTimeToHire', value: 'AVG TIME TO HIRE' },
                { index: 'avgTimeToArchive', value: 'AVG TIME TO ARCHIVE' },
                { index: 'avgTimeToReject', value: 'AVG TIME TO REJECT' },
            ],
            filename: 'TIME SPENT ANALYSIS_MONTH',
        },
        tag: {
            columns: TSA_TAG_COLUMNS,
            downloadColumns: [
                { index: 'tagName', value: 'TAG NAME' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'archived', value: 'ARCHIVED' },
                { index: 'rejected', value: 'REJECTED' },
                { index: 'joined', value: 'JOINED' },
                { index: 'avgTimeToHire', value: 'AVG TIME TO HIRE' },
                { index: 'avgTimeToArchive', value: 'AVG TIME TO ARCHIVE' },
                { index: 'avgTimeToReject', value: 'AVG TIME TO REJECT' },
            ],
            filename: 'TIME SPENT ANALYSIS_TAGS',
        },
    }

    useEffect(() => {
        candidatesInitialLoad()
    }, [])

    const candidatesInitialLoad = async () => {
        const urlSearchResult = new URLSearchParams(history.location.search)
        const jobTypes = urlSearchResult.get('jobTypes') ? urlSearchResult.get('jobTypes').split(',') : []
        const jobNames = urlSearchResult.get('jobNames') ? urlSearchResult.get('jobNames').split(',') : []
        const tagNames = urlSearchResult.get('tagNames') ? urlSearchResult.get('tagNames').split(',') : []
        const startDate = urlSearchResult.get('startDate') ? urlSearchResult.get('startDate') : null
        const endDate = urlSearchResult.get('endDate') ? urlSearchResult.get('endDate') : null
        let tagNamesArray = []

        if (jobTypes.length || jobNames.length || tagNames.length || startDate || endDate)
            tagNamesArray = await getCandidateTags()
        const { payload, filteredJobNames } = getCandidatesInitialPayload(
            companyId,
            jobTypes,
            jobNames,
            jobsObject,
            tagNames,
            tagNamesArray,
            startDate,
            endDate
        )
        getTsaData(payload)
        getCandidateTags({ jobId: payload.jobId })
        getTsaJobsData(payload, jobTypes, jobNames)
        setSelectedJobTypes(jobTypes)
        setSelectedJobNames(jobNames)
        setJobNamesArray(filteredJobNames)
        setSelectedTags(tagNames)
        setStartDate(startDate && moment(startDate))
        setEndDate(endDate && moment(endDate))
    }

    const getTsaData = async (payload = {}) => {
        try {
            setCardLoading(true)
            const { result } = await GetAnalyticsData('GetTsaData', payload)
            result.totalCandidates = result.active + result.archived + result.rejected + result.joined
            setCardData(result)
            setCardLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getCandidateTags = async (payload = {}) => {
        try {
            setFilterLoading(true)
            const { result } = await GetAnalyticsData('GetCandidatesTags', payload, companyId)
            setTagsArray(result)
            setFilterLoading(false)
            return result
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getTsaJobsData = async (payload = {}, jobTypes, jobNames) => {
        try {
            setTableLoading(true)
            const { result } = await GetAnalyticsData('GetTsaJobsData', payload, companyId)
            const jobResultList = []
            let jobNamesList = []
            if (jobNames.length) jobNamesList = jobNames
            else if (jobTypes.length) {
                const jobStatusArray = getJobStatusArray(jobTypes)
                for (let job in jobsObject) {
                    if (jobStatusArray.includes(jobsObject[job].status)) jobNamesList.push(job)
                }
            } else jobNamesList = Object.keys(jobsObject)

            const tableData = result.map((res, index) => {
                jobResultList.push(res.jobTitle[0])
                return {
                    key: index,
                    jobName: res.jobTitle[0],
                    candidatesAdded: res.count || 0,
                    archived: res.archived || 0,
                    rejected: res.rejected || 0,
                    joined: res.joined || 0,
                    avgTimeToHire: getNumberOfDays(res.joinedTime),
                    avgTimeToArchive: getNumberOfDays(res.archivedTime),
                    avgTimeToReject: getNumberOfDays(res.rejectedTime),
                }
            })
            jobNamesList.forEach(jobName => {
                if (!jobResultList.includes(jobName))
                    tableData.push({
                        key: jobName,
                        jobName,
                        candidatesAdded: 0,
                        archived: 0,
                        rejected: 0,
                        joined: 0,
                        avgTimeToHire: getNumberOfDays(0),
                        avgTimeToArchive: getNumberOfDays(0),
                        avgTimeToReject: getNumberOfDays(0),
                    })
            })
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getTsaSourceData = async (payload = {}) => {
        try {
            setTableLoading(true)
            const { reqObj } = await GetAnalyticsData('GetTsaSourceData', payload, companyId)
            const tableData = []
            for (let source in reqObj) {
                tableData.push({
                    key: source,
                    sourceName: source,
                    candidatesAdded: reqObj[source][0].total || 0,
                    archived: reqObj[source][0].archived || 0,
                    rejected: reqObj[source][0].rejected || 0,
                    joined: reqObj[source][0].joined || 0,
                    avgTimeToHire: getNumberOfDays(reqObj[source][0].joinedTime),
                    avgTimeToArchive: getNumberOfDays(reqObj[source][0].archivedTime),
                    avgTimeToReject: getNumberOfDays(reqObj[source][0].rejectedTime),
                })
            }
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getTsaMonthData = async (payload = {}) => {
        try {
            setTableLoading(true)
            const { result } = await GetAnalyticsData('GetTsaMonthData', payload, companyId)
            const tableData = result.map((res, index) => {
                const obj = {
                    key: index,
                    candidatesAdded: res.total || 0,
                    archived: res.archived || 0,
                    rejected: res.rejected || 0,
                    joined: res.joined || 0,
                    avgTimeToHire: getNumberOfDays(res.joinedTime),
                    avgTimeToArchive: getNumberOfDays(res.archivedTime),
                    avgTimeToReject: getNumberOfDays(res.rejectedTime),
                }
                obj.monthCreated = `${MONTHS_ARRAY[res._id.month - 1]} ${res._id.year}`
                return obj
            })
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getTsaTagsData = async (payload = {}) => {
        try {
            setTableLoading(true)
            const { result } = await GetAnalyticsData('GetTsaTagsData', payload, companyId)
            const tableData = result.map((res, index) => ({
                key: index,
                tagName: res._id.tagName,
                candidatesAdded: res.total || 0,
                archived: res.archived || 0,
                rejected: res.rejected || 0,
                joined: res.joined || 0,
                avgTimeToHire: getNumberOfDays(res.joinedTime),
                avgTimeToArchive: getNumberOfDays(res.archivedTime),
                avgTimeToReject: getNumberOfDays(res.rejectedTime),
            }))
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const applyFilteredData = (type, data) => {
        const payload = getFilteredPayload(
            type,
            data,
            companyId,
            selectedJobTypes,
            selectedJobNames,
            jobsObject,
            selectedTags,
            tagsArray,
            startDate,
            endDate,
            getCandidateTags,
            setSelectedJobTypes,
            setJobNamesArray,
            setSelectedJobNames,
            setSelectedTags,
            setStartDate,
            setEndDate,
            history
        )
        getTsaData(payload)
        if (type === 'jobType') updateTableData(payload, data, [])
        else if (type === 'jobName') updateTableData(payload, selectedJobTypes, data)
        else updateTableData(payload, selectedJobTypes, selectedJobNames)
    }

    const resetFilter = type => {
        const params = new URLSearchParams(history.location.search)
        if (type === 'jobType') {
            const payload = {
                companyId,
                startDate: startDate && startDate.format(),
                endDate: endDate && endDate.format(),
            }
            getCandidateTags(payload)
            getTsaData(payload)
            updateTableData(payload, [], [])
            setSelectedJobTypes([])
            setSelectedJobNames([])
            setSelectedTags([])
            setJobNamesArray(Object.keys(jobsObject))
            params.delete('jobTypes')
            params.delete('jobNames')
            params.delete('tagNames')
            history.push({ search: params.toString() })
        } else if (type === 'jobName') {
            const jobStatusArray = getJobStatusArray(selectedJobTypes)
            let jobId = []
            if (jobStatusArray.length) {
                for (let job in jobsObject)
                    if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
            } else jobId = null
            const payload = {
                companyId,
                jobId,
                startDate: startDate && startDate.format(),
                endDate: endDate && endDate.format(),
            }
            getCandidateTags(payload)
            getTsaData(payload)
            updateTableData(payload, selectedJobTypes, [])
            setSelectedJobNames([])
            setSelectedTags([])
            params.delete('jobNames')
            params.delete('tagNames')
            history.push({ search: params.toString() })
        } else {
            const jobStatusArray = getJobStatusArray(selectedJobTypes)
            let jobId = []
            if (selectedJobNames.length) jobId = selectedJobNames.map(jobName => jobsObject[jobName]._id)
            else if (jobStatusArray.length) {
                for (let job in jobsObject)
                    if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
            }
            const payload = {
                companyId,
                jobId: (jobId.length && jobId) || null,
                startDate: startDate && startDate.format(),
                endDate: endDate && endDate.format(),
            }
            getTsaData(payload)
            updateTableData(payload, selectedJobTypes, selectedJobNames)
            setSelectedTags([])
            params.delete('tagNames')
            history.push({ search: params.toString() })
        }
    }

    const updateTableData = (payload, jobTypes, jobNames) => {
        if (selectedTab === 'job') getTsaJobsData(payload, jobTypes, jobNames)
        else if (selectedTab === 'source') getTsaSourceData(payload)
        else if (selectedTab === 'month') getTsaMonthData(payload)
        else getTsaTagsData(payload)
    }

    const handleTabClick = tab => {
        const jobStatusArray = getJobStatusArray(selectedJobTypes)
        let jobId = [],
            tags = []
        if (selectedJobNames.length) jobId = selectedJobNames.map(jobName => jobsObject[jobName]._id)
        else if (jobStatusArray.length) {
            for (let job in jobsObject)
                if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
        }

        if (selectedTags.length) {
            tagsArray.forEach(tag => {
                if (selectedTags.includes(tag.name)) tags.push(tag._id)
            })
        }
        const payload = {
            companyId,
            jobId: (jobId.length && jobId) || null,
            tags: (tags.length && tags) || null,
            startDate: startDate && startDate.format(),
            endDate: endDate && endDate.format(),
        }
        if (tab === 'job') getTsaJobsData(payload, selectedJobTypes, selectedJobNames)
        else if (tab === 'source') getTsaSourceData(payload)
        else if (tab === 'month') getTsaMonthData(payload)
        else getTsaTagsData(payload)

        setSelectedTab(tab)
    }

    const handleRefresh = () => {
        const payload = getRefreshPayload(
            selectedJobTypes,
            selectedJobNames,
            selectedTags,
            jobsObject,
            tagsArray,
            companyId,
            startDate,
            endDate
        )
        getTsaData(payload)
        updateTableData(payload, selectedJobTypes, selectedJobNames)
        setLoadTime(moment().format('DD MMM, YYYY LT'))
    }

    return (
        <>
            <div className="sub-header">
                <div>
                    <Heading>Time Spent Analysis</Heading>
                    <Tooltip title="Learn how candidates spent time through different stages in your pipeline. Use the filters to narrow the search criteria.">
                        <InfoIcon />
                    </Tooltip>
                </div>
                <div>
                    <RefreshIcon onClick={() => handleRefresh()} />
                    <span>Last Updated: {loadTime}</span>
                </div>
            </div>
            {filterLoading ? (
                <CandidatesFilterLoader />
            ) : (
                <>
                    {isMobileDevice && <Heading>Filter</Heading>}
                    <Filter>
                        <CheckboxFilter
                            title="Filter by job type"
                            data={jobTypeArray}
                            search={false}
                            apply={false}
                            type="jobType"
                            applyFilteredData={applyFilteredData}
                            resetFilter={resetFilter}
                            selectedValues={selectedJobTypes}
                        />
                        <CheckboxFilter
                            title="Filter by job name"
                            data={jobNamesArray}
                            type="jobName"
                            applyFilteredData={applyFilteredData}
                            resetFilter={resetFilter}
                            selectedValues={selectedJobNames}
                        />
                        <CheckboxFilter
                            title="Filter by tag"
                            data={tagsArray.map(tag => tag.name)}
                            type="tag"
                            applyFilteredData={applyFilteredData}
                            resetFilter={resetFilter}
                            selectedValues={selectedTags}
                        />
                        <DateFilter applyFilteredData={applyFilteredData} start={startDate} end={endDate} />
                    </Filter>
                </>
            )}
            {cardLoading || filterLoading ? (
                <CandidatesTSALoader />
            ) : (
                <div className="cards">
                    <StatCard total={cardData.totalCandidates} title="Total Candidates" />
                    <StatCard
                        total={cardData.archived}
                        title="Archived"
                        desc={`${getPercentage(cardData.archived, cardData.totalCandidates)}% of Total Candidates`}
                    />
                    <StatCard
                        total={cardData.rejected}
                        title="Rejected"
                        desc={`${getPercentage(cardData.rejected, cardData.totalCandidates)}% of Total Candidates`}
                    />
                    <StatCard
                        total={cardData.joined}
                        title="Joined"
                        desc={`${getPercentage(cardData.joined, cardData.totalCandidates)}% of Total Candidates`}
                    />
                    <StatCard total={cardData.joinedTime} title="Avg Time to Hire" day={true} />
                    <StatCard total={cardData.archivedTime} title="Avg Time to Archive" day={true} />
                    <StatCard total={cardData.rejectedTime} title="Avg Time to Reject" day={true} />
                </div>
            )}
            <Tabs>
                <div className={classNames({ active: selectedTab === 'job' })} onClick={() => handleTabClick('job')}>
                    JOB
                </div>
                <div
                    className={classNames({ active: selectedTab === 'source' })}
                    onClick={() => handleTabClick('source')}
                >
                    SOURCE
                </div>
                <div
                    className={classNames({ active: selectedTab === 'month' })}
                    onClick={() => handleTabClick('month')}
                >
                    MONTH CREATED
                </div>
                <div className={classNames({ active: selectedTab === 'tag' })} onClick={() => handleTabClick('tag')}>
                    CANDIDATE TAG
                </div>
            </Tabs>
            <Table
                columns={selectedColumn[selectedTab].columns}
                downloadColumns={selectedColumn[selectedTab].downloadColumns}
                filename={`${selectedColumn[selectedTab].filename}_${moment().format('DD-MM-YYYY_h-mm a')}`}
                tableData={tableData}
                loading={tableLoading || filterLoading}
                xScroll={1180}
            />
        </>
    )
}

const mapStateToProps = ({ Auth, Job }) => {
    const { user } = Auth
    const { allJobs } = Job
    const jobNames = [],
        jobsObject = {}

    allJobs.forEach(job => {
        jobNames.push(job.jobTitle)
        jobsObject[job.jobTitle] = {
            _id: job._id,
            status: job.status,
        }
    })

    return {
        companyId: user.companyId,
        jobNames,
        jobsObject,
    }
}

export default connect(
    mapStateToProps,
    {
        GetAnalyticsData,
    }
)(TSA)
