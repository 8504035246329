import React from 'react'

const SVGComponent = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g
                id="Dashboard-New---Create-Job+Step3:-Promote"
                transform="translate(-420 -784)"
                fill="#4767A0"
                fillRule="nonzero"
            >
                <g id="Candidate-Application" transform="translate(365 200)">
                    <g id="Group-2-Copy-2" transform="translate(0 508)">
                        <g id="Share-Options" transform="translate(40 60)">
                            <g id="Share-Option1">
                                <g id="Logo" transform="translate(15 16)">
                                    <path
                                        d="M24,1.28645833 L24,22.0416667 C24,22.7552083 23.4053571,23.328125 22.6767857,23.328125 L16.5589286,23.328125 L16.5589286,14.296875 L19.6767857,14.296875 L20.1428571,10.7760417 L16.5535714,10.7760417 L16.5535714,8.52604167 C16.5535714,7.50520833 16.8428571,6.8125 18.3482143,6.8125 L20.2660714,6.8125 L20.2660714,3.66145833 C19.9339286,3.61979167 18.7982143,3.52083333 17.4696429,3.52083333 C14.7053571,3.52083333 12.8089286,5.16145833 12.8089286,8.17708333 L12.8089286,10.7760417 L9.68035714,10.7760417 L9.68035714,14.296875 L12.8089286,14.296875 L12.8089286,23.3333333 L1.32321429,23.3333333 C0.594642857,23.3333333 0,22.7552083 0,22.046875 L0,1.28645833 C0,0.578125 0.594642857,0 1.32321429,0 L22.6714286,0 C23.4053571,0 24,0.578125 24,1.28645833 Z"
                                        id="facebook"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
