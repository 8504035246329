import ContentLoader from 'react-content-loader'
import React from 'react'

export const CandidatesOverviewLoader = () => (
    <ContentLoader height={205}>
        <rect x="0" y="17" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="17" rx="1" ry="1" width="92" height="52" />
        <rect x="202" y="17" rx="1" ry="1" width="92" height="52" />
        <rect x="303" y="17" rx="1" ry="1" width="92" height="52" />
        <rect x="0" y="79" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="79" rx="1" ry="1" width="92" height="52" />
        <rect x="202" y="79" rx="1" ry="1" width="92" height="52" />
        <rect x="303" y="79" rx="1" ry="1" width="92" height="52" />
        <rect x="0" y="141" rx="1" ry="1" width="92" height="52" />
    </ContentLoader>
)

export const CandidatesFilterLoader = () => (
    <ContentLoader height={40}>
        <rect x="0" y="19" rx="1" ry="1" width="92" height="19" />
        <rect x="101" y="19" rx="1" ry="1" width="92" height="19" />
        <rect x="202" y="19" rx="1" ry="1" width="92" height="19" />
        <rect x="303" y="19" rx="1" ry="1" width="92" height="19" />
    </ContentLoader>
)

export const CandidatesProgressLoader = () => (
    <ContentLoader height={143}>
        <rect x="0" y="12" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="12" rx="1" ry="1" width="92" height="52" />
        <rect x="202" y="12" rx="1" ry="1" width="92" height="52" />
        <rect x="303" y="12" rx="1" ry="1" width="92" height="52" />
        <rect x="0" y="72" rx="1" ry="1" width="92" height="52" />
    </ContentLoader>
)

export const CandidatesTSALoader = () => (
    <ContentLoader height={143}>
        <rect x="0" y="12" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="12" rx="1" ry="1" width="92" height="52" />
        <rect x="202" y="12" rx="1" ry="1" width="92" height="52" />
        <rect x="303" y="12" rx="1" ry="1" width="92" height="52" />
        <rect x="0" y="72" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="72" rx="1" ry="1" width="92" height="52" />
        <rect x="202" y="72" rx="1" ry="1" width="92" height="52" />
    </ContentLoader>
)

export const JobsOverviewLoader = () => (
    <ContentLoader height={140}>
        <rect x="0" y="17" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="17" rx="1" ry="1" width="92" height="52" />
        <rect x="202" y="17" rx="1" ry="1" width="92" height="52" />
        <rect x="303" y="17" rx="1" ry="1" width="92" height="52" />
        <rect x="0" y="78" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="78" rx="1" ry="1" width="92" height="52" />
    </ContentLoader>
)

export const JobTimelineLoader = () => (
    <ContentLoader height={75}>
        <rect x="0" y="10" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="10" rx="1" ry="1" width="92" height="52" />
        <rect x="202" y="10" rx="1" ry="1" width="92" height="52" />
        <rect x="303" y="10" rx="1" ry="1" width="92" height="52" />
    </ContentLoader>
)

export const JobStageLoader = () => (
    <ContentLoader height={135}>
        <rect x="0" y="10" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="10" rx="1" ry="1" width="92" height="52" />
        <rect x="202" y="10" rx="1" ry="1" width="92" height="52" />
        <rect x="303" y="10" rx="1" ry="1" width="92" height="52" />
        <rect x="0" y="70" rx="1" ry="1" width="92" height="52" />
    </ContentLoader>
)

export const TeamMemberOverviewLoader = () => (
    <ContentLoader height={82}>
        <rect x="0" y="17" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="17" rx="1" ry="1" width="92" height="52" />
        <rect x="202" y="17" rx="1" ry="1" width="92" height="52" />
    </ContentLoader>
)

export const TeamMemberLoader = () => (
    <ContentLoader height={75}>
        <rect x="0" y="10" rx="1" ry="1" width="92" height="52" />
        <rect x="101" y="10" rx="1" ry="1" width="92" height="52" />
        <rect x="202" y="10" rx="1" ry="1" width="92" height="52" />
        <rect x="303" y="10" rx="1" ry="1" width="92" height="52" />
    </ContentLoader>
)
