import actions from './actions'
import { PURGE } from 'redux-persist'
import _ from 'lodash'

const initState = { inUse: null, accounts: null, availability: null, sortedAccounts: true }

export default function gCalReducer(state = initState, action) {
    let { accounts, availability, inUse } = state

    switch (action.type) {
        case PURGE:
            return { ...initState }
        case actions.SET_IN_USE:
            return { ...state, inUse: action.payload.inUse }
        case actions.SET_NOT_IN_USE:
            return { ...initState, inUse: false }
        case actions.SET_AVAILABILITY:
            return { ...state, availability: action.payload, inUse: true }
        case actions.SET_DEFAULT:
            const oldDefault = _.values(accounts).find(account => account.status === 'default')
            const newDefault = _.values(accounts).find(account => account.email === action.payload.email)
            oldDefault.status = 'active'
            newDefault.status = 'default'
            accounts = {
                ...accounts,
                [oldDefault._id]: oldDefault,
                [newDefault._id]: newDefault,
            }
            return { ...state, accounts, sortedAccounts: false }
        case actions.SET_USER_GCAL:
            accounts = { ..._.mapKeys(action.payload.accounts, '_id') }
            availability = action.payload.availability
            inUse = action.payload.inUse
            return { ...state, accounts, availability, inUse, sortedAccounts: true }
        case actions.ADD_ACCOUNT:
            accounts = { ...accounts, [action.payload._id]: action.payload }
            return { ...state, accounts, inUse: true, sortedAccounts: true }
        case actions.REMOVE_ACCOUNT:
            const toBeRemoved = _.values(accounts).find(account => account.email === action.payload.email)
            accounts = _.omit(accounts, toBeRemoved._id)
            return { ...state, accounts, inUse, sortedAccounts: true }
        default:
            return state
    }
}
