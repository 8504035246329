import React, { Component } from 'react'
import { Form, Select } from 'antd'
import { Footer, Header } from '../common'
import { ContentWrapper, ContentBody } from '../style'
import { warning } from '../../../services/Notification'
import { connect } from 'react-redux'
import './style.scss'

const { Option } = Select

class UnarchiveCandidate extends Component {
    constructor(props) {
        super(props)
    }

    moveAndUnarchive = () => {
        const jobId = this.props.form.getFieldValue('job-opening')
        const stageId = this.props.form.getFieldValue('stage')
        if (!jobId) {
            warning('Job opening is required')
            return
        }
        this.props.onConfirm({ jobId, stageId })
    }

    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form

        let allJobs = this.props.allJobs
        allJobs = allJobs.filter(job => job.status && job.stages && job.stages.length).reverse()

        const stagesOptions = value => {
            return allJobs.find(job => job._id === value) && allJobs.find(job => job._id === value).stages
        }

        return (
            <>
                <Header title="UNARCHIVE CANDIDATE" />
                <ContentWrapper>
                    <div className="popup-header">
                        <div className="popup-header-text">
                            <p>
                                As the current job opening of the candidate is archived, please move the candidate to
                                appropriate job opening
                            </p>
                        </div>
                        <Form className="popup-form">
                            <div className="popup-form-field">
                                <p className="popup-form-field-text">Job Opening*</p>
                                <Form.Item>
                                    {getFieldDecorator('job-opening')(
                                        <Select
                                            size="large"
                                            placeholder="Select"
                                            onChange={value => {
                                                setFieldsValue({
                                                    stage: stagesOptions(value)[0]._id,
                                                })
                                            }}
                                            getPopupContainer={trigger => trigger.parentNode}
                                        >
                                            {allJobs.map(option => (
                                                <Option className="dropdn-option" key={option._id} value={option._id}>
                                                    <h5>{option.jobTitle}</h5>
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>
                            <div className="popup-form-field field-margin">
                                <p className="popup-form-field-text">Stage*</p>
                                <Form.Item>
                                    {getFieldDecorator('stage')(
                                        <Select
                                            size="large"
                                            placeholder="Select"
                                            getPopupContainer={trigger => trigger.parentNode}
                                        >
                                            {stagesOptions(getFieldValue('job-opening')) &&
                                                stagesOptions(getFieldValue('job-opening')).map(option => (
                                                    <Option
                                                        className="dropdn-option"
                                                        key={option._id}
                                                        value={option._id}
                                                    >
                                                        <h5>{option.stageName}</h5>
                                                    </Option>
                                                ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </ContentWrapper>
                <Footer
                    onCancel={this.props.onCancel}
                    onConfirm={this.moveAndUnarchive}
                    cancelText="CANCEL"
                    confirmText="MOVE AND UNARCHIVE"
                    width="160px"
                    hasSeparator
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    const { Job } = state
    return {
        allJobs: Job.allJobs,
    }
}

export default connect(mapStateToProps)(Form.create()(UnarchiveCandidate))
