import React, { useState } from 'react'
import './column.css'
import { Skeleton, Icon } from 'antd'
import { ColumnContainer, Title } from './style'
import { Modal } from '../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'

function EmptyCard() {
    return (
        <div className="ats-skeleton" key={0}>
            <Skeleton avatar={{ shape: 'square' }} paragraph={{ rows: 1 }} active />
        </div>
    )
}

function EmptyColumn(props) {
    let emptyCardCount = Array(props.cards || 2)
    let [deleteModalVisible, handleDeleteModal] = useState(false)
    let [warningDeleteModalVisible, handlewarningDeleteModalVisible] = useState(false)
    let [candidatesPresent, handleCandidatesPresent] = useState()

    const handleCancel = () => {
        handleDeleteModal(false)
        handlewarningDeleteModalVisible(false)
    }

    const handleDelete = () => {
        const { allApplication, filterName } = props
        if (!allApplication || (allApplication && allApplication.length === 0)) {
            handleDeleteModal(true)
        } else if (allApplication.length > 0) {
            let candidatesPresent
            switch (filterName) {
                case 'Active Candidates':
                    candidatesPresent = allApplication.find(app => app.status !== 1).status
                    break
                case 'Archived Candidates':
                    candidatesPresent = allApplication.find(app => app.status !== 0).status
                    break
                case 'Rejected Candidates':
                    candidatesPresent = allApplication.find(app => app.status !== 2).status
                    break
                case 'Joined Candidates':
                    candidatesPresent = allApplication.find(app => app.status !== 3).status
                    break
                default:
                    candidatesPresent = null
            }
            handleCandidatesPresent(candidatesPresent)
            handlewarningDeleteModalVisible(true)
        }
    }

    return (
        <div className="ats-column-empty-container">
            <ColumnContainer {...props} className="board-column">
                <Title className={props.isCardsAbsent ? 'column-title-no-candidate' : 'column-title-preview'}>
                    {props.isCardsAbsent && props.title ? (
                        <div>
                            <span>{props.title}</span>
                            {props.accessLevel < 3 &&
                                !['MAKE OFFER', 'JOINED'].includes(props.title) &&
                                props.stageLength > 1 && (
                                    <span className="column-nocandidate">
                                        {' '}
                                        <Icon className="column-edit-icon" type="delete" onClick={handleDelete} />{' '}
                                    </span>
                                )}

                            <Modal
                                type={MODAL_TYPES.CONFIRM_DELETE_STAGE}
                                visible={deleteModalVisible}
                                onCancel={handleCancel}
                                onConfirm={() => props.onDelete({ id: props.id, title: props.title })}
                                stageName={props.title}
                            />

                            <Modal
                                type={MODAL_TYPES.WARNING_DELETE_STAGE}
                                visible={warningDeleteModalVisible}
                                onConfirm={handleCancel}
                                candidatesPresent={candidatesPresent}
                            />
                        </div>
                    ) : (
                        <Skeleton
                            style={{ display: 'flex', alignItems: 'center' }}
                            title={false}
                            paragraph={{ rows: 1 }}
                            active
                        />
                    )}
                </Title>
                {!props.isCardsAbsent &&
                    emptyCardCount.map((value, index) => {
                        return <EmptyCard key={index} />
                    })}
            </ColumnContainer>
        </div>
    )
}

export default EmptyColumn
