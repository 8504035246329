import React from 'react'

const SVGComponent = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g
                id="ATS-Board---Sort-By-Dropdown-(Hover-+-Selected-state"
                transform="translate(-265 -222)"
                fill="#697998"
                fillRule="nonzero"
            >
                <g id="ATS-Stages" transform="translate(40 200)">
                    <g id="Stage1">
                        <g id="Header" transform="translate(20 20)">
                            <g id="noun_filter_1608657" transform="translate(205 2)">
                                <path
                                    d="M11.07,11.2615385 C11.04,11.3076923 11.01,11.3769231 11.01,11.4230769 L11.01,17.3307692 C11.01,17.5846154 10.74,17.7923077 10.41,17.7923077 L7.53,17.7923077 C7.2,17.7923077 6.93,17.5846154 6.93,17.3307692 L6.93,11.4230769 C6.93,11.3538462 6.9,11.3076923 6.87,11.2615385 L2.25,4.08461538 L15.78,4.08461538 L11.07,11.2615385 Z M16.26,3.32307692 L1.74,3.32307692 L0.15,0.853846154 C-0.06,0.553846154 0.24,0.184615385 0.69,0.184615385 L17.34,0.184615385 C17.79,0.184615385 18.09,0.553846154 17.88,0.853846154 L16.26,3.32307692 Z"
                                    id="Shape"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
