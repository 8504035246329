import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 25 25">
            <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
                <path
                    fill="#4767A0"
                    d="M12.5 0C5.625 0 0 5.625 0 12.5S5.625 25 12.5 25 25 19.375 25 12.5 19.375 0 12.5 0z"
                    transform="translate(-230 -103) translate(39 99) translate(191 4)"
                />
                <path
                    fill="#FFF"
                    d="M19.375 9.688a1.007 1.007 0 00-1.458 0L12.5 15.104 7.083 9.688a1.007 1.007 0 00-1.458 0 1.007 1.007 0 000 1.458l6.146 6.146a1.007 1.007 0 001.458 0l6.146-6.146c.417-.313.417-1.042 0-1.459z"
                    transform="translate(-230 -103) translate(39 99) translate(191 4)"
                />
            </g>
        </svg>
    )
}

export default Icon
