import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Body, Container, Heading, Main } from '../../Pages/Analytics/style'
import { MobileSidebar, Sidebar, SIDEBAR_CONTENT } from '../../Pages/Analytics/Components/Sidebar'
import config from '../../../config'

const { isMobileDevice } = config

export default ({ url, style, allowSwitchTabs, location }) => {
    const ROUTER_SWITCH = []
    SIDEBAR_CONTENT.forEach(({ HEADER, CONTENT }) => {
        CONTENT.forEach(({ key, route, component }) => {
            ROUTER_SWITCH.push(
                <Route exact key={HEADER.key + key} component={component} path={url + '/' + HEADER.key + '/' + route} />
            )
        })
    })

    return (
        <Container>
            <div className="header">
                <Heading white>Reports</Heading>
                {isMobileDevice && <MobileSidebar location={location} />}
            </div>
            <Body>
                <Sidebar location={location} />
                <Main>
                    <Switch>
                        <Route exact path={url}>
                            <Redirect to={url + '/candidate'} />
                        </Route>
                        {ROUTER_SWITCH}
                        <Redirect path="*" to="/error" />
                    </Switch>
                </Main>
            </Body>
        </Container>
    )
}
