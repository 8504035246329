import React, { Component } from 'react'
import config from '../../../config'
import { PageWrapper } from './style'
import TeamMembers from './Components/TeamMember/TeamMembers'
import { connect } from 'react-redux'
import UserSettings from './Components/UserSettings/UserSettings'
import Sourcing from './Components/Sourcing/Sourcing'
import GoogleCalendar from './Components/GoogleCalendar/GoogleCalendar'
import DoSelectDetails from './Components/DoSelectDetails/DoSelectDetails'
import UnberryDetails from './Components/UnberryDetails/UnberryDetails'
import { LogE } from './../../Helpers/errorHandler'
import { Modal } from '../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import { ACCESS_LEVEL_DEFINITIONS } from '../../Helpers/constants'
import { GetCompany } from '../../../services/api'
import { UpdateEmailNotifications } from '../../../services/NetworkCalls/User'
import { ChangePassword } from '../../../services/NetworkCalls/Authentication'
import { LabelBox } from '../../../container/Pages/Todo/style'
import './style.scss'
import ComponentContainer from '../../Pages/AccountSettingsNew/Components/ComponentContainer.js'
import { ReactComponent as ZoomIcon } from '../../../assets/Icons/Extras/zoom.svg'
import { ZoomGetUserDetails, ZoomGetUserDisconnect, ZoomUserRegister } from '../../../services/NetworkCalls/Zoom'

import { OutlookUserRegister, OutlookUserDisconnect } from '../../../services/NetworkCalls/OutlookCalendar'
import { randomString } from '../../Helpers/utils'
const { zoomConnectLink, outlookConnectLink } = config.config()

class AccountSettingsNew extends Component {
    constructor(props) {
        super(props)

        const {
            user: { userName: fullName, companyName, websiteUrl, email: userEmail, id: userId, accessLevel, companyId },
        } = props

        this.state = {
            accessLevel,
            currentUserId: userId,
            companyId,
            company: {
                fullName,
                companyName,
                websiteUrl,
            },
            emailNotifications: {},
            companySettings: {},
            sourcing: {},
            notifyByEmail: false,
            userEmail,
            changePasswordModalVisible: false,
            manageNotificationsModalVisible: false,
            doSelectCredential: {
                apiKey: undefined,
                apiSecret: undefined,
            },
            unberryCredential: {
                apiKey: undefined,
            },
            hasDoSelectCredentials: false,
            hasUnberryCredentials: false,
            isLoading: true,
        }
    }

    async componentDidMount() {
        document.title = 'Account | SpringRecruit'

        try {
            this.props.allowSwitchTabs(true)
            let doSelectCredential = {
                apiKey: undefined,
                apiSecret: undefined,
            }
            let unberryCredential = {
                apiKey: undefined,
            }

            let hasDoSelectCredentials = false
            let hasUnberryCredentials = false

            // Zoom: after login, the redirect url adds code in the url, that needs to be sent to BE.
            this.registerZoomCreds()
            this.props.ZoomGetUserDetails()

            // Outlook connect:
            this.registerOutlookCreds()

            const companyDetails = await GetCompany()

            let company = Object.assign({}, companyDetails)
            if (companyDetails.company && companyDetails.company.doSelectCredential) {
                doSelectCredential = companyDetails.company.doSelectCredential
                hasDoSelectCredentials = true
            }
            if (companyDetails.company && companyDetails.company.unberryCredential) {
                unberryCredential = companyDetails.company.unberryCredential
                hasUnberryCredentials = true
            }

            company = {
                ...(companyDetails.company ? companyDetails.company : {}),
                userId: companyDetails.user._id,
                fullName: companyDetails.user.fullName,
            }
            delete company.doSelectCredential
            delete company.unberryCredential

            const {
                email_notifications: { candidate, feedback },
            } = companyDetails.user_settings
            const notifyByEmail = candidate.added || candidate.message_sent || candidate.note_added || feedback.added

            this.setState({
                company,
                emailNotifications: companyDetails.user_settings.email_notifications,
                companySettings: companyDetails.companySettings,
                sourcing: companyDetails.sourcing,
                notifyByEmail,
                doSelectCredential,
                unberryCredential,
                hasDoSelectCredentials,
                hasUnberryCredentials,
                userEmail: companyDetails.user.email,
                isLoading: false,
            })
        } catch (err) {
            LogE('accountsetting-componentDidMount', '', err)
        }
    }

    showChangePasswordModal = () => {
        this.setState({ changePasswordModalVisible: true })
    }

    handleCancel = () => {
        this.setState({
            changePasswordModalVisible: false,
        })
    }

    changePassword = async ({ values, email }) => {
        const response = await this.props.ChangePassword({ ...values, email: this.state.userEmail })
        if (response) this.handleCancel()
    }

    updateSubscription = (enabledFor, jobIds, hasSubscribed) => {
        const sourcing = {
            enabledFor,
            jobIds,
            status: hasSubscribed ? 'enabled' : 'disabled',
        }
        this.setState({ sourcing })
    }

    zoomConnect = () => {
        const rString = randomString()
        localStorage.setItem('ZOOM_START', rString)
    }

    outlookConnect = () => {
        const rString = randomString()
        localStorage.setItem('OUTLOOK_START', rString)
        window.open(outlookConnectLink + rString, '_self')
    }

    outlookDisconnect = () => {
        this.props.OutlookUserDisconnect()
    }

    registerZoomCreds = async () => {
        const urlQuery = new URLSearchParams(this.props.location.search)
        const rString = localStorage.getItem('ZOOM_START')

        if (rString && rString === urlQuery.get('state')) {
            localStorage.removeItem('ZOOM_START')
            this.props.ZoomUserRegister(urlQuery.get('code'))
        }
    }

    registerOutlookCreds = async () => {
        const urlQuery = new URLSearchParams(this.props.location.search)
        const rString = localStorage.getItem('OUTLOOK_START')
        if (rString && rString === urlQuery.get('state')) {
            localStorage.removeItem('OUTLOOK_START')
            this.props.OutlookUserRegister(urlQuery.get('code'))
        }
    }

    render() {
        const { teamMembers, zoom, OCal } = this.props
        const {
            company,
            emailNotifications,
            companySettings,
            sourcing,
            isLoading,
            userEmail,
            accountEdit,
            notifyByEmail,
            doSelectCredential,
            unberryCredential,
            hasDoSelectCredentials,
            hasUnberryCredentials,
            accessLevel,
            companyId,
        } = this.state

        return (
            <div className="AccountSettingWrapper">
                <PageWrapper>
                    <LabelBox>ACCOUNT SETTINGS</LabelBox>
                    <div className="AccountSettingWrapperContent">
                        <div className="AccountSettingWrapperContentLeft">
                            <UserSettings
                                notifyByEmail={notifyByEmail}
                                emailNotifications={emailNotifications}
                                companySettings={companySettings}
                                showChangePasswordModal={this.showChangePasswordModal}
                                handleCancel={this.handleCancel}
                                isLoading={isLoading}
                                location={this.props.location}
                            />
                            {accessLevel === 1 ? (
                                <Sourcing
                                    enabledFor={sourcing.enabledFor}
                                    jobIds={sourcing.jobIds}
                                    hasSubscribed={sourcing.status === 'enabled'}
                                    isLoading={isLoading}
                                    updateSubscription={this.updateSubscription}
                                />
                            ) : null}
                            <GoogleCalendar />

                            {/* TODO: remove after zoom deployment */}
                            {/* <ComponentContainer
                                heading="Zoom Meetings"
                            //  hidden={!this.props.user.isZoomEnabled}
                            >
                                <div className="calendar-data-body">
                                    {zoom.avatar ? (
                                        <img src={zoom.avatar} style={{ width: 50, height: 50 }} />
                                    ) : (
                                        <ZoomIcon />
                                    )}
                                    <div style={{ marginLeft: 5 }}>
                                        <p className="calendar-data-title">
                                            <b>
                                                {zoom.inUse
                                                    ? zoom.fullName + ' (' + zoom.zoomEmail + ')'
                                                    : 'Connect your zoom account'}
                                            </b>
                                        </p>
                                        <p className="calendar-data-text">
                                            {zoom.inUse
                                                ? 'All meeting links of Zoom interview type will be cretaed from this account'
                                                : 'Connect with your Zoom account to enable your interviews to be scheduled on\n Zoom meetings'}
                                        </p>
                                    </div>
                                    <div className="calendar-data-btn">
                                        {zoom.inUse && (
                                            <button onClick={() => this.props.ZoomGetUserDisconnect()}>
                                                Disconnect
                                            </button>
                                        )}
                                        {!zoom.inUse && <button onClick={this.zoomConnect}>Connect</button>}
                                    </div>
                                </div>
                            </ComponentContainer> */}

                            <ComponentContainer heading="Outlook Calendar">
                                <div className="calendar-data-body">
                                    <div style={{ marginLeft: 5 }}>
                                        <p className="calendar-data-title">
                                            <b>
                                                {OCal.outlookInUse
                                                    ? // ? 'Outlook Calendar Connected'
                                                    OCal.name && OCal.name + ' (' + OCal.email + ')'
                                                    : 'Connect your outlook calendar'}
                                            </b>
                                        </p>
                                        <p className="calendar-data-text">
                                            {OCal.outlookInUse
                                                ? 'Recruiters will be able to schedule interviews within time slots which you providedasdasd'
                                                : 'Connect your calendar to stay up to date with events scheduled in\n SpringRecruit'}
                                        </p>
                                    </div>
                                    <div className="calendar-data-btn">
                                        {OCal.outlookInUse && (
                                            <button onClick={this.outlookDisconnect}>Disconnect</button>
                                        )}
                                        {!OCal.outlookInUse && <button onClick={this.outlookConnect}>Connect</button>}
                                    </div>
                                </div>
                            </ComponentContainer>

                            <DoSelectDetails
                                accessLevel={accessLevel}
                                apiKey={doSelectCredential.apiKey}
                                apiSecret={doSelectCredential.apiSecret}
                                hasDoSelectCredentials={hasDoSelectCredentials}
                                companyId={companyId}
                            />
                            <UnberryDetails
                                accessLevel={accessLevel}
                                apiKey={unberryCredential.apiKey}
                                hasUnberryCredentials={hasUnberryCredentials}
                                companyId={companyId}
                            />
                        </div>
                        {accessLevel < ACCESS_LEVEL_DEFINITIONS.JOB_LEVEL_ACCESS.accessLevel && (
                            <div className="AccountSettingWrapperContentright">
                                <TeamMembers
                                    hidden={accessLevel > 2}
                                    currentUserId={this.state.currentUserId}
                                    companyId={this.state.companyId}
                                />
                            </div>
                        )}
                    </div>
                </PageWrapper>
                <Modal
                    type={MODAL_TYPES.CHANGE_PASSWORD}
                    visible={this.state.changePasswordModalVisible}
                    onCancel={this.handleCancel}
                    onConfirm={this.changePassword}
                    width={400}
                />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        user: state.Auth.user,
        teamMembers: state.Mixed.teamMembers.filter(member => member._id !== state.Auth.user.id),
        zoom: state.Zoom,
        OCal: state.OCal,
    }
}

export default connect(
    mapStateToProps,
    {
        ChangePassword,
        UpdateEmailNotifications,
        ZoomUserRegister,
        ZoomGetUserDetails,
        ZoomGetUserDisconnect,
        OutlookUserRegister,
        OutlookUserDisconnect,
    }
)(AccountSettingsNew)
