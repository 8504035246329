import React from 'react'
import Switch from './style'

const StyledSwitch = ({ disable = false, checked, onChange = () => {} }) => {
    return (
        <Switch disable={disable}>
            <input id="switchInput" type="checkbox" checked={checked} onChange={() => !disable && onChange()} />
            <span className="slider round" />
        </Switch>
    )
}

export default StyledSwitch
