import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import { Select, Icon, AutoComplete, Input } from 'antd'
import './algoliaSearchBox.css'
import { store } from '../../../redux/store'
import { warning } from '../../../services/Notification'
import RemoveIcon from '../../../assets/Icons/AccountSettingPage/Remove Button'
import config from '../../../config'
import { ACCESS_LEVEL_DEFINITIONS } from '../../Helpers/constants'
import Typesense from 'typesense'
const client = algoliasearch('KC3488J1QJ', '08bf615eb3bfb1a9300be44426ab6766')
const { typesense } = config.config()
const index = client.initIndex(config.config().indexName)

const typesenseClient = new Typesense.Client({
    nodes: [
        {
            host: typesense.host,
            port: typesense.port,
            protocol: 'https',
        },
    ],
    apiKey: typesense.search_api_key,
})

class AlgoliaSearch extends React.Component {
    state = {
        accessLevel: store.getState().Auth.user.accessLevel,
        userId: store.getState().Auth.user.id,
        companyId: store.getState().Auth.user.companyId,
        options: [],
        value: undefined,
        selectedCandidate: undefined,
    }

    submit = async value => {
        const { selectedCandidate, options } = this.state
        if (!value && selectedCandidate) {
            await this.setState({ value: undefined, selectedCandidate: undefined, options: [] })
            this.props.onClear(selectedCandidate)
        } else {
            const candidate = options.find(can => can.objectID === value)
            this.setState({ value, selectedCandidate: value })
            if (candidate.isRejected || !candidate.status) {
                this.props.history.push({
                    pathname: `/openings/list/${candidate.jobId}`,
                    state: {
                        searchCandidate: value,
                        filter: candidate.isRejected ? 'rejected' : 'archived',
                    },
                })
            } else {
                this.props.onSubmit(value, selectedCandidate)
            }
        }
    }

    searchApplications = async query => {
        const { jobId } = this.props
        const { selectedCandidate, accessLevel, userId, companyId } = this.state
        const currentJob = store.getState().Job.allJobs.find(job => job._id === jobId)

        if (!query) {
            this.setState({ value: undefined, selectedCandidate: undefined, options: [] })
            if (selectedCandidate) {
                this.props.onClear(selectedCandidate)
            }
            return
        }

        this.setState({ value: query })

        let filters = `companyId:"${companyId}" && jobId:"${jobId}"`
        if (accessLevel === ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel) {
            filters = `${filters} && userId:"${userId}"`
        }
        typesenseClient
            .collections(typesense.collection)
            .documents()
            .search({ q: query, query_by: 'email,name', filter_by: filters, per_page: 20 })
            .then(({ hits: options }) => {
                options = options ? options : []
                options = options.map(({ document: opt }) => {
                    if (opt.isRejected || !opt.status) {
                        opt.stageName = opt.isRejected ? 'REJECTED' : 'ARCHIVED'
                    } else {
                        const stage = currentJob.stages.find(stg => stg._id === opt.stageId)
                        if (stage) {
                            opt.stageName = stage.stageName
                        }
                    }

                    return opt
                })
                this.setState({ options })
            })
            .catch(err => {
                if (err) {
                    warning(err.message)
                }
            })
    }

    render() {
        const options = this.state.options.map(opt => (
            <Select.Option key={opt.objectID} className="ats-algolia-option" title={opt.name}>
                {opt.name}
                <span className="ats-search-stages"> | {opt.stageName}</span>
            </Select.Option>
        ))

        return (
            <AutoComplete
                className="ats-searchbox"
                defaultActiveFirstOption
                size="large"
                dataSource={options}
                placeholder="Search candidates within this job"
                onSearch={this.searchApplications}
                onSelect={this.submit}
                value={this.state.value}
                optionLabelProp="title"
            >
                <Input
                    onFocus={() => this.props.handleFocus(true)}
                    onBlur={() => this.props.handleFocus(false)}
                    ref={this.props.reference}
                    value={this.state.value}
                    suffix={
                        this.state.selectedCandidate ? (
                            <Icon component={RemoveIcon} onClick={() => this.submit()} />
                        ) : (
                            <Icon type="search" />
                        )
                    }
                />
            </AutoComplete>
        )
    }
}

export default AlgoliaSearch
