import React, { useState } from 'react'
import { ReactComponent as CalendarIcon } from '../../../../assets/Icons/Calendar/calendar.svg'
import { ReactComponent as GoogleIcon } from '../../../../assets/Icons/Calendar/google_icon.svg'
import { Content, Header, Item, Num, Buttons, Button, Footer, Google } from '../style'
import { Checkbox } from 'antd'
import { initiateGoogleAuth } from './../../../Helpers/calendarPopUp'
import { useSelector, connect } from 'react-redux'
import { SetGCalNotInUse } from '../../../../services/NetworkCalls/GoogleCalendar'

const ConnectRequest = props => {
    const [googleCalDenied, setGoogleCalDenied] = useState(false)
    const _checkBoxChange = () => setGoogleCalDenied(!googleCalDenied)
    const gCal = useSelector(({ GCal }) => GCal)

    const gCalNotUsed = async () => {
        let success = await props.SetGCalNotInUse()
        if (success) props.changeStep(5)
    }

    const googleAuthInitiate = () => {
        initiateGoogleAuth(props.redirectPath)
    }
    return (
        <Content>
            <Header>
                <CalendarIcon /> Connect your google calendar
            </Header>
            <Item>
                <Num>01</Num>
                <p>Connect your calendar to stay up to date with events scheduled in SpringRecruit</p>
            </Item>
            <Item>
                <Num>02</Num>
                <p>Allow SpringRecruit to auto check your calendar for your availability and scheduling events</p>
            </Item>
            <Item>
                <Num>03</Num>
                <p>
                    Let your recruitment team know your availability before scheduling interviews without any conflicts
                    / event overlaps
                </p>
            </Item>
            <Buttons onClick={googleAuthInitiate}>
                <Google disabled={googleCalDenied}>
                    <GoogleIcon />
                </Google>
                <Button disabled={googleCalDenied}>Connect with Google</Button>
            </Buttons>
            {!gCal.inUse && (
                <Footer>
                    <Checkbox onChange={_checkBoxChange}>I don't use google calendar</Checkbox>
                    {googleCalDenied && (
                        <Button onClick={gCalNotUsed} secondary>
                            Confirm
                        </Button>
                    )}
                </Footer>
            )}
        </Content>
    )
}

export default connect(
    null,
    { SetGCalNotInUse }
)(ConnectRequest)
