import React from 'react'
import { MessageModal } from '../common'

export const ConfirmGoogleDisconnect = props => (
    <MessageModal
        title="DISCONNECT GOOGLE CALENDAR"
        message="Are you sure you want to disconnect google calendar?"
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
        cancelText="NO"
        confirmText="YES"
    />
)
