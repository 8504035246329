import styled from 'styled-components'
import { ContentWrapper } from '../style'
import { CustomScrollBar } from '../../Helpers/Stylesheet/styledComponents'

export const ModalHeader = styled.div`
    text-align: center;
    border-bottom: solid 1px #e1e4e8;
    padding-bottom: 20px;
`
export const ModalTitle = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-transform: uppercase;
    text-align: left;
`
export const ModalDesc = styled.div`
    font-size: 16px;
    color: #333333;
`
export const ModalSubTitle = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: ${({ small }) => (small ? '10px' : '15px')};
`

export const ModalWrapper = styled(ContentWrapper)`
    max-height: 70vh;
    overflow: auto;
    margin-bottom: 0;

    ${CustomScrollBar};
    padding-right: 20px;
    margin-right: -20px;

    ${({ noShow = false }) => (noShow ? 'opacity:0.4' : '')}
    ${({ noShow = false }) => (noShow ? 'pointer-events: none' : '')}
`
export const ModalButtons = styled.div`
    text-align: left;
    button {
        width: 80px;
        height: 40px;
        border-radius: 4px;
        border: solid 1px #4767a0;
        margin-left: 10px;
    }
    .add {
        background: #4767a0;
        color: #fff;
    }
    .cancel {
        background: #fff;
        color: #4767a0;
    }
    .cancel:hover {
        background: #fff;
        color: #4767a0;
    }
    @media only screen and (max-width: 769px) {
        display: flex;
        button {
            width: 50%;
        }
        .cancel {
            margin: 0;
        }
    }
`
export const Block = styled.div`
    padding: ${props => (!props.small ? '30px 0' : '20px 0 0 0')};
    border-bottom: ${props => !props.small && 'solid 1px #e1e4e8'};
    .opinion {
        display: flex;
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 12px;
            color: #999999;
            margin-right: 30px;
            i {
                height: 60px;
                width: 60px;
                margin: auto;
            }
            svg {
                margin-bottom: 10px;
            }
            cursor: pointer;
        }
    }
    .rating {
        width: 80%;
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: sanFranciscoMedium;
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            svg {
                width: 14px;
                height: 14px;
            }
            .item-custom-skill {
                line-height: 16px;
                margin-top: 4px;
                svg {
                    cursor: pointer;
                    margin-left: 5px;
                    width: 12px;
                    height: 12px;
                }
            }
        }
        .ant-rate {
            color: #4767a0;
        }
    }
    textarea {
        width: 100%;
        height: 150px;
        resize: none;
    }
    @media only screen and (max-width: 769px) {
        .opinion {
            flex-wrap: wrap;
            margin-top: -15px;
            .item {
                margin-top: 15px;
                width: 20%;
            }
            svg {
                width: 30px;
                height: 30px;
            }
        }
        .rating {
            flex-direction: column;
            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 10px 0;
            }
        }
    }
`
