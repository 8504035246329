import styled from 'styled-components'

export const ComponentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
    @media only screen and (max-width: 995px) {
        flex-direction: column;
        margin-bottom: 0;
    }
`
export const Body = styled.div`
    height: 100%;
    overflow: auto;
    padding: 30px 4.5vw;
    background-color: #f7f8fc;
    @media only screen and (max-width: 995px) {
        padding: 0;
    }
`
export const Title = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
    text-transform: uppercase;
    @media only screen and (max-width: 995px) {
        padding: 20px 0 15px 15px;
        font-size: 16px;
        margin: 0;
    }
    padding: 0 10px;
`
export const FooterBar = styled.div`
    height: 64px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 4.5vw;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    @media only screen and (max-width: 995px) {
        display: block;
        height: auto;
        padding: 10px 0;
        text-align: center;
    }
`

export const CancelConfirmationText = styled.p`
    display: ${({ cancelMode }) => (cancelMode ? 'block' : 'none')};
    transition: color 0.5s ease;
    margin: auto 14px;
    line-height: 16px;
    @media only screen and (max-width: 995px) {
        text-align: center;
        margin-bottom: 10px;
    }
    .help {
        font-size: 10px;
    }
`
