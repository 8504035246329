import ContentLoader from 'react-content-loader'
import React from 'react'

export const MessageLoader = () => (
    <ContentLoader>
        <rect x="0" y="0" rx="5" ry="5" width="25" height="25" />
        <rect x="30" y="0" rx="1" ry="1" width="150" height="7" />
        <rect x="30" y="12" rx="1" ry="1" width="100" height="7" />
        <rect x="0" y="35" rx="1" ry="1" width="350" height="7" />
        <rect x="0" y="47" rx="1" ry="1" width="350" height="7" />
        <rect x="0" y="59" rx="1" ry="1" width="350" height="7" />
        <rect x="0" y="71" rx="1" ry="1" width="350" height="7" />
        <rect x="0" y="83" rx="1" ry="1" width="350" height="7" />
        <rect x="0" y="95" rx="1" ry="1" width="350" height="7" />
        <rect x="0" y="107" rx="1" ry="1" width="200" height="7" />
    </ContentLoader>
)

export const FeedBackLoader = () => (
    <ContentLoader height="100">
        <rect x="2%" y="10%" rx="0.5%" ry="0.5%" width="30%" height="10%" />
        <rect x="2%" y="30%" rx="0.5%" ry="0.5%" width="40%" height="7%" />
        <rect x="2%" y="42%" rx="0.5%" ry="0.5%" width="40%" height="7%" />
        <rect x="2%" y="54%" rx="0.5%" ry="0.5%" width="20%" height="7%" />
        <rect x="51%" y="30%" rx="0.5%" ry="0.5%" width="30%" height="7%" />
        <rect x="51%" y="42%" rx="0.5%" ry="0.5%" width="30%" height="7%" />
        <rect x="51%" y="54%" rx="0.5%" ry="0.5%" width="30%" height="7%" />
        <rect x="51%" y="66%" rx="0.5%" ry="0.5%" width="30%" height="7%" />
        <circle cx="92%" cy="40%" r="4%" />
        <rect x="88.5%" y="55%" rx="0.5%" ry="0.5%" width="7%" height="5%" />
    </ContentLoader>
)
