import React, { useState } from 'react'
import { StyledButton } from '../../../Components'
import { Heading } from '../../../../container/Pages/Analytics/style'
import { ReactComponent as AttachIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/attach.svg'
import { Checkbox, Icon, Input, Select, Upload } from 'antd'
import { ReactComponent as LockIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/lock.svg'
import MessageSection from '../Sections/MessageSection'
import { Editor } from '@tinymce/tinymce-react'
import config from '../../../../config'
import RemoveIcon from '../../../../assets/Icons/AccountSettingPage/Remove Button'
import { getCandidateEmailList } from '../../../../services/NetworkCalls/Inbox'
import { emailRegExp } from '../../../Modals/AddCandidateDetails/Data'
import PdfIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/pdf'
import XlsIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/xls'
import ImgIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/img'
import DocIcon from '../../../../assets/Icons/CandidateProfile-Messages+ThreadView/docx'
import moment from 'moment'
import { validateEmailData } from './helperFunctions'
import { warning } from '../../../../services/Notification'
import { NewMessageContainer } from '../style'
import { CANDIDATE } from '../../../Helpers/RegEx'
const { isSmallDesktop } = config

const { v1: uuid } = require('uuid')
const { tinyMCEApiKey } = config.config()

const { TextArea } = Input

export const HeadingSection = ({ toggleNewMessage, showNewMessage }) => (
    <Heading flex>
        Inbox
        <StyledButton width="139px" height="40px" disable={showNewMessage} onClick={toggleNewMessage}>
            Compose
        </StyledButton>
    </Heading>
)

const DUMMY_CANDIDATES = [
    { email: 'suryaaa@yopmail', fullName: 'Surya' },
    { email: 'anthony@yopmail', fullName: 'Anthony' },
]
const filterValidEmails = ls => ls.filter(each => emailRegExp.test(each))

export const NewMessageView = ({ toggleNewMessage, showNewMessage, teamMembers, user, sendInboxEmail }) => {
    const [ccEmailL, setccEmail] = useState([])
    const [toEmail, setToEmail] = useState([])
    const [candidateDropList, setCandidateDropList] = useState([])

    const [disableSendButton, setDisableSentButton] = useState(false)
    const [attachmentsArray, setAttachmentsArray] = useState([])
    const [markedPrivate, setMarkedPrivate] = useState(false)
    const [addMore, setAddMore] = useState(false)
    const [subject, setSubject] = useState('')
    const [emailContent, setEmailContent] = useState('')
    const [accessibleList, setAccessibleList] = useState([])

    if (!showNewMessage) return null

    const handleSendEmail = async () => {
        const values = {
            attachments: attachmentsArray,
            userId: user.id,
            toEmail,
            fromEmail: user.companyUniqueEmailId,
            ccEmails: ccEmailL,
            subject: subject.trim(),
            emailContent: emailContent.trim(),
            emailDate: moment().format(),
            markedPrivate,
            accessibleTo: accessibleList,
        }

        const validationResult = validateEmailData(values)
        if (!validationResult.result) {
            warning(validationResult.message)
            return null
        }
        setDisableSentButton(true)
        await sendInboxEmail(values)
        setDisableSentButton(false)
        toggleNewMessage(null)
    }

    let apiInQueue = null

    const handleSearch = value => {
        apiInQueue = uuid().toString()
        getCandidateEmailList({ apiInQueue, value })
            .then(({ data: { result, apiInQueue: resApiInQueue }, status }) => {
                if (apiInQueue === resApiInQueue) setCandidateDropList(result)
            })
            .catch(err => console.log('ERRRRRRR', err))
    }

    const setFile = file => {
        const newArray = attachmentsArray.map(each => each)
        newArray.push(file)
        setAttachmentsArray(newArray)
        return false
    }

    const removeFile = file => {
        const index = attachmentsArray.indexOf(file)
        const newattachments = attachmentsArray.slice()
        newattachments.splice(index, 1)
        setAttachmentsArray(newattachments)
    }

    const displayAttachment = file => {
        const { name, uid } = file
        const ext = name.split('.')[1]

        let icon = PdfIcon
        if (['xls', 'xlxs', 'excel', 'csv'].includes(ext)) {
            icon = XlsIcon
        } else if (['jpg', 'jpeg', 'png', 'svg'].includes(ext)) {
            icon = ImgIcon
        } else if (['doc', 'docx'].includes(ext)) {
            icon = DocIcon
        }

        return (
            <div className="message-files" key={uid}>
                <Icon component={icon} />
                <div className="sample-pdf">{name}</div>
                <Icon component={RemoveIcon} className="cpm-attachment-icon" onClick={() => removeFile(file)} />
            </div>
        )
    }

    const handleTOEMail = data => {
        let canUpdate = true

        if (data.length > toEmail.length) {
            // email added.. check if its in teamMember
            canUpdate =
                CANDIDATE.EMAIL.test(data[data.length - 1]) &&
                candidateDropList.find(({ email }) => email === data[data.length - 1])
        }

        if (canUpdate) setToEmail(data)
    }

    const handleCCEMail = data => {
        if (data.length > ccEmailL.length) {
            // email added.. check if its in teamMember
            data = data.filter(each => CANDIDATE.EMAIL.test(each) && teamMembers.find(({ email }) => email === each))
        }
        setccEmail(data)
    }

    const removeUserHandler = mem => setAccessibleList(accessibleList.filter(each => each !== mem))

    const handleMessageChange = (content, editor) => {
        setEmailContent(content)
    }

    return (
        <NewMessageContainer isSmallDesktop={isSmallDesktop}>
            <div className="header">NEW MESSAGE</div>
            <div className="body">
                <div className="input">
                    <span>To</span>
                    <Select
                        placeholder="Enter email"
                        name="ccemail"
                        filterOption
                        optionFilterProp="title"
                        optionLabelProp="label"
                        mode="tags"
                        showArrow={false}
                        size="large"
                        onChange={handleTOEMail}
                        onSearch={handleSearch}
                        menuItemSelectedIcon={null}
                        removeIcon={<Icon component={RemoveIcon} className="cpm-cc-remove-icon" />}
                        value={toEmail}
                        dropdownClassName="cc-email-dropdown"
                        tokenSeparators={[',']}
                    >
                        {candidateDropList.map(({ name, email, _id }) => {
                            const displayName = `${name || 'No Name'} <${email}>`
                            return (
                                <Select.Option
                                    key={_id}
                                    value={email}
                                    title={displayName}
                                    label={name || email}
                                    className="cc-email-options"
                                >
                                    {displayName}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </div>
                <div className="input">
                    <span>Cc:</span>
                    <Select
                        placeholder="Enter email"
                        name="ccemail"
                        filterOption
                        optionFilterProp="title"
                        optionLabelProp="label"
                        mode="tags"
                        showArrow={false}
                        onChange={handleCCEMail}
                        menuItemSelectedIcon={null}
                        removeIcon={<Icon component={RemoveIcon} className="cpm-cc-remove-icon" />}
                        value={ccEmailL}
                        dropdownClassName="cc-email-dropdown"
                        tokenSeparators={[',']}
                    >
                        {teamMembers.map((mem, index) => {
                            const displayName = `${mem.fullName || 'No Name'} <${mem.email}>`
                            return (
                                <Select.Option
                                    key={index}
                                    value={mem.email}
                                    title={displayName}
                                    label={mem.fullName || mem.email}
                                    className="cc-email-options"
                                >
                                    {displayName}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </div>
                <div className="input">
                    <span>Sub</span>
                    <input
                        value={subject}
                        type="text"
                        className="sub"
                        onChange={({ target: { value } }) => setSubject(value)}
                    />
                </div>
                {/* Textarea needs the tool icons to be added */}
                <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    // apiKey={tinyMCEApiKey}
                    id="new-message-view"
                    onEditorChange={handleMessageChange}
                    value={emailContent}
                    init={{
                        min_height: 180,
                        max_height: 180,
                        force_br_newlines: true,
                        forced_root_block: '',
                        menubar: false,
                        plugins: 'link image code autoresize lists',
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                    }}
                />
                <div className="mail-options">
                    <Upload
                        multiple
                        beforeUpload={setFile}
                        showUploadList={false}
                        onRemove={removeFile}
                        fileList={attachmentsArray}
                    >
                        <span>
                            <AttachIcon />
                            Add Attachment
                        </span>
                    </Upload>
                    <Checkbox checked={markedPrivate} onChange={() => setMarkedPrivate(!markedPrivate)}>
                        <LockIcon className="lock" />
                        Mark as private
                    </Checkbox>
                </div>

                <div className="email-attachment-view mt-20">{attachmentsArray.map(displayAttachment)}</div>

                {markedPrivate ? (
                    <>
                        <div className="note">
                            Note: This message is only visible to You,
                            {accessibleList.map(mem => {
                                let displayName = teamMembers.find(x => x._id === mem)
                                displayName = displayName.fullName || displayName.email
                                return (
                                    <React.Fragment key={mem}>
                                        <div className="an-nn-sel-user2 top-margin-7">
                                            <div>{displayName}</div>
                                            <Icon component={RemoveIcon} onClick={() => removeUserHandler(mem)} />
                                        </div>
                                        , &nbsp;
                                    </React.Fragment>
                                )
                            })}
                            {teamMembers
                                .filter(mem => ccEmailL.includes(mem.email))
                                .map(mem => (
                                    <div>,&nbsp; {mem.fullName || mem.email}</div>
                                ))}
                            Super Admins and Admins
                        </div>
                        <div className="add-more top-margin-7" onClick={() => setAddMore(true)}>
                            Add more people who can view this message?
                        </div>
                        {addMore ? (
                            <Select
                                className="an-nn-search"
                                placeholder="Search team member to add"
                                notFoundContent={null}
                                showSearch
                                suffixIcon={<Icon type="search" />}
                                defaultActiveFirstOption={false}
                                onSelect={data => setAccessibleList([...accessibleList, data])}
                                style={{ width: '60%' }}
                                value={''}
                                // onSearch={searchTextHandler}
                                filterOption
                                optionFilterProp="title"
                            >
                                {teamMembers
                                    .filter(
                                        ({ _id, status, email }) =>
                                            status && !ccEmailL.includes(email) && !accessibleList.includes(_id)
                                    )
                                    .map(mem => (
                                        <Select.Option
                                            key={mem._id}
                                            className="an-nn-opt"
                                            title={mem.fullName + ' ' + mem.email}
                                        >
                                            <div className="an-nn-name">{mem.fullName || mem.email}</div>
                                            <div className="an-nn-add">Add</div>
                                        </Select.Option>
                                    ))}
                            </Select>
                        ) : null}
                    </>
                ) : null}

                <div className="buttons">
                    <StyledButton type="inverted" width="80px" height="40px" onClick={() => toggleNewMessage(null)}>
                        Cancel
                    </StyledButton>
                    <StyledButton disabled={disableSendButton} width="80px" height="40px" onClick={handleSendEmail} di>
                        Send
                    </StyledButton>
                </div>
            </div>
        </NewMessageContainer>
    )
}
