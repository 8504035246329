import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module'
import 'antd/dist/antd.css';
import './styles.css'
import "./antd-overrides.less";

import App from './App'

const tagManagerArgs = {
  gtmId: 'GTM-NJ35SZC',
}

if (process.env.REACT_APP_PATH === 'production') TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
