import React, { Component } from 'react'
import { Content, Preview, Header, Body, Label, Field, Tag, Footer } from './style'
import { Checkbox, Tooltip, Select, Input } from 'antd'

import { Editor } from '@tinymce/tinymce-react'
import { ReactComponent as InfoIcon } from '../../../../assets/Icons/Interview/info.svg'
import { ReactComponent as TickIcon } from '../../../../assets/Icons/Interview/tick.svg'
import { store } from '../../../../redux/store'
import config from '../../../../config'
import { EMAIL_TEMPLATE, INTERVIEW_TYPES_KEYS, SUBJECT_TEMPLATE } from './../Constants'

const { tinyMCEApiKey } = config.config()

const text = (
    <span className="tooltip">A separate mail will be sent to the interviewer with all the relevant details.</span>
)

export default class EmailPreview extends Component {
    constructor(props) {
        super(props)
        const { teamMembers } = store.getState().Mixed
        let cc = teamMembers
            .filter(member => props.cc && props.cc.includes(member._id))
            .map(member => {
                return { key: member._id, label: member.fullName || member.email }
            })

        this.state = {
            sendEmailToCandidate: true,
            content: EMAIL_TEMPLATE(props),
            showCCOptions: false,
            subject: SUBJECT_TEMPLATE(props),
            cc: cc || [],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.interviewType !== prevProps.interviewType)
            this.setState({
                content: EMAIL_TEMPLATE(this.props),
                subject: SUBJECT_TEMPLATE({ ...this.props, interview: null }), // when update happens already saved subject is discarded
            })
        else if (
            this.props.interviewType === INTERVIEW_TYPES_KEYS.HANGOUTS &&
            this.props.hangoutsLink !== prevProps.hangoutsLink
        )
            this.setState({ content: EMAIL_TEMPLATE(this.props) })
        else if (
            this.props.selectedDate !== prevProps.selectedDate ||
            this.props.startTime !== prevProps.startTime ||
            this.props.endTime !== prevProps.endTime
        ) {
            this.setState({ content: EMAIL_TEMPLATE(this.props) })
        } else if (
            this.props.interviewType === INTERVIEW_TYPES_KEYS.F2F &&
            this.props.selectedAddress !== prevProps.selectedAddress
        ) {
            this.setState({ content: EMAIL_TEMPLATE(this.props) })
        } else if (this.props.noHangouts !== prevProps.noHangouts) {
            this.setState({ content: EMAIL_TEMPLATE(this.props) })
        }

        if (this.props.cc !== prevProps.cc) {
            const { teamMembers } = store.getState().Mixed
            let cc = teamMembers
                .filter(member => this.props.cc && this.props.cc.includes(member._id))
                .map(member => {
                    return { key: member._id, label: member.fullName || member.email }
                })

            this.setState({ cc })
        }
    }

    checkBoxChange = data => {
        this.setState(({ sendEmailToCandidate }) => ({ sendEmailToCandidate: !sendEmailToCandidate }))
    }

    handleChange = values => {
        this.setState({ showCCOptions: false }, () => {
            const { teamMembers } = store.getState().Mixed
            let ccIds = teamMembers
                .filter(member => values.map(value => value.key).includes(member._id))
                .map(member => member._id)
            this.props.updateParentState({ cc: ccIds })
            this.setState({ cc: values })
        })
    }

    handleBlur = () => {
        this.setState({ showCCOptions: false })
    }

    render() {
        const { candidate } = this.props
        const { sendEmailToCandidate, cc, subject } = this.state
        const { companyName, companyUniqueEmailId } = store.getState().Auth.user
        let { teamMembers } = store.getState().Mixed
        teamMembers = teamMembers
            .filter(member => (this.props.cc ? !this.props.cc.includes(member._id) : true))
            .sort((a, b) => {
                if (a.status === 1) return -1
                else return 1
            })
            .sort((a, b) => {
                if (a.fullName < b.fullName) return -1
                else return 1
            })

        return (
            <Content>
                <Header>
                    <Checkbox onChange={this.checkBoxChange} checked={sendEmailToCandidate}>
                        Send Email invite to the candidate
                    </Checkbox>
                </Header>
                <Preview disabled={!sendEmailToCandidate}>
                    <Body>
                        <Field>
                            <Label>From</Label>
                            <Tag>
                                {`${companyName} | SpringRecruit`}{' '}
                                <span className="email">({companyUniqueEmailId})</span>
                            </Tag>
                        </Field>
                        <Field>
                            <Label>To</Label>
                            <Tag>
                                {candidate.name || 'Candidate'} <span className="email">({candidate.email})</span>
                            </Tag>
                        </Field>
                        <Field>
                            <Label>CC</Label>
                            <Select
                                mode="multiple"
                                dropdownClassName="ghost-select-dropdown"
                                optionLabelProp="label"
                                labelInValue
                                getPopupContainer={trigger => trigger.parentNode}
                                className="ghost-select"
                                style={{ width: '100%' }}
                                placeholder="Optional"
                                filterOption={(input, { props }) => {
                                    return (
                                        props.label.toLowerCase().includes(input.toLowerCase()) ||
                                        props.email.toLowerCase().includes(input.toLowerCase())
                                    )
                                }}
                                value={cc}
                                open={this.state.showCCOptions}
                                onInputKeyDown={() => this.setState({ showCCOptions: true })}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                            >
                                {teamMembers.map(member => {
                                    return (
                                        <Select.Option
                                            key={member._id}
                                            label={member.fullName || member.email}
                                            email={member.email}
                                            value={member._id}
                                        >
                                            <div className="option">
                                                <span>{member.fullName}</span>
                                                <span className="email">{member.email}</span>
                                            </div>
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </Field>
                        <Field>
                            <Label>Sub</Label>
                            <Input
                                className="subject"
                                value={subject}
                                onChange={({ target: { value } }) => this.setState({ subject: value })}
                            />
                        </Field>

                        <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                            // apiKey={tinyMCEApiKey}
                            value={this.state.content}
                            onEditorChange={content => {
                                this.setState({ content })
                            }}
                            disabled={!this.state.sendEmailToCandidate}
                            init={{
                                force_br_newlines: true,
                                forced_root_block: '',
                                content_style: 'div { font-size: 13px; color: #333 }',
                                menubar: false,
                                min_height: 390,
                                max_height: 390,
                                plugins: 'link image code autoresize lists',
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                            }}
                        />
                    </Body>
                    <Footer>
                        <TickIcon />
                        <span>Mail will be sent to the interviewer</span>
                        <Tooltip placement="bottom" title={text}>
                            <InfoIcon className="info" />
                        </Tooltip>
                    </Footer>
                </Preview>
            </Content>
        )
    }
}
