import React from 'react'
import { DrawerHeadContainer } from './../style'
import RemoveIconFreeSize from '../../../../assets/Icons/Todo/Remove Button Free Size'
import { Icon } from 'antd'

const DrawerHeader = ({ title, width = '100%', height = '50px', onClose }) => {
    return (
        <DrawerHeadContainer width={width} height={height}>
            <span className="drawer-herder-title">{title}</span>
            <Icon style={{ width: '20px', height: '20px' }} component={RemoveIconFreeSize} onClick={onClose} />
        </DrawerHeadContainer>
    )
}

export default DrawerHeader
