import React from 'react'
import { MessageModal } from '../common'
import './style.scss'

const WarningHangoutsUnavailable = ({ onCancel, onConfirm, isRescheduled }) => (
    <MessageModal
        title="GOOGLE MEET UNAVAILABLE"
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelText="GO BACK"
        confirmText="PROCEED"
        message={
            <div className="message-container">
                <div className="message-container__msg">
                    Google Meet link will not be created for the interview, as none of the selected interviewers have
                    given access to their google calendars.
                </div>
                <div className="message-container__question">
                    Do you want to {isRescheduled ? 'reschedule' : 'schedule'} the interview without the link?
                </div>
            </div>
        }
    />
)

export default WarningHangoutsUnavailable
