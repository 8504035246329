import React from 'react'
import { Aside, Title, Message } from './style'
import { SIDE_PANEL_DATA } from './DataConfigs'
import { CSSTransition } from 'react-transition-group'
import { ReactComponent as WelcomeImg } from '../../../assets/Icons/Calendar/welcome.svg'
import { ReactComponent as ConnectedImg } from '../../../assets/Icons/Calendar/connected.svg'
import { ReactComponent as DeniedImg } from '../../../assets/Icons/Calendar/denied.svg'
import { ReactComponent as TickIcon } from '../../../assets/Icons/Calendar/tick.svg'

const SidePanel = ({ step }) => {
    const data = SIDE_PANEL_DATA[step]
    return (
        <Aside>
            {data.tick && <TickIcon className="tick" />}
            {data.title && (
                <CSSTransition in appear timeout={5000} classNames="calendar-onboarding-title">
                    <Title>
                        <span>{data.title}</span>
                    </Title>
                </CSSTransition>
            )}
            {data.text && <Message>{data.text}</Message>}
            {data.image && (
                <CSSTransition in appear timeout={5000} classNames="calendar-onboarding-image">
                    {data.image === 'welcome' ? (
                        <WelcomeImg />
                    ) : data.image === 'connected' ? (
                        <ConnectedImg />
                    ) : data.image === 'denied' ? (
                        <DeniedImg />
                    ) : null}
                </CSSTransition>
            )}
        </Aside>
    )
}

export default SidePanel
