import React from 'react'

const SVGComponent = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M22.2857143,0 L1.70892857,0 C0.766071429,0 0,0.755208333 0,1.68229167 L0,21.6510417 C0,22.578125 0.766071429,23.3333333 1.70892857,23.3333333 L22.2857143,23.3333333 C23.2285714,23.3333333 24,22.578125 24,21.6510417 L24,1.68229167 C24,0.755208333 23.2285714,0 22.2857143,0 Z M7.25357143,20 L3.69642857,20 L3.69642857,8.86458333 L7.25892857,8.86458333 L7.25892857,20 L7.25357143,20 Z M5.475,7.34375 C4.33392857,7.34375 3.4125,6.44270833 3.4125,5.33854167 C3.4125,4.234375 4.33392857,3.33333333 5.475,3.33333333 C6.61071429,3.33333333 7.5375,4.234375 7.5375,5.33854167 C7.5375,6.44791667 6.61607143,7.34375 5.475,7.34375 L5.475,7.34375 Z M20.5875,20 L17.0303571,20 L17.0303571,14.5833333 C17.0303571,13.2916667 17.0035714,11.6302083 15.1821429,11.6302083 C13.3285714,11.6302083 13.0446429,13.0364583 13.0446429,14.4895833 L13.0446429,20 L9.4875,20 L9.4875,8.86458333 L12.9,8.86458333 L12.9,10.3854167 L12.9482143,10.3854167 C13.425,9.51041667 14.5875,8.58854167 16.3178571,8.58854167 C19.9178571,8.58854167 20.5875,10.8958333 20.5875,13.8958333 L20.5875,20 L20.5875,20 Z"
                id="path-1-lindin"
            />
        </defs>
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g id="Dashboard-New---Create-Job+Step3:-Promote" transform="translate(-615 -784)">
                <g id="Candidate-Application" transform="translate(365 200)">
                    <g id="Group-2-Copy-2" transform="translate(0 508)">
                        <g id="Share-Options-Copy" transform="translate(235 60)">
                            <g id="Share-Option1">
                                <g id="Logo" transform="translate(15 16)">
                                    <mask id="mask-2-linkedin" fill="#fff">
                                        <use xlinkHref="#path-1-lindin" />
                                    </mask>
                                    <use id="linkedin" fill="#4767A0" fillRule="nonzero" xlinkHref="#path-1-lindin" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
