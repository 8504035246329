import React from 'react'

export const IMPORTANT_NOTES = {
    DESC: [
        'First row of the given file will be treated as field names. It should be exact as mentioned in sample file.',
        'While importing, Duplicates will be verified based on Email or Phone number field.',
        'Unexpected error may happen if the XLS file contains any special controls like filters or images embedded within it.',
        'If you want to attach resume along with the excel file, please follow the steps below.',
    ],
    SUBSTEPS: [
        'In your XLS or CSV file, create a separate column and name it as ‘Attachment’.',
        'Under the column ‘Attachment’ enter the file names.',
        'All files under the ‘Attachment’ should be created as single zip file.',
        'Browse the Zip file and import it under Upload Resume Zip File.',
        'Attachment Zip should not have any directory structure.',
    ],
}
