import React, { Component } from 'react'
import { Footer, Header } from '../common'
import { ComponentWrapper, Tabs, FooterMessages } from './style'
import InlineMode from './Components/InlineMode'
import BulkMode from './Components/BulkMode'
import BulkModePreview from './Components/BulkPreview'
import { MODE } from './DummyData'
import actionsCandidate from '../../../redux/candidate/actions'
import { defaultValues } from './Components/constants'
import { connect } from 'react-redux'
import { CANDIDATE } from '../../Helpers/RegEx'
import ConfirmDeleteCard from '../ViewFeedbacks/ConfirmDeleteCard'
import { ReactComponent as ErrorIcon } from '../../../assets/Icons/Openings/error.svg'
import { AddCandidateWithJob } from '../../../services/NetworkCalls/Candidate'
import { countryCodes } from '../../Helpers/countryCode'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { ReactComponent as SuccessIcon } from '../../../assets/Icons/Openings/success.svg'
import { success } from '../../../services/Notification'
import { store } from '../../../redux/store'
import { hasListDataModified } from './Components/HelperComponents'

const handleErrors = (name, email, phone, existingErrors, jobDetails = {}, data = {}) => {
    // errors to be only updated if there the no exixting errors.
    const errors = { name: false, email: false, phone: false, ...existingErrors }
    // name error
    if (errors.name && errors.name.length) {
        /*no change*/
    } else if (!errors.name && name.length < 1) errors.name = 'Candidate name is required'
    else errors.name = false
    // email error
    if (!errors.email) {
        if (email.length < 1) errors.email = 'Candidate email is required'
        else if (!CANDIDATE.EMAIL.test(email)) errors.email = 'Candidate email is invalid'
        else errors.email = false
    }

    // phone error:  if phone no is there then country code is required, else nothing happens
    if ([2, 3].includes(jobDetails.contactNo) && !errors.phone) {
        if (phone.number && phone.number.length) {
            if (phone.code && phone.code.length < 1) errors.phone = 'Country Code is required'
            else {
                const phoneObject = parsePhoneNumberFromString(phone.code + phone.number)
                if (phoneObject && !phoneObject.isValid()) errors.phone = 'Phone number is invalid'
            }
        } else if (phone && !phone.number && jobDetails.contactNo === 3) {
            errors.phone = 'Phone number is required'
        } else errors.phone = false
    }
    // Resume Errors
    if (jobDetails.resume === 3 && data && !data.resumeLink) {
        errors.resume = 'Resume is required'
    } else errors.resume = false

    Object.keys(jobDetails).map(item => {
        if (item != 'resume' && jobDetails[item] == 3 && defaultValues.includes(item) && !data[item]) {
            errors[item] = `${item} is required`
        }
    })
    return !errors.name && !errors.email && !errors.phone ? false : errors
}

class AddCandidate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mode: MODE.INLINE,
            errorCount: 0,
            confirmSwitch: null,
            confirmCancel: false,
            hasSubmitClicked: false,
            apiInProgress: false,
            successUploadsId: {},
        }
    }

    updateSuccessCount = (uploadId, type) => {
        this.setState(({ successUploadsId }) => {
            if (type === 'add') return { successUploadsId: { ...successUploadsId, [uploadId]: uploadId } }
            if (type === 'sub') {
                delete successUploadsId[uploadId]
                return { successUploadsId }
            }
        })
    }

    resetStateData = tab => {
        this.setState({
            ...(tab && { mode: MODE[tab] }),
            hasSubmitClicked: false,
            errorCount: 0,
            successUploadsId: {},
            apiInProgress: false,
        })
    }

    handleTab = tab => {
        const { mode } = this.state
        const { jobId: props_jobId = '', allJobs } = this.props

        // check if its a valid move
        if (tab === mode) return null

        let isSwitchAllowed = !hasListDataModified(props_jobId)

        if (isSwitchAllowed) {
            this.props.setStoreForMode(tab, props_jobId, allJobs)
            this.resetStateData(tab)
        } else this.setState({ confirmSwitch: MODE[tab] })
    }

    confirmCancelPreCheck = () => {
        const { jobId = '', onCancel } = this.props
        const hasModified = hasListDataModified(jobId)
        if (hasModified) this.setState({ confirmCancel: true })
        else onCancel()
    }

    onSubmit = async shouldReSet => {
        const { replaceCandidateData, emitCandidateError, props_jobId, jobDetails, allJobs } = this.props
        const { apiInProgress } = this.state
        const { data, list } = store.getState().Candidate
        if (apiInProgress) return null

        let finalOrder = []
        let hasListOrderUpdated = false
        const emailKeysToAppend = []
        const phoneKeysToAppend = []
        const uniqueEmails = {}
        const uniquePhoneNumber = {}

        // The applications with errors are put to top
        list.forEach((uploadId, index) => {
            const { name, email, phone, errors } = data[uploadId]
            const hasErrors = handleErrors(
                name,
                email,
                phone,
                errors,
                this.props && this.props.jobDetails && this.props.jobDetails.jobApplication,
                data[uploadId]
            )

            if (hasErrors === false) {
                finalOrder.push(uploadId)

                if (!hasErrors.email) {
                    // uniqueEmails
                    if (uniqueEmails.hasOwnProperty(email)) uniqueEmails[email].push(index)
                    else uniqueEmails[email] = [index]
                }

                if (!hasErrors.phone && phone.number) {
                    // uniquePhoneNumbers
                    if (uniquePhoneNumber.hasOwnProperty(phone.number)) uniquePhoneNumber[phone.number].push(index)
                    else uniquePhoneNumber[phone.number] = [index]
                }
            } else {
                hasListOrderUpdated = true
                data[uploadId].errors = hasErrors
                data[uploadId].hasErrors = true
                finalOrder.unshift(uploadId)
            }
        })

        // special check to unique emailId within the entered list if error not founds
        if (list.length > 1) {
            const uniqueEmailKeys = Object.keys(uniqueEmails)
            const uniquePhoneKeys = Object.keys(uniquePhoneNumber)

            // For Email
            if (uniqueEmailKeys.length && uniqueEmailKeys.length < list.length) {
                let indexToRemove = {}

                uniqueEmailKeys.forEach(each => {
                    const val = uniqueEmails[each]
                    if (val.length > 1)
                        indexToRemove = {
                            ...val.reduce((o, val) => {
                                o[val] = val
                                return o
                            }, {}),
                            ...indexToRemove,
                        }
                })

                // push all the indexToRemove ids to top
                finalOrder = finalOrder.map((val, index) => {
                    if (indexToRemove.hasOwnProperty(index)) {
                        emailKeysToAppend.push(val)
                        hasListOrderUpdated = true
                        return null
                    }
                    return val
                })

                finalOrder = finalOrder.filter(each => each)

                finalOrder = [...emailKeysToAppend, ...finalOrder]
            }

            // For Phone
            if (uniquePhoneKeys.length && uniquePhoneKeys.length < list.length) {
                let indexToRemove = {}

                uniquePhoneKeys.forEach(each => {
                    const val = uniquePhoneNumber[each]
                    if (val.length > 1)
                        indexToRemove = {
                            ...val.reduce((o, val) => {
                                o[val] = val
                                return o
                            }, {}),
                            ...indexToRemove,
                        }
                })

                // push all the indexToRemove ids to top
                finalOrder = finalOrder.map((val, index) => {
                    if (indexToRemove.hasOwnProperty(index)) {
                        phoneKeysToAppend.push(val)
                        hasListOrderUpdated = true
                        return null
                    }
                    return val
                })

                finalOrder = finalOrder.filter(each => each)

                finalOrder = [...phoneKeysToAppend, ...finalOrder]
            }
        }

        if (emailKeysToAppend.length) emitCandidateError(emailKeysToAppend, 'email', 'Duplicate Email Id')
        if (phoneKeysToAppend.length) emitCandidateError(phoneKeysToAppend, 'phone', 'Duplicate Phone Number')

        if (hasListOrderUpdated) {
            this.setState({ hasSubmitClicked: true })
            replaceCandidateData({ list: finalOrder })
        } else {
            // API Call
            const value = list.map(uploadId => {
                const {
                    name,
                    email,
                    phone,
                    resumeId,
                    resumeLink,
                    jobId,
                    stageId,
                    skypeId,
                    currentLocation,
                    designation,
                    currentCtc,
                    expectedCtc,
                    noticePeriod,
                    linkedIn,
                    github,
                    otherDetails,
                    noteToTheHiringTeam,
                    currentCompanyName,
                    totalExperience,
                } = data[uploadId]
                // send only what is needed
                const finalCandidateData = { name, email, resumeId, candidateResumeLink: resumeLink }

                // optional
                let ctcInfo = {}
                // Through out the platform only name and email are compulsory
                // Only input phone if it has been manually verified by the client
                if (
                    jobDetails &&
                    jobDetails.jobApplication &&
                    [2, 3].includes(jobDetails.jobApplication.contactNo) &&
                    phone.number &&
                    phone.number.length
                )
                    finalCandidateData.phone = `${phone.code || '+91'}${phone.number}`

                if (jobId && jobId.length) finalCandidateData.jobId = jobId

                if (jobDetails && jobDetails.jobApplication && jobDetails.jobApplication.jobId)
                    finalCandidateData.jobId = jobDetails.jobApplication.jobId

                if (skypeId && skypeId.length) finalCandidateData.skypeId = skypeId

                if (currentLocation && currentLocation.length) finalCandidateData.currentLocation = currentLocation

                if (designation && designation.length) finalCandidateData.designation = designation

                if (currentCtc && Object.keys(currentCtc).length > 0) ctcInfo = { ...ctcInfo, ...currentCtc }

                if (expectedCtc && Object.keys(expectedCtc).length > 0) ctcInfo = { ...ctcInfo, ...expectedCtc }

                if (noticePeriod && noticePeriod.length > 0) finalCandidateData.noticePeriod = noticePeriod

                if (linkedIn && linkedIn.length > 0) finalCandidateData.linkedIn = linkedIn

                if (github && github.length > 0) finalCandidateData.github = github

                if (totalExperience && totalExperience.length > 0) finalCandidateData.totalExperience = totalExperience

                if (currentCompanyName && currentCompanyName.length > 0)
                    finalCandidateData.currentCompanyName = currentCompanyName

                if (otherDetails && otherDetails.length > 0) finalCandidateData.otherDetails = otherDetails

                if (noteToTheHiringTeam && noteToTheHiringTeam.length > 0)
                    finalCandidateData.noteToTheHiringTeam = noteToTheHiringTeam

                if (ctcInfo && Object.keys(ctcInfo).length > 0) finalCandidateData.ctcInfo = ctcInfo
                // @Override
                if (props_jobId) finalCandidateData.jobId = props_jobId

                if (stageId && stageId.length) finalCandidateData.stageId = stageId

                if (!stageId && finalCandidateData.jobId) {
                    const { stages = [] } = allJobs.find(job => job._id === finalCandidateData.jobId) || {}

                    finalCandidateData.stageId = stages[0]._id
                }

                return finalCandidateData
            })
            await this.setState({ apiInProgress: true })
            const result = await this.props.AddCandidateWithJob(value)
            if (result === false) {
                // some API error came up as result =>
                this.setState({ apiInProgress: false })
            } else if (result.status) {
                success('Candidate added successfully')

                if (shouldReSet) {
                    this.props.setStoreForMode(MODE.BULK)
                    this.resetStateData(MODE.BULK)
                } else this.props.onSubmit(result)
            } else this.resetStateData()
        }
    }

    render() {
        const { mode, confirmSwitch, hasSubmitClicked, successUploadsId, apiInProgress, confirmCancel } = this.state
        const { jobId, allJobs, isPreviewMode, errorCounts, listLength, onCancel, jobDetails } = this.props
        const successCounts = Object.keys(successUploadsId).length
        if (confirmCancel)
            return (
                <>
                    <Header title="ADD CANDIDATE" />

                    <ConfirmDeleteCard
                        initialHeight="368px"
                        confirmMessage="Are you sure you want to cancel uploading?"
                        onCancel={() => this.setState({ confirmCancel: false })}
                        onDelete={onCancel}
                        noBorder
                    />

                    <Footer disable confirmText="SUBMIT" hasSeparator onConfirm={() => { }} onCancel={() => { }} />
                </>
            )
        return (
            <>
                <Header title="ADD CANDIDATE" />
                <ComponentWrapper>
                    <div className="header">
                        <Tabs>
                            <div
                                className={mode === MODE.INLINE ? 'active' : undefined}
                                onClick={() => this.handleTab('INLINE')}
                            >
                                Add inline
                            </div>
                            <div
                                className={mode === MODE.BULK ? 'active' : undefined}
                                onClick={() => this.handleTab('BULK')}
                            >
                                Bulk upload
                            </div>
                        </Tabs>
                        {mode === MODE.BULK && isPreviewMode && (
                            <div
                                className={`save ${(successCounts !== listLength || apiInProgress) && 'disabled'}`}
                                onClick={() => {
                                    if (successCounts === listLength) this.onSubmit(true)
                                }}
                            >
                                Save and add more Candidate
                            </div>
                        )}
                    </div>
                    {confirmSwitch ? (
                        <ConfirmDeleteCard
                            initialHeight="258px"
                            confirmMessage={
                                <>
                                    Data added will get lost on this action.
                                    <br />
                                    Are you sure you want to proceed?
                                </>
                            }
                            onCancel={() => this.setState({ confirmSwitch: false })}
                            onDelete={() => {
                                this.props.setStoreForMode(confirmSwitch, jobId, allJobs)
                                this.setState({ mode: confirmSwitch, confirmSwitch: false })
                            }}
                        />
                    ) : mode === MODE.INLINE ? (
                        <InlineMode jobDetails={jobDetails} />
                    ) : mode === MODE.BULK && isPreviewMode ? (
                        <BulkModePreview updateSuccessCount={this.updateSuccessCount} jobDetails={jobDetails} />
                    ) : (
                        <BulkMode jobId={jobId} jobDetails={jobDetails} />
                    )}
                </ComponentWrapper>

                <FooterMessages hidden={mode === MODE.INLINE || !isPreviewMode}>
                    {hasSubmitClicked ? (
                        errorCounts ? (
                            <>
                                <ErrorIcon style={{ marginRight: 10 }} />
                                <span className="bold">{errorCounts}</span>&nbsp;Errors Left
                            </>
                        ) : null
                    ) : successCounts ? (
                        <>
                            <SuccessIcon style={{ marginRight: 10 }} />
                            <span className="bold">
                                {successCounts}/{listLength}
                            </span>
                            &nbsp;profiles added successfully
                        </>
                    ) : null}
                </FooterMessages>

                <Footer
                    disable={
                        (listLength !== 1 && isPreviewMode && successCounts !== listLength) ||
                        confirmSwitch ||
                        apiInProgress ||
                        listLength === 0
                    }
                    loadingButton={apiInProgress}
                    onCancel={this.confirmCancelPreCheck}
                    onConfirm={() => this.onSubmit(false)}
                    confirmText="SUBMIT"
                    hasSeparator
                />
            </>
        )
    }
}

const setStoreForMode = (mode, jobId, allJobs) => dispatch => {
    if (mode === MODE.INLINE) {
        // 1. clean the list
        // 2. add a dummy upload
        dispatch({ type: actionsCandidate.CLEAR_CANDIDATES })

        if (jobId && jobId.length) {
            const { stages = [] } = allJobs.find(job => job._id === jobId) || {}
            dispatch({
                type: actionsCandidate.ADD_CANDIDATES,
                payload: { jobId, hasJobId: true, stageId: (stages.length && stages[0]._id) || '' },
            })
        } else dispatch({ type: actionsCandidate.ADD_CANDIDATES })
    }
    if (mode === MODE.BULK) {
        dispatch({ type: actionsCandidate.CLEAR_CANDIDATES })
    }
}

const replaceCandidateData = data => dispatch => {
    dispatch({ type: actionsCandidate.REORDER_LIST, payload: data })
}

const emitCandidateError = (uploadIds, type, msg) => dispatch => {
    dispatch({ type: actionsCandidate.EMIT_ERROR, payload: { uploadIds, type, msg } })
}

const mapStateToProps = state => {
    const { list, data } = state.Candidate
    const errorCounts = list.filter(each => data[each] && data[each].hasErrors).length
    return {
        listLength: list.length,
        isPreviewMode: list.length > 0,
        allJobs: state.Job.allJobs,
        errorCounts,
    }
}

export default connect(
    mapStateToProps,
    { setStoreForMode, replaceCandidateData, emitCandidateError, AddCandidateWithJob }
)(AddCandidate)
