import React, { useState, useEffect } from 'react'
import * as S from './style'
import GoMobileImg from '../../../assets/Icons/Landing/free-ats-mobile-app.svg'
import { DemoButton, StartedButton } from './Components/StaticComponents'

const GoMobile = props => {
    const [variable, setVariable] = useState(true)
    return (
        <S.GoMobile className="go-mobile">
            <div>
                <div>
                    <S.H4 className="show-sm">Go Mobile</S.H4>
                    <img src={GoMobileImg} title="Free ATS Mobile App" alt="Free ATS Mobile App" />
                </div>
                <div>
                    <S.H4 className="hide-sm">Go Mobile</S.H4>
                    <ul>
                        <li>
                            Schedule interviews, send emails, take feedback and manage candidates and openings on-the-go
                            with the mobile app.
                        </li>
                        <li>
                            Use the WhatsApp bot to connect with our support team, check your next 5 tasks and quick-add
                            candidates by simply uploading PDF and Word files right inside WhatsApp.
                        </li>
                    </ul>
                    {/* <div className="buttons">
                        <DemoButton type="primary" />
                    </div> */}
                </div>
            </div>
        </S.GoMobile>
    )
}

export default GoMobile
