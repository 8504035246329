import React from 'react'
import { Success, Top } from './../style'
import { ReactComponent as MedalIcon } from '../../../../assets/Icons/Interview/medal.svg'
import { ReactComponent as LogoIcon } from '../../../../assets/Icons/Landing/logo.svg'
import { StyledButton } from '../../../Components'
import { SendClickAnalytics } from '../../../../services/NetworkCalls/SourcingCandidate'

export const SuccessComponent = ({ authProof }) => (
    <Success>
        <MedalIcon />
        <div className="title">Your form has been successfully submitted</div>
        <p>
            Congratulations on being a part of the Sourcing Initiative! Verified profiles improve your chances of
            getting hired. Do you want to create your first blockchain-verified profile on SpringRole?
        </p>
        <StyledButton
            width="140px"
            height="40px"
            onClick={() => {
                SendClickAnalytics(authProof)
                window.open('http://springrole.com', '_self')
            }}
        >
            Take Me There!
        </StyledButton>
    </Success>
)

export const TopComponent = (
    <Top>
        <div className="header">
            <LogoIcon />
        </div>
        <h1>Join Our Global Talent Network</h1>
        <p>
            Welcome to the SpringRecruit Sourcing Initiative where we help you connect with actively hiring
            companies.
            <br />
            Let's get started by filling this form
        </p>
    </Top>
)
