import React from 'react'

const SVGComponent = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g
                id="ATS-Board---Sort-By-Dropdown-(Hover-+-Selected-state"
                transform="translate(-153 -322)"
                stroke="#697998"
                strokeWidth="1.2"
            >
                <g id="ATS-Stages" transform="translate(40 200)">
                    <g id="Stage1">
                        <g id="Candidates" transform="translate(20 64)">
                            <g id="Candidate-Tile">
                                <g id="Action-Buttons" transform="translate(72 60)">
                                    <polygon
                                        id="Star"
                                        points="28.5 9.75 24.6793959 11.7586105 25.4090663 7.50430523 22.3181326 4.49138954 26.5896979 3.87069477 28.5 0 30.4103021 3.87069477 34.6818674 4.49138954 31.5909337 7.50430523 32.3206041 11.7586105"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
