export const defaultValues = [
    'name',
    'email',
    'phone',
    'designation',
    'currentLocation',
    'skypeId',
    'currentCtc',
    'expectedCtc',
    'noticePeriod',
    'resume',
    'noteToTheHiringTeam',
    'linkedIn',
    'github',
    'otherDetails',
]
