import styled from 'styled-components'
import { Button } from 'antd'
import { BLACK, DARK_BLUE } from '../../Helpers/Stylesheet/styledComponents'

const commonHeadingStyles = `
    font-size: 0.9em;
    font-weight: bold;
    color: #333333 !important;
    text-transform: uppercase;
`
export const NotificationContainer = styled.div`
    margin: 15px auto;
    max-width: 1200px;
`
export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px auto;
    max-width: 1200px;
    height: 100px;

    @media only screen and (max-width: 769px) {
        display: block;
        padding: 0 15px;
    }
`
export const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    max-width: 1200px;

    @media only screen and (max-width: 769px) {
        display: block;
        max-width: unset;
        width: 100%;
        padding: 0 15px;
    }
`

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`

export const ModalElementWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    text-align: left;
`

export const OpeningCardWrapper = styled.div`
    height: 300px;
    border: 1px solid #eff0f4;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 38px 12px 0 20px;
    margin: 30px 0;

    @media only screen and (max-width: 995px) {
        display: block;
        width: 100%;
        height: auto;
        padding: 15px;
        margin: 0 auto 20px 0;
    }
`

export const SidebarWrapper = styled.div`
    width: 320px;
    @media only screen and (max-width: 769px) {
        width: 100%;
        margin: auto;
    }
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    z-index: 2;
    width: 1200px;
    height: 90px;
    margin: auto;
    padding: 30px;
    @media only screen and (max-width: 995px) {
        width: 100%;
        padding: 15px;
        position: unset;
        display: block;
        height: auto;
    }
`
export const Form = styled.div`
    display: flex;
    @media only screen and (max-width: 995px) {
        display: block;
    }
`
export const Body = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #f7f8fc;
    padding: 0 0 40px 0;
    > div {
        width: 100%;
    }
`
export const Title = styled.span`
    font-family: sanFranciscoMedium;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    text-transform: uppercase;
`
export const AddButton = styled.button`
    padding: 0 10px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #4767a0;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #4767a0;
    background: #fff;
    cursor: pointer;
    @media only screen and (max-width: 995px) {
        width: 100%;
        margin-left: 0;
    }
    svg {
        vertical-align: middle;
        margin-right: 5px;
    }
`
export const SubTitle = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    ${commonHeadingStyles}
    a {
        color: #4767a0;
    }
`
export const Aside = styled.div`
    min-width: 300px;
    min-height: 725px;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 0 30px;
    margin-right: 40px;
    label {
        display: block;
        :last-child {
            margin-bottom: 0;
        }
    }
    .ant-input {
        border: 1px solid #d9d9d9 !important;
    }
    .ant-input,
    .ant-select {
        font-size: 0.9em;
        color: #999999;
    }
    label,
    .ant-select {
        margin-bottom: 1em;
        .ant-select-arrow-icon {
            font-size: 10px;
            svg use {
                fill: #999999;
            }
        }
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
    }
    .ant-collapse {
        margin-top: 30px;
        svg use {
            fill: #999999;
        }
        .ant-collapse-item {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: solid 1px #e1e4e8 !important;
            :last-child {
                border-bottom: 0 !important;
            }
        }
        > .ant-collapse-item > .ant-collapse-header {
            margin-bottom: 0;
            line-height: 38px;
            ${commonHeadingStyles}
            .ant-collapse-arrow {
                right: 0;
                font-size: 10px;
            }
        }
        .ant-collapse-content {
            margin-bottom: 20px;
            > .ant-collapse-content-box {
                padding: 0;
            }
        }
    }
`
export const Block = styled.div`
    padding: 30px 0;
    border-bottom: solid 1px #e1e4e8;
`
export const Heading = styled.span`
    margin-bottom: ${props => (props.noMargin ? '0' : '10px')};
    display: ${props => (props.noMargin ? '' : 'block')};
    ${commonHeadingStyles}
`

export const RefreshFooter = styled.div`
    height: 30px;
    background-color: #ffffff;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Card = styled.div`
    margin: 0 2.5px;
    border-radius: ${props => (props.bradius ? '5px 5px 0 0' : '5px')};
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 30px;
    @media only screen and (max-width: 995px) {
        min-width: unset;
        width: 100%;
        padding: 15px;
        margin: 0;
    }
    .tp-card-edit {
        display: none;
    }

    &:hover {
        .tp-card-edit {
            display: block;
            margin-left: 10px;
        }
    }
`
export const Top = styled.div`
    display: flex;
    border-bottom: solid 1px #e1e4e8;
    padding-bottom: 10px;
    @media only screen and (max-width: 995px) {
        display: block;
    }
`
export const Info = styled.div`
    margin-left: 20px;
    flex: 1;
    @media only screen and (max-width: 995px) {
        margin-left: 0;
        text-align: center;
    }
`
export const Bottom = styled.div`
    width: 100%;
    margin-top: 15px;

    td {
        font-size: 0.9em;
        font-weight: 500;
        color: #999;
        padding-top: 5px;
        padding-bottom: 5px;
        &.value {
            padding-right: 25px;
            color: #333;
        }
    }
`
export const Avatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background-color: #4767a0;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    @media only screen and (max-width: 995px) {
        margin: 10px auto 10px;
    }
`
export const Name = styled.div`
    ${commonHeadingStyles}
    font-size: 18px;
    text-transform: unset;
    color: #000000;
`
export const Icons = styled.div`
    svg {
        margin-left: 15px;
        cursor: pointer;
    }
`
export const Label = styled.div`
    font-size: 0.9em;
    font-weight: 500;
    color: #999;
    margin: 5px 0;
    min-width: 180px;
    @media only screen and (max-width: 995px) {
        text-align: center;
        min-width: unset;
        justify-content: center;
    }
    display: ${props => props.flex && 'flex'};
    a {
        color: #4767a0;
    }
    span {
        color: #333;
        & + svg {
            margin-left: 20px;
        }
    }
    svg {
        margin-right: 5px;
        vertical-align: middle;
    }
    .tp-card-edit svg {
        cursor: pointer;
    }
`
export const ActionsCard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 0 20px;
    margin-bottom: 20px;
    > span {
        font-size: 14px;
        font-weight: 600;
        color: #666666;
    }
`
export const CTA = styled(Button)`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    svg {
        font-size: 10px;
        use {
            fill: #fff;
        }
    }
`
export const TagBar = styled.div`
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 10px 30px;
    border-radius: 0 0 5px 5px;
    background-color: #f1f2f4;
    box-shadow: ${props => (props.sourcing ? '' : '0 0 3px 0 rgba(0, 0, 0, 0.15)')};
    font-size: 12px;
    margin: 0 2.5px;
    margin: ${props => (props.sourcing ? '30px -30px -30px' : '')};
    > div {
        display: flex;
        flex-wrap: wrap;
    }
    .button {
        border-radius: 15px;
        margin-right: 15px;
        flex-shrink: 0;
        svg {
            margin-right: 5px;
        }
    }
    span {
        color: ${BLACK};
        margin: 5px 7.5px;
    }
    a {
        color: ${DARK_BLUE};
        margin: 5px 7.5px;
    }
`
