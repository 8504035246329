import React from 'react'
import moment from 'moment'
import { Tooltip } from 'antd'

const stringSortFunction = name => (a, b) => {
    const nameA = a[name] && a[name].toLowerCase(),
        nameB = b[name] && b[name].toLowerCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
}

const dateSortFunction = name => (a, b) => {
    let [nameA, nameB] = [a[name], b[name]]
    if (nameA === '--') nameA = '1st Jan 2000'
    if (nameB === '--') nameB = '1st Jan 2000'
    const [dateA, dateB] = [moment(nameA.replace(/st|nd|rd|th/g, '')), moment(nameB.replace(/st|nd|rd|th/g, ''))]
    if (dateA.isBefore(dateB)) return -1
    else if (dateA.isAfter(dateB)) return 1
    return 0
}

const timeSortFunction = name => (a, b) => {
    const [timeA, timeB] = [parseInt(a[name].split(' ')[0]), parseInt(b[name].split(' ')[0])]
    return timeA - timeB
}

const column = (title, dataIndex, width, type, tooltipText) => {
    const col = {
        title: <Tooltip title={tooltipText}>{title}</Tooltip>,
        dataIndex,
    }
    if (width) col.width = width
    if (type === 'string') col.sorter = stringSortFunction(dataIndex)
    if (type === 'date') col.sorter = dateSortFunction(dataIndex)
    if (type === 'time') col.sorter = timeSortFunction(dataIndex)
    if (type === 'number') {
        col.sorter = (a, b) => a[dataIndex] - b[dataIndex]
        col.render = text => <span style={{ float: 'right' }}>{text}</span>
    }
    return col
}

const PROGRESS_COLUMNS = [
    column('TOTAL CANDIDATES', 'totalCandidates', 150, 'number', 'Total candidate in selected date range'),
    column('CANDIDATES ADDED', 'candidatesAdded', 150, 'number', 'Candidate added in the selected date range'),
    column('PROCEEDED', 'proceeded', 135, 'number', 'Candidate moved from one stage to another in selected date range'),
    column('ARCHIVED', 'archived', 123, 'number', 'Candidate archived in the selected date range'),
    column('REJECTED', 'rejected', 123, 'number', 'Candidate rejected in the selected date range'),
    column('OFFERED', 'offered', 118, 'number', 'Candidate offered job in the selected date range'),
    column('JOINED', 'joined', null, 'number', 'Candidate marked joined in the selected date range'),
]

export const PROGRESS_JOBS_COLUMNS = [
    column('JOB NAME', 'jobName', 230, 'string', 'Name of the job'),
    ...PROGRESS_COLUMNS,
]

export const PROGRESS_SOURCE_COLUMNS = [
    column('SOURCE NAME', 'sourceName', 230, 'string', 'Name of the source'),
    ...PROGRESS_COLUMNS,
]

export const PROGRESS_MONTH_COLUMNS = [
    column('MONTH CREATED', 'monthCreated', 230, 'date', 'Month in which the job was created'),
    ...PROGRESS_COLUMNS,
]

export const PROGRESS_TAG_COLUMNS = [
    column('TAG NAME', 'tagName', 230, 'string', 'Name of the tag'),
    ...PROGRESS_COLUMNS,
]

const TSA_COLUMNS = [
    column('CANDIDATES ADDED', 'candidatesAdded', 150, 'number', 'Candidate added in the selected date range'),
    column('ARCHIVED', 'archived', 123, 'number', 'Candidate archived in the selected date range'),
    column('REJECTED', 'rejected', 123, 'number', 'Candidate rejected in the selected date range'),
    column('JOINED', 'joined', 103, 'number', 'Candidate marked joined in the selected date range'),
    column(
        'AVG TIME TO HIRE',
        'avgTimeToHire',
        150,
        'time',
        'Average time taken to hire a candidate in the selected date range'
    ),
    column(
        'AVG TIME TO ARCHIVE',
        'avgTimeToArchive',
        150,
        'time',
        'Average time taken to archive a candidate in the selected date range'
    ),
    column(
        'AVG TIME TO REJECT',
        'avgTimeToReject',
        null,
        'time',
        'Average time taken to reject a candidate in the selected date range'
    ),
]

export const TSA_JOBS_COLUMNS = [column('JOB NAME', 'jobName', 230, 'string', 'Name of the job'), ...TSA_COLUMNS]

export const TSA_SOURCE_COLUMNS = [
    column('SOURCE NAME', 'sourceName', 230, 'string', 'Name of the source'),
    ...TSA_COLUMNS,
]

export const TSA_MONTH_COLUMNS = [
    column('MONTH CREATED', 'monthCreated', 230, 'date', 'Month in which the job was created'),
    ...TSA_COLUMNS,
]

export const TSA_TAG_COLUMNS = [column('TAG NAME', 'tagName', 230, 'string', 'Name of the tag'), ...TSA_COLUMNS]

const SOURCING_COLUMNS = [
    column('CANDIDATES ADDED', 'candidatesAdded', 195, 'number', 'Candidate added in the selected date range'),
    column('CAREER PAGE', 'careerPage', 148, 'number', 'Candidates added through career page in selected date range '),
    column('ATS', 'ats', 80, 'number', 'Candidates added through ATS in the selected date range '),
    column(
        'SOURCED',
        'sourced',
        120,
        'number',
        'Candidates added through sourcing talent pool in the selected date range'
    ),
    column('EMAIL', 'email', null, 'number', 'Candidates added through email in the selected date range '),
]

export const SOURCING_JOB_COLUMNS = [column('JOB NAME', 'name', 280, 'string', 'Name of the job'), ...SOURCING_COLUMNS]

export const SOURCING_MONTH_COLUMNS = [
    column('MONTH CREATED', 'name', 280, 'date', 'Month in which the job was created'),
    ...SOURCING_COLUMNS,
]

export const SOURCING_TAG_COLUMNS = [column('TAG NAME', 'name', 280, 'string', 'Name of the tag'), ...SOURCING_COLUMNS]

export const JOBS_TIMELINE_COLUMNS = [
    column('JOB NAME', 'jobName', 280, 'string', 'Name of the job'),
    column('CREATION DATE', 'createdDate', 180, 'date', 'Date on which job was created'),
    column('ARCHIVAL DATE', 'archivalDate', 180, 'date', 'Date on which job was archived'),
    column('JOB LIFETIME', 'jobLifetime', 180, 'time', 'Time interval between job creation and job archival date'),
    column('JOINED', 'joined', null, 'number', 'Candidate marked joined in the selected date range'),
]

export const JOBS_STAGE_COLUMNS = [
    column('STAGE NAME', 'stageName', 250, 'string', 'Name of the stage'),
    column('CANDIDATES', 'candidates', 140, 'number', 'Total candidate in the stage'),
    column('MOVED', 'moved', 100, 'number', 'Candidate moved from one stage to another'),
    column('ARCHIVED', 'archived', 125, 'number', 'Candidate archived in the stage'),
    column('REJECTED', 'rejected', 125, 'number', 'Candidate rejected in the stage'),
    column('AVG TIME SPENT IN STAGE', 'avg', null, 'time', 'Average time spent by candidate in the stage'),
]

export const TEAM_MEMBER_COLUMNS = [
    column('TEAM MEMBER', 'teamMember', 210, 'string', 'Name of the team member'),
    column('TOTAL CANDIDATES', 'totalCandidates', 150, 'number', 'Total candidate in selected date range'),
    column('CANDIDATES ADDED', 'candidatesAdded', 150, 'number', 'Candidate added in the selected date range'),
    column(
        'INTERVIEWS SCHEDULED',
        'interviewsScheduled',
        150,
        'number',
        'Total interview scheduled in the selected date range'
    ),
    column(
        'PROCEEDED',
        'proceeded',
        135,
        'number',
        'Candidate moved from one stage to another in the selected date range'
    ),
    column('ARCHIVED', 'archived', 125, 'number', 'Candidate archived in the selected date range'),
    column('REJECTED', 'rejected', 125, 'number', 'Candidate rejected in the selected date range'),
    column('JOINED', 'joined', null, 'number', 'Candidate marked joined in the selected date range'),
]

export const EMPTY_JOBS_STAGE_CARD_DATA = {
    totalCandidate: 0,
    totalArchived: 0,
    totalRejected: 0,
    totalJoined: 0,
    avgtimeToHire: 0,
}

export const getJobStatusArray = jobTypes => {
    const jobStatusArray = []
    jobTypes.forEach(each => {
        if (each === 'Active') {
            jobStatusArray.push(1)
            jobStatusArray.push(2)
        } else jobStatusArray.push(0)
    })
    return jobStatusArray
}

export const getJobTypesPayload = (jobTypes, jobsObject, companyId, startDate, endDate) => {
    const jobStatusArray = getJobStatusArray(jobTypes)
    let filteredJobNames = [],
        jobId = []
    if (jobTypes.length) {
        for (let jobName in jobsObject) {
            if (jobStatusArray.includes(jobsObject[jobName].status)) {
                filteredJobNames.push(jobName)
                jobId.push(jobsObject[jobName]._id)
            }
        }
    } else {
        filteredJobNames = Object.keys(jobsObject)
    }
    const payload = {
        companyId,
        jobId: (jobId.length && jobId) || null,
        startDate: startDate && startDate.format(),
        endDate: endDate && endDate.format(),
    }
    return { payload, jobId: (jobId.length && jobId) || null, filteredJobNames }
}

export const getJobNamesPayload = (jobTypes, jobNames, jobsObject, companyId, startDate, endDate) => {
    const jobStatusArray = getJobStatusArray(jobTypes)
    let jobId = []
    if (jobNames.length) {
        jobId = jobNames.map(jobName => jobsObject[jobName]._id)
    } else if (jobTypes.length) {
        for (let jobName in jobsObject) {
            if (jobStatusArray.includes(jobsObject[jobName].status)) jobId.push(jobsObject[jobName]._id)
        }
    }
    const payload = {
        companyId,
        jobId: (jobId.length && jobId) || null,
        startDate: startDate && startDate.format(),
        endDate: endDate && endDate.format(),
    }
    return { payload, jobId: (jobId.length && jobId) || null }
}

export const getTagsPayload = (jobTypes, jobNames, jobsObject, tagNames, tagsArray, companyId, startDate, endDate) => {
    let jobId = [],
        tags = []
    if (jobNames.length) jobId = jobNames.map(jobName => jobsObject[jobName]._id)
    else if (jobTypes.length) {
        const jobStatusArray = getJobStatusArray(jobTypes)
        for (let jobName in jobsObject) {
            if (jobStatusArray.includes(jobsObject[jobName].status)) jobId.push(jobsObject[jobName]._id)
        }
    }
    if (tagNames.length)
        tagsArray.forEach(tag => {
            if (tagNames.includes(tag.name)) tags.push(tag._id)
        })
    return {
        companyId,
        jobId: (jobId.length && jobId) || null,
        tags: (tags.length && tags) || null,
        startDate: startDate && startDate.format(),
        endDate: endDate && endDate.format(),
    }
}

export const getFilteredPayload = (
    type,
    data,
    companyId,
    selectedJobTypes,
    selectedJobNames,
    jobsObject,
    selectedTags,
    tagsArray,
    startDate,
    endDate,
    getCandidateTags,
    setSelectedJobTypes,
    setJobNamesArray,
    setSelectedJobNames,
    setSelectedTags,
    setStartDate,
    setEndDate,
    history
) => {
    let input = {}
    const params = new URLSearchParams(history.location.search)
    if (type === 'jobType') {
        const { payload, jobId, filteredJobNames } = getJobTypesPayload(data, jobsObject, companyId, startDate, endDate)
        input = payload
        getCandidateTags({ jobId })
        setSelectedJobTypes(data)
        setJobNamesArray(filteredJobNames)
        setSelectedJobNames([])
        setSelectedTags([])
        params.delete('jobTypes')
        params.delete('jobNames')
        params.delete('tagNames')
        if (data.length) params.append('jobTypes', data.toString())
        history.push({ search: params.toString() })
    } else if (type === 'jobName') {
        const { payload, jobId } = getJobNamesPayload(selectedJobTypes, data, jobsObject, companyId, startDate, endDate)
        input = payload
        getCandidateTags({ jobId })
        setSelectedJobNames(data)
        setSelectedTags([])
        params.delete('jobNames')
        params.delete('tagNames')
        if (data.length) params.append('jobNames', data.toString())
        history.push({ search: params.toString() })
    } else if (type === 'tag') {
        input = getTagsPayload(
            selectedJobTypes,
            selectedJobNames,
            jobsObject,
            data,
            tagsArray,
            companyId,
            startDate,
            endDate
        )
        setSelectedTags(data)
        params.delete('tagNames')
        if (data.length) params.append('tagNames', data.toString())
        history.push({ search: params.toString() })
    } else {
        const [startDate, endDate] = data
        input = getTagsPayload(
            selectedJobTypes,
            selectedJobNames,
            jobsObject,
            selectedTags,
            tagsArray,
            companyId,
            startDate,
            endDate
        )
        setStartDate(startDate)
        setEndDate(endDate)
        params.delete('startDate')
        params.delete('endDate')
        if (startDate) params.append('startDate', startDate.format().toString())
        if (endDate) params.append('endDate', endDate.format())
        history.push({ search: params.toString() })
    }
    return input
}

export const getCandidatesInitialPayload = (
    companyId,
    jobTypes,
    jobNames,
    jobsObject,
    tagNames,
    tagsArray,
    startDate,
    endDate
) => {
    let payload = { companyId },
        filteredJobNames = []
    const jobStatusArray = getJobStatusArray(jobTypes)

    if (jobTypes.length) {
        for (let jobName in jobsObject) {
            if (jobStatusArray.includes(jobsObject[jobName].status)) filteredJobNames.push(jobName)
        }
    } else filteredJobNames = Object.keys(jobsObject)

    if (jobTypes.length || jobNames.length || tagNames.length || startDate || endDate) {
        let jobId = [],
            tags = []
        if (jobNames.length) jobId = jobNames.map(jobName => jobsObject[jobName]._id)
        else if (jobTypes.length) {
            for (let jobName in jobsObject) {
                if (jobStatusArray.includes(jobsObject[jobName].status)) jobId.push(jobsObject[jobName]._id)
            }
        }
        if (tagNames.length)
            tagsArray.forEach(tag => {
                if (tagNames.includes(tag.name)) tags.push(tag._id)
            })
        payload = {
            ...payload,
            jobId: (jobId.length && jobId) || null,
            tags: (tags.length && tags) || null,
            startDate,
            endDate,
        }
    }
    return { payload, filteredJobNames }
}

export const getRefreshPayload = (
    selectedJobTypes,
    selectedJobNames,
    selectedTags,
    jobsObject,
    tagsArray,
    companyId,
    startDate,
    endDate
) => {
    const jobStatusArray = getJobStatusArray(selectedJobTypes)

    let jobId = [],
        tags = []
    if (selectedJobNames.length) jobId = selectedJobNames.map(jobName => jobsObject[jobName]._id)
    else if (jobStatusArray.length) {
        for (let job in jobsObject) if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
    } else jobId = null

    if (selectedTags.length) {
        tagsArray.forEach(tag => {
            if (selectedTags.includes(tag.name)) tags.push(tag._id)
        })
    } else tags = null

    return {
        companyId,
        jobId,
        tags,
        startDate: startDate && startDate.format(),
        endDate: endDate && endDate.format(),
    }
}
