import styled from 'styled-components'

export const HeaderContainer = styled.div`
    width: 587px;
    margin: 0 auto 30px;
`

export const DashboardContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const DashboardTitleContainer = styled.div`
    display: ${props => (props.visible ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-top: 4px solid ${props => (props.selected ? '#4767a0' : 'transparent')};
    width: ${props => (props.boxGrow ? '32%' : '24%')};
    border-radius: 4px;
    cursor: ${props => (!props.selected ? 'pointer' : 'auto')};
    height: 150px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    transition: border 0.4s ease-in;
    img {
        position: absolute;
        top: 10px;
        right: 50px;
    }
    ${props => !props.selected}:after, ${props => !props.selected}:before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        bottom: -10px;
        content: '';
        left: 50%;
        margin-left: -10px;
        position: absolute;
    }
    ${props => !props.selected}:before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid;
        border-top-color: rgba(0, 0, 0, 0.45);
        bottom: -10px;
    }
`

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px auto;
    width: 100%;
    @media only screen and (max-width: 769px) {
        display: block;
    }
`

export const CardWrapper = styled.div`
    width: 31%;
    height: 360px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    @media only screen and (max-width: 769px) {
        width: 100%;
        height: unset;
        padding: 20px 15px 30px 15px;
        margin-bottom: 14px;
    }
`

export const FullCardWrapper = styled.div`
    width: 100%;
    height: 200px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 40px 107px 41px 40px;
    @media only screen and (max-width: 995px) {
        height: auto;
        padding: 15px 15px 50px 15px;
        position: relative;
    }
`

export const CompanyEmailWrapper = styled.div`
    display: flex;
    margin: 20px auto;
`

//Candidate Application Component
export const CAContainer = styled.div`
    width: 100%;
    max-height: 278.5px;
    margin-top: 30px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
`

export const NoJob = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
    margin-top: 30px;
    padding: 40px 0;
`

export const NoJobTitle = styled.div`
    width: 50%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    font-family: 'sanFranciscoMedium';
    color: #666666;
    margin-bottom: 20px;
`

export const NoCandidate = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
    text-align: center;
    padding: 30px 0;
    @media only screen and (max-width: 995px) {
        display: block;
        padding: 15px;
        margin-top: 0;
    }
`

export const NoCandidateTitle = styled.div`
    height: 22px;
    width: 807px;
    color: #53627c;
    font-size: 16px;
    font-weight: 500;
    @media only screen and (max-width: 995px) {
        width: 100%;
        height: unset;
        margin-bottom: 4px;
        font-size: 14px;
    }
`

export const NCSub = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    text-align: center;
    @media only screen and (max-width: 995px) {
        display: block;
        font-size: 14px;
    }
`

export const NoCandidateClickHere = styled.a`
    height: 22px;
    color: #4767a0;
    font-size: 16px;
    font-weight: 500;
    margin-right: 5px;
    @media only screen and (max-width: 995px) {
        height: unset;
        margin: 10px 0;
        display: block;
        font-size: 14px;
    }
`

export const NoCandidateSubTitle = styled.div`
    height: 22px;
    color: #666666;
    font-size: 16px;
    font-weight: 500;
    @media only screen and (max-width: 995px) {
        height: unset;
        font-size: 14px;
    }
`

export const ACListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
`

export const ACListCell = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 25px;
    padding: 25px 0;
    height: 93px;
    border-bottom: 1px solid #e1e4e8;

    :last-child {
        border: none;
    }
`

export const PositionDetail = styled.div`
    padding-right: 10px;
    width: 80%;
`

export const Candidate = styled.span`
    color: #4767a0;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
`

export const StaticString = styled.span`
    color: #333333;
    font-size: 16px;
`

export const Position = styled.span`
    color: #53627c;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
`

export const AppliedTime = styled.span`
    height: 16px;
    color: #697998;
    font-size: 12px;
    font-weight: 500;
    margin-right: 25px;
`

export const AppliedDate = styled.span`
    height: 16px;
    color: #697998;
    font-size: 12px;
    font-weight: 500;
`

// Schedule Interview
export const SIContainer = styled.div`
    width: 100%;
`

export const NoInterview = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
    margin-top: 30px;
    padding: 40px 0;
`

export const NoInterviewTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    font-family: 'sanFranciscoMedium';
    color: #666666;
    margin-bottom: 20px;
`

export const SIListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
    margin-top: 30px;
    padding: 0 25px;
    @media only screen and (max-width: 769px) {
        justify-content: left;
    }
`

export const InterviewItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e1e4e8;
    padding: 25px 0;
    @media only screen and (max-width: 769px) {
        display: block;
        padding: 15px 0;
    }
    &:last-child {
        border: none;
    }
`

export const CandidateName = styled.div`
    width: 18%;
    margin: 0 1% 0 1%;
    font-size: 16px;
    font-weight: bold;
    color: #4767a0;
    text-transform: capitalize;
    word-wrap: break-word;
    cursor: pointer;
    @media only screen and (max-width: 769px) {
        width: 100%;
        margin: 0;
    }
`

export const Role = styled.div`
    width: 33%;
    margin: 0 1% 0 1%;
    font-size: 16px;
    color: #333333;
    text-align: center;
    word-wrap: break-word;
    @media only screen and (max-width: 769px) {
        width: 100%;
        margin: 0;
        text-align: left;
        font-size: 12px;
        margin-bottom: 15px;
    }
`

export const Type = styled.div`
    width: 13%;
    margin: 0 1% 0 1%;
    font-size: 16px;
    color: #4767a0;
    text-align: right;
    word-wrap: break-word;
    @media only screen and (max-width: 769px) {
        width: 100%;
        margin: 0;
        text-align: left;
        margin-bottom: 6px;
    }
`

export const Time = styled.div`
    width: 10%;
    font-size: 16px;
    color: #333333;
    text-align: right;
    @media only screen and (max-width: 995px) {
        width: 100%;
        text-align: left;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 4px;
    }
`

export const Date = styled.div`
    width: 10%;
    font-size: 16px;
    color: #333333;
    text-align: right;
    @media only screen and (max-width: 995px) {
        width: 100%;
        text-align: left;
        font-size: 12px;
        margin-bottom: 6px;
    }
`

export const Detail = styled.div`
    width: 10%;
    font-size: 16px;
    color: #333333;
    text-align: right;
    @media only screen and (max-width: 995px) {
        width: 100%;
        text-align: left;
        font-size: 12px;
        margin-top: 6px;
    }
`

//Team Activity
export const TAContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 3px 0px;
    margin-top: 30px;
`

export const NoActivity = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 40px 40px 31px 40px;
`

export const NoTeamMember = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 40px 40px 31px 40px;
`

export const NoTAText = styled.div`
    padding: 20px 0;
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    font-family: sanFranciscoMedium;
`

export const ActivityCell = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 24px 0 10px 0;
    align-items: center;
`

export const Activity = styled.div`
    display: flex;
    flex: 0.5;
    flex-direction: row;
`

export const ActivityData = styled.div`
    height: 16px;
    color: #53627c;
    font-size: 12px;
    font-family: 'sanFranciscoMedium';
    line-height: 16px;
    margin: 0 4px 0 4px;
`

export const TimeAndRole = styled.div`
    display: flex;
    flex: 0.5;
    flex-direction: row;
    justify-content: space-between;
`

export const RoleActivity = styled.div`
    display: flex;
    flex-direction: row;
`

export const ActivityStatic = styled.div`
    height: 16px;
    color: #53627c;
    font-size: 12px;
    line-height: 16px;
`

export const ActivityTime = styled.div`
    height: 16px;
    color: #697998;
    font-size: 12px;
    font-family: sanFranciscoMedium;
    line-height: 16px;
`

export const AssignmentWrapper = styled.div`
    height: auto;
    width: 100%;
    background-color: #fff;
    color: #53627c;
    font-size: 16px;
    padding: 20px 0;
    border-bottom: 1px solid rgb(225, 228, 232);
    display: flex;
    @media only screen and (max-width: 769px) {
        padding: 10px 0;
        display: block;
    }

    :last-child {
        border: none;
    }
`

export const InterviewContainer = styled.div`
    width: auto;
    margin: 0 auto;
    padding: 0 25px;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    overflow-y: auto;
    @media only screen and (max-width: 995px) {
        padding: 0 15px;
    }
`

export const InterviewWrapper = styled.div`
    width: 82vw;
    min-width: 1136px;
    background-color: #ffffff;
    color: #53627c;
    font-size: 16px;
    display: flex;
    border-bottom: 1px solid rgb(225, 228, 232);
    justify-content: space-between;
    :last-child {
        border: none;
    }
`

export const NoDataWrapper = styled.div`
    height: 92px;
    width: 100%;
    background-color: black;
    min-width: 1137px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 0 60px 0 40px;
    line-height: 92px;
    color: #a6aebc;
    font-size: 16px;
    display: flex;
    justify-content: space-between;

    .anticon {
        height: 55px;
        margin: auto 0;
    }
`

export const Total = styled.span`
    display: block;
    font-size: 36px;
    font-weight: bold;
    line-height: 20px;
    color: #333333;
    font-family: 'sanFranciscoMedium';
    margin-bottom: 10px;

    .ant-skeleton-header {
        padding: 0;
    }

    > svg {
        position: absolute;
        right: 10px;
        top: 10px;
        &:hover path {
            fill: #666;
            cursor: pointer;
            opacity: 1;
        }
    }
`

export const ViewAllButton = styled.span`
    width: 180px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #4767a0;
    background-color: #fff;
    font-family: 'sanFranciscoMedium';
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #4767a0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    cursor: pointer;
`
