import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Spin } from 'antd'
import { Bar } from 'react-chartjs-2'
import { Graph, Heading, GraphCard } from '../../style'
import { ReactComponent as RefreshIcon } from '../../../../../assets/Icons/Analytics/refresh.svg'
import { CandidatesOverviewLoader } from '../../Components/Loaders'
import StatCard from '../../Components/Card'
import { getPercentage } from '../../../../Helpers/utils'
import { GetAnalyticsData } from '../../../../../services/NetworkCalls/Analytics'
import { warning } from '../../../../../services/Notification'
import apiCaller from '../../../../../services/apiCaller'
import {
    CHRISTMAS_PINK,
    CORNFLOWER_BLUE,
    FRIENDLY_YELLOW,
    NON_PHOTO_BLUE,
} from '../../../../Helpers/Stylesheet/styledComponents'
import { MONTHS_ARRAY } from '../../../../Helpers/constants'

const { formatError } = apiCaller

const Overview = ({ companyId, GetAnalyticsData }) => {
    const [cardLoading, setCardLoading] = useState(false)
    const [chartLoading, setChartLoading] = useState(false)
    const [cardData, setCardData] = useState({})
    const [chartData, setChartData] = useState({})
    const [loadTime, setLoadTime] = useState(moment().format('DD MMM, YYYY LT'))

    useEffect(() => {
        getCandidatesOverviewData()
        getCandidatesChartData()
    }, [])

    const getCandidatesOverviewData = async () => {
        try {
            setCardLoading(true)
            const res = await GetAnalyticsData('GetCandidatesOverviewData', null, companyId)
            setCardData(res)
            setCardLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getCandidatesChartData = async () => {
        try {
            setChartLoading(true)
            const { result } = await GetAnalyticsData('GetCandidatesChartData', null, companyId)
            const dataPoints = {
                ATS: {
                    data: [],
                    color: CORNFLOWER_BLUE,
                },
                'Careers Page': {
                    data: [],
                    color: NON_PHOTO_BLUE,
                },
                Email: {
                    data: [],
                    color: CHRISTMAS_PINK,
                },
                Sourced: {
                    data: [],
                    color: FRIENDLY_YELLOW,
                },
            }
            const labels = []
            for (let res in result) {
                const day = res.split('-')[0],
                    month = MONTHS_ARRAY[res.split('-')[1] - 1]
                labels.push(`${month} ${day}`)
                dataPoints.ATS.data.push(result[res].ats || 0)
                dataPoints['Careers Page'].data.push(result[res].careerPage || 0)
                dataPoints.Email.data.push(result[res].email || 0)
                dataPoints.Sourced.data.push(result[res].sourcing || 0)
            }
            const datasets = []
            for (let source in dataPoints) {
                datasets.push({
                    label: source,
                    data: dataPoints[source].data,
                    borderColor: dataPoints[source].color,
                    backgroundColor: dataPoints[source].color,
                    pointBorderColor: dataPoints[source].color,
                    pointBackgroundColor: dataPoints[source].color,
                    pointHoverBackgroundColor: dataPoints[source].color,
                    pointHoverBorderColor: dataPoints[source].color,
                })
            }
            setChartData({
                labels,
                datasets,
            })
            setChartLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const handleRefresh = () => {
        getCandidatesOverviewData()
        getCandidatesChartData()
        setLoadTime(moment().format('DD MMM, YYYY LT'))
    }

    return (
        <>
            <div className="sub-header">
                <div>
                    <Heading>Overview</Heading>
                </div>
                <div>
                    <RefreshIcon onClick={() => handleRefresh()} />
                    <span>Last Updated: {loadTime}</span>
                </div>
            </div>
            {cardLoading ? (
                <CandidatesOverviewLoader />
            ) : cardData ? (
                <div className="cards">
                    <StatCard
                        total={cardData.totalCandidates}
                        title="Total Candidates"
                        tooltipText="Total number of candidates"
                    />
                    <StatCard
                        total={cardData.archived}
                        title="Archived"
                        desc={`${getPercentage(cardData.archived, cardData.totalCandidates)}% of Total Candidates`}
                        tooltipText="Total number of archived candidates"
                    />
                    <StatCard
                        total={cardData.rejected}
                        title="Rejected"
                        desc={`${getPercentage(cardData.rejected, cardData.totalCandidates)}% of Total Candidates`}
                        tooltipText="Total number of rejected candidates"
                    />
                    <StatCard
                        total={cardData.makeOffer}
                        title="Offered Job"
                        desc={`${getPercentage(cardData.makeOffer, cardData.totalCandidates)}% of Total Candidates`}
                        tooltipText="Total number of candidate offered job"
                    />
                    <StatCard
                        total={cardData.joined}
                        title="Joined"
                        desc={`${getPercentage(cardData.joined, cardData.totalCandidates)}% of Total Candidates`}
                        tooltipText="Total number of candidate marked joined"
                    />
                    <StatCard
                        total={`${getPercentage(cardData.convertionCount, cardData.makeOffer)}%`}
                        title="Conversion Ratio"
                        tooltipText="No of candidates were made offer and are now joined Vs No of candidate made offer"
                    />
                    <StatCard
                        total={cardData.avgActiveTime}
                        title="Avg Time to Hire"
                        day={true}
                        tooltipText="Average time taken to hire a candidate"
                    />
                    <StatCard
                        total={cardData.avgArchivedTime}
                        title="Avg Time to Archive"
                        day={true}
                        tooltipText="Average time taken to archive a candidate"
                    />
                    <StatCard
                        total={cardData.avgRejectedTime}
                        title="Avg Time to Reject"
                        day={true}
                        tooltipText="Average time taken to reject a candidate"
                    />
                </div>
            ) : (
                null
            )}
            <Heading mb>Last 30 days</Heading>
            <Spin spinning={chartLoading}>
                <GraphCard>CANDIDATES ADDED VIA DIFFERENT SOURCES</GraphCard>
                <Graph>
                    <Bar
                        data={chartData}
                        options={{
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [
                                    {
                                        stacked: true,
                                        gridLines: {
                                            drawOnChartArea: false,
                                        },
                                    },
                                ],
                                yAxes: [
                                    {
                                        stacked: true,
                                        gridLines: {
                                            drawOnChartArea: false,
                                        },
                                        scaleLabel: {
                                            display: true,
                                            labelString: 'No. of Candidates',
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                            legend: {
                                display: true,
                                position: 'top',
                                align: 'end',
                                labels: {
                                    boxWidth: 7,
                                    usePointStyle: true,
                                    padding: 20,
                                },
                            },
                            plugins: {
                                labels: {
                                    render: () => '',
                                },
                            },
                        }}
                    />
                </Graph>
            </Spin>
        </>
    )
}

const mapStateToProps = ({ Auth }) => {
    return {
        companyId: Auth.user.companyId,
    }
}

export default connect(
    mapStateToProps,
    {
        GetAnalyticsData,
    }
)(Overview)
