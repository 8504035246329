import apiCaller from '../../apiCaller'
import { warning } from '../../../services/Notification'
import ajaxCaller from '../../apiCaller'
import config from '../../../config'
const { open } = apiCaller.type
const axios = ajaxCaller.wrappers.axios
const { baseURL, apiVersion } = config.config()
const API_VERSION = apiVersion.v2
const baseUrl_V2 = `${baseURL}${API_VERSION}me/`
const { formatError } = apiCaller
// api/v2/springworksml/temp/access

export const GetPublicToken = async () => {
    try {
        const url = 'springworksml/temp/access'
        const { data } = await open.get(url)
        return data
    } catch (error) {
        warning(formatError(error))
        return {}
    }
}
// springworksml/limited/fullresume

export const UploadForDetailedResume = async (authToken, payload) => {
    const url = `${baseUrl_V2}springworksml/limited/fullresume`
    return axios.post(
        url,
        payload,
        {
            headers: {
                Authorization: authToken,
                'Content-Type': 'application/json',
            },
        }
    )
}
