import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Card, Avatar, Top, Icons } from '../style'
import { CardActions, CardBottom, CardInfo, BottomTags } from './Presentational/CandidateStatics'
import { Checkbox } from 'antd'
import getAvatar from '../../../../services/getAvatar'
import { sendEmail } from '../../../../services/NetworkCalls/SourcingTalentPool'
import ResumeDrawer from './Presentational/ResumeDrawer'
import { MODAL_TYPES } from '../../../Helpers/ModalTypes'
import { Modal } from '../../../Modals'
import { MODAL_TYPE } from '../../TalentPool/constants'

const CandidateCard = ({
    data,
    selectCandidate,
    bulkMode,
    updateUniversalCandidate,
    assignToJob,
    selectedCandidate,
    addNote,
    updateTags,
    allJobs,
}) => {
    const { _id, name, email, tags = [] } = data
    const [resumeLink, setResumeLink] = useState(null)
    const [showMoreOptions, setShowMoreOptions] = useState(false)
    const [showSendEmail, setShowSendEmail] = useState(false)
    const [showAddNote, setShowAddNote] = useState(false)
    const [modalType, setModalType] = useState(null)
    const [showMoreTags, setShowMoreTags] = useState(false)
    const [showAddTag, setShowAddTag] = useState(false)

    const updateCandidate = (update, data) => updateUniversalCandidate({ update, data, ids: [_id], name })

    const handleSendEmail = ({ content, subject }) => {
        const payload = {
            email_to: email,
            universalCandidateId: _id,
            email_subject: subject,
            email_content: content,
            privacy_options_private: false,
            privacy_options_allowedToView: JSON.stringify({
                ids: [],
            }),
        }
        // allowedToView will be parsed as a JSON object in the backend because of formData type API
        sendEmail(payload)
        setShowSendEmail(false)
    }

    const handleJobChange = (result, { jobId, stageId }) => {
        const selectedJob = allJobs.find(job => job._id === jobId)
        const selectedStage = selectedJob.stages.find(stage => stage._id === stageId)
        const payload = {
            jobTitle: selectedJob.jobTitle,
            jobId,
            stageName: selectedStage.stageName,
            stageId,
            universalCandidateIds: [_id],
            name,
        }
        assignToJob(payload)
        setModalType(null)
    }

    const handleAddNote = newNote => {
        const { note, canView, private: isPrivate } = newNote
        const payload = {
            note: { private: isPrivate, content: note, allowedToView: canView },
            universalCandidateId: _id,
        }
        addNote(payload)
        setShowAddNote(false)
    }

    const actionMenuClicked = option => {
        setShowMoreOptions(false)
        if (option === 1) setShowAddNote(true)
        else if (option === 2) setShowSendEmail(true)
        else if (option === 3) setModalType(MODAL_TYPE.MOVE_JOB)
        else if (option === 4) setShowAddTag(true)
    }

    const updateCandidateTags = payload => {
        updateTags(payload)
        setShowAddTag(false)
    }

    return (
        <Card>
            <Top>
                {bulkMode && (
                    <Checkbox
                        className="mr-10"
                        checked={selectedCandidate.hasOwnProperty(_id)}
                        onChange={() => selectCandidate(data)}
                    />
                )}
                <Avatar>{getAvatar(name)}</Avatar>

                <CardInfo data={data} />

                <CardActions
                    data={data}
                    setResumeLink={setResumeLink}
                    updateCandidate={updateCandidate}
                    actionMenuClicked={actionMenuClicked}
                    setShowMoreOptions={setShowMoreOptions}
                    showMoreOptions={showMoreOptions}
                    bulkMode={bulkMode}
                />
            </Top>

            <CardBottom data={data} />

            {tags.length ? <BottomTags tags={tags} setShowAddTag={setShowAddTag} bulkMode={bulkMode} /> : null}

            <ResumeDrawer resumeLink={resumeLink} onClose={() => setResumeLink(false)} />
            <Modal
                data={{
                    candidate: { name, email },
                    job: {},
                    interviewStage: {},
                    prefillSubject: 'Candidate details required',
                }}
                type={MODAL_TYPES.SEND_EMAIL}
                visible={!!showSendEmail}
                onConfirm={result => handleSendEmail(result)}
                onCancel={() => setShowSendEmail(false)}
            />

            <Modal
                type={MODAL_TYPES.UPDATE_CANDIDATE}
                visible={!!modalType}
                onConfirm={handleJobChange}
                onCancel={() => setModalType(null)}
                selectionSize={1}
                selected={{ [data._id]: data }}
                source={modalType}
            />

            <Modal
                type={MODAL_TYPES.ADD_NOTES}
                visible={showAddNote}
                candidateId={_id}
                onCancel={() => setShowAddNote(false)}
                onConfirm={handleAddNote}
                candidateName={name}
            />
            <Modal
                type={MODAL_TYPES.ADD_TAG}
                visible={showAddTag}
                selectedCandidates={[data]}
                updateTags={updateCandidateTags}
                onCancel={() => setShowAddTag(false)}
            />
        </Card>
    )
}

export default CandidateCard
