import React from 'react'
import { Header, Footer } from '../common'
import { Input, Radio, Select } from 'antd'
import { BoardPreview } from './preview'
import { ReactComponent as ErrorIcon } from '../../../assets/Icons/Access/error.svg'
import './style.scss'

const { Option } = Select
const { Group: RadioGroup } = Radio

export const AddStage = props => {
    const stagesMap = props.stagesMap
    const stagesArray = stagesMap.map(stage => stage.title)

    return (
        <>
            <Header title={props.editMode ? 'EDIT STAGE' : 'ADD STAGE'} />

            <div className="stage-modal-wrapper">
                <div className="stage-modal-config">
                    <div className="stage-modal-config-block">
                        <div className="stage-modal-config-block-heading">Stage Name</div>
                        <Input
                            size="large"
                            className="modal-ad-name"
                            placeholder="Enter the stage name"
                            value={props.stageForm.stageName}
                            onChange={props.handleChange('stageName')}
                        />
                        {props.error ? (
                            <div className="stage-modal-config-block-error">
                                <ErrorIcon />
                                <span>Stage name already exists</span>
                            </div>
                        ) : null}
                    </div>

                    {props.stageForm.stageName !== 'JOINED' ? (
                        <div className="stage-modal-config-block stage-modal-config-top-margin">
                            <div className="stage-modal-config-block-heading">Stage Position</div>
                            <Select
                                size="large"
                                disabled={!stagesMap.length}
                                className="stage-modal-config-block-select"
                                defaultValue={props.stageForm.stagePosition}
                                onChange={props.handleChange('stagePosition')}
                            >
                                {stagesMap.map((option, i) => (
                                    <Option className="modal-ad-position-option" key={option.id} value={i}>
                                        <h5>Before "{option.title}" Stage</h5>
                                    </Option>
                                ))}
                                {stagesMap.length && !stagesArray.includes('JOINED') ? (
                                    <Option
                                        className="modal-ad-position-option"
                                        key="keyOption"
                                        value={stagesMap.length}
                                    >
                                        <h5>After "{stagesMap[stagesMap.length - 1].title}" Stage</h5>
                                    </Option>
                                ) : null}
                            </Select>
                        </div>
                    ) : null}
                </div>

                <div className="stage-modal-radio">
                    <div className="stage-modal-radio-heading">
                        Does this stage includes scheduling of interview / meetings?
                    </div>
                    <RadioGroup value={props.stageForm.hasInterview} onChange={props.handleChange('hasInterview')}>
                        <Radio className="modal-radio-text" value={true}>
                            Yes
                        </Radio>
                        <Radio className="modal-radio-text" value={false}>
                            No
                        </Radio>
                    </RadioGroup>
                </div>
                {(props.hasDoselectCredentials && props.doSelectTests.length) ||
                (props.hasUnberryCredentials && props.unberryPositions.length) ? (
                    <div className="stage-modal-radio">
                        <div className="stage-modal-radio-heading">
                            Does this stage includes auto assigning of test?
                        </div>
                        <RadioGroup value={props.stageForm.hasTest} onChange={props.handleChange('hasTest')}>
                            <Radio className="modal-radio-text" value={true}>
                                Yes
                            </Radio>
                            <Radio className="modal-radio-text" value={false}>
                                No
                            </Radio>
                        </RadioGroup>
                    </div>
                ) : null}
                {props.stageForm.hasTest ? (
                    <div className="stage-modal-radio">
                        <div className="stage-modal-radio-heading">Assesment action</div>
                        <RadioGroup
                            value={props.stageForm.assesmentType}
                            onChange={props.handleChange('assesmentType')}
                        >
                            {props.hasUnberryCredentials && props.unberryPositions.length ? (
                                <Radio className="modal-radio-text" value={'unberry'}>
                                    Unberry
                                </Radio>
                            ) : null}
                            {props.hasDoselectCredentials && props.doSelectTests.length ? (
                                <Radio className="modal-radio-text" value={'doSelect'}>
                                    DoSelect
                                </Radio>
                            ) : null}
                        </RadioGroup>
                    </div>
                ) : null}
                {props.stageForm.hasTest && props.hasUnberryCredentials && props.stageForm.unberry.status && (
                    <div className="stage-modal-config">
                        <div className="stage-modal-config-block">
                            <div className="stage-modal-config-block-heading">Select Unberry Assesment</div>
                            <Select
                                size="large"
                                className="stage-modal-config-block-select"
                                defaultValue={
                                    props.stageForm.unberry.positionId ||
                                    (props.unberryPositions.length && props.unberryPositions[0].positionId)
                                }
                                onChange={props.handleChange('unberryPosition')}
                            >
                                {props.unberryPositions.map(option => (
                                    <Option
                                        className="modal-ad-position-option"
                                        key={option.positionId}
                                        value={option.positionId}
                                    >
                                        <h5>{option.name}</h5>
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}
                {props.stageForm.hasTest && props.hasDoselectCredentials && props.stageForm.doSelect.status && (
                    <div className="stage-modal-config">
                        <div className="stage-modal-config-block">
                            <div className="stage-modal-config-block-heading">Select DoSelect Test</div>
                            <Select
                                size="large"
                                className="stage-modal-config-block-select"
                                defaultValue={
                                    props.stageForm.doSelect.slug ||
                                    (props.doSelectTests.length && props.doSelectTests[0].slug)
                                }
                                onChange={props.handleChange('doSelectSlug')}
                            >
                                {props.doSelectTests.map(option => (
                                    <Option className="modal-ad-position-option" key={option.slug} value={option.slug}>
                                        <h5>{option.name}</h5>
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}

                <div className="stage-modal-radio">
                    <div className="stage-modal-radio-heading">Layout Preview</div>
                    <BoardPreview tasks={props.previewTask} />
                </div>
            </div>

            <Footer
                onCancel={props.onCancel}
                onConfirm={props.onConfirm}
                confirmText={props.editMode ? 'EDIT' : 'ADD'}
                cancelText="CANCEL"
                hasSeparator
            />
        </>
    )
}
