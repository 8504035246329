import React, { useState, useEffect } from 'react'
import { Header, Footer } from '../common'
import { Input, Tooltip } from 'antd'
import { Body, GreyTags } from './style'
import { ReactComponent as CloseIcon } from '../../../assets/Icons/Access/close.svg'
import { ReactComponent as InfoIcon } from '../../../assets/Icons/Interview/info.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/Icons/AccountSettingPage/Error_Icon.svg'
import { StyledButton } from '../../Components'

// To get all the tags that are common for all the selected candidates
const getCommonTags = candidates => {
    let tagArray = [],
        tagNameArray = []

    tagArray = candidates[0].tags || []
    tagNameArray = (candidates[0].tags && candidates[0].tags.map(each => each.name)) || []

    for (let i = 1; i < candidates.length; i++) {
        const tempTagArray = [],
            tempTagNameArray = []
        if (!candidates[i].tags) {
            tagArray = []
            tagNameArray = []
            break
        } else {
            candidates[i].tags.forEach(tag => {
                if (tagNameArray.includes(tag.name)) {
                    tempTagArray.push(tag)
                    tempTagNameArray.push(tag.name)
                }
            })
            tagArray = tempTagArray
            tagNameArray = tempTagNameArray
        }
    }

    return { tagArray, tagNameArray, hasTags: tagArray.length }
}

const ManageTag = props => {
    const [tags, setTags] = useState([])
    const [tagNames, setTagNames] = useState([])
    const [selectedTag, setSelectedTag] = useState(null)
    const [selectedTagValue, setSelectedTagValue] = useState(null)
    const [addedTags, setAddedTags] = useState([])
    const [deletedTags, setDeletedTags] = useState([])
    const [errors, setErrors] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [hasTags, setHasTags] = useState(false)

    useEffect(() => {
        const { tagArray, tagNameArray, hasTags } = getCommonTags(props.selectedCandidates)
        setTags(tagArray)
        setTagNames(tagNameArray)
        setHasTags(hasTags)
    }, [])

    const filterTag = tag => {
        const remainingTags = tags.filter(each => each.name !== tag.name)
        const remainingTagNames = tagNames.filter(each => each !== tag.name)
        setTags(remainingTags)
        setTagNames(remainingTagNames)
        if (tag.hasOwnProperty('tagId')) setDeletedTags([...deletedTags, tag])
        else {
            const remainingAddedTags = addedTags.filter(each => each.name !== tag.name)
            setAddedTags(remainingAddedTags)
        }
    }

    const modifyTags = () => {
        const tagValue = selectedTagValue.trim()
        if (selectedTag.name === tagValue) {
            setSelectedTag(null)
            setSelectedTagValue(null)
            return
        } else if (tagNames.includes(tagValue)) {
            setErrors({ tagExist: 'Tag already exists' })
            return
        } else if (!tagValue.length) {
            setErrors({ tagEmpty: 'Tag name cannot be blank' })
            return
        }
        const remainingTags = tags.filter(tag => tag.name !== selectedTag.name)
        const remainingAddedTags = addedTags.filter(tag => tag.name !== selectedTag.name)
        const remainingTagNames = tagNames.filter(tag => tag !== selectedTag.name)
        if (selectedTag.hasOwnProperty('tagId')) setDeletedTags([...deletedTags, selectedTag])
        setAddedTags([...remainingAddedTags, { name: tagValue }])
        setTags([...remainingTags, { name: tagValue }])
        setTagNames([...remainingTagNames, tagValue])
        setSelectedTag(null)
        setSelectedTagValue(null)
        setErrors(null)
    }

    const updateTags = () => {
        const data = {
            addedTags,
            deletedTags,
            uCandidateList: props.selectedCandidates.map(each => each._id || each.candidateId || each.objectID),
        }
        props.updateTags(data)
    }

    return (
        <>
            <Header title="MANAGE TAG" />
            {confirm ? (
                <>
                    {hasTags && !tags.length ? (
                        <Body>
                            <div className="confirm">
                                All tags will be removed from selected profile, do you want to proceed?
                            </div>
                        </Body>
                    ) : (
                        <Body>
                            <GreyTags mBottom={false}>
                                {tags.map(tag => (
                                    <span>{tag.name}</span>
                                ))}
                            </GreyTags>
                            <div className="confirm">Are you sure you want to save the changes?</div>
                        </Body>
                    )}
                </>
            ) : (
                <>
                    {!tags.length ? (
                        <Body>
                            <div className="confirm">No tag found!</div>
                        </Body>
                    ) : (
                        <Body>
                            {selectedTag && (
                                <>
                                    <Input
                                        size="large"
                                        value={selectedTagValue}
                                        onChange={({ target: { value } }) => {
                                            if (value.trim().length > 25) return
                                            setSelectedTagValue(value)
                                            setErrors(null)
                                        }}
                                        onPressEnter={() => modifyTags()}
                                    />
                                    {errors && (
                                        <div className="error">
                                            <ErrorIcon />
                                            <p>{errors.tagEmpty || errors.tagExist}</p>
                                        </div>
                                    )}
                                </>
                            )}
                            <GreyTags>
                                {tags.map(tag => {
                                    if (tag.tagId === (selectedTag && selectedTag.tagId))
                                        return <div>{selectedTagValue}</div>
                                    else
                                        return (
                                            <span
                                                onClick={e => {
                                                    setSelectedTag(tag)
                                                    setSelectedTagValue(tag.name)
                                                }}
                                            >
                                                {tag.name}{' '}
                                                <CloseIcon
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                        filterTag(tag)
                                                    }}
                                                />
                                            </span>
                                        )
                                })}
                            </GreyTags>
                            {!selectedTag ? (
                                <div className="help">
                                    <InfoIcon /> Click on tag to edit
                                </div>
                            ) : null}
                        </Body>
                    )}
                </>
            )}
            <Footer
                hasSeparator
                onCancel={() => (confirm ? setConfirm(false) : props.onCancel())}
                onConfirm={() => (confirm ? updateTags() : setConfirm(true))}
                disable={!hasTags && !tags.length}
                cancelText={confirm ? 'NO' : 'CANCEL'}
                confirmText={confirm ? 'YES' : 'SAVE'}
            />
        </>
    )
}

export default ManageTag
