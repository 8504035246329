import styled from 'styled-components'

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;

    .ant-form-explain {
        margin-top: 5px;
    }
`

export const ModalElementWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: left;
    @media only screen and (max-width: 769px) {
        color: black;
        flex-direction: column-reverse;
        button {
            width: 100% !important;
            margin: 4px 0;
        }
    }
`

export const CandidateDetailsWrapper = styled.div`
    min-width: 547px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 3px solid #94aad0;
    padding: 20px;
    min-height: 189px;
    @media only screen and (max-width: 769px) {
        min-width: unset;
        width: 100%;
        display: block;
        border: 1px solid #94aad0;
        min-height: unset;
        padding: 15px;
    }
    .ant-form {
        height: 100%;
        font-size: 12px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .ant-form-item-control {
        line-height: normal !important;
    }

    .ant-form-item {
        font-size: 12px !important;
        margin-bottom: 0 !important;
    }

    .ant-form label {
        font-size: 12px !important;
    }

    .ant-form-explain {
        margin-top: 5px;
    }
`
