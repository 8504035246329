import React from 'react'
import moment from 'moment'
import { ReactComponent as ClockIcon } from '../../../../assets/Icons/Calendar/clock.svg'
import { Content, Header, Button, Heading, Days, Day, DayLabel, Footer, Item, Error } from '../style'
import { timestamps } from '../../../Helpers/timestamps'
import { Select, Form } from 'antd'
import { warning } from '../../../../services/Notification'
import { Btn } from '../../../Pages/ScheduleInterview/Form/Address/style'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { SetAvailability as SetUserAvailability } from '../../../../services/NetworkCalls/GoogleCalendar'

const DEFAULT_SCHEDULE = {
    startTime: '9:00 am',
    endTime: '5:00 pm',
    status: true,
}

class SetAvailability extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            schedule: {
                Sunday: { day: 'Sun', ...DEFAULT_SCHEDULE, status: false },
                Monday: Object.assign({ day: 'Mon' }, DEFAULT_SCHEDULE),
                Tuesday: Object.assign({ day: 'Tue' }, DEFAULT_SCHEDULE),
                Wednesday: Object.assign({ day: 'Wed' }, DEFAULT_SCHEDULE),
                Thursday: Object.assign({ day: 'Thu' }, DEFAULT_SCHEDULE),
                Friday: Object.assign({ day: 'Fri' }, DEFAULT_SCHEDULE),
                Saturday: { day: 'Sat', ...DEFAULT_SCHEDULE, status: false },
            },
            daysCount: 5,
            hideError: true,
        }

        const availability = this.props.availability || null

        if (availability && availability.length) {
            // showing the set availability data
            const storeData = availability || []
            let { schedule } = this.state
            const daysArray = storeData.map(data => data.day)
            const daysList = Object.keys(schedule)
            const daysCount = storeData.length

            daysList.forEach(each => {
                const day = schedule[each].day
                const index = daysArray.indexOf(day)
                if (daysArray.includes(day)) {
                    schedule[each].startTime = storeData[index].start
                    schedule[each].endTime = storeData[index].end
                    schedule[each].status = true
                } else {
                    schedule[each].status = false
                }
            })

            this.state['schedule'] = schedule
            this.state['daysCount'] = daysCount
        }
    }

    changeDate = value => {
        let { schedule, daysCount } = this.state
        if (daysCount === 1 && schedule[value].status === true) {
            this.setState({ hideError: false }, () => {
                setTimeout(() => this.setState({ hideError: true }), 3000)
            })
        } else {
            daysCount = schedule[value].status ? daysCount - 1 : daysCount + 1
            schedule[value].status = !schedule[value].status
            this.setState({ schedule, daysCount, hideError: true })
        }
    }

    momentValidator = (rule, value, callback) => {
        const day = rule.field.split('-')[1]
        const startTime = this.props.form.getFieldValue(`startTime-${day}`)
        const startTimeMoment = moment(startTime, 'h:mm a')
        const endTime = this.props.form.getFieldValue(`endTime-${day}`)
        const endTimeMoment = moment(endTime, 'h:mm a')

        if (endTimeMoment.diff(startTimeMoment) <= 0) {
            callback('Invalid time')
        } else {
            let errors = this.props.form.getFieldsError([`startTime-${day}`, `endTime-${day}`])
            if (errors[`startTime-${day}`]) {
                this.props.form.setFields({ [`startTime-${day}`]: { value: startTime } })
            }
            if (errors[`endTime-${day}`]) {
                this.props.form.setFields({ [`endTime-${day}`]: { value: endTime } })
            }
            callback()
        }
    }

    handleSubmit = e => {
        e.preventDefault()

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    const { schedule } = this.state
                    const dayList = Object.keys(schedule)

                    dayList.map(each => {
                        if (`startTime-${each}` in values) {
                            schedule[each].startTime = values[`startTime-${each}`]
                        }
                        if (`endTime-${each}` in values) {
                            schedule[each].endTime = values[`endTime-${each}`]
                        }
                    })
                    this.setState({ schedule })

                    const valueArray = []
                    dayList.map(each => {
                        const obj = {}
                        if (schedule[each].status === true) {
                            obj.day = schedule[each].day
                            obj.start = schedule[each].startTime
                            obj.end = schedule[each].endTime
                            valueArray.push(obj)
                        }
                    })

                    const changed = await this.props.SetUserAvailability(valueArray, moment().utcOffset())
                    if (changed) this.props.changeStep(4)
                } catch (err) {
                    warning(err.message || err)
                }
            }
        })
    }
    componentRender = (each, schedule) => {
        const { getFieldDecorator } = this.props.form
        return (
            <Item key={each} small>
                <DayLabel>{each}</DayLabel>
                <div className="options">
                    <Form.Item>
                        {getFieldDecorator(`startTime-${each}`, {
                            initialValue: schedule[each].startTime,
                            rules: [{ validator: this.momentValidator }],
                        })(
                            <Select
                                size="large"
                                getPopupContainer={trigger => trigger.parentNode}
                                suffixIcon={<DownIcon />}
                            >
                                {timestamps.map(stamp => (
                                    <Select.Option
                                        key={`${stamp.time} ${stamp.meridian}`}
                                        title={`${stamp.time} ${stamp.meridian}`}
                                    >
                                        {`${stamp.time} ${stamp.meridian}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator(`endTime-${each}`, {
                            initialValue: schedule[each].endTime,
                            rules: [{ validator: this.momentValidator }],
                        })(
                            <Select
                                size="large"
                                getPopupContainer={trigger => trigger.parentNode}
                                suffixIcon={<DownIcon />}
                            >
                                {timestamps.map(stamp => (
                                    <Select.Option
                                        key={`${stamp.time} ${stamp.meridian}`}
                                        title={`${stamp.time} ${stamp.meridian}`}
                                    >
                                        {`${stamp.time} ${stamp.meridian}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </div>
            </Item>
        )
    }

    render() {
        let { schedule } = this.state
        const daysList = Object.keys(schedule).filter(each => schedule[each].status)
        return (
            <Content>
                <Header>
                    <ClockIcon /> Set your availability
                </Header>
                <Heading>Available days</Heading>
                <Days>
                    {Object.keys(schedule).map(each => (
                        <Day key={each} status={schedule[each].status} onClick={() => this.changeDate(each)}>
                            {each[0]}
                        </Day>
                    ))}
                </Days>
                <Error hidden={this.state.hideError}>Atleast one day needs to be selected</Error>
                <Heading>Available hours</Heading>
                <Form>
                    <ReactCSSTransitionGroup
                        transitionName="set-availabitiy-transition"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={0}
                    >
                        {daysList.map(each => this.componentRender(each, schedule))}
                    </ReactCSSTransitionGroup>
                    <Btn primary save onClick={this.handleSubmit}>
                        Save
                    </Btn>
                </Form>
                <Footer>
                    <span>You can always go to your settings section to update your availability at later stages</span>
                </Footer>
            </Content>
        )
    }
}

const mapStateToProps = state => {
    return {
        availability: state.GCal.availability,
    }
}

export default connect(
    mapStateToProps,
    { SetUserAvailability }
)(Form.create()(SetAvailability))
