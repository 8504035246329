import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Card, Body, Title, SubTitle, Label, Footer, Content, Header, Icons } from '../style'
import { ReactComponent as CommentIcon } from '../../../../assets/Icons/Todo/comment.svg'
import { ReactComponent as FeedbackIcon } from '../../../../assets/Icons/Todo/feedback.svg'
import { ReactComponent as CancelIcon } from '../../../../assets/Icons/Todo/cancel.svg'
import { ReactComponent as CalendarIcon } from '../../../../assets/Icons/Todo/calendar.svg'
import { ReactComponent as TickIcon } from '../../../../assets/Icons/Interview/tick.svg'
import { ReactComponent as ThreeDots } from '../../../../assets/Icons/Dashboard/threeDots.svg'
import { ReactComponent as CopyIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Copy.svg'
import { Icon, Tooltip, Popover, Menu } from 'antd'
import { INTERVIEW_TYPES_KEYS } from '../../ScheduleInterview/Constants'
import moment from 'moment'
import { timeFormat, dateFormat } from '../../../Helpers/times'
import { RequestFeedbackForInterview } from '../../../../services/NetworkCalls/Feedback'
import '../style.scss'
import { MODAL_TYPES } from '../../../Helpers/ModalTypes'
import { ACCESS_LEVEL_DEFINITIONS } from '../../../Helpers/constants'

const candidatePageOpener = (cId, { openMessage = false, feedback = false }) => {
    window.open(`/candidate/${cId}${feedback ? '?view=feedback' : openMessage ? '?view=message' : ''}`, '_blank')
}

const DataSelection = ({ data, render }) => {
    if (data) return render
    return null
}

const copyToClipboard = url => {
    const el = document.createElement('textarea')
    el.value = url
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
}
const InterviewDetails = ({ interviewType, meetingDetails, skypeId }) => {
    switch (interviewType) {
        case INTERVIEW_TYPES_KEYS.SKYPE:
            return (
                <Label>
                    <span className="key">Skype ID: </span>
                    <span className="value value-ellipsis">{skypeId || 'NA'}</span>
                </Label>
            )
        case 'HANGOUTS':
            return (
                <Label>
                    <span className="key">Google Meet: </span>
                    <span className="value">
                        <a onClick={() => window.open(meetingDetails, '_blank')} rel="external">
                            {meetingDetails ? meetingDetails.replace('https://meet.google.com/', '') : 'NA'}{' '}
                        </a>
                        {meetingDetails && (
                            <Tooltip title="Copy">
                                <CopyIcon
                                    onClick={() => {
                                        copyToClipboard(meetingDetails)
                                    }}
                                />
                            </Tooltip>
                        )}
                    </span>
                </Label>
            )
        case INTERVIEW_TYPES_KEYS.OUTLOOK:
            return (
                <Label>
                    <span className="key">OUTLOOK: </span>
                    <span className="value">
                        <a onClick={() => window.open(meetingDetails, '_blank')} rel="external">
                            {meetingDetails ? 'INTERVIEWER LINK' : 'NA'}{' '}
                        </a>
                        {meetingDetails && (
                            <Tooltip title="Copy">
                                <CopyIcon
                                    onClick={() => {
                                        copyToClipboard(meetingDetails)
                                    }}
                                />
                            </Tooltip>
                        )}
                    </span>
                </Label>
            )
        case INTERVIEW_TYPES_KEYS.F2F:
            return (
                <Label>
                    <span className="key">Address: </span>
                    <span className="value">{meetingDetails || 'NA'}</span>
                </Label>
            )
        case INTERVIEW_TYPES_KEYS.ZOOM:
            return (
                <Label>
                    <span className="key">Zoom: </span>
                    <span className="value">
                        <a onClick={() => window.open(meetingDetails, '_blank')} rel="external">
                            {meetingDetails ? 'INTERVIEWER LINK' : 'NA'}{' '}
                        </a>
                        {meetingDetails && (
                            <Tooltip title="Copy">
                                <CopyIcon
                                    onClick={() => {
                                        copyToClipboard(meetingDetails)
                                    }}
                                />
                            </Tooltip>
                        )}
                    </span>
                </Label>
            )

        default:
            return null
    }
}

const GetStatus = ({ _id, status, hasFeedback, interviewStartTime, interviewEndTime, remind }) => {
    const [statusText, setStatusText] = useState('Feedback Pending ')
    switch (status) {
        case 'pending':
            if (moment().isAfter(moment(interviewStartTime)) && !hasFeedback)
                return (
                    <span className={statusText === 'Feedback Pending ' ? 'pending' : 'resend'}>
                        {`${statusText} `}
                        {moment().isAfter(moment(interviewEndTime)) && (
                            <span
                                className="remind"
                                onClick={() => {
                                    remind(_id)
                                    setStatusText('Reminder sent to the interviewer ')
                                }}
                            >
                                {statusText === 'Feedback Pending ' ? '(Send Reminder)' : '(Resend)'}
                            </span>
                        )}
                    </span>
                )
            else if (moment().isBefore(moment(interviewStartTime))) return <span className="upcoming">Upcoming</span>
            break
        case 'canceled':
            return <span className="cancelled">Canceled</span>
        case 'completed':
            return (
                <span className="complete">
                    Completed <TickIcon style={{ width: '10px', height: '10px', marginLeft: '2px' }} />
                </span>
            )
        default:
    }
}

const CardComponent = props => {
    const {
        showInterviewModal,
        setSelectedData,
        isMobileDevice,
        data: {
            _id,
            interviewEventUserId,
            interviewEventId,
            interviewType,
            assignee,
            candidate: {
                name,
                email,
                _id: cId,
                phone,
                totalExperience,
                socialMedia: { skypeId },
            },
            job: { companyName, jobTitle, title }, // { _id, companyName, jobTitle, title, status }
            job_application, // {_id, status}
            interviewDate,
            interviewStage,
            interviewStartTime,
            interviewEndTime,
            status,
            interviewers,
            meetingDetails,
            hasFeedback,
            isRescheduled,
            hasAssociatedJobApplication,
        },
    } = props
    let a_name = 'SpringRecruit User',
        a_email
    if (assignee) {
        a_name = assignee.fullName || (assignee.name && assignee.name.fullName)
        a_email = assignee.email
    }
    const [isLoading, setIsLoading] = useState(false)
    const isInterviwerOnly = props.accessLevel === ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel

    const isArchived =
        hasAssociatedJobApplication &&
        ((props.data.job && props.data.job.status === 0) || (job_application && job_application.status === 0))

    return (
        <Card>
            <Body>
                <Header>
                    <Title>{INTERVIEW_TYPES_KEYS[interviewType]} Interview</Title>
                    <Icons>
                        {hasAssociatedJobApplication ? (
                            <Popover
                                placement="bottomLeft"
                                trigger="click"
                                content={
                                    <Menu>
                                        <Menu.Item
                                            onClick={() =>
                                                setSelectedData({ type: MODAL_TYPES.FEEDBACKS, data: props.data })
                                            }
                                        >
                                            Add Interview Feedback
                                        </Menu.Item>
                                        <Menu.Item
                                            disabled={!hasAssociatedJobApplication}
                                            onClick={() =>
                                                setSelectedData({ type: MODAL_TYPES.SEND_EMAIL, data: props.data })
                                            }
                                        >
                                            Send Email
                                        </Menu.Item>
                                        {!isInterviwerOnly && (
                                            <Menu.Item onClick={() => showInterviewModal(props.data)}>
                                                Reschedule Interview
                                            </Menu.Item>
                                        )}
                                        {!isInterviwerOnly && !['canceled', 'completed'].includes(status) && (
                                            <Menu.Item
                                                style={{ color: 'red' }}
                                                onClick={() => {
                                                    setIsLoading(true)
                                                    props.cancelInterview({
                                                        _id,
                                                        interviewEventId,
                                                        interviewEventUserId,
                                                        offset: moment().utcOffset(),
                                                        interviewDate: moment(interviewStartTime).format('LL'),
                                                    })
                                                }}
                                            >
                                                Cancel Interview
                                            </Menu.Item>
                                        )}
                                    </Menu>
                                }
                            >
                                <ThreeDots className="menu" />
                            </Popover>
                        ) : (
                            <Tooltip
                                placement="bottomLeft"
                                trigger="click"
                                title="Actions cannot be performed for this interview as the candidate has been moved to a different opening"
                            >
                                <ThreeDots className="menu" />
                            </Tooltip>
                        )}
                        <Tooltip
                            title={
                                hasAssociatedJobApplication
                                    ? 'Add Interview Feedback'
                                    : 'Feedback cannot be added as the candidate has been moved to a different opening'
                            }
                        >
                            <FeedbackIcon
                                className={hasAssociatedJobApplication ? '' : 'disabled-icon-feedback'}
                                onClick={() => {
                                    if (hasAssociatedJobApplication)
                                        setSelectedData({ type: MODAL_TYPES.FEEDBACKS, data: props.data })
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            title={
                                hasAssociatedJobApplication
                                    ? 'Send Email'
                                    : 'Message cannot be sent as the candidate has been moved to a different opening'
                            }
                        >
                            <CommentIcon
                                className={hasAssociatedJobApplication ? '' : 'disabled-icon-comment'}
                                onClick={() => {
                                    if (hasAssociatedJobApplication)
                                        setSelectedData({ type: MODAL_TYPES.SEND_EMAIL, data: props.data })
                                }}
                            />
                        </Tooltip>
                        {
                            // if the job/candidate is archived or user is interview only access level, they cant schedule/reschedule/cancel
                        }
                        {!isInterviwerOnly && !isArchived && (
                            <React.Fragment>
                                <Tooltip
                                    title={
                                        hasAssociatedJobApplication
                                            ? 'Reschedule Interview'
                                            : 'This interview cannot be rescheduled as the candidate has been moved to a different opening'
                                    }
                                >
                                    <CalendarIcon
                                        className={hasAssociatedJobApplication ? '' : 'disabled-icon-calendar'}
                                        onClick={() => {
                                            if (hasAssociatedJobApplication) showInterviewModal(props.data)
                                        }}
                                    />
                                </Tooltip>
                                {!['canceled', 'completed'].includes(status) && (
                                    <Tooltip
                                        title={
                                            hasAssociatedJobApplication
                                                ? isLoading
                                                    ? 'Canceling...'
                                                    : 'Cancel Interview'
                                                : 'This interview cannot be canceled as the candidate has been moved to a different opening'
                                        }
                                    >
                                        {isLoading ? (
                                            <Icon type="loading" style={{ fontSize: '14px' }} />
                                        ) : (
                                            <CancelIcon
                                                className={hasAssociatedJobApplication ? '' : 'disabled-icon-cancel'}
                                                onClick={() => {
                                                    if (hasAssociatedJobApplication) {
                                                        setIsLoading(true)
                                                        props.cancelInterview({
                                                            _id,
                                                            interviewEventId,
                                                            interviewEventUserId,
                                                            offset: moment().utcOffset(),
                                                            interviewDate: moment(interviewStartTime).format('LL'),
                                                        })
                                                    }
                                                }}
                                            />
                                        )}
                                    </Tooltip>
                                )}
                            </React.Fragment>
                        )}
                    </Icons>
                </Header>
                <Content>
                    <div className="candidate-details">
                        <SubTitle>{jobTitle || title}</SubTitle>
                        <DataSelection
                            data={name || email}
                            render={
                                <Label>
                                    <span className="key">Candidate Name: </span>
                                    <span
                                        className="value value-ellipsis candidate-name-span"
                                        onClick={() => candidatePageOpener(cId, { openMessage: false })}
                                    >
                                        {name || email}
                                    </span>
                                </Label>
                            }
                        />

                        <DataSelection
                            data={companyName}
                            render={
                                <Label>
                                    <span className="key">Applied Company: </span>
                                    <span className="value value-ellipsis">{companyName}</span>
                                </Label>
                            }
                        />
                        <DataSelection
                            data={totalExperience}
                            render={
                                <Label>
                                    <span className="key">Experience: </span>
                                    <span className="value">{totalExperience}</span>
                                </Label>
                            }
                        />
                        <DataSelection
                            data={phone}
                            render={
                                <Label>
                                    <span className="key">Mobile Number: </span>
                                    <span className="value">{phone}</span>
                                </Label>
                            }
                        />
                        <DataSelection
                            data={interviewStage.stageName}
                            render={
                                <Label>
                                    <span className="key">Candidate Stage: </span>
                                    <span className="value">{interviewStage.stageName}</span>
                                </Label>
                            }
                        />
                    </div>
                    <div className="interview-details">
                        <SubTitle>INTERVIEW DETAILS</SubTitle>
                        <Label>
                            <span className="key">Interview Type: </span>
                            <span className="value">{INTERVIEW_TYPES_KEYS[interviewType]}</span>
                        </Label>
                        <InterviewDetails
                            interviewType={interviewType}
                            skypeId={skypeId}
                            meetingDetails={meetingDetails}
                        />
                        <Label>
                            <span className="key grey value-bold">Interview Date: </span>
                            <span className="value value-bold">{moment(interviewDate).format(dateFormat)}</span>
                        </Label>
                        <Label>
                            <span className="key grey value-bold">Interview Time: </span>
                            <b className="value value-bold">{moment(interviewStartTime).format(timeFormat)}</b>
                        </Label>
                        <Label>
                            <span className="key grey value-bold">Interviewer(s): </span>
                            <b className="value value-bold">
                                {interviewers.map(({ fullName, email }) => fullName || email).join(', ')}
                            </b>
                        </Label>
                    </div>
                    {isRescheduled ? (
                        <div className="rescheduled">
                            <span>Rescheduled</span>
                        </div>
                    ) : null}
                </Content>
            </Body>
            <Footer>
                <div className="assignment">
                    <div className="assigned-by">Assigned by:{a_name || a_email}</div>
                    {hasFeedback && isMobileDevice && (
                        <div className="view-feedback" onClick={() => candidatePageOpener(cId, { feedback: true })}>
                            View Feedback
                        </div>
                    )}
                </div>
                <div className="status-reschedule-box">
                    <span>
                        Status:{' '}
                        {GetStatus({
                            _id,
                            status,
                            interviewStartTime,
                            interviewEndTime,
                            hasFeedback,
                            remind: props.RequestFeedbackForInterview,
                        })}
                    </span>
                    <div className="labels">
                        {/*view feedback to be placed next to "assigned by" in mobile */}
                        {hasFeedback && !isMobileDevice ? (
                            <span
                                className="view-feedback"
                                onClick={() => candidatePageOpener(cId, { feedback: true })}
                            >
                                View Feedback
                            </span>
                        ) : null}
                        <span className="view-profile" onClick={() => candidatePageOpener(cId, { openMessage: false })}>
                            View Profile
                        </span>
                    </div>
                </div>
            </Footer>
        </Card>
    )
}

const mapStateToProps = ({ Auth }) => {
    return {
        accessLevel: Auth.user.accessLevel,
    }
}
export default connect(
    mapStateToProps,
    { RequestFeedbackForInterview }
)(CardComponent)
