import React, { Component } from 'react'
import { Input, Tooltip } from 'antd'
import ComponentContainer from '../ComponentContainer'
import { Footer } from '../../../../Modals/common'
import { SaveUnberryCredentials, GetUnberryPositions } from '../../../../../services/api'
import { warning } from '../../../../../services/Notification'
import { ReactComponent as InfoIcon } from '../../../../../assets/Icons/Interview/info.svg'
import { UnberryContainer, UnberryEditPass, UnberryMessage } from './style.js'

class UnberryDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            apiKey: props.apiKey || '',
            unberryEdit: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { apiKey } = this.props

        if (apiKey !== prevProps.apiKey) this.setState({ apiKey })
    }

    handleChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    handleCancel = () => {
        this.setState({
            unberryEdit: false,
            apiKey: this.props.apiKey,
        })
    }

    setUnberryEdit = () => {
        this.setState({ unberryEdit: true })
    }

    hasUnberryCredentials = () => {
        this.saveUnberryCredentials()
    }

    saveUnberryCredentials = async () => {
        try {
            const { apiKey } = this.state
            const unberryCredential = {
                apiKey,
            }
            await SaveUnberryCredentials({ unberryCredential })
            GetUnberryPositions()
            this.setState({
                unberryEdit: false,
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    render() {
        return (
            <>
                {this.props.accessLevel < 3 ? (
                    <ComponentContainer
                        heading={
                            <div style={{ width: '200px', display: 'flex' }}>
                                <p style={{ marginRight: '15px', marginTop: '-3px' }}>Unberry Integration</p>
                                <Tooltip
                                    placement="top"
                                    title="Integrate DoSelect to find the right person for your technical roles quickly and easily by setting up tests to check a candidate' s technical abilities so you can make smart hiring choices."
                                    overlayClassName="fs-10"
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                        }
                        headingOptions={
                            <UnberryEditPass>
                                <span onClick={() => this.setUnberryEdit()}>
                                    <img src={require('./images/edit.svg').default} />
                                    Edit
                                </span>
                            </UnberryEditPass>
                        }
                    >
                        {this.state.unberryEdit || this.state.apiKey ? (
                            <UnberryContainer>
                                <div className="details">
                                    <div className="label">
                                        <span>API Key</span>
                                    </div>
                                    {!this.state.unberryEdit ? (
                                        <div className="label values">
                                            <span>{'*****************'}</span>
                                        </div>
                                    ) : (
                                        <div className="detailsInputs">
                                            <Input
                                                size="large"
                                                placeholder="Enter API Key"
                                                value={this.state.apiKey}
                                                onChange={this.handleChange}
                                                name="apiKey"
                                                className="detailsInputField"
                                            />
                                        </div>
                                    )}
                                </div>

                                {this.state.unberryEdit ? (
                                    <Footer
                                        onCancel={this.handleCancel}
                                        onConfirm={this.hasUnberryCredentials}
                                        cancelText="CANCEL"
                                        confirmText="SAVE"
                                    />
                                ) : null}
                            </UnberryContainer>
                        ) : (
                            <UnberryMessage>
                                Enter your Unberry keys to start assigning test to the candidates.
                            </UnberryMessage>
                        )}
                    </ComponentContainer>
                ) : null}
            </>
        )
    }
}

export default UnberryDetails
