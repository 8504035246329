import styled from 'styled-components'

/*
    Contains all custom input types components

    Naming Rule : PascalCase
    Naming Format: Input<Type: text/submit/password(optional)><feature>
 */

const InputBorderless = styled.input.attrs(({ placeholder = '' }) => ({
    // we can define static props
    type: 'text',

    placeholder,
}))`
    width: ${({ width = '100%' }) => width};
    height: ${({ height = '100%' }) => height};
    padding: 12px 0;
    display: inline-block;
    border: ${({ border = 'none' }) => border};
    box-sizing: border-box;
    :active,
    :hover,
    :focus {
        box-shadow: inherit;
        border: inherit;
        outline: none;
    }
`

export default { InputBorderless }
