import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ContainerBox } from '../style'

const ComponentContainer = ({ heading, headingOptions, hidden, children }) => {
    if (hidden) return null

    return (
        <ContainerBox>
            <div className="heading">
                {heading}
                {headingOptions}
            </div>
            <div className="division-line" />
            <div>{children}</div>
        </ContainerBox>
    )
}

ComponentContainer.defaultProps = {
    hidden: false,
}
ComponentContainer.propTypes = {
    heading: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
}

export default ComponentContainer
