import React, { Component } from 'react'
import {
    FormContainer,
    Interviewee,
    Avatar,
    Name,
    Key,
    Value,
    Detail,
    Button,
    Warning,
    Indicator,
    Footer,
} from './style'
import { Form, Input, Select, Row, Col, DatePicker, Tooltip, Modal, Icon, ConfigProvider, AutoComplete } from 'antd'
import { ReactComponent as InfoIcon } from '../../../../assets/Icons/Interview/info.svg'
import { ReactComponent as WarningIcon } from '../../../../assets/Icons/Interview/warning.svg'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { ReactComponent as TickIcon } from '../../../../assets/Icons/Interview/tick.svg'
import Address from './Address/Address'
import Slots from './Slots/Slots'
import Interviewer from './Interviewer/Interviewer'
import { warning } from '../../../../services/Notification'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { countryCodes } from '../../../Helpers/countryCode'
import { GetAvailableTimeSlots, CreateTemporaryEvent } from '../../../../services/NetworkCalls/GoogleCalendar'
import moment from 'moment'
import _ from 'lodash'
import { getAvailableTimeSlots } from '../../../Helpers/calendarTimeSlots'
import { times, timeFormat } from '../../../Helpers/times'
import getAvatar from '../../../../services/getAvatar'
import Resume from './../Resume'
import {
    INTERVIEW_TYPES_ICONS,
    INTERVIEW_TYPES_KEYS,
    FORM_CONFIGS,
    INTERVIEW_TYPES_ICONS_CONDITIONAL_SWITCH,
} from '../Constants'
import { connect } from 'react-redux'
import { NotFound } from '../../../Helpers/CustomNotFound'
import { GCAL_USER_STATUS } from '../../../Helpers/constants'

const { Option } = Select
const { ITEM_SKYPE, ITEM_PHONE, ITEM_INTERVIEW_TYPES, ITEM_TIME, ITEM_DATE, ITEM_INTERVIEWERS } = FORM_CONFIGS
const tooltip = <span>You can search an existing user or enter a new email ID to send an invite</span>
const noHangoutsLink = (
    <span>
        If you continue to schedule interview, we will try to create the google meet link if at least one interviewer
        has provided google calendar access.
    </span>
)
const TIME_FORMAT = /^\d{1,2}:\d{2} [APap][mM]$/

class InterviewForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedInterview: null,
            phoneNumber: null,
            skypeId: null,
            hangoutsLink: null,
            selectedAddress: null,
            duration: 30,
            interviewers: [],
            schedules: {},
            availableTimeSlots: {},
            loadingTimeSlots: false,
            selectedDate: null,
            startTime: null,
            hideBusyWarning: true,
            unavailableInterviewers: '',
            viewResume: false,
            loadingHangoutsLink: false,
            showSlots: true,
            endTimeDataSource: times,
            startTimeDataSource: times,
        }

        this._interviewerRef = React.createRef()
    }

    componentDidMount() {
        const { phone, skypeId } = this.props.candidate
        if (phone) {
            this.props.updateParentState({ phone }, false)
        }
        if (skypeId) {
            this.props.updateParentState({ skypeId }, false)
        }
        if (this.props.interview) {
            let interviewerIds = this.props.interview.interviewers
                ? this.props.interview.interviewers.map(interviewerId => interviewerId._id)
                : this.props.interview.interviewerIds.map(interviewerId => interviewerId._id)
            this.setState(
                {
                    interviewers: interviewerIds,
                    loadingTimeSlots: true,
                    selectedDate: moment(this.props.interview.interviewDate),
                },
                () =>
                    this.getAvailableSlots(
                        moment(this.props.interview.interviewDate).isSameOrAfter(moment())
                            ? moment(this.props.interview.interviewDate)
                            : moment()
                    )
            )
            this.props.updateParentState(
                {
                    ...this.props.interview,
                    selectedDate: moment(this.props.interview.interviewDate),
                },
                false
            )

            if (this.props.interview.interviewType === INTERVIEW_TYPES_KEYS.HANGOUTS) {
                const { meetingDetails, interviewEventId } = this.props.interview
                if (meetingDetails && interviewEventId) {
                    this.props.updateParentState(
                        {
                            hangoutsLink: this.props.interview.meetingDetails,
                            eventId: this.props.interview.interviewEventId,
                        },
                        false
                    )
                    this.setState({
                        hangoutsLink:
                            this.props.gCalInUse && this.props.defaultAccount && this.props.interview.meetingDetails,
                    })
                } else {
                    if (this.props.gCalInUse && this.props.defaultAccount) this.generateHangoutsLink()
                }
            } else if (this.props.interview.interviewType === INTERVIEW_TYPES_KEYS.TELEPHONIC) {
                if (this.props.interview.meetingDetails) {
                    this.props.updateParentState(
                        {
                            phone: this.props.interview.meetingDetails,
                        },
                        false
                    )
                    const _phone = parsePhoneNumberFromString(this.props.interview.meetingDetails)
                    if (_phone)
                        this.props.form.setFieldsValue({ countryCode: _phone.country, phone: _phone.nationalNumber })
                }
            }
        }
    }

    phoneValidator = (rule, value, callback) => {
        let countryCode = this.props.form.getFieldValue('countryCode')

        if (value && !countryCode) {
            this.setState({ phone: value })
            return callback('Country code not selected')
        }

        if (value && countryCode) {
            countryCode = countryCodes.find(x => x.code === countryCode).dial_code
            const num = countryCode + value
            const phone = parsePhoneNumberFromString(num)
            this.setState({ phone: num })
            this.props.updateParentState({ phone: num })
            if (!(phone && phone.isValid())) {
                return callback('Invalid phone number')
            }
            this.props.form.setFields({ phone: { value } })
        }
        callback()
    }

    countryCodeValidator = (rule, value, callback) => {
        let _phone = this.props.form.getFieldValue('phone')

        if (value && !_phone) {
            this.props.form.setFields({ phone: { errors: [new Error('Phone number is required')] } })
            return
        }

        if (value && _phone) {
            let countryCode = countryCodes.find(x => x.code === value).dial_code
            const num = countryCode + _phone
            let phone = parsePhoneNumberFromString(num)
            this.setState({ phone })
            this.props.updateParentState({ phone })
            if (!(phone && phone.isValid())) {
                return callback('Invalid phone number')
            }
        }

        this.props.form.setFields({ phone: { value: _phone } })

        callback()
    }

    getSelectedAddress = address => {
        this.setState({ selectedAddress: address })
        this.props.updateParentState({ selectedAddress: address })
    }

    inputFieldForInterviewType = type => {
        const { getFieldDecorator, getFieldValue } = this.props.form
        let _candidate = {}
        let { candidate, interview } = this.props
        if (candidate.phone) {
            const phoneNumber = parsePhoneNumberFromString(candidate.phone)
            if (phoneNumber && phoneNumber.nationalNumber) {
                _candidate.phone = phoneNumber.nationalNumber
                _candidate.countryCode = phoneNumber.country
            }
        }

        if (interview && interview.meetingDetails && interview.interviewType === INTERVIEW_TYPES_KEYS.TELEPHONIC) {
            const phoneNumber = parsePhoneNumberFromString(interview.meetingDetails)
            if (phoneNumber && phoneNumber.nationalNumber) {
                _candidate.phone = phoneNumber.nationalNumber
                _candidate.countryCode = phoneNumber.country
            }
        }

        const prefixSelector = initialValue => (
            <ConfigProvider renderEmpty={() => NotFound('Country Code Not Found')}>
                {getFieldDecorator('countryCode', {
                    initialValue: initialValue,
                    rules: [
                        {
                            required: !!getFieldValue('phone'),
                            message: 'Country code is required',
                        },
                        {
                            validator: this.countryCodeValidator,
                        },
                    ],
                })(
                    <Select
                        showSearch
                        style={{ minWidth: 50, display: 'inline-block' }}
                        dropdownStyle={{ width: 225 }}
                        optionLabelProp="title"
                        optionFilterProp="children"
                        dropdownMatchSelectWidth={false}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={value => {
                            this.props.updateParentState({ countryCode: value })
                        }}
                        filterOption={(input, { props }) =>
                            props.title.indexOf(input) === 0 ||
                            props.name.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                        suffixIcon={' '}
                    >
                        {countryCodes.map(code => (
                            <Select.Option key={code.code} title={code.dial_code} name={code.name}>
                                {code.name} ({code.dial_code})
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </ConfigProvider>
        )

        switch (type) {
            case INTERVIEW_TYPES_KEYS.TELEPHONIC:
                return (
                    <Col md={12} xs={24}>
                        <Form.Item label="Candidate Phone Number">
                            {getFieldDecorator('phone', {
                                initialValue: _candidate.phone,
                                rules: [
                                    {
                                        required: true,
                                        message: ITEM_PHONE.ERROR,
                                    },
                                    { validator: this.phoneValidator },
                                ],
                            })(
                                <Input
                                    addonBefore={prefixSelector(_candidate.countryCode)}
                                    autoComplete="off"
                                    size="large"
                                    placeholder={ITEM_PHONE.HOLDER}
                                    onChange={({ target }) => this.props.updateParentState({ phone: target.value })}
                                />
                            )}
                        </Form.Item>
                    </Col>
                )
            case INTERVIEW_TYPES_KEYS.SKYPE:
                return (
                    <Col md={12} xs={24}>
                        <Form.Item label="Candidate Skype ID">
                            {getFieldDecorator('skypeId', {
                                initialValue:
                                    (interview &&
                                        interview.interviewType === INTERVIEW_TYPES_KEYS.SKYPE &&
                                        interview.meetingDetails) ||
                                    candidate.skypeId,
                                rules: [
                                    {
                                        required: true,
                                        whitespace: false,
                                        message: ITEM_SKYPE.ERROR,
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    autoComplete="off"
                                    placeholder={ITEM_SKYPE.HOLDER}
                                    onChange={({ target }) => this.props.updateParentState({ skypeId: target.value })}
                                />
                            )}
                        </Form.Item>
                    </Col>
                )

            case INTERVIEW_TYPES_KEYS.HANGOUTS:
                return (
                    <Col md={12} className="h-80" xs={24}>
                        <Form.Item label="Google Meet Link">
                            {(this.props.gCalInUse && this.props.defaultAccount) || this.state.hangoutsLink ? (
                                <Input
                                    size="large"
                                    readOnly
                                    value={
                                        (interview &&
                                            interview.interviewType === INTERVIEW_TYPES_KEYS.HANGOUTS &&
                                            interview.meetingDetails) ||
                                        (this.state.loadingHangoutsLink
                                            ? 'Generating Google Meet Link'
                                            : this.state.hangoutsLink === undefined
                                                ? 'Google Meet Link unavailable'
                                                : `https://meet.google.com/${this.state.hangoutsLink}`)
                                    }
                                    onChange={({ target }) =>
                                        this.props.updateParentState({ hangoutsLink: target.value })
                                    }
                                    prefix={
                                        this.state.loadingHangoutsLink ? (
                                            <Icon type="loading" />
                                        ) : this.state.hangoutsLink === undefined ? (
                                            <Icon type="close-circle" theme="twoTone" twoToneColor="#ad2a21" />
                                        ) : (
                                            <TickIcon />
                                        )
                                    }
                                />
                            ) : (
                                <div style={{ marginTop: '7px' }}>
                                    Google calendar access required{' '}
                                    <Tooltip placement="right" title={noHangoutsLink}>
                                        <InfoIcon style={{ verticalAlign: 'middle' }} />
                                    </Tooltip>
                                </div>
                            )}
                        </Form.Item>
                    </Col>
                )

            case INTERVIEW_TYPES_KEYS.F2F:
                return (
                    <Col md={12} xs={24}>
                        <Form.Item label="Select Address">
                            <Address
                                getSelectedAddress={this.getSelectedAddress}
                                updateParentState={this.props.updateParentState}
                                addedAddress={
                                    (this.props.interviewType === INTERVIEW_TYPES_KEYS.F2F &&
                                        this.props.selectedAddress) ||
                                    (this.props.interview &&
                                        this.props.interview.interviewType === INTERVIEW_TYPES_KEYS.F2F &&
                                        this.props.interview.meetingDetails)
                                }
                            />
                        </Form.Item>
                    </Col>
                )
            default:
                return null
        }
    }

    generateHangoutsLink = () => {
        this.setState({ loadingHangoutsLink: true }, async () => {
            if (this.props.gCalInUse && this.props.defaultAccount) {
                if (this.props.defaultAccount.revoked) {
                    this.setState({
                        hangoutsLink: undefined,
                        loadingHangoutsLink: false,
                    })
                    warning(
                        'Access to your default account is revoked. Please reconnect to create Google Meet link with your ID.'
                    )
                } else {
                    const response = await this.props.CreateTemporaryEvent()
                    if (!response.error) {
                        this.setState({
                            hangoutsLink: response.hangoutsId,
                            loadingHangoutsLink: false,
                        })
                        this.props.updateParentState({
                            hangoutsLink: `https://meet.google.com/${response.hangoutsId}`,
                            eventId: response.eventId,
                        })
                    } else {
                        this.setState({
                            hangoutsLink: undefined,
                            loadingHangoutsLink: false,
                        })
                    }
                }
            }
        })
    }

    handleTypeChange = value => {
        this.props.updateParentState({ interviewType: value })
        if (!this.props.isRescheduled) {
            if (value === INTERVIEW_TYPES_KEYS.HANGOUTS && !this.state.hangoutsLink) {
                this.generateHangoutsLink()
            }
        } else {
            if (value === INTERVIEW_TYPES_KEYS.HANGOUTS) {
                if (
                    this.state.hangoutsLink ||
                    (this.props.meetingDetails && this.props.meetingDetails.includes('meet.google.com'))
                )
                    this.props.updateParentState({
                        hangoutsLink: `https://meet.google.com/${this.state.hangoutsLink}` || this.props.meetingDetails,
                    })
                else {
                    this.generateHangoutsLink()
                }
            } else if (value === INTERVIEW_TYPES_KEYS.F2F) {
                this.props.updateParentState({ meetingDetails: this.props.selectedAddress })
            }
        }
        if (value === INTERVIEW_TYPES_KEYS.F2F) {
            this.props.updateParentState({ meetingDetails: this.props.selectedAddress })
        }
    }

    getAvailableSlots = (date = moment()) => {
        let { interviewers, schedules } = this.state
        let activeInterviewers = interviewers.filter(interviewer => {
            const _interviewer = this.props.coordinators.find(coordinator => coordinator._id === interviewer)
            if (_interviewer && _interviewer.status === 1) return interviewer
        })
        let schedulesTobeRemoved = Object.keys(schedules).filter(schedule => !activeInterviewers.includes(schedule))
        schedules = _.omit(schedules, schedulesTobeRemoved)
        this.setState({ schedules }, async () => {
            if (activeInterviewers.length === 0) {
                this.setState({ loadingTimeSlots: false })
            }
            const _schedules = await Promise.all(
                activeInterviewers.map(async aInterviewer => {
                    const response = await this.props.GetAvailableTimeSlots(aInterviewer, date.format())
                    if (response.error) warning(response.error)
                    return {
                        [aInterviewer]: response.slots,
                    }
                })
            )
            let { schedules, duration } = this.state
            for (let index = 0; index < _schedules.length; index++) {
                let actInt = Object.keys(_schedules[index])[0]
                schedules = { ...schedules, [actInt]: _schedules[index][actInt] }
            }
            let availableTimeSlots = getAvailableTimeSlots(schedules, duration)
            this.setState({ availableTimeSlots, loadingTimeSlots: false, schedules })
        })
    }

    getUnavailableInterviewers = (start, end) => {
        const { selectedDate, interviewers, schedules } = this.state
        if (!selectedDate) return ''
        let date = `${moment(selectedDate).format('YYYY-MM-DD')}T00:00:00.000Z`
        let unavailableInterviewers = interviewers
            .map(interviewer => {
                if (_.has(schedules[interviewer], date)) {
                    let availability = schedules[interviewer][date]
                    let available = false
                    availability.forEach(_availability => {
                        let s = moment(_availability.start)
                        let e = moment(_availability.end)
                        let st = moment(start, timeFormat)
                            .date(s.date())
                            .month(s.month())
                            .year(s.year())
                        let et = moment(end, timeFormat)
                            .date(e.date())
                            .month(e.month())
                            .year(e.year())
                        available = available || (st.isBetween(s, e, null, '[]') && et.isBetween(s, e, null, '[]'))
                    })
                    return available ? null : interviewer
                } else {
                    return interviewer
                }
            })
            .filter(value => value !== null)
        if (unavailableInterviewers.length > 0) {
            let names = unavailableInterviewers
                .map(interviewer => {
                    const _interviewer = this.props.coordinators.find(member => member._id === interviewer)
                    return (_interviewer && _interviewer.fullName) || 'Interviewer'
                })
                .filter(interviewer => interviewer !== 'Interviewer')
            if (names.length > 0) return [...new Set(names)].join(', ')
            else return ''
        } else {
            return ''
        }
    }

    handleDurationChange = value => {
        this.setState({ duration: value, showSlots: true }, () => {
            if (this.state.interviewers.length !== 0) {
                this.setState({ loadingTimeSlots: true, availableTimeSlots: {} }, () => {
                    let availableTimeSlots = getAvailableTimeSlots(this.state.schedules, this.state.duration)
                    this.setState({ availableTimeSlots, loadingTimeSlots: false })
                    this.props.form.setFields({
                        startTime: { value: undefined },
                        endTime: { value: undefined },
                    })
                })
            }
        })
    }

    handleDateSelect = value => {
        this.props.form.setFieldsValue({ date: value, startTime: undefined, endTime: undefined })
        this.props.updateParentState({ startTime: undefined, endTime: undefined })
        this.setState({ selectedDate: value, showSlots: true }, () => {
            this.props.updateParentState({ selectedDate: value })
            if (this.state.interviewers.length !== 0) {
                this.setState({ loadingTimeSlots: true, availableTimeSlots: {} }, () => {
                    let availableTimeSlots = getAvailableTimeSlots(this.state.schedules, this.state.duration)
                    this.setState({
                        availableTimeSlots,
                        loadingTimeSlots: false,
                        hideBusyWarning: true,
                    })
                })
            }
        })
    }

    handleInterviewerChange = interviewers => {
        if (interviewers.length <= ITEM_INTERVIEWERS.MAX) {
            if (interviewers.length === 0) {
                this.props.form.setFieldsValue({ startTime: undefined, endTime: undefined })
                this.setState({
                    interviewers,
                    availableTimeSlots: {},
                    schedules: {},
                    unavailableInterviewers: '',
                    hideBusyWarning: true,
                })
            } else
                this.setState(
                    {
                        interviewers,
                        loadingTimeSlots: true,
                        availableTimeSlots: {},
                        hideBusyWarning: true,
                        unavailableInterviewers: '',
                    },
                    this.getAvailableSlots
                )
            this.props.updateParentState({ interviewers })
        } else warning(ITEM_INTERVIEWERS.ERROR_NOTI_MAX, null, { style: { bottom: 30 } })
    }

    handleTimeSlotSelection = slot => {
        const [startTime, endTime] = slot.split('-')
        let endTimeStartIndex = times.findIndex(time => time === startTime) + 1
        this.setState(
            {
                startTime,
                endTime,
                hideBusyWarning: true,
                unavailableInterviewers: '',
                reShow: undefined,
                endTimeDataSource: times.slice(endTimeStartIndex),
                startTimeDataSource: times,
            },
            () => {
                this.props.form.setFieldsValue({ startTime, endTime })
                this.props.updateParentState({ startTime, endTime })
            }
        )
    }

    getNextTime = (time, after = 0) => {
        let nextTime = moment(time, timeFormat).add(after, 'minutes')
        let nextMin = nextTime.minutes()
        if (nextMin > 0 && nextMin < 15) nextTime.minutes(0)
        else if (nextMin > 15 && nextMin < 30) nextTime.minutes(15)
        else if (nextMin > 30 && nextMin < 45) nextTime.minutes(30)
        else if (nextMin > 45) nextTime.minutes(45)
        return nextTime
    }

    handleStartChange = newStartTime => {
        const endTime = moment(newStartTime, timeFormat)
            .add(this.state.duration, 'minutes')
            .format(timeFormat)
        let unavailableInterviewers = this.getUnavailableInterviewers(newStartTime, endTime)
        let nextTime = this.getNextTime(endTime)
        let endTimeStartIndex = times.findIndex(time => time === nextTime.format(timeFormat)) + 1
        this.setState({
            startTime: newStartTime,
            endTime,
            unavailableInterviewers,
            hideBusyWarning: unavailableInterviewers === '',
            reShow: unavailableInterviewers !== '' ? true : undefined,
            endTimeDataSource: times.slice(endTimeStartIndex),
        })
        this.props.form.setFieldsValue({ endTime })
        this.props.updateParentState({ startTime: newStartTime, endTime })
    }

    handleEndChange = newEndtime => {
        const { startTime } = this.state
        let unavailableInterviewers = this.getUnavailableInterviewers(startTime, newEndtime)
        this.setState(
            {
                endTime: newEndtime,
                unavailableInterviewers,
                hideBusyWarning: unavailableInterviewers === '',
            },
            () => {
                this.setState({ duration: 30 })
            }
        )
        this.props.form.setFieldsValue({ duration: 30 })
        this.props.updateParentState({ endTime: newEndtime })
    }

    timeValidator = (rule, value, callback) => {
        const startTime = this.props.form.getFieldValue('startTime')
        const endTime = this.props.form.getFieldValue('endTime')
        const date = this.props.form.getFieldValue('date')

        if (date && moment().isSame(date, 'day') && moment(startTime, timeFormat).diff(moment()) <= 0) {
            callback(ITEM_TIME.ERROR)
        } else if (moment(endTime, timeFormat).diff(moment(startTime, timeFormat)) <= 0) {
            callback(ITEM_TIME.ERROR)
        } else {
            let errors = this.props.form.getFieldsError(['startTime', 'endTime'])
            if (errors['startTime']) {
                this.props.form.setFieldsValue({ startTime })
            }
            if (errors['endTime']) {
                this.props.form.setFieldsValue({ endTime })
            }
            callback()
        }
    }

    searchStartTime = value => {
        let startTimeDataSource = times.filter(time => time.startsWith(value))
        if (startTimeDataSource.length === 0) {
            startTimeDataSource = times
            if (TIME_FORMAT.test(value)) {
                value = moment(value, timeFormat).format(timeFormat)
                this.handleStartChange(value)
            } else {
                this.props.form.setFields({ startTime: { errors: [ITEM_TIME.ERROR] } })
            }
        } else if (TIME_FORMAT.test(value)) {
            this.handleStartChange(value)
        }
        this.setState({ startTimeDataSource })
    }

    searchEndTime = value => {
        if (TIME_FORMAT.test(value)) {
            value = moment(value, timeFormat).format(timeFormat)
            let endTimeDataSource = times
            if (this.state.startTime && !times.includes(value)) {
                let nextTime = this.getNextTime(this.state.startTime, this.state.duration)
                endTimeDataSource = times.slice(times.findIndex(time => time === nextTime.format(timeFormat)) + 1)
            } else if (times.includes(value)) {
                endTimeDataSource = times.filter(time => time.startsWith(value))
                if (this.state.startTime) {
                    endTimeDataSource = times
                        .slice(times.findIndex(time => time === this.state.startTime) + 1)
                        .filter(time => time.startsWith(value))
                } else {
                    endTimeDataSource = times.slice(times.findIndex(time => time === this.state.startTime) + 1)
                }
            }
            this.setState({ endTimeDataSource })
            this.props.updateParentState({ endTime: value })
        } else {
            this.props.form.setFields({ endTime: { errors: [ITEM_TIME.ERROR] } })
        }
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form
        const { candidate, jobDetail, interview } = this.props
        if (interview && interview.interviewType && interview.interviewType === 'HANGOUTS') {
            interview.interviewType = 'Google Meet'
        }
        return (
            <FormContainer>
                <Interviewee>
                    <div>
                        <Avatar>{getAvatar(candidate.name || 'Candidate')}</Avatar>
                        <div>
                            <Name>{candidate.name || 'Candidate'}</Name>
                            <Detail className="openingTitleWrapper">
                                <Key>Job opening: </Key>
                                <Value className="openingTitle">{jobDetail && jobDetail.jobTitle}</Value>
                            </Detail>
                            <Detail>
                                <Key>Stage: </Key> <Value>{jobDetail && jobDetail.stageName}</Value>
                            </Detail>
                        </div>
                    </div>
                    {candidate && candidate.candidateResumeLink && (
                        <Button onClick={() => this.setState({ viewResume: true })}>View Resume</Button>
                    )}
                </Interviewee>
                <Form>
                    <Row gutter={24}>
                        <Col md={12} xs={24}>
                            <Form.Item label="Type of Interview">
                                {getFieldDecorator('interviewType', {
                                    initialValue: (interview && interview.interviewType) || undefined,
                                    rules: [{ required: true, whitespace: true, message: ITEM_INTERVIEW_TYPES.ERROR }],
                                })(
                                    <Select
                                        size="large"
                                        placeholder={ITEM_INTERVIEW_TYPES.HOLDER}
                                        onChange={value => this.handleTypeChange(value)}
                                        dropdownClassName="interview-types"
                                        className="interview-select"
                                        getPopupContainer={trigger => trigger.parentNode}
                                        suffixIcon={<Icon component={DownIcon} />}
                                    >
                                        {INTERVIEW_TYPES_ICONS_CONDITIONAL_SWITCH(this.props.isZoomEnabled).map(
                                            option => (
                                                <Option key={option.type} value={option.type}>
                                                    {option.icon} {option.name}
                                                </Option>
                                            )
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        {this.inputFieldForInterviewType(getFieldValue('interviewType'))}
                    </Row>
                    <Form.Item htmlFor="label" className="interviewSection">
                        <label id="info">
                            <span>Interviewer(s)</span>
                            <Tooltip placement="top" title={tooltip} overlayClassName="fs-10">
                                <InfoIcon />
                            </Tooltip>
                        </label>
                        <Interviewer
                            wrappedComponentRef={this._interviewerRef}
                            handleInterviewerChange={this.handleInterviewerChange}
                            interviewers={this.state.interviewers}
                        />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col md={12} xs={24}>
                            <Form.Item label="Date">
                                {getFieldDecorator('date', {
                                    initialValue: interview
                                        ? moment(moment(interview.interviewDate).format('DD MMM YYYY'))
                                        : this.state.selectedDate !== null
                                            ? moment(this.state.selectedDate, 'DD MMM YYYY')
                                            : null,
                                    rules: [{ required: true, message: ITEM_DATE.ERROR }],
                                })(
                                    <DatePicker
                                        disabledDate={current => current.isBefore(moment().subtract(1, 'day'))}
                                        size="large"
                                        format="DD MMM YYYY"
                                        showToday={false}
                                        renderExtraFooter={() => {
                                            return this.state.loadingTimeSlots ? (
                                                <Footer>Loading availability</Footer>
                                            ) : _.isEmpty(this.state.availableTimeSlots) ? (
                                                <Footer>Select interviewers to show available days</Footer>
                                            ) : (
                                                <Footer>
                                                    <Indicator /> indicates dates on which the interviewer is available
                                                </Footer>
                                            )
                                        }}
                                        onChange={this.handleDateSelect}
                                        getCalendarContainer={trigger => trigger.parentNode}
                                        dateRender={(current, today) => {
                                            const style = {
                                                color: current.month() === today.month() ? '#7c86a2' : '#e1e4e7',
                                                fontSize: '14.5px',
                                            }
                                            if (current.month() === today.month()) {
                                                if (
                                                    _.has(
                                                        this.state.availableTimeSlots,
                                                        current.startOf('day').format()
                                                    )
                                                ) {
                                                    if (current.date() === today.date()) {
                                                        style.backgroundColor = '#0091ff'
                                                        style.color = '#ffffff'
                                                        style.border = '1px solid #0091ff'
                                                        style.borderRadius = '50%'
                                                    } else {
                                                        style.color = '#0091ff'
                                                    }
                                                } else {
                                                    if (current.date() === today.date()) {
                                                        style.color = '#7c86a2'
                                                        style.backgroundColor = '#ffffff'
                                                        style.border = '1px solid #7c86a2'
                                                        style.borderRadius = '50%'
                                                    }
                                                }
                                            }
                                            return (
                                                <div className="ant-calendar-date" style={style}>
                                                    {current.date()}
                                                </div>
                                            )
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item label="Duration">
                                {getFieldDecorator('duration', {
                                    initialValue: 30,
                                })(
                                    <Select
                                        size="large"
                                        suffixIcon={<Icon component={DownIcon} />}
                                        onChange={this.handleDurationChange}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        <Option value={15}>15 mins</Option>
                                        <Option value={30}>30 mins</Option>
                                        <Option value={45}>45 mins</Option>
                                        <Option value={60}>1 hr</Option>
                                        <Option value={120}>2 hrs</Option>
                                        <Option value={180}>3 hrs</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Available time slots">
                        <Slots
                            loading={this.state.selectedDate && this.state.loadingTimeSlots}
                            timeSlots={
                                this.state.selectedDate && !_.isEmpty(this.state.availableTimeSlots)
                                    ? this.state.availableTimeSlots[this.state.selectedDate.startOf('day').format()]
                                    : null
                            }
                            handleTimeSlotSelection={this.handleTimeSlotSelection}
                            interviewers={this.state.interviewers}
                            selectedDate={this.state.selectedDate}
                            showSlots={this.state.showSlots}
                            reShow={this.state.reShow}
                        />
                    </Form.Item>
                    <Row gutter={24} className="times">
                        <Col md={12} xs={24}>
                            <Form.Item label="Start Time">
                                {getFieldDecorator('startTime', {
                                    validateFirst: true,
                                    initialValue:
                                        (interview && moment(interview.interviewStartTime).format(timeFormat)) ||
                                        undefined,
                                    rules: [
                                        { required: true, message: 'Start time is required' },
                                        { validator: this.timeValidator },
                                    ],
                                })(
                                    <AutoComplete
                                        size="large"
                                        onSelect={this.handleStartChange}
                                        onSearch={this.searchStartTime}
                                        dataSource={this.state.startTimeDataSource}
                                        placeholder={'Select start time'}
                                        dropdownMenuStyle={{ height: '150px' }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item label="End Time">
                                {getFieldDecorator('endTime', {
                                    validateFirst: true,
                                    initialValue:
                                        (interview &&
                                            interview.interviewEndTime &&
                                            moment(interview.interviewEndTime).format(timeFormat)) ||
                                        undefined,
                                    rules: [
                                        { required: true, message: 'End time is required' },
                                        { validator: this.timeValidator },
                                    ],
                                })(
                                    <AutoComplete
                                        size="large"
                                        onSelect={this.handleEndChange}
                                        onSearch={this.searchEndTime}
                                        dataSource={this.state.endTimeDataSource}
                                        placeholder={'Select end time'}
                                        dropdownMenuStyle={{ height: '150px' }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Warning hidden={this.state.hideBusyWarning}>
                        <WarningIcon />
                        {this.state.unavailableInterviewers} may be busy at this time.
                    </Warning>
                </Form>

                <Modal
                    visible={this.state.viewResume}
                    bodyStyle={{ padding: 0 }}
                    width={'90%'}
                    footer={false}
                    closable={false}
                    centered
                    destroyOnClose={true}
                >
                    {this.state.viewResume ? (
                        <Resume
                            candidateResumeLink={candidate.candidateResumeLink}
                            onCancel={() => this.setState({ viewResume: false })}
                        />
                    ) : null}
                </Modal>
            </FormContainer>
        )
    }
}

const mapStateToProps = state => {
    const { inUse, accounts } = state.GCal
    return {
        gCalInUse: inUse || null,
        defaultAccount:
            (accounts && _.values(accounts).find(account => account.status === GCAL_USER_STATUS.DEFAULT)) || null,
        userId: state.Auth.user.id,
        coordinators: state.Mixed.teamMembers,
    }
}

export default connect(
    mapStateToProps,
    { GetAvailableTimeSlots, CreateTemporaryEvent }
)(Form.create({ name: 'interviewForm' })(InterviewForm))
