import React from 'react'
import './style.scss'

export class PreLoader extends React.Component {
    render() {
        return (
            <div
                className="page"
                style={{
                    opacity: this.props.startAnimation ? 0 : 1,
                    transition: 'opacity 500ms linear 100ms',
                }}
            >
                <div className="loader">
                    <div class="loader__bar" />
                    <div class="loader__bar" />
                    <div class="loader__bar" />
                    <div class="loader__bar" />
                    <div class="loader__bar" />
                    <div class="loader__ball" />
                </div>
            </div>
        )
    }
}
