import React, { useState } from 'react'
import { connect } from 'react-redux'
import { StyledDropzone } from '../../../Components/StyledDropzone'
import { ALLOWED_FILE_FORMATS } from '../../../Helpers/fileTypeValidation'
import { GetResumeDetails } from '../../../../services/NetworkCalls/Candidate'
import { JobStageSelector, onDropBulkMode } from './HelperComponents'

const BulkMode = ({ GetResumeDetails, allJobs, jobId, accessLevel, jobDetails }) => {
    const [selectedJob, setSelectedJob] = useState(jobId || undefined)
    const [selectedStage, setSelectedStage] = useState(undefined)
    const [dropError, setDropError] = useState(false)

    const { stages = [] } = (selectedJob && allJobs.find(job => job._id === selectedJob)) || {}

    const calculatedStage = selectedStage || (stages.length && stages[0]._id) || undefined

    return (
        <>
            <StyledDropzone
                onDrop={onDropBulkMode({
                    GetResumeDetails,
                    calculatedStage,
                    selectedJob,
                    setDropError,
                })}
                multiple
                error={dropError}
                height={145}
                accept={ALLOWED_FILE_FORMATS.CANDIDATE.join(', ')}
            />
            <JobStageSelector
                selectedJob={selectedJob}
                jobId={jobId}
                setSelectedJob={setSelectedJob}
                allJobs={allJobs}
                calculatedStage={calculatedStage}
                setSelectedStage={setSelectedStage}
                stages={stages}
                accessLevel={accessLevel}
                jobDetails={jobDetails}
            />

            <div className="help">Select these two fields to set default value for all profiles</div>
        </>
    )
}

export default connect(
    ({ Job, Auth }) => ({
        allJobs: (Job.allJobs && Job.allJobs.filter(each => each.status)) || [],
        accessLevel: Auth.user.accessLevel,
    }),
    { GetResumeDetails }
)(BulkMode)
