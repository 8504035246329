import { store } from '../../redux/store'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { GetCandidatesPhoneNumber, ValidateEmailAddress } from '../../services/api'
import { warning } from '../../services/Notification'
import { LogE } from './errorHandler'
import { countryCodes } from './countryCode'
import querystring from 'querystring'
import moment from 'moment'
// NOTE: (validateJobId) function uses redux store

export const randomString = () =>
    '_' +
    Math.random()
        .toString(36)
        .substr(2, 9)

export const findNextEmpty = (arr, start) => {
    for (let each = start; each < arr.length; each++) {
        if (!arr[each].isProfileCompleted) return { cData: arr[each], index: each }
    }
    return { cData: null, index: null }
}

// TODO : can make it O(1) in space and time, using for loop
export const isObjectEmpty = obj => {
    if (obj === null || obj === undefined) return true
    return Object.keys(obj).length === 0
}

export const checkObjectSize = (obj, size) => {
    if (obj === null || obj === undefined) return true
    return Object.keys(obj).length === size
}

// This is only used to map hitData(from Algolia) according to our selected data fields
export const arrayToObject = (array, key, elem) =>
    array.reduce((obj, item) => {
        obj[item[key]] = {
            candidateId: item.objectID,
            unassigned: item.unassigned,
            jobApplicationId: item.jobApplicationId,
            _id: item.candidateId,
            name: item.name,
            tags: item.tags || [],
        }
        return obj
    }, {})

export const getZeroArray = size => Array.from(Array(size), () => 0)

// an array of object converted to array of key value
// [{name:"john",age:23} , {name:"rich",age:23}] ==> (key:name) ==> ["john","rich"]
export const objectKeyExtractAsArray = (obj, key) => {
    // used for state
    return Object.values(obj).map(each => each[key])
}

export const validateJobId = jobId => {
    if (jobId == null) return null
    const { allJobs } = store.getState().Job || []
    const found = (allJobs.length && allJobs.find(job => job._id === jobId)) || {}
    if (!isObjectEmpty(found)) {
        return found.status ? jobId : null
    }
    return null
}

export const toCustomFirstCharCap = string => {
    const temp = string.slice(0, 3)
    // only handling F2f to F2F
    if (temp === 'F2F') {
        string = string.slice(3)
        return 'F2F' + string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const isObject = item => {
    if (item === null || item === undefined) return false
    if (typeof item === 'object') return !Array.isArray(item) // it can be array or object
    return false
}

export const formatFileName = (name, maxLen) => {
    if (name.length < maxLen) return name

    const ext = name.split('.').pop()
    const balanceLen = maxLen - ext.length
    return (
        name.substring(0, balanceLen / 2 - 2) +
        '...' +
        name.substring(name.length - ext.length - 5, name.length - ext.length - 1) +
        '.' +
        ext
    )
}

export const phoneValidator = (getFieldValue, setFields, phoneNo = null, index = null, options = {}) => async (
    rule,
    value,
    callback
) => {
    try {
        const { company, jobId } = options

        let companyId = null

        if (store.getState() && store.getState().Auth && store.getState().Auth.user) {
            companyId = store.getState().Auth.user.companyId
        } else if (company) {
            companyId = company._id
        }

        const [phoneField, ccField] =
            rule.field === 'phone' || rule.field === 'countryCode'
                ? ['phone', 'countryCode']
                : [`value[${index}].phone`, `value[${index}].countryCode`]

        if (rule.field === phoneField) {
            let countryCode = getFieldValue(ccField)

            if (value && !countryCode) {
                return callback('Country code not selected')
            }

            if (value && countryCode) {
                countryCode = countryCodes.find(x => x.code === countryCode).dial_code
                const num = countryCode + value
                const phoneNumber = parsePhoneNumberFromString(num)
                if (!(phoneNumber && phoneNumber.isValid())) {
                    return callback('Invalid phone number')
                } else {
                    if (companyId) {
                        const res = await GetCandidatesPhoneNumber({ phone: phoneNumber.number, companyId, jobId })
                        if (res.isPresent && phoneNumber.number !== phoneNo) {
                            return callback('Phone number is already present')
                        }
                    }
                }
            }
            callback()
        } else if (rule.field === ccField) {
            let phone = getFieldValue(phoneField)
            if (value && phone) {
                value = countryCodes.find(x => x.code === value).dial_code
                const num = value + phone
                const phoneNumber = parsePhoneNumberFromString(num)
                if (!(phoneNumber && phoneNumber.isValid())) {
                    setFields(phoneField, phone, [new Error('Invalid phone number')])
                    return
                } else {
                    if (companyId) {
                        const res = await GetCandidatesPhoneNumber({ phone: phoneNumber.number, companyId, jobId })
                        if (res.isPresent && phoneNumber.number !== phoneNo) {
                            setFields(phoneField, phone, [new Error('Phone number is already present')])
                            return
                        } else {
                            setFields(phoneField, phone, null)
                            return
                        }
                    } else {
                        setFields(phoneField, phone, null)
                        return
                    }
                }
            }
        }
    } catch (err) {
        warning(err.message || err)
        LogE('helpers-utils-phone-validator', '', err)
    }
}

export const emailValidator = (getFieldsValue, setFields, email = null, index = null, options = {}) => async (
    rule,
    value,
    callback
) => {
    const domainName = value.split('@')[1]
    const res = await ValidateEmailAddress(domainName)
    if (res.data && res.data.disposable === "true") {
        return callback('Please enter a valid email address!')
    }
    callback()
}

export const phoneValidatorWithoutForm = async value => {
    const companyId = store.getState().Auth.user.companyId
    const phoneNumber = parsePhoneNumberFromString(value)
    if (!(phoneNumber && phoneNumber.isValid())) {
        return 'Phone number is invalid'
    } else {
        const res = await GetCandidatesPhoneNumber({ phone: phoneNumber.number, companyId })
        if (res.isPresent) {
            return 'Phone number is already present'
        }
    }
    return null
}

export const utmBuilder = ({ campaign = '', source = '', medium = '' }) => {
    return querystring.stringify({
        utm_campaign: campaign,
        utm_source: source,
        utm_medium: medium,
    })
}

export const humaniseMoment = (date = moment(), customFormat = 'D MMM YYYY') => {
    return moment(date).calendar(null, {
        lastWeek: customFormat,
        lastDay: customFormat,
        sameDay: '[Today]',
        nextDay: customFormat,
        nextWeek: customFormat,
        sameElse: customFormat,
    })
}

export const extractSocialsUsername = (urlLink, generateLink) => {
    try {
        if (!urlLink) return null
        let socialLink = urlLink
        if (generateLink) {
            const starting = urlLink.substring(0, 4)
            if (starting === 'http') socialLink = urlLink
            // already full url
            else if (starting === 'www.') socialLink = `https://${urlLink}`
            else if (starting === 'gith' || starting === 'link') socialLink = `https://www.${urlLink}`
            else socialLink = urlLink
        }

        const endsWithSlash = urlLink[urlLink.length - 1] === '/'
        const splitedLink = urlLink.split('/')
        const userNameIndex = (endsWithSlash ? splitedLink.length - 1 : splitedLink.length) - 1
        return { socialName: splitedLink[userNameIndex], socialLink }
    } catch (e) {
        return { socialName: urlLink, socialLink: urlLink }
    }
}

export const getPercentage = (num, den) => {
    if (num === 0 || den === 0) return 0
    let percentage = (num / den) * 100
    if (!Number.isInteger(percentage)) percentage = percentage.toFixed(1)
    return percentage
}

export const getNumberOfDays = (hours, suffix = true) => {
    const days = Math.floor(hours / 24 / 0.25) * 0.25
    if (suffix) {
        if (days > 1) return `${days} days`
        else return `${days} day`
    }
    return days
}
