import styled from 'styled-components'

export const Button = styled.button`
    height: 40px;
    width: 90px;
    background-color: #4767a0;
    color: #fff;
    font-family: sanFrancisco;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: none;
    cursor: pointer;
`

export const Description = styled.div`
    word-break: break-word;
    font-family: sanFranciscoMedium;
    color: #333;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
`

export const Logo = styled.img.attrs({ src: props => props.src })`
    height: 20%;
`

export const CompanyName = styled.div`
    font-family: SFProText-Heavy;
    font-size: 36px;
    font-weight: 900;
    color: #333;
    text-transform: uppercase;
`

export const Content = styled.div`
    height: 40%;
    position: absolute;
    bottom: 10%;
    width: 368px;
    justify-content: space-between;
    text-align: center;
    padding-right: 5vmin
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
`
