module.exports = data => {
    if (!data) {
        return ''
    }

    const firstInitial = data.charAt(0).toUpperCase()
    const lastSpaceIndex = data.lastIndexOf(' ')
    let secondInitial = data.charAt(1).toUpperCase()
    if (lastSpaceIndex > 0) {
        secondInitial = data.charAt(lastSpaceIndex + 1).toUpperCase()
    }

    return `${firstInitial}${secondInitial}`
}
