export const emailDomainExtractor = email => {
    if (email) return email.split('@')[1].toLowerCase()
    return ''
}

export const websiteDomainExtractor = web => {
    if (web)
        return web
            .replace('http://', '')
            .replace('https://', '')
            .replace('www.', '')
            .split(/[/?#]/)[0]
            .toLowerCase()
    return ''
}

export const websiteDomainResolve = link => {
    if (link) return link.split('.')[0].toLowerCase()
    return ''
}

export const areDomainSame = (email, web) => {
    if (!!email && !!web) {
        return emailDomainExtractor(email) === websiteDomainExtractor(web)
    }
    return false
}
