import { actions } from './actions'
import { PURGE } from 'redux-persist'
import { EVENTS, FILTER_TYPES } from '../../container/Pages/Sourcing/constants'

const initState = {
    applications: null,
    callInProgress: true,
    currentPage: 0,
    hasNextPage: false,
    countData: {},
    interestedJobs: [],
    assignedJobs: [],
    skills: [],
    tags: [],
    locations: [],
    mainFilter: FILTER_TYPES.ALL,
}

const resolveApplicationFieldName = (type, countData, value, totalCount) => {
    const result = { fieldName: null, newCountData: countData }
    if (type === EVENTS.ADD_JOB) {
        result.fieldName = 'assignedJob'
        result.newCountData.assignedJob = countData.assignedJob += totalCount
        result.newCountData.unassignedJob = countData.unassignedJob -= totalCount
        //
    } else if (type === EVENTS.MARK_FAV) {
        result.fieldName = FILTER_TYPES.FAVOURITE
        result.newCountData.favourite = value
            ? (countData.favourite += totalCount)
            : (countData.favourite -= totalCount)
        //
    } else if (type === EVENTS.HIDE_CANDIDATE) {
        result.fieldName = FILTER_TYPES.HIDDEN
        result.newCountData[FILTER_TYPES.HIDDEN] = value
            ? (countData[FILTER_TYPES.HIDDEN] += totalCount)
            : (countData[FILTER_TYPES.HIDDEN] -= totalCount)
    }
    return result
}

export default function sTalentPoolReducer(state = initState, action) {
    const { payload, type } = action

    switch (type) {
        case PURGE:
            return initState

        case actions.SET_CALL_IN_PROGRESS:
            return { ...state, callInProgress: true }

        case actions.GET_APPLICATIONS: {
            const {
                applications,
                countData,
                skills,
                tags,
                interestedJobs,
                assignedJobs,
                mainFilter,
                currentPage,
                hasNextPage,
                dataChange,
                locations,
            } = payload
            return {
                ...state,
                callInProgress: false,
                applications:
                    !dataChange && state.applications ? [...state.applications, ...applications] : applications,
                skills,
                tags,
                interestedJobs,
                assignedJobs,
                countData: { ...state.countData, ...countData },
                mainFilter,
                currentPage,
                hasNextPage,
                locations,
            }
        }

        case actions.UPDATE_APPLICATIONS: {
            const { update, ids, data, totalCount = 1 } = payload
            let applications = Object.assign([], state.applications)
            let countData = Object.assign({}, state.countData)
            // INFO: this ensures time complexity is always O(n)
            const applicationIndexObject = {}

            applications.forEach((app, index) => (applicationIndexObject[app._id] = index))

            const updateApplication = () => {
                ids.forEach(id => {
                    const location = applicationIndexObject[id]
                    const { fieldName, newCountData } = resolveApplicationFieldName(update, countData, data, 1)
                    applications[location][fieldName] = data
                    countData = newCountData
                })
            }

            if (state.mainFilter === FILTER_TYPES.ALL && (update === EVENTS.ADD_JOB || update === EVENTS.MARK_FAV)) {
                updateApplication()

                // ADD to job opening
                // } else if (state.mainFilter === FILTER_TYPES.UNASSIGNED && update !== EVENTS.ADD_JOB) {
                //     updateApplication()
            } else if (state.mainFilter === FILTER_TYPES.HIDDEN && update !== EVENTS.HIDE_CANDIDATE) {
                updateApplication()
            } else if (state.mainFilter === FILTER_TYPES.FAVOURITE && update !== EVENTS.MARK_FAV) {
                updateApplication()
            } else {
                const { newCountData } = resolveApplicationFieldName(update, countData, data, totalCount)
                countData = newCountData
                applications = applications.filter(application => !ids.includes(application._id))
            }
            return { ...state, applications, countData, callInProgress: false }
        }

        case actions.UPDATE_TAGS: {
            const { addedTags, deletedTags, universalCandidateIds } = payload
            const deletedTagsArray = deletedTags.map(tag => tag.tagId)
            const applications = Object.assign([], state.applications)

            // INFO: this ensures time complexity is always O(n)
            const applicationIndexObject = {}
            applications.forEach((app, index) => (applicationIndexObject[app._id] = index))

            universalCandidateIds.forEach(id => {
                const location = applicationIndexObject[id]
                let tags = applications[location].tags || []
                tags = tags.filter(tag => !deletedTagsArray.includes(tag.tagId))
                applications[location].tags = [...tags, ...addedTags.slice(0, 15 - tags.length)]
            })

            return { ...state, applications }
        }

        default:
            return { ...state }
    }
}
