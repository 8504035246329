import actions from './actions'
import { PURGE } from 'redux-persist'

const initState = { allJobs: [] }

export default function jobReducer(state = initState, action) {
    switch (action.type) {
        case PURGE:
            return initState
        case actions.SET_ALL_JOBS:
            return { ...state, allJobs: action.jobs }

        case actions.ADD_JOB: {
            state.allJobs.push({
                _id: action.job._id,
                status: 1,
                jobTitle: action.job.jobTitle,
                stages: action.stages,
                feedbackForm: action.job.feedbackForm,
            })
            return state
        }

        case actions.ADD_STAGE: {
            state.allJobs = state.allJobs.map(job => {
                if (job._id === action.stage.jobId) {
                    job.stages.splice(action.stage.stagePosition - 1, 0, action.stage)
                }
                return job
            })
            return state
        }

        case actions.DELETE_JOB: {
            return state
        }

        case actions.DELETE_STAGE: {
            state.allJobs = state.allJobs.map(job => {
                if (job._id === action.jobId) {
                    job.stages = job.stages.filter(stage => stage._id !== action.stageId)
                }
                return job
            })
            return state
        }

        case actions.UPDATE_JOB: {
            const updatedJob = action.job
            state.allJobs = state.allJobs.map(job => {
                if (job._id === updatedJob._id) {
                    // testing the availability of the field to update then updating, thus entire data is not to needed be passed to update a job
                    if (updatedJob.hasOwnProperty('jobTitle')) job.jobTitle = updatedJob.jobTitle

                    if (updatedJob.hasOwnProperty('status')) job.status = updatedJob.status

                    if (updatedJob.hasOwnProperty('feedbackForm')) job.feedbackForm = updatedJob.feedbackForm
                }
                return job
            })
            return state
        }

        case actions.UPDATE_STAGE: {
            state.allJobs = state.allJobs.map(job => {
                if (job._id === action.jobId) {
                    job.stages = action.stages.map(stage => ({
                        _id: stage.stageId,
                        stageName: stage.stageName,
                    }))
                }
                return job
            })
            return state
        }

        case actions.UPDATE_JOBS:
            let updateJobs = state.allJobs.filter(job => job._id != action.data)
            return { ...state, allJobs: updateJobs }
        default:
            return state
    }
}
