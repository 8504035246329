import { warning } from '../../../services/Notification'
import apiCaller from '../../apiCaller'
import { LogE } from '../../../container/Helpers/errorHandler'

const { restricted } = apiCaller.type
const baseUrl = 'report/'

const ANALYTICS_API_DETAILS = id => ({
    GetCandidatesOverviewData: {
        key: 'getCandidatesOverviewData',
        url: `candidate/overview/${id}`,
        type: 'GET',
    },
    GetCandidatesChartData: {
        key: 'getCandidatesChartData',
        url: `candidate/overview/table/${id}`,
        type: 'GET',
    },
    GetCandidatesTags: {
        key: 'getCandidatesTags',
        url: `candidate/tags/all/${id}`,
        type: 'POST',
    },
    GetProgressData: {
        key: 'getProgressData',
        url: 'candidate/progress/box',
        type: 'POST',
    },
    GetProgressJobsData: {
        key: 'getProgressJobsData',
        url: 'candidate/progress/job',
        type: 'POST',
    },
    GetProgressSourceData: {
        key: 'getProgressSourceData',
        url: 'candidate/progress/source',
        type: 'POST',
    },
    GetProgressMonthData: {
        key: 'getProgressMonthData',
        url: 'candidate/progress/month',
        type: 'POST',
    },
    GetProgressTagsData: {
        key: 'getProgressTagsData',
        url: 'candidate/progress/tag',
        type: 'POST',
    },
    GetTsaData: {
        key: 'getTsaData',
        url: 'candidate/tsa/box',
        type: 'POST',
    },
    GetTsaJobsData: {
        key: 'getTsaJobsData',
        url: `candidate/tsa/job/${id}`,
        type: 'POST',
    },
    GetTsaSourceData: {
        key: 'getTsaSourceData',
        url: `candidate/tsa/source/${id}`,
        type: 'POST',
    },
    GetTsaMonthData: {
        key: 'getTsaMonthData',
        url: `candidate/tsa/month/${id}`,
        type: 'POST',
    },
    GetTsaTagsData: {
        key: 'getTsaTagsData',
        url: `candidate/tsa/tag/${id}`,
        type: 'POST',
    },
    GetSourcingData: {
        key: 'getSourcingTableData',
        url: 'candidate/sourced/box',
        type: 'POST',
    },
    GetSourcingTableData: {
        key: 'getSourcingData',
        url: `candidate/sourcing/report/${id}`,
        type: 'POST',
    },
    GetJobsOverviewData: {
        key: 'getJobsOverviewData',
        url: `jobs/overview/${id}`,
        type: 'GET',
    },
    GetJobsChartData: {
        key: 'getJobsChartData',
        url: `jobs/overview/graph/${id}`,
        type: 'GET',
    },
    GetJobsTimelineData: {
        key: 'getJobsTimelineData',
        url: `jobs/job/${id}`,
        type: 'POST',
    },
    GetJobsStageData: {
        key: 'getJobsStageData',
        url: `jobs/stage/box/${id}`,
        type: 'POST',
    },
    GetJobsStageTableData: {
        key: 'getJobsStageTableData',
        url: `jobs/stage/table/${id}`,
        type: 'POST',
    },
    GetTeamMemberOverviewData: {
        key: 'getTeamMemberOverviewData',
        url: `teamMember/overview/${id}`,
        type: 'GET',
    },
    GetTeamMemberChartData: {
        key: 'getTeamMemberChartData',
        url: `teamMember/chart/${id}`,
        type: 'GET',
    },
    GetTeamMemberCandidatesData: {
        key: 'getTeamMemberCandidatesData',
        url: `teamMember/candidateData/${id}`,
        type: 'POST',
    },
    GetTeamMemberTableData: {
        key: 'getTeamMemberTableData',
        url: `teamMember/tableReport/${id}`,
        type: 'POST',
    },
})

export const GetAnalyticsData = (apiName, payload, id) => async dispatch => {
    const { key, url, type } = ANALYTICS_API_DETAILS(id)[apiName]
    try {
        let res
        if (type === 'GET') res = await restricted.get(`${baseUrl}${url}`)
        else if (type === 'POST') res = await restricted.post(`${baseUrl}${url}`, payload)

        if (res.data.error) {
            LogE(`networkCalls-analytics-${key}`, 'Failed to fetch', res.data.error)
            warning('Failed to fetch')
            return {}
        }
        return res.data
    } catch (error) {
        LogE(`networkCalls-analytics-${key}-catch`, 'Failed to fetch', error)
    }
}
