import React from 'react'
import { ReactComponent as SuperAdminIcon } from '../../../../assets/Icons/Access/super-admin.svg'
import { ReactComponent as AdminIcon } from '../../../../assets/Icons/Access/admin.svg'
import { ReactComponent as JobLevelIcon } from '../../../../assets/Icons/Access/job-level.svg'
import { ReactComponent as RecruiterIcon } from '../../../../assets/Icons/Access/recruiter.svg'
import { ReactComponent as InterviewOnlyIcon } from '../../../../assets/Icons/Access/interview-only.svg'

export const Cards = [
    {
        id: '1',
        icon: SuperAdminIcon,
        title: 'Super Admin',
        paras: [
            'Access to manage job opening & Candidate Profile',
            'Access to modify account settings',
            'Manage and Invite team members',
        ],
        accessLevel: 1,
    },
    {
        id: '2',
        icon: AdminIcon,
        title: 'Admin',
        paras: [
            'Manage job opening and candidate profile',
            'Access to modify account settings',
            'Manage and Invite team members',
        ],
        accessLevel: 2,
    },
    {
        id: '3',
        icon: JobLevelIcon,
        title: 'Job Level Access',
        paras: [
            'View only assigned job openings',
            'Access candidate profiles within assigned job openings',
            'Manage assigned candidate profile',
        ],
        accessLevel: 3,
    },
    {
        id: '4',
        icon: RecruiterIcon,
        title: 'Recruiter',
        paras: ['View only assigned job openings', 'View & manage candidate profile added by themselves'],
        accessLevel: 4,
    },
    {
        id: '5',
        icon: InterviewOnlyIcon,
        title: 'Interview Only',
        paras: ['View & manage candidate profile assigned to themselves'],
        accessLevel: 5,
    },
]

/**
 * Access Level Definitions can be found on the Invite Team Member popup
 *
 * Here: 1 - Super Admin, 2 - Admin, 3 - Job Level Access, 4 - Recruiter, 5 - Interview only access
 */
export const AccessLevelDefinitions = {
    'Company Settings Permissions': {
        'Invite Team Members': [1, 2],
        'Manage Team Members': [1, 2],
        'View Team Members': [1, 2],
        'Edit Career Portal': [1, 2],
    },
    'Job Opening Permissions': {
        'Create / Edit Job Opening': [1, 2],
        'View All Job openings': [1, 2],
        'View assigned job openings': [1, 2, 3, 4],
        'Archive Job Opening': [1, 2],
        'Add/Edit/Move/Delete stage': [1, 2],
        'Assign Job Opening': [1, 2],
    },
    'Candidates Permissions': {
        'View All candidates': [1, 2],
        'View All candidates in assigned job opening': [1, 2, 3],
        'View only the candidates added by the user themselves': [1, 2, 3, 4],
        'View candidates assign to the user themselves': [1, 2, 3, 4, 5],
        'Add candidate to all openings (via platform)': [1, 2],
        'Add candidate to assigned openings (via platform)': [1, 2, 3, 4],
    },
    'Permissions on viewable Candidates': {
        'Move Candidates': [1, 2, 3],
        'Message Candidates': [1, 2, 3, 4, 5],
        'Schedule Interviews': [1, 2, 3, 4],
        'Add Feedback': [1, 2, 3, 4, 5],
        'View All Feedback': [1, 2, 3, 4, 5],
        'Assign Candidate': [1, 2, 3],
        'View or send messages to candidate': [1, 2, 3, 4, 5],
        'Reject Candidate': [1, 2, 3, 4],
        'Archive Candidate': [1, 2],
        'Bulk Move Candidates': [1, 2, 3],
        'Bulk Reject Candidates': [1, 2, 3, 4],
        'View Candidate Details': [1, 2, 3, 4, 5],
        'Edit and Update Candidate Details': [1, 2, 3, 4],
        'Add Note': [1, 2, 3, 4, 5],
    },
    'Private field Permissions': {
        'Private Internal Note (default visibility)': [1, 2],
        'Current and Expected CTC': [1, 2],
        'Private Marked Messages': [1, 2],
        'Create Public and Private Notes on Candidates': [1, 2, 3, 4, 5],
    },
    'Talent Pool': {
        'View All candidates': [1, 2],
        'View All candidates in the assigned job opening': [1, 2, 3],
        'View only the candidates added by the user himself': [1, 2, 3, 4],
    },
}
