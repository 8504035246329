import styled from 'styled-components'

export const Notification = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    font-family: sanFranciscoMedium;
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    max-width: 1200px;
    margin: ${props => (props.talentpool ? '0px auto' : '-20px 0 30px 0')};
    transition: height 1s linear;
    a {
        color: #4767a0;
        font-family: sanFranciscoSemi;
        font-weight: 600;
        text-decoration: underline;
        margin: 0 3px;
    }
    svg {
        margin-right: 7px;
    }
    div {
        color: rgb(71, 103, 160);
    }
    @media only screen and (max-width: 769px) {
        margin-top: 0;
        height: auto;
        padding: 5px;
        svg {
            flex-shrink: 0;
            align-self: start;
            margin-top: 2px;
        }
    }
`
