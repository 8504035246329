import React from 'react'
import RefineResult from './RefineResult'
import Card from './Card'
import { TodoCardLoader, MobileCardLoader } from '../Loaders'
import { Main } from '../style'
import { Empty } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'

const CardRender = ({
    todos,
    showInterviewModal,
    setSelectedData,
    callInProgress,
    hasMore,
    getNewPage,
    cancelInterview,
    isMobileDevice,
}) => {
    if (todos === null || callInProgress)
        return isMobileDevice ? (
            <>
                <MobileCardLoader />
                <MobileCardLoader />
            </>
        ) : (
            <>
                <TodoCardLoader /> <TodoCardLoader />
            </>
        )
    if (todos.length === 0) return <Empty style={{ margin: '100px 0' }} description={'No interviews to show'} />
    return (
        <InfiniteScroll
            loadMore={getNewPage}
            hasMore={hasMore}
            loader={isMobileDevice ? <MobileCardLoader /> : <TodoCardLoader />}
        >
            {todos.map(each => (
                <Card
                    key={each._id}
                    data={each}
                    showInterviewModal={showInterviewModal}
                    setSelectedData={setSelectedData}
                    cancelInterview={cancelInterview}
                    isMobileDevice={isMobileDevice}
                />
            ))}
        </InfiniteScroll>
    )
}

const Content = ({
    todos,
    updateRefineType,
    refineType,
    showInterviewModal,
    setSelectedData,
    callInProgress,
    hasMore,
    getNewPage,
    cancelInterview,
    showMobileFilter,
    mobileFilter,
    isMobileDevice,
}) => {
    return (
        <Main hidden={mobileFilter}>
            <RefineResult
                interviewCount={(todos && todos.length) || 0}
                updateRefineType={updateRefineType}
                refineType={refineType}
                showMobileFilter={showMobileFilter}
                mobileFilter={mobileFilter}
            />
            <CardRender
                todos={todos}
                showInterviewModal={showInterviewModal}
                setSelectedData={setSelectedData}
                callInProgress={callInProgress}
                hasMore={hasMore}
                getNewPage={getNewPage}
                cancelInterview={cancelInterview}
                isMobileDevice={isMobileDevice}
            />
        </Main>
    )
}

export default Content
