import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import Button from '../StyledButton'
import { ReactComponent as ErrorIcon } from '../../../assets/Icons/Access/error.svg'
import './style.scss'

export const StyledDropzone = ({
    onDrop,
    multiple = false,
    accept = 'image/jpeg, image/png',
    content = 'Drop your file here (PDF/DOC/DOCX)',
    error = false,
    height = 135,
}) => {
    return (
        <>
            <Dropzone onDrop={onDrop} multiple={multiple} accept={accept}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <div {...getRootProps()} className={`drop-zone ${error && 'error'}`} style={{ height }}>
                        <input {...getInputProps()} />
                        {isDragActive && 'Unsupported file format'}
                        <div hidden={isDragReject}>
                            <div className="content">{content}</div>
                            <div className="content">OR</div>
                            <div className="content">
                                <span>Click here</span> to browse
                            </div>
                        </div>
                    </div>
                )}
            </Dropzone>
            <div className="drop-zone-error-msg" hidden={!error}>
                <ErrorIcon /> {error}
            </div>
        </>
    )
}

export const StyledDropzoneMini = ({
    onDrop,
    uploadText = 'Choose File',
    multiple = false,
    accept = 'image/jpeg, image/png',
}) => {
    return (
        <Dropzone onDrop={onDrop} multiple={multiple} accept={accept}>
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="drop-zone-mini">
                    <input {...getInputProps()} />
                    <span>{uploadText}</span>
                </div>
            )}
        </Dropzone>
    )
}

export const StyledDropzoneButton = ({
    onDrop,
    uploadText = 'Choose File',
    multiple = false,
    accept = 'image/jpeg, image/png',
    height = '30px',
}) => {
    return (
        <Dropzone onDrop={onDrop} multiple={multiple} accept={accept}>
            {({ getRootProps, getInputProps }) => (
                <Button capitalize type="inverted" width="120px" height={height} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <span>{uploadText}</span>
                </Button>
            )}
        </Dropzone>
    )
}
