import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'
import { DataCard, Filter, Heading, Tabs } from '../../style'
import CustomFilters from '../../Components/Filters'
import { CandidatesFilterLoader, CandidatesProgressLoader } from '../../Components/Loaders'
import { GetAnalyticsData } from '../../../../../services/NetworkCalls/Analytics'
import apiCaller from '../../../../../services/apiCaller'
import { warning } from '../../../../../services/Notification'
import { ReactComponent as InfoIcon } from '../../../../../assets/Icons/Openings/info.svg'
import { ReactComponent as RefreshIcon } from '../../../../../assets/Icons/Analytics/refresh.svg'
import { getPercentage } from '../../../../Helpers/utils'
import StatCard from '../../Components/Card'
import {
    PROGRESS_JOBS_COLUMNS,
    PROGRESS_SOURCE_COLUMNS,
    PROGRESS_MONTH_COLUMNS,
    PROGRESS_TAG_COLUMNS,
    getRefreshPayload,
    getJobStatusArray,
    getFilteredPayload,
    getCandidatesInitialPayload,
} from '../../Components/constants'
import Table from '../../Components/Table'
import { Tooltip } from 'antd'
import config from '../../../../../config'
import { StyledButton } from '../../../../../container/Components/'

const { isMobileDevice } = config

const { CheckboxFilter, DateFilter } = CustomFilters
const { formatError } = apiCaller

const Progress = ({ companyId, jobNames, jobsObject, GetAnalyticsData, history }) => {
    const [filterLoading, setFilterLoading] = useState(false)
    const [cardLoading, setCardLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [cardData, setCardData] = useState({})
    const [tableData, setTableData] = useState([])
    const [jobNamesArray, setJobNamesArray] = useState(jobNames)
    const [tagsArray, setTagsArray] = useState([])
    const [selectedJobTypes, setSelectedJobTypes] = useState([])
    const [selectedJobNames, setSelectedJobNames] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedTab, setSelectedTab] = useState('job')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [loadTime, setLoadTime] = useState(moment().format('DD MMM, YYYY LT'))

    const jobTypeArray = ['Active', 'Archived']
    const selectedColumn = {
        job: {
            columns: PROGRESS_JOBS_COLUMNS,
            downloadColumns: [
                { index: 'jobName', value: 'JOB NAME' },
                { index: 'totalCandidates', value: 'TOTAL CANDIDATES' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'proceeded', value: 'PROCEEDED' },
                { index: 'archived', value: 'ARCHIVED' },
                { index: 'rejected', value: 'REJECTED' },
                { index: 'offered', value: 'OFFERED' },
                { index: 'joined', value: 'JOINED' },
            ],
            filename: 'CANDIDATE PROGRESS_JOBS',
        },
        source: {
            columns: PROGRESS_SOURCE_COLUMNS,
            downloadColumns: [
                { index: 'sourceName', value: 'SOURCE NAME' },
                { index: 'totalCandidates', value: 'TOTAL CANDIDATES' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'proceeded', value: 'PROCEEDED' },
                { index: 'archived', value: 'ARCHIVED' },
                { index: 'rejected', value: 'REJECTED' },
                { index: 'offered', value: 'OFFERED' },
                { index: 'joined', value: 'JOINED' },
            ],
            filename: 'CANDIDATE PROGRESS_SOURCE',
        },
        month: {
            columns: PROGRESS_MONTH_COLUMNS,
            downloadColumns: [
                { index: 'monthCreated', value: 'MONTH CREATED' },
                { index: 'totalCandidates', value: 'TOTAL CANDIDATES' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'proceeded', value: 'PROCEEDED' },
                { index: 'archived', value: 'ARCHIVED' },
                { index: 'rejected', value: 'REJECTED' },
                { index: 'offered', value: 'OFFERED' },
                { index: 'joined', value: 'JOINED' },
            ],
            filename: 'CANDIDATE PROGRESS_MONTH',
        },
        tag: {
            columns: PROGRESS_TAG_COLUMNS,
            downloadColumns: [
                { index: 'tagName', value: 'TAG NAME' },
                { index: 'totalCandidates', value: 'TOTAL CANDIDATES' },
                { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
                { index: 'proceeded', value: 'PROCEEDED' },
                { index: 'archived', value: 'ARCHIVED' },
                { index: 'rejected', value: 'REJECTED' },
                { index: 'offered', value: 'OFFERED' },
                { index: 'joined', value: 'JOINED' },
            ],
            filename: 'CANDIDATE PROGRESS_TAGS',
        },
    }

    useEffect(() => {
        candidatesInitialLoad()
    }, [])

    const candidatesInitialLoad = async () => {
        const urlSearchResult = new URLSearchParams(history.location.search)
        const jobTypes = urlSearchResult.get('jobTypes') ? urlSearchResult.get('jobTypes').split(',') : []
        const jobNames = urlSearchResult.get('jobNames') ? urlSearchResult.get('jobNames').split(',') : []
        const tagNames = urlSearchResult.get('tagNames') ? urlSearchResult.get('tagNames').split(',') : []
        const startDate = urlSearchResult.get('startDate') ? urlSearchResult.get('startDate') : null
        const endDate = urlSearchResult.get('endDate') ? urlSearchResult.get('endDate') : null
        let tagNamesArray = []

        if (jobTypes.length || jobNames.length || tagNames.length || startDate || endDate)
            tagNamesArray = await getCandidateTags()
        const { payload, filteredJobNames } = getCandidatesInitialPayload(
            companyId,
            jobTypes,
            jobNames,
            jobsObject,
            tagNames,
            tagNamesArray,
            startDate,
            endDate
        )
        getProgressData(payload)
        getCandidateTags({ jobId: payload.jobId })
        getProgressJobsData(payload, jobTypes, jobNames)
        setSelectedJobTypes(jobTypes)
        setSelectedJobNames(jobNames)
        setJobNamesArray(filteredJobNames)
        setSelectedTags(tagNames)
        setStartDate(startDate && moment(startDate))
        setEndDate(endDate && moment(endDate))
    }

    const getProgressData = async (payload = {}) => {
        try {
            setCardLoading(true)
            const { result } = await GetAnalyticsData('GetProgressData', payload)
            setCardData(result)
            setCardLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getCandidateTags = async (payload = {}) => {
        try {
            setFilterLoading(true)
            const { result } = await GetAnalyticsData('GetCandidatesTags', payload, companyId)
            setTagsArray(result)
            setFilterLoading(false)
            return result
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getProgressJobsData = async (payload = {}, jobTypes, jobNames) => {
        try {
            setTableLoading(true)
            const { result } = await GetAnalyticsData('GetProgressJobsData', payload)
            const jobResultList = []
            let jobNamesList = []
            if (jobNames.length) jobNamesList = jobNames
            else if (jobTypes.length) {
                const jobStatusArray = getJobStatusArray(jobTypes)
                for (let job in jobsObject) {
                    if (jobStatusArray.includes(jobsObject[job].status)) jobNamesList.push(job)
                }
            } else jobNamesList = Object.keys(jobsObject)

            const tableData = result.map((res, index) => {
                jobResultList.push(res.jobTitle)
                return {
                    key: res.jobTitle,
                    jobName: res.jobTitle,
                    totalCandidates: res.totalCandidate || 0,
                    candidatesAdded: res.candidateAdded || 0,
                    proceeded: res.proceeded || 0,
                    archived: res.archived || 0,
                    rejected: res.rejected || 0,
                    offered: res.makeOffer || 0,
                    joined: res.joined || 0,
                }
            })
            jobNamesList.forEach(jobName => {
                if (!jobResultList.includes(jobName))
                    tableData.push({
                        key: jobName,
                        jobName,
                        totalCandidates: 0,
                        candidatesAdded: 0,
                        proceeded: 0,
                        archived: 0,
                        rejected: 0,
                        offered: 0,
                        joined: 0,
                    })
            })
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getProgressSourceData = async (payload = {}) => {
        try {
            setTableLoading(true)
            const { result } = await GetAnalyticsData('GetProgressSourceData', payload)
            const sourceMap = {
                ats: 'ATS',
                careerPage: 'Career Page',
                email: 'Email',
                sourced: 'Sourcing',
            }
            const tableData = []
            for (let source in result) {
                tableData.push({
                    key: source,
                    sourceName: sourceMap[source],
                    totalCandidates: result[source].total || 0,
                    candidatesAdded: result[source].added || 0,
                    proceeded: result[source].proceeded || 0,
                    archived: result[source].archived || 0,
                    rejected: result[source].rejected || 0,
                    offered: result[source].makeOffer || 0,
                    joined: result[source].joined || 0,
                })
            }
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getProgressMonthData = async (payload = {}) => {
        try {
            setTableLoading(true)
            const { result } = await GetAnalyticsData('GetProgressMonthData', payload)
            result.sort((a, b) => {
                const month1 = moment(Object.keys(a)[0]),
                    month2 = moment(Object.keys(b)[0])
                if (month1.isAfter(month2)) return -1
                else if (month1.isBefore(month2)) return 1
                return 0
            })
            const tableData = result.map((res, index) => {
                const values = Object.values(res)[0]
                return {
                    key: index,
                    monthCreated: Object.keys(res)[0],
                    totalCandidates: values.totalCandidate || 0,
                    candidatesAdded: values.candidateAdded || 0,
                    proceeded: values.proceeded || 0,
                    archived: values.archived || 0,
                    rejected: values.rejected || 0,
                    offered: values.makeOffer || 0,
                    joined: values.joined || 0,
                }
            })
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getProgressTagsData = async (payload = {}) => {
        try {
            setTableLoading(true)
            const { result } = await GetAnalyticsData('GetProgressTagsData', payload)
            const tableData = result.map((res, index) => {
                const values = Object.values(res)[0]
                return {
                    key: index,
                    tagName: Object.keys(res)[0],
                    totalCandidates: values.totalCandidate || 0,
                    candidatesAdded: values.candidateAdded || 0,
                    proceeded: values.proceeded || 0,
                    archived: values.archived || 0,
                    rejected: values.rejected || 0,
                    offered: values.makeOffer || 0,
                    joined: values.joined || 0,
                }
            })
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const applyFilteredData = (type, data) => {
        const payload = getFilteredPayload(
            type,
            data,
            companyId,
            selectedJobTypes,
            selectedJobNames,
            jobsObject,
            selectedTags,
            tagsArray,
            startDate,
            endDate,
            getCandidateTags,
            setSelectedJobTypes,
            setJobNamesArray,
            setSelectedJobNames,
            setSelectedTags,
            setStartDate,
            setEndDate,
            history
        )
        getProgressData(payload)
        if (type === 'jobType') updateTableData(payload, data, [])
        else if (type === 'jobName') updateTableData(payload, selectedJobTypes, data)
        else updateTableData(payload, selectedJobTypes, selectedJobNames)
    }

    const resetFilter = type => {
        const params = new URLSearchParams(history.location.search)
        if (type === 'jobType') {
            const payload = {
                companyId,
                startDate: startDate && startDate.format(),
                endDate: endDate && endDate.format(),
            }
            getCandidateTags(payload)
            getProgressData(payload)
            updateTableData(payload, [], [])
            setSelectedJobTypes([])
            setSelectedJobNames([])
            setSelectedTags([])
            setJobNamesArray(Object.keys(jobsObject))
            params.delete('jobTypes')
            params.delete('jobNames')
            params.delete('tagNames')
            history.push({ search: params.toString() })
        } else if (type === 'jobName') {
            const jobStatusArray = getJobStatusArray(selectedJobTypes)
            let jobId = []
            if (jobStatusArray.length) {
                for (let job in jobsObject)
                    if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
            } else jobId = null
            const payload = {
                companyId,
                jobId,
                startDate: startDate && startDate.format(),
                endDate: endDate && endDate.format(),
            }
            getCandidateTags(payload)
            getProgressData(payload)
            updateTableData(payload, selectedJobTypes, [])
            setSelectedJobNames([])
            setSelectedTags([])
            params.delete('jobNames')
            params.delete('tagNames')
            history.push({ search: params.toString() })
        } else {
            const jobStatusArray = getJobStatusArray(selectedJobTypes)
            let jobId = []
            if (selectedJobNames.length) jobId = selectedJobNames.map(jobName => jobsObject[jobName]._id)
            else if (jobStatusArray.length) {
                for (let job in jobsObject)
                    if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
            }
            const payload = {
                companyId,
                jobId: (jobId.length && jobId) || null,
                startDate: startDate && startDate.format(),
                endDate: endDate && endDate.format(),
            }
            getProgressData(payload)
            updateTableData(payload, selectedJobTypes, selectedJobNames)
            setSelectedTags([])
            params.delete('tagNames')
            history.push({ search: params.toString() })
        }
    }

    const updateTableData = (payload, jobTypes, jobNames) => {
        if (selectedTab === 'job') getProgressJobsData(payload, jobTypes, jobNames)
        else if (selectedTab === 'source') getProgressSourceData(payload)
        else if (selectedTab === 'month') getProgressMonthData(payload)
        else getProgressTagsData(payload)
    }

    const handleTabClick = tab => {
        const jobStatusArray = getJobStatusArray(selectedJobTypes)
        let jobId = [],
            tags = []
        if (selectedJobNames.length) jobId = selectedJobNames.map(jobName => jobsObject[jobName]._id)
        else if (jobStatusArray.length) {
            for (let job in jobsObject)
                if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
        }

        if (selectedTags.length) {
            tagsArray.forEach(tag => {
                if (selectedTags.includes(tag.name)) tags.push(tag._id)
            })
        }
        const payload = {
            companyId,
            jobId: (jobId.length && jobId) || null,
            tags: (tags.length && tags) || null,
            startDate: startDate && startDate.format(),
            endDate: endDate && endDate.format(),
        }
        if (tab === 'job') getProgressJobsData(payload, selectedJobTypes, selectedJobNames)
        else if (tab === 'source') getProgressSourceData(payload)
        else if (tab === 'month') getProgressMonthData(payload)
        else getProgressTagsData(payload)

        setSelectedTab(tab)
    }

    const handleRefresh = () => {
        const payload = getRefreshPayload(
            selectedJobTypes,
            selectedJobNames,
            selectedTags,
            jobsObject,
            tagsArray,
            companyId,
            startDate,
            endDate
        )
        getProgressData(payload)
        updateTableData(payload, selectedJobTypes, selectedJobNames)
        setLoadTime(moment().format('DD MMM, YYYY LT'))
    }

    return (
        <>
            <div className="sub-header">
                <div>
                    <Heading>Candidate Progress</Heading>
                    <Tooltip title="Learn how your candidates progressed through different pipelines on SpringRecruit. Use the filters to narrow the search criteria.">
                        <InfoIcon />
                    </Tooltip>
                </div>
                <div>
                    <RefreshIcon onClick={() => handleRefresh()} />
                    <span>Last Updated: {loadTime}</span>
                </div>
            </div>
            {filterLoading ? (
                <CandidatesFilterLoader />
            ) : (
                <>
                    {isMobileDevice && <Heading>Filter</Heading>}
                    <Filter>
                        <CheckboxFilter
                            title="Filter by job type"
                            data={jobTypeArray}
                            search={false}
                            apply={false}
                            type="jobType"
                            applyFilteredData={applyFilteredData}
                            resetFilter={resetFilter}
                            selectedValues={selectedJobTypes}
                        />
                        <CheckboxFilter
                            title="Filter by job name"
                            data={jobNamesArray}
                            type="jobName"
                            applyFilteredData={applyFilteredData}
                            resetFilter={resetFilter}
                            selectedValues={selectedJobNames}
                        />
                        <CheckboxFilter
                            title="Filter by tag"
                            data={tagsArray.map(tag => tag.name)}
                            type="tag"
                            applyFilteredData={applyFilteredData}
                            resetFilter={resetFilter}
                            selectedValues={selectedTags}
                        />
                        <DateFilter applyFilteredData={applyFilteredData} start={startDate} end={endDate} />
                    </Filter>
                </>
            )}
            {cardLoading || filterLoading ? (
                <CandidatesProgressLoader />
            ) : (
                <div className="cards">
                    <StatCard total={cardData.totalCandidate} title="Total Candidates" />
                    <StatCard
                        total={cardData.archived}
                        title="Archived"
                        desc={`${getPercentage(cardData.archived, cardData.totalCandidate)}% of Total Candidates`}
                    />
                    <StatCard
                        total={cardData.rejected}
                        title="Rejected"
                        desc={`${getPercentage(cardData.rejected, cardData.totalCandidate)}% of Total Candidates`}
                    />
                    <StatCard
                        total={cardData.makeOffer}
                        title="Offered Job"
                        desc={`${getPercentage(cardData.makeOffer, cardData.totalCandidate)}% of Total Candidates`}
                    />
                    <StatCard
                        total={cardData.joined}
                        title="Joined"
                        desc={`${getPercentage(cardData.joined, cardData.totalCandidate)}% of Total Candidates`}
                    />
                </div>
            )}
            <Tabs>
                <div className={classNames({ active: selectedTab === 'job' })} onClick={() => handleTabClick('job')}>
                    JOB
                </div>
                <div
                    className={classNames({ active: selectedTab === 'source' })}
                    onClick={() => handleTabClick('source')}
                >
                    SOURCE
                </div>
                <div
                    className={classNames({ active: selectedTab === 'month' })}
                    onClick={() => handleTabClick('month')}
                >
                    MONTH CREATED
                </div>
                <div className={classNames({ active: selectedTab === 'tag' })} onClick={() => handleTabClick('tag')}>
                    CANDIDATE TAG
                </div>
            </Tabs>
            <Table
                columns={selectedColumn[selectedTab].columns}
                downloadColumns={selectedColumn[selectedTab].downloadColumns}
                filename={`${selectedColumn[selectedTab].filename}_${moment().format('DD-MM-YYYY_h-mm a')}`}
                tableData={tableData}
                loading={tableLoading || filterLoading}
                xScroll={1136}
            />
        </>
    )
}

const mapStateToProps = ({ Auth, Job }) => {
    const { user } = Auth
    const { allJobs } = Job
    const jobNames = [],
        jobsObject = {}

    allJobs.forEach(job => {
        jobNames.push(job.jobTitle)
        jobsObject[job.jobTitle] = {
            _id: job._id,
            status: job.status,
        }
    })

    return {
        companyId: user.companyId,
        jobNames,
        jobsObject,
    }
}

export default connect(
    mapStateToProps,
    {
        GetAnalyticsData,
    }
)(Progress)
