import { success, warning } from '../../../services/Notification'
import { store } from '../../../redux/store'
import apiCaller from '../../apiCaller'
import ajaxCaller from '../../apiCaller'
import { actions } from '../../../redux/inbox/actions'
import { LogE } from '../../../container/Helpers/errorHandler'
import { EVENTS } from '../../../container/Pages/Sourcing/constants'
import {
    FILTER_TYPES,
    FILTER_TYPES_BE,
    FILTER_TYPES_BE_REVERSED,
    PAGE_SIZE_LIMIT,
    TAB_MODE,
} from '../../../container/Pages/Inbox/Components/constants'
import { SendMessage } from '../../api'
import { encode } from 'querystring'
import config from '../../../config'

const { restricted } = apiCaller.type
const { formatError } = apiCaller
const axios = ajaxCaller.wrappers.axios
const baseUrl = 'inbox/'
const { baseURL, apiVersion } = config.config()

export const setApiProcess = () => dispatch => {
    dispatch({ type: actions.SET_CALL_IN_PROGRESS })
    return true
}

export const getContacts = ({
    mainFilter = null,
    searchResult = '',
    stageId = [],
    jobId = [],
    pageNo,
    status = [],
    filterType = null,
    tabMode = TAB_MODE.ALL,
}) => async dispatch => {
    const bodyData = {
        filterBy: FILTER_TYPES_BE[mainFilter || FILTER_TYPES.ALL],
        query: searchResult || null,
        stageId: (stageId.length && stageId) || null,
        jobId: (jobId.length && jobId) || null,
        status: (status.length && status) || null,
        pageNo: pageNo || 1,
        size: PAGE_SIZE_LIMIT,
        tabMode,
    }

    try {
        if (bodyData.pageNo === 1) dispatch({ type: actions.SET_CALL_IN_PROGRESS })

        const { data } = await restricted.post(`${baseUrl}search`, bodyData)

        const payload = {
            ...data,
            mainFilter: FILTER_TYPES_BE_REVERSED[bodyData.filterBy],
            search: bodyData.query,
            pageNo: bodyData.pageNo,
            selectedFilter: {
                status: bodyData.status,
                stageId: bodyData.stageId,
                jobId: bodyData.jobId,
                tabMode: bodyData.tabMode,
            },
            filterType,
        }

        dispatch({ type: actions.GET_CONTACTS, payload })
        return true
    } catch (error) {
        warning(formatError(error))
        return false
    }
}

export const getInboxEmails = ({ candidateId, shouldLoad }) => async dispatch => {
    try {
        dispatch({ type: actions.SELECT_CONTACT, payload: { candidateId, shouldLoad } })

        const { data } = await restricted.get(`${baseUrl}emails/${candidateId}`)
        data.email_content.reverse()

        const payload = {
            data,
            candidateId,
        }
        dispatch({ type: actions.GET_INBOX, payload })

        dispatch({ type: 'MODIFY_CACHE_DATA', data: { payload: false, key: 'pullToRefresh', event: 'DELETE' } })
        return true
    } catch (error) {
        warning(formatError(error))
        return false
    }
}

export const sendInboxEmail = data => async dispatch => {
    try {
        const [result] = await SendMessage(data)
        success('Message sent successfully')
        if (data.shouldSendDispatch) dispatch({ type: actions.SEND_INBOX, payload: result.details })
    } catch (error) {
        LogE('EEEEEE', error)
        warning('OOPS')
    }
}

export const getCandidateEmailList = async ({ apiInQueue, value }) => {
    try {
        const str = encode({
            code: 'utf-8',
            value,
            apiInQueue,
        })

        const URL = `${baseUrl}candidate/emails?${str}`
        const response = await restricted.get(URL)
        return response
    } catch (error) {
        warning(
            (error.response && error.response.data && error.response.data.error && error.response.data.error.msg) ||
                error
        )
    }
}

/*DUPLICATE USE:
 * from api.js
 * to be updated once BE moves to v2
 * */
export const ChangeEmailPrivacy = (conversationId, token, markedPrivate) => async dispatch => {
    try {
        const url = `${baseURL}${apiVersion.v1}email/${conversationId}/setPrivacy`
        let { body } = await fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify({ markedPrivate }),
        })
        dispatch({ type: actions.INBOX_MARK_PRIVATE, payload: { markedPrivate, conversationId } })

        if (body.error) {
            throw new Error(body.error)
        }
        return body
    } catch (err) {
        throw err
    }
}
