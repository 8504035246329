import React from 'react'
import { MessageModal } from '../common'

const ConfirmCloneJobAlert = ({ onCancel, onConfirm }) => {
    return (
        <MessageModal
            title="CLONE JOB"
            message={<span>Do you want to Clone this job?</span>}
            onConfirm={onConfirm}
            onCancel={onCancel}
            confirmText="CONFIRM"
            cancelText="CANCEL"
        />
    )
}

export default ConfirmCloneJobAlert
