import React, { Component } from 'react'
// import { Aside } from '../../TalentPool/style'
import { Aside } from '../style'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { ReactComponent as CandidateIcon } from '../../../../assets/Icons/Talent Pool/candidate.svg'
import { ReactComponent as BriefcaseIcon } from '../../../../assets/Icons/Openings/briefcase.svg'
import { ReactComponent as TeamMemberIcon } from '../../../../assets/Icons/Talent Pool/team-member.svg'
import { Checkbox, Collapse, Icon, Input } from 'antd'
const { Panel } = Collapse

const customPanelStyle = {
    background: '#fff',
    border: 0,
    overflow: 'hidden',
    color: '#333',
    fontFamily: 'SFProText-Bold',
}

export const Sidebar = () => {
    return (
        <>
            <Aside>
                <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => <Icon component={DownIcon} rotate={isActive ? 180 : 0} />}
                    expandIconPosition="right"
                >
                    <Panel
                        header={
                            <>
                                <CandidateIcon />
                                CANDIDATE
                            </>
                        }
                        key="assigned"
                        style={customPanelStyle}
                    >
                        <a href="#" className="active">
                            Overview
                        </a>
                        <a href="#">Candidate Progress</a>
                        <a href="#">Time Spent Analysis</a>
                        <a href="#">Candidate Source</a>
                    </Panel>
                    <Panel
                        header={
                            <>
                                <BriefcaseIcon />
                                JOBS
                            </>
                        }
                        key="jobs"
                        style={customPanelStyle}
                    >
                        <a href="#">Overview</a>
                        <a href="#">Job Timeline</a>
                        <a href="#">Stage Analysis</a>
                    </Panel>
                    <Panel
                        header={
                            <>
                                <TeamMemberIcon />
                                TEAM MEMBER
                            </>
                        }
                        key="team"
                        style={customPanelStyle}
                    >
                        <a href="#">Overview</a>
                        <a href="#">Candidates by Member</a>
                    </Panel>
                </Collapse>
            </Aside>
        </>
    )
}

export default Sidebar
