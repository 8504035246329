import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { CardCollapsible, Title, Notes } from '../style'
import { NotesLoader } from './Loaders'
import { ReactComponent as ExpandIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Btn.svg'
import { ReactComponent as CollapseIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand or Collapse Btn.svg'
import { ReactComponent as AddIcon } from '../../../../assets/Icons/Talent Pool/add.svg'
import EditIcon from '../../../../assets/Icons/Openings/Edit Icon'
import StyledButton from '../../../../container/Components/StyledButton/index.js'
import Paragraph from '../../../Components/StyledParagraph'
import { Tooltip, Icon, Popover, Button } from 'antd'
import { Modal } from '../../../Modals'
import { MODAL_TYPES } from '../../../Helpers/ModalTypes'
import { BLUE_GREY } from '../../../Helpers/Stylesheet/styledComponents'
import { warning } from '../../../../services/Notification'
import apiCaller from '../../../../services/apiCaller'
import { AddNote, UpdateNote, DeleteNote } from '../../../../services/NetworkCalls/SourcingTalentPool'

const { formatError } = apiCaller

const InternalNotes = ({ candidate, allNotes = [], isLoading, user, teamMembers, AddNote, UpdateNote, DeleteNote }) => {
    const [notes, setNotes] = useState(allNotes)
    const [showNoteModal, setShowNoteModal] = useState(false)
    const [selectedNote, setSelectedNote] = useState(null)
    const [isActive, setIsActive] = useState(true)

    useEffect(() => {
        if (allNotes.length) setNotes(allNotes)
    }, [allNotes])

    const handleCancel = () => {
        setShowNoteModal(false)
        setSelectedNote(null)
    }

    const showNoteBox = note => {
        const newNote = {
            _id: note._id,
            note: note.content,
            private: note.private,
            canView: note.allowedToView,
        }
        setSelectedNote(newNote)
        setShowNoteModal(true)
    }

    const deleteNoteToggle = index => {
        const newNotes = notes.slice()
        const [currentNote] = newNotes.splice(index, 1)
        currentNote.showDeleteBox = !currentNote.showDeleteBox
        newNotes.splice(index, 0, currentNote)
        setNotes(newNotes)
    }

    const deleteNote = async (id, index) => {
        try {
            const data = {
                noteId: id,
                universalCandidateId: candidate._id,
            }
            const { notes } = await DeleteNote(id, candidate._id)
            setNotes(notes)
        } catch (error) {
            warning(formatError(error))
        }
    }

    const onNoteSubmit = async note => {
        try {
            const data = {
                note: {
                    private: note.private,
                    content: note.note,
                    allowedToView: note.canView,
                },
                universalCandidateId: note.candidateId,
            }

            if (note._id) {
                data.note._id = note._id
                const { notes } = await UpdateNote(data)
                setNotes(notes)
            } else {
                const { notes } = await AddNote(data)
                setNotes(notes)
            }
            setShowNoteModal(false)
            setSelectedNote(null)
        } catch (error) {
            warning(formatError(error))
        }
    }
    return (
        <>
            <CardCollapsible active={isActive} maxHeight={'100%'}>
                <div className="header">
                    <Title>Internal Notes:</Title>
                    {isActive ? (
                        <CollapseIcon onClick={() => setIsActive(false)} />
                    ) : (
                        <ExpandIcon onClick={() => setIsActive(true)} />
                    )}
                </div>
                {isLoading ? (
                    <NotesLoader />
                ) : (
                    <Notes className="body">
                        <div>
                            {notes.map((note, index) => {
                                const member = teamMembers.find(mem => mem._id === note.addedBy._id)
                                note.userName = member && member.fullName
                                if (note.addedBy._id === user.id) {
                                    note.userName += '(You)'
                                    note.isEditable = true
                                }
                                let visibility = note.allowedToView.concat([note.addedBy._id])
                                visibility = visibility.filter(x => x !== user.userId)

                                let visibilityMessage = 'Visible to you'
                                visibility.forEach(x => {
                                    let noteUserName = teamMembers.find(mem => mem._id === x)
                                    noteUserName = noteUserName && (noteUserName.fullName || noteUserName.email)
                                    visibilityMessage += `, ${noteUserName}`
                                })

                                visibilityMessage += ' admins and super admins.'
                                return (
                                    <>
                                        <div key={note._id} className="note">
                                            <div className="header">
                                                <div className="title">
                                                    {note.userName || 'User'} added a{' '}
                                                    {note.private ? 'private' : 'public'} note
                                                    <span className="when">{moment(note.updatedAt).fromNow()}</span>
                                                </div>
                                                <div>
                                                    {note.isEditable && !note.showDeleteBox && (
                                                        <Icon
                                                            className="note-icon"
                                                            component={EditIcon}
                                                            onClick={() => showNoteBox(note)}
                                                        />
                                                    )}
                                                    {note.isEditable && !note.showDeleteBox && (
                                                        <Icon
                                                            className="note-icon"
                                                            type="delete"
                                                            theme="filled"
                                                            onClick={() => deleteNoteToggle(index)}
                                                        />
                                                    )}
                                                    {note.private && (
                                                        <Popover
                                                            title={visibilityMessage}
                                                            arrowPointAtCenter
                                                            placement="topRight"
                                                        >
                                                            <Icon
                                                                className="note-icon"
                                                                type="lock"
                                                                theme="twoTone"
                                                                twoToneColor="#D1D6D4"
                                                            />
                                                        </Popover>
                                                    )}
                                                </div>
                                            </div>
                                            <Paragraph rows={3} fontSize={18} fontColor={BLUE_GREY}>
                                                {note.content}
                                            </Paragraph>
                                        </div>
                                        {note.showDeleteBox ? (
                                            <div className="delete">
                                                <span>Are you sure you want to delete this note?</span>
                                                <Button
                                                    size="small"
                                                    type="primary"
                                                    className="delete-btn"
                                                    onClick={() => deleteNote(note._id, index)}
                                                >
                                                    Yes
                                                </Button>
                                                <Button
                                                    size="small"
                                                    type="primary"
                                                    className="delete-btn"
                                                    onClick={() => deleteNoteToggle(index)}
                                                >
                                                    Don't delete
                                                </Button>
                                            </div>
                                        ) : null}
                                    </>
                                )
                            })}
                            <StyledButton
                                capitalize
                                type="inverted"
                                className="add-btn"
                                width={'135px'}
                                height={'40px'}
                                onClick={() => setShowNoteModal(true)}
                            >
                                <AddIcon />
                                <span>Add Notes</span>
                            </StyledButton>
                        </div>
                    </Notes>
                )}
                <Modal
                    type={MODAL_TYPES.ADD_NOTES}
                    visible={showNoteModal}
                    returnOnSubmit={true}
                    candidateId={candidate._id}
                    candidateName={candidate.name}
                    note={selectedNote}
                    onCancel={handleCancel}
                    onConfirm={onNoteSubmit}
                />
            </CardCollapsible>
        </>
    )
}

const mapStateToProps = state => {
    const { Auth, Mixed } = state
    return {
        teamMembers: Mixed.teamMembers,
        user: Auth.user,
    }
}

export default connect(
    mapStateToProps,
    {
        AddNote,
        UpdateNote,
        DeleteNote,
    }
)(InternalNotes)
