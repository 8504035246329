import React from 'react'
import { Icon, Avatar, Popover, Input, Button, Tooltip, Menu } from 'antd'
import './style.css'
import getAvatar from '../../../services/getAvatar'
import { success, warning } from '../../../services/Notification'
import { validateJobId, toCustomFirstCharCap } from '../../Helpers/utils'
import { ReactComponent as Briefcase } from '../../../assets/Icons/Openings/briefcase.svg'
import { ReactComponent as Location } from '../../../assets/Icons/Openings/location.svg'
import { ReactComponent as MoreOptionIcon } from '../../../assets/Icons/Openings/menu.svg'
import { ReactComponent as PlusIcon } from '../../../assets/Icons/Openings/plus.svg'
import { ReactComponent as CompanyIcon } from '../../../assets/Icons/Openings/Company.svg'
import _ from 'lodash'
import { OPENING_STATUS } from '../../Helpers/constants'
import { UpdateJobOpening, AddJobCoordinator, RemoveJobCoordinator } from '../../../services/NetworkCalls/Opening'
import { GetJobDetails } from '../../../services/NetworkCalls/Job'
import { AddTeamMember, DeleteJobOpening } from '../../../services/api'
import { connect } from 'react-redux'
import { FSMODAL_TYPES, MODAL_TYPES } from '../../Helpers/ModalTypes'
import { FSModal, Modal } from '../../Modals'

const { Search } = Input

class OpeningCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            openings: props.openings,
            coordinators: props.coordinators,
            coordinatorText: '',
            accessLevel: props.accessLevel,
            showTeamMemberModal: false,
            selectedJob: null,
            selectedJobId: null,
            disbaleClick: false,
            show: false,
            showArchiveWarning: false,
            showCloneJobWarning: false,
            showJobDelete: false,
            candidateCount: 0,
            archivingJobId: null,
            callInProgress: false,
        }
    }

    componentDidMount() {
        this.updateFontSize()
    }

    updateFontSize = () => {
        const getFontSize = textLength => {
            let fontSize = 18
            const baseSize = 19
            if (textLength > baseSize) {
                fontSize = textLength > 21 ? 16 : 17
            }
            return `${fontSize}px`
        }

        const boxes = document.querySelectorAll('.card-header-text')

        boxes.forEach(box => {
            box.style.fontSize = getFontSize(box.textContent.length)
        })
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.openings !== this.state.openings) {
            await this.setState({
                openings: nextProps.openings,
            })
            this.updateFontSize()
        }
    }

    handleCoordinatorPopover = index => visible => {
        const { openings } = this.state
        openings[index].popoverVisible = visible
        this.setState({ openings, coordinatorText: '' })
    }
    handleVisibleClick = index => visible => {
        const { openings } = this.state
        openings[index].show = visible
        this.setState({ openings })
    }
    isCoordinatorValid = () => {
        const { coordinatorText, coordinators } = this.state
        const valid = coordinatorText
            ? coordinators.filter(x => x.email.toLowerCase().includes(coordinatorText.toLowerCase())).length
            : true

        const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isEmail = emailRegExp.test(coordinatorText)
        return { valid, isEmail }
    }

    gotToDetailView = (view, id) => {
        this.props.history.push(`openings/${view}/${id}`)
    }

    gotoFilteredTalentPool = id => {
        this.props.history.push(`talent-pool/?jobId=${id}`)
    }

    addCoordinator = async (coordinator, jobId) => {
        try {
            let { openings } = this.state
            const data = {
                companyId: this.props.companyId,
                jobId,
                userId: coordinator._id,
            }
            this.props.AddJobCoordinator(data)
            const index = openings.findIndex(job => job._id === jobId)
            openings[index].coordinators.push(coordinator)
            this.setState({
                openings,
                showTeamMemberModal: false,
                selectedJob: null,
                coordinatorText: null,
            })
        } catch (err) {
            warning(err.message || err)
        }
    }
    removeCoordinator = async (coordinator, jobId) => {
        try {
            let { openings } = this.state
            const data = {
                companyId: this.props.companyId,
                jobId,
                userId: coordinator._id,
            }
            this.props.RemoveJobCoordinator(data)
            const index = openings.findIndex(job => job._id === jobId)
            const oo = openings[index].coordinators.filter(c => {
                return c._id !== coordinator._id
            })
            openings[index].coordinators = oo
            this.setState({
                openings,
                showTeamMemberModal: false,
                selectedJob: null,
                coordinatorText: null,
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    copyToClipboard = (str, type) => {
        let message = ''
        if (type === 'uniqueEmailId') {
            message = `The unique email id “${str}” to apply for the job has been copied to clipboard.`
        } else if (type === 'uniqueReferral') {
            str = `${window.location.origin}/applynow/${str}`
            message = `The job link “${str}” to apply for the job has been copied to clipboard.`
        } else {
            str = `${window.location.origin}/refernow/${str}`
            message = `The job link “${str}” to apply for the job has been copied to clipboard.`
        }

        const el = document.createElement('textarea')
        el.value = str
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)

        success(message)
    }

    handleCancel = () => {
        this.setState({
            showTeamMemberModal: false,
            selectedJob: null,
            showArchiveWarning: false,
            showCloneJobWarning: false,
            showJobDelete: false,
            archivingJobId: null,
        })
    }

    addTeamMember = (jobId, index) => {
        this.setState({ showTeamMemberModal: true, selectedJob: jobId }, () => {
            const { openings } = this.state
            openings[index].popoverVisible = false
            this.setState({ openings })
        })
    }

    archiveJob = async (jobId, sendEmailToCandidate = true) => {
        try {
            let { openings } = this.state
            const index = openings.findIndex(job => job._id === jobId)

            this.props.UpdateJobOpening(jobId, {
                status: OPENING_STATUS.ARCHIVED,
                companyId: this.props.companyId,
                feedbackForm: openings[index].feedbackForm,
                sendEmailToCandidate,
            })

            openings[index].status = OPENING_STATUS.ARCHIVED
            success(`Opening ${openings[index].jobTitle || ''} has been archived and moved to the bottom of the list.`)
            this.setState({
                openings: openings.sort((a, b) => (b.status ? 1 : -1)),
            })
        } catch (err) {
            warning(err.message || err)
        } finally {
            this.setState({
                showArchiveWarning: false,
                archivingJobId: null,
            })
        }
    }

    showArchiveWarning = (jobId, totalCandidates) => {
        this.setState({
            showArchiveWarning: true,
            candidateCount: totalCandidates,
            archivingJobId: jobId,
        })
    }

    showCloneJobWarning = _id => {
        this.setState({
            showCloneJobWarning: true,
            selectedJobId: _id,
        })
    }

    showJobDeleteWarning = jobId => {
        let { openings } = this.state
        const index = openings.findIndex(job => job._id === jobId)
        this.setState({
            showJobDelete: true,
            selectedJob: openings[index],
        })
    }

    deleteJob = jobId => {
        this.setState({ showJobDelete: false })
        const { openings } = this.state
        DeleteJobOpening({
            jobId,
            companyId: this.props.companyId,
        })
            .then(res => {
                if (res && res.msg && res.msg === 'Job Deleted') {
                    success('Job Opening Deleted Successfully.')
                    const filteredOpeings = openings.filter(job => job._id !== jobId)
                    this.setState({ openings: filteredOpeings })
                } else {
                    warning('Something went wrong.')
                }
            })
            .catch(err => {
                warning(err.message || err)
            })
    }

    unarchiveJob = async jobId => {
        try {
            let { openings } = this.state
            const index = openings.findIndex(job => job._id === jobId)

            this.props.UpdateJobOpening(jobId, {
                status: OPENING_STATUS.ACTIVE,
                companyId: this.props.companyId,
                feedbackForm: openings[index].feedbackForm,
            })

            openings[index].status = OPENING_STATUS.ACTIVE
            success(`Opening ${openings[index].jobTitle || ''} has been unarchived and moved to the top of the list.`)
            const final = openings.findIndex(job => job._id === jobId)
            openings.splice(0, 0, openings[final])
            openings.splice(final + 1, 1)
            this.setState({ openings })
        } catch (err) {
            warning(err.message || err)
        }
    }

    unpublishJob = async jobId => {
        try {
            let { openings } = this.state
            const index = openings.findIndex(job => job._id === jobId)

            this.props.UpdateJobOpening(jobId, {
                status: OPENING_STATUS.UNPUBLISHED,
                companyId: this.props.companyId,
                feedbackForm: openings[index].feedbackForm,
            })

            openings[index].status = OPENING_STATUS.UNPUBLISHED
            this.setState({ openings })
            success(`Opening ${openings[index].jobTitle || ''} has been unpublished.`)
        } catch (err) {
            warning(err.message || err)
        }
    }

    publishJob = async jobId => {
        try {
            let { openings } = this.state
            const index = openings.findIndex(job => job._id === jobId)

            this.props.UpdateJobOpening(jobId, {
                status: OPENING_STATUS.ACTIVE,
                companyId: this.props.companyId,
                feedbackForm: openings[index].feedbackForm,
            })

            openings[index].status = OPENING_STATUS.ACTIVE
            this.setState({ openings })
            success(`Opening ${openings[index].jobTitle || ''} is now active.`)
        } catch (err) {
            warning(err.message || err)
        }
    }

    addTeamMemberAsCoordinator = ({ email, accessibleJobs, accessLevel }) => {
        try {
            this.setState({ callInProgress: true }, async () => {
                let details = {
                    email,
                    accessibleJobs,
                    accessLevel,
                    userId: this.props.userId,
                    companyId: this.props.companyId,
                }
                const response = await AddTeamMember(details)
                this.addCoordinator(response.user, this.state.selectedJob)
                this.setState({
                    showTeamMemberModal: false,
                    callInProgress: false,
                    selectedJob: null,
                })
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    renderMoreOptions({ _id, status, uniqueReferal, totalCandidates }, index) {
        const { accessLevel, openings } = this.state
        const options = [
            { name: 'View board', action: () => this.gotToDetailView('board', _id), condition: Boolean(status) },
            {
                name: 'Edit opening',
                action: () => this.props.history.push(`/openings/edit/${_id}`),
                condition: accessLevel < 3 && Boolean(status),
            },
            {
                name: 'Clone Job',
                action: () => this.showCloneJobWarning(_id),
                condition: accessLevel < 3 && Boolean(status),
            },
            {
                name: 'Add candidate',
                action: async () => {
                    openings[index].show = false
                    this.setState({ disbaleClick: true, openings })
                    const jobDetails = await this.props.GetJobDetails(_id, this.props.companyId)
                    this.setState({ disbaleClick: false })
                    this.props.addCandidate(jobDetails)
                },
                condition: Boolean(status) && (() => validateJobId(_id)),
            },
            {
                name: 'Share job opening',
                action: () => this.props.history.push({ pathname: `/openings/edit/${_id}`, state: 'promote' }),
                condition: accessLevel < 3 && Boolean(status),
            },
            {
                name: 'View job application URL',
                action: () => window.open(`/applynow/${uniqueReferal}`, '_blank'),
                condition: Boolean(status),
            },
            // {
            //     name: 'View referral job application URL',
            //     action: () => window.open(`/refernow/${uniqueReferal}`, '_blank'),
            //     condition: Boolean(status),
            // },
            {
                name: 'Copy job application URL',
                action: () => this.copyToClipboard(uniqueReferal, 'uniqueReferral'),
                condition: Boolean(status),
            },
            {
                name: 'Copy referral application URL',
                action: () => this.copyToClipboard(uniqueReferal, 'referralLink'),
                condition: Boolean(status),
            },
            {
                name: 'View all applicants',
                action: () => (Boolean(status) ? this.gotToDetailView('list', _id) : this.gotoFilteredTalentPool(_id)),
                condition: true,
            },
            {
                name: 'Archive opening',
                action: () =>
                    totalCandidates > 0 ? this.showArchiveWarning(_id, totalCandidates) : this.archiveJob(_id),
                condition: accessLevel < 3 && OPENING_STATUS.ACTIVE === status,
            },
            {
                name: 'Unarchive opening',
                action: () => this.unarchiveJob(_id),
                condition: accessLevel < 3 && OPENING_STATUS.ARCHIVED === status,
            },
            {
                name: 'Delete opening',
                action: () => this.showJobDeleteWarning(_id),
                condition: accessLevel < 3,
            },
            {
                name: 'Unpublish opening',
                action: () => this.unpublishJob(_id),
                condition: accessLevel < 3 && OPENING_STATUS.ACTIVE === status,
            },
            {
                name: 'Publish opening',
                action: () => this.publishJob(_id),
                condition: accessLevel < 3 && OPENING_STATUS.UNPUBLISHED === status,
            },
        ]

        return (
            <Menu className="card-option-cont">
                {options.map((option, index) => {
                    return (
                        option.condition && (
                            <Menu.Item
                                disabled={this.state.disbaleClick}
                                className="card-options"
                                key={index}
                                onClick={option.action}
                            >
                                <span className="card-option-color">{option.name}</span>
                            </Menu.Item>
                        )
                    )
                })}
            </Menu>
        )
    }

    render() {
        const { accessLevel, coordinatorText, coordinators, openings } = this.state
        return (
            <div className="card-container">
                {openings.map((data, index) => {
                    let candidates = _.reduce(
                        data.interviewStages.map(stage => stage.candidateCount),
                        (result = 0, value) => {
                            return result + value
                        }
                    )
                    candidates = candidates ? candidates : 0
                    data.totalCandidates = candidates
                    return (
                        <div className="opening-card" key={index}>
                            <div className="opening-designation">
                                <h3
                                    onClick={() =>
                                        Boolean(data.status) ? this.gotToDetailView('board', data._id) : null
                                    }
                                    className={Boolean(data.status) ? 'cursor-pointer' : ''}
                                >
                                    {data.jobTitle}
                                    {OPENING_STATUS.ARCHIVED === data.status && (
                                        <span className="openings-tag">Archived</span>
                                    )}
                                    {OPENING_STATUS.UNPUBLISHED === data.status && (
                                        <span className="openings-tag">Unpublished</span>
                                    )}
                                </h3>

                                <div className="opening-exp-n-location-parent">
                                    <div className="opening-exp-n-location">
                                        <div className="open-exp-n-location">
                                            {data.minExperience !== null && data.maxExperience !== null ? (
                                                data.minExperience === 0 && data.maxExperience === 0 ? (
                                                    <p>
                                                        <Icon component={Briefcase} />
                                                        Fresher
                                                    </p>
                                                ) : (
                                                    <p>
                                                        <Icon component={Briefcase} />
                                                        {data.minExperience} - {data.maxExperience} Years
                                                    </p>
                                                )
                                            ) : null}
                                        </div>
                                        <div className="open-exp-n-location">
                                            <p>
                                                <Icon component={Location} />
                                                {data.jobLocation}
                                            </p>
                                        </div>
                                        {data.agencyStatus === 1 && (
                                            <div className="open-exp-n-location">
                                                <p>
                                                    <Icon component={CompanyIcon} />
                                                    {data.companyName}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="opening-addCan-dropdown-parent">
                                        <div className="openings-add-candidate">
                                            {accessLevel !== 4 &&
                                                data.coordinators &&
                                                data.coordinators.map((c, ind) => {
                                                    const co = coordinators.find(x => {
                                                        return x._id === c._id
                                                    })
                                                    if (co && co.status === 1)
                                                        return (
                                                            <div className="circle" key={ind}>
                                                                <Tooltip placement="top" title={c.fullName || c.email}>
                                                                    {getAvatar(c.fullName || c.email)}
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                })}
                                            {[OPENING_STATUS.ACTIVE, OPENING_STATUS.UNPUBLISHED].includes(
                                                data.status
                                            ) ? (
                                                <Popover
                                                    getPopupContainer={() =>
                                                        document.getElementById('add-co-ordinator')
                                                    }
                                                    placement="bottomRight"
                                                    trigger="click"
                                                    content={
                                                        <div className="coordinator-popover">
                                                            <Search
                                                                placeholder="Search name or email"
                                                                onChange={e =>
                                                                    this.setState({
                                                                        coordinatorText: e.target.value,
                                                                    })
                                                                }
                                                                className="coordinator-popover-search"
                                                                value={coordinatorText}
                                                            />

                                                            {this.isCoordinatorValid().valid && coordinatorText ? (
                                                                <div className="coordinator-list-result">
                                                                    {coordinators
                                                                        .filter(
                                                                            x =>
                                                                                x.status === 1 &&
                                                                                x.email
                                                                                    .toLowerCase()
                                                                                    .includes(
                                                                                        coordinatorText.toLowerCase()
                                                                                    )
                                                                        )
                                                                        .map((c, ind) => (
                                                                            <div
                                                                                key={ind}
                                                                                style={{
                                                                                    display: 'flex',
                                                                                }}
                                                                            >
                                                                                <Avatar
                                                                                    shape="square"
                                                                                    className="coordinator-list-result-avatar"
                                                                                >
                                                                                    {getAvatar(c.email)}
                                                                                </Avatar>
                                                                                <div className="coordinator-result-name">
                                                                                    {c.email}
                                                                                </div>
                                                                                {!data.coordinators.find(
                                                                                    cor => cor._id === c._id
                                                                                ) ? (
                                                                                    <Button
                                                                                        ghost
                                                                                        size="large"
                                                                                        type="primary"
                                                                                        className="coordinator-result-btn"
                                                                                        onClick={() =>
                                                                                            this.addCoordinator(
                                                                                                c,
                                                                                                data._id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Add
                                                                                    </Button>
                                                                                ) : (
                                                                                    <Button
                                                                                        ghost
                                                                                        size="large"
                                                                                        type="primary"
                                                                                        className="coordinator-result-btn"
                                                                                        onClick={() =>
                                                                                            this.removeCoordinator(
                                                                                                c,
                                                                                                data._id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Remove
                                                                                    </Button>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            ) : null}

                                                            {!this.isCoordinatorValid().valid ? (
                                                                <div>
                                                                    <small
                                                                        style={{
                                                                            color: '#bfbfbf',
                                                                        }}
                                                                    >
                                                                        No result found
                                                                    </small>
                                                                </div>
                                                            ) : null}

                                                            {!this.isCoordinatorValid().valid &&
                                                            this.isCoordinatorValid().isEmail ? (
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        marginTop: '10px',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            margin: 'auto 0',
                                                                        }}
                                                                    >
                                                                        {coordinatorText}
                                                                    </div>
                                                                    <Button
                                                                        ghost
                                                                        size="large"
                                                                        type="primary"
                                                                        style={{
                                                                            border: 'none',
                                                                            boxShadow: 'none',
                                                                            marginLeft: 'auto',
                                                                        }}
                                                                        onClick={() =>
                                                                            this.addTeamMember(data._id, index)
                                                                        }
                                                                    >
                                                                        Invite
                                                                    </Button>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    visible={data.popoverVisible}
                                                    onVisibleChange={this.handleCoordinatorPopover(index)}
                                                >
                                                    <Tooltip
                                                        placement="top"
                                                        overlayClassName="tool-tip-overlay"
                                                        title="Click to add coordinators for this opening"
                                                        getPopupContainer={() =>
                                                            document.getElementById('add-co-ordinator')
                                                        }
                                                    >
                                                        <div className="cursor-pointer circle" hidden={accessLevel > 2}>
                                                            <Button id="add-co-ordinator">
                                                                <Icon component={PlusIcon} />
                                                            </Button>
                                                        </div>
                                                    </Tooltip>
                                                </Popover>
                                            ) : null}
                                        </div>

                                        <Popover
                                            placement="bottomRight"
                                            arrowPointAtCenter
                                            trigger="click"
                                            getPopupContainer={() => document.getElementById('moreButton')}
                                            content={this.renderMoreOptions(data, index)}
                                            visible={data.show}
                                            onVisibleChange={this.handleVisibleClick(index)}
                                        >
                                            <Button
                                                id="moreButton"
                                                style={{
                                                    border: 'none',
                                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                                }}
                                            >
                                                <Icon component={MoreOptionIcon} className="cursor-pointer" />
                                            </Button>
                                        </Popover>
                                    </div>
                                </div>
                            </div>

                            <div className="opening-alloments">
                                {data.interviewStages &&
                                    data.interviewStages.map(stage => (
                                        <div className="opening-alloments-inner" key={stage._id}>
                                            <div>
                                                <span>{stage.candidateCount}</span>
                                                {toCustomFirstCharCap(stage.stageName)}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )
                })}

                {this.state.showTeamMemberModal && (
                    <FSModal
                        type={FSMODAL_TYPES.INVITE_TEAM_MEMBER}
                        visible={this.state.showTeamMemberModal}
                        onCancel={this.handleCancel}
                        selectedUser={{ email: coordinatorText }}
                        onConfirm={this.addTeamMemberAsCoordinator}
                        callInProgress={this.state.callInProgress}
                    />
                )}

                <Modal
                    type={MODAL_TYPES.CONFIRM_ARCHIVE_OPENING_ALERT}
                    onConfirm={sendEmailToCandidate => this.archiveJob(this.state.archivingJobId, sendEmailToCandidate)}
                    redirect={() => this.gotToDetailView('board', this.state.archivingJobId)}
                    onCancel={this.handleCancel}
                    visible={this.state.showArchiveWarning}
                    activeCandidates={this.state.candidateCount}
                />
                <Modal
                    type={MODAL_TYPES.CONFIRM_CLONE_JOB_ALERT}
                    onConfirm={() => this.props.history.push(`/openings/clone/${this.state.selectedJobId}`)}
                    onCancel={this.handleCancel}
                    visible={this.state.showCloneJobWarning}
                />
                <Modal
                    type={MODAL_TYPES.CONFIRM_DELETE_JOB}
                    visible={this.state.showJobDelete}
                    onCancel={this.handleCancel}
                    onConfirm={data => this.deleteJob(data)}
                    data={this.state.selectedJob}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        coordinators: state.Mixed.teamMembers.filter(each => each.accessLevel !== 5),
        accessLevel: state.Auth.user.accessLevel,
        companyId: state.Auth.user.companyId,
        userId: state.Auth.user.id,
    }
}

export default connect(
    mapStateToProps,
    { AddJobCoordinator, UpdateJobOpening, GetJobDetails, RemoveJobCoordinator }
)(OpeningCard)
