import React from 'react'
import { Button } from 'antd'

const FloatingContainer = ({ addSection, onPublish, onPreview }) => {
    return (
        <div className="career-btns">
            <div className="career-button-float-container">
                <div className="career-btn-individual">
                    <Button className="career-footer-btn" type="primary" size="small" onClick={addSection}>
                        ADD SECTION
                    </Button>
                </div>
                <div className="career-btn-individual">
                    <Button className="career-footer-btn" type="primary" size="small" onClick={onPreview}>
                        PREVIEW
                    </Button>
                </div>
                <div className="career-btn-individual">
                    <Button className="career-footer-btn" type="primary" size="small" onClick={onPublish}>
                        PUBLISH
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default FloatingContainer
