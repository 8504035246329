import React from 'react'

function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="15" width="12" viewBox="0 0 12 15">
            <path
                fill="#8A94A6"
                fillRule="nonzero"
                stroke="none"
                strokeWidth="1"
                d="M.706 15l7.941-.018a.66.66 0 00.653-.669v-2.042h1.994c.37 0 .653-.307.653-.687V4.5a.982.982 0 00-.23-.578L8.242.325A.84.84 0 007.676.09H3.353c-.37 0-.653.308-.67.669v2.042H.705c-.37 0-.67.307-.67.669v10.843c0 .38.3.687.67.687zm9.688-10.5H7.66V1.645L10.394 4.5zm-6.37-3.054h2.311V4.5c0 .741.6 1.355 1.324 1.355h2.982v5.079H4.024V1.446zm-2.648 2.71H2.7v7.446c0 .38.282.67.653.687h4.641v1.356H1.376V4.157z"
                transform="translate(-1185 -475) translate(326 202) translate(200 267) translate(659 6)"
            />
        </svg>
    )
}

export default Icon
