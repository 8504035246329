import styled, { css } from 'styled-components'
import { DIVISION_COLOR, DARK_BLUE, BLUE_GREY, RED } from '../Helpers/Stylesheet/styledComponents'
import { StyledButton } from '../Components'
import Tick from '../../assets/Icons/Access/tick.svg'

export const Wrapper = styled.div`
    max-width: 1280px;
    margin: auto;
`

export const ModalHeaderWrapper = styled.div`
    font-family: sanFranciscoMedium;
    font-size: 24px;
    line-height: 26px;
    font-weight: bold;
    display: block;
    color: black;
    ${({ centeredTitle }) => (centeredTitle ? 'text-align: center;' : '')};
    letter-spacing: normal;
    @media only screen and (max-width: 769px) {
        font-size: 16px;
        line-height: 18px;
    }
    .division-line {
        margin-top: 25px;
        width: 100%;
        height: 1px;
        background-color: ${DIVISION_COLOR};
        @media only screen and (max-width: 769px) {
            margin-top: 20px;
        }
    }
    .subtitle {
        font-family: sanFrancisco;
        font-size: 14px;
        margin-top: 5px;
        color: ${BLUE_GREY};
    }
`

export const FooterButtons = styled(StyledButton)`
    width: ${props => (props.width ? props.width : '80px')};
    height: 40px;
    text-transform: uppercase;
`
export const ModalFooterWrapper = styled.div`
    .buttons {
        display: flex;
        flex-direction: row;
        &.centered {
            justify-content: center;
            > div {
                margin-right: 0;
            }
        }
    }
    .separator {
        width: 100%;
        height: 1px;
        margin-bottom: 25px;
        background-color: ${DIVISION_COLOR};
        @media only screen and (max-width: 769px) {
            margin-bottom: 20px;
        }
    }
`

export const Content = styled.div`
    font-size: 16px;
    line-height: 1.25;
    margin: 25px 0;
    font-family: sanFrancisco;
    color: #333;
    b {
        font-family: sanFranciscoSemi;
    }
`

export const ContentWrapper = styled.div`
    ${({ mTop = true }) => (mTop ? 'margin-top: 30px' : '')};
    margin-bottom: 30px;

    @media only screen and (max-width: 769px) {
        margin-bottom: 20px;
        ${({ mTop = true }) => (mTop ? 'margin-top: 20px' : '')};
    }
`

export const ChangePassElementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 31px;
`

export const Divider = styled.div`
    margin-top: 25px;
    width: 100%;
    height: 1px;
    background-color: ${DIVISION_COLOR};
`

/* ===================
    FULLSCREEN MODAL
   =================== */

export const FSModalWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #fff;
    overflow: hidden;
`

export const FSModalHeaderWrapper = styled.div`
    padding: 40px 40px 20px;
    background: #fff;
    .title {
        font-family: SFProText-Heavy;
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
    }
    .subtitle {
        font-size: 16px;
        color: #333;
    }
    .division-line {
        margin-top: 25px;
        width: 100%;
        height: 1px;
        background-color: ${DIVISION_COLOR};
    }
    @media only screen and (max-width: 769px) {
        padding: 20px;
    }
`

export const FSContentWrapper = styled.div`
    padding: 40px;
    margin-bottom: 80px;
    .title {
        font-family: sanFranciscoMedium;
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin-bottom: 10px;
        margin-top: 30px;
    }
    .ant-select-arrow .ant-select-arrow-icon svg {
        width: 10px;
    }
    .ant-select-selection-selected-value,
    .ant-input {
        font-size: 12px;
        color: #666;
    }
    .ant-input {
        border: 1px solid #d9d9d9;
    }
    .ant-select-selection__placeholder,
    .ant-input::placeholder {
        font-size: 12px;
        color: #999999;
    }
    .item {
        margin: 20px 0;
        .ant-select {
            width: 250px;
        }
        ${({ jobsError }) => (jobsError.length ? `.ant-select-selection { border: 1px solid ${RED}; }` : '')};
    }
    @media only screen and (max-width: 769px) {
        padding: 0 20px 20px;
    }
    .definition {
        border-radius: 5px;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
        height: 320px;
        overflow-y: auto;
        @media (max-width: 767px) {
            height: auto;
        }
    }
    .collapsible-header {
        display: flex;
        justify-content: space-between;
        background: #fff;
        padding: 20px 0;
        border-radius: 5px 5px 0px 0px;
        top: 0;
        position: sticky;
        font-size: 14px;
        font-weight: bold;
        font-family: sanFranciscoSemi;
        text-transform: uppercase;
        color: #333;
        div {
            width: 13%;
            :first-child {
                text-align: left;
                padding-left: 55px;
                width: 30%;
            }
            :last-child {
                padding-right: 35px;
            }
            :not(:first-child) {
                text-align: center;
            }
        }
        @media (max-width: 767px) {
            padding: 10px 0;
            font-size: 12px;
            div:first-child {
                width: auto;
                padding-left: 35px;
            }
        }
    }
`
export const StickyFooterWrapper = styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    background: #fff;
    height: 80px;
    align-items: center;
    padding: 0 50px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    @media (max-width: 767px) {
        padding: 0 20px;
    }
    @media (min-width: 1280px) {
        padding: 0px calc((100% - 1190px) / 2);
    }
`
export const Inputs = styled.div`
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 15px 30px;
    .ant-form {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
        grid-gap: 20px 30px;
        .ant-form-item {
            margin-bottom: 10px;
        }
    }

    ${({ emailError }) => (emailError.length ? `.ant-input { border: 1px solid ${RED}; }` : '')};

    @media (max-width: 767px) {
        padding: 15px;
        .ant-form-item {
            margin-bottom: 0;
        }
    }
`
export const Input = styled.input`
    width: 272px;
    height: 42px;
`
export const Cards = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
    grid-gap: 20px;
    @media only screen and (min-width: 1025px) and (max-width: 1280px) {
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
`
export const Card = styled.div`
    padding: 20px;
    border-radius: 5px;
    box-shadow: ${props => (props.active ? '0 0 10px 0 rgba(71, 103, 160, 0.5)' : '0 0 3px 0 rgba(0, 0, 0, 0.15)')};
    ${props => (props.error ? 'border: solid 1px #e02020' : '')};
    background-color: #ffffff;
    cursor: pointer;
    svg {
        margin: auto;
        display: block;
        margin-bottom: 20px;
    }
    .content {
        min-height: 175px;
    }
    .title {
        font-family: sanFranciscoSemi;
        font-size: 14px;
    }
    p {
        display: flex;
        font-size: 12px;
        color: #666666;
        &::before {
            content: url(${Tick});
            margin-right: 8px;
        }
    }
    button {
        height: 30px;
        border-radius: 4px;
        border: solid 1px #4767a0;
        margin-top: 1em;
        cursor: pointer;
        :focus {
            outline: none;
        }
    }
    @media (max-width: 767px) {
        .content {
            min-height: initial;
        }
    }
    ${props =>
        props.active
            ? css`
                  button {
                      color: #ffffff;
                      background-color: #4767a0;
                      &:hover {
                          opacity: 0.8;
                      }
                  }
              `
            : css`
                  button {
                      color: #4767a0;
                      background-color: #fff;
                      &:hover {
                          border-width: 1.5px;
                          font-weight: bold;
                      }
                  }
              `}
`
export const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    div {
        display: flex;
        align-items: center;
        border-radius: 13.5px;
        background-color: #ccdbf8;
        line-height: initial;
        margin-right: 10px;
        margin-bottom: 5px;
        font-size: 12px;
        color: #4767a0;
        span {
            padding: 4px 12px;
            display: inline-block;
            word-break: break-all;
        }
        i {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 25px;
            border-radius: 0 13.5px 13.5px 0;
            background: #c1cfeb;
            cursor: pointer;
        }
    }
`
export const Collapsible = styled.div`
    .category {
        background-color: #fbfbfb;
        color: #333;
        cursor: pointer;
        padding: 15px;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        font-family: sanFranciscoSemi;
        text-transform: uppercase;
        padding-left: 30px;
        > span,
        > div span {
            margin-left: 10px;
        }
        * {
            vertical-align: middle;
        }
        &.active + .list {
            max-height: calc(${props => props.items} * 60px);
            transition: max-height 0.3s ease-in;
        }
    }
    &:not(:first-child) .category {
        border-top: 1px solid ${DIVISION_COLOR};
    }

    .list {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        background-color: #fff;
    }

    .sub-category {
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        span {
            width: 13%;
            text-align: center;
            font-size: 16px;
            color: #333;
            :first-child {
                text-align: left;
                padding-left: 55px;
                width: 30%;
            }
            :last-child {
                padding-right: 35px;
            }
        }
    }
    @media (max-width: 767px) {
        .category {
            padding: 10px;
            padding-left: 55px;
            font-size: 12px;
            svg {
                margin-left: -45px;
            }
            &.active + .list {
                max-height: calc(${props => props.items} * 250px);
                transition: max-height 0.5s ease-in;
            }
        }
        .sub-category span:first-child {
            padding-left: 35px;
        }
        .sub-category span:last-child {
            padding-right: 0;
        }
        .sub-category {
            padding: 10px 0;
            :not(:first-child) {
                margin-top: 10px;
            }
            span,
            span:first-child {
                width: auto;
            }
        }
        .list div:not(.sub-category) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            font-weight: bold;
            font-family: sanFranciscoMedium;
            text-transform: uppercase;
            color: #333;
            padding: 10px 35px;
        }
    }
`

/* ===================
    OTHER COMPONENT
 =================== */
export const FeedbackOpinionEditorBlock = styled.div`
    cursor: pointer;
    margin-right: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70px;

    :last-child {
        margin-right: 0;
    }

    p {
        color: ${({ isActive }) => (isActive ? '#c8ccca' : DARK_BLUE)};
        font-family: sanFrancisco;
        font-size: 12px;
        line-height: 13px;
        text-align: center;
        margin-top: 8px;
    }

    .icon-image {
        margin: 0 auto;
        ${({ size = 60 }) => `width:${size}px; height:${size}px`}
    }

    @media (max-width: 767px) {
        .icon-image {
            ${({ size = 50 }) => `width:${size}px; height:${size}px`}
        }
    }
`
