import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Input, Tooltip, Skeleton } from 'antd'
import ComponentContainer from '../ComponentContainer'
import StyledSwitch from '../../../../Components/StyledSwitch'
import { Modal } from '../../../../Modals'
import { Footer } from '../../../../Modals/common'
import { ACCESS_LEVEL_DEFINITIONS } from '../../../../Helpers/constants'
import { MODAL_TYPES } from '../../../../Helpers/ModalTypes'
import { ChangePassword } from '../../../../../services/NetworkCalls/Authentication'
import { success, warning } from '../../../../../services/Notification'
import { UpdateUserDetails, UpdateEmailNotifications } from '../../../../../services/NetworkCalls/User'
import _ from 'lodash'
import * as querystring from 'querystring'
import { UserEditPass, UserDetails } from './style.js'
import { CANDIDATE } from '../../../../Helpers/RegEx'

class UserSettings extends Component {
    constructor(props) {
        super(props)

        const {
            user: { userName: fullName, companyName, websiteUrl, companyId },
        } = props

        this.state = {
            company: {
                fullName,
                companyName,
                websiteUrl,
                companyId,
                candidateCoolOffPeriod: (props.companySettings && props.companySettings.candidateCoolOffPeriod) || 6,
            },
            notifyByEmail: props.notifyByEmail,
            emailNotifications: props.emailNotifications,
            companySettings: props.companySettings,
            isLoading: props.isLoading,
            accountEdit: false,
            manageNotificationsModalVisible: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { notifyByEmail, emailNotifications, isLoading, location, companySettings } = this.props
        if (notifyByEmail !== prevProps.notifyByEmail) this.setState({ notifyByEmail })
        if (isLoading !== prevProps.isLoading)
            this.setState(state => {
                let { notification } = querystring.parse(location.search.substring(1))
                if (notification === '1')
                    return { emailNotifications, manageNotificationsModalVisible: true, isLoading, companySettings }
                return {
                    emailNotifications,
                    isLoading,
                    companySettings,
                    company: { ...state.company, candidateCoolOffPeriod: companySettings.candidateCoolOffPeriod },
                }
            })
    }

    accountDetailEdit = () => {
        this.setState({ accountEdit: true })
    }

    handleChange = ({ target: { name, value } }) => {
        const { company } = this.state
        company[name] = value
        this.setState({ company })
    }

    handleCancel = () => {
        let { company } = this.state
        company.fullName = this.props.user.userName
        company.companyName = this.props.user.companyName
        this.setState({
            accountEdit: false,
            manageNotificationsModalVisible: false,
            company,
        })
    }

    showManageNotificationsModal = () => {
        if (this.state.isLoading) warning('Please wait while the data is loading. Retry after some time')
        else this.setState({ manageNotificationsModalVisible: true })
    }

    manageNotifications = () => {
        try {
            const notifyByEmail = !this.state.notifyByEmail
            if (notifyByEmail === false) {
                const defaultInput = {
                    candidate: {
                        added: false,
                        message_sent: false,
                        note_added: false,
                        stage_moved: false,
                    },
                    feedback: {
                        added: false,
                    },
                    companySettings: {
                        disableAllCandidateEmail: false,
                        disableNotifyCandidateEmail: false,
                    },
                }
                this.props.UpdateEmailNotifications({ input: defaultInput, companyId: this.props.user.companyId })
                this.setState({
                    notifyByEmail,
                    emailNotifications: defaultInput,
                })
            } else {
                const emailNotifications = {
                    candidate: {
                        added: false,
                        message_sent: false,
                        note_added: false,
                        stage_moved: false,
                    },
                    feedback: {
                        added: false,
                    },
                }
                this.setState({ emailNotifications }, () => this.showManageNotificationsModal())
            }
        } catch (err) {
            warning(err.message || err)
        }
    }

    updateNotificationSettings = input => {
        try {
            const { candidate, feedback, companySettings } = input
            this.props.UpdateEmailNotifications({ input, companyId: this.props.user.companyId })
            this.handleCancel()
            this.setState({
                notifyByEmail:
                    candidate.added ||
                    candidate.message_sent ||
                    candidate.note_added ||
                    candidate.stage_moved ||
                    feedback.added,
                emailNotifications: { candidate, feedback },
                companySettings,
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    saveAccountDetails = async () => {
        try {
            let { company } = this.state
            let {
                company: { fullName, companyName, candidateCoolOffPeriod },
            } = this.state
            fullName = fullName.trim()
            companyName = companyName.trim()

            // validation checks
            if (fullName === null || fullName.length === 0) {
                warning('Full Name cannot be empty')
                return null
            }

            if (candidateCoolOffPeriod === null || candidateCoolOffPeriod === '') {
                warning('Candidate Cool Off Period cannot be empty')
                return null
            }

            if (companyName === null || companyName.length === 0) {
                warning('Company Name cannot be empty')
                return null
            }

            if (!CANDIDATE.NAME.test(fullName)) {
                warning('Invalid full name')
                return null
            }

            if (CANDIDATE.NOSPECIALCHAR.test(companyName)) {
                warning('No special characters allowed in Company Name')
                return null
            }

            company.userId = this.props.user.id
            company.fullName = fullName
            company.companyName = companyName

            this.props.UpdateUserDetails(company)
            this.setState({
                accountEdit: false,
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    render() {
        const { company, accountEdit, notifyByEmail, isLoading, emailNotifications, companySettings } = this.state
        const { email, accessLevel } = this.props.user
        const tooltipText = 'Only applicable for events which can be turned off'
        return (
            <ComponentContainer
                heading="Account Details"
                headingOptions={
                    <UserEditPass>
                        {!accountEdit && (
                            <span onClick={() => this.accountDetailEdit()}>
                                <img src={require('./images/edit.svg').default} />
                                Edit
                            </span>
                        )}
                        <span onClick={() => this.props.showChangePasswordModal()}>
                            <img src={require('./images/change-password.svg').default} />
                            Change Password
                        </span>
                    </UserEditPass>
                }
            >
                <UserDetails>
                    <div className="userLabelsValues">
                        <label className="userLabels">Full Name:</label>
                        {accountEdit ? (
                            <Input
                                size="large"
                                value={company.fullName}
                                onChange={this.handleChange}
                                name="fullName"
                                className="userInputField"
                            />
                        ) : (
                            <span>{company.fullName}</span>
                        )}
                    </div>

                    <div className="userLabelsValues">
                        <label className="userLabels">Company ID:</label>
                        <span>{company.companyId}</span>
                    </div>

                    <div className="userLabelsValues">
                        <label className="userLabels">Company Name:</label>
                        {accountEdit ? (
                            <Input
                                size="large"
                                value={company.companyName}
                                onChange={this.handleChange}
                                name="companyName"
                                className="userInputField"
                            />
                        ) : (
                            <span>{company.companyName}</span>
                        )}
                    </div>
                    <div className="userLabelsValues">
                        <label className="userLabels">Website URL:</label>
                        <span>
                            <a href={`https://${company.websiteUrl}`} target="_blank">
                                {`https://${company.websiteUrl}`}
                            </a>
                        </span>
                    </div>
                    <div className="userLabelsValues">
                        <label className="userLabels">Email:</label>
                        <span>{email}</span>
                        <div className="userDetailsAL">
                            {_.values(ACCESS_LEVEL_DEFINITIONS).find(role => role.accessLevel === accessLevel).name}
                        </div>
                    </div>
                    <div className="userLabelsValues">
                        <label className="userLabels">Candidate Cool Off Period:</label>
                        {accountEdit ? (
                            <Input
                                size="large"
                                value={company.candidateCoolOffPeriod}
                                onChange={this.handleChange}
                                placeholder={company.candidateCoolOffPeriod ? company.candidateCoolOffPeriod : 6}
                                name="candidateCoolOffPeriod"
                                className="userInputField"
                                type="Number"
                            />
                        ) : (
                            <span>
                                {isLoading ? (
                                    <Skeleton paragraph={{ rows: 0 }} active />
                                ) : (
                                    <>{company.candidateCoolOffPeriod} Months</>
                                )}
                            </span>
                        )}
                    </div>
                    <div className="userLabelsValues">
                        <label className="userLabels">
                            Notify By Email:
                            <Tooltip
                                mouseEnterDelay={0.3}
                                title={tooltipText}
                                overlayClassName="fs-12"
                                overlayStyle={{ minWidth: '310px' }}
                            >
                                <img src={require('./images/info.svg').default} />
                            </Tooltip>
                        </label>
                        <span className="manageNotification">
                            {isLoading ? (
                                <Skeleton paragraph={{ rows: 0 }} active />
                            ) : (
                                <StyledSwitch checked={notifyByEmail} onChange={this.manageNotifications} />
                            )}
                            <div
                                className="manageNotificationLabel"
                                hidden={isLoading || !notifyByEmail}
                                onClick={this.showManageNotificationsModal}
                            >
                                Manage Notifications
                            </div>
                        </span>
                    </div>
                    {accountEdit && (
                        <Footer
                            onCancel={this.handleCancel}
                            onConfirm={this.saveAccountDetails}
                            cancelText="CANCEL"
                            confirmText="SAVE"
                        />
                    )}
                </UserDetails>

                <Modal
                    type={MODAL_TYPES.MANAGE_NOTIFICATIONS}
                    visible={this.state.manageNotificationsModalVisible}
                    emailNotifications={emailNotifications}
                    companySettings={companySettings}
                    accessLevel={accessLevel}
                    onCancel={this.handleCancel}
                    onConfirm={this.updateNotificationSettings}
                />
            </ComponentContainer>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.Auth.user,
    }
}

export default connect(
    mapStateToProps,
    { UpdateUserDetails, UpdateEmailNotifications }
)(UserSettings)
