import React from 'react'

const SVGComponent = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g
                id="Candidate-Profile---Full-Details-+-Unscreened"
                transform="translate(-1176 -248)"
                stroke="#27628C"
                strokeWidth="1.5"
            >
                <g id="Candidate-Profile-Details" transform="translate(326 202)">
                    <g id="Profile-Details">
                        <g id="Expand-or-Collapse-Btn" transform="translate(851 47)">
                            <path
                                d="M3,0 L21,0 C22.6568542,-3.04359188e-16 24,1.34314575 24,3 L24,21 C24,22.6568542 22.6568542,24 21,24 L3,24 C1.34314575,24 2.02906125e-16,22.6568542 0,21 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 Z M7.05882353,12.9411765 L16.4705882,12.9411765 C17.120335,12.9411765 17.6470588,12.4144526 17.6470588,11.7647059 C17.6470588,11.1149591 17.120335,10.5882353 16.4705882,10.5882353 L7.05882353,10.5882353 C6.40907676,10.5882353 5.88235294,11.1149591 5.88235294,11.7647059 C5.88235294,12.4144526 6.40907676,12.9411765 7.05882353,12.9411765 Z"
                                id="collopse"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
