import React from 'react'
import './style.scss'

export default class LiveUpdate extends React.Component {
    render() {
        return (
            <div>
                <div class="live-update">
                    <button class="pulse-button"></button>
                    <span>UPDATING LIVE</span>
                </div>
            </div>
        )
    }
}
