import React, { Component } from 'react'
import { ComponentContainer, Title, Body, FooterBar, CancelConfirmationText } from './style'
import Form from './Form/Form'
import EmailPreview from './EmailPreview/EmailPreview'
import { Btn } from './Form/Address/style'
import { SCHEDULE_CONFIRMATIONS, INTERVIEW_TYPES, INTERVIEW_TYPES_KEYS } from './Constants'
import { Button, Tooltip } from 'antd'
import { store } from '../../../redux/store'
import { connect } from 'react-redux'
import { PostInterview, GetUsersCalendarStatus } from '../../../services/api'
import { DeleteTemporaryEvent } from '../../../services/NetworkCalls/GoogleCalendar'
import moment from 'moment'
import { success, warning } from '../../../services/Notification'
import { LogE } from './../../Helpers/errorHandler'
import { timeFormat } from '../../Helpers/times'
import './style.scss'
import { Modal } from '../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'

const { BACK, NO, YES, SCHEDULE, RESCHEDULE } = SCHEDULE_CONFIRMATIONS

class ScheduleInterview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobDetail: props.jobDetail,
            companyName: store.getState().Auth.user.companyName,
            cancelMode: false,
            btn_cancelText: BACK,
            btn_confirmText: props.isRescheduled ? RESCHEDULE : SCHEDULE,
            scheduling: false,
            isFormDataChanged: false,
            usersCalendarStatus: [],
            noHangouts: false,
            showHangoutsWarning: false,
            initialDataChange: true,
        }

        if (props.interview) {
            const newObject = {
                interviewType: props.interview.interviewType || undefined,
                selectedDate: moment(props.interview.selectedDate) || undefined,
                startTime: moment(props.interview.interviewStartTime).format(timeFormat) || undefined,
                endTime:
                    (props.interview.interviewEndTime && moment(props.interview.interviewEndTime).format(timeFormat)) ||
                    undefined,
            }
            this.state = { ...newObject, ...this.state }
        }

        // finding the jobTitle and stageName, if JobTitle and stageId is not passed in props
        if (
            props.jobDetail &&
            props.jobDetail.hasOwnProperty('jobId') &&
            (!props.jobDetail.jobTitle || !props.jobDetail.stageName)
        ) {
            const { allJobs } = store.getState().Job
            const job = allJobs.find(job => job._id === props.jobDetail.jobId)
            if (job) {
                // Only update if job exist in the store.
                this.state.jobDetail['jobTitle'] = job.jobTitle
                if (props.jobDetail.hasOwnProperty('stageId')) {
                    const stage = job.stages.find(stg => stg._id === props.jobDetail.stageId)
                    if (stage) this.state.jobDetail['stageName'] = stage.stageName
                }
            }
        }

        this._emailPreviewRef = React.createRef()
        this._formRef = React.createRef()
    }

    componentDidMount() {
        GetUsersCalendarStatus().then(usersCalendarStatus => this.setState({ usersCalendarStatus }))
    }

    scheduleInterview = async data => {
        const {
            interviewerIds,
            hangoutsLink: antd_hangoutsLink,
            phone: antd_phone,
            skypeId: antd_skypeId,
            selectedAddress: antd_selectedAddress,
        } = data
        const { id, email, companyId } = store.getState().Auth.user
        const { candidate, isRescheduled } = this.props
        const { interviewType, selectedDate, hangoutsLink, phone, skypeId, selectedAddress, cc = [] } = this.state
        const { sendEmailToCandidate = true, content, subject } = this._emailPreviewRef.current.state
        let emailDetails = {
            to: candidate.email,
            subject: subject.trim(),
            body: content,
        }

        // check if email subject line is there or not
        if (sendEmailToCandidate && emailDetails.subject.length === 0) {
            this.setState({ scheduling: false })
            warning('Email Subject cannot be empty')
            return null
        }

        let interviewDate = moment(selectedDate)
        let interviewStartTime = moment(interviewDate)
            .seconds(0)
            .minutes(0)
            .hours(8)
            .format()
        let interviewEndTime = moment(interviewDate)
            .seconds(0)
            .minutes(30)
            .hours(8)
            .format()
        if (this.state.startTime && this.state.endTime) {
            interviewStartTime = moment(this.state.startTime, timeFormat)
                .seconds(0)
                .date(interviewDate.date())
                .month(interviewDate.month())
                .year(interviewDate.year())
                .format()
            interviewEndTime = moment(this.state.endTime, timeFormat)
                .seconds(0)
                .date(interviewDate.date())
                .month(interviewDate.month())
                .year(interviewDate.year())
                .format()
        }

        let meetingDetails
        if (interviewType === INTERVIEW_TYPES_KEYS.HANGOUTS) {
            meetingDetails = hangoutsLink || antd_hangoutsLink
        } else if (interviewType === INTERVIEW_TYPES_KEYS.TELEPHONIC) {
            meetingDetails = phone.number || antd_phone
        } else if (interviewType === INTERVIEW_TYPES_KEYS.SKYPE) {
            meetingDetails = skypeId || antd_skypeId
        } else if (interviewType === INTERVIEW_TYPES_KEYS.F2F) {
            meetingDetails = selectedAddress || antd_selectedAddress
        }

        if (isRescheduled) {
            const { interview } = this.props
            const { _id, interviewEventId, interviewEventUserId, interviewType } = interview
            let newEventId = null
            if (this.state.eventId && this.state.eventId !== interviewEventId) {
                newEventId = this.state.eventId
            }
            let interviewData = {
                candidateId: this.props.candidate._id,
                interviewStageId: this.props.jobDetail.stageId,
                isRescheduled,
                interviewType: this.state.interviewType || interviewType,
                eventId: this.state.eventId || null,
                meetingDetails: meetingDetails,
                interviewDate: interviewDate.format() || interview.interviewDate,
                interviewerTodo: true,
                myTodo: false,
                jobId: this.props.jobDetail.jobId,
                companyId: companyId,
                userId: id,
                userEmail: email,
                isCandidateNotified: sendEmailToCandidate,
                email: emailDetails,
                interviewerIds: interviewerIds,
                interviewStartTime: interviewStartTime || interview.interviewStartTime,
                interviewEndTime: interviewEndTime || interview.interviewEndTime,
                _id,
                interviewEventId,
                interviewEventUserId,
                newEventId,
                status: interview.status,
                cc,
            }
            let result = await PostInterview(interviewData)
            if (!result.error) {
                if (
                    result.hangoutsLinkInfo &&
                    !result.hangoutsLinkInfo.available &&
                    (this.state.interviewType || interviewType) === INTERVIEW_TYPES_KEYS.HANGOUTS
                ) {
                    warning(
                        'Interview Rescheduled successfully but Google meet link could not be generated since no interviewer has access to Google hangouts meet'
                    )
                } else {
                    success('Interview Rescheduled successfully')
                }
                this.setState({ scheduling: false }, () => {
                    this.props.onSchedule({ ...interview, ...result.interview, interviewerIds })
                })
            } else {
                if (result.interview) {
                    warning(result.error)
                    this.props.onSchedule({ ...interview, ...result.interview, interviewerIds })
                } else {
                    this.setState({ scheduling: false }, () => {
                        warning(result.error.msg)
                    })
                }
            }
        } else {
            let interviewData = {
                candidateId: this.props.candidate._id,
                interviewStageId: this.props.jobDetail.stageId,
                isRescheduled,
                interviewType: this.state.interviewType,
                interviewEventId: this.state.eventId || null,
                interviewEventUserId: this.state.eventId ? id : null,
                meetingDetails: meetingDetails,
                interviewDate: interviewDate.format(),
                interviewerTodo: true,
                myTodo: false,
                jobId: this.props.jobDetail.jobId,
                companyId: companyId,
                userId: id,
                userEmail: email,
                isCandidateNotified: sendEmailToCandidate,
                email: emailDetails,
                interviewerIds,
                interviewStartTime: interviewStartTime,
                interviewEndTime: interviewEndTime,
                cc,
            }
            let result = await PostInterview(interviewData)
            if (!result.error) {
                if (
                    result.hangoutsLinkInfo &&
                    !result.hangoutsLinkInfo.available &&
                    interviewType === INTERVIEW_TYPES_KEYS.HANGOUTS
                ) {
                    warning(
                        'Interview Scheduled successfully but Google meet link could not be generated since no interviewer has access to Google hangouts meet'
                    )
                } else {
                    success('Interview Scheduled successfully')
                }
                this.setState({ scheduling: false }, () => {
                    this.props.onSchedule(result.interview)
                })
            } else {
                if (result.interview) {
                    success(result.message)
                    warning(result.error)
                    this.props.onSchedule(result.interview)
                } else {
                    this.setState({ scheduling: false }, () => {
                        warning(result.error.msg)
                    })
                }
            }
        }
    }

    cancelClicked = () => {
        const { cancelMode, isFormDataChanged } = this.state
        const { isRescheduled, onCancel } = this.props
        if (cancelMode) {
            this.setState({
                cancelMode: false,
                btn_cancelText: BACK,
                btn_confirmText: isRescheduled ? RESCHEDULE : SCHEDULE,
            })
        } else {
            if (isFormDataChanged)
                this.setState({
                    cancelMode: true,
                    btn_cancelText: NO,
                    btn_confirmText: YES,
                })
            else onCancel()
        }
    }

    confirmClicked = async () => {
        try {
            const { cancelMode } = this.state

            if (cancelMode) {
                if (this.state.eventId) {
                    this.props.DeleteTemporaryEvent(this.state.eventId)
                }
                this.props.onCancel()
            } else {
                await this.submitButton()
            }
        } catch (error) {
            LogE('scheduleInterview-confirmClicked', '', error)
            warning(error.message || error)
        }
    }

    updateParentState = (state, isFormDataChanged = true, addressUpdated) => {
        let { initialDataChange } = this.state
        if (addressUpdated && initialDataChange) {
            initialDataChange = false
        } else if (addressUpdated && !initialDataChange) {
            isFormDataChanged = true
        }
        this.setState({ ...state, isFormDataChanged, initialDataChange })
    }

    submitButton = (skipHangoutsCheck = false) => {
        this._formRef.current.props.form.validateFields((err, values) => {
            let { interviewType } = values
            this._formRef.current._interviewerRef.current.props.form.validateFields(
                (interviewError, { interviewerIds }) => {
                    if (!err && !interviewError) {
                        interviewerIds = interviewerIds.map(interviewer => interviewer.key)
                        let allowScheduling = true
                        if (!skipHangoutsCheck && interviewType === INTERVIEW_TYPES_KEYS.HANGOUTS) {
                            let allUsers = [...interviewerIds, store.getState().Auth.user.id]
                            let interviewerCalStatus = this.state.usersCalendarStatus
                                .filter(user => allUsers.includes(user.userId))
                                .map(user => user.connected)
                            allowScheduling =
                                interviewerCalStatus.length && interviewerCalStatus.reduce((a = false, b) => a || b)
                        }
                        if (allowScheduling) {
                            this.setState({ scheduling: true, noHangouts: false }, () => {
                                this.scheduleInterview({
                                    interviewerIds,
                                    ...values,
                                })
                            })
                        } else {
                            this.setState({ noHangouts: true }, () => this.setState({ showHangoutsWarning: true }))
                        }
                    }
                }
            )
        })
    }

    render() {
        const { btn_cancelText, btn_confirmText } = this.state
        if (this.props.visible)
            return (
                <>
                    <Body>
                        <Title>{this.props.isRescheduled ? 'Reschedule' : 'Schedule'} Interview</Title>
                        <ComponentContainer>
                            <Form
                                {...this.props}
                                {...this.state}
                                updateParentState={this.updateParentState}
                                wrappedComponentRef={this._formRef}
                            />
                            <EmailPreview
                                {...this.props}
                                {...this.state}
                                updateParentState={this.updateParentState}
                                ref={this._emailPreviewRef}
                            />
                        </ComponentContainer>
                    </Body>
                    <FooterBar cancelMode={this.state.cancelMode}>
                        <CancelConfirmationText cancelMode={this.state.cancelMode}>
                            Are you sure you want to go back? <br />
                            <span className="help">You have unsaved changes that will be reverted</span>
                        </CancelConfirmationText>
                        <Btn onClick={this.cancelClicked}>{btn_cancelText}</Btn>
                        {this.props.isRescheduled ? (
                            this.state.isFormDataChanged ? (
                                <Button
                                    onClick={this.confirmClicked}
                                    loading={this.state.scheduling}
                                    className="primary-button"
                                >
                                    {btn_confirmText}
                                </Button>
                            ) : (
                                <Tooltip title="No details have been changed" overlayClassName="fs-10">
                                    <Button
                                        onClick={this.confirmClicked}
                                        loading={this.state.scheduling}
                                        className="primary-button"
                                        disabled
                                        style={{ height: '40px' }}
                                    >
                                        {btn_confirmText}
                                    </Button>
                                </Tooltip>
                            )
                        ) : (
                            <Button
                                onClick={this.confirmClicked}
                                loading={this.state.scheduling}
                                className="primary-button"
                            >
                                {btn_confirmText}
                            </Button>
                        )}
                        <Modal
                            type={MODAL_TYPES.WARNING_HANGOUTS_UNAVAILABLE}
                            visible={this.state.showHangoutsWarning}
                            onCancel={() => this.setState({ showHangoutsWarning: false })}
                            onConfirm={() => {
                                this.setState({ showHangoutsWarning: false }, () => this.submitButton(true))
                            }}
                            isRescheduled={this.props.isRescheduled}
                        />
                    </FooterBar>
                </>
            )
        else return null
    }
}
ScheduleInterview.defaultProps = {
    jobDetail: {},
    isRescheduled: false,
}

const mapStateToProps = state => {
    return { __redux: state }
}

export default connect(
    mapStateToProps,
    { DeleteTemporaryEvent }
)(ScheduleInterview)
