import apiCaller from '../../apiCaller'
import { success, warning } from '../../../services/Notification'
import _ from 'lodash'
import { actions } from '../../../redux/todo/actions'
import { store } from '../../../redux/store'

const { restricted } = apiCaller.type
const { formatError } = apiCaller
const baseUrl = `feedback/`

const formatFeedback = feedback => {
    let _feedback = { ...feedback }
    _feedback.userId = (_feedback.user && _feedback.user._id) || _feedback.userId
    _feedback.jobId = (_feedback.job && _feedback.job._id) || _feedback.jobId
    _feedback.interviewId = (_feedback.interview && _feedback.interview._id) || _feedback.interviewId
    _feedback.stageId = (_feedback.interviewStage && _feedback.interviewStage._id) || _feedback.stageId
    _feedback.candidateId = (_feedback.candidate && _feedback.candidate._id) || _feedback.candidateId
    _feedback.companyId = store.getState().Auth.user.companyId
    return _.omit(_feedback, 'user', 'job', 'interview', 'interviewStage', 'candidate')
}

export const CreateFeedback = (feedback, todoFilters = {}) => async dispatch => {
    try {
        const { data } = await restricted.post(baseUrl, {
            ...feedback,
            companyId: store.getState().Auth.user.companyId,
        })
        const { filter, type } = todoFilters
        success(data.message)

        if (filter && type)
            dispatch({
                type: actions.FEEDBACK_ADDED,
                payload: { interview: data.feedback.interview, userId: store.getState().Auth.user.id, filter, type },
            })
        return data.feedback
    } catch (error) {
        warning(formatError(error))
    }
}

export const EditFeedback = feedback => async dispatch => {
    feedback = formatFeedback(feedback)
    try {
        const { companyId } = store.getState().Auth.user
        const { data } = await restricted.patch(`${baseUrl}${feedback._id}`, { ..._.omit(feedback, '_id'), companyId })
        success(data.message)
        return data.feedback
    } catch (error) {
        warning(formatError(error))
    }
}

export const DeleteFeedback = id => async dispatch => {
    try {
        const { companyId } = store.getState().Auth.user
        const { data } = await restricted.delete(`${baseUrl}${id}?companyId=${companyId}`)
        success(data.message)
        return data.feedback
    } catch (error) {
        warning(formatError(error))
    }
}

export const GetFeedbacksForCandidate = candidateId => async dispatch => {
    try {
        const { companyId } = store.getState().Auth.user
        const { data } = await restricted.get(`${baseUrl}all/${candidateId}?companyId=${companyId}`)
        return data.feedbacks
    } catch (error) {
        warning(formatError(error))
    }
}

export const RequestFeedbackForInterview = interviewId => async dispatch => {
    try {
        const { companyId } = store.getState().Auth.user
        const { data } = await restricted.post(`${baseUrl}request/${interviewId}`, { companyId })
        success(data.message)
        return data
    } catch (error) {
        warning(formatError(error))
    }
}
