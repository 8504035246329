import React from 'react'

const SVGComponent = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="26"
        height="30"
        viewBox="0 0 26 30"
    >
        <image
            id="image0"
            width="26"
            height="30"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAMAAAAFBf7qAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABC1BMVEUAAAAkpGQloWIloGNv wZqL0bkloGOAxaWKyq4loWN6wqJhupAso2mKyaxArHaJyKwpomVNsYBmtpBpt5NWroUgkFkgk1kj n2IloWMkoGMkomQkoWNAn2AloWMloGInnWIkoGKKya0jm18gj1gimV0hlVsjn2Ehk1ojnmB8xqF1 w5xqvpSHy6lju495xJ5zwpu64c2h1rswpWs9q3Tp9e9CrXjm9O1Ir3t/x6Of1brW7eI6qXKT0LKK zKvt9/KZ07Yso2jZ7uOCyKUwpWpovZP+/v48qnOY0rXk8+tiu47i8upvwJjn9O4zpm2y3cio2cDr 9vDR695KsH3C5NPO6dvO6txXtoYpomX///9TLoR1AAAAIHRSTlMAHKblnAvtysDmxb/u6dPi9MjB wpTU1NHRt3jTCNyRDTbucwEAAAABYktHRFjttcSOAAAAB3RJTUUH4wYYBjkNMlHE6QAAAOBJREFU KM+l03lPwjAcxvGCoHIIyhQQEZ45LpVTkE08Ni7HEJkIyPt/JwhZE7rSxMTvX00+SY/kV0I83gPs 5vMTJ88h2I6OqXnhJplagCdqbkFQpsZRSKbGUfiEGkeIRB3jCadnMUmSzvcRcKMoysV+Qk5MyIup UBQSSnEhISGm5D/o9g735Ur1d1WrNx4YarYe26r2BKid55cGu+Hrm44NGd1e33XWYFjdEt7NkcWQ Mf6YfG7IKqM2Zci0odva12z2PV8sf0SXX/3tXQERXfIjSkvxg+10lSbcd9h2ncmSNbszYLGSDF8H AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTA2LTI0VDA2OjU3OjEzKzAzOjAwlVoXVwAAACV0RVh0 ZGF0ZTptb2RpZnkAMjAxOS0wNi0yNFQwNjo1NzoxMyswMzowMOQHr+sAAAAASUVORK5CYII="
        />
    </svg>
)

export default SVGComponent
