const actions = {
    SET_USER_GCAL: 'SET_USER_GCAL',
    SET_AVAILABILITY: 'SET_AVAILABILITY',
    SET_DEFAULT: 'SET_DEFAULT',
    ADD_ACCOUNT: 'ADD_USER_GCAL',
    REMOVE_ACCOUNT: 'REMOVE_ACCOUNT',
    SET_NOT_IN_USE: 'SET_NOT_IN_USE',
    SET_IN_USE: 'SET_IN_USE',
}

export default actions
