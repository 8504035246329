import { warning } from '../../../services/Notification'
import apiCaller from '../../apiCaller'
import { LogE } from '../../../container/Helpers/errorHandler'
import { AxiosInstance as axios } from 'axios'
import config from '../../../config'

const { restricted } = apiCaller.type
const { formatError } = apiCaller
const { baseURL, apiVersion } = config.config()
const baseUrl = 'sourcing/profile/'

export const GetUniversalCandidateDetails = universalCandidateId => async dispatch => {
    try {
        const { data } = await restricted.get(`${baseUrl}${universalCandidateId}`)
        if (data.error) {
            LogE('networkcalls-sourcingcandidate-getuniversalcandidatedetails', 'Failed to fetch', data.error)
            warning('Failed to fetch universal candidate details')
            return null
        }
        return data
    } catch (error) {
        LogE('networkcalls-soucingcandidate-getuniversalcandidatedetails-catch', 'Failed to fetch', error)
        warning(formatError(error))
        return {}
    }
}

/*
 * TODO: in future will make a anyalytics class which will handle api calls for such actions
 *
 */

export const SendClickAnalytics = authProof => {
    try {
        const URL = baseURL + apiVersion.v2 + 'analytics/sourcing/sentToSpringRole'
        axios.patch(URL, {
            headers: {
                AuthSecret: authProof,
            },
        })
        return true
    } catch (error) {
        LogE('networkcalls-soucingcandidate-SendClickAnalytics-catch', 'Failed to fetch', error)
        return null
    }
}
