import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { DataCard, Filter, Heading } from '../../style'
import { ReactComponent as InfoIcon } from '../../../../../assets/Icons/Openings/info.svg'
import { ReactComponent as RefreshIcon } from '../../../../../assets/Icons/Analytics/refresh.svg'
import { JobTimelineLoader } from '../../Components/Loaders'
import StatCard from '../../Components/Card'
import { getNumberOfDays, getPercentage } from '../../../../Helpers/utils'
import Table from '../../Components/Table'
import { GetAnalyticsData } from '../../../../../services/NetworkCalls/Analytics'
import { warning } from '../../../../../services/Notification'
import apiCaller from '../../../../../services/apiCaller'
import CustomFilters from '../../Components/Filters'
import { getJobStatusArray, JOBS_TIMELINE_COLUMNS } from '../../Components/constants'
import { Tooltip } from 'antd'
import config from '../../../../../config'
import { StyledButton } from '../../../../../container/Components/'

const { isMobileDevice } = config

const { CheckboxFilter, DateFilter } = CustomFilters
const { formatError } = apiCaller

const Timeline = ({ companyId, jobNames, jobsObject, GetAnalyticsData, history }) => {
    const [cardData, setCardData] = useState({})
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedJobTypes, setSelectedJobTypes] = useState([])
    const [selectedJobNames, setSelectedJobNames] = useState([])
    const [jobNamesArray, setJobNamesArray] = useState(jobNames)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [loadTime, setLoadTime] = useState(moment().format('DD MMM, YYYY LT'))

    const jobTypeArray = ['Active', 'Archived']
    const downloadColumns = [
        { index: 'jobName', value: 'JOB NAME' },
        { index: 'createdDate', value: 'CREATED DATE' },
        { index: 'archivalDate', value: 'ARCHIVAL DATE' },
        { index: 'jobLifetime', value: 'JOB LIFETIME' },
        { index: 'joined', value: 'JOINED' },
    ]

    useEffect(() => {
        jobsInitialLoad()
    }, [])

    const jobsInitialLoad = () => {
        const urlSearchResult = new URLSearchParams(history.location.search)
        const jobTypes = urlSearchResult.get('jobTypes') ? urlSearchResult.get('jobTypes').split(',') : []
        const jobNames = urlSearchResult.get('jobNames') ? urlSearchResult.get('jobNames').split(',') : []
        const startDate = urlSearchResult.get('startDate') ? urlSearchResult.get('startDate') : null
        const endDate = urlSearchResult.get('endDate') ? urlSearchResult.get('endDate') : null

        let filteredJobNames = []
        const jobStatusArray = getJobStatusArray(jobTypes)

        if (jobTypes.length) {
            for (let jobName in jobsObject) {
                if (jobStatusArray.includes(jobsObject[jobName].status)) filteredJobNames.push(jobName)
            }
        } else filteredJobNames = Object.keys(jobsObject)

        const id = jobNames.map(jobName => jobsObject[jobName]._id)
        const payload = {
            status: (jobStatusArray.length && jobStatusArray) || null,
            id: (id.length && id) || null,
            startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
            endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
        }
        getJobsTimelineData(payload)
        setSelectedJobTypes(jobTypes)
        setSelectedJobNames(jobNames)
        setJobNamesArray(filteredJobNames)
        setStartDate(startDate && moment(startDate))
        setEndDate(endDate && moment(endDate))
    }

    const getJobsTimelineData = async (payload = {}) => {
        try {
            setIsLoading(true)
            const res = await GetAnalyticsData('GetJobsTimelineData', payload, companyId)

            const tableData = res.jobArray.map((data, index) => ({
                key: index,
                jobName: data.jobTitle,
                createdDate: (data.createdAt && moment(data.createdAt).format('Do MMM YYYY')) || '--',
                archivalDate: (data.archivedAt && moment(data.archivedAt).format('Do MMM YYYY')) || '--',
                jobLifetime: getNumberOfDays(data.avgJobLifeTime) || 0,
                joined: data.joined || 0,
            }))

            setCardData(res)
            setTableData(tableData)
            setIsLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const applyFilteredData = (type, data) => {
        const params = new URLSearchParams(history.location.search)
        if (type === 'jobType') {
            const jobStatusArray = []
            data.forEach(each => {
                if (each === 'Active') {
                    jobStatusArray.push(1)
                    jobStatusArray.push(2)
                } else jobStatusArray.push(0)
            })
            let filteredJobNames = []
            if (data.length) {
                for (let jobName in jobsObject) {
                    if (jobStatusArray.includes(jobsObject[jobName].status)) filteredJobNames.push(jobName)
                }
            } else filteredJobNames = Object.keys(jobsObject)
            const payload = {
                status: (jobStatusArray.length && jobStatusArray) || null,
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
            }
            getJobsTimelineData(payload)
            setSelectedJobTypes(data)
            setJobNamesArray(filteredJobNames)
            setSelectedJobNames([])
            params.delete('jobTypes')
            params.delete('jobNames')
            if (data.length) params.append('jobTypes', data.toString())
            history.push({ search: params.toString() })
        } else if (type === 'jobName') {
            const jobStatusArray = []
            selectedJobTypes.forEach(each => {
                if (each === 'Active') {
                    jobStatusArray.push(1)
                    jobStatusArray.push(2)
                } else jobStatusArray.push(0)
            })
            let filteredJobIds = []
            if (data.length) filteredJobIds = data.map(jobName => jobsObject[jobName]._id)
            const payload = {
                status: (jobStatusArray.length && jobStatusArray) || null,
                id: (filteredJobIds.length && filteredJobIds) || null,
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
            }
            getJobsTimelineData(payload)
            setSelectedJobNames(data)
            params.delete('jobNames')
            if (data.length) params.append('jobNames', data.toString())
            history.push({ search: params.toString() })
        } else {
            const jobStatusArray = []
            selectedJobTypes.forEach(each => {
                if (each === 'Active') {
                    jobStatusArray.push(1)
                    jobStatusArray.push(2)
                } else jobStatusArray.push(0)
            })
            const selectedJobIds = selectedJobNames.map(jobName => jobsObject[jobName]._id)
            const [startDate, endDate] = data
            const payload = {
                status: (jobStatusArray.length && jobStatusArray) || null,
                id: (selectedJobIds.length && selectedJobIds) || null,
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
            }
            getJobsTimelineData(payload)
            setStartDate(startDate)
            setEndDate(endDate)
            params.delete('startDate')
            params.delete('endDate')
            if (startDate) params.append('startDate', startDate.format().toString())
            if (endDate) params.append('endDate', endDate.format())
            history.push({ search: params.toString() })
        }
    }

    const resetFilter = type => {
        const params = new URLSearchParams(history.location.search)
        if (type === 'jobType') {
            const payload = {
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
            }
            getJobsTimelineData(payload)
            setSelectedJobTypes([])
            setSelectedJobNames([])
            setJobNamesArray(Object.keys(jobsObject))
            params.delete('jobTypes')
            params.delete('jobNames')
            history.push({ search: params.toString() })
        } else {
            const jobStatusArray = []
            selectedJobTypes.forEach(each => {
                if (each === 'Active') {
                    jobStatusArray.push(1)
                    jobStatusArray.push(2)
                } else jobStatusArray.push(0)
            })
            let jobId = []
            if (jobStatusArray.length) {
                for (let job in jobsObject)
                    if (jobStatusArray.includes(jobsObject[job].status)) jobId.push(jobsObject[job]._id)
            }
            const payload = {
                status: jobStatusArray,
                jobId,
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
            }
            getJobsTimelineData(payload)
            setSelectedJobNames([])
            params.delete('jobNames')
            history.push({ search: params.toString() })
        }
    }

    const handleRefresh = () => {
        const status = getJobStatusArray(selectedJobTypes)
        let jobId = []
        if (selectedJobNames.length) jobId = selectedJobNames.map(jobName => jobsObject[jobName]._id)
        const payload = {
            status: (status.length && status) || null,
            id: (jobId.length && jobId) || null,
            startDate: startDate && startDate.format('YYYY-MM-DD'),
            endDate: endDate && endDate.format('YYYY-MM-DD'),
        }
        getJobsTimelineData(payload)
        setLoadTime(moment().format('DD MMM, YYYY LT'))
    }

    return (
        <>
            <div className="sub-header">
                <div>
                    <Heading>Jobs Timeline</Heading>
                    <Tooltip title="Analyze your job openings from creation to archival on SpringRecruit. Narrow the search criteria with the filters.">
                        <InfoIcon />
                    </Tooltip>
                </div>
                <div>
                    <RefreshIcon onClick={() => handleRefresh()} />
                    <span>Last Updated: {loadTime}</span>
                </div>
            </div>
            {isMobileDevice && <Heading>Filter</Heading>}
            <Filter>
                <CheckboxFilter
                    title="Filter by job type"
                    data={jobTypeArray}
                    search={false}
                    apply={false}
                    type="jobType"
                    applyFilteredData={applyFilteredData}
                    resetFilter={resetFilter}
                    selectedValues={selectedJobTypes}
                />
                <CheckboxFilter
                    title="Filter by job name"
                    data={jobNamesArray}
                    type="jobName"
                    applyFilteredData={applyFilteredData}
                    resetFilter={resetFilter}
                    selectedValues={selectedJobNames}
                />
                <DateFilter applyFilteredData={applyFilteredData} start={startDate} end={endDate} />
            </Filter>
            {isLoading ? (
                <JobTimelineLoader />
            ) : (
                <div className="cards">
                    <StatCard total={cardData.total} title="Total Jobs" />
                    <StatCard
                        total={cardData.active}
                        title="Active Jobs"
                        desc={`${getPercentage(cardData.active, cardData.total)}% of Total Jobs`}
                    />
                    <StatCard
                        total={cardData.archived}
                        title="Archived Jobs"
                        desc={`${getPercentage(cardData.archived, cardData.total)}% of Total Jobs`}
                    />
                    <StatCard total={cardData.totalJoined} title="Joined" />
                </div>
            )}
            <Table
                columns={JOBS_TIMELINE_COLUMNS}
                downloadColumns={downloadColumns}
                filename={`JOBS TIMELINE_${moment().format('DD-MM-YYYY_h-mm a')}`}
                tableData={tableData}
                loading={isLoading}
            />
        </>
    )
}

const mapStateToProps = ({ Auth, Job }) => {
    const { user } = Auth
    const { allJobs } = Job
    const jobNames = [],
        jobsObject = {}

    allJobs.forEach(job => {
        jobNames.push(job.jobTitle)
        jobsObject[job.jobTitle] = {
            _id: job._id,
            status: job.status,
        }
    })

    return {
        companyId: user.companyId,
        jobNames,
        jobsObject,
    }
}

export default connect(
    mapStateToProps,
    {
        GetAnalyticsData,
    }
)(Timeline)
