import React, { useState, useEffect } from 'react'
import * as S from './style'
import Feature3Img from '../../../assets/Icons/Landing/Features/employer-branding-ats-recruitment.svg'
import { ReactComponent as TryNowImg } from '../../../assets/Icons/Landing/try-now.svg'
import Feature5Img from '../../../assets/Icons/Landing/Features/effective-collaboration-hr-applicant-tracking-software.svg'
import { LandingPageDropZone } from './Components/LandingPageDropZone'
import { Icon, Spin } from 'antd'
import { SingleFileTypeValidation } from '../../Helpers/fileTypeValidation'
import { UploadForDetailedResume } from '../../../services/NetworkCalls/LandingPage'
import { warning } from '../../../services/Notification'
import apiCaller from '../../../services/apiCaller'
import { GetResumeDetails } from '../../../services/api'
import { LOGIN_TEXT } from './constants'
import { DemoButton, StartedButton } from './Components/StaticComponents'
import { randomString } from '../../Helpers/utils'
import { LogE } from '../../Helpers/errorHandler'

const { formatError } = apiCaller

const resolveDataField = (value, fieldName) => {
    if (value === '') return fieldName
    if (value === null) return `${fieldName}: ${value ? value : 'N/A'}`
    if (Array.isArray(value)) return `${fieldName}: ${value.join(', ')}`
    else return `${fieldName}: ${value}`
}

const formatResponseData = ({ data = null }) => {
    if (!data) return { resumeLink: null }
    const {
        candidateDetail: { name, email, phone },
        resumeLink,
    } = data[0]
    return {
        resumeLink,
        candidateDetail: {
            name: name || null,
            email: (email && email[0]) || null,
            phone: (phone && phone[0] && phone[0]['Extracted phone number']) || null,
        },
    }
}

const INVALID_FILE_ERROR = 'Please ensure the file is either of type pdf, doc or docx'

const Features = ({ token, isLoggedIn, accessLevel }) => {
    const [fileUploaded, setFileUploaded] = useState(false)
    const [uploadError, setUploadError] = useState(false)
    const [introLoader, setIntroLoader] = useState(false)
    const [detailedLoader, setDetailedLoader] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [skills, setSkills] = useState('')

    const uploadIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

    const uploadTextResolver = introLoader
        ? 'Uploading and Parsing file'
        : uploadError
            ? uploadError
            : fileUploaded
                ? fileUploaded
                : 'Drop your Resume file here'

    const onCancel = () => {
        setName('')
        setEmail('')
        setPhone('')
        setSkills('')
        setFileUploaded(false)
        setUploadError(false)
        setIntroLoader(false)
        setDetailedLoader(false)
    }

    const onDrop = async acceptedFile => {
        if (acceptedFile.length > 0) {
            const resumeUploadId = randomString()
            setIntroLoader(true)
            setDetailedLoader(resumeUploadId)
            const formData = SingleFileTypeValidation(acceptedFile[0])
            if (!formData) {
                setUploadError(INVALID_FILE_ERROR)
                warning('Error uploading resume!')
                return true
            }
            let result = {}
            try {
                result = await GetResumeDetails(formData, token)
            } catch (error) {
                warning(formatError(error) || 'Something went wrong')

                setIntroLoader(false)
                setDetailedLoader(false)
                return true
            }

            const { resumeLink, candidateDetail } = formatResponseData(result)
            setFileUploaded(acceptedFile[0].name)
            setIntroLoader(false)
            setName(candidateDetail.name)
            setPhone(candidateDetail.phone)
            setEmail(candidateDetail.email)
            UploadForDetailedResume(token, { resumeLink, resumeUploadId })
                .then(({ data }) => {
                    // if uploadId is same then update else, there has been a new upload
                    if (data.resumeUploadId === detailedLoader) {
                        setSkills(data.skills)
                        setDetailedLoader(false)
                    }
                })
                .catch(err => {
                    LogE('landing-page-UploadForDetailedResume-catch', '', err)
                    setSkills(null)
                    setDetailedLoader(false)
                })
        } else {
            setUploadError(INVALID_FILE_ERROR)
        }
    }
    return (
        <>
            <S.Features className="features">
                <div>
                    <S.Feature>
                        <S.H4 className="show-sm">Customisable Board View</S.H4>
                        <video autoPlay loop muted playsInline>
                            <source
                                src="https://assets.springrecruit.com/landing/ats-software-customisable-board-view.mp4"
                                type="video/mp4"
                            />
                        </video>
                        <div>
                            <S.H4 className="hide-sm">Customisable Board View</S.H4>
                            <p>
                                Inspect and visualize your recruitment processes better with customized board views for
                                each stage and job opening.
                            </p>
                            {/* <div className="buttons">
                                <DemoButton type="primary" />
                            </div> */}
                        </div>
                    </S.Feature>
                    <S.Feature>
                        <div>
                            <S.H4 className="hide-sm">
                                Seamless Interview Scheduling &<br />
                                Automated Reminders
                            </S.H4>
                            <p>
                                With Google Calendar integration, set meeting slots and reminders for upcoming
                                interviews and feedback hassle-free.
                            </p>
                            <div className="buttons">
                                <StartedButton isLoggedIn={isLoggedIn} accessLevel={accessLevel} />
                            </div>
                        </div>
                        <S.H4 className="show-sm">
                            Seamless Interview
                            <br />
                            Scheduling &<br />
                            Automated Reminders
                        </S.H4>
                        <video autoPlay loop muted playsInline>
                            <source
                                src="https://assets.springrecruit.com/landing/ats-software-schedule-interview-from-calendar.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </S.Feature>
                    <S.Feature>
                        <S.H4 className="show-sm">Step Up Your Employer Branding</S.H4>
                        <img
                            src={Feature3Img}
                            title="Employer Branding with ATS Recruitment"
                            alt="Employer Branding with ATS Recruitment"
                        />
                        <div>
                            <S.H4 className="hide-sm">Step Up Your Employer Branding</S.H4>
                            <p>
                                Design your very own career page and develop an identity from the very beginning of the
                                recruitment process.
                            </p>
                        </div>
                    </S.Feature>
                    <S.Feature uploadError={uploadError}>
                        <div>
                            <S.H4 className="hide-sm">Automated Assessments & Resume Parser</S.H4>
                            <p>
                                Set SpringRecruit to automatically send tests to candidates at different stages of the
                                hiring process and fetch introductory details like name, email, and phone number from
                                candidate resumes.
                            </p>
                            <div className="buttons">
                                <StartedButton isLoggedIn={isLoggedIn} accessLevel={accessLevel} />
                            </div>
                        </div>
                        <div className="drop-resume">
                            <S.H4 className="show-sm">Automated Assessments & Resume Parser</S.H4>
                            {!fileUploaded ? <TryNowImg /> : null}
                            <LandingPageDropZone
                                onDrop={onDrop}
                                uploadText={uploadTextResolver}
                                isDisabled={!!fileUploaded}
                                handleCancel={onCancel}
                            />
                            <div className="form">
                                <div>
                                    <span>
                                        {introLoader ? <Spin indicator={uploadIcon} /> : resolveDataField(name, 'Name')}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        {introLoader ? (
                                            <Spin indicator={uploadIcon} />
                                        ) : (
                                            resolveDataField(email, 'Email')
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        {introLoader ? (
                                            <Spin indicator={uploadIcon} />
                                        ) : (
                                            resolveDataField(phone, 'Phone')
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        {detailedLoader ? (
                                            <Spin indicator={uploadIcon} />
                                        ) : (
                                            resolveDataField(skills, 'Skills')
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </S.Feature>
                    <S.Feature>
                        <S.H4 className="show-sm">Effective Collaboration</S.H4>
                        <img
                            src={Feature5Img}
                            title="Effective Collaboration with HR Applicant Tracking Software"
                            alt="Effective Collaboration with HR Applicant Tracking Software"
                        />
                        <div>
                            <S.H4 className="hide-sm">Effective Collaboration</S.H4>
                            <p>
                                Invite everyone from recruiters to interviewers, grant them different access levels, and
                                collaborate effectively as a team. Track all candidate emails in one place without ever
                                having to leave the platform
                            </p>
                        </div>
                    </S.Feature>
                </div>
            </S.Features>
        </>
    )
}

export default Features
