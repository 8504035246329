import { warning, success } from '../../../services/Notification'
import apiCaller from '../../apiCaller'
import actions from '../../../redux/gCal/actions'

const { restricted } = apiCaller.type
const { formatError } = apiCaller
const baseUrl = 'google-calendar/'

export const GetAllConnectedAccounts = () => async dispatch => {
    try {
        const { data } = await restricted.get(`${baseUrl}accounts`)
        dispatch({ type: actions.SET_USER_GCAL, payload: data })
    } catch (error) {
        warning(formatError(error))
    }
}

export const DisconnectAccount = emailId => async dispatch => {
    try {
        const { data } = await restricted.put(`${baseUrl}disconnectAccount`, { emailId })
        dispatch({ type: actions.REMOVE_ACCOUNT, payload: { email: data.emailId } })
        success(`${emailId} has been disconnected successfully.`)
    } catch (error) {
        warning(formatError(error))
    }
}

export const SetAvailability = (availability, timezone) => async dispatch => {
    try {
        const { data } = await restricted.put(`${baseUrl}setUserAvailability`, { availability, timezone })
        dispatch({ type: actions.SET_AVAILABILITY, payload: data.availability })
        return true
    } catch (error) {
        warning(formatError(error))
    }
}

export const SetDefault = emailId => async dispatch => {
    try {
        const { data } = await restricted.put(`${baseUrl}setDefault`, { emailId })
        dispatch({ type: actions.SET_DEFAULT, payload: { email: data.emailId } })
        success(`${emailId} has been set as your default account.`)
    } catch (error) {
        warning(formatError(error))
    }
}

export const SetGCalNotInUse = () => async dispatch => {
    try {
        await restricted.put(`${baseUrl}setGCalNotInUse`)
        dispatch({ type: actions.SET_NOT_IN_USE })
        return true
    } catch (error) {
        warning(formatError(error))
    }
}

export const GetAvailableTimeSlots = (userId, date) => async dispatch => {
    try {
        const { data } = await restricted.get(`${baseUrl}getAvailableSlots/${userId}?date=${date}`)
        return data
    } catch (error) {
        if (error.response.status === 412) {
            warning(error.response.data.error.msg)
            return {}
        }
        warning(formatError(error))
    }
}

export const CreateTemporaryEvent = () => async dispatch => {
    try {
        const { data } = await restricted.post(`${baseUrl}createEvent`, { type: 'temporary' })
        return data
    } catch (error) {
        warning(formatError(error))
        return { error }
    }
}

export const DeleteTemporaryEvent = eventId => async dispatch => {
    try {
        const { data } = await restricted.post(`${baseUrl}deleteEvent`, { eventId })
        return data
    } catch (error) {
        warning(formatError(error))
    }
}
