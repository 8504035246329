import React from 'react'
import { Background } from '../../Error/style'
import { Button, Description, Logo, CompanyName, Content } from './style'
import config from '../../../../config'

const { careerPageURL } = config.config()

export default class InactiveJob extends React.Component {
    gotoCareers = () => {
        window.open(careerPageURL(this.props.domain), '_self')
    }

    render() {
        return (
            <Background startAnimation={this.props.startAnimation}>
                <Content>
                    {this.props.logo ? (
                        <Logo src={this.props.logo} />
                    ) : (
                        <CompanyName>{this.props.companyName}</CompanyName>
                    )}
                    <Description>
                        Currently we are not accepting applications for the {this.props.jobTitle} position. You can
                        visit our Careers page to checkout other open positions.
                    </Description>
                    <Button onClick={this.gotoCareers}>CAREERS</Button>
                </Content>
            </Background>
        )
    }
}
