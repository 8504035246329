import React from 'react'

const SVGComponent = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="26"
        height="30"
        viewBox="0 0 26 30"
    >
        <image
            id="image0"
            width="26"
            height="30"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAMAAAAFBf7qAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABF1BMVEUAAAD/bVL/Z1H/ZlDy kIT/oqL/ZlHvlo/snpb/ZlHvlYn0h3v+albsnZT6dWPtnJT/Z1P5fWzthXvriX3pfHDlW0joXUn7 ZU//ZlH/Z1H/ZlH/Z1H/gGD/ZlH/ZlH/dk7/ZlDsnZX4Y03lW0f0YUzvX0r+ZU/rXkn8ZE//i3v/ p5r/fWr/z8j/eWb/ua//ppr/vbT/wbj/g3H/nY//xLz/h3b/+/r/s6j/eGT/+vn/xLv/Z1L/6+n/ jHv/iXj/5OD/Z1H/2NP/sKX/0Mr/inn/08z/bFf/1tH/jX3/4t7/opX/m43/y8P/2dP/7uz/eWX/ 29X/5eH/xb3/npD/e2j/8e//bVj/6OT/qJz/6ub/0Mn/aVP///9Pj5CUAAAAIHRSTlMAHKblnAvt ysDmxb/u6dPi9MjBwpTU1NHRt3jTCNyRDTbucwEAAAABYktHRFzq2ACXAAAAB3RJTUUH4wYYBjkI QjswZgAAAOFJREFUKM+l0+lOAjEUhuGyqAyKCwMCgvANI/suuFCEQWUTBVlGQLz/+yAh00Rampj4 /n1ykjY5hxCb3YHfOQ+Ile0Qux25mNnBk8ZMEYkZL3BrzAQ61pgJdOJhJhBOzywTCecXXlVVffsI iOu67t9PuJETEnJKpqSE9KWUEJBT8B+UyebyBaBYQq5cud2hau3u/gGPddp4araMNkfPBl5eaacL 9PipPh28Den7R2M0/uRo0prO5tT8WmBp8oRlZ06x+p6ue5LHt3/+9C9FRiFxRVlX4mJbhSNEOIdt 19EY2QC6FWLqAXwTrgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNi0yNFQwNjo1NzowOCswMzow MFv3QzMAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDYtMjRUMDY6NTc6MDgrMDM6MDAqqvuPAAAA AElFTkSuQmCC"
        />
    </svg>
)

export default SVGComponent
