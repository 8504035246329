import React from 'react'

const SVGComponent = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M21.4285714,0 L2.57142857,0 C1.15178571,0 0,1.11979167 0,2.5 L0,20.8333333 C0,22.2135417 1.15178571,23.3333333 2.57142857,23.3333333 L21.4285714,23.3333333 C22.8482143,23.3333333 24,22.2135417 24,20.8333333 L24,2.5 C24,1.11979167 22.8482143,0 21.4285714,0 Z M18.8089286,8.27083333 C18.8196429,8.41666667 18.8196429,8.56770833 18.8196429,8.71354167 C18.8196429,13.2291667 15.2839286,18.4322917 8.82321429,18.4322917 C6.83035714,18.4322917 4.98214286,17.8697917 3.42857143,16.9010417 C3.7125,16.9322917 3.98571429,16.9427083 4.275,16.9427083 C5.91964286,16.9427083 7.43035714,16.4010417 8.63571429,15.484375 C7.09285714,15.453125 5.79642857,14.46875 5.35178571,13.1145833 C5.89285714,13.1927083 6.38035714,13.1927083 6.9375,13.0520833 C5.33035714,12.734375 4.125,11.359375 4.125,9.69791667 L4.125,9.65625 C4.59107143,9.91145833 5.1375,10.0677083 5.71071429,10.0885417 C4.73188294,9.45553997 4.14450396,8.38773058 4.14642857,7.24479167 C4.14642857,6.609375 4.31785714,6.02604167 4.62321429,5.52083333 C6.35357143,7.59375 8.95178571,8.94791667 11.8660714,9.09375 C11.3678571,6.77604167 13.1517857,4.89583333 15.2946429,4.89583333 C16.3071429,4.89583333 17.2178571,5.30729167 17.8607143,5.97395833 C18.6535714,5.828125 19.4142857,5.54166667 20.0892857,5.15104167 C19.8267857,5.94270833 19.275,6.609375 18.5464286,7.03125 C19.2535714,6.95833333 19.9392857,6.765625 20.5714286,6.5 C20.0946429,7.18229167 19.4946429,7.78645833 18.8089286,8.27083333 L18.8089286,8.27083333 Z"
                id="path-1-twitter"
            />
        </defs>
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g id="Dashboard-New---Create-Job+Step3:-Promote" transform="translate(-810 -784)">
                <g id="Candidate-Application" transform="translate(365 200)">
                    <g id="Group-2-Copy-2" transform="translate(0 508)">
                        <g id="Share-Options-Copy-2" transform="translate(430 60)">
                            <g id="Share-Option1">
                                <g id="Logo" transform="translate(15 16)">
                                    <mask id="mask-2-twitter" fill="#fff">
                                        <use xlinkHref="#path-1-twitter" />
                                    </mask>
                                    <use
                                        id="twitter-square"
                                        fill="#3fb0ed"
                                        fillRule="nonzero"
                                        xlinkHref="#path-1-twitter"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
