import React, { Component } from 'react'
import Filter from './Filter'
import Content from './Content'
import { REFINE_DAYS } from './../../Helpers/constants'
import { TodoContainer, LabelBox, GlobalStyle } from './style'
import { connect } from 'react-redux'
import { RescheduleInterview, CancelInterview } from '../../../services/NetworkCalls/Interview'
import { GetList, GetCount } from '../../../services/NetworkCalls/Todo'
import ScheduleInterview from '../ScheduleInterview'
import { FORCE_OPEN } from '../../Helpers/calendarPopUp'
import { BannerGoogleCal } from '../../Banners'
import _ from 'lodash'
import moment from 'moment'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import { SendMessage } from '../../../services/api'
import { success } from '../../../services/Notification'
import { Modal } from '../../Modals'
import { history } from '../../../redux/store'

class Todo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            refineType: REFINE_DAYS.NONE,
            type: 'forMe',
            filter: {
                upcoming: true,
            },
            showInterviewModal: false,
            interviewSelected: null,
            selectedFeedback: false,
            selectedEmail: null,
            calPopUpStatus: false,
            mobileFilter: false,
            isMobileDevice: false,
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        this.setState({ isMobileDevice: window.innerWidth <= 769 })
    }

    componentDidMount() {
        document.title = 'Todo | SpringRecruit'
        this.props.GetCount()
        this.props.GetList({ type: 'forMe', filter: 'upcoming' })
        window.addEventListener('resize', this.resize)
        this.resize()
    }

    feedbackUpdated = feedback => {
        this.handleCancel()
    }

    handleSendEmail = ({ cc, content, subject }) => {
        const {
            candidate: { _id: cId, email },
            job: { _id: jobId },
            interviewStage: { _id: stageId },
        } = this.state.selectedEmail
        const {
            Auth: {
                user: { companyUniqueEmailId },
            },
            userId,
            teamMembers,
        } = this.props

        const values = {
            jobId,
            attachments: [],
            candidateId: cId,
            userId,
            toEmail: email,
            fromEmail: companyUniqueEmailId,
            ccEmails: cc.map(entry => teamMembers.find(mem => mem._id == entry).email),
            subject,
            emailContent: content,
            emailDate: moment().format(),
            emailStage: stageId,
            markedPrivate: false,
            accessibleTo: [],
        }

        SendMessage(values).then(result => {
            success(`Email has been sent to ${email}`)
            this.handleCancel()
        })
    }

    updateRefineType = data => {
        this.setState(
            ({ refineType }) => ({
                refineType:
                    refineType.key !== 'CUSTOM' && refineType.key === data.key
                        ? REFINE_DAYS.NONE
                        : REFINE_DAYS.hasOwnProperty(data.key)
                        ? REFINE_DAYS[data.key]
                        : data,
            }),
            () => {
                const { type, filter, refineType } = this.state
                const filterKeys = Object.keys(filter)
                    .filter(key => key !== 'rescheduled' && filter[key])
                    .join(',')
                const rescheduled = filter['rescheduled']
                this.props.GetCount({ refineType, rescheduled })
                this.props.GetList({
                    type,
                    filter: filterKeys,
                    refineType: refineType.key !== REFINE_DAYS.NONE.key ? refineType : undefined,
                    rescheduled,
                })
            }
        )
    }

    updateFilter = ({ type, checked }) => {
        this.setState(({ filter }) => ({
            filter: { ...filter, [type]: checked },
        }))
    }

    updateAllOrMeFilter = value => {
        this.setState({ type: value })
    }

    handleCancel = () => {
        this.setState({
            showInterviewModal: false,
            interviewSelected: null,
            selectedFeedback: null,
            selectedEmail: null,
            selectedTodo: null,
            noEmailPresent: false,
        })
    }

    getNewPage = () => {
        const { currentPage } = this.props
        const { type, refineType, filter } = this.state
        const filterKeys = Object.keys(filter)
            .filter(key => key !== 'rescheduled' && filter[key])
            .join(',')
        this.props.GetList({
            type,
            refineType: refineType.key !== REFINE_DAYS.NONE.key ? refineType : undefined,
            filter: filterKeys,
            page: currentPage + 1,
            rescheduled: filter['rescheduled'],
        })
    }

    setSelectedData = ({ data, type }) => {
        if (type === MODAL_TYPES.FEEDBACKS) this.setState({ selectedFeedback: data })
        if (type === MODAL_TYPES.SEND_EMAIL) this.setState({ selectedEmail: data })
    }

    showInterviewModal = interview => {
        if (interview.candidate.email) {
            this.setState({ showInterviewModal: true, interviewSelected: interview })
        } else {
            this.setState({ noEmailPresent: true })
        }
    }

    editInterview = interview => {
        this.setState({ showInterviewModal: false, interviewSelected: null }, () => {
            this.props.RescheduleInterview(interview, {
                filter: this.state.filter,
                refineType: this.state.refineType,
                type: this.state.type,
                userId: this.props.userId,
            })
        })
    }

    cancelInterview = interview => {
        this.props.CancelInterview(interview, this.props.companyId, {
            filter: this.state.filter,
            refineType: this.state.refineType,
            type: this.state.type,
            userId: this.props.userId,
        })
    }

    showMobileFilter = show => {
        this.setState({ mobileFilter: show })
    }

    render() {
        const {
            refineType,
            interviewSelected,
            type,
            selectedFeedback,
            selectedEmail,
            calPopUpStatus,
            filter,
            mobileFilter,
            isMobileDevice,
        } = this.state
        let todoss = _.values(this.props.interviews) || []
        todoss.sort((a, b) => {
            if (a.status === 'pending' && moment().isSameOrBefore(moment(a.interviewStartTime))) return -1
            else if (a.status === 'pending' && moment().isAfter(moment(a.interviewStartTime))) return 0
            else return 1
        })

        const calBanner = this.props.inUse === null || this.props.inUse === undefined

        return (
            <div style={{ backgroundColor: '#f7f8fc' }}>
                <GlobalStyle />
                <div className={calBanner && 'banner'}>
                    <BannerGoogleCal
                        talentpool
                        data={this.props.GCal}
                        action_allowAccess={() => this.setState({ calPopUpStatus: FORCE_OPEN.AUTHENTICATE })}
                        action_setManually={() => this.setState({ calPopUpStatus: FORCE_OPEN.AVAILABILITY })}
                    />
                </div>
                <LabelBox>TODO LIST</LabelBox>
                <TodoContainer>
                    <Filter
                        updateFilter={this.updateFilter}
                        updateAllOrMeFilter={this.updateAllOrMeFilter}
                        type={type}
                        refineType={refineType}
                        mobileFilter={mobileFilter}
                        showMobileFilter={this.showMobileFilter}
                        isMobileDevice={isMobileDevice}
                    />
                    <Content
                        todos={todoss}
                        refineType={refineType}
                        cancelInterview={this.cancelInterview}
                        showInterviewModal={this.showInterviewModal}
                        setSelectedData={this.setSelectedData}
                        updateRefineType={this.updateRefineType}
                        callInProgress={this.props.callInProgress}
                        getNewPage={this.getNewPage}
                        hasMore={this.props.hasNextPage}
                        mobileFilter={mobileFilter}
                        showMobileFilter={this.showMobileFilter}
                        isMobileDevice={isMobileDevice}
                    />
                </TodoContainer>

                <ScheduleInterview
                    visible={this.state.showInterviewModal}
                    onCancel={this.handleCancel}
                    onSchedule={this.editInterview}
                    candidate={
                        interviewSelected && {
                            ...interviewSelected.candidate,
                            id: interviewSelected.candidate._id,
                        }
                    }
                    jobDetail={
                        (interviewSelected && {
                            stageName: interviewSelected.interviewStage.stageName,
                            jobTitle: interviewSelected.job.jobTitle,
                            stageId: interviewSelected.interviewStage._id,
                            jobId: interviewSelected.job._id,
                        }) ||
                        undefined
                    }
                    isRescheduled={true}
                    interview={interviewSelected}
                />

                <Modal
                    type={MODAL_TYPES.ADD_FEEDBACK}
                    key="key-todo-addfeedbackmodal"
                    visible={!!selectedFeedback}
                    interviewId={selectedFeedback && selectedFeedback._id}
                    candidateId={selectedFeedback && selectedFeedback.candidate._id}
                    stageId={selectedFeedback && selectedFeedback.interviewStage._id}
                    jobId={selectedFeedback && selectedFeedback.job._id}
                    candidateName={selectedFeedback && selectedFeedback.candidate.name}
                    onCancel={this.handleCancel}
                    onConfirm={this.feedbackUpdated}
                    filter={filter}
                    todoFilterType={type}
                />

                <Modal
                    data={selectedEmail}
                    type={MODAL_TYPES.SEND_EMAIL}
                    visible={!!selectedEmail}
                    onConfirm={result => this.handleSendEmail(result)}
                    onCancel={this.handleCancel}
                />

                <Modal
                    type={MODAL_TYPES.CALENDAR_ONBOARDING}
                    visible={!!calPopUpStatus}
                    onCancel={() =>
                        this.setState({ calPopUpStatus: false }, () => history.replace(window.location.pathname))
                    }
                    redirectPath={'todo'}
                    calPopUpStatus={calPopUpStatus}
                    bodyStyle={{ padding: 0 }}
                    closable={true}
                    width={750}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ Auth, Todo, GCal, Mixed: { teamMembers } }) => {
    const { interviews, callInProgress, currentPage, hasNextPage } = Todo
    return {
        inUse: GCal.inUse,
        userId: Auth.user.id,
        companyId: Auth.user.companyId,
        interviews,
        GCal,
        callInProgress,
        currentPage,
        hasNextPage,
        teamMembers,
        Auth,
    }
}

export default connect(
    mapStateToProps,
    { GetList, RescheduleInterview, GetCount, CancelInterview }
)(Todo)
