import React from 'react'
import { connect } from 'react-redux'
import { Rate, Input, Icon, Tooltip, Checkbox } from 'antd'
import { CreateFeedback, EditFeedback } from '../../../services/NetworkCalls/Feedback'
import { Block, ModalWrapper } from './style'
import { ModalSubTitle } from '../AddFeedback/style.js'
import { Header, Footer, FeedbackOpinionEditor } from '../common'
import { OpinionEditorBox } from '../ViewFeedbacks/style'
import Config from './../../../config'
import { StyledButton } from '../../Components'
import { SkillsPreview } from './Components/SkillsPreview'
import { DEFAULT_SKILLS, EDIT_TYPES } from './Constants'
import { SkillForm } from './Components/SkillsForm'
import PropTypes from 'prop-types'

const { isMobileDevice } = Config

class CustomizeFeedback extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            skills: [...DEFAULT_SKILLS, ...(props.customSkills || [])],
            formDataSkills: [],
            formMode: null,
            editSkill: null,
        }
    }

    onCancel = () => this.props.onCancel()

    onSubmit = () => this.props.onSubmit(this.state.skills.slice(4))

    addSkillMode = () => this.setState({ formMode: EDIT_TYPES.ADD, editSkill: null })

    addNewSkills = newSkills => {
        const { skills } = this.state
        const formatedSkillData = newSkills.map(({ name, description, required, _id = null }) => ({
            name,
            description,
            required,
            ...(_id ? { _id } : {}),
        }))
        this.setState({ skills: [...skills, ...formatedSkillData], formMode: null })
    }

    deleteSkill = index => {
        const { skills } = this.state
        this.setState({ skills: [...skills.slice(0, index), ...skills.slice(index + 1)] })
    }

    editSkill = index => {
        const { skills } = this.state
        this.setState({
            skills: [...skills.slice(0, index), ...skills.slice(index + 1)],
            editSkill: { ...skills[index], nameError: '', descriptionError: '', originalIndex: index },
            formMode: EDIT_TYPES.EDIT,
        })
    }

    cancelFormMode = value => {
        const { formMode, skills, editSkill } = this.state
        const resetState = { editSkill: null, formMode: value }

        // need to append the skill if it was edit mode
        if (formMode === EDIT_TYPES.EDIT) {
            const { originalIndex = 0 } = editSkill
            this.setState({
                skills: [...skills.slice(0, originalIndex), editSkill, ...skills.slice(originalIndex)],
                ...resetState,
            })
        } else this.setState(resetState)
    }

    render() {
        const { skills, formMode, editSkill } = this.state
        return (
            <>
                <Header title={`CUSTOMIZE FEEDBACK`} />
                <ModalWrapper mTop={false} className="customize">
                    <Block>
                        <ModalSubTitle>Your rating based on these skills</ModalSubTitle>

                        <SkillsPreview
                            skills={skills}
                            editSkill={this.editSkill}
                            onDelete={this.deleteSkill}
                            formMode={formMode}
                        />

                        <StyledButton
                            type="inverted"
                            width="130px"
                            height="40px"
                            hidden={formMode !== null || skills.length >= 8}
                            onClick={this.addSkillMode}
                        >
                            Add new skill
                        </StyledButton>

                        <SkillForm
                            formMode={formMode}
                            skills={skills}
                            changeFormMode={this.cancelFormMode}
                            confirmSkill={this.addNewSkills}
                            editSkill={editSkill}
                        />
                    </Block>

                    <Block>
                        <ModalSubTitle>Your overall opinion on the candidate*</ModalSubTitle>
                        <OpinionEditorBox>
                            <FeedbackOpinionEditor
                                points={10}
                                showText={isMobileDevice}
                                showPopUp
                                onChange={() => {}}
                            />
                        </OpinionEditorBox>
                    </Block>

                    <Block small>
                        <ModalSubTitle small>Overall Feedback*</ModalSubTitle>
                        <Input.TextArea placeholder="Enter your feedback here" rows={5} onChange={() => {}} />
                    </Block>
                </ModalWrapper>
                <Footer
                    disable={formMode !== null}
                    hasSeparator
                    onCancel={this.onCancel}
                    onConfirm={this.onSubmit}
                    cancelText="CANCEL"
                    confirmText="SAVE"
                />
            </>
        )
    }
}

CustomizeFeedback.defaultProps = {
    customSkills: null,
}

CustomizeFeedback.propTypes = {
    onSubmit: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        userId: state.Auth.user.id,
    }
}

export default connect(
    mapStateToProps,
    { CreateFeedback, EditFeedback }
)(CustomizeFeedback)
