import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Badge, Icon, Popover, Menu, Skeleton, Button, Select, Avatar, Tooltip } from 'antd'
import { ColumnContainer, Title, TaskList, TaskActionWrapper } from './style'
import './column.css'
import Card from './card'
import { store } from '../../../redux/store'
import InfiniteScroll from 'react-infinite-scroller'
import FilterIcon from '../../../assets/Icons/Board/Sort by'
import MoveIcon from '../../../assets/Icons/Board/Move Icon'
import EditIcon from '../../../assets/Icons/Board/Edit Icon'
import { success, warning } from '../../../services/Notification'
import {
    SendMultipleDoSelectTest,
    RejectMultipleCandidates,
    UpdateMultipleCandidatesStage,
    AssignStage,
} from '../../../services/api'
import getAvatar from '../../../services/getAvatar'
import className from 'classnames'
import { Modal } from '../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import { ACCESS_LEVEL_DEFINITIONS } from '../../Helpers/constants'

class TaskListComponent extends React.Component {
    render() {
        return (
            <TaskList
                ref={ref => {
                    this.props.provided.innerRef(ref)
                    this.scrollParentRef = ref
                }}
                {...this.props.provided.droppableProps}
            >
                <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    loadMore={this.props.nextDataSet}
                    hasMore={this.props.totalApplications > this.props.cards.length}
                    loader={
                        <div className="ats-skeleton" key={0}>
                            <Skeleton avatar={{ shape: 'square' }} paragraph={{ rows: 1 }} />
                        </div>
                    }
                    getScrollParent={() => this.scrollParentRef}
                >
                    {this.props.cards.map((card, i) => {
                        const interview = card.interviews.find(
                            ({ interviewStageId }) => interviewStageId === this.props.stageId
                        )

                        if (interview) {
                            card = Object.assign({}, card, interview)
                        }

                        const isCandidatesSelected = this.props.selectedCandidates.includes(card.jobAppId)

                        return (
                            <Card
                                hasInterview={this.props.hasInterview}
                                card={card}
                                index={i}
                                key={card.id}
                                stageId={this.props.stageId}
                                hasDoselectCredentials={this.props.hasDoselectCredentials}
                                candidateEvents={this.props.candidateEvents}
                                showSelectBox={this.props.showSelectBox}
                                isCandidatesSelected={isCandidatesSelected}
                                candidateSelectionChange={this.props.candidateSelectionChange}
                                filterName={this.props.filterName}
                                candidateStatusObject={this.props.candidateStatusObject}
                                stages={this.props.stages}
                            />
                        )
                    })}
                </InfiniteScroll>
                {this.props.provided.placeholder}
            </TaskList>
        )
    }
}

export default class Column extends React.Component {
    state = {
        accessLevel: store.getState().Auth.user.accessLevel,
        filterDatasource: [
            { name: 'Interview Date', key: 'interviewDate' },
            { name: 'Name', key: 'title' },
            { name: 'Total Experience', key: 'totalExperience' },
            { name: 'Added Date', key: 'createdAt' },
        ],
        columnOptions: [
            {
                key: 'Send DoSelect test',
                value: 0,
                hide: !this.props.hasDoselectCredentials,
            },
            { key: 'Move candidates', value: 1, hide: false },
            { key: 'Reject candidates', value: 2, hide: false },
        ],
        ...this.props,
        deleteModalVisible: false,
        warningDeleteModalVisible: false,
        eventIndex: null,
        selectedCandidates: [],
        selectedTest: null,
        selectedStage: null,
        rejectCandidatesModal: false,
    }

    handleCancel = () => {
        this.setState({
            deleteModalVisible: false,
            eventIndex: null,
            selectedCandidates: [],
            selectedTest: null,
            selectedStage: null,
            rejectCandidatesModal: false,
        })
    }

    componentWillReceiveProps(nextProps) {
        if (
            this.props.cards !== nextProps.cards ||
            this.props.index !== nextProps.index ||
            this.props.task !== nextProps.task ||
            this.props.hasDoselectCredentials !== nextProps.hasDoselectCredentials
        ) {
            this.setState(nextProps)
        }
    }

    changeFilter = key => {
        const cards = this.state.cards.sort((a, b) => (b[key] > a[key] ? 1 : -1)).map(a => a.id)
        this.props.sortCards(this.state.task.id, cards)
    }

    deleteStage = () => {
        const { allApplication, cards, filterName } = this.props
        if (!allApplication || (allApplication && allApplication.length === 0)) {
            this.setState({ deleteModalVisible: true })
        } else if (cards.length > 0) {
            this.setState({ warningDeleteModalVisible: true })
        } else if (cards.length === 0 && allApplication.length > 0) {
            let candidatesPresent
            switch (filterName) {
                case 'Active Candidates':
                    candidatesPresent = allApplication.find(app => app.status !== 1).status
                    break
                case 'Archived Candidates':
                    candidatesPresent = allApplication.find(app => app.status !== 0).status
                    break
                case 'Rejected Candidates':
                    candidatesPresent = allApplication.find(app => app.status !== 2).status
                    break
                case 'Joined Candidates':
                    candidatesPresent = allApplication.find(app => app.status !== 3).status
                    break
                default:
                    candidatesPresent = null
            }
            this.setState({ warningDeleteModalVisible: true, candidatesPresent })
        }
    }

    showSelectBox = eventIndex => {
        const allJobs = store.getState().Job.allJobs.slice()
        const { stages } = allJobs.find(job => job._id === this.props.jobId)
        const stage = stages[this.props.index + 1] || stages[0]

        this.setState({ eventIndex, selectedStage: stage })
    }

    selectTest = test => {
        this.setState({ selectedTest: test })
    }

    selectStage = stage => {
        this.setState({ selectedStage: stage })
    }

    sendTest = async () => {
        try {
            const {
                task: { id: stageId },
                selectedTest,
                selectedCandidates,
            } = this.state
            const input = {
                applications: selectedCandidates,
                slug: selectedTest.slug,
            }
            const res = await SendMultipleDoSelectTest(stageId, input)

            success(`${selectedTest.name} successfully assigned to ${res.length} candidate(s)`)
            this.handleCancel()
            this.props.candidateEvents(null, 'multipleTestAssigned', {
                candidates: res,
                slug: input.slug,
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    moveMultipleCandidate = async () => {
        try {
            const {
                task: { id: stageId },
                selectedStage,
                selectedCandidates,
            } = this.state

            const input = {
                applications: selectedCandidates,
                newStageId: selectedStage._id,
            }

            const res = await UpdateMultipleCandidatesStage(stageId, input)

            success(`${res.length} candidates(s) moved to stage ${selectedStage.stageName}.`)
            this.handleCancel()
            this.props.candidateEvents(null, 'multipleCandidatesMoved', {
                stageId,
                candidates: res.map(x => x.candidateId._id),
                newStageId: input.newStageId,
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    rejectCandidates = async () => {
        try {
            this.setState({ rejectCandidatesModal: false })
            const {
                task: { id: stageId, jobId },
                selectedCandidates,
            } = this.state

            const input = {
                applications: selectedCandidates,
            }

            const res = await RejectMultipleCandidates(stageId, input)

            success(`${res.length} candidate(s) has been rejected. You can view rejected candidates here.`, {
                pathname: `/openings/list/${jobId}?filter=rejected`,
                newTab: true,
            })
            this.handleCancel()
            this.props.candidateEvents(null, 'rejectMultiple', {
                stageId,
                candidates: res.map(x => x.candidateId),
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    showConfirmRejectModal = () => {
        this.setState({ rejectCandidatesModal: true })
    }

    getActionBtn = () => {
        const { selectedCandidates, selectedTest, selectedStage } = this.state
        let actionRef = null
        let count = selectedCandidates.length

        if (!count) {
            return 'Select one or more candidate(s)'
        }

        if (this.state.eventIndex === 0) {
            const doSelectTests = store.getState().Mixed.doSelectTests
            const displayTestName = selectedTest ? selectedTest.name : 'Select test'

            actionRef = (
                <div className="display-flex w-100">
                    <div className="black-black-base-900">
                        <span className="black-black-base-display">{displayTestName}</span>
                        <Popover
                            placement="bottomRight"
                            arrowPointAtCenter
                            overlayClassName="doselect-all-test"
                            content={
                                <Menu className="border-right-0">
                                    {doSelectTests.map(option => (
                                        <Menu.Item
                                            className="ats-col-popover-item sort-by-popover-item ats-multiple-pop-option"
                                            key={option.slug}
                                            onClick={() => this.selectTest(option)}
                                        >
                                            <span className="ats-col-popover-option">{option.name}</span>
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }
                        >
                            <Icon type="down" className="ats-col-test-down-icon" />
                        </Popover>
                    </div>
                    <div className="ats-multiple-text">for {count} candidates</div>
                    {selectedTest && (
                        <Button className="ats-col-test-action-btn" onClick={this.sendTest}>
                            Send
                        </Button>
                    )}
                </div>
            )
        } else if (this.state.eventIndex === 2) {
            actionRef = (
                <div>
                    <Button className="ats-col-action-btn" onClick={this.showConfirmRejectModal}>
                        Reject
                    </Button>
                    {count} candidates
                </div>
            )
        } else if (this.state.eventIndex === 1) {
            const allJobs = store.getState().Job.allJobs.slice()
            const stages = allJobs.find(job => job._id === this.props.jobId).stages.slice()
            stages.splice(this.props.index, 1)

            actionRef = (
                <div className="display-flex w-100">
                    <div className="black-black-base-900">
                        <span className="black-black-base-display">{selectedStage.stageName}</span>
                        <Popover
                            placement="bottomRight"
                            arrowPointAtCenter
                            content={
                                <Menu className="border-right-0">
                                    {stages.map(option => (
                                        <Menu.Item
                                            className="ats-col-popover-item sort-by-popover-item ats-multiple-pop-option"
                                            key={option._id}
                                            onClick={() => this.selectStage(option)}
                                        >
                                            <span className="ats-col-popover-option">{option.stageName}</span>
                                        </Menu.Item>
                                    ))}
                                </Menu>
                            }
                        >
                            <Icon type="down" className="ats-col-test-down-icon" />
                        </Popover>
                    </div>
                    <div className="ats-multiple-text">for {count} candidates</div>
                    <Button className="ats-col-test-action-btn" onClick={this.moveMultipleCandidate}>
                        Move
                    </Button>
                </div>
            )
        }

        return actionRef
    }

    selectAllCbChange = ({ target: { checked } }) => {
        if (checked) {
            const selectedCandidates = []
            const { cards } = this.state
            cards.forEach(card => selectedCandidates.push(card.jobAppId))
            this.setState({ selectedCandidates })
        } else {
            this.setState({ selectedCandidates: [] })
        }
    }

    candidateSelectionChange = (candidateId, checked) => {
        const { selectedCandidates } = this.state

        if (checked) {
            selectedCandidates.push(candidateId)
        } else {
            const index = selectedCandidates.indexOf(candidateId)
            selectedCandidates.splice(index, 1)
        }

        this.setState({ selectedCandidates })
    }

    assignStage = async (userId, { props: { title } }) => {
        try {
            const input = { stageId: this.state.task.id, userId }
            await AssignStage(input)
            success(`Stage ${this.state.task.title} has been assigned to ${title}`)
            this.props.candidateEvents(null, 'assignStage', input)
        } catch (err) {
            warning(err.message || err)
        }
    }

    render() {
        const { teamMembers } = store.getState().Mixed
        let assignedTo = null
        if (this.state.task.assignedTo) {
            assignedTo = teamMembers.find(mem => mem._id === this.state.task.assignedTo)
            assignedTo = assignedTo ? assignedTo.fullName || assignedTo.email : '(Disabled User)'
        }
        return (
            <Draggable
                draggableId={this.state.task.id}
                index={this.state.index}
                isDragDisabled={this.state.accessLevel > 2 || this.state.task.title === 'JOINED'}
            >
                {provided => (
                    <div
                        className="ats-column-container"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        id={this.state.task.id}
                    >
                        <TaskActionWrapper hidden={this.state.eventIndex === null}>
                            {this.getActionBtn()}
                            <i className="ats-action-cancel" onClick={this.handleCancel}>
                                x
                            </i>
                        </TaskActionWrapper>
                        <ColumnContainer
                            className={className({
                                'board-column': true,
                                'highlight-stage': this.state.task.highlightStage,
                            })}
                        >
                            <Title className="column-title-preview">
                                <Badge
                                    count={this.state.task.totalApplications}
                                    style={{
                                        backgroundColor: '#697398',
                                        color: '#fff',
                                        height: '24px',
                                        width: '24px',
                                        borderRadius: '50%',
                                        padding: 0,
                                        lineHeight: '24px',
                                        boxShadow: 'none',
                                    }}
                                />

                                <div className="modal-preview-title">
                                    <div className="column-title">{this.state.task.title}</div>
                                    {this.state.accessLevel < 3 &&
                                    !['MAKE OFFER', 'JOINED'].includes(this.state.task.title) ? (
                                        <span className="column-title-btn">
                                            <Icon
                                                className="column-edit-icon"
                                                component={EditIcon}
                                                onClick={() => this.props.editStage(this.state.task)}
                                            />
                                            {this.state.stageLength > 1 ? (
                                                <Icon
                                                    className="column-edit-icon"
                                                    type="delete"
                                                    onClick={this.deleteStage}
                                                />
                                            ) : null}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="column-items">
                                    {this.state.accessLevel !== ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel ? (
                                        <Popover
                                            arrowPointAtCenter
                                            placement="bottomRight"
                                            content={
                                                <Menu className="border-right-0" mode="vertical">
                                                    {this.state.columnOptions
                                                        .filter(option => !option.hide)
                                                        .map(option => (
                                                            <Menu.Item
                                                                className="ats-col-popover-item sort-by-popover-item"
                                                                key={option.value}
                                                                onClick={() => this.showSelectBox(option.value)}
                                                            >
                                                                <span className="ats-col-popover-option">
                                                                    {option.key}
                                                                </span>
                                                            </Menu.Item>
                                                        ))}

                                                    <Menu.Item
                                                        className="ats-col-popover-item sort-by-popover-item ats-assign-stage"
                                                        key="assignStage"
                                                    >
                                                        <Popover
                                                            placement="bottomRight"
                                                            content={
                                                                <Select
                                                                    className="ats-assign-tm-search"
                                                                    placeholder="Select team member"
                                                                    notFoundContent={null}
                                                                    showSearch
                                                                    suffixIcon={<Icon type="search" />}
                                                                    defaultActiveFirstOption={false}
                                                                    filterOption
                                                                    optionFilterProp="title"
                                                                    onSelect={this.assignStage}
                                                                >
                                                                    {teamMembers
                                                                        .filter(mem => {
                                                                            const status = mem.status === 1
                                                                            const accessCheck = ![5].includes(
                                                                                mem.accessLevel
                                                                            )
                                                                            return status && accessCheck
                                                                        })
                                                                        .map(mem => (
                                                                            <Select.Option
                                                                                key={mem._id}
                                                                                className="ats-col-popover-option sort-by-popover-item"
                                                                                title={mem.fullName || mem.email}
                                                                            >
                                                                                {mem.fullName || mem.email}
                                                                            </Select.Option>
                                                                        ))}
                                                                </Select>
                                                            }
                                                        >
                                                            <span className="ats-col-popover-option">Assign stage</span>
                                                            {assignedTo && (
                                                                <Tooltip
                                                                    title={`Stage has been assigned to ${assignedTo}`}
                                                                    overlayClassName="fs-10"
                                                                >
                                                                    <Avatar
                                                                        size="small"
                                                                        className="card-avatar-bg my-auto"
                                                                    >
                                                                        {getAvatar(assignedTo)}
                                                                    </Avatar>
                                                                </Tooltip>
                                                            )}
                                                        </Popover>
                                                    </Menu.Item>

                                                    <Menu.SubMenu
                                                        key="sort"
                                                        className="ats-col-submenu-item"
                                                        title={<span className="ats-col-popover-option">Sort by</span>}
                                                    >
                                                        {this.state.filterDatasource.map(filter => (
                                                            <Menu.Item
                                                                className="ats-col-popover-item sort-by-popover-item"
                                                                key={filter.key}
                                                                onClick={() => this.changeFilter(filter.key)}
                                                            >
                                                                <span className="ats-col-popover-option">
                                                                    {filter.name}
                                                                </span>
                                                            </Menu.Item>
                                                        ))}
                                                    </Menu.SubMenu>
                                                </Menu>
                                            }
                                        >
                                            {this.state.task.totalApplications ? (
                                                <Icon className="ats-col-filters sort-by-icon" type="menu" />
                                            ) : null}
                                        </Popover>
                                    ) : (
                                        <Popover
                                            title={<span className="sort-by-popover">SORT BY:</span>}
                                            arrowPointAtCenter
                                            placement="bottomRight"
                                            content={
                                                <Menu
                                                    className="border-right-0"
                                                    onSelect={({ key }) => this.changeFilter(key)}
                                                >
                                                    {this.state.filterDatasource.map(filter => (
                                                        <Menu.Item key={filter.key}>
                                                            <span className="sort-by-popover-option">
                                                                {filter.name}
                                                            </span>
                                                        </Menu.Item>
                                                    ))}
                                                </Menu>
                                            }
                                        >
                                            <Icon className="sort-by-icon" component={FilterIcon} />
                                        </Popover>
                                    )}
                                    {this.state.accessLevel < 3 && this.state.task.title !== 'JOINED' ? (
                                        <Icon
                                            className="column-drag-icon"
                                            component={MoveIcon}
                                            {...provided.dragHandleProps}
                                        />
                                    ) : null}
                                </div>

                                <Modal
                                    type={MODAL_TYPES.WARNING_DELETE_STAGE}
                                    visible={this.state.warningDeleteModalVisible}
                                    onConfirm={() =>
                                        this.setState({
                                            warningDeleteModalVisible: false,
                                        })
                                    }
                                    candidatesPresent={this.state.candidatesPresent}
                                />

                                <Modal
                                    type={MODAL_TYPES.CONFIRM_DELETE_STAGE}
                                    visible={this.state.deleteModalVisible}
                                    onConfirm={() => {
                                        this.setState({ deleteModalVisible: false })
                                        this.props.deleteStage(this.state.task)
                                    }}
                                    onCancel={this.handleCancel}
                                    stageName={this.props.task.title}
                                />
                            </Title>
                            <div className="column-select-all-cont">
                                <label className="col-cb-cont" hidden={this.state.eventIndex === null}>
                                    <span className="col-cb-select-all-label">Select All</span>
                                    <input
                                        type="checkbox"
                                        onChange={this.selectAllCbChange}
                                        checked={
                                            this.state.selectedCandidates.length === this.state.task.totalApplications
                                        }
                                    />
                                    <span className="col-cb-ch"></span>
                                </label>
                            </div>
                            <Droppable droppableId={this.state.task.id} type="card">
                                {provided => (
                                    <TaskListComponent
                                        totalApplications={this.state.task.totalApplications}
                                        stageId={this.state.task.id}
                                        cards={this.state.cards}
                                        hasInterview={this.state.task.hasInterview}
                                        provided={provided}
                                        hasDoselectCredentials={this.state.hasDoselectCredentials}
                                        candidateEvents={this.props.candidateEvents}
                                        nextDataSet={page => this.props.nextDataSet(this.state.task.id, page)}
                                        showSelectBox={this.state.eventIndex !== null}
                                        selectedCandidates={this.state.selectedCandidates}
                                        candidateSelectionChange={this.candidateSelectionChange}
                                        filterName={this.props.filterName}
                                        candidateStatusObject={this.state.candidateStatusObject}
                                        stages={this.state.stages}
                                    />
                                )}
                            </Droppable>
                        </ColumnContainer>

                        <Modal
                            type={MODAL_TYPES.CONFIRM_REJECT_CANDIDATE}
                            visible={this.state.rejectCandidatesModal}
                            onConfirm={this.rejectCandidates}
                            onCancel={this.handleCancel}
                            singleCandidate={false}
                            totalCandidates={this.state.selectedCandidates.length || 'the selected'}
                        />
                    </div>
                )}
            </Draggable>
        )
    }
}
