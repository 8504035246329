import React from 'react'
import './style.css'

export default function RightSide(props) {
    return (
        <div className="right-blocks">
            <div className="block-right-top">
                <div className="block-element block-e-13"></div>
                <div className="block-element block-e-14"></div>
                <div className="block-element block-e-15"></div>
            </div>
            <div className="block-right-second">
                <div className="block-element block-e-16"></div>
                <div className="block-element block-e-17"></div>
                <div className="block-element block-e-18 block-e-18-block-content">
                    <p>Intelligent Resume Parsing</p>
                    <div className="block-icon">
                        <img src={require('./images/intelligent.png').default} default />
                    </div>
                </div>
                <div className="block-element block-e-19 block-e-19-block-content">
                    <p>Quick sourcing</p>
                    <div className="block-icon">
                        <img src={require('./images/sourcing.svg').default} default />
                    </div>
                </div>
                <div className="block-element block-e-20"></div>
            </div>
            <div className="block-right-thrid">
                <div className="block-element block-e-21"></div>
                <div className="block-element block-e-22 block-e-22-block-content">
                    <p>Realtime Dashboard</p>
                    <div className="block-icon">
                        <img src={require('./images/realtime-dashbaord.png').default} default />
                    </div>
                </div>
                <div className="block-element block-e-23 block-e-23-block-content">
                    <p>Simple, elegant & advanced ATS for startups </p>
                    <div className="block-icon">
                        <img src={require('./images/ats-assembly.png').default} default />
                    </div>
                </div>
                <div className="block-element block-e-24"></div>
            </div>

            <div className="block-right-fourth">
                <div className="block-element block-e-25 block-e-23-block-content">
                    <p>Total control over job openings - Create, promote, manage and lot more!</p>
                    <div className="block-icon">
                        <img src={require('./images/total-control.png').default} default />
                    </div>
                </div>
                <div className="block-element block-e-26 block-e-22-block-content">
                    <p>Designed for collaboration</p>
                    <div className="block-icon">
                        <img src={require('./images/collabortation.png').default} default />
                    </div>
                </div>
                <div className="block-element block-e-27 block-e-18-block-content">
                    <p>One stop for candidates from all sources</p>
                    <div className="block-icon">
                        <img src={require('./images/candiate-src.png').default} default />
                    </div>
                </div>
            </div>

            <div className="block-right-fifth">
                <div className="block-element block-e-28"></div>
                <div className="block-element block-e-29"></div>
                <div className="block-element block-e-30"></div>
            </div>
            <div className="block-right-sixth">
                <div className="block-element block-e-31"></div>
            </div>
        </div>
    )
}
