import React, { Component } from 'react'
import { Icon, Typography, Tooltip, Button, Input, Skeleton } from 'antd'
import CountUp from 'react-countup'
import TickIcon from '../../../assets/Icons/Dashboard/Tick'
import AddCandidateIcon from '../../../assets/Icons/Dashboard/Add Candidate Icon.1'
import { ReactComponent as Briefcase } from '../../../assets/Icons/Dashboard/briefcase.svg'
import { ReactComponent as Clock } from '../../../assets/Icons/Dashboard/clock.svg'
import { ReactComponent as InfoCardIcon } from '../../../assets/Icons/Openings/info.svg'
import InfoIcon from '../../../assets/Icons/Dashboard/Info'
import ThumbsUpIcon from '../../../assets/Icons/Dashboard/ThumbsUp'
import DashCard from './Components/DashCard'
import JobOpening from './Components/JobOpening'
import CandidateApplication from './Components/CandidateApplication'
import ScheduledInterview from './Components/ScheduledInterview'
import TeamActivity from './Components/TeamActivity'
import { Modal, FSModal } from '../../Modals'
import { LogE } from './../../Helpers/errorHandler'
import { DASHBOARD_COUNTER_DURATION, ACCESS_LEVEL_DEFINITIONS, FILE_STATUS } from './../../Helpers/constants'
import {
    BodyContainer,
    DashboardContainer,
    HeaderContainer,
    DashboardTitleContainer,
    FullCardWrapper,
    AssignmentWrapper,
    InterviewContainer,
    NoDataWrapper,
    CompanyEmailWrapper,
    Total,
    ViewAllButton,
    InterviewItem,
    CandidateName,
    Role,
    Type,
    Time,
    Date,
    Detail,
} from './StyleComponent'
import styles from './style'
import './style.css'
import DashCards from './dashboardCards'
import AddCandidateModal from '../../Components/addCandidate'
import { GetDashboardData, UpdateJob, AddTeamMember } from '../../../services/api'
import { history, store } from '../../../redux/store'
import moment from 'moment'
import { success, warning } from './../../../services/Notification'
import { HotKeys } from 'react-keyboard'
import { keyMap, access } from '../../Helpers/KeyMap'
import HiddenInput from '../../Helpers/HiddenInput'
import { shouldCalPopBeOpened, AUTH_ENTRY_POINTS, FORCE_OPEN } from './../../Helpers/calendarPopUp'
import { BannerGoogleCal } from './../../Banners'
import { timeFormat } from '../../Helpers/times'
import { MODAL_TYPES, FSMODAL_TYPES } from '../../Helpers/ModalTypes'
import { connect } from 'react-redux'
import Config from './../../../config'
import actionsCandidate from '../../../redux/candidate/actions'
import { randomString } from '../../Helpers/utils'
const { isMobileDevice } = Config

const { Text, Title } = Typography
const dashboardAccess = access.dashboard

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            jobs: [],
            candidates: [],
            interviews: [],
            teamMembers: [],
            assignedCandidates: [],
            assignedStages: [],
            data: DashCards,
            currentTab: 'JobOpening',
            showAddCandidateModal: false,
            showInviteTeamMemberModal: false,
            isLoading: true,
            activeOpenings: 0,
            callInProgress: false,
        }
        document.title = 'Dashboard | SpringRecruit'
    }

    openAddCandidateModal = () => {
        store.dispatch({ type: actionsCandidate.CLEAR_CANDIDATES })
        store.dispatch({ type: actionsCandidate.ADD_CANDIDATES })

        this.setState({ showAddCandidateModal: true })
    }

    openInviteMemberModal = (jobId = null) => {
        this.setState({ showInviteTeamMemberModal: true, coordinatorJob: jobId })
    }

    componentDidMount() {
        // promise should be used in componentDidMount not async/await
        GetDashboardData()
            .then(res => {
                const {
                    location: { state },
                } = this.props.router
                const calPopUpStatus =
                    (state &&
                        state.entryPoint === AUTH_ENTRY_POINTS.SIGN_UP &&
                        shouldCalPopBeOpened(this.props.__redux.GCal)) ||
                    false
                this.setState({ ...res, isLoading: false, calPopUpStatus })
            })
            .catch(err => LogE('pages-dashboard-index-componentDidMount', '', err))
        this.props.allowSwitchTabs(true)
    }

    handleTab = currentTab => {
        this.setState({ currentTab })
    }

    handleCancel = () => {
        this.setState({ showAddCandidateModal: false, showInviteTeamMemberModal: false })
        this.copyRef.focus({ preventScroll: true })
    }

    addTeamMember = ({ email, accessibleJobs, accessLevel }) => {
        try {
            this.setState({ callInProgress: true }, async () => {
                let details = {
                    email,
                    accessibleJobs,
                    accessLevel,
                    userId: this.props.userId,
                    companyId: this.props.companyId,
                }

                await AddTeamMember(details)

                this.setState({
                    showInviteTeamMemberModal: false,
                    callInProgress: false,
                })
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    copyText = elementId => {
        const id = document.getElementById(elementId)

        let message = ''
        const value = id.value
        message = `The unique email id “${value}” to apply for the job has been copied to clipboard.`
        success(message)
        id.select()
        document.execCommand('copy')
    }

    gotoOpenings = () => {
        this.props.history.push('/openings')
    }

    gotoTodo = () => {
        this.props.history.push('/todo')
    }

    redirectTo = data => {
        if (data.candidateId) {
            window.open(`/candidate/${data.candidateId._id}`, '_blank')
        } else {
            this.props.history.push({
                pathname: `/openings/board/${data.jobId._id}`,
                state: { highlightStage: true, stageId: data._id },
            })
        }
    }

    // @PROP: used in JobList.js to archive a job and updating the state
    jobRemover = (job, sendEmailToCandidate = true) => {
        let { jobs, candidates, interviews, activeOpenings } = this.state
        jobs = jobs.filter(_job => _job._id !== job._id)
        candidates = candidates.filter(candidate => candidate.jobId !== job._id)
        interviews = interviews.filter(interview => interview.jobId._id !== job._id)

        UpdateJob({ status: 0, sendEmailToCandidate }, job._id)
            .then(res => {
                this.setState({ jobs, candidates, activeOpenings: --activeOpenings, interviews }, () =>
                    success(`${job.jobTitle} opening has been archived`)
                )
            })
            .catch(err => {
                LogE('dashboard-archive-job', 'jobRemover', err)
                warning(err.message || err)
            })
    }

    handlers = {
        createJob: () => {
            if (this.props.allowShortcuts && this.props.accessLevel <= dashboardAccess.createJob)
                this.props.history.push('openings/createJob')
        },
        createCandidate: () => {
            if (this.props.allowShortcuts && this.props.accessLevel <= dashboardAccess.createCandidate)
                this.openAddCandidateModal()
        },
        inviteTeamMember: () => {
            if (this.props.allowShortcuts && this.props.accessLevel <= dashboardAccess.inviteTeamMember)
                this.openInviteMemberModal()
        },
        switchDashboardTabRight: e => {
            if (this.props.allowShortcuts && this.props.accessLevel <= 4) {
                e.preventDefault()
                let newTab = 'JobOpening'
                switch (this.state.currentTab) {
                    case 'JobOpening':
                        newTab = 'CandidateApplication'
                        break
                    case 'CandidateApplication':
                        newTab = 'ScheduledInterview'
                        break
                    case 'ScheduledInterview':
                        newTab = this.props.accessLevel <= 2 ? 'TeamActivity' : 'JobOpening'
                        break
                    case 'TeamActivity':
                        newTab = 'JobOpening'
                        break
                    default:
                        newTab = 'JobOpening'
                }
                this.handleTab(newTab)
            }
        },
        switchDashboardTabLeft: e => {
            if (this.props.allowShortcuts && this.props.accessLevel <= 4) {
                e.preventDefault()
                let newTab = 'JobOpening'
                switch (this.state.currentTab) {
                    case 'JobOpening':
                        newTab = this.props.accessLevel <= 2 ? 'TeamActivity' : 'ScheduledInterview'
                        break
                    case 'CandidateApplication':
                        newTab = 'JobOpening'
                        break
                    case 'ScheduledInterview':
                        newTab = 'CandidateApplication'
                        break
                    case 'TeamActivity':
                        newTab = 'ScheduledInterview'
                        break
                    default:
                        newTab = 'JobOpening'
                }
                this.handleTab(newTab)
            }
        },
    }

    render() {
        const { history, accessLevel, companyName, userName } = this.props
        const {
            jobs,
            candidates,
            currentTab,
            data,
            interviews,
            assignedStages,
            assignedCandidates,
            activeOpenings,
            calPopUpStatus,
        } = this.state

        const showDashCards = !!(this.props.allJobs && this.props.allJobs.length)

        let unpublished = 0
        let published = activeOpenings
        let newCandidates = 0

        jobs.forEach(job => {
            if (!job.status) unpublished += 1
        })

        candidates.forEach(candidate => {
            newCandidates = newCandidates + candidate.candidates.length
        })

        const assignments = [...assignedCandidates, ...assignedStages]
            .map(x => {
                x.displayName = x.candidateId ? x.candidateId.name : `${x.stageName} stage`
                return x
            })
            .sort((a, b) => (b.assignedAt > a.assignedAt ? 1 : -1))

        return (
            <HotKeys keyMap={keyMap.dashboard} handlers={this.handlers}>
                <div className="dashboard-main-container">
                    <BannerGoogleCal
                        data={this.props.__redux.GCal}
                        action_allowAccess={() => this.setState({ calPopUpStatus: FORCE_OPEN.AUTHENTICATE })}
                        action_setManually={() => this.setState({ calPopUpStatus: FORCE_OPEN.AVAILABILITY })}
                    />
                    {!showDashCards ? (
                        <HeaderContainer>
                            <Title level={1}>Welcome {userName || companyName}!</Title>
                            {accessLevel < 3 && <Title level={3}>Let's get started by creating a Job Opening</Title>}
                        </HeaderContainer>
                    ) : (
                        accessLevel < 5 && <span className="dashboard-text">DASHBOARD</span>
                    )}

                    <DashboardContainer>
                        <div style={styles.gridContainer}>
                            <DashboardTitleContainer
                                selected={currentTab === 'JobOpening'}
                                boxGrow={[3, 4].includes(accessLevel)}
                                onClick={() => this.handleTab('JobOpening')}
                                visible={accessLevel < 5}
                            >
                                <Total>
                                    {this.state.isLoading ? (
                                        <Skeleton
                                            loading={true}
                                            paragraph={false}
                                            title={false}
                                            avatar={{ shape: 'square', size: 'large' }}
                                            active
                                        />
                                    ) : (
                                        <CountUp
                                            className="card-count"
                                            start={0}
                                            end={published}
                                            duration={DASHBOARD_COUNTER_DURATION}
                                        />
                                    )}
                                    <Tooltip title="Active Openings Includes Both Published And Unpublished Openings">
                                        <InfoCardIcon />
                                    </Tooltip>
                                </Total>
                                {this.state.isLoading ? (
                                    <Text style={styles.gridRowText}>
                                        <Skeleton
                                            loading={true}
                                            title={{ width: isMobileDevice ? 60 : 200 }}
                                            paragraph={false}
                                            active
                                        />
                                    </Text>
                                ) : activeOpenings === 0 ? (
                                    <Text style={styles.gridRowText}>Active Openings</Text>
                                ) : published && unpublished ? (
                                    <div>
                                        <Text style={styles.gridRowText}>
                                            You have {unpublished} unpublished job{unpublished > 1 ? 's' : ''}
                                        </Text>
                                        <br />
                                        <Text style={styles.gridRowText}>
                                            {published} active job opening{published > 1 ? 's' : ''}
                                        </Text>
                                    </div>
                                ) : unpublished ? (
                                    <Text style={styles.gridRowText}>
                                        You have {unpublished} unpublished job opening{unpublished > 1 ? 's' : ''}
                                    </Text>
                                ) : published ? (
                                    <Text className="card-count-text" style={styles.gridRowText}>
                                        Active Openings
                                    </Text>
                                ) : null}
                            </DashboardTitleContainer>
                            <DashboardTitleContainer
                                selected={currentTab === 'CandidateApplication'}
                                boxGrow={[3, 4].includes(accessLevel)}
                                onClick={() => this.handleTab('CandidateApplication')}
                                visible={accessLevel < 5}
                            >
                                <Total>
                                    {this.state.isLoading ? (
                                        <Skeleton
                                            loading={true}
                                            paragraph={false}
                                            title={false}
                                            avatar={{ shape: 'square', size: 'large' }}
                                            active
                                        />
                                    ) : (
                                        <CountUp
                                            className="card-count"
                                            start={0}
                                            end={newCandidates || 0}
                                            duration={DASHBOARD_COUNTER_DURATION}
                                        />
                                    )}
                                </Total>
                                <Text className="card-count-text" style={styles.gridRowText}>
                                    {this.state.isLoading ? (
                                        <Skeleton
                                            loading={true}
                                            title={{ width: isMobileDevice ? 60 : 200 }}
                                            paragraph={false}
                                            active
                                        />
                                    ) : (
                                        'New Candidates'
                                    )}
                                </Text>
                            </DashboardTitleContainer>
                            <DashboardTitleContainer
                                selected={currentTab === 'ScheduledInterview'}
                                boxGrow={[3, 4, 5].includes(accessLevel)}
                                onClick={() => this.handleTab('ScheduledInterview')}
                                visible={accessLevel < 5}
                            >
                                <Total>
                                    {this.state.isLoading ? (
                                        <Skeleton
                                            loading={true}
                                            paragraph={false}
                                            title={false}
                                            avatar={{ shape: 'square', size: 'large' }}
                                            active
                                        />
                                    ) : (
                                        <CountUp
                                            className="card-count"
                                            start={0}
                                            end={interviews.length}
                                            duration={DASHBOARD_COUNTER_DURATION}
                                        />
                                    )}
                                </Total>
                                <Text className="card-count-text" style={styles.gridRowText}>
                                    {this.state.isLoading ? (
                                        <Skeleton
                                            loading={true}
                                            title={{ width: isMobileDevice ? 60 : 200 }}
                                            paragraph={false}
                                            active
                                        />
                                    ) : (
                                        "Today's Interviews"
                                    )}
                                </Text>
                            </DashboardTitleContainer>
                            {[1, 2].includes(accessLevel) ? (
                                <DashboardTitleContainer
                                    selected={currentTab === 'TeamActivity'}
                                    boxGrow={[3, 4].includes(accessLevel)}
                                    onClick={() => this.handleTab('TeamActivity')}
                                    visible={[1, 2].includes(accessLevel)}
                                >
                                    <Total>
                                        {this.state.isLoading ? (
                                            <Skeleton
                                                loading={true}
                                                paragraph={false}
                                                title={false}
                                                avatar={{ shape: 'square', size: 'large' }}
                                                active
                                            />
                                        ) : (
                                            <CountUp
                                                className="card-count"
                                                start={0}
                                                end={0}
                                                duration={DASHBOARD_COUNTER_DURATION}
                                            />
                                        )}
                                    </Total>
                                    <Text className="card-count-text" style={styles.gridRowText}>
                                        {this.state.isLoading ? (
                                            <Skeleton
                                                loading={true}
                                                title={{ width: isMobileDevice ? 60 : 200 }}
                                                paragraph={false}
                                                active
                                            />
                                        ) : (
                                            'New Activities'
                                        )}
                                    </Text>
                                </DashboardTitleContainer>
                            ) : null}
                        </div>

                        {this.state.isLoading ? (
                            <Skeleton active />
                        ) : currentTab === 'JobOpening' ? (
                            <JobOpening
                                jobRemover={this.jobRemover}
                                history={history}
                                accessLevel={accessLevel}
                                jobs={this.state.jobs}
                                activeOpenings={activeOpenings}
                            />
                        ) : currentTab === 'CandidateApplication' ? (
                            <CandidateApplication
                                history={history}
                                openAddCandidateModal={this.openAddCandidateModal}
                                candidates={candidates}
                                jobs={jobs}
                            />
                        ) : currentTab === 'ScheduledInterview' ? (
                            <ScheduledInterview history={history} interviews={interviews} hasJobs={jobs.length} />
                        ) : (
                            <TeamActivity />
                        )}
                    </DashboardContainer>

                    {showDashCards && accessLevel !== 5 && currentTab === 'JobOpening' && (
                        <div className="view-all">
                            <ViewAllButton onClick={this.gotoOpenings}>VIEW ALL JOB OPENINGS</ViewAllButton>
                        </div>
                    )}

                    {accessLevel === ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel ? (
                        <>
                            <span className="dashboard-text">SCHEDULED INTERVIEWS</span>
                            <InterviewContainer>
                                {this.state.isLoading ? (
                                    <Skeleton title={false} paragraph={{ width: '100%' }} active />
                                ) : interviews.length ? (
                                    interviews.map(interviewData => (
                                        <InterviewItem key={interviewData._id}>
                                            <CandidateName>
                                                {interviewData.candidateId && interviewData.candidateId.name}
                                            </CandidateName>
                                            <Role>{interviewData.jobId.jobTitle}</Role>
                                            <Type>{interviewData.interviewType}</Type>
                                            <Time>
                                                <Icon className="interview-time-icon" component={Clock} />
                                                {moment(interviewData.interviewStartTime).format(timeFormat)}
                                            </Time>
                                            <Date>Today</Date>
                                            <Detail>
                                                <Button
                                                    className="interview-details"
                                                    onClick={() => this.redirectTo(interviewData)}
                                                >
                                                    DETAILS
                                                </Button>
                                            </Detail>
                                        </InterviewItem>
                                    ))
                                ) : (
                                    <NoDataWrapper>
                                        <div className="no-interview">
                                            <div className="no-interview-text">
                                                All good here! No new interviews has been scheduled for you.
                                            </div>
                                            <div className="no-interview-icon">
                                                <Icon component={ThumbsUpIcon} />
                                            </div>
                                        </div>
                                    </NoDataWrapper>
                                )}
                            </InterviewContainer>
                        </>
                    ) : null}

                    {showDashCards &&
                        accessLevel !== 5 &&
                        currentTab === 'ScheduledInterview' &&
                        interviews.length > 3 && (
                            <div className="view-all">
                                <ViewAllButton onClick={this.gotoTodo}>VIEW ALL INTERVIEWS</ViewAllButton>
                            </div>
                        )}

                    {accessLevel ? (
                        this.props.companyUniqueEmailId ? (
                            <div className="dashboard-assignment-cont">
                                <span className="dashboard-text">COMPANY EMAIL ID</span>
                                <div className="ce-body">
                                    <CompanyEmailWrapper>
                                        <div className="ce-block-left">
                                            <p className="ce-block-notes">
                                                The following unique email ID has been generated for your company:
                                            </p>
                                            <Input
                                                id="uniqueCompanyEmail"
                                                style={styles.referLink}
                                                size="large"
                                                placeholder="springrole@springrecruit.com"
                                                value={this.props.companyUniqueEmailId}
                                                className="ce-text"
                                            />
                                            <Button
                                                type="primary"
                                                className="ce-button"
                                                onClick={() => this.copyText('uniqueCompanyEmail')}
                                            >
                                                Copy Email
                                            </Button>
                                        </div>
                                        <div className="ce-block-right">
                                            <div className="ce-notes">
                                                <div className="ce-icon">
                                                    <Icon component={TickIcon}></Icon>
                                                </div>
                                                <div className="ce-noteProperties">
                                                    <p>
                                                        The company email can be used to send candidate resumes to the
                                                        platform{' '}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="ce-notes">
                                                <div className="ce-icon">
                                                    <Icon component={TickIcon}></Icon>
                                                </div>
                                                <div className="ce-noteProperties">
                                                    <p>
                                                        Remember to add the <b>job opening name</b> in the{' '}
                                                        <b>subject line of the email</b> and we will add the candidate
                                                        to that job opening
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="ce-notes">
                                                <div className="ce-icon">
                                                    <Icon component={TickIcon}></Icon>
                                                </div>
                                                <div className="ce-noteProperties">
                                                    <p>All candidate communication will be done via this email id</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CompanyEmailWrapper>
                                </div>
                            </div>
                        ) : null
                    ) : null}
                    {accessLevel < ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel && !!assignments.length && (
                        <div className="dashboard-assignment-cont">
                            <span className="dashboard-text">
                                Assignments
                                <Tooltip
                                    placement="top"
                                    title="This section lists the assigned stages/assigned candidates that the admin wants you to handle and proceed with the recruitment process."
                                    overlayClassName="ya-tooltip"
                                >
                                    &nbsp; <Icon component={InfoIcon} />
                                </Tooltip>
                            </span>
                            <div className="dashboard-assignment-body">
                                {this.state.isLoading ? (
                                    <Skeleton title={{ width: '100%' }} paragraph={false} active />
                                ) : assignments.length ? (
                                    assignments.map(x => (
                                        <AssignmentWrapper key={x._id}>
                                            <div className="ya-title">
                                                <span
                                                    className="ya-title-bold cursor-pointer"
                                                    onClick={() => this.redirectTo(x)}
                                                >
                                                    {x.displayName}&nbsp;
                                                </span>
                                                was assigned to you by
                                                <span className="ya-title-bold">
                                                    &nbsp;{x.assignedBy && x.assignedBy.fullName}
                                                </span>
                                            </div>

                                            <div className="ya-role">
                                                <span className="ya-role-title">
                                                    <Icon component={Briefcase} />
                                                </span>
                                                <span
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        x.jobId
                                                            ? history.push({
                                                                  pathname: `/openings/board/${x.jobId._id}`,
                                                                  state: {
                                                                      highlightStage: !x.candidateId,
                                                                      stageId: x._id,
                                                                  },
                                                              })
                                                            : null
                                                    }
                                                >
                                                    {x.jobId ? x.jobId.jobTitle : 'Not Assigned'}
                                                </span>
                                            </div>

                                            <div className="ya-date">
                                                <span className="ya-date-title">
                                                    <Icon component={Clock} /> {moment(x.assignedAt).format('hh:mm A')}
                                                </span>
                                                <span>
                                                    <Icon type="calendar" /> {moment(x.assignedAt).format('Do MMM, Y')}
                                                </span>
                                            </div>
                                        </AssignmentWrapper>
                                    ))
                                ) : (
                                    <NoDataWrapper>
                                        <div>All good here! No new candidate has been assigned to you.</div>
                                        <Icon component={ThumbsUpIcon} />
                                    </NoDataWrapper>
                                )}
                            </div>
                        </div>
                    )}

                    <BodyContainer className="job-description-parent">
                        {[1, 2].includes(accessLevel) &&
                            data.map((dashCard, index) => (
                                <DashCard
                                    key={index}
                                    history={history}
                                    data={dashCard}
                                    inviteTeamMember={this.openInviteMemberModal}
                                />
                            ))}

                        {[3, 4].includes(accessLevel) && (
                            <FullCardWrapper>
                                <div className="dashboard-3-title">
                                    Candidate addition made easier, you can add candidates in the following possible
                                    ways:
                                </div>
                                <div className="dash-card-info">
                                    <div>
                                        <div className="display-flex dash-card-info-inner">
                                            <Icon component={TickIcon} className="dashboard-icon-tick" />
                                            <Text className="dashboard-card-text-3">
                                                You can directly add candidates from here and assign them to job
                                                openings
                                            </Text>
                                        </div>

                                        <div className="display-flex dash-card-info-inner">
                                            <Icon component={TickIcon} className="dashboard-icon-tick" />
                                            <Text className="dashboard-card-text-3">
                                                You can add candidates to specific job opening directly
                                            </Text>
                                        </div>

                                        <div className="display-flex dash-card-info-inner">
                                            <Icon component={TickIcon} className="dashboard-icon-tick" />
                                            <div className="dashboard-card-text-3">
                                                You can use the unique email id specific to each job opening to forward
                                                candidates from other ATS, email applications etc
                                            </div>
                                        </div>
                                    </div>

                                    <Icon
                                        className="dash-card-info-icon"
                                        component={AddCandidateIcon}
                                        onClick={this.openAddCandidateModal}
                                    />
                                </div>
                            </FullCardWrapper>
                        )}
                    </BodyContainer>

                    <Modal
                        type={MODAL_TYPES.ADD_CANDIDATE}
                        visible={this.state.showAddCandidateModal}
                        onCancel={this.handleCancel}
                        onSubmit={this.handleCancel}
                    />

                    <FSModal
                        type={FSMODAL_TYPES.INVITE_TEAM_MEMBER}
                        visible={this.state.showInviteTeamMemberModal}
                        onCancel={this.handleCancel}
                        onConfirm={this.addTeamMember}
                        callInProgress={this.state.callInProgress}
                    />

                    <Modal
                        type={MODAL_TYPES.CALENDAR_ONBOARDING}
                        visible={!!calPopUpStatus}
                        onCancel={() =>
                            this.setState({ calPopUpStatus: false }, () => history.replace(window.location.pathname))
                        }
                        redirectPath={'dashboard'}
                        bodyStyle={{ padding: 0 }}
                        width={750}
                        closable={true}
                        calPopUpStatus={calPopUpStatus}
                    />
                </div>

                <HiddenInput reference={ip => (this.copyRef = ip)} />
            </HotKeys>
        )
    }
}

const mapStateToProps = ({ Auth: { user }, Job: { allJobs }, router }) => {
    return {
        companyName: user.companyName,
        userName: user.userName,
        accessLevel: user.accessLevel,
        allowShortcuts: user.allowShortcuts,
        companyUniqueEmailId: user.companyUniqueEmailId,
        router,
        allJobs,
        userId: user.id,
        companyId: user.companyId,
    }
}

export default connect(mapStateToProps)(Dashboard)
