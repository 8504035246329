import ContentLoader from 'react-content-loader'
import React from 'react'
import { Card, Footer } from './../style'
export const TodoCardLoader = () => (
    <Card>
        <ContentLoader>
            <rect x="3.75%" y="15" rx="1" ry="1" width="80" height="10" />
            <rect x="3.75%" y="33" rx="1" ry="1" width="55" height="8" />
            <rect x="3.75%" y="50" rx="1" ry="1" width="100" height="7" />
            <rect x="3.75%" y="63" rx="1" ry="1" width="100" height="7" />
            <rect x="3.75%" y="76" rx="1" ry="1" width="100" height="7" />
            <rect x="41.25%" y="30" rx="0" ry="0" width="1" height="90" />
            <rect x="53.75%" y="33" rx="1" ry="1" width="55" height="8" />
            <rect x="53.75%" y="50" rx="1" ry="1" width="100" height="7" />
            <rect x="53.75%" y="63" rx="1" ry="1" width="100" height="7" />
            <rect x="53.75%" y="76" rx="1" ry="1" width="100" height="7" />
        </ContentLoader>
        <Footer>
            <span style={{ color: '#f1f1f1' }}>Pseudo content</span>
        </Footer>
    </Card>
)

export const MobileCardLoader = () => (
    <Card>
        <ContentLoader height={450}>
            <rect x="40" y="30" rx="1" ry="1" width="140" height="13" />
            <rect x="40" y="70" rx="1" ry="1" width="100" height="10" />
            <rect x="40" y="100" rx="1" ry="1" width="220" height="7" />
            <rect x="40" y="125" rx="1" ry="1" width="220" height="7" />
            <rect x="40" y="150" rx="1" ry="1" width="220" height="7" />
            <rect x="40" y="175" rx="1" ry="1" width="220" height="7" />
            <rect x="40" y="200" rx="1" ry="1" width="220" height="7" />
            <rect x="40" y="240" rx="1" ry="1" width="100" height="10" />
            <rect x="40" y="270" rx="1" ry="1" width="220" height="7" />
            <rect x="40" y="295" rx="1" ry="1" width="220" height="7" />
            <rect x="40" y="320" rx="1" ry="1" width="220" height="7" />
            <rect x="40" y="345" rx="1" ry="1" width="220" height="7" />
            <rect x="40" y="370" rx="1" ry="1" width="220" height="7" />
        </ContentLoader>
        <Footer>
            <span style={{ color: '#f1f1f1' }}>Pseudo content</span>
        </Footer>
    </Card>
)
