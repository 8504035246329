import React, { useState, useEffect } from 'react'

const HowItWorks = props => {
    const [variable, setVariable] = useState(true)
    return (
        <>
            <h3>How does Sourcing Initiative works?</h3>
            <ul className="desc">
                <li>Subscribe to sourcing by choosing</li>
                <ul>
                    <li>Type of candidate you want to source </li>
                    <li>Job openings you want to source candidate from</li>
                </ul>
                <li>
                    Go to Sourcing Talent Pool to check assembled pool of candidates, post subscription to initiative
                </li>
            </ul>
            <ul className="desc info">
                <li>
                    Candidate profile added by access role 'Recruiter' will not be part of the sourcing initative to
                    maintain confidentiality of data
                </li>
            </ul>
        </>
    )
}

export default HowItWorks
