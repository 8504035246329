import React from 'react'
import { connect } from 'react-redux'
import './style.css'
import {
    HeaderContainer,
    FilterContainer,
    FilterWrapper,
    ListContainer,
    ListFilterWrapper,
    CandidateHeaderWrapper,
    CandidateListWrapper,
    ListCheckboxWrapper,
    CandidateWrapper,
    StageWrapper,
    DateWrapper,
    FilterListWrapper,
} from './style'
import { Button, Typography, Icon, Tag, Popover, Menu, Badge, Tooltip, Skeleton } from 'antd'
import moment from 'moment'
import AddIcon from '../../../assets/Icons/Talent Pool/Add'
import CLBoradViewIcon from '../../../assets/Icons/Board/CLBoradView'
import CLViewIcon from '../../../assets/Icons/Board/CLView'
import { success, warning } from '../../../services/Notification'
import {
    GetJobLIstViewData,
    UpdateCandidateStage,
    UpdateMultipeCandidateStage,
    ArchiveApplications,
} from '../../../services/api'
import AddCandidateModal from '../../Components/addCandidate'
import { store } from '../../../redux/store'
import { randomString, validateJobId } from './../../Helpers/utils'
import { HotKeys } from 'react-keyboard'
import { keyMap, access } from '../../Helpers/KeyMap'
import { ACCESS_LEVEL_DEFINITIONS, FILE_STATUS } from '../../Helpers/constants'
import { Modal } from '../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import actionsCandidate from '../../../redux/candidate/actions'

const { Title } = Typography

class CandidatesList extends React.Component {
    constructor(props) {
        super(props)
        this.allowShortcuts = store.getState().Auth.user.allowShortcuts
        let filter = props.location.state ? props.location.state.filter : 'all'
        if (props.location.search) {
            if (props.location.search.indexOf('filter=rejected')) {
                filter = 'rejected'
            }
        }

        this.state = {
            jobDetails: {},
            stages: [],
            candidates: [],
            sortOptions: [
                { key: 'appliedDate', name: 'Applied Date' },
                { key: 'name', name: 'Candidates' },
                { key: 'stage', name: 'Current Stage' },
            ],
            filter,
            selectedSortOption: 'appliedDate',
            selectedCandidates: [],
            selectedCandidatesObject: {},
            jobId: props.match.params.jobId,
            // props value is safe to save in state initialisation as the props will not change
            isJobValid: validateJobId(props.match.params.jobId),
            showAddCandidateModal: false,
            searchCandidate: props.location.state ? props.location.state.searchCandidate : null,
            isLoading: true,
            visible: false,
            archiveReasonModal: false,
            clickedOnce: false,
        }
    }

    async componentDidMount() {
        try {
            const { searchCandidate } = this.state
            const res = await GetJobLIstViewData(this.state.jobId)
            if (res.error) {
                warning(res.error)
                return this.props.history.push(this.props.accessLevel === 5 ? '/dashboard' : '/openings')
            }
            const userId = store.getState().Auth.user.id

            const candidates = res.jobApplications.map(app => ({
                jobAppId: app._id,
                id: app.candidateId._id,
                name: app.candidateId.name,
                details: '',
                stage: res.interviewStages.filter(stg => stg._id === app.stageId)[0]
                    ? res.interviewStages.filter(stg => stg._id === app.stageId)[0].stageName
                    : '',
                appliedDate: app.createdAt,
                stared: app.isFavourite,
                newApplicant: app.newApplicant,
                rejected: app.isRejected && app.isRejected.status,
                archived: app.status === 0,
                joined: app.status === 3,
                addedByMe: app.userId === userId,
                status: app.status,
                highlight: searchCandidate === app.candidateId._id,
            }))

            const stages = res.interviewStages

            this.setState({
                jobDetails: res.jobDetails[0],
                candidates,
                stages,
                showAddCandidateModal: false,
                isLoading: false,
            })
            document.title = `${res.jobDetails[0].jobTitle} | SpringRecruit`
        } catch (err) {
            success('Opening has been archived or deleted')
            this.props.history.push('/openings')
        }
    }

    removeHighlight = id => {
        const candidates = this.state.candidates.map(can => {
            can.highlight = can.id === id ? false : can.highlight
            return can
        })

        this.setState({ candidates })
    }

    handleCancel = () => {
        this.setState({ showAddCandidateModal: false, archiveReasonModal: false })
    }

    candidateSelected = ({ target }) => {
        let { selectedCandidates, selectedCandidatesObject, candidates } = this.state

        if (target.checked) {
            selectedCandidates.push(target.id)
            candidates.forEach(each => {
                if (each.id === target.id) selectedCandidatesObject[target.id] = { name: each.name }
            })
        } else {
            selectedCandidates = selectedCandidates.filter(x => x !== target.id)
            delete selectedCandidatesObject[target.id]
        }

        this.setState({ selectedCandidates, selectedCandidatesObject })
    }

    selectAllCandidates = ({ target }) => {
        let { selectedCandidates, candidates } = this.state
        candidates = candidates.filter(
            can =>
                this.state.filter === 'all' ||
                (this.state.filter === 'stared' && can.stared) ||
                (this.state.filter === 'addedByMe' && can.addedByMe) ||
                (this.state.filter === 'archived' && can.archived) ||
                (this.state.filter === 'rejected' && can.rejected) ||
                (this.state.filter === 'joined' && can.joined)
        )

        if (target.checked) {
            selectedCandidates = candidates.map(x => x.id)
        } else {
            selectedCandidates = []
        }

        this.setState({ selectedCandidates })
    }

    viewCandidate = id => {
        window.open(`/candidate/${id}`, '_blank')
        let { candidates } = this.state
        candidates = candidates.map(can => {
            if (can.id === id) {
                can.newApplicant = false
            }

            return can
        })

        this.setState({ candidates })
    }

    updateFilter = name => event => {
        let filter = 'all'
        if (event.target.checked) {
            filter = name
        }

        this.setState({ filter, selectedCandidates: [] })
    }

    moveCandidateToStage = async (candidateId, stageId) => {
        try {
            const { jobId, stages } = this.state
            let candidates = this.state.candidates.slice()

            await UpdateCandidateStage({ jobId, candidateId, stageId })
            candidates = candidates.map(can => {
                if (can.id === candidateId) {
                    can.stage = stages.find(stg => stg._id === stageId).stageName
                }

                return can
            })

            success('Candidate stage updated successfully')
            this.setState({ candidates })
        } catch (err) {
            warning(err.message || err)
        }
    }

    moveCandidatesToStage = async ({ key: stageId }) => {
        try {
            const { selectedCandidates, jobId, stages } = this.state
            let candidates = this.state.candidates.slice()

            await UpdateMultipeCandidateStage(
                selectedCandidates.map(candidateId => ({
                    candidateId,
                    jobId,
                    stageId,
                }))
            )

            candidates = candidates.map(can => {
                if (selectedCandidates.includes(can.id)) {
                    can.stage = stages.find(stg => stg._id === stageId).stageName
                }

                return can
            })

            success('Candidates stage updated successfully')
            this.setState({ candidates, selectedCandidates: [] })
        } catch (err) {
            warning(err.message || err)
        }
    }

    archiveCandidates = (reason, sendEmailToCandidate) => {
        try {
            this.setState({ clickedOnce: true }, async () => {
                const { selectedCandidates } = this.state
                let candidates = this.state.candidates.slice()
                let selectedCandidatesData = selectedCandidates.map(candidateId => {
                    return candidates.find(candidate => candidate.id === candidateId)
                })
                selectedCandidatesData = selectedCandidatesData.filter(
                    candidate => !candidate.archived && candidate.status !== 3
                )
                if (selectedCandidatesData.length === 0) {
                    warning('All Candidates already archived')
                } else {
                    const filteredCandidatesIds = selectedCandidatesData.map(candidates => candidates.jobAppId)
                    await ArchiveApplications(filteredCandidatesIds, reason, sendEmailToCandidate)

                    candidates = candidates.map(can => {
                        if (selectedCandidates.includes(can.id)) {
                            can.archived = true
                            can.joined = false
                            can.rejected = false
                        }
                        return can
                    })
                    success(
                        filteredCandidatesIds.length === selectedCandidates.length
                            ? 'Candidates archived successfully'
                            : `${filteredCandidatesIds.length} of ${selectedCandidates.length} Canidates Archived Successfully `
                    )
                }

                this.setState({
                    candidates,
                    selectedCandidates: [],
                    archiveReasonModal: false,
                    clickedOnce: false,
                })
            })
        } catch (err) {
            this.setState({ clickedOnce: false })
            warning(err.message || err)
        }
    }

    showAddCandidate = () => {
        const { stages, jobId } = this.state

        store.dispatch({ type: actionsCandidate.CLEAR_CANDIDATES })
        store.dispatch({
            type: actionsCandidate.ADD_CANDIDATES,
            payload: { jobId, hasJobId: true, stageId: stages[0]._id },
        })

        this.setState({ showAddCandidateModal: true })
    }

    showArchiveReasonModal = () => {
        this.setState({ archiveReasonModal: true })
    }

    newCandidateAdded = () => {
        this.componentDidMount()
    }

    setSortOption = ({ key }) => {
        this.setState({ selectedSortOption: key, visible: false })
    }

    handleVisibleChange = visible => {
        this.setState({ visible })
    }

    handlers = {
        toggleView: () => {
            if (this.allowShortcuts) this.props.history.push(`/openings/board/${this.state.jobId}`)
        },
        createCandidate: () => {
            if (
                this.allowShortcuts &&
                store.getState().Auth.user.accessLevel <= access.opening.createCandidate &&
                this.state.isJobValid
            )
                this.showAddCandidate()
        },
    }

    render() {
        let candidates = this.state.candidates.slice()
        const {
            filter,
            selectedCandidates,
            selectedCandidatesObject,
            sortOptions,
            selectedSortOption,
            isJobValid,
            warningModal,
        } = this.state
        const selectedSortOptionName = sortOptions.find(x => x.key === selectedSortOption).name
        const { accessLevel } = store.getState().Auth.user

        const filters = {
            all: candidates.length,
            stared: candidates.filter(can => can.stared).length,
            addedByMe: candidates.filter(can => can.addedByMe).length,
            archived: candidates.filter(can => can.archived).length,
            rejected: candidates.filter(can => can.rejected).length,
            joined: candidates.filter(can => can.joined).length,
        }

        candidates = candidates
            .filter(
                can =>
                    filter === 'all' ||
                    (filter === 'stared' && can.stared) ||
                    (filter === 'addedByMe' && can.addedByMe) ||
                    (filter === 'archived' && can.archived) ||
                    (filter === 'rejected' && can.rejected) ||
                    (filter === 'joined' && can.joined)
            )
            .sort((a, b) => (b[selectedSortOption] > a[selectedSortOption] ? 1 : -1))
            .sort((a, b) => (b.highlight > a.highlight ? 1 : -1))

        return (
            <HotKeys keyMap={keyMap.opening} handlers={this.handlers}>
                <div className="candidates-list-container">
                    <HeaderContainer>
                        <div className="candidate-list-header">
                            <div className="title-cont">
                                <Title level={3} className="title-text">
                                    {this.state.jobDetails.jobTitle}
                                </Title>
                                <Tag
                                    className="view-job-detail cursor-pointer"
                                    onClick={() => this.props.history.push(`/openings/edit/${this.state.jobId}`)}
                                >
                                    View Job Details
                                </Tag>
                            </div>
                            <Title level={4} className="title-subtitle">
                                CANDIDATE LIST
                            </Title>
                        </div>

                        {/*for the archived jobs the candidate cannot be added*/}
                        {isJobValid ? (
                            <div>
                                <Button ghost size="large" type="primary" onClick={this.showAddCandidate}>
                                    <Icon component={AddIcon} />
                                    Add Candidate
                                </Button>
                            </div>
                        ) : null}

                        <div className="ats-view-button">
                            <Tooltip placement="top" overlayClassName="fs-10" title="Board view">
                                <Icon
                                    component={CLBoradViewIcon}
                                    onClick={() => this.props.history.push(`/openings/board/${this.state.jobId}`)}
                                />
                            </Tooltip>
                            <Tooltip placement="top" overlayClassName="fs-10" title="Candidate list view">
                                <Icon component={CLViewIcon} style={{ marginLeft: -4 }} />
                            </Tooltip>
                        </div>
                    </HeaderContainer>

                    <div className="list-display-flex">
                        <FilterContainer>
                            <Title level={4} className="filter-title">
                                FILTERS:
                            </Title>
                            <FilterWrapper>
                                <Title level={4} className="filter-cd-title">
                                    CANDIDATES
                                </Title>

                                <FilterListWrapper>
                                    <label className="filter-container">
                                        <input
                                            type="checkbox"
                                            checked={filter === 'all'}
                                            onChange={this.updateFilter('all')}
                                        />
                                        <span className="checkmark2"></span>
                                    </label>

                                    <span>All Candidates</span>
                                    <span className="filter-cd-count">{filters.all}</span>
                                </FilterListWrapper>

                                <FilterListWrapper>
                                    <label className="filter-container">
                                        <input
                                            type="checkbox"
                                            checked={filter === 'stared'}
                                            onChange={this.updateFilter('stared')}
                                        />
                                        <span className="checkmark2"></span>
                                    </label>

                                    <span>Stared candidates</span>
                                    <span className="filter-cd-count">{filters.stared}</span>
                                </FilterListWrapper>

                                <FilterListWrapper>
                                    <label className="filter-container">
                                        <input
                                            type="checkbox"
                                            checked={filter === 'addedByMe'}
                                            onChange={this.updateFilter('addedByMe')}
                                        />
                                        <span className="checkmark2"></span>
                                    </label>

                                    <span>Added by me</span>
                                    <span className="filter-cd-count">{filters.addedByMe}</span>
                                </FilterListWrapper>

                                <FilterListWrapper>
                                    <label className="filter-container">
                                        <input
                                            type="checkbox"
                                            checked={filter === 'archived'}
                                            onChange={this.updateFilter('archived')}
                                        />
                                        <span className="checkmark2"></span>
                                    </label>

                                    <span>Archived</span>
                                    <span className="filter-cd-count">{filters.archived}</span>
                                </FilterListWrapper>

                                <FilterListWrapper>
                                    <label className="filter-container">
                                        <input
                                            type="checkbox"
                                            checked={filter === 'rejected'}
                                            onChange={this.updateFilter('rejected')}
                                        />
                                        <span className="checkmark2"></span>
                                    </label>

                                    <span>Rejected</span>
                                    <span className="filter-cd-count">{filters.rejected}</span>
                                </FilterListWrapper>

                                <FilterListWrapper>
                                    <label className="filter-container">
                                        <input
                                            type="checkbox"
                                            checked={filter === 'joined'}
                                            onChange={this.updateFilter('joined')}
                                        />
                                        <span className="checkmark2"></span>
                                    </label>

                                    <span>Joined</span>
                                    <span className="filter-cd-count">{filters.joined}</span>
                                </FilterListWrapper>
                            </FilterWrapper>
                        </FilterContainer>

                        <ListContainer>
                            <ListFilterWrapper>
                                {selectedCandidates.length ? (
                                    <div>
                                        <Popover
                                            placement="bottom"
                                            trigger="click"
                                            content={
                                                <Menu style={{ borderRight: 0 }}>
                                                    {this.state.stages.map(option => (
                                                        <Menu.Item
                                                            key={option._id}
                                                            className="stage-filter"
                                                            onClick={this.moveCandidatesToStage}
                                                        >
                                                            <span
                                                                style={{
                                                                    color: '#545e7c',
                                                                }}
                                                            >
                                                                {option.stageName}
                                                            </span>
                                                        </Menu.Item>
                                                    ))}
                                                </Menu>
                                            }
                                        >
                                            <Button size="small" className="move-to-stage-btn">
                                                Move To Stage
                                                <Icon type="down" />
                                            </Button>
                                        </Popover>

                                        {[1, 2].includes(this.props.accessLevel) ? (
                                            <Button
                                                size="small"
                                                className="move-to-stage-btn"
                                                onClick={this.showArchiveReasonModal}
                                            >
                                                Archive
                                            </Button>
                                        ) : null}
                                    </div>
                                ) : null}

                                <div style={{ marginLeft: 'auto' }}>
                                    <Popover
                                        openClassName="sort-popover"
                                        placement="bottom"
                                        visible={this.state.visible}
                                        onVisibleChange={this.handleVisibleChange}
                                        content={
                                            <Menu className="sort-popover-menu" onSelect={this.setSortOption}>
                                                {this.state.sortOptions.map(option => (
                                                    <Menu.Item key={option.key} className="stage-filter1">
                                                        <span className="sort-popover-menu-option">{option.name}</span>
                                                    </Menu.Item>
                                                ))}
                                            </Menu>
                                        }
                                    >
                                        <Button size="small" className="sort-filter">
                                            Sort By: <span className="sort-filter-name">{selectedSortOptionName}</span>
                                            <Icon type="down" className="filter-icon" />
                                        </Button>
                                    </Popover>
                                </div>
                            </ListFilterWrapper>

                            <div className="cd-list-wrapper">
                                <CandidateHeaderWrapper>
                                    <ListCheckboxWrapper
                                        hidden={accessLevel === ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel}
                                    >
                                        <label className="container">
                                            <input
                                                type="checkbox"
                                                checked={
                                                    candidates.length && candidates.length === selectedCandidates.length
                                                }
                                                onChange={this.selectAllCandidates}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </ListCheckboxWrapper>
                                    <CandidateWrapper>Candidates</CandidateWrapper>
                                    {/* TODO: When Details data is fetched and populated
                                <DetailWrapper>Details</DetailWrapper> */}
                                    <StageWrapper>Current Stage</StageWrapper>
                                    <DateWrapper>Applied Date</DateWrapper>
                                </CandidateHeaderWrapper>

                                {this.state.isLoading
                                    ? [1, 2, 3, 5].map(each => (
                                          <Skeleton
                                              key={each}
                                              active
                                              title={false}
                                              paragraph={{ rows: 1, width: '100%' }}
                                          />
                                      ))
                                    : candidates.map((can, canIndex) => (
                                          <CandidateListWrapper
                                              key={canIndex}
                                              highlight={can.highlight}
                                              onClick={() => this.removeHighlight(can.id)}
                                          >
                                              <ListCheckboxWrapper
                                                  hidden={
                                                      accessLevel === ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel
                                                  }
                                              >
                                                  {can.newApplicant ? (
                                                      <Badge className="can-star" dot color="#4767A0" />
                                                  ) : null}
                                                  <label className="container">
                                                      <input
                                                          type="checkbox"
                                                          id={can.id}
                                                          onChange={this.candidateSelected}
                                                          checked={selectedCandidates.includes(can.id)}
                                                      />
                                                      <span className="checkmark"></span>
                                                  </label>
                                              </ListCheckboxWrapper>
                                              <CandidateWrapper onClick={() => this.viewCandidate(can.id)}>
                                                  {can.name}
                                              </CandidateWrapper>
                                              {/* TODO: When Details data is fetched and populated
                                        <DetailWrapper>{can.details}</DetailWrapper> */}
                                              <StageWrapper className="can-stage-detail">
                                                  {can.stage}
                                                  <Popover
                                                      arrowPointAtCenter={false}
                                                      placement="bottom"
                                                      content={
                                                          <Menu
                                                              style={{
                                                                  borderRight: 0,
                                                              }}
                                                          >
                                                              {this.state.stages.map(option => (
                                                                  <Menu.Item
                                                                      key={option._id}
                                                                      className="stage-filter-individual"
                                                                      onClick={() =>
                                                                          this.moveCandidateToStage(can.id, option._id)
                                                                      }
                                                                  >
                                                                      <span
                                                                          style={{
                                                                              color: '#545e7c',
                                                                          }}
                                                                      >
                                                                          {option.stageName}
                                                                      </span>
                                                                  </Menu.Item>
                                                              ))}
                                                          </Menu>
                                                      }
                                                  >
                                                      {accessLevel === ACCESS_LEVEL_DEFINITIONS.RECRUITER.accessLevel ||
                                                      can.stage === 'JOINED' ? null : (
                                                          <Icon className="can-stage-hover" type="down" />
                                                      )}
                                                  </Popover>
                                              </StageWrapper>
                                              <DateWrapper>
                                                  {moment(can.appliedDate).format('Do MMM, YYYY')}
                                              </DateWrapper>
                                          </CandidateListWrapper>
                                      ))}
                            </div>
                        </ListContainer>
                    </div>

                    <Modal
                        type={MODAL_TYPES.ADD_CANDIDATE}
                        visible={this.state.showAddCandidateModal}
                        onCancel={this.handleCancel}
                        onSubmit={this.newCandidateAdded}
                        jobId={this.state.jobId}
                    />

                    <Modal
                        type={MODAL_TYPES.ARCHIVE_REASON}
                        visible={this.state.archiveReasonModal}
                        onCancel={this.handleCancel}
                        onConfirm={(reason, sendEmailToCandidate) =>
                            this.archiveCandidates(reason, sendEmailToCandidate)
                        }
                        callInProgress={this.state.clickedOnce}
                    />
                </div>
            </HotKeys>
        )
    }
}

const mapStateToProps = ({ Auth }) => {
    return {
        accessLevel: Auth.user.accessLevel,
    }
}

export default connect(
    mapStateToProps,
    {}
)(CandidatesList)
