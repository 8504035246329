import styled from 'styled-components'
import {
    DARK_BLUE,
    LIGHT_GREY,
    BORDER_COLOR,
    GREY,
    BLACK,
    WHITE,
    FlexCenterCol,
} from '../../Helpers/Stylesheet/styledComponents'
import { Card } from '../../../container/Pages/Sourcing/style.js'
import Dots from '../../../assets/Icons/Openings/dots.svg'

export const Body = styled.div`
    display: ${({ isMobileDevice }) => (isMobileDevice ? 'block' : 'flex')};
    width: ${({ isMobileDevice }) => (isMobileDevice ? '' : '1200px')};
    margin: auto;
    @media (min-width: 767px) {
        margin-top: 30px;
    }
`
export const Main = styled.div`
    width: 70%;
    margin-left: 30px;
    div[class^='grid'] {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        .ant-input-number {
            width: 100%;
        }
    }
    div.errors-grid-2 {
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-gap: 20px;
        margin-left: 85px;
    }
    div.grid-3 {
        grid-template-columns: 1fr 2fr 2fr;
        .ant-select-dropdown {
            ul {
                margin: 0px;
                min-height: 256px;
                li {
                    padding: 5px;
                    font-size: 14px;
                    font-family: sanFranciscoMedium;
                    border-bottom: none;
                }
            }
        }
        .ant-input-number-input {
            text-align: right;
            padding-right: 25px;
        }
    }
    .show {
        margin-top: 10px;
        > span {
            margin-right: 15px;
        }
    }
    .show,
    p span {
        font-size: 12px;
        color: ${GREY};
    }
    .tox.tox-tinymce {
        min-height: 239px;
        border-radius: 4px;
    }
    .button {
        float: right;
        margin-bottom: 30px;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
    }
    ul {
        list-style: none;
        padding-left: 0;
        margin: -30px;
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            font-family: sanFranciscoSemi;
            color: ${BLACK};
            padding: 20px 30px;
            border-bottom: 1px solid ${BORDER_COLOR};
            .radios {
                display: flex;
                justify-content: space-between;
                [type='inverted'] {
                    color: #9099a4;
                    font-weight: normal;
                }
            }
        }
    }
    .social-buttons {
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: 767px) {
        width: auto;
        margin-left: 0;
        .button {
            margin-right: 15px;
        }
        .buttons {
            margin: 0 15px 30px;
        }
        li {
            flex-wrap: wrap;
            span {
                width: 100%;
            }
            .radios {
                margin-top: 10px;
            }
        }
        .social-buttons {
            justify-content: center;
            flex-wrap: wrap;
            > div + div {
                margin-top: 10px;
            }
        }
    }
`
export const Aside = styled.div`
    padding: 30px;
    background: ${WHITE};
    width: 30%;
    margin-bottom: 100px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    .item {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-family: sanFranciscoMedium;
        color: ${LIGHT_GREY};
        margin-bottom: 30px;
        cursor: pointer;
        &.active {
            color: ${DARK_BLUE};
        }
        &.complete {
            color: ${BLACK};
        }
    }
    .step {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${BORDER_COLOR};
        color: ${LIGHT_GREY};
        &.active {
            background: ${DARK_BLUE};
            color: ${WHITE};
        }
    }
`
export const AsideRow = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-right: 10px;
        path {
            fill: ${({ status }) => (status === 'active' ? DARK_BLUE : status === 'complete' ? BLACK : '')};
        }
    }
`
export const StepsBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${WHITE};
    height: 95px;
    padding: 0 15px;
    font-size: 12px;
    margin-bottom: 30px;
    position: relative;
    &::after {
        content: '';
        background: url(${Dots});
        width: calc(100% - 60px);
        position: absolute;
        top: 38px;
        height: 3px;
        left: 50%;
        transform: translateX(-50%);
    }
`

export const StepsBarRow = styled.div`
    ${FlexCenterCol};
    padding: 5px;
    background: ${WHITE};
    z-index: 2;
    &.active {
        color: ${DARK_BLUE};
    }
    &.complete {
        color: ${BLACK};
    }
    svg {
        path {
            fill: ${({ status }) => (status === 'active' ? DARK_BLUE : status === 'complete' ? BLACK : '')};
        }
    }
`

export const Content = styled(Card)`
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    padding-bottom: ${props => (props.empty ? '0' : '')};
    .button {
        margin-bottom: 20px;
        float: left;
    }
    .feedback {
        display: flex;
        flex-direction: column;
        a {
            color: ${DARK_BLUE};
        }
    }
    .field {
        width: 60%;
        .feedback-select {
            width: 100%;
            margin-bottom: 15px;
            .ant-select-selection-selected-value {
                font-size: 12px;
            }
        }
    }
    a + a {
        margin-left: 10px;
    }
    p {
        display: inline-block;
        font-size: 12px;
        &.flex {
            a + svg {
                margin-left: 10px;
            }
        }
        :last-child {
            margin-bottom: 0;
        }
        svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;
            vertical-align: sub;
        }
        .eye-icon {
            width: 15px;
            height: 15px;
        }
        .edit-icon {
            width: 12px;
            height: 12px;
        }
        .copy {
            width: 20px;
            height: 20px;
            margin-right: 0;
            margin-left: 5px;
            cursor: pointer;
        }
    }
    .ant-input {
        margin-bottom: 15px;
    }
    @media (min-width: 767px) {
        p.flex {
            display: flex;
            justify-content: space-between;
        }
    }
    @media (max-width: 767px) {
        .field {
            width: 100%;
            margin-bottom: 0;
        }
    }
`
export const SocialButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.bg};
    width: 180px;
    height: 40px;
    font-size: 12px;
    color: #fff;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        background: ${props => props.iconBg};
        width: 40px;
        height: 40px;
        border-radius: 4px 0 0 4px;
    }
    .text {
        padding-left: 40px;
    }
`
