import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Auth from './auth/reducer'
import Job from './jobs/reducer'
import Mixed from './mixed/reducer'
import Todo from './todo/reducer'
import GCal from './gCal/reducer'
import Candidate from './candidate/reducer'
import Sourcing from './sourcing/reducer'
import Inbox from './inbox/reducer'
import Zoom from './zoom/reducer'
import OCal from './outlook/reducer'

export default history =>
    combineReducers({
        router: connectRouter(history),
        Auth,
        Job,
        Mixed,
        Todo,
        GCal,
        Candidate,
        Sourcing,
        Inbox,
        Zoom,
        OCal,
    })
