import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { DataCard, Filter, Heading } from '../../style'
import Table from '../../Components/Table'
import { TeamMemberLoader } from '../../Components/Loaders'
import { ReactComponent as InfoIcon } from '../../../../../assets/Icons/Openings/info.svg'
import { ReactComponent as RefreshIcon } from '../../../../../assets/Icons/Analytics/refresh.svg'
import StatCard from '../../Components/Card'
import { GetAnalyticsData } from '../../../../../services/NetworkCalls/Analytics'
import { warning } from '../../../../../services/Notification'
import apiCaller from '../../../../../services/apiCaller'
import { getPercentage } from '../../../../Helpers/utils'
import { ACCESS_LEVEL_ARR } from '../../../../Helpers/constants'
import { TEAM_MEMBER_COLUMNS } from '../../Components/constants'
import CustomFilters from '../../Components/Filters'
import { Tooltip } from 'antd'
import config from '../../../../../config'
import { StyledButton } from '../../../../../container/Components/'

const { isMobileDevice } = config

const { CheckboxFilter, DateFilter } = CustomFilters
const { formatError } = apiCaller

const Member = ({ companyId, accessLevelArray, teamMembers, teamMembersObject, GetAnalyticsData, history }) => {
    const [cardData, setCardData] = useState({})
    const [tableData, setTableData] = useState([])
    const [cardLoading, setCardLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [selectedAccessLevels, setSelectedAccessLevels] = useState([])
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([])
    const [teamMembersArray, setTeamMembersArray] = useState(teamMembers)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [loadTime, setLoadTime] = useState(moment().format('DD MMM, YYYY LT'))

    const downloadColumns = [
        { index: 'teamMember', value: 'TEAM MEMBER' },
        { index: 'totalCandidates', value: 'TOTAL CANDIDATES' },
        { index: 'candidatesAdded', value: 'CANDIDATES ADDED' },
        { index: 'interviewsScheduled', value: 'INTERVIEWS SCHEDULED' },
        { index: 'proceeded', value: 'PROCEEDED' },
        { index: 'archived', value: 'ARCHIVED' },
        { index: 'rejected', value: 'REJECTED' },
        { index: 'joined', value: 'JOINED' },
    ]

    useEffect(() => {
        teamMembersInitialLoad()
    }, [])

    const teamMembersInitialLoad = () => {
        const urlSearchResult = new URLSearchParams(history.location.search)
        const accessLevels = urlSearchResult.get('accessLevels') ? urlSearchResult.get('accessLevels').split(',') : []
        const teamMember = urlSearchResult.get('teamMembers') ? urlSearchResult.get('teamMembers').split(',') : []
        const startDate = urlSearchResult.get('startDate') ? urlSearchResult.get('startDate') : null
        const endDate = urlSearchResult.get('endDate') ? urlSearchResult.get('endDate') : null

        let filteredTeamMembers = []
        const accessLevel = accessLevels.map(al => ACCESS_LEVEL_ARR.indexOf(al) + 1)
        let memberId = []
        if (accessLevel.length) {
            for (let member in teamMembersObject) {
                if (accessLevel.includes(teamMembersObject[member].accessLevel)) {
                    filteredTeamMembers.push(member)
                    memberId.push(teamMembersObject[member]._id)
                }
            }
        } else filteredTeamMembers = Object.keys(teamMembersObject)

        if (teamMember.length) memberId = teamMember.map(member => teamMembersObject[member]._id)
        const payload = {
            memberId: (memberId.length && memberId) || null,
            startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
            endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
        }
        getTeamMemberCandidateData(payload)
        getTeamMemberTableData(payload)
        setSelectedAccessLevels(accessLevels)
        setSelectedTeamMembers(teamMember)
        setTeamMembersArray(filteredTeamMembers)
        setStartDate(startDate && moment(startDate))
        setEndDate(endDate && moment(endDate))
    }

    const getTeamMemberCandidateData = async (payload = {}) => {
        try {
            setCardLoading(true)
            const res = await GetAnalyticsData('GetTeamMemberCandidatesData', payload, companyId)
            setCardLoading(false)
            setCardData(res)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const getTeamMemberTableData = async (payload = {}) => {
        try {
            setTableLoading(true)
            const res = await GetAnalyticsData('GetTeamMemberTableData', payload, companyId)
            const tableData = []
            for (let each in res) {
                tableData.push({
                    key: each,
                    teamMember: each,
                    totalCandidates: res[each].totalCandidate || 0,
                    candidatesAdded: res[each].total || 0,
                    interviewsScheduled: res[each].interviewsScheduled || 0,
                    proceeded: res[each].proceeded || 0,
                    archived: res[each].archived || 0,
                    rejected: res[each].rejected || 0,
                    joined: res[each].joined || 0,
                })
            }
            setTableData(tableData)
            setTableLoading(false)
        } catch (err) {
            warning(formatError(err))
        }
    }

    const applyFilteredData = (type, data) => {
        const params = new URLSearchParams(history.location.search)
        let payload = {}
        if (type === 'accessLevel') {
            let filteredTeamMembers = [],
                memberId = []
            if (data.length) {
                const accessLevel = data.map(al => ACCESS_LEVEL_ARR.indexOf(al) + 1)
                for (let member in teamMembersObject) {
                    if (accessLevel.includes(teamMembersObject[member].accessLevel)) {
                        filteredTeamMembers.push(member)
                        memberId.push(teamMembersObject[member]._id)
                    }
                }
            } else filteredTeamMembers = Object.keys(teamMembersObject)
            payload = {
                memberId: (memberId.length && memberId) || null,
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
            }
            setSelectedAccessLevels(data)
            setSelectedTeamMembers([])
            setTeamMembersArray(filteredTeamMembers)
            params.delete('accessLevels')
            params.delete('teamMembers')
            if (data.length) params.append('accessLevels', data.toString())
            history.push({ search: params.toString() })
        } else if (type === 'teamMember') {
            const accessLevel = selectedAccessLevels.map(al => ACCESS_LEVEL_ARR.indexOf(al) + 1)
            let memberId = []
            if (data.length) memberId = data.map(each => teamMembersObject[each]._id)
            else if (selectedAccessLevels.length) {
                const accessLevel = selectedAccessLevels.map(al => ACCESS_LEVEL_ARR.indexOf(al) + 1)
                for (let member in teamMembersObject) {
                    if (accessLevel.includes(teamMembersObject[member].accessLevel))
                        memberId.push(teamMembersObject[member]._id)
                }
            }
            payload = {
                memberId: (memberId.length && memberId) || null,
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
            }
            setSelectedTeamMembers(data)
            params.delete('teamMembers')
            if (data.length) params.append('teamMembers', data.toString())
            history.push({ search: params.toString() })
        } else {
            const [startDate, endDate] = data
            let memberId = []
            if (selectedTeamMembers.length) memberId = selectedTeamMembers.map(each => teamMembersObject[each]._id)
            else if (selectedAccessLevels.length) {
                const accessLevel = selectedAccessLevels.map(al => ACCESS_LEVEL_ARR.indexOf(al) + 1)
                for (let member in teamMembersObject) {
                    if (accessLevel.includes(teamMembersObject[member].accessLevel))
                        memberId.push(teamMembersObject[member]._id)
                }
            }
            payload = {
                memberId: (memberId.length && memberId) || null,
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
            }
            params.delete('startDate')
            params.delete('endDate')
            if (startDate) params.append('startDate', startDate.format().toString())
            if (endDate) params.append('endDate', endDate.format())
            history.push({ search: params.toString() })
        }
        getTeamMemberCandidateData(payload)
        getTeamMemberTableData(payload)
    }

    const resetFilter = type => {
        const params = new URLSearchParams(history.location.search)
        let payload = {}
        if (type === 'accessLevel') {
            payload = {
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
            }
            setSelectedAccessLevels([])
            setSelectedTeamMembers([])
            setTeamMembersArray(Object.keys(teamMembersObject))
            params.delete('accessLevels')
            params.delete('teamMembers')
            history.push({ search: params.toString() })
        } else {
            const accessLevel = selectedAccessLevels.map(al => ACCESS_LEVEL_ARR.indexOf(al) + 1)
            let memberId = []
            for (let member in teamMembersObject) {
                if (accessLevel.includes(teamMembersObject[member].accessLevel))
                    memberId.push(teamMembersObject[member]._id)
            }
            payload = {
                memberId: (memberId.length && memberId) || null,
                startDate: startDate && startDate.format('YYYY-MM-DD'),
                endDate: endDate && endDate.format('YYYY-MM-DD'),
            }
            setSelectedTeamMembers([])
            params.delete('teamMembers')
            history.push({ search: params.toString() })
        }
        getTeamMemberCandidateData(payload)
        getTeamMemberTableData(payload)
    }

    const handleRefresh = () => {
        let memberId = []
        if (selectedTeamMembers.length) memberId = selectedTeamMembers.map(each => teamMembersObject[each]._id)
        else if (selectedAccessLevels.length) {
            const accessLevel = selectedAccessLevels.map(al => ACCESS_LEVEL_ARR.indexOf(al) + 1)
            for (let member in teamMembersObject) {
                if (accessLevel.includes(teamMembersObject[member].accessLevel))
                    memberId.push(teamMembersObject[member]._id)
            }
        }
        const payload = {
            memberId: (memberId.length && memberId) || null,
            startDate: startDate && startDate.format('YYYY-MM-DD'),
            endDate: endDate && endDate.format('YYYY-MM-DD'),
        }
        getTeamMemberCandidateData(payload)
        getTeamMemberTableData(payload)
        setLoadTime(moment().format('DD MMM, YYYY LT'))
    }

    return (
        <>
            <div className="sub-header">
                <div>
                    <Heading>Candidate By Member</Heading>
                    <Tooltip title="Track candidates uploaded by different members and analyze their performance. Filter by access levels. particular team members or by a time period.">
                        <InfoIcon />
                    </Tooltip>
                </div>
                <div>
                    <RefreshIcon onClick={() => handleRefresh()} />
                    <span>Last Updated: {loadTime}</span>
                </div>
            </div>
            {isMobileDevice && <Heading>Filter</Heading>}
            <Filter>
                <CheckboxFilter
                    title="Filter by access level"
                    data={accessLevelArray}
                    search={false}
                    type="accessLevel"
                    applyFilteredData={applyFilteredData}
                    resetFilter={resetFilter}
                    selectedValues={selectedAccessLevels}
                />
                <CheckboxFilter
                    title="Filter by team member"
                    data={teamMembersArray}
                    type="teamMember"
                    applyFilteredData={applyFilteredData}
                    resetFilter={resetFilter}
                    selectedValues={selectedTeamMembers}
                />
                <DateFilter applyFilteredData={applyFilteredData} start={startDate} end={endDate} />
            </Filter>
            {cardLoading ? (
                <TeamMemberLoader />
            ) : (
                <div className="cards">
                    <StatCard total={cardData.total} title="Total Candidates" />
                    <StatCard
                        total={cardData.joined}
                        title="Joined"
                        desc={`${getPercentage(cardData.joined, cardData.total)}% of Total Candidates`}
                    />
                    <StatCard total={cardData.interviewsScheduled} title="Interviews Scheduled" />
                    <StatCard
                        total={cardData.feedbackAdded}
                        title="Feedback Added"
                        desc={`${getPercentage(
                            cardData.feedbackAdded,
                            cardData.interviewsScheduled
                        )}% of Interviews Scheduled`}
                    />
                </div>
            )}
            <Table
                columns={TEAM_MEMBER_COLUMNS}
                downloadColumns={downloadColumns}
                filename={`CANDIDATE BY MEMBER_${moment().format('DD-MM-YYYY_h-mm a')}`}
                tableData={tableData}
                loading={tableLoading}
                xScroll={1150}
            />
        </>
    )
}

const mapStateToProps = ({ Auth, Mixed }) => {
    const { teamMembers } = Mixed
    const accessLevelArray = [],
        teamMembersArray = [],
        teamMembersObject = {}
    teamMembers.forEach(each => {
        if (!accessLevelArray.includes(ACCESS_LEVEL_ARR[each.accessLevel - 1]))
            accessLevelArray.push(ACCESS_LEVEL_ARR[each.accessLevel - 1])
        if (each.fullName && each.status === 1) {
            teamMembersArray.push(each.fullName)
            teamMembersObject[each.fullName] = {
                _id: each._id,
                accessLevel: each.accessLevel,
            }
        }
    })
    return {
        companyId: Auth.user.companyId,
        accessLevelArray,
        teamMembers: teamMembersArray,
        teamMembersObject,
    }
}

export default connect(
    mapStateToProps,
    {
        GetAnalyticsData,
    }
)(Member)
