import React from 'react'
import './style.css'
import { Form } from 'antd'
import * as querystring from 'querystring'
import { warning } from '../../../services/Notification'
import { SIGNUPS } from './signups'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { countryCodes } from '../../Helpers/countryCode'
import { OTPS } from './otp'
import { areDomainSame, websiteDomainExtractor, websiteDomainResolve } from './utils'
import { SendOtpSignup, CompanySignup, UserSignup } from '../../../services/NetworkCalls/Authentication'
import { Modal } from '../../Modals'
import { MODAL_TYPES } from '../../Helpers/ModalTypes'
import AuthWrapper from '../../Components/SignupWrapper/index.js'
import { connect } from 'react-redux'
const LAYOUTS = {
    SIGNUP: 'SIGNUP',
    OTP: 'OTP',
}

class SignUp extends React.Component {
    constructor(props) {
        super(props)
        let { slug, userId } = props.match.params
        if (slug && userId) {
            slug = querystring.parse(slug)
        }

        this.state = {
            slug,
            userId,
            passwordType: true,
            layout: LAYOUTS.SIGNUP,
            formData: {},
            loading: false,
            accExists: false,
            mail: null,
        }

        document.title = 'Sign Up | SpringRecruit'
    }

    resendOTP = async () => {
        await this.props.SendOtpSignup(this.state.formData)
    }

    OTPRegisterHandler = async values => {
        const { userId, layout } = this.state
        if (!!!userId && layout === LAYOUTS.SIGNUP) {
            values.websiteUrl = websiteDomainExtractor(values.websiteUrl)
            values.domain = websiteDomainResolve(values.websiteUrl)

            let ans = await this.props.form.validateFields(['email', 'websiteUrl'])
            if (!areDomainSame(ans.email, ans.websiteUrl)) {
                warning('The email and Website URL must have same domain')
                return true
            }

            const result = await this.props.SendOtpSignup(values)

            if (result.payload && result.payload.duplicate) {
                this.setState({ accExists: true, mail: values.domain })
            } else if (!result.error) this.setState({ formData: values, layout: LAYOUTS.OTP })
            return true
        }
        return false
    }

    signup = () => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                const { userId } = this.state
                if (values && values.phone) {
                    const countryCode = values.countryCode
                        ? countryCodes.find(c => c.code === values.countryCode).dial_code
                        : '+91'
                    const phoneNumber = parsePhoneNumberFromString(countryCode + values.phone)
                    values.contactNo = phoneNumber.number
                }
                if (await this.OTPRegisterHandler(values)) {
                    return null
                }
                this.setState({ loading: true }, async () => {
                    if (userId)
                        this.props.UserSignup({
                            userId,
                            password: values.password,
                            fullName: values.fullName,
                            contactNo: values.contactNo,
                        })
                    else this.props.CompanySignup({ ...this.state.formData, status: 1, subdomain: 1, ...values })
                    if (this.props.signUpFailed) {
                        this.setState({ loading: false })
                    }
                })
            }
        })
    }

    backButton = () => {
        this.setState({ layout: LAYOUTS.SIGNUP })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { slug, layout, formData, loading } = this.state
        return (
            <AuthWrapper>
                {layout === LAYOUTS.SIGNUP ? (
                    <SIGNUPS
                        form={this.props.form}
                        getFieldDecorator={getFieldDecorator}
                        getFieldsValue={this.props.form.getFieldsValue}
                        slug={slug}
                        history={this.props.history}
                        signup={this.signup}
                        loading={loading}
                    />
                ) : (
                    <OTPS
                        getFieldDecorator={getFieldDecorator}
                        signup={this.signup}
                        backButton={this.backButton}
                        formData={formData}
                        resendOTP={this.resendOTP}
                        loading={loading}
                    />
                )}

                <Modal
                    type={MODAL_TYPES.WARNING_ACCOUNT_ALREADY_EXISTS}
                    companyMail={this.state.mail}
                    visible={this.state.accExists}
                    onConfirm={() => this.setState({ accExists: false })}
                />
            </AuthWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        signUpFailed: state.Auth.failure,
    }
}

export default connect(
    mapStateToProps,
    { SendOtpSignup, CompanySignup, UserSignup }
)(Form.create({ name: 'SignupForm' })(SignUp))
