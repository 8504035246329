import React from 'react'

const SVGComponent = () => (
    <svg width="60" height="64" viewBox="0 0 94 92" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g id="Dashboard-New" transform="translate(-592 -310)" fill="#E2E5E4">
                <g id="Onboarding-Option2" transform="translate(460 270)">
                    <g id="Add-Candidate-Icon" transform="translate(132 40)">
                        <path
                            d="M56.076875,50.9545833 C55.2845336,51.6787276 54.8342938,52.6918992 54.8333333,53.7529167 L54.8333333,61.3333333 C54.8333334,63.4504248 56.5868848,65.1666665 58.75,65.1666665 C60.9131152,65.1666665 62.6666666,63.4504248 62.6666667,61.3333333 L62.6666667,55.3341667 C67.6730128,50.1526195 70.47412,43.2988466 70.5,36.1675 L70.5,19.00375 C70.0495833,8.70166667 59.2983333,0 47,0 C34.7016667,0 23.9504167,8.70166667 23.5,19.1666667 L23.5,36.1675 C23.52588,43.2988466 26.3269872,50.1526195 31.3333333,55.3341667 L31.3333333,58.6116667 L10.486875,65.7608333 C4.20546837,67.914557 8.12676181e-05,73.7175685 0,80.2316667 L0,88.1666667 C4.34837351e-16,90.2837582 1.7535514,92 3.91666667,92 L58.75,92 C60.9131153,92 62.6666667,90.2837582 62.6666667,88.1666667 C62.6666667,86.0495751 60.9131153,84.3333333 58.75,84.3333333 L7.83333333,84.3333333 L7.83333333,80.2316667 C7.83410217,76.9733693 9.93903137,74.0714881 13.0816667,72.99625 L36.5816667,64.9558333 C38.1392305,64.4047144 39.1742369,62.9543041 39.1666667,61.3333333 L39.1666667,53.7529167 C39.1682429,52.6933436 38.7216446,51.6803905 37.9329167,50.9545833 C33.7494345,47.1232127 31.3624575,41.7749316 31.3333333,36.1675 L31.3333333,19.3295833 C31.5977083,13.1195833 38.921875,7.66666667 47,7.66666667 C55.078125,7.66666667 62.4022917,13.1195833 62.6666667,19.1666667 L62.6666667,36.1675 C62.6389245,41.773254 60.2557767,47.1208898 56.076875,50.9545833 L56.076875,50.9545833 Z"
                            id="Path"
                        />
                        <path
                            d="M90.0833333,72.8333333 L82.25,72.8333333 L82.25,65.1666667 C82.25,63.0495751 80.4964486,61.3333333 78.3333333,61.3333333 C76.1702181,61.3333333 74.4166667,63.0495751 74.4166667,65.1666667 L74.4166667,72.8333333 L66.5833333,72.8333333 C64.4202181,72.8333333 62.6666667,74.5495751 62.6666667,76.6666667 C62.6666667,78.7837582 64.4202181,80.5 66.5833333,80.5 L74.4166667,80.5 L74.4166667,88.1666667 C74.4166667,90.2837582 76.1702181,92 78.3333333,92 C80.4964486,92 82.25,90.2837582 82.25,88.1666667 L82.25,80.5 L90.0833333,80.5 C92.2464486,80.5 94,78.7837582 94,76.6666667 C94,74.5495751 92.2464486,72.8333333 90.0833333,72.8333333 Z"
                            id="Path"
                            fill="#4767A0"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
