import React, { useState, useEffect } from 'react'
import { Header } from '../common'
import { SOURCE_TEXT } from '../UpdateCandidate/constants'
import { ReactComponent as CongratulationsIcon } from '../../../assets/Icons/WebForm/congratulations.svg'
import { ContentParagraph } from './style'
import StyledButton from '../../Components/StyledButton'

const SuccessWebForm = ({ name }) => {
    return (
        <>
            <Header title="Congratulations" centeredTitle />
            <ContentParagraph>
                <CongratulationsIcon />
                <div className="para">
                    Hi {name}, your form has been successfully submitted. Congratulations on being part of the growing
                    talent network with SpringRecruit sourcing initiative.
                </div>

                <StyledButton width="80px" height="40px">
                    DONE
                </StyledButton>
            </ContentParagraph>
        </>
    )
}

export default SuccessWebForm
