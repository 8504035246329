import styled from 'styled-components'

const Switch = styled.label`
    position: relative;
    display: inline-block;
    width: 24px;
    height: 14px;
    #switchInput {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        ${({ disable }) => disable && 'opacity:0.5; pointer-events: none;'};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #999999;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &::before {
            position: absolute;
            content: '';
            height: 10px;
            width: 10px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
    }
    input:checked + .slider {
        background-color: #3acc6c;
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #3acc6c;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
    }
    .slider.round {
        border-radius: 34px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
`
export default Switch
