import React from 'react'
import { Header, Footer } from '../common'
import moment from 'moment'
import { Button, Checkbox, Icon, Input, Menu, Popover, Select, Typography } from 'antd'
import EditIcon from '../../../assets/Icons/AccountSettingPage/Role_Edit'
import CancelIcon from '../../../assets/Icons/Todo/Remove Button'
import { ReactComponent as InfoIcon } from '../../../assets/Icons/Board/Info.svg'
import { warning } from '../../../services/Notification'
import './style.scss'
import { Tags } from '../style'
import { ReactComponent as CloseIcon } from '../../../assets/Icons/Access/close.svg'
import { ReactComponent as MoreIcon } from '../../../assets/Icons/Extras/More.svg'
import { ReactComponent as ClockIcon } from '../../../assets/Icons/Extras/clock.svg'
import { connect } from 'react-redux'
import { FooterButtons } from '../style'
import Paragraph from '../../Components/StyledParagraph'
import config from '../../../config'
import StorageManager from '../../Managers/Storage'
const { isMobileDevice } = config

const defaultNote = {
    note: undefined,
    private: false,
    canView: [],
}

class AddNotes extends React.Component {
    constructor(props) {
        super(props)

        this.cachedInput = new StorageManager('NOTE', props.candidateId)

        const newNote = { ...defaultNote, ...props.note }
        const cachedData = this.cachedInput.getData() || undefined

        if (cachedData && !newNote.note) {
            newNote.note = cachedData
        }

        this.state = {
            newNote,
            showUserList: false,
            userSearchText: undefined,
            notes: props.notes || [],
            showNoteBox: props.notes ? !props.notes.length : true,
            isEdited: false,
            returnOnSubmit: props.returnOnSubmit,
            disable: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.notes && this.props.notes !== prevState.notes) {
            let { notes } = this.state
            notes = this.props.notes || []
            this.setState({ notes })
        }
    }

    handleChange = name => ({ target: { value, checked } }) => {
        const { newNote, showNoteBox } = this.state
        let { showUserList } = this.state
        if (name === 'note') {
            newNote.note = value
            if (!newNote._id) {
                this.cachedInput.setData(value)
            }
        } else {
            newNote.private = checked
            showUserList = false
        }

        this.setState({ newNote, showUserList })
    }

    showUserList = () => {
        this.setState(state => ({ showUserList: !state.showUserList }))
    }

    addUser = user => {
        const { newNote } = this.state
        newNote.canView.push(user)
        this.setState({ newNote, userSearchText: undefined })
    }

    removeUser = userId => {
        const { newNote } = this.state
        newNote.canView = newNote.canView.filter(mem => mem !== userId)
        this.setState({ newNote })
    }

    isNoteInvalid = note => {
        return note ? !note.trim().length : !note
    }

    submitNote = async () => {
        try {
            this.setState({ disable: true })
            const { newNote } = this.state
            if (this.isNoteInvalid(newNote.note)) {
                warning('Note text cannot be empty.')
                return null
            }
            let notes = this.state.notes.slice()
            newNote.note = newNote.note.trim()
            newNote.candidateId = this.props.candidateId
            newNote.stageId = this.props.stageId
            newNote.jobApplicationId = this.props.jobApplicationId
            const res = await this.props.onConfirm(newNote)
            if (newNote._id) {
                notes = notes.filter(note => note._id !== newNote._id)
            }
            this.cachedInput.clearSelf()

            await this.setState({
                showNoteBox: false,
                newNote: Object.assign({}, defaultNote),
                isEdited: true,
            })
        } catch (err) {
            warning(err.message || err)
        } finally {
            this.setState({ disable: false })
        }
    }

    handleCancel = () => {
        this.cachedInput.clearSelf()
        this.setState(
            { showNoteBox: false, newNote: { ...defaultNote } },
            this.props.onCancel({ notes: this.state.notes, isEdited: this.state.isEdited })
        )
    }

    showNoteBox = async note => {
        const newNote = Object.assign({}, note)
        if (!note.note) {
            newNote.canView = []
            newNote.note = this.cachedInput.getData()
        }
        await this.setState({ showNoteBox: true, newNote })
        this.scrollTo('noteBox', 0)
    }

    scrollTo = (id, offset) => {
        const [internalNotes] = document.getElementsByClassName('ant-modal-body')
        const offsetTop = document.getElementById(id).offsetTop
        internalNotes.scrollTo(0, offsetTop - offset)
    }

    hideShowBox = async () => {
        const newNoteId = this.state.newNote._id
        await this.setState({ showNoteBox: false, newNote: Object.assign({}, defaultNote) })
        if (newNoteId) {
            this.scrollTo(newNoteId, 100)
        } else {
            this.cachedInput.clearSelf()
        }
    }

    deleteNoteToggle = index => {
        const notes = this.state.notes.slice()
        const [currentNote] = notes.splice(index, 1)
        currentNote.showDeleteBox = !currentNote.showDeleteBox
        notes.splice(index, 0, currentNote)
        this.setState({ notes })
    }

    render() {
        const { showUserList, userSearchText, showNoteBox, disable } = this.state
        const { userId } = this.props
        let notes = this.state.notes.slice()
        const newNote = Object.assign({}, this.state.newNote)
        const { teamMembers } = this.props
        let members = this.props.teamMembers

        if (showNoteBox) {
            members = members.filter(
                mem => mem.status < 2 && mem.accessLevel > 1 && mem._id !== userId && !newNote.canView.includes(mem._id)
            )

            if (userSearchText) {
                members = members.filter(mem => {
                    const hasEmail = mem.email && mem.email.toLowerCase().indexOf(userSearchText) !== -1
                    const hasName = mem.fullName && mem.fullName.toLowerCase().indexOf(userSearchText) !== -1
                    return hasEmail || hasName
                })
            }

            newNote.canView = newNote.canView.map(user => {
                user = teamMembers.find(mem => mem._id === user)
                user.displayName = user.fullName || user.email
                return user
            })
        }
        return (
            <div>
                <Header title={`ADD${notes.length ? ' / VIEW' : ''} INTERNAL NOTE`} />
                <div className="wrapper">
                    {notes &&
                        notes.map((note, index) => {
                            if (note._id === newNote._id) return null
                            const teamMember = teamMembers.find(mem => mem._id === note.addedBy)
                            note.userName = teamMember && teamMember.fullName
                            if (note.addedBy === userId) {
                                note.userName += '(You)'
                                note.isEditable = true
                            }

                            let visibility = [note.addedBy].concat(note.canView)
                            visibility = visibility.filter(x => x !== userId)

                            let visibilityMessage = 'Visible to you,'
                            visibility.forEach(x => {
                                let noteUserName = teamMembers.find(mem => mem._id === x)
                                noteUserName = noteUserName && (noteUserName.fullName || noteUserName.email)
                                visibilityMessage += `, ${noteUserName}`
                            })

                            visibilityMessage += ', admins and super admins'

                            let mobileVisibilityMessage = ''
                            visibility.forEach((x, index) => {
                                let noteUserName = teamMembers.find(mem => mem._id === x)
                                noteUserName = noteUserName && (noteUserName.fullName || noteUserName.email)
                                if (index === 0) mobileVisibilityMessage += `${noteUserName}`
                                else mobileVisibilityMessage += `, ${noteUserName}`
                            })
                            mobileVisibilityMessage += ', admins and super admins'

                            if (!note.showDeleteBox) {
                                return (
                                    <React.Fragment key={note._id}>
                                        <div className="view" key={note._id} id={note._id}>
                                            <div className="view-title">
                                                <div>
                                                    {note.userName || 'User'}&nbsp; added a
                                                    {note.private ? ' private' : ' public'} note&nbsp;
                                                    <span className="view-wrapper-title-time">
                                                        {!isMobileDevice && moment(note.created).fromNow()}
                                                    </span>
                                                </div>

                                                <div>
                                                    {isMobileDevice ? (
                                                        <>
                                                            {note.private && (
                                                                <Icon
                                                                    className="view-title-icon"
                                                                    type="lock"
                                                                    theme="filled"
                                                                />
                                                            )}

                                                            {note.isEditable && !note.showDeleteBox && (
                                                                <Popover
                                                                    arrowPointAtCenter
                                                                    placement={'bottomLeft'}
                                                                    content={
                                                                        <Menu>
                                                                            <Menu.Item
                                                                                onClick={() => this.showNoteBox(note)}
                                                                            >
                                                                                EDIT
                                                                            </Menu.Item>
                                                                            <Menu.Item
                                                                                onClick={() =>
                                                                                    this.deleteNoteToggle(index)
                                                                                }
                                                                            >
                                                                                DELETE
                                                                            </Menu.Item>
                                                                        </Menu>
                                                                    }
                                                                >
                                                                    <MoreIcon />
                                                                </Popover>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="view-title-icons">
                                                            {note.isEditable && !note.showDeleteBox && (
                                                                <Icon
                                                                    className="view-title-icon"
                                                                    component={EditIcon}
                                                                    onClick={() => this.showNoteBox(note)}
                                                                />
                                                            )}
                                                            {note.isEditable && !note.showDeleteBox && (
                                                                <Icon
                                                                    className="view-title-icon"
                                                                    type="delete"
                                                                    theme="filled"
                                                                    onClick={() => this.deleteNoteToggle(index)}
                                                                />
                                                            )}
                                                            {note.private && (
                                                                <Popover
                                                                    overlayClassName="fs-10"
                                                                    title={visibilityMessage}
                                                                    placement="topRight"
                                                                    arrowPointAtCenter
                                                                >
                                                                    <Icon
                                                                        className="view-title-icon"
                                                                        type="lock"
                                                                        theme="filled"
                                                                    />
                                                                </Popover>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {isMobileDevice && (
                                                <div className="mobile-time">
                                                    <ClockIcon />
                                                    <span className="mobile-time-text">
                                                        {moment(note.created).fromNow()}
                                                    </span>
                                                </div>
                                            )}
                                            <Paragraph>{note.note}</Paragraph>
                                            {isMobileDevice && note.private && (
                                                <div className="mobile-visible">
                                                    <div className="mobile-visible-title">Also visible to</div>
                                                    <div className="mobile-visible-list">{mobileVisibilityMessage}</div>
                                                </div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                )
                            } else {
                                return (
                                    <React.Fragment key={note._id}>
                                        <div className="view-delete" key={note._id} id={note._id}>
                                            <div>
                                                Are you sure you want to delete this note? This action cannot be undone
                                            </div>
                                            <div className="view-delete-btn">
                                                <div>
                                                    <FooterButtons
                                                        type="inverted"
                                                        onClick={() => this.deleteNoteToggle(index)}
                                                    >
                                                        NO
                                                    </FooterButtons>
                                                </div>
                                                <div>
                                                    <FooterButtons
                                                        type="danger"
                                                        onClick={() => this.props.handleDelete(note._id, index)}
                                                    >
                                                        YES
                                                    </FooterButtons>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        })}

                    <div className={!!notes.length ? 'main-hasNote' : 'main'} hidden={!showNoteBox} id="noteBox">
                        <div className="main-title">
                            {newNote._id ? 'Update' : 'New'} Note:
                            {notes.length ? <Icon component={CancelIcon} onClick={this.hideShowBox} /> : null}
                        </div>
                        <Input.TextArea
                            className="main-box"
                            placeholder="Enter your note here"
                            rows={7}
                            value={newNote.note}
                            onChange={this.handleChange('note')}
                        />
                        <div className="main-group">
                            <Checkbox
                                className="main-group-cb"
                                checked={newNote.private}
                                onChange={this.handleChange('private')}
                            >
                                <span className="main-group-mark"> Mark as private </span>
                                <Icon className="main-group-icon" type="lock" theme="twoTone" twoToneColor="#53627C" />
                            </Checkbox>
                            <div className="main-group-candidate">For candidate: {this.props.candidateName}</div>
                        </div>
                        <div className="main-private">
                            {newNote.private ? (
                                <div>
                                    <div className="main-private-flex">
                                        <div className="main-private-flex-info">
                                            <InfoIcon />{' '}
                                            <span className="main-private-flex-info-text">
                                                {' '}
                                                This internal note is visible to you, admins and super admins{' '}
                                            </span>
                                        </div>
                                        {newNote.canView.length > 0 && (
                                            <div className="main-private-flex-visible">
                                                <div className="main-private-flex-visible-text">Also visible to</div>
                                                <div className="main-private-flex-visible-tags">
                                                    <Tags>
                                                        {newNote.canView.map(mem => (
                                                            <div
                                                                key={mem._id}
                                                                className="main-private-flex-visible-tagwrapper"
                                                            >
                                                                <span>{mem.displayName}</span>
                                                                <i>
                                                                    <CloseIcon
                                                                        onClick={() => this.removeUser(mem._id)}
                                                                    />
                                                                </i>
                                                            </div>
                                                        ))}
                                                    </Tags>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {!showUserList ? (
                                        <div className="main-private-people" onClick={this.showUserList}>
                                            Add more people who can view this note?
                                        </div>
                                    ) : (
                                        <div className="main-private-select">
                                            <Select
                                                className="main-private-search"
                                                placeholder="Search team member to add"
                                                notFoundContent={null}
                                                showSearch
                                                suffixIcon={<Icon type="search" />}
                                                dropdownClassName="an-nn-search-dropdown"
                                                defaultActiveFirstOption={false}
                                                onSelect={this.addUser}
                                                value={userSearchText}
                                                onSearch={v => this.setState({ userSearchText: v })}
                                                filterOption={false}
                                            >
                                                {members.map(mem => (
                                                    <Select.Option key={mem._id} className="main-private-option">
                                                        <div className="main-private-name">
                                                            {mem.fullName || mem.email}
                                                        </div>
                                                        <div className="main-private-add">Add</div>
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="main-info">
                                    <div className="main-info-icon">
                                        <InfoIcon />{' '}
                                    </div>
                                    <div className="main-info-text">
                                        <span>This internal note is visible to everyone.</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Footer
                    hasSeparator
                    onConfirm={showNoteBox ? this.submitNote : () => this.showNoteBox(defaultNote)}
                    onCancel={this.handleCancel}
                    confirmText={showNoteBox ? (newNote._id ? 'UPDATE' : 'ADD') : 'ADD NOTE'}
                    cancelText={'CANCEL'}
                    loadingButton={true}
                    disable={disable}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { Auth, Mixed } = state
    return {
        teamMembers: Mixed.teamMembers,
        userId: Auth.user.id,
    }
}

export default connect(mapStateToProps)(AddNotes)
