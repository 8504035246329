export const FILTER_TYPES = {
    ALL: 'all',
    ASSIGNED: 'assigned_to_job',
    UNASSIGNED: 'unassigned_to_job',
    FAVOURITE: 'starred',
    HIDDEN: 'hidden',
}

export const EVENTS = {
    ADD_JOB: 'add_to_job',
    HIDE_CANDIDATE: 'hide_candidate',
    MARK_FAV: 'mark_favorite',
    UPDATE_TAG: 'update_tag',
}

export const COUNTDATA_REVERSE_MAP = {
    all: 'all',
    assigned_to_job: 'assignedJob',
    unassigned_to_job: 'unassignedJob',
    starred: 'favourite',
    hidden: 'hidden',
}
