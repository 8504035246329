import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from '../../../../assets/Icons/Notification/close.svg'
import { Field } from './../../../Modals/AddCandidate/style.js'
import { AutoComplete, Select } from 'antd'
import Button from '../../../Components/StyledButton'
import { StepButton } from '../style'
import { GetSkills, GetJobs } from '../../../../services/NetworkCalls/Sourcing'
import { JobSkillLoader } from '../Loaders'

const SEPARATORS = [',', '.']
const inputsHasSeparator = (input, separators) => {
    for (let str in input) if (separators.includes(input[str])) return true
    return false
}

// Page 1 of the form
const JobSkill = ({ flipPage, authProof, jobSkill, isLoading, showComponent }) => {
    const [jobs, setJobs] = useState(jobSkill.jobs || [])
    const [jobValue, setJobValue] = useState('')
    const [jobDropList, setJobDropList] = useState([])

    const [jobResultList, setJobResultList] = useState([])
    const [skillResultList, setSkillResultList] = useState([])

    const [skills, setSkills] = useState(jobSkill.skills || [])
    const [skillValue, setSkillValue] = useState('')
    const [skillDropList, setSkillDropList] = useState([])

    const [error, setError] = useState({})

    if (!showComponent) return null
    if (isLoading) return <JobSkillLoader />
    const onNextClicked = () => {
        const newError = { jobs: false, skills: false }
        if (jobs.length === 0) newError.jobs = 'Job role is required'
        if (skills.length === 0) newError.skills = 'Skill set is required'

        if (newError.jobs || newError.skills) setError(newError)
        else flipPage({ jobs, skills, field: 'jobSkill', direction: 'NEXT' })
    }

    const callJobsAPI = async search => {
        if (inputsHasSeparator(search, SEPARATORS)) return null
        setJobValue(search)
        if (search.length) {
            const result = await GetJobs({ authProof, search })
            const jobDataList = (result && result.results) || []
            const newList = (jobDataList.length &&
                jobDataList.map(each => (
                    <Select.Option key={each._id} label={each._id} name={each.title}>
                        {each.title}
                    </Select.Option>
                ))) || [<Select.Option key="there-is-another">{search}</Select.Option>]
            setJobDropList(newList)
            setJobResultList(jobDataList)
        }
    }

    const callSkillsAPI = async search => {
        if (inputsHasSeparator(search, SEPARATORS)) return null
        setSkillValue(search)
        if (search.length) {
            const result = await GetSkills({ authProof, search })
            const skillDataList = (result && result.results) || []
            const newList = (skillDataList.length &&
                skillDataList.map(each => (
                    <Select.Option key={each._id} label={each._id}>
                        {each.name}
                    </Select.Option>
                ))) || [<Select.Option key="there-is-another">{search}</Select.Option>]
            setSkillDropList(newList)
            setSkillResultList(skillDataList)
        }
    }

    return (
        <>
            <Field>
                <div className="title">Enter the job role you are looking for*</div>
                <div className="input-container">
                    <div className="tags">
                        {jobs.map((job, index) => (
                            <span key={index}>
                                {job.title}
                                <CloseIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setJobs(jobs.filter(each => each.title !== job.title))}
                                />
                            </span>
                        ))}
                    </div>
                    <AutoComplete
                        value={jobValue}
                        dataSource={jobDropList}
                        style={{ width: '100%' }}
                        onSelect={value => {
                            const newData = jobResultList.find(each => each._id === value) || { title: jobValue }
                            setJobs([...jobs, newData])
                            setJobValue('')
                            setJobDropList([])
                            if (error.jobs) setError({ ...error, jobs: false })
                        }}
                        onSearch={text => callJobsAPI(text)}
                        placeholder="Please enter your interested job roles"
                    />
                </div>
                {error.jobs && <div className="error">{error.jobs}</div>}
            </Field>
            <Field className="mt30">
                <div className="title">Enter your skill set*</div>
                <div className="input-container">
                    <div className="tags">
                        {skills.map((skill, index) => (
                            <span key={index}>
                                {skill.name}
                                <CloseIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setSkills(skills.filter(each => each.name !== skill.name))}
                                />
                            </span>
                        ))}
                    </div>
                    <AutoComplete
                        value={skillValue}
                        dataSource={skillDropList}
                        style={{ width: '100%' }}
                        onSelect={value => {
                            const newData = skillResultList.find(each => each._id === value) || { name: skillValue }
                            setSkills([...skills, newData])
                            setSkillValue('')
                            setSkillDropList([])
                            if (error.skills) setError({ ...error, skills: false })
                        }}
                        onSearch={text => callSkillsAPI(text)}
                        placeholder="Please enter your skills"
                    />
                </div>
                {error.skills && <div className="error">{error.skills}</div>}
            </Field>
            <StepButton>
                <Button width="80px" height="40px" onClick={onNextClicked}>
                    Next
                </Button>
            </StepButton>
        </>
    )
}

export default JobSkill
