import React, { useState, useEffect } from 'react'
import { DeleteBox } from './style'
import { FooterButtons, StickyFooter } from '../style'

const ConfirmDeleteCard = ({ onDelete, onCancel, confirmMessage, initialHeight = null, noBorder = false }) => {
    return (
        <DeleteBox initialHeight={initialHeight} noBorder={noBorder}>
            <div className="text-box">{confirmMessage}</div>
            <div className="button-box">
                <FooterButtons type="inverted" onClick={onCancel}>
                    NO
                </FooterButtons>
                <FooterButtons type="danger" onClick={onDelete}>
                    YES
                </FooterButtons>
            </div>
        </DeleteBox>
    )
}

export default ConfirmDeleteCard
