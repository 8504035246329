export const keyMap = {
    global: {
        search: { combo: 'shift+g', eventType: 'keydown' },
        switchTabLeft: { combo: 'shift+left', eventType: 'keydown' },
        switchTabRight: { combo: 'shift+right', eventType: 'keydown' },
        displayShortcuts: [{ combo: 'ctrl+k', eventType: 'keydown' }, { combo: 'command+k', eventType: 'keydown' }],
    },
    dashboard: {
        createJob: { combo: 'shift+j', eventType: 'keydown' },
        createCandidate: { combo: 'shift+c', eventType: 'keydown' },
        inviteTeamMember: { combo: 'shift+t', eventType: 'keydown' },
        switchDashboardTabRight: [
            { combo: 'ctrl+shift+right', eventType: 'keydown' },
            { combo: 'command+shift+right', eventType: 'keydown' },
        ],
        switchDashboardTabLeft: [
            { combo: 'ctrl+shift+left', eventType: 'keydown' },
            { combo: 'command+shift+left', eventType: 'keydown' },
        ],
    },
    openings: {
        createJob: { combo: 'shift+j', eventType: 'keydown' },
        createCandidate: { combo: 'shift+c', eventType: 'keydown' },
    },
    opening: {
        toggleView: { combo: 'shift+t', eventType: 'keydown' },
        createCandidate: { combo: 'shift+c', eventType: 'keydown' },
        createStage: { combo: 'shift+s', eventType: 'keydown' },
    },
    candidateProfile: {
        messageCandidate: { combo: 'shift+m', eventType: 'keydown' },
        addFeedback: { combo: 'shift+r', eventType: 'keydown' },
        scheduleInterview: { combo: 'shift+i', eventType: 'keydown' },
        addNote: { combo: 'shift+n', eventType: 'keydown' },
        archive: [{ combo: 'shift+del', eventType: 'keydown' }, { combo: 'shift+backspace', eventType: 'keydown' }],
        reject: { combo: 'shift+d', eventType: 'keydown' },
        join: { combo: 'shift+j', eventType: 'keydown' },
        switchCPTabRight: [
            { combo: 'ctrl+shift+right', eventType: 'keydown' },
            { combo: 'command+shift+right', eventType: 'keydown' },
        ],
        switchCPTabLeft: [
            { combo: 'ctrl+shift+left', eventType: 'keydown' },
            { combo: 'command+shift+left', eventType: 'keydown' },
        ],
        edit: { combo: 'shift+e', eventType: 'keydown' },
        cancelEdit: { combo: 'esc', eventType: 'keydown' },
    },
    todo: {
        switchFilterUp: { combo: 'shift+up', eventType: 'keydown' },
        switchFilterDown: { combo: 'shift+down', eventType: 'keydown' },
    },
    talentPool: {
        createCandidate: { combo: 'shift+c', eventType: 'keydown' },
    },
    addCandidate: {
        manualEdit: { combo: 'shift+e', eventType: 'keydown' },
    },
}

export const access = {
    global: {
        search: 4,
        switchTabLeft: 5,
        switchTabRight: 5,
        displayShortcuts: 5,
    },
    dashboard: {
        createJob: 2,
        createCandidate: 4,
        inviteTeamMember: 2,
    },
    openings: {
        createJob: 2,
        createCandidate: 4,
    },
    opening: {
        toggleView: 4,
        createCandidate: 4,
        createStage: 2,
        searchBoard: 5,
    },
    candidateProfile: {
        messageCandidate: 5,
        addFeedback: 5,
        scheduleInterview: 4,
        addNote: 5,
        archive: 2,
        reject: 5,
        join: 5,
        switchCPTabRight: 5,
        switchCPTabLeftswitchCPTabLeft: 5,
        edit: 4,
        cancelEdit: 4,
    },
    todo: {
        switchFilterUp: 5,
        switchFilterDown: 5,
    },
    talentPool: {
        createCandidate: 5,
    },
    addCandidate: {
        manualEdit: 5,
    },
}
