import React, { useState, useEffect } from 'react'
import { Button, Form, Input } from 'antd'
import { success } from '../../../services/Notification'
import { ButtonWrapper, ContentWrapperOTP, FormWrapperOTP } from './style'

const Component_Text = {
    title: 'Enter OTP',
    subTitle: email => (
        <p>
            A one time password (OTP) has been sent to <b>{email}</b>. Please enter the OTP below to verify your email
            address
        </p>
    ),
    placeHolder: 'Enter OTP',
    resendText: 'Resend OTP?',
    confirm: 'Validate OTP & SignUp',
}

export const OTPS = props => {
    const { getFieldDecorator, signup, formData, resentOTP, loading } = props
    const [stopResend, setStopResend] = useState(true)
    var [OTPtimer, setCount] = useState(60)

    useEffect(() => {
        timer()
    }, [])

    const timer = () => {
        var interval = setInterval(() => {
            setCount(OTPtimer--)
            if (OTPtimer < 0) {
                clearInterval(interval)
                setStopResend(false)
            }
        }, 1000)
    }

    const resentClicked = async () => {
        try {
            setStopResend(true)
            setCount((OTPtimer = 60))
            if (await resentOTP()) {
                success('OTP Resent')
                timer()
            }
        } catch (err) {
            throw err
        }
    }
    return (
        <ContentWrapperOTP>
            <div className="header-text fp-title">{Component_Text.title}</div>
            <div className="fp-subtitle">{Component_Text.subTitle(formData.email)}</div>
            <FormWrapperOTP>
                <Form className="otp-form" onSubmit={signup}>
                    <Form.Item>
                        {getFieldDecorator('otp', {
                            preserve: true,
                            rules: [{ required: true, whitespace: true, message: 'OTP is required' }],
                        })(
                            <Input
                                className="background-colour otp-input"
                                placeholder={Component_Text.placeHolder}
                                onPressEnter={e => {
                                    e.preventDefault()
                                    signup()
                                }}
                            />
                        )}
                        <div>
                            {stopResend ? (
                                <p className="otp-resend-false">{`Resend OTP (${OTPtimer < 0 ? 0 : OTPtimer}s)`}</p>
                            ) : (
                                <p onClick={resentClicked} className="otp-resend">
                                    Resend OTP
                                </p>
                            )}
                        </div>
                    </Form.Item>
                </Form>
            </FormWrapperOTP>
            <ButtonWrapper>
                <Button className="fp-button" size="large" onClick={signup} loading={loading}>
                    {Component_Text.confirm}
                </Button>
            </ButtonWrapper>
        </ContentWrapperOTP>
    )
}
