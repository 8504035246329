import React from 'react'
import { MessageModal } from '../common'

const WarningDeleteSection = ({ onCancel, onConfirm }) => (
    <MessageModal
        title="DELETE"
        message="Are you sure you want to delete? This action cannot be undone"
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelText="CANCEL"
        confirmText="DELETE"
    />
)

export default WarningDeleteSection
