import styled from 'styled-components'

export const Title = styled.h4`
    font-weight: bolder;
    color: #697398;
    padding: 16px 16px 0;
    display: flex;
    flex-grow: 1;
    justify-content: space-between !important;
`

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
    height: 40px;
    width: 100%;
`

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`

export const ModalElementWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    text-align: left;
`

export const EmptyWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 180px);
    border: 1px solid #eff0f4;
    border-radius: 8px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
`

export const OpeningCardWrapper = styled.div`
    width: 90.62vw;
    height: 100px;
    border: 1px solid #eff0f4;
    border-radius: 8px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 16px 40px;
    margin: 0 auto 30px;
`

export const BulletWrapper = styled.div`
    width: 1200px;
    height: 100px;
    padding: 16px 40px;
    margin: 0 auto 30px;
`
