import React from 'react'
import { Icon, Avatar, Button, Checkbox, Select } from 'antd'
import './style.css'
import getAvatar from '../../../services/getAvatar'
import moment from 'moment'
import className from 'classnames'
import querystring from 'querystring'
import { ReactComponent as GreyInfoIcon } from '../../../assets/Icons/Board/Info.svg'
import AttachmentIcon from '../../../assets/Icons/CandidateProfile-Messages+ThreadView/Attachment Icon'
import ExpandIcon from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Btn'
import CollapseIcon from '../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Or Collapse Btn'
import RemoveIcon from '../../../assets/Icons/AccountSettingPage/Remove Button'
import DocIcon from '../../../assets/Icons/CandidateProfile-Messages+ThreadView/docx'
import XlsIcon from '../../../assets/Icons/CandidateProfile-Messages+ThreadView/xls'
import PdfIcon from '../../../assets/Icons/CandidateProfile-Messages+ThreadView/pdf'
import ImgIcon from '../../../assets/Icons/CandidateProfile-Messages+ThreadView/img'

import { EmailAddTeamMember, EmailRemoveTeamMember } from '../../../services/api'
import MessageBox from './MessageBox'
import { MessageLoader } from './Helper/Loaders'
import { CandidateProfileContainer } from './style'
import { store } from '../../../redux/store'
import { warning } from '../../../services/Notification'
import { markAsReadMessage } from '../../../services/NetworkCalls/Candidate'

export default class Message extends React.Component {
    constructor(props) {
        super(props)

        if (props.messages && props.messages[0]) {
            props.messages[0].showDetail = true
        }

        this.state = {
            ...props,
            messagedetail: null,
            userSearchText: {},
            selectedId: props.selectedId,
        }
    }

    componentDidMount() {
        markAsReadMessage(this.props.match.params.id)
    }

    async componentWillReceiveProps(nextProps) {
        let { messages } = nextProps
        if (
            this.state.messages !== messages ||
            this.state.candidateName !== nextProps.candidateName ||
            this.state.isAdd !== nextProps.isAdd ||
            this.state.selectedId !== nextProps.selectedId
        ) {
            if (nextProps.selectedId) {
                messages = messages.map(message => {
                    message.showDetail = nextProps.selectedId === message._id
                    return message
                })
            } else if (messages[0]) {
                messages[0].showDetail = true
            }
            await this.setState({ ...nextProps, messages })
            this.scrollToMessage(nextProps.selectedId)
        }
    }

    scrollToMessage = messageId => {
        if (messageId) {
            const currentMessage = document.getElementById(messageId)
            if (currentMessage) {
                currentMessage.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }

    createMessage = () => {
        this.setState({ isAdd: true })
    }

    cancelAddMessage = res => {
        this.setState({ isAdd: false })
        this.props.cancelNewMessage(res)
        this.props.handleFocus()
    }

    replyMessage = message => {
        this.setState({
            messagedetail: message,
            isAdd: true,
        })
        window.scroll(0, 230)
    }

    openAndClose = index => {
        const messages = this.state.messages.slice()
        const [message] = messages.splice(index, 1)
        message.showDetail = !message.showDetail
        messages.splice(index, 0, message)
        this.setState({ messages })
    }

    displayAttachment = (file, i1) => {
        if (typeof file === 'string') {
            const index = file.lastIndexOf('/')
            let name = file.substring(index + 1)
            name = querystring.parse(name)
            name = Object.keys(name)[0]
            const ext = name.split('.')[1]

            let icon = PdfIcon
            if (['xls', 'xlxs', 'excel', 'csv'].includes(ext)) {
                icon = XlsIcon
            } else if (['jpg', 'jpeg', 'png', 'svg'].includes(ext)) {
                icon = ImgIcon
            } else if (['doc', 'docx'].includes(ext)) {
                icon = DocIcon
            }

            return (
                <div
                    className="message-files message-cursor cpm-mr-10"
                    key={i1}
                    onClick={() => window.open(file, '_blank')}
                >
                    <Icon component={icon} />
                    <div className="sample-pdf">{name}</div>
                </div>
            )
        }
    }

    handlePrivateChange = emailId => event => {
        this.props.changeEmailPrivacy(emailId, event.target.checked)
    }

    showUserList = emailId => {
        const messages = this.state.messages.map(email => {
            if (email._id === emailId) {
                email.showUserList = true
            }

            return email
        })

        this.setState({ messages })
    }

    addUser = emailId => async user => {
        try {
            await EmailAddTeamMember(emailId, user)
            const messages = this.state.messages.map(email => {
                if (email._id === emailId) {
                    email.accessibleTo = email.accessibleTo || []
                    email.accessibleTo.push(user)
                }

                return email
            })

            this.setState({ messages })
        } catch (err) {
            warning(err.message || err)
        }
    }

    removeUser = async (user, emailId) => {
        try {
            await EmailRemoveTeamMember(emailId, user)
            const messages = this.state.messages.map(email => {
                if (email._id === emailId) {
                    email.accessibleTo = email.accessibleTo.filter(x => x !== user)
                }

                return email
            })

            this.setState({ messages })
        } catch (err) {
            warning(err.message || err)
        }
    }

    searchText = emailId => value => {
        const { userSearchText } = this.state
        userSearchText[emailId] = value
        this.setState({ userSearchText })
    }

    render() {
        const {
            user,
            user: { accessLevel },
        } = store.getState().Auth
        let { teamMembers } = store.getState().Mixed

        const getDate = date => {
            if (moment(date).isBetween(moment().startOf('day'), moment().endOf('day'), 'date', [])) {
                return moment(date).format('hh:mm A [Today]')
            } else {
                return moment(date).format('hh:mm A Do MMMM, Y')
            }
        }

        const MessageHeader = props => (
            <div className="main-message-header">
                <div className="collapse-title">
                    <div className="subject">{props.subject}</div>
                    <div className="candidate">
                        {props.userId ? props.userId.fullName || props.userId.email : props.fromEmail}
                    </div>
                </div>
                <div className="date-time">
                    <div className="time">
                        <div>{getDate(props.emailDate)}</div>
                        <div className="cpm-panel-close-icon">
                            {props.markedPrivate ? <Icon className="lockIcon" type="lock" theme="filled" /> : null}
                            {props.attachments.length ? (
                                <Icon className="attchIcon" component={AttachmentIcon} />
                            ) : null}
                        </div>
                    </div>
                    <Icon
                        component={ExpandIcon}
                        className="message-exp-icon"
                        onClick={() => this.openAndClose(props.index)}
                    />
                </div>
            </div>
        )

        const MessageBody = props => (
            <div>
                <div className="mail-header">
                    <div className="mail-header-detail">
                        <div className="cpm-avatar-block">
                            <span style={{ marginRight: 10 }}>
                                <Avatar className="avatarimage" size={45} shape="square">
                                    <span className="cpm-avatarName">
                                        {getAvatar(
                                            props.userId ? props.userId.fullName || props.userId.email : props.fromEmail
                                        )}
                                    </span>
                                </Avatar>
                            </span>
                        </div>
                        <div className="cpm-mail-detail">
                            <p className="cpm-recruiter-name">
                                {props.userId && (props.userId.fullName || props.userId.email)}
                            </p>
                            <p className="cpm-from-mail">
                                From:
                                <span className="from">{props.fromEmail}</span>
                            </p>

                            <p className="cpm-from-mail">
                                To:
                                <span className="from">{props.toEmail}</span>
                            </p>

                            <p className="cpm-from-mail" hidden={!props.ccEmails.length}>
                                Cc:
                                <span className="from">{props.ccEmails.join(', ')}</span>
                            </p>
                        </div>
                    </div>
                    <div className="mail-detail-time-parent" style={{ display: 'flex' }}>
                        <div className="mail-detail-time">{getDate(props.emailDate)}</div>
                        <Icon component={CollapseIcon} onClick={() => this.openAndClose(props.index)} />
                    </div>
                </div>
                {props.emailContent ? <div className="message-detail-seprator"></div> : null}
                <div className="cpm-mail-content-block">
                    <p className="cpm-mail-label" dangerouslySetInnerHTML={{ __html: props.emailContent }}></p>
                </div>
                <div className="message-detail-seprator1"></div>
                <div className="cpm-mail-attach-file-block">
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: -10, width: '100%' }}>
                        {props.attachments.map(this.displayAttachment)}
                        {props.sourcingEmail ? (
                            <div className="via-stp ml-auto">
                                <GreyInfoIcon />
                                <span>Via Sourcing Talent Pool</span>
                            </div>
                        ) : (
                            accessLevel < 3 && (
                                <Checkbox
                                    checked={props.markedPrivate}
                                    onChange={this.handlePrivateChange(props._id)}
                                    className={className({
                                        'cpm-view-private': true,
                                        'no-pointer-event': props.markedPrivate && props.markedPrivateBy !== user.id,
                                    })}
                                >
                                    <span className="cpm-mark-as-private">Mark as private</span>
                                    <Icon type="lock" theme="filled" />
                                </Checkbox>
                            )
                        )}
                    </div>
                </div>
                {props.markedPrivate && accessLevel < 3 && !props.sourcingEmail && (
                    <div className="cpm-private-cont">
                        <div className="cpm-private-message">
                            <div>Note: This message is only visible to you,&nbsp;</div>
                            {props.accessibleTo
                                ? props.accessibleTo.map(mem => {
                                      let displayName = teamMembers.find(x => x._id === mem)
                                      displayName = displayName.fullName || displayName.email
                                      return (
                                          <React.Fragment key={mem}>
                                              <div className="an-nn-sel-user1 top-margin-7">
                                                  <div>{displayName}</div>
                                                  {props.markedPrivateBy === user.id ? (
                                                      <Icon
                                                          component={RemoveIcon}
                                                          onClick={() => this.removeUser(mem, props._id)}
                                                      />
                                                  ) : null}
                                              </div>
                                              , &nbsp;
                                          </React.Fragment>
                                      )
                                  })
                                : null}
                            <div>{this.state.candidateName}</div>
                            {teamMembers
                                .filter(mem => props.ccEmails.includes(mem.email))
                                .map(mem => (
                                    <div>,&nbsp; {mem.fullName || mem.email}</div>
                                ))}
                            <div>&nbsp;, admins and super admins.</div>
                        </div>
                        {props.markedPrivate && props.markedPrivateBy !== user.id ? null : !props.showUserList ? (
                            <div className="add-more-people-who" onClick={() => this.showUserList(props._id)}>
                                Add more people who can view this note?
                            </div>
                        ) : (
                            <Select
                                className="an-nn-search"
                                placeholder="Search team member to add"
                                notFoundContent={null}
                                showSearch
                                suffixIcon={<Icon type="search" />}
                                defaultActiveFirstOption={false}
                                onSelect={this.addUser(props._id)}
                                value={this.state.userSearchText[props._id]}
                                onSearch={this.searchText}
                                filterOption
                                optionFilterProp="title"
                            >
                                {teamMembers
                                    .filter(
                                        mem =>
                                            mem.status &&
                                            mem._id !== user.id &&
                                            !props.ccEmails.includes(mem.email) &&
                                            (props.accessibleTo ? !props.accessibleTo.includes(mem._id) : true)
                                    )
                                    .map(mem => (
                                        <Select.Option
                                            key={mem._id}
                                            className="an-nn-opt"
                                            title={mem.fullName + ' ' + mem.email}
                                        >
                                            <div className="an-nn-name">{mem.fullName || mem.email}</div>
                                            <div className="an-nn-add">Add</div>
                                        </Select.Option>
                                    ))}
                            </Select>
                        )}
                    </div>
                )}
                <div className="cpm-mail-footer-block">
                    <Button className="send" type="primary" onClick={() => this.replyMessage(props)}>
                        <Icon type="rollback" />
                        Reply
                    </Button>
                </div>
            </div>
        )

        return (
            <CandidateProfileContainer>
                <div className="candidate-message-wrapper Message_right-wrapper">
                    <h2 className="Message_container_title ">Message: </h2>
                    {this.props.isLoading ? (
                        <MessageLoader />
                    ) : (
                        <div>
                            {!this.state.messages.length && !this.state.isAdd ? (
                                <div className="Message_empmty_textInfo">
                                    <h2 className="Message_header-text">Send Message to {this.state.candidateName}</h2>
                                    <span className="Message_sub-header-text">
                                        Start conversation with candidate to get any additional details related to
                                        candidate profile, send interview schedules etc.
                                    </span>
                                </div>
                            ) : (
                                <React.Fragment>
                                    <div className="add-message">
                                        <div>
                                            {this.state.isAdd ? (
                                                <MessageBox
                                                    cancelAddMessage={this.cancelAddMessage}
                                                    messagedetail={this.state.messagedetail}
                                                    {...this.props}
                                                />
                                            ) : null}
                                        </div>

                                        <div>
                                            {this.state.messages.map((message, index) => {
                                                message.attachments = message.attachments
                                                    ? message.attachments.filter(x => x)
                                                    : []
                                                message.ccEmails = message.ccEmails
                                                    ? message.ccEmails.filter(x => x)
                                                    : []

                                                return (
                                                    <div key={message._id} id={message._id}>
                                                        {!message.showDetail ? (
                                                            <div className="message-header">
                                                                <MessageHeader {...message} index={index} />
                                                            </div>
                                                        ) : (
                                                            <div className="new-message-cont message-detail-animation">
                                                                <MessageBody {...message} index={index} />
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </div>
            </CandidateProfileContainer>
        )
    }
}
