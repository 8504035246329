import styled from 'styled-components'

export const Background = styled.div`
    background-color: #f7f8fc;
    margin-bottom: 3%;
    padding-bottom: 3%;
`

export const Container = styled.div`
    width: 93%;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    justify-content: center;
    margin: 1.7% 3.9% 2% 3.9%;
    padding: 2%;

    .career-cont-btn {
        display: none;
    }

    &:hover {
        box-shadow: 0 0 7px 0 rgba(71, 103, 160, 0.5);
        border: solid 2% rgba(71, 103, 160, 0.5);

        .career-cont-btn {
            display: flex;
        }
    }
`

export const EndContainer = styled.div`
    display: flex;
    width: 93%;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    margin: 1.7% 3.9% 2% 3.9%;
    background-color: #ffffff;
    justify-content: center;
    padding: 2%;
`

export const ButtonContainer = styled.div`
    display: none;

    &:hover {
        display: initial;
        background-color: #000;
    }
`

export const NoHeaderWrapper = styled.div`
    height: 44px;
    width: 100%;
    border: 1px solid #e1e4e8;
    border-radius: 4px;
    display: flex;
    padding-right: 10px;
    justify-content: space-between;
`

export const CultureImage = styled.div`
    height: 60px;
    width: 100%;
    border: 1px solid #e1e4e8;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
`

export const CultureUpload = styled.div`
    width: 100%;
    height: 240px;
    overflow: auto;
    padding: 10px 0 10px ${image_urls => (image_urls.length > 3 ? '5%' : '10px')};
    border-radius: 5px;
    border: 2px solid rgba(156, 163, 160, 0.25);
}
`

export const DrawerHeadContainer = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    padding: 0 8%;
    justify-content: space-between;
    background-color: white;
    border-bottom: 2px solid #1c6ea4;
    border-radius: 0 0 30px 30px;
    -webkit-box-shadow: -1px 0 14px 0 rgba(0, 0, 0, 0.77);
    box-shadow: -1px 0 14px 0 rgba(0, 0, 0, 0.77);
    z-index: 500;
`
