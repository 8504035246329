import styled from 'styled-components'
import { CustomScrollBar } from '../../../../Helpers/Stylesheet/styledComponents'

export const SendInvite = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #e1e4e8;
    padding: 30px 0;
    &:first-child {
        padding: 0 0 30px 0;
    }
    &:last-child {
        border-bottom: 0;
        padding: 30px 0 0 0;
    }
    .sendInviteUser {
        h6 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 4px;
            max-width: 240px;
        }
        span {
            font-size: 12px;
            font-weight: 600;
            line-height: 0.83;
            color: #4767a0;
            margin-bottom: 0;
            cursor: pointer;
            img {
                margin-left: 5px;
            }
        }
    }
    .sendInviteStatus {
        min-width: 128px;
        margin-left: 30px;
        .sendInviteStatuslabel {
            font-size: 12px;
            font-weight: bold;
            line-height: 1;
            color: #333;
            margin-top: 15px;
            font-family: 'sanFranciscoMedium';
            .resend-icon {
                margin: 0 18px;
            }
        }
        .statusAccepted {
            color: #3acc6c;
            margin-right: 42px;
        }
        .statusPending {
            color: #fa6400;
        }
        .statusDisabled {
            color: #999;
            margin-right: 47px;
        }
        styledSwitch {
            margin-left: 10px;
        }
    }
`
export const AddSendInvites = styled.div`
    span {
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.08px;
        color: #4767a0;
    }
`
export const InviteMenu = styled.div`
    display: flex;
    overflow-x: auto;
    ${CustomScrollBar};
    height: 34px;
    margin-bottom: 10px;
    span {
        font-size: 14px;
        font-weight: 500;
        color: #999999;
        margin-right: 21px;
        cursor: pointer;
        opacity: 0.8;
        flex-shrink: 0;
        height: 18px;
        &:hover {
            opacity: 1;
            transition: all 0.5s ease;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .current {
        color: #4d679c;
        font-weight: bold;
        position: relative;
        transition: all 0.5s ease;
        &::before {
            width: 100%;
            height: 3px;
            background-color: #4d679c;
            position: absolute;
            bottom: -8px;
            content: '';
            transition: all 0.5s ease;
        }
    }
`
