import apiCaller from '../../apiCaller'
import { warning, success } from '../../../services/Notification'
import _ from 'lodash'
import { LogE } from '../../../container/Helpers/errorHandler'
import { store } from '../../../redux/store'
import { actions } from '../../../redux/todo/actions'
import jobActions from '../../../redux/jobs/actions'

const { restricted } = apiCaller.type
const { formatError } = apiCaller
const baseUrl = `interview/`

export const SendReminder = interviewId => async dispatch => {
    try {
        const { data } = await restricted.post(`${baseUrl}reminder/${interviewId}`)
        success(data.message)
        return true
    } catch (error) {
        warning(formatError(error))
    }
}

/**
 * Cancel interview action listener. Whenever an interview is canceled,
 * an action is dispatched to the store.
 *
 * @param {payload} payload cancelled interview event details
 * @param {companyId} companyId id of the company
 */
export const CancelInterview = (payload, companyId, { filter, type, refineType, userId }) => async dispatch => {
    try {
        const { data } = await restricted.put(`${baseUrl}cancelInterview/${companyId}`, payload)
        dispatch({ type: actions.UPDATE_INTERVIEW, payload: { data, filter, type, refineType, userId } })
        if (data.message) success(data.message)
        if (data.error) warning(data.error)
    } catch (error) {
        warning(formatError(error))
    }
}

/**
 * Reschedule interview action listener. Whenever an interview is rescheduled,
 * an action is dispatched to the store.
 *
 * @param {interview} interview updated interview event details
 */
export const RescheduleInterview = (interview, { filter, type, refineType, userId }) => dispatch => {
    try {
        dispatch({ type: actions.UPDATE_INTERVIEW, payload: { data: { interview }, filter, type, refineType, userId } })
    } catch (error) {
        warning(formatError(error))
    }
}

export const UpdateStages = input => async dispatch => {
    try {
        const { data } = await restricted.put(`${baseUrl}stages`, input)
        if (data.error) {
            LogE('networkCalls-interview-updateStages', 'Failed to update', data.error)
            warning('Failed to update stages')
            return null
        } else {
            const { created } = data
            const stages = input.stages.map(stage => {
                if (stage.stageId) return stage
                else
                    return {
                        stageId: created[0]._id,
                        stageName: created[0].stageName,
                    }
            })
            dispatch({
                type: jobActions.UPDATE_STAGE,
                jobId: input.jobId,
                stages,
            })
            return data
        }
    } catch (error) {
        LogE('networkCalls-interview-updateStages-catch', 'Failed to update', error)
        warning(formatError(error))
    }
}

export const DeleteStage = (stageId, jobId) => async dispatch => {
    try {
        const { data } = await restricted.delete(`${baseUrl}stages/${stageId}/${jobId}`)
        if (data.error) {
            LogE('networkCalls-interview-deleteStage', 'Failed to delete', data.error)
            warning('Failed to delete stage')
            return null
        } else {
            dispatch({
                type: jobActions.DELETE_STAGE,
                jobId,
                stageId,
            })
            return data
        }
    } catch (error) {
        LogE('networkCalls-interview-deleteStage-catch', 'Failed to delete', error)
        warning(formatError(error))
    }
}
