import React, { useState, useEffect } from 'react'
import { Field } from '../../../../container/Modals/AddCandidate/style.js'
import { StepButton } from '../style'
import Button from '../../../Components/StyledButton'
import { currencyUnitAndValueCalculator } from '../../../Helpers/currencies'

// Page 3 of the form
const Preview = ({ data: { jobSkill, details }, submitCandidature, changePage, showComponent }) => {
    const [disable, setDisable] = useState(false)

    const { ctcInfo } = details
    const currentCTCArray = currencyUnitAndValueCalculator(ctcInfo.current, ctcInfo.expectedCTCCurrency)
    const expectedCTCArray = currencyUnitAndValueCalculator(ctcInfo.expected, ctcInfo.expectedCTCCurrency)

    const submit = async () => {
        setDisable(true)
        await submitCandidature()
        setDisable(false)
    }

    return (
        <>
            <Field>
                <div className="title">Job roles you are looking for</div>
                <div className="tags bordered">
                    {jobSkill.jobs && jobSkill.jobs.map(each => <span key={each.title}>{each.title}</span>)}
                </div>
            </Field>
            <Field>
                <div className="title">Key skills</div>
                <div className="tags bordered">
                    {jobSkill.skills && jobSkill.skills.map(each => <span key={each.name}>{each.name}</span>)}
                </div>
            </Field>
            <div className="details">
                <div className="item">
                    <span>Notice Period</span>
                    <span>{details.noticePeriod}</span>
                </div>
                <div className="item">
                    <span>Current CTC</span>
                    {ctcInfo.current ? (
                        <span>{`${ctcInfo.expectedCTCCurrency || ''} ${currentCTCArray[1] ||
                            'NA'} ${currentCTCArray[2] || ''}`}</span>
                    ) : (
                        <span>NA</span>
                    )}
                </div>
                <div className="item">
                    <span>Expected CTC</span>
                    {ctcInfo.expected ? (
                        <span>{`${ctcInfo.expectedCTCCurrency || ''} ${expectedCTCArray[1] ||
                            ''} ${expectedCTCArray[2] || ''}`}</span>
                    ) : (
                        <span>NA</span>
                    )}
                </div>
                <div className="item">
                    <span>Willing to Relocate</span>
                    <span>{details.relocation ? 'Yes' : 'No'}</span>
                </div>
                <div className="item">
                    <span>{details.socialType} Link</span>
                    {details.socialLink ? (
                        <span
                            style={{ color: '#4767a0', cursor: 'pointer' }}
                            onClick={() => window.open(details.socialLink, '_blank')}
                        >
                            {details.socialLink}
                        </span>
                    ) : (
                        <span>NA</span>
                    )}
                </div>
                <div className="item">
                    <span>Resume</span>
                    {details.resume && details.resume.link ? (
                        <div className="tags">
                            <span onClick={() => window.open(details.resume.link, '_blank')}>
                                {details.resume.name || 'Resume'}
                            </span>
                        </div>
                    ) : (
                        <span>NA</span>
                    )}
                </div>
            </div>
            <StepButton>
                <Button type="inverted" width="80px" height="40px" onClick={() => changePage('DEC')}>
                    BACK
                </Button>
                <Button width="80px" height="40px" disable={disable} onClick={submit}>
                    CONFIRM
                </Button>
            </StepButton>
        </>
    )
}

export default Preview
