import React, { Component, useState } from 'react'
import { Aside, Tabs, SubTabs } from '../style'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { ReactComponent as CandidateIcon } from '../../../../assets/Icons/Talent Pool/candidate.svg'
import { ReactComponent as BriefcaseIcon } from '../../../../assets/Icons/Openings/briefcase.svg'
import { ReactComponent as TeamMemberIcon } from '../../../../assets/Icons/Talent Pool/team-member.svg'
import { Collapse, Popover } from 'antd'
import { Link } from 'react-router-dom'
import PageModule from '../Pages'
import className from 'classnames'

const { Panel } = Collapse
const customPanelStyle = {
    background: '#fff',
    border: 0,
    overflow: 'hidden',
    color: '#333',
    fontFamily: 'SFProText-Bold',
}

export const SIDEBAR_CONTENT = [
    {
        HEADER: {
            key: 'candidate',
            icon: CandidateIcon,
            text: 'CANDIDATE',
        },
        CONTENT: [
            { key: 'OVERVIEW', route: '', name: 'Overview', component: PageModule.CANDIDATE.Overview },
            {
                key: 'PROGRESS',
                route: 'progress',
                name: 'Candidate Progress',
                component: PageModule.CANDIDATE.Progress,
            },
            { key: 'TSA', route: 'tsa', name: 'Time Spent Analysis', component: PageModule.CANDIDATE.TSA },
            { key: 'SOURCE', route: 'source', name: 'Candidate Source', component: PageModule.CANDIDATE.Source },
        ],
    },
    {
        HEADER: {
            key: 'job',
            icon: BriefcaseIcon,
            text: 'JOBS',
        },
        CONTENT: [
            { key: 'OVERVIEW', route: '', name: 'Overview', component: PageModule.JOB.Overview },
            { key: 'TIMELINE', route: 'timeline', name: 'Job Timeline', component: PageModule.JOB.Timeline },
            { key: 'STAGE', route: 'stage', name: 'Stage Analysis', component: PageModule.JOB.Stage },
        ],
    },
    {
        HEADER: {
            key: 'team',
            icon: TeamMemberIcon,
            text: 'TEAM MEMBER',
        },
        CONTENT: [
            { key: 'OVERVIEW', route: '', name: 'Overview', component: PageModule.TEAM_MEMBER.Overview },
            { key: 'MEMBER', route: 'member', name: 'Candidates by Member', component: PageModule.TEAM_MEMBER.Member },
        ],
    },
]

export const MobileSidebar = ({ location: { pathname } }) => {
    const SELECTED_CONTENT = SIDEBAR_CONTENT.find(({ HEADER, CONTENT }) => pathname.includes(HEADER.key))
    const [HEADER, CONTENT] = [
        (SELECTED_CONTENT && SELECTED_CONTENT.HEADER) || {},
        (SELECTED_CONTENT && SELECTED_CONTENT.CONTENT) || [],
    ]
    let section = ''
    CONTENT.forEach(({ route, name }) => {
        if (route && pathname.includes(route)) section = name
    })
    if (section === '') section = 'Overview'

    let [popoverVisible, setPopoverVisible] = useState(false)
    const hide = () => {
        setPopoverVisible(false)
    }
    const handleVisibleChange = visible => {
        setPopoverVisible(visible)
    }

    return (
        <>
            <Tabs className="tabs">
                {SIDEBAR_CONTENT.map(({ HEADER }) => {
                    const evaluatedPath = `/reports/${HEADER.key}`
                    return (
                        <Link
                            to={evaluatedPath}
                            key={HEADER.key}
                            className={className({ active: pathname.includes(evaluatedPath) })}
                        >
                            {HEADER.text}
                        </Link>
                    )
                })}
            </Tabs>
            <Popover
                placement="bottomRight"
                visible={popoverVisible}
                onVisibleChange={handleVisibleChange}
                content={
                    <SubTabs>
                        {CONTENT.map(({ name, route }) => {
                            const evaluatedPath = `/reports/${HEADER.key}/${route}`
                            return (
                                <Link
                                    to={evaluatedPath}
                                    key={name}
                                    onClick={() => {
                                        hide()
                                    }}
                                >
                                    {name}
                                </Link>
                            )
                        })}
                    </SubTabs>
                }
            >
                <span>
                    {section} <DownIcon />
                </span>
            </Popover>
        </>
    )
}

export const Sidebar = ({ location: { pathname } }) => {
    return (
        <>
            <Aside>
                <Collapse bordered={false} activeKey={SIDEBAR_CONTENT.map(({ HEADER }) => HEADER.key)}>
                    {SIDEBAR_CONTENT.map(({ HEADER, CONTENT }) => {
                        const ICON = HEADER.icon
                        return (
                            <Panel
                                header={
                                    <>
                                        <ICON />
                                        {HEADER.text}
                                    </>
                                }
                                showArrow={false}
                                key={HEADER.key}
                                style={customPanelStyle}
                            >
                                {CONTENT.map(({ key, name, route }) => {
                                    const evaluatedPath = `/reports/${HEADER.key}/${route}`
                                    return (
                                        <Link
                                            to={evaluatedPath}
                                            key={true}
                                            className={className({ active: pathname === evaluatedPath })}
                                        >
                                            {name}
                                        </Link>
                                    )
                                })}
                            </Panel>
                        )
                    })}
                </Collapse>
            </Aside>
        </>
    )
}
