import React, { Component } from 'react'
import { Background, EndContainer } from './style'
import './style.css'
import { Typography } from 'antd'
import { success, warning } from '../../../services/Notification'
import { store } from '../../../redux/store'
import NewSection from './Components/NewSection'
import Banner from './Components/Banner'
import WorkWithUs from './Components/WorkWithUs'
import Culture from './Components/Culture'
import Benefit from './Components/Benefit'
import FloatingContainer from './Components/FloatingContainer'
import Preview from './Components/Preview'
import CompanyLogo from './Components/CompanyLogo'
import ContentLoader from 'react-content-loader'
import { CareerFormGet, CareerFormPublish, CareerFormSave } from '../../../services/api'
import { LogE } from '../../Helpers/errorHandler'
import { DYNAMIC_SECTIONS, MANUAL_SECTIONS, USER_NAME_RESOLVER } from './DataConfigs'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const { Text } = Typography

const MyLoader = () => (
    <ContentLoader
        height={150}
        width={300}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        className="career-loader"
    >
        <rect x="3%" y="0" rx="3" ry="3" width="94%" height="10" />
        <rect x="3%" y="15" rx="3" ry="3" width="94%" height="27" />
        <rect x="3%" y="50" rx="3" ry="3" width="94%" height="27" />
        <rect x="3%" y="85" rx="3" ry="3" width="94%" height="27" />
        <rect x="3%" y="118" rx="3" ry="3" width="94%" height="27" />
    </ContentLoader>
)

export default class Career extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disableBtn: false,
            sectionBtns: false,
            addSection: false,
            sectionCounter: 0,
            data: [],
            banner: {
                title: '',
            },
            company: {},
            sections: [],
            isLoading: true,
            _id: 0,
            isPublished: true,
            benefitLimit: 4,
            pointLimit: 6,
            showPreview: false,
            editModeCount: { section: {} },
        }

        // using it this way to have the single function reference in the event: beforeunload, so that removeEventListener works
        this.pageRefreshhandler = this.pageRefreshhandler.bind(this)
    }

    scrollToBottom = () => {
        this.el.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }

    canShowAlertDecider = () => {
        const { editModeCount } = this.state
        const items = Object.keys(editModeCount)
        if (items.length > 1) {
            const evaluateItem = (editModeCount, items) => {
                let section = ['banner', 'benefit', 'culture', 'work']
                for (let each of section) {
                    if (editModeCount.hasOwnProperty(each))
                        if (editModeCount[each]['save'] !== 1) {
                            return {
                                item: USER_NAME_RESOLVER[each],
                                count: 'a', // count not required, will show text "an/a"
                            }
                        }
                }
                return false
            }

            return evaluateItem(editModeCount, items)
        } else {
            // if length is === 1 then it will always be section
            const { section = {} } = editModeCount

            for (let each in section) {
                if (section[each].save !== 1) {
                    return {
                        item: USER_NAME_RESOLVER.section,
                        count: Object.keys(section).length,
                    }
                }
            }
            return false
        }
    }

    pageRefreshhandler(e) {
        const alert = this.canShowAlertDecider()
        if (alert) {
            warning(`You have un-published ${alert.item} data for ${alert.count} section`)
            var confirmationMessage = 'o/'
            e.returnValue = confirmationMessage
            return confirmationMessage
        }
        return
    }

    componentDidMount() {
        const { companyName } = store.getState().Auth.user
        document.title = `Customize Career Page | ${companyName}`
        this.getData()

        window.addEventListener('beforeunload', this.pageRefreshhandler)
        this.props.allowSwitchTabs(false)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.pageRefreshhandler)
    }

    getData = () => {
        CareerFormGet().then(careerPageData => {
            if (careerPageData.settings === null) {
                let { data, benefitLimit, pointLimit } = this.state
                data[0] = {
                    type: 'why_work_with_us',
                    why_work_with_us: {
                        points: Array(pointLimit).fill(''),
                        position: 0,
                    },
                }
                data[1] = { type: 'culture', culture: { position: 1 } }
                data[2] = {
                    type: 'benefits',
                    benefits: {
                        details: Array(benefitLimit).fill({
                            title: '',
                            description: '',
                        }),
                        position: 2,
                    },
                }
                this.setState({ isPublished: false, isLoading: false, data })
            } else {
                const { benefitLimit, pointLimit } = this.state
                let { sectionCounter } = this.state // The section counter in state is updated with the sections present in incoming data
                const arr = ['why_work_with_us', 'culture', 'benefits']
                let length = arr.length // logo and banner are added seperately
                if (careerPageData.settings.sections) {
                    sectionCounter = careerPageData.settings.sections.length
                    length += careerPageData.settings.sections.length
                }
                let data = Array(length).fill(0)

                arr.forEach(key => {
                    data[careerPageData.settings[key].position] = {
                        type: key,
                        [key]: careerPageData.settings[key],
                    }
                })
                if (careerPageData.settings.sections) {
                    careerPageData.settings.sections.forEach(each => {
                        data[each.position] = {
                            type: 'section',
                            section: { ...each, key: this.getRandomId() },
                        }
                    })
                }

                // backfilling benefit and points
                if (careerPageData.settings.benefits.details.length < benefitLimit) {
                    const benefitLength = benefitLimit - careerPageData.settings.benefits.details.length
                    data[careerPageData.settings.benefits.position] = {
                        type: 'benefits',
                        benefits: {
                            details: [
                                ...careerPageData.settings.benefits.details,
                                ...Array(benefitLength).fill({
                                    title: '',
                                    description: '',
                                }),
                            ],
                            title: careerPageData.settings.benefits.title,
                            position: careerPageData.settings.benefits.position,
                        },
                    }
                }
                if (careerPageData.settings.why_work_with_us.points.length < pointLimit) {
                    const pointLength = pointLimit - careerPageData.settings.why_work_with_us.points.length
                    data[careerPageData.settings.why_work_with_us.position] = {
                        type: 'why_work_with_us',
                        why_work_with_us: {
                            points: [
                                ...careerPageData.settings.why_work_with_us.points,
                                ...Array(pointLength).fill(''),
                            ],
                            title: careerPageData.settings.why_work_with_us.title,
                            position: careerPageData.settings.why_work_with_us.position,
                        },
                    }
                }
                this.setState({
                    ...careerPageData.settings,
                    _id: careerPageData.settings._id,
                    isLoading: false,
                    data,
                    sectionCounter,
                })
            }
        })
    }

    updateEditModeCount = (key, increment, id) => {
        // increment can be +1 or -1
        let { editModeCount } = this.state
        if (increment === 0) {
            if (key === 'section') editModeCount[key][id] = { save: 0 }
            // doing (index+1) to eliminate evaluation of 0 position as false
            else editModeCount[key] = { save: 0 }
        } else if (increment === -1) {
            if (key === 'section') delete editModeCount[key][id]
            else delete editModeCount[key]
        } else if (increment === 1) {
            if (key === 'section') editModeCount[key][id] = { save: 1 }
            // doing (index+1) to eliminate evaluation of 0 position as false
            else editModeCount[key] = { save: 1 }
        }
        this.setState({ editModeCount })
    }

    componentRender({ type, value }, index) {
        const { data } = this.state
        switch (type) {
            case 'why_work_with_us':
                return (
                    <WorkWithUs
                        key={index}
                        workWithUs={data[index].why_work_with_us}
                        onSubmit={this.onSubmit}
                        moveUp={this.moveUp}
                        moveDown={this.moveDown}
                        updateEditModeCount={this.updateEditModeCount}
                        index={index}
                        length={this.state.data.length}
                    />
                )
            case 'culture':
                return (
                    <Culture
                        key={index}
                        culture={data[index].culture}
                        onSubmit={this.onSubmit}
                        moveUp={this.moveUp}
                        moveDown={this.moveDown}
                        updateEditModeCount={this.updateEditModeCount}
                        index={index}
                        length={this.state.data.length}
                    />
                )
            case 'benefits':
                return (
                    <Benefit
                        key={index}
                        benefit={data[index].benefits}
                        onSubmit={this.onSubmit}
                        moveUp={this.moveUp}
                        moveDown={this.moveDown}
                        updateEditModeCount={this.updateEditModeCount}
                        index={index}
                        length={this.state.data.length}
                    />
                )
            case 'section':
                return (
                    <NewSection
                        key={data[index].section.key}
                        newSection={data[index].section}
                        onSubmit={this.onSubmit}
                        moveUp={this.moveUp}
                        moveDown={this.moveDown}
                        updateEditModeCount={this.updateEditModeCount}
                        index={index}
                        length={this.state.data.length}
                        deleteSection={this.deleteSection}
                    />
                )
            default:
                return null
        }
    }

    moveUp = index => {
        const alert = this.canShowAlertDecider()
        if (alert) {
            warning(`Please save the ${alert.item} data before moving the position`)
            return null
        }
        let { data } = this.state
        let temp = data[index]
        data[index] = data[index - 1]
        data[index - 1] = temp
        this.setState({ data })
    }

    moveDown = index => {
        const alert = this.canShowAlertDecider()
        if (alert) {
            warning(`Please save the ${alert.item} data before moving the position`)
            return null
        }
        let { data } = this.state
        let temp = data[index]
        data[index] = data[index + 1]
        data[index + 1] = temp
        this.setState({ data })
    }

    deleteSection = index => {
        let { data, sectionCounter } = this.state
        data.splice(index, 1)
        this.setState({ data, sectionCounter: --sectionCounter })
    }

    addSection = () => {
        if (this.state.sectionCounter >= 5) {
            warning('You can only add a maximum of 5 new sections')
            return null
        }
        let { data, sectionCounter } = this.state
        const section = {
            title: '',
            description: '',
            position: data.length,
            key: this.getRandomId(),
        }

        data.push({ type: 'section', section })
        this.scrollToBottom()
        this.setState({ data, sectionCounter: ++sectionCounter })
    }

    getRandomId = (min = 100, max = 10000) => {
        return min + Math.random() * (max - min)
    }

    onSubmit = (values, index, key) => {
        // the state is updated entirely with the set values
        // checking if the data update is in allowed state or not
        if (DYNAMIC_SECTIONS.hasOwnProperty(key)) {
            let { data } = this.state
            data[index] = {
                type: key,
                [key]: values,
            }
            this.setState({ data })
        } else if (MANUAL_SECTIONS[key]) {
            this.setState({ [key]: values })
        } else {
            LogE(
                'pages-career-onSubmit',
                `The action is illegal for: ${key} index: ${index}` + JSON.stringify(values),
                null
            )
        }
    }

    communicationDataFormatter = () => {
        let { company, banner, data, _id } = this.state
        const { companyId, companyName } = store.getState().Auth.user
        let payload = {
            company: {
                id: companyId,
                logo_url: company.logo_url || null,
                companyName,
            },
            banner: {
                title: banner.title || null,
                description: banner.description || null,
                background_url: banner.background_url || null,
            },
            why_work_with_us: {
                points: [],
                title: '',
                position: 0,
            },
            sections: [],
            benefits: {
                details: [],
                title: '',
                position: 2,
            },
            culture: {
                title: null,
                description: null,
                image_urls: null,
                position: 1,
            },
            _id,
        }

        //TODO: Update the DB with same packing eg : benefits.benefits instead of benefits.details
        //After that it will be just a generic function
        data.forEach((each, index) => {
            if (each === null) return
            if (each.type === 'benefits') {
                payload[each.type] = {
                    details: each[each.type]['details'],
                    title: each[each.type]['title'],
                    position: index,
                }
            } else if (each.type === 'why_work_with_us') {
                payload[each.type] = {
                    points: each[each.type]['points'],
                    title: each[each.type]['title'] || 'WHY WORK WITH US',
                    position: index,
                }
            } else if (each.type === 'section') {
                // just pushing the data to section array
                payload.sections.push({ ...each['section'], position: index })
            } else if (each.type === 'culture') {
                payload[each.type] = {
                    ...each[each.type],
                    position: index,
                }
            }
        })

        // cleaning data at the time for API call
        payload.benefits.details =
            payload.benefits.details.filter(
                value => value.title.trim().length !== 0 || value.description.trim().length !== 0
            ) || []

        payload.why_work_with_us.points =
            payload.why_work_with_us.points.filter(value => value && value.trim().length !== 0) || []

        return payload
    }

    onPublish = () => {
        try {
            let { company, banner, isPublished } = this.state

            if (!company.logo_url) {
                warning('The company logo must be uploaded')
                return null
            }
            const alert = this.canShowAlertDecider()
            if (alert) {
                warning(`You have un-published ${alert.item} data for ${alert.count} section`)
                return null
            }

            const pageData = this.communicationDataFormatter()

            const result = isPublished ? CareerFormSave(pageData) : CareerFormPublish(pageData)
            result
                .then(res => {
                    if (res.error) warning(res.error.msg || 'Unknown Error, Please try again')
                    else {
                        success('Career Page updated successfully')
                        this.setState({ editModeCount: { section: {} }, isPublished: true, _id: res._id })
                    }
                })
                .catch(err => {
                    warning(err)
                    LogE('pages-career-onPublish-api', JSON.stringify({ isPublished }), err)
                })
        } catch (err) {
            LogE('pages-career-onPublish', '', err)
            warning(err.message || err)
        }
    }

    onPreview = () => {
        this.setState({ showPreview: true })
    }

    render() {
        // Loaders are defined and rendered here
        if (this.state.isLoading)
            return (
                <div className="career-loader-page">
                    <Text className="career-title">CUSTOMIZE CAREER PAGE</Text>
                    <div className="card-container">
                        <MyLoader />
                    </div>
                </div>
            )

        return (
            <>
                <Background>
                    <Text className="career-title">CUSTOMIZE CAREER PAGE</Text>

                    <ReactCSSTransitionGroup
                        transitionName="career-page-transition"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnterTimeout={0}
                        transitionLeaveTimeout={0}
                    >
                        <CompanyLogo onSubmit={this.onSubmit} company={this.state.company} key="key-company-logo" />
                        <Banner
                            banner={this.state.banner}
                            onSubmit={this.onSubmit}
                            updateEditModeCount={this.updateEditModeCount}
                            key="key-banner-section"
                        />
                    </ReactCSSTransitionGroup>

                    <ReactCSSTransitionGroup
                        transitionName="career-page-transition"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={0}
                    >
                        {this.state.data.map((each, index) => this.componentRender(each, index))}
                    </ReactCSSTransitionGroup>

                    <EndContainer>
                        <div className="career-opening-info">
                            List of open positions will be shown at the bottom of the career page
                        </div>
                    </EndContainer>
                </Background>

                <Preview
                    visible={this.state.showPreview}
                    onClose={() => this.setState({ showPreview: false })}
                    payload={{ settings: this.communicationDataFormatter() }}
                />

                <FloatingContainer onPublish={this.onPublish} addSection={this.addSection} onPreview={this.onPreview} />
                <div
                    ref={el => {
                        this.el = el
                    }}
                />
            </>
        )
    }
}
