import React from 'react'
import Bluebird from 'bluebird'
import { Icon, Select } from 'antd'
import { Field, FormWrapper } from '../style'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { SingleFileTypeValidation } from '../../../Helpers/fileTypeValidation'
import { randomString } from '../../../Helpers/utils'
import { LogE } from '../../../Helpers/errorHandler'
import { warning } from '../../../../services/Notification'
import { store } from '../../../../redux/store'
import { FILE_STATUS } from '../../../Helpers/constants'

const { Option } = Select

const jobDataChange = (setSelectedJob, setSelectedStage) => value => {
    if (value === undefined) {
        setSelectedJob(value)
        setSelectedStage(value)
    } else setSelectedJob(value)
}

export const JobStageSelector = ({
    selectedJob,
    setSelectedJob,
    allJobs,
    calculatedStage,
    setSelectedStage,
    stages,
    accessLevel,
    jobDetails,
}) => {
    const jobId = jobDetails && jobDetails.jobApplication && jobDetails.jobApplication.jobId
    return (
        <FormWrapper mt="20px">
            <Field>
                <div className="title">Opening</div>
                <Select
                    size="large"
                    placeholder={'Select'}
                    getPopupContainer={trigger => trigger.parentNode}
                    suffixIcon={<Icon component={DownIcon} />}
                    value={jobId}
                    disabled={true}
                    onChange={jobDataChange(setSelectedJob, setSelectedStage)}
                >
                    <Option key="job-unselect-key" value={undefined}>
                        ---Select---
                    </Option>
                    {allJobs.map(option => (
                        <Option value={option._id} key={option._id}>
                            {option.jobTitle}
                        </Option>
                    ))}
                </Select>
            </Field>
            <Field>
                <div className="title">Stage</div>
                <Select
                    size="large"
                    placeholder={'Select'}
                    getPopupContainer={trigger => trigger.parentNode}
                    suffixIcon={<Icon component={DownIcon} />}
                    value={calculatedStage}
                    onChange={setSelectedStage}
                >
                    {stages.map(option => {
                        if (accessLevel > 3 && option.stageName === 'JOINED') return null
                        else
                            return (
                                <Option value={option._id} key={option._id}>
                                    {option.stageName}
                                </Option>
                            )
                    })}
                </Select>
            </Field>
        </FormWrapper>
    )
}
export const onDropBulkMode = ({
    GetResumeDetails,
    calculatedStage,
    selectedJob,
    setDropError,
}) => async acceptedFiles => {
    try {
        if (acceptedFiles.length === 0) setDropError('Unsupported file format')
        else if (acceptedFiles.length > 10) {
            setDropError('Maximum number of allowed file upload is 10')
        } else {
            await Bluebird.map(
                acceptedFiles,
                async file => {
                    const uploadFile = SingleFileTypeValidation(file)
                    if (uploadFile) {
                        const uploadId = randomString()
                        await GetResumeDetails({
                            uploadFile,
                            fileName: file.name,
                            uploadId,
                            jobId: selectedJob,
                            stageId: calculatedStage,
                            ...(selectedJob && { hasJobId: true }),
                        })
                    }
                },
                { concurrency: 2 }
            )
        }
    } catch (err) {
        console.log('addCandidate-bulkMode-onDrop', '', err)

        const errorMsg = (err.response && err.response.data && err.response.data.error.msg) || err

        warning(errorMsg)
    }
}

export const hasListDataModified = props_jobId => {
    const { data, list } = store.getState().Candidate

    // check if data is really is changed
    for (let index = 0; index < list.length; index++) {
        const {
            name = '',
            email = '',
            phone,
            resumeLink = '',
            jobId = props_jobId,
            uploadStatus,
            hasJobId = !!props_jobId,
        } = data[list[index]]
        if (uploadStatus === FILE_STATUS.UPLOADING) return true
        if (name.trim().length > 0) return true
        if (email.trim().length > 0) return true
        if (resumeLink.length > 0) return true
        if (!hasJobId && jobId.length > 0) return true
        if (phone.number && phone.number.length > 0) return true
        if (phone.code && phone.code.length > 0) return true
    }

    return false
}
