import React from 'react'
import { Modal } from 'antd'
import Main from '../../Pages/ScheduleInterview/main'

const ScheduleModalWrapper = props => (
    <Modal
        visible={props.visible}
        footer={false}
        width={'100%'}
        bodyStyle={{ width: '100%', height: '100%', padding: 0, position: 'relative' }}
        className="schedule-interview-modal"
        centered
        closable={false}
        destroyOnClose
        maskClosable={false}
    >
        {props.visible && <Main {...props} />}
    </Modal>
)

export default ScheduleModalWrapper
