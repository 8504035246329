import React, { Component } from 'react'
import { Body } from './style'
import { InboxWrapper, Main } from './style'
import { HeadingSection } from './Components/Static'
import ContactsSection from './Sections/ContactsSection'
import MessageSection from './Sections/MessageSection'
import FilterSection from './Sections/FilterSection'
import { connect } from 'react-redux'
import { getContacts, getInboxEmails, setApiProcess } from '../../../services/NetworkCalls/Inbox'
import { EVENTS, TAB_MODE } from './Components/constants'

class Inbox extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showNewMessage: false,
        }
    }

    toggleNewMessage = (data = null) =>
        this.setState({ showNewMessage: data === null ? !this.state.showNewMessage : data })

    componentDidMount() {
        document.title = 'Inbox | SpringRecruit'
        this.props.getContacts({})
        // this.props.getInboxEmails({ candidateId: '60422765dbc53f0019696a99' })
    }

    handleFilterUpdates = (eventType, data) => {
        const { mainFilter, jobId, stageId, status, searchResult, selectedFilter, currentPage } = this.props
        const payload = {
            mainFilter,
            jobId: selectedFilter.jobId || [],
            stageId: selectedFilter.stageId || [],
            status: selectedFilter.status || [],
            searchResult,
            pageNo: 1,
            filterType: eventType,
            tabMode: selectedFilter.tabMode || TAB_MODE.ALL,
        }
        if (eventType === EVENTS.FILTER) {
            payload.mainFilter = data
            payload.jobId = []
            payload.status = []
        } else if (eventType === EVENTS.OPENINGS) {
            payload.jobId = data
        } else if (eventType === EVENTS.STAGE) {
            payload.stageId = data
        } else if (eventType === EVENTS.STATUS) {
            payload.status = data
            payload.jobId = []
        } else if (eventType === EVENTS.SEARCH) {
            payload.searchResult = data
        } else if (eventType === EVENTS.NEXT_PAGE) {
            payload.pageNo = currentPage + 1
        } else if (eventType === EVENTS.SEARCH_WITH_TAB_MODE) {
            payload.tabMode = data
        }

        this.toggleNewMessage(false)
        this.props.getContacts(payload)
    }

    switchTabMode = tabMode => {
        this.handleFilterUpdates(EVENTS.SEARCH_WITH_TAB_MODE, tabMode)
    }

    render() {
        const { showNewMessage } = this.state
        return (
            <InboxWrapper>
                <HeadingSection toggleNewMessage={this.toggleNewMessage} showNewMessage={showNewMessage} />
                <Body>
                    <FilterSection handleFilterUpdates={this.handleFilterUpdates} callInProgress={false} inbox={true} />
                    <Main>
                        <ContactsSection
                            handleFilterUpdates={this.handleFilterUpdates}
                            switchTabMode={this.switchTabMode}
                            toggleNewMessage={this.toggleNewMessage}
                        />
                        <MessageSection toggleNewMessage={this.toggleNewMessage} showNewMessage={showNewMessage} />
                    </Main>
                </Body>
            </InboxWrapper>
        )
    }
}

const mapStateToProps = ({ Inbox }) => {
    return Inbox
}

export default connect(
    mapStateToProps,
    { setApiProcess, getContacts, getInboxEmails }
)(Inbox)
