import { Icon, Input, Rate, Tooltip, Checkbox } from 'antd'
import StarIcon from '../../../../assets/Icons/Feedback/Star Outline'
import { ReactComponent as InfoIcon } from '../../../../assets/Icons/Interview/info.svg'
import React, { Fragment, useState } from 'react'
import { Field } from '../../AddCandidate/style'
import { ReactComponent as CloseIcon } from '../../../../assets/Icons/AccountSettingPage/Remove Button.svg'
import { ReactComponent as TickIcon } from '../../../../assets/Icons/Todo/Tick.svg'
import { ReactComponent as BinIcon } from '../../../../assets/Icons/Feedback/bin.svg'
import { ReactComponent as EditIcon } from '../../../../assets/Icons/AccountSettingPage/CalendarEdit.svg'
import { ReactComponent as GreyInfoIcon } from '../../../../assets/Icons/Board/Info.svg'

export const SkillsPreview = ({ skills, onDelete, editSkill, formMode }) => {
    const [deleteConfirm, setDeleteConfirm] = useState(-1)
    return (
        <div className="rating">
            {skills.map(({ name, description, required }, index) => (
                <div className="rating-rows">
                    <div className="item" hidden={deleteConfirm === index}>
                        <div>
                            {name} {required && ' *'}
                            {index > 3 && description && (
                                <Tooltip
                                    placement="topLeft"
                                    autoAdjustOverflow
                                    overlayClassName="fs-10"
                                    title={description}
                                    arrowPointAtCenter
                                    overlayStyle={{ maxWidth: 'initial' }}
                                >
                                    <GreyInfoIcon />
                                </Tooltip>
                            )}
                        </div>
                        <Rate className="feedback-star" character={<Icon component={StarIcon} />} disabled count={5} />
                    </div>

                    <div className="confirmSection" hidden={index < 4 || deleteConfirm === index || formMode !== null}>
                        <EditIcon onClick={() => editSkill(index)} />
                        <BinIcon onClick={() => setDeleteConfirm(index)} />
                    </div>

                    <div className="delete-confirm" hidden={deleteConfirm !== index}>
                        Are you sure you want to delete {name.substring(0, 20)}...?
                    </div>

                    <div className="confirmSection" hidden={index < 4 || deleteConfirm !== index}>
                        <CloseIcon onClick={() => setDeleteConfirm(-1)} />
                        <TickIcon
                            onClick={() => {
                                onDelete(index)
                                setDeleteConfirm(-1)
                            }}
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}

export const FieldPreview = ({ updateSkillArray, removeSkill, skills }) =>
    skills.map(
        (
            {
                name,
                description,
                nameError = '',
                descriptionError = '',
                nameWarning = '',
                descriptionWarning = '',
                required,
            },
            index
        ) => (
            <Fragment key={index}>
                <Field className="full">
                    <div className="close">
                        <CloseIcon style={{ cursor: 'pointer' }} onClick={() => removeSkill(index)} />
                    </div>
                    <div className="title">Section Name*</div>
                    <Input
                        size="large"
                        value={name}
                        onChange={({ target: { value } }) => updateSkillArray(index, 'name', value)}
                    />
                    {nameError.length > 0 && <div className="error">{nameError}</div>}
                    {nameWarning.length > 0 && <div className="warning">{nameWarning}</div>}
                </Field>
                <Field className="full">
                    <div className="title">Section Description</div>
                    <Input
                        size="large"
                        value={description}
                        onChange={({ target: { value } }) => updateSkillArray(index, 'description', value)}
                    />
                    {descriptionError.length > 0 && <div className="error">{descriptionError}</div>}
                    {descriptionWarning.length > 0 && <div className="warning">{descriptionWarning}</div>}
                </Field>
                <Field className="full">
                    <Checkbox
                        checked={required}
                        onChange={({ target: { checked } }) => updateSkillArray(index, 'required', checked)}
                    >
                        <span>Mark as Mandatory</span>
                    </Checkbox>
                </Field>
                {index !== skills.length - 1 ? <Field className="full divider"></Field> : null}
            </Fragment>
        )
    )
