import React from 'react'

const SVGComponent = () => (
    <svg width="12" height="18" viewBox="0 0 12 18" xmlns="http://www.w3.org/2000/svg">
        <g id="Product-Flow" fill="none" fillRule="evenodd">
            <g
                id="ATS-Board---Sort-By-Dropdown-(Hover-+-Selected-state"
                transform="translate(-293 -222)"
                fill="#697998"
                fillRule="nonzero"
            >
                <g id="ATS-Stages" transform="translate(40 200)">
                    <g id="Stage1">
                        <g id="Header" transform="translate(20 20)">
                            <g id="Move-Icon" transform="translate(233 2)">
                                <path
                                    d="M2,11 C0.8954305,11 0,10.1045695 0,9 C0,7.8954305 0.8954305,7 2,7 C3.1045695,7 4,7.8954305 4,9 C4,10.1045695 3.1045695,11 2,11 Z M2,18 C0.8954305,18 0,17.1045695 0,16 C0,14.8954305 0.8954305,14 2,14 C3.1045695,14 4,14.8954305 4,16 C4,17.1045695 3.1045695,18 2,18 Z M2,4 C0.8954305,4 0,3.1045695 0,2 C0,0.8954305 0.8954305,0 2,0 C3.1045695,0 4,0.8954305 4,2 C4,3.1045695 3.1045695,4 2,4 Z"
                                    id="Mask"
                                />
                                <path
                                    d="M10,11 C8.8954305,11 8,10.1045695 8,9 C8,7.8954305 8.8954305,7 10,7 C11.1045695,7 12,7.8954305 12,9 C12,10.1045695 11.1045695,11 10,11 Z M10,18 C8.8954305,18 8,17.1045695 8,16 C8,14.8954305 8.8954305,14 10,14 C11.1045695,14 12,14.8954305 12,16 C12,17.1045695 11.1045695,18 10,18 Z M10,4 C8.8954305,4 8,3.1045695 8,2 C8,0.8954305 8.8954305,0 10,0 C11.1045695,0 12,0.8954305 12,2 C12,3.1045695 11.1045695,4 10,4 Z"
                                    id="Mask-Copy"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default SVGComponent
