import styled from 'styled-components'
import {
    DARK_BLUE,
    FlexCenterRow,
    BORDER_COLOR,
    FlexCenterCol,
    RED,
    GREY,
} from '../../Helpers/Stylesheet/styledComponents'
import { Tags } from '../../../container/Modals/style'

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 28px auto 35px;
    .tabs {
        display: flex;
        cursor: pointer;
        .tab {
            ${FlexCenterRow};
            width: 102px;
            height: 40px;
            border-radius: 5px;
            border: solid 1px #e2e5e4;
            background-color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            color: #bfbfbf;
            &.active {
                color: ${DARK_BLUE};
                font-weight: bold;
                border: solid 1px #ffffff;
                box-shadow: 0 2px 8px 0 rgba(71, 103, 160, 0.18);
                z-index: 1;
            }
            &:not(:first-child) {
                margin-left: -2px;
            }
        }
    }
`
export const Title = styled.div`
    color: ${DARK_BLUE};
    font-size: 24px;
`
export const HeaderTitle = styled.div`
    color: ${DARK_BLUE};
    font-size: 24px;
    font-family: sanFranciscoMedium;
`
export const Main = styled.main`
    width: 1200px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 3fr;
    .sidebar {
        .card {
            box-shadow: 0 2px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
            background-color: #ffffff;
            margin-right: 25px;
            padding: 20px;
            font-size: 12px;
            .title {
                font-weight: 600;
                font-family: sanFranciscoSemi;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
            .expand {
                font-weight: 500;
                font-family: sanFranciscoMedium;
                color: ${DARK_BLUE};
                cursor: pointer;
            }
        }
    }
`
export const CardCollapsible = styled.div`
    padding: 40px;
    box-shadow: 0 2px 10px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08);
    background-color: #ffffff;
    margin-bottom: 40px;
    .body {
        display: flex;
        max-height: ${props => (props.active ? props.maxHeight : 0)};
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        background-color: #fff;
        .avatar {
            margin-top: 10px;
            background: ${DARK_BLUE};
            color: #fff;
            font-weight: 900;
            margin-right: 40px;
            span {
                font-size: 36px;
            }
        }
    }
    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        svg {
            cursor: pointer;
        }
    }
    .data {
        .item {
            display: flex;
            line-height: 42px;
            span {
                font-size: 16px;
                &.key {
                    color: #697998;
                    width: 177px;
                }
                &.value {
                    color: #8a94a6;
                }
            }
            .hyper-links {
                color: #4767a0;
                cursor: pointer;
            }
        }
    }
    .icons {
        display: flex;
        margin-left: auto;
        .eye-icon {
            width: 20px;
            height: 20px;
            margin-left: 20px;
        }
        svg {
            cursor: pointer;
        }
    }
    .msg-btn-text {
        font-size: 16px;
        margin-left: 5px;
    }
    .message-info {
        ${FlexCenterCol};
        margin: 120px 0 190px 0;
        .title {
            font-size: 28px;
            font-family: sanFranciscoMedium;
            color: ${DARK_BLUE};
        }
        p {
            color: #8a94a6;
            font-size: 16px;
            font-family: sanFranciscoMedium;
            line-height: 32px;
        }
    }
`
export const Notes = styled.div`
    flex-direction: column;
    .note {
        border: 1px solid ${BORDER_COLOR};
        margin-bottom: 20px;
        padding: 20px;
        .header {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            font-family: sanFranciscoSemi;
            margin-bottom: 10px;
            color: #8a94a6;
            .when {
                font-size: 16px;
                font-family: sanFrancisco;
                margin-left: 5px;
            }
            .note-icon svg {
                width: 15px;
                height: 15px;
            }
        }
    }
    .delete {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        span {
            font-size: 12px;
        }
        .delete-btn {
            margin-top: -5px;
            margin-left: 10px;
        }
    }
    .add-btn {
        span {
            font-size: 16px;
        }
        svg {
            margin-right: 5px;
        }
    }
`
export const Message = styled.div`
    margin-bottom: 20px;
    .header {
        display: flex;
        justify-content: space-between;
        padding: 20px 30px;
        background-color: #e2e5e4;
        margin-bottom: 20px;
        .title {
            font-size: 15px;
            font-weight: 500;
            color: #4c4f59;
            margin-bottom: 8px;
        }
        .sub-title {
            font-size: 12px;
            font-weight: 600;
            color: #a3a3a8;
        }
        .when {
            font-size: 12px;
            font-weight: 600;
            color: #4c4f59;
            margin-bottom: 8px;
            span + span {
                margin-left: 15px;
            }
        }
        .icons {
            text-align: right;
            svg + svg {
                margin-left: 8px;
            }
        }
        .right {
            display: flex;
            > svg {
                margin-left: 20px;
            }
        }
        .msg-btn {
            font-size: 10px;
            svg {
                width: 18px;
                height: 18px;
                g {
                    fill: ${DARK_BLUE};
                }
                margin-right: 10px;
            }
        }
    }
    .content {
        max-height: ${props => (props.active ? props.maxHeight : 0)};
        transition: max-height 0.3s ease-out;
        overflow: hidden;
    }
    .compose {
        border: solid 1px #e2e5e4;
        padding: 30px;
        .avatar {
            width: 45px;
            height: 45px;
            font-size: 12px;
            ${FlexCenterRow};
            background: ${DARK_BLUE};
            color: #fff;
            border-radius: 24px;
            margin-right: 10px;
            border-radius: 6px;
        }
        .header {
            all: unset;
            display: flex;
            justify-content: space-between;
            .flex {
                display: flex;
            }
        }
        .name {
            font-size: 16px;
            font-weight: 600;
            color: #4c4f59;
        }
        .field {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            font-size: 10px;
            color: #6b6e7f;
            label {
                width: 40px;
            }
            input {
                height: 20px;
                width: 503px;
                font-size: 10px;
                border: 1px solid #f1f1f4;
            }
        }
        .subject {
            width: 100%;
            height: 34px;
            border: 1px solid #f1f1f4;
            font-size: 14px;
            font-family: sanFranciscoMedium;
            color: #65676e;
            padding: 15px;
            margin-bottom: 15px;
        }
        .tox.tox-tinymce {
            min-height: 205px;
        }
        p {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.71;
            color: #65676e;
            white-space: pre-wrap;
            margin: 24px 0;
        }
        .divider {
            width: 492px;
            height: 1px;
            background-color: #f4f4f4;
        }
        .attachments {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            .attachment {
                display: flex;
                align-items: center;
                height: 45px;
                border-radius: 4px;
                border: solid 1px #f4f4f4;
                padding: 5px;
                svg {
                    width: 26px;
                    height: 30px;
                    margin-right: 3px;
                }
                span {
                    font-size: 10px;
                    font-weight: 500;
                    color: #a6aebc;
                    align-self: flex-end;
                }
            }
            .checkbox {
                margin-left: auto;
            }
            .ant-checkbox-wrapper {
                font-size: 10px;
                font-weight: 500;
                color: #a6aebc;
            }
        }
        .note {
            margin-top: 20px;
            .title {
                font-size: 14px;
                color: #53627c;
            }
            .desc {
                font-size: 10px;
                font-weight: 500;
                color: ${DARK_BLUE};
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        > div {
            margin-right: 0;
        }
        .add-attachment {
            border: solid 1px #a6aebc;
            color: #a6aebc;
            font-size: 10px;
            .attachment-icon {
                margin-right: 5px;
            }
        }
    }
`
export const Tags1 = styled(Tags)`
    div {
        color: #8a94a6;
        background: #f1f2f4;
        border-radius: 2px;
        font-weight: 600;
        font-family: sanFranciscoSemi;
        margin-bottom: 10px;
        span {
            padding: 7px 10px;
        }
    }
`
export const TagCard = styled.div`
    border-radius: 4px;
    width: 275px;
    box-shadow: 0 2px 10px 0 #0a1f4419, 0 0 1px 0 #0a1f4414;
    padding: 20px 20px 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    background-color: #ffffff;
    .header {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-family: sanFranciscoSemi;
        color: ${GREY};
        svg + svg {
            margin-left: 20px;
        }
    }
    .tags {
        display: flex;
        flex-wrap: wrap;
        margin: 5px -5px;
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-family: sanFranciscoSemi;
            padding: 0 9px;
            height: 25px;
            border-radius: 3px;
            background-color: #f1f2f4;
            margin: 5px;
            color: #8a94a6;
        }
    }
    a {
        font-size: 12px;
        color: ${DARK_BLUE};
    }
`
