import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { CardCollapsible, Title, Message } from '../style'
import { ReactComponent as ExpandIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Btn.svg'
import { ReactComponent as CollapseIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand or Collapse Btn.svg'
import { ReactComponent as LockIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/lock.svg'
import { ReactComponent as AttachIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/attach.svg'
import { ReactComponent as PdfIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/pdf.svg'
import { Editor } from '@tinymce/tinymce-react'
import { Checkbox } from 'antd'
import { ReactComponent as CommentIcon } from '../../../../assets/Icons/Todo/comment.svg'
import StyledButton from '../../../../container/Components/StyledButton/index.js'
import MessageBox from './MessageBox'
import { MessageView } from './Message'
import getAvatar from '../../../../services/getAvatar'
import { SendEmail, UpdateEmailPrivacy } from '../../../../services/NetworkCalls/SourcingTalentPool'
import config from '../../../../config'

const Messages = ({ candidate, allEmails, user, teamMembers, SendEmail, UpdateEmailPrivacy }) => {
    const [emails, setEmails] = useState(allEmails)
    const [newMessage, setNewMessage] = useState(false)
    const [messageDetail, setMessageDetail] = useState(null)

    const sendEmail = async data => {
        const { sentEmails } = await SendEmail(data)
        setEmails(sentEmails.emails)
        setNewMessage(false)
    }

    const updateEmailPrivacy = async (data, emailId) => {
        UpdateEmailPrivacy(emailId, data)
    }

    const setReply = email => {
        setMessageDetail(email)
        setNewMessage(true)
        window.scroll(0, 230)
    }

    const handleCancel = () => setNewMessage(false)

    return (
        <CardCollapsible>
            <div className="header">
                <Title>Messages:</Title>
                {emails.length && !newMessage ? (
                    <StyledButton
                        capitalize
                        type="inverted"
                        className="msg-btn"
                        width={'200px'}
                        height={'53px'}
                        onClick={() => setNewMessage(true)}
                    >
                        <CommentIcon />
                        <span className="msg-btn-text">New Message</span>
                    </StyledButton>
                ) : null}
            </div>
            {!newMessage && !emails.length ? (
                <div className="message-info">
                    <div className="title">Send message to {candidate.name}</div>
                    <p>
                        Start conversing with candidate to get any
                        <br />
                        additional details related to candidate profile.
                    </p>
                    <StyledButton
                        capitalize
                        type="inverted"
                        className="msg-btn"
                        width={'245px'}
                        height={'53px'}
                        onClick={() => setNewMessage(true)}
                    >
                        <CommentIcon />
                        <span className="msg-btn-text">Message Candidate</span>
                    </StyledButton>
                </div>
            ) : null}
            {newMessage && (
                <MessageBox
                    candidate={candidate}
                    user={user}
                    teamMembers={teamMembers}
                    sendEmail={sendEmail}
                    handleCancel={handleCancel}
                    messageDetail={messageDetail}
                />
            )}
            {emails.map((email, index) => (
                <MessageView
                    active={index === 0}
                    email={email}
                    user={user}
                    teamMembers={teamMembers}
                    candidate={candidate}
                    updateEmailPrivacy={updateEmailPrivacy}
                    setReply={setReply}
                />
            ))}
        </CardCollapsible>
    )
}

const mapStateToProps = state => {
    const { Auth, Mixed } = state
    return {
        user: Auth.user,
        teamMembers: Mixed.teamMembers,
    }
}

export default connect(
    mapStateToProps,
    {
        SendEmail,
        UpdateEmailPrivacy,
    }
)(Messages)
