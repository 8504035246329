import React, { useEffect, useState } from 'react'
import './style.scss'
import { Badge, Icon } from 'antd'
import classNames from 'classnames'
import FilterIcon from '../../../assets/Icons/Board/Sort by'
import MoveIcon from '../../../assets/Icons/Board/Move Icon'

export const BoardPreview = props => {
    const [tasks, handleTasks] = useState(props.tasks)

    useEffect(() => {
        if (tasks !== props.tasks) {
            handleTasks(tasks)
        }
    })

    const eachStageGenerator = tasks => {
        return tasks.map(({ id, title }) => (
            <div
                key={id}
                className={classNames({
                    'stage-column-preview-container': true,
                    'bg-color': title === 'JOINED',
                })}
            >
                <Badge count={4} className="board-preview-count" />
                <div className="board-preview-text">{title}</div>
                <Icon className="board-preview-icons" component={FilterIcon} />
                {title !== 'JOINED' && <Icon className="board-preview-icons" component={MoveIcon} />}
            </div>
        ))
    }

    const preview = eachStageGenerator(tasks)

    return <div className="stage-board-preview-container">{preview}</div>
}
