import ContentLoader from 'react-content-loader'
import React from 'react'
import { SingleFBCard } from './style'

export const FeedbackLoader = () => (
    <SingleFBCard height={'300px'}>
        <ContentLoader height={207}>
            <rect x="0" y="0" rx="1" ry="1" width="170" height="10" />
            <rect x="0" y="20" rx="1" ry="1" width="200" height="10" />
            <rect x="0" y="60" rx="1" ry="1" width="390" height="30" />

            <circle cx="25" cy="153" r="20" />
            <rect x="5" y="183" rx="1" ry="1" width="40" height="10" />

            <rect x="70" y="120" rx="1" ry="1" width="70" height="10" />
            <rect x="165" y="120" rx="1" ry="1" width="70" height="10" />
            <rect x="260" y="120" rx="1" ry="1" width="70" height="10" />

            <rect x="70" y="140" rx="1" ry="1" width="70" height="10" />
            <rect x="165" y="140" rx="1" ry="1" width="70" height="10" />
            <rect x="260" y="140" rx="1" ry="1" width="70" height="10" />

            <rect x="70" y="177" rx="1" ry="1" width="70" height="10" />
            <rect x="70" y="197" rx="1" ry="1" width="70" height="10" />
        </ContentLoader>
    </SingleFBCard>
)
