import styled from 'styled-components'

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    .ant-form-explain {
        margin-top: 5px;
    }
`

export const ModalElementWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: left;
`

export const ElementWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const InfoWrapper = styled.div`
    height: 107px;
    width: 464px;
    border-radius: 4px 4px 0 0;
    background-color: #f1f2f4;
    margin: 40px -80px 0;
    padding: 21px 70px;

    div {
        display: flex;
        height: 16px;
        color: #8a94a6;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 9px;
    }

    .anticon {
        margin-right: 10px;
    }
`
