import React from 'react'
import { MessageModal } from '../common'

export const ConfirmSourcingUnsubscribe = props => (
    <MessageModal
        title="UNSUBSCRIBE CANDIDATE SOURCING"
        message={<span>Are you sure you want to unsubscribe candidate sourcing feature?</span>}
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
        confirmText="CONFIRM"
        cancelText="CANCEL"
    />
)
