const actions = {
    SET_JOB_COORDINATORS: 'SET_JOB_COORDINATORS',
    SET_ALL_JOBS: 'SET_ALL_JOBS',
    ADD_JOB: 'ADD_JOB',
    ADD_STAGE: 'ADD_STAGE',
    DELETE_JOB: 'DELETE_JOB',
    DELETE_STAGE: 'DELETE_STAGE',
    UPDATE_JOB: 'UPDATE_JOB',
    UPDATE_STAGE: 'UPDATE_STAGE',
    UPDATE_JOBS: 'UPDATE_JOBS',
}
export default actions
