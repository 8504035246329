export const currencies = [
    {
        symbol: 'INR',
        name: 'Indian Rupee',
    },
    {
        symbol: 'USD',
        name: 'US Dollar',
    },
    {
        symbol: 'CAD',
        name: 'Canadian Dollar',
    },
    {
        symbol: 'EUR',
        name: 'Euro',
    },
    {
        symbol: 'EGP',
        name: 'Egyptian Pound',
    },
    {
        symbol: 'SGD',
        name: 'Singapore Dollar',
    },
    {
        symbol: 'GBP',
        name: 'British Pound',
    },
    {
        symbol: 'JPY',
        name: 'Japanese Yen',
    },
    {
        symbol: 'CNY',
        name: 'Chinese Renminbi Yen',
    },
    {
        symbol: 'PKR',
        name: 'Pakistani Rupees',
    },

    {
        symbol: 'R',
        name: 'South African Rand',
    },
    {
        symbol: 'PHP',
        name: 'Philippine Peso',
    },
    {
        symbol: 'SAR',
        name: 'Saudi Riyal',
    },
    {
        symbol: 'THB',
        name: 'Thai Baht',
    },
    {
        symbol: 'MYR',
        name: 'Malaysian Ringgit',
    },
    {
        symbol: 'IDR',
        name: 'Indonesian Rupiah',
    },
    {
        symbol: 'VND',
        name: 'Vietnamese Dong',
    },
    {
        symbol: 'TWD',
        name: 'Taiwanese Dollar ',
    },
    {
        symbol: 'MXN',
        name: 'Mexican Peso',
    },
]

export const currencyUnitAndValueCalculator = (maxAmount, currencyUnit, minAmount = 0) => {
    const currencyArray = []
    const noOfZeros = maxAmount ? Math.floor(Math.log10(maxAmount)) : 0

    if (currencyUnit === 'INR') {
        if (noOfZeros > 6) {
            currencyArray.push(minAmount / Math.pow(10, 7), maxAmount / Math.pow(10, 7), 'Cr')
        } else if (noOfZeros > 4) {
            currencyArray.push(minAmount / Math.pow(10, 5), maxAmount / Math.pow(10, 5), 'LPA')
        } else if (noOfZeros > 2) {
            currencyArray.push(minAmount / Math.pow(10, 3), maxAmount / Math.pow(10, 3), 'K')
        } else {
            currencyArray.push(minAmount, maxAmount, '')
        }
    } else {
        if (noOfZeros > 11) {
            currencyArray.push(minAmount / Math.pow(10, 12), maxAmount / Math.pow(10, 12), 'T')
        } else if (noOfZeros > 8) {
            currencyArray.push(minAmount / Math.pow(10, 9), maxAmount / Math.pow(10, 9), 'B')
        } else if (noOfZeros > 5) {
            currencyArray.push(minAmount / Math.pow(10, 6), maxAmount / Math.pow(10, 6), 'M')
        } else if (noOfZeros > 2) {
            currencyArray.push(minAmount / Math.pow(10, 3), maxAmount / Math.pow(10, 3), 'K')
        } else {
            currencyArray.push(minAmount, maxAmount, '')
        }
    }
    return currencyArray
}
