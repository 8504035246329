import React from 'react'
import {
    ModalHeaderWrapper,
    ModalFooterWrapper,
    FooterButtons,
    Content,
    FeedbackOpinionEditorBlock,
    StickyFooterWrapper,
    FSModalHeaderWrapper,
} from './style'
import { ReactComponent as LoadingIcon } from '../../assets/Icons/Openings/loading.svg'
import { F_OVERALL_COLLECTION } from '../Helpers/constants'
import { Icon, Tooltip } from 'antd'
import PropTypes from 'prop-types'

export const Header = ({ title, subtitle, fullscreen = false, centeredTitle = false, disabled = false }) => {
    return (
        <ModalHeaderWrapper centeredTitle={centeredTitle}>
            <div>{title}</div>
            {subtitle && <div className="subtitle">{subtitle}</div>}
            {!fullscreen && <div className="division-line" />}
        </ModalHeaderWrapper>
    )
}

export const Footer = ({
    onCancel,
    onConfirm,
    cancelText = 'CANCEL',
    confirmText = 'CONFIRM',
    hasSeparator = false,
    disable = false,
    disableCancel = true,
    width = null,
    centered,
    loadingButton = false,
}) => {
    return (
        <ModalFooterWrapper>
            <div className="separator" hidden={!hasSeparator} />
            <div className={`buttons ${centered && 'centered'}`}>
                {onCancel ? (
                    <FooterButtons type="inverted" onClick={onCancel} disable={disable && disableCancel}>
                        {cancelText}
                    </FooterButtons>
                ) : null}
                {onConfirm ? (
                    <FooterButtons
                        disable={disable}
                        width={width}
                        onClick={onConfirm}
                        loading={loadingButton && disable}
                    >
                        {confirmText}
                    </FooterButtons>
                ) : null}
            </div>
        </ModalFooterWrapper>
    )
}

export const MessageModal = ({
    onCancel,
    onConfirm,
    title,
    message,
    cancelText,
    confirmText,
    width = null,
    disabled = false,
}) => {
    return (
        <>
            <Header title={title} />
            <Content>{message}</Content>
            <Footer
                onCancel={onCancel}
                onConfirm={onConfirm}
                cancelText={cancelText}
                confirmText={confirmText}
                disable={disabled}
                width={width}
            />
        </>
    )
}

export const MessageWithIconModal = props => {
    return (
        <MessageModal
            {...props}
            message={
                <div className="message-with-icon-modal-wrapper">
                    <div className="message-with-icon-modal-wrapper-icon">{props.modalIcon}</div>
                    <div className="message-with-icon-modal-wrapper-msg">{props.message}</div>
                </div>
            }
        />
    )
}

MessageWithIconModal.propTypes = {
    modalIcon: PropTypes.element.isRequired,
}

export const FSHeader = ({ title, subtitle, fullscreen = false }) => {
    return (
        <FSModalHeaderWrapper>
            <div className="title">{title}</div>
            {subtitle && <div className="subtitle">{subtitle}</div>}
            {!fullscreen && <div className="division-line" />}
        </FSModalHeaderWrapper>
    )
}

export const StickyFooter = ({
    onCancel,
    onConfirm,
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    disabled = false,
}) => {
    return (
        <StickyFooterWrapper>
            <FooterButtons type="inverted" onClick={onCancel}>
                {cancelText}
            </FooterButtons>
            <FooterButtons onClick={onConfirm} disabled={disabled}>
                {confirmText}
            </FooterButtons>
        </StickyFooterWrapper>
    )
}

export const FeedbackOpinionEditor = ({ points, showText = true, size, onChange, showPopUp = false }) => {
    const saved_opinion = points
    return F_OVERALL_COLLECTION.map(opinion => {
        const { name, text, value, inActiveIcon, activeIcon } = opinion
        const isActive = saved_opinion === value

        const MainRenderComponent = (
            <FeedbackOpinionEditorBlock isActive size={size} key={name} onClick={() => onChange(value)}>
                <Icon className="icon-image" component={isActive ? activeIcon : inActiveIcon} />
                {showText ? <p>{text}</p> : null}
            </FeedbackOpinionEditorBlock>
        )

        if (showPopUp)
            return (
                <Tooltip mouseEnterDelay={0.5} title={text} key={name}>
                    {MainRenderComponent}
                </Tooltip>
            )
        else return MainRenderComponent
    })
}
