import React, { Component } from 'react'
import { ConnectRequest, ConnectSuccess, SetAvailability, Availability } from './pages'
import SidePanel from './SidePanel'
import { Popup } from './style'
import './style.scss'

export default class CalendarOnBoarding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 0,
        }
    }

    componentDidMount() {
        this.setState({
            step: (this.props.calPopUpStatus && this.props.calPopUpStatus.step) || 1,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            step: (nextProps.calPopUpStatus && nextProps.calPopUpStatus.step) || 1,
        })
    }

    changeStep = newStep => {
        this.setState({
            step: newStep,
        })
    }

    componentWrapper(ReactCompnent, success, message) {
        const { step } = this.state
        return (
            <Popup>
                <SidePanel step={step} />
                <ReactCompnent
                    changeStep={this.changeStep}
                    success={success}
                    cancel={this.props.onCancel}
                    redirectPath={this.props.redirectPath}
                    message={message}
                />
            </Popup>
        )
    }

    renderComponent(step, message) {
        switch (step) {
            case 1:
                return this.componentWrapper(ConnectRequest)
            case 2:
                return this.componentWrapper(ConnectSuccess)
            case 3:
                return this.componentWrapper(SetAvailability)
            case 4:
                return this.componentWrapper(Availability, true)
            case 5:
                return this.componentWrapper(Availability, false, message)
            default:
                return null
        }
    }

    render() {
        const { calPopUpStatus } = this.props
        let message = ''
        if (calPopUpStatus) message = calPopUpStatus.message
        return this.renderComponent(this.state.step, message)
    }
}
