import React from 'react'
import { FilterContainer, Heading, Card, Body, Item, CloseFilter } from '../style'
import { Checkbox, Radio, Spin } from 'antd'
import { connect } from 'react-redux'
import '../style.scss'
import { GetList, GetCount } from '../../../../services/NetworkCalls/Todo'
import _ from 'lodash'

class Filter extends React.Component {
    state = {
        type: 'forMe',
        filter: {
            upcoming: true,
            feedbackPending: false,
            canceled: false,
            completed: false,
            rescheduled: false,
        },
    }

    formatCount = count => {
        if (!count) return '00'
        return count < 10 ? `0${count}` : count
    }

    handleFilterChange = ({ target: { checked, value: type } }) => {
        let { filter } = this.state
        Object.keys(filter).forEach(key => {
            if (key === type) filter[key] = checked
        })
        this.setState({ filter }, () => {
            this.props.updateFilter({ type, checked })
            const filterKeys = Object.keys(this.state.filter)
                .filter(key => key !== 'rescheduled' && this.state.filter[key])
                .join(',')
            const rescheduled = this.state.filter['rescheduled']
            this.props.GetCount({ refineType: this.props.refineType, rescheduled })
            this.props.GetList({
                type: this.state.type,
                filter: filterKeys,
                rescheduled,
                refineType: this.props.refineType,
            })
        })
    }

    handleTypeChange = ({ target: { value } }) => {
        this.setState({ type: value }, () => {
            this.props.updateAllOrMeFilter(value)
            this.props.GetList({
                type: value,
                filter: Object.keys(this.state.filter)
                    .filter(key => key !== 'rescheduled' && this.state.filter[key])
                    .join(','),
                rescheduled: this.state.filter['rescheduled'],
                refineType: this.props.refineType,
            })
        })
    }

    render() {
        const {
            upcoming = null,
            feedbackPending = null,
            rescheduled = null,
            canceled = null,
            completed = null,
        } = this.props.dataCount

        const isEmpty =
            upcoming === null &&
            feedbackPending === null &&
            rescheduled === null &&
            canceled === null &&
            completed === null
        const FILTERS = [
            {
                key: 1,
                name: 'Upcoming',
                value: 'upcoming',
                count: this.formatCount(upcoming),
            },
            {
                key: 2,
                name: 'Feedback Pending',
                value: 'feedbackPending',
                count: this.formatCount(feedbackPending),
            },
            {
                key: 3,
                name: 'Canceled',
                value: 'canceled',
                count: this.formatCount(canceled),
            },
            {
                key: 4,
                name: 'Completed',
                value: 'completed',
                count: this.formatCount(completed),
            },
        ]
        return (
            <FilterContainer hidden={this.props.isMobileDevice && !this.props.mobileFilter}>
                <Heading>
                    <div>Filter by</div>
                    <CloseFilter hidden={!this.props.isMobileDevice} onClick={() => this.props.showMobileFilter(false)}>
                        CLOSE
                    </CloseFilter>
                </Heading>
                <Spin spinning={isEmpty}>
                    <Card filters>
                        <Body filters>
                            <Radio.Group
                                onChange={this.handleTypeChange}
                                value={this.state.type}
                                size="large"
                                style={{ display: 'inline-block' }}
                            >
                                <Radio value="all" className="radio-filter">
                                    <span className="label">All Interviews</span>
                                    <span className="count">{this.formatCount(this.props.allTotal)}</span>
                                </Radio>
                                <Radio value="forMe" className="radio-filter">
                                    <span className="label">Scheduled for me</span>
                                    <span className="count">{this.formatCount(this.props.forMeTotal)}</span>
                                </Radio>
                                <Radio value="byMe" className="radio-filter">
                                    <span className="label">Scheduled by me</span>
                                    <span className="count">{this.formatCount(this.props.byMeTotal)}</span>
                                </Radio>
                            </Radio.Group>
                            <hr />
                            {FILTERS.map(filter => (
                                <Item key={filter.key}>
                                    <Checkbox
                                        defaultChecked={Object.keys(this.state.filter)
                                            .filter(key => this.state.filter[key])
                                            .includes(filter.value)}
                                        onClick={filter.handleEvent}
                                        onChange={this.handleFilterChange}
                                        value={filter.value}
                                    >
                                        {filter.name}
                                    </Checkbox>
                                    <span className="count">{filter.count}</span>
                                </Item>
                            ))}
                            <hr />
                            <Item>
                                <Checkbox onChange={this.handleFilterChange} value={'rescheduled'}>
                                    Rescheduled
                                </Checkbox>
                                <span className="count">{this.formatCount(rescheduled)}</span>
                            </Item>
                        </Body>
                    </Card>
                </Spin>
            </FilterContainer>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const {
        Todo: { dataCount },
    } = state
    if (_.isEmpty(dataCount)) return { dataCount: {}, allTotal: 0, forMeTotal: 0, byMeTotal: 0 }
    return {
        dataCount: dataCount[ownProps.type] || {},
        allTotal: (dataCount['all'] && dataCount['all'].total) || 0,
        forMeTotal: (dataCount['forMe'] && dataCount['forMe'].total) || 0,
        byMeTotal: (dataCount['byMe'] && dataCount['byMe'].total) || 0,
    }
}

export default connect(
    mapStateToProps,
    { GetList, GetCount }
)(Filter)
