import React, { Component } from 'react'
import { Input, Button, Tooltip } from 'antd'
import ComponentContainer from '../ComponentContainer'
import { Footer } from '../../../../Modals/common'
import { SaveDoSelectCredentials, GetDoSelectTests } from '../../../../../services/api'
import { success, warning } from '../../../../../services/Notification'
import { Modal } from '../../../../Modals'
import { MODAL_TYPES } from '../../../../Helpers/ModalTypes'
import { ReactComponent as InfoIcon } from '../../../../../assets/Icons/Interview/info.svg'
import { DoselectContainer, DoSelectEditPass, DoSelectWebHook, DoSelectMessage, WebHookContent } from './style.js'

class DoSelectDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            apiKey: props.apiKey || '',
            apiSecret: props.apiSecret || '',
            doSelectEdit: false,
            showDoSelectWarningModal: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { apiKey, apiSecret } = this.props

        if (apiKey !== prevProps.apiKey) this.setState({ apiKey })
        if (apiSecret !== prevProps.apiSecret) this.setState({ apiSecret })
    }

    handleChange = ({ target: { name, value } }) => {
        this.setState({ [name]: value })
    }

    handleCancel = () => {
        this.setState({
            doSelectEdit: false,
            showDoSelectWarningModal: false,
            apiKey: this.props.apiKey,
            apiSecret: this.props.apiSecret,
        })
    }

    setDoSelectEdit = () => {
        this.setState({ doSelectEdit: true })
    }

    copyWebhookUrl = () => {
        const str = `https://api.springrecruit.com/api/v1/doSelect/webhook/${this.props.companyId}`
        const message = 'The webhook URL has been copied to clipboard'

        const el = document.createElement('textarea')
        el.value = str
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        success(message)
    }

    setDoSelectWarningModal = () => {
        this.setState({ showDoSelectWarningModal: true })
    }

    hasDoSelectCredentials = () => {
        this.props.hasDoSelectCredentials ? this.setDoSelectWarningModal() : this.saveDoselectCredentials()
    }

    saveDoselectCredentials = async () => {
        try {
            const { apiKey, apiSecret } = this.state
            const doSelectCredential = {
                apiKey,
                apiSecret,
            }
            await SaveDoSelectCredentials({ doSelectCredential })
            GetDoSelectTests()
            this.setState({
                doSelectEdit: false,
                showDoSelectWarningModal: false,
            })
        } catch (err) {
            warning(err.message || err)
        }
    }

    render() {
        return (
            <>
                {this.props.accessLevel < 3 ? (
                    <ComponentContainer
                        heading={
                            <div style={{ width: '200px', display: 'flex' }}>
                                <p style={{ marginRight: '15px', marginTop: '-3px' }}>DoSelect Integration</p>
                                <Tooltip
                                    placement="top"
                                    title="Integrate DoSelect to find the right person for your technical roles quickly and easily by setting up tests to check a candidate' s technical abilities so you can make smart hiring choices."
                                    overlayClassName="fs-10"
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </div>
                        }
                        headingOptions={
                            <DoSelectEditPass>
                                <span onClick={() => this.setDoSelectEdit()}>
                                    <img src={require('./images/edit.svg').default} />
                                    Edit
                                </span>
                            </DoSelectEditPass>
                        }
                    >
                        {this.state.doSelectEdit || this.state.apiKey ? (
                            <DoselectContainer>
                                <div className="details">
                                    <div className="label">
                                        <span>API Key</span>
                                        <span>API Secret</span>
                                    </div>
                                    {!this.state.doSelectEdit ? (
                                        <div className="label values">
                                            <span>{this.state.apiKey}</span>
                                            <span>{this.state.apiKey.replace(/[a0-z9]/gi, '*')}</span>
                                        </div>
                                    ) : (
                                        <div className="detailsInputs">
                                            <Input
                                                size="large"
                                                placeholder="Enter API Key"
                                                value={this.state.apiKey}
                                                onChange={this.handleChange}
                                                name="apiKey"
                                                className="detailsInputField"
                                            />
                                            <Input
                                                size="large"
                                                placeholder="Enter API Secret"
                                                value={this.state.apiSecret}
                                                onChange={this.handleChange}
                                                name="apiSecret"
                                                className="detailsInputField"
                                            />
                                        </div>
                                    )}
                                </div>
                                {!this.state.doSelectEdit && this.state.apiKey ? (
                                    <DoSelectWebHook>
                                        <WebHookContent>
                                            <h5>Enter the webhook url in your DoSelect account</h5>
                                            <a>
                                                https://api.springrecruit.com/api/v1/doSelect/webhook/
                                                {this.props.companyId}
                                            </a>
                                        </WebHookContent>
                                        <div className="webHookCopyIcon">
                                            <img
                                                src={require('./images/copy-icon.svg').default}
                                                onClick={() => this.copyWebhookUrl()}
                                            />
                                        </div>
                                    </DoSelectWebHook>
                                ) : null}

                                {this.state.doSelectEdit ? (
                                    <Footer
                                        onCancel={this.handleCancel}
                                        onConfirm={this.hasDoSelectCredentials}
                                        cancelText="CANCEL"
                                        confirmText="SAVE"
                                    />
                                ) : null}
                            </DoselectContainer>
                        ) : (
                            <DoSelectMessage>
                                Enter your DoSelect keys to start assigning test to the candidates.
                            </DoSelectMessage>
                        )}
                    </ComponentContainer>
                ) : null}

                <Modal
                    type={MODAL_TYPES.WARNING_DOSELECT}
                    visible={this.state.showDoSelectWarningModal}
                    onCancel={this.handleCancel}
                    onConfirm={this.saveDoselectCredentials}
                />
            </>
        )
    }
}

export default DoSelectDetails
