import styled from 'styled-components'
import { Layout } from 'antd'
const { Footer } = Layout


export const FooterBar = styled(Footer)`
    height: 64px;
    box-shadow: 0 1px 4px 0 rgba(10, 31, 68, 0.1);
    background-color: #1c2234;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
        color: #999;
        padding: 0 10px;
        cursor: pointer;
        border-right: 1px solid #9996;
        :hover {
            color: #fff;
        }
        :last-child {
            border: none;
        }
    }

    @media only screen and (max-width: 769px) {
        display: block;
        height: unset;
        padding: 15px;
        font-size: 10px;

        a {
            width: 100%;
            display: block;
            border: 0;
            text-align: center;
            line-height: 20px;
        }
    }
`
