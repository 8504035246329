import React from 'react'
import TalentPoolCard from './TalentPoolCard'
import { Button } from 'antd'
import isEqual from 'lodash.isequal'

export default class InfiniteHits extends React.Component {
    componentDidMount() {
        this.props.setHits(this.props.hits)
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.hits, this.props.hits)) this.props.setHits(this.props.hits)
    }

    listRenderer = () => {
        return this.props.hits.length > 0
            ? this.props.hits.map(hit => (
                  <TalentPoolCard
                      hit={hit}
                      key={hit.objectID}
                      refresh={this.props.refresh}
                      eventManager={this.props.eventManager}
                      selected={this.props.selected}
                      renderList={this.props.hits}
                      bulkMode={this.props.bulkMode}
                      allSelectList={this.props.allSelectList}
                      clickedOnce={this.props.clickedOnce}
                      updateCandidate={(value, jobPayload) => this.props.updateCandidate(value, jobPayload)}
                      handleBulkCancel={this.props.handleBulkCancel}
                      handleUpdateResume={(fileCheck, objectID, companyId) =>
                          this.props.handleUpdateResume(fileCheck, objectID, companyId)
                      }
                  />
              ))
            : null
    }

    render() {
        const { hasMore, refineNext } = this.props
        return (
            <div className="tc-cards">
                {this.listRenderer()}
                {hasMore && (
                    <Button className="tp-show-more" onClick={refineNext}>
                        Show more
                    </Button>
                )}
            </div>
        )
    }
}
