import React from 'react'
import { Select, AutoComplete, Input, Icon } from 'antd'
import './style.css'

class GlobalSearch extends React.Component {
    state = {
        inputValue: '',
    }
    render() {
        const candidates = this.props.candidates.map(can => (
            <Select.Option
                key={can.objectID}
                className="topbar-serach-option"
                label={can.name}
                onClick={() => this.props.onSelect(can)}
            >
                <div className="topbar-search-candidateName">{can.name}</div>
                {can.jobTitle ? (
                    <div className="topbar-search-stages">
                        {can.stageName} | {can.jobTitle}
                    </div>
                ) : (
                    <div className="topbar-search-stages">Unassigned</div>
                )}
            </Select.Option>
        ))

        return (
            <AutoComplete
                className="topbar-search"
                size="large"
                dataSource={candidates}
                placeholder="Search candidates"
                optionLabelProp="title"
                value={this.state.inputValue}
                onChange={query => {
                    this.setState({ inputValue: query })
                    this.props.onSearch(query)
                }}
                onBlur={() => this.setState({ inputValue: '' })}
            >
                <Input
                    onFocus={() => this.props.handleFocus(true)}
                    onBlur={() => this.props.handleFocus(false)}
                    ref={this.props.reference}
                    suffix={<Icon type="search" />}
                />
            </AutoComplete>
        )
    }
}

export default GlobalSearch
