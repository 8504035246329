import React, { useState, useEffect } from 'react'
import { ReactComponent as ReportIcon } from '../../../../assets/Icons/Openings/report.svg'

const Success = props => {
    return (
        <div className="status img">
            <ReportIcon />
        </div>
    )
}

export default Success
