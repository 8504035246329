import React from 'react'
import { MessageModal } from '../common'

export const ConfirmDeleteJob = props => (
    <MessageModal
        title="DELETE JOB OPENING"
        message={
            <span>
                Are you sure you want to Delete{' '}
                {props.data ? <b>{props.data.jobTitle || 'Selected Job'} </b> : <b> Selected Job </b>} opening ?
            </span>
        }
        onConfirm={() => props.onConfirm(props.data._id)}
        onCancel={props.onCancel}
        confirmText="DELETE"
        cancelText="CANCEL"
    />
)
