import React, { Component } from 'react'
import { Select, Form } from 'antd'
import { warning } from '../../../../../services/Notification'
import { CANDIDATE } from '../../../../Helpers/RegEx'
import { ReactComponent as TickIcon } from '../../../../../assets/Icons/Interview/tick.svg'
import { Help } from '../style'

import './style.css'
import { connect } from 'react-redux'
import { FORM_CONFIGS } from '../../Constants'
import { ACCESS_LEVEL_DEFINITIONS } from '../../../../Helpers/constants'
import { FSModal } from '../../../../Modals'
import { FSMODAL_TYPES } from '../../../../Helpers/ModalTypes'
import { AddTeamMember } from '../../../../../services/NetworkCalls/User'

const { Option } = Select
const { ITEM_INTERVIEWERS } = FORM_CONFIGS

class Interviewer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hideInvite: true,
            inviteMember: null,
            showInviteModal: false,
            showInvitedMessage: false,
            showSuggestions: false,
            notificationShown: false,
            callInProgress: false,
        }
    }

    hideInvitedMessage = () => {
        this.setState({ showInvitedMessage: false })
    }

    handleSearch = value => {
        if (this.props.interviewers.length < ITEM_INTERVIEWERS.MAX) {
            if ([1, 2].includes(this.props.accessLevel)) {
                const found = this.props.coordinators.find(member => member.email === value)
                if (!found && CANDIDATE.EMAIL.test(value)) {
                    this.setState({
                        hideInvite: false,
                        inviteMember: {
                            email: value,
                            accessLevel: ACCESS_LEVEL_DEFINITIONS.INTERVIEW_ONLY.accessLevel,
                        },
                    })
                } else this.setState({ hideInvite: true, inviteMember: null })
            } else {
                this.setState({ hideInvite: true, inviteMember: null })
            }
        } else {
            if (!this.state.notificationShown) {
                warning(ITEM_INTERVIEWERS.ERROR_NOTI_MAX, null, { style: { bottom: 30 } })
                this.setState({ notificationShown: true }, () => {
                    setTimeout(() => this.setState({ notificationShown: false }), 4500)
                })
            }
        }
    }

    handleChange = values => {
        this.setState({ showSuggestions: false }, () => {
            let interviewerIds = this.props.coordinators
                .filter(coordinator => values.map(value => value.key).includes(coordinator._id))
                .map(interviewer => interviewer._id)
            this.props.handleInterviewerChange(interviewerIds)
            this.interviewerSelect.blur()
        })
    }

    onInviteClick = () => {
        this.setState({ showInviteModal: true })
    }

    handleCancel = () => {
        this.setState({ showInviteModal: false, inviteMember: null })
    }

    handleInviteSubmit = ({ email, accessibleJobs, accessLevel }) => {
        this.setState({ callInProgress: true }, async () => {
            let details = {
                email,
                accessibleJobs,
                accessLevel,
                userId: this.props.userId,
                companyId: this.props.companyId,
            }
            const member = await this.props.AddTeamMember(details)
            this.setState({ callInProgress: false, hideInvite: true, showSuggestions: false }, () => {
                this.setState({ showInviteModal: false, inviteMember: null, showInvitedMessage: true }, () => {
                    setTimeout(this.hideInvitedMessage, 5000)
                })
                if (member && member.data.user) {
                    this.props.handleInterviewerChange([...this.props.interviewers, member.data.user._id])
                    this.props.form.setFieldsValue({
                        interviewerIds: [
                            ...this.props.coordinators
                                .filter(coordinator => this.props.interviewers.includes(coordinator._id))
                                .map(coordinator => ({
                                    key: coordinator._id,
                                    label: coordinator.fullName || coordinator.email,
                                })),
                            { key: member.data.user._id, label: member.data.user.email },
                        ],
                    })
                } else {
                    this.props.handleInterviewerChange([...this.props.interviewers])
                    this.props.form.setFieldsValue({
                        interviewerIds: [
                            ...this.props.coordinators
                                .filter(coordinator => this.props.interviewers.includes(coordinator._id))
                                .map(coordinator => ({
                                    key: coordinator._id,
                                    label: coordinator.fullName || coordinator.email,
                                })),
                        ],
                    })
                }
            })
        })
    }

    handleBlur = () => {
        this.setState({ hideInvite: true, showSuggestions: false })
    }

    interviewerValidator = (rule, value, callback) => {
        if (value.length < 1) {
            callback(ITEM_INTERVIEWERS.ERROR)
        }

        if (value.length > ITEM_INTERVIEWERS.MAX) callback(ITEM_INTERVIEWERS.ERROR_MAX)
        callback()
    }

    render() {
        let { getFieldDecorator } = this.props.form
        let options = this.props.coordinators.filter(
            mem => mem.status !== 2 && !this.props.interviewers.includes(mem._id)
        )
        let initialValue = this.props.coordinators
            .filter(mem => mem.status !== 2 && this.props.interviewers.includes(mem._id))
            .map(option => ({
                key: option._id,
                label: option.fullName || option.email,
            }))
        return (
            <div className="interviewer">
                <Form.Item>
                    {getFieldDecorator('interviewerIds', {
                        initialValue,
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{ validator: this.interviewerValidator }],
                    })(
                        <Select
                            ref={select => (this.interviewerSelect = select)}
                            dropdownClassName="interviewer-select"
                            labelInValue
                            onBlur={this.handleBlur}
                            showSearch
                            size="large"
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Select interviewer(s)"
                            onChange={this.handleChange}
                            optionLabelProp="label"
                            filterOption={(input, { props }) => {
                                return (
                                    props.label.toLowerCase().includes(input.toLowerCase()) ||
                                    props.email.toLowerCase().includes(input.toLowerCase())
                                )
                            }}
                            onSearch={this.handleSearch}
                            maxTagCount={ITEM_INTERVIEWERS.MAX}
                            getPopupContainer={trigger => trigger.parentNode}
                            open={this.state.showSuggestions}
                            onInputKeyDown={() => this.setState({ showSuggestions: true })}
                        >
                            {options.map(option => {
                                return (
                                    <Option
                                        key={option._id}
                                        label={option.fullName || option.email}
                                        email={option.email}
                                        value={option._id}
                                        disabled={this.props.interviewers.length >= ITEM_INTERVIEWERS.MAX}
                                    >
                                        <div className="option">
                                            <span>{option.fullName}</span>
                                            <span className="email">{option.email}</span>
                                        </div>
                                    </Option>
                                )
                            })}
                        </Select>
                    )}
                </Form.Item>
                <button className="invite" hidden={this.state.hideInvite} onMouseDown={this.onInviteClick}>
                    Invite
                </button>
                <Help hidden={!this.state.showInvitedMessage}>
                    <TickIcon /> Invite sent successfully
                </Help>
                <FSModal
                    type={FSMODAL_TYPES.INVITE_TEAM_MEMBER}
                    visible={this.state.showInviteModal}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleInviteSubmit}
                    selectedUser={this.state.inviteMember}
                    callInProgress={this.state.callInProgress}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        coordinators: state.Mixed.teamMembers,
        accessLevel: state.Auth.user.accessLevel,
        userId: state.Auth.user.id,
        companyId: state.Auth.user.companyId,
    }
}

export default connect(
    mapStateToProps,
    { AddTeamMember }
)(Form.create({})(Interviewer))
