import styled from 'styled-components'
import { GREY, BORDER_COLOR, LIGHT_GREY, RED, DARK_BLUE } from '../../Helpers/Stylesheet/styledComponents'
export const Body = styled.div`
    margin: 30px 0;
    .ant-select-auto-complete.ant-select .ant-input {
        width: 260px;
        height: 42px;
        color: ${GREY};
        font-size: 12px;
    }
    .ant-select-selection__placeholder {
        font-size: 12px;
        margin-top: -5px;
    }

    .help {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 12px;
        color: ${LIGHT_GREY};
        svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }
    .confirm {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: ${GREY};
        margin-top: 10px;
    }
    .error {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        p {
            font-size: 12px;
            color: ${RED};
            margin-left: 5px;
            margin-top: -3px;
        }
    }
    .bulk-warning {
        display: flex;
        flex-direction: row;
        padding-top: 30px;
        border-top: 1px solid ${BORDER_COLOR};
        .tag-icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }
        .warning {
            color: ${GREY};
            line-height: 16px;
            font-size: 12px;
            a {
                color: ${DARK_BLUE};
            }
        }
    }
`
export const GreyTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${props => (props.noMargins ? '' : 'margin: 20px -5px')};
    font-size: 12px;
    color: ${GREY};
    max-height: 220px;

    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;

    > div {
        ${props => (props.noMargins ? '' : 'margin: 5px;')};
    }
    > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        padding: 0 10px;
        height: 30px;
        border-radius: 5px;
        background-color: #eeeeee;
        margin: 5px;
        &.show {
            background: initial;
            padding: 0;
        }
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        svg {
            margin-left: 10px;
            path {
                fill: ${GREY};
            }
        }
    }
    @media (max-width: 767px) {
        justify-content: center;
    }
`
