import React from 'react'
import { Content, Buttons, Button, Google, Item, Message } from '../style'
import { ReactComponent as GoogleIcon } from '../../../../assets/Icons/Calendar/google_icon.svg'
import { ReactComponent as TickIcon } from '../../../../assets/Icons/Calendar/tick.svg'
import { ReactComponent as CrossIcon } from '../../../../assets/Icons/Calendar/cross.svg'
import { ReactComponent as WarnIcon } from '../../../../assets/Icons/Calendar/Warning.svg'
import { initiateGoogleAuth } from './../../../Helpers/calendarPopUp'
import { Btn } from '../../../Pages/ScheduleInterview/Form/Address/style'
import { history } from '../../../../redux/store'

const Availability = props => {
    const googleAuthInitiate = () => {
        history.replace(window.location.pathname)
        initiateGoogleAuth(window.location.pathname.substring(1))
    }

    const { message } = props
    let _message = (
        <>
            <CrossIcon className="tick" />
            <Message small>Your calendar access has been denied</Message>
        </>
    )
    let submessage = (
        <p>
            Please make sure to accept SpringRecruit’s calendar
            <br />
            access to connect your calendar
        </p>
    )
    if (message && message.includes('|')) {
        _message = (
            <>
                <WarnIcon className="tick" />
                <Message small>{message.split('|')[0]}</Message>
            </>
        )
        submessage = <p>{message.split('|')[1]}</p>
    } else if (message && message !== 'access_denied') {
        submessage = <p>{message}</p>
    }

    return (
        <Content center>
            {props.success ? (
                <>
                    <TickIcon className="tick" />
                    <Message small>Your availability has been saved</Message>
                    <Item small>
                        <p>HR / Recruiters will be able to schedule interviews within these slots</p>
                    </Item>
                    <Btn
                        primary
                        onClick={() => {
                            history.replace(window.location.pathname)
                            props.cancel()
                        }}
                    >
                        Continue
                    </Btn>
                </>
            ) : (
                <>
                    {_message}
                    <Item small center>
                        {submessage}
                    </Item>
                    <Buttons onClick={googleAuthInitiate}>
                        <Google>
                            <GoogleIcon />
                        </Google>
                        <Button>Connect with Google</Button>
                    </Buttons>
                </>
            )}
        </Content>
    )
}

export default Availability
