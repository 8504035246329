import React, { useState } from 'react'
import { CardCollapsible, Title, Tags1 } from '../style'
import { SkillsLoader } from './Loaders'
import { ReactComponent as ExpandIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand Btn.svg'
import { ReactComponent as CollapseIcon } from '../../../../assets/Icons/CandidateProfile-Full Details+Unscreened/Expand or Collapse Btn.svg'
import { Tooltip } from 'antd'

const Skills = ({ keySkills = [], isLoading }) => {
    const [isActive, setIsActive] = useState(true)
    return (
        <>
            <CardCollapsible active={isActive} maxHeight={'300px'}>
                <div className="header">
                    <Title>Skills:</Title>
                    {isActive ? (
                        <CollapseIcon onClick={() => setIsActive(false)} />
                    ) : (
                        <ExpandIcon onClick={() => setIsActive(true)} />
                    )}
                </div>
                {isLoading ? (
                    <SkillsLoader />
                ) : (
                    <div className="body">
                        <Tags1>
                            {keySkills.map(skill => (
                                <div key={skill.skillId}>
                                    <span>{skill.name}</span>
                                </div>
                            ))}
                        </Tags1>
                    </div>
                )}
            </CardCollapsible>
        </>
    )
}

export default Skills
