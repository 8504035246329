import { warning } from '../../../services/Notification'

/*
 * When Error found return False
 * */
export const feedbackDataValidator = (feedback, customSkills) => {
    if (feedback.noShow) {
        return true
    }
    if (feedback.overallPoints === 0) {
        warning('Overall opinion is required')
        return false
    }
    if (feedback.overallFeedback.trim() === '') {
        warning('Feedback text is required')
        return false
    }

    // if (feedback.communication === 0) {
    //     warning('Rating for Communication is required')
    //     return false
    // }

    // if (feedback.attitude === 0) {
    //     warning('Rating for Attitude is required')
    //     return false
    // }

    // if (feedback.learningPotential === 0) {
    //     warning('Rating for Potential To Learn is required')
    //     return false
    // }
    // if (feedback.technicalSkills === 0) {
    //     warning('Rating for Technical Skills is required')
    //     return false
    // }

    // for (let skill of customSkills) {
    //     if (skill.required && skill.status && !skill.ans) {
    //         warning(`${skill.name} is required`)
    //         return false
    //     }
    // }

    return true
}
