import React, { Component } from 'react'
import { Footer, Header } from '../common'
import SingleFeedback from './SingleFeedback'
import EmptyFeedback from './EmptyFeedback'
import { ContentWrapper } from '../style'
import { connect } from 'react-redux'
import { EditFeedback, GetFeedbacksForCandidate, DeleteFeedback } from '../../../services/NetworkCalls/Feedback'
import PropTypes from 'prop-types'
import { warning } from '../../../services/Notification'
import { FeedbackWrapper } from './style'
import { feedbackDataValidator } from '../../Helpers/Validations/Feedback'
import { FeedbackLoader } from './Loaders'

class ViewFeedbacks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            feedbackEditingId: null,
            feedbacks: null,
        }
    }

    async componentDidMount() {
        const feedbacks = await this.props.GetFeedbacksForCandidate(this.props.candidateId)
        this.setState({ feedbacks })
    }

    handleCancel = () => {
        if (this.state.feedbackEditingId !== null) warning('You have unsaved feedback in the list')
        else this.props.onCancel()
        return null
    }

    setFeedbackEditingId = feedbackEditingId => {
        this.setState({ feedbackEditingId })
    }

    saveEditChanges = async feedback => {
        // simply close the edit mode
        if (!feedbackDataValidator(feedback, feedback.customFields)) return null

        let { feedbacks } = this.state
        const result = await this.props.EditFeedback({
            ...feedback,
            _id: this.state.feedbackEditingId,
            userId: this.props.userId,
        })

        feedbacks = feedbacks.map(feedback => {
            if (feedback._id === result._id) return result
            else return feedback
        })
        this.setState({ feedbacks, feedbackEditingId: null })
    }

    deleteFeedback = async index => {
        const { feedbacks } = this.state
        const { _id } = feedbacks[index]
        const result = await this.props.DeleteFeedback(_id)
        // we close the modal when there are not feedback present while delete is happening.
        if (feedbacks.length === 1) this.props.onCancel()
        else {
            feedbacks.splice(index, 1)
            this.setState({ feedbacks, feedbackEditingId: null })
        }
    }

    render() {
        const { feedbackEditingId, feedbacks } = this.state
        const { userId, allJobs, teamMembers } = this.props
        let tempChosenMember = null

        if (feedbacks && feedbacks.length) {
            const [firstFeedback] = feedbacks
            const { jobTitle, stages } =
                (firstFeedback.job && allJobs.find(job => job._id === firstFeedback.job._id)) || {}
            const { stageName } = (stages && stages.find(stage => stage._id === firstFeedback.interviewStage._id)) || {}

            return (
                <>
                    <Header title="CANDIDATE FEEDBACK" />
                    <FeedbackWrapper>
                        {feedbacks.map(
                            (
                                {
                                    _id,
                                    communication,
                                    attitude,
                                    learningPotential,
                                    technicalSkills,
                                    overallPoints,
                                    overallFeedback,
                                    customFields,
                                    user: { _id: addedBy } = { addedBy: null },
                                    createdAt,
                                },
                                index
                            ) => (
                                <SingleFeedback
                                    key={_id}
                                    data={{
                                        id: _id,
                                        feedback: {
                                            communication,
                                            attitude,
                                            learningPotential,
                                            technicalSkills,
                                            overallPoints,
                                            overallFeedback,
                                            customFields,
                                        },
                                        index,
                                        jobTitle,
                                        stageName,
                                        addedByLabel: addedBy
                                            ? ((tempChosenMember = teamMembers.find(mem => mem._id === addedBy)),
                                              tempChosenMember.fullName || tempChosenMember.email)
                                            : 'DoSelect',
                                        isAddedByMe: addedBy === userId,
                                        createdAt,
                                    }}
                                    setFeedbackEditingId={this.setFeedbackEditingId}
                                    feedbackEditingId={feedbackEditingId}
                                    saveEditChanges={this.saveEditChanges}
                                    deleteFeedback={this.deleteFeedback}
                                />
                            )
                        )}
                    </FeedbackWrapper>
                    <Footer hasSeparator onCancel={this.handleCancel} />
                </>
            )
        } else
            return (
                <>
                    <Header title="CANDIDATE FEEDBACK" />
                    <ContentWrapper>
                        {feedbacks && feedbacks.length === 0 ? (
                            <EmptyFeedback />
                        ) : (
                            <FeedbackWrapper>
                                <FeedbackLoader />
                                <FeedbackLoader />
                            </FeedbackWrapper>
                        )}
                    </ContentWrapper>
                    <Footer hasSeparator onCancel={this.handleCancel} />
                </>
            )
    }
}

ViewFeedbacks.propTypes = {
    candidateId: PropTypes.string.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    const {
        Auth,
        Job: { allJobs },
        Mixed: { teamMembers },
    } = state
    return {
        userId: Auth.user.id,
        allJobs,
        teamMembers,
    }
}

export default connect(
    mapStateToProps,
    { EditFeedback, GetFeedbacksForCandidate, DeleteFeedback }
)(ViewFeedbacks)
