export default {
    dashboardCardTitle: {
        fontFamily: 'sanFranciscoSemi',
        fontSize: '18px',
        fontWeight: 600,
        color: '#666666',
    },
    headerWelcome: {
        height: '20px',
        color: '#4767A0',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '20px',
        textAlign: 'left',
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        textAlign: 'center',
    },
    gridRowText: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#333333',
        fontFamily: 'sanFranciscoMedium',
    },

    //Job Openeing
    jobOpeningContainer: {
        width: '100%',
        display: 'flex',
        background: '#fff',
        marginTop: '30px',
        borderRadius: '4px',
        boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.15)',
    },
    jobContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 25px',
        padding: '25px 0',
    },
    noJobText: {
        height: '32px',
        width: '553px',
        color: '#53627C',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '32px',
    },
    createJobButton: {
        color: '#FFFFFF',
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '20px',
        textAlign: 'center',
    },
    joblistContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    joblistDetailContainer: {
        flex: 2,
    },
    joblistTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '700px',
    },
    joblistTitle: {
        height: '20px',
        color: '#4767a0',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '20px',
        marginRight: '10px',
        fontFamily: 'sanFranciscoMedium',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textTransform: 'capitalize',
    },
    joblistTag: {
        height: '16px',
        width: '67px',
        color: '#8A94A6',
        fontSize: '8px',
        fontWeight: '600',
        lineHeight: '8px',
        textAlign: 'center',
        border: '1px solid #eff0f4',
        borderRadius: '2px',
        background: '#f1f1f4',
        paddingTop: '2px',
    },
    joblistCandidates: {
        display: 'flex',
        flexDirection: 'row',
        height: '20px',
        color: '#4767A0',
        fontSize: '14px',
        lineHeight: '20px',
        cursor: 'pointer',
    },
    joblistSubTitle: {
        height: '16px',
        color: '#4767A0',
        fontSize: '9px',
        lineHeight: '16px',
        marginTop: 6,
        cursor: 'pointer',
        fontFamily: 'sanFranciscoMedium',
    },
    joblistActionContainer: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
    },
    joblistCoordinatorText: {
        height: '16px',
        width: '93px',
        color: '#8A94A6',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '16px',
        marginRight: '8px',
    },
    joblistCoordinators: {
        width: '30px',
        height: '30px',
        textAlign: 'center',
        padding: '0px',
        color: '#FFFFFF',
        fontSize: '12px',
        lineHeight: '16px',
        marginRight: '5px',
    },
    joblistVP: {
        height: '32px',
        minWidth: '95px',
        color: '#FFFFFF',
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '20px',
        textAlign: 'center',
        margin: '0 0 0 20px',
    },

    //Candidate Application
    noJobButton: {
        width: '174px',
        height: '40px',
        fontFamily: 'sanFranciscoMedium',
        fontSize: '12px',
        fontWeight: 'bold',
        borderRadius: '4px',
        backgroundColor: '#4767a0',
    },
    noCandidateButton: {
        width: 174,
        height: 40,
        borderRadius: 4,
        backgroundColor: '#4767a0',
        fontFamily: 'sanFranciscoMedium',
        marginTop: 20,
        fontSize: 12,
        fontWeight: 'bold',
    },

    //Scheduled Interview
    interviewDetail: {
        color: '#FFFFFF',
        fontFamily: 'sanFranciscoMedium',
        fontSize: '10px',
        lineHeight: '20px',
        textAlign: 'center',
        marginLeft: 'auto',
    },

    //Team Activity
    inviteTeamMembers: {
        color: '#FFFFFF',
        fontFamily: 'sanFranciscoMedium',
        fontSize: '12px',
        lineHeight: '20px',
        textAlign: 'center',
    },

    referLink: {
        referLink: {
            height: 34,
            width: 683,
            color: '#A6AEBC',
            fontSize: 14,
            marginTop: 10,
        },
    },
}
