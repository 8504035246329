import React from 'react'
import { ReactComponent as MobileIcon } from '../../../../assets/Icons/Interview/mobile.svg'
import { ReactComponent as OfficeIcon } from '../../../../assets/Icons/Interview/office.svg'
import { ReactComponent as SkypeIcon } from '../../../../assets/Icons/Interview/skype.svg'
import { ReactComponent as MeetIcon } from '../../../../assets/Icons/Interview/google-meet.svg'
import { ReactComponent as OutlookIcon } from '../../../../assets/Icons/Interview/outlook.svg'
import { store } from '../../../../redux/store'

// INTERVIEW_TYPES
//  save -->  *type*
//  view(frontend) -->  *name*
export const INTERVIEW_TYPES = {
    TELEPHONIC: 'Telephonic',
    SKYPE: 'Skype',
    HANGOUTS: 'Google Meet',
    F2F: 'Face to Face',
    ZOOM: 'Zoom Meetings',
    OUTLOOK: 'Microsoft Outlook',
}

export const INTERVIEW_TYPES_KEYS = {
    TELEPHONIC: 'TELEPHONIC',
    SKYPE: 'SKYPE',
    HANGOUTS: 'Google Meet',
    F2F: 'F2F',
    ZOOM: 'ZOOM',
    OUTLOOK: 'OUTLOOK',
}

export const INTERVIEW_TYPES_ICONS = [
    { type: 'TELEPHONIC', name: INTERVIEW_TYPES.TELEPHONIC, icon: <MobileIcon /> },
    { type: 'SKYPE', name: INTERVIEW_TYPES.SKYPE, icon: <SkypeIcon /> },
    { type: 'Google Meet', name: INTERVIEW_TYPES.HANGOUTS, icon: <MeetIcon /> },
    { type: 'F2F', name: INTERVIEW_TYPES.F2F, icon: <OfficeIcon /> },
    // { type: 'ZOOM', name: INTERVIEW_TYPES.ZOOM, icon: <OfficeIcon /> },
    { type: INTERVIEW_TYPES_KEYS.OUTLOOK, name: INTERVIEW_TYPES.OUTLOOK, icon: <OfficeIcon /> },
]

// TODO: remove after zoom deployment
export const INTERVIEW_TYPES_ICONS_ZOOM = [
    { type: 'TELEPHONIC', name: INTERVIEW_TYPES.TELEPHONIC, icon: <MobileIcon /> },
    { type: 'SKYPE', name: INTERVIEW_TYPES.SKYPE, icon: <SkypeIcon /> },
    { type: 'Google Meet', name: INTERVIEW_TYPES.HANGOUTS, icon: <MeetIcon /> },
    { type: 'F2F', name: INTERVIEW_TYPES.F2F, icon: <OfficeIcon /> },
    // { type: 'ZOOM', name: INTERVIEW_TYPES.ZOOM, icon: <OfficeIcon /> },
    // { type: INTERVIEW_TYPES_KEYS.OUTLOOK, name: INTERVIEW_TYPES.OUTLOOK, icon: <OfficeIcon /> },
]
export const INTERVIEW_TYPES_ICONS_CONDITIONAL_SWITCH = isZoomEnabled => {
    return isZoomEnabled ? INTERVIEW_TYPES_ICONS_ZOOM : INTERVIEW_TYPES_ICONS
}

export const SCHEDULE_CONFIRMATIONS = {
    RESCHEDULE: 'Reschedule',
    SCHEDULE: 'Schedule',
    BACK: 'BACK',
    YES: 'Yes',
    NO: 'No',
}

export const SUBJECT_TEMPLATE = ({ interviewType, isRescheduled, jobDetail, interview, isZoomEnabled }) => {
    if (interview && interview.emailSubject && interview.emailSubject.length) return interview.emailSubject
    return `${interviewType
            ? (interviewType === 'Google Meet' ? INTERVIEW_TYPES.HANGOUTS : INTERVIEW_TYPES[interviewType]) + ' '
            : ''
        }Interview ${isRescheduled ? 'rescheduled' : 'scheduled'} for the ${jobDetail && jobDetail.jobTitle} position`
}

export const EMAIL_TEMPLATE = ({
    candidate: { name = 'Candidate' },
    jobDetail: { jobTitle, jobId },
    __redux: {
        Job: { allJobs },
    },
    companyName,
    interviewType = '',
    selectedDate,
    hangoutsLink,
    selectedAddress,
    startTime,
    endTime,
    isRescheduled,
    meetingDetails,
    noHangouts,
}) => {
    let body =
        '<b>Please be on time for the interview. Reply to this thread in case you are not available or you would like to get the interview rescheduled.</b>'
    let information = ''
    let { gCal } = store.getState().Auth
    const selectedJob = allJobs.find(({ _id }) => _id === jobId) || {}

    switch (interviewType) {
        case INTERVIEW_TYPES_KEYS.HANGOUTS:
            const linkGenerator = hangoutsLink ? `<a href="${hangoutsLink}">${hangoutsLink}</a>` : ''
            if (gCal && gCal.default)
                body = `At the time of interview, please click on the google Meet link below to join the interview<br/><b>${linkGenerator}</b>`
            else if (!noHangouts) {
                body = '---The updated Google Meet link will be sent along with this mail---'
            }
            break

        case INTERVIEW_TYPES_KEYS.ZOOM:
            body = '---The updated Zoom meetings link will be sent along with this mail---'
            break

        case INTERVIEW_TYPES_KEYS.OUTLOOK:
            body = '---The updated Outlook meeting link will be sent along with this mail---'
            break

        case INTERVIEW_TYPES_KEYS.F2F:
            if (selectedAddress) information = `Address: <b>${selectedAddress}</b><br\>`
            else if (meetingDetails) information = `Address: <b>${meetingDetails}</b><br\>`
            break
        default:
    }

    return `<div>Hi ${name},<br\> <br\>
            An interview has been ${isRescheduled ? 'rescheduled' : 'scheduled'}. Details are stated below.<br\>
            Job Role: <b>${jobTitle}</b><br\>
            Company: <b>${selectedJob.companyName || companyName}</b><br\>
            Interview type: <b>${interviewType.length
            ? interviewType === 'Google Meet'
                ? INTERVIEW_TYPES.HANGOUTS
                : INTERVIEW_TYPES[interviewType]
            : ''
        }</b> <br\>
            ${information}
            Interview date and time: <b>${(selectedDate && selectedDate.format('DD MMM YYYY')) || ''} ${(selectedDate &&
            startTime) ||
        ''}${(selectedDate && startTime && endTime && ` - ${endTime}`) || ''}</b><br\>

            </b><br\>${body}<br\> <br\>

            Best Regards,<br\>
            Hiring Team<br\>
            ${selectedJob.companyName || companyName}.</div>`
}

export const FORM_CONFIGS = {
    ITEM_SKYPE: {
        ERROR: 'Candidate Skype ID is required',
        HOLDER: 'Enter Skype ID',
    },
    ITEM_PHONE: {
        ERROR: 'Phone number is not available',
        HOLDER: 'Enter Phone number',
    },

    ITEM_INTERVIEW_TYPES: {
        ERROR: 'Interview type is required ',
        HOLDER: 'Select type of interview',
    },

    ITEM_DATE: {
        ERROR: 'Date is required',
    },
    ITEM_TIME: {
        ERROR: 'Invalid time',
    },
    ITEM_INTERVIEWERS: {
        ERROR: 'Atleast 1 interviewer required.',
        MAX: 10,
        ERROR_MAX: 'Maximum of 10 interviewers added',
        ERROR_NOTI_MAX: 'Maximum of 10 interviewers can be added to the interview',
    },
}
