import React, { Component } from 'react'
import './../style.css'
import { Container } from '../style'
import { Row, Col, Form, Typography, Input, Icon } from 'antd'
import { warning } from '../../../../services/Notification'
import { ReactComponent as Up } from '../../../../assets/svg/career-up.svg'
import { ReactComponent as Down } from '../../../../assets/svg/career-down.svg'
import { ReactComponent as Edit } from '../../../../assets/svg/career-edit.svg'
import { BENEFITS_LIMITS, REQUIRED_ERRORS, VALIDATION_STATUS } from '../DataConfigs'
import SaveContainer from './SaveContainer'

const { Text } = Typography
const { TextArea } = Input
const { TITLE, DESCRIPTION, TITLE_ERROR, DESCRIPTION_ERROR } = BENEFITS_LIMITS
const { REQUIRED_TITLE_ERROR, REQUIRED_DESC_ERROR, REQUIRED_ONLY_TITLE_ERROR } = REQUIRED_ERRORS

class Benefit extends Component {
    state = {
        editFlag: false,
        titleRequired: false,
        validate_title_status: VALIDATION_STATUS.success,
        validate_title_help: '',
    }

    onSubmit = e => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                try {
                    const number = ['1', '2', '3', '4']
                    const details = []
                    const { title } = values
                    let dataFilledPoints = 0

                    // formating the data to be saved in the parent state
                    number.forEach(each => {
                        const title = values[`title${each}`]
                        const description = values[`description${each}`]
                        if (title.length && description.length) ++dataFilledPoints
                        details.push({ title, description })
                    })

                    // VALIDATION CHECK: if there are any of the details filled then title is neceseccary
                    if (title.length === 0 && dataFilledPoints > 0) {
                        this.setState({
                            validate_title_status: VALIDATION_STATUS.error,
                            validate_title_help: REQUIRED_ONLY_TITLE_ERROR,
                        })
                        return null
                    }

                    if (title.length > TITLE) {
                        this.setState({
                            validate_title_status: VALIDATION_STATUS.error,
                            validate_title_help: TITLE_ERROR,
                        })
                        return null
                    }

                    let value = { details, title }
                    this.props.onSubmit(value, this.props.index, 'benefits')
                    this.setState({ editFlag: false })
                    this.props.updateEditModeCount('benefit', 1)
                    warning(`BENEFITS section saved successfully`)
                } catch (err) {
                    warning(err.message || err)
                }
            }
        })
    }

    validator = index => {
        const title = this.props.form.getFieldValue(`title${index + 1}`)
        const description = this.props.form.getFieldValue(`description${index + 1}`)
        const isTitleValid = title && title.length > 0
        const isDescValid = description && description.length > 0

        return isTitleValid || isDescValid
    }

    detailsValidator = () => {
        const { getFieldsValue } = this.props.form
        const { title } = getFieldsValue(['title'])
        const { title1, title2, title3, title4 } = getFieldsValue(['title1', 'title2', 'title3', 'title4'])
        const { description1, description2, description3, description4 } = getFieldsValue([
            'description1',
            'description2',
            'description3',
            'description4',
        ])
        const pointOne = title1 && description1
        const pointTwo = title2 && description2
        const pointThree = title3 && description3
        const pointFour = title4 && description4
        const req = pointOne || pointTwo || pointThree || pointFour
        return (title && title.length > 0 && !req) || false
    }

    renderBenefit = (each, index) => {
        const { getFieldDecorator } = this.props.form

        return (
            <Col span={12} key={index}>
                <Text className="carrer-cont-title">BENEFIT {index + 1}</Text>
                <Text className="carrer-cont-title">Title</Text>
                <Form.Item className="benefit-title">
                    {getFieldDecorator(`title${index + 1}`, {
                        initialValue: each.title || '',
                        validateTrigger: ['onChange', 'onBlur'],
                        validateFirst: true,
                        rules: [
                            { max: TITLE, message: TITLE_ERROR },
                            {
                                required: this.validator(index),
                                message: REQUIRED_TITLE_ERROR,
                            },
                            {
                                required: this.detailsValidator(index),
                                message: BENEFITS_LIMITS.REQUIRED_ERROR,
                            },
                        ],
                    })(<Input size="large" placeholder="Enter your benefit title" onChange={this.handleChange} />)}
                </Form.Item>
                <Text className="carrer-cont-title">Description</Text>
                <Form.Item className="benefit-title">
                    {getFieldDecorator(`description${index + 1}`, {
                        initialValue: each.description || '',
                        validateTrigger: ['onChange', 'onBlur'],
                        validateFirst: true,
                        rules: [
                            { max: DESCRIPTION, message: DESCRIPTION_ERROR },
                            {
                                required: this.validator(index),
                                message: REQUIRED_DESC_ERROR,
                            },
                        ],
                    })(<TextArea rows={4} placeholder="Enter your description" onChange={this.handleChange} />)}
                </Form.Item>
            </Col>
        )
    }

    onCancel = () => {
        const { benefit } = this.props
        this.props.form.setFieldsValue({
            title1: benefit.details[0] ? benefit.details[0].title : '',
            description1: benefit.details[0] ? benefit.details[0].description : '',
            title2: benefit.details[1] ? benefit.details[1].title : '',
            description2: benefit.details[1] ? benefit.details[1].description : '',
            title3: benefit.details[2] ? benefit.details[2].title : '',
            description3: benefit.details[2] ? benefit.details[2].description : '',
            title4: benefit.details[3] ? benefit.details[3].title : '',
            description4: benefit.details[3] ? benefit.details[3].description : '',
            title: benefit.title,
        })
        this.setState({
            editFlag: false,
            validate_title_status: VALIDATION_STATUS.success,
            validate_title_help: '',
        })
        this.props.updateEditModeCount('benefit', -1)
    }

    moveUp = () => {
        this.props.moveUp(this.props.index)
    }

    moveDown = () => {
        this.props.moveDown(this.props.index)
    }

    edit = () => {
        this.setState({ editFlag: true })
        this.props.updateEditModeCount('benefit', 0)
    }

    handleChange = value => {
        this.setState({ editFlag: true })
        this.props.updateEditModeCount('benefit', 0)
    }

    handleTitleChange = ({ target: { value } }) => {
        const successIndicator = {
            validate_title_status: VALIDATION_STATUS.success,
            validate_title_help: '',
        }

        this.setState(state => {
            if (value.length > 0) return { editFlag: true, ...successIndicator }
            return { editFlag: true }
        })
        this.props.updateEditModeCount('benefit', 0)
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { benefit } = this.props

        return (
            <div key={this.props.key}>
                <Container>
                    <Row gutter={48}>
                        <Col span={12}>
                            <Text className="carrer-cont-title">BENEFITS</Text>
                        </Col>
                        <Col span={12}>
                            <div className="career-cont-btn">
                                {this.props.index >= 1 ? (
                                    <div className="career-section-icons" onClick={this.moveUp}>
                                        <Icon className="career-icons" component={Up} />
                                    </div>
                                ) : null}
                                {this.props.index < this.props.length - 1 ? (
                                    <div className="career-section-icons" onClick={this.moveDown}>
                                        <Icon className="career-icons" component={Down} />
                                    </div>
                                ) : null}
                                <div className="career-section-icons" onClick={this.edit}>
                                    <Icon className="career-icons" component={Edit} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Text className="carrer-cont-title">Title</Text>
                            <Form.Item
                                validateStatus={this.state.validate_title_status}
                                help={this.state.validate_title_help}
                            >
                                {getFieldDecorator('title', {
                                    initialValue: benefit.title || '',
                                })(<Input size="large" placeholder="BENEFITS" onChange={this.handleTitleChange} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={48}>
                        {this.props.benefit.details.map((each, index) => this.renderBenefit(each, index))}
                    </Row>
                    {this.state.editFlag && <SaveContainer onSubmit={this.onSubmit} onCancel={this.onCancel} />}
                </Container>
            </div>
        )
    }
}

export default Form.create({ name: 'benefit' })(Benefit)
