import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { store } from '../../../redux/store'

import Todo from '../../Pages/Todo'
import { connect } from 'react-redux'

const routes = [
    {
        path: '',
        render: props => <Todo {...props} />,
        accessLevel: [1, 2, 3, 4, 5],
    },
]

class TodoRouter extends Component {
    render() {
        const { url, style, allowSwitchTabs, __redux } = this.props
        const { accessLevel } = store.getState().Auth.user

        return (
            <div style={style}>
                {routes
                    .filter(singleRoute => singleRoute.accessLevel.includes(accessLevel))
                    .map(singleRoute => {
                        const { path, exact, render, ...otherProps } = singleRoute
                        return (
                            <Route
                                exact={exact === false ? false : true}
                                key={singleRoute.path}
                                render={render ? props => render({ ...props, allowSwitchTabs, __redux }) : undefined}
                                path={`${url}/${singleRoute.path}`}
                                {...otherProps}
                            />
                        )
                    })}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { __redux: state }
}

export default connect(mapStateToProps)(TodoRouter)
