export const SIDE_PANEL_DATA = {
    1: {
        title: 'Welcome to SpringRecruit',
        image: 'welcome',
    },
    2: {
        tick: true,
        text: 'Successfully connected',
        image: 'connected',
    },
    3: {
        text: 'Select the time slots when you are typically available to take interviews',
        image: 'connected',
    },
    4: {
        image: 'connected',
    },
    5: {
        image: 'denied',
    },
}
