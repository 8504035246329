import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { Form1, OpeningsSearch } from '../style'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/Icons/Access/close.svg'
import { Icon, Input, Radio, Popover, Checkbox } from 'antd'
import { Tags } from '../../../Modals/style'
import { Field } from '../../../Modals/AddCandidate/style'
const { Group: RadioGroup } = Radio

const StyledOpenings = props => {
    const { allJobs, setSelectedOpenings, selectedOpeningIds } = props
    const [inputValue, setInputValue] = useState('')

    const handleChange = (e, job) => setSelectedOpenings(e.target.checked, job)
    return (
        <OpeningsSearch>
            <Input
                size="large"
                className="openings-search-box"
                value={inputValue}
                placeholder="Search openings"
                suffix={<Icon type="search" />}
                onChange={({ target: { value } }) => setInputValue(value)}
            />
            <div className="openings-list">
                {allJobs
                    .filter(job => job.jobTitle.toLowerCase().includes(inputValue.trim().toLowerCase()))
                    .map(job => (
                        <Checkbox
                            key={job._id}
                            defaultChecked={selectedOpeningIds.includes(job._id)}
                            className="openings-checkbox"
                            onChange={e => handleChange(e, job)}
                        >
                            {job.jobTitle}
                        </Checkbox>
                    ))}
            </div>
        </OpeningsSearch>
    )
}

class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedOpenings: props.selectedOpenings,
            selectedOpeningIds: props.selectedOpeningIds,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedOpenings, selectedOpeningIds } = this.props
        if (prevProps.selectedOpenings.length !== selectedOpenings.length) this.setState({ selectedOpenings })
        if (prevProps.selectedOpeningIds.length !== selectedOpeningIds.length) this.setState({ selectedOpeningIds })
    }

    setSelectedOpenings = (checked, job) => {
        let { selectedOpenings, selectedOpeningIds } = this.state
        if (checked) this.props.setSelectedOpenings('push', job)
        else this.props.setSelectedOpenings('filter', job)
        this.setState({ selectedOpenings, selectedOpeningIds })
    }

    setSelectedRadio = (e, field) => {
        this.props.setSelectedRadioFields(field, e.target.value)
    }

    render() {
        const {
            candidateType,
            openingType,
            candidateTypeError,
            openingTypeError,
            selectedOpenings,
            selectedOpeningIds,
        } = this.props
        return (
            <Form1>
                <Field>
                    <div className="title">Select type of candidate to source*</div>
                    <RadioGroup onChange={e => this.setSelectedRadio(e, 'candidateType')} value={candidateType}>
                        <Radio value={'archived'}>Only Archived Candidate</Radio>
                        <Radio value={'rejected'}>Only Rejected Candidate</Radio>
                        <Radio value={'both'}>Both</Radio>
                    </RadioGroup>
                    {candidateTypeError ? <div className="error">This field can not be blank</div> : null}
                </Field>
                <Field>
                    <div className="title">Select filter type for sourcing candidate*</div>
                    <RadioGroup onChange={e => this.setSelectedRadio(e, 'openingType')} value={openingType}>
                        <Radio value={'all'}>All Openings</Radio>
                        <Radio value={'selected'}>Selected Openings</Radio>
                    </RadioGroup>
                    {!openingType && openingTypeError ? <div className="error">This field can not be blank</div> : null}
                </Field>
                {openingType === 'selected' ? (
                    <Field>
                        <div className="title">Select Openings*</div>
                        <Popover
                            arrowPointAtCenter
                            placement="bottom"
                            trigger="click"
                            content={
                                <StyledOpenings
                                    allJobs={this.props.allJobs}
                                    setSelectedOpenings={this.setSelectedOpenings}
                                    selectedOpeningIds={selectedOpeningIds}
                                />
                            }
                            getPopupContainer={trigger => trigger.parentNode}
                            destroyTooltipOnHide={true}
                        >
                            <div className={`openings ${openingTypeError ? 'border-red' : ''}`}>
                                Select
                                <DownIcon className="down-icon" />
                            </div>
                        </Popover>
                        {openingTypeError ? <div className="error">This field can not be blank</div> : null}
                        <Tags className="tags">
                            {selectedOpenings.map(opening => (
                                <div key={opening._id}>
                                    <span>{opening.jobTitle}</span>
                                    <i onClick={() => this.setSelectedOpenings(false, opening)}>
                                        <CloseIcon />
                                    </i>
                                </div>
                            ))}
                        </Tags>
                    </Field>
                ) : null}
            </Form1>
        )
    }
}

const mapStateToProps = state => {
    return {
        allJobs: (state.Job.allJobs && state.Job.allJobs.filter(each => each.status)) || [],
    }
}

export default connect(mapStateToProps)(Form)
