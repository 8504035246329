import styled from 'styled-components'
import BackgroundSVG from '../../../assets/svg/background.svg'

import LogoIcon from '../../../assets/Icons/Landing/logo.svg'

export const SignupWrapper = styled.div`
    // width: auto;
    // height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
`

export const SignupContainer = styled.div`
    width: 100%;
    height: 100vh;
    background: url(${BackgroundSVG});
    background-size: cover;
`

export const ContentWrapper = styled.div`
    text-align: center;
    margin: auto;
    border-radius: 8px;
    padding: 33px 80px 20px 80px;
    width: 100%;
    @media only screen and (max-width: 1280px) {
        padding: 33px 10px 20px 10px;
    }
`

export const FormWrapper = styled.div`
    margin: 30px auto;
`

export const LogoWrapper = styled.div`
    position: absolute;
    top: 0;
    margin: 55px 0 0 48px;
    background: url(${LogoIcon});
    padding: 50px;
    background-repeat: no-repeat;
`


export const ContentWrapperOTP = styled.div`
    background-color: #ffffff;
    padding: 33px 56px;
    text-align: center;
    margin: auto;
    min-height: 410px;
    width: 464px;
    border-radius: 8px;
    @media only screen and (max-width: 995px) {
        width: 100%;
        padding: 10px;
    }
`

export const FormWrapperOTP = styled.div`
    margin: 60px auto 30px;
`

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
`

export const ToCWrapper = styled.div`
    color: #8a94a6;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 20px;
`
