import styled from 'styled-components'

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 15px auto 30px;
    height: 40px;
    min-width: 1200px;
    width: 1200px;
    @media only screen and (max-width: 995px) {
        display: block;
        height: unset;
        min-width: unset;
        margin: 15px auto 15px;
    }
`

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`

export const ModalElementWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    text-align: left;
`

export const FilterWrapper = styled.div`
    border: 1px solid #eff0f4;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px 0 100px;
    @media only screen and (max-width: 995px) {
        display: block;
        padding: 15px;
    }
`

export const FilterContainer = styled.div`
    min-width: 285px;
    width: 20.86vw;
    height: 578px;
    margin-right: 2.93vw;
    @media only screen and (max-width: 769px) {
        margin: 0;
        padding: 0;
        width: 100%;
        min-width: unset;
        margin-bottom: 20px;
        height: unset;
    }
`

export const ListContainer = styled.div`
    width: 100%;
    font-family: sanFranciscoMedium;
    @media only screen and (max-width: 995px) {
        min-width: unset;
        width: 100%;
    }
`

export const ListFilterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 995px) {
        min-width: unset;
        width: 100%;
        display: block;
        div {
            width: 100%;
            margin-bottom: 8px;
            button {
                width: 100%;
            }
        }
    }
`

export const CandidateHeaderWrapper = styled.div`
    height: 40px;
    line-height: 40px;
    border: 1px solid #eff0f4;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08), 0 2px 10px 0 rgba(10, 31, 68, 0.1);
    display: flex;
    padding: 0px 28.5px 0 12px;
    justify-content: space-between;
    background-color: #eff0f4;
    font-size: 12px;
    color: #697998;
    margin-bottom: 20px;
    @media only screen and (max-width: 995px) {
        padding: 0 12px;
        height: unset;
    }
`

export const ListCheckboxWrapper = styled.div`
    width: 7%;
    display: flex;
`

export const CandidateWrapper = styled.div`
    width: 40%;
    padding: 0 10px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

//TODO: Once details data is fetched and populated
// export const DetailWrapper = styled.div`
//     width: 32%;
// `;

export const StageWrapper = styled.div`
    width: 40%;
`

export const DateWrapper = styled.div`
    width: 20%;
`

export const CandidateListWrapper = styled.div`
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    box-shadow: ${props =>
        props.highlight ? 'none' : '0 0 1px 0 rgba(10,31,68,0.08), 0 2px 10px 0 rgba(10,31,68,0.1)'};
    display: flex;
    padding: 0 28.5px 0 12px;
    justify-content: space-between;
    font-size: 12px;
    color: #697398;
    margin-bottom: 20px;
    font-weight: 500;
    border: ${props => (props.highlight ? '2px solid #4767A0' : 'none')};
    @media only screen and (max-width: 995px) {
        padding: 0 12px;
        height: auto;
    }
`

export const FilterListWrapper = styled.div`
    display: flex;
    padding: 0 20px;
    color: #8990a5;
    font-family: sanFranciscoMedium;
    line-height: 2;
    @media only screen and (max-width: 995px) {
        padding: 0;
    }
    :hover {
        background-color: #f4f5fa;
    }
`
