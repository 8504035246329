import React from 'react'
import { MessageModal } from '../common'

const WarningDoSelect = ({ onCancel, onConfirm }) => (
    <MessageModal
        title="WARNING"
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelText="CANCEL"
        confirmText="UPDATE"
        message="On updating DoSelect credentials all existing candidates test data will be updated as per new DoSelect account."
    />
)

export default WarningDoSelect
