import actions from './actions'
import { PURGE } from 'redux-persist'

const initState = { inUse: false }

export default function zoomReducer(state = initState, action) {
    const { type, payload } = action

    switch (type) {
        case PURGE:
            return { ...initState }
        case actions.SET_IN_USE:
            return { ...state, inUse: action.payload.inUse }

        case actions.SET_NOT_IN_USE:
            return { ...initState, inUse: false }

        case actions.SET_USER_ZOOM:
            return { ...state, inUse: true, ...payload }

        case actions.REMOVE_USER_ZOOM:
            return { ...initState }
        default:
            return state
    }
}
