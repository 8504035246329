import { store } from './store'
import { GetDoSelectTests, GetAllJobsWithStage, GetTeamMember, GetUnberryPositions } from '../services/api'
import { LogE } from '../container/Helpers/errorHandler'

export const getBootData = _user => {
    try {
        const user = _user || store.getState().Auth.user
        GetAllJobsWithStage()
        GetTeamMember()
        if ([1, 2, 3, 4].includes(user.accessLevel)) {
            GetDoSelectTests()
            GetUnberryPositions()
        }
    } catch (err) {
        LogE('getBootData', '', err)
    }
}
