import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Select, Form, Icon, Button } from 'antd'
import { ReactComponent as DownIcon } from '../../../../assets/Icons/Interview/down.svg'
import { ReactComponent as ErrorIcon } from '../../../../assets/Icons/AccountSettingPage/Error_Icon.svg'
import { ReactComponent as FileIcon } from '../../../../assets/Icons/Talent Pool/File_Icon.svg'
import { ReactComponent as CrossIcon } from '../../../../assets/Icons/Talent Pool/Cross_Icon.svg'
import { ReactComponent as LoadingIcon } from '../../../../assets/Icons/Openings/loading.svg'
import { StyledDropzone } from '../../../Components/StyledDropzone'
import { ALLOWED_FILE_FORMATS } from '../../../Helpers/fileTypeValidation'
import { FormWrapper } from '../style'
import StyledButton from '../../../Components/StyledButton'
import { warning } from '../../../../services/Notification'
import { UploadCSVFile } from '../../../../services/NetworkCalls/Candidate'
import apiCaller from '../../../../services/apiCaller'

const { Option } = Select
const { formatError } = apiCaller

const Loading = () => (
    <div className="drop-zone">
        <LoadingIcon style={{ width: 50, height: 50 }} />
    </div>
)

const Loaded = props => (
    <div className="drop-zone drop-zone-flex-column">
        <div className="drop-zone-flex-row">
            <div className="drop-zone-file">
                <FileIcon />
            </div>
            <div
                className="drop-zone-cross"
                onClick={() => {
                    props.setStatus(null)
                    props.setFileName(null)
                }}
            >
                <CrossIcon />
            </div>
        </div>
        <div className="drop-zone-file-name">{props.fileName}</div>
    </div>
)

class Uploader extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.pageRefreshhandler = this.pageRefreshhandler.bind(this)
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.pageRefreshhandler)
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.pageRefreshhandler)
    }

    pageRefreshhandler(e) {
        const alert = this.state.excelUploadStatus || this.state.zipUploadStatus
        if (alert) {
            var confirmationMessage = 'o/'
            e.returnValue = confirmationMessage
            return confirmationMessage
        }
        return
    }

    onDrop = async (acceptedFile, fileName, uploadStatus, fileError) => {
        try {
            let formData = new FormData()
            formData.append('excelFile', acceptedFile[0], acceptedFile[0].name)
            const res = await this.props.UploadCSVFile(formData)
            if (res.status === 200) {
                this.setState({ [fileName]: acceptedFile[0].name, [uploadStatus]: 'uploaded' })
            } else if (res.error) warning(res.error.msg)
        } catch (err) {
            warning(formatError(err))
            this.setState({ [uploadStatus]: null, [fileError]: 'Unsupported file format' })
        }
    }

    excelDrop = acceptedFile => {
        if (acceptedFile.length === 0) this.setState({ excelFileError: 'Unsupported file format' })
        else if (acceptedFile[0].size > 60 * 1024 * 1024)
            this.setState({ excelFileError: 'File size exceeds maximum limit' })
        else {
            this.setState({ excelFileError: null, excelUploadStatus: 'uploading' }, () => {
                this.onDrop(acceptedFile, 'excelFileName', 'excelUploadStatus', 'excelFileError')
            })
        }
    }

    zipDrop = acceptedFile => {
        if (acceptedFile.length === 0) this.setState({ zipFileError: 'Unsupported file format' })
        else if (acceptedFile[0].size > 60 * 1024 * 1024)
            this.setState({ zipFileError: 'File size exceeds maximum limit' })
        else {
            this.setState({ zipFileError: null, zipUploadStatus: 'uploading' }, () => {
                this.onDrop(acceptedFile, 'zipFileName', 'zipUploadStatus', 'zipFileError')
            })
        }
    }

    setExcelUploadStatus = status => {
        this.setState({ excelUploadStatus: status })
    }

    setExcelFileName = fileName => {
        this.setState({ excelFileName: fileName })
    }

    setZipUploadStatus = status => {
        this.setState({ zipUploadStatus: status })
    }

    setZipFileName = fileName => {
        this.setState({ zipFileName: fileName })
    }

    setSelectedJob = value => {
        const { stages } = this.props.allJobs.find(job => job._id === value) || {}
        const selectedStage = stages && stages[0]._id
        this.setState({ selectedJob: value, selectedStage })
    }

    setSelectedStage = value => {
        this.setState({ selectedStage: value })
    }

    onSubmit = () => {
        const { excelFileError, excelUploadStatus, selectedJob, selectedStage } = this.state
        this.props.onSubmit({ excelFileError, excelUploadStatus, selectedJob, selectedStage })
    }

    render() {
        const {
            excelUploadStatus,
            excelFileName,
            excelFileError,
            zipUploadStatus,
            zipFileName,
            zipFileError,
        } = this.state
        const { stages = [] } =
            (this.state.selectedJob && this.props.allJobs.find(job => job._id === this.state.selectedJob)) || {}
        const calculatedStage = this.state.selectedStage || (stages.length && stages[0]._id) || undefined
        const disable = excelUploadStatus !== 'uploaded' || zipUploadStatus === 'uploading'

        if (excelUploadStatus === 'uploading' || zipUploadStatus === 'uploading') this.props.setDisable(true)
        else this.props.setDisable(false)

        return (
            <FormWrapper>
                <p className="drop-header-title">Upload candidate profile details*</p>
                <div className="drop-header-zone">
                    {excelUploadStatus === 'uploading' ? (
                        <Loading />
                    ) : excelUploadStatus === 'uploaded' ? (
                        <Loaded
                            fileName={excelFileName}
                            setStatus={this.setExcelUploadStatus}
                            setFileName={this.setExcelFileName}
                        />
                    ) : (
                        <StyledDropzone
                            onDrop={this.excelDrop}
                            content={'Drop your XLS/XLSX/CSV file here'}
                            error={excelFileError}
                            height={112}
                            accept={ALLOWED_FILE_FORMATS.SPREADSHEET_CSV.join(', ')}
                        />
                    )}
                </div>
                <div className="drop-header">
                    <p className="drop-header-title">Upload resume file</p>
                    <div className="drop-header-zone">
                        {zipUploadStatus === 'uploading' ? (
                            <Loading />
                        ) : zipUploadStatus === 'uploaded' ? (
                            <Loaded
                                fileName={zipFileName}
                                setStatus={this.setZipUploadStatus}
                                setFileName={this.setZipFileName}
                            />
                        ) : (
                            <StyledDropzone
                                onDrop={this.zipDrop}
                                content={'Drop your ZIP file here'}
                                error={zipFileError}
                                height={112}
                                accept={ALLOWED_FILE_FORMATS.SPREADSHEET_ZIP.join(', ')}
                            />
                        )}
                    </div>
                </div>
                <div className="drop-header drop-display">
                    <div>
                        <p className="drop-header-title">Opening</p>
                        <Select
                            size="large"
                            className="drop-header-select"
                            style={{ width: '245px' }}
                            placeholder={'Select'}
                            getPopupContainer={trigger => trigger.parentNode}
                            suffixIcon={<Icon component={DownIcon} />}
                            value={this.state.selectedJob}
                            onChange={this.setSelectedJob}
                        >
                            <Option key="job-unselect-key" value={undefined}>
                                --Select---
                            </Option>
                            {this.props.allJobs.map(option => (
                                <Option value={option._id} key={option._id}>
                                    {option.jobTitle}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <p className="drop-header-title">Stage</p>
                        <Select
                            size="large"
                            className="drop-header-select"
                            style={{ width: '245px' }}
                            placeholder={'Select'}
                            getPopupContainer={trigger => trigger.parentNode}
                            suffixIcon={<Icon component={DownIcon} />}
                            value={calculatedStage}
                            onChange={this.setSelectedStage}
                        >
                            {stages.map(option => (
                                <Option value={option._id} key={option._id}>
                                    {option.stageName}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <p className="help">Select these two fields to set default value for all profiles</p>
                <StyledButton
                    style={{ float: 'right' }}
                    width="80px"
                    height="40px"
                    disable={disable}
                    onClick={() => this.onSubmit()}
                >
                    SUBMIT
                </StyledButton>
            </FormWrapper>
        )
    }
}

export default connect(
    ({ Job }) => ({ allJobs: Job.allJobs && Job.allJobs.filter(each => each.status) }),
    { UploadCSVFile }
)(Uploader)
